import React from "react";

export default ({ theme, getInput }) => {
  class OtpBox extends React.Component {
    render() {
      const OtpInput = getInput && getInput("otpInput");
      let { data, field, setValue, inputProps } = this.props;
      let value = data[field];
      return (
        <OtpInput
          otpLength={6}
          onChange={otp => {
            setValue && setValue({ data, field, value: otp });
          }}
          value={value}
          {...inputProps}
          {...theme}
        />
      );
    }
  }
  return OtpBox;
};
