import React from "react";

export default ({ Box, theme, getSizeFormat, resolveMQ, getImage, I18N, getUserSpaceData, setUserSpaceRef }) => {
  class DrawerMenuHeader extends React.Component {
    componentDidMount() {
      setUserSpaceRef && setUserSpaceRef(this);
      this.interval = setInterval(() => {
        let data = getUserSpaceData();
        if (data && this.interval) {
          clearInterval(this.interval);
          this.interval = void 0;
          this.setState({});
        }
      }, 1000);
    }

    setInfo = () => {
      this.setState({});
    };

    componentWillUnmount() {
      setUserSpaceRef && setUserSpaceRef(void 0);
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = void 0;
      }
    }
    defaultColors = {};

    render() {
      const {
        user,
        getActionContainer,
        closeDrawer,
        activeMQ,
        showHeader,
        editProfile,
        user: { _id: userId }
      } = this.props;
      let { headerStyle } = resolveMQ(theme, ["headerStyle"], activeMQ);
      let { profile_pic, avatar } = user || {};
      let initials = (avatar && avatar.initials && avatar.initials.toUpperCase()) || "";
      let color = avatar && avatar.color;

      if (!color) {
        if (!this.defaultColors[userId]) {
          const x = Math.floor(Math.random() * 200);
          const y = Math.floor(Math.random() * 200);
          const z = Math.floor(Math.random() * 200);
          const defaultColor = "rgb(" + x + "," + y + "," + z + ")"; // default value for color (randomly generated)
          this.defaultColors[userId] = defaultColor;
        }
        color = this.defaultColors[userId];
      }
      if (!initials && user && user.name) {
        initials = user.name
          .split(" ")
          .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
          .join("")
          .toUpperCase();
      }

      let data = getUserSpaceData();
      let totalSpace = "0 B";
      let usedSpace = "0 B";
      if (data) {
        totalSpace = data.nolimit ? I18N.t("unlimited") : getSizeFormat(data.totalSpace || 0);
        usedSpace = getSizeFormat((data.usedSpace || 0) + (data.trash || 0));
        totalSpace = totalSpace.replace(".", ",");
        usedSpace = usedSpace.replace(".", ",");
      }
      return (
        <Box
          render={[
            showHeader && {
              ...headerStyle.headerActionStyle,
              render: [
                { ...headerStyle.logoStyle },
                {
                  ...headerStyle.crossIconStyle,
                  image: getImage("menuCrossIcon"),
                  onClick: closeDrawer
                }
              ]
            },
            {
              ...headerStyle.boxStyle,
              render: [
                {
                  ...headerStyle.imageContainerStyle,
                  ...getActionContainer({
                    type: "link",
                    link: { uri: "/profile#profile" }
                  }),
                  render: [
                    profile_pic
                      ? {
                          image: { uri: profile_pic },
                          imageStyle: { width: 80, height: 80, borderRadius: 40, backgroundColor: "#e7e7e7" }
                        }
                      : initials && {
                          viewStyle: { width: 80, height: 80, borderRadius: 40, backgroundColor: color },
                          textStyle: { textAlign: "center", marginTop: 23, color: "white", fontSize: 26 },
                          text: initials
                        },
                    editProfile && {
                      ...headerStyle.editIconStyle,
                      title: I18N.t("editProfile"),
                      image: getImage("menuPencilIcon")
                    }
                  ]
                },
                { text: `${user.name || user.mobile}`, ...headerStyle.titleStyle },
                {
                  text:
                    data && data.nolimit
                      ? `${usedSpace} ${I18N.t("used")} - ${I18N.t("unlimited")}`
                      : `${usedSpace} ${I18N.t("used")} ${I18N.t("of")} ${totalSpace} `,
                  ...headerStyle.userLimitStyle
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return DrawerMenuHeader;
};
