/**
 *
 *  what to do with rest props - rohit bansal - 09-12-18
 *  rohit - 09-01-19 - defaultConnectState - TableScreenLoader - for defaultGroupBy
 *  columns :[]
 *  rowActions
 *  multiRowActions
 *  group by row
 *  hidden columns
 *  columns :{field:"",type:"", textColor, textStyle}
 *  cell level - background color, row level bg color,
 *  column:{render:[]}
 *  hover color, hover bg, shadow
 *  group by row - >
 *
 */
import React from "react";
import { shallowCompare } from "app-utility-functions";
export default ({
  errorComponent,
  connectStateKey,
  JsonTable,
  selectionStoreStateKey,
  componentStateKey,
  writeLog,
  Connect,
  SelectionStore,
  Panel,
  resolveMQ,
  ScreenContextProvider,
  logger,
  addLog,
  logFirebaseAnalyticsScreenEvent
}) => {
  class ConnectPanelTable extends React.Component {
    tableLoader = () => {};

    constructor(props) {
      super(props);
      let { route } = props;
      let state = {};
      if (route) {
        state.Route = { ...route };
      }
      this.state = state;
    }

    shouldComponentUpdate(nextProps, nextState) {
      let equal = shallowCompare(nextProps, this.props, { name: "ConnectPanelTable:" + nextProps.screenName });
      if (equal) {
        equal = shallowCompare(nextState, this.state, { name: "ConnectPanelTable:" + nextProps.screenName });
      }
      return !equal;
    }

    _getState = () => {
      let { state } = this.props;
      return state || this.state;
    };

    _setState = (key, state) => {
      let keyState = this._getState()[key];
      if (typeof state === "function") {
        state = state(keyState);
      }
      let { setState } = this.props;
      let newState = {
        [key]: {
          ...keyState,
          ...state
        }
      };
      if (setState) {
        setState(newState);
      } else {
        this.setState(newState);
      }
    };

    setConnectState = state => {
      this._setState(connectStateKey, state);
    };

    setSelectionState = state => {
      this._setState(selectionStoreStateKey, state);
    };

    setComponentState = state => {
      this._setState(componentStateKey, state);
    };

    UNSAFE_componentWillMount() {
      addLog && addLog(`CPT mounted ${this.props.screenName}`);
      if (!this.props.selectedTab) {
        logFirebaseAnalyticsScreenEvent && logFirebaseAnalyticsScreenEvent({ screenName: this.props.screenName });
      }
    }

    componentWillUnmount() {
      this._unmounted = true;
      addLog && addLog(`CPT unmounted ${this.props.screenName}`);
    }

    UNSAFE_componentWillUpdate() {
      this.endLog =
        logger &&
        logger.startLog({
          component: "ConnectPanelTable:" + (this.props.screenName || ""),
          method: "CWU"
        });
    }

    componentDidUpdate() {
      addLog && addLog(`CPT updated ${this.props.screenName}`);
      this.endLog && this.endLog();
    }

    render() {
      try {
        let {
          componentType,
          subComponentType,
          schema,
          connectProps,
          selectionStoreProps,
          panelProps,
          componentProps,
          defaultConnectState,
          Component,
          link,
          activeMQ,
          onScroll,
          tabKey,
          showsVerticalScrollIndicator,
          onScrollEndDrag,
          onMomentumScrollBegin,
          onMomentumScrollEnd,
          onViewableItemsChanged,
          onContentSizeChange,
          onLayout,
          addOnScrollListener,
          removeOnScrollListener,
          absoluteHeader,
          getRef,
          addUri,
          deleteUri,
          replaceUri,
          getParentInfo,
          getPath,
          selectedTab,
          screenWidth,
          screenHeight,
          language,
          screenName
        } = this.props;
        let state = this._getState();
        let { renderNoData, renderPromptMessage, selectionMode, ComponentWrapper } = resolveMQ(
          componentProps,
          ["renderNoData", "renderPromptMessage", "selectionMode", "ComponentWrapper"],
          activeMQ
        );

        let pstate = state.pstate;
        let initialComponentPState = pstate && pstate[componentStateKey];
        state.Schema = schema;
        state[connectStateKey] = state[connectStateKey] || { ...defaultConnectState };
        state[selectionStoreStateKey] = state[selectionStoreStateKey] || { selectionMode };
        state[componentStateKey] = state[componentStateKey] || {};
        state[componentStateKey].pstate = initialComponentPState;
        state.setComponentState = this.setComponentState;
        const index = (state.Route && state.Route.index) || 0;
        const columnsPerRow =
          (componentProps && componentProps.columnsPerRow) || state[componentStateKey].columnsPerRow;
        let { _connectProps: parentConnectProps } = (getParentInfo && getParentInfo(link)) || {};
        return (
          <ScreenContextProvider state={state}>
            <Connect
              {...connectProps}
              {...parentConnectProps}
              state={state[connectStateKey]}
              setState={this.setConnectState}
              screenName={this.props.screenName}
            >
              {() => {
                const {
                  updatableData,
                  data,
                  aggregates,
                  pending,
                  dataLoaded,
                  fetchMore,
                  validationData,
                  setValue,
                  dataParams,
                  fetchForUpdates,
                  getUri,
                  loadingMore,
                  hasNext,
                  lastDoc,
                  dataIds
                } = state[connectStateKey];

                let renderComponent = (
                  <SelectionStore
                    key="selectionStore"
                    data={data}
                    selectionMode={selectionMode}
                    aggregates={aggregates}
                    {...selectionStoreProps}
                    state={state[selectionStoreStateKey]}
                    setState={this.setSelectionState}
                  >
                    {({
                      isSelected,
                      isActive,
                      selectRow,
                      isSelectionMode,
                      getSelectedIds,
                      selectMultiRow,
                      unSelectMultiRow
                    }) => {
                      let selectedIds = getSelectedIds && getSelectedIds();
                      let scrollViewProps = {
                        onContentSizeChange,
                        onLayout,
                        onScroll,
                        onScrollEndDrag,
                        selectedTab,
                        onMomentumScrollBegin,
                        onMomentumScrollEnd,
                        addOnScrollListener,
                        removeOnScrollListener,
                        getRef,
                        absoluteHeader
                      };
                      componentProps = {
                        ...componentProps,
                        ...scrollViewProps,
                        addUri,
                        deleteUri,
                        replaceUri,
                        getPath,
                        getUri,
                        activeMQ,
                        selectRow,
                        isSelected,
                        selectMultiRow,
                        unSelectMultiRow,
                        isSelectionMode,
                        screenName,
                        getSelectedIds,
                        selectedIds,
                        tabKey,
                        onViewableItemsChanged,
                        columnsPerRow,
                        screenHeight,
                        screenWidth,
                        showsVerticalScrollIndicator,
                        language
                      };

                      if (this.props.renderComponent) {
                        return this.props.renderComponent({
                          data,
                          pending,
                          dataLoaded,
                          loadingMore,
                          fetchMore,
                          hasNext,
                          dataIds,
                          lastDoc
                        });
                      }
                      if (renderNoData) {
                        renderNoData = React.cloneElement(renderNoData, {
                          scrollViewProps,
                          panelProps
                        });
                      }
                      let showLoading = pending;
                      if (showLoading && loadingMore && data && Array.isArray(data) && data.length) {
                        showLoading = false;
                      }
                      return (
                        <Panel
                          showLoading={showLoading}
                          hasNext={hasNext}
                          {...panelProps}
                          renderNoData={renderNoData}
                          index={index}
                          data={data}
                        >
                          <JsonTable
                            link={link}
                            componentType={componentType}
                            subComponentType={subComponentType}
                            validationData={validationData}
                            updatableData={updatableData}
                            fetch={fetchForUpdates}
                            schema={schema}
                            component={Component}
                            setValue={setValue}
                            data={data}
                            aggregates={aggregates}
                            isSelected={isSelected}
                            selectRow={selectRow}
                            isActive={isActive}
                            fetchMore={fetchMore}
                            componentProps={componentProps}
                            dataParams={dataParams}
                            state={state[componentStateKey]}
                            setState={this.setComponentState}
                            hasNext={hasNext}
                            dataIds={dataIds}
                            lastDoc={lastDoc}
                            language={language}
                          />
                        </Panel>
                      );
                    }}
                  </SelectionStore>
                );
                if (ComponentWrapper) {
                  renderComponent = <ComponentWrapper {...componentProps}>{renderComponent}</ComponentWrapper>;
                }
                return [renderComponent, renderPromptMessage];
              }}
            </Connect>
          </ScreenContextProvider>
        );
      } catch (e) {
        writeLog && writeLog({ type: "error", log: e });
        return errorComponent({ message: e.message });
      }
    }
  }

  return ConnectPanelTable;
};
