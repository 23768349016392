import React from "react";
import CollaborationActions from "./CollaborationActions";
import { Box, getImage, theme, I18N, RowAction, withContext, getAction, Platform } from "../../../FsCloudComponent";
import SwitchCollaborator from "./SwitchCollaborator";

let { colors, fonts, shadows } = theme;
let { h1_16, h2_l_18, h3MD, h9, h16_l } = fonts;
let { brownGrey, primaryColor, highlightColor, themeColor, whiteTwo } = colors;

class CollaborationCardSM extends React.Component {
  defaultColors = {};
  render() {
    let { data } = this.props;
    let { myProfile, owner, collaboratorData: avatar = [], uid: userId } = data;
    let initials = (avatar[1] || "").toUpperCase();
    let color = avatar[2];
    const profile_pic = avatar[3];
    if (!color) {
      if (!this.defaultColors[userId]) {
        const x = Math.floor(Math.random() * 200);
        const y = Math.floor(Math.random() * 200);
        const z = Math.floor(Math.random() * 200);
        const defaultColor = "rgb(" + x + "," + y + "," + z + ")"; // default value for color (randomly generated)
        this.defaultColors[userId] = defaultColor;
      }
      color = this.defaultColors[userId];
    }
    if (!initials && data.name) {
      initials = data.name
        .split(" ")
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join("")
        .toUpperCase();
    }

    let imageStyle = {
      width: 50,
      height: 50,
      borderRadius: 25
    };
    if (Platform.OS === "web") {
      imageStyle.objectFit = "cover";
    }
    return (
      <Box
        render={[
          {
            direction: "row",
            viewStyle: {
              backgroundColor: highlightColor,
              marginLeft: 12,
              marginRight: 12,
              marginTop: 5,
              marginBottom: 5,
              borderRadius: 4,
              height: 74,
              alignItems: "center",
              ...shadows.cardShadow
            },
            render: [
              profile_pic && {
                viewStyle: {
                  marginLeft: 12,
                  marginRight: 16,
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: whiteTwo
                },
                imageStyle,
                imageProps: {
                  resizeMode: "cover"
                },
                image: { uri: profile_pic }
              },
              !profile_pic &&
                initials && {
                  viewStyle: {
                    marginLeft: 12,
                    marginRight: 16,
                    height: 50,
                    width: 50,
                    borderRadius: 25,
                    backgroundColor: color
                  },
                  textStyle: { color: "white", textAlign: "center", marginTop: 15 },
                  text: initials
                },
              {
                width: "1fr",
                render: [
                  {
                    textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                    text: data.name || ""
                  },
                  owner && {
                    textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                    viewStyle: { paddingTop: 4 },
                    text: I18N.t("owner")
                  }
                ]
              },
              myProfile && {
                viewStyle: {
                  marginLeft: 12,
                  marginRight: 12,
                  alignItems: "center",
                  justifyContent: "center",
                  height: 24,
                  width: 24
                },
                image: getImage("iconMenuGrey"),
                Container: RowAction,
                containerProps: {
                  data,
                  action: [CollaborationActions.editProfile]
                }
              }
            ]
          }
        ]}
      />
    );
  }
}

CollaborationCardSM = withContext(CollaborationCardSM, {
  link: "ScreenRoute.link",
  invoke: "App.invoke",
  urls: "App.urls"
});

class CollaborationHeaderSM extends React.Component {
  render() {
    let { link: { props: { params } = {} } = {} } = this.props;
    let { owner: collectionOwner } = (params && params.collection) || {};
    if (!collectionOwner) {
      return null;
    }
    return (
      <Box
        viewStyle={{ height: 35, alignItems: "center" }}
        direction="row"
        render={[
          {
            width: "1fr",
            viewStyle: {
              paddingLeft: 16,
              paddingRight: 16
            },
            textStyle: {
              ...h3MD,
              color: brownGrey
            },
            text: I18N.t("collaborationHeaderMessage")
          },
          {
            viewStyle: {
              paddingLeft: 16,
              paddingRight: 16
            },
            render: <SwitchCollaborator {...this.props} />
          }
        ]}
      />
    );
  }
}

CollaborationHeaderSM = withContext(CollaborationHeaderSM, {
  link: "ScreenRoute.link",
  invoke: "App.invoke",
  urls: "App.urls"
});

class CollaborationCardMD extends React.Component {
  defaultColors = {};
  render() {
    let Link = getAction("link");
    let { data, link: { props: { params } = {} } = {} } = this.props;
    let { owner: collectionOwner } = (params && params.collection) || {};
    return (
      <Box
        viewStyle={{ flex: 1, paddingLeft: 110, paddingRight: 110, paddingTop: 18, paddingBottom: 18 }}
        render={[
          collectionOwner && {
            direction: "row",
            viewStyle: {
              paddingTop: 9,
              paddingBottom: 18,
              alignItems: "center"
            },
            render: [
              <SwitchCollaborator {...this.props} />,
              {
                viewStyle: {
                  flex: 1,
                  paddingLeft: 24,
                  paddingRight: 18
                },
                textStyle: { ...h9, color: brownGrey },
                text: I18N.t("collaborationHeaderMessage")
              }
            ]
          },
          data &&
            data.map((rowData = {}) => {
              let isMyProfile = rowData.myProfile;
              let isOwner = rowData.owner;
              const avatar = rowData.collaboratorData || [];
              let userId = rowData.uid || "";
              let initials = (avatar[1] || "").toUpperCase();
              let color = avatar[2];
              const profile_pic = avatar[3];
              if (!color) {
                if (!this.defaultColors[userId]) {
                  const x = Math.floor(Math.random() * 200);
                  const y = Math.floor(Math.random() * 200);
                  const z = Math.floor(Math.random() * 200);
                  const defaultColor = "rgb(" + x + "," + y + "," + z + ")"; // default value for color (randomly generated)
                  this.defaultColors[userId] = defaultColor;
                }
                color = this.defaultColors[userId];
              }
              if (!initials && rowData.name) {
                initials = rowData.name
                  .split(" ")
                  .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
                  .join("")
                  .toUpperCase();
              }

              return (
                <Box
                  direction="row"
                  viewStyle={{
                    paddingTop: 12,
                    paddingBottom: 12
                  }}
                  render={[
                    profile_pic && {
                      viewStyle: {
                        height: 56,
                        width: 56,
                        borderRadius: 28,
                        backgroundColor: whiteTwo
                      },
                      imageStyle: {
                        width: 56,
                        height: 56,
                        borderRadius: 28,
                        objectFit: "cover"
                      },
                      image: { uri: profile_pic }
                    },
                    !profile_pic &&
                      initials && {
                        viewStyle: {
                          height: 56,
                          width: 56,
                          borderRadius: 28,
                          backgroundColor: color
                        },
                        textStyle: {
                          width: 56,
                          height: 56,
                          borderRadius: 28,
                          marginTop: 19,
                          textAlign: "center",
                          color: "white"
                        },
                        text: initials
                      },
                    {
                      width: "1fr",
                      viewStyle: {
                        marginLeft: 51,
                        justifyContent: "center"
                      },
                      render: [
                        {
                          text: rowData.name || "",
                          textStyle: { ...h16_l, color: brownGrey }
                        },
                        {
                          direction: "row",
                          viewStyle: { paddingTop: 6 },
                          render: [
                            isOwner && {
                              textStyle: { ...h9, color: brownGrey },
                              text: I18N.t("owner")
                            },
                            isOwner &&
                              isMyProfile && {
                                viewStyle: { paddingLeft: 3, paddingRight: 3 },
                                text: "-"
                              },
                            isMyProfile && {
                              viewStyle: { cursor: "pointer" },
                              textStyle: { ...h9, color: themeColor },
                              text: I18N.t("editMyProfile"),
                              Container: Link,
                              containerProps: {
                                action: {
                                  link: { uri: "/profile#profile" }
                                }
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]}
                />
              );
            })
        ]}
      />
    );
  }
}

CollaborationCardMD = withContext(CollaborationCardMD, {
  invoke: "App.invoke",
  urls: "App.urls",
  link: "ScreenRoute.link",
  user: "User.user"
});

export { CollaborationCardSM, CollaborationHeaderSM, CollaborationCardMD };
