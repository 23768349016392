import { I18N, Platform, MetadataTable } from "../../../FsCloudComponent";
import {
  albumAddItemsQuery,
  setAddItemsQuery,
  playlistAddItemsQuery,
  setCoverQuery,
  vaultAlbumAddItemsQuery,
  vaultSetAddItemsQuery
} from "../queries";
import { shareForm, getLinkActionProps, getLinkNativeActionProps } from "../resource/ResourceActions";

const createCollectionForm = {
  componentMD: "createCollectionName",
  componentSM: "addCollectionNameMobile",
  formType: "create",
  modalProps: {
    modalSM: {
      stopPropagation: true,
      position: "center",
      width: 0.85,
      animationType: "none",
      style: {
        borderRadius: 4,
        overflow: "hidden"
      },
      adjustOnKeyboard: true
    },
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};

const renameCollectionForm = {
  componentMD: "renameCollection",
  componentSM: "addCollectionNameMobile",
  formType: "rename",
  formSource: "Collection",
  modalProps: {
    modalSM: {
      stopPropagation: true,
      position: "center",
      width: 0.85,
      animationType: "none",
      style: {
        borderRadius: 4,
        overflow: "hidden"
      },
      adjustOnKeyboard: true
    },
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};

const collectionAddItemsForm = {
  component: "collectionItemSelector",
  modalProps: {
    modalSM: {
      key: "addItemModal",
      stopPropagation: true,
      position: "center",
      width: 0.9,
      maxHeight: 500,
      style: {
        borderRadius: 4,
        overflow: "hidden"
      },
      animationType: "none"
    },
    modalMD: {
      key: "addItemModal",
      stopPropagation: true,
      position: "center",
      maxHeight: "95%",
      style: {
        borderRadius: 4
      }
    }
  }
};

const setCoverForm = {
  component: "albumCoverSelector",
  modalProps: {
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};

const collectionDetailLinkProps = ({ data } = {}) => {
  return {
    filter: {
      _id: data._id
    },
    params: {
      collection: {
        _id: data._id,
        name: data.name,
        type: data.type,
        itemCount: data.itemCount,
        mode: data.mode,
        collection: data.collection,
        modeText: data.modeText,
        owner: data.owner,
        colloborator_mode: data.colloborator_mode
      }
    }
  };
};

const actions = {
  createAlbum: {
    title: () => I18N.t("createAlbumTitle"),
    form: createCollectionForm,
    origin: "album",
    ...albumAddItemsQuery
  },
  createSet: {
    title: () => I18N.t("createSetTitle"),
    form: createCollectionForm,
    origin: "set",
    ...setAddItemsQuery
  },
  createPlaylist: {
    title: () => I18N.t("createPlaylistTitle"),
    form: createCollectionForm,
    origin: "playlist",
    ...playlistAddItemsQuery
  },
  createVaultAlbum: {
    title: () => I18N.t("createAlbumTitle"),
    form: createCollectionForm,
    origin: "vaultAlbum",
    ...vaultAlbumAddItemsQuery
  },
  createVaultSet: {
    title: () => I18N.t("createSetTitle"),
    form: createCollectionForm,
    origin: "vaultSet",
    ...vaultSetAddItemsQuery
  },
  addItemsToAlbumForm: {
    text: () => I18N.t("addItems"),
    title: () => I18N.t("addToAlbum"),
    visible: data => {
      let { colloborator_mode, owner } = data;
      return owner || colloborator_mode !== "read";
    },
    form: collectionAddItemsForm,
    origin: "albumDetail",
    ...albumAddItemsQuery
  },
  addItemsToSetForm: {
    text: () => I18N.t("addItems"),
    title: () => I18N.t("addToSet"),
    visible: data => {
      return data.owner || data.colloborator_mode !== "read";
    },
    form: collectionAddItemsForm,
    origin: "setDetail",
    ...setAddItemsQuery
  },
  addItemsToPlaylistForm: {
    text: () => I18N.t("addItems"),
    title: () => I18N.t("addToPlaylist"),
    form: collectionAddItemsForm,
    origin: "playlistDetail",
    ...playlistAddItemsQuery
  },
  addItemsToAlbumLink: {
    type: "link",
    text: () => I18N.t("addItems"),
    title: () => I18N.t("addToAlbum"),
    visible: data => {
      return data.owner || data.colloborator_mode !== "read";
    },
    link: ({ data }) => {
      return {
        uri: "/select-image",
        props: {
          params: {
            collection: data,
            excludeCollectionItems: true,
            origin: "album"
          }
        }
      };
    }
  },
  addItemsToSetLink: {
    type: "link",
    text: () => I18N.t("addItems"),
    title: I18N.t("addToSet"),
    visible: data => data.owner || data.colloborator_mode !== "read",
    link: ({ data }) => {
      return {
        uri: "/select-docs",
        props: {
          params: {
            collection: data,
            excludeCollectionItems: true,
            origin: "set"
          }
        }
      };
    }
  },
  addItemsToPlaylistLink: {
    type: "link",
    text: () => I18N.t("addItems"),
    title: I18N.t("addToPlaylist"),
    link: ({ data }) => {
      return {
        uri: "/select-music",
        props: {
          params: {
            collection: data,
            excludeCollectionItems: true,
            origin: "playlist"
          }
        }
      };
    }
  },
  addItemsToVaultAlbumForm: {
    text: () => I18N.t("addItems"),
    title: () => I18N.t("addToAlbum"),
    form: collectionAddItemsForm,
    origin: "albumDetail",
    ...vaultAlbumAddItemsQuery
  },
  addItemsToVaultSetForm: {
    text: () => I18N.t("addItems"),
    title: () => I18N.t("addToSet"),
    form: collectionAddItemsForm,
    origin: "setDetail",
    ...vaultSetAddItemsQuery
  },
  addItemsToVaultAlbumLink: {
    type: "link",
    text: () => I18N.t("addItems"),
    title: () => I18N.t("addToAlbum"),
    link: ({ data }) => {
      return {
        uri: "/select-vault-image",
        props: {
          params: {
            collection: data,
            excludeCollectionItems: true,
            origin: "vaultAlbum"
          }
        }
      };
    }
  },
  addItemsToVaultSetLink: {
    type: "link",
    text: () => I18N.t("addItems"),
    title: I18N.t("addToSet"),
    link: ({ data }) => {
      return {
        uri: "/select-vault-docs",
        props: {
          params: {
            collection: data,
            excludeCollectionItems: true,
            origin: "vaultSet"
          }
        }
      };
    }
  },
  albumDetailLink: {
    type: "link",
    link: props => {
      let { data } = props;
      return {
        uri: "/album-detail",
        props: {
          filter: {
            _id: data._id
          },
          params: {
            collection: data
          }
        },
        origin: "album"
      };
    }
  },
  placeDetailLink: {
    type: "link",
    link: props => {
      let { data } = props;
      return {
        uri: "/place-detail",
        props: {
          filter: {
            location: data.city_lower
          },
          params: {
            collection: data
          }
        },
        origin: "places"
      };
    }
  },
  deviceFolderDetailLink: {
    type: "link",
    link: props => {
      let { data } = props;
      return {
        uri: "/device-folder-detail",
        props: {
          filter: {
            deviceFolder: data.deviceFolder
          },
          params: {
            collection: data
          }
        },
        origin: "deviceFolder"
      };
    }
  },
  setDetailLink: {
    type: "link",
    link: props => {
      let { data } = props;
      return {
        uri: "/set-detail",
        props: {
          filter: {
            _id: data._id
          },
          params: {
            collection: data
          }
        },
        origin: "set"
      };
    }
  },
  playlistDetailLink: {
    type: "link",
    link: props => {
      return {
        uri: "/playlist-detail",
        props: collectionDetailLinkProps(props),
        origin: "playlist"
      };
    }
  },
  artistDetailLink: {
    type: "link",
    link: props => {
      return {
        uri: "/artist-detail",
        props: collectionDetailLinkProps(props),
        origin: "playlist",
        fromArtist: true
      };
    }
  },
  vaultAlbumDetailLink: {
    type: "link",
    link: props => {
      let { data } = props;
      return {
        uri: "/vault-album-detail",
        props: {
          filter: {
            _id: data._id
          },
          params: {
            collection: data
          }
        },
        origin: "vaultAlbum"
      };
    }
  },
  vaultSetDetailLink: {
    type: "link",
    link: props => {
      let { data } = props;
      return {
        uri: "/vault-set-detail",
        props: {
          filter: {
            _id: data._id
          },
          params: {
            collection: data
          }
        },
        origin: "vaultSet"
      };
    }
  },
  manageCollabration: {
    type: "link",
    text: () => I18N.t("manageCollaboration"),
    visible: data => {
      return data.mode !== "Private";
    },
    link: ({ data }) => {
      return {
        uri: "/collaboration",
        props: {
          params: {
            collection: data
          }
        }
      };
    }
  },
  makePrivate: {
    type: "invoke",
    service: (props, { urls }) => {
      const { selectedIds } = props;
      let _id = selectedIds[0];
      const commandToPost = {
        url: urls["markPrivate"],
        uriProps: {
          data: { collectionId: _id }
        }
      };
      return commandToPost;
    },
    postMessage: () => I18N.t("collectionMakePrivate"),
    text: () => I18N.t("makePrivate"),
    visible: data => data.mode === "Shared" && data.owner,
    confirm: {
      titleMD: () => I18N.t("makePrivatePopupHeaderCaps"),
      confirmText: () => I18N.t("makePrivate"),
      cancelText: () => I18N.t("cancel")
    },
    afterInvoke: (result, props) => {
      if (Platform.OS === "web") {
        return;
      }
      let { deleteUri, getPath, link } = props;
      let url = getPath && getPath();
      deleteUri && deleteUri(url, link);
    }
  },
  rename: {
    text: () => I18N.t("rename"),
    title: () => I18N.t("rename"),
    form: renameCollectionForm
  },
  getLink: {
    ...getLinkActionProps,
    image: "linkGreyIcon",
    origin: "collection",
    visible: () => Platform.OS === "web"
  },
  getLinkNative: {
    ...getLinkNativeActionProps("collection"),
    image: void 0,
    visible: data => Platform.OS !== "web" && data && data.owner
  },
  select: {
    type: "enableSelectionMode",
    text: () => I18N.t("select"),
    visible: (data, { selectionMode }) => !selectionMode
  },
  setCover: {
    text: () => I18N.t("changeCover"),
    visible: data => data.owner,
    form: setCoverForm,
    origin: "album",
    ...setCoverQuery
  },
  setCoverLink: {
    type: "link",
    text: () => I18N.t("changeCover"),
    visible: data => data.owner,
    link: ({ data }) => {
      return {
        uri: "/select-cover-image",
        props: {
          filter: {
            _id: data._id
          }
        }
      };
    }
  },
  setCoverLinkVault: {
    type: "link",
    text: () => I18N.t("changeCover"),
    visible: data => data.owner,
    link: ({ data }) => {
      return {
        uri: "/select-cover-image-vault",
        props: {
          filter: {
            _id: data._id
          }
        }
      };
    }
  },
  markDelete: {
    text: () => I18N.t("delete"),
    title: () => I18N.t("delete"),
    confirm: {
      titleMD: () => I18N.t("deletePopupHeaderCaps"),
      confirmText: () => I18N.t("delete"),
      cancelText: () => I18N.t("cancel")
    },
    service: (props, { urls }) => {
      const { selectedIds } = props;
      let _id = selectedIds[0];
      const commandToPost = {
        url: urls["removeCollection"],
        uriProps: {
          data: { _id }
        }
      };
      return commandToPost;
    },
    type: "invoke"
  },
  // called from addToCollection from resource / resourceDetail / collection detail / collection detail> resource detail
  addItemsSelectCollection: {
    type: "invoke",
    afterInvoke: (result, props) => {
      let { deleteUri, getParentInfo, getPath, link } = props;
      let { unSelectAll: parentUnSelectAll } = (getParentInfo && getParentInfo(link)) || {};
      parentUnSelectAll && parentUnSelectAll();
      let url = getPath && getPath();
      deleteUri && deleteUri(url, link);
    },
    postMessage: props => {
      let { link, invokeResult: { addedResources, totalResources } = {} } = props;
      let origin = link && link.origin;
      let message = "";
      let toast = {
        gallery: { add: I18N.t("itemsCountAddedToAlbum"), ignore: I18N.t("itemsCountIgnoredToAlbum") },
        doc: { add: I18N.t("itemsCountAddedToSet"), ignore: I18N.t("itemsCountIgnoredToSet") },
        music: { add: I18N.t("itemsCountAddedToPlaylist"), ignore: I18N.t("itemsCountIgnoredToPlaylist") }
      };
      let { add, ignore } = toast[origin];
      if (totalResources === addedResources) {
        message = add.replace("__itemcount__", addedResources);
      } else if (addedResources === 0) {
        message = ignore.replace("__itemcount__", totalResources);
      } else {
        message =
          add.replace("__itemcount__", addedResources) +
          " " +
          I18N.t("and") +
          " " +
          ignore.replace("__itemcount__", totalResources - addedResources);
      }
      if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
        message = I18N.t("itemsAddedSuccessfully");
      }
      return message;
    },
    service: (props, { urls }) => {
      const { data, getParentInfo, link } = props;
      let { selectedIds: parentSelectedIds } = (getParentInfo && getParentInfo(link)) || {};
      if (!parentSelectedIds || !parentSelectedIds.length) {
        return;
      }
      const items = parentSelectedIds.map(_id => ({ _id }));
      const commandToPost = {
        url: urls["addItemToCollection"],
        uriProps: {
          data: {
            _id: data._id,
            items
          }
        }
      };
      return commandToPost;
    },
    notifyLocalOnInvoke: (_, props) => {
      let { invokeProps, fireLocalDataChangeListener } = props;
      let { link, getParentInfo, selectedIds } = invokeProps;
      let collectionId = selectedIds && selectedIds[0];
      let { selectedData: parentSelectedData = [] } = getParentInfo && getParentInfo(link); // resource
      if (collectionId && parentSelectedData.length) {
        let localChanges = [];
        parentSelectedData.forEach(doc => {
          let { collections, _id } = doc;
          collections = collections || [];
          if (collections.indexOf(collectionId) === -1) {
            collections.push(collectionId);
            localChanges.push({ _id, source: "action", changes: { collections } });
          }
        });
        localChanges.length &&
          fireLocalDataChangeListener &&
          fireLocalDataChangeListener({ key: MetadataTable, localChanges, updateSqlite: true });
      }
    }
  },
  share: {
    ...shareForm,
    text: () => I18N.t("toShare"),
    origin: "collection",
    visible: data => Platform.OS === "web" && data && data.owner
  },
  shareNative: {
    type: "appShare",
    text: () => I18N.t("toShare"),
    shareType: "LINK",
    origin: "collection",
    visible: data => Platform.OS !== "web" && data && data.owner
  }
};

actions.manageCollabrationWithGreyImage = {
  ...actions.manageCollabration,
  image: "collabrationGreyIcon"
};

actions.makePrivateAlbum = {
  ...actions.makePrivate,
  confirm: {
    ...actions.makePrivate.confirm,
    message: () => I18N.t("makePrivateAlbumPopupMessage")
  }
};

actions.makePrivateSet = {
  ...actions.makePrivate,
  confirm: {
    ...actions.makePrivate.confirm,
    message: () => I18N.t("makePrivateSetPopupMessage")
  }
};

actions.makePrivateAlbumWithGreyImage = {
  ...actions.makePrivateAlbum,
  image: "lockGreyIcon"
};

actions.makePrivateSetWithGreyImage = {
  ...actions.makePrivateSet,
  image: "lockGreyIcon"
};

actions.markDeleteAlbum = {
  ...actions.markDelete,
  text: () => I18N.t("markDeleteAlbum"),
  title: () => I18N.t("markDeleteAlbum"),
  visible: data => data.owner,
  confirm: {
    ...actions.markDelete.confirm,
    titleMD: () => I18N.t("deleteAlbumCaps"),
    message: ({ data }) => {
      let translateMsg =
        data.type === "vaultAlbum" ? I18N.t("removeVaultAlbumPopupMessage") : I18N.t("removeAlbumPopupMessage");
      translateMsg = translateMsg.replace("__albumName__", `"${data.name}"`);
      return translateMsg;
    }
  },
  postMessage: () => I18N.t("albumDeleted")
};

actions.markDeleteSet = {
  ...actions.markDelete,
  text: () => I18N.t("markDeleteSet"),
  title: () => I18N.t("markDeleteSet"),
  visible: data => data.owner,
  confirm: {
    ...actions.markDelete.confirm,
    titleMD: () => I18N.t("deleteSetCaps"),
    message: ({ data }) => {
      let translateMsg =
        data.type === "vaultSet" ? I18N.t("removeVaultSetPopupMessage") : I18N.t("removeSetPopupMessage");
      translateMsg = translateMsg.replace("__setName__", `"${data.name}"`);
      return translateMsg;
    }
  },
  postMessage: () => I18N.t("setDeleted")
};

actions.markDeletePlaylist = {
  ...actions.markDelete,
  text: () => I18N.t("markDeletePlaylist"),
  title: () => I18N.t("markDeletePlaylist"),
  confirm: {
    ...actions.markDelete.confirm,
    titleMD: () => I18N.t("deletePlaylistCaps"),
    message: ({ data }) => {
      let translateMsg = I18N.t("removePlaylistPopupMessage");
      translateMsg = translateMsg.replace("__playlistName__", `"${data.name}"`);
      return translateMsg;
    }
  },
  postMessage: () => I18N.t("playlistDeleted")
};

actions.renameAlbum = {
  ...actions.rename,
  text: () => I18N.t("renameAlbum"),
  title: () => I18N.t("renameAlbum"),
  visible: data => data.owner
};

actions.renameSet = {
  ...actions.rename,
  text: () => I18N.t("renameSet"),
  title: () => I18N.t("renameSet"),
  visible: data => data.owner
};

actions.renamePlaylist = {
  ...actions.rename,
  text: () => I18N.t("renamePlaylist"),
  title: () => I18N.t("renamePlaylist")
};

actions.markDeleteAlbumWithGreyImage = {
  ...actions.markDeleteAlbum,
  image: "deleteGreyIcon"
};

actions.markDeleteSetWithGreyImage = {
  ...actions.markDeleteSet,
  image: "deleteGreyIcon"
};

actions.markDeletePlaylistWithGreyImage = {
  ...actions.markDeletePlaylist,
  image: "deleteGreyIcon"
};

actions.addItemsToAlbumFormWithGreyImage = {
  ...actions.addItemsToAlbumForm,
  image: "addItemsGreyIcon"
};

actions.addItemsToSetFormWithGreyImage = {
  ...actions.addItemsToSetForm,
  image: "addItemsGreyIcon"
};

actions.addItemsToPlaylistFormWithGreyImage = {
  ...actions.addItemsToPlaylistForm,
  image: "addItemsGreyIcon"
};

actions.addItemsToVaultAlbumFormWithGreyImage = {
  ...actions.addItemsToVaultAlbumForm,
  image: "addItemsGreyIcon"
};

actions.addItemsToVaultSetFormWithGreyImage = {
  ...actions.addItemsToVaultSetForm,
  image: "addItemsGreyIcon"
};
actions.renameAlbumWithGreyImage = {
  ...actions.renameAlbum,
  image: "editGreyIcon"
};

actions.renameSetWithGreyImage = {
  ...actions.renameSet,
  image: "editGreyIcon"
};

actions.renamePlaylistWithGreyImage = {
  ...actions.renamePlaylist,
  image: "editGreyIcon"
};

actions.shareWithGreyImage = {
  ...actions.share,
  image: "shareGreyIcon"
};

actions.selectWithGreyImage = {
  ...actions.select,
  image: "selectGreyIcon"
};

actions.setCoverWithGreyImage = {
  ...actions.setCover,
  image: "albumCoverGreyIcon"
};

export default actions;
