import React from "react";
import ResourceActions from "../resource/ResourceActions";
import CollectionActions from "../collection/CollectionActions";
import ContactActions from "../configuration/contacts/ContactActions";
import GroupMemberActions from "../groups/GroupMemberActions";
import {
  GroupTabsNoData,
  RenderNoData,
  I18N,
  getAction,
  RowAction,
  WebAppUpload,
  withContext,
  Platform
} from "../../../FsCloudComponent";

const HighlightNoData = props => {
  const info = {
    image: "highlightsNoDataIcon",
    primaryText: I18N.t("highlightsNoDataHeader"),
    secondaryText: I18N.t("highlightsNoDataMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const MemoriesOfDayNoData = props => {
  const info = {
    image: "highlightsNoDataIcon",
    primaryText: I18N.t("noMemories"),
    secondaryText: I18N.t("highlightsNoDataMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const GalleryAllNoData = props => {
  const info = {
    image: "photosNoDataIcon",
    primaryText: I18N.t("galleryAllNoDataHeader"),
    secondaryTextSM: I18N.t("galleryAllNoDataMobileMessage"),
    secondaryTextMD: I18N.t("galleryAllNoDataMessage"),
    buttonTextMD: I18N.t("add"),
    buttonContainerMD: WebAppUpload,
    buttonContainerProps: {
      action: { showChildren: true }
    }
  };
  return <RenderNoData {...info} {...props} />;
};

const DuplicateResourceNoData = props => {
  const info = {
    image: "duplicateNoDataIcon",
    primaryText: I18N.t("galleryAllNoDataHeader")
  };
  return <RenderNoData {...info} {...props} />;
};

const VaultGalleryAllNoData = props => {
  const info = {
    image: "photosNoDataIcon",
    primaryText: I18N.t("galleryAllNoDataHeader"),
    secondaryTextSM: I18N.t("galleryAllNoDataMobileMessage"),
    secondaryTextMD: I18N.t("vaultNoDataMessage"),
    buttonTextMD: I18N.t("moveToSafeArea"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      actions: ResourceActions,
      action: ResourceActions.selectImagesForSafeWeb
    }
  };
  return <RenderNoData {...info} {...props} />;
};

const ArchiveGalleryAllNoData = props => {
  const info = {
    image: "photosNoDataIcon",
    primaryText: I18N.t("galleryAllNoDataHeader"),
    secondaryTextSM: I18N.t("archiveNoDataMobileMessage"),
    secondaryTextMD: I18N.t("archiveNoDataMessage"),
    buttonTextMD: I18N.t("moveToArchive"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      actions: ResourceActions,
      action: ResourceActions.selectImagesForArchiveWeb
    }
  };
  return <RenderNoData {...info} {...props} />;
};

const GalleryAlbumNoData = props => {
  const info = {
    image: "albumNoDataIcon",
    primaryText: I18N.t("galleryAlbumNoDataHeader"),
    secondaryTextSM: props.selectAlbum ? I18N.t("selectAlbumNoDataMobileMessage") : I18N.t("albumNoDataMobileMessage"),
    secondaryTextMD: I18N.t("galleryAlbumNoDataMessage"),
    buttonContainerMD: RowAction,
    buttonContainerProps: {
      action: CollectionActions.createAlbum
    },
    buttonTextMD: I18N.t("createAlbum")
  };
  return <RenderNoData {...info} {...props} />;
};

const GalleryPlaceNoData = props => {
  const info = {
    image: "albumNoDataIcon",
    primaryText: I18N.t("galleryPlaceNoDataHeader")
  };
  return <RenderNoData {...info} {...props} />;
};

const GalleryFolderNoData = props => {
  const info = {
    image: "albumNoDataIcon",
    primaryText: I18N.t("galleryDeviceFolderNoDataHeader")
  };
  return <RenderNoData {...info} {...props} />;
};

const VaultAlbumNoData = props => {
  const info = {
    image: "albumNoDataIcon",
    primaryText: I18N.t("galleryAlbumNoDataHeader"),
    secondaryTextSM: props.selectAlbum ? I18N.t("selectAlbumNoDataMobileMessage") : I18N.t("albumNoDataMobileMessage"),
    secondaryTextMD: I18N.t("galleryAlbumNoDataMessage"),
    buttonContainerMD: RowAction,
    buttonContainerProps: {
      action: CollectionActions.createVaultAlbum
    },
    buttonTextMD: I18N.t("createAlbum")
  };
  return <RenderNoData {...info} {...props} />;
};

const DocsNoData = props => {
  const info = {
    image: "docsNoDataIcon",
    primaryText: I18N.t("docsAllNoDataHeader"),
    buttonTextMD: I18N.t("add"),
    buttonContainerMD: WebAppUpload,
    buttonContainerProps: {
      action: { showChildren: true }
    }
  };
  if (Platform.OS !== "ios") {
    info["secondaryTextSM"] = I18N.t("docsNoDataMobileMessage");
    info["secondaryTextMD"] = I18N.t("docsNoDataMessage");
  }
  return <RenderNoData {...info} {...props} />;
};

const VaultDocsNoData = props => {
  const info = {
    image: "docsNoDataIcon",
    primaryText: I18N.t("docsAllNoDataHeader"),
    buttonTextMD: I18N.t("moveToSafeArea"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      actions: ResourceActions,
      action: ResourceActions.selectDocsForSafeWeb
    }
  };
  if (Platform.OS !== "ios") {
    info["secondaryTextSM"] = I18N.t("docsNoDataMobileMessage");
    info["secondaryTextMD"] = I18N.t("vaultNoDataMessage");
  }
  return <RenderNoData {...info} {...props} />;
};

const ArchiveDocsNoData = props => {
  const info = {
    image: "docsNoDataIcon",
    primaryText: I18N.t("docsAllNoDataHeader"),
    buttonTextMD: I18N.t("moveToArchive"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      actions: ResourceActions,
      action: ResourceActions.selectDocsForArchiveWeb
    }
  };
  if (Platform.OS !== "ios") {
    info["secondaryTextSM"] = I18N.t("archiveNoDataMobileMessage");
    info["secondaryTextMD"] = I18N.t("archiveNoDataMessage");
  }
  return <RenderNoData {...info} {...props} />;
};

const DocsSetNoData = props => {
  const info = {
    image: "setNoDataIcon",
    primaryText: I18N.t("docsSetNoDataHeader"),
    secondaryTextSM: props.selectSet ? I18N.t("selectSetsNoDataMobileMessage") : I18N.t("setsNoDataMobileMessage"),
    secondaryTextMD: I18N.t("docsSetNoDataMessage"),
    buttonContainerMD: RowAction,
    buttonContainerProps: {
      action: CollectionActions.createSet
    },
    buttonTextMD: I18N.t("createSet")
  };
  return <RenderNoData {...info} {...props} />;
};

const VaultSetNoData = props => {
  const info = {
    image: "setNoDataIcon",
    primaryText: I18N.t("docsSetNoDataHeader"),
    secondaryTextSM: props.selectSet ? I18N.t("selectSetsNoDataMobileMessage") : I18N.t("setsNoDataMobileMessage"),
    secondaryTextMD: I18N.t("docsSetNoDataMessage"),
    buttonContainerMD: RowAction,
    buttonContainerProps: {
      action: CollectionActions.createVaultSet
    },
    buttonTextMD: I18N.t("createSet")
  };
  return <RenderNoData {...info} {...props} />;
};

const MusicNoData = props => {
  const info = {
    image: "musicNoDataIcon",
    primaryTextMD: I18N.t("musicAllNoDataHeader"),
    primaryTextSM: I18N.t("musicAllNoDataHeader"),
    secondaryTextMD: I18N.t("musicAllNoDataMessage"),
    secondaryTextSM: I18N.t("musicAllNoDataMobileMessage"),
    buttonTextMD: I18N.t("add"),
    buttonContainerMD: WebAppUpload,
    buttonContainerProps: {
      action: { showChildren: true }
    }
  };
  return <RenderNoData {...info} {...props} />;
};

const ArchiveMusicNoData = props => {
  const info = {
    image: "musicNoDataIcon",
    primaryTextMD: I18N.t("musicAllNoDataHeader"),
    primaryTextSM: I18N.t("musicAllNoDataHeader"),
    secondaryTextMD: I18N.t("archiveNoDataMessage"),
    secondaryTextSM: I18N.t("archiveNoDataMobileMessage"),
    buttonTextMD: I18N.t("moveToArchive"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      actions: ResourceActions,
      action: ResourceActions.selectMusicsForArchiveWeb
    }
  };
  return <RenderNoData {...info} {...props} />;
};

const PlaylistNoData = props => {
  const info = {
    image: "playlistNoDataIcon",
    primaryTextMD: I18N.t("musicPlaylistNoDataHeader"),
    primaryTextSM: I18N.t("musicPlaylistNoDataHeader"),
    secondaryTextMD: I18N.t("musicPlaylistNoDataMessage"),
    secondaryTextSM: props.selectPlaylist
      ? I18N.t("selectPlaylistNoDataMobileMessage")
      : I18N.t("playlistNoDataMobileMessage"),
    buttonContainerMD: RowAction,
    buttonContainerProps: {
      action: CollectionActions.createPlaylist
    },
    buttonTextMD: I18N.t("createPlaylist")
  };
  return <RenderNoData {...info} {...props} />;
};

const ArtistNoData = props => {
  const info = {
    image: "artistsNoDataIcon",
    primaryText: I18N.t("musicArtistsNoDataHeader"),
    secondaryText: I18N.t("musicArtistsNoDataMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const GroupNoData = props => {
  const info = {
    image: "groupNoDataIcon",
    primaryTextMD: I18N.t("groupNoDataHeaderWeb"),
    primaryTextSM: I18N.t("groupNoDataHeaderMobile"),
    secondaryTextMD: I18N.t("groupNoDataMessageWeb"),
    secondaryTextSM: I18N.t("groupNoDataMessageMobile"),
    buttonTextSM: I18N.t("start"),
    buttonTextMD: I18N.t("begin"),
    buttonContainerSM: getAction("link"),
    buttonContainerMD: RowAction,
    buttonContainerPropsSM: { action: { link: { uri: "/invite-member" } } },
    buttonContainerPropsMD: { action: GroupMemberActions.addMemberWeb }
  };
  return <RenderNoData {...info} {...props} />;
};

const GroupGalleryNoDataSM = props => {
  const info = {
    image: "photosNoDataIcon",
    primaryText: I18N.t("galleryAllNoDataHeader"),
    secondaryText: I18N.t("groupGalleryNoDataMobileMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const GroupGalleryNoDataMD = props => {
  const info = {
    image: "photosNoDataIcon",
    primaryText: I18N.t("galleryAllNoDataHeader"),
    secondaryText: I18N.t("groupGalleryNoDataMessage"),
    notifyMessageText: I18N.t("groupNotifyMessage"),
    infoMessageText: I18N.t("groupNoDataInfoMessage"),
    buttonText: I18N.t("sendToGroup"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      actions: ResourceActions,
      action: ResourceActions.selectImagesForGroupWeb
    }
  };
  return <GroupTabsNoData {...info} {...props} />;
};

const GroupDocsNoDataSM = props => {
  const info = {
    image: "docsNoDataIcon",
    primaryText: I18N.t("docsAllNoDataHeader"),
    secondaryText: I18N.t("groupDocsNoDataMobileMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const GroupDocsNoDataMD = props => {
  const info = {
    image: "docsNoDataIcon",
    primaryText: I18N.t("docsAllNoDataHeader"),
    secondaryText: I18N.t("groupDocsNoDataMessage"),
    notifyMessageText: I18N.t("groupNotifyMessage"),
    buttonText: I18N.t("sendToGroup"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      actions: ResourceActions,
      action: ResourceActions.selectDocsForGroupWeb
    }
  };
  return <GroupTabsNoData {...info} {...props} />;
};

const SelectImageNoData = props => {
  const info = {
    image: "photosNoDataIcon",
    primaryText: I18N.t("selectImageNoDataHeader"),
    secondaryText: I18N.t("selectImageNoDataText")
  };
  return <RenderNoData {...info} {...props} />;
};

const SelectDocsNoData = props => {
  const info = {
    image: "noSetItemsIcon",
    primaryText: I18N.t("selectDocsNoDataHeader"),
    secondaryText: I18N.t("selectDocsNoDataText")
  };
  return <RenderNoData {...info} {...props} />;
};

const SelectMusicNoData = props => {
  const info = {
    image: "noPlaylistItemsIcon",
    secondaryText: I18N.t("selectPlaylistNoDataText")
  };
  return <RenderNoData {...info} {...props} />;
};

const UploadGalleryNoData = props => {
  const info = {
    image: "albumNoDataIcon",
    primaryTextMD: I18N.t("uploadGalleryNoDataHeader"),
    primaryTextSM: `${I18N.t("uploadGalleryNoDataHeader")}`,
    secondaryTextMD: I18N.t("uploadGallerytNoDataMessage"),
    secondaryTextSM: I18N.t("uploadGalleryNoDataMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const UploadDocumentNoData = props => {
  const info = {
    image: "docsNoDataIcon",
    primaryTextMD: I18N.t("uploadDocumentNoDataHeader"),
    primaryTextSM: `${I18N.t("uploadDocumentNoDataHeader")}`,
    secondaryTextMD: I18N.t("uploadDocumentNoDataMessage"),
    secondaryTextSM: I18N.t("uploadDocumentNoDataMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const UploadMusicNoData = props => {
  const info = {
    image: "musicNoDataIcon",
    primaryText: I18N.t("uploadMusicNoDataHeader"),
    secondaryText: I18N.t("uploadMusicNoDataMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const UploadProfileNoData = props => {
  const info = {
    image: "photosNoDataIcon",
    primaryTextSM: `${I18N.t("uploadProfileGalleryNoDataHeader")}`
  };
  return <RenderNoData {...info} {...props} />;
};

const SearchGalleryNoData = ({ link }) => {
  const {
    props: {
      params: { from }
    }
  } = link;
  const noDataProps = {
    favourite: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    },
    shared: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    },
    video: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    },
    dateFilter: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    }
  };
  return <RenderNoData {...noDataProps[from]} />;
};

const SearchGalleryTypeNoData = withContext(SearchGalleryNoData, {
  link: "Screen.Route.link"
});

const SearchDocsTypeNoData = props => {
  const info = {
    image: "searchNoMatchIcon",
    primaryText: I18N.t("noMatchHeader"),
    secondaryText: I18N.t("noMatchMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const SearchMusicNoData = ({ link }) => {
  const {
    props: {
      params: { from }
    }
  } = link;
  const noDataProps = {
    favourite: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    },
    aac: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    },
    mp3: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    },
    dateFilter: {
      image: "searchNoMatchIcon",
      primaryText: I18N.t("noMatchHeader"),
      secondaryText: I18N.t("noMatchMessage")
    }
  };
  return <RenderNoData {...noDataProps[from]} />;
};

const SearchMusicTypeNoData = withContext(SearchMusicNoData, {
  link: "Screen.Route.link"
});

const SharedNoData = props => {
  const info = {
    image: "sharedNoDataIcon",
    primaryText: I18N.t("sharedNoDataHeader"),
    secondaryText: I18N.t("sharedNoDataMessage")
  };
  return <RenderNoData {...info} {...props} />;
};

const TrashNoData = props => {
  const info = {
    image: "trashNoDataIcon",
    primaryText: I18N.t("noItemsTrash"),
    secondaryText: I18N.t("deletedItemsPlaced")
  };
  return <RenderNoData {...info} {...props} />;
};

const FailedNoData = props => {
  const info = {
    image: "failedNoDataIcon",
    primaryText: I18N.t("noItemsFailed"),
    secondaryText: I18N.t("failedItemsPlaced")
  };
  return <RenderNoData {...info} {...props} />;
};

const NonUploadedNoData = props => {
  const info = {
    image: "failedNoDataIcon",
    primaryText: I18N.t("noNonUploadedItems"),
    secondaryText: I18N.t("NonUploadedItemsPlaced")
  };
  return <RenderNoData {...info} {...props} />;
};

const InfectedNoData = props => {
  const info = {
    image: "infectedNoDataIcon",
    primaryText: I18N.t("noItemsInfected"),
    secondaryText: I18N.t("infectedItemsPlaced")
  };
  return <RenderNoData {...info} {...props} />;
};

const ContactsNoData = props => {
  const info = {
    image: "contactNoDataIcon",
    primaryText: I18N.t("contactNoDataHeader"),
    secondaryTextSM: I18N.t("contactNoDataMobileMessage"),
    secondaryTextMD: I18N.t("contactNoDataMessage"),
    buttonTextMD: I18N.t("addContact"),
    buttonContainerMD: RowAction,
    buttonContainerProps: {
      action: ContactActions.addContact
    }
  };
  return <RenderNoData {...info} {...props} />;
};

const ContactsSearchNoData = props => {
  const info = {
    image: "contactSearchNoDataIcon",
    primaryText: I18N.t("noContactFound")
  };
  return <RenderNoData {...info} {...props} />;
};

const UploadQueueNoData = props => {
  const info = {
    image: "noDataIcon",
    primaryText: I18N.t("uploadQueueNoDataHeader")
  };
  return <RenderNoData {...info} {...props} />;
};

const DownloadQueueNoData = props => {
  const info = {
    image: "noDataIcon",
    primaryText: I18N.t("downloadQueueNoDataHeader")
  };
  return <RenderNoData {...info} {...props} />;
};

const IssuesNoData = props => {
  const info = {
    image: "noDataIcon",
    primaryText: I18N.t("issuesNoDataHeader"),
    secondaryText: I18N.t("issuesNoDataMobileMessage"),
    buttonText: I18N.t("add"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      action: {
        type: "link",
        text: () => I18N.t("add"),
        link: {
          uri: "/add-issue"
        }
      }
    }
  };
  return <RenderNoData {...info} {...props} />;
};
const CommentNoData = props => {
  const info = {
    image: "noDataIcon",
    primaryText: I18N.t("commentNoDataHeader"),
    secondaryText: I18N.t("commentNoDataMobileMessage"),
    buttonText: I18N.t("addComment"),
    buttonContainer: RowAction,
    buttonContainerProps: {
      action: {
        type: "link",
        text: () => I18N.t("addComment"),
        link: props => {
          let {
            link: {
              props: {
                filter: { _id }
              }
            }
          } = props;
          return {
            uri: "/add-comment",
            props: {
              filter: { _id: _id }
            }
          };
        }
      }
    }
  };
  return <RenderNoData {...info} {...props} />;
};
export {
  HighlightNoData,
  GalleryAllNoData,
  DuplicateResourceNoData,
  VaultGalleryAllNoData,
  GalleryAlbumNoData,
  DocsNoData,
  VaultDocsNoData,
  DocsSetNoData,
  MusicNoData,
  PlaylistNoData,
  ArtistNoData,
  GroupNoData,
  GroupGalleryNoDataSM,
  GroupGalleryNoDataMD,
  GroupDocsNoDataSM,
  GroupDocsNoDataMD,
  SelectImageNoData,
  SelectDocsNoData,
  SelectMusicNoData,
  UploadGalleryNoData,
  UploadDocumentNoData,
  UploadMusicNoData,
  UploadProfileNoData,
  SearchGalleryTypeNoData,
  SearchDocsTypeNoData,
  SearchMusicTypeNoData,
  SharedNoData,
  TrashNoData,
  ContactsNoData,
  ContactsSearchNoData,
  IssuesNoData,
  CommentNoData,
  UploadQueueNoData,
  DownloadQueueNoData,
  MemoriesOfDayNoData,
  GalleryPlaceNoData,
  GalleryFolderNoData,
  FailedNoData,
  InfectedNoData,
  ArchiveGalleryAllNoData,
  NonUploadedNoData,
  ArchiveDocsNoData,
  ArchiveMusicNoData,
  VaultAlbumNoData,
  VaultSetNoData
};
