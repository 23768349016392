import React from "react";
import { getErrorMessage } from "./CollectionUtility";

export default ({
  Box,
  View,
  resolveMQ,
  getComponent,
  getJsonComponent,
  getImage,
  theme,
  I18N,
  Header,
  LoadingIndicator,
  RenderNoData,
  showMessage,
  Platform,
  RowAction
}) => {
  const { fonts, colors, bgs } = theme;
  const { h16_l, h9 } = fonts;
  const { themeBg } = bgs;
  const { highlightColor, primaryColor, tomato } = colors;
  const metadata = {
    album: {
      headerTitle: () => I18N.t("createANewAlbumCaps"),
      listComponentName: "albumItemSelector",
      successMessage: () => I18N.t("albumCreated"),
      buttonText: () => I18N.t("create"),
      containerStyle: { width: 823, height: 465 }
    },
    set: {
      headerTitle: () => I18N.t("createANewSetCaps"),
      listComponentName: "setItemSelector",
      successMessage: () => I18N.t("setCreated"),
      buttonText: () => I18N.t("create"),
      containerStyle: { width: 1005, height: 579 }
    },
    playlist: {
      headerTitle: () => I18N.t("createANewPlaylistCaps"),
      listComponentName: "playlistItemSelector",
      successMessage: () => I18N.t("playlistCreated"),
      buttonText: () => I18N.t("create"),
      containerStyle: { width: 1005, height: 579 }
    },
    albumDetail: {
      headerTitle: () => I18N.t("addToAlbumCaps"),
      listComponentName: "albumItemSelector",
      buttonText: () => I18N.t("add"),
      containerStyle: { width: 823, height: 465 },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToAlbum"),
      successMessageMultiple: () => I18N.t("itemsAddedToAlbum")
    },
    setDetail: {
      headerTitle: () => I18N.t("addToSetCaps"),
      listComponentName: "setItemSelector",
      buttonText: () => I18N.t("add"),
      containerStyle: { width: 1005, height: 579 },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToSet"),
      successMessageMultiple: () => I18N.t("itemsAddedToSet")
    },
    playlistDetail: {
      headerTitle: () => I18N.t("addToPlaylistCaps"),
      listComponentName: "playlistItemSelector",
      buttonText: () => I18N.t("add"),
      containerStyle: { width: 1005, height: 579 },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToPlaylist"),
      successMessageMultiple: () => I18N.t("itemsAddedToPlaylist")
    },
    groupDoc: {
      headerTitle: () => I18N.t("sendToGroupCaps"),
      listComponentName: "setItemSelector",
      buttonText: () => I18N.t("post"),
      containerStyle: { width: 823, height: 468 },
      successMessage: () => I18N.t("itemsPostSuccessfully"),
      successMessageSingle: () => I18N.t("itemPostToGroup"),
      successMessageMultiple: () => I18N.t("itemsPostToGroup")
    },
    groupImage: {
      headerTitle: () => I18N.t("sendToGroupCaps"),
      listComponentName: "albumItemSelector",
      buttonText: () => I18N.t("post"),
      containerStyle: { width: 823, height: 468 },
      successMessage: () => I18N.t("itemsPostSuccessfully"),
      successMessageSingle: () => I18N.t("itemPostToGroup"),
      successMessageMultiple: () => I18N.t("itemsPostToGroup")
    },
    vaultGallery: {
      headerTitle: () => I18N.t("moveToSafeAreaCaps"),
      listComponentName: "albumItemSelector",
      buttonText: () => I18N.t("move"),
      containerStyle: { width: 823, height: 468 },
      successMessage: () => I18N.t("itemsMovedSuccessfully"),
      successMessageSingle: () => I18N.t("item_moved_to_safe_area"),
      successMessageMultiple: () => I18N.t("items_moved_to_safe_area")
    },
    vaultDocs: {
      headerTitle: () => I18N.t("moveToSafeAreaCaps"),
      listComponentName: "setItemSelector",
      buttonText: () => I18N.t("move"),
      containerStyle: { width: 823, height: 468 },
      successMessage: () => I18N.t("itemsMovedSuccessfully"),
      successMessageSingle: () => I18N.t("item_moved_to_safe_area"),
      successMessageMultiple: () => I18N.t("items_moved_to_safe_area")
    },
    archiveGallery: {
      headerTitle: () => I18N.t("moveToArchiveCaps"),
      listComponentName: "albumItemSelector",
      buttonText: () => I18N.t("move"),
      containerStyle: { width: 823, height: 468 },
      successMessage: () => I18N.t("itemsMovedSuccessfully"),
      successMessageSingle: () => I18N.t("item_moved_to_archive"),
      successMessageMultiple: () => I18N.t("items_moved_to_archive")
    },
    archiveDocs: {
      headerTitle: () => I18N.t("moveToArchiveCaps"),
      listComponentName: "setItemSelector",
      buttonText: () => I18N.t("move"),
      containerStyle: { width: 823, height: 468 },
      successMessage: () => I18N.t("itemsMovedSuccessfully"),
      successMessageSingle: () => I18N.t("item_moved_to_archive"),
      successMessageMultiple: () => I18N.t("items_moved_to_archive")
    },
    archiveMusic: {
      headerTitle: () => I18N.t("moveToArchiveCaps"),
      listComponentName: "playlistItemSelector",
      buttonText: () => I18N.t("move"),
      containerStyle: { width: 823, height: 468 },
      successMessage: () => I18N.t("itemsMovedSuccessfully"),
      successMessageSingle: () => I18N.t("item_moved_to_archive"),
      successMessageMultiple: () => I18N.t("items_moved_to_archive")
    },
    vaultAlbum: {
      headerTitle: () => I18N.t("createANewAlbumCaps"),
      listComponentName: "albumItemSelector",
      successMessage: () => I18N.t("albumCreated"),
      buttonText: () => I18N.t("create"),
      containerStyle: { width: 823, height: 465 }
    },
    vaultSet: {
      headerTitle: () => I18N.t("createANewSetCaps"),
      listComponentName: "setItemSelector",
      successMessage: () => I18N.t("setCreated"),
      buttonText: () => I18N.t("create"),
      containerStyle: { width: 1005, height: 579 }
    }
  };

  class ItemSelector extends React.Component {
    state = {};

    componentDidMount() {
      if (Platform && Platform.OS === "web") {
        window.addEventListener("keydown", this.handleKey);
      }
    }

    componentWillUnmount() {
      if (Platform && Platform.OS === "web") {
        window.removeEventListener("keydown", this.handleKey);
      }
    }

    handleKey = e => {
      if (e.keyCode === 13) {
        this.onAddClick();
      }
    };

    onAddClick = async () => {
      const { selectedIds = [] } = this.state.SelectionStore || {};
      const { data: formData } = this.props; // it is collection data if open in detail
      if (!selectedIds.length) {
        this.setState({
          error: I18N.t("selectErrorMesage")
        });
        return;
      }
      try {
        const { urls, invoke, action, collectionName, onClose, user } = this.props;
        this.setState({ loading: true });
        let origin = action && action.origin;
        const items = selectedIds.map(_id => ({ _id }));
        let service;
        if (formData && formData._id && !collectionName) {
          service = {
            service: {
              url: urls["addItemToCollection"],
              uriProps: {
                data: {
                  _id: formData._id,
                  items
                }
              }
            }
          };
          await invoke(service);
          let { successMessage, successMessageSingle, successMessageMultiple } = metadata[action.origin];
          const itemsLength = selectedIds && selectedIds.length;
          let message = itemsLength > 1 ? `${itemsLength} ${successMessageMultiple()}` : successMessageSingle();
          if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
            message = successMessage();
          }
          message && showMessage && showMessage(message, 2000);
        } else if (origin === "groupDoc" || origin === "groupImage") {
          service = {
            service: {
              url: urls["addGroupItem"],
              uriProps: {
                data: {
                  _id: user && user.group && user.group._id,
                  items
                }
              }
            }
          };
          await invoke(service);
          let { successMessage, successMessageSingle, successMessageMultiple } = metadata[action.origin];
          const itemsLength = selectedIds && selectedIds.length;
          let message = itemsLength > 1 ? `${itemsLength} ${successMessageMultiple()}` : successMessageSingle();
          if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
            message = successMessage();
          }
          message && showMessage && showMessage(message, 2000);
        } else if (origin === "vaultGallery" || origin === "vaultDocs") {
          service = {
            service: {
              url: urls["moveToVault"],
              uriProps: {
                data: {
                  resourceIds: selectedIds
                }
              }
            }
          };
          await invoke(service);
          let { successMessage, successMessageSingle, successMessageMultiple } = metadata[action.origin];
          const itemsLength = selectedIds && selectedIds.length;
          let message = itemsLength > 1 ? `${successMessageMultiple()}` : successMessageSingle();
          if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
            message = successMessage();
          }
          message && showMessage && showMessage(message, 2000);
        } else if (origin === "archiveGallery" || origin === "archiveDocs" || origin === "archiveMusic") {
          service = {
            service: {
              url: urls["moveToArchive"],
              uriProps: {
                data: {
                  resourceIds: selectedIds
                }
              }
            }
          };
          await invoke(service);
          let { successMessage, successMessageSingle, successMessageMultiple } = metadata[action.origin];
          const itemsLength = selectedIds && selectedIds.length;
          let message = itemsLength > 1 ? `${successMessageMultiple()}` : successMessageSingle();
          if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
            message = successMessage();
          }
          message && showMessage && showMessage(message, 2000);
        } else {
          service = {
            service: {
              url: urls["createCollection"],
              uriProps: {
                data: {
                  name: collectionName,
                  type: action.origin,
                  items
                }
              }
            }
          };
          await invoke(service);
          let { successMessage } = metadata[action.origin];
          successMessage && showMessage && showMessage(successMessage(), 2000);
        }
        this.setState({ loading: false });
        onClose && onClose();
      } catch (e) {
        let message = getErrorMessage(e, { I18N });
        this.setState({ error: message, loading: false });
      }
    };

    render() {
      const { action, activeMQ, onClose } = this.props;
      let { origin, connectProps, defaultConnectState } = action;
      const { headerTitle, listComponentName, buttonText, containerStyle } = resolveMQ(
        metadata[origin],
        ["headerTitle", "listComponentName", "buttonText", "containerStyle"],
        activeMQ
      );
      if (connectProps && typeof connectProps === "function") {
        connectProps = connectProps(this.props);
      }
      const ConnectPanelTable = getJsonComponent("connectPanelTable");
      let ListComponent = getComponent(listComponentName);
      if (defaultConnectState) {
        defaultConnectState = {
          ...defaultConnectState,
          dataParams: {
            ...defaultConnectState.dataParams
          }
        };
      }
      return (
        <ConnectPanelTable
          screenName="collectionItemSelector"
          defaultConnectState={defaultConnectState}
          connectProps={connectProps}
          state={this.state}
          setState={state => this.setState(state)}
          componentProps={{ selectionMode: true }}
          renderComponent={connectResult => {
            let { data, pending, dataIds, hasNext, lastDoc, fetchMore } = connectResult;
            let isEmptyData = !data || (Array.isArray(data) && !data.length);
            let noData = !pending && isEmptyData;
            if (noData) {
              return <RenderNoData onClose={onClose} origin={origin} />;
            } else {
              let selectedIds = this.state.SelectionStore && this.state.SelectionStore.selectedIds;
              let selectionCount = selectedIds && selectedIds.length;
              let listComponentProps = {
                data,
                dataIds,
                hasNext,
                lastDoc,
                fetchMore
              };
              return (
                <View style={containerStyle}>
                  {((pending && isEmptyData) || this.state.loading) && <LoadingIndicator />}
                  <Header title={headerTitle} onClose={onClose} />
                  {data && data.length ? (
                    <Box
                      viewStyle={{ flex: 1 }}
                      render={[
                        {
                          viewStyle: {
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 24,
                            paddingBottom: 12
                          },
                          textStyle: {
                            ...h16_l,
                            color: primaryColor
                          },
                          direction: "row",
                          render: [
                            {
                              width: "1fr",
                              text: selectionCount ? `${selectionCount} ${I18N.t("selected")} ` : I18N.t("selectItems")
                            },
                            data && data.length && (
                              <RowAction
                                data={data}
                                menuModalStyle={{
                                  modal: {
                                    stopPropagation: true,
                                    width: 200,
                                    position: "left",
                                    autoHide: true,
                                    style: { borderRadius: 4, zIndex: 10 }
                                  }
                                }}
                                action={[
                                  {
                                    type: "selectAll",
                                    text: () => I18N.t("selectAll"),
                                    visible: () => data && data.length !== selectionCount
                                  },
                                  {
                                    type: "unSelectAll",
                                    text: () => I18N.t("selectNone"),
                                    visible: () => selectionCount
                                  }
                                ]}
                              >
                                <Box
                                  viewStyle={{
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                  }}
                                  image={getImage("iconMenu")}
                                />
                              </RowAction>
                            )
                          ]
                        },
                        {
                          viewStyle: { flex: 1 },
                          render: <ListComponent {...listComponentProps} />
                        },
                        {
                          viewStyle: {
                            paddingTop: 14,
                            paddingBottom: 14,
                            paddingRight: 30,
                            paddingLeft: 30,
                            alignItems: "center"
                          },
                          direction: "row",
                          render: [
                            {
                              viewStyle: {
                                flex: 1,
                                paddingRight: 12
                              },
                              textStyle: {
                                ...h9,
                                color: tomato,
                                numberOfLines: 2
                              },
                              text: this.state.error || ""
                            },
                            {
                              viewStyle: {
                                paddingTop: 9,
                                paddingBottom: 9,
                                paddingLeft: 24,
                                paddingRight: 24,
                                backgroundColor: themeBg,
                                cursor: "pointer",
                                borderRadius: 4
                              },
                              textStyle: {
                                ...h16_l,
                                color: highlightColor
                              },
                              text: buttonText,
                              onClick: e => {
                                this.onAddClick();
                              }
                            }
                          ]
                        }
                      ]}
                    />
                  ) : (
                    void 0
                  )}
                </View>
              );
            }
          }}
        />
      );
    }
  }
  return ItemSelector;
};
