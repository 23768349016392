import landing from "./landing";
import logo from "./logo";
import headerIcons from "./headerIcons";
import docExtIcons from "./docExtIcons";
import docExtShadowIcons from "./docExtShadowIcons";
import docExtShadowPopupIcons from "./docExtShadowPopupIcons";
import homeIcons from "./homeIcons";
import drawerIcons from "./drawerIcons";
import sharePopUpIcons from "./sharePopUpIcons";
import montageIcons from "./montageIcons";
import searchIcons from "./searchIcons";
import audioPlayIcons from "./audioPlayerIcons";
import highlightIcons from "./highlightIcons";
import popupActionIcons from "./popupActionIcons";
import noDataIcons from "./noDataIcons";
import openActionIcons from "./openActionIcons";
import miscellaneousIcons from "./miscellaneousIcons";
import miscellaneousMDIcons from "./miscellaneousMDIcons";


export default props => {
  return {
    ...landing(props),
    ...logo(props),
    ...headerIcons(props),
    ...docExtIcons(props),
    ...docExtShadowIcons(props),
    ...docExtShadowPopupIcons(props),
    ...homeIcons(props),
    ...drawerIcons(props),
    ...sharePopUpIcons(props),
    ...montageIcons(props),
    ...searchIcons(props),
    ...audioPlayIcons(props),
    ...highlightIcons(props),
    ...popupActionIcons(props),
    ...noDataIcons(props),
    ...openActionIcons(props),
    ...miscellaneousIcons(props),
    ...miscellaneousMDIcons(props)
  };
};
