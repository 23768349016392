export default {
  "/docs": {
    screen: "DocsTab"
  },
  "/upload-docs": {
    screen: "UploadDocument"
  },
  "/select-docs": {
    screen: "SelectDocs"
  },
  "/select-set": {
    screen: "SelectSet"
  },
  "/set-detail": {
    screen: "SetDetail"
  },
  "/doc-detail": {
    screen: "DocDetail"
  },
  "/set-doc-detail": {
    screen: "SetDocDetail"
  },
  "/search-doc-detail": {
    screen: "SearchDocDetail"
  },
  "/group-doc-detail": {
    screen: "GroupDocDetail"
  },
  "/vault-doc-detail": {
    screen: "VaultDocDetail"
  },
  "/archive-doc-detail": {
    screen: "ArchiveDocDetail"
  }
};
