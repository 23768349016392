import React from "react";

export default ({ Box, getImage, WithModal, resolveMQ, languages, theme, DisplayComponent }) => {
  const { colors, fonts } = theme;
  const { themeColor, brownGrey } = colors;
  const { h9 } = fonts;

  const modalStyle = {
    modalSM: {
      width: 153,
      maxHeight: 200,
      autoHide: true,
      style: {
        borderRadius: 2
      },
      position: "cover"
    },
    modalMD: {
      width: 153,
      maxHeight: 200,
      autoHide: true,
      style: {
        borderRadius: 2
      },
      position: "bottom"
    }
  };

  class LanguageSelector extends React.Component {
    state = { isModalOpen: false };
    renderModal = ({ closeModal } = {}) => {
      let { setLanguage, getLanguage } = this.props;
      let currentLanguage = getLanguage && getLanguage();
      return (
        <Box
          viewStyle={{ paddingTop: 6, paddingBottom: 6 }}
          render={Object.keys(languages).map(lang => {
            let selected = currentLanguage === lang;
            let textStyle = { ...h9, color: selected ? themeColor : brownGrey };
            return {
              viewStyle: {
                alignItems: "center",
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 6,
                paddingBottom: 6,
                cursor: "pointer"
              },
              onClick: () => {
                setLanguage && setLanguage(lang);
                closeModal && closeModal();
              },
              direction: "row",
              render: [
                { width: "1fr", textStyle, text: languages[lang] },
                selected && { image: getImage("selectedLanguageIcon") }
              ]
            };
          })}
        />
      );
    };
    onModalOpen = () => {
      let { isModalOpen } = this.state;
      if (!isModalOpen) {
        this.setState({ isModalOpen: !isModalOpen });
      }
    };
    onModalClose = () => {
      let { isModalOpen } = this.state;
      if (isModalOpen) {
        this.setState({ isModalOpen: !isModalOpen });
      }
    };
    render() {
      let { style, activeMQ } = this.props;
      let { isModalOpen } = this.state;
      let modalProps = resolveMQ(modalStyle, ["modal"], activeMQ);
      return (
        <WithModal
          {...modalProps}
          onModalOpen={this.onModalOpen}
          onModalClose={this.onModalClose}
          renderModal={this.renderModal}
        >
          <Box
            style={style}
            viewStyle={{ cursor: "pointer" }}
            render={<DisplayComponent {...this.props} isModalOpen={isModalOpen} />}
          />
        </WithModal>
      );
    }
  }
  return LanguageSelector;
};
