import React from "react";
import { I18N } from "../../../../FsCloudComponent";
import ContactSM from "./ContactSM";
import ContactMD from "./ContactMD";
import { getContactQuery } from "../../queries";
import { ContactsNoData } from "../../noData/NoData";

export default {
  ContactsSM: {
    component: "table",
    title: () => I18N.t("contacts"),
    dataObject: "Contact",
    ComponentSM: ContactSM,
    ...getContactQuery,
    renderNoData: <ContactsNoData />,
    headerActions: [
      {
        type: "headerAction",
        image: "searchIcon",
        visible: ({ selectedIds = [] }) => selectedIds.length === 0,
        action: "searchContact"
      },
      {
        type: "headerAction",
        image: "createIcon",
        visible: ({ selectedIds = [] }) => selectedIds.length === 0,
        action: "addContactLink"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds = [] }) => selectedIds.length === 1,
        action: "editContactLink"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds = [] }) => selectedIds.length > 0,
        action: "deleteContact"
      },
      {
        type: "multiActions",
        image: "iconMenu",
        visible: ({ selectedIds = [] }) => selectedIds.length === 0,
        action: ["restoreContact", "mergeDuplicateContact"]
      }
    ]
  },

  ContactsMD: {
    component: "table",
    dataObject: "Contact",
    ComponentMD: ContactMD,
    ...getContactQuery,
    panelProps: {
      showHeader: false,
      showLoading: false
    },
    hideNavHeader: true
  }
};
