import React from "react";

export default ({ View, LoadingBar, renderProps, resolveMQ, theme, ...hocProps }) => {
  const mqHocProps = ["Header", "Footer"];
  const mqProps = ["title", "showLoading", "showHeader", "Header", "Footer", "renderNoData"];

  class Panel extends React.Component {
    render() {
      let { activeMQ, headerActions, footerActions, data, hasNext, ...restProps } = this.props;
      const { Header: HeaderHoc, Footer: FooterHoc } = resolveMQ(hocProps, mqHocProps, activeMQ);
      let { title, showLoading, showHeader, Header, Footer, renderNoData } = resolveMQ(restProps, mqProps, activeMQ);
      Header = Header || HeaderHoc;
      Footer = Footer || FooterHoc;

      let isRenderNoData = renderNoData && !showLoading && (!data || (Array.isArray(data) && !data.length));
      if (hasNext && isRenderNoData) {
        isRenderNoData = false;
        showLoading = true;
      }
      return (
        <View style={theme.style}>
          {showHeader !== false && Header && (
            <Header {...restProps} data={data} title={title} actions={headerActions} />
          )}
          <View style={theme.style}>
            {showLoading && <LoadingBar />}
            {renderProps(
              isRenderNoData
                ? {
                    render: renderNoData
                  }
                : restProps
            )}
          </View>
          {Footer && <Footer {...restProps} data={data} actions={footerActions} />}
        </View>
      );
    }
  }
  return Panel;
};
