import LoginThemeHoc from "./loginTheme";
import WalkthroughThemeHoc from "./walkthroughTheme";

export default props => {
  const { colors, fonts } = props;
  const { brownGrey } = colors;
  const { h16_l } = fonts;
  const loginTheme = LoginThemeHoc(props);
  const walkthroughTheme = WalkthroughThemeHoc(props);
  const cardHeight = 74;
  const navHeight = 60;
  const totalNavHeight = 97;
  const tabHeight = 37;
  const bottomTabHeight = 60;

  const columnTextMD = {
    ...h16_l,
    color: brownGrey,
    numberOfLines: 1
  };

  return {
    ...loginTheme,
    ...walkthroughTheme,
    columnTextMD,
    cardHeight,
    navHeight,
    totalNavHeight,
    tabHeight,
    bottomTabHeight
  };
};
