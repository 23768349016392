import React from "react";
import { Box } from "../../../FsCloudComponent";

export default {
  AllFiles: {
    component: "table",
    title: "All Files",
    Component: () => <Box text={"This is All Files View"} />
  }
};
