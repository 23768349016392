import React from "react";
export default ({ View, Text, mergeUri, resolveLinkFilter, resolveFilterDef }) => {
  const ensureState = props => {
    const { state, setState } = props;
    if (!state || !setState) {
      throw new Error(`state or setState must be passed in props in ConnectPanelTable`);
    }
  };
  const errorComponent = ({ message, stack } = {}) => {
    return (
      <View>
        <Text>Message : {message}</Text>
        <Text>Stack : {stack}</Text>
      </View>
    );
  };

  const getUri = ({ views = {}, dataObject, fetch, link, linkFilter }) => {
    if (!fetch) {
      return;
    }
    let uri = void 0;
    if (typeof fetch === "function") {
      fetch = fetch({ link });
    }
    const { view } = fetch;
    if (typeof view !== "string") {
      if (fetch.props) {
        uri = { ...fetch };
      } else {
        uri = { props: { ...fetch } };
      }
    } else {
      let dataObjectViews = views[dataObject] || {};
      if (!dataObjectViews || !dataObjectViews.views) {
        throw new Error(
          `View definition not found for view [${view}]. DataObject is [${dataObject}] 
          )}. Views available for dataObjects${JSON.stringify(Object.keys(views))}`
        );
      }
      dataObjectViews = dataObjectViews.views;
      const viewDef = dataObjectViews[view];
      if (!viewDef) {
        throw new Error(
          `View definition not found for view [${view}]. DataObject is [${dataObject}] and available views are ${JSON.stringify(
            Object.keys(dataObjectViews)
          )}. Views available for dataObjects${JSON.stringify(Object.keys(views))}`
        );
      }
      const { service, query, model, data, subscribe } = viewDef;
      uri = {
        props: {
          service,
          query: {
            ...query,
            ...data,
            metadata: subscribe ? true : false
          },
          subscribe,
          model
        }
      };
    }
    if (link) {
      let props = { ...link.props };
      if (linkFilter) {
        let resolvedLinkFilter = resolveLinkFilter({ link, linkFilter });
        if (resolvedLinkFilter) {
          props = { ...props, ...resolvedLinkFilter };
        }
      }
      link = { ...link, props };
      uri = mergeUri({ uri, link }).uri;
      if (link.forceReload) {
        // for fscloud, need to force reload on logo click
        uri = {
          ...uri,
          props: {
            ...uri.props,
            forceReload: link.forceReload
          }
        };
      }
    }
    return uri;
  };

  const writeLog = (log = {}) => {
    if (log.type === "error") {
      // console.error(log);
    } else {
      // console.warn(log);
    }
  };

  const resolveHeaderActions = ({ headerActions, actions, fields, groups, filterData }) => {
    return headerActions
      ? headerActions.map(action => {
          if (typeof action === "string") {
            action = { ...actions[action] };
          }
          let { type } = action;
          if (type === "filter" || type === "tabFilter") {
            let { filters = [], ...restProps } = action;
            action = {
              ...restProps,
              filters: resolveFilterDef({
                filters,
                fields,
                source: type === "tabFilter" ? "tab" : "view"
              }),
              filterData
            };
          } else if (type === "groupBy") {
            action = {
              ...action,
              fields,
              groups
            };
          } else if (type === "multiActions" || type === "rowAction" || type === "headerAction") {
            action = {
              ...action,
              actions
            };
          }
          return action;
        })
      : void 0;
  };

  return { ensureState, errorComponent, writeLog, getUri, resolveHeaderActions };
};
