export default ({ colors, bgs, fonts, shadows }) => {
  const { primaryBg2, lightThemeBg } = bgs;
  const { h16_l, h16, h9, h4 } = fonts;
  const { primaryColor, themeColor, highlightColor, tabBlackColor } = colors;

  const tabActionsStyle = {
    boxStyleSM: {
      direction: "row",
      viewStyle: {
        height: 60,
        paddingLeft: 4,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        alignItems: "center",
        backgroundColor: highlightColor
      }
    },
    boxStyleMD: {
      viewStyle: {
        alignItems: "center",
        height: 44,
        paddingLeft: 15,
        paddingRight: 15
      },
      direction: "row"
    },
    separatorStyleSM: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    separatorStyleMD: {
      viewStyle: {
        paddingLeft: 15,
        paddingRight: 15
      }
    }
  };

  const tabMoreStyle = {
    containerTypeMD: "tabBorder",
    tabBarStyle: {
      direction: "row"
    },
    tabItemContainerStyle: {
      viewStyle: {
        paddingRight: 15
      }
    },
    tabItemStyle: {
      direction: "row",
      containerStyle: {
        justifyContent: "center",
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 14,
        paddingRight: 14,
        borderBottomWidth: 3,
        borderBottomStyle: "solid",
        borderBottomColor: "transparent",
        cursor: "pointer"
      },
      selectedContainerStyle: {
        borderBottomColor: themeColor
      },
      textStyle: {
        ...h16_l,
        color: primaryColor
      },
      selectedTextStyle: {
        ...h16_l,
        color: themeColor
      }
    },
    itemRightGap: 16
  };

  const tabStyle = {
    tabItemContainerStyle: {
      direction: "row",
      itemStyle: {
        flex: 1
      }
    },
    tabBarStyle: {
      viewStyle: {
        marginBottom: 1,
        backgroundColor: highlightColor,
        ...shadows.navHeaderShadowSM
      }
    },
    animatedBorderStyle: {
      height: 3,
      backgroundColor: themeColor
    },
    tabItemStyle: {
      direction: "row",
      containerStyle: {
        justifyContent: "center",
        paddingTop: 6,
        paddingBottom: 9,
        paddingLeft: 9,
        paddingRight: 9,
        cursor: "pointer"
      },
      textStyle: {
        textAlign: "center",
        ...h16,
        color: tabBlackColor,
        opacity: 0.5
      },
      selectedTextStyle: {
        ...h16,
        opacity: void 0
      },
      itemStyle: {
        flex: 1,
        paddingRight: 6
      }
    }
  };

  const bottomTabStyle = {
    tabBarStyle: {
      viewStyle: {
        height: 56,
        marginTop: 4,
        backgroundColor: primaryBg2,
        justifyContent: "center",
        ...shadows.bottomTabShadow
      }
    },
    tabItemContainerStyle: {
      direction: "row",
      viewStyle: {
        maxWidth: 500,
        alignSelf: "center",
        width: "100%",
        alignItems: "center"
      },
      itemStyle: {
        flex: 1
      }
    },
    tabItemStyle: {
      containerStyle: {
        cursor: "pointer",
        alignItems: "center",
        paddingLeft: 6,
        paddingRight: 6
      },
      itemStyle: {
        paddingTop: 5
      },
      textStyle: {
        ...h4,
        color: primaryColor,
        numberOfLines: 1
      },
      selectedTextStyle: {
        ...h4,
        color: themeColor,
        numberOfLines: 1
      }
    }
  };

  const tabScrollAnimationStyle = {
    containerStyle: {
      position: "absolute",
      left: 0,
      right: 0,
      zIndex: 12,
      elevation: 1
    }
  };

  const leftTabStyle = {
    tabBarStyle: {
      viewStyle: {
        backgroundColor: primaryBg2,
        marginRight: 1,
        width: 80,
        paddingTop: 58
      }
    },
    tabItemStyle: {
      containerStyle: {
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        height: 42,
        marginTop: 8,
        marginBottom: 8,
        borderLeftWidth: 2,
        borderLeftColor: "transparent"
      },
      selectedContainerStyle: {
        borderLeftColor: themeColor,
        backgroundColor: lightThemeBg
      },
      textStyle: {
        ...h4,
        color: primaryColor,
        numberOfLines: 1
      },
      selectedTextStyle: {
        ...h4,
        color: themeColor,
        numberOfLines: 1
      }
    }
  };

  const contactTabStyleSM = {
    tabItemContainerStyle: {
      direction: "row",
      itemStyle: {
        flex: 1
      }
    },
    tabBarStyle: {
      viewStyle: {
        marginBottom: 1,
        backgroundColor: highlightColor,
        ...shadows.navHeaderShadowSM
      }
    },
    animatedBorderStyle: {
      height: 3,
      backgroundColor: themeColor
    },
    tabItemStyle: {
      direction: "row",
      containerStyle: {
        justifyContent: "center",
        paddingTop: 6,
        paddingBottom: 9,
        cursor: "pointer"
      },
      textStyle: {
        ...h9,
        color: primaryColor,
        opacity: 0.5
      },
      selectedTextStyle: {
        ...h9,
        color: themeColor,
        opacity: void 0
      },
      itemStyle: {
        paddingRight: 6
      }
    }
  };

  return {
    tabStyle,
    tabMoreStyle,
    tabActionsStyle,
    bottomTabStyle,
    tabScrollAnimationStyle,
    leftTabStyle,
    contactTabStyleSM
  };
};
