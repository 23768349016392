export default ({ fonts, colors, images, shadows, bgs }) => {
  const { primaryColor, themeColor, brownGrey } = colors;
  const { lightThemeBg } = bgs;
  const { menuHeaderShadow } = shadows;
  const { h16, h2, h1, h16_l, h9 } = fonts;
  const { menuHeaderLogo } = images;

  const drawerMenuStyle = {
    scrollViewStyle: {
      style: {
        flex: 1
      }
    },
    boxStyle: {
      viewStyle: {
        flex: 1,
        overflow: void 0
      }
    },
    menuContainerStyle: {
      viewStyle: {
        paddingTop: 20,
        paddingBottom: 20
      }
    },
    headerStyleMD: {
      headerActionStyle: {
        viewStyle: {
          alignItems: "center",
          height: 64,
          ...menuHeaderShadow
        },
        direction: "row"
      },
      logoStyle: {
        width: "1fr",
        viewStyle: { alignItems: "flex-start", paddingRight: 17, paddingLeft: 31 },
        image: menuHeaderLogo
      },
      crossIconStyle: {
        viewStyle: {
          marginRight: 18,
          width: 24,
          height: 24,
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer"
        }
      },
      boxStyle: {
        viewStyle: {
          alignItems: "center"
        }
      },
      imageContainerStyle: {
        viewStyle: { paddingTop: 16, paddingBottom: 12, cursor: "pointer" }
      },
      titleStyle: {
        viewStyle: { paddingBottom: 4 },
        textStyle: {
          ...h16,
          color: primaryColor
        }
      },
      editIconStyle: {
        viewStyle: {
          borderRadius: "50%",
          position: "absolute",
          bottom: 15,
          right: 0,
          cursor: "pointer"
        }
      },
      userLimitStyle: {
        viewStyle: { paddingTop: 4 },
        textStyle: {
          ...h2,
          color: brownGrey
        }
      }
    },
    headerStyleSM: {
      boxStyle: {
        viewStyle: {
          alignItems: "center"
        }
      },
      imageContainerStyle: {
        viewStyle: { paddingTop: 29, paddingBottom: 12 }
      },
      titleStyle: {
        viewStyle: { paddingBottom: 4 },
        textStyle: {
          ...h16,
          color: primaryColor
        }
      },
      userLimitStyle: {
        viewStyle: { paddingTop: 4, paddingBottom: 6 },
        textStyle: {
          ...h2,
          color: brownGrey
        }
      }
    },
    menuItemStyleMD: {
      boxStyle: {
        viewStyle: {
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 6,
          marginBottom: 6,
          height: 42,
          alignItems: "center",
          cursor: "pointer",
          borderLeftWidth: 0
        }
      },
      iconStyle: {
        viewStyle: {
          paddingRight: 20,
          paddingLeft: 20,
          width: 64,
          height: 42,
          alignItems: "center",
          justifyContent: "center"
        }
      },
      titleStyle: {
        viewStyle: { flex: 1 },
        textStyle: {
          ...h16_l,
          paddingRight: 20,
          paddingLeft: 20,
          color: primaryColor,
          numberOfLines: 1
        }
      }
    },
    selectedMenuItemStyleMD: {
      boxStyle: {
        viewStyle: {
          paddingLeft: 8,
          paddingRight: 10,
          marginTop: 6,
          marginBottom: 6,
          height: 42,
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: lightThemeBg,
          borderLeftWidth: 2,
          borderLeftColor: themeColor
        }
      },
      iconStyle: {
        viewStyle: {
          paddingRight: 20,
          paddingLeft: 20,
          width: 64,
          height: 42,
          alignItems: "center",
          justifyContent: "center"
        }
      },
      titleStyle: {
        viewStyle: { flex: 1 },
        textStyle: {
          ...h16,
          paddingRight: 20,
          paddingLeft: 20,
          color: themeColor,
          numberOfLines: 1
        }
      }
    },
    menuItemStyleSM: {
      boxStyle: {
        viewStyle: {
          marginTop: 5,
          marginBottom: 5,
          paddingLeft: 12,
          paddingRight: 12,
          height: 42,
          alignItems: "center",
          cursor: "pointer",
          borderLeftWidth: 0
        }
      },
      iconStyle: {
        viewStyle: {
          paddingLeft: 12,
          paddingRight: 12,
          width: 48,
          height: 42,
          alignItems: "center",
          justifyContent: "center"
        }
      },
      titleStyle: {
        viewStyle: { flex: 1 },
        textStyle: {
          ...h9,
          paddingLeft: 12,
          paddingRight: 12,
          color: primaryColor,
          numberOfLines:1
        }
      }
    },
    selectedMenuItemStyleSM: {
      boxStyle: {
        viewStyle: {
          paddingLeft: 10,
          paddingRight: 12,
          height: 42,
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: lightThemeBg,
          borderLeftWidth: 2,
          borderLeftColor: themeColor
        }
      },
      iconStyle: {
        viewStyle: {
          paddingLeft: 12,
          paddingRight: 12,
          width: 48,
          height: 42,
          alignItems: "center",
          justifyContent: "center"
        }
      },
      titleStyle: {
        viewStyle: { flex: 1 },
        textStyle: {
          ...h1,
          paddingLeft: 12,
          paddingRight: 12,
          color: themeColor,
          numberOfLines:1
        }
      }
    }
  };

  return {
    drawerMenuStyle
  };
};
