import GalleryTab from "./GalleryTab";
import GalleryAll from "./GalleryAll";
import SelectImages from "./SelectImages";
import SelectAlbum from "./SelectAlbum";
import SharedData from "./SharedData";
import BePart from "./BePart";
import Album from "./Album";
import AlbumDetail from "./AlbumDetail";
import GalleryDetail from "./GalleryDetail";
import AlbumSlideShowComponent from "./AlbumSlideShowComponent";

export default {
  ...GalleryTab,
  ...GalleryAll,
  ...SelectImages,
  ...SelectAlbum,
  ...SharedData,
  ...Album,
  ...AlbumDetail,
  ...GalleryDetail,
  ...BePart,
  ...AlbumSlideShowComponent
};
