export default ({ resolveImage }) => {
  const docExtSMSprite = {
    uri: "/webImages/docExtIcons/SM/1_sprite.png",
    aviIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    cdrIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -54px",
      objectFit: "none"
    },
    cssIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -98px",
      objectFit: "none"
    },
    csvIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -142px",
      objectFit: "none"
    },
    dllIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -186px",
      objectFit: "none"
    },
    dmgIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -230px",
      objectFit: "none"
    },
    docIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -274px",
      objectFit: "none"
    },
    docxIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -318px",
      objectFit: "none"
    },
    epsIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -362px",
      objectFit: "none"
    },
    excelIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -406px",
      objectFit: "none"
    },
    flvIcon: {
      height: 34,
      width: 32,
      objectPosition: "-10px -450px",
      objectFit: "none"
    },
    gifIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -10px",
      objectFit: "none"
    },
    htmlIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -54px",
      objectFit: "none"
    },
    jpgIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -98px",
      objectFit: "none"
    },
    jsIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -142px",
      objectFit: "none"
    },
    movIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -186px",
      objectFit: "none"
    },
    mp3Icon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -230px",
      objectFit: "none"
    },
    mpgIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -274px",
      objectFit: "none"
    },
    odpIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -318px",
      objectFit: "none"
    },
    odsIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -362px",
      objectFit: "none"
    },
    odtIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -406px",
      objectFit: "none"
    },
    pdfIcon: {
      height: 34,
      width: 32,
      objectPosition: "-52px -450px",
      objectFit: "none"
    },
    phpIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -10px",
      objectFit: "none"
    },
    pngIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -54px",
      objectFit: "none"
    },
    pptIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -98px",
      objectFit: "none"
    },
    psdIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -142px",
      objectFit: "none"
    },
    rawIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -186px",
      objectFit: "none"
    },
    svgIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -230px",
      objectFit: "none"
    },
    txtIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -274px",
      objectFit: "none"
    },
    unknownIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -318px",
      objectFit: "none"
    },
    wmvIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -362px",
      objectFit: "none"
    },
    wordIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -406px",
      objectFit: "none"
    },
    xlsIcon: {
      height: 34,
      width: 32,
      objectPosition: "-94px -450px",
      objectFit: "none"
    },
    xlsxIcon: {
      height: 34,
      width: 32,
      objectPosition: "-136px -10px",
      objectFit: "none"
    },
    xmlIcon: {
      height: 34,
      width: 32,
      objectPosition: "-136px -54px",
      objectFit: "none"
    },
    zipIcon: {
      height: 34,
      width: 32,
      objectPosition: "-136px -98px",
      objectFit: "none"
    }
  };

  const docExtMDSprite = {
    uri: "/webImages/docExtIcons/MD/1_sprite.png",
    aviIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -62px",
      objectFit: "none"
    },
    cdrIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -166px",
      objectFit: "none"
    },
    cssIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -218px",
      objectFit: "none"
    },
    csvIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -270px",
      objectFit: "none"
    },
    dllIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -322px",
      objectFit: "none"
    },
    dmgIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -374px",
      objectFit: "none"
    },
    docIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -426px",
      objectFit: "none"
    },
    docxIcon: {
      height: 42,
      width: 37,
      objectPosition: "-10px -478px",
      objectFit: "none"
    },
    epsIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -10px",
      objectFit: "none"
    },
    excelIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -62px",
      objectFit: "none"
    },
    flvIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -114px",
      objectFit: "none"
    },
    gifIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -166px",
      objectFit: "none"
    },
    htmlIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -218px",
      objectFit: "none"
    },
    jpgIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -270px",
      objectFit: "none"
    },
    jsIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -322px",
      objectFit: "none"
    },
    movIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -374px",
      objectFit: "none"
    },
    mp3Icon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -426px",
      objectFit: "none"
    },
    mpgIcon: {
      height: 42,
      width: 37,
      objectPosition: "-57px -478px",
      objectFit: "none"
    },
    odpIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -10px",
      objectFit: "none"
    },
    odsIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -162px",
      objectFit: "none"
    },
    odtIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -114px",
      objectFit: "none"
    },
    pdfIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -166px",
      objectFit: "none"
    },
    phpIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -218px",
      objectFit: "none"
    },
    pngIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -270px",
      objectFit: "none"
    },
    pptIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -322px",
      objectFit: "none"
    },
    psdIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -374px",
      objectFit: "none"
    },
    rawIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -426px",
      objectFit: "none"
    },
    svgIcon: {
      height: 42,
      width: 37,
      objectPosition: "-105px -478px",
      objectFit: "none"
    },
    txtIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -10px",
      objectFit: "none"
    },
    unknownIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -62px",
      objectFit: "none"
    },
    wmvIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -114px",
      objectFit: "none"
    },
    wordIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -166px",
      objectFit: "none"
    },
    xlsIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -218px",
      objectFit: "none"
    },
    xlsxIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -270px",
      objectFit: "none"
    },
    xmlIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -322px",
      objectFit: "none"
    },
    zipIcon: {
      height: 42,
      width: 37,
      objectPosition: "-151px -374px",
      objectFit: "none"
    }
  };

  const aviIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.aviIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.aviIcon
        }
      }
    });
  const cdrIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.cdrIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.cdrIcon
        }
      }
    });
  const cssIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.cssIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.cssIcon
        }
      }
    });
  const dllIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.dllIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.dllIcon
        }
      }
    });
  const csvIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.csvIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.csvIcon
        }
      }
    });
  const dmgIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.dmgIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.dmgIcon
        }
      }
    });
  const docIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.docIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.docIcon
        }
      }
    });
  const docxIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.docxIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.docxIcon
        }
      }
    });
  const epsIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.epsIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.epsIcon
        }
      }
    });
  const excelIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.excelIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.excelIcon
        }
      }
    });
  const flvIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.flvIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.flvIcon
        }
      }
    });
  const gifIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.gifIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.gifIcon
        }
      }
    });
  const htmlIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.htmlIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.htmlIcon
        }
      }
    });
  const jpgIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.jpgIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.jpgIcon
        }
      }
    });
  const jsIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.jsIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.jsIcon
        }
      }
    });
  const movIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.movIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.movIcon
        }
      }
    });
  const mp3Icon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.mp3Icon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.mp3Icon
        }
      }
    });
  const mpgIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.mpgIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.mpgIcon
        }
      }
    });
  const odpIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.odpIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.odpIcon
        }
      }
    });
  const odsIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.odsIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.odsIcon
        }
      }
    });
  const odtIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.odsIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.odtIcon
        }
      }
    });
  const pdfIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.pdfIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.pdfIcon
        }
      }
    });
  const phpIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.phpIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.phpIcon
        }
      }
    });
  const pngIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.pngIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.pngIcon
        }
      }
    });
  const pptIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.pptIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.pptIcon
        }
      }
    });
  const psdIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.psdIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.psdIcon
        }
      }
    });
  const rawIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.rawIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.rawIcon
        }
      }
    });
  const svgIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.svgIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.svgIcon
        }
      }
    });
  const txtIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.txtIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.txtIcon
        }
      }
    });
  const unknownIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.unknownIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.unknownIcon
        }
      }
    });
  const wmvIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.wmvIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.wmvIcon
        }
      }
    });
  const wordIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.wordIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.wordIcon
        }
      }
    });
  const xlsIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.xlsIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.xlsIcon
        }
      }
    });
  const xlsxIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.xlsxIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.xlsxIcon
        }
      }
    });
  const xmlIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.xmlIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.xmlIcon
        }
      }
    });
  const zipIcon = () =>
    resolveImage({
      SM: {
        uri: docExtSMSprite.uri,
        spriteProps: {
          ...docExtSMSprite.zipIcon
        }
      },
      MD: {
        uri: docExtMDSprite.uri,
        spriteProps: {
          ...docExtMDSprite.zipIcon
        }
      }
    });

  return {
    aviIcon,
    cdrIcon,
    cssIcon,
    csvIcon,
    dllIcon,
    dmgIcon,
    docIcon,
    docxIcon,
    epsIcon,
    excelIcon,
    flvIcon,
    gifIcon,
    htmlIcon,
    jpgIcon,
    jsIcon,
    movIcon,
    mp3Icon,
    mpgIcon,
    odpIcon,
    odsIcon,
    odtIcon,
    pdfIcon,
    phpIcon,
    pngIcon,
    pptIcon,
    psdIcon,
    rawIcon,
    svgIcon,
    txtIcon,
    unknownIcon,
    wmvIcon,
    wordIcon,
    xlsIcon,
    xlsxIcon,
    xmlIcon,
    zipIcon
  };
};
