import React from "react";
import { CollaborationCardSM, CollaborationHeaderSM, CollaborationCardMD } from "./CollaborationCards";
import { collaborationQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

export default {
  ManageCollaborationSM: {
    component: "table",
    dataObject: "Collaboration",
    title: () => I18N.t("manageCollaboration"),
    selectable: false,
    ...collaborationQuery,
    bodyProps: {
      DataRow: CollaborationCardSM
    },
    headerActions: [
      {
        type: "multiActions",
        selectionRequired: false,
        action: ["leaveAlbum", "leaveSet", "makePrivate"]
      }
    ],
    renderHeader: () => <CollaborationHeaderSM />
  },
  ManageCollaborationMD: {
    component: "table",
    dataObject: "Collaboration",
    title: () => I18N.t("manageCollaboration"),
    ...collaborationQuery,
    Component: CollaborationCardMD,
    headerActions: [
      {
        type: "headerAction",
        image: "leaveCollectionIcon",
        selectionRequired: false,
        action: "leaveAlbum",
        visible: ({ link }) => {
          let { props: { params: { collection = {} } } = {} } = link || {};
          if (collection && collection.type === "album" && !collection.owner) {
            return true;
          }
        }
      },
      {
        type: "headerAction",
        image: "leaveCollectionIcon",
        selectionRequired: false,
        action: "leaveSet",
        visible: ({ link }) => {
          let { props: { params: { collection = {} } } = {} } = link || {};
          if (collection && collection.type === "set" && !collection.owner) {
            return true;
          }
        }
      },
      {
        type: "headerAction",
        image: "makePrivateIcon",
        selectionRequired: false,
        action: "makePrivate",
        visible: ({ link }) => {
          let { props: { params: { collection = {} } } = {} } = link || {};
          if (collection && collection.owner) {
            return true;
          }
        }
      }
    ]
  }
};
