import axios from "axios";
import { Platform } from "react-components";

export default ({
  uuid,
  showMessage,
  showError,
  uploadUrl,
  /* The authenticate url is passed because a diffrent authentication path was
 needed in the MSH Calcified App - Ankush Kalra, 29 March 2019 */
  fetchUrl,
  postUrl,
  firebaseAuth,
  firebase,
  firebaseUploadFile,
  nativeViews,
  mappings,
  preFetch,
  urls,
  normalizeUser,
  deviceId,
  I18N,
  brandName
}) => {
  let token = void 0;
  let generateOtpConfirmResult = void 0;
  let notificationToken = void 0;
  let appVerifier = void 0;
  let invokeInProress = void 0;

  const globalProps = {
    timezoneOffset: -330,
    platform: "web"
  };

  const reformatmobile = mobile => {
    if (mobile.length === 10) {
      return `+91${mobile}`;
    } else if (mobile.length === 11) {
      return `+55${mobile}`;
    } else {
      return mobile;
    }
  };

  const fetch = async ({ uri, url, uid, props, user }) => {
    // console.log("======================: ", inspect({ uri, url, uid, props, user },false, null, true));
    let headers = {};
    if (uri && uri.props && uri.props.query) {
      let { query } = uri.props;
      query = { ...query };
      if (nativeViews && query.view && nativeViews[query.view]) {
        return await nativeViews[query.view]({ uri, props, uid, query, user });
      }
      let { addOnFilter, ...restQuery } = query;
      if (addOnFilter) {
        restQuery["filter"] = addOnFilter;
      }
      props = {
        query: restQuery,
        context: { brand: brandName }
      };
      headers.token = token;
      uri = "/fetch";
    }

    let _fetchUrl = `${url || fetchUrl}${uri}`;
    if (_fetchUrl.indexOf("/fetch") >= 0 || _fetchUrl.indexOf("/post") >= 0) {
      throw new Error(`Invalid fetch url [${_fetchUrl}]`);
    }
    return axios
      .post(_fetchUrl, props, { headers })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        const originalErrorMessage = e.message;
        let err = void 0;
        if (originalErrorMessage === "Network Error") {
          const err = new Error(`Unable to connect with server[${url || fetchUrl}]`);
          err.code = "no_internet";
        } else if (e.response && e.response.data) {
          let responseData = e.response.data;
          if (responseData.error) {
            err = new Error(responseData.error.message);
            err.code = responseData.error.code || responseData.error.status_code;
          } else if (responseData.message) {
            err = new Error(responseData.message);
            err.code = responseData.code || responseData.status_code;
          } else {
            err = new Error(JSON.stringify(responseData));
          }
        } else {
          err = new Error(originalErrorMessage);
        }
        throw err;
      });
  };

  const getUserSpace = async ({ user }) => {
    return await nativeViews["userSpace"]({ user });
  };

  // eslint-disable-next-line
  const getInvokeUriProps = props => {
    let { selectedIds, allPageSelected = false, uri = {} } = props;
    const { props: { query, model, args } = {} } = uri;
    let { limit, skip, ...restQueryParams } = query || {};
    if (!allPageSelected && selectedIds && selectedIds.length && restQueryParams) {
      let paramsAddOnFilter = restQueryParams.addOnFilter;
      restQueryParams.addOnFilter = { ...paramsAddOnFilter } || {};
      restQueryParams.addOnFilter["$and"] = restQueryParams.addOnFilter["$and"]
        ? [...restQueryParams.addOnFilter["$and"]]
        : [];
      restQueryParams.addOnFilter["$and"].push({ _id: { $in: selectedIds } });
    }
    const uriProps = {
      ...globalProps,
      token,
      ...args,
      paramValue: {
        _selectedIds_: allPageSelected ? [] : selectedIds,
        _allPageSelected: allPageSelected,
        _query: restQueryParams,
        _model: model
      }
    };
    return uriProps;
  };

  const invoke = async props => {
    let headers = {};
    let { service, allowParallelInvoke, ...restProps } = props;
    if (invokeInProress && !allowParallelInvoke) {
      const error = Error(I18N.t("requestInProgress"));
      error.code = "invoke_already_in_progress";
      throw error;
    }

    if (typeof service === "function") {
      service = service(
        {
          ...restProps
        },
        { postUri: "/post", urls }
      );
    }
    service = service || {};
    let { url, uri, uriProps } = service;
    uriProps = { ...uriProps, context: { token, brand: brandName } };
    // console.log("{ url, uri, uriProps }: ", { url, uri, uriProps });
    // return Promise.resolve();
    if (!allowParallelInvoke) {
      invokeInProress = true;
    }
    url = url() || `${postUrl}${uri}`;
    if (preFetch) {
      const prefetchResult = await preFetch({
        url,
        uri,
        props: uriProps
      });
      url = prefetchResult.url;
      uriProps = prefetchResult.props;
      headers = prefetchResult.headers;
    }

    if (url.indexOf("/fetch") >= 0 || url.indexOf("/post") >= 0) {
      throw new Error(`Invalid invoke url [${url}]`);
    }

    return axios
      .post(url, uriProps, { headers })
      .then(res => {
        if (!allowParallelInvoke) {
          invokeInProress = false;
        }
        return res.data;
      })
      .catch((e, resp) => {
        if (!allowParallelInvoke) {
          invokeInProress = false;
        }
        const originalErrorMessage = e.message;
        let err = void 0;
        if (originalErrorMessage === "Network Error") {
          err = new Error(`Unable to connect with server[${url}]`);
          err.code = "no_internet";
        } else if (e.response && e.response.data) {
          let responseData = e.response.data;
          if (responseData.error) {
            err = new Error(responseData.error.message);
            err.code = responseData.error.code || responseData.error.status_code;
          } else if (responseData.message) {
            err = new Error(responseData.message);
            err.code = responseData.code || responseData.status_code;
          } else {
            err = new Error(JSON.stringify(responseData));
          }
        } else {
          err = new Error(originalErrorMessage);
        }
        throw err;
      });
  };

  const generateOtpUsingFirebase = async props => {
    let { mobile } = props;
    mobile = reformatmobile(mobile);
    return firebase()
      .auth()
      .signInWithPhoneNumber(mobile, appVerifier)
      .then(confirmResult => {
        generateOtpConfirmResult = confirmResult;
        return { message: "Code has been sent!" };
      })
      .catch(error => {
        // console.warn(" ::: signInWithPhoneNumber error ::: ", error);
        throw error;
      });
  };

  const generateOtp = async props => {
    // TODO Required for master login currently, need to change when validate password field on client side - sachin
    if (firebaseAuth) {
      const result = await generateOtpUsingFirebase(props);
      return result;
    } else {
      return Promise.reject("generateOtp not supported without firebase");
    }
  };

  const validateOtpUsingFirebase = async props => {
    let { otp } = props;
    console.log("otpotpotp: ", otp);
    return (
      generateOtpConfirmResult &&
      generateOtpConfirmResult
        .confirm(otp)
        .then(user => {
          return firebase()
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(async idToken => {
              return {
                token: idToken,
                user: await normalizeUser({ user: firebase().auth().currentUser, fromLogin: true })
              };
            });
        })
        .catch(error => {
          // console.warn(" ::: validateOtpUsingFirebase error ::: ", error);
          throw error;
        })
    );
  };

  const validateOtp = async props => {
    // TODO Required for master login currently, need to change when validate password field on client side - sachin
    if (firebaseAuth) {
      const user = await validateOtpUsingFirebase(props);
      token = user.token;
      return user;
    } else {
      return Promise.reject("validateOtp not supported without firebase");
    }
  };

  const resetPassword = ({ email, otp, password, confirmPassword }) => {
    return fetch({
      uri: "/resetPassword",
      props: {
        service: "resetPassword",
        email,
        otp,
        password,
        confirmPassword
      }
    })
      .then(user => {
        user = user && user.result;
        if (!user) {
          throw new Error("Password/Confirm Password Invalid");
        }
        token = user.token;
        return user;
      })
      .catch(e => {
        throw e;
      });
  };

  const verifyOtp = ({ email, otp }) => {
    return fetch({
      uri: {
        props: {
          service: "isOtpMatched",
          email,
          otp
        }
      }
    }).then(result => {
      let { match } = result || {};
      if (!match) {
        throw new Error("Otp not matched,Please try again");
      }
      return result;
    });
  };
  var populateGoogleAddressLevels = result => {
    var addressLevelsToManage = [
      "country",
      "administrative_area_level_1",
      "administrative_area_level_2",
      "locality",
      "sublocality",
      "sublocality_level_1"
    ];
    var address_components = result && result.address_components;
    if (!address_components) {
      return;
    }
    var level = 0;
    var address_levels = void 0;
    for (var j = address_components.length - 1; j >= 0; j--) {
      var locationValue = address_components[j];
      var levelValue = addressLevelsToManage[level];
      if (locationValue.types && locationValue.types.indexOf(levelValue) !== -1) {
        address_levels = address_levels || {};
        address_levels[levelValue] = locationValue.long_name;
        level += 1;
      }
      if (level === addressLevelsToManage.length) {
        break;
      }
    }
    return address_levels;
  };

  const getGooglePlaceDetail = (value, { sessionToken } = {}) => {
    let { place_id } = value;
    let path = `/maps/api/place/details/json?placeid=${place_id}&sessiontoken=${sessionToken}&key=AIzaSyBVpN48FtFrg5-iJ87VoJFSeGAQ6dRImsU`;
    return fetch({
      uri: {
        props: {
          service: "_getGooglePlace",
          query: { addOnFilter: { path } }
        }
      }
    })
      .then(({ result = {} }) => {
        result = result.result || {};
        if (result) {
          result.address_levels = populateGoogleAddressLevels(result);
        }
        return result;
      })
      .catch(e => {
        showError(e, 2000);
        throw e;
      });
  };

  const forgotPassword = ({ email }) => {
    return fetch({
      uri: "/forgotPassword",
      props: {
        service: "forgotPasswordFn",
        email
      }
    })
      .then(user => {
        user = user && user.result;
        if (!user) {
          const e = new Error(`Email invalid.`);
          throw e;
        }
        token = user.token;
        return user;
      })
      .catch(e => {
        throw e;
      });
  };

  const googleLogin = ({ code }) => {
    return fetch({
      uri: {
        props: {
          service: "_googleLogin",
          code: code
        }
      }
    })
      .then(({ result }) => {
        if (!result) {
          throw new Error(" Email not found");
        }
        if (result.status === "not registerd") {
          throw new Error("Your email address is not registered");
        }
        token = result.token;
        return result;
      })
      .catch(e => {
        if (e.message.indexOf("<!DOCTYPE html>") === 0) {
          e = new Error("Error in Google Login");
        }
        throw e;
      });
  };

  const googleCalendarPermission = ({ code }) => {
    return fetch({
      uri: {
        props: {
          service: "_calendarAuth",
          code,
          model: "GoogleCalendarAccessTokenModel"
        }
      }
    })
      .then(({ result }) => {
        if (!result) {
          throw new Error("Calendar not Found");
        }
        return result;
      })
      .catch(e => {
        if (e.message.indexOf("<!DOCTYPE html>") === 0) {
          e = new Error("Error in Google Calender Permission");
        }
        throw e;
      });
  };

  const loadNotificationToken = ({ notification_token }) => {
    notificationToken = notification_token;
  };

  //todo will handle later - currently giving issue as not supported on cqrs server -rohit bansal - 02-04-19
  const registerNotificationToken = ({ type }) => {
    if (!notificationToken) {
      return;
    }
    // return fetch({
    // uri: {
    // props: {
    // service: "_notificationRegistration",
    // device: notificationToken,
    // type
    // }
    // }
    // }).catch(err => {
    // showError(err, 2000);
    // });
  };

  const profilePictureAfterUpload = ({ task }) => {
    return task.catch(err => showMessage(I18N.t("failedToUpdateProfilePicture")));
  };
  const issuesPictureAfterUpload = ({ task, storageRef }) => {
    return task.then(_ => storageRef.getDownloadURL()).catch(err => showMessage(I18N.t("failedToUploadIssueFile")));
  };

  const issuesPictureAfterUploadWeb = ({ task }) => {
    return task
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(url => {
        return url;
      })
      .catch(err => showMessage(I18N.t("failedToUploadIssueFile")));
  };

  const firebaseUpload = async ({ file, path, customMetadata, onUploadStart, uploadType }) => {
    var storageRef = firebase().storage().ref(path);
    let task = void 0;
    if (Platform.OS === "web") {
      task = storageRef.put(file, { customMetadata });
    } else {
      task = storageRef.putFile(file, { customMetadata });
    }

    if (uploadType === "profile_pic") {
      return profilePictureAfterUpload({ task });
    }
    if (uploadType === "logs" || uploadType === "issues_file") {
      if (Platform.OS === "web") {
        return issuesPictureAfterUploadWeb({ task });
      } else {
        return issuesPictureAfterUpload({ task, storageRef });
      }
    }
    onUploadStart && onUploadStart({ task });
  };

  const initiateFirebaseUpload = async (file, options = {}) => {
    const { path, metadata, customMetadata, onUploadStart, uploadType, throwError } = options;
    return firebaseUpload({
      file,
      path,
      metadata,
      customMetadata,
      onUploadStart,
      uploadType
    }).catch(e => {
      showMessage("Error in uploading" + e.message);
      if (throwError) throw e;
    });
  };

  const loadWebMetadata = ({ file }) => {
    return new Promise((resolve, reject) => {
      const { name, lastModified, size } = file;
      let metadata = { name, lastModified, size };
      resolve({ metadata });
    });
  };

  const uploadRequest = async ({
    file: files,
    uploadType,
    uploadSource,
    secureType = "default",
    skipUploadToken,
    encrypted, // tell whether to encrypt or not(mainly to distinguish older builds wihout encryption)
    encryptRequired // used for encrypting original file later on server(original file is upload initially and encrypted later)
  }) => {
    console.log("555555555555555555555");
    if (!Array.isArray(files)) {
      files = [files];
    }
    let resources = [];

    for (let index = 0; index < files.length; index++) {
      let file = files[index];
      let metadata, aggregateId;
      let fileUri = void 0;

      if (Platform.OS === "web") {
        let fileData = await loadWebMetadata({ file });
        metadata = fileData.metadata;
        aggregateId = uuid();
        uploadSource = uploadSource || "web";
      } else {
        metadata = file.metadata;
        fileUri = file.fileUri;
        aggregateId = file.aggregateId;
      }
      let resource = { aggregateId, fileUri, deviceId, uploadSource, secureType, encrypted, encryptRequired };

      if (metadata.name) {
        resource.resource_name = metadata.name;
      }
      if (metadata.lastModified) {
        resource.resource_lastModified = metadata.lastModified;
      }
      if (metadata.size) {
        resource.resource_size = metadata.size;
      }
      if (metadata.artist) {
        resource.resource_artist = metadata.artist;
      }
      if (metadata.album) {
        resource.resource_album = metadata.album;
      }
      if (metadata.duration) {
        resource.resource_duration = metadata.duration;
      }
      if (metadata.genre) {
        resource.resource_genre = metadata.genre;
      }
      if (metadata.title) {
        resource.resource_title = metadata.title;
      }
      if (metadata.location) {
        resource.location = metadata.location;
      }
      resources.push(resource);
    }

    let uploadRequestUri = "/uploadRequest";
    if (uploadType === "profile_pic") {
      uploadRequestUri = "/uploadProfilePic";
      resources = resources[0];
    }
    if (uploadType === "contact_pic") {
      uploadRequestUri = "/uploadContactPic";
      resources = resources[0];
      resources["photo_id"] = uuid();
    }

    const {
      url: _uploadUrl,
      props: uploadProps,
      headers
    } = await preFetch({
      url: `${uploadUrl()}${uploadRequestUri}`,
      uri: uploadRequestUri,
      props: { data: resources, skipUploadToken }
    });

    if (uploadType === "issues_file") {
      resources = resources[0];
      return { uploadProps, resource: resources };
    }

    console.log("uploadRequest: ", _uploadUrl, uploadProps, headers);
    return axios
      .post(_uploadUrl, uploadProps, { headers })
      .then(res => {
        invokeInProress = false;
        return res.data;
      })
      .catch((e, resp) => {
        invokeInProress = false;
        const originalErrorMessage = e.message;
        let err = void 0;
        if (originalErrorMessage === "Network Error") {
          let err = new Error(originalErrorMessage);
          err.code = "no_internet";
        } else if (e.response && e.response.data) {
          let responseData = e.response.data;
          if (responseData.error) {
            err = new Error(responseData.error.message);
            err.code = responseData.error.code || responseData.error.status_code;
          } else if (responseData.message) {
            err = new Error(responseData.message);
            err.code = responseData.code || responseData.status_code;
          } else {
            err = new Error(JSON.stringify(responseData));
          }
        } else {
          err = new Error(originalErrorMessage);
        }
        throw err;
      });
  };

  const upload = async (file, options = {}) => {
    console.log("firebaseAuth, firebaseUploadFile", firebaseAuth, firebaseUploadFile);
    let { throwError } = options;
    if (firebaseAuth && firebaseUploadFile) {
      try {
        return initiateFirebaseUpload(file, options);
      } catch (err) {
        showError(err, 2000);
        if (throwError) throw err;
      }
    } else {
      let { multiPart = true, uri = "/upload" } = options;
      if (multiPart) {
        let formData = new FormData();
        formData.append("", file);
        formData.append("token", token);
        formData.append("timezoneOffset", globalProps.timezoneOffset);
        formData.append("platform", globalProps.platform);

        console.log("upload: ", uploadUrl, uri, formData);
        return axios
          .post(`${uploadUrl}${uri}`, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          })
          .then(res => {
            let result = res.data;
            result = result && result.length ? result[0] : void 0;
            return result;
          })
          .catch(e => {
            showError(e, 2000);
          });
      } else {
        showError(new Error("Upload not supported without multiPart"), 2000);
      }
    }
  };

  const getImageUrl = params => {
    if (typeof params === "string") {
      return params;
    }
    const { url, uri } = params;
    if (uri) {
      return uri;
    } else if (url) {
      return url;
    } else {
      return void 0;
    }
  };

  const removeToken = () => {
    token = void 0;
    notificationToken = void 0;
  };

  const getNotificationToken = () => {
    return notificationToken;
  };

  return {
    fetch,
    invoke,
    removeToken,
    googleLogin,
    googleCalendarPermission,
    upload,
    getImageUrl,
    loadNotificationToken,
    registerNotificationToken,
    resetPassword,
    forgotPassword,
    verifyOtp,
    getGooglePlaceDetail,
    generateOtp,
    validateOtp,
    uploadRequest,
    getUserSpace,
    urls,
    getNotificationToken
  };
};
