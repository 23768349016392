import React from "react";
import moment from "moment";

const modalStyle = {
  modalSM: {
    position: "screenBottom",
    height: 250,
    autoHide: true
  },
  modalMD: {
    width: 300,
    height: 200,
    position: "bottom",
    autoHide: true
  }
};

export default ({ Box, SelectDate, Modal, resolveMQ }) => {
  class DateCalendar extends React.Component {
    state = {};

    onChange = date => {
      let { onDateChange } = this.props;
      if (date) {
        date = moment(date)
          .startOf("day")
          .toDate();
      }
      this.setState({ date, showModal: false });
      onDateChange && onDateChange(date);
    };

    openModal = () => {
      this.setState({ showModal: true });
    };

    closeModal = () => {
      this.setState({ showModal: false });
    };

    render() {
      let { activeMQ, label, labelTextStyle, renderValue, ...restProps } = this.props;
      let { date, showModal } = this.state;
      let { modal } = resolveMQ(modalStyle, ["modal"], activeMQ);
      return (
        <Box
          viewStyle={{ marginRight: 12, flex: 1 }}
          render={[
            label && { ...labelTextStyle, text: label },
            {
              onClick: this.openModal,
              getRef: _ => (this.ref = _),
              direction: "row",
              ...renderValue(date)
            },
            showModal && (
              <Modal {...modal} parentRef={this.ref} isOpen={true} onClose={this.closeModal}>
                <SelectDate {...restProps} onChange={this.onChange} />
              </Modal>
            )
          ]}
        />
      );
    }
  }
  return DateCalendar;
};
