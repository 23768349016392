import CollaborationActions from "../screens/collaboration/CollaborationActions";

export default {
  Collaboration: {
    fields: {
      name: { field: "name", type: "text" },
      type: { field: "type", type: "text" },
      memberType: { field: "memberType", type: "text" }
    },
    actions: {
      ...CollaborationActions
    },
    updateProps: {
      model: "Collaboration"
    }
  }
};
