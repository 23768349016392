import React from "react";
import {
  Box,
  Modal,
  withContext,
  showMessage,
  theme,
  I18N,
  getImage,
  LoginEditor as Editor,
  LoginButton as Button,
  LoginContainer as Container,
  Platform,
  Connect,
  StatusBar as LoadingIndicator,
  getFsTrackId,
  FSLoginApi,
  SmsReader,
  ResendOtp,
  resolveMQ,
  View,
  brandName,
  RenderNoData,
  getErrorMessage
} from "../../../FsCloudComponent";

import axios from "axios";

import ShareLogoComponent from "../share/ShareLogoComponent";

let { fonts, colors, bgs, shadows } = theme;
let { h9, h16, h20, h16_l } = fonts;
let { themeColor, primaryColor, highlightColor, errorColor } = colors;
let { veryLightPink, primaryBg2 } = bgs;
const { startListener, removeListener } = SmsReader;

const blockInfo = {
  "confirm-user": {
    field: "mobile",
    icon: getImage("telephoneIcon"),
    inputStyle: {},
    title: "blockDevice",
    primaryText: "loginSecondaryText",
    placeholder: "mobilePlaceholder",
    submitText: "continue",
    cancelText: "cancel"
  },
  "block-user": {
    fields: [{ field: "otp", label: "otpFieldLabel", placeholder: "otpPlaceholder" }],
    title: "blockDevice",
    primaryText: "blockOtp",
    submitText: "continue",
    cancelText: "cancel"
  }
};

const headerStyleSM = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      height: 60,
      paddingLeft: 4,
      paddingRight: 12,
      alignItems: "center",
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowSM
    }
  },
  titleStyle: {
    width: "1fr",
    viewStyle: {
      height: 32,
      paddingLeft: 12,
      paddingRight: 12,
      justifyContent: "center",
      whiteSpace: "pre"
    },
    textStyle: {
      ...h16,
      color: themeColor
    }
  }
};

const headerStyleMD = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      height: 72,
      paddingLeft: 18,
      paddingRight: 18,
      alignItems: "center",
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowMD
    }
  },
  titleStyle: {
    width: "1fr",
    viewStyle: {
      height: 44,
      paddingLeft: 18,
      paddingRight: 18,
      justifyContent: "center",
      whiteSpace: "pre"
    },
    textStyle: {
      ...h16_l,
      color: primaryColor
    }
  }
};

const resetPinViewStyleSM = {
  flex: 1,
  paddingLeft: 27,
  paddingRight: 27,
  paddingTop: 18
};

const resetPinViewStyleMD = {
  ...resetPinViewStyleSM,
  "align-self": "center", //MD
  minWidth: 450
};

const linkExpiredStyle = {
  headerContainerStyleMD: {
    viewStyle: {
      height: 72,
      alignItems: "center",
      backgroundColor: highlightColor,
      padding: 15,
      position: "fixed",
      zIndex: 1,
      top: 0,
      right: 0,
      left: 0,
      ...shadows.imageViewerHeaderShadowMD
    }
  },
  headerContainerStyleSM: {
    viewStyle: {
      alignItems: "center",
      height: 60,
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowSM
    }
  },
  titleStyleMD: {
    viewStyle: {
      paddingLeft: 15
    },
    textStyle: {
      color: themeColor,
      ...h16
    }
  },
  emptyBoxStyleMD: {
    viewStyle: { height: 72 }
  },
  titleStyleSM: {
    viewStyle: {
      paddingLeft: 12
    },
    textStyle: {
      color: themeColor,
      ...h16
    }
  },
  logoStyleSM: {
    viewStyle: {
      paddingLeft: 8,
      paddingRight: 8
    },
    icon: "headerLogo"
  },
  logoStyleMD: {
    icon: "headerLogo"
  }
};

const sendSMS = async data => {
  let { sendSMS } = FSLoginApi;
  if (!sendSMS) {
    return;
  }
  let params = {
    mobile: data.mobile
  };
  if (Platform.OS === "android") {
    params.smshash = await SmsReader.getHash();
  }
  return await sendSMS(params);
};

const sendBlockMailToUser = async props => {
  try {
    let { invoke, urls, deviceId } = props;
    await invoke({
      service: {
        url: urls["requestLinkToBlockRestAPI"],
        uriProps: {
          data: { deviceId }
        }
      },
      allowParallelInvoke: true
    });
  } catch (err) {
    // do nothing because no need to raise error if main not send
  }
};

const resendPincode = async transaction_id => {
  let { resendPincode } = FSLoginApi;
  if (!resendPincode) {
    return;
  }
  await resendPincode({ transaction_id });
  showMessage && showMessage(I18N.t("codeSentMessage"), 2000);
};

const sendBlockOtp = async (data, transaction_id, deviceId, props) => {
  let { invoke, urls } = props;
  let res = await invoke({
    service: {
      url: urls["blockDeviceRestAPI"],
      uriProps: {
        data: {
          otp: data.otp,
          deviceId: deviceId,
          transaction_id,
          fsTrackId: getFsTrackId()
        }
      }
    },
    allowParallelInvoke: true
  });
  if (res && res.error) {
    throw res.error;
  }
  showMessage && showMessage(I18N.t("blockDeviceMessage"), 2000);
};

class AutoSMSReadWrapper extends React.Component {
  componentDidMount() {
    if (Platform.OS === "android") {
      this.startListener();
    }
  }

  onMessageReceived = message => {
    removeListener();
    if (message.includes("Timeout Error.")) {
      this.startListener();
    } else {
      let code = message.match(/\d+/) && message.match(/\d+/)[0];
      if (code) {
        let { dataProps: { data, setValue } = {}, field } = this.props;
        setValue && setValue({ data, field, value: code });
      }
    }
  };

  startListener = async () => {
    try {
      await startListener(this.onMessageReceived);
    } catch (err) {
      this.props.onError(err, "viewError");
    }
  };

  componentWillUnmount() {
    if (Platform.OS === "android") {
      removeListener();
    }
  }

  render() {
    return this.props.children;
  }
}

class Login extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.clickEvents = {
      "confirm-user": this.onBlockClick,
      "block-user": this.onOtpConfirmClick
    };
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  onError = (err, errorField) => {
    let message = getErrorMessage(err);
    this._setState({ [errorField || "error"]: message, loading: false });
  };

  addLink = link => {
    let { addUri, getPath } = this.props;
    addUri && getPath && addUri(getPath(), link);
  };

  replaceLink = (link, uriToReplace) => {
    let { deleteUri, replaceUri, getPath } = this.props;
    let path = getPath && getPath();
    if (path) {
      if (Platform.OS === "web") {
        deleteUri && deleteUri(path, { uri: uriToReplace });
        setTimeout(_ => {
          this.addLink(link);
        }, 0);
      } else if (replaceUri) {
        let indexOf = path.indexOf(uriToReplace);
        if (indexOf >= 0) {
          link.uri = path.substring(0, indexOf) + link.uri;
        }
        replaceUri && replaceUri(link);
      }
    }
  };

  deleteLink = () => {
    let { getPath, deleteUri, link } = this.props;
    let path = getPath && getPath();
    path && deleteUri && deleteUri(path, link);
  };

  onBlockClick = async () => {
    let { data } = this.dataProps;
    let { deviceId } = this.props;
    let result = await sendSMS(data);
    sendBlockMailToUser(this.props);
    let link = {
      uri: "/otp-block-device",
      props: {
        params: { transaction_id: result.transaction_id, deviceId }
      }
    };
    this.addLink(link);
  };

  onOtpConfirmClick = async () => {
    let { data } = this.dataProps;
    try {
      let { link: { props: { params: { transaction_id, deviceId } = {} } = {} } = {} } = this.props;
      await sendBlockOtp(data, transaction_id, deviceId, this.props);
      this.deleteLink();
    } catch (err) {
      this.onError(err, "viewError");
    }
  };

  resendOtp = async () => {
    try {
      if (this.isResend) {
        return;
      }
      this.isResend = true;
      let { link: { props: { params: { transaction_id } = {} } = {} } = {} } = this.props;
      await resendPincode(transaction_id);
      this.isResend = false;
    } catch (err) {
      this.onError(err, "viewError");
    }
  };

  onSubmitClick = async e => {
    try {
      let { updatableData } = this.dataProps;
      let validateData = updatableData && updatableData.validateData;
      if (validateData) {
        let validations = await validateData();
        if (validations && Object.keys(validations).length) {
          return;
        }
      }
      let { source } = this.props;
      this._setState({ loading: true, error: void 0, viewError: void 0 });
      let onSubmit = this.clickEvents[source];
      onSubmit && (await onSubmit(e));
      this._setState({ loading: false });
    } catch (err) {
      this.onError(err);
    }
  };

  render() {
    let { source, minHeight } = this.props;
    let { submitText, primaryText, field, placeholder, icon, fields } = blockInfo[source] || {};
    let contentContainerStyle = { flex: 1, minHeight: minHeight || 400 };

    return (
      <Container
        key={source}
        scrollViewStyle={{
          style: { flex: 1 },
          contentContainerStyle: Platform.OS === "web" ? {} : contentContainerStyle
        }}
      >
        {props => {
          this.dataProps = props;
          let editorProps = {
            ...props,
            state: this.state,
            setState: this._setState,
            onClick: this.onSubmitClick
          };

          let component = (
            <>
              {this.state.showloader ? <LoadingIndicator /> : null}
              <Box
                viewStyle={{ ...(Platform.OS !== "web" ? { flex: 1 } : contentContainerStyle) }}
                render={[
                  {
                    viewStyle: {
                      flex: 1,
                      paddingLeft: 27,
                      paddingRight: 27,
                      paddingTop: 18
                    },
                    render: [
                      {
                        image: getImage("shareNoDataIcon"),
                        viewStyle: {
                          alignItems: "center",
                          paddingTop: 18
                        },
                        imageProps: {
                          resizeMode: "contain"
                        }
                      },
                      {
                        viewStyle: {
                          paddingTop: 18,
                          paddingBottom: 18,
                          alignSelf: "center"
                        },
                        textStyle: {
                          ...h16,
                          color: primaryColor
                        },
                        text: I18N.t(primaryText)
                      },
                      field
                        ? {
                            viewStyle: {
                              marginBottom: 18
                            },
                            render: <Editor {...editorProps} field={field} icon={icon} placeholder={placeholder} />
                          }
                        : fields &&
                          fields.map((fieldInfo, index) => {
                            return {
                              viewStyle: {
                                marginBottom: 18
                              },
                              render: [
                                Platform.OS !== "web" &&
                                  fieldInfo.label && {
                                    textStyle: {
                                      ...h16,
                                      color: primaryColor
                                    },
                                    text: I18N.t(fieldInfo.label)
                                  },
                                <Editor
                                  {...editorProps}
                                  inputProps={{
                                    autoFocus: index === 0
                                  }}
                                  field={fieldInfo.field}
                                  placeholder={fieldInfo.placeholder}
                                />
                              ]
                            };
                          }),
                      source === "block-user" && {
                        viewStyle: {
                          marginBottom: 18,
                          alignItems: "center"
                        },
                        render: <ResendOtp onClick={this.resendOtp} time={30} />
                      },
                      this.state.viewError && {
                        viewStyle: {
                          marginBottom: 18
                        },
                        textStyle: {
                          ...h9,
                          color: errorColor
                        },
                        text: this.state.viewError
                      },
                      {
                        viewStyle: {
                          marginBottom: 18
                        },
                        render: <Button loading={this.state.loading} onClick={this.onSubmitClick} text={submitText} />
                      }
                    ]
                  }
                ]}
              />
            </>
          );
          if (source === "block-user") {
            component = (
              <AutoSMSReadWrapper onError={this.onError} dataProps={this.dataProps} field={fields[0].field}>
                {component}
              </AutoSMSReadWrapper>
            );
          }
          return component;
        }}
      </Container>
    );
  }
}

class BlockDevice extends React.Component {
  render() {
    let { link: { props: { params: { deviceId } = {} } = {} } = {} } = this.props;
    return <Login source={"confirm-user"} deviceId={deviceId} />;
  }
}

class OtpBlockDevice extends React.Component {
  render() {
    return <Login source={"block-user"} />;
  }
}

const emailBlockDevice = async props => {
  let {
    invoke,
    urls,
    hashMap: { blockToken }
  } = props;

  await invoke({
    service: {
      url: urls["blockDeviceUsingLinkApi"],
      uriProps: {
        data: {
          token: blockToken
        }
      }
    },
    allowParallelInvoke: true
  });
  showMessage && showMessage(I18N.t("blockDeviceMessage"), 2000);
};

class LinkExpired extends React.Component {
  render() {
    const noDataProps = {
      image: "shareNoDataIcon",
      primaryText: I18N.t("resetPinLinkExpiredHeader"),
      secondaryText: I18N.t("resetPinLinkExpiredMessage")
    };
    const { activeMQ } = this.props;
    const { headerContainerStyle, emptyBoxStyle, titleStyle, logoStyle } = resolveMQ(
      linkExpiredStyle,
      ["headerContainerStyle", "emptyBoxStyle", "titleStyle", "logoStyle"],
      activeMQ
    );
    return (
      <View style={{ flex: 1 }}>
        <Box
          direction="row"
          {...headerContainerStyle}
          render={[
            {
              ...titleStyle,
              width: "1fr",
              text: () => I18N.t("blockDevice")
            },
            <ShareLogoComponent {...logoStyle} />
          ]}
        />
        <Box {...emptyBoxStyle} />
        <RenderNoData {...noDataProps} />
      </View>
    );
  }
}

class HeaderSM extends React.Component {
  render() {
    let { containerStyle, titleStyle } = headerStyleSM;
    return (
      <Box
        {...containerStyle}
        render={[
          {
            ...titleStyle,
            text: I18N.t("blockDevice")
          },
          <ShareLogoComponent
            viewStyle={{
              paddingLeft: 8,
              paddingRight: 8
            }}
            icon={"headerLogo"}
          />
        ]}
      />
    );
  }
}

class HeaderMD extends React.Component {
  render() {
    let { containerStyle, titleStyle } = headerStyleMD;
    return (
      <Box
        {...containerStyle}
        render={[
          {
            ...titleStyle,
            text: I18N.t("blockDevice")
          },
          <ShareLogoComponent icon={"headerLogo"} />
        ]}
      />
    );
  }
}

class BlockDeviceWebEmailComponent extends React.Component {
  state = {};

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  onError = (err, errorField) => {
    let message = getErrorMessage(err);
    this._setState({ [errorField || "error"]: message, loading: false });
  };

  onResetClick = async () => {
    let { replaceUri } = this.props;
    try {
      await emailBlockDevice(this.props);
      replaceUri && replaceUri({ uri: "/home#gallery" });
    } catch (err) {
      this.onError(err, "viewError");
    }
  };

  onCancel = async () => {
    let { replaceUri } = this.props;
    try {
      replaceUri && replaceUri({ uri: "/home#gallery" });
    } catch (err) {
      this.onError(err, "viewError");
    }
  };
  onSubmitClick = async e => {
    try {
      let { updatableData } = this.dataProps;
      let validateData = updatableData && updatableData.validateData;
      if (validateData) {
        let validations = await validateData();
        if (validations && Object.keys(validations).length) {
          return;
        }
      }
      this._setState({ loading: true, error: void 0, viewError: void 0 });
      await this.onResetClick(e);
      this._setState({ loading: false });
    } catch (err) {
      this.onError(err);
    }
  };

  render() {
    let contentContainerStyle = { flex: 1, minHeight: 650 };
    let { activeMQ } = this.props;
    const { resetPinViewStyle } = resolveMQ(
      { resetPinViewStyleSM, resetPinViewStyleMD },
      ["resetPinViewStyle"],
      activeMQ
    );
    return (
      <Container
        scrollViewStyle={{
          style: { flex: 1 }
        }}
      >
        {props => {
          this.dataProps = props;
          let component = (
            <Box
              viewStyle={{ ...contentContainerStyle }}
              render={[
                {
                  viewStyle: resetPinViewStyle,
                  render: [
                    {
                      image: getImage("shareNoDataIcon"),
                      viewStyle: {
                        alignItems: "center",
                        paddingTop: 18
                      },
                      imageProps: {
                        resizeMode: "contain"
                      }
                    },
                    {
                      viewStyle: {
                        paddingTop: 18,
                        paddingBottom: 18,
                        alignSelf: "center"
                      },
                      textStyle: {
                        ...h16,
                        color: primaryColor
                      },
                      text: I18N.t("blockDevice")
                    },
                    this.state.viewError && {
                      viewStyle: {
                        marginBottom: 18
                      },
                      textStyle: {
                        ...h9,
                        color: errorColor
                      },
                      text: this.state.viewError
                    },
                    {
                      viewStyle: {
                        marginBottom: 18
                      },
                      render: <Button loading={this.state.loading} onClick={this.onSubmitClick} text={"Block"} />
                    },
                    {
                      viewStyle: {
                        marginBottom: 18
                      },
                      render: <Button onClick={this.onCancel} text={"Cancel"} />
                    }
                  ]
                }
              ]}
            />
          );
          return component;
        }}
      </Container>
    );
  }
}

class BlockDeviceWebEmail extends React.Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.authenticateToken();
  }
  authenticateToken = async () => {
    let { hashMap: { blockToken } = {} } = this.props;
    let { urls } = this.props;
    try {
      let result = await axios.get(`${urls["authenticateBlockToken"]}/${blockToken}?context={"brand":"${brandName}"}`);
      let { data: { status, error } = {} } = result || {};
      if (status === "Authenticated") {
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, error: (error && error.message) || "error" });
      }
    } catch (err) {
      this.setState({ loading: false, error: (err && err.message) || "error" });
    }
  };

  render() {
    let { loading, error } = this.state;
    let { hashMap: { blockToken } = {}, activeMQ } = this.props;
    const { Header } = resolveMQ({ HeaderSM, HeaderMD }, ["Header"], activeMQ);
    if (loading) {
      return <LoadingIndicator />;
    } else if (error) {
      return <LinkExpired {...this.props} />;
    } else {
      return (
        <>
          <Header />
          <BlockDeviceWebEmailComponent {...this.props} blockToken={blockToken} />
        </>
      );
    }
  }
}

BlockDeviceWebEmail = withContext(BlockDeviceWebEmail, {
  invoke: "App.invoke",
  urls: "App.urls",
  activeMQ: "ActiveMQ",
  replaceUri: "Router.replaceUri"
});

Login = withContext(Login, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user",
  addUri: "Router.addUri",
  replaceUri: "Router.replaceUri",
  deleteUri: "Router.deleteUri",
  getPath: "Router.getPath",
  link: "ScreenRoute.link"
});

let modalStyle = {
  position: "center",
  width: 486,
  style: {
    borderRadius: 4
  },
  parentStyle: {
    zIndex: 100
  },
  escEnabled: false
};
class BlockDeviceWebComponent extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    this.modalTypeClickEvents = {
      "confirm-user": this.onCreateClick,
      "block-user": this.onConfirmClick
    };
    this.modalTypeCancelTypes = {
      "confirm-user": void 0,
      "block-user": "confirm-user"
    };
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  onError = (err, errorField) => {
    let message = getErrorMessage(err);
    this._setState({ [errorField || "error"]: message, loading: false });
  };

  setModalType = (modalType, _state) => {
    this._setState({
      modalType,
      error: void 0,
      modalError: void 0,
      ..._state
    });
  };

  actionClick = () => {
    this.setModalType("confirm-user");
  };

  renderModal = ({
    title,
    primaryText,
    placeholder,
    submitText,
    cancelText,
    onConfirm,
    onCancel,
    field,
    inputStyle,
    fields
  }) => {
    return (
      <Connect data={{}} updatable>
        {props => {
          this.dataProps = props;
          let editorProps = {
            ...this.dataProps,
            state: this.state,
            setState: this._setState,
            onClick: this.onSubmitClick
          };
          return [
            this.state.loading && <LoadingIndicator />,
            <Box
              viewStyle={{
                backgroundColor: primaryBg2
              }}
              render={[
                title && {
                  direction: "row",
                  viewStyle: {
                    height: 77,
                    paddingLeft: 30,
                    alignItems: "center",
                    borderBottomWidth: 2,
                    borderColor: veryLightPink
                  },
                  textStyle: {
                    ...h20,
                    color: themeColor
                  },
                  render: [
                    { width: "1fr", text: I18N.t(title) },
                    onCancel && {
                      viewStyle: {
                        paddingLeft: 24,
                        paddingRight: 30,
                        width: 78,
                        height: 48,
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer"
                      },
                      image: getImage("crossIcon"),
                      onClick: onCancel
                    }
                  ]
                },
                {
                  viewStyle: { marginLeft: 30, marginRight: 30 },
                  render: [
                    {
                      viewStyle: { paddingTop: 24, paddingBottom: 24, alignSelf: "center" },
                      image: getImage("safeAreaIcon")
                    },
                    primaryText && {
                      viewStyle: {
                        paddingBottom: 24,
                        paddingLeft: 30,
                        paddingRight: 30,
                        alignSelf: "center"
                      },
                      textStyle: {
                        ...h16_l,
                        color: primaryColor
                      },
                      text: I18N.t(primaryText)
                    },
                    field
                      ? {
                          viewStyle: {
                            paddingBottom: 12
                          },
                          render: (
                            <Editor {...editorProps} field={field} inputStyle={inputStyle} placeholder={placeholder} />
                          )
                        }
                      : fields &&
                        fields.map((fieldInfo, index) => {
                          return {
                            viewStyle: {
                              paddingBottom: 12
                            },
                            render: (
                              <Editor
                                {...editorProps}
                                inputProps={{
                                  autoFocus: index === 0
                                }}
                                field={fieldInfo.field}
                                placeholder={fieldInfo.placeholder}
                              />
                            )
                          };
                        })
                  ]
                },
                {
                  direction: "row",
                  viewStyle: {
                    paddingTop: 12,
                    paddingBottom: 24,
                    paddingLeft: 30,
                    paddingRight: 30
                  },
                  render: [
                    {
                      viewStyle: {
                        flex: 1,
                        alignSelf: "center",
                        paddingRight: 24
                      },
                      textStyle: {
                        ...h9,
                        color: errorColor
                      },
                      text: this.state.modalError || ""
                    },
                    {
                      viewStyle: {
                        cursor: "pointer",
                        marginRight: 24,
                        alignSelf: "center"
                      },
                      textStyle: {
                        ...h9,
                        color: themeColor
                      },
                      text: I18N.t(cancelText),
                      onClick: onCancel
                    },
                    {
                      viewStyle: {
                        cursor: "pointer",
                        paddingTop: 9,
                        paddingBottom: 9,
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: themeColor,
                        borderRadius: 4
                      },
                      textStyle: {
                        ...h9,
                        color: highlightColor
                      },
                      text: I18N.t(submitText),
                      onClick: onConfirm
                    }
                  ]
                }
              ]}
            />
          ];
        }}
      </Connect>
    );
  };

  onClick = event => {
    event && event.stopPropagation && event.stopPropagation();
    const { onClick } = this.props;
    if (onClick) {
      onClick({ onClick: this.actionClick });
    } else {
      this.actionClick();
    }
  };

  onCreateClick = async () => {
    let { data: { mobile } = {} } = this.dataProps;
    let result = await sendSMS({ mobile });
    const { invoke, urls, action: { device: deviceId } = {} } = this.props;
    sendBlockMailToUser({ invoke, urls, deviceId });
    this.setModalType("block-user", { data: { mobile }, transaction_id: result.transaction_id, deviceId });
  };

  onConfirmClick = async () => {
    let { data } = this.dataProps;
    try {
      let { transaction_id, deviceId } = this.state;
      await sendBlockOtp(data, transaction_id, deviceId, this.props);
      this.setModalType();
    } catch (err) {
      this.onError(err, "modalError");
    }
  };

  onChangePinClick = () => {
    this.setModalType("change-pin");
  };

  resendOtp = async () => {
    try {
      if (this.isResend) {
        return;
      }
      this.isResend = true;
      await resendPincode(this.state.transaction_id);
      this.isResend = false;
    } catch (err) {
      this.onError(err, "modalError");
    }
  };

  onSubmitClick = async e => {
    try {
      let { updatableData } = this.dataProps;
      let validateData = updatableData && updatableData.validateData;
      if (validateData) {
        let validations = await validateData();
        if (validations && Object.keys(validations).length) {
          return;
        }
      }
      let { modalType } = this.state;
      this._setState({ loading: true, error: void 0, modalError: void 0 });
      let onSubmit = this.modalTypeClickEvents[modalType];
      onSubmit && (await onSubmit(e));
      this._setState({ loading: false });
    } catch (err) {
      this.onError(err);
    }
  };

  onCancel = () => {
    let { modalType } = this.state;
    this.setModalType(this.modalTypeCancelTypes[modalType]);
  };

  render() {
    let { children } = this.props;
    let modalComponent = void 0;
    let { modalType } = this.state;
    if (modalType) {
      modalComponent = (
        <Modal key={modalType} isOpen={true} {...modalStyle}>
          {this.renderModal({
            ...blockInfo[modalType],
            onConfirm: this.onSubmitClick,
            onCancel: this.onCancel
          })}
        </Modal>
      );
    }
    return (
      <>
        {React.cloneElement(children, { onClick: this.onClick })}
        {modalComponent}
      </>
    );
  }
}

BlockDeviceWebComponent = withContext(BlockDeviceWebComponent, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user",
  addUri: "Router.addUri",
  getPath: "Router.getPath",
  link: "ScreenRoute.link"
});

export default {
  BlockDeviceWebComponent,
  BlockDevice: {
    component: "custom",
    Component: BlockDevice,
    title: I18N.t("blockDevice"),
    headerActions: [
      {
        type: "menu",
        visible: false
      }
    ]
  },
  OtpBlockDevice: {
    component: "custom",
    Component: OtpBlockDevice,
    title: I18N.t("blockDevice"),
    headerActions: [
      {
        type: "menu",
        visible: false
      }
    ]
  },
  blockDeviceMail: {
    component: "custom",
    Component: BlockDeviceWebEmail,
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true
  }
};
