import React from "react";
import { Box, theme, I18N, getImage, WithModal, Dimensions } from "../../../FsCloudComponent";
import { getSizeFormat } from "client-utility/lib/FileUtility";
import PieChart from "./PieChart";

const { fonts, colors, shadows } = theme;
const { h1, h3, h2_18 } = fonts;
const { primaryColor, highlightColor, brownGrey, themeColor, tomato, bluish, turtleGreen, blackBg } = colors;
const modalStyle = {
  position: "cover",
  autoHide: true,
  animationType: "none",
  noBackdrop: true,
  width: 150,
  height: 80,
  style: {
    boxShadow: "0 0px 7px 0 rgba(0, 0, 0, 0.13)",
    shadowColor: blackBg,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    borderRadius: 2
  }
};

const mappings = {
  usedSpace: {
    headerTitle: "usedSpace",
    stats: [
      { type: "image", label: "photos", color: "orange" },
      { type: "video", label: "videos", color: turtleGreen },
      { type: "doc", label: "docs", color: tomato },
      { type: "audio", label: "audios", color: bluish }
    ]
  },
  trashedSpace: {
    headerTitle: "trashedSpace",
    stats: [
      { type: "trashed_image", label: "photos", color: "orange" },
      { type: "trashed_video", label: "videos", color: turtleGreen },
      { type: "trashed_doc", label: "docs", color: tomato },
      { type: "trashed_audio", label: "audios", color: bluish }
    ]
  }
};

export default class SpaceInfo extends React.Component {
  state = {
    contentType: "sizes"
  };

  updateContent = (contentType, closeModal) => () => {
    this.setState({ contentType });
    closeModal && closeModal();
  };

  renderModal = ({ closeModal } = {}) => {
    return (
      <Box
        render={[
          {
            onClick: this.updateContent("sizes", closeModal),
            viewStyle: { paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 8, cursor: "pointer" },
            textStyle: { color: brownGrey, ...h3 },
            text: I18N.t("bySize")
          },
          {
            onClick: this.updateContent("counts", closeModal),
            viewStyle: { paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 16, cursor: "pointer" },
            textStyle: { color: brownGrey, ...h3 },
            text: I18N.t("byCount")
          }
        ]}
      />
    );
  };

  renderCard = () => {
    let { data, source, pieChartWidth } = this.props;
    if (!(data && data.counts && data.sizes)) {
      return null;
    }
    if ((source === "usedSpace" && data.usedSpace > 0) || (source === "trashedSpace" && data.trash > 0)) {
      let stats = mappings[source]["stats"];
      const headerTitle = mappings[source]["headerTitle"];
      const { contentType } = this.state;
      stats.forEach(item => {
        item.counts = (data.counts && data.counts[item.type]) || 0;
        const itemSize = getSizeFormat((data.sizes && data.sizes[item.type]) || 0);
        item.sizes = itemSize.replace(".", ",");
        item.value = data[contentType][item.type];
        item.title = I18N.t(item.label);
      });

      if (!pieChartWidth) {
        let windowWidth = Dimensions.get("window").width;
        pieChartWidth = windowWidth - (140 + 68); // 140 fixed width for list view, 48 + 16 styles + 4 extra
      }
      pieChartWidth = Math.min(pieChartWidth, 180);

      return (
        <Box
          viewStyle={{
            backgroundColor: highlightColor,
            margin: 12,
            borderRadius: 4,
            ...shadows.cardShadow,
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 15,
            paddingBottom: 15
          }}
          render={[
            {
              direction: "row",
              viewStyle: { justifyContent: "space-between" },
              render: [
                {
                  textStyle: { ...h1, color: primaryColor },
                  text: I18N.t(headerTitle)
                },
                <WithModal modal={modalStyle} renderModal={this.renderModal}>
                  <Box
                    direction={"row"}
                    viewStyle={{ alignItems: "center", cursor: "pointer" }}
                    render={[
                      {
                        textStyle: { color: themeColor, ...h1 },
                        text: contentType === "sizes" ? I18N.t("bySize") : I18N.t("byCount")
                      },
                      {
                        viewStyle: { paddingLeft: 6, paddingRight: 12 },
                        image: getImage("networkSelectorDropDownIcon")
                      }
                    ]}
                  />
                </WithModal>
              ]
            },
            {
              direction: "row",
              viewStyle: { flex: 1, paddingTop: 8, alignItems: "center", justifyContent: "center" },
              render: [
                {
                  viewStyle: {
                    width: pieChartWidth,
                    height: pieChartWidth,
                    alignItems: "center",
                    justifyContent: "center"
                  },
                  render: <PieChart stats={stats} width={pieChartWidth} />
                },
                {
                  viewStyle: { justifyContent: "center", marginLeft: 16, width: 140 },
                  render: stats.map(item =>
                    !item.value
                      ? null
                      : {
                          direction: "row",
                          viewStyle: { paddingTop: 4, paddingLeft: 4, paddingRight: 4 },
                          render: [
                            {
                              viewStyle: {
                                height: 12,
                                width: 12,
                                backgroundColor: item.color,
                                marginRight: 6,
                                borderRadius: 3,
                                alignSelf: "center"
                              }
                            },
                            {
                              textStyle: { ...h2_18, color: primaryColor, fontSize: 11 },
                              text: `${item.title} (${item[contentType]})`
                            }
                          ]
                        }
                  )
                }
              ]
            }
          ]}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    return this.renderCard();
  }
}
