import uuid from "uuid/v4";
import { Platform, I18N, mappings, getFileUriFromContentUri } from "../../../FsCloudComponent";

import moment from "moment";
let supportIssueRef = void 0;
let commentListRef = void 0;

export const onUploadFile = async ({ props }) => {
  let { attachment, uploadRequest, upload, user } = props || {};
  if (!attachment) {
    return;
  }
  let uploadFile = attachment;
  try {
    let { uploadProps, resource } =
      (await uploadRequest({
        file: [uploadFile],
        uploadType: "issues_file"
      })) || {};
    if (resource) {
      let { resource_name, aggregateId = uuid() } = resource;
      let path = `issues/${user._id}/${aggregateId}/${resource_name}`;
      uploadFile = {
        ...uploadFile,
        uploadProps,
        path,
        customMetadata: {
          issue: "true"
        },
        uploadType: "issues_file"
      };
      if (Platform && Platform.OS !== "web") {
        attachment = attachment.uri;
        if (
          Platform.OS === "android" &&
          typeof attachment === "string" &&
          attachment.startsWith("content://") &&
          getFileUriFromContentUri
        ) {
          try {
            attachment = await getFileUriFromContentUri(attachment);
          } catch (e) {
            if (e && e.message === "Could't retrieve filepath") {
              e.message = I18N.t("chooseAnotherFile");
            }
            throw e;
          }
        }
      }
      let data = await upload(attachment, {
        ...uploadFile
      });
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const dateModifier = ({ date: dateObj }) => {
  let date = moment(dateObj).format("DD/MM/YYYY");
  let time = moment(dateObj).format("HH:mm");

  return { date, time };
};

export const getFileType = name => {
  let ext = name && name.split(".").pop();
  let extImage = void 0;
  if (ext) {
    ext = ext.toLowerCase();
    extImage = mappings[ext];
  }
  return extImage;
};

export const getFileInfo = value => {
  let imgUri = void 0;
  let imgName = void 0;
  if (typeof value == "string") {
    let url = (decodeURIComponent && decodeURIComponent(value)) || "";
    let l = url.lastIndexOf("?");
    let f = url.lastIndexOf("/");
    if (l > -1 && f > -1) {
      imgName = url.substring(f + 1, l);
    }
    imgUri = value;
  } else if (typeof value === "object") {
    let { name, url } = value;
    imgUri = url;
    imgName = name;
  }
  return {
    imgName: imgName,
    imgUri: imgUri
  };
};

export const getSupportIssueRef = ref => {
  supportIssueRef = ref;
};

export const setSupportIssueRef = props => {
  supportIssueRef && supportIssueRef.setInfo && supportIssueRef.setInfo(props);
};

export const setCommentListRef = ref => {
  commentListRef = ref;
};

export const getCommentListRef = () => commentListRef;

export const getErrorMessage = e => {
  let message = e.code ? I18N.t(e.code.toString()) : void 0;
  if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
    message = e.message;
  }
  return message;
};
