import React from "react";
import "./uploadWrapper.css";
import UploadDialogBoxHoc from "./UploadDialogBox";
import UploadContext from "./UploadContext";

const StatusConstant = {
  REQUESTED: "requested",
  RUNNING: "running",
  PAUSED: "paused",
  COMPLETED: "completed",
  THUMBNAIL: "thumbnailGeneration",
  CANCEL: "cancel",
  FAILED: "failed",
  Error: "error",
  SCANNING: "scanning"
};

const getFileType = file => {
  let fileType = file && file.type;
  if (fileType) {
    if (fileType.includes("image/") || fileType.includes("video/")) {
      return "gallery";
    } else if (fileType.includes("audio/")) {
      return "audio";
    } else {
      return "doc";
    }
  }
};

export default hocProps => {
  const {
    View,
    Box,
    renderChildren,
    getImage,
    theme,
    showError,
    getInput,
    I18N,
    waitForScanning,
    uploadErrorCall,
    logUploadAnalytics,
    encryptFile,
    cancelEncryptDecryptWeb
  } = hocProps;

  const UploadDialogBox = UploadDialogBoxHoc(hocProps);

  const { colors, fonts, bgs } = theme;
  const { primaryBg2 } = bgs;
  const { themeColor, brownGrey } = colors;
  const { h24, h9_21 } = fonts;

  const dragWrapperStyle = {
    containerStyle: {
      viewStyle: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: primaryBg2,
        opacity: 0.96,
        zIndex: 20,
        alignItems: "center",
        justifyContent: "center"
      }
    },
    itemContainerStyle: {
      viewStyle: {
        position: "absolute",
        zIndex: 29,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center"
      }
    },
    uploadIconStyle: {
      viewStyle: {
        animationName: "bounceInUp",
        animationDuration: "0.75s",
        animationIterationCount: 1,
        animationTimingFunction: "linear",
        paddingBottom: 15
      }
    },
    uploadTitleStyle: {
      textStyle: {
        ...h24,
        color: themeColor
      },
      viewStyle: {
        paddingTop: 15,
        paddingBottom: 6,
        paddingLeft: 21,
        paddingRight: 21
      }
    },
    uploadSecondTitleStyle: {
      textStyle: {
        ...h9_21,
        color: brownGrey
      },
      viewStyle: {
        paddingTop: 6
      }
    }
  };

  class UploadWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.uploadFiles = [];
      this.contextValue = {
        onUploadFile: this.onUploadFile,
        onSelectUpload: this.onSelectUpload
      };
    }

    onSelectUpload = () => {
      this.inputRef && this.inputRef.click();
    };

    uploadTask = void 0;
    componentDidMount() {
      window.addEventListener(
        "dragover",
        function (e) {
          e.preventDefault();
        },
        false
      );
      window.addEventListener(
        "drop",
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    componentWillUnmount() {
      window.removeEventListener(
        "dragover",
        function (e) {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    componentDidUpdate(prevProps) {
      if (!this.props.user && this.uploadFiles && this.uploadFiles.length) {
        this.onClearAll();
        this._setState({});
      }
    }

    checkScanning = async ({ uploadFile }) => {
      return await waitForScanning({
        resourceId: uploadFile.resourceId,
        user: this.props.user
      });
    };

    _setState = (state = {}) => {
      if (this.timeout) {
        return;
      }
      this.timeout = setTimeout(_ => {
        clearTimeout(this.timeout);
        this.timeout = void 0;
        this.setState(state);
      }, 10);
    };

    uploadStart = ({ task, uploadFile }) => {
      if (uploadFile.status === StatusConstant.CANCEL) {
        task.cancel && task.cancel();
        this.uploadFile();
        return;
      }
      uploadFile.task = task;
      task &&
        task.on(
          "state_changed",
          snapshot => {
            let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            uploadFile.firebaseStatus = snapshot.state;
            switch (snapshot.state) {
              case "paused": {
                // or 'paused'
                // console.log("Upload is paused");
                uploadFile.status = StatusConstant.PAUSED;
                this._setState();
                break;
              }
              case "running": {
                uploadFile.progress = progress;
                uploadFile.status = StatusConstant.RUNNING;
                this._setState();
                break;
              }
              default: {
                break;
              }
            }
          },
          error => {
            if (uploadFile.size !== uploadFile.file.size && uploadFile.file.size === 0) {
              uploadFile.status = StatusConstant.FAILED;
              uploadFile.errorCode = "file_no_more_error";
            } else if (error.code === "storage/canceled") {
              uploadFile.status = StatusConstant.CANCEL;
              uploadFile.errorCode = "upload_user_cancelled";
            } else if (error.code === "storage/retry-limit-exceeded") {
              uploadFile.status = StatusConstant.CANCEL;
              uploadFile.errorCode = "upload_no_internet";
            } else {
              uploadFile.status = StatusConstant.FAILED;
              uploadFile.errorCode = "upload_storage_unknown";
            }
            uploadErrorCall({ resourceId: uploadFile.resourceId, upload_error: error && error.message });
            this._setState();
            this.uploadFile();
          },
          async () => {
            uploadFile.status = StatusConstant.SCANNING;
            this._setState();
            this.uploadFile();
            let result = await this.checkScanning({ uploadFile });
            if (result && result.error) {
              uploadFile.status = StatusConstant.FAILED;
              uploadFile.errorCode = result.error.code;
              uploadFile.errorMessage = result.error.message || result.error.code;
            } else {
              console.log("Deus esteja aqui!!!!!!!!!!!!!");
              uploadFile.status = StatusConstant.COMPLETED;
            }
            this._setState();
          }
        );
    };

    uploadingFile = async (uploadFile, uploadIndex) => {
      let { upload, uploadOptions, uploadRequest } = this.props;
      let { file, size, secureType } = uploadFile;

      const onUploadError = (error, status = StatusConstant.FAILED) => {
        uploadFile.status = status;
        uploadFile.errorCode = error.code;
        uploadFile.errorMessage = error.message || error.code;
        this._setState();
        this.uploadFile();
      };

      try {
        if (size !== file.size) {
          onUploadError({ code: "file_no_more_error" });
        } else {
          let uploadRequestedResult = await uploadRequest({ file, secureType, encrypted: true, encryptRequired: true });
          uploadRequestedResult = uploadRequestedResult && uploadRequestedResult[0];
          Object.assign(uploadFile, uploadRequestedResult);
          if (uploadFile.error) {
            onUploadError(uploadFile.error);
          } else if (!uploadFile.spaceReserved) {
            onUploadError({ code: "uploader/limit-exceeds" });
          } else {
            try {
              if (uploadRequestedResult.customMetadata && uploadRequestedResult.customMetadata.encrypted) {
                uploadFile.encrypting = true;
                file = await encryptFile(file, { resourceId: uploadFile.resourceId });
                uploadFile.encrypting = void 0;
                if (!file) {
                  throw new Error(I18N.t("encryptFileError"));
                }
              }
              await upload(file, {
                ...uploadOptions,
                ...uploadFile,
                onUploadStart: ({ task }) => {
                  this.uploadStart({ task, uploadFile, index: uploadIndex });
                },
                throwError: true
              });
            } catch (error) {
              uploadFile.encrypting = void 0;
              uploadErrorCall({ resourceId: uploadFile.resourceId, upload_error: error && error.message });
              throw error;
            }
          }
        }
      } catch (error) {
        if (error.code === "no_internet") {
          onUploadError(
            {
              code: "upload_no_internet",
              message: error.message
            },
            StatusConstant.CANCEL
          );
        } else {
          let status = error.code === "upload_user_cancelled" ? StatusConstant.CANCEL : StatusConstant.FAILED;
          onUploadError(error, status);
        }
      }
    };

    uploadFile = () => {
      let uploadIndex =
        this.leastResumeIndex !== undefined
          ? this.leastResumeIndex
          : this.lastUploadIndex !== undefined
          ? this.lastUploadIndex + 1
          : 0;
      if (uploadIndex >= this.uploadFiles.length) {
        this.uploading = false;
        return;
      }
      this.lastUploadIndex = uploadIndex;
      let uploadFile = this.uploadFiles[uploadIndex];
      if (uploadFile.status === StatusConstant.REQUESTED) {
        this.uploadingFile(uploadFile, uploadIndex);
      } else {
        this.leastResumeIndex = void 0;
        this.uploadFile();
      }
    };

    startUploading = () => {
      if (!this.uploading) {
        this.uploading = true;
        this.uploadFile();
      }
    };

    onUploadFile = async e => {
      e.preventDefault();
      let files = e.dataTransfer ? e.dataTransfer.files : e.target && e.target.files;
      let items = e.dataTransfer ? e.dataTransfer.items : [];
      let filesKeys = files ? Object.keys(files) : [];
      if (!filesKeys.length) {
        return;
      }
      let secureType = this.props.getSecureType && this.props.getSecureType();
      try {
        let filesToUpload = [];
        for (let i = 0; i < filesKeys.length; i++) {
          let item = items[i] && items[i].webkitGetAsEntry && items[i].webkitGetAsEntry();
          if (item && item.isDirectory) {
            continue;
          }
          const fileToUpload = {
            file: files[i],
            status: StatusConstant.REQUESTED,
            fileType: getFileType(files[i]),
            size: files[i].size,
            secureType
          };
          filesToUpload.push(fileToUpload);
          this.uploadFiles.push(fileToUpload);
        }
        logUploadAnalytics(filesToUpload);
        if (this.inputRef) {
          this.inputRef.value = "";
        }
        this.newUpload = true;
        this.startUploading();
        this._setState();
      } catch (e) {
        showError(e);
      }
    };

    onCancel = ({ index }) => {
      let uploadFile = this.uploadFiles[index];
      if (uploadFile.task) {
        uploadFile.task.cancel();
      } else if (uploadFile.encrypting) {
        cancelEncryptDecryptWeb(uploadFile.resourceId);
      } else {
        uploadFile.status = StatusConstant.CANCEL;
        uploadFile.errorCode = "upload_user_cancelled";
      }
      this._setState();
    };

    onCancelAll = () => {
      let uploadFiles = this.uploadFiles;
      uploadFiles.forEach((uploadFile, index) => {
        this.onCancel({ index });
      });
    };

    onResume = ({ index }) => {
      let uploadFile = this.uploadFiles[index];
      if (uploadFile.status === StatusConstant.CANCEL) {
        uploadFile.progress = void 0;
        uploadFile.status = StatusConstant.REQUESTED;
        uploadFile.error = void 0;
        uploadFile.errorCode = void 0;
        uploadFile.errorMessage = void 0;
        if (this.leastResumeIndex === undefined || this.leastResumeIndex > index) {
          this.leastResumeIndex = index;
        }
        this.startUploading();
        this._setState();
      }
    };

    onResumeAll = () => {
      let uploadFiles = this.uploadFiles;
      uploadFiles.forEach((uploadFile, index) => {
        this.onResume({ index });
      });
    };

    onClearAll = () => {
      this.uploadFiles = [];
      this.leastResumeIndex = void 0;
      this.uploading = void 0;
      this.lastUploadIndex = void 0;
    };

    render() {
      let { children, ...restProps } = this.props;
      let newUpload = this.newUpload;
      if (newUpload) {
        this.newUpload = void 0;
      }
      let FileInput = getInput("sFile");

      let renderComponent = this.state.dragOver ? (
        <Box
          viewStyle={{ flex: 1 }}
          render={[
            { viewStyle: { flex: 1 }, render: children },
            dragWrapperStyle.containerStyle,
            {
              ...dragWrapperStyle.itemContainerStyle,
              render: [
                {
                  ...dragWrapperStyle.uploadIconStyle,
                  image: getImage("dragUploadIcon")
                },
                {
                  ...dragWrapperStyle.uploadTitleStyle,
                  text: I18N.t("uploadNewItem")
                },
                {
                  ...dragWrapperStyle.uploadSecondTitleStyle,
                  text: I18N.t("dragAndDropQuote")
                }
              ]
            }
          ]}
        />
      ) : (
        renderChildren(children)
      );
      return (
        <UploadContext.Provider value={this.contextValue}>
          <View style={{ flex: 1 }}>
            <View
              style={{ flex: 1 }}
              onDragOver={e => {
                this.setState({ dragOver: true });
              }}
              onDrop={e => {
                this.setState({ dragOver: false });
                this.onUploadFile(e);
              }}
              onDragLeave={e => {
                this.setState({ dragOver: false });
              }}
            >
              {renderComponent}
            </View>
            <UploadDialogBox
              {...restProps}
              files={this.uploadFiles}
              newUpload={newUpload}
              onCancel={this.onCancel}
              onCancelAll={this.onCancelAll}
              onResume={this.onResume}
              onResumeAll={this.onResumeAll}
              onClose={this.onClearAll}
            />
            <FileInput
              style={{ display: "none" }}
              multiple
              getRef={_ => (this.inputRef = _)}
              onChangeValue={e => {
                this.onUploadFile(e);
              }}
            />
          </View>
        </UploadContext.Provider>
      );
    }
  }
  return UploadWrapper;
};
