export default () => {
  const editorFormat = {
    currency: {
      number: {
        type: "number"
      },
      currency: {
        type: "reference",
        placeholder: "Currency",
        fetch: {
          props: {
            service: "_find",
            query: {
              id: "currencies"
            },
            model: "Currency"
          }
        },
        display: "currency",
        suggestionProps: {
          suggestionTitle: "Add Currency"
        }
      }
    }
  };

  const typeFormat = {
    number: {
      format: "0",
      aggregateFormat: "0.00i"
    },
    decimal: {
      format: "0.00",
      aggregateFormat: "0.00i"
    },
    date: {
      format: "DD MMM, YY",
      classic: {
        today: "LT",
        rest: "DD MMM, YY"
      }
    },
    dateTime: {
      format: "DD MMM, YY HH:mm:ss",
      classic: {
        today: "LT",
        rest: "DD MMM, YY HH:mm:ss"
      }
    },
    currency: {
      aggregateFormat: "0.00i",
      number: {
        type: "decimal",
        format: "0.00"
      },
      currency: {
        type: "reference",
        display: "currency"
      }
    },
    googlePlace: {
      searchField: "description"
    }
  };

  const typeStyle = {
    Selection: {
      width: "46px"
    },
    Draggable: {
      width: "30px"
    },
    HeaderDraggable: {
      width: "30px"
    },
    HeaderSelection: {
      width: "46px"
    },
    RowAction: {
      width: "30px"
    },
    HeaderRowAction: {
      width: "30px"
    },

    decimal: {
      width: "150px",
      align: "right",
      textStyle: {
        whiteSpace: "pre"
      }
    },
    duration: {
      width: "100px",
      align: "right",
      textStyle: {
        whiteSpace: "pre"
      }
    },
    number: {
      width: "150px",
      align: "right",
      textStyle: {
        whiteSpace: "pre"
      }
    },
    currency: {
      width: "150px",
      align: "right",
      textStyle: {
        whiteSpace: "pre"
      }
    },
    date: {
      width: "100px"
    },
    link: {
      textStyle: {}
    },
    invoke: {
      width: "100px",
      viewStyle: {
        alignItems: "center"
      },
      imageStyle: {
        width: 16,
        height: 16
      }
    },
    downloadFile: {
      width: "100px",
      viewStyle: {
        alignItems: "center"
      },
      imageStyle: {
        width: 16,
        height: 16
      }
    }
  };

  return {
    editorFormat,
    typeFormat,
    typeStyle
  };
};
