import React from "react";
import {
  Platform,
  withContext,
  I18N,
  theme,
  View,
  Box,
  ConfirmBox,
  AppConfig,
  LinkBox,
  getAction
} from "./FsCloudComponent";

let { fonts, colors, bgs } = theme;
let { h9_21, h5 } = fonts;
let { themeColor, primaryColor } = colors;
let { lightThemeBg, lightPink } = bgs;

const messageStyle = {
  viewStyle: { paddingBottom: 15 },
  textStyle: {
    ...h9_21,
    color: primaryColor
  }
};

const progressInfoStyle = {
  textStyle: { ...h5 }
};

const progressBarStyle = {
  containerStyle: {
    height: 12,
    overflow: "hidden",
    backgroundColor: lightThemeBg,
    borderRadius: 6
  },
  progressStyle: {
    height: 12,
    overflow: "hidden",
    backgroundColor: themeColor,
    borderRadius: 6,
    transitionDuration: "1s",
    transitionTimingFunction: "ease",
    transitionDelay: "0s"
  }
};

class NotifyIssueComponent extends React.Component {
  render() {
    return (
      <Box
        render={[
          {
            viewStyle: { paddingTop: 10, cursor: "pointer" },
            textStyle: { ...h9_21, color: themeColor },
            text: I18N.t("report_an_issues"),
            Container: getAction("link"),
            containerProps: {
              action: {
                link: {
                  uri: "/user-issues"
                }
              }
            }
          },
          AppConfig.APP_MIGRATION_CHAT_URL && (
            <LinkBox url={AppConfig.APP_MIGRATION_CHAT_URL}>
              <Box
                viewStyle={{ marginTop: 10, paddingTop: 10, borderTopWidth: 1, borderTopColor: lightPink }}
                textStyle={{ ...h9_21, color: themeColor }}
                text={I18N.t("chat")}
              />
            </LinkBox>
          )
        ]}
      />
    );
  }
}

class MigrationProgressComponent extends React.Component {
  getProgressBar = ({ progress = 0 }) => {
    return (
      <View style={progressBarStyle.containerStyle}>
        <View
          style={{
            ...progressBarStyle.progressStyle,
            width: `${progress}%`
          }}
        />
      </View>
    );
  };

  render() {
    let { onClose, migrationDetails: { progress, files_copied, files_total } = {} } = this.props;
    let messageComponent = (
      <Box
        render={[
          {
            ...messageStyle,
            text: I18N.t("transferInfoAcceptMessage")
          },
          {
            viewStyle: { paddingBottom: 4, paddingTop: 3 },
            render: this.getProgressBar({ progress })
          },
          {
            direction: "row",
            viewStyle: { paddingTop: 4, paddingBottom: 15, justifyContent: "space-between" },
            render: [
              {
                textStyle: { ...progressInfoStyle.textStyle, color: primaryColor },
                text: files_total ? `${files_copied} ${I18N.t("of")} ${files_total}` : `${I18N.t("transferingMessage")}`
              },
              progress && {
                textStyle: { ...progressInfoStyle.textStyle, color: themeColor },
                text: `${progress} %`
              }
            ]
          }
        ]}
      />
    );
    return (
      <ConfirmBox
        title={I18N.t("transferFileTitle")}
        message={<Box render={[messageComponent, <NotifyIssueComponent />]} />}
        confirmText={I18N.t("underStood")}
        onConfirm={onClose}
        skipConfirm={Platform.OS === "android" ? false : true}
      />
    );
  }
}

MigrationProgressComponent = withContext(MigrationProgressComponent, {
  user: "User.user",
  migrationDetails: "StartUp.migrationDetails"
});

class MigrationSuccessComponent extends React.Component {
  getMigrationText = () => {
    let { user } = this.props;
    let { fsTotalFiles, fsMigratedFiles, showStats } = user || {};

    let migrationStatusMessage = "";
    if (showStats && fsTotalFiles) {
      fsMigratedFiles = fsMigratedFiles || 0;
      let fsPendingFiles = fsTotalFiles - fsMigratedFiles;
      let migrationPendingMessage = fsPendingFiles
        ? I18N.t("migrationPendingMessage").replace("__pendingCount__", fsPendingFiles)
        : "";

      migrationStatusMessage = I18N.t("migrationStatusMessage").replace(
        "__migrationPendingDetails__",
        `${migrationPendingMessage ? " " + migrationPendingMessage : ""}`
      );
      migrationStatusMessage = migrationStatusMessage.replace("__transferredFiles__", fsMigratedFiles);
      migrationStatusMessage = migrationStatusMessage.replace("__totalFiles__", fsTotalFiles);
    }

    let afterMigrationMessage = I18N.t("afterMigrationMessage");
    afterMigrationMessage = afterMigrationMessage.replace(
      "__migrationStatusDetails__",
      `${migrationStatusMessage ? " " + migrationStatusMessage : ""}`
    );
    return afterMigrationMessage;
  };

  render() {
    let { onAccept } = this.props;
    let messageComponent = <Box render={[{ ...messageStyle, text: this.getMigrationText() }]} />;
    return (
      <ConfirmBox
        title={I18N.t("afterMigrationTitle")}
        message={<Box render={[messageComponent, <NotifyIssueComponent />]} />}
        confirmText={I18N.t("okCaps")}
        onConfirm={onAccept}
      />
    );
  }
}

MigrationSuccessComponent = withContext(MigrationSuccessComponent, {
  user: "User.user",
  migrationDetails: "StartUp.migrationDetails"
});

export { MigrationProgressComponent, MigrationSuccessComponent };
