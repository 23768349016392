export default function getModalStyleFromProps({ maxHeight, maxWidth, minHeight, minWidth, width, height, size }) {
  let properties = {
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    width,
    height
  };
  let valueToReturn = {};
  for (var k in properties) {
    if (properties[k]) {
      valueToReturn[k] = properties[k];
    }
  }
  return valueToReturn;
}
