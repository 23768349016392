import React from "react";
import { SelectSetCard, SelectSetHeader } from "./DocsCards";
import { DocsSetNoData } from "../noData/NoData";
import { selectSetQuery, selectVaultSetQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

export default {
  SelectSet: {
    component: "table",
    dataObject: "Collection",
    title: () => I18N.t("select"),
    selectable: false,
    ...selectSetQuery,
    renderNoData: <DocsSetNoData selectSet />,
    headerActionsSM: [
      {
        type: "headerAction",
        text: () => I18N.t("newSetCaps"),
        action: "createSet"
      }
    ],
    renderHeader: SelectSetHeader,
    rowProps: {
      ...SelectSetCard
    }
  },
  SelectVaultSet: {
    component: "table",
    dataObject: "Collection",
    title: () => I18N.t("select"),
    selectable: false,
    ...selectVaultSetQuery,
    renderNoData: <DocsSetNoData selectSet />,
    headerActionsSM: [
      {
        type: "headerAction",
        text: () => I18N.t("newSetCaps"),
        action: "createVaultSet"
      }
    ],
    renderHeader: SelectSetHeader,
    rowProps: {
      ...SelectSetCard
    }
  }
};
