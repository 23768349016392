import React from "react";

export default ({ NavContext, ...hocProps }) => {
  class Nav extends React.Component {
    state = {};
    scrollListeners = [];
    constructor(props) {
      super(props);
      this.navContextValue = {
        setTitle: this.setTitle,
        addScrollListener: this.addScrollListener,
        removeScrollListener: this.removeScrollListener,
        notifyScrollListener: this.notifyScrollListener
      };
    }

    addScrollListener = callback => {
      let index = this.scrollListeners.indexOf(callback);
      if (index < 0) {
        this.scrollListeners.push(callback);
      }
    };
    notifyScrollListener = event => {
      this.scrollListeners.forEach(callback => callback(event));
    };
    removeScrollListener = callback => {
      let index = this.scrollListeners.indexOf(callback);
      if (index >= 0) {
        this.scrollListeners.splice(index, 1);
      }
    };

    setTitle = title => {
      this.setState({
        title
      });
    };

    render() {
      const { KeyboardAvoidingView, resolveMQ } = hocProps;
      const { activeMQ } = this.props;
      const { style } = resolveMQ(hocProps, ["style"], activeMQ);
      const { renderNav } = resolveMQ(this.props, ["renderNav"], activeMQ);
      let component = renderNav({
        style,
        title: this.state.title,
        ...this.props
      });
      if (!component) {
        return null;
      }
      return (
        <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
          <NavContext.Provider value={this.navContextValue}>{component}</NavContext.Provider>
        </KeyboardAvoidingView>
      );
    }
  }
  return Nav;
};
