import React from "react";

export default ({ Box, Platform, getAction, resolveMQ, resolveVisibleExp, getImage, theme: themeHoc = {} }) => {
  class MenuItems extends React.Component {
    getMenuItem = (item, menuItemStyle = {}, options = {}) => {
      const { onItemClick, data, actions, user, Screen, getSelectedData, link } = this.props;
      if (!item) {
        return;
      }
      let actionDef = void 0;
      if (typeof item === "string") {
        actionDef = actions && actions[item];
      } else {
        actionDef = item;
      }
      if (!actionDef) {
        return;
      }
      let { text, image, type, render, visible } = actionDef;
      if (visible !== undefined && resolveVisibleExp) {
        const isVisible = resolveVisibleExp({
          data,
          visibleExp: visible,
          user,
          Platform,
          Screen,
          getSelectedData,
          link,
          ...options
        });
        if (!isVisible) {
          return;
        }
      }
      let onClick = props => {
        onItemClick && onItemClick(props, { action: item, actionDef });
      };

      let menuItem = {
        ...menuItemStyle.containerStyle
      };
      if (type && getAction(type)) {
        menuItem.Container = getAction(type);
        menuItem.containerProps = {
          data,
          action: actionDef,
          onClick,
          source: "menuItem"
        };
      } else {
        menuItem.onClick = onClick;
      }
      if (image && getImage) {
        image = getImage(image) || image;
      }
      if (text && image && !render) {
        menuItem.render = [
          {
            direction: "row",
            viewStyle: {
              alignItems: "center"
            },
            render: [{ ...menuItemStyle.iconStyle, image }, { ...menuItemStyle.titleStyle, text }]
          }
        ];
      } else {
        menuItem.text = text;
        menuItem.image = image;
        menuItem.render = render;
      }
      return menuItem;
    };

    render() {
      const { items, activeMQ, theme, isSelectionMode, getSelectedIds, isAllSelected } = this.props;
      let { boxStyle, menuItemStyle } = resolveMQ(theme || themeHoc, ["boxStyle", "menuItemStyle"], activeMQ);
      let options = {
        selectionMode: isSelectionMode && isSelectionMode(),
        selectedIds: (getSelectedIds && getSelectedIds()) || [],
        allSelected: isAllSelected && isAllSelected()
      };
      return (
        <Box
          {...boxStyle}
          render={[
            ...items.map(item => {
              return this.getMenuItem(item, menuItemStyle, options);
            })
          ]}
        />
      );
    }
  }
  return MenuItems;
};
