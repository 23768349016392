import { AppContext, ActiveMQContext, UserContext } from "../../appstore";
import { DrawerLayoutContext } from "drawer-layout";
import {
  WithStateRouter as WithStateRouterHoc,
  Router as RouterHoc,
  Location as LocationHoc,
  WithState as WithStateHoc,
  RouterContext
} from "../../router";
import {
  ScreenContext,
  ScreenRouteContext,
  NavBodyContext,
  NavContext,
  ScreenContextProvider as ScreenContextProviderHoc
} from "../../nav";
import ToastContext from "../../toast/lib/ToastContext";
import Box from "box";
import {
  View,
  Text,
  TextInput,
  Image,
  ScrollView,
  AsyncStorage,
  Keyboard,
  Platform,
  KeyboardAvoidingView as KeyboardAvoidingViewHoc,
  Dimensions,
  ImageBackground
} from "react-components";
import { SelectionStoreContext, InfiniteScrollListContext } from "table";
import Modal from "../../modal";
import { renderProps, renderChildren, withContext as withContextHoc } from "inject";
import { putDottedValue, deepEqual } from "app-utility-functions";
import LayourWrapper from "layoutwrapper";
import MusicPlayerContext from "../../musicPlayer/MusicPlayerContext";
import StartUpContext from "../../../StartUpContext";
import firebase from "../../../Firebase";

const selectionStoreStateKey = "SelectionStore";
const routeStateKey = "Route";

const KeyboardAvoidingView = KeyboardAvoidingViewHoc({ renderProps });
const Location = LocationHoc({ renderChildren });
const WithState = WithStateHoc({ renderProps });

const Contexts = {
  User: UserContext,
  App: AppContext,
  ActiveMQ: ActiveMQContext,
  Router: RouterContext,
  DrawerLayout: DrawerLayoutContext,
  Screen: ScreenContext,
  ScreenRoute: ScreenRouteContext,
  Nav: NavContext,
  NavBody: NavBodyContext,
  ScrollView: InfiniteScrollListContext,
  SelectionStore: SelectionStoreContext,
  Toast: ToastContext,
  MusicPlayer: MusicPlayerContext,
  StartUp: StartUpContext
};

const mergeContext = context => {
  for (var type in context) {
    Contexts[type] = context[type];
  }
};

const withContext = withContextHoc({
  contexts: Contexts,
  putDottedValue
});

const WithStateRouter = withContext(
  WithStateRouterHoc({
    Router: withContext(
      RouterHoc({
        renderProps,
        routeStateKey
      }),
      {
        activeMQ: "ActiveMQ",
        user: "User.user"
      }
    ),
    Box,
    Modal,
    Platform,
    firebase
  }),
  {
    activeMQ: "ActiveMQ",
    user: "User.user"
  }
);

const ScreenContextProvider = ScreenContextProviderHoc({
  ScreenContext,
  ScreenRouteContext,
  routeStateKey: "Route",
  SelectionStoreContext,
  selectionStoreStateKey: selectionStoreStateKey,
  renderProps,
  deepEqual
});

// apply layout wrappers for  listen resize listener for calculating partition index when expand button clicked - paritosh : 27.02.2018
const ResizedBox = LayourWrapper(Box);

export {
  withContext,
  Box,
  ResizedBox,
  Text,
  View,
  Image,
  ScrollView,
  Modal,
  Location,
  WithState,
  AsyncStorage,
  WithStateRouter,
  Keyboard,
  Platform,
  KeyboardAvoidingView,
  ImageBackground,
  TextInput,
  Dimensions,
  ScreenContextProvider,
  SelectionStoreContext,
  InfiniteScrollListContext as ScrollContext,
  ToastContext,
  mergeContext
};
