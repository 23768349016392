import React from "react";
import { Box, theme, I18N, getInput, getImage, withContext, showMessage } from "../../../FsCloudComponent";
const { colors } = theme;
const { whiteTwo } = colors;

class ProfileImage extends React.Component {
  defaultColors = {};
  uploadingFile = async files => {
    let { upload, uploadRequest, onUploadStart, onUploadEnd, onUploadError } = this.props;
    try {
      onUploadStart && onUploadStart();
      let uploadFile = files;
      let uploadRequestedResult = await uploadRequest({ file: [uploadFile], uploadType: "profile_pic" });
      if (uploadRequestedResult) {
        uploadFile = { ...uploadFile, ...uploadRequestedResult, uploadType: "profile_pic" };
        await upload(files, {
          ...uploadFile
        });
        onUploadEnd && onUploadEnd();
        showMessage && showMessage(I18N.t("updateProfilePicMessage"), 2000);
      }
    } catch (error) {
      onUploadError && onUploadError(error);
      showMessage && showMessage(error.message, 2000);
    }
  };

  onUploadFile = async e => {
    let { updateUserInfo } = this.props;
    let file = e.target && e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = async () => {
      let src = reader.result;
      await this.uploadingFile(file);
      updateUserInfo &&
        updateUserInfo({
          profile_pic: src
        });
      if (this.inputRef) {
        this.inputRef.value = null;
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  onProfilePicClick = () => {
    this.inputRef && this.inputRef.click();
  };

  render() {
    let { user, icon, radius = 60, skipUpdate } = this.props;
    let { profile_pic, avatar, _id: userId } = user;
    let initials = (avatar && avatar.initials && avatar.initials.toUpperCase()) || "";
    let color = avatar && avatar.color;

    if (!color) {
      if (!this.defaultColors[userId]) {
        const x = Math.floor(Math.random() * 200);
        const y = Math.floor(Math.random() * 200);
        const z = Math.floor(Math.random() * 200);
        const defaultColor = "rgb(" + x + "," + y + "," + z + ")"; // default value for color (randomly generated)
        this.defaultColors[userId] = defaultColor;
      }
      color = this.defaultColors[userId];
    }
    if (!initials && user && user.name) {
      initials = user.name
        .split(" ")
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join("")
        .toUpperCase();
    }

    const FileInput = getInput("sFile");
    return (
      <Box
        viewStyle={{ cursor: "pointer" }}
        render={[
          <FileInput
            accept="image/*"
            style={{ display: "none" }}
            getRef={_ => (this.inputRef = _)}
            onChangeValue={this.onUploadFile}
          />,
          {
            onClick: skipUpdate ? void 0 : this.onProfilePicClick,
            direction: "row",
            render: [
              profile_pic
                ? {
                    image: { uri: profile_pic },
                    imageStyle: {
                      backgroundColor: whiteTwo,
                      width: radius * 2,
                      height: radius * 2,
                      borderRadius: radius
                    },
                    imageProps: {
                      resizeMode: "cover"
                    }
                  }
                : initials && {
                    viewStyle: { backgroundColor: color, width: radius * 2, height: radius * 2, borderRadius: radius },
                    textStyle: { textAlign: "center", marginTop: 44, color: "white", fontSize: 35 },
                    text: initials
                  },
              icon &&
                !skipUpdate && {
                  viewStyle: {
                    position: "absolute",
                    paddingRight: 8,
                    bottom: 0,
                    right: 0
                  },
                  image: getImage(icon)
                }
            ]
          }
        ]}
      />
    );
  }
}

ProfileImage = withContext(ProfileImage, {
  user: "User.user",
  upload: "App.upload",
  uploadRequest: "App.uploadRequest",
  updateUserInfo: "User.updateUserInfo"
});

export default ProfileImage;
