//height 44

export default ({ colors, bgs, fonts, shadows, navLeftActionSeparatorStyle, navStyle: navTheme }) => {
  const { highlightColor, themeColor } = colors;
  const { primaryBg2 } = bgs;
  const { shadow3, shadow6 } = shadows;
  const { h16 } = fonts;

  const navStyle = {
    style: { ...navTheme }
  };

  const navBodyStyle = {
    gapMD: 16,
    boxStyleSM: {
      direction: "row",
      viewStyle: {
        flex: 1
      }
    },
    boxStyleMD: {
      direction: "row",
      viewStyle: {
        flex: 1,
        backgroundColor: "#fafafa"
      }
    },
    modalStyleSM: {
      position: "screenBottom",
      escEnabled: false
    },
    modalStyleMD: {
      position: "screenRight",
      escEnabled: false
    },
    componentContainerStyle: {
      flex: 1,
      overflow: "hidden"
    }
  };

  const navTitleStyle = {
    boxStyleSM: {
      viewStyle: {
        flex: 1,
        height: 32,
        paddingLeft: 12,
        paddingRight: 12,
        justifyContent: "center"
      },
      textStyle: {
        ...h16,
        color: themeColor,
        numberOfLines: 1
      }
    },
    boxStyleMD: {}
  };

  const navHeaderStyle = {
    boxStyleSM: {
      direction: "row",
      viewStyle: {
        height: 60,
        paddingLeft: 4,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        alignItems: "center",
        backgroundColor: highlightColor,
        overflow: void 0,
        marginBottom: 1,
        ...shadows.navHeaderShadowSM
      }
    },
    boxStyleMD: {
      direction: "row",
      viewStyle: {
        height: 72,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 12,
        paddingBottom: 12,
        alignItems: "center",
        backgroundColor: highlightColor,
        overflow: void 0,
        marginBottom: 2,
        ...shadows.navHeaderShadowMD
      }
    },
    separatorStyleSM: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    separatorStyleMD: {
      viewStyle: {
        paddingLeft: 15,
        paddingRight: 15
      }
    }
  };

  const navFooterStyle = {
    boxStyle: {
      direction: "row",
      viewStyle: {
        height: 51,
        backgroundColor: primaryBg2,
        ...shadow3
      }
    }
  };

  const navLeftStyle = {
    boxStyle: {
      width: 90,
      viewStyle: {
        flex: 1,
        paddingBottom: 30,
        paddingTop: 14,
        zIndex: 5,
        backgroundColor: highlightColor,
        justifyContent: "center",
        ...shadow6
      },
      separator: ""
    },
    separatorStyle: {
      viewStyle: {
        ...navLeftActionSeparatorStyle
      }
    }
  };

  const navStatusBarStyle = {
    backgroundColor: highlightColor,
    barStyle: "dark-content"
  };

  return {
    navStyle,
    navBodyStyle,
    navTitleStyle,
    navHeaderStyle,
    navFooterStyle,
    navLeftStyle,
    navStatusBarStyle
  };
};
