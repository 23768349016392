import React from "react";

export default ({ onClick: actionClick, source, name: coreActionName }) => {
  class Action extends React.Component {
    onClick = event => {
      event && event.stopPropagation && event.stopPropagation();
      const { onClick, children, ...restProps } = this.props;
      if (onClick) {
        onClick({ source, onClick: actionClick, props: restProps });
      } else {
        actionClick && actionClick({ source, props: restProps });
      }
    };
    render() {
      let { children, triggerEvent } = this.props;
      return React.cloneElement(children, { [triggerEvent || "onClick"]: this.onClick });
    }
  }
  return Action;
};
