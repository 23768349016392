import Configuration from "./Configuration";
import Settings from "./Settings";
import UploadSetting from "./UploadSetting";
import About from "./About";
import Help from "./Help";
import DeleteAccount from "./DeleteAccount";
import Contacts from "./contacts/Contacts";
import ResetContact from "./contacts/ResetContact";
import ContactDetail from "./contacts/ContactDetail";
import AddContactSM from "./contacts/AddContactSM";
import SearchContact from "./contacts/SearchContact";

export default {
  ...Configuration,
  ...Settings,
  ...UploadSetting,
  ...About,
  ...Help,
  ...Contacts,
  ...ContactDetail,
  ...SearchContact,
  ...AddContactSM,
  ...ResetContact,
  ...DeleteAccount
};
