import React from "react";
import {
  Box,
  theme,
  showMessage,
  withContext,
  getInput,
  getImage,
  Platform,
  getContactPicUrl
} from "../../../../FsCloudComponent";

const { colors } = theme;
const { whiteTwo } = colors;

class ContactPhotoEditor extends React.Component {
  checkUploadPhoto = async (photo_id, callback) => {
    let timer = setTimeout(async () => {
      let { invoke, urls } = this.props;
      let response = await invoke({
        service: {
          url: urls["isContactPicUploaded"],
          uriProps: {
            photo_id
          }
        }
      });
      if (response && response.result && response.result === "no") {
        this.checkUploadPhoto(photo_id, callback);
      } else {
        clearTimeout(timer);
        callback();
      }
    }, 2000);
  };

  uploadingFile = async files => {
    let { upload, uploadRequest, data, setValue, setState } = this.props;
    try {
      let uploadFile = files;
      setState && setState({ loading: true });
      let uploadRequestedResult = await uploadRequest({ file: [uploadFile], uploadType: "contact_pic" });
      if (uploadRequestedResult) {
        uploadFile = { ...uploadFile, ...uploadRequestedResult, uploadType: "contact_pic" };
        await upload(files, {
          ...uploadFile
        });
        let photoId =
          uploadRequestedResult && uploadRequestedResult.metadata && uploadRequestedResult.metadata.photo_id;
        if (photoId) {
          const setPhotoId = () => {
            setValue && setValue({ data, field: "photo_id", value: photoId });
            setState && setState({ loading: false });
          };
          this.checkUploadPhoto(photoId, setPhotoId);
        }
      }
    } catch (error) {
      showMessage && showMessage(error.message, 2000);
      setState && setState({ loading: false });
    }
  };

  onUploadFile = async e => {
    let file = e.target && e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = async () => {
      await this.uploadingFile(file);
      if (this.inputRef) {
        this.inputRef.value = null;
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  onProfilePicClick = () => {
    this.inputRef && this.inputRef.click();
  };

  render() {
    let { data, radius = 78, skipUpdate } = this.props;
    let { photo_id } = data || {};
    const FileInput = getInput("sFile");
    return (
      <Box
        viewStyle={{ flex: 1, alignItems: "center", justifyContent: "center", cursor: "pointer" }}
        render={[
          Platform.OS === "web" ? (
            <FileInput
              accept="image/*"
              style={{ display: "none" }}
              getRef={_ => (this.inputRef = _)}
              onChangeValue={this.onUploadFile}
            />
          ) : (
            void 0
          ),
          {
            onClick: skipUpdate ? void 0 : this.onProfilePicClick,
            direction: "row",
            render: [
              {
                image: photo_id ? { uri: getContactPicUrl(photo_id) } : getImage("userPhotoIcon"),
                imageStyle: {
                  backgroundColor: whiteTwo,
                  width: radius * 2,
                  height: radius * 2,
                  borderRadius: radius
                },
                imageProps: {
                  resizeMode: "cover"
                }
              },
              {
                viewStyle: {
                  position: "absolute",
                  paddingRight: 6,
                  bottom: 10,
                  right: 0
                },
                image: getImage("editProfileIcon")
              }
            ]
          }
        ]}
      />
    );
  }
}

ContactPhotoEditor = withContext(ContactPhotoEditor, {
  upload: "App.upload",
  uploadRequest: "App.uploadRequest",
  urls: "App.urls",
  invoke: "App.invoke"
});

export default ContactPhotoEditor;
