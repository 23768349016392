import {
  Form as FormHoc,
  FormGroup as FormGroupHoc,
  Editor as EditorHoc,
  NestedEditor as NestedEditorHoc,
  VerticalEditorContainer as VerticalEditorContainerHoc,
  FormCard as FormCardHoc,
  FormCardGrid as FormCardGridHoc,
  TabForm as TabFormHoc,
  NestedModalForm as NestedModalFormHoc
} from "form";
import ConnectedFormHoc from "form/lib/ConnectedForm";

export default ({
  theme = {},
  Box,
  View,
  withContext,
  ScrollView,
  ScrollContext,
  resolveValue,
  resolveParams,
  getFieldDef,
  resolveVisibleExp,
  getAggregateValue,
  resolveMQ,
  getFormEditor,
  getAction,
  getImage,
  dataFormat,
  getFieldHeader,
  getMandatoryMessage,
  Connect,
  getComponent,
  getJsonComponent,
  showMessage
}) => {
  const VerticalEditorContainer = withContext(
    VerticalEditorContainerHoc({ Box, resolveMQ, theme: theme.verticalEditorContainerStyle }),
    { activeMQ: "ActiveMQ" }
  );

  const Editor = EditorHoc({
    Box,
    Container: VerticalEditorContainer,
    resolveVisibleExp,
    getEditor: getFormEditor,
    getAction,
    getFieldDef,
    getFieldHeader,
    getMandatoryMessage
  });

  const FormCard = FormCardHoc({
    Box,
    resolveValue,
    getAggregateValue,
    dataFormat,
    getAction,
    theme: theme.formCardStyle,
    typeFormat: theme.typeFormat
  });

  const FormCardGrid = withContext(
    FormCardGridHoc({ Box, resolveValue, Card: FormCard, resolveMQ, theme: theme.formCardGridStyle }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const FormGroup = withContext(
    FormGroupHoc({
      Box,
      getComponent,
      Editor,
      theme: { ...theme.groupLabelStyle },
      resolveMQ,
      resolveVisibleExp,
      getFieldDef,
      getAction
    }),
    { activeMQ: "ActiveMQ" }
  );

  const TabForm = withContext(TabFormHoc({ getComponent, resolveValue, resolveMQ }), {
    activeMQ:"ActiveMQ"
  });

  const Form = withContext(
    FormHoc({
      Box,
      View,
      ScrollView,
      FormGroup,
      TabForm,
      theme: theme.formStyle,
      resolveMQ,
      ScrollContext
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const NestedForm = NestedEditorHoc({ Component: FormHoc({ Box, Group: FormGroup, ScrollContext }) });

  const NestedModalForm = NestedModalFormHoc({ Box, resolveValue, Component: Form, theme: theme.nestedModalFormStyle });

  const ConnectedForm = withContext(
    ConnectedFormHoc({
      Box,
      Connect,
      getComponent,
      resolveParams,
      getJsonComponent,
      showMessage,
      getActionLayout: ({ title, onClose, action: { text, image, applyText }, onActionClick }) => {
        return {
          headerActions: [
            title,
            { type: "flex" },
            {
              image: getImage("crossIcon"),
              onClick: onClose
            }
          ],
          footerActions: [
            {
              type: "updateForm",
              text: applyText || text,
              image: (image && getImage(image)) || image,
              onClick: onActionClick
            }
          ]
        };
      }
    })
  );

  return {
    FormCard,
    FormCardGrid,
    Editor,
    VerticalEditorContainer,
    FormGroup,
    Form,
    NestedForm,
    TabForm,
    NestedModalForm,
    ConnectedForm
  };
};
