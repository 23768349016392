import React from "react";
import { Box, theme, I18N, getImage, getTotalCacheSize, Platform, ScrollView } from "../../../FsCloudComponent";
import { getSizeFormat } from "client-utility/lib/FileUtility";
import SpaceInfo from "./SpaceInformation";

const { fonts, colors, shadows } = theme;
const { h3, h18_bl, h2_18 } = fonts;
const { primaryColor, highlightColor, brownGrey, themeColor } = colors;

class StorageComponent extends React.Component {
  state = {};

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  componentDidMount() {
    setTimeout(_ => {
      this.setTotalCacheSize();
    }, 500);
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  setTotalCacheSize = async () => {
    if (Platform.OS !== "web") {
      let totalCacheSize = await getTotalCacheSize();
      this._setState({
        totalCacheSize
      });
    }
  };

  render() {
    let { data } = this.props;
    if (data && Array.isArray(data)) {
      data = data[0];
    }
    let trashSpace = "0 B";
    let usedSpace = "0 B";
    let totalSpace = "0 B";
    let nonUploadedSize = "0 B";
    if (data) {
      totalSpace = data.nolimit ? I18N.t("unlimited") : getSizeFormat(data.totalSpace || 0);
      usedSpace = getSizeFormat((data.usedSpace || 0) + (data.trash || 0));
      trashSpace = getSizeFormat(data.trash || 0);
      nonUploadedSize = getSizeFormat(data.nonUploadedSize || 0);
      totalSpace = totalSpace.replace(".", ",");
      usedSpace = usedSpace.replace(".", ",");
      trashSpace = trashSpace.replace(".", ",");
      nonUploadedSize = nonUploadedSize.replace(".", ",");
    }
    return (
      <ScrollView style={{ flex: 1 }} bounces={false}>
        <Box
          render={[
            {
              viewStyle: {
                backgroundColor: highlightColor,
                marginLeft: 12,
                marginRight: 12,
                marginTop: 12,
                marginBottom: 12,
                borderRadius: 4,
                ...shadows.cardShadow,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 15,
                paddingBottom: 15
              },
              render: [
                {
                  direction: "row",
                  viewStyle: {
                    justifyContent: "space-between",
                    alignItems: "center"
                  },
                  render: [
                    {
                      width: "1fr",
                      render: [
                        {
                          textStyle: { ...h3, color: brownGrey },
                          text: I18N.t("storage")
                        },
                        {
                          textStyle: { ...h18_bl, color: themeColor },
                          text: totalSpace
                        },
                        {
                          direction: "row",
                          viewStyle: { paddingTop: 8 },
                          render: [
                            {
                              viewStyle: {
                                height: 6,
                                width: 6,
                                backgroundColor: themeColor,
                                marginRight: 6,
                                borderRadius: 3,
                                alignSelf: "center"
                              }
                            },
                            {
                              textStyle: { ...h2_18, color: primaryColor },
                              text: `${usedSpace} ${I18N.t("inUse")}`
                            }
                          ]
                        },
                        {
                          direction: "row",
                          viewStyle: { paddingTop: 8 },
                          render: [
                            {
                              viewStyle: { paddingLeft: 2, paddingRight: 2 },
                              textStyle: { ...h2_18, color: brownGrey },
                              text: "-"
                            },
                            {
                              textStyle: { ...h2_18, color: brownGrey },
                              text: `${nonUploadedSize} ${I18N.t("inNonUploaded")}`
                            }
                          ]
                        },
                        {
                          direction: "row",
                          viewStyle: { paddingTop: 8 },
                          render: [
                            {
                              viewStyle: { paddingLeft: 2, paddingRight: 2 },
                              textStyle: { ...h2_18, color: brownGrey },
                              text: "-"
                            },
                            {
                              textStyle: { ...h2_18, color: brownGrey },
                              text: `${trashSpace} ${I18N.t("inTheBin")}`
                            }
                          ]
                        },
                        Platform.OS !== "web" && {
                          itemStyle: { paddingTop: 6 },
                          render: [
                            {
                              textStyle: { ...h3, color: brownGrey },
                              text: I18N.t("cacheData")
                            },
                            {
                              textStyle: { ...h2_18, color: primaryColor },
                              text: (this.state.totalCacheSize && this.state.totalCacheSize.replace(".", ",")) || ""
                            }
                          ]
                        }
                      ]
                    },
                    {
                      image: getImage("storageFolderIcon")
                    }
                  ]
                }
              ]
            },
            <SpaceInfo data={data} source="usedSpace" />,
            <SpaceInfo data={data} source="trashedSpace" />
          ]}
        />
      </ScrollView>
    );
  }
}

export default {
  Storage: {
    component: "custom",
    Component: StorageComponent,
    dataObject: "Resource",
    connectProps: {
      uri: {
        props: {
          query: {
            view: "userSpace"
          },
          subscribe: true
        }
      }
    },
    headerActions: [
      {
        type: "multiActions",
        // visible: ({ data }) => (data && data.length && data[0].trash ? true : false),
        action: ["clearNonUploadSM", "clearTrash", "clearCache"]
      }
    ]
  }
};
