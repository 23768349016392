import React from "react";

export default ({ Box, theme, resolveMQ, getImage, ...hocProps }) => {
  const { fonts, colors, bgs, bottomTabHeight } = theme;
  const { h16, h24, h16_l, h9, h9_18 } = fonts;
  const { primaryColor, highlightColor, brownGrey } = colors;
  const { themeBg } = bgs;

  const renderNoDataStyle = {
    containerStyleSM: {
      viewStyle: { alignItems: "center", paddingLeft: 24, paddingRight: 24, paddingBottom: bottomTabHeight }
    },
    containerStyleMD: {
      viewStyle: { flex: 1, alignItems: "center", justifyContent: "center", padding: 24 }
    },
    iconStyleSM: {
      viewStyle: { paddingTop: 24, paddingBottom: 29 }
    },
    iconStyleMD: {
      viewStyle: { paddingBottom: 26 }
    },
    primaryStyleSM: {
      viewStyle: { paddingTop: 6, paddingBottom: 6 },
      textStyle: {
        ...h16,
        color: primaryColor,
        textAlign: "center"
      }
    },
    primaryStyleMD: {
      viewStyle: { paddingTop: 8, paddingBottom: 8, width: "100%" },
      textStyle: {
        ...h24,
        color: primaryColor,
        textAlign: "center"
      }
    },
    secondaryStyleSM: {
      viewStyle: { paddingTop: 8, paddingBottom: 8 },
      textStyle: {
        ...h9_18,
        color: brownGrey,
        textAlign: "center"
      }
    },
    secondaryStyleMD: {
      viewStyle: { paddingTop: 8, paddingBottom: 8, width: "100%" },
      textStyle: {
        ...h16_l,
        color: primaryColor,
        textAlign: "center"
      }
    },
    buttonStyleSM: {
      viewStyle: {
        marginTop: 8,
        marginBottom: 8,
        backgroundColor: themeBg,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 11,
        paddingBottom: 10,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center"
      },
      textStyle: {
        ...h9,
        color: highlightColor
      }
    },
    buttonStyleMD: {
      viewStyle: {
        marginTop: 8,
        marginBottom: 8,
        backgroundColor: themeBg,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 11,
        paddingBottom: 10,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      },
      textStyle: {
        ...h9,
        color: highlightColor
      }
    }
  };

  class RenderNoData extends React.Component {
    state = { contentHeight: 0, scrollHeight: 0 };

    onContentLayout = ({
      nativeEvent: {
        layout: { height }
      }
    }) => {
      if (height !== this.state.contentHeight) {
        this.setState({ contentHeight: height });
      }
    };

    onScrollLayout = ({
      nativeEvent: {
        layout: { height }
      }
    }) => {
      if (height !== this.state.scrollHeight) {
        this.setState({ scrollHeight: height });
      }
    };

    render() {
      let { onClick, activeMQ, panelProps } = this.props;
      let { image, primaryText, secondaryText, buttonText, buttonContainer, buttonContainerProps } = resolveMQ(
        this.props,
        ["image", "primaryText", "secondaryText", "buttonText", "buttonContainer", "buttonContainerProps"],
        activeMQ
      );
      let { containerStyle, iconStyle, primaryStyle, secondaryStyle, buttonStyle } = resolveMQ(
        renderNoDataStyle,
        ["containerStyle", "iconStyle", "primaryStyle", "secondaryStyle", "buttonStyle"],
        activeMQ
      );

      let { ScrollView } = resolveMQ(hocProps, ["ScrollView"], activeMQ);

      let button = buttonText && {
        ...buttonStyle,
        text: buttonText,
        onClick
      };
      if (buttonContainer && button) {
        button.Container = buttonContainer;
        button.containerProps = {
          ...buttonContainerProps
        };
      }

      let boxProps = {};
      if (ScrollView) {
        boxProps.onLayout = this.onContentLayout;
        if (panelProps && panelProps.absoluteHeader) {
          containerStyle = {
            ...containerStyle,
            viewStyle: {
              ...containerStyle.viewStyle,
              paddingBottom: (containerStyle.viewStyle.paddingBottom || 0) + bottomTabHeight
            }
          };
        }
      }

      let renderComponent = (
        <Box
          {...containerStyle}
          {...boxProps}
          render={[
            image && { ...iconStyle, image: getImage(image) },
            primaryText && {
              ...primaryStyle,
              text: primaryText
            },
            secondaryText && {
              ...secondaryStyle,
              text: secondaryText
            },
            button
          ]}
        />
      );
      if (ScrollView) {
        let { contentHeight, scrollHeight } = this.state;
        let minHeight = scrollHeight > contentHeight ? scrollHeight : contentHeight;
        renderComponent = (
          <ScrollView onLayout={this.onScrollLayout} style={{ flex: 1 }}>
            <Box
              viewStyle={{
                flex: 1,
                minHeight: minHeight,
                justifyContent: "center"
              }}
              render={renderComponent}
            />
          </ScrollView>
        );
      }
      return renderComponent;
    }
  }
  return RenderNoData;
};
