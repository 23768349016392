import ProfileRoutes from "./ProfileRoutes";
import HomeRoutes from "./HomeRoutes";
import Configuration from "./Configuration";
import GalleryRoutes from "./GalleryRoutes";
import MusicRoutes from "./MusicRoutes";
import DocsRoutes from "./DocsRoutes";
import GroupRoutes from "./GroupRoutes";
import ResourceRoutes from "./ResourceRoutes";
import AlbumResourceRoutes from "./AlbumResourceRoutes";
import UploadGalleryRoutes from "./UploadGalleryRoutes";
import LoginRoutes from "./LoginRoutes";
import AllFilesRoutes from "./AllFilesRoutes";
import SharedRoutes from "./SharedRoutes";
import TrashCanRoutes from "./TrashCanRoutes";
import BackupRoutes from "./BackupRoutes";
import FreeUpSpaceRoutes from "./FreeUpSpaceRoutes";
import SearchRoutes from "./SearchRoutes";
import CollaborationRoutes from "./CollaborationRoutes";
import Connections from "./Connections";
import IssuesRoutes from "./Issues";
import VaultRoutes from "./VaultRoutes";
import FailedItemsRoutes from "./FailedItemsRoutes";
import ArchiveRoutes from "./ArchiveRoutes";
import DocumentScannerRoutes from "./DocumentScannerRoutes";
import LocalStats from "./LocalStatsRoutes";
import NonUploadedItemsRoutes from "./NonUploadedItemsRoutes";

export default {
  ...HomeRoutes,
  ...GalleryRoutes,
  ...MusicRoutes,
  ...ProfileRoutes,
  ...Configuration,
  ...DocsRoutes,
  ...GroupRoutes,
  ...ResourceRoutes,
  ...AlbumResourceRoutes,
  ...UploadGalleryRoutes,
  ...LoginRoutes,
  ...AllFilesRoutes,
  ...SharedRoutes,
  ...TrashCanRoutes,
  ...BackupRoutes,
  ...FreeUpSpaceRoutes,
  ...CollaborationRoutes,
  ...SearchRoutes,
  ...Connections,
  ...IssuesRoutes,
  ...VaultRoutes,
  ...FailedItemsRoutes,
  ...ArchiveRoutes,
  ...DocumentScannerRoutes,
  ...LocalStats,
  ...NonUploadedItemsRoutes
};
