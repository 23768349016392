import { I18N } from "../../../FsCloudComponent";
import { GroupNoData } from "../noData/NoData";

export default {
  GroupNoData: {
    component: "table",
    Component: GroupNoData,
    titleSM: () => I18N.t("app-title"),
    titleMD: () => I18N.t("group")
  },
  GroupTabSM: {
    component: "tab",
    title: () => I18N.t("app-title"),
    showTitleInTab: true,
    swipeable: true,
    tabs: {
      groupGallery: {
        tab: {
          text: () => I18N.t("gallery")
        },
        screen: "GroupGallery",
        absoluteHeader: true
      },
      groupDocs: {
        tab: {
          text: () => I18N.t("docs")
        },
        screen: "GroupDocs",
        absoluteHeader: true
      },
      groupMembers: {
        tab: {
          text: () => I18N.t("members")
        },
        screen: "GroupMembersSM",
        absoluteHeader: true
      }
    }
  },
  GroupTabMD: {
    component: "tab",
    title: () => I18N.t("group"),
    tabs: {
      groupGallery: {
        tab: {
          text: () => I18N.t("gallery")
        },
        screen: "GroupGalleryWeb"
      },
      groupDocs: {
        tab: {
          text: () => I18N.t("docs")
        },
        screen: "GroupDocs"
      },
      groupMembers: {
        tab: {
          text: () => I18N.t("members")
        },
        screen: "GroupMembersMD"
      }
    }
  }
};
