import React from "react";

export default ({
  dataObjects = {},
  views = {},
  getUri,
  ConnectPanelTable,
  Table,
  resolveHeaderActions,
  resolveActionLayout,
  resolveMQ,
  getComponent,
  resolveLinkDefaults
}) => {
  const loader = ({
    user,
    screen,
    screenDef,
    link,
    state,
    activeMQ,
    panelProps = {},
    hashMap,
    panelActionsRequired,
    mergeHeaderActions,
    route
  }) => {
    try {
      let { tableProps: screenTableProps } = screenDef;
      let {
        dataObject,
        fetch,
        fields,
        groups,
        defaultGroup,
        actions,
        layouts,
        linkFilter,
        linkHeaderFilter,
        linkHiddenFields,
        connectProps: screenConnectProps,
        notifyOnChange,
        updateProps,
        expanded,
        updatable,
        defaultConnectState,
        panelProps: screenPanelProps,
        ...restScreenDef
      } = screenDef;
      let {
        headerActions,
        Component,
        componentType,
        selectable = true,
        hideNavHeader,
        hideStatusBar,
        statusBarTheme,
        title,
        navTitle,
        componentProps: customComponentProps
      } = resolveMQ(
        screenDef,
        [
          "headerActions",
          "Component",
          "componentProps",
          "componentType",
          "selectable",
          "hideNavHeader",
          "hideStatusBar",
          "statusBarTheme",
          "title",
          "navTitle"
        ],
        activeMQ
      );

      let schema = {};
      if (dataObject) {
        schema = dataObjects[dataObject];
        if (!schema) {
          throw new Error(
            `Data object not found with name [${dataObject}]. Availale dataobjects are ${JSON.stringify(
              Object.keys(dataObjects)
            )}`
          );
        }
        groups = groups || schema.groups;
        fields = fields || schema.fields;
        actions = actions || schema.actions;
        layouts = layouts || schema.layouts;
        linkFilter = linkFilter || schema.linkFilter;
        linkHeaderFilter = linkHeaderFilter || schema.linkHeaderFilter;
        linkHiddenFields = linkHiddenFields || schema.linkHiddenFields;
        updateProps = updateProps || schema.updateProps;
      }
      const filterData = resolveLinkDefaults({ link, linkDefaults: linkHeaderFilter });
      if (mergeHeaderActions) {
        headerActions = mergeHeaderActions(headerActions);
      }
      headerActions = resolveHeaderActions({
        headerActions,
        actions,
        fields,
        groups,
        filterData
      });
      let hiddenFields = void 0;
      if (link && link.props && link.props.params && linkHiddenFields) {
        let params = link.props.params;

        for (let linkDataObject in linkHiddenFields) {
          if (params[linkDataObject]) {
            //hide these fields
            hiddenFields = hiddenFields || {};
            let definedHiddenFields = linkHiddenFields[linkDataObject];
            if (definedHiddenFields && !Array.isArray(definedHiddenFields)) {
              definedHiddenFields = definedHiddenFields.fields || [];
            }
            for (let hiddenField of definedHiddenFields) {
              hiddenFields[hiddenField] = 1;
            }
          }
        }
      }

      const schemaToPass = {
        fields,
        actions,
        layouts,
        hiddenFields,
        groups
      };

      if (defaultConnectState) {
        if (defaultConnectState.dataParams) {
          defaultConnectState = { ...defaultConnectState, dataParams: { ...defaultConnectState.dataParams } };
        } else {
          defaultConnectState = { ...defaultConnectState, dataParams: {} };
        }
      } else {
        defaultConnectState = { dataParams: {} };
      }

      if (defaultGroup) {
        defaultConnectState = {
          ...defaultConnectState,
          dataParams: {
            ...defaultConnectState.dataParams,
            groupBy: {
              group: defaultGroup,
              value: groups[defaultGroup].group
            }
          }
        };
      }
      let tableProps = { ...restScreenDef };

      let RenderComponent = Table;
      let componentProps = { ...customComponentProps, hashMap };
      if (componentType) {
        if (typeof componentType === "object") {
          componentProps = componentType[Object.keys(componentType)[0]];
          componentType = Object.keys(componentType)[0];
        }
        RenderComponent = getComponent(componentType);
        if (!RenderComponent) {
          throw new Error(`TableScreenLoader -> componentType [${componentType}] not passed`);
        }
      } else if (Component) {
        //expecting Component from screen def and need to pass updateProps, use case : in caht Screen /paritosh - 28 jan 2019
        RenderComponent = Component;
        componentType = "custom";
        componentProps["updateProps"] = updateProps;
        componentProps["link"] = link;
      }
      if (groups) {
        tableProps["groups"] = groups;
      }
      let linkTitle = link && link.props && link.props.title;

      if (typeof screenConnectProps === "function") {
        screenConnectProps = screenConnectProps({ link, user, hashMap });
        if (screenConnectProps && screenConnectProps.fetch) {
          screenConnectProps = { ...screenConnectProps };
          fetch = screenConnectProps.fetch;
          delete screenConnectProps.fetch;
        }
      }

      const uri = getUri({ dataObject, views, fetch, link, linkFilter });
      let connectProps = {
        uri,
        updatable,
        groups,
        notifyOnChange,
        ...screenConnectProps
      };
      const screenProps = {
        screenState: state,
        connect: connectProps,
        title: linkTitle || navTitle || title,
        expanded,
        hideNavHeader,
        hideStatusBar,
        statusBarTheme
      };

      //need to send same connectProps in screenProps (required by tab count), but here we do not need to load count for ConnectPanelTable query
      //rohit with subhash, we do not need to load aggregates again for tab query
      // if (source && source === "TabScreenLoader") {
      //   let query = uri && uri.props && uri.props.query;
      //   if (query) {
      //     query = { ...query, skipAggregates: true };
      //     connectProps = {
      //       ...connectProps,
      //       uri: { ...uri, props: { ...uri.props, query } }
      //     };
      //   }
      // }

      let { panelFooter, panelHeader, navHeader, navLeft, navFooter, tableHeader } = resolveActionLayout({
        actions: headerActions,
        activeMQ
      });

      let multiRowActions = void 0;
      if (tableHeader && tableHeader.right && tableHeader.right.length) {
        // it should be a multiAction. pass it to table
        multiRowActions = tableHeader.right[0].action;
        tableProps["headerRowProps"] = { multiRowActions, selectable };
      }
      tableProps["selectable"] = selectable;
      tableProps["multiRowActions"] = multiRowActions;
      schemaToPass.selectable = selectable;
      panelProps = {
        ...panelProps,
        ...screenPanelProps,
        title
      };
      if (panelFooter) {
        panelProps.footerActions = panelFooter;
      }
      if (panelHeader) {
        if (panelActionsRequired) {
          screenProps.panelHeaderActions = panelHeader;
        } else {
          panelProps.headerActions = panelHeader;
        }
      }
      if (navLeft) {
        screenProps.navLeftActions = navLeft;
      }
      if (navFooter) {
        screenProps.navFooterActions = navFooter;
      }
      if (navHeader) {
        screenProps.navHeaderActions = navHeader;
      }
      screenProps.component = (
        <ConnectPanelTable
          componentType="table"
          subComponentType={componentType}
          schema={schemaToPass}
          connectProps={connectProps}
          panelProps={panelProps}
          Component={RenderComponent}
          defaultConnectState={defaultConnectState}
          componentProps={{ ...screenTableProps, ...tableProps, ...componentProps }}
          screenName={screen}
          selectable={selectable}
          link={link}
          route={route}
        />
      );
      return screenProps;
    } catch (e) {
      throw e;
    }
  };
  return {
    loader
  };
};
