import React from "react";
import { getComponent } from "./../../FsCloudComponent";
import { setGlobal } from "./../../FsCloudAppServices";
import WebBrandList from "../../BrandListWeb";
export default ({
  LoginHeader,
  Box,
  Editor,
  theme,
  I18N,
  resolveMQ,
  Button,
  ChatLinkComponent,
  getErrorMessage,
  logFirebaseAnalyticsEvent
}) => {
  const { colors, fonts } = theme;
  const { highlightColor, primaryColor, brownGrey, themeColor, registrationColor } = colors;
  const { h20, h16_l, h16, h1, h16_18, h2_16 } = fonts;

  const styles = {
    styleLG: {
      bodyContainerStyle: {
        viewStyle: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: highlightColor
        }
      },
      primaryTextStyle: {
        viewStyle: {
          marginTop: 12,
          marginBottom: 12
        },
        textStyle: {
          ...h20,
          color: primaryColor,
          textAlign: "center"
        }
      },
      secondaryTextStyle: {
        viewStyle: {
          marginBottom: 12
        },
        textStyle: {
          ...h16_l,
          color: brownGrey,
          textAlign: "center"
        }
      },
      editorContainerStyle: {
        viewStyle: {
          marginTop: 12
        }
      },
      captchaContainerStyle: {
        viewStyle: {
          marginTop: 12
        }
      },
      buttonContainerStyle: {
        viewStyle: {
          marginTop: 12,
          marginBottom: 12
        }
      }
    },
    styleMD: {
      bodyContainerStyle: {
        viewStyle: {
          paddingTop: 40,
          paddingBottom: 40,
          paddingLeft: 30,
          paddingRight: 30,
          backgroundColor: highlightColor
        }
      },
      primaryTextStyle: {
        viewStyle: {
          marginTop: 10,
          marginBottom: 8
        },
        textStyle: {
          ...h16,
          color: primaryColor,
          textAlign: "center"
        }
      },
      secondaryTextStyle: {
        textStyle: {
          ...h1,
          color: brownGrey,
          textAlign: "center"
        }
      },
      editorContainerStyle: {
        viewStyle: {
          marginTop: 50
        }
      },
      captchaContainerStyle: {
        viewStyle: {
          marginTop: 12
        }
      },
      buttonContainerStyle: {
        viewStyle: {
          marginTop: 16,
          marginBottom: 16
        }
      }
    }
  };

  const registrationStyles = {
    registrationStyleLG: {
      primaryTextStyle: {
        viewStyle: {
          marginBottom: 4,
          alignItems: "center",
          justifyContent: "center"
        },
        textStyle: { ...h16, color: themeColor }
      },
      secondaryTextStyle: {
        viewStyle: {
          marginTop: 4,
          marginBottom: 12,
          alignItems: "center",
          justifyContent: "center"
        },
        textStyle: { ...h16_l, color: brownGrey }
      },
      buttonTextStyle: {
        viewStyle: {
          marginTop: 12,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 4,
          backgroundColor: registrationColor,
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer"
        },
        textStyle: { ...h16_18, color: highlightColor }
      }
    },
    registrationStyleMD: {
      primaryTextStyle: {
        viewStyle: {
          marginBottom: 4,
          alignItems: "center",
          justifyContent: "center"
        },
        textStyle: { ...h16, color: themeColor }
      },
      secondaryTextStyle: {
        viewStyle: {
          marginTop: 4,
          marginBottom: 12,
          alignItems: "center",
          justifyContent: "center"
        },
        textStyle: { ...h1, color: brownGrey, textAlign: "center" }
      },
      buttonTextStyle: {
        viewStyle: {
          marginTop: 12,
          paddingTop: 15,
          paddingBottom: 15,
          borderRadius: 2.9,
          backgroundColor: registrationColor,
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer"
        },
        textStyle: { ...h2_16, color: highlightColor }
      }
    }
  };

  class WebRegisterComponent extends React.Component {
    render() {
      let { loginContainerProps: { data } = {}, setState, activeMQ } = this.props;
      const { registrationStyle } = resolveMQ(registrationStyles, ["registrationStyle"], activeMQ);
      return (
        <Box
          render={[
            {
              ...registrationStyle.primaryTextStyle,
              text: I18N.t("noAccountYet")
            },
            {
              ...registrationStyle.secondaryTextStyle,
              text: I18N.t("needSomeInformation")
            },
            {
              onClick: () => {
                if (data && data.mobile) {
                  data.mobile = void 0;
                }
                setState &&
                  setState({
                    viewType: "register-email"
                  });
              },
              ...registrationStyle.buttonTextStyle,
              text: I18N.t("enterInformation")
            }
          ]}
        />
      );
    }
  }

  class WebLogin extends React.Component {
    state = {
      chosen: false
    };

    _setState = state => {
      this.setState(state);
    };

    onError = err => {
      let message = getErrorMessage(err);
      this._setState({ error: message });
    };

    sendSMS = () => {
      try {
        let { loginContainerProps: { data, onSubmit } = {}, setState, state: { fromLandline } = {} } = this.props;
        if (this.state.error) {
          this.setState({ error: void 0 });
        }
        let { mobile } = data;
        logFirebaseAnalyticsEvent &&
          logFirebaseAnalyticsEvent({ screen: "login", event: "phone_no", params: { mobile } });
        onSubmit &&
          onSubmit(
            "sendSMS",
            { mobile, fromLandline },
            {
              afterSubmit: result => {
                setState &&
                  setState({
                    viewType: "validateOtp",
                    transaction_id: result.transaction_id,
                    mobile
                  });
              },
              throwError: true
            }
          ).catch(err => {
            this.onError(err);
          });
      } catch (err) {
        this.onError(err);
      }
    };

    setFirebaseApp = firebaseAppName => {
      setGlobal(firebaseAppName);
      localStorage.setItem("firebaseInstance", firebaseAppName);
      this.setState({ chosen: true });
    };

    render() {
      const { activeMQ, loginContainerProps = {}, state } = this.props;
      const { style } = resolveMQ(styles, ["style"], activeMQ);
      let editorProps = {
        ...loginContainerProps,
        state: this.state,
        setState: this._setState
      };

      const collumnA = [
        {
          firebaseName: "TERTIARY_APP",
          brand: "tim",
          logo: require("./../../../images/tim_logo.png"),
          label: "tim"
        },
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/agility_logo.png"),
          label: "agility"
        },
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/algar_logo.png"),
          label: "algar"
        },
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/bradesco_logo.png"),
          label: "bradesco"
        },
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/brisanet_logo.png"),
          label: "brisanet"
        }
      ];
      const collumnB = [
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/desktop.png"),
          label: "desktop"
        },
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/exa_logo.png"),
          label: "exa"
        },
        {
          firebaseName: "SECONDARY_APP",
          brand: "oi",
          logo: require("./../../../images/logo_oi.png"),
          label: "oi"
        },
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/sumicity_logo.png"),
          label: "sumicity"
        },
        {
          firebaseName: "[DEFAULT]",
          brand: "hero",
          logo: require("./../../../images/outro_logo.png"),
          label: "outro"
        }
      ];

      return (
        <>
          {!this.state.chosen && (
            <Box
              render={[
                LoginHeader && <LoginHeader onClose={this.props.onClose} />,
                {
                  ...style.bodyContainerStyle,
                  render: [
                    {
                      ...style.primaryTextStyle,
                      text: "Escolha com qual plano você deseja continuar acessando."
                    },
                    {
                      ...style.bodyContainerStyle,
                      direction: "row",

                      ...style.iconCloudBoxStyle,
                      viewStyle: { justifyContent: "center", alignItems: "center" },
                      render: [
                        <WebBrandList data={collumnA} setFirebaseApp={this.setFirebaseApp} setState={this.setState} />,
                        <WebBrandList data={collumnB} setFirebaseApp={this.setFirebaseApp} setState={this.setState} />
                      ]
                    },

                    state.registrationRequired && <WebRegisterComponent {...this.props} />,
                    {
                      viewStyle: { alignItems: "center", marginTop: 30 },
                      render: <ChatLinkComponent />
                    }
                  ]
                }
              ]}
            />
          )}

          {this.state.chosen && (
            <Box
              render={[
                LoginHeader && <LoginHeader onClose={this.props.onClose} />,
                {
                  ...style.bodyContainerStyle,
                  render: [
                    {
                      ...style.primaryTextStyle,
                      text:
                        state && state.fromLandline ? I18N.t("loginPrimaryLandLineText") : I18N.t("loginPrimaryText")
                    },
                    {
                      ...style.secondaryTextStyle,
                      text: I18N.t("loginSecondaryText")
                    },
                    {
                      ...style.editorContainerStyle,
                      render: (
                        <Editor
                          {...editorProps}
                          field={"mobile"}
                          placeholder={"mobilePlaceholder"}
                          onClick={this.sendSMS}
                        />
                      )
                    },
                    {
                      ...style.captchaContainerStyle,
                      render: (
                        <Editor {...editorProps} field={"captchaVerify"} editorTheme={theme.reCaptchaContainerStyle} />
                      )
                    },
                    {
                      ...style.buttonContainerStyle,
                      render: (
                        <Button
                          loading={loginContainerProps.loading}
                          onClick={this.sendSMS}
                          text={"login"}
                          loaderText={"logging"}
                        />
                      )
                    },
                    state.registrationRequired && <WebRegisterComponent {...this.props} />,
                    {
                      viewStyle: { alignItems: "center", marginTop: 30 },
                      render: <ChatLinkComponent />
                    }
                  ]
                }
              ]}
            />
          )}
        </>
      );
    }
  }
  return WebLogin;
};
