export default ({ fonts, colors, images, bgs, I18N }) => {
  const { primaryColor, secondaryColor, themeColor, highlightColor, brownGrey, paleLavender, greyishBrownTwo } = colors;
  const { h1, h2, h20, h9, h9_21, h9_24, h18_bl, h16_l, h18 } = fonts;
  const { iconMenuGrey, crossIcon, radioUnselectedIcon, radioSelectedIcon, checkedIcon, uncheckedIcon } = images;
  const { primaryBg2, lightThemeBg, veryLightPink } = bgs;

  const minWidthFrMD = 150;
  const minWidthFrSM = null;

  const tableBodyStyle = {
    flex: 1,
    marginTop: 6
  };

  const tableBodyStyleMD = {
    flex: 1,
    paddingRight: 80
  };

  const tableHeaderSelectionBoxStyle = {
    boxStyle: {
      direction: "row"
    }
  };

  const tableRowSelectionBoxStyle = {
    highlightIcon: {
      checkedIcon: checkedIcon,
      uncheckedIcon: uncheckedIcon
    }
  };

  const selectAllBoxStyle = {
    pointerStyleMD: {
      pointerEvents: "auto",
      cursor: "pointer",
      textDecoration: "underline"
    },
    pointerStyleSM: {},
    modalStyle: {
      position: "absolute",
      top: 0,
      padding: 10
    }
  };

  const tableRowStyle = {
    viewStyle: {
      flexDirection: "row",
      overflow: void 0
    }
  };

  const menuItemsStyle = {
    boxStyleSM: {
      viewStyle: { backgroundColor: "white", paddingTop: 6, paddingBottom: 6 },
      itemStyle: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        cursor: "pointer"
      },
      textStyle: {
        ...h9,
        color: primaryColor,
        numberOfLines: 1
      }
    },
    boxStyleMD: {
      viewStyle: { backgroundColor: "white", paddingTop: 4, paddingBottom: 4 },
      itemStyle: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        paddingBottom: 12,
        cursor: "pointer"
      },
      textStyle: {
        ...h16_l,
        color: primaryColor,
        numberOfLines: 1
      }
    },
    menuItemStyleSM: {
      iconStyle: {
        viewStyle: {
          width: 24,
          height: 24,
          alignItems: "center",
          justifyContent: "center",
          marginRight: 16
        }
      },
      titleStyle: {
        width: "1fr"
      }
    },
    menuItemStyleMD: {
      iconStyle: {
        viewStyle: {
          width: 24,
          height: 24,
          alignItems: "center",
          justifyContent: "center",
          marginRight: 16
        }
      },
      titleStyle: {
        width: "1fr"
      }
    }
  };

  const onlyActionStyle = {
    containerStyleSM: {
      imageStyle: {
        viewStyle: {
          height: 44,
          width: 44,
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }
      },
      textStyle: {
        viewStyle: {
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
          cursor: "pointer",
          borderRadius: 2,
          marginLeft: 4,
          marginRight: 4,
          backgroundColor: lightThemeBg
        },
        textStyle: {
          ...h2,
          color: themeColor
        }
      }
    },
    containerStyleMD: {
      imageStyle: {
        viewStyle: {
          width: 48,
          height: 44,
          paddingLeft: 12,
          paddingRight: 12,
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }
      },
      textStyle: {
        viewStyle: {
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
          cursor: "pointer",
          backgroundColor: lightThemeBg
        },
        textStyle: {
          ...h2,
          color: themeColor
        }
      }
    }
  };

  const imageRowStyle = {
    selectedImageContainerStyle: {
      opacity: 0.5
    },
    selectedImageStyleSM: {
      image: images.tickIcon,
      viewStyle: {
        position: "absolute",
        top: 8,
        right: 8
      }
    },
    selectedImageStyleMD: {
      image: images.tickIcon,
      viewStyle: {
        position: "absolute",
        top: 12,
        left: 12
      }
    }
  };

  const rowActionStyle = {
    boxStyle: {
      viewStyle: {
        paddingLeft: 4,
        paddingRight: 4,
        alignItems: "center",
        justifyContent: "center"
      },
      image: iconMenuGrey
    },
    confirmBoxModalStyleSM: {
      modal: {
        autoHide: true,
        stopPropagation: true,
        width: 0.9,
        animationType: "none",
        style: { borderRadius: 4, overflow: "hidden" }
      }
    },
    confirmBoxModalStyleMD: {
      modal: { autoHide: true, stopPropagation: true, style: { borderRadius: 4 } }
    },
    confirmMessage: "Are you sure ?",
    menuModalStyleSM: {
      modal: {
        position: "cover",
        stopPropagation: true,
        autoHide: true,
        width: 260,
        animationType: "none",
        style: { borderRadius: 4, overflow: "hidden" }
      }
    },
    menuModalStyleMD: {
      modal: {
        stopPropagation: true,
        width: 237,
        position: "cover",
        autoHide: true,
        style: { borderRadius: 4 }
      }
    },
    formModalStyle: {
      modalSM: {
        stopPropagation: true,
        autoHide: true,
        position: "center",
        width: 0.8,
        maxHeight: 500,
        animationType: "none",
        style: { borderRadius: 4, overflow: "hidden" }
      },
      modalMD: {
        stopPropagation: true,
        autoHide: true,
        position: "screenRight",
        width: 0.3
      }
    },
    formStyle: {
      viewStyle: { backgroundColor: primaryBg2, flex: 1 }
    }
  };

  const confirmBoxStyle = {
    boxStyleSM: {
      viewStyle: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        paddingBottom: 14,
        backgroundColor: primaryBg2
      }
    },
    boxStyleMD: {
      viewStyle: {
        width: 486,
        backgroundColor: primaryBg2
      }
    },
    titleStyleSM: {
      direction: "row",
      viewStyle: {
        paddingBottom: 18
      },
      textStyle: {
        ...h18,
        color: primaryColor
      }
    },
    titleStyleMD: {
      direction: "row",
      viewStyle: {
        height: 77,
        paddingLeft: 30,
        alignItems: "center",
        borderBottomWidth: 2,
        borderColor: veryLightPink
      },
      textStyle: {
        ...h20,
        color: themeColor
      }
    },
    crossStyleMD: {
      viewStyle: {
        paddingLeft: 24,
        paddingRight: 30,
        width: 78,
        height: 48,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      },
      image: crossIcon
    },
    messageStyleSM: {
      viewStyle: {
        paddingBottom: 24
      },
      textStyle: {
        ...h9_24,
        color: greyishBrownTwo
      }
    },
    messageStyleMD: {
      viewStyle: {
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 30,
        paddingRight: 30
      },
      textStyle: {
        ...h9_21,
        color: primaryColor
      }
    },
    buttonBoxStyleSM: {
      direction: "row",
      viewStyle: {
        paddingLeft: 14,
        paddingRight: 14,
        justifyContent: "flex-end"
      }
    },
    buttonBoxStyleMD: {
      direction: "row",
      viewStyle: {
        paddingBottom: 24,
        paddingLeft: 30,
        paddingRight: 30,
        justifyContent: "flex-end"
      }
    },
    cancelButtonStyleSM: {
      viewStyle: {
        cursor: "pointer",
        marginRight: 40,
        paddingTop: 6,
        paddingBottom: 6
      },
      textStyle: {
        ...h1,
        color: secondaryColor
      },
      text: () => I18N.t("cancel")
    },
    cancelButtonStyleMD: {
      viewStyle: {
        cursor: "pointer",
        marginRight: 24,
        alignSelf: "center"
      },
      textStyle: {
        ...h9,
        color: themeColor
      },
      text: () => I18N.t("cancel")
    },
    confirmButtonStyleSM: {
      viewStyle: {
        cursor: "pointer",
        paddingTop: 6,
        paddingBottom: 6
      },
      textStyle: {
        ...h1,
        color: themeColor
      },
      text: () => I18N.t("confirm")
    },
    confirmButtonStyleMD: {
      viewStyle: {
        cursor: "pointer",
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: themeColor,
        borderRadius: 4
      },
      textStyle: {
        ...h9,
        color: highlightColor
      },
      text: () => I18N.t("confirm")
    }
  };

  const PermissionConfirmBoxStyle = {
    boxStyleSM: {
      viewStyle: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        paddingBottom: 14,
        backgroundColor: primaryBg2
      }
    },
    boxStyleMD: {
      viewStyle: {
        width: 486,
        backgroundColor: primaryBg2
      }
    },
    titleStyleSM: {
      direction: "row",
      viewStyle: {
        paddingBottom: 18
      },
      textStyle: {
        ...h18,
        color: primaryColor
      }
    },
    titleStyleMD: {
      direction: "row",
      viewStyle: {
        height: 77,
        paddingLeft: 30,
        alignItems: "center",
        borderBottomWidth: 2,
        borderColor: veryLightPink
      },
      textStyle: {
        ...h20,
        color: themeColor
      }
    },
    crossStyleMD: {
      viewStyle: {
        paddingLeft: 24,
        paddingRight: 30,
        width: 78,
        height: 48,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      },
      image: crossIcon
    },
    messageStyleSM: {
      viewStyle: {
        paddingBottom: 24
      },
      textStyle: {
        ...h9_24,
        color: greyishBrownTwo
      }
    },
    messageStyleMD: {
      viewStyle: {
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 30,
        paddingRight: 30
      },
      textStyle: {
        ...h9_21,
        color: primaryColor
      }
    },
    buttonBoxStyleSM: {
      direction: "column",
      viewStyle: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
      }
    },
    buttonBoxStyleMD: {
      direction: "row",
      viewStyle: {
        paddingBottom: 24,
        paddingLeft: 30,
        paddingRight: 30,
        justifyContent: "flex-end"
      }
    },
    cancelButtonStyleSM: {
      viewStyle: {
        cursor: "pointer",
        paddingTop: 16,
        paddingBottom: 16
      },
      textStyle: {
        ...h1,
        color: secondaryColor
      },
      text: () => I18N.t("cancel")
    },
    cancelButtonStyleMD: {
      viewStyle: {
        cursor: "pointer",
        marginRight: 24,
        alignSelf: "center"
      },
      textStyle: {
        ...h9,
        color: themeColor
      },
      text: () => I18N.t("cancel")
    },
    confirmButtonStyleSM: {
      viewStyle: {
        cursor: "pointer",
        paddingTop: 6,
        paddingBottom: 6
      },
      textStyle: {
        ...h1,
        color: themeColor
      },
      text: () => I18N.t("confirm")
    },
    confirmButtonStyleMD: {
      viewStyle: {
        cursor: "pointer",
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: themeColor,
        borderRadius: 4
      },
      textStyle: {
        ...h9,
        color: highlightColor
      },
      text: () => I18N.t("confirm")
    }
  };

  const imageViewerHeaderMenuItemsStyle = {
    boxStyle: {
      viewStyle: { backgroundColor: "rgb(169,169,169)", paddingTop: 6, paddingBottom: 6 },
      itemStyle: {
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 6,
        paddingBottom: 6,
        cursor: "pointer"
      },
      textStyle: {
        ...h2,
        color: "white",
        numberOfLines: 1
      }
    }
  };

  const listLayoutSelectorStyle = {
    containerStyle: {
      viewStyle: {
        paddingTop: 10,
        paddingBottom: 21
      }
    },
    headerStyle: {
      viewStyle: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 15,
        paddingBottom: 15
      },
      textStyle: {
        ...h18_bl
      },
      text: () => I18N.t("layout")
    },
    bodyStyle: {
      itemContainerStyle: {
        direction: "row",
        viewStyle: {
          paddingLeft: 26,
          paddingRight: 24,
          paddingTop: 9,
          paddingBottom: 9,
          alignItems: "center"
        }
      },
      iconStyle: {
        image: radioUnselectedIcon
      },
      selectedIconStyle: {
        image: radioSelectedIcon
      },
      titleStyle: {
        viewStyle: {
          paddingLeft: 15
        },
        textStyle: {
          ...h9,
          color: brownGrey
        }
      }
    }
  };

  const scrollIndicatorStyle = {
    containerStyle: {
      viewStyle: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 8,
        justifyContent: "center",
        alignItems: "center",
        elevation: 1,
        zIndex: 2000
      }
    },
    titleStyle: {
      viewStyle: {
        width: 200,
        backgroundColor: primaryColor,
        alignItems: "center",
        justifyContent: "center",
        padding: 5,
        borderRadius: 5
      },
      textStyle: { ...h1, color: highlightColor }
    }
  };

  const tableHeaderRowStyleMD = {
    viewStyle: {
      height: 56,
      flexDirection: "row",
      marginLeft: 15,
      marginRight: 95
    },
    itemStyle: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 16,
      paddingBottom: 16,
      justifyContent: "center"
    },
    textStyle: {
      ...h16_l,
      numberOfLines: 1,
      color: primaryColor
    },
    rowActionStyle: {
      viewStyle: {
        paddingLeft: 4,
        paddingRight: 4,
        width: 16
      }
    }
  };

  const searchtableHeaderRowStyleMD = {
    viewStyle: {
      height: 56,
      flexDirection: "row",
      marginLeft: 95,
      marginRight: 95,
      marginTop: 12
    },
    itemStyle: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 16,
      paddingBottom: 16,
      justifyContent: "center"
    },
    textStyle: {
      ...h16_l,
      numberOfLines: 1,
      color: primaryColor
    },
    rowActionStyle: {
      viewStyle: {
        paddingLeft: 4,
        paddingRight: 4,
        width: 16
      }
    }
  };

  const tableRowStyleMD = {
    nativeViewStyle: {
      overflow: void 0
    },
    viewStyle: {
      paddingLeft: 15,
      paddingRight: 15,
      borderBottomWidth: 1
    },
    selectedViewStyle: {
      borderRadius: 4,
      backgroundColor: paleLavender
    },
    itemStyle: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 9,
      paddingBottom: 9,
      justifyContent: "center"
    }
  };

  return {
    tableBodyStyle,
    tableBodyStyleMD,
    tableHeaderSelectionBoxStyle,
    tableRowSelectionBoxStyle,
    tableRowStyle,
    menuItemsStyle,
    onlyActionStyle,
    minWidthFrSM,
    minWidthFrMD,
    selectAllBoxStyle,
    imageRowStyle,
    imageViewerHeaderMenuItemsStyle,
    listLayoutSelectorStyle,
    scrollIndicatorStyle,
    tableRowStyleMD,
    tableHeaderRowStyleMD,
    searchtableHeaderRowStyleMD,
    rowActionStyle,
    confirmBoxStyle,
    PermissionConfirmBoxStyle
  };
};
