import React from "react";
export default ({
  errorComponent,
  writeLog,
  routeStateKey,
  tabStateKey,
  selectedTabStateKey,
  ScreenContextProvider,
  getImage,
  getComponent,
  resolveMQ,
  logFirebaseAnalyticsScreenEvent,
  withContext
}) => {
  class TabWithStateInternal extends React.Component {
    tabStateSetters = {};
    constructor(props) {
      super(props);
      let { selectedTab, state, isNestedTab } = this.props || {};
      if (isNestedTab) {
        this.state = state;
      } else {
        this.state = {};
      }
      logFirebaseAnalyticsScreenEvent &&
        logFirebaseAnalyticsScreenEvent({ tab: selectedTab, isNestedTab: isNestedTab });
    }

    _getPState = () => {
      const { state } = this.props;
      return state.pstate;
    };

    _setState = state => {
      if (this.props.isNestedTab && this.props.setStateNestedTab) {
        this.setState(state);
      } else {
        this.props.setState(state);
      }
    };

    _getState = () => {
      if (this.props.isNestedTab && this.props.setStateNestedTab) {
        return this.state;
      } else {
        return this.props.state;
      }
    };

    getSelectedTab = () => {
      if (this.props.isNestedTab && this.props.setStateNestedTab) {
        return this.state.selectedTab || this.props.selectedTab;
      } else {
        return this.props.selectedTab;
      }
    };

    selectTab = (e, { selectedTab }) => {
      let { replaceUri, link = {}, isNestedTab, tabs } = this.props;
      if (selectedTab === this.getSelectedTab()) {
        return;
      }
      let replaceLink = {
        ...link,
        props: {
          ...link.props
        }
      };
      if (isNestedTab) {
        if (this.props.setStateNestedTab) {
          this._setState({ selectedTab: selectedTab });
        } else {
          replaceLink.nestedTab = selectedTab;
          replaceUri(replaceLink);
        }
      } else {
        let selectedTabInfo = tabs && tabs[selectedTab];
        let { pushStateInHistory } = selectedTabInfo || {};
        let uri = replaceLink.uri;
        // add selected tab on url as hash - rohit bansal - 14-05-19
        let hashIndex = uri.indexOf("#");
        if (hashIndex > 0) {
          uri = uri.substring(0, hashIndex);
        }
        uri = uri + "#" + selectedTab;
        replaceLink.uri = uri;
        replaceLink.nestedTab = "";
        replaceLink.props.cstate = { [tabStateKey]: {} };
        replaceUri(replaceLink, pushStateInHistory ? { setUrl: { push: true } } : void 0);
      }
      logFirebaseAnalyticsScreenEvent && logFirebaseAnalyticsScreenEvent({ tab: selectedTab });
    };

    setTabState = ({ tabKey }) => {
      return _state => {
        this._setState(state => {
          let tabKeyState = state[tabStateKey][tabKey];
          return {
            [tabStateKey]: {
              ...state[tabStateKey],
              [tabKey]: { ...tabKeyState, ...(typeof _state === "function" ? _state(tabKeyState) : _state) }
            }
          };
        });
      };
    };

    render() {
      try {
        let { tabs, componentType, swipeable, stackable } = this.props;
        let RenderComponent = getComponent(componentType || "tab");
        if (!RenderComponent) {
          throw new Error(`TabScreenLoader -> componentType [${componentType}] not passed`);
        }
        let selectedTab = this.getSelectedTab();
        let tabsState = this._getState()[tabStateKey];
        let selectedTabState = tabsState && tabsState[selectedTab];
        if (!selectedTabState) {
          throw new Error("TabScreenLoader -> selected Tab state must be available but not found");
        }
        tabs = {
          ...tabs
        };
        Object.keys(tabs).forEach(tabKey => {
          let tabState = tabsState[tabKey];
          if (!this.tabStateSetters[tabKey]) {
            this.tabStateSetters[tabKey] = this.setTabState({ tabKey });
          }
          tabs[tabKey] = {
            ...tabs[tabKey],
            component: React.cloneElement(tabs[tabKey].component, {
              state: tabState,
              setState: this.tabStateSetters[tabKey]
            })
          };
        });
        let absoluteHeader = tabs[selectedTab] && tabs[selectedTab].absoluteHeader;
        let currentTabSelectionMode =
          selectedTabState.SelectionStore &&
          ((selectedTabState.SelectionStore.selectedIds && selectedTabState.SelectionStore.selectedIds.length > 0) ||
            selectedTabState.SelectionStore.selectionMode);
        return (
          <ScreenContextProvider state={selectedTabState}>
            <RenderComponent
              tabs={tabs}
              selectedTab={selectedTab}
              selectTab={this.selectTab}
              swipeable={swipeable}
              stackable={stackable}
              absoluteHeader={absoluteHeader}
              currentTabSelectionMode={currentTabSelectionMode}
            />
          </ScreenContextProvider>
        );
      } catch (e) {
        writeLog && writeLog({ type: "error", log: e });
        return errorComponent({ message: e.message });
      }
    }
  }

  const TabWithState = withContext(TabWithStateInternal, {
    replaceUri: "Router.replaceUri"
  });

  const loader = ({
    user,
    link,
    screenDef,
    state,
    screenLoader,
    activeMQ,
    routeScreenProps,
    source,
    hashMap,
    route
  }) => {
    let isNestedTab = source ? true : false;
    const pstate = state.pstate;
    let {
      headerActions: tabHeaderActions,
      expanded,
      hideNavHeaderOnScroll,
      navComponentContainerStyle,
      isNestedTab: screenIsNested
    } = screenDef;

    if (screenIsNested) {
      isNestedTab = true;
    }

    const { setStateNestedTab } = resolveMQ(
      {
        setStateNestedTabSM: true,
        setStateNestedTabMD: false
      },
      ["setStateNestedTab"],
      activeMQ
    );
    let {
      tabs = {},
      componentType,
      swipeable,
      showSelectedTabTitle,
      showTitleInTab,
      title,
      navTitle,
      stackable
    } = resolveMQ(
      screenDef,
      [
        "tabs",
        "componentType",
        "swipeable",
        "stackable",
        "showSelectedTabTitle",
        "showTitleInTab",
        "title",
        "navTitle"
      ],
      activeMQ
    );
    tabs = {
      ...tabs
    };
    for (let tabKey in tabs) {
      let { visible } = resolveMQ(tabs[tabKey], ["visible"], activeMQ);
      if (visible !== undefined) {
        if (typeof visible === "function") {
          visible = visible({ user });
          if (!visible) {
            delete tabs[tabKey];
          }
        }
      }
    }

    state[tabStateKey] = state[tabStateKey] || {};
    let selectedTab = (isNestedTab && link && link.nestedTab) || (hashMap && hashMap["SelectedTab"]);
    if (!selectedTab) {
      selectedTab = state[selectedTabStateKey]; //case when tab comes on the fly from link for ex open direct active task tab
    }
    if (!selectedTab || !tabs[selectedTab]) {
      selectedTab = Object.keys(tabs)[0];
    }
    state[selectedTabStateKey] = selectedTab;

    let linkTitle = link && link.props && link.props.title;
    title = linkTitle || navTitle || title;
    let screenProps = { title, expanded, hideNavHeaderOnScroll, navComponentContainerStyle };
    let tabComponents = {};
    for (let tabKey in tabs) {
      let {
        tab,
        screen,
        screenProps: tabScreenProps,
        panelActionRequired = true,
        pushStateInHistory,
        navHeaderStyleProps,
        fastScrollerRequire,
        absoluteHeader
      } = resolveMQ(
        tabs[tabKey],
        [
          "screen",
          "screenProps",
          "tab",
          "filters",
          "panelActionRequired",
          "navHeaderStyleProps",
          "absoluteHeader",
          "fastScrollerRequire",
          "pushStateInHistory"
        ],
        activeMQ
      );
      if (typeof screen === "function") {
        screen = screen({ user });
      }

      let { count, image, selectedImage, ...restTab } = tab;
      pstate[tabStateKey] = pstate[tabStateKey] || {};
      pstate[tabStateKey][tabKey] = pstate[tabStateKey][tabKey] || {};

      state[tabStateKey][tabKey] = state[tabStateKey][tabKey] || {};
      state[tabStateKey][tabKey].pstate = pstate[tabStateKey][tabKey];
      state[tabStateKey][tabKey][routeStateKey] = state[routeStateKey];

      let currentTabHeaderActions = tabHeaderActions ? [...tabHeaderActions] : [];

      const {
        component,
        panelHeaderActions,
        navHeaderActions,
        navLeftActions,
        navFooterActions,
        screenState,
        title: tabTitle
      } = screenLoader({
        user,
        link,
        screen,
        screenProps: tabScreenProps,
        state: state[tabStateKey][tabKey],
        activeMQ,
        panelProps: {
          absoluteHeader,
          showHeader: !panelActionRequired
        },
        route,
        mergeHeaderActions: headerActions => {
          if (headerActions) {
            for (var i = 0; i < headerActions.length; i++) {
              let headerAction = headerActions[i];
              currentTabHeaderActions.push(headerAction);
            }
          }
          return currentTabHeaderActions;
        },
        panelActionsRequired: panelActionRequired,
        source: "TabScreenLoader",
        routeScreenProps
      });
      let newTab = { ...restTab };
      if (image) {
        image = getImage(image) || image;
        newTab["image"] = image;
      }
      if (selectedImage) {
        selectedImage = getImage(selectedImage) || selectedImage;
        newTab["selectedImage"] = selectedImage;
      }
      let tabProps = {
        fastScrollerRequire,
        tab: newTab,
        component,
        actions: panelHeaderActions,
        absoluteHeader,
        pushStateInHistory
      };
      if (showTitleInTab) {
        tabProps.title = title;
      }
      if (tabKey === selectedTab) {
        screenProps.screenState = screenState;
        if (showSelectedTabTitle && tabTitle) {
          screenProps.title = tabTitle;
        }

        if (navLeftActions) {
          screenProps.navLeftActions = navLeftActions;
        }
        if (navFooterActions) {
          screenProps.navFooterActions = navFooterActions;
        }
        if (navHeaderActions) {
          screenProps.navHeaderActions = navHeaderActions;
        }
        if (panelHeaderActions) {
          tabProps.actions = panelHeaderActions;
        }
        if (navHeaderStyleProps) {
          screenProps.navHeaderStyleProps = navHeaderStyleProps;
        }
      }
      tabComponents[tabKey] = tabProps;
    }

    screenProps.component = (
      <TabWithState
        isNestedTab={isNestedTab}
        selectedTab={selectedTab}
        tabs={tabComponents}
        componentType={componentType}
        link={link}
        swipeable={swipeable}
        stackable={stackable}
        setStateNestedTab={setStateNestedTab}
      />
    );
    return screenProps;
  };
  return {
    loader
  };
};
