import React from "react";
import {
  renameCollection,
  renameResource,
  checkCollectionExists,
  addItems,
  getErrorMessage
} from "./CollectionUtility";
import { METADATA_TABLE } from "../../Constants";

export default ({ Box, View, theme, getInput, I18N, getImage, LoadingIndicator, showMessage }) => {
  const { colors, fonts, bgs } = theme;
  const { themeColor, brownGrey, tomato, primaryColor } = colors;
  const { lightPink } = bgs;
  const { h2_18, h10, h5_16, h18_l_22, h2 } = fonts;

  const componentTheme = {
    containerStyle: {
      viewStyle: {
        paddingLeft: 12,
        paddingRight: 12
      }
    },
    headerStyle: {
      viewStyle: {
        paddingTop: 25,
        paddingBottom: 25
      },
      textStyle: { ...h18_l_22, color: primaryColor }
    },
    inputHeaderStyle: {
      viewStyle: {
        paddingTop: 8,
        paddingBottom: 3
      },
      textStyle: { ...h5_16, color: brownGrey }
    },
    inputStyle: {
      containerStyle: {
        viewStyle: {
          paddingBottom: 8,
          borderBottomWidth: 2,
          borderBottomColor: themeColor
        },
        direction: "row"
      },
      textInputStyle: {
        ...h2,
        color: primaryColor,
        height: 24,
        paddingTop: 6
      },
      extensionStyle: {
        viewStyle: {
          marginLeft: 8,
          paddingLeft: 14,
          paddingRight: 14,
          borderRadius: 4,
          backgroundColor: lightPink,
          height: 24,
          justifyContent: "center"
        },
        textStyle: {
          ...h2,
          color: primaryColor
        }
      }
    },
    errorStyle: {
      containerStyle: {
        direction: "row",
        viewStyle: {
          paddingTop: 12
        }
      },
      errorMessageStyle: {
        viewStyle: {
          flex: 1,
          paddingLeft: 8
        },
        textStyle: {
          ...h10,
          color: tomato,
          numberOfLines: 2
        }
      }
    },
    footerStyle: {
      containerStyle: {
        viewStyle: {
          paddingTop: 23,
          paddingBottom: 8,
          justifyContent: "flex-end"
        },
        direction: "row"
      },
      okActionStyle: {
        viewStyle: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24,
          cursor: "pointer"
        },
        textStyle: {
          ...h2_18,
          color: themeColor
        }
      },
      cancelActionStyle: {
        viewStyle: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 16,
          paddingRight: 16,
          cursor: "pointer"
        },
        textStyle: {
          ...h2_18,
          color: brownGrey
        }
      }
    }
  };

  const metadata = {
    create_album: {
      headerTitle: () => I18N.t("albumHeader"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => I18N.t("albumDuplicateMessage"),
      nameLengthError: () => I18N.t("albumNameLengthMessage"),
      link: {
        uri: "/select-image"
      },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToAlbum"),
      successMessageMultiple: () => I18N.t("itemsAddedToAlbum")
    },
    create_set: {
      headerTitle: () => I18N.t("setHeader"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => I18N.t("setDuplicateMessage"),
      nameLengthError: () => I18N.t("setNameLengthMessage"),
      link: {
        uri: "/select-docs"
      },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToSet"),
      successMessageMultiple: () => I18N.t("itemsAddedToSet")
    },
    create_playlist: {
      headerTitle: () => I18N.t("playlistHeader"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => I18N.t("playlistDuplicateMessage"),
      nameLengthError: () => I18N.t("playlistNameLengthMessage"),
      link: {
        uri: "/select-music"
      },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToPlaylist"),
      successMessageMultiple: () => I18N.t("itemsAddedToPlaylist")
    },
    rename_album: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("album")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("albumNameLengthMessage"),
      renameMethod: renameCollection,
      successMessage: () => I18N.t("albumRenameSuccessMessage")
    },
    rename_set: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("set")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("setNameLengthMessage"),
      renameMethod: renameCollection,
      successMessage: () => I18N.t("setRenameSuccessMessage")
    },
    rename_playlist: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("playlist")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("playlistNameLengthMessage"),
      renameMethod: renameCollection,
      successMessage: () => I18N.t("playlistRenameSuccessMessage")
    },
    rename_image: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("image")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("imageNameLengthMessage"),
      renameMethod: renameResource,
      successMessage: () => I18N.t("imageRenameSuccessMessage")
    },
    rename_video: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("video")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("videoNameLengthMessage"),
      renameMethod: renameResource,
      successMessage: () => I18N.t("videoRenameSuccessMessage")
    },
    rename_doc: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("doc")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("docNameLengthMessage"),
      renameMethod: renameResource,
      successMessage: () => I18N.t("docRenameSuccessMessage")
    },
    rename_audio: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("music")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("audioNameLengthMessage"),
      renameMethod: renameResource,
      successMessage: () => I18N.t("audioRenameSuccessMessage")
    },
    create_vaultAlbum: {
      headerTitle: () => I18N.t("albumHeader"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => I18N.t("albumDuplicateMessage"),
      nameLengthError: () => I18N.t("albumNameLengthMessage"),
      link: {
        uri: "/select-vault-image"
      },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToAlbum"),
      successMessageMultiple: () => I18N.t("itemsAddedToAlbum")
    },
    create_vaultSet: {
      headerTitle: () => I18N.t("setHeader"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => I18N.t("setDuplicateMessage"),
      nameLengthError: () => I18N.t("setNameLengthMessage"),
      link: {
        uri: "/select-vault-docs"
      },
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToSet"),
      successMessageMultiple: () => I18N.t("itemsAddedToSet")
    },
    rename_vaultAlbum: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("album")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("albumNameLengthMessage"),
      renameMethod: renameCollection,
      successMessage: () => I18N.t("albumRenameSuccessMessage")
    },
    rename_vaultSet: {
      headerTitle: () => I18N.t("rename"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      duplicateError: () => `${I18N.t("set")} ${I18N.t("nameAlreadyExists")}`,
      nameLengthError: () => I18N.t("setNameLengthMessage"),
      renameMethod: renameCollection,
      successMessage: () => I18N.t("setRenameSuccessMessage")
    }
  };

  class AddCollectionNameMobile extends React.Component {
    state = { error: void 0, loading: false };

    componentWillUnmount() {
      this._unmounted = true;
    }

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    onChangeName = name => {
      let newState = { name };
      if (!this.state.loading) {
        if (this.state.error) {
          newState["error"] = void 0;
        }
        this._setState(newState);
      }
    };

    onClose = () => {
      let { onClose, unSelectAll } = this.props;
      unSelectAll && unSelectAll();
      onClose
        ? onClose()
        : this._setState({
            loading: false
          });
    };

    invoke = async (serviceProps, options = {}) => {
      const { invoke } = this.props;
      if (invoke) {
        return await invoke(serviceProps, { skipPending: true, ...options });
      }
    };

    render() {
      let { action, data, urls, getParentInfo, getSelectedData } = this.props;
      let { selectedIds: parentSelectedIds, unSelectAll: parentUnSelectAll, selectedData: parentSelectedData } =
        (getParentInfo && getParentInfo(this.props.link)) || {};
      const invoke = this.invoke;
      let { name, error, loading } = this.state;
      let { origin, form: { formType, formSource } = {} } = action;
      if (formSource === "Resource" && (!data || Array.isArray(data))) {
        let selectedData = getSelectedData && getSelectedData();
        if (selectedData && selectedData.length) {
          data = selectedData[0];
        } else if (data && data.length === 1) {
          data = data[0];
        }
      }
      data = data || {};
      if (origin === undefined) {
        origin = data.type;
      }

      let metadataOrigin = origin;
      if (formType) {
        if (formType === "rename") {
          if (name === undefined) {
            name = data.name;
            if (name && formSource === "Resource") {
              let nameWithoutExtension = name;
              let indexOfDot = name.lastIndexOf(".");
              if (indexOfDot >= 0) {
                nameWithoutExtension = name.substring(0, indexOfDot);
                if (!this.extension) {
                  this.extension = name.substring(indexOfDot);
                }
              }
              name = nameWithoutExtension;
            }
          }
        }
        metadataOrigin = `${formType}_${origin}`;
      }
      const TextInput = getInput("text");
      const { containerStyle, inputStyle, errorStyle, footerStyle, headerStyle, inputHeaderStyle } = componentTheme;
      const {
        headerTitle,
        mandatoryError,
        duplicateError,
        link,
        nameLengthError,
        successMessage,
        successMessageSingle,
        successMessageMultiple,
        renameMethod
      } = metadata[metadataOrigin] || {};

      let maxLength = 55;
      let minLength = 3;
      if (formSource === "Resource" && this.extension) {
        maxLength = maxLength - this.extension.length;
        minLength = minLength - this.extension.length;
      }

      const onOkClick = async () => {
        try {
          name = name && name.trim();
          if (!name || name.length === 0) {
            this._setState({ error: mandatoryError });
            return;
          }
          if ((name.length < minLength || name.length > maxLength) && nameLengthError) {
            this._setState({ error: nameLengthError });
            return;
          }
          this._inputRef && this._inputRef.blur && this._inputRef.blur();
          this._setState({ loading: true });
          if (formType === "rename") {
            if (formSource === "Resource") {
              name = name + (this.extension || "");
            }
            await renameMethod({ invoke, urls, data, name, origin });
            let message = successMessage();
            message && showMessage && showMessage(message, 2000);
            this.onClose();
          } else if (parentSelectedIds && parentSelectedIds.length) {
            let { deleteUri, getPath } = this.props;
            await addItems({
              invoke,
              urls,
              collectionName: name,
              origin,
              onClose: this.onClose,
              selectedIds: parentSelectedIds,
              notifyLocalOnInvoke: (_, props) => {
                let { invokeResult, fireLocalDataChangeListener } = props;
                let collectionId = invokeResult && invokeResult.collectionId;
                if (collectionId) {
                  let localChanges = [];
                  parentSelectedData.forEach(doc => {
                    let { collections, _id } = doc;
                    collections = collections || [];
                    if (!collections.includes(collectionId)) {
                      collections.push(collectionId);
                      localChanges.push({ _id, source: "action", changes: { collections } });
                    }
                  });
                  localChanges.length &&
                    fireLocalDataChangeListener &&
                    fireLocalDataChangeListener({ key: METADATA_TABLE, localChanges, updateSqlite: true });
                }
              }
            });
            const itemsLength = parentSelectedIds && parentSelectedIds.length;
            let message = itemsLength > 1 ? `${itemsLength} ${successMessageMultiple()}` : successMessageSingle();
            if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
              message = successMessage();
            }
            message && showMessage && showMessage(message, 2000);
            parentUnSelectAll && parentUnSelectAll();
            deleteUri && deleteUri(getPath && getPath(), this.props.link);
          } else {
            const existsResult = await checkCollectionExists({ invoke, urls, name, type: origin });
            if (existsResult && !existsResult.exists) {
              this.onClose();
              if (link) {
                const { addUri, getPath } = this.props;
                addUri &&
                  getPath &&
                  addUri(getPath(), {
                    ...link,
                    props: {
                      ...link.props,
                      params: { collectionName: name, origin }
                    }
                  });
              }
            } else {
              this._setState({ error: duplicateError, loading: false });
            }
          }
        } catch (e) {
          let message = getErrorMessage(e, { duplicateError, nameLengthError, I18N });
          this._setState({ error: message, loading: false });
        }
      };

      let okComponent = {
        ...footerStyle.okActionStyle,
        text: () => I18N.t("ok"),
        onClick: onOkClick
      };

      return (
        <View>
          {loading && <LoadingIndicator />}
          <Box
            {...containerStyle}
            render={[
              {
                ...headerStyle,
                text: headerTitle
              },
              {
                ...inputHeaderStyle,
                text: () => I18N.t("inputTitle")
              },
              {
                ...inputStyle.containerStyle,
                render: [
                  {
                    width: "1fr",
                    render: (
                      <TextInput
                        getRef={_ => (this._inputRef = _)}
                        value={name}
                        onChangeValue={this.onChangeName}
                        style={{ ...inputStyle.textInputStyle }}
                        autoFocus
                        onSubmitEditing={onOkClick}
                        maxLength={maxLength}
                      />
                    )
                  },
                  this.extension && {
                    ...inputStyle.extensionStyle,
                    text: this.extension
                  }
                ]
              },
              error && {
                ...errorStyle.containerStyle,
                render: [
                  {
                    image: getImage("errorIcon")
                  },
                  {
                    ...errorStyle.errorMessageStyle,
                    text: error
                  }
                ]
              },
              {
                ...footerStyle.containerStyle,
                render: [
                  {
                    ...footerStyle.cancelActionStyle,
                    onClick: this.onClose,
                    text: () => I18N.t("cancel")
                  },
                  okComponent
                ]
              }
            ]}
          />
        </View>
      );
    }
  }
  return AddCollectionNameMobile;
};
