import React from "react";
import {
  Box,
  View,
  getImage,
  RowAction,
  theme,
  DocumentSlider,
  Platform,
  ImageLoader as LoadingIndicator,
  DecryptedImage,
  I18N,
  withContext
} from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import FileViewerComponent from "./FileViewerComponent";
import ShareLogoComponent from "../share/ShareLogoComponent";
import {
  isImportFromGroupDetailVisible,
  isRemoveFromGroupDetailVisible,
  isResourceUploading
} from "../resource/ResourceUtility";
import { SECURE_TYPES } from "../../../Constants";

const { colors, fonts, images, shadows } = theme;
const { themeColor, highlightColor } = colors;
const { h16 } = fonts;

const renderHeaderStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      alignItems: "center",
      height: 60,
      paddingRight: 8,
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowSM
    },
    textStyle: {
      ...h16,
      color: themeColor,
      numberOfLines: 1
    }
  },
  actionStyle: {
    viewStyle: {
      width: 44,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const documentSliderStyle =
  Platform.OS === "web"
    ? {
        boxStyle: {
          viewStyle: {
            flex: 1
          },
          direction: "row"
        },
        contentWrapperStyle: {
          viewStyle: {
            flex: 2
          }
        },
        leftArrowIconStyle: {
          viewStyle: {
            justifyContent: "center",
            alignSelf: "center",
            padding: 10,
            position: "absolute",
            cursor: "pointer",
            zIndex: 1,
            left: 0
          },
          image: images.previousIcon
        },
        rightArrowIconStyle: {
          viewStyle: {
            justifyContent: "center",
            alignSelf: "center",
            padding: 10,
            position: "absolute",
            cursor: "pointer",
            right: 0
          },
          image: images.nextIcon
        },
        imageContainerStyle: {
          boxStyle: {
            viewStyle: {
              flex: 1
            },
            direction: "row"
          },
          imageViewConatinerStyle: {
            viewStyle: { flex: 1 }
          }
        },
        headerStyle: {}
      }
    : {
        boxStyle: {
          viewStyle: {
            flex: 1
          }
        },
        swiperContainerStyle: {
          viewStyle: { flex: 1 }
        }
      };

const renderHeader = ({ onClose, value: data = {}, origin }) => {
  let { actionStyle, containerStyle } = renderHeaderStyle;
  let { sharedCollection, resourceOwner, collectionOwner } = data;
  resourceOwner = !sharedCollection || resourceOwner; // if not shared collection, then current user will be resource owner
  let isUploading = isResourceUploading(data);
  let isVault = data.secureType === SECURE_TYPES.VAULT;
  let isArchive = data.secureType === SECURE_TYPES.ARCHIVE;
  let isCollection = data.collection && data.collection._id;
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backIcon"),
      onClick: onClose
    },
    {
      width: "1fr",
      text: data.name,
      ...actionStyle,
      viewStyle: {
        ...actionStyle.viewStyle,
        alignItems: void 0
      }
    },
    resourceOwner && !isVault && !isArchive && !isUploading && (
      <RowAction
        data={data}
        action={[
          ResourceActions.postToGroup,
          ResourceActions.webShareDoc,
          ResourceActions.nativeShareDoc,
          ResourceActions.getLinkDoc,
          ResourceActions.getLinkDocNative
        ]}
      >
        <Box {...actionStyle} image={getImage("shareGreyIcon")} />
      </RowAction>
    ),
    data && (!isCollection || (resourceOwner && collectionOwner)) && !data.favourite && !isUploading && (
      <RowAction data={data} action={ResourceActions.markFavourite} image={"favouriteGreyIcon"} />
    ),
    data && (!isCollection || (resourceOwner && collectionOwner)) && data.favourite && !isUploading && (
      <RowAction data={data} action={ResourceActions.unmarkFavourite} image={"favouriteGreySelectedIcon"} />
    ),
    {
      render: (
        <RowAction
          data={data}
          action={[
            {
              ...ResourceActions.addDocsToSetLink,
              visible: () => resourceOwner && !isUploading && !isVault && !isArchive
            },
            {
              ...ResourceActions.addDocsToVaultSetLink,
              visible: () => resourceOwner && !isUploading && isVault && !isArchive
            },
            { ...ResourceActions.removeFromSet, visible: () => isCollection && (resourceOwner || collectionOwner) },
            { ...ResourceActions.download, visible: () => !isUploading && Platform.OS !== "ios" },
            { ...ResourceActions.rename, visible: () => resourceOwner && !isUploading },
            { ...ResourceActions.makePrivate, visible: () => !isCollection && !data.private && !isVault && !isArchive },
            { ...ResourceActions.moveToVault, visible: () => !isUploading && resourceOwner && !isVault && !isArchive },
            { ...ResourceActions.removeFromVault, visible: () => !isUploading && resourceOwner && isVault },
            {
              ...ResourceActions.moveToArchive,
              visible: () => !isUploading && resourceOwner && !isArchive && !isVault
            },
            { ...ResourceActions.removeFromArchive, visible: () => !isUploading && resourceOwner && isArchive },
            { ...ResourceActions.markDelete, visible: () => resourceOwner && !isUploading && !isVault && !isArchive },
            { ...ResourceActions.printFile, origin, visible: () => data.docType === "PDF" && resourceOwner },
            { ...ResourceActions.infoMobile, origin, visible: () => resourceOwner }
          ]}
        >
          <Box {...actionStyle} image={getImage("iconMenuGrey")} />
        </RowAction>
      )
    }
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

const groupDocRenderHeader = ({ onClose, value: data = {}, origin, user }) => {
  let { actionStyle, containerStyle } = renderHeaderStyle;
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backIcon"),
      onClick: onClose
    },
    {
      width: "1fr",
      text: data.name,
      ...actionStyle,
      viewStyle: {
        ...actionStyle.viewStyle,
        alignItems: void 0
      }
    },
    isImportFromGroupDetailVisible({ data, user }) && (
      <RowAction data={data} action={ResourceActions.importItemFromGroup} image={"importGreyIcon"} />
    ),
    isRemoveFromGroupDetailVisible({ data, user }) && (
      <RowAction data={data} action={ResourceActions.removeItemFromGroup} image={"pinRemoveGreyIcon"} />
    ),
    <RowAction data={data} action={[{ ...ResourceActions.infoMobile, origin }]}>
      <Box {...actionStyle} image={getImage("iconMenuGrey")} />
    </RowAction>
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

const shareDocRenderHeader = ({ onClose, value: data = {}, sharedToken }) => {
  let { actionStyle, containerStyle } = renderHeaderStyle;
  let title = I18N.t("shared");
  if (data.sharedBy) {
    title = I18N.t("sharedByName");
    title = title.replace("__name__", data.sharedBy);
  }
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backIcon"),
      onClick: onClose
    },
    {
      width: "1fr",
      text: title,
      viewStyle: {
        paddingLeft: 12
      }
    },
    {
      render: (
        <RowAction data={data} action={{ ...ResourceActions.download, sharedToken, visible: true }}>
          <Box {...actionStyle} image={getImage("downloadIcon")} />
        </RowAction>
      )
    },
    <ShareLogoComponent
      icon={"headerLogo"}
      viewStyle={{
        paddingLeft: 8,
        paddingRight: 8
      }}
    />
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

class RenderImage extends React.Component {
  state = { loading: true };

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.online !== this.props.online && this.props.online && this.state.networkError) {
      this._setState({ networkError: false });
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  onLoad = () => {
    if (this.state.loading) {
      this._setState({ loading: false });
    }
  };

  onError = err => {
    setTimeout(_ => {
      if (this.props.online) {
        this._setState({ loading: false, showDefaultThumbnail: true });
      } else {
        this._setState({ networkError: true });
      }
    }, 1000);
  };

  render() {
    let imageProps = {
      onLoad: this.onLoad,
      onError: this.onError,
      ...this.props
    };
    if (Platform.OS === "web") {
      imageProps.style = { maxHeight: "100%", maxWidth: "100%" };
    } else {
      imageProps.style = { height: "100%", width: "100%" };
      imageProps.resizeMode = "contain";
    }
    if (this.state.showDefaultThumbnail) {
      imageProps.source = getImage("noPreviewDocIcon");
    }
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        {this.state.loading ? <LoadingIndicator /> : void 0}
        {!this.state.networkError && <DecryptedImage {...imageProps} />}
      </View>
    );
  }
}

RenderImage = withContext(RenderImage, {
  online: "NetworkListener.online"
});

class DocDetailComponent extends React.Component {
  renderImage = ({ value = {} }) => {
    let { decryptionSourceProps } = this.props;
    let thumbnailUrl = value.thumbnail_url;
    let imageKey = thumbnailUrl || "noPreviewAvailable";
    return (
      <Box
        key={imageKey}
        viewStyle={{
          flex: 1,
          marginTop: 14,
          marginRight: 12,
          marginLeft: 12,
          marginBottom: 14,
          backgroundColor: highlightColor,
          ...shadows.cardShadow
        }}
        render={
          <RenderImage
            key={imageKey}
            source={thumbnailUrl ? { uri: thumbnailUrl } : getImage("noPreviewDocIcon")}
            data={value}
            decryptionSourceProps={decryptionSourceProps}
          />
        }
      />
    );
  };

  renderFooter = props => {
    if (Platform.OS === "web") {
      return null;
    }
    let { value = {} } = props;
    return (
      <FileViewerComponent
        data={value}
        url={value.resource_url}
        name={value.name}
        id={value._id}
        decryptionSourceProps={this.props.decryptionSourceProps}
      />
    );
  };

  render() {
    let {
      link,
      pending,
      data = [],
      index,
      fromGroup,
      fromShare,
      sharedToken,
      onClose,
      origin,
      user,
      decryptionSourceProps,
      ...restProps
    } = this.props;
    let value = (data && index !== undefined && data[index]) || {};
    let renderHeaderFn = renderHeader;
    if (fromGroup) {
      renderHeaderFn = groupDocRenderHeader;
    } else if (fromShare) {
      renderHeaderFn = shareDocRenderHeader;
    }
    return (
      <View style={{ flex: 1 }}>
        {renderHeaderFn &&
          renderHeaderFn({
            value,
            onClose,
            fromShare,
            sharedToken,
            origin,
            index,
            user
          })}
        <DocumentSlider
          value={data}
          index={index}
          imageField={"thumbnail_url"}
          renderBody={this.renderImage}
          renderPagination={() => void 0}
          loadMinimalLoader={<LoadingIndicator />}
          theme={documentSliderStyle}
          {...restProps}
          showHeader={false}
          renderFooter={this.renderFooter}
        />
      </View>
    );
  }
}

export default DocDetailComponent;
