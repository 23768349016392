import React from "react";
import AudioPlayer from "audio-player";

export default ({ Box, getImage, theme, resolveMQ, I18N, EqualizerComponent }) => {
  const { colors, bgs, fonts } = theme;
  const { h3, h11_4, h1_16 } = fonts;
  const { primaryColor, brownGrey, highlightColor, themeColor } = colors;
  const { lightPink } = bgs;

  class MusicPlayer extends React.Component {
    handleMusicChange = props => {
      let { onChangeMusic, onChangePlayerState } = this.props;
      let { value, playerState } = props;
      if (!this.playerState || this.playerState !== playerState) {
        this.playerState = playerState;
        onChangePlayerState(playerState);
      }

      if (!this.currentTrackId || this.currentTrackId !== value._id) {
        let changeValue = onChangeMusic(value);
        if (changeValue !== false) {
          this.currentTrackId = value._id;
        }
      }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { playlist, onClose } = this.props;
      if (!nextProps.user && playlist && playlist.length) {
        onClose && onClose();
      }
    }

    render() {
      let { playlist, currentTrackIdx, onClose, visible, isDetailView, activeMQ } = this.props;
      let audioPlayerProps = {
        audioPlayerViewStyleSM: {
          display: visible ? "flex" : "none",
          alignItems: "center",
          height: "58px",
          backgroundColor: highlightColor
        },
        audioPlayerViewStyleMD: {
          display: visible ? "flex" : "none",
          alignItems: "center",
          height: "64px",
          backgroundColor: highlightColor,
          padding: "0 15px"
        },
        rearrangedPlayerSM: [
          {
            style: { height: 0 },
            innerComponents: [
              {
                type: "seek",
                style: {
                  flex: 1,
                  cursor: "pointer",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  transform: "translateY(-8px)"
                }
              }
            ]
          },
          {
            style: { alignItem: "center", padding: "5px 0px 0px", justifyContent: "flex-start", width: "100vw" },
            innerComponents: [
              this.handleMusicChange,
              props => {
                let { value, playerState } = props;
                if (!value) {
                  return;
                }
                return <EqualizerComponent playerState={playerState} />;
              },
              props => {
                let { value, key } = props;
                if (!value) {
                  return;
                }
                return (
                  <Box
                    key={key}
                    viewStyle={{ flex: 1 }}
                    render={[
                      {
                        textStyle: {
                          ...h1_16,
                          marginRight: 5,
                          color: primaryColor,
                          numberOfLines: 1
                        },
                        text: value.title ? value.title : value.name
                      }
                    ]}
                  />
                );
              },
              // isDetailView
              //   ? () => {
              //       return <Box width={40} />;
              //     }
              //   : {
              //       type: "shuffle",
              //       style: {
              //         width: "40px",
              //         padding: "5px 8px",
              //         cursor: "pointer"
              //       }
              //     },
              {
                type: "rewind",
                style: {
                  width: "fit-content",
                  padding: "10px 14px",
                  cursor: "pointer"
                }
              },
              {
                type: "play",
                style: {
                  width: "fit-content",
                  margin: "0 10px",
                  cursor: "pointer"
                }
              },
              {
                type: "forward",
                style: {
                  width: "fit-content",
                  padding: "10px 14px",
                  marginRight: "7px",
                  cursor: "pointer"
                }
              }
            ]
          }
        ],
        rearrangedPlayerMD: [
          {
            innerComponents: [
              this.handleMusicChange,
              props => {
                let { value, key } = props;
                if (!value) {
                  return;
                }
                return (
                  <Box
                    key={key}
                    viewStyle={{
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: 15,
                      paddingRight: 15,
                      width: 72,
                      height: 42
                    }}
                    image={value.cover ? value.cover : getImage("audioCoverIcon")}
                    imageStyle={{ objectFit: "contain" }}
                  />
                );
              },
              {
                type: "name",
                style: {
                  width: 212,
                  ...h3,
                  color: primaryColor,
                  display: "block"
                }
              },
              props => {
                let { value, key } = props;
                if (!value) {
                  return;
                }
                return (
                  <Box
                    key={key}
                    viewStyle={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 12,
                      width: 48,
                      cursor: "pointer"
                    }}
                    title={value.favourite ? I18N.t("unmarkFavouriteTitle") : I18N.t("markFavouriteTitle")}
                    image={getImage(!value.favourite ? "favouriteGreyIcon" : "favouriteSelectedIcon")}
                    onClick={e =>
                      !value.favourite ? this.props.markFavourite(props) : this.props.unmarkFavourite(props)
                    }
                  />
                );
              },
              {
                type: "verticalLine",
                style: {
                  border: `1px solid ${lightPink}`,
                  height: 43,
                  width: 0,
                  marginLeft: 12,
                  marginRight: 10
                }
              },
              {
                type: "rewind",
                style: { width: "fit-content", padding: 10, cursor: "pointer" }
              },
              {
                type: "play",
                style: {
                  width: "fit-content",
                  padding: "0 10px",
                  cursor: "pointer"
                }
              },
              {
                type: "forward",
                style: { width: "fit-content", padding: 10, cursor: "pointer" }
              },
              {
                type: "verticalLine",
                style: {
                  border: `1px solid ${lightPink}`,
                  height: 43,
                  width: 0,
                  marginLeft: 10
                }
              },
              {
                type: "time",
                style: {
                  width: "fit-content",
                  padding: "0 15px",
                  ...h11_4,
                  color: brownGrey
                }
              },
              {
                type: "seek",
                style: { flex: 8, cursor: "pointer" }
              },
              {
                type: "totalTime",
                style: {
                  width: "fit-content",
                  padding: "0 15px",
                  ...h11_4,
                  color: brownGrey
                }
              },
              {
                type: "volume",
                style: { flex: 3, margin: "0 21px 0px 9px", cursor: "pointer" }
              },
              isDetailView
                ? () => {
                    return <Box width={12} />;
                  }
                : {
                    type: "verticalLine",
                    style: {
                      border: `1px solid ${lightPink}`,
                      height: 43,
                      width: 0,
                      marginRight: 10
                    }
                  },

              isDetailView
                ? () => {
                    return <Box width={40} />;
                  }
                : {
                    type: "shuffle",
                    style: { width: "40px", padding: 10, cursor: "pointer" }
                  },
              props => {
                let { value, key } = props;
                if (!value) {
                  return;
                }
                if (isDetailView) {
                  return <Box width={54} />;
                }
                return (
                  <Box
                    key={key}
                    viewStyle={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 54,
                      padding: 15,
                      cursor: "pointer"
                    }}
                    image={getImage("infoCrossWebIcon")}
                    onClick={onClose}
                  />
                );
              }
            ]
          }
        ],
        handleStyleSM: {
          borderColor: themeColor,
          backgroundColor: themeColor
          // height: 10,
          // width: 10,
        },
        trackStyleSM: { backgroundColor: themeColor, height: 2 },
        railStyleSM: { backgroundColor: lightPink, height: 2 },
        trackStyleMD: { backgroundColor: themeColor },
        handleStyleMD: {
          borderColor: themeColor,
          backgroundColor: themeColor,
          height: 14.2,
          width: 14.2
        },
        railStyleMD: { backgroundColor: lightPink },

        playIconSM: getImage("audioPlay"),
        playHoverIconSM: getImage("audioPlay"),
        pauseIconSM: getImage("audioPause"),
        pauseHoverIconSM: getImage("audioPause"),
        forwardIconSM: getImage("audioForwardIcon"),
        forwardHoverIconSM: getImage("audioForwardIcon"),
        rewindIconSM: getImage("audioRewindIcon"),
        rewindHoverIconSM: getImage("audioRewindIcon"),

        playIconMD: getImage("audioPlayGreyIcon"),
        playHoverIconMD: getImage("audioPlayGreyIcon"),
        pauseIconMD: getImage("audioPauseGreyIcon"),
        pauseHoverIconMD: getImage("audioPauseGreyIcon"),
        forwardIconMD: getImage("audioForwardGreyIcon"),
        forwardHoverIconMD: getImage("audioForwardGreyIcon"),
        rewindIconMD: getImage("audioRewindGreyIcon"),
        rewindHoverIconMD: getImage("audioRewindGreyIcon"),

        shuffleIconSM: getImage("audioShuffle"),
        shuffleEngagedIconSM: getImage("audioShuffle"),
        shuffleIconMD: getImage("audioShuffleGreyIcon"),
        shuffleEngagedIconMD: getImage("audioShuffleEngagedIcon")
      };

      let {
        rearrangedPlayer,
        audioPlayerViewStyle,
        trackStyle,
        handleStyle,
        railStyle,
        playIcon,
        playHoverIcon,
        pauseIcon,
        pauseHoverIcon,
        forwardIcon,
        forwardHoverIcon,
        rewindIcon,
        rewindHoverIcon,
        shuffleIcon,
        shuffleEngagedIcon
      } = resolveMQ(
        audioPlayerProps,
        [
          "rearrangedPlayer",
          "audioPlayerViewStyle",
          "trackStyle",
          "handleStyle",
          "railStyle",
          "playIcon",
          "playHoverIcon",
          "pauseIcon",
          "pauseHoverIcon",
          "forwardIcon",
          "forwardHoverIcon",
          "rewindIcon",
          "rewindHoverIcon",
          "shuffleIcon",
          "shuffleEngagedIcon"
        ],
        activeMQ
      );
      let renderComponent = (
        <div style={audioPlayerViewStyle}>
          <AudioPlayer
            rearrange={rearrangedPlayer}
            audioFiles={playlist}
            playerWidth="100%"
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            railStyle={railStyle}
            currentTrackIdx={currentTrackIdx}
            // playStarted
            // playing

            // player icons
            playIcon={playIcon}
            playHoverIcon={playHoverIcon}
            pauseIcon={pauseIcon}
            pauseHoverIcon={pauseHoverIcon}
            forwardIcon={forwardIcon}
            forwardHoverIcon={forwardHoverIcon}
            rewindIcon={rewindIcon}
            rewindHoverIcon={rewindHoverIcon}
            volumeIcon={getImage("audioVolumnUpGreyIcon")}
            volumeEngagedIcon={getImage("audioVolumnUpGreyIcon")}
            muteIcon={getImage("audioVolumnOffGreyIcon")}
            muteEngagedIcon={getImage("audioVolumnOffGreyIcon")}
            // new icons
            shuffleIcon={shuffleIcon}
            shuffleEngagedIcon={shuffleEngagedIcon}
          />
        </div>
      );
      return renderComponent;
    }
  }
  return MusicPlayer;
};
