export default ({ bgs, colors }) => {
  const { themeBg } = bgs;
  const { highlightColor } = colors;

  const spinnerStyle = {
    containerStyle: {
      overflow: void 0,
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 11
    },
    animationStyle: {
      width: 50,
      height: 50,
      borderWidth: 4,
      borderColor: themeBg,
      borderRightColor: "transparent",
      borderRadius: "50%",
      animationName: "spinnerRotate",
      animationDuration: "0.75s",
      animationIterationCount: "infinite",
      animationTimingFunction: "linear"
    }
  };

  const imageLoaderSpinnerStyle = {
    containerStyle: {
      overflow: void 0,
      position: "absolute",
      left: "50%",
      top: "50%",
      right: "50%",
      bottom: "50%",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 11
    },
    animationStyle: {
      width: 50,
      height: 50,
      borderWidth: 4,
      borderColor: themeBg,
      borderRightColor: "transparent",
      borderRadius: "50%",
      animationName: "spinnerRotate",
      animationDuration: "0.75s",
      animationIterationCount: "infinite",
      animationTimingFunction: "linear"
    }
  };

  const loginSpinnerStyle = {
    animationStyle: {
      width: 42,
      height: 42,
      borderWidth: 4,
      borderColor: highlightColor,
      borderRightColor: "transparent",
      borderRadius: "50%",
      animationName: "spinnerRotate",
      animationDuration: "0.75s",
      animationIterationCount: "infinite",
      animationTimingFunction: "linear"
    }
  };

  return { spinnerStyle, imageLoaderSpinnerStyle, loginSpinnerStyle };
};
