import React from "react";

const getComponentWidth = (componentCount, visibleView, screenWidth, expanded) => {
  if (expanded) {
    return screenWidth;
  }
  const viewCount = componentCount < visibleView ? componentCount : visibleView;
  const componentWidth = screenWidth ? screenWidth / viewCount : void 0;
  return componentWidth;
};

export default ({
  NavBodyContext,
  Box,
  Modal,
  resolveMQ,
  renderChildren,
  ActiveMQ,
  Platform,
  Dimensions,
  ItemContainer,
  theme = {},
  ...hocProps
}) => {
  const isAndroid = Platform.OS === "android";
  class NavBody extends React.Component {
    state = { expandToggled: false, width: Dimensions.get("window").width };

    constructor(props) {
      super(props);
      this.navContext = {
        expand: this.expand,
        isExpanded: this.isExpanded,
        getOpenViews: this.getOpenViews
      };
    }

    getOpenViews = () => {
      if (this.props.components) {
        return this.props.components.length;
      } else {
        return 0;
      }
    };

    expand = ({ index }) => {
      if (this.expandedIndex === index) {
        this.expandedIndex = void 0;
      } else {
        this.expandedIndex = index;
      }
      this.setState({
        expandToggled: !this.expandToggled
      });
    };

    isExpanded = ({ index }) => {
      return this.expandedIndex === index;
    };

    setExpandedIndex = components => {
      let componentCount = components ? components.length : 0;
      if (
        this.expandedIndex !== undefined &&
        (this.componentCount !== componentCount || this.expandedIndex >= componentCount)
      ) {
        this.expandedIndex = void 0;
      }
      let lastIndex = componentCount - 1;
      if (this.expandedIndex === undefined && components[lastIndex].expanded) {
        this.expandedIndex = lastIndex;
      }
      this.componentCount = componentCount;
    };

    render() {
      const { components = [], activeMQ, screenWidth } = this.props;
      let { width } = this.state;
      const { visibleView } = resolveMQ(hocProps, ["visibleView"], activeMQ);
      //todo change context reference if visibleView are getting changed - Rohit bansal - 12-02-19
      this.navContext.visibleView = visibleView;

      let { boxStyle, componentContainerStyle, gap = 0 } = resolveMQ(
        theme,
        ["boxStyle", "componentContainerStyle", "gap"],
        activeMQ
      );
      let boxStyleToPass = { ...boxStyle };

      const componentCount = components.length;
      if (componentCount > visibleView && isAndroid) {
        boxStyleToPass.viewStyle = {
          ...boxStyleToPass.viewStyle,
          flex: void 0,
          width: componentCount * width,
          right: (componentCount - 1) * width
        };
      }
      this.setExpandedIndex(components);
      let renders = [
        ...components.map(({ component, componentKey, navComponentContainerStyle }, index) => {
          let selfExpanded = this.expandedIndex === index;
          const componentWidth = getComponentWidth(componentCount, visibleView, screenWidth, selfExpanded);
          let { route = {} } = component.props;
          let componentViewStyle = {
            ...componentContainerStyle,
            ...navComponentContainerStyle
          };
          if (index > 0 && visibleView > 1) {
            componentViewStyle.marginLeft = gap;
          }
          if (componentCount - index > visibleView || (this.expandedIndex !== undefined && !selfExpanded)) {
            /**
             * In android, scroll position in not maintained while navigation due to changing display,
             * so by assuming only 1 view at a time in mobile, View is shifted to the right
             * display is not changed - Rohit Garg 21-jan-2019
             * */
            componentViewStyle.display = isAndroid ? "flex" : "none";
          }
          component = renderChildren(component, { expand: this.expand });
          if (ItemContainer) {
            component = (
              <ItemContainer key={componentKey} {...route}>
                {component}
              </ItemContainer>
            );
          }
          if (componentWidth && ActiveMQ) {
            component = <ActiveMQ.Component width={componentWidth}>{component}</ActiveMQ.Component>;
          }
          return {
            viewStyle: componentViewStyle,
            render: component
          };
        })
      ];
      return (
        <NavBodyContext.Provider value={this.navContext}>
          <Box
            {...boxStyleToPass}
            onLayout={() => {
              const { width } = Dimensions.get("window");
              if (width !== this.state.width) {
                this.setState({
                  width
                });
              }
            }}
            render={renders}
          />
        </NavBodyContext.Provider>
      );
    }
  }
  return NavBody;
};
