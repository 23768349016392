import React from "react";

export default ({ AvatarMD, AvatarSM, resolveMQ }) => {
  class AvatarBox extends React.Component {
    render() {
      let { Avatar } = resolveMQ(
        {
          AvatarSM,
          AvatarMD
        },
        ["Avatar"],
        this.props.activeMQ
      );
      return <Avatar {...this.props} />;
    }
  }
  return AvatarBox;
};
