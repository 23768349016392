export default ({ colors, fonts }) => {
  const { primaryColor } = colors;
  const { h1,h3 } = fonts;
  const linkBoxStyle = {
    boxStyleMD: {
      textStyle: {
        ...h1,
        color: primaryColor,
        numberOfLines: 1
      }
    },
    boxStyleSM: {
      textStyle: {
        ...h3,
        color: primaryColor,
        numberOfLines: 1
      }
    }
  };
  return {
    linkBoxStyle
  };
};
