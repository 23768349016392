import React from "react";
import uuid from "uuid/v4";
import MusicPlayerContext from "./MusicPlayerContext";
import { STREAM_TOKEN_TABLE } from "./../../Constants";
import { AsyncStorage } from "react-components";

export default ({
  View,
  renderChildren,
  I18N,
  LoadingIndicator,
  addTableListener,
  removeTableListener,
  MetadataTable,
  MusicPlayer,
  resolveMQ,
  showMessage,
  getEncryptionKeyAndIV,
  Platform,
  removeStreamToken,
  getAudioStreamUrl,
  firebaseSave,
  getUser,
  ...hocProps
}) => {
  const AUDIO_STREAM_TOKEN_KEY = "audio_token";
  let currentAudioToken = void 0;

  const unsetToken = async () => {
    /* called currently for close button on web/ no music remains/ new music played(new music files added to track player,
       not when same is updated again)
     */
    try {
      let token = await AsyncStorage.getItem(AUDIO_STREAM_TOKEN_KEY);
      if (token) {
        await removeStreamToken({ token });
        await AsyncStorage.removeItem(AUDIO_STREAM_TOKEN_KEY);
        currentAudioToken = void 0;
      }
    } catch (error) {
      console.warn(error, "@@@@unable to remove streaming token");
    }
  };

  const setToken = async () => {
    try {
      const token = uuid();
      let { uid } = getUser() || {};
      await firebaseSave({
        table: STREAM_TOKEN_TABLE,
        insert: {
          Platform: Platform.OS,
          _id: token,
          type: "audio",
          // key,
          _createdOn: new Date(),
          _createdBy: { firebaseUid: uid }
        }
      });
      await AsyncStorage.setItem(AUDIO_STREAM_TOKEN_KEY, token);
      currentAudioToken = token;
    } catch (err) {
      console.warn("error in set audio token", err && err.message);
    }
  };

  class MusicStore extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        playlist: []
      };
      this.contextValue = {
        onPlayMusic: this.onPlayMusic,
        addChangeSongListener: this.addChangeSongListener,
        removeChangeSongListener: this.removeChangeSongListener,
        addPlayerStateListener: this.addPlayerStateListener,
        removePlayerStateListener: this.removePlayerStateListener,
        isDetailView: this.isDetailView,
        isPlaying: this.isPlaying,
        getPlayerState: this.getPlayerState
      };
      this.changeMusicListener = [];
      this.playerStateListener = [];
    }

    handleTableListener = ({ data }) => {
      if (!data.length || !this.state.playlist || !this.state.playlist.length) {
        return;
      }
      let removedData = [];
      let modifiedData = [];
      data.forEach(row => {
        if (row._op === "removed") {
          removedData.push(row);
        }
        if (row._op === "modified") {
          modifiedData.push(row);
        }
      });
      if (removedData.length) {
        this.onRemove(removedData);
      }
      if (modifiedData.length) {
        let { playlist } = this.state;
        modifiedData.forEach(modifiedRow => {
          for (var i = 0; i < playlist.length; i++) {
            if (modifiedRow._id === playlist[i]._id) {
              playlist[i].favourite = modifiedRow.favourite;
              break;
            }
          }
        });
      }
    };

    componentDidMount() {
      addTableListener && addTableListener({ table: MetadataTable, callback: this.handleTableListener });
    }

    componentWillUnmount() {
      removeTableListener && removeTableListener({ table: MetadataTable, callback: this.handleTableListener });
    }

    isPlaying = row => {
      return row._id === this.state.currentTrackId;
    };

    getPlayerState = row => {
      if (row._id === this.state.currentTrackId) {
        return this.playerState || "Paused";
      }
    };

    addChangeSongListener = cb => {
      if (cb) {
        this.changeMusicListener.push(cb);
      }
    };

    removeChangeSongListener = cb => {
      const index = this.changeMusicListener.indexOf(cb);
      if (index >= 0) {
        this.changeMusicListener.splice(index, 1);
      }
    };

    onChangeMusic = data => {
      if (this.removeState) {
        this.setState({ ...this.removeState });
        this.removeState = void 0;
        return false;
      }
      this.setState(
        {
          currentTrackId: data ? data._id : void 0
        },
        () => {
          if (this.changeMusicListener && this.changeMusicListener.length) {
            this.changeMusicListener.forEach(listener => listener({ data }));
          }
        }
      );
    };

    addPlayerStateListener = cb => {
      if (cb) {
        this.playerStateListener.push(cb);
      }
    };

    removePlayerStateListener = cb => {
      const index = this.playerStateListener.indexOf(cb);
      if (index >= 0) {
        this.playerStateListener.splice(index, 1);
      }
    };

    onChangePlayerState = playerState => {
      if (this.playerStateListener && this.playerStateListener.length) {
        this.playerState = playerState;
        this.playerStateListener.forEach(listener => listener({ playerState }));
      }
    };

    isDetailView = value => {
      this.setState({
        isDetailView: value
      });
    };

    updateFavourite = ({ trackId, updateValue }) => {
      let { playlist } = this.state;
      let index = -1;
      playlist &&
        playlist.forEach((data, currentId) => {
          if (data._id === trackId) {
            index = currentId;
          }
        });
      if (index !== -1) {
        playlist[index].favourite = updateValue;
        this.setState({});
      }
    };

    markFavourite = props => {
      this.toggleFavourite(props, {
        serviceName: "markFavourite",
        updateValue: true
      }).then(_ => {
        showMessage && showMessage(I18N.t("markFavouriteMessage"));
      });
    };

    unmarkFavourite = props => {
      this.toggleFavourite(props, {
        serviceName: "unmarkFavourite",
        updateValue: false
      }).then(_ => {
        showMessage && showMessage(I18N.t("unmarkFavouriteMessage"));
      });
    };

    toggleFavourite = ({ value: data }, { serviceName, updateValue }) => {
      let { invoke } = this.props;
      this.setState({ loading: true });
      return invoke({
        service: (props, { urls }) => {
          const { selectedIds } = props;
          const resourceId = selectedIds[0];
          return {
            url: urls[serviceName],
            uriProps: {
              data: {
                resourceId
              }
            }
          };
        },
        data,
        selectedData: [data],
        selectedIds: [data._id]
      })
        .then(_ => {
          this.setState({ loading: false });
          this.updateFavourite({ trackId: data._id, updateValue });
        })
        .catch(err => {
          this.setState({ loading: false });
          showMessage && showMessage(err.message, 2000);
        });
    };

    onPlayMusic = async (props, skipTokenGeneration) => {
      // console.warn("@@@@ skipTokenGeneration", skipTokenGeneration);
      let key, iv;
      if (Platform.OS !== "android") {
        if (!skipTokenGeneration) {
          await unsetToken();
          await setToken();
        }
      } else {
        let encResult = (await getEncryptionKeyAndIV()) || {};
        key = encResult.key;
        iv = encResult.iv;
      }
      let { playlist = [], currentTrackId } = props;
      let playlistArr = [];
      for (let item of playlist) {
        let encryptionProps = {};
        if (item.encrypted) {
          if (key && iv) {
            encryptionProps.key = key;
            encryptionProps.iv = iv;
          } else if (currentAudioToken) {
            let streamUrl = getAudioStreamUrl(currentAudioToken, item._id);
            encryptionProps.src = streamUrl;
            encryptionProps.url = streamUrl;
          }
        }
        playlistArr.push({
          _id: item._id,
          id: item._id,
          name: item.name,
          src: item.converted_mp3_url || item.resource_url,
          url: item.converted_mp3_url || item.resource_url,
          title: item.resource_title || item.name,
          artist: item.resource_artist || "No Artist",
          favourite: item.favourite,
          album: item.resource_album,
          genre: item.resource_genre,
          date: item.resource_lastModified,
          type: item.type,
          localUri: item.localUri,
          status: item.status,
          actionType: item.actionType,
          ...encryptionProps
        });
      }
      this.setState({
        playlist: playlistArr,
        currentTrackId
      });
    };

    onRemove = data => {
      let state = this.removeState || this.state;
      this.removeState = void 0;
      let { playlist = [] } = state;
      if (!playlist.length) {
        return;
      }
      let removeIds = Array.isArray(data) ? data.map(row => row._id) : [data._id];
      let { currentTrackId } = this.state;
      let isCurrentTrackDelete = false;
      playlist = playlist.filter(row => {
        let trackId = row._id;
        if (removeIds.indexOf(trackId) !== -1) {
          if (currentTrackId === trackId) {
            currentTrackId = void 0;
            isCurrentTrackDelete = true;
          }
          return false;
        } else if (currentTrackId === void 0) {
          currentTrackId = trackId;
        }
        return true;
      });
      if (playlist.length === 0) {
        this.onClose();
      } else {
        if (!currentTrackId) {
          currentTrackId = playlist[0]._id;
        } else if (!isCurrentTrackDelete) {
          for (var i = 0; i < playlist.length; i++) {
            if (playlist[i]._id === currentTrackId) {
              currentTrackId = playlist[i === playlist.length - 1 ? 0 : i + 1]._id;
              break;
            }
          }
        }
        let newState = {
          playlist,
          currentTrackId
        };
        if (isCurrentTrackDelete) {
          this.setState(newState);
        } else {
          this.removeState = newState;
        }
      }
    };

    onClose = () => {
      this.setState({ playlist: [], currentTrackId: void 0 });
      this.onChangeMusic();
      if (Platform.OS !== "android") {
        try {
          unsetToken();
        } catch (err) {}
      }
    };

    render() {
      let { children, activeMQ, ...restProps } = this.props;
      let { isMusicPlayerVisible } = resolveMQ(hocProps, ["isMusicPlayerVisible"], activeMQ);
      const { playlist, currentTrackId } = this.state;
      let musicPlayerComponent = void 0;
      if (playlist && playlist.length && currentTrackId) {
        let currentTrackIdx = -1;
        for (var i = 0; i < playlist.length; i++) {
          if (playlist[i]._id === currentTrackId) {
            currentTrackIdx = i;
            break;
          }
        }
        musicPlayerComponent = (
          <MusicPlayer
            playlist={playlist}
            currentTrackIdx={currentTrackIdx}
            markFavourite={this.markFavourite}
            unmarkFavourite={this.unmarkFavourite}
            onClose={this.onClose}
            visible={isMusicPlayerVisible(restProps)}
            isDetailView={this.state.isDetailView}
            onChangeMusic={this.onChangeMusic}
            onChangePlayerState={this.onChangePlayerState}
            {...restProps}
          />
        );
      }
      return (
        <MusicPlayerContext.Provider value={this.contextValue}>
          <View style={{ flex: 1 }}>
            {this.state.loading && <LoadingIndicator />}
            {renderChildren(children)}
            {musicPlayerComponent}
          </View>
        </MusicPlayerContext.Provider>
      );
    }
  }
  return MusicStore;
};
