export default {
  "/user-issues": {
    screen: "UserIssues"
  },
  "/add-issue": {
    screen: "AddIssue"
  },
  "/add-comment": {
    screen: "AddComment"
  },
  "/support-issues": {
    screen: "SupportTab",
    hash: ({ hash }) => {
      if (hash) {
        return {
          SelectedTab: hash
        };
      }
    }
  },
  "/comments": {
    screen: "CommentList"
  }
};
