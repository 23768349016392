export default {
  "/group": {
    screenSM: "GroupTabSM",
    screenMD: "GroupTabMD"
  },
  "/invite-member": {
    screen: "InviteMember"
  }, 
  "/join-group": {
    screen: "JoinGroup",
    hash: ({ hash }) => {
      if (hash) {
        return {
          sharedToken: hash
        };
      }
    }
  }
};
