import uuid from "uuid/v4";
import {
  withContext,
  Box,
  ResizedBox,
  Text,
  View,
  Image,
  ScrollView,
  Modal,
  Location,
  WithState,
  AsyncStorage,
  WithStateRouter,
  Platform,
  KeyboardAvoidingView,
  ImageBackground,
  ScreenContextProvider,
  ScrollContext,
  Dimensions,
  Keyboard,
  SelectionStoreContext,
  mergeContext
} from "./BasicComponents";
import ConnectComponentsHoc from "./ConnectComponents";
import { renderChildren, renderProps } from "inject";
import {
  resolveActions as resolveActionsHoc,
  resolveActionLayout as resolveActionLayoutHoc
} from "../../json-components";
import {
  getImageSize,
  separateNumber,
  resolveMQ as resolveMQHoc,
  enhancePropWithMQ as enhancePropWithMQHoc,
  overridePropWithMQ as overridePropWithMQHoc
} from "style-utility";
import {
  resolveTheme,
  resolveValue,
  isJSONObject,
  getAvatarDisplay,
  resolveParams,
  getFieldDef,
  resolveField,
  resolveColumn,
  resolveExp,
  resolveVisibleExp,
  getAggregateValue,
  putDottedValue,
  iterator,
  beforeFetch,
  afterFetch,
  deepEqual,
  setState as setStateHoc,
  shallowCompare
} from "app-utility-functions";
import DataFormatHoc from "../../data-format";
import { getSizeFormat } from "client-utility/lib/FileUtility";
import ActionComponentsHoc from "./ActionComponents";
import NavComponentsHoc from "./NavComponents";
import PanelComponentsHoc from "./PanelComponents";
import FormComponentsHoc from "./FormComponents";
import TableComponentsHoc from "./TableComponents";

export default ({
  appServices,
  theme = {},
  images,
  beforeFetch: _beforeFetch,
  afterFetch: _afterFetch,
  I18N,
  getUserMenus,
  Swipeout,
  cqrs,
  getFieldHeader,
  getMandatoryMessage,
  getPlaceholder,
  resolveNavHeaderStyle,
  resolveTabBarStyle,
  renderSuggestionValue,
  unsubscribe,
  subscribe,
  onRealTimeUpdate,
  skipValidationToast,
  LoadingIndicator,
  ImageLoader,
  ActiveMQ,
  WithModal,
  fetchTimeout,
  sounds,
  showMessage,
  showError,
  Toast,
  addLocalDataChangeListener,
  removeLocalDataChangeListener,
  fireLocalDataChangeListener,
  mergeTwoSortedArrays,
  fireListRowUpdateListener,
  onPendingBlocker
}) => {
  const dataFormat = DataFormatHoc({ types: theme.typeFormat, resolveValue, getSizeFormat });
  const StatusBar = typeof LoadingIndicator === "function" ? LoadingIndicator({ View }) : LoadingIndicator;
  ImageLoader = typeof ImageLoader === "function" ? ImageLoader({ View }) : ImageLoader;

  const { breakPoints } = theme;

  const getNewId = () => {
    return `${cqrs ? "" : "new_"}${uuid()}`;
  };

  const resolveMQ = resolveMQHoc({ breakPoints });
  const enhancePropWithMQ = enhancePropWithMQHoc({ breakPoints });
  const overridePropWithMQ = overridePropWithMQHoc({ breakPoints });

  let Actions = {};
  let InputComponents = {};
  let FilterGroupActions = {};
  let FormEditors = {};
  let ListEditors = {};
  const FilterEditors = {};
  let CardComponents = {};
  let Components = {};
  let JsonComponents = {};

  const getImage = image => images[image];
  const getJsonComponent = type => JsonComponents[type];
  const getCardComponent = type => CardComponents[type];
  const getComponent = type => Components[type];
  const getAction = type => Actions[type];
  const getFilterGroupAction = type => FilterGroupActions[type] || Actions[type];
  const getInput = type => InputComponents[type];
  const getFormEditor = type => FormEditors[type];
  const getListEditor = type => ListEditors[type];
  const getFilterEditor = type => FilterEditors[type];
  const getTheme = style => {
    return resolveTheme(theme, style);
  };

  const resolveActionLayout = resolveActionLayoutHoc({ resolveMQ, actionLayout: theme.actionLayout });
  const resolveActions = resolveActionsHoc({
    getAction,
    resolveMQ,
    renderChildren,
    actionStyle: theme.actionStyle,
    Platform
  });

  const mergeAppComponents = (componentsInMerge, componentsToMerge) => {
    for (var type in componentsToMerge) {
      componentsInMerge[type] = componentsToMerge[type];
    }
  };

  const mergeCardComponents = components => mergeAppComponents(CardComponents, components);
  const mergeComponents = components => mergeAppComponents(Components, components);
  const mergeActions = components => mergeAppComponents(Actions, components);
  const mergeFilterGroupActions = components => mergeAppComponents(FilterGroupActions, components);
  const mergeInputComponents = components => mergeAppComponents(InputComponents, components);
  const mergeFormEditors = components => mergeAppComponents(FormEditors, components);
  const mergeListEditors = components => mergeAppComponents(ListEditors, components);
  const mergeFilterEditors = components => mergeAppComponents(FilterEditors, components);
  const mergeJsonComponents = components => mergeAppComponents(JsonComponents, components);

  const defaultProps = {
    breakPoints,
    getNewId,
    resolveMQ,
    enhancePropWithMQ,
    overridePropWithMQ,
    WithModal,
    showMessage,
    showError,
    getTheme,
    getImage,
    getAction,
    getFilterGroupAction,
    resolveActions,
    resolveActionLayout,
    getInput,
    getFormEditor,
    getListEditor,
    getFilterEditor,
    getCardComponent,
    getComponent,
    getJsonComponent,
    mergeActions,
    mergeFilterGroupActions,
    mergeInputComponents,
    mergeFormEditors,
    mergeListEditors,
    mergeFilterEditors,
    mergeCardComponents,
    mergeComponents,
    mergeJsonComponents
  };

  const componentProps = {
    sounds,
    theme,
    images,
    Toast,
    Box,
    ResizedBox,
    Image,
    View,
    Text,
    ScrollView,
    Modal,
    withContext,
    Keyboard,
    mergeContext,
    KeyboardAvoidingView,
    ImageBackground,
    Platform,
    Dimensions,
    Location,
    WithState,
    AsyncStorage,
    WithStateRouter,
    getFieldDef,
    separateNumber,
    getImageSize,
    resolveField,
    resolveColumn,
    getAggregateValue,
    resolveValue,
    resolveParams,
    resolveExp,
    resolveVisibleExp,
    renderProps,
    renderChildren,
    dataFormat,
    setStateHoc,
    putDottedValue,
    iterator,
    isJSONObject,
    StatusBar,
    ImageLoader,
    ScrollContext,
    ScreenContextProvider,
    getFieldHeader,
    getMandatoryMessage,
    getAvatarDisplay,
    getPlaceholder,
    renderSuggestionValue,
    resolveTabBarStyle,
    resolveNavHeaderStyle,
    Swipeout,
    getUserMenus,
    shallowCompare,
    WithModal: defaultProps.WithModal,
    resolveMQ: defaultProps.resolveMQ,
    enhancePropWithMQ: defaultProps.enhancePropWithMQ,
    overridePropWithMQ: defaultProps.overridePropWithMQ,
    showMessage: defaultProps.showMessage,
    showError: defaultProps.showError,
    getNewId: defaultProps.getNewId,
    getComponent: defaultProps.getComponent,
    getInput: defaultProps.getInput,
    getJsonComponent: defaultProps.getJsonComponent,
    getAction: defaultProps.getAction,
    getTheme: defaultProps.getTheme,
    getImage: defaultProps.getImage,
    resolveActions: defaultProps.resolveActions,
    getFilterGroupAction: defaultProps.getFilterGroupAction,
    getFilterEditor: defaultProps.getFilterEditor,
    getCardComponent: defaultProps.getCardComponent,
    getFormEditor: defaultProps.getFormEditor,
    getListEditor: defaultProps.getListEditor,
    I18N,
    ActiveMQ,
    skipValidationToast,
    uuid,
    appServices
  };

  const ConnectComponents = ConnectComponentsHoc({
    ...componentProps,
    SelectionStoreContext,
    beforeFetch: _beforeFetch || beforeFetch,
    afterFetch: _afterFetch || afterFetch,
    deepEqual,
    cqrs,
    unsubscribe,
    subscribe,
    onRealTimeUpdate,
    fetchTimeout,
    addLocalDataChangeListener,
    removeLocalDataChangeListener,
    fireLocalDataChangeListener,
    mergeTwoSortedArrays,
    fireListRowUpdateListener,
    onPendingBlocker
  });
  const { Connect, SelectionStore } = ConnectComponents;
  componentProps.Connect = Connect;
  componentProps.SelectionStore = SelectionStore;

  const ActionComponents = ActionComponentsHoc(componentProps);
  const PanelComponents = PanelComponentsHoc(componentProps);
  const NavComponents = NavComponentsHoc(componentProps);
  const FormComponents = FormComponentsHoc(componentProps);
  const TableComponents = TableComponentsHoc(componentProps);

  mergeComponents({
    card: FormComponents.FormCard,
    cardGrid: FormComponents.FormCardGrid,
    form: FormComponents.Form,
    formGroup: FormComponents.FormGroup,
    nestedModalForm: FormComponents.NestedModalForm,
    actionForm: FormComponents.ConnectedForm,
    table: TableComponents.Table,
    list: TableComponents.List,
    panel: PanelComponents.Panel,
    panelHeader: PanelComponents.PanelHeader,
    bottomMenu: ActionComponents.RowAction,
    confirmBox: ActionComponents.ConfirmBox,
    menuItems: ActionComponents.MenuItems,
    onlyAction: ActionComponents.OnlyAction
  });

  mergeCardComponents({
    Selection: TableComponents.RowSelectionBox,
    Draggable: TableComponents.SRowDragger,
    HeaderDraggable: TableComponents.HeaderSRowDragger,
    HeaderSelection: TableComponents.HeaderSelectionBox,
    HeaderRowAction: TableComponents.HeaderRowAction,
    nestedTable: TableComponents.NestedTable,
    nestedGridTable: TableComponents.NestedGridTable,
    innerNestedTable: TableComponents.InnerNestedTable,
    HeaderSelectRowAction: ActionComponents.HeaderSelectRowAction,
    RowAction: ActionComponents.RowAction
  });

  mergeFormEditors({
    nestedTable: TableComponents.NestedTable,
    nestedGridTable: TableComponents.NestedGridTable,
    innerNestedTable: TableComponents.InnerNestedTable,
    card: TableComponents.Card,
    tabForm: FormComponents.TabForm,
    nestedModalForm: FormComponents.NestedModalForm
  });

  mergeListEditors({
    nestedTable: TableComponents.NestedTable,
    nestedGridTable: TableComponents.NestedGridTable,
    innerNestedTable: TableComponents.InnerNestedTable,
    card: TableComponents.Card,
    tabForm: FormComponents.TabForm,
    nestedModalForm: FormComponents.NestedModalForm
  });

  mergeActions({
    invoke: ActionComponents.Invoke,
    close: ActionComponents.Close,
    link: ActionComponents.Link,
    navLink: ActionComponents.LinkAction,
    remove: ActionComponents.Remove,
    save: ActionComponents.SaveAndClose,
    logout: ActionComponents.Logout,
    insert: ActionComponents.Insert,
    insertLink: ActionComponents.Link,
    enableSelectionMode: ActionComponents.EnableSelectionMode,
    selectAll: ActionComponents.SelectAll,
    unSelectAll: ActionComponents.UnSelectAll,
    multiActions: ActionComponents.HeaderAction,
    headerAction: ActionComponents.HeaderAction,
    unSupported: ActionComponents.UnSupported
  });

  return {
    ...componentProps,
    ...ConnectComponents,
    ...ActionComponents,
    ...PanelComponents,
    ...FormComponents,
    ...TableComponents,
    ...NavComponents,
    ...defaultProps
  };
};
