import React from "react";

export default ({ Box, resolveMQ, theme, getImage }) => {
  const { colors, fonts, bgs } = theme;
  const { themeColor } = colors;
  const { veryLightPink } = bgs;
  const { h20 } = fonts;

  const componentTheme = {
    containerStyle: {
      direction: "row",
      viewStyle: {
        height: 77,
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: "center",
        borderBottomWidth: 2,
        borderColor: veryLightPink
      }
    },
    titleStyle: {
      viewStyle: {
        flex: 1,
        paddingLeft: 15,
        paddingRight: 15
      },
      textStyle: { ...h20, color: themeColor, numberOfLines: 1 }
    },
    crossStyle: {
      viewStyle: {
        paddingLeft: 15,
        paddingRight: 15,
        width: 54,
        height: 54,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      },
      image: getImage("crossIcon")
    }
  };

  class Header extends React.Component {
    render() {
      const { onClose, title, activeMQ } = this.props;
      const { containerStyle, titleStyle, crossStyle } = resolveMQ(
        componentTheme,
        ["containerStyle", "titleStyle", "crossStyle"],
        activeMQ
      );
      return (
        <Box
          {...containerStyle}
          render={[
            {
              ...titleStyle,
              text: title
            },
            onClose && {
              ...crossStyle,
              onClick: onClose
            }
          ]}
        />
      );
    }
  }
  return Header;
};
