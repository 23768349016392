import React from "react";

export  default () =>{
  class NotificationHandler extends React.Component{
      render(){
          return this.props.children;
      }

  }
  return NotificationHandler;
}