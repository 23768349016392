export default {
  "/create-vault-pin": {
    screen: "CreateVaultPin"
  },
  "/confirm-vault-pin": {
    screen: "ConfirmVaultPin"
  },
  "/enter-vault-pin": {
    screen: "EnterVaultPin"
  },
  "/forgot-vault-pin": {
    screen: "ForgotVaultPin"
  },
  "/reset-vault-pin": {
    screen: "ResetVaultPin"
  },
  "/change-vault-pin": {
    screen: "ChangeVaultPin"
  },
  "/vault": {
    screenSM: "VaultSM",
    screenMD: "VaultMD",
    hash: ({ hash }) => {
      if (hash) {
        return {
          SelectedTab: hash
        };
      }
    }
  },
  "/reset-vault-pin-mail": {
    screen: "ResetVaultPinMail",
    hash: ({ hash }) => {
      if (hash) {
        return {
          resetPinToken: hash
        };
      }
    }
  },
  "/vault-album-detail": {
    screen: "VaultAlbumDetail"
  },
  "/vault-set-detail": {
    screen: "VaultSetDetail"
  },
  "/select-vault-album": {
    screen: "SelectVaultAlbum"
  },
  "/select-vault-set": {
    screen: "SelectVaultSet"
  },
  "/select-vault-image": {
    screen: "SelectVaultImages"
  },
  "/select-vault-docs": {
    screen: "SelectVaultDocs"
  },
  "/montage-vault": {
    screen: "VaultSlideShowComponent"
  },
  "/select-cover-image-vault": {
    screen: "SelectCoverImageVault"
  }
};
