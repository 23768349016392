import React from "react";

export default ({ Box, theme, Platform }) => {
  const { colors, fonts } = theme;
  const { errorColor, highlightColor } = colors;
  const { h3, h5 } = fonts;
  class LoginErrorComponent extends React.Component {
    render() {
      const { error } = this.props;
      return (
        <Box
          viewStyle={{ paddingTop: 8 }}
          direction="row"
          render={[
            {
              viewStyle: {
                ...(Platform.OS === "web" ? { marginTop: 1 } : {}),
                height: 13,
                width: 13,
                borderRadius: 6.5,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 8,
                backgroundColor: errorColor
              },
              textStyle: { ...h5, color: highlightColor },
              text: "!"
            },
            {
              text: error,
              viewStyle: { flex: 1 },
              textStyle: {
                ...h3,
                color: errorColor,
                numberOfLines: 2
              }
            }
          ]}
        />
      );
    }
  }
  return LoginErrorComponent;
};
