import React from "react";

export default ({ Box, getImage, resolveMQ, theme, getAction, I18N }) => {
  let { fonts, colors } = theme;
  let { h16_l } = fonts;
  let { brownGrey } = colors;
  const componentStyle = {
    boxStyle: {
      direction: "row",
      viewStyle: {
        width: 385,
        height: 44,
        borderRadius: 4,
        marginLeft: 15,
        marginRight: 15,
        backgroundColor: "#efefef",
        alignItems: "center",
        cursor: "text"
      }
    },
    imageContainerStyle: {
      viewStyle: {
        paddingLeft: 16,
        paddingRight: 4,
        height: 22,
        width: 42,
        alignItems: "center",
        justifyContent: "center"
      }
    },
    labelStyle: {
      viewStyle: {
        flex: 1,
        paddingLeft: 16,
        paddingRight: 16
      },
      textStyle: {
        ...h16_l,
        color: brownGrey
      }
    }
  };

  class SearchAction extends React.Component {
    renderComponentSM = () => {
      let { style, action } = this.props;
      return (
        <Box style={style} image={getImage("searchIcon")} Container={getAction("link")} containerProps={{ action }} />
      );
    };

    renderComponentMD = () => {
      let { boxStyle, imageContainerStyle, labelStyle } = componentStyle;
      let { action } = this.props;
      let { containerStyle } = action || {};
      let component = (
        <Box
          {...boxStyle}
          Container={getAction("link")}
          containerProps={{ action }}
          render={[
            {
              image: getImage("searchIcon"),
              ...imageContainerStyle
            },
            {
              ...labelStyle,
              text: I18N.t("search")
            }
          ]}
        />
      );
      if (containerStyle) {
        component = <Box style={{ ...containerStyle }} render={component} />;
      }
      return component;
    };
    
    render() {
      const { activeMQ } = this.props;
      let { renderComponent } = resolveMQ(
        {
          renderComponentSM: this.renderComponentSM,
          renderComponentMD: this.renderComponentMD
        },
        ["renderComponent"],
        activeMQ
      );
      return renderComponent();
    }
  }
  return SearchAction;
};
