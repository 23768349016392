import React from "react";
import axios from "axios";
import {
  Box,
  withContext,
  showMessage,
  theme,
  I18N,
  getImage,
  LoginEditor as Editor,
  LoginButton as Button,
  LoginContainer as Container,
  StatusBar as LoadingIndicator,
  resolveMQ,
  View,
  RenderNoData,
  brandName,
  getErrorMessage
} from "../../../FsCloudComponent";
import ShareLogoComponent from "../share/ShareLogoComponent";

let { fonts, colors, shadows } = theme;
let { h9, h16, h16_l } = fonts;
let { primaryColor, errorColor, highlightColor, themeColor } = colors;

const headerStyleSM = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      height: 60,
      paddingLeft: 4,
      paddingRight: 12,
      alignItems: "center",
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowSM
    }
  },
  titleStyle: {
    width: "1fr",
    viewStyle: {
      height: 32,
      paddingLeft: 12,
      paddingRight: 12,
      justifyContent: "center",
      whiteSpace: "pre"
    },
    textStyle: {
      ...h16,
      color: themeColor
    }
  }
};

const headerStyleMD = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      height: 72,
      paddingLeft: 18,
      paddingRight: 18,
      alignItems: "center",
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowMD
    }
  },
  titleStyle: {
    width: "1fr",
    viewStyle: {
      height: 44,
      paddingLeft: 18,
      paddingRight: 18,
      justifyContent: "center",
      whiteSpace: "pre"
    },
    textStyle: {
      ...h16_l,
      color: primaryColor
    }
  }
};

const resetPinViewStyleSM = {
  flex: 1,
  paddingLeft: 27,
  paddingRight: 27,
  paddingTop: 18
};

const resetPinViewStyleMD = {
  ...resetPinViewStyleSM,
  "align-self": "center", //MD
  minWidth: 450
};

const linkExpiredStyle = {
  headerContainerStyleMD: {
    viewStyle: {
      height: 72,
      alignItems: "center",
      backgroundColor: highlightColor,
      padding: 15,
      position: "fixed",
      zIndex: 1,
      top: 0,
      right: 0,
      left: 0,
      ...shadows.imageViewerHeaderShadowMD
    }
  },
  headerContainerStyleSM: {
    viewStyle: {
      alignItems: "center",
      height: 60,
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowSM
    }
  },
  titleStyleMD: {
    viewStyle: {
      paddingLeft: 15
    },
    textStyle: {
      color: themeColor,
      ...h16
    }
  },
  emptyBoxStyleMD: {
    viewStyle: { height: 72 }
  },
  titleStyleSM: {
    viewStyle: {
      paddingLeft: 12
    },
    textStyle: {
      color: themeColor,
      ...h16
    }
  },
  logoStyleSM: {
    viewStyle: {
      paddingLeft: 8,
      paddingRight: 8
    },
    icon: "headerLogo"
  },
  logoStyleMD: {
    icon: "headerLogo"
  }
};

const resetFields = [
  { field: "newPin", label: "newPinLabel", placeholder: "createPinPlaceholder" },
  { field: "confirmPin", label: "confirmPinLabel", placeholder: "confirmPinPlaceholder" }
];

const resetVaultPin = async (data, props) => {
  let { invoke, urls, resetPinToken } = props;
  await invoke({
    service: {
      url: urls["resetVaultPinUsingLink"],
      uriProps: {
        data: {
          pin: data.newPin,
          resetPinToken
        }
      }
    },
    allowParallelInvoke: true
  });
  showMessage && showMessage(I18N.t("pinResetMessage"), 2000);
};

class LinkExpired extends React.Component {
  render() {
    const noDataProps = {
      image: "safeAreaIcon",
      primaryText: I18N.t("resetPinLinkExpiredHeader"),
      secondaryText: I18N.t("resetPinLinkExpiredMessage")
    };
    const { activeMQ } = this.props;
    const { headerContainerStyle, emptyBoxStyle, titleStyle, logoStyle } = resolveMQ(
      linkExpiredStyle,
      ["headerContainerStyle", "emptyBoxStyle", "titleStyle", "logoStyle"],
      activeMQ
    );
    return (
      <View style={{ flex: 1 }}>
        <Box
          direction="row"
          {...headerContainerStyle}
          render={[
            {
              ...titleStyle,
              width: "1fr",
              text: () => I18N.t("resetPin")
            },
            <ShareLogoComponent {...logoStyle} />
          ]}
        />
        <Box {...emptyBoxStyle} />
        <RenderNoData {...noDataProps} />
      </View>
    );
  }
}

class HeaderSM extends React.Component {
  render() {
    let { containerStyle, titleStyle } = headerStyleSM;
    return (
      <Box
        {...containerStyle}
        render={[
          {
            ...titleStyle,
            text: I18N.t("resetPin")
          },
          <ShareLogoComponent
            viewStyle={{
              paddingLeft: 8,
              paddingRight: 8
            }}
            icon={"headerLogo"}
          />
        ]}
      />
    );
  }
}

class HeaderMD extends React.Component {
  render() {
    let { containerStyle, titleStyle } = headerStyleMD;
    return (
      <Box
        {...containerStyle}
        render={[
          {
            ...titleStyle,
            text: I18N.t("resetPin")
          },
          <ShareLogoComponent icon={"headerLogo"} />
        ]}
      />
    );
  }
}

class ResetPinComponent extends React.Component {
  state = {};

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  onError = (err, errorField) => {
    let message = getErrorMessage(err);
    this._setState({ [errorField || "error"]: message, loading: false });
  };

  onResetClick = async () => {
    let { replaceUri } = this.props;
    let { data } = this.dataProps;
    try {
      await resetVaultPin(data, this.props);
      replaceUri && replaceUri({ uri: "/home#gallery" });
    } catch (err) {
      this.onError(err, "viewError");
    }
  };

  onSubmitClick = async e => {
    try {
      let { updatableData } = this.dataProps;
      let validateData = updatableData && updatableData.validateData;
      if (validateData) {
        let validations = await validateData();
        if (validations && Object.keys(validations).length) {
          return;
        }
      }
      this._setState({ loading: true, error: void 0, viewError: void 0 });
      await this.onResetClick(e);
      this._setState({ loading: false });
    } catch (err) {
      this.onError(err);
    }
  };

  render() {
    let contentContainerStyle = { flex: 1, minHeight: 650 };
    let { activeMQ } = this.props;
    const { resetPinViewStyle } = resolveMQ(
      { resetPinViewStyleSM, resetPinViewStyleMD },
      ["resetPinViewStyle"],
      activeMQ
    );
    return (
      <Container
        scrollViewStyle={{
          style: { flex: 1 }
        }}
      >
        {props => {
          this.dataProps = props;
          let editorProps = {
            ...props,
            state: this.state,
            setState: this._setState,
            onClick: this.onSubmitClick
          };
          let component = (
            <Box
              viewStyle={{ ...contentContainerStyle }}
              render={[
                {
                  viewStyle: resetPinViewStyle,
                  render: [
                    {
                      image: getImage("safeAreaIcon"),
                      viewStyle: {
                        alignItems: "center",
                        paddingTop: 18
                      },
                      imageProps: {
                        resizeMode: "contain"
                      }
                    },
                    {
                      viewStyle: {
                        paddingTop: 18,
                        paddingBottom: 18,
                        alignSelf: "center"
                      },
                      textStyle: {
                        ...h16,
                        color: primaryColor
                      },
                      text: I18N.t("resetPinMessage")
                    },
                    resetFields.map((fieldInfo, index) => {
                      return {
                        viewStyle: {
                          marginBottom: 18
                        },
                        render: [
                          <Editor
                            {...editorProps}
                            inputProps={{
                              autoFocus: index === 0
                            }}
                            field={fieldInfo.field}
                            placeholder={fieldInfo.placeholder}
                          />
                        ]
                      };
                    }),
                    this.state.viewError && {
                      viewStyle: {
                        marginBottom: 18
                      },
                      textStyle: {
                        ...h9,
                        color: errorColor
                      },
                      text: this.state.viewError
                    },
                    {
                      viewStyle: {
                        marginBottom: 18
                      },
                      render: <Button loading={this.state.loading} onClick={this.onSubmitClick} text={"continue"} />
                    }
                  ]
                }
              ]}
            />
          );
          return component;
        }}
      </Container>
    );
  }
}

class ResetPin extends React.Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.authenticateToken();
  }
  authenticateToken = async () => {
    let { hashMap: { resetPinToken } = {} } = this.props;
    let { urls } = this.props;
    try {
      let result = await axios.get(
        `${urls["authenticateVaultPinToken"]}/${resetPinToken}?context={"brand":"${brandName}"}`
      );
      let { data: { status, error } = {} } = result || {};
      if (status === "Authenticated") {
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, error: (error && error.message) || "error" });
      }
    } catch (err) {
      this.setState({ loading: false, error: (err && err.message) || "error" });
    }
  };
  render() {
    let { loading, error } = this.state;
    let { hashMap: { resetPinToken } = {}, activeMQ } = this.props;
    const { Header } = resolveMQ({ HeaderSM, HeaderMD }, ["Header"], activeMQ);
    if (loading) {
      return <LoadingIndicator />;
    } else if (error) {
      return <LinkExpired {...this.props} />;
    } else {
      return (
        <>
          <Header />
          <ResetPinComponent {...this.props} resetPinToken={resetPinToken} />
        </>
      );
    }
  }
}
ResetPin = withContext(ResetPin, {
  invoke: "App.invoke",
  urls: "App.urls",
  activeMQ: "ActiveMQ",
  replaceUri: "Router.replaceUri"
});
export default ResetPin;
