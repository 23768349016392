import React from "react";

export default ({ Box, resolveValue, ImageComponent, Modal, ViewComponent, isImage }) => {
  class MultiImageViewer extends React.Component {
    state = {};

    closeModal = () => {
      this.setState({ index: void 0 });
    };

    render() {
      let { index } = this.state;
      const { data, field, getImageUrl } = this.props;
      const values = resolveValue(data, field) || [];
      return (
        <Box
          render={[
            <ImageComponent
              {...this.props}
              onItemClick={({ index }) => {
                if (values && values[index] && !isImage(values[index].name)) {
                  window.open(getImageUrl(values[index]));
                } else {
                  this.setState({ index });
                }
              }}
            />,
            <Modal
              isOpen={index !== undefined}
              onClose={this.closeModal}
              autoHide={true}
              width={"100%"}
              height={"100%"}
            >
              <ViewComponent values={values} onClose={this.closeModal} index={index} />
            </Modal>
          ]}
        />
      );
    }
  }
  return MultiImageViewer;
};
