import React from "react";
import moment from "moment";
import { SECURE_TYPES } from "../Constants";
export default ({
  Box,
  theme,
  I18N,
  getImage,
  getAction,
  resolveMQ,
  RenderNoData,
  ScrollView,
  Keyboard,
  getDateRange,
  DateSelector,
  Platform,
  showMessage,
  logSearchAnalytics
}) => {
  let { colors, shadows, fonts, bgs } = theme;
  let { h1_16, h3, h3MD, h16_l } = fonts;
  let { brownGrey, primaryColor, themeColor } = colors;
  const { primaryBorder } = bgs;
  let { cardShadow } = shadows;

  const componentStyle = {
    containerStyleSM: {
      viewStyle: {
        margin: 12,
        paddingTop: 4,
        paddingBottom: 8,
        borderRadius: 2,
        backgroundColor: "white",
        ...cardShadow
      }
    },
    containerStyleMD: {
      viewStyle: {
        marginLeft: 98,
        marginRight: 110,
        paddingTop: 15,
        paddingBottom: 15,
        alignItems: "flex-start"
      }
    },
    headerTextStyleSM: {
      textStyle: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 12,
        paddingLeft: 12,
        ...h3,
        color: themeColor
      }
    },
    headerTextStyleMD: {
      textStyle: {
        paddingTop: 15,
        paddingBottom: 22,
        paddingRight: 12,
        paddingLeft: 12,
        ...h16_l,
        color: themeColor
      }
    }
  };

  const dateTypes = [
    { type: "today", title: "today" },
    { type: "yesterday", title: "yesterday" },
    { type: "thisWeek", title: "thisWeek" },
    { type: "lastWeek", title: "lastWeek" },
    { type: "thisMonth", title: "thisMonth" },
    { type: "lastMonth", title: "lastMonth" },
    { type: "thisYear", title: "thisYear" },
    { type: "lastYear", title: "lastYear" }
  ];

  const searchGallery = {
    noMatchIcon: "searchNoMatchIcon",
    noMatchHeader: () => I18N.t("noMatchHeader"),
    noMatchMessage: () => I18N.t("noMatchMessage"),
    types: [
      { type: "favourite", title: "favourite", image: "favouriteSearchIcon" },
      { type: "shared", title: "shared", image: "shareGreySearchIcon" },
      { type: "video", title: "video", image: "videoSearchIcon" }
    ],
    dateTypes
  };

  const searchDocs = {
    noMatchIcon: "searchNoMatchIcon",
    noMatchHeader: () => I18N.t("noMatchHeader"),
    noMatchMessage: () => I18N.t("noMatchMessage"),
    types: [
      { type: "favourite", title: "favourite", image: "favouriteSearchIcon" },
      { type: "shared", title: "shared", image: "shareGreySearchIcon" },
      { type: "PDF", title: "pdf", image: "pdfSearchIcon" },
      { type: "Document", title: "document", image: "docsSearchIcon" },
      { type: "Presentation", title: "presentation", image: "presentationSearchIcon" },
      { type: "Spreadsheet", title: "spreadsheet", image: "spreadsheetSearchIcon" },
      { type: "Media", title: "media", image: "mediaSearchIcon" },
      { type: "Text Document", title: "text", image: "textSearchIcon" },
      { type: "Web Page", title: "webPage", image: "webpageSearchIcon" },
      { type: "Archive", title: "archive", image: "archiveSearchIcon" }
    ],
    dateTypes
  };

  const metadata = {
    "search-gallery": {
      ...searchGallery,
      searchLink: {
        uri: "/search-gallery-type"
      }
    },
    "search-vault-gallery": {
      ...searchGallery,
      searchLink: {
        uri: "/search-vault-gallery-type"
      }
    },
    "search-archive-gallery": {
      ...searchGallery,
      searchLink: {
        uri: "/search-archive-gallery-type"
      }
    },
    "search-docs": {
      ...searchDocs,
      searchLink: {
        uri: "/search-docs-type"
      }
    },
    "search-vault-docs": {
      ...searchDocs,
      searchLink: {
        uri: "/search-vault-docs-type"
      }
    },
    "search-archive-docs": {
      ...searchDocs,
      searchLink: {
        uri: "/search-archive-docs-type"
      }
    },
    "search-music": {
      noMatchIcon: "searchNoMatchIcon",
      noMatchHeader: () => I18N.t("noMatchHeader"),
      noMatchMessage: () => I18N.t("noMatchMessage"),
      searchLink: {
        uri: "/search-music-type"
      },
      types: [
        { type: "favourite", title: "favourite", image: "favouriteSearchIcon" },
        { type: "aac", title: "aac", image: "musicSearchIcon" },
        { type: "mp3", title: "mp3", image: "musicSearchIcon" }
      ],
      dateTypes
    },
    "search-archive-music": {
      noMatchIcon: "searchNoMatchIcon",
      noMatchHeader: () => I18N.t("noMatchHeader"),
      noMatchMessage: () => I18N.t("noMatchMessage"),
      searchLink: {
        uri: "/search-archive-music-type"
      },
      types: [
        { type: "favourite", title: "favourite", image: "favouriteSearchIcon" },
        { type: "aac", title: "aac", image: "musicSearchIcon" },
        { type: "mp3", title: "mp3", image: "musicSearchIcon" }
      ],
      dateTypes
    },
    "search-group-gallery": {
      noMatchIcon: "searchNoMatchIcon",
      noMatchHeader: () => I18N.t("noMatchHeader"),
      noMatchMessage: () => I18N.t("noMatchMessage"),
      types: []
    },
    "search-group-docs": {
      noMatchIcon: "searchNoMatchIcon",
      noMatchHeader: () => I18N.t("noMatchHeader"),
      noMatchMessage: () => I18N.t("noMatchMessage"),
      types: []
    }
  };

  const getFormattedDate = ms => {
    let date = new Date(ms);
    return moment(date).format("DD/MM/YYYY");
  };

  class CustomDateSearch extends React.Component {
    onDateChange = field => value => {
      if (field && value) {
        if (field === "to") {
          value = moment(value)
            .endOf("day")
            .toDate();
        }
        this[field] = value.getTime();
      }
      let { from, to } = this;
      if (!from || !to) {
        return;
      }
      if (from > to) {
        showMessage(I18N.t("fromToValidation"), 5000);
        return;
      }
      const {
        getPath,
        addUri,
        searchLink,
        link: { props: { params: { from: fromScreen } = {} } = {} } = {}
      } = this.props;
      if (getPath && addUri && searchLink) {
        logSearchAnalytics && logSearchAnalytics({ fromScreen, type: "dateFilter" });
        addUri(getPath(), {
          ...searchLink,
          props: {
            params: {
              from: "dateFilter",
              fromTitle: `${getFormattedDate(from)} - ${getFormattedDate(to)}`,
              dateRange: { from, to }
            }
          }
        });
      }
    };

    render() {
      const textStyle = {
        ...h1_16,
        color: brownGrey
      };
      const dateSelectorCommonProps =
        Platform.OS === "web"
          ? {
              labelTextStyle: {
                textStyle
              },
              renderValue: value => {
                return {
                  viewStyle: {
                    width: 150,
                    height: 30,
                    borderBottomWidth: 1,
                    borderColor: primaryBorder,
                    alignItems: "center",
                    color: primaryColor
                  },
                  textStyle: { ...h3MD, color: primaryColor },
                  render: [
                    {
                      width: "1fr",
                      text: value ? moment(value).format("DD/MM/YYYY") : I18N.t("selectDate")
                    },
                    { viewStyle: { paddingLeft: 6, paddingRight: 6 }, image: getImage("calendarIcon") }
                  ]
                };
              }
            }
          : {
              placeholder: I18N.t("selectDate"),
              confirmBtnText: I18N.t("ok"),
              cancelBtnText: I18N.t("cancel"),
              labelTextStyle: textStyle
            };
      return (
        <Box
          render={[
            {
              textStyle,
              viewStyle: { paddingBottom: 8 },
              text: () => I18N.t("custom")
            },
            {
              direction: "row",
              itemStyle: { flex: 1 },
              render: [
                <DateSelector
                  {...dateSelectorCommonProps}
                  onDateChange={this.onDateChange("from")}
                  label={I18N.t("from")}
                />,
                <DateSelector
                  {...dateSelectorCommonProps}
                  onDateChange={this.onDateChange("to")}
                  label={I18N.t("to")}
                />
              ]
            }
          ]}
        />
      );
    }
  }

  class SearchType extends React.Component {
    onClickType = ({ onClick, props }) => {
      if (Keyboard) {
        Keyboard.dismiss();
      }
      let {
        action: { link: { props: { params: { from: type } = {} } = {} } = {} } = {},
        link: { props: { params: { from: fromScreen } = {} } = {} } = {}
      } = props || {};
      logSearchAnalytics && logSearchAnalytics({ fromScreen, type });
      onClick && onClick({ props });
    };

    dateRange = rangeStr => {
      let dateRange = getDateRange && rangeStr && getDateRange(rangeStr);
      if (dateRange) {
        let { from, to } = dateRange;
        if (from) {
          from = new Date(from).getTime();
        }
        if (to) {
          to = new Date(to).getTime();
        }
        return { from, to };
      }
    };

    render() {
      let { searchText, activeMQ, link = {} } = this.props;
      let from = link && link.props && link.props.params && link.props.params.from;
      let { secureType } = link;
      if (!from) {
        return null;
      }
      let { types: searchTypes, dateTypes, noMatchIcon, noMatchHeader, noMatchMessage, searchLink } = resolveMQ(
        metadata[from],
        ["types", "dateTypes", "noMatchIcon", "noMatchHeader", "noMatchMessage", "searchLink"],
        activeMQ
      );
      if (searchText) {
        const noDataProps = {
          image: noMatchIcon,
          primaryText: noMatchHeader,
          secondaryText: noMatchMessage
        };
        return <RenderNoData {...noDataProps} />;
      } else if (from === "search-group-gallery" || from === "search-group-docs") {
        const noDataProps = {
          image: "searchGroupIcon",
          primaryText: I18N.t("searchByNamePublisher")
        };
        return <RenderNoData {...noDataProps} />;
      } else {
        searchLink = {
          ...searchLink,
          secureType
        };
        const { containerStyle, headerTextStyle } = resolveMQ(
          componentStyle,
          ["containerStyle", "headerTextStyle"],
          activeMQ
        );
        let renderComponent = (
          <Box
            {...containerStyle}
            render={[
              {
                ...headerTextStyle,
                text: () => I18N.t("types")
              },
              {
                viewStyle: {
                  paddingBottom: 3
                },
                render: searchTypes.map(searchType => {
                  if (secureType !== SECURE_TYPES.DEFAULT && searchType.type === "shared") {
                    return void 0;
                  }
                  return {
                    Container: getAction("link"),
                    containerProps: {
                      action: {
                        link: {
                          ...searchLink,
                          props: {
                            params: {
                              from: searchType.type,
                              fromTitle: I18N.t(searchType.title)
                            }
                          }
                        }
                      },
                      onClick: this.onClickType
                    },
                    direction: "row",
                    viewStyle: {
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 8,
                      paddingBottom: 8,
                      alignItems: "center",
                      cursor: "pointer"
                    },
                    render: [
                      {
                        viewStyle: {
                          height: 24,
                          width: 24,
                          alignItems: "center",
                          justifyContent: "center"
                        },
                        image: getImage(searchType.image)
                      },
                      {
                        textStyle: {
                          ...h1_16,
                          paddingLeft: 12,
                          color: brownGrey
                        },
                        text: I18N.t(searchType.title)
                      }
                    ]
                  };
                })
              }
            ]}
          />
        );
        if (dateTypes) {
          let dateRenderComponent = (
            <Box
              {...containerStyle}
              render={[
                {
                  ...headerTextStyle,
                  text: () => I18N.t("byDate")
                },
                {
                  viewStyle: {
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    cursor: "pointer"
                  },
                  render: <CustomDateSearch {...this.props} searchLink={searchLink} />
                },
                {
                  render: dateTypes.map(dateType => {
                    return {
                      Container: getAction("link"),
                      containerProps: {
                        action: {
                          link: {
                            ...searchLink,
                            props: {
                              params: {
                                from: "dateFilter",
                                fromTitle: I18N.t(dateType.title),
                                dateRange: this.dateRange(dateType.type)
                              }
                            }
                          }
                        },
                        onClick: this.onClickType
                      },
                      direction: "row",
                      viewStyle: {
                        paddingTop: 8,
                        paddingBottom: 8,
                        alignItems: "center",
                        cursor: "pointer"
                      },
                      render: [
                        {
                          textStyle: {
                            ...h1_16,
                            paddingLeft: 12,
                            color: brownGrey
                          },
                          text: I18N.t(dateType.title)
                        }
                      ]
                    };
                  })
                }
              ]}
            />
          );
          renderComponent = <Box render={[renderComponent, dateRenderComponent]} />;
        }
        if (ScrollView) {
          renderComponent = (
            <ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps={"always"}>
              {renderComponent}
            </ScrollView>
          );
        }
        return renderComponent;
      }
    }
  }

  return SearchType;
};
