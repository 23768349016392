export default ({ fonts, colors, Platform }) => {
  let { h16_l_18, h18_bl, h16_18 } = fonts;
  const { logoColor, themeColor, highlightColor } = colors;
  const walkthroughStyle = {
    boxStyle: {
      viewStyle: {
        flex: 1
      }
    },
    swiperContainerStyle: {
      viewStyle: {
        flex: 1
      }
    },
    dotStyle: {
      marginRight: 7,
      width: 8,
      height: 8,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: themeColor,
      backgroundColor: "transparent"
    },
    activeDotStyle: {
      borderColor: "red",
      backgroundColor: "red"
    },
    logoContainerStyle: {
      viewStyle: {
        position: "absolute",
        top: 112,
        right: 0,
        left: 0,
        alignItems: "center"
      }
    },
    footerContainerStyle: {
      direction: "column",
      viewStyle: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        paddingLeft: 25,
        paddingBottom: 25,
        paddingRight: 25
      }
    },
    paginationStyle: {
      direction: "row",
      viewStyle: {
        justifyContent: "center"
      }
    },
    loginButtonStyle: {
      viewStyle: {
        paddingTop: 19,
        paddingBottom: 19,
        alignItems: "center",
        marginBottom: 8,
        marginTop: 12,
        borderRadius: 2,
        backgroundColor: logoColor,
        justifyContent: "center"
      },
      textStyle: {
        ...h18_bl,
        color: highlightColor
      }
    }
  };

  const walkthroughImageStyle = {
    boxStyle: { viewStyle: { flex: 1 } },
    imageConatinerStyle: {
      width: "1fr",
      imageProps: { resizeMode: "cover" },
      imageStyle:
        Platform.OS !== "web"
          ? { height: "100%", width: "100%" }
          : { height: "100%", width: "100%", objectFit: "cover" }
    },
    descriptionContainerStyle: {
      viewStyle: {
        position: "absolute",
        left: 25,
        right: 25,
        top: 266,
        alignItems: "center"
      }
    },
    primaryStyle: {
      textStyle: {
        ...h16_18,
        color: themeColor,
        textAlign: "center",
        paddingBottom: 6
      }
    },
    secondaryStyle: {
      textStyle: {
        paddingTop: 6,
        paddingBottom: 6,
        ...h16_l_18,
        textAlign: "center",
        color: themeColor
      }
    }
  };
  return {
    walkthroughStyle,
    walkthroughImageStyle
  };
};
