import { fetchProfilePictureQuery } from "../queries";
import { UploadProfileNoData } from "../noData/NoData";
import ResourceActions from "../resource/ResourceActions";

import React from "react";
import {
  Box,
  Dimensions,
  showMessage,
  I18N,
  withContext,
  PermissionConfirmBox,
  Modal,
  RecyclerList,
  DeviceSetting
} from "../../../FsCloudComponent";

class UploadProfilePictureView extends React.Component {
  state = {
    width: 0,
    isModalOpen: true
  };

  moveToHome = () => {
    let { url, deleteUri } = this.props;
    let path = url.split("/introduce-yourself");
    deleteUri && deleteUri(url, { uri: path[1] });
    this.setState({ isModalOpen: false });
  };
  renderConfirm = () => {
    return (
      <PermissionConfirmBox
        message={I18N.t("permissionConfirmBoxMessage")}
        cancelText={I18N.t("noThanks")}
        confirmText={I18N.t("takeMeToSettings")}
        onConfirm={() => {
          DeviceSetting.app();
          this.moveToHome();
        }}
        onCancel={() => {
          showMessage(I18N.t("galleryPermissionMessage"));
          this.moveToHome();
        }}
      />
    );
  };

  render() {
    let { data, listProps, ...rest } = this.props;
    if (!data || !data.length) {
      return null;
    } else if (data && data.length && data[0]["permissionDenied"]) {
      let modalProps = {
        stopPropagation: true,
        position: "center",
        width: 0.85,
        animationType: "none",
        style: {
          borderRadius: 4
        },
        adjustOnKeyboard: true,
        isOpen: this.state.isModalOpen
      };

      return (
        <Modal {...modalProps}>
          <Box render={this.renderConfirm()} />
        </Modal>
      );
    }
    return (
      <Box
        width={"1fr"}
        render={[
          {
            width: "1fr",
            render: (
              <RecyclerList
                data={data}
                screenWidth={Dimensions.width}
                extraData={this.props.rowData}
                {...listProps}
                rowProps={{
                  ...listProps.rowProps
                }}
                {...rest}
              />
            )
          }
        ]}
      />
    );
  }
}

UploadProfilePictureView = withContext(UploadProfilePictureView, {
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri"
});

export default {
  UploadProfilePicture: {
    component: "table",
    dataObject: "Resource",
    Component: UploadProfilePictureView,
    renderNoData: <UploadProfileNoData />,
    ...fetchProfilePictureQuery,
    listProps: {
      list: "recyclerList",
      imageField: "node.image.uri",
      imageHeight: "node.image.orientedHeight",
      imageWidth: "node.image.orientedWidth",
      indicator_field: "resource_lastModified",
      singleSelection: true,
      rowProps: {
        imageField: "node.image.uri",
        fields: {
          resource_name: "node.image.fileName",
          resource_size: "node.image.size",
          height: "node.image.orientedHeight",
          width: "node.image.orientedWidth",
          lastModified: "node.timestamp"
        },
        lastModifiedUnit: "seconds",
        imagePrefix: "thumbnail"
      }
    },
    selectionMode: true,
    headerActions: [
      {
        type: "headerAction",
        visible: visibleProps => {
          let { selectedIds } = visibleProps;
          return selectedIds && selectedIds.length > 0;
        },
        action: "uploadProfileMobileAction"
      },
      {
        type: "multiActions",
        visible: ({ data, selectedIds = [] }) => (!selectedIds.length && data && data.length ? true : false),
        action: [
          {
            ...ResourceActions.layout,
            visible: true
          }
        ]
      }
    ]
  }
};
