export default ({ resolveImage }) => {
  const miscellaneousSprite = {
    uri: "/webImages/miscellaneousIcons/1_sprite.png",
    telephoneIcon: {
      width: 18,
      height: 19,
      objectPosition: "-200px -361px",
      objectFit: "none"
    },
    mobileIcon: {
      width: 62,
      height: 62,
      objectPosition: "-70px -362px",
      objectFit: "none"
    },
    contactConfigurationIcon: {
      width: 34,
      height: 28,
      objectPosition: "-10px -184px",
      objectFit: "none"
    },
    settingsConfigurationIcon: {
      width: 34,
      height: 34,
      objectPosition: "-200px -109px",
      objectFit: "none"
    },
    helpConfigurationIcon: {
      width: 32,
      height: 32,
      objectPosition: "-70px -268px",
      objectFit: "none"
    },
    aboutConfigurationIcon: {
      width: 34,
      height: 34,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    rightArrowIcon: {
      width: 9,
      height: 16,
      objectPosition: "-199px -62px",
      objectFit: "none"
    },
    radioSelectedIcon: {
      width: 16,
      height: 16,
      objectPosition: " -200px -36px",
      objectFit: "none"
    },
    radioUnselectedIcon: {
      width: 16,
      height: 16,
      objectPosition: "-200px -10px",
      objectFit: "none"
    },
    contactSelectedIcon: {
      width: 32,
      height: 32,
      objectPosition: "-10px -222px",
      objectFit: "none"
    },
    editProfileIcon: {
      width: 29,
      height: 29,
      objectPosition: "-70px -137px",
      objectFit: "none"
    },
    editProfileMDIcon: {
      width: 27,
      height: 27,
      objectPosition: "-70px -100px",
      objectFit: "none"
    },
    nextIcon: {
      width: 48,
      height: 48,
      objectPosition: "-142px -186px",
      objectFit: "none"
    },
    previousIcon: {
      width: 48,
      height: 48,
      objectPosition: "-142px -371px",
      objectFit: "none"
    },
    musicNextIcon: {
      width: 48,
      height: 48,
      objectPosition: "-142px -10px",
      objectFit: "none"
    },
    musicPreviousIcon: {
      width: 48,
      height: 48,
      objectPosition: "-142px -68px",
      objectFit: "none"
    },
    artistIcon: {
      width: 50,
      height: 50,
      objectPosition: "-10px -54px",
      objectFit: "none"
    },
    createMemberIcon: {
      width: 50,
      height: 50,
      objectPosition: "-10px -264px",
      objectFit: "none"
    },
    toggleUpIcon: {
      width: 16,
      height: 10,
      objectPosition: "-200px -295px",
      objectFit: "none"
    },
    toggleDownIcon: {
      width: 16,
      height: 10,
      objectPosition: "-200px -315px",
      objectFit: "none"
    },
    downloadingIcon: {
      width: 28,
      height: 20,
      objectPosition: "-70px -70px",
      objectFit: "none"
    },
    uploadingIcon: {
      width: 28,
      height: 20,
      objectPosition: "-200px -390px",
      objectFit: "none"
    },
    slideShowIcon: {
      width: 18,
      height: 11,
      objectPosition: "-200px -88px",
      objectFit: "none"
    },
    gmailIcon: {
      width: 32,
      height: 24,
      objectPosition: "-70px -234px",
      objectFit: "none"
    },
    facebookIcon: {
      width: 24,
      height: 24,
      objectPosition: "-70px -200px",
      objectFit: "none"
    },
    docSelectedIcon: {
      width: 50,
      height: 50,
      objectPosition: "-10px -346px",
      objectFit: "none"
    },
    docSetIcon: {
      width: 50,
      height: 50,
      objectPosition: "-10px -406px",
      objectFit: "none"
    },
    uploadFolderIcon: {
      width: 50,
      height: 50,
      objectPosition: "-200px -420px",
      objectFit: "none"
    },
    storageFolderIcon: {
      width: 60,
      height: 60,
      objectPosition: "-200px -225px",
      objectFit: "none"
    },
    storageFolderMDIcon: {
      width: 62,
      height: 62,
      objectPosition: "-204px -153px",
      objectFit: "none"
    },
    noInternetIcon: {
      width: 102,
      height: 102,
      objectPosition: "-276px -277px",
      objectFit: "none"
    },
    inviteMemberIcon: {
      width: 105,
      height: 102,
      objectPosition: "-276px -165px",
      objectFit: "none"
    },
    uploadCoverIcon: {
      width: 22,
      height: 22,
      objectPosition: "-276px -10px",
      objectFit: "none"
    },
    errorIcon: {
      width: 14,
      height: 14,
      objectPosition: "-70px -176px",
      objectFit: "none"
    },
    musicIcon: {
      width: 51,
      height: 50,
      objectPosition: "-142px -126px",
      objectFit: "none"
    },
    musicMDIcon: {
      width: 27,
      height: 29,
      objectPosition: "-70px -434px",
      objectFit: "none"
    },
    tickIcon: {
      width: 16,
      height: 16,
      objectPosition: "-200px -335px",
      objectFit: "none"
    },
    imageCheckedSelectedIcon: {
      width: 16,
      height: 16,
      objectPosition: "-142px -285px",
      objectFit: "none"
    },
    imageCheckedHightlightIcon: {
      width: 16,
      height: 16,
      objectPosition: "-70px -336px",
      objectFit: "none"
    },
    imageCheckedIcon: {
      width: 16,
      height: 16,
      objectPosition: "-70px -310px",
      objectFit: "none"
    },
    checkedIcon: {
      width: 14,
      height: 14,
      objectPosition: "-10px -160px",
      objectFit: "none"
    },
    uncheckedIcon: {
      width: 14,
      height: 14,
      objectPosition: "-276px -42px",
      objectFit: "none"
    },
    audioIcon: {
      width: 36,
      height: 36,
      objectPosition: "-10px -114px",
      objectFit: "none"
    },
    playlistIcon: {
      width: 50,
      height: 50,
      objectPosition: "-142px -311px",
      objectFit: "none"
    },
    playlistMDIcon: {
      width: 33,
      height: 31,
      objectPosition: "-142px -244px",
      objectFit: "none"
    },
    videoBottomIcon: {
      width: 24,
      height: 14,
      objectPosition: "-276px -66px",
      objectFit: "none"
    },
    videoIcon: {
      width: 15,
      height: 15,
      objectPosition: "-276px -140px",
      objectFit: "none"
    },
    videoMDIcon: {
      width: 40,
      height: 40,
      objectPosition: "-276px -90px",
      objectFit: "none"
    },
    crossSmallIcon: {
      width: 12,
      height: 12,
      objectPosition: "-10px -324px",
      objectFit: "none"
    }
  };
  const telephoneIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.telephoneIcon
    }
  };
  const mobileIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.mobileIcon
    }
  };
  const contactConfigurationIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.contactConfigurationIcon
    }
  };
  const settingsConfigurationIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.settingsConfigurationIcon
    }
  };
  const helpConfigurationIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.helpConfigurationIcon
    }
  };
  const aboutConfigurationIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.aboutConfigurationIcon
    }
  };
  const rightArrowIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.rightArrowIcon
    }
  };
  const radioSelectedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.radioSelectedIcon
    }
  };
  const radioUnselectedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.radioUnselectedIcon
    }
  };
  const contactSelectedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.contactSelectedIcon
    }
  };
  const editProfileIcon = () =>
    resolveImage({
      SM: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.editProfileIcon
        }
      },
      MD: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.editProfileMDIcon
        }
      }
    });
  const nextIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.nextIcon
    }
  };
  const previousIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.previousIcon
    }
  };
  const musicNextIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.musicNextIcon
    }
  };
  const musicPreviousIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.musicPreviousIcon
    }
  };
  const artistIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.artistIcon
    }
  };
  const createMemberIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.createMemberIcon
    }
  };
  const toggleUpIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.toggleUpIcon
    }
  };
  const toggleDownIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.toggleDownIcon
    }
  };
  const downloadingIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.downloadingIcon
    }
  };
  const uploadingIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.uploadingIcon
    }
  };
  const slideShowIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.slideShowIcon
    }
  };
  const gmailIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.gmailIcon
    }
  };
  const facebookIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.facebookIcon
    }
  };
  const docSelectedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.docSelectedIcon
    }
  };
  const docSetIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.docSetIcon
    }
  };
  const uploadFolderIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.uploadFolderIcon
    }
  };
  const storageFolderIcon = () =>
    resolveImage({
      SM: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.storageFolderIcon
        }
      },
      MD: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.storageFolderMDIcon
        }
      }
    });
  const noInternetIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.noInternetIcon
    }
  };
  const inviteMemberIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.inviteMemberIcon
    }
  };
  const uploadCoverIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.uploadCoverIcon
    }
  };
  const errorIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.errorIcon
    }
  };
  const musicIcon = () =>
    resolveImage({
      SM: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.musicIcon
        }
      },
      MD: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.musicMDIcon
        }
      }
    });
  const tickIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.tickIcon
    }
  };

  const imageCheckedSelectedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.imageCheckedSelectedIcon
    }
  };
  const imageCheckedHightlightIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.imageCheckedHightlightIcon
    }
  };
  const imageCheckedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.imageCheckedIcon
    }
  };
  const checkedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.checkedIcon
    }
  };
  const uncheckedIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.uncheckedIcon
    }
  };
  const audioIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.audioIcon
    }
  };
  const playlistIcon = () =>
    resolveImage({
      SM: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.playlistIcon
        }
      },
      MD: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.playlistMDIcon
        }
      }
    });
  const videoBottomIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.videoBottomIcon
    }
  };
  const videoIcon = () =>
    resolveImage({
      SM: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.videoIcon
        }
      },
      MD: {
        uri: miscellaneousSprite.uri,
        spriteProps: {
          ...miscellaneousSprite.videoMDIcon
        }
      }
    });
  const crossSmallIcon = {
    uri: miscellaneousSprite.uri,
    spriteProps: {
      ...miscellaneousSprite.crossSmallIcon
    }
  };
  const musicEqualizerIcon = "/webImages/miscellaneousIcons/musicEqualizer.gif";
  const musicLoaderIcon = "/webImages/miscellaneousIcons/musicLoader.gif";
  const profileImageSmallIcon = "/webImages/miscellaneousIcons/profileImageSmall.png";
  const profileImageIcon = "/webImages/miscellaneousIcons/profileImage.png";
  const docsImageBackground = "/webImages/miscellaneousIcons/docsImageBackground.png";
  const userPhotoIcon = "/webImages/miscellaneousIcons/userPhoto.png";
  const calendarIcon = "/webImages/miscellaneousIcons/calendar.png";
  const safeAreaIcon = "/webImages/miscellaneousIcons/safeArea.png";
  const networkSelectorDropDownIcon = "/webImages/miscellaneousIcons/networkSelectorDropDown.png";
  const dotIcon = "/webImages/miscellaneousIcons/blackDot.png";
  const markerIcon = "/webImages/miscellaneousIcons/marker.png";
  const markerSMIcon = "/webImages/miscellaneousIcons/markerSM.png";
  const refreshIcon = "/webImages/miscellaneousIcons/refresh.png";
  const slideShowGreyIcon = "/webImages/miscellaneousIcons/slideShowGrey.png";
  return {
    telephoneIcon,
    mobileIcon,
    contactConfigurationIcon,
    settingsConfigurationIcon,
    helpConfigurationIcon,
    aboutConfigurationIcon,
    rightArrowIcon,
    radioSelectedIcon,
    radioUnselectedIcon,
    profileImageSmallIcon,
    profileImageIcon,
    contactSelectedIcon,
    editProfileIcon,
    nextIcon,
    previousIcon,
    musicNextIcon,
    musicPreviousIcon,
    artistIcon,
    createMemberIcon,
    toggleUpIcon,
    toggleDownIcon,
    downloadingIcon,
    uploadingIcon,
    slideShowIcon,
    gmailIcon,
    facebookIcon,
    docSelectedIcon,
    docSetIcon,
    docsImageBackground,
    uploadFolderIcon,
    storageFolderIcon,
    noInternetIcon,
    inviteMemberIcon,
    uploadCoverIcon,
    errorIcon,
    userPhotoIcon,
    musicIcon,
    musicLoaderIcon,
    musicEqualizerIcon,
    tickIcon,
    imageCheckedSelectedIcon,
    imageCheckedHightlightIcon,
    imageCheckedIcon,
    checkedIcon,
    uncheckedIcon,
    audioIcon,
    playlistIcon,
    videoBottomIcon,
    videoIcon,
    crossSmallIcon,
    calendarIcon,
    safeAreaIcon,
    networkSelectorDropDownIcon,
    refreshIcon,
    dotIcon,
    markerIcon,
    markerSMIcon,
    slideShowGreyIcon
  };
};
