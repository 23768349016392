import React from "react";

export default ({ Box, theme = {} }) => {
  class DrawerMenu extends React.Component {
    onClick = () => {
      let { openDrawer } = this.props;
      openDrawer && openDrawer();
    };
    render() {
      let { children } = this.props;
      return <Box {...theme.boxStyle} onClick={this.onClick} render={children} />;
    }
  }
  return DrawerMenu;
};
