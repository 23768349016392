import React from "react";
export default ({ Box, theme: themeHoc, I18N }) => {
  class ImageRender extends React.Component {
    state = { isLoaded: false };

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    componentWillUnmount() {
      this._unmounted = true;
    }

    onLoad = () => {
      this._setState({ isLoaded: true });
    };

    getComponent = ({ value }) => {
      let { getImageUrl, theme, imageStyle, loadingRender, renderNoData, imageProps, renderComponent } = this.props;
      let { isLoaded } = this.state;
      theme = theme || themeHoc || {};
      if (value.name) {
        value = { ...value, name: encodeURIComponent(value.name) };
      }
      let imageSrc = getImageUrl && getImageUrl(value);
      imageStyle = {
        ...(imageProps || imageStyle || theme.imageStyle)
      };
      let imageBoxProps;
      if (imageSrc) {
        if (renderComponent) {
          imageBoxProps = {
            render: renderComponent({
              value,
              style: imageStyle,
              source: { uri: imageSrc },
              onLoad: this.onLoad
            })
          };
        } else {
          imageBoxProps = {
            imageProps: {
              onLoad: this.onLoad
            },
            image: { uri: imageSrc }
          };
        }
      } else {
        imageBoxProps = renderNoData
          ? renderNoData(value)
          : { text: I18N ? I18N.t("imagePreviewError") : "Image preview not available", textStyle: { color: "white" } };
        isLoaded = true;
      }
      return (
        <Box
          viewStyle={{ flex: 1 }}
          render={[
            {
              ...theme,
              imageStyle,
              ...imageBoxProps
            },
            loadingRender && !isLoaded
              ? {
                  Container: loadingRender
                }
              : void 0
          ]}
        />
      );
    };

    render() {
      let { value } = this.props;
      if (Array.isArray(value)) {
        if (!value.length) {
          return null;
        }
        return (
          <Box
            direction="row"
            render={value.map(row => ({
              viewStyle: { padding: 12 },
              render: this.getComponent({ value: row })
            }))}
          />
        );
      } else if (value && typeof value === "object" && Object.keys(value).length) {
        return this.getComponent({ value });
      } else {
        return null;
      }
    }
  }
  return ImageRender;
};
