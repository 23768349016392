import React from "react";
import {
  Box,
  getImage,
  I18N,
  ScrollView,
  View,
  theme,
  withContext,
  RowAction,
  getAction,
  getContactPicUrl,
  getComponent,
  StatusBar as LoadingIndicator
} from "../../../../FsCloudComponent";
import groupBy from "lodash/groupBy";
import ContactActions from "./ContactActions";
import { ContactsSearchNoData } from "../../noData/NoData";
import { folkColumns, jobColumns, otherColumns } from "./ContactColumns";

const { fonts, colors, bgs, shadows } = theme;
const { highlightColor, themeColor, primaryColor, whiteTwo, brownGrey } = colors;
const { lightPink, lightThemeBg } = bgs;
const { h16, h1, h24_xl, h16_l, h3MD } = fonts;

const contactHeaderStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      height: 72,
      paddingLeft: 18,
      paddingRight: 18,
      alignItems: "center",
      marginBottom: 2,
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowMD
    }
  },
  actionStyle: {
    viewStyle: {
      height: 44,
      width: 48,
      paddingLeft: 12,
      paddingRight: 12,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    }
  },
  titleStyle: {
    width: "1fr",
    viewStyle: {
      height: 44,
      paddingLeft: 18,
      paddingRight: 18,
      justifyContent: "center",
      whiteSpace: "pre"
    },
    textStyle: {
      ...h16_l,
      color: primaryColor
    }
  }
};

class ContactsList extends React.Component {
  render() {
    let { data, onSelect, isSelected, searchText, pending, getScrollRef } = this.props;
    data.forEach(contact => {
      contact["first_character"] = contact.firstName.charAt(0).toUpperCase();
    });
    let contactGroup = groupBy(data, "first_character") || {};
    return (
      <View style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.13)", width: 270, backgroundColor: highlightColor }}>
        {searchText && (
          <Box
            viewStyle={{
              paddingTop: 18,
              paddingBottom: 12,
              paddingLeft: 30,
              paddingRight: 18
            }}
            textStyle={{
              ...h16_l,
              color: brownGrey
            }}
            text={`Search result for "${searchText}" ${!pending && data && data.length ? "(" + data.length + ")" : ""}`}
          />
        )}
        <ScrollView style={{ flex: 1 }} getRef={getScrollRef}>
          {Object.keys(contactGroup).map(firstCharacter => (
            <Box
              viewStyle={{ paddingTop: 18, cursor: "pointer" }}
              render={[
                {
                  viewStyle: { paddingLeft: 24, paddingBottom: 18, paddingRight: 18 },
                  textStyle: { ...h16, color: themeColor },
                  text: firstCharacter
                },
                contactGroup[firstCharacter].map(contact => {
                  if (!contact) {
                    return void 0;
                  }
                  let selected = isSelected(contact);

                  let backgroundColor = selected
                    ? { backgroundColor: lightThemeBg, borderLeftColor: themeColor }
                    : void 0;
                  let textStyleToPass = selected ? { ...h1 } : { ...h3MD };
                  return (
                    <Box
                      key={contact._id}
                      viewStyle={{
                        marginTop: 3,
                        marginBottom: 3,
                        paddingLeft: 24,
                        paddingTop: 6,
                        paddingBottom: 6,
                        borderLeftWidth: 2,
                        borderLeftColor: "transparent",
                        ...backgroundColor
                      }}
                      onClick={() => onSelect(contact)}
                      direction="row"
                      render={[
                        {
                          viewStyle: { paddingRight: 12 },
                          imageStyle: { width: 32, height: 32, borderRadius: 16, backgroundColor: whiteTwo },
                          image: contact["photo_id"]
                            ? { uri: getContactPicUrl(contact["photo_id"]) }
                            : getImage("profileImageSmallIcon")
                        },
                        {
                          viewStyle: { flex: 1, paddingLeft: 12, paddingRight: 12, justifyContent: "center" },
                          textStyle: {
                            color: themeColor,
                            ...textStyleToPass,
                            numberOfLines: 1
                          },
                          text: `${contact["name"]}${contact.company ? " (" + contact.company + ")" : ""}`
                        }
                      ]}
                    />
                  );
                }),
                {
                  viewStyle: {
                    marginLeft: 24,
                    marginRight: 24,
                    marginTop: 21,
                    marginBottom: 6,
                    borderBottomWidth: 1,
                    borderBottomColor: lightPink
                  }
                }
              ]}
            />
          ))}
        </ScrollView>
      </View>
    );
  }
}

class ContactInfo extends React.Component {
  getFormGroupRender = ({ title, columnsPerRow = 2, columns }) => {
    let columnCount = columns ? columns.length : 0;
    if (!columnCount) {
      return;
    }
    let { data = {} } = this.props;
    let formGroupRender = [];
    let row = [];
    for (let i = 0; i < columnCount; i++) {
      let { field, label, isEmail, isWebsite } = columns[i];
      let fieldValue = data[field];
      if (fieldValue !== undefined && fieldValue !== null && fieldValue !== "") {
        let valueComponent = {
          viewStyle: { flex: 1 },
          textStyle: { color: brownGrey, ...h3MD, numberOfLines: 1 },
          text: fieldValue
        };
        if (isWebsite || isEmail) {
          const LinkBox = getComponent("anchor");
          let url = `${isWebsite ? "http://" : "mailto:"}${fieldValue}`;
          let openInNewTab = isWebsite ? true : false;
          valueComponent = {
            viewStyle: { flex: 1, cursor: "pointer" },
            render: (
              <LinkBox url={url} openInNewTab={openInNewTab}>
                <Box textStyle={{ color: themeColor, ...h3MD, numberOfLines: 1 }} text={fieldValue} />
              </LinkBox>
            )
          };
        }

        row.push({
          width: "1fr",
          direction: "row",
          render: [
            {
              viewStyle: { width: 134, paddingRight: 12 },
              textStyle: { color: primaryColor, ...h3MD, numberOfLines: 1 },
              text: I18N.t(label)
            },
            valueComponent
          ]
        });
      }
      if (row.length === columnsPerRow) {
        formGroupRender.push({
          direction: "row",
          viewStyle: { paddingBottom: 4, paddingTop: 4 },
          render: row
        });
        row = [];
      }
    }
    if (row.length) {
      formGroupRender.push({
        direction: "row",
        viewStyle: { paddingBottom: 4, paddingTop: 4 },
        render: row
      });
    }

    if (formGroupRender.length) {
      formGroupRender.unshift({
        viewStyle: { paddingTop: 24, paddingBottom: 16 },
        textStyle: { color: themeColor, ...h16_l },
        text: I18N.t(title)
      });
      return formGroupRender;
    }
  };

  render() {
    let { data } = this.props;
    let { photo_id, name, office, department, company } = data;
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          key={data._id}
          viewStyle={{
            marginTop: 30,
            marginBottom: 30,
            marginLeft: 30,
            marginRight: 110
          }}
          render={[
            {
              direction: "row",
              viewStyle: { paddingTop: 2, paddingBottom: 10 },
              render: [
                {
                  viewStyle: { paddingRight: 30 },
                  imageStyle: { width: 125, height: 125, borderRadius: 62.5, backgroundColor: whiteTwo },
                  image: photo_id ? { uri: getContactPicUrl(photo_id) } : getImage("profileImageIcon")
                },
                {
                  width: "1fr",
                  render: [
                    {
                      viewStyle: { paddingTop: 10, paddingBottom: 8 },
                      textStyle: { color: primaryColor, ...h24_xl },
                      text: name
                    },
                    {
                      itemStyle: { paddingBottom: 6 },
                      textStyle: { color: primaryColor, ...h16_l },
                      render: [
                        office && {
                          text: office
                        },
                        department && {
                          text: department
                        },
                        company && {
                          text: company
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            this.getFormGroupRender({
              title: "personalInformation",
              columns: folkColumns
            }),
            this.getFormGroupRender({
              title: "jobDetails",
              columns: jobColumns
            }),
            this.getFormGroupRender({
              title: "otherInformation",
              columns: otherColumns
            })
          ]}
        />
      </ScrollView>
    );
  }
}

class ContactHeader extends React.Component {
  render() {
    let { selectedContact, onClose, searchText } = this.props;
    let { containerStyle, actionStyle, titleStyle } = contactHeaderStyle || {};
    return (
      <Box
        {...containerStyle}
        render={[
          {
            ...actionStyle,
            image: getImage("backArrowGreyIcon"),
            onClick: onClose
          },
          {
            ...titleStyle,
            text: I18N.t("contacts")
          },
          (selectedContact || searchText) && {
            Container: getAction("searchInput"),
            containerProps: {
              action: { loadDataOnReset: true },
              autoFocus: false
            }
          },
          {
            direction: "row",
            render: selectedContact
              ? [
                  <RowAction data={selectedContact} action={ContactActions.addContact} />,
                  <RowAction data={selectedContact} action={ContactActions.editContact} />,
                  <RowAction data={selectedContact} action={ContactActions.deleteContact} />,
                  <RowAction data={selectedContact} action={ContactActions.restoreContact} />,
                  <RowAction data={selectedContact} action={ContactActions.mergeDuplicateContact} />
                ]
              : [
                  {
                    viewStyle: { flex: 1, width: 240 },
                    direction: "row",
                    render: [
                      { width: "1fr" },
                      <RowAction data={selectedContact} action={ContactActions.addContact} />,
                      <RowAction data={selectedContact} action={ContactActions.restoreContact} />,
                      <RowAction data={selectedContact} action={ContactActions.mergeDuplicateContact} />
                    ]
                  }
                ]
          }
        ]}
      />
    );
  }
}

class ContactsViewMD extends React.Component {
  state = {};

  onSelectContact = contact => {
    this.setState({
      selectedContactId: contact ? contact._id : void 0
    });
  };

  isContactExist = contactId => {
    let { data } = this.props;
    if (data && contactId) {
      for (var row of data) {
        if (row && row._id === contactId) {
          return row;
        }
      }
    }
  };

  componentDidUpdate(prevProps) {
    let { data, searchText } = this.props;
    if (data !== prevProps.data) {
      if (searchText !== prevProps.searchText || !this.isContactExist(this.state.selectedContactId)) {
        let firstContact = data && data.length ? data[0] : void 0;
        this.onSelectContact(firstContact);
        if (this.scrollRef) {
          this.scrollRef.scrollTop = 0;
        }
      }
    }
  }

  getSelectedContact = () => {
    let { data } = this.props;
    if (data && data.length) {
      let selectedContact = this.isContactExist(this.state.selectedContactId);
      return selectedContact || data[0];
    }
  };

  isSelected = contact => {
    let selectedContact = this.getSelectedContact();
    return selectedContact && selectedContact._id === contact._id;
  };

  onClose = () => {
    let { deleteUri, url, link } = this.props;
    deleteUri && deleteUri(url, link);
  };

  getScrollRef = ref => {
    this.scrollRef = ref;
  };

  render() {
    let { data, pending } = this.props;
    let selectedContact = this.getSelectedContact();
    let isRenderNoData = !pending && (!data || (Array.isArray(data) && !data.length));
    return (
      <View style={{ flex: 1 }}>
        <ContactHeader {...this.props} selectedContact={selectedContact} onClose={this.onClose} />
        <View style={{ flex: 1 }}>
          {pending && <LoadingIndicator />}
          {isRenderNoData ? (
            <ContactsSearchNoData />
          ) : data && data.length ? (
            <View style={{ flex: 1, flexDirection: "row" }}>
              <ContactsList
                {...this.props}
                getScrollRef={this.getScrollRef}
                onSelect={this.onSelectContact}
                isSelected={this.isSelected}
              />
              <ContactInfo {...this.props} data={selectedContact} />
            </View>
          ) : (
            void 0
          )}
        </View>
      </View>
    );
  }
}

ContactsViewMD = withContext(ContactsViewMD, {
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri",
  pending: "Screen.Connect.pending",
  searchText: "Screen.Connect.dataParams.ftsString"
});

export default ContactsViewMD;
