import React from "react";
import StartUpWrapper from "./StartUpWrapper";
import { isIntroductionRequired, getSecureType, View } from "./FsCloudComponent";

export const NavLayoutMobile = ({ Body, NavStatusBar, Orientation }) => {
  class NavLayoutMobile extends React.Component {
    constructor(props) {
      super(props);
      this.handleOrientation(props);
    }

    componentDidUpdate(prevProps) {
      if (this.props.user !== prevProps.user) {
        this.handleOrientation(this.props);
      }
    }

    handleOrientation = ({ user }) => {
      if (Orientation) {
        // if (user) {
        //   Orientation.unlockAllOrientations();
        // } else {
        Orientation.lockToPortrait();
        // }
      }
    };

    onLayout = () => {
      if (Orientation) {
        Orientation.getOrientation((err, orientation) => {
          if (orientation) {
            if (this.props.orientation !== orientation) {
              this.props.setOrientation && this.props.setOrientation(orientation);
            }
          }
        });
      }
    };

    render() {
      let { style, ...restProps } = this.props;
      let { components } = restProps;

      let currentIndex = components.length - 1;
      let currentComponent = components[currentIndex];
      if (!currentComponent) {
        return null;
      }
      let { hideStatusBar, statusBarTheme } = currentComponent;
      return (
        <View style={style} onLayout={this.onLayout}>
          <NavStatusBar.Component hidden={hideStatusBar} theme={statusBarTheme} />
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Body {...restProps} />
          </View>
        </View>
      );
    }
  }

  return NavLayoutMobile;
};

export const NavLayoutWeb = ({ Header, Body }) => {
  class NavLayoutWeb extends React.Component {
    render() {
      let { style, ...restProps } = this.props;
      let { components } = restProps;
      if (!components || !components.length) {
        return null;
      }
      let currentIndex = components.length - 1;
      let navComponent = components[currentIndex];
      if (!navComponent) {
        return null;
      }
      let { screenState, navHeaderActions, component, title, hideNavHeader } = navComponent;
      screenState = screenState || (component && component.props.state);
      let link = component && component.props.link;

      return (
        <View style={style}>
          {!hideNavHeader && (
            <Header
              title={title}
              actions={navHeaderActions}
              screenState={screenState}
              link={link}
              index={currentIndex}
            />
          )}
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Body {...restProps} />
          </View>
        </View>
      );
    }
  }
  return NavLayoutWeb;
};

export const RenderNav = ({ urlMappings, resolveMQ, Platform, ...hocProps }) => {
  return props => {
    let { components, activeMQ, user } = props;
    if (!components || !components.length) {
      return null;
    }
    let componentsCount = components.length;
    let { NavLayout, DrawerLayout, UploadWrapper, NetworkListener, MusicStore } = resolveMQ(
      hocProps,
      ["NavLayout", "DrawerLayout", "UploadWrapper", "MusicStore", "NetworkListener"],
      activeMQ
    );
    let component = <NavLayout {...props} />;
    if (Platform.OS === "web" && UploadWrapper) {
      component = (
        <UploadWrapper componentsCount={componentsCount} user={user} getSecureType={getSecureType}>
          {component}
        </UploadWrapper>
      );
    }
    if (MusicStore) {
      component = <MusicStore user={user}>{component}</MusicStore>;
    }
    return (
      <NetworkListener user={user}>
        <DrawerLayout isLocked={!user || isIntroductionRequired({ user }) || componentsCount > 1}>
          <StartUpWrapper user={user} componentsCount={componentsCount}>{component}</StartUpWrapper>
        </DrawerLayout>
      </NetworkListener>
    );
  };
};
