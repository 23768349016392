export default ({ resolveImage }) => {
  const miscellaneousMdSprite = {
    uri: "/webImages/miscellaneousMDIcons/1_sprite.png",
    loginCrossIconLG: {
      width: 24,
      height: 24,
      objectPosition: "-10px -207px",
      objectFit: "none"
    },
    loginCrossIconMD: {
      width: 17,
      height: 17,
      objectPosition: "-68px -179px",
      objectFit: "none"
    },
    loginTransparentCrossIconLG: {
      width: 24,
      height: 24,
      objectPosition: "-10px -173px",
      objectFit: "none"
    },
    loginTransparentCrossIconMD: {
      width: 17,
      height: 17,
      objectPosition: "-68px -206px",
      objectFit: "none"
    },
    loginLanguageSelectorDropdown: {
      width: 12,
      height: 7,
      objectPosition: "-10px -64px",
      objectFit: "none"
    },
    selectedLanguageIcon: {
      width: 16,
      height: 16,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    copyRightIcon: {
      width: 14,
      height: 14,
      objectPosition: "-10px -109px",
      objectFit: "none"
    },
    infoCrossWebIcon: {
      width: 18,
      height: 18,
      objectPosition: "-10px -36px",
      objectFit: "none"
    },
    infoImageGreyIcon: {
      width: 20,
      height: 20,
      objectPosition: "-10px -307px",
      objectFit: "none"
    },
    infoCalenderGreyIcon: {
      width: 18,
      height: 18,
      objectPosition: "-10px -81px",
      objectFit: "none"
    },
    infoLinkGreyIcon: {
      width: 24,
      height: 24,
      objectPosition: "-10px -273px",
      objectFit: "none"
    },
    albumSlideshowTransparentIcon: {
      width: 48,
      height: 30,
      objectPosition: "-10px -133px",
      objectFit: "none"
    },
    accendingSortIcon: {
      width: 24,
      height: 24,
      objectPosition: "-10px -403px",
      objectFit: "none"
    },
    descendingSortIcon: {
      width: 24,
      height: 24,
      objectPosition: "-10px -369px",
      objectFit: "none"
    },
    androidGreyIcon: {
      width: 20,
      height: 22,
      objectPosition: "-10px -437px",
      objectFit: "none"
    },
    iosGreyIcon: {
      width: 18,
      height: 22,
      objectPosition: "-10px -241px",
      objectFit: "none"
    },
    dragUploadIcon: {
      width: 180,
      height: 159,
      objectPosition: "-68px -10px",
      objectFit: "none"
    },
    createNewPopupIcon: {
      width: 21,
      height: 22,
      objectPosition: "-10px -337px",
      objectFit: "none"
    }
  };

  const loginLanguageSelectorDropdown = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.loginLanguageSelectorDropdown
    }
  };
  const selectedLanguageIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.selectedLanguageIcon
    }
  };
  const copyRightIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.copyRightIcon
    }
  };
  const infoCrossWebIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.infoCrossWebIcon
    }
  };
  const infoImageGreyIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.infoImageGreyIcon
    }
  };
  const infoCalenderGreyIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.infoCalenderGreyIcon
    }
  };
  const infoLinkGreyIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.infoLinkGreyIcon
    }
  };
  const albumSlideshowTransparentIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.albumSlideshowTransparentIcon
    }
  };
  const accendingSortIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.accendingSortIcon
    }
  };

  const descendingSortIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.descendingSortIcon
    }
  };
  const androidGreyIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.androidGreyIcon
    }
  };
  const iosGreyIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.iosGreyIcon
    }
  };
  const dragUploadIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.dragUploadIcon
    }
  };
  const createNewPopupIcon = {
    uri: miscellaneousMdSprite.uri,
    spriteProps: {
      ...miscellaneousMdSprite.createNewPopupIcon
    }
  };

  const loginCrossIcon = () =>
    resolveImage({
      MD: {
        uri: miscellaneousMdSprite.uri,
        spriteProps: {
          ...miscellaneousMdSprite.loginCrossIconMD
        }
      },
      LG: {
        uri: miscellaneousMdSprite.uri,
        spriteProps: {
          ...miscellaneousMdSprite.loginCrossIconLG
        }
      }
    });
  const loginTransparentCrossIcon = () =>
    resolveImage({
      MD: {
        uri: miscellaneousMdSprite.uri,
        spriteProps: {
          ...miscellaneousMdSprite.loginTransparentCrossIconMD
        }
      },
      LG: {
        uri: miscellaneousMdSprite.uri,
        spriteProps: {
          ...miscellaneousMdSprite.loginTransparentCrossIconLG
        }
      }
    });

  const musicDetailCoverIcon = "/webImages/miscellaneousMDIcons/musicDetailCover.png";
  const musicPreviewIcon = "/webImages/miscellaneousMDIcons/musicDetailCover.png";
  const locationIcon = "/webImages/miscellaneousMDIcons/locationGrey.png";
  const sortingIcon = "/webImages/miscellaneousMDIcons/Sort.png";

  return {
    loginCrossIcon,
    loginTransparentCrossIcon,
    loginLanguageSelectorDropdown,
    selectedLanguageIcon,
    copyRightIcon,
    infoCrossWebIcon,
    infoImageGreyIcon,
    infoLinkGreyIcon,
    infoCalenderGreyIcon,
    albumSlideshowTransparentIcon,
    accendingSortIcon,
    descendingSortIcon,
    androidGreyIcon,
    iosGreyIcon,
    dragUploadIcon,
    createNewPopupIcon,
    musicDetailCoverIcon,
    musicPreviewIcon,
    locationIcon,
    sortingIcon
  };
};
