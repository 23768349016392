export default ({ resolveImage }) => {
  const searchSprite = {
    uri: "/webImages/searchIcons/1_sprite.png",
    searchCrossIcon: {
      width: 16,
      height: 16,
      objectPosition: "-48px -14px",
      objectFit: "none"
    },
    favouriteSearchIcon: {
      width: 19,
      height: 18,
      objectPosition: "-13px -81px",
      objectFit: "none"
    },
    shareGreySearchIcon: {
      width: 18,
      height: 18,
      objectPosition: "-47px -47px",
      objectFit: "none"
    },
    videoSearchIcon: {
      width: 14,
      height: 17,
      objectPosition: "-49px -150px",
      objectFit: "none"
    },
    musicSearchIcon: {
      width: 18,
      height: 20,
      objectPosition: "-13px -151px",
      objectFit: "none"
    },
    archiveSearchIcon: {
      width: 8,
      height: 21,
      objectPosition: "-18px -12px",
      objectFit: "none"
    },
    docsSearchIcon: {
      width: 15,
      height: 20,
      objectPosition: "-15px -46px",
      objectFit: "none"
    },
    mediaSearchIcon: {
      width: 18,
      height: 16,
      objectPosition: "-13px -115px",
      objectFit: "none"
    },
    pdfSearchIcon: {
      width: 15,
      height: 20,
      objectPosition: "-15px -182px",
      objectFit: "none"
    },
    presentationSearchIcon: {
      width: 18,
      height: 18,
      objectPosition: "-13px -217px",
      objectFit: "none"
    },
    spreadsheetSearchIcon: {
      width: 20,
      height: 17,
      objectPosition: "-46px -82px",
      objectFit: "none"
    },
    textSearchIcon: {
      width: 20,
      height: 16,
      objectPosition: "-46px -115px",
      objectFit: "none"
    },
    webpageSearchIcon: {
      width: 20,
      height: 13,
      objectPosition: "-46px -186px",
      objectFit: "none"
    },
    searchGroupIcon: {
      width: 147,
      height: 124,
      objectPosition: "-288px -158px",
      objectFit: "none"
    },
    searchGroupMDIcon: {
      width: 166,
      height: 141,
      objectPosition: "-288px -7px",
      objectFit: "none"
    },
    searchNoMatchIcon: {
      width: 125,
      height: 125,
      objectPosition: "-76px -10px",
      objectFit: "none"
    },
    searchNoMatchMDIcon: {
      width: 200,
      height: 200,
      objectPosition: "-78px -145px",
      objectFit: "none"
    }
  };
  const favouriteSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.favouriteSearchIcon
    }
  };

  const shareGreySearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.shareGreySearchIcon
    }
  };
  const videoSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.videoSearchIcon
    }
  };
  const musicSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.musicSearchIcon
    }
  };
  const archiveSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.archiveSearchIcon
    }
  };
  const docsSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.docsSearchIcon
    }
  };
  const mediaSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.mediaSearchIcon
    }
  };
  const pdfSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.pdfSearchIcon
    }
  };
  const presentationSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.presentationSearchIcon
    }
  };
  const spreadsheetSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.spreadsheetSearchIcon
    }
  };
  const textSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.textSearchIcon
    }
  };
  const webpageSearchIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.webpageSearchIcon
    }
  };
  const searchCrossIcon = {
    uri: searchSprite.uri,
    spriteProps: {
      ...searchSprite.searchCrossIcon
    }
  };
  const searchGroupIcon = () =>
    resolveImage({
      SM: {
        uri: searchSprite.uri,
        spriteProps: {
          ...searchSprite.searchGroupIcon
        }
      },
      MD: {
        uri: searchSprite.uri,
        spriteProps: {
          ...searchSprite.searchGroupMDIcon
        }
      }
    });
  const searchNoMatchIcon = () =>
    resolveImage({
      SM: {
        uri: searchSprite.uri,
        spriteProps: {
          ...searchSprite.searchNoMatchIcon
        }
      },
      MD: {
        uri: searchSprite.uri,
        spriteProps: {
          ...searchSprite.searchNoMatchMDIcon
        }
      }
    });

  return {
    searchCrossIcon,
    favouriteSearchIcon,
    shareGreySearchIcon,
    videoSearchIcon,
    musicSearchIcon,
    archiveSearchIcon,
    docsSearchIcon,
    mediaSearchIcon,
    pdfSearchIcon,
    presentationSearchIcon,
    spreadsheetSearchIcon,
    textSearchIcon,
    webpageSearchIcon,
    searchGroupIcon,
    searchNoMatchIcon
  };
};
