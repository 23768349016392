import React from "react";
import {
  DataActions as DataActionsHoc,
  CoreAction as CoreActionHoc,
  LinkAction as LinkActionHoc,
  RowAction as RowActionHoc,
  MenuItems as MenuItemsHoc,
  OnlyAction as OnlyActionHoc
} from "../../actions";
import { ConfirmBox as ConfirmBoxHoc } from "react-components";

export default ({
  theme = {},
  Box,
  withContext,
  resolveParams,
  resolveValue,
  Modal,
  Platform,
  resolveMQ,
  getNewId,
  showMessage,
  showError,
  renderChildren,
  resolveVisibleExp,
  getTheme,
  getAction,
  getImage,
  WithModal,
  getComponent,
  skipValidationToast,
  uuid,
  appServices,
  I18N
}) => {
  const {
    invoke,
    save,
    insert,
    logout,
    close,
    openLink,
    remove,
    upload,
    enableSelectionMode,
    selectAll,
    unSelectAll,
    unSupported
  } = DataActionsHoc({
    Platform,
    getNewId,
    resolveParams,
    resolveValue,
    showMessage,
    showError,
    skipValidationToast,
    uuid,
    ...appServices,
    I18N
  });

  const Close = withContext(CoreActionHoc({ onClick: close }), {
    url: "ScreenRoute.url",
    deleteUri: "Router.deleteUri",
    link: "ScreenRoute.link"
  });

  const Link = withContext(CoreActionHoc({ onClick: openLink }), {
    link: "ScreenRoute.link",
    url: "ScreenRoute.url",
    addUri: "Router.addUri",
    replaceUri: "Router.replaceUri",
    getPath: "Router.getPath",
    user: "User.user",
    getSelectedIds: "SelectionStore.getSelectedIds",
    getSelectedData: "SelectionStore.getSelectedData",
    unSelectAll: "SelectionStore.unSelectAll",
    isSyncProcessing: "StartUp.isSyncProcessing"
  });

  const Logout = withContext(CoreActionHoc({ onClick: logout }), {
    removeUserInfo: "User.removeUserInfo",
    replaceUri: "Router.replaceUri"
  });

  const EnableSelectionMode = withContext(CoreActionHoc({ onClick: enableSelectionMode }), {
    setSelectionMode: "SelectionStore.setSelectionMode"
  });

  const SelectAll = withContext(CoreActionHoc({ onClick: selectAll }), {
    selectAll: "SelectionStore.selectAll"
  });

  const UnSelectAll = withContext(CoreActionHoc({ onClick: unSelectAll }), {
    unSelectAll: "SelectionStore.unSelectAll"
  });

  const UnSupported = withContext(CoreActionHoc({ onClick: unSupported }));

  const Invoke = withContext(CoreActionHoc({ onClick: invoke }), {
    getUri: "Screen.Connect.getUri",
    reload: "Screen.Connect.reload",
    invoke: "Screen.Connect.invoke",
    getSelectedIds: "SelectionStore.getSelectedIds",
    getSelectedData: "SelectionStore.getSelectedData",
    unSelectAll: "SelectionStore.unSelectAll",
    allPageSelected: "SelectionStore.allPageSelected",
    link: "ScreenRoute.link",
    url: "ScreenRoute.url",
    addUri: "Router.addUri",
    replaceUri: "Router.replaceUri",
    deleteUri: "Router.deleteUri",
    getParentInfo: "Router.getParentInfo",
    getPath: "Router.getPath",
    validateData: "Screen.Connect.updatableData.validateData",
    showNetworkError: "NetworkListener.showNetworkError",
    user: "User.user",
    updateUserInfo: "User.updateUserInfo"
  });

  const Save = withContext(CoreActionHoc({ onClick: save }), {
    post: "Screen.Connect.post",
    getUpdates: "Screen.Connect.updatableData.getUpdates",
    addNew: "Screen.Connect.updatableData.newData",
    dataMode: "Screen.Connect.dataMode",
    pendingCount: "Screen.Connect.getPendingCount",
    validateData: "Screen.Connect.updatableData.validateData"
  });

  const Insert = withContext(CoreActionHoc({ onClick: insert }), {
    setValue: "Screen.Connect.setValue"
  });

  const Remove = withContext(CoreActionHoc({ onClick: remove }), {
    setValue: "Screen.Connect.setValue"
  });

  const LinkAction = withContext(
    LinkActionHoc({ onClick: openLink, Box, Modal, resolveMQ, theme: theme.linkActionStyle }),
    {
      link: "Screen.Route.link",
      url: "Screen.Route.url",
      addUri: "Router.addUri",
      replaceUri: "Router.replaceUri",
      getPath: "Router.getPath",
      activeMQ: "ActiveMQ",
    }
  );

  const MenuItems = withContext(
    MenuItemsHoc({
      Platform,
      Box,
      getAction,
      resolveMQ,
      resolveVisibleExp,
      getImage,
      theme: theme.menuItemsStyle
    }),
    {
      activeMQ: "ActiveMQ",
      Screen: "Screen",
      isSelectionMode: "SelectionStore.isSelectionMode",
      getSelectedIds: "SelectionStore.getSelectedIds",
      isAllSelected: "SelectionStore.isAllSelected",
      user: "User.user",
      getSelectedData: "SelectionStore.getSelectedData",
      link: "ScreenRoute.link"
    }
  );

  const OnlyAction = withContext(
    OnlyActionHoc({
      Box,
      resolveMQ,
      getImage,
      theme: theme.onlyActionStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const RowAction = withContext(
    RowActionHoc({
      Box,
      WithModal,
      resolveMQ,
      renderChildren,
      resolveTheme: getTheme,
      getAction,
      getImage,
      getComponent,
      theme: theme.rowActionStyle
    }),
    {
      activeMQ: "ActiveMQ",
    }
  );

  const FSRowAction = RowActionHoc({
    Box,
    WithModal,
    resolveMQ,
    renderChildren,
    resolveTheme: getTheme,
    getAction,
    getImage,
    getComponent,
    theme: theme.rowActionStyle
  });

  const HeaderSelectRowAction = withContext(
    RowActionHoc({
      Box,
      WithModal,
      resolveMQ,
      renderChildren,
      resolveTheme: getTheme,
      getAction,
      getImage,
      getComponent,
      theme: theme.headerRowActionStyle
    }),
    {
      activeMQ: "ActiveMQ",
      addComponentInNav: "Router.addComponent",
      removeComponentInNav: "Router.removeComponent"
    }
  );

  const HeaderAction = props => {
    const {
      action: { selectionRequired = true, ...restActionProps },
      children,
      ...restProps
    } = props;
    return (
      <RowAction
        source="Header"
        selectionRequired={selectionRequired}
        {...restActionProps}
        {...restProps}
        theme={{
          ...theme.rowActionStyle,
          ...theme.headerActionStyle
        }}
      />
    );
  };

  const ConfirmBox = withContext(ConfirmBoxHoc({ Box, resolveMQ, theme: theme.confirmBoxStyle }), {
    activeMQ: "ActiveMQ",
    link: "ScreenRoute.link",
    getSelectedIds: "SelectionStore.getSelectedIds",
    getSelectedData: "SelectionStore.getSelectedData"
  });

  const PermissionConfirmBox = withContext(ConfirmBoxHoc({ Box, resolveMQ, theme: theme.PermissionConfirmBoxStyle }), {
    activeMQ: "ActiveMQ",
    link: "ScreenRoute.link",
    getSelectedIds: "SelectionStore.getSelectedIds",
    getSelectedData: "SelectionStore.getSelectedData"
  });

  return {
    Close,
    Link,
    LinkAction,
    Invoke,
    Save,
    Insert,
    Remove,
    Logout,
    EnableSelectionMode,
    SelectAll,
    UnSelectAll,
    RowAction,
    HeaderSelectRowAction,
    HeaderAction,
    ConfirmBox,
    PermissionConfirmBox,
    MenuItems,
    OnlyAction,
    UnSupported,
    upload,
    CoreAction: CoreActionHoc,
    logout,
    FSRowAction
  };
};
