import React from "react";
import { Box, getImage, theme, I18N, ScrollView, Platform, AppConfig, MultipleText } from "../../../FsCloudComponent";

const { colors, fonts } = theme;
const { h16, h3 } = fonts;
const { primaryColor, brownGrey, themeColor } = colors;
class AboutView extends React.Component {
  render() {
    let containerStyle = { flex: 1, minHeight: 400 };
    let contentContainerStyle = Platform.OS === "web" ? {} : containerStyle;
    return (
      <ScrollView style={{ flex: 1 }} contentContainerStyle={contentContainerStyle} bounces={false}>
        <Box
          style={containerStyle}
          render={[
            {
              viewStyle: {
                flex: 1,
                minHeight: 300,
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 24,
                paddingBottom: 24
              },
              render: [
                { image: getImage("aboutLogo") },
                {
                  viewStyle: { paddingTop: 27 },
                  textStyle: { ...h16, color: primaryColor },
                  text: I18N.t("aboutVersion")
                }
              ]
            },
            {
              viewStyle: { paddingBottom: 24, alignItems: "center" },
              render: [
                {
                  viewStyle: { paddingTop: 24 },
                  textStyle: { ...h3, color: brownGrey },
                  text: `2024 ${I18N.t("copyrightText")}`
                },
                {
                  direction: "row",
                  viewStyle: { paddingTop: 12 },
                  render: (
                    <MultipleText
                      value={[
                        {
                          textStyle: { ...h3, color: brownGrey },
                          text: `${I18N.t("viewOur")} `
                        },
                        {
                          textStyle: { ...h3, color: themeColor, textDecorationLine: "underline" },
                          text: I18N.t("termsAndPolicy"),
                          link: { uri: AppConfig.APP_TERMS_CONDITION_URL },
                          external: true
                        }
                      ]}
                    />
                  )
                }
              ]
            }
          ]}
        />
      </ScrollView>
    );
  }
}

export default {
  About: {
    component: "custom",
    Component: AboutView,
    title: () => I18N.t("aboutTitle")
  }
};
