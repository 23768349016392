import React from "react";

export default ({ Box, theme, resolveValue, resolveMQ, EqualizerComponent }) => {
  let { colors, fonts } = theme;
  let { h16_l, h1_16 } = fonts;
  let { primaryColor, brownGrey, themeColor } = colors;

  const containerStyle = {
    textStyleSM: {
      ...h1_16,
      color: primaryColor,
      numberOfLines: 1
    },
    textStyleMD: {
      ...h16_l,
      color: brownGrey,
      numberOfLines: 1,
      minWidth: 0
    }
  };

  class AudioTitleComponent extends React.Component {
    constructor(props) {
      super(props);
      let { isPlaying, getPlayerState, data } = props;
      this.state = {
        isPlaying: isPlaying ? isPlaying(data) : false,
        playerState: getPlayerState ? getPlayerState(data) || "Buffering" : "Buffering"
      };
    }

    onChangeMusic = () => {
      let { isPlaying, data } = this.props;
      if (isPlaying && isPlaying(data)) {
        this.setState({
          isPlaying: true
        });
      } else if (this.state.isPlaying) {
        this.setState({
          isPlaying: false
        });
      }
    };

    onChangePlayerState = ({ playerState }) => {
      if (this.state.isPlaying) {
        this.setState({ playerState: playerState });
      }
    };

    componentDidMount() {
      let { addChangeSongListener, addPlayerStateListener } = this.props;
      addChangeSongListener && addChangeSongListener(this.onChangeMusic);
      addPlayerStateListener && addPlayerStateListener(this.onChangePlayerState);
    }

    componentWillUnmount() {
      const { removeChangeSongListener, removePlayerStateListener } = this.props;
      removeChangeSongListener && removeChangeSongListener(this.onChangeMusic);
      removePlayerStateListener && removePlayerStateListener(this.onChangePlayerState);
    }

    render() {
      let { field, data, value, activeMQ } = this.props;
      if (value === undefined && data && field) {
        value = resolveValue && resolveValue(data, field);
      }
      let { textStyle } = resolveMQ(containerStyle, ["textStyle"], activeMQ);
      if (this.state.isPlaying) {
        textStyle = {
          ...textStyle,
          color: themeColor
        };
      }
      return (
        <Box
          direction="row"
          title={value}
          render={[
            { viewStyle: { flex: 1, marginRight: 4 }, textStyle: textStyle, text: value },
            this.state.isPlaying && <EqualizerComponent playerState={this.state.playerState} />
          ]}
        />
      );
    }
  }
  return AudioTitleComponent;
};
