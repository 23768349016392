export default {
  "/resources": {
    screen: "Resources"
  },
  "/resources-temp-realtime": {
    screen: "ResourceRealTimeTempView"
  },
  "/resource-detail": {
    screen: "ResourceDetail"
  },
  "/resource-new": {
    screen: "ResourceDetail",
    props: {
      dataMode: "insert",
      viewTitle: "Upload"
    }
  }
};
