export default () => {
  const popupActionSprite = {
    uri: "/webImages/popupActionIcons/1_sprite.png",
    addItemsGreyIcon: {
      width: 21,
      height: 21,
      objectPosition: "-12px -12px",
      objectFit: "none"
    },
    editGreyIcon: {
      width: 22,
      height: 21,
      objectPosition: "-44px -112px",
      objectFit: "none"
    },
    albumCoverGreyIcon: {
      width: 20,
      height: 18,
      objectPosition: "-12px -47px",
      objectFit: "none"
    },
    calenderGreyIcon: {
      width: 18,
      height: 18,
      objectPosition: "-13px -81px",
      objectFit: "none"
    },
    deleteGreyIcon: {
      width: 16,
      height: 19,
      objectPosition: "-14px -115px",
      objectFit: "none"
    },
    lockGreyIcon: {
      width: 16,
      height: 21,
      objectPosition: "-14px -182px",
      objectFit: "none"
    },
    selectGreyIcon: {
      width: 20,
      height: 20,
      objectPosition: "-46px -12px",
      objectFit: "none"
    },
    uploadGreyIcon: {
      width: 18,
      height: 17,
      objectPosition: "-47px -82px",
      objectFit: "none"
    },

    collabrationGreyIcon: {
      height: 15,
      width: 24,
      objectPosition: "-10px -151px",
      objectFit: "none"
    },
    pinBlackIcon: {
      height: 26,
      width: 26,
      objectPosition: "-44px -239px",
      objectFit: "none"
    },
    linkGreyIcon: {
      height: 24,
      width: 24,
      objectPosition: "-44px -205px",
      objectFit: "none"
    }
  };
  const addItemsGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.addItemsGreyIcon
    }
  };
  const editGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.editGreyIcon
    }
  };
  const albumCoverGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.albumCoverGreyIcon
    }
  };
  const calenderGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.calenderGreyIcon
    }
  };
  const deleteGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.deleteGreyIcon
    }
  };
  const lockGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.lockGreyIcon
    }
  };
  const selectGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.selectGreyIcon
    }
  };
  const uploadGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.uploadGreyIcon
    }
  };
  const collabrationGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.collabrationGreyIcon
    }
  };
  const pinBlackIcon = "/webImages/popupActionIcons/pinBlack.png";
  const linkGreyIcon = {
    uri: popupActionSprite.uri,
    spriteProps: {
      ...popupActionSprite.linkGreyIcon
    }
  };

  const downloadZipIcon = "/webImages/popupActionIcons/downloadGrey.png";

  return {
    addItemsGreyIcon,
    editGreyIcon,
    albumCoverGreyIcon,
    calenderGreyIcon,
    deleteGreyIcon,
    lockGreyIcon,
    selectGreyIcon,
    uploadGreyIcon,
    collabrationGreyIcon,
    pinBlackIcon,
    linkGreyIcon,
    downloadZipIcon
  };
};
