import MusicTab from "./MusicTab";
import MusicAll from "./MusicAll";
import Artist from "./Artist";
import Playlist from "./Playlist";
import SelectMusic from "./SelectMusic";
import SelectPlaylist from "./SelectPlaylist";
import PlaylistDetail from "./PlaylistDetail";
import MusicDetail from "./MusicDetail";
import ArtistDetail from "./ArtistDetail";

export default {
  ...MusicAll,
  ...MusicTab,
  ...SelectMusic,
  ...Playlist,
  ...SelectPlaylist,
  ...Artist,
  ...PlaylistDetail,
  ...MusicDetail,
  ...ArtistDetail,
};
