import DocsAll from "./DocsAll";
import DocsSet from "./DocsSet";
import DocsTab from "./DocsTab";
import SelectDocs from "./SelectDocs";
import SelectSet from "./SelectSet";
import SetDetail from "./SetDetail";
import DocDetail from "./DocDetail";

export default {
  ...DocsAll,
  ...DocsSet,
  ...DocsTab,
  ...SelectDocs,
  ...SelectSet,
  ...SetDetail,
  ...DocDetail
};
