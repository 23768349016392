import React from "react";
import {
  Box,
  View,
  getComponent,
  getJsonComponent,
  theme,
  RowAction,
  getImage,
  withContext,
  getAction,
  resolveMQ,
  StatusBar as LoadingIndicator,
  I18N,
  addRealTimeListener,
  removeRealTimeListener,
  addTableListener,
  removeTableListener,
  CollectionTable,
  dataFormat,
  getOwnerAndModeText,
  getModeText,
  Platform,
  Link
} from "../../../FsCloudComponent";
import CollectionActions from "./CollectionActions";
import ResourceDataObject from "../../dataObjects/ResourceDataObject";
import ResourceActions from "../resource/ResourceActions";
import { uploadResourceActionVisibility } from "../resource/ResourceUtility";
import AlbumDetail from "../gallery/AlbumDetailComponent";
import { getVisibilityParams } from "./CollectionUtility";
const { numberFormatter } = dataFormat;

const { fonts, colors, shadows, bgs } = theme;
const { primaryBg5 } = bgs;
const { h16_l, h16 } = fonts;
const { primaryColor, themeColor, highlightColor } = colors;

const metadata = {
  album: {
    addMoreItemsToCollectionSM: "addItemsToAlbumLink",
    addMoreItemsToCollectionMD: "addItemsToAlbumForm",
    addItemsToOtherCollectionSM: "addImagesToAlbumLink",
    addItemsToOtherCollectionMD: "addImagesToAlbumForm",
    removeFromCollection: "removeFromAlbum",
    markDeleteSM: "markDeleteAlbum",
    markDeleteMD: "markDeleteAlbumWithGreyImage",
    renameSM: "renameAlbum",
    renameMD: "renameAlbumWithGreyImage",
    addToCollectionIcon: "addToCollectionWhiteIcon",
    removeToCollectionIcon: "removeToCollectionWhiteIcon",
    downloadIcon: "downloadWhiteIcon",
    editIcon: "editWhiteIcon",
    deleteIcon: "deleteWhiteIcon",
    shareIconSM: "shareGreyIcon",
    shareIconMD: "shareWhiteIcon",
    iconMenuSM: "iconMenuGrey",
    iconMenuMD: "iconMenuWhite",
    crossIconSM: "backIcon",
    crossIconMD: "crossWhiteIcon",
    backIconSM: "backIcon",
    backIconMD: "backArrowWhiteIcon",
    moveToVaultIcon: "moveToVaultWhiteIcon",
    moveToArchiveIcon: "moveToArchiveWhiteIcon"
  },
  places: {
    addItemsToOtherCollectionSM: "addImagesToAlbumLink",
    addItemsToOtherCollectionMD: "addImagesToAlbumForm",
    addToCollectionIcon: "addToCollectionWhiteIcon",
    downloadIcon: "downloadWhiteIcon",
    editIcon: "editWhiteIcon",
    deleteIcon: "deleteWhiteIcon",
    shareIconSM: "shareGreyIcon",
    shareIconMD: "shareWhiteIcon",
    iconMenuSM: "iconMenuGrey",
    iconMenuMD: "iconMenuWhite",
    crossIconSM: "backIcon",
    crossIconMD: "crossWhiteIcon",
    backIconSM: "backIcon",
    backIconMD: "backArrowWhiteIcon",
    moveToVaultIcon: "moveToVaultWhiteIcon",
    moveToArchiveIcon: "moveToArchiveWhiteIcon"
  },
  deviceFolder: {
    addItemsToOtherCollectionSM: "addImagesToAlbumLink",
    addItemsToOtherCollectionMD: "addImagesToAlbumForm",
    addToCollectionIcon: "addToCollectionWhiteIcon",
    downloadIcon: "downloadWhiteIcon",
    editIcon: "editWhiteIcon",
    deleteIcon: "deleteWhiteIcon",
    shareIconSM: "shareGreyIcon",
    shareIconMD: "shareWhiteIcon",
    iconMenuSM: "iconMenuGrey",
    iconMenuMD: "iconMenuWhite",
    crossIconSM: "backIcon",
    crossIconMD: "crossWhiteIcon",
    backIconSM: "backIcon",
    backIconMD: "backArrowWhiteIcon",
    moveToVaultIcon: "moveToVaultWhiteIcon",
    moveToArchiveIcon: "moveToArchiveWhiteIcon"
  },
  set: {
    typePostFixMD: () => I18N.t("set"),
    addMoreItemsToCollectionSM: "addItemsToSetLink",
    addMoreItemsToCollectionMD: "addItemsToSetForm",
    addItemsToOtherCollectionSM: "addDocsToSetLink",
    addItemsToOtherCollectionMD: "addDocsToSetForm",
    removeFromCollection: "removeFromSet",
    markDeleteSM: "markDeleteSet",
    markDeleteMD: "markDeleteSetWithGreyImage",
    renameSM: "renameSet",
    renameMD: "renameSetWithGreyImage",
    addToCollectionIcon: "addToCollectionIcon",
    removeToCollectionIcon: "removeToCollectionIcon",
    downloadIcon: "downloadIcon",
    editIcon: "editIcon",
    deleteIcon: "deleteIcon",
    shareIconSM: "shareGreyIcon",
    shareIconMD: "shareIcon",
    iconMenuSM: "iconMenuGrey",
    iconMenuMD: "iconMenu",
    crossIconSM: "backIcon",
    crossIconMD: "crossIcon",
    backIconSM: "backIcon",
    backIconMD: "backArrowGreyIcon",
    moveToVaultIcon: "moveToVaultIcon",
    moveToArchiveIcon: "moveToArchiveIcon"
  },
  playlist: {
    addMoreItemsToCollectionSM: "addItemsToPlaylistLink",
    addMoreItemsToCollectionMD: "addItemsToPlaylistForm",
    addItemsToOtherCollectionSM: "addMusicToPlaylistLink",
    addItemsToOtherCollectionMD: "addMusicToPlaylistForm",
    removeFromCollection: "removeFromPlaylist",
    markDeleteSM: "markDeletePlaylist",
    markDeleteMD: "markDeletePlaylistWithGreyImage",
    renameSM: "renamePlaylist",
    renameMD: "renamePlaylistWithGreyImage",
    addToCollectionIcon: "addToCollectionIcon",
    removeToCollectionIcon: "removeToCollectionIcon",
    downloadIcon: "downloadIcon",
    editIcon: "editIcon",
    deleteIcon: "deleteIcon",
    iconMenuSM: "iconMenuGrey",
    iconMenuMD: "iconMenu",
    crossIconSM: "backIcon",
    crossIconMD: "crossIcon",
    backIconSM: "backIcon",
    backIconMD: "backArrowGreyIcon",
    moveToArchiveIcon: "moveToArchiveIcon"
  },
  vaultAlbum: {
    addMoreItemsToCollectionSM: "addItemsToVaultAlbumLink",
    addMoreItemsToCollectionMD: "addItemsToVaultAlbumForm",
    addItemsToOtherCollectionSM: "addImagesToVaultAlbumLink",
    addItemsToOtherCollectionMD: "addImagesToVaultAlbumForm",
    removeFromCollection: "removeFromAlbum",
    markDeleteSM: "markDeleteAlbum",
    markDeleteMD: "markDeleteAlbumWithGreyImage",
    renameSM: "renameAlbum",
    renameMD: "renameAlbumWithGreyImage",
    addToCollectionIcon: "addToCollectionWhiteIcon",
    removeToCollectionIcon: "removeToCollectionWhiteIcon",
    downloadIcon: "downloadWhiteIcon",
    editIcon: "editWhiteIcon",
    iconMenuSM: "iconMenuGrey",
    iconMenuMD: "iconMenuWhite",
    crossIconSM: "backIcon",
    crossIconMD: "crossWhiteIcon",
    backIconSM: "backIcon",
    backIconMD: "backArrowWhiteIcon",
    removeFromVaultIcon: "removeFromVaultWhiteIcon"
  },
  vaultSet: {
    typePostFixMD: () => I18N.t("set"),
    addMoreItemsToCollectionSM: "addItemsToVaultSetLink",
    addMoreItemsToCollectionMD: "addItemsToVaultSetForm",
    addItemsToOtherCollectionSM: "addDocsToVaultSetLink",
    addItemsToOtherCollectionMD: "addDocsToVaultSetForm",
    removeFromCollection: "removeFromSet",
    markDeleteSM: "markDeleteSet",
    markDeleteMD: "markDeleteSetWithGreyImage",
    renameSM: "renameSet",
    renameMD: "renameSetWithGreyImage",
    addToCollectionIcon: "addToCollectionIcon",
    removeToCollectionIcon: "removeToCollectionIcon",
    downloadIcon: "downloadIcon",
    editIcon: "editIcon",
    deleteIcon: "deleteIcon",
    iconMenuSM: "iconMenuGrey",
    iconMenuMD: "iconMenu",
    crossIconSM: "backIcon",
    crossIconMD: "crossIcon",
    backIconSM: "backIcon",
    backIconMD: "backArrowGreyIcon",
    removeFromVaultIcon: "removeFromVaultWhiteIcon"
  }
};

const componentTheme = {
  containerStyleSM: {
    flex: 1
  },
  containerStyleMD: {
    flex: 1,
    backgroundColor: primaryBg5
  },
  headerStyleSM: {
    containerStyle: {
      direction: "row",
      viewStyle: {
        height: 60,
        paddingLeft: 4,
        paddingRight: 12,
        alignItems: "center",
        backgroundColor: highlightColor,
        ...shadows.navHeaderShadowSM
      }
    },
    actionStyle: {
      viewStyle: {
        height: 44,
        width: 44,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      }
    },
    titleStyle: {
      width: "1fr",
      viewStyle: {
        height: 32,
        paddingLeft: 12,
        paddingRight: 12,
        justifyContent: "center",
        whiteSpace: "pre"
      },
      textStyle: {
        ...h16,
        color: themeColor
      }
    }
  },
  headerStyleMD: {
    containerStyle: {
      direction: "row",
      viewStyle: {
        height: 72,
        paddingLeft: 18,
        paddingRight: 18,
        alignItems: "center",
        backgroundColor: highlightColor,
        ...shadows.navHeaderShadowMD
      }
    },
    actionStyle: {
      viewStyle: {
        height: 44,
        width: 48,
        paddingLeft: 12,
        paddingRight: 12,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      }
    },
    titleStyle: {
      width: "1fr",
      viewStyle: {
        height: 44,
        paddingLeft: 18,
        paddingRight: 18,
        justifyContent: "center",
        whiteSpace: "pre"
      },
      textStyle: {
        ...h16_l,
        color: primaryColor
      }
    }
  },
  bodyStyleSM: {
    flex: 1
  },
  bodyStyleMD: {
    flex: 1,
    marginLeft: 81
  }
};

class DetailCollectionHeaderSM extends React.Component {
  render() {
    let { Resource: { actions: { sortingCreatedOn, sortingLastModified } = {} } = {} } = ResourceDataObject;

    let {
      skipTitle,
      rowData,
      data,
      onClose,
      selectedIds,
      selectionMode,
      origin,
      fromArtist,
      activeMQ,
      theme,
      getSelectedData,
      sharedBy,
      fromShare,
      link: { props: { sharedToken } = {} } = {}
    } = this.props;
    const {
      addMoreItemsToCollection,
      addItemsToOtherCollection,
      markDelete,
      rename,
      removeFromCollection,
      iconMenu,
      crossIcon,
      backIcon,
      shareIcon
    } = resolveMQ(
      metadata[origin],
      [
        "addMoreItemsToCollection",
        "addItemsToOtherCollection",
        "markDelete",
        "rename",
        "removeFromCollection",
        "iconMenu",
        "crossIcon",
        "backIcon",
        "shareIcon"
      ],
      activeMQ
    );
    let { containerStyle, actionStyle, titleStyle } = theme;
    if (this.props.containerStyle) {
      containerStyle = this.props.containerStyle;
    }
    if (this.props.titleStyle) {
      titleStyle = this.props.titleStyle;
    }
    const selectionCount = selectedIds && selectedIds.length ? selectedIds.length : void 0;
    let { collectionOwner, selectedResourcesOwner } = getVisibilityParams(this.props);
    let selectedData = getSelectedData && getSelectedData();
    let shareActionVisible = uploadResourceActionVisibility({ selectedData });
    const title = selectionMode
      ? selectionCount
        ? `${selectionCount} ${I18N.t("selected")}`
        : I18N.t("selectItemsDot")
      : skipTitle
      ? ""
      : rowData.name;

    let backAction = {};
    if (selectionMode) {
      backAction.image = getImage(crossIcon);
      backAction.Container = getAction("unSelectAll");
    } else {
      backAction.image = getImage(backIcon);
      backAction.onClick = onClose;
    }

    const renderActions = selectionCount
      ? [
          selectedResourcesOwner &&
            shareActionVisible &&
            origin !== "playlist" &&
            origin !== "vaultAlbum" &&
            origin !== "vaultSet" && (
              <RowAction
                source="Header"
                data={data}
                action={[
                  ResourceActions.postToGroup,
                  origin === "set" ? ResourceActions.webShareDoc : ResourceActions.webShareGallery,
                  origin === "set" ? ResourceActions.nativeShareDoc : ResourceActions.nativeShareGallery,
                  origin === "set" ? ResourceActions.getLinkDoc : ResourceActions.getLink,
                  origin === "set" ? ResourceActions.getLinkDocNative : ResourceActions.getLinkNative
                ]}
              >
                <Box {...actionStyle} title={I18N.t("toShareTitle")} image={getImage(shareIcon)} />
              </RowAction>
            ),
          <RowAction
            source="Header"
            data={data}
            action={[
              ResourceActions.selectAll,
              ResourceActions.selectNone,
              selectedResourcesOwner && ResourceActions[addItemsToOtherCollection],
              !fromArtist && (collectionOwner || selectedResourcesOwner) && ResourceActions[removeFromCollection],
              { ...ResourceActions.download, visible: () => Platform.OS !== "ios" || origin === "album" },
              selectedResourcesOwner && (origin === "album" || origin === "places") && ResourceActions.saveAsPdfWeb,
              selectedResourcesOwner && origin !== "playlist" && selectionCount === 1 && ResourceActions.rename,
              selectedResourcesOwner &&
                origin !== "playlist" &&
                origin !== "vaultAlbum" &&
                origin !== "vaultSet" &&
                ResourceActions.moveToVault,
              (origin === "vaultAlbum" || origin === "vaultSet") && ResourceActions.removeFromVault,
              selectedResourcesOwner &&
                origin !== "vaultAlbum" &&
                origin !== "vaultSet" &&
                ResourceActions.moveToArchive,
              selectedResourcesOwner && origin !== "vaultAlbum" && origin !== "vaultSet" && ResourceActions.markDelete
            ]}
          >
            <Box {...actionStyle} image={getImage(iconMenu)} />
          </RowAction>
        ]
      : selectionMode
      ? [
          <RowAction source="Header" data={data} action={[ResourceActions.selectAll, ResourceActions.selectNone]}>
            <Box {...actionStyle} image={getImage(iconMenu)} />
          </RowAction>
        ]
      : [
          <RowAction
            data={rowData}
            action={
              fromArtist
                ? [CollectionActions.select]
                : [
                    (origin === "album" || origin === "set") && CollectionActions.manageCollabration,
                    origin === "album" && CollectionActions.makePrivateAlbum,
                    origin === "set" && CollectionActions.makePrivateSet,
                    (origin === "album" || origin === "set") && CollectionActions.share,
                    (origin === "album" || origin === "set") && CollectionActions.shareNative,
                    (origin === "album" || origin === "set") && CollectionActions.getLinkNative,
                    origin === "album" && { ...sortingLastModified, image: undefined },
                    origin === "album" && { ...sortingCreatedOn, image: undefined },
                    CollectionActions[addMoreItemsToCollection],
                    CollectionActions[rename],
                    (origin === "album" || origin === "vaultAlbum") && CollectionActions.setCoverLink,
                    CollectionActions[markDelete],
                    CollectionActions.select,
                    { ...ResourceActions.layout, visible: () => origin === "album" || origin === "vaultAlbum" }
                  ]
            }
          >
            <Box {...actionStyle} image={getImage(iconMenu)} />
          </RowAction>
        ];

    return (
      <Box
        {...containerStyle}
        render={[
          {
            ...actionStyle,
            ...backAction
          },
          {
            ...titleStyle,
            text: title
          },
          origin === "places" && {
            viewStyle: {
              width: 40,
              height: 40,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            },
            Container: Link,
            containerProps: {
              action: {
                link: {
                  uri: "/montage-place",
                  props: {
                    params: { data: rowData, sharedBy, fromShare, sharedToken },
                    parentInfo: { _connectProps: { data: data } }
                  }
                }
              }
            },
            title: () => I18N.t("slideshowTitle"),
            image: getImage("slideShowGreyIcon")
          },
          {
            direction: "row",
            render: renderActions
          }
        ]}
      />
    );
  }
}

class DetailCollectionHeaderMD extends React.Component {
  render() {
    let {
      skipTitle,
      rowData,
      data,
      onClose,
      selectedIds,
      selectionMode,
      origin,
      activeMQ,
      theme,
      getSelectedData,
      isAllSelected,
      sharedBy,
      fromShare,
      link: { props: { sharedToken } = {} } = {}
    } = this.props;
    // console.warn("detail collection data props", this.props, "selected data");
    const {
      addMoreItemsToCollection,
      addItemsToOtherCollection,
      markDelete,
      rename,
      removeFromCollection,
      addToCollectionIcon,
      removeToCollectionIcon,
      downloadIcon,
      editIcon,
      deleteIcon,
      shareIcon,
      iconMenu,
      crossIcon,
      backIcon,
      moveToVaultIcon,
      moveToArchiveIcon,
      removeFromVaultIcon
    } = resolveMQ(
      metadata[origin],
      [
        "addMoreItemsToCollection",
        "addItemsToOtherCollection",
        "markDelete",
        "rename",
        "removeFromCollection",
        "addToCollectionIcon",
        "removeToCollectionIcon",
        "downloadIcon",
        "editIcon",
        "deleteIcon",
        "shareIcon",
        "iconMenu",
        "crossIcon",
        "backIcon",
        "moveToVaultIcon",
        "moveToArchiveIcon",
        "removeFromVaultIcon"
      ],
      activeMQ
    );
    let { containerStyle, actionStyle, titleStyle } = theme;
    if (this.props.containerStyle) {
      containerStyle = this.props.containerStyle;
    }
    if (this.props.titleStyle) {
      titleStyle = this.props.titleStyle;
    }
    const selectionCount = selectedIds && selectedIds.length ? selectedIds.length : void 0;
    let { collectionOwner, selectedResourcesOwner } = getVisibilityParams(this.props);
    let selectedData = getSelectedData && getSelectedData();
    let allSelected = isAllSelected && isAllSelected();
    let shareActionVisible = uploadResourceActionVisibility({ selectedData });
    let title = selectionMode
      ? selectionCount
        ? `${selectionCount} ${I18N.t("selected")}`
        : I18N.t("selectItemsDot")
      : skipTitle
      ? ""
      : rowData.name;

    let backAction = {};
    if (selectionMode) {
      backAction.image = getImage(crossIcon);
      backAction.Container = getAction("unSelectAll");
    } else {
      backAction.image = getImage(backIcon);
      backAction.onClick = onClose;
    }
    const renderActions = selectionCount
      ? [
          selectedResourcesOwner &&
            shareActionVisible &&
            origin !== "playlist" &&
            origin !== "vaultAlbum" &&
            origin !== "vaultSet" && (
              <RowAction
                source="Header"
                data={data}
                action={origin === "set" ? ResourceActions.webShareDoc : ResourceActions.webShareGallery}
                image={shareIcon}
              />
            ),
          selectedResourcesOwner && (
            <RowAction
              source="Header"
              data={data}
              action={{
                ...ResourceActions[addItemsToOtherCollection],
                collection: rowData,
                image: addToCollectionIcon
              }}
            />
          ),
          (selectedResourcesOwner || collectionOwner) && (
            <RowAction
              source="Header"
              data={data}
              action={{
                ...ResourceActions[removeFromCollection],
                image: removeToCollectionIcon
              }}
            />
          ),
          <RowAction
            source="Header"
            data={data}
            action={{
              ...ResourceActions.download,
              image: downloadIcon
            }}
          />,
          selectedResourcesOwner && origin !== "playlist" && selectionCount === 1 && (
            <RowAction
              source="Header"
              data={data}
              action={{
                ...ResourceActions.rename,
                image: editIcon
              }}
            />
          ),
          selectedResourcesOwner && origin !== "playlist" && origin !== "vaultAlbum" && origin !== "vaultSet" && (
            <RowAction
              source="Header"
              data={data}
              action={{
                ...ResourceActions.moveToVault,
                image: moveToVaultIcon
              }}
            />
          ),
          selectedResourcesOwner && (origin === "vaultAlbum" || origin === "vaultSet") && (
            <RowAction
              source="Header"
              data={data}
              action={{
                ...ResourceActions.removeFromVault,
                image: removeFromVaultIcon
              }}
            />
          ),
          selectedResourcesOwner && origin !== "vaultAlbum" && origin !== "vaultSet" && (
            <RowAction
              source="Header"
              data={data}
              action={{
                ...ResourceActions.moveToArchive,
                image: moveToArchiveIcon
              }}
            />
          ),
          selectedResourcesOwner && origin !== "vaultAlbum" && origin !== "vaultSet" && (
            <RowAction
              source="Header"
              data={data}
              action={{
                ...ResourceActions.markDelete,
                image: deleteIcon
              }}
            />
          ),
          data && data.length && (
            <RowAction
              data={data}
              action={[
                {
                  ...ResourceActions.selectAll,
                  visible: () => !allSelected
                },
                ResourceActions.selectNone,
                selectedResourcesOwner && (origin === "album" || origin === "places") && ResourceActions.saveAsPdfWeb
              ]}
            >
              <Box {...actionStyle} image={getImage(iconMenu)} />
            </RowAction>
          )
        ]
      : selectionMode
      ? [
          data && data.length && (
            <RowAction data={rowData} action={[{ ...ResourceActions.selectAll, visible: () => true }]}>
              <Box {...actionStyle} image={getImage(iconMenu)} />
            </RowAction>
          )
        ]
      : [
          (origin === "album" || origin === "set") && rowData.owner && (
            <RowAction data={rowData} action={CollectionActions.shareWithGreyImage} image={shareIcon} />
          ),
          (origin === "playlist" || rowData.owner || rowData.colloborator_mode !== "read") && origin !== "places" && (
            <RowAction
              data={rowData}
              action={{
                ...CollectionActions[addMoreItemsToCollection],
                image: addToCollectionIcon
              }}
            />
          ),
          <RowAction
            data={rowData}
            menuModalStyle={
              origin === "album" && {
                modal: {
                  stopPropagation: true,
                  width: 270,
                  position: "cover",
                  autoHide: true,
                  style: { borderRadius: 4 }
                }
              }
            }
            action={[
              (origin === "album" || origin === "set") && CollectionActions.manageCollabrationWithGreyImage,
              origin === "album" && CollectionActions.makePrivateAlbumWithGreyImage,
              origin === "set" && CollectionActions.makePrivateSetWithGreyImage,
              origin === "album" && ResourceDataObject.Resource.actions.sortingLastModified,
              origin === "album" && ResourceDataObject.Resource.actions.sortingCreatedOn,
              CollectionActions[rename],
              origin === "album" && CollectionActions.setCoverWithGreyImage,
              origin === "vaultAlbum" && { ...CollectionActions.setCoverWithGreyImage, origin: "vault" },
              CollectionActions.selectWithGreyImage,
              CollectionActions[markDelete]
            ]}
          >
            <Box {...actionStyle} image={getImage(iconMenu)} />
          </RowAction>
        ];

    return (
      <Box
        {...containerStyle}
        render={[
          {
            ...actionStyle,
            ...backAction
          },
          {
            ...titleStyle,
            text: title
          },
          origin === "places" && {
            viewStyle: {
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              alignSelf: "center"
            },
            Container: Link,
            containerProps: {
              action: {
                link: {
                  uri: origin === "album" ? "/montage" : origin === "vaultAlbum" ? "/montage-vault" : "/montage-place",
                  props: {
                    params: { data: rowData, sharedBy, fromShare, sharedToken },
                    parentInfo: { _connectProps: { data: data } }
                  }
                }
              }
            },
            title: () => I18N.t("slideshowTitle"),
            image: getImage("slideShowIcon")
          },
          {
            direction: "row",
            render: renderActions
          }
        ]}
      />
    );
  }
}

class DetailCollection extends React.Component {
  state = {};

  onClose = () => {
    let { deleteUri, url, link } = this.props;
    this.deleted = true;
    deleteUri && deleteUri(url, link);
  };

  onRealTimeCollectionUpdate = ({ data: collectionData }) => {
    let ownerAndModeText = getOwnerAndModeText(collectionData, { user: this.props.user });
    this.setState({
      collectionData: {
        ...collectionData,
        ...ownerAndModeText
      }
    });
  };

  handleTableListener = ({ data }) => {
    if (!data || !data.length) {
      return;
    }

    let { props: { params: { collection } = {} } = {} } = this.props.link || {};

    let _id = collection && collection._id;

    data.forEach(row => {
      if (row && _id && row._id === _id && row._op === "removed") {
        //collection has been removed.
        this.onClose();
      }
    });
  };

  componentDidMount() {
    let { props: { params: { collection } = {} } = {} } = this.props.link || {};
    let table = CollectionTable;
    let _id = collection && collection._id;
    if (_id) {
      addRealTimeListener({ table, _id, callback: this.onRealTimeCollectionUpdate });
      addTableListener && addTableListener({ table, callback: this.handleTableListener });
    }
  }

  componentWillUnmount() {
    let { props: { params: { collection } = {} } = {} } = this.props.link || {};
    let table = CollectionTable;
    let _id = collection && collection._id;
    if (_id) {
      removeRealTimeListener({ table, _id, callback: this.onRealTimeCollectionUpdate });
      removeTableListener && removeTableListener({ table, callback: this.handleTableListener });
    }
  }

  render() {
    let {
      data,
      pending,
      link,
      fetchMore,
      dataIds,
      hasNext,
      lastDoc,
      activeMQ,
      rowProps,
      listProps,
      schema,
      fromShare,
      sharedBy
    } = this.props;
    if (this.deleted) {
      return null;
    }
    let { DetailCollectionHeader } = resolveMQ(
      {
        DetailCollectionHeaderSM,
        DetailCollectionHeaderMD
      },
      ["DetailCollectionHeader"],
      activeMQ
    );
    if (this.props.DetailCollectionHeader) {
      DetailCollectionHeader = this.props.DetailCollectionHeader;
    }
    const { containerStyle, headerStyle, bodyStyle } = resolveMQ(
      componentTheme,
      ["containerStyle", "headerStyle", "bodyStyle"],
      activeMQ
    );

    let { origin, props: { params } = {}, fromArtist } = link || {};
    const { typePostFix } = resolveMQ(metadata[origin], ["typePostFix"], activeMQ);

    let rowData = (params && params.collection) || {};
    if (this.state.collectionData) {
      rowData = this.state.collectionData;
    }
    if (this.oldDataCount && (!data || !data.length)) {
      this.onClose();
      return null;
    }
    if (data && data.length) {
      this.oldDataCount = data.length;
    }
    const headerProps = {
      rowData,
      origin,
      fromArtist,
      collectionItems: data,
      onClose: this.onClose
    };
    if (origin === "album" || origin === "places" || origin === "vaultAlbum" || origin === "deviceFolder") {
      return (
        <AlbumDetail
          {...this.props}
          {...headerProps}
          origin={origin}
          renderHeader={props => {
            return <DetailCollectionHeader {...props} theme={headerStyle} />;
          }}
        />
      );
    } else {
      let tableProps = {
        componentType: "table",
        component: getComponent("table"),
        data,
        fetchMore,
        dataIds,
        hasNext,
        lastDoc,
        schema: schema,
        extraData: this.state.collectionData,
        componentProps: {
          selectable: true,
          showHeaderMD: true,
          renderHeaderMD: (data = []) => {
            let newRowData = this.state.collectionData || rowData;
            let itemCount = data.length || newRowData.itemCount;
            return (
              <Box
                getSeparator={({ separator, textStyle }) => {
                  return <Box textStyle={textStyle} viewStyle={{ paddingLeft: 5, paddingRight: 5 }} text={separator} />;
                }}
                render={[
                  {
                    direction: "row",
                    viewStyle: { paddingLeft: 30, paddingRight: 30, paddingTop: 28, paddingBottom: 10 },
                    textStyle: { ...h16_l, color: primaryColor, numberOfLines: 1 },
                    separator: "|",
                    render: [
                      { text: `${numberFormatter(itemCount, "0.0i")} ${I18N.t("items")}` },
                      !newRowData.system &&
                        origin !== "playlist" && {
                          width: "1fr",
                          text: `${(fromShare && sharedBy) || getModeText(newRowData)} ${(typePostFix &&
                            newRowData.owner &&
                            typePostFix()) ||
                            ""}`
                        }
                    ]
                  }
                ]}
              />
            );
          },
          rowProps,
          listProps
        }
      };
      const Table = getJsonComponent("jsonTable");
      return (
        <View style={{ ...containerStyle }}>
          {pending && <LoadingIndicator />}
          <DetailCollectionHeader {...this.props} {...headerProps} theme={headerStyle} />
          <View style={{ ...bodyStyle }}>
            <Table {...tableProps} />
          </View>
        </View>
      );
    }
  }
}

DetailCollection = withContext(DetailCollection, {
  user: "User.user",
  selectedIds: "SelectionStore.selectedIds",
  selectionMode: "SelectionStore.selectionMode",
  getSelectedData: "SelectionStore.getSelectedData",
  isAllSelected: "SelectionStore.isAllSelected",
  activeMQ: "ActiveMQ",
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri"
});

export default DetailCollection;
