import React from "react";
import { Box, I18N, ScrollView } from "../../../FsCloudComponent";

class HelpView extends React.Component {
  render() {
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box text={"This is help screen"} />
      </ScrollView>
    );
  }
}

export default {
  Help: {
    component: "custom",
    title: () => I18N.t("help"),
    Component: HelpView
  }
};
