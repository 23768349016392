export default ({ mergeQueryOld }) => {
  // Used to do some operations after fetching the data.
  const afterFetch = props => {
    let {
      fetchMore,
      uri,
      state,
      afterState,
      result,
      props: { afterFetch: pAfterFetch, user },
      setConnectValue,
      getConnectValue,
      discardOldResult
    } = props;
    let { result: fetchResult = [], aggregates, hasNext, lastDoc, dataIds } = result || {};
    if (hasNext === undefined) {
      let limit = uri.props.query.limit;
      if (limit !== undefined && limit !== -1) {
        if (uri.props.query.pagination && fetchMore) {
          hasNext = fetchResult.length > 0;
        } else {
          hasNext = fetchResult.length >= limit;
        }
      }
    }
    let data = fetchMore ? [...state.data, ...fetchResult] : fetchResult;
    if (!dataIds) {
      dataIds = fetchResult.map(doc => ({ _id: doc._id }));
    }
    let oldDataIds = fetchMore && state.dataIds ? state.dataIds : [];
    let newDataIds = [...oldDataIds, ...dataIds];
    if (lastDoc === undefined && uri.props.query.pagination && fetchResult.length) {
      lastDoc = fetchResult[fetchResult.length - 1];
    }

    let newState = {
      loadingMore: false,
      pending: false,
      hasNext,
      data,
      dataIds: newDataIds,
      aggregates,
      dataLoaded: true,
      lastDoc,
      setConnectValue,
      getConnectValue,
      ...afterState
    };
    if (pAfterFetch) {
      return pAfterFetch(newState, {
        user,
        uri,
        result,
        fetchMore,
        oldData: discardOldResult && !fetchMore ? [] : state.data
      });
    } else {
      return {
        state: newState
      };
    }
  };

  // Used to do some operations before fetching the data.
  const beforeFetch = props => {
    let {
      fetchMore,
      state = {},
      defaultUriProps = {},
      dataParams,
      uri,
      link,
      uriProps,
      props: { beforeFetch: pBeforeFetch } = {}
    } = props;
    if (fetchMore && (state.pending || state.hasNext === false)) {
      return;
    }
    let { limit: defaultLimit } = defaultUriProps;
    let { limit } = uri.props.query || {};
    if (limit === -1) {
      limit = undefined;
    } else if (limit === undefined && defaultLimit) {
      limit = defaultLimit;
    }
    if (fetchMore && limit === undefined) {
      return;
    }

    let lastDoc;
    if (!dataParams) {
      dataParams = state.dataParams;
    }
    if (state.lastDoc) {
      lastDoc = state.lastDoc;
    }
    uri = mergeQueryOld({
      uri,
      link,
      uriProps,
      limit,
      dataParams
    });
    let afterState = {};
    if (dataParams !== undefined) {
      afterState.dataParams = dataParams;
    }
    if (fetchMore) {
      uri = {
        ...uri,
        props: {
          ...uri.props,
          query: {
            ...uri.props.query,
            skip: state.dataIds,
            lastDoc
          }
        }
      };
    }
    if (pBeforeFetch) {
      return pBeforeFetch(state, { uri, afterState, fetchMore });
    }

    return {
      uri,
      state: { pending: true, loadingMore: fetchMore },
      afterState
    };
  };
  return {
    beforeFetch,
    afterFetch
  };
};
