export default () => {
  const firstOnboarding = "/webImages/landing/firstOnboarding.png";
  const secondOnboarding = "/webImages/landing/secondOnboarding.png";
  const thirdOnboarding = "/webImages/landing/thirdOnboarding.png";
  const fourthOnboarding = "/webImages/landing/fourthOnboarding.png";
  const landingBackground = "/webImages/landing/landingBackground.png";
  const appStoreIcon = "/webImages/landing/appStore.png";
  const googlePlayIcon = "/webImages/landing/googlePlay.png";

  return {
    firstOnboarding,
    secondOnboarding,
    thirdOnboarding,
    fourthOnboarding,
    landingBackground,
    appStoreIcon,
    googlePlayIcon
  };
};
