import { DrawerLayout as DrawerLayoutHoc, DrawerMenuView as DrawerMenuViewHoc } from "drawer-layout";
import {
  Nav as NavHoc,
  NavHeader as NavHeaderHoc,
  NavBody as NavBodyHoc,
  NavBodyItem as NavBodyItemHoc,
  NavFooter as NavFooterHoc,
  NavDrawerMenu as NavDrawerMenuHoc,
  NavTitle as NavTitleHoc,
  NavLeft as NavLeftHoc,
  NavBodyContext,
  NavContext
} from "../../nav";

export default ({
  theme = {},
  Box,
  View,
  ScrollView,
  withContext,
  KeyboardAvoidingView,
  Dimensions,
  Platform,
  Modal,
  renderChildren,
  resolveExp,
  dataFormat,
  resolveMQ,
  getUserMenus,
  resolveNavHeaderStyle,
  resolveActions,
  getAction,
  ActiveMQ,
  getAvatarDisplay,
  ScreenContextProvider
}) => {
  const DrawerMenuView = withContext(
    DrawerMenuViewHoc({
      Box,
      resolveMQ,
      ScrollView,
      getAction,
      userMenus: getUserMenus,
      theme: theme.drawerMenuStyle
    }),
    {
      activeMQ: "ActiveMQ",
      setParams: "App.setParams",
      menuIndex: "App.menuIndex",
      getPath: "Router.getPath",
      closeDrawer: "DrawerLayout.closeDrawer",
      user: "User.user"
    }
  );

  const DrawerLayout = withContext(
    DrawerLayoutHoc({
      View,
      resolveMQ,
      drawer: true,
      DrawerView: DrawerMenuView,
      drawerWidthSM: 270,
      drawerWidthMD: 300,
      theme: theme.drawerLayoutStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const NavTitle = withContext(
    NavTitleHoc({
      Box,
      resolveMQ,
      resolveExp,
      dataFormat,
      theme: theme.navTitleStyle
    }),
    {
      activeMQ: "ActiveMQ",
      aggregates: "Screen.Connect.aggregates",
      data: "Screen.Connect.data",
      link: "Screen.Route.link",
      replaceUri: "Router.replaceUri",
      user: "User.user",
      editMode: "Screen.Component.editMode"
    }
  );

  const NavDrawerMenu = withContext(
    NavDrawerMenuHoc({
      Box,
      theme: theme.navMenuStyle
    }),
    {
      openDrawer: "DrawerLayout.openDrawer"
    }
  );

  const NavFooter = withContext(
    NavFooterHoc({
      Box,
      resolveMQ,
      resolveActions,
      ScreenContextProvider,
      theme: theme.navFooterStyle
    }),
    {
      activeMQ: "ActiveMQ",
      menu: "App.menu"
    }
  );

  const NavHeader = withContext(
    NavHeaderHoc({
      Box,
      resolveMQ,
      resolveActions,
      ScreenContextProvider,
      resolveStyle: resolveNavHeaderStyle,
      getScreenProps: Screen => {
        if (Screen) {
          /**
           * SelectionMode and selectedIds need to be get from function but there is issue in state.
           * Selection store has old state in function and when we get data from function, old selected ids and selection mode is received
           * causing one state back in each level - required to show selected no of records in nav header for fs app
           * sachin 1-04-19
           *
           */
          let data = Screen.Connect && Screen.Connect.data;
          let selectionStore = Screen.SelectionStore;
          let selectionMode = selectionStore && selectionStore.selectionMode;
          let selectedIds = selectionStore && selectionStore.selectedIds;
          let isAllSelected = selectionStore && selectionStore.isAllSelected;
          let componentState = Screen.Component;
          let editMode = componentState ? componentState.editMode : void 0;
          return {
            data,
            selectionMode,
            selectedIds,
            editMode,
            isAllSelected
          };
        }
      },
      theme: theme.navHeaderStyle
    }),
    {
      activeMQ: "ActiveMQ",
      user: "User.user"
    }
  );

  const NavBodyItem = withContext(NavBodyItemHoc({ renderChildren }), {
    deleteUri: "Router.deleteUri",
    addBackHandlerListener: "App.addBackHandlerListener",
    removeBackHandlerListener: "App.removeBackHandlerListener"
  });

  const NavBody = withContext(
    NavBodyHoc({
      Box,
      Modal,
      resolveMQ,
      ActiveMQ,
      Platform,
      Dimensions,
      NavBodyContext,
      renderChildren,
      ItemContainer: NavBodyItem,
      ...theme.visibleView,
      theme: theme.navBodyStyle
    }),
    {
      activeMQ: "ActiveMQ",
      screenWidth: "App.screenWidth"
    }
  );

  const Nav = withContext(
    NavHoc({
      KeyboardAvoidingView,
      resolveMQ,
      NavContext,
      ...theme.navStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const NavLeft = withContext(
    NavLeftHoc({
      Box,
      resolveMQ,
      resolveActions,
      ScreenContextProvider,
      theme: theme.navLeftStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  return {
    DrawerMenuView,
    DrawerLayout,
    Nav,
    NavBody,
    NavHeader,
    NavFooter,
    NavTitle,
    NavLeft,
    NavDrawerMenu
  };
};
