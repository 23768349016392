import React from "react";

export default ({ Box, getImage, getAction, theme, I18N, resolveMQ }) => {
  const { colors, fonts } = theme;
  const { highlightColor, themeColor } = colors;
  const { h20, h1 } = fonts;
  const styles = {
    styleLG: {
      containerStyle: {
        viewStyle: {
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 20,
          paddingBottom: 20,
          backgroundColor: themeColor
        }
      },
      loginTextStyle: {
        textStyle: {
          color: highlightColor,
          ...h20
        }
      },
      transparentCrossIconStyle: {
        viewStyle: { cursor: "pointer" },
        image: getImage("loginTransparentCrossIcon")
      },
      whiteCrossIconStyle: {
        viewStyle: { cursor: "pointer" },
        image: getImage("loginCrossIcon")
      }
    },
    styleMD: {
      containerStyle: {
        viewStyle: {
          paddingLeft: 12,
          paddingRight: 18,
          paddingTop: 14,
          paddingBottom: 15,
          backgroundColor: themeColor
        }
      },
      loginTextStyle: {
        textStyle: {
          color: highlightColor,
          ...h1
        }
      },
      transparentCrossIconStyle: {
        viewStyle: {
          cursor: "pointer",
          height: 17,
          width: 17
        },
        image: getImage("loginTransparentCrossIcon"),
        imageStyle: {
          height: 17,
          width: 17,
          objectFit: "scale-down"
        }
      },
      whiteCrossIconStyle: {
        viewStyle: {
          cursor: "pointer",
          height: 17,
          width: 17
        },
        image: getImage("loginCrossIcon"),
        imageStyle: {
          height: 17,
          width: 17,
          objectFit: "scale-down"
        }
      }
    }
  };
  class WebLoginHeader extends React.Component {
    state = {};
    render() {
      const { activeMQ, title = "loginCaps", onClose } = this.props;
      const { style } = resolveMQ(styles, ["style"], activeMQ);
      return (
        <Box
          render={[
            {
              ...style.containerStyle,
              direction: "row",
              render: [
                {
                  ...style.loginTextStyle,
                  width: "1fr",
                  text: I18N.t(title)
                },
                onClose && {
                  ...(this.state.mouseIn ? style.whiteCrossIconStyle : style.transparentCrossIconStyle),
                  onClick: onClose,
                  onMouseEnter: () => {
                    if (!this.state.mouseIn) {
                      this.setState({ mouseIn: true });
                    }
                  },
                  onMouseLeave: () => {
                    if (this.state.mouseIn) {
                      this.setState({ mouseIn: false });
                    }
                  }
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return WebLoginHeader;
};
