import React from "react";
import ListLayoutSelectorHoc from "./ListLayoutSelector";
import {
  CreateCollectionName as CreateCollectionNameHoc,
  CreateCollectionNoData as CreateCollectionNoDataHoc,
  CollectionItemSelector as CollectionItemSelectorHoc,
  AddItemsToCollection as AddItemsToCollectionHoc,
  AddItemsCollectionSelector as AddItemsCollectionSelectorHoc,
  AddItemsCreateCollection as AddItemsCreateCollectionHoc,
  RenameCollection as RenameCollectionHoc,
  AddCollectionNameMobile as AddCollectionNameMobileHoc,
  AlbumCoverSelector as AlbumCoverSelectorHoc
} from "./collection";
import { SavePDFName as SavePDFNameHoc } from "./save-pdf";
import { SaveGIFName as SaveGIFNameHoc } from "./save-gif";
import PopupHeaderHoc from "./PopupHeader";
import {
  InviteMember as InviteMemberHoc,
  AddGroupMember as AddGroupMemberHoc,
  GroupTabsNoData as GroupTabsNoDataHoc
} from "./group";
import { onShare } from "./group/Share";
import ResourceInfoComponentHoc from "./ResourceInfoComponent";
import WebResourceInfoComponentHoc from "./WebResourceInfoComponent";
import WebImageInfoComponentHoc from "./WebImageInfoComponent";
import { CachedImage as CachedImageHoc } from "./cache-image";

import { ImageRow as ImageRowHoc } from "table";
import { MenuItems as MenuItemsHoc } from "./actions";
import MasonryListHoc, { GestureHandler as GestureHandlerHoc, Indicator as ScrollIndicatorHoc } from "masonry-list";
import { shallowCompare, resolveValue } from "app-utility-functions";
import { getSizeFormat } from "client-utility/lib/FileUtility";
import { getTimeStringFromSeconds, getDateRange } from "client-utility/lib/DateUtility";
import NotificationHandlerHoc from "./react-notification";
import DrawerMenuHeaderHoc from "./DrawerMenuHeader";
import WebMasonaryListHoc from "./webMasonaryList";
import VirtualMasonaryListHoc from "./VirtualMasonaryList";
import ImageViewerHoc from "./ImageViewer";
import VideoImageViewerHoc from "./VideoImageViewer";
import RenderNoDataHoc from "./RenderNoData";
import RenderPromptMessageHoc from "./RenderPromptMessage";
import UploadWrapperHoc from "./uploadData/UploadWrapper";
import NetworkListenerHoc from "./NetworkListener";
import NoNetworkToastHoc from "./NoNetworkToast";
import NetworkListenerContext from "./NetworkListenerContext";
import WebAppUploadHoc from "./uploadData/WebAppUpload";
import RecyclerListRowImageHoc from "./RecyclerListRowImage";
import RecyclerListHoc from "./RecyclerList";
import AnimatedRecyclerListHoc from "./AnimatedRecyclerList";
import RecyclerListWithHeaderHoc from "./RecyclerListWithHeader";
import ScrollIndicatorListHoc from "./ScrollIndicatorList";
import RowUpdateListenerWrapperHoc from "./RowUpdateListenerWrapper";
import LanguageSelectorHoc from "./LanguageSelector";
import LanguageDisplayComponentHoc from "./LanguageDisplayComponent";
import DocsImageComponentHoc from "./DocsImageComponent";
import AudioTitleComponentHoc from "./musicPlayer/AudioTitleComponent";
import MusicImageComponentHoc from "./musicPlayer/MusicImageComponent";
import EqualizerComponentHoc from "./musicPlayer/EqualizerComponent";
import ImageEditorHoc from "./ImageEditor";

import MusicPlayerHoc from "./musicPlayer/MusicPlayer";
import MusicStoreHoc from "./musicPlayer/MusicStore";
import PlayMusicActionHoc from "./musicPlayer/PlayMusicAction";
import { MultipleText as MultipleTextHoc, AnchorText as AnchorTextHoc } from "react-components";
import VideoPlayerHoc from "./VideoPlayer";
import googleCastHoc from "./googleCast";
import CastVideoPlayerHoc from "./cast-video-react-player/CastVideoPlayer";
import SlideShowComponentHoc from "./slideshow";
import { fromNowDateFormatterWithI18N } from "./Utility";
import SearchLinkHoc from "./SearchLink";
import SearchInputHoc from "./SearchInput";
import SearchTypeHoc from "./SearchType";
import { DateSelector as DateSelectorHoc, SelectDate as SelectDateHoc } from "./date-box";
import SwitchHoc from "./Switch";
import AnimatedScrollViewHoc from "../AnimatedScrollView";
import DecryptedImageHoc from "./decryptedImage/DecryptedImage";
import DecryptedVideoHoc from "./decryptedVideo/DecryptedVideo";
export default (
  components,
  {
    waitForScanning,
    addTableListener,
    removeTableListener,
    MetadataTable,
    checkFileExistsLocally,
    loadNotificationToken,
    VIDEO_UNSUPPORTED,
    languages,
    ReactNativeDownload,
    isValidEmail,
    uploadErrorCall,
    getUserSpaceData,
    setUserSpaceRef,
    getUser,
    addListRowUpdateListener,
    removeListRowUpdateListener,
    addLog,
    deviceId,
    fetchMoreOnScroll,
    logFirebaseAnalyticsEvent,
    logUploadAnalytics,
    logSearchAnalytics,
    Orientation,
    firebase,
    uploadRequest,
    onPendingBlocker,
    fireLocalDataChangeListener,
    formatLocalDataToResourceData,
    fetchAssetData,
    DeviceSetting,
    checkPhotoPermissions,
    encryptFile,
    cancelEncryptDecryptWeb,
    getLocalPathOfResource,
    getModeText,
    decryptFile,
    cancelDecryption,
    getDecryptionProps,
    getEncryptionKeyAndIV,
    firebaseSave,
    getVideoStreamUrl,
    getAudioStreamUrl,
    getDataStreamingUrl,
    removeStreamToken,
    isNetworkUrl,
    isEncrypted,
    getResourceNameFromUrl,
    invokeUploadAction,
    getCacheFileName,
    onConnectionChange,
    downloadFile,
    isFileExists,
    validateResizeInfo,
    getCachePath,
    updateCachePath,
    updateCacheTime,
    isPublicUrl,
    unsetLocalUris,
    updateCacheErrorEntries,
    onClickNotification,
    saveAsPdfNative,
    FSListItem,
    typeCastDateField,
    getDataOfFile
  }
) => {
  const {
    theme,
    View,
    Modal,
    Box,
    Image,
    resolveMQ,
    getImage,
    getInput,
    List,
    iterator,
    renderChildren,
    withContext,
    getAction,
    resolveVisibleExp,
    Text,
    getImageSize,
    getComponent,
    ScrollView,
    ImageBackground,
    showMessage,
    showError,
    WithModal,
    Platform,
    I18N,
    StatusBar,
    ImageLoader,
    Connect,
    GridTable,
    getJsonComponent,
    mergeContext,
    sounds,
    dataFormat,
    Keyboard,
    getCardComponent,
    ConfirmBox,
    RowAction,
    AsyncStorage
  } = components;

  const CachedImage = withContext(
    CachedImageHoc({
      addLog,
      downloadFile,
      isNetworkUrl,
      validateResizeInfo,
      getCachePath,
      updateCachePath,
      updateCacheErrorEntries
    }),
    {
      online: "NetworkListener.online"
    }
  );

  const DecryptedImage = DecryptedImageHoc({
    Image,
    decryptFile,
    getDecryptionProps,
    cancelDecryption,
    isEncrypted,
    getCacheFileName,
    CachedImage
  });

  const DecryptedVideo = DecryptedVideoHoc({
    getComponent,
    getDecryptionProps,
    getEncryptionKeyAndIV,
    isEncrypted,
    firebaseSave,
    getVideoStreamUrl,
    isNetworkUrl,
    removeStreamToken,
    Platform,
    decryptFile,
    LoadingIndicator: ImageLoader,
    getUser
  });

  const { numberFormatter } = dataFormat;
  const AnchorText = AnchorTextHoc({ Text });

  const AnimatedScrollView = AnimatedScrollViewHoc({ ScrollView });

  const MultipleText = MultipleTextHoc({ Text, AnchorText, Platform, getAction, resolveValue });

  const fromNowDateFormatter = date => {
    return fromNowDateFormatterWithI18N(date, { I18N });
  };

  const shareLink = link => {
    if (Platform.OS !== "web") {
      return onShare(link);
    }
  };

  const NotificationHandler = NotificationHandlerHoc({
    loadNotificationToken,
    onClickNotification,
    Platform
  });

  const googleCast = googleCastHoc({
    Platform,
    getEncryptionKeyAndIV,
    removeStreamToken,
    firebaseSave,
    getDataStreamingUrl,
    getImage,
    getUser,
    showMessage,
    I18N,
    isNetworkUrl
  });

  const CastVideoPlayer = withContext(
    CastVideoPlayerHoc({
      Box,
      View,
      Modal,
      getImage,
      theme,
      I18N,
      googleCast,
      getTimeStringFromSeconds
    }),
    {
      online: "NetworkListener.online"
    }
  );

  const VideoPlayer = withContext(
    VideoPlayerHoc({
      Box,
      View,
      Modal,
      getImage,
      theme,
      I18N,
      getTimeStringFromSeconds
    }),
    {
      online: "NetworkListener.online"
    }
  );

  const VideoImageViewer = withContext(
    VideoImageViewerHoc({
      Box,
      Modal,
      CastVideoPlayer,
      VideoPlayer: DecryptedVideo,
      Platform,
      Orientation,
      I18N,
      ConfirmBox,
      checkFileExistsLocally,
      VIDEO_UNSUPPORTED,
      showMessage,
      googleCast,
      getAudioCodec: ReactNativeDownload.getAudioCodec
    })
  );

  const NoNetworkToast = NoNetworkToastHoc({ Box, I18N, theme });

  const NetworkListener = withContext(NetworkListenerHoc({ View, NoNetworkToast, onConnectionChange }), {
    user: "User.user"
  });

  const ImageViewer = withContext(
    ImageViewerHoc({
      Box,
      View,
      Modal,
      WithModal,
      VideoImageViewer,
      resolveMQ,
      getComponent,
      LoadingIndicator: ImageLoader,
      Image,
      getImage,
      renderChildren,
      NetworkListener,
      theme: theme.imageViewerStyle,
      I18N,
      googleCast,
      DecryptedImage,
      showMessage
    }),
    {
      orientation: "App.orientation",
      activeMQ: "ActiveMQ",
      pending: "Screen.Connect.pending",
      online: "NetworkListener.online"
    }
  );

  const ImageRow = withContext(
    ImageRowHoc({
      Box,
      View,
      Text,
      Modal,
      shallowCompare,
      resolveMQ,
      renderChildren,
      theme: theme.imageRowStyle,
      Platform
    }),
    {
      activeMQ: "ActiveMQ",
      isSelected: "SelectionStore.isSelected"
    }
  );

  const RowUpdateListenerWrapper = RowUpdateListenerWrapperHoc({
    addListRowUpdateListener,
    removeListRowUpdateListener
  });

  const RecyclerListRowImage = RecyclerListRowImageHoc({
    ImageRow,
    Image,
    CachedImage,
    View,
    resolveValue,
    shallowCompare,
    RowUpdateListenerWrapper,
    deviceId,
    isNetworkUrl,
    isPublicUrl,
    getCacheFileName,
    unsetLocalUris
  });

  const RecyclerImageRow = ImageRowHoc({
    Box,
    View,
    Text,
    Modal,
    Platform,
    shallowCompare,
    resolveMQ,
    renderChildren,
    theme: theme.imageRowStyle
  });

  const AnimatedRecyclerListRowImage = RecyclerListRowImageHoc({
    ImageRow: RecyclerImageRow,
    Image,
    CachedImage,
    View,
    resolveValue,
    shallowCompare,
    RowUpdateListenerWrapper,
    deviceId,
    isNetworkUrl,
    isPublicUrl,
    getCacheFileName,
    unsetLocalUris
  });

  const ScrollIndicator = ScrollIndicatorHoc({
    Box,
    theme: theme.scrollIndicatorStyle
  });

  const GestureHandler = GestureHandlerHoc({
    View,
    renderChildren
  });

  const Switch = withContext(
    SwitchHoc({
      themeSM: theme.switchStyleSM,
      themeMD: theme.switchStyleMD,
      resolveMQ
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  let defaultColors = {};
  const MasonryList = withContext(
    MasonryListHoc({
      View,
      List,
      Image,
      getImageSize,
      iterator,
      resolveValue,
      ScrollIndicator,
      renderComponent: props => {
        let { data = {}, imageSource, imageComponent, decryptionSourceProps, isShowVideoIcon, collaboratorData = {} } =
          props || {};

        return (
          <DecryptedImage
            source={imageSource}
            data={data}
            decryptionSourceProps={decryptionSourceProps}
            renderComponent={({ source }) => {
              imageComponent = React.cloneElement(imageComponent, {
                source
              });
              if (isShowVideoIcon) {
                const { _createdBy: { firebaseUid } = {} } = data;
                let [name, initials, color, profile_pic] = collaboratorData[firebaseUid] || [];
                if (!color) {
                  if (!defaultColors[firebaseUid]) {
                    const x = Math.floor(Math.random() * 200);
                    const y = Math.floor(Math.random() * 200);
                    const z = Math.floor(Math.random() * 200);
                    const defaultColor = "rgb(" + x + "," + y + "," + z + ")"; // default value for color (randomly generated)
                    defaultColors[firebaseUid] = defaultColor;
                  }
                  color = defaultColors[firebaseUid];
                }
                if (!initials && name) {
                  initials = name
                    .split(" ")
                    .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
                    .join("");
                }

                return (
                  <Box
                    render={[
                      <ImageRow {...props} imageComponent={imageComponent} />,
                      profile_pic && {
                        viewStyle: {
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                          height: 40,
                          width: 40,
                          borderRadius: 20,
                          zIndex: 1
                        },
                        imageStyle: {
                          width: 56,
                          height: 56,
                          borderRadius: 28,
                          objectFit: "cover"
                        },
                        image: { uri: profile_pic }
                      },
                      !profile_pic &&
                        initials && {
                          viewStyle: {
                            position: "absolute",
                            bottom: 8,
                            right: 8,
                            height: 40,
                            width: 40,
                            backgroundColor: color,
                            borderRadius: 20,
                            zIndex: 1
                          },
                          textStyle: { textAlign: "center", marginTop: 11, color: "white" },
                          text: initials.toUpperCase()
                        },
                      data.type === "video" && {
                        viewStyle: {
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 1,
                          alignItems: "center",
                          justifyContent: "center"
                        },
                        image: getImage("videoIcon"),
                        imageStyle: {
                          height: 15,
                          width: 15
                        },
                        imageProps: {
                          resizeMode: "contain"
                        }
                      }
                    ]}
                  />
                );
              }
              return <ImageRow {...props} imageComponent={imageComponent} />;
            }}
          />
        );
      },
      ContainerComponent: GestureHandler,
      ScrollView
    }),
    {
      getImageUrl: "App.getImageUrl",
      selectRow: "SelectionStore.selectRow",
      isSelectionMode: "SelectionStore.isSelectionMode",
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      getUri: "Screen.Connect.getUri"
    }
  );

  const RecyclerList = withContext(
    RecyclerListHoc({
      Box,
      ContainerComponent: GestureHandler,
      ScrollIndicator,
      fetchMoreOnScroll,
      fromNowDateFormatter,
      RecyclerListRowImage,
      MasonryList,
      resolveValue
    }),
    {
      screenWidth: "App.screenWidth",
      screenHeight: "App.screenHeight",
      selectRow: "SelectionStore.selectRow",
      isSelectionMode: "SelectionStore.isSelectionMode",
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      getUri: "Screen.Connect.getUri",
      link: "ScreenRoute.link"
    }
  );

  const AnimatedRecyclerList = AnimatedRecyclerListHoc({
    Box,
    ContainerComponent: GestureHandler,
    ScrollIndicator,
    fetchMoreOnScroll,
    fromNowDateFormatter,
    RecyclerListRowImage,
    MasonryList,
    resolveValue,
    shallowCompare
  });

  const RecyclerListWithHeader = withContext(
    RecyclerListWithHeaderHoc({
      Box,
      ContainerComponent: GestureHandler,
      ScrollIndicator,
      fetchMoreOnScroll,
      fromNowDateFormatter,
      RecyclerListRowImage,
      MasonryList,
      resolveValue,
      shallowCompare,
      theme,
      getImage,
      I18N
    }),
    {
      dataParams: "Screen.Connect.dataParams"
    }
  );

  const WebMasonaryList = withContext(
    WebMasonaryListHoc({
      ScrollView,
      View,
      Box,
      theme,
      getTimeStringFromSeconds,
      getImage,
      I18N,
      FSListItem,
      DecryptedImage
    }),
    {
      activeMQ: "ActiveMQ",
      dataParams: "Screen.Connect.dataParams",
      isSelected: "SelectionStore.isSelected",
      isSelectionMode: "SelectionStore.isSelectionMode",
      selectRow: "SelectionStore.selectRow",
      selectedIds: "SelectionStore.getSelectedIds()",
      openViews: "NavBody.getOpenViews()",
      selectMultiRow: "SelectionStore.selectMultiRow",
      unSelectMultiRow: "SelectionStore.unSelectMultiRow",
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      getUri: "Screen.Connect.getUri",
      link: "ScreenRoute.link"
    }
  );
  const VirtualMasonaryList = withContext(
    VirtualMasonaryListHoc({
      ScrollView,
      View,
      Box,
      theme,
      getTimeStringFromSeconds,
      getImage,
      I18N,
      FSListItem,
      DecryptedImage,
      typeCastDateField
    }),
    {
      activeMQ: "ActiveMQ",
      dataParams: "Screen.Connect.dataParams",
      isSelected: "SelectionStore.isSelected",
      isSelectionMode: "SelectionStore.isSelectionMode",
      selectRow: "SelectionStore.selectRow",
      selectedIds: "SelectionStore.getSelectedIds()",
      openViews: "NavBody.getOpenViews()",
      selectMultiRow: "SelectionStore.selectMultiRow",
      unSelectMultiRow: "SelectionStore.unSelectMultiRow",
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      getUri: "Screen.Connect.getUri",
      link: "ScreenRoute.link",
      pending: "Screen.Connect.pending"
    }
  );
  const ImageViewerHeaderMenuItem = withContext(
    MenuItemsHoc({
      Box,
      getAction,
      resolveMQ,
      resolveVisibleExp,
      getImage,
      theme: theme.imageViewerHeaderMenuItemsStyle
    }),
    {
      activeMQ: "ActiveMQ",
      isSelectionMode: "SelectionStore.isSelectionMode",
      getSelectedIds: "SelectionStore.getSelectedIds",
      isAllSelected: "SelectionStore.isAllSelected"
    }
  );

  const ListLayoutSelector = withContext(
    ListLayoutSelectorHoc({
      Box,
      ScrollView,
      layoutOptions: {
        1: I18N.t("layoutHuge"),
        2: I18N.t("layoutLarge"),
        3: I18N.t("layoutMedium"),
        4: I18N.t("layoutSmall"),
        5: I18N.t("layoutTiny")
      },
      theme: theme.listLayoutSelectorStyle
    }),
    {
      componentState: "Screen.Component",
      setComponentState: "Screen.setComponentState"
    }
  );
  const PopupHeader = withContext(PopupHeaderHoc({ Box, theme, getImage, resolveMQ }), {
    activeMQ: "ActiveMQ"
  });

  const AddItemsCreateCollection = withContext(
    AddItemsCreateCollectionHoc({
      Box,
      View,
      getImage,
      getInput,
      I18N,
      getAction,
      resolveMQ,
      Header: PopupHeader,
      theme,
      LoadingIndicator: StatusBar,
      showMessage
    }),
    {
      activeMQ: "ActiveMQ",
      getSelectedData: "SelectionStore.getSelectedData",
      invoke: "App.invoke",
      urls: "App.urls"
    }
  );

  const RenameCollection = withContext(
    RenameCollectionHoc({
      Box,
      View,
      getInput,
      I18N,
      resolveMQ,
      Header: PopupHeader,
      theme,
      LoadingIndicator: StatusBar,
      showMessage
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "Screen.Connect.invoke",
      urls: "App.urls",
      unSelectAll: "SelectionStore.unSelectAll",
      getSelectedData: "SelectionStore.getSelectedData"
    }
  );

  const SavePDFName = withContext(
    SavePDFNameHoc({
      Box,
      View,
      getInput,
      I18N,
      resolveMQ,
      Header: PopupHeader,
      theme,
      LoadingIndicator: StatusBar,
      showMessage,
      saveAsPdfNative,
      getImage,
      Platform
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "Screen.Connect.invoke",
      urls: "App.urls",
      unSelectAll: "SelectionStore.unSelectAll",
      getSelectedData: "SelectionStore.getSelectedData"
    }
  );

  const SaveGIFName = withContext(
    SaveGIFNameHoc({
      Box,
      View,
      getInput,
      I18N,
      resolveMQ,
      Header: PopupHeader,
      theme,
      LoadingIndicator: StatusBar,
      showMessage,
      getImage,
      Platform
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "Screen.Connect.invoke",
      urls: "App.urls",
      unSelectAll: "SelectionStore.unSelectAll",
      getSelectedData: "SelectionStore.getSelectedData"
    }
  );

  const CreateCollectionNoData = withContext(
    CreateCollectionNoDataHoc({ Box, getImage, I18N, resolveMQ, Header: PopupHeader, theme }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const AddItemsCollectionSelector = withContext(
    AddItemsCollectionSelectorHoc({
      Box,
      View,
      getImage,
      Connect,
      GridTable,
      I18N,
      NameComponent: AddItemsCreateCollection,
      Header: PopupHeader,
      theme,
      resolveMQ,
      LoadingIndicator: StatusBar,
      numberFormatter,
      showMessage,
      Platform,
      getModeText,
      DecryptedImage
    }),
    {
      activeMQ: "ActiveMQ",
      selectedIds: "SelectionStore.selectedIds",
      getSelectedData: "SelectionStore.getSelectedData"
    }
  );

  const AddItemsToCollection = withContext(
    AddItemsToCollectionHoc({
      Connect,
      NameComponent: AddItemsCreateCollection,
      CollectionSelector: AddItemsCollectionSelector
    }),
    {
      activeMQ: "ActiveMQ",
      selectedIds: "SelectionStore.selectedIds",
      unSelectAll: "SelectionStore.unSelectAll",
      invoke: "App.invoke",
      urls: "App.urls"
    }
  );

  const CollectionItemSelector = withContext(
    CollectionItemSelectorHoc({
      Box,
      resolveMQ,
      theme,
      View,
      Header: PopupHeader,
      I18N,
      RowAction,
      getImage,
      getComponent,
      getJsonComponent,
      LoadingIndicator: StatusBar,
      RenderNoData: CreateCollectionNoData,
      Connect,
      Platform,
      showMessage
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "App.invoke",
      urls: "App.urls",
      user: "User.user"
    }
  );

  const AlbumCoverSelector = withContext(
    AlbumCoverSelectorHoc({
      Box,
      resolveMQ,
      theme,
      View,
      Header: PopupHeader,
      I18N,
      LoadingIndicator: StatusBar,
      Connect,
      getComponent,
      getJsonComponent,
      showMessage
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "App.invoke",
      urls: "App.urls"
    }
  );

  const AddCollectionNameMobile = withContext(
    AddCollectionNameMobileHoc({
      Box,
      View,
      getInput,
      getImage,
      getAction,
      I18N,
      LoadingIndicator: StatusBar,
      resolveMQ,
      theme,
      showMessage
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "Screen.Connect.invoke",
      urls: "App.urls",
      deleteUri: "Router.deleteUri",
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      getParentInfo: "Router.getParentInfo",
      link: "ScreenRoute.link",
      unSelectAll: "SelectionStore.unSelectAll",
      getSelectedData: "SelectionStore.getSelectedData"
    }
  );

  const CreateCollectionName = withContext(
    CreateCollectionNameHoc({
      Box,
      View,
      getImage,
      getInput,
      I18N,
      getAction,
      resolveMQ,
      Header: PopupHeader,
      LoadingIndicator: StatusBar,
      ItemSelector: CollectionItemSelector,
      theme
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "App.invoke",
      urls: "App.urls"
    }
  );

  const ResourceInfoComponent = ResourceInfoComponentHoc({
    Box,
    ScrollView,
    getSizeFormat,
    getTimeStringFromSeconds,
    theme,
    I18N
  });

  const WebResourceInfoComponent = WebResourceInfoComponentHoc({
    Box,
    theme,
    getImage,
    getSizeFormat,
    getTimeStringFromSeconds,
    I18N
  });

  const WebImageInfoComponent = WebImageInfoComponentHoc({ Box, theme, getImage, getSizeFormat, I18N });

  const NavLogo = props => {
    let { style } = props;
    style = {
      ...style
    };
    return (
      <Box
        style={style}
        direction={"row"}
        Container={getAction("link")}
        containerProps={{
          action: {
            link: {
              uri: "/home#gallery",
              nestedTab: "galleryAll",
              forceReload: new Date().getTime()
            },
            replace: true
          }
        }}
        image={getImage("headerLogo")}
      />
    );
  };

  const RenderNoData = withContext(
    RenderNoDataHoc({
      Box,
      getImage,
      resolveMQ,
      theme,
      ScrollViewSM: AnimatedScrollView,
      ScrollViewMD: null
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const RenderPromptMessage = withContext(
    RenderPromptMessageHoc({
      Modal,
      I18N,
      ConfirmBox,
      AsyncStorage,
      resolveMQ
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const GroupTabsNoData = withContext(
    GroupTabsNoDataHoc({
      Box,
      getImage,
      resolveMQ,
      theme
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const WebAppUpload = WebAppUploadHoc({
    Box,
    getImage,
    renderChildren,
    theme,
    I18N,
    Platform
  });

  const UploadWrapper = withContext(
    UploadWrapperHoc({
      Box,
      View,
      renderChildren,
      getImage,
      Modal,
      resolveMQ,
      theme,
      getInput,
      showMessage,
      showError,
      getSizeFormat,
      I18N,
      waitForScanning,
      uploadErrorCall,
      shallowCompare,
      logUploadAnalytics,
      encryptFile,
      cancelEncryptDecryptWeb
    }),
    {
      upload: "App.upload",
      uploadRequest: "App.uploadRequest",
      activeMQ: "ActiveMQ",
      getPath: "Router.getPath",
      addUri: "Router.addUri",
      user: "User.user",
      language: "App.pstate.language",
      orientation: "App.orientation",
      screenWidth: "App.screenWidth"
    }
  );

  //odt
  const DocsImageComponent = withContext(
    DocsImageComponentHoc({
      Box,
      theme,
      resolveValue,
      getImage,
      View,
      ImageBackground,
      Image,
      resolveMQ,
      Platform,
      DecryptedImage,
      getComponent: getCardComponent,
      selectableSM: true,
      selectableMD: false,
      imageMappings: {
        avi: "aviIcon",
        css: "cssIcon",
        csv: "csvIcon",
        cdr: "cdrIcon",
        dll: "dllIcon",
        dmg: "dmgIcon",
        doc: "docIcon",
        docx: "docxIcon",
        eps: "epsIcon",
        gif: "gifIcon",
        html: "htmlIcon",
        htm: "htmlIcon",
        js: "jsIcon",
        jpg: "jpgIcon",
        mov: "movIcon",
        mp3: "mp3Icon",
        mpg: "mpgIcon",
        ods: "odsIcon",
        odp: "odpIcon",
        odt: "odtIcon",
        ppt: "pptIcon",
        pptx: "pptIcon",
        pdf: "pdfIcon",
        png: "pngIcon",
        php: "phpIcon",
        psd: "psdIcon",
        raw: "rawIcon",
        svg: "svgIcon",
        txt: "txtIcon",
        wmv: "wmvIcon",
        xml: "xmlIcon",
        xlsx: "xlsxIcon",
        xls: "xlsIcon",
        zip: "zipIcon"
      }
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const SDocsImageComponent = DocsImageComponentHoc({
    Box,
    theme,
    resolveValue,
    getImage,
    View,
    ImageBackground,
    Image,
    resolveMQ,
    getComponent: getCardComponent,
    selectableSM: true,
    selectableMD: false,
    DecryptedImage,
    Platform,
    imageMappings: {
      avi: "aviIcon",
      css: "cssIcon",
      csv: "csvIcon",
      cdr: "cdrIcon",
      dll: "dllIcon",
      dmg: "dmgIcon",
      doc: "docIcon",
      docx: "docxIcon",
      eps: "epsIcon",
      gif: "gifIcon",
      html: "htmlIcon",
      htm: "htmlIcon",
      js: "jsIcon",
      jpg: "jpgIcon",
      mov: "movIcon",
      mp3: "mp3Icon",
      mpg: "mpgIcon",
      ods: "odsIcon",
      odp: "odpIcon",
      odt: "odtIcon",
      ppt: "pptIcon",
      pptx: "pptIcon",
      pdf: "pdfIcon",
      png: "pngIcon",
      php: "phpIcon",
      psd: "psdIcon",
      raw: "rawIcon",
      svg: "svgIcon",
      txt: "txtIcon",
      wmv: "wmvIcon",
      xml: "xmlIcon",
      xlsx: "xlsxIcon",
      xls: "xlsIcon",
      zip: "zipIcon"
    }
  });

  const LanguageDisplayComponent = withContext(
    LanguageDisplayComponentHoc({
      Box,
      getImage,
      theme,
      languages
    }),
    {
      getLanguage: "App.getLanguage"
    }
  );

  const LanguageSelector = withContext(
    LanguageSelectorHoc({
      Box,
      WithModal,
      getImage,
      theme,
      resolveMQ,
      languages,
      DisplayComponent: LanguageDisplayComponent
    }),
    {
      activeMQ: "ActiveMQ",
      setLanguage: "App.setLanguage",
      getLanguage: "App.getLanguage"
    }
  );

  const ScrollIndicatorList = withContext(
    ScrollIndicatorListHoc({
      Box,
      List,
      ScrollIndicator,
      fromNowDateFormatter,
      renderChildren,
      Platform,
      resolveMQ
    }),
    {
      activeMQ: "ActiveMQ",
      getPath: "Router.getPath",
      addUri: "Router.addUri",
      getUri: "Screen.Connect.getUri",
      link: "ScreenRoute.link"
    }
  );

  const DrawerMenuHeader = withContext(
    DrawerMenuHeaderHoc({
      Box,
      resolveMQ,
      getSizeFormat,
      View,
      getImage,
      getAction,
      I18N,
      theme: theme.drawerMenuStyle,
      getUserSpaceData,
      setUserSpaceRef
    }),
    {
      activeMQ: "ActiveMQ",
      user: "User.user",
      invoke: "App.invoke",
      urls: "App.urls"
    }
  );

  const EqualizerComponent = withContext(
    EqualizerComponentHoc({
      View,
      theme,
      resolveMQ,
      LoadingIndicator: StatusBar
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const AudioTitleComponent = withContext(
    AudioTitleComponentHoc({
      Box,
      getTimeStringFromSeconds,
      theme,
      resolveValue,
      resolveMQ,
      EqualizerComponent
    }),
    {
      activeMQ: "ActiveMQ",
      addChangeSongListener: "MusicPlayer.addChangeSongListener",
      removeChangeSongListener: "MusicPlayer.removeChangeSongListener",
      addPlayerStateListener: "MusicPlayer.addPlayerStateListener",
      removePlayerStateListener: "MusicPlayer.removePlayerStateListener",
      isPlaying: "MusicPlayer.isPlaying",
      getPlayerState: "MusicPlayer.getPlayerState"
    }
  );

  const MusicImageComponent = withContext(
    MusicImageComponentHoc({
      Box,
      getImage,
      EqualizerComponent
    }),
    {
      addChangeSongListener: "MusicPlayer.addChangeSongListener",
      removeChangeSongListener: "MusicPlayer.removeChangeSongListener",
      addPlayerStateListener: "MusicPlayer.addPlayerStateListener",
      removePlayerStateListener: "MusicPlayer.removePlayerStateListener",
      isPlaying: "MusicPlayer.isPlaying",
      getPlayerState: "MusicPlayer.getPlayerState"
    }
  );

  const MusicPlayer = withContext(
    MusicPlayerHoc({
      Box,
      View,
      renderChildren,
      getImage,
      theme,
      resolveMQ,
      I18N,
      EqualizerComponent,
      ConfirmBox,
      Modal,
      checkFileExistsLocally,
      Platform
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "App.invoke",
      user: "User.user"
    }
  );

  const MusicStore = withContext(
    MusicStoreHoc({
      Box,
      View,
      renderChildren,
      resolveMQ,
      I18N,
      LoadingIndicator: StatusBar,
      addTableListener,
      removeTableListener,
      MetadataTable,
      MusicPlayer,
      showMessage,
      getEncryptionKeyAndIV,
      Platform,
      firebaseSave,
      getAudioStreamUrl,
      removeStreamToken,
      getUser,
      isMusicPlayerVisibleSM: props => {
        let { getPath } = props;
        let path = getPath && getPath();
        if (path) {
          let indexOf = -1;
          if (path.indexOf("/music-detail") >= 0) {
            indexOf = path.indexOf("/music-detail");
          } else if (path.indexOf("/playlist-music-detail") >= 0) {
            indexOf = path.indexOf("/playlist-music-detail");
          } else if (path.indexOf("/artist-music-detail") >= 0) {
            indexOf = path.indexOf("/artist-music-detail");
          } else if (path.indexOf("/search-music-detail") >= 0) {
            indexOf = path.indexOf("/search-music-detail");
          } else if (path.indexOf("/archive-music-detail") >= 0) {
            indexOf = path.indexOf("/archive-music-detail");
          }
          if (indexOf >= 0 && path.substring(indexOf + 1).indexOf("/") === -1) {
            return true;
          }
        }
      },
      isMusicPlayerVisibleMD: props => {
        let { getPath } = props;
        let path = getPath && getPath();
        if (path) {
          if (path.indexOf("/vault") >= 0) {
            return false;
          }
          let indexOfMusicDetail = -1;
          if (path.indexOf("/music-detail") >= 0) {
            indexOfMusicDetail = path.indexOf("/music-detail");
          } else if (path.indexOf("/playlist-music-detail") >= 0) {
            indexOfMusicDetail = path.indexOf("/playlist-music-detail");
          } else if (path.indexOf("/artist-music-detail") >= 0) {
            indexOfMusicDetail = path.indexOf("/artist-music-detail");
          } else if (path.indexOf("/search-music-detail") >= 0) {
            indexOfMusicDetail = path.indexOf("/search-music-detail");
          } else if (path.indexOf("/archive-music-detail") >= 0) {
            indexOfMusicDetail = path.indexOf("/archive-music-detail");
          }
          if (indexOfMusicDetail >= 0 || path.indexOf("/home#music") >= 0) {
            return true;
          }
          let indexOf = path.indexOf("/home");
          if (indexOf >= 0 && path.substring(indexOf + 1).indexOf("/") === -1) {
            return true;
          }
        }
      }
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "App.invoke",
      getPath: "Router.getPath"
    }
  );

  const PlayMusicAction = withContext(
    PlayMusicActionHoc({
      Box,
      getImage,
      getAction
    }),
    {
      allData: "Screen.Connect.data",
      getSelectedData: "SelectionStore.getSelectedData",
      unSelectAll: "SelectionStore.unSelectAll",
      onPlayMusic: "MusicPlayer.onPlayMusic",
      addUri: "Router.addUri",
      getPath: "Router.getPath"
    }
  );

  mergeContext({
    NetworkListener: NetworkListenerContext
  });

  const AddGroupMember = withContext(
    AddGroupMemberHoc({
      Box,
      View,
      theme,
      I18N,
      getInput,
      getComponent,
      Header: PopupHeader,
      showMessage,
      LoadingIndicator: StatusBar,
      isValidEmail,
      resolveMQ,
      logFirebaseAnalyticsEvent
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "App.invoke",
      urls: "App.urls",
      user: "User.user",
      replaceUri: "Router.replaceUri"
    }
  );

  const InviteMember = withContext(
    InviteMemberHoc({
      Box,
      View,
      ScrollView,
      theme,
      getImage,
      I18N,
      Platform,
      LoadingIndicator: StatusBar,
      showMessage,
      logFirebaseAnalyticsEvent
    }),
    {
      invoke: "App.invoke",
      urls: "App.urls",
      user: "User.user",
      updateUserInfo: "User.updateUserInfo",
      replaceUri: "Router.replaceUri"
    }
  );

  const SlideShowComponent = withContext(
    SlideShowComponentHoc({
      View,
      Image,
      getImage,
      Box,
      sounds,
      theme,
      Connect,
      StatusBar,
      getInput,
      I18N,
      Platform,
      decryptFile,
      cancelDecryption,
      isNetworkUrl,
      isEncrypted,
      downloadFile,
      fireLocalDataChangeListener,
      logFirebaseAnalyticsEvent,
      getCachePath,
      getCacheFileName,
      getDecryptionProps,
      isFileExists,
      updateCacheTime
    }),
    {
      deleteUri: "Router.deleteUri",
      getPath: "Router.getPath",
      invoke: "App.invoke",
      urls: "App.urls",
      replaceUri: "Router.replaceUri",
      link: "ScreenRoute.link"
    }
  );

  const SearchLink = withContext(
    SearchLinkHoc({
      Box,
      getImage,
      getAction,
      resolveMQ,
      theme,
      I18N
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const SearchInput = withContext(
    SearchInputHoc({
      Box,
      getImage,
      getAction,
      resolveMQ,
      theme,
      getInput,
      View,
      I18N,
      autoFocusSM: false,
      autoFocusMD: true
    }),
    {
      activeMQ: "ActiveMQ",
      setParams: "Screen.Connect.setParams",
      value: "Screen.Connect.dataParams.ftsString",
      resetParams: "Screen.Connect.resetParams",
      link: "ScreenRoute.link",
      getConnectState: "Screen.Connect.getState",
      setConnectState: "Screen.Connect.setState",
      incFetchCount: "Screen.Connect.incFetchCount"
    }
  );

  const DateSelector = withContext(
    DateSelectorHoc({
      Box,
      Modal,
      resolveMQ,
      SelectDate: SelectDateHoc({
        Box,
        View,
        Text,
        getDisplayText: (type, index) => {
          if (type === "month") {
            return I18N.t(`month_${index}`);
          } else if (type === "week") {
            let Weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            let week = I18N.t(Weeks[index]);
            return week && week.substring(0, 1);
          }
        },
        theme: theme.dateCalendarStyle.calendarStyle
      })
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const SearchType = withContext(
    SearchTypeHoc({
      Box,
      getImage,
      I18N,
      theme,
      getAction,
      resolveMQ,
      RenderNoData,
      ScrollView,
      Keyboard,
      getDateRange,
      DateSelector,
      Platform,
      getInput,
      showMessage,
      logSearchAnalytics
    }),
    {
      activeMQ: "ActiveMQ",
      searchText: "Screen.Connect.dataParams.ftsString",
      link: "ScreenRoute.link",
      addUri: "Router.addUri",
      getPath: "Router.getPath"
    }
  );

  const ImageEditor = ImageEditorHoc({
    Box,
    Modal,
    ConfirmBox,
    checkPhotoPermissions,
    DeviceSetting,
    Platform,
    getImage,
    theme,
    showMessage,
    I18N,
    ReactNativeDownload,
    firebase,
    uploadRequest,
    onPendingBlocker,
    fireLocalDataChangeListener,
    formatLocalDataToResourceData,
    fetchAssetData,
    checkFileExistsLocally,
    isNetworkUrl,
    getResourceNameFromUrl,
    getCachePath,
    isFileExists,
    downloadFile,
    updateCacheTime,
    invokeUploadAction,
    getLocalPathOfResource
  });

  return {
    ImageEditor,
    ScrollIndicatorList,
    RecyclerList,
    NotificationHandler,
    ImageRow,
    MasonryList,
    ImageViewerHeaderMenuItem,
    ListLayoutSelector,
    AddItemsToCollection,
    RenameCollection,
    AddCollectionNameMobile,
    CreateCollectionName,
    ResourceInfoComponent,
    WebResourceInfoComponent,
    WebImageInfoComponent,
    NavLogo,
    RenderNoData,
    RenderPromptMessage,
    GroupTabsNoData,
    WebMasonaryList,
    VirtualMasonaryList,
    WebAppUpload,
    UploadWrapper,
    NetworkListener,
    DocsImageComponent,
    LanguageSelector,
    DrawerMenuHeader,
    AudioTitleComponent,
    MusicImageComponent,
    EqualizerComponent,
    MusicStore,
    PlayMusicAction,
    VideoPlayer,
    ImageViewer,
    googleCast,
    CollectionItemSelector,
    PopupHeader,
    InviteMember,
    AlbumCoverSelector,
    SlideShowComponent,
    AddGroupMember,
    MultipleText,
    shareLink,
    SearchLink,
    SearchInput,
    SearchType,
    Switch,
    SDocsImageComponent,
    AnimatedRecyclerList,
    fromNowDateFormatter,
    getSizeFormat,
    AnimatedRecyclerListRowImage,
    RowUpdateListenerWrapper,
    DecryptedImage,
    DecryptedVideo,
    RecyclerListWithHeader,
    SavePDFName,
    SaveGIFName
  };
};
