import React from "react";
import { I18N, theme, Box, withContext, StatusBar, getImage, FSList } from "../../../FsCloudComponent";
import FileViewerComponent from "./FileViewerComponent";
import { dateModifier } from "./issuesUtility";

const { fonts, colors, shadows } = theme;
const { highlightColor } = colors;
const { h16_l, h1_16, h9 } = fonts;
let { cardShadow } = shadows;

class IssuesList extends React.Component {
  state = {
    loading: false
  };

  onClick = data => {
    let { addUri, getPath } = this.props;
    addUri &&
      addUri(getPath(), {
        uri: "/comments",
        props: { filter: { _id: data._id } }
      });
  };
  statusComponent = ({ status }) => {
    let bgColor = "white";
    bgColor = status === "in_progress" ? "#99cb6b" : status === "done" ? "#99cb6b" : "#f04b2c";
    let txt = status === "in_progress" ? "in_progress" : status === "done" ? "resolved" : "open";
    return (
      <Box
        viewStyle={{
          alignItems: "center",
          opacity: 0.8,
          justifyContent: "center",
          backgroundColor: bgColor,
          padding: 5,
          borderRadius: 3
        }}
        render={[{ textStyle: { ...h9, color: "#ffffff" }, text: () => I18N.t(txt) }]}
      />
    );
  };
  dateComponent = ({ createdOn }) => {
    if (createdOn) {
      let data = dateModifier && dateModifier({ date: createdOn });
      let { date, time } = data || {};
      let dateText = `${date} - ${time}`;
      return <Box textStyle={{ color: "#a7a7a7", ...h9 }} text={dateText} />;
    }
  };

  renderItem = ({ item, index }) => {
    return (
      <Box
        key={item._id}
        viewStyle={{
          marginLeft: 12,
          marginRight: 12,
          marginTop: 6,
          marginBottom: 6,
          backgroundColor: highlightColor,
          padding: 12,
          cursor: "pointer",
          ...cardShadow
        }}
        onClick={() => {
          this.onClick(item);
        }}
        render={[
          {
            viewStyle: { paddingBottom: 5 },
            textStyle: {
              color: "#5c5c5c",
              ...h1_16,
              userSelect: "text"
            },
            text: item.title
          },
          {
            textStyle: {
              color: "#5c5c5c",
              ...h16_l,
              userSelect: "text"
            },
            text: item.description
          },
          {
            direction: "row",
            viewStyle: {
              paddingTop: 5,
              paddingBottom: 5,
              alignItems: "center"
            },
            render: [
              {
                viewStyle: { flex: 1 },
                render: [
                  item.count && {
                    textStyle: { color: "#a7a7a7", ...h9 },
                    text: `${I18N.t("totalComment")} : ${item.count}`
                  },
                  this.dateComponent({
                    createdOn: item._createdOn
                  })
                ]
              },
              {
                viewStyle: { marginLeft: 5 },
                render: this.statusComponent({ status: item.status })
              }
            ]
          },
          item.attachments &&
            item.attachments.length && {
              direction: "row",
              viewStyle: {
                paddingTop: 5,
                paddingBottom: 5
              },
              render: [
                {
                  image: getImage("linkGreyIcon")
                },
                { width: "1fr", render: <FileViewerComponent data={item} /> }
              ]
            }
        ]}
      />
    );
  };
  getSelectedIds = () => {
    return [];
  };
  render() {
    let { data = [] } = this.props;
    let { loading } = this.state;
    return [
      <FSList
        style={{
          paddingTop: 10,
          paddingBottom: 12,
          backgroundColor: "#fafafa",
          flex: 1
        }}
        data={data}
        renderItem={this.renderItem}
        getSelectedIds={this.getSelectedIds}
      />,
      loading ? <StatusBar /> : null
    ];
  }
}

export default IssuesList = withContext(IssuesList, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  addUri: "Router.addUri",
  replaceUri: "Router.replaceUri",
  getPath: "Router.getPath",
  user: "User.user"
});
