import React from "react";
import {
  Box,
  theme,
  ScrollView,
  StatusBar as LoadingIndicator,
  I18N,
  withContext,
  showMessage,
  Editor,
  getImage,
  loginUrl,
  isValidEmail
} from "../../../FsCloudComponent";
import ProfileImage from "./ProfileImage";

const { colors, fonts } = theme;
const { brownGrey, primaryColor, themeColor, tomato } = colors;
const { h16, h1, h2 } = fonts;

const editorTheme = {
  editorContainerStyle: {
    height: 36,
    width: 224,
    justifyContent: "center",
    paddingLeft: 16,
    paddingRight: 16,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: brownGrey,
    marginRight: 34
  },
  activeEditorContainerStyle: {
    borderColor: themeColor
  },
  errorEditorContainerStyle: {
    borderColor: tomato
  },
  errorStyle: {
    viewStyle: {
      paddingTop: 5
    },
    textStyle: {
      color: tomato,
      ...h2,
      numberOfLines: 1
    }
  }
};

class MyProfileComponent extends React.Component {
  state = {};

  onRemovePhoto = async () => {
    try {
      this.setState({ loading: true });
      let { invoke, urls } = this.props;
      await invoke({
        service: {
          url: urls["removeProfilePic"]
        }
      });
      this.setState({ loading: false });
      this.updateData({ field: "_updatedOn", value: new Date() });
      showMessage && showMessage(I18N.t("photoRemovedMessage"), 2000);
    } catch (err) {
      showMessage && showMessage(err.message, 2000);
      this.setState({
        loading: false
      });
    }
  };

  getColumnEditor = (column, { editable = true, maxLength } = {}) => {
    let { field } = column;
    let { data, setValue, updatableData, validationData, editMode } = this.props;
    if (!editMode) {
      editable = false;
    }

    return {
      direction: "row",
      render: [
        {
          width: 112,
          height: 36,
          viewStyle: {
            justifyContent: "center"
          },
          textStyle: {
            ...h1,
            color: primaryColor
          },
          text: I18N.t(`${field}Label`)
        },
        <Editor
          data={data}
          setValue={setValue}
          updatableData={updatableData}
          validationData={validationData}
          column={{
            type: "text",
            ...column,
            containerProps: {
              theme: editorTheme,
              inputProps: {
                style: { ...h1, color: editable ? primaryColor : brownGrey, textOverflow: "ellipsis" },
                editable,
                maxLength
              }
            }
          }}
        />
      ]
    };
  };

  updateData = ({ field, value }) => {
    let { editMode, data = {}, setValue } = this.props;
    if (editMode && setValue && field && value) {
      setValue({ data, field, value });
    }
  };

  render() {
    let { editMode, user, data, setValue } = this.props;
    let { profile_pic } = user;
    return (
      <ScrollView style={{ flex: 1 }}>
        {this.state.loading && <LoadingIndicator />}
        <Box
          viewStyle={{ paddingLeft: 110, paddingRight: 110 }}
          render={[
            {
              viewStyle: {
                alignItems: "flex-start"
              },
              render: (
                <ProfileImage
                  onUploadStart={() => {
                    this.setState({ loading: true });
                    this.updateData({ field: "_updatedOn", value: new Date() });
                  }}
                  onUploadEnd={() => this.setState({ loading: false })}
                  onUploadError={() => this.setState({ loading: false })}
                  radius={62}
                  icon={"editProfileIcon"}
                  skipUpdate={!editMode}
                />
              )
            },
            editMode &&
              profile_pic && {
                viewStyle: {
                  paddingTop: 12,
                  paddingLeft: 17,
                  paddingRight: 17,
                  cursor: "pointer",
                  textDecoration: "underline",
                  alignSelf: "flex-start"
                },
                textStyle: { ...h1, color: themeColor },
                text: I18N.t("removePhoto"),
                onClick: this.onRemovePhoto
              },
            {
              viewStyle: {
                paddingLeft: 6
              },
              render: [
                {
                  viewStyle: {
                    paddingTop: 16,
                    paddingBottom: 16
                  },
                  textStyle: {
                    ...h16,
                    color: themeColor
                  },
                  text: I18N.t("personalInformation")
                },
                {
                  direction: "row",
                  viewStyle: {
                    paddingTop: 8,
                    paddingBottom: 8
                  },
                  render: [
                    this.getColumnEditor(
                      {
                        field: "firstName",
                        placeholder: I18N.t("firstName"),
                        validation: data => {
                          let { firstName } = data;
                          if (firstName) {
                            firstName = firstName.trim();
                          }
                          if (!firstName) {
                            return I18N.t("nameMandatoryError");
                          }
                        }
                      },
                      {
                        maxLength: 25
                      }
                    ),
                    this.getColumnEditor(
                      {
                        field: "lastName",
                        placeholder: I18N.t("lastName")
                      },
                      {
                        maxLength: 25
                      }
                    )
                  ]
                },
                {
                  viewStyle: {
                    paddingTop: 26,
                    paddingBottom: 16
                  },
                  textStyle: {
                    ...h16,
                    color: themeColor
                  },
                  text: I18N.t("contactInformation")
                },
                {
                  direction: "row",
                  viewStyle: {
                    paddingTop: 8,
                    paddingBottom: 8
                  },
                  render: [
                    this.getColumnEditor(
                      {
                        field: "email",
                        placeholder: I18N.t("email"),
                        validation: data => {
                          let { email } = data;
                          if (email) {
                            email = email.trim();
                          }
                          if (!email) {
                            return I18N.t("emailError");
                          }
                          if (!isValidEmail(email)) {
                            return I18N.t("emailValidationError");
                          }
                        }
                      },
                      {
                        maxLength: 55
                      }
                    )
                    // this.getColumnEditor(
                    //   {
                    //     field: "mobile"
                    //   },
                    //   { editable: false, maxLength: 16 }
                    // )
                  ]
                },
                {
                  direction: "row",
                  viewStyle: { paddingTop: 24, alignItems: "center" },
                  render: [
                    {
                      onClick: () => {
                        editMode &&
                          setValue &&
                          setValue({
                            data,
                            field: "activityEmailEnabled",
                            value: !data.activityEmailEnabled
                          });
                      },
                      image: data && data.activityEmailEnabled ? getImage("checkedIcon") : getImage("uncheckedIcon")
                    },
                    {
                      viewStyle: {
                        paddingLeft: 16
                      },
                      textStyle: {
                        ...h1,
                        color: brownGrey
                      },
                      text: I18N.t("receiveEmailInAccountConfirmationMessage")
                    }
                  ]
                }
              ]
            }
          ]}
        />
      </ScrollView>
    );
  }
}

MyProfileComponent = withContext(MyProfileComponent, {
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user",
  editMode: "Screen.Component.editMode"
});

export default {
  ProfileMD: {
    component: "custom",
    Component: MyProfileComponent,
    connectProps: ({ user }) => {
      let data = {
        name: user.name,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.mobile,
        activityEmailEnabled: user.activityEmailEnabled
      };
      return {
        data,
        updatable: true
      };
    },
    headerActions: [
      {
        type: "headerAction",
        visible: ({ editMode }) => editMode,
        action: {
          type: "updateProfile",
          image: "saveIcon",
          title: () => I18N.t("save"),
          updateEditMode: true
        }
      },
      {
        type: "headerAction",
        visible: ({ editMode }) => !editMode,
        action: {
          type: "edit",
          image: "editIcon",
          title: () => I18N.t("edit")
        }
      },
      {
        type: "headerAction",
        visible: ({ editMode }) => !editMode,
        action: {
          type: "logout",
          image: "headerLogoutIcon",
          title: () => I18N.t("logout"),
          loginUrl: loginUrl
        }
      }
    ]
  }
};
