import React from "react";
export default ({ Component, View, StatusBar, iterator, validateValue, inputProps, showMessage, I18N }) => {
  class FileInput extends React.Component {
    state = {};
    componentDidMount() {
      window.addEventListener(
        "dragover",
        function(e) {
          e.preventDefault();
        },
        false
      );
      window.addEventListener(
        "drop",
        function(e) {
          e.preventDefault();
        },
        false
      );
    }

    componentWillUnmount() {
      window.removeEventListener(
        "dragover",
        function(e) {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        function(e) {
          e.preventDefault();
        },
        false
      );
    }

    onBlur = e => {
      let { inputProps: { onBlur } = {} } = this.props;
      onBlur && onBlur(e);
    };

    onFocus = e => {
      let { inputProps: { onFocus } = {} } = this.props;
      onFocus && onFocus(e);
    };

    onChangeValue = e => {
      e.preventDefault();
      let {
        multiple,
        onUploadFile,
        onChangeValue,
        upload,
        uploadOptions,
        setValue,
        maxAttachmentsLimit,
        data: { attachments = [] } = {}
      } = this.props;
      let files = e.dataTransfer ? e.dataTransfer.files : e.target && e.target.files;
      if (maxAttachmentsLimit && files.length + attachments.length > maxAttachmentsLimit) {
        let maxLimitExceededMessage = attachments.length
          ? I18N.t("attachmentLimitExceedMessage")
          : I18N.t("share/attachments-length");
        maxLimitExceededMessage = maxLimitExceededMessage.replace("__attachmentLimit__", maxAttachmentsLimit);
        showMessage && showMessage(maxLimitExceededMessage, 2000);
        return;
      }
      let filesKeys = files ? Object.keys(files) : [];
      if (!filesKeys.length) {
        return;
      }
      let validateMessage = validateValue && validateValue(files);
      if (validateMessage) {
        showMessage(validateMessage, 3000);
        return;
      }
      this.setState({ uploading: true });
      setValue && setValue({ field: "uploading", value: true });
      if (!multiple) {
        if (onUploadFile) {
          onUploadFile(files[filesKeys[0]], this.props);
          this.setState({ uploading: false });
          setValue && setValue({ field: "uploading", value: false });
        } else {
          upload(files[filesKeys[0]], uploadOptions).then(result => {
            if (result) {
              onChangeValue && onChangeValue(result);
            }
            this.setState({ uploading: false });
            setValue && setValue({ field: "uploading", value: false });
          });
        }
      } else {
        let multiResult = [];
        iterator(filesKeys, (index, fileKey) => {
          return upload(files[fileKey], uploadOptions).then(result => {
            if (result) {
              multiResult.push(result);
            }
          });
        }).then(_ => {
          onChangeValue && onChangeValue(multiResult);
          this.setState({ uploading: false });
          setValue && setValue({ field: "uploading", value: false });
        });
      }
      this.onBlur();
    };

    onClick = e => {
      this.inputRef.click();
      this.onFocus(e);
    };

    render() {
      return (
        <View>
          {this.state.uploading && StatusBar && <StatusBar key="file_upload_status" />}
          <View
            draggable={true}
            onDragOver={e => {
              this.onFocus(e);
              this.setState({ dragOver: true });
            }}
            onDrop={e => {
              this.onChangeValue(e);
              this.setState({ dragOver: false });
            }}
            onDragLeave={e => {
              this.onBlur(e);
              this.setState({ dragOver: false });
            }}
            onClick={this.onClick}
          >
            <Component
              {...this.props}
              inputProps={{
                ...inputProps,
                ...this.props.inputProps
              }}
              getRef={_ => (this.inputRef = _)}
              onChangeValue={this.onChangeValue}
            />
          </View>
        </View>
      );
    }
  }
  return FileInput;
};
