import React from "react";

export default ({ getImage, Box, theme, I18N }) => {
  const { colors, fonts } = theme;
  const { highlightColor } = colors;
  const { h1 } = fonts;

  const detectMob = () => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      return true;
    } else {
      return false;
    }
  };

  class ControlBarComponent extends React.Component {
    render() {
      let {
        videoEnd,
        pauseVideo,
        onMouseEnter,
        onMouseLeave,
        onPlayClick,
        onBackwardClick,
        onPrevClick,
        onNextClick
      } = this.props;
      return (
        <Box
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          viewStyle={{
            height: 72,
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
            alignItems: "center",
            padding: 11,
            left: 0,
            right: 0,
            bottom: 0,
            elevation: 5,
            zIndex: 20
          }}
          direction={"row"}
          render={[
            videoEnd || pauseVideo
              ? { viewStyle: { flex: 1 } }
              : {
                  viewStyle: {
                    flex: 1,
                    justifyContent: "flex-end"
                  },
                  direction: "row",
                  itemStyle: {
                    marginLeft: 4,
                    marginRight: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    height: 32,
                    justifyContent: "center",
                    cursor: "pointer"
                  },
                  render: [
                    {
                      onClick: onBackwardClick,
                      image: getImage("montageBackwardIcon")
                    },
                    {
                      onClick: () => onPrevClick(false),
                      image: getImage("montagePrevWhiteIcon")
                    }
                  ]
                },
            {
              viewStyle: { marginLeft: 12, marginRight: 12, cursor: "pointer" },
              onClick: onPlayClick,
              image: getImage(videoEnd || pauseVideo ? "montagePlayIcon" : "montagePauseIcon")
            },
            videoEnd || pauseVideo
              ? { viewStyle: { flex: 1 } }
              : {
                  viewStyle: { flex: 1 },
                  itemStyle: {
                    marginLeft: 4,
                    marginRight: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    height: 32,
                    justifyContent: "center",
                    cursor: "pointer"
                  },
                  direction: "row",
                  render: [
                    { onClick: () => onNextClick(true), image: getImage("montageNextWhiteIcon") }
                    // {
                    //   onClick: () => onVolumeClick(),
                    //   image: volumeMute ? getImage("montageVolumnOffIcon") : getImage("montageVolumnUpIcon")
                    // }
                  ]
                }
          ]}
        />
      );
    }
  }

  class TopHeaderComponent extends React.Component {
    render() {
      let { onCrossClick } = this.props;
      return (
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            padding: 15,
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            elevation: 5,
            zIndex: 20
          }}
          render={[
            {
              viewStyle: {
                flex: 1,
                padding: 6,
                alignSelf: "flex-end",
                cursor: "pointer"
              },
              onClick: onCrossClick,
              image: getImage("crossWhiteIcon")
            }
          ]}
        />
      );
    }
  }

  class LinkComponent extends React.Component {
    render() {
      let { linkUrl, linkCopied, copyLinkUrl, getShareUrl } = this.props;
      return (
        <Box
          render={[
            linkUrl && {
              viewStyle: {
                alignItems: "center",
                paddingTop: 15,
                paddingBottom: 18
              },
              textStyle: {
                color: highlightColor,
                ...h1
              },
              text: I18N.t("createdPublicLink")
            },
            linkCopied
              ? {
                  viewStyle: {
                    alignItems: "center",
                    paddingTop: 15,
                    paddingBottom: 18
                  },
                  direction: "row",
                  render: [
                    {
                      image: getImage("montageLinkCopiedIcon"),
                      viewStyle: { paddingRight: 8 }
                    },
                    {
                      textStyle: {
                        color: highlightColor,
                        ...h1
                      },
                      text: I18N.t("linkCopied")
                    }
                  ]
                }
              : {
                  viewStyle: {
                    marginTop: linkUrl ? 0 : 50,
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 160,
                    borderWidth: 2,
                    borderRadius: 4,
                    borderColor: highlightColor,
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  },
                  direction: "row",
                  onClick: linkUrl ? copyLinkUrl : getShareUrl,
                  render: [
                    {
                      viewStyle: {
                        paddingRight: 12
                      },
                      image: getImage(linkUrl ? "montageCopyLinkIcon" : "montageGetLinkIcon")
                    },
                    {
                      textStyle: {
                        color: highlightColor,
                        ...h1
                      },
                      text: I18N.t(linkUrl ? "copyLink" : "createLink")
                    }
                  ]
                }
          ]}
        />
      );
    }
  }

  return {
    detectMob,
    TopHeaderComponent,
    ControlBarComponent,
    LinkComponent
  };
};
