import React from "react";
export default ({ resolveMQ, screenLoader, routes, ErrorScreen }) => {
  const getRoute = ({ link, state, user, activeMQ, route: routeInfo, onRouteError }) => {
    let { uri } = link;
    let hash;
    let uriToCheck = uri;
    let indexOfSearch = uri && uri.indexOf("?");
    if (indexOfSearch >= 0) {
      uriToCheck = uri.substring(0, indexOfSearch);
    }
    let indexOfHash = uri && uri.indexOf("#");
    if (indexOfHash >= 0) {
      hash = uri.substring(indexOfHash + 1);
      uriToCheck = uri.substring(0, indexOfHash);
    }
    const route = routes[uriToCheck];

    if (!route) {
      let error = new Error(`Route not found for uri [${uri}]`);
      if (onRouteError) {
        onRouteError({ error });
        return;
      } else {
        return {
          component: <ErrorScreen error={error} />,
          screenState: state
        };
      }
    }

    const { props: routeScreenProps, hash: routeHash } = route;
    const { screen } = resolveMQ(route, ["screen"], activeMQ);
    let hashMap = routeHash && routeHash({ hash });
    let screenLoaderComponent = screenLoader({
      state,
      screen,
      routeScreenProps,
      link,
      user,
      hashMap,
      activeMQ,
      route: routeInfo
    });
    if (!screenLoaderComponent || !screenLoaderComponent.component) {
      return {
        component: (
          <ErrorScreen
            error={
              new Error(
                `component must be returned by screenLoader.But none returned for route [${uri}]. and screen defiend is [${screen}]`
              )
            }
          />
        )
      };
    }

    return {
      ...screenLoaderComponent
    };
  };
  return {
    getRoute
  };
};
