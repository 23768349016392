import React from "react";
import { I18N ,getImage} from "../../../FsCloudComponent";
import IssuesList from "./UserIssues";
import { getIssuesQuery } from "../queries";
import { IssuesNoData } from "../noData/NoData";

export default {
  UserIssues: {
    component: "table",
    title: () =>I18N.t("issuesReported"),
    dataObject: "Issues",
    Component: IssuesList,
    ...getIssuesQuery,
    renderNoData: <IssuesNoData  />,
    headerActions: [
      {
        type: "headerAction",
        action: {
          type: "link",
          image:getImage("createGreyIcon"),
          link: {
            uri: "/add-issue"
          }
        }
      }
    ]
  }
};
