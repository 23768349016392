import React from "react";
import {
  Box,
  ScrollView,
  theme,
  I18N,
  StatusBar as LoadingIndicator,
  Editor,
  LoginErrorComponent as ErrorComponent,
  isValidEmail
} from "../../../FsCloudComponent";
import ProfileImage from "./ProfileImage";
import keyboardType from "../../../keyboardType";

const { fonts, colors } = theme;
const { h2_16, h3, h1 } = fonts;
const { brownGrey, primaryColor, paleGrey, tomato, themeColor } = colors;

const editorTheme = {
  containerStyle: {
    viewStyle: {
      paddingBottom: 8
    }
  },
  headerContainerStyle: {
    viewStyle: {
      paddingTop: 8,
      paddingBottom: 7
    },
    textStyle: {
      ...h2_16,
      color: brownGrey
    }
  },
  activeHeaderContainerStyle: {
    viewStyle: {
      paddingTop: 8,
      paddingBottom: 7
    },
    textStyle: {
      ...h2_16,
      color: themeColor
    }
  },
  editorContainerStyle: {
    paddingBottom: 8,
    borderColor: paleGrey,
    borderBottomWidth: 1,
    borderBottomStyle: "solid"
  },
  errorStyle: {
    viewStyle: {
      paddingTop: 5
    },
    textStyle: {
      color: tomato,
      ...h3
    }
  }
};

class ProfileComponent extends React.Component {
  state = {};
  getColumnEditor = (column, otherProps = {}) => {
    let { data, setValue, updatableData, validationData } = this.props;
    let { editable } = otherProps;
    return (
      <Editor
        data={data}
        setValue={setValue}
        updatableData={updatableData}
        validationData={validationData}
        column={{
          type: "text",
          ...column,
          containerProps: {
            theme: editorTheme,
            inputProps: {
              style: { ...h1, color: editable ? primaryColor : brownGrey },
              ...otherProps
            },
            ErrorComponent
          }
        }}
      />
    );
  };

  render() {
    return (
      <ScrollView style={{ flex: 1 }}>
        {this.state.loading && <LoadingIndicator />}
        <Box
          viewStyle={{
            paddingLeft: 16,
            paddingRight: 16
          }}
          render={[
            {
              viewStyle: { alignItems: "center", paddingTop: 21 },
              render: (
                <ProfileImage
                  onUploadStart={() => this.setState({ loading: true })}
                  onUploadEnd={() => this.setState({ loading: false })}
                  onUploadError={() => this.setState({ loading: false })}
                  icon={"editProfileIcon"}
                />
              )
            },
            {
              direction: "row",
              viewStyle: { paddingTop: 18 },
              render: [
                {
                  width: "1fr",
                  render: this.getColumnEditor(
                    {
                      header: I18N.t("firstName"),
                      field: "firstName",
                      placeholder: I18N.t("enterFirstName"),
                      validation: data => {
                        let { firstName } = data;
                        if (firstName) {
                          firstName = firstName.trim();
                        }
                        if (!firstName) {
                          return I18N.t("nameMandatoryError");
                        }
                      }
                    },
                    {
                      maxLength: 25
                    }
                  )
                },
                {
                  width: "1fr",
                  viewStyle: {
                    paddingLeft: 26
                  },
                  render: this.getColumnEditor(
                    {
                      header: I18N.t("lastName"),
                      field: "lastName",
                      placeholder: I18N.t("enterLastName")
                    },
                    {
                      maxLength: 25
                    }
                  )
                }
              ]
            },
            this.getColumnEditor(
              {
                header: I18N.t("userName"),
                field: "name",
                placeholder: I18N.t("enteruserName")
              },
              {
                editable: false,
                maxLength: 50
              }
            ),
            // this.getColumnEditor(
            //   {
            //     header: I18N.t("phoneNumber"),
            //     field: "mobile"
            //     placeholder: I18N.t("enterPhoneNumber")
            //   },
            //   {
            //     editable: false,
            //     maxLength: 16
            //   }
            // ),
            this.getColumnEditor(
              {
                header: I18N.t("emailAddress"),
                field: "email",
                placeholder: I18N.t("enterEmailId"),
                validation: data => {
                  let { email } = data;
                  if (email) {
                    email = email.trim();
                  }
                  if (!email) {
                    return I18N.t("emailError");
                  }
                  if (!isValidEmail(email)) {
                    return I18N.t("emailValidationError");
                  }
                }
              },
              {
                ...keyboardType.email,
                maxLength: 55
              }
            )
          ]}
        />
      </ScrollView>
    );
  }
}

export default {
  ProfileSM: {
    component: "custom",
    Component: ProfileComponent,
    connectProps: ({ user }) => {
      let data = {
        name: user.name,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.mobile
      };
      return {
        data,
        updatable: true
      };
    },
    dataObject: "MyProfile",
    headerActions: [
      {
        type: "headerAction",
        action: {
          type: "updateProfile",
          text: () => I18N.t("saveCaps")
        }
      }
    ]
  }
};
