import React from "react";

export default ({ Box, resolveActions, resolveMQ, theme = {} }) => ({
  title,
  actions,
  activeMQ,
  index,
  visibleView,
  isExpanded,
  onLayout,
  data,
  selectedIds,
  selectionMode,
  link,
  user
}) => {
  let resolvedActions =
    resolveActions &&
    resolveActions({
      actions,
      activeMQ,
      index,
      visibleView,
      title,
      isExpanded,
      data,
      selectedIds,
      selectionMode,
      link,
      user
    });

  if (!resolvedActions || !resolvedActions.length) {
    return null;
  }
  const { boxStyle } = resolveMQ(theme, ["boxStyle"], activeMQ);
  return <Box {...boxStyle} onLayout={onLayout} render={[...resolvedActions]} />;
};
