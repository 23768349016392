/***
 * TODO review addUri, deleteUri, replaceUri for persistState
 * in case of replace uri - need to clear state of other uris
 *
 */

import React from "react";

export default ({ Router, Platform, firebase }) => {
  class StateRouter extends React.Component {
    state = {};
    parentInfo = {};

    componentDidMount() {
      if (Platform.OS !== "web") {
        const redirect = url => {
          if (url) {
            let uriIndex = url.indexOf("/be-part");
            if (uriIndex === -1) {
              uriIndex = url.indexOf("/join-group");
            }
            if (uriIndex >= 0) {
              const uri = url.substring(uriIndex);
              this.replaceUri({ uri });
            }
          }
        };
        firebase
          .links()
          .getInitialLink()
          .then(url => {
            redirect(url);
          });
        firebase.links().onLink(url => {
          redirect(url);
        });
      }
      if (this.props.setRouterContext) {
        this.props.setRouterContext({ replaceUri: this.replaceUri, deleteUri: this.deleteUri, addUri: this.addUri });
      }
    }

    withoutHashUrl = url => {
      const splitted = url.split("/");
      let withHashUrl = ``;
      splitted.forEach(uri => {
        if (uri.trim().length === 0) {
          return;
        }
        uri = "/" + uri;
        let indexOfHash = uri.indexOf("#");
        if (indexOfHash > 0) {
          uri = uri.substring(0, indexOfHash);
        }
        withHashUrl = `${withHashUrl}${uri}`;
      });
      return withHashUrl;
    };

    UNSAFE_componentWillMount() {
      const { getUrl, landingUrl, user, activeMQ } = this.props;
      let path = getUrl();
      path = path && path.trim();
      const link = landingUrl({ path, user, activeMQ });
      if (link && link.persist) {
        this.replaceUri(link);
      } else {
        const { uri } = link;
        this.props.setUrl({ url: uri });
      }
    }

    removeInvalidState = (keysToCheck, key, state) => {
      let newState = {};
      if (keysToCheck && keysToCheck.length) {
        keysToCheck.forEach(keyToCheck => {
          if (key.indexOf(keyToCheck) !== 0) {
            if (state) {
              delete state[keyToCheck];
            } else {
              newState[keyToCheck] = void 0;
            }
          }
        });
      }
      return state || newState;
    };

    updateAddUri = (url, link, extraProps) => {
      const { uri } = link;
      url = url + uri;
      if (Platform.OS === "web") {
        let setUrl = extraProps && extraProps.setUrl;
        this.props.setUrl({ url, link, ...setUrl });
      }
      return url;
    };

    removeSelectionStoreStateFromTab = state => {
      state = { ...state, Tab: { ...state["Tab"] } };
      let tabState = state["Tab"];
      for (let tabKey in tabState) {
        tabState[tabKey] = { ...tabState[tabKey] };
        tabState[tabKey]["Tab"] = { ...tabState[tabKey]["Tab"] };
        let nestedTabState = tabState[tabKey]["Tab"];
        for (let nestedTabKey in nestedTabState) {
          nestedTabState[nestedTabKey] = { ...nestedTabState[nestedTabKey], SelectionStore: {} };
        }
      }
      return state;
    };

    addUri = (url, link, extraProps) => {
      let { setState } = this.props;
      const { uri } = link;
      let key = this.withoutHashUrl(`${url}${uri}`);

      const linkCState = link && link.props && link.props.cstate;
      const linkParentInfo = link && link.props && link.props.parentInfo;
      if (linkParentInfo) {
        this.parentInfo[key] = linkParentInfo;
      }
      link = {
        parentUrl: url,
        ...link,
        props: {
          ...link.props
        }
      };

      let _user = void 0;
      if (link._user) {
        _user = link._user;
        delete link._user;
      }

      delete link.props.cstate;
      delete link.props.parentInfo;
      let locationUrl = this.updateAddUri(url, link, extraProps);
      setState(state => {
        const { pstate, ...restState } = state;
        this.removeInvalidState(Object.keys(pstate), key, pstate);
        pstate[key] = pstate[key] || { cstate: {} };
        pstate[key]["link"] = link;

        let finalState = this.removeInvalidState(Object.keys(restState), key);
        let cstate = state[key] && state[key].cstate; //case when we click on another row of list, prev state get empty, it should not be
        if (link && link.nestedTab) {
          cstate = cstate && cstate.Tab && this.removeSelectionStoreStateFromTab(cstate);
        }

        finalState.pstate = pstate;
        finalState[key] = { cstate: { pstate: pstate[key].cstate, ...cstate, ...linkCState } };
        finalState._location = locationUrl;
        if (_user) {
          finalState._user = _user;
        }
        return finalState;
      });
    };

    updateDeleteUri = (url, link) => {
      const { uri } = link;
      const indexOfURI = url.lastIndexOf(uri);
      url = url.substring(0, indexOfURI);

      if (Platform.OS === "web") {
        let toRemove = 0;
        if (indexOfURI > 0) {
          let splits = uri.split("/");
          splits.forEach(p => {
            if (p.trim().length > 0) {
              toRemove++;
            }
          });
        }

        this.props.setUrl({ url, remove: -1 * toRemove });
      }
      return url;
    };

    deleteUri = (url, link) => {
      let { setState } = this.props;
      let _user = void 0;
      if (link && link._user) {
        link = {
          ...link
        };
        _user = link._user;
        delete link._user;
      }
      let key = this.withoutHashUrl(url);
      if (this.parentInfo[key]) {
        delete this.parentInfo[key];
      }
      let locationUrl = this.updateDeleteUri(url, link);
      this.props.setIgnoreHistoryChange && this.props.setIgnoreHistoryChange({ _user });
      if (Platform.OS !== "web") {
        setState(state => {
          const pstate = state.pstate;
          delete pstate[key];
          let finalState = { [key]: void 0, _location: locationUrl };
          if (_user) {
            finalState._user = _user;
          }
          return finalState;
        });
      }
    };

    replaceUri = (link, extraProps = {}) => {
      let url = (link && link.parentUrl) || "";

      let setUrl = extraProps.setUrl || { replace: true };
      extraProps = { ...extraProps, setUrl };
      this.addUri(url, link, extraProps);
    };

    getParentInfo = link => {
      if (!link) {
        return;
      }
      const { parentUrl = "", uri } = link;
      let key = this.withoutHashUrl(`${parentUrl}${uri}`);
      return this.parentInfo[key];
    };

    setLinkState = ({ url }) => {
      return _state => {
        let { setState } = this.props;
        setState(state => {
          let key = this.withoutHashUrl(url);
          let urlState = state[key];
          if (!urlState) {
            // case when url is removed from state
            return {};
          }
          let cstate = urlState.cstate;
          return {
            [key]: {
              ...urlState,
              cstate: { ...cstate, ...(typeof _state === "function" ? _state(cstate) : _state) }
            }
          };
        });
      };
    };

    uris = path => {
      const splitted = path.split("/");
      let uris = [];
      let url = ``;
      splitted.forEach(uri => {
        if (uri.trim().length === 0) {
          return;
        }
        uri = "/" + uri;
        url = `${url}${uri}`;
        uris.push({ url, uri });
      });
      return uris;
    };

    render() {
      let { state, getUrl, BlockerStatusBar, getPendingBlockerRef } = this.props;
      const pstate = state.pstate;
      const path = getUrl && getUrl();
      let uris = this.uris(path);
      uris =
        uris &&
        uris.map(_uri => {
          const { url, uri } = _uri;
          let key = this.withoutHashUrl(url);
          pstate[key] = pstate[key] || { cstate: {} };
          state[key] = state[key] || {};
          let urlPState = pstate[key];
          let urlState = state[key];

          urlPState["link"] = urlPState["link"] || { uri };
          urlState["cstate"] = urlState["cstate"] || {
            uri,
            url,
            pstate: urlPState.cstate
          };
          urlState["cstate"].uri = uri;
          urlState["cstate"].url = url;

          let link = { ...urlPState["link"], uri };
          return {
            url,
            link,
            state: urlState["cstate"],
            setState: this.setLinkState({ url })
          };
        });

      return [
        <Router
          {...this.props}
          key="__router"
          addUri={this.addUri}
          deleteUri={this.deleteUri}
          replaceUri={this.replaceUri}
          getParentInfo={this.getParentInfo}
          uris={uris}
          path={path}
        />,
        <BlockerStatusBar key="blockerStatusBar" ref={getPendingBlockerRef} />
      ];
    }
  }
  return StateRouter;
};
