import React from "react";

export default ({ Box, View, getComponent, getJsonComponent, Header, LoadingIndicator, theme, I18N, showMessage }) => {
  const { fonts, colors, bgs } = theme;
  const { h16_l, h9 } = fonts;
  const { themeBg } = bgs;
  const { highlightColor, tomato } = colors;

  class AlbumCoverSelector extends React.Component {
    state = {};

    onSubmit = async () => {
      let { getSelectedData } = this.state.SelectionStore;
      let selectedData = getSelectedData && getSelectedData();
      if (!selectedData || !selectedData.length) {
        this.setState({ error: I18N.t("coverUpdateSelectErrorMessage") });
        return;
      }
      const { data: albumData, onClose, invoke, urls } = this.props;
      try {
        this.setState({ loading: true });
        await invoke({
          service: {
            url: urls["updateAlbumCover"],
            uriProps: {
              data: {
                _id: albumData._id,
                item: {
                  _id: selectedData[0]._id
                }
              }
            }
          }
        });
        const successMessage = I18N.t("coverUpdatedSuccessMessage");
        showMessage && showMessage(successMessage, 2000);
        onClose ? onClose() : this.setState({ loading: false });
      } catch (err) {
        let errorCode = err.code;
        let errorMessage = I18N && errorCode && typeof errorCode === "string" && I18N.t(errorCode);
        if (!errorMessage || (errorMessage.indexOf("missing") !== -1 && errorMessage.indexOf("translation") !== -1)) {
          errorMessage = err.message;
        }
        this.setState({ error: errorMessage, loading: false });
      }
    };

    render() {
      const { action, onClose, data: { collaboratorData } = {} } = this.props;
      let { connectProps, defaultConnectState } = action;
      if (defaultConnectState) {
        defaultConnectState = {
          ...defaultConnectState,
          dataParams: {
            ...defaultConnectState.dataParams
          }
        };
      }
      if (connectProps && typeof connectProps === "function") {
        connectProps = connectProps(this.props);
      }
      const ConnectPanelTable = getJsonComponent("connectPanelTable");
      return (
        <ConnectPanelTable
          screenName="albumCoverSelector"
          defaultConnectState={defaultConnectState}
          connectProps={connectProps}
          state={this.state}
          setState={state => this.setState(state)}
          componentProps={{ selectionMode: true }}
          renderComponent={connectResult => {
            let { data, pending, loadingMore } = connectResult;
            const WebMasonryList = getComponent && getComponent("webMasonaryList");
            let { selectRow } = this.state.SelectionStore;
            return (
              <View style={{ width: 823, height: 465 }}>
                {((pending && !loadingMore) || this.state.loading) && <LoadingIndicator />}
                <Header title={I18N.t("setCoverCaps")} onClose={onClose} />
                {data && data.length > 0 && WebMasonryList && (
                  <Box
                    viewStyle={{ flex: 1 }}
                    render={[
                      {
                        viewStyle: { flex: 1, paddingTop: 24, paddingLeft: 12, paddingRight: 12 },
                        render: (
                          <WebMasonryList
                            data={data}
                            scrollViewStyle={{ paddingLeft: 18, paddingRight: 18 }}
                            selectRow={(image, singleSelection) => {
                              selectRow && selectRow(image, singleSelection);
                              if (this.state.error) {
                                this.setState({
                                  error: void 0
                                });
                              }
                            }}
                            decryptionSourceProps={{ decryptionSource: "collection" }}
                            singleSelection
                            rowData={{ collaboratorData }}
                          />
                        )
                      },
                      {
                        viewStyle: {
                          paddingTop: 14,
                          paddingBottom: 14,
                          paddingRight: 30,
                          paddingLeft: 30,
                          alignItems: "center"
                        },
                        direction: "row",
                        render: [
                          {
                            viewStyle: {
                              flex: 1,
                              paddingRight: 12
                            },
                            textStyle: {
                              ...h9,
                              color: tomato,
                              numberOfLines: 2
                            },
                            text: this.state.error || ""
                          },
                          {
                            viewStyle: {
                              paddingTop: 14,
                              paddingBottom: 14,
                              paddingLeft: 13,
                              paddingRight: 13,
                              backgroundColor: themeBg,
                              cursor: "pointer",
                              borderRadius: 4
                            },
                            textStyle: {
                              ...h16_l,
                              color: highlightColor
                            },
                            text: I18N.t("setCoverImage"),
                            onClick: this.onSubmit
                          }
                        ]
                      }
                    ]}
                  />
                )}
              </View>
            );
          }}
        />
      );
    }
  }
  return AlbumCoverSelector;
};
