import { getDownloadingFiles } from "./../../../Sqlite";
import {
  Platform,
  getAllUploadedData,
  getUploadedFileMap,
  mergeTwoSortedArrays,
  formatLocalDataToResourceData,
  getSortValue,
  _updateCacheFromLocalChanges,
  isMetadataPopulatedForCurrentSession
} from "../../../FsCloudComponent";
import { SECURE_TYPES } from "../../../Constants";

export const isResourceUploading = (data = {}) => {
  return !data.uploaded;
};

export const isResourceDownloading = (data = {}) => {
  return data.downloading === true;
};

// only for gallery items
export const uploadResourceActionVisibility = ({ selectedData }) => {
  if (!selectedData || !Array.isArray(selectedData) || !selectedData.length) {
    return false;
  }
  let visibility = true;
  for (let i = 0; i < selectedData.length; i++) {
    if (isResourceUploading(selectedData[i])) {
      visibility = false;
      break;
    }
  }
  return visibility;
};

export const getSelectedData = ({ selectedIds = [], data = [] }) => {
  let selectedIdsMap = {};
  selectedIds.forEach(selectedId => {
    selectedIdsMap[selectedId] = 1;
  });
  return data.filter(row => selectedIdsMap[row._id]);
};

export const isDownloadActionVisible = (data = [], { selectedIds = [] }) => {
  if (Platform.OS === "web") {
    return selectedIds.length;
  } else if (Platform.OS !== "ios") {
    let selectedData = getSelectedData({ data, selectedIds });
    return uploadResourceActionVisibility({ selectedData });
  }
};

export const makePrivateActionVisibility = ({ selectedIds = [], data = [] } = {}) => {
  if (!data.length || !selectedIds.length) {
    return false;
  }
  let selectedIdsMap = {};
  selectedIds.forEach(selectedId => {
    selectedIdsMap[selectedId] = 1;
  });
  for (let index = 0; index < data.length; index++) {
    let doc = data[index];
    if (selectedIdsMap[doc._id] && doc["private"]) {
      return false;
    }
  }
  return true;
};

export const ensureAndGetDownloadingFilesMap = async state => {
  if (Platform.OS === "web") {
    return;
  }
  let { getConnectValue, setConnectValue } = state;
  let downloadingFilesMap = getConnectValue && getConnectValue("downloadingFilesMap");
  if (downloadingFilesMap !== undefined) {
    return downloadingFilesMap;
  }
  downloadingFilesMap = {};
  try {
    let sqliteData = await getDownloadingFiles();
    sqliteData &&
      sqliteData.forEach(sqlItem => {
        if (sqlItem.resource) {
          downloadingFilesMap[sqlItem.resource] = { downloading: true };
        }
      });
  } catch (err) {}
  setConnectValue && setConnectValue("downloadingFilesMap", downloadingFilesMap);
  return downloadingFilesMap;
};

const getMergedData = async (result = [], props = {}, { source } = {}) => {
  let { fetchMore, uri, oldData = [] } = props;
  let { props: { query: { sort, localChangeKey, mergeLocalWithSecureType } = {} } = {} } = uri || {};
  let newData = mergeTwoSortedArrays(oldData, result, sort);
  if (mergeLocalWithSecureType && !fetchMore) {
    let allUploadedData = await getAllUploadedData();
    let uploadingFiles = [];
    for (let file of allUploadedData) {
      let { status, fileType, secureType = SECURE_TYPES.DEFAULT } = file;
      if (status !== "success" && fileType === source && secureType === mergeLocalWithSecureType) {
        uploadingFiles.push(formatLocalDataToResourceData(file));
      }
    }
    if (source === "audio") {
      uploadingFiles = uploadingFiles.sort((a, b) => getSortValue(a, b, sort));
    }
    if (uploadingFiles.length) {
      newData = mergeTwoSortedArrays(newData, uploadingFiles, sort);
      _updateCacheFromLocalChanges &&
        _updateCacheFromLocalChanges({
          key: localChangeKey,
          localChanges: { insert: uploadingFiles, sort }
        });
    }
  }
  return newData;
};

export const isDownloadingRequired = data => {
  return (
    data.actionType === "download" &&
    (data.status === "queued" || data.status === "paused" || data.status === "downloading")
  );
};

export const getMusicExtraData = data => ({
  downloading: isDownloadingRequired(data)
});

export const getDocsExtraData = (data, { mergeDownloading } = {}) => {
  let newData = {
    url: data.thumbnail_url
  };
  if (mergeDownloading) {
    newData.downloading = isDownloadingRequired(data);
  }
  return newData;
};

export const getGalleryExtraData = (data, { mergeDownloading } = {}) => {
  let newData = {
    url: data.converted_jpeg_url || data.thumbnail_url,
    height: data.converted_height || data.thumbnail_height,
    width: data.converted_width || data.thumbnail_width
  };
  if (mergeDownloading) {
    newData.downloading = isDownloadingRequired(data);
  }
  return newData;
};

export const onSnapshotMusic = async ({ result }) => {
  let modifiedData = [];
  result &&
    result.forEach(doc => {
      if (doc._op === "removed" || (doc.resource_title && doc.uploaded)) {
        modifiedData.push(doc);
      }
    });
  return { result: modifiedData };
};

export const afterFetchMusic = async (state, props = {}) => {
  if (Platform.OS === "web") {
    let modifiedResult = [];
    let {
      uri: { props: { query: { sort } = {} } = {} } = {},
      oldData = [],
      result: { result: fetchResult } = {}
    } = props;
    fetchResult &&
      fetchResult.forEach(doc => {
        if (doc.resource_title && doc.uploaded) {
          modifiedResult.push(doc);
        }
      });
    modifiedResult = mergeTwoSortedArrays(oldData, modifiedResult, sort);
    return { state: { ...state, data: modifiedResult } };
  } else {
    let sqlite = props.uri && props.uri.props && props.uri.props.query && props.uri.props.query.sqlite;
    if (sqlite && isMetadataPopulatedForCurrentSession && (await isMetadataPopulatedForCurrentSession())) {
      return { state };
    } else {
      let modifiedResult = [];
      let { result: fetchResult } = props.result || {};
      let downloadingFilesMap = await ensureAndGetDownloadingFilesMap(state);
      fetchResult &&
        fetchResult.forEach(doc => {
          if (doc.resource_title && doc.uploaded) {
            if (downloadingFilesMap && downloadingFilesMap[doc._id]) {
              Object.assign(doc, downloadingFilesMap[doc._id]);
            }
            modifiedResult.push(doc);
          }
        });
      modifiedResult = await getMergedData(modifiedResult, props, { source: "audio" });
      return { state: { ...state, data: modifiedResult } };
    }
  }
};

export const onSnapshotDocs = async ({ result }) => {
  let modifiedData = [];
  result &&
    result.forEach(doc => {
      if (doc._op === "removed" || doc.uploaded) {
        Object.assign(doc, getDocsExtraData(doc));
        modifiedData.push(doc);
      }
    });
  return { result: modifiedData };
};

export const afterFetchDocs = async (state, props = {}) => {
  if (Platform.OS === "web") {
    let modifiedResult = [];
    let {
      uri: { props: { query: { sort } = {} } = {} } = {},
      oldData = [],
      result: { result: fetchResult } = {}
    } = props;
    fetchResult &&
      fetchResult.forEach(doc => {
        if (doc.uploaded) {
          Object.assign(doc, getDocsExtraData(doc));
          modifiedResult.push(doc);
        }
      });
    modifiedResult = mergeTwoSortedArrays(oldData, modifiedResult, sort);
    return { state: { ...state, data: modifiedResult } };
  } else {
    let sqlite = props.uri && props.uri.props && props.uri.props.query && props.uri.props.query.sqlite;
    if (sqlite && isMetadataPopulatedForCurrentSession && (await isMetadataPopulatedForCurrentSession())) {
      return { state };
    } else {
      let modifiedResult = [];
      let { result: fetchResult } = props.result || {};
      let downloadingFilesMap = await ensureAndGetDownloadingFilesMap(state);
      fetchResult &&
        fetchResult.forEach(doc => {
          if (doc.uploaded) {
            Object.assign(doc, getDocsExtraData(doc));
            if (downloadingFilesMap && downloadingFilesMap[doc._id]) {
              Object.assign(doc, downloadingFilesMap[doc._id]);
            }
            modifiedResult.push(doc);
          }
        });
      modifiedResult = await getMergedData(modifiedResult, props, { source: "doc" });
      return { state: { ...state, data: modifiedResult } };
    }
  }
};

export const onSnapshotGallery = async ({ result }) => {
  let modifiedData = [];
  result &&
    result.forEach(doc => {
      const { _op, thumbnail_height, thumbnail_width, thumbnail_url } = doc;
      if (_op === "removed" || (thumbnail_height && thumbnail_width && thumbnail_url)) {
        Object.assign(doc, getGalleryExtraData(doc));
        modifiedData.push(doc);
      }
    });
  return { result: modifiedData };
};

export const afterFetchGallery = async (state, props = {}) => {
  const specialProps = props.result && props.result.specialProps;
  if (state && specialProps) {
    state.dataParams = { ...state.dataParams, specialProps };
  }
  if (Platform.OS === "web") {
    let modifiedResult = [];
    let {
      uri: { props: { query: { sort } = {} } = {} } = {},
      oldData = [],
      result: { result: fetchResult } = {}
    } = props;
    fetchResult &&
      fetchResult.forEach(doc => {
        const { thumbnail_height, thumbnail_width, thumbnail_url } = doc;
        if (thumbnail_height && thumbnail_width && thumbnail_url) {
          Object.assign(doc, getGalleryExtraData(doc));
          modifiedResult.push(doc);
        }
      });
    modifiedResult = mergeTwoSortedArrays(oldData, modifiedResult, sort);
    return { state: { ...state, data: modifiedResult } };
  } else {
    let query = props.uri && props.uri.props && props.uri.props.query;
    let { sqlite, mergeLocalWithSecureType } = query || {};
    if (sqlite && isMetadataPopulatedForCurrentSession && (await isMetadataPopulatedForCurrentSession())) {
      return { state };
    } else {
      let modifiedResult = [];
      let { result: fetchResult } = props.result || {};
      let downloadingFilesMap = await ensureAndGetDownloadingFilesMap(state);
      let allUploadedIdsMap = mergeLocalWithSecureType && (await getUploadedFileMap({ keyField: "resource" }));
      fetchResult &&
        fetchResult.forEach(doc => {
          if (mergeLocalWithSecureType && doc.uploaded && !doc.thumbnail_url && !doc.thumbnail_error) {
            let matchingUpload = allUploadedIdsMap && allUploadedIdsMap[doc._id];
            if (matchingUpload && matchingUpload.status === "success") {
              Object.assign(doc, {
                thumbnail_url: matchingUpload.uri,
                thumbnail_height: matchingUpload.orientedHeight,
                thumbnail_width: matchingUpload.orientedWidth,
                localThumbnail: true
              });
            }
          }
          const { thumbnail_height, thumbnail_width, thumbnail_url } = doc;
          if (thumbnail_height && thumbnail_width && thumbnail_url) {
            Object.assign(doc, getGalleryExtraData(doc));
            if (downloadingFilesMap && downloadingFilesMap[doc._id]) {
              Object.assign(doc, downloadingFilesMap[doc._id]);
            }
            Object.assign(doc);
            modifiedResult.push(doc);
          }
        });
      modifiedResult = await getMergedData(modifiedResult, props, { source: "gallery" });
      return { state: { ...state, data: modifiedResult } };
    }
  }
};

export const isRemoveFromGroupDetailVisible = ({ data, user }) => {
  if (!data || !user) {
    return;
  }
  if (data._createdBy && data._createdBy.firebaseUid === user.uid) {
    return true;
  }
};

export const isImportFromGroupDetailVisible = ({ data, user }) => {
  if (!data || !user) {
    return;
  }
  if (data._createdBy && data._createdBy.firebaseUid !== user.uid) {
    return true;
  }
};

export const isRemoveFromGroupVisible = ({ data, selectedIds, user }) => {
  if (!selectedIds || selectedIds.length === 0 || !user) {
    return;
  }
  let dataMap = {};
  data &&
    data.forEach(row => {
      dataMap[row._id] = row;
    });
  for (var i = 0; i < selectedIds.length; i++) {
    let selectedRow = dataMap[selectedIds[i]];
    if (selectedRow && selectedRow._createdBy && selectedRow._createdBy.firebaseUid === user.uid) {
      return true;
    }
  }
};

export const isImportFromGroupVisible = ({ data, selectedIds, user }) => {
  if (!selectedIds || selectedIds.length === 0 || !user) {
    return;
  }
  let dataMap = {};
  data &&
    data.forEach(row => {
      dataMap[row._id] = row;
    });
  for (var i = 0; i < selectedIds.length; i++) {
    let selectedRow = dataMap[selectedIds[i]];
    if (selectedRow && selectedRow._createdBy && selectedRow._createdBy.firebaseUid !== user.uid) {
      return true;
    }
  }
};
