import axios from "axios";

export default ({ privacyConsentUrl, REGISTRY_HASHES, getUserFromUserContext, AsyncStorage }) => {
  const getCurrentUserExtrefKey = () => {
    let user = (getUserFromUserContext && getUserFromUserContext()) || {};
    // console.log("@@@@@ user is", user);
    return user.extRefKey;
  };

  const addPrivacyConsent = async (hash, extRefKey) => {
    try {
      let registryHash = REGISTRY_HASHES[hash];
      if (!registryHash) {
        return;
      }
      extRefKey = extRefKey || getCurrentUserExtrefKey();
      if (!extRefKey) {
        return;
      }
      let isConsentAdded = await AsyncStorage.getItem(`${hash}_consent_added`);
      if (!isConsentAdded) {
        const result = await axios.get(`${privacyConsentUrl()}${registryHash()}/${extRefKey}/true`);
        await AsyncStorage.setItem(`${hash}_consent_added`, "true");
        return result;
      }
    } catch (err) {
      console.warn("@@@@ error in adding consent", err);
    }
  };

  return { addPrivacyConsent };
};
