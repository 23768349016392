import uuid from "uuid/v4";
import { findIndex } from "lodash";
import { Connect as ConnectHoc, PureRealtimeSync as PureRealtimeSyncHoc } from "connect";
import {
  populateUpdates,
  denormalizeUpdates,
  mergeUpdates,
  normalizeUpdatesOld,
  normalizeUpdatesCqrs
} from "data-functions/lib/DataFunctions";
import Computation from "computation/lib/Computation";
import UpdateDataHoc from "update-data/lib/UpdateData";
import { SelectionStore as SelectionStoreHoc } from "table";

export default ({
  withContext,
  SelectionStoreContext,
  isJSONObject,
  resolveValue,
  beforeFetch,
  afterFetch,
  deepEqual,
  iterator,
  cqrs,
  getNewId,
  renderProps,
  subscribe,
  unsubscribe,
  onRealTimeUpdate,
  fetchTimeout,
  addLocalDataChangeListener,
  removeLocalDataChangeListener,
  fireLocalDataChangeListener,
  mergeTwoSortedArrays,
  fireListRowUpdateListener,
  onPendingBlocker,
  showMessage,
  I18N
} = {}) => {
  const UpdateData = UpdateDataHoc({
    Computation,
    mergeUpdates,
    denormalizeUpdates,
    populateUpdates,
    normalizeUpdates: cqrs ? normalizeUpdatesCqrs : normalizeUpdatesOld,
    getNewId,
    iterator
  });

  if (!onRealTimeUpdate) {
    const updateRealTimeData = PureRealtimeSyncHoc({ findIndex, isJSONObject, resolveValue });
    onRealTimeUpdate = updateRealTimeData.updateRealTimeData;
  }

  const Connect = withContext(
    ConnectHoc({
      uriProps: {
        limit: 20
      },
      renderProps,
      beforeFetch,
      afterFetch,
      deepEqual,
      UpdateData,
      subscribe,
      unsubscribe,
      onRealTimeUpdate,
      uuid,
      fetchTimeout,
      addLocalDataChangeListener,
      removeLocalDataChangeListener,
      fireLocalDataChangeListener,
      mergeTwoSortedArrays,
      fireListRowUpdateListener,
      onPendingBlocker,
      showMessage,
      I18N,
      minDataLimit: 50
    }),
    {
      fetch: "App.fetch",
      invoke: "App.invoke",
      subscribe: "App.subscribe",
      unsubscribe: "App.unsubscribe",
      user: "User.user",
      notifyListner: "App.notifyListner",
      showNetworkError: "NetworkListener.showNetworkError"
    }
  );

  const AppConnect = withContext(
    ConnectHoc({
      uriProps: {
        limit: 20
      },
      renderProps,
      beforeFetch,
      afterFetch,
      deepEqual,
      UpdateData,
      onRealTimeUpdate,
      uuid,
      showMessage,
      I18N
    }),
    {
      fetch: "App.fetch",
      invoke: "App.invoke",
      subscribe: "App.subscribe",
      unsubscribe: "App.unsubscribe",
      user: "User.user",
      notifyListner: "App.notifyListner"
    }
  );

  const SelectionStore = withContext(SelectionStoreHoc({ id: "_id", renderProps, SelectionStoreContext }), {
    addLoadDataListener: "Screen.Connect.addLoadDataListener",
    removeLoadDataListener: "Screen.Connect.removeLoadDataListener"
  });

  return { Connect, AppConnect, SelectionStore };
};
