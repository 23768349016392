import React from "react";
import { SharedSMComponent, SharedMDComponent } from "./SharedCards";
import { SharedNoData } from "../noData/NoData";
import { sharedCollectionsQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

export default {
  SharedSM: {
    component: "table",
    dataObject: "Collection",
    title: () => I18N.t("shared"),
    selectable: false,
    renderNoData: <SharedNoData />,
    ...sharedCollectionsQuery,
    bodyProps: {
      DataRow: SharedSMComponent
    }
  },
  SharedMD: {
    component: "table",
    dataObject: "Collection",
    title: () => I18N.t("shared"),
    ...sharedCollectionsQuery,
    renderNoData: <SharedNoData />,
    componentTypeMD: {
      gridTable: {
        cardPerRowLG: 3,
        cardPerRowMD: 2,
        bodyStyle: {
          flex: 1,
          paddingTop: 34,
          paddingBottom: 4,
          paddingLeft: 110,
          paddingRight: 110
        }
      }
    },
    bodyProps: {
      DataRow: SharedMDComponent
    }
  }
};
