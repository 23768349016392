export default {
  "/configuration": {
    screen: "Configuration"
  },
  "/contacts": {
    screenSM: "ContactsSM",
    screenMD: "ContactsMD"
  },
  "/settings": {
    screen: "Settings"
  },
  "/upload-setting": {
    screen: "UploadSetting"
  },
  "/about": {
    screen: "About"
  },
  "/reset-contact": {
    screen: "ResetContact"
  },
  "/help": {
    screen: "Help"
  },
  "/contact-detail": {
    screen: "ContactDetail"
  },
  "/add-contact": {
    screen: "AddContactSM"
  },
  "/edit-contact": {
    screen: "EditContactSM"
  },
  "/search-contact": {
    screen: "SearchContact"
  },
  "/delete-account": {
    screen: "DeleteAccount"
  },  
};
