export default {
  "/landing": {
    screenSM: "Walkthrough",
    screenMD: "WebLandingScreen"
  },
  "/login-option": {
    screen: "LoginOption"
  },
  "/login": {
    screen: "Login"
  },
  "/custom-login": {
    screen: "CustomLogin"
  },
  "/validate-otp": {
    screen: "ValidateOtp"
  },
  "/register-email": {
    screen: "RegisterEmail"
  },
  "/validate-email-otp": {
    screen: "ValidateEmailOtp"
  },
  "/register-mobile": {
    screen: "RegisterMobile"
  },
  "/validate-mobile-otp": {
    screen: "ValidateMobileOtp"
  },
  // "/register-success": {
  //   screen: "RegisterSuccess"
  // },
  "/forgot-email": {
    screen: "ForgotEmail"
  },
  "/show-forgot-email": {
    screen: "ShowForgotEmail"
  }
};
