import React from "react";
export default ({ Box, Component }) => {
  class ViewAR extends React.Component {
    render() {
      let { aspectRatio = 1.5, ...rest } = this.props;
      return (
        <Box
          render={[
            {
              viewStyle: {
                flex: 1,
                height: 0,
                paddingBottom: `${100 / aspectRatio}%`
              },
              render: [
                {
                  viewStyle: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  },
                  render: <Component {...rest} />
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return ViewAR;
};
