export default () => {
  const toastStyle = {
    boxStyle: {
      position: "absolute",
      top: "20px",
      display: "flex",
      "align-items": "center",
      "justify-content": "center",
      "align-self": "center",
      "z-index": 15
    },
    containerStyle: {
      "background-color": "#1c1c1c",
      color: "#ffffff",
      "padding-left": "60px",
      "padding-right": "60px",
      "padding-top": "14px",
      "padding-bottom": "14px",
      "border-radius": "4px",
      "font-size": "16px",
      "font-family": "OfficinaSerif-Book",
      "text-align": "center"
    }
  };
  return {
    toastStyle
  };
};
