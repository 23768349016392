import React from "react";
import {
  Box,
  PopupHeader as Header,
  I18N,
  View,
  StatusBar as LoadingIndicator,
  withContext,
  theme,
  MultipleText,
  fireLocalDataChangeListener,
  logFirebaseAnalyticsEvent,
  resolveMQ,
  getInput,
  AppConfig
} from "../../../FsCloudComponent";
import { generateShareLink } from "./shareUtility";
let { colors, fonts, bgs } = theme;
let { themeColor, brownGrey, highlightColor, primaryColor } = colors;
let { h9, h9_21, h1_21 } = fonts;
let { lightPink } = bgs;

const { APP_TERMS_CONDITION_URL: termsOfUseUrl } = AppConfig;

const componentTheme = {
  containerStyleSM: {
    viewStyle: {
      minHeight: 460
    }
  },
  containerStyleMD: {
    viewStyle: {
      width: 539,
      minHeight: 335
    }
  },
  componentContainerStyle: {
    containerStyle: {
      viewStyle: {
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 12
      }
    },
    infoMessageStyle: {
      viewStyle: {
        paddingTop: 24,
        paddingBottom: 9
      }
    },
    inputBoxStyle: {
      viewStyle: {
        backgroundColor: lightPink,
        borderRadius: 4,
        marginTop: 9,
        marginBottom: 9,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 14,
        paddingBottom: 13
      },
      textStyle: {
        ...h9,
        color: primaryColor
      }
    },
    agreementStyle: {
      containerStyle: {
        viewStyle: {
          paddingTop: 8,
          paddingBottom: 12,
          cursor: "pointer"
        }
      },
      textStyle: {
        ...h9_21,
        color: brownGrey
      }
    }
  },
  buttonBoxStyle: {
    direction: "row",
    viewStyle: {
      justifyContent: "flex-end",
      paddingTop: 12,
      paddingBottom: 12
    }
  },

  closeButtonStyle: {
    viewStyle: {
      borderRadius: 4,
      height: 36,
      cursor: "pointer",
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 19,
      paddingRight: 19,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: themeColor
    },
    textStyle: { ...h9, color: highlightColor }
  }
};

class GetLinkPopupComponent extends React.Component {
  state = { pending: true };
  async componentDidMount() {
    try {
      let { selectedIds, data, action: { origin } = {}, invoke, urls, collaborator_mode } = this.props;
      this.setState({ pending: true });
      let { url } = await generateShareLink({
        fireLocalDataChangeListener,
        logFirebaseAnalyticsEvent,
        selectedIds,
        data,
        origin,
        invoke,
        urls,
        collaborator_mode
      });
      this.setState({ url, pending: false });
    } catch (err) {
      this.setState({ error: err && err.message, pending: false });
    }
  }
  componentDidUpdate() {
    if (this.inputRef) {
      this.inputRef.select();
    }
  }
  render() {
    const { onClose, activeMQ } = this.props;
    const { containerStyle, buttonBoxStyle, closeButtonStyle, componentContainerStyle } = resolveMQ(
      componentTheme,
      ["containerStyle", "buttonBoxStyle", "buttonStyle", "closeButtonStyle", "componentContainerStyle"],
      activeMQ
    );
    const { url, pending } = this.state;
    const TextAreaInput = getInput("textArea");
    return (
      <View style={{ ...containerStyle.viewStyle }}>
        <Header title={I18N.t("getLinkCaps")} onClose={onClose} />
        {pending && <LoadingIndicator />}
        {url && (
          <Box
            {...componentContainerStyle.containerStyle}
            render={[
              {
                ...componentContainerStyle.infoMessageStyle,
                render: (
                  <MultipleText
                    data={{
                      textData: [
                        {
                          text: I18N.t("openLinkText1"),
                          textStyle: { ...h1_21, color: themeColor },
                          link: { uri: url },
                          external: true
                        },
                        {
                          text: ` ${I18N.t("openLinkText2")}`,
                          textStyle: { ...h9_21, color: brownGrey }
                        }
                      ]
                    }}
                    field="textData"
                  />
                )
              },
              {
                ...componentContainerStyle.inputBoxStyle,
                render: (
                  <TextAreaInput
                    inputRef={ref => (this.inputRef = ref)}
                    value={url}
                    readonly={"readonly"}
                    style={{ ...componentContainerStyle.inputBoxStyle.textStyle }}
                    onClick={() => {
                      if (this.inputRef) {
                        this.inputRef.select();
                      }
                    }}
                    onDragStart={e => {
                      e && e.preventDefault && e.preventDefault();
                      e && e.stopPropagation && e.stopPropagation();
                    }}
                    maxLength={1000}
                  />
                )
              },
              {
                ...componentContainerStyle.agreementStyle.containerStyle,
                render: (
                  <MultipleText
                    data={{
                      textData: [
                        {
                          text: `${I18N.t("linkAgreementText")} `,
                          textStyle: { ...h9_21, color: brownGrey }
                        },
                        {
                          text: I18N.t("termsOfUseText"),
                          textStyle: { ...h1_21, color: themeColor },
                          link: { uri: termsOfUseUrl },
                          external: true
                        }
                      ]
                    }}
                    field="textData"
                  />
                )
              },
              {
                ...buttonBoxStyle,
                render: [
                  {
                    ...closeButtonStyle,
                    text: I18N.t("close")
                  }
                ],
                onClick: onClose
              }
            ]}
          />
        )}
        {this.state.error && (
          <Box
            style={{ flex: 1, alignItems: "center", justifyContent: "center", padding: 30 }}
            textStyle={{
              ...h9,
              color: primaryColor
            }}
            text={this.state.error}
          />
        )}
      </View>
    );
  }
}

GetLinkPopupComponent = withContext(GetLinkPopupComponent, {
  selectedIds: "SelectionStore.selectedIds",
  invoke: "App.invoke",
  urls: "App.urls",
  activeMQ: "ActiveMQ"
});

export default GetLinkPopupComponent;
