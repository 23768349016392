import React from "react";
import { Box, getImage, ScrollView, View, theme, withContext, getContactPicUrl } from "../../../../FsCloudComponent";
import groupBy from "lodash/groupBy";
const { fonts, colors, bgs } = theme;
const { themeColor, primaryColor, whiteTwo } = colors;
const { lightPink } = bgs;
const { h1, h16_l, h9 } = fonts;

class ContactsViewSM extends React.Component {
  selectRow = data => {
    let { selectRow } = this.props;
    selectRow && selectRow(data);
  };

  onClick = data => {
    let { isSelectionMode, addUri, getPath } = this.props;
    if (isSelectionMode && isSelectionMode()) {
      this.selectRow(data);
    } else {
      addUri &&
        addUri(getPath(), {
          uri: "/contact-detail",
          props: { filter: { _id: data._id } }
        });
    }
  };

  render() {
    let { data, isSelected } = this.props;
    if (!data || !data.length) {
      return null;
    }
    data.forEach(contact => {
      contact["first_character"] = contact.firstName.charAt(0).toUpperCase();
    });
    let contactGroup = groupBy(data, "first_character") || {};
    return (
      <ScrollView
        style={{
          flex: 1,
          marginTop: 4,
          marginBottom: 12
        }}
      >
        {Object.keys(contactGroup).map(firstCharacter => (
          <View>
            <Box
              direction={"row"}
              viewStyle={{ marginTop: 4 }}
              render={[
                {
                  viewStyle: { width: 48, alignItems: "center", height: 56, justifyContent: "center" },
                  textStyle: { color: themeColor, textTransform: "uppercase", ...h16_l },
                  text: firstCharacter
                },
                {
                  width: "1fr",
                  render: contactGroup[firstCharacter].map(contact => {
                    if (!contact) {
                      return void 0;
                    }
                    let selected = isSelected(contact);
                    return {
                      viewStyle: {
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingRight: 12,
                        alignItems: "center"
                      },
                      direction: "row",
                      onClick: () => this.onClick(contact),
                      onLongPress: () => this.selectRow(contact),
                      render: [
                        {
                          imageStyle: { width: 32, height: 32, borderRadius: 16, backgroundColor: whiteTwo },
                          image: selected
                            ? getImage("contactSelectedIcon")
                            : contact["photo_id"]
                            ? { uri: getContactPicUrl(contact["photo_id"]) }
                            : getImage("profileImageIcon")
                        },
                        {
                          viewStyle: { flex: 1, paddingLeft: 16 },
                          textStyle: {
                            numberOfLines: 1,
                            ...(selected ? { ...h1, color: themeColor } : { ...h9, color: primaryColor })
                          },
                          text: contact["name"]
                        }
                      ]
                    };
                  })
                }
              ]}
            />
            <View
              style={{
                marginLeft: 48,
                marginTop: 19,
                marginBottom: 1,
                borderBottomWidth: 1,
                borderBottomColor: lightPink
              }}
            />
          </View>
        ))}
      </ScrollView>
    );
  }
}

ContactsViewSM = withContext(ContactsViewSM, {
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri",
  pending: "Screen.Connect.pending",
  selectRow: "Screen.SelectionStore.selectRow",
  isSelected: "Screen.SelectionStore.isSelected",
  isSelectionMode: "Screen.SelectionStore.isSelectionMode"
});
export default ContactsViewSM;
