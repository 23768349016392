import React from "react";
import { Box, withContext, showMessage, I18N, Keyboard, updateUserProfile } from "../../../FsCloudComponent";

class UpdateProfileAction extends React.Component {
  onClick = async () => {
    let {
      data,
      invoke,
      getUpdates,
      validateData,
      resetUpdates,
      setValue,
      updateUserInfo,
      setComponentState,
      action: { updateEditMode } = {}
    } = this.props;
    Keyboard && Keyboard.dismiss();
    if (validateData) {
      let validations = await validateData();
      if (validations && Object.keys(validations).length) {
        return;
      }
    }
    let { updates } = (getUpdates && getUpdates()) || {};
    let update = updates && updates.length ? updates[0].update : void 0;
    let updatedData = update && update.data && update.data.set;
    if (!updatedData) {
      showMessage && showMessage(I18N.t("noChangesFound"), 1000);
      return;
    }
    updatedData = {
      ...updatedData
    };
    if (updatedData.firstName !== undefined) {
      updatedData.firstName = updatedData.firstName.trim();
    }
    if (updatedData.lastName !== undefined) {
      updatedData.lastName = updatedData.lastName.trim();
    }
    if (updatedData.email !== undefined) {
      updatedData.email = updatedData.email.trim();
    }
    try {
      let response = await updateUserProfile(updatedData, { invoke });
      updateUserInfo &&
        updateUserInfo({
          ...updatedData
        });
      showMessage && showMessage(I18N.t("profileUpdatedMessage"), 1000);
      if (response && response.name && setValue) {
        setValue({ data, field: "name", value: response.name });
      }
      resetUpdates && resetUpdates();
      updateEditMode && setComponentState && setComponentState({ editMode: false });
    } catch (err) {
      showMessage && showMessage(err.message, 2000);
    }
  };

  render() {
    let { style, children } = this.props;
    return <Box style={style} onClick={this.onClick} render={children} />;
  }
}

UpdateProfileAction = withContext(UpdateProfileAction, {
  invoke: "Screen.Connect.invoke",
  validateData: "Screen.Connect.updatableData.validateData",
  getUpdates: "Screen.Connect.updatableData.getUpdates",
  resetUpdates: "Screen.Connect.updatableData.resetUpdates",
  setValue: "Screen.Connect.setValue",
  data: "Screen.Connect.data",
  updateUserInfo: "User.updateUserInfo",
  setComponentState: "Screen.setComponentState"
});

export default UpdateProfileAction;
