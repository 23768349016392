import React from "react";

export default ({ Box, theme: themeHoc }) => {
  const NetworkImageRender = props => {
    let { getImageUrl, value = {}, theme } = props;
    theme = theme || themeHoc || {};
    let { key, name } = value;
    name = name && encodeURIComponent(name);
    let imageSrc = getImageUrl && getImageUrl({ key, name });
    return (
      <Box
        {...theme}
        image={{
          uri: imageSrc
        }}
        key={imageSrc}
      />
    );
  };

  return NetworkImageRender;
};
