import { I18N } from "../../../FsCloudComponent";

export default {
  ArchiveSM: {
    component: "tab",
    title: () => I18N.t("archive"),
    showTitleInTab: true,
    swipeable: true,
    isNestedTab: true,
    tabs: {
      archiveGallery: {
        tab: {
          text: () => I18N.t("gallery")
        },
        screen: "ArchiveGallery",
        absoluteHeader: true
      },
      archiveDocs: {
        tab: {
          text: () => I18N.t("docs")
        },
        screen: "ArchiveDocs",
        absoluteHeader: true
      },
      archiveMusic: {
        tab: {
          text: () => I18N.t("music")
        },
        screen: "ArchiveMusic",
        absoluteHeader: true
      }
    }
  },
  ArchiveMD: {
    component: "tab",
    title: () => I18N.t("archive"),
    navComponentContainerStyle: {
      marginLeft: 81
    },
    tabs: {
      archiveGallery: {
        tab: {
          text: () => I18N.t("gallery")
        },
        screen: "ArchiveGalleryWeb"
      },
      archiveDocs: {
        tab: {
          text: () => I18N.t("docs")
        },
        screen: "ArchiveDocs"
      },
      archiveMusic: {
        tab: {
          text: () => I18N.t("music")
        },
        screen: "ArchiveMusic"
      }
    }
  }
};
