import { commonLayout } from "../../layouts";
import { I18N, theme, getSizeFormat } from "../../../FsCloudComponent";

const { fonts, colors } = theme;
const { h1_16, h2_l_18 } = fonts;
const { primaryColor, brownGrey } = colors;

const TrashCardSM = {
  render: [
    {
      direction: "row",
      viewStyle: {
        height: 74,
        alignItems: "center"
      },
      render: [
        {
          viewStyle: { paddingLeft: 12, paddingRight: 16 },
          value: ({ data }) => {
            return {
              field: "name",
              type: "imageDoc",
              componentName: data.type === "audio" ? "musicImage" : void 0
            };
          }
        },
        {
          width: "1fr",
          render: [
            {
              textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1, whiteSpace: "pre" },
              value: { field: "name" }
            },
            {
              direction: "row",
              textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
              viewStyle: { paddingTop: 4 },
              separator: "|",
              render: [
                {
                  value: ({ data }) => {
                    return { text: getSizeFormat(data.resource_size) };
                  }
                },
                {
                  value: ({ data }) => {
                    let text = "Unknown";
                    let { type, docType } = data;
                    if (type === "doc") {
                      text = docType;
                    } else if (type === "audio") {
                      text = "Audio";
                    } else if (type === "image" || type === "video") {
                      text = "Media";
                    }
                    return { text };
                  }
                },
                {
                  value: {
                    field: "deletedOn",
                    type: "date",
                    format: "classic",
                    classic: {
                      today: "LT",
                      rest: "DD/MM/YYYY"
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

const TrashCardMD = {
  columns: [
    {
      width: "1fr",
      header: () => I18N.t("name"),
      field: "name",
      type: "imageDoc"
    },
    {
      header: () => I18N.t("size"),
      field: "resource_size",
      type: "fileSize",
      width: "175px"
    },
    {
      header: () => I18N.t("deleted"),
      type: "date",
      field: "deletedOn",
      width: "202px",
      format: "classic",
      classic: {
        today: "LT",
        rest: "DD/MM/YYYY"
      }
    },
    {
      header: () => I18N.t("type"),
      type: "text",
      field: data => {
        if (!data) {
          return;
        }
        let { type, docType } = data;
        if (type === "doc") {
          return docType;
        } else if (type === "audio") {
          return "Audio";
        } else {
          return "Media";
        }
      },
      width: "148px"
    },
    {
      header: () => I18N.t("source"),
      type: "text",
      field: "source",
      defaultText: "-",
      width: "170px"
    }
  ]
};

const TrashCard = commonLayout({
  SM: TrashCardSM,
  MD: TrashCardMD
});

export { TrashCard };
