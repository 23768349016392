import React from "react";

export default ({ Connect, NameComponent, CollectionSelector }) => {
  class AddItemsToCollection extends React.Component {
    state = { viewType: "selectView" };

    setViewType = viewType => {
      this.setState({
        viewType
      });
    };

    render() {
      const { action, rowData, data, pending, onClose, selectedIds, unSelectAll } = this.props;
      let { origin } = action;

      let noData = !pending && (!data || (Array.isArray(data) && !data.length));

      let componentProps = {
        onClose: () => {
          onClose && onClose();
          unSelectAll && unSelectAll();
        },
        rowData,
        data,
        selectedIds,
        origin,
        setViewType: this.setViewType
      };
      let { viewType } = this.state;
      if (noData) {
        viewType = "createView";
      }

      let renderComponent = void 0;
      if (viewType === "createView") {
        renderComponent = <NameComponent showSecondaryText={noData} {...componentProps} />;
      } else {
        renderComponent = <CollectionSelector {...this.props} {...componentProps} />;
      }
      return renderComponent;
    }
  }

  class AddItemsToCollectionConnect extends React.Component {
    render() {
      let { action, data: rowData } = this.props;
      let { connectProps } = action;
      if (connectProps && typeof connectProps === "function") {
        connectProps = connectProps(this.props);
      }
      return (
        <Connect {...connectProps}>
          {({ data, pending, loadingMore, fetchMore }) => {
            return (
              <AddItemsToCollection
                {...this.props}
                rowData={rowData}
                data={data}
                pending={pending}
                loadingMore={loadingMore}
                fetchMore={fetchMore}
              />
            );
          }}
        </Connect>
      );
    }
  }

  return AddItemsToCollectionConnect;
};
