import UNSUPPORTED_VIDEO from "./VideoUnspported";

export const METADATA_TABLE = "metadata";
export const DOWNLOAD_TOKENS = "download_tokens";
export const CONNECTIONS_TABLE = "connections";
export const STREAM_TOKEN_TABLE = "user_streaming_tokens";
export const REMOVED_METADATA_TABLE = "removed_metadata";
export const ISSUES_TABLE = "issues";
export const CRASH_REPORTS_TABLE = "crash_reports";
export const SQLITE_DB = "Vivotek";
export const SQLITE_DB_CONTACTS = "VivotekContacts";
export const VIDEO_UNSUPPORTED = UNSUPPORTED_VIDEO;
export const montageSoundFileNames = [
  "montage-audio-1.mp3",
  "montage-audio-2.mp3",
  "montage-audio-3.mp3",
  "montage-audio-4.mp3"
];
export const SECURE_TYPES = {
  DEFAULT: "default",
  VAULT: "vault",
  ARCHIVE: "archive"
};

export const MAX_PDF_FILE_LIMIT = 50;
export const MAX_PDF_FILE_SIZE_LIMIT_IN_BYTES = 50 * 1024 * 1024;

const GALLERY_SORT_MAPPING = {
  createdOn: { uploadedOn: -1, _id: -1 },
  lastModified: { resource_lastModified: -1, _id: -1 },
  size: { resource_size: -1, _id: -1 }
};

export let GALLERY_ALL_SORT = { resource_lastModified: -1, _id: -1 };
export const DOCS_ALL_SORT = { resource_lastModified: -1, _id: -1 };
export const HIGHLIGHTS_ALL_SORT = { resource_lastModified: -1, _id: -1 };
export const MUSIC_ALL_SORT = { lower_name: 1, _id: -1 };
export const DEFAULT_ALL_SORT = { resource_lastModified: -1, _id: -1 };

export const DUPLICATE_GALLERY_SORT = { duplicateMarkedOn: -1, resource_md5Hash: -1, _id: -1 };

export const GROUP_GALLERY_SORT = { postedOn: -1, _id: -1 };
export const GROUP_DOC_SORT = { postedOn: -1, _id: -1 };

export const UPLOAD_GALLERY_SORT = { lastModified: -1, resource: -1 };
export const UPLOAD_DOCS_SORT = { lastModified: -1, resource: -1 };
export const UPLOAD_MUSIC_SORT = { title: 1, resource: -1 };

export const GALLERY_ALL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__gallery`;
export const DOCS_ALL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__doc`;
export const MUSIC_ALL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__audio`;

export const VAULT_GALLERY_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__vault_gallery`;
export const VAULT_DOCS_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__vault_doc`;

export const ARCHIVE_GALLERY_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__archive_gallery`;
export const ARCHIVE_DOCS_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__archive_doc`;
export const ARCHIVE_MUSIC_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__archive_audio`;

export const PLACES_LOCAL_CHANGE_KEY = `PLACES`;
export const DEVICE_FOLDER_LOCAL_CHANGE_KEY = `DEVICEFOLDER`;
export const FAILED_ITEMS_LOCAL_CHANGE_KEY = "failed";
export const INFECTED_ITEMS_LOCAL_CHANGE_KEY = "infected";
export const DUPLICATED_ITEMS_LOCAL_CHANGE_KEY = "duplicateResources";

export const ALBUM_DETAIL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__album_detail`;
export const SET_DETAIL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__set_detail`;
export const PLAYLIST_DETAIL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__playlist_detail`;
export const PLACE_DETAIL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__place_detail`;
export const VAULT_ALBUM_DETAIL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__vaultAlbum_detail`;
export const VAULT_SET_DETAIL_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__vaultSet_detail`;

export const GROUP_GALLERY_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__group_gallery`;
export const GROUP_DOC_LOCAL_CHANGE_KEY = `${METADATA_TABLE}__group_doc`;

export const UPLOAD_QUEUE_LOCAL_CHANGE_KEY = `uploadQueue`;
export const NON_UPLOAD_LOCAL_CHANGE_KEY = `nonUploadItemKey`;
export const DOWNLOAD_QUEUE_LOCAL_CHANGE_KEY = `downloadQueue`;

export const CONTACT_LAST_RESET_SETTING = "lastResetSetting";
export const CONTACT_LAST_SYNCED_DATE = "lastSynced";
export const CONTACT_SERVER_LAST_SYNCED_DATE = "lastSyncedServer";

export const LAST_AUTO_SYNCED_FILE_TIME_IN_SEC = "lastAutoSyncedFileTimeInSec";

const GALLERY_ALL_INDEX = ["gallery", "deleted", "resource_lastModified", "_id"];
const GALLERY_ALL_SIZE_INDEX = ["gallery", "deleted", "resource_size", "_id"];
// FIXME: need to add this index but how
// const GALLERY_ALL_UPLOADED_ON_INDEX = ["gallery", "deleted", "uploadedOn", "_id"];
const DOCS_ALL_INDEX = ["type", "deleted", "resource_lastModified", "_id"];
const MUSIC_ALL_INDEX = ["type", "deleted", "lower_name", "_id"];
const UPLOAD_DOWNLOAD_INDEX = ["status", "actionType"];

export const METADATA_INDEXES = [
  GALLERY_ALL_INDEX,
  DOCS_ALL_INDEX,
  MUSIC_ALL_INDEX,
  UPLOAD_DOWNLOAD_INDEX,
  GALLERY_ALL_SIZE_INDEX
];

export const setGallerySort = sortType => {
  GALLERY_ALL_SORT = GALLERY_SORT_MAPPING[sortType] || { resource_lastModified: -1, _id: -1 };
  return GALLERY_ALL_SORT;
};
