export default ({ fonts, colors }) => {
  const { primaryColor } = colors;
  const { h3 } = fonts;

  const textRenderStyle = {
    textStyle: {
      ...h3,
      color: primaryColor
    }
  };

  return { textRenderStyle };
};
