import React from "react";
import {
  Box,
  PopupHeader as Header,
  View,
  Modal,
  theme,
  getInput,
  I18N,
  withContext,
  StatusBar as LoadingIndicator,
  isValidEmail,
  updateUserProfile
} from "../../../FsCloudComponent";
import ProfileImage from "./ProfileImage";

const { fonts, colors, bgs } = theme;
const { themeColor, primaryColor, brownGrey, tomato, highlightColor } = colors;
const { h20_l, h16_l_22, h9 } = fonts;
const { lightPink } = bgs;
const styles = {
  bodyStyle: {
    viewStyle: {
      paddingTop: 40,
      paddingBottom: 24,
      paddingLeft: 15,
      paddingRight: 15
    }
  },
  primaryMessageStyle: {
    viewStyle: {
      alignSelf: "center",
      paddingBottom: 16,
      paddingLeft: 15,
      paddingRight: 15
    },
    textStyle: {
      ...h20_l,
      color: themeColor
    },
    text: () => I18N.t("enterYourDetails")
  },
  secondaryMessageStyle: {
    viewStyle: {
      alignSelf: "center",
      paddingBottom: 52,
      paddingLeft: 15,
      paddingRight: 15
    },
    textStyle: {
      ...h16_l_22,
      color: primaryColor,
      textAlign: "center"
    },
    text: () => I18N.t("introductionMessage")
  },
  textInputContainerStyle: {
    viewStyle: {
      marginRight: 15,
      marginLeft: 15,
      paddingLeft: 21,
      paddingRight: 21,
      paddingTop: 14,
      paddingBottom: 13,
      backgroundColor: lightPink,
      borderRadius: 4
    }
  },
  textInputStyle: {
    style: {
      ...h9,
      color: brownGrey
    }
  }
};

let modalProps = {
  modal: {
    style: {
      width: 501,
      borderRadius: 4,
      escEnabled: false
    },
    parentStyle: {
      zIndex: 12
    }
  }
};

class IntroducePopup extends React.Component {
  constructor(props) {
    super(props);
    let { user } = props;
    let userState = {};
    if (user.firstName) {
      userState.firstName = user.firstName;
    }
    if (user.lastName) {
      userState.lastName = user.lastName;
    }
    if (user.email) {
      userState.email = user.email;
    }
    this.state = userState;
  }

  onAddClick = async () => {
    try {
      let { user } = this.props;
      let { firstName = "", lastName, email = "" } = this.state;
      firstName = firstName.trim();
      email = email.trim();
      if (lastName !== undefined) {
        lastName = lastName.trim();
      }
      let error = void 0;
      if (firstName.length === 0 || email.length === 0) {
        error = I18N.t("introductionMandatoryError");
      } else if (!isValidEmail(email)) {
        error = I18N.t("emailValidationError");
      }
      if (error) {
        this.setState({ error });
        return;
      }
      this.setState({ loading: true });
      let { updateUserInfo } = this.props;
      let data = {};
      if (user.firstName !== firstName) {
        data.firstName = firstName;
      }
      if (user.email !== email) {
        data.email = email;
      }
      if (lastName !== undefined && user.lastName !== lastName) {
        data.lastName = lastName;
      }
      let userResult = await updateUserProfile(data);
      updateUserInfo && updateUserInfo(userResult);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        error: e.message,
        loading: false
      });
    }
  };

  getInputComponent = (field, inputProps) => {
    const TextInput = getInput("text");
    return (
      <TextInput
        value={this.state[field]}
        onChangeValue={name => {
          let newState = { [field]: name };
          if (this.state.error) {
            newState["error"] = void 0;
          }
          this.setState(newState);
        }}
        placeholder={I18N.t(field)}
        {...styles.textInputStyle}
        {...inputProps}
      />
    );
  };

  render() {
    let { bodyStyle, primaryMessageStyle, secondaryMessageStyle, textInputContainerStyle } = styles;
    return (
      <Modal isOpen={true} {...modalProps.modal} onClose={() => {}}>
        <View>
          {this.state.loading && <LoadingIndicator />}
          <Box
            render={[
              <Header title={I18N.t("introduceHeader")} />,
              {
                ...bodyStyle,
                render: [
                  {
                    viewStyle: { marginBottom: 21, alignItems: "center" },
                    render: (
                      <ProfileImage
                        onUploadStart={() => this.setState({ loading: true })}
                        onUploadEnd={() => this.setState({ loading: false })}
                        onUploadError={() => this.setState({ loading: false })}
                        radius={52}
                      />
                    )
                  },
                  primaryMessageStyle,
                  secondaryMessageStyle,
                  {
                    direction: "row",
                    viewStyle: { paddingBottom: 16 },
                    render: [
                      {
                        width: "1fr",
                        ...textInputContainerStyle,
                        render: this.getInputComponent("firstName", { maxLength: 25 })
                      },
                      {
                        width: "1fr",
                        ...textInputContainerStyle,
                        render: this.getInputComponent("lastName", { maxLength: 25 })
                      }
                    ]
                  },
                  {
                    direction: "row",
                    viewStyle: { paddingBottom: 24 },
                    render: [
                      {
                        width: "1fr",
                        ...textInputContainerStyle,
                        render: this.getInputComponent("email", {
                          maxLength: 55,
                          onSubmitEditing: this.onAddClick
                        })
                      }
                    ]
                  },
                  {
                    viewStyle: {
                      alignItems: "center",
                      paddingLeft: 15,
                      paddingRight: 15
                    },
                    direction: "row",
                    render: [
                      {
                        viewStyle: {
                          flex: 1,
                          paddingRight: 12
                        },
                        textStyle: {
                          ...h9,
                          color: tomato,
                          numberOfLines: 2
                        },
                        text: this.state.error || ""
                      },
                      {
                        viewStyle: {
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 10,
                          paddingBottom: 9,
                          backgroundColor: themeColor,
                          borderRadius: 4,
                          cursor: "pointer"
                        },
                        textStyle: {
                          ...h9,
                          color: highlightColor
                        },
                        text: () => I18N.t("add"),
                        onClick: this.onAddClick
                      }
                    ]
                  }
                ]
              }
            ]}
          />
        </View>
      </Modal>
    );
  }
}

IntroducePopup = withContext(IntroducePopup, {
  user: "User.user",
  updateUserInfo: "User.updateUserInfo",
  upload: "App.upload",
  uploadRequest: "App.uploadRequest"
});

export default IntroducePopup;
