import { I18N } from "../../../FsCloudComponent";

export default {
  HomeSM: {
    component: "tab",
    componentType: "bottomTab",
    title: () => I18N.t("app-title"),
    tabs: {
      highlights: {
        tab: {
          selectedText: () => I18N.t("highlights"),
          image: "homeHighlightIcon",
          selectedImage: "homeHighlightSelectedIcon"
        },
        screen: "HighlightTab",
        panelActionRequired: false,
        pushStateInHistory: true
      },
      gallery: {
        tab: {
          selectedText: () => I18N.t("gallery"),
          image: "homeGalleryIcon",
          selectedImage: "homeGallerySelectedIcon"
        },
        screen: "GalleryTab",
        pushStateInHistory: true
      },
      docs: {
        tab: {
          selectedText: () => I18N.t("docs"),
          image: "homeDocIcon",
          selectedImage: "homeDocSelectedIcon"
        },
        screen: "DocsTab",
        pushStateInHistory: true
      },
      music: {
        tab: {
          selectedText: () => I18N.t("music"),
          image: "homeMusicIcon",
          selectedImage: "homeMusicSelectedIcon"
        },
        screen: "MusicTab",
        pushStateInHistory: true
      }
    }
  },
  HomeMD: {
    component: "tab",
    componentType: "leftTab",
    showSelectedTabTitle: true,
    tabs: {
      gallery: {
        tab: {
          image: "homeGalleryIcon",
          selectedImage: "homeGallerySelectedIcon",
          title: () => I18N.t("gallery")
        },
        screen: "GalleryTab",
        pushStateInHistory: true
      },
      highlights: {
        tab: {
          image: "homeHighlightIcon",
          selectedImage: "homeHighlightSelectedIcon",
          title: () => I18N.t("highlights")
        },
        screen: "Highlight",
        pushStateInHistory: true
      },
      docs: {
        tab: {
          image: "homeDocIcon",
          selectedImage: "homeDocSelectedIcon",
          title: () => I18N.t("docs")
        },
        screen: "DocsTab",
        pushStateInHistory: true
      },
      music: {
        tab: {
          image: "homeMusicIcon",
          selectedImage: "homeMusicSelectedIcon",
          title: () => I18N.t("music")
        },
        screen: "MusicTab",
        pushStateInHistory: true
      }
    }
  }
};
