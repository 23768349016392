import React from "react";

export default ({ Component, getComponent, Box, theme = {}, resolveMQ, SelectAllBox }) => {
  class HeaderSelectionBox extends React.Component {
    onClick = () => {
      let { isAllSelected, selectAll, unSelectAll } = this.props;
      isAllSelected() ? unSelectAll() : selectAll();
    };

    render() {
      let { isAllSelected, multiRowActions = [], actions, activeMQ } = this.props;
      let { boxStyle } = (resolveMQ && activeMQ && resolveMQ(theme, ["boxStyle"], activeMQ)) || theme;
      let RowAction = getComponent && getComponent("HeaderSelectRowAction");
      return (
        <Box
          {...boxStyle}
          render={[
            <Component selected={isAllSelected()} onClick={this.onClick} />,
            multiRowActions.length && RowAction && (
              <RowAction source="Header" action={multiRowActions} actions={actions} selectionRequired />
            ),
            SelectAllBox && <SelectAllBox />
          ]}
        />
      );
    }
  }
  return HeaderSelectionBox;
};
