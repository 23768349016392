import React from "react";

export default ({
  Box,
  Editor,
  LoginContainer,
  theme,
  getImage,
  I18N,
  ChatLinkComponent,
  Button,
  Platform,
  Keyboard,
  getErrorMessage
}) => {
  const { colors, fonts } = theme;
  const { h9, h16 } = fonts;
  const { brownGrey, primaryColor, themeColor } = colors;

  class HeaderComponent extends React.Component {
    render() {
      let { logo, title, primaryText, secondaryText } = this.props;
      return (
        <Box
          render={[
            {
              image: getImage(logo),
              viewStyle: {
                alignItems: "center",
                paddingTop: 30,
                paddingBottom: 24
              }
            },
            title && {
              viewStyle: {
                paddingBottom: 12
              },
              textStyle: {
                ...h16,
                color: themeColor,
                textAlign: "center"
              },
              text: I18N.t(title)
            },
            primaryText && {
              textStyle: {
                ...h9,
                color: brownGrey,
                textAlign: "center"
              },
              text: I18N.t(primaryText)
            },
            secondaryText && {
              viewStyle: {
                paddingTop: 8
              },
              textStyle: {
                ...h9,
                color: brownGrey,
                textAlign: "center"
              },
              text: I18N.t(secondaryText)
            }
          ]}
        />
      );
    }
  }

  class Register extends React.Component {
    state = {};

    _setState = state => {
      this.setState(state);
    };

    onError = err => {
      let message = getErrorMessage(err);
      this._setState({ error: message });
    };

    componentDidMount() {
      let { getRef } = this.props;
      getRef && getRef(this);
    }

    setValue = ({ field, value }) => {
      let { data, setValue } = this.loginContainerProps;
      setValue({ data, field, value });
    };

    onSubmit = async () => {
      let { onClick, addUri, getPath, replaceUri, link, url } = this.props;
      let { data, onSubmit } = this.loginContainerProps;
      if (this.state.error) {
        this.setState({ error: void 0 });
      }
      Keyboard && Keyboard.dismiss();
      let { functionName, functionParams, replaceLink, afterSubmit, afterSubmitLink } = await onClick({
        data,
        link,
        url
      });
      return (
        onSubmit &&
        onSubmit(functionName, functionParams, {
          afterSubmit: result => {
            if (afterSubmit) {
              afterSubmit(result, this.props);
            } else if (afterSubmitLink) {
              let link = afterSubmitLink(result);
              if (replaceLink) {
                replaceUri(link);
              } else {
                addUri && getPath && addUri(getPath(), link);
              }
            }
          },
          throwError: true
        })
      ).catch(err => {
        this.onError(err);
      });
    };

    render() {
      let { header, editor, button, footerComponent, resendOtpComponent, minHeight = 450 } = this.props;
      let contentContainerStyle = { flex: 1, minHeight };
      return (
        <LoginContainer
          scrollViewStyle={{
            style: { flex: 1 },
            contentContainerStyle: Platform.OS === "web" ? {} : contentContainerStyle
          }}
        >
          {props => {
            this.loginContainerProps = props;
            let editorProps = {
              ...props,
              state: this.state,
              setState: this._setState
            };
            return (
              <Box
                className="registrationPlaceholder"
                viewStyle={{ ...(Platform.OS !== "web" ? { flex: 1 } : contentContainerStyle) }}
                render={[
                  {
                    viewStyle: {
                      flex: 1,
                      paddingLeft: 14,
                      paddingRight: 14
                    },
                    render: [
                      <HeaderComponent {...header} />,
                      editor && {
                        viewStyle: {
                          marginTop: 16,
                          marginLeft: 14,
                          marginRight: 14
                        },
                        render: (
                          <Editor
                            {...editorProps}
                            {...editor}
                            inputStyle={
                              editor.field === "otp" && Platform.OS !== "web"
                                ? void 0
                                : {
                                    ...h9,
                                    color: primaryColor
                                  }
                            }
                            placeholderTextColor={brownGrey}
                            onClick={this.onSubmit}
                          />
                        )
                      },
                      resendOtpComponent && resendOtpComponent({ onError: this.onError }),
                      button && {
                        viewStyle: {
                          marginTop: 16,
                          marginBottom: 16,
                          marginLeft: 14,
                          marginRight: 14
                        },
                        render: <Button onClick={this.onSubmit} loading={props.loading} {...button} />
                      },
                      footerComponent && footerComponent({ onError: this.onError })
                    ]
                  },
                  {
                    viewStyle: { alignItems: "center", marginBottom: 18 },
                    render: <ChatLinkComponent />
                  }
                ]}
              />
            );
          }}
        </LoginContainer>
      );
    }
  }
  return Register;
};
