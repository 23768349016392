import React from "react";
import { View, Box, ImageViewer, getImage, RowAction, theme } from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import AudioPlayer from "./DetailAudioPlayer";
import { SECURE_TYPES } from "../../../Constants";

const { colors, fonts, bgs, shadows } = theme;
const { highlightColor, themeColor } = colors;
const { h16 } = fonts;
const { primaryBg5 } = bgs;
const { musicDetailHeaderShadow } = shadows;

const renderHeaderStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      ...musicDetailHeaderShadow,
      backgroundColor: highlightColor,
      alignItems: "center",
      height: 72,
      paddingLeft: 18,
      paddingRight: 18
    },
    textStyle: {
      ...h16,
      color: themeColor,
      numberOfLines: 1
    }
  },
  actionStyle: {
    viewStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      width: 48,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const renderHeader = ({ onClose, value: data, origin }) => {
  let { secureType } = data;
  let isArchive = secureType === SECURE_TYPES.ARCHIVE;
  let { containerStyle, actionStyle } = renderHeaderStyle;
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backArrowGreyIcon"),
      onClick: onClose
    },
    { width: "1fr" },
    {
      direction: "row",
      viewStyle: { alignItems: "center" },
      render: [
        !isArchive && (
          <RowAction
            data={data}
            action={{
              ...ResourceActions.addMusicToPlaylistForm,
              collection: data.collection
            }}
            image={"addToCollectionIcon"}
          />
        ),
        data.collection && data.collection._id && (
          <RowAction data={data} action={ResourceActions.removeFromPlaylist} image={"removeToCollectionIcon"} />
        ),
        <RowAction data={data} action={ResourceActions.download} image={"downloadIcon"} />,
        <RowAction data={data} action={{ ...ResourceActions.infoWeb, origin }} />,
        data && !data.favourite && (
          <RowAction data={data} action={ResourceActions.markFavourite} image={"favouriteIcon"} />
        ),
        data && data.favourite && (
          <RowAction data={data} action={ResourceActions.unmarkFavourite} image={"favouriteSelectedIcon"} />
        ),
        !isArchive && <RowAction data={data} action={ResourceActions.moveToArchive} image={"moveToArchiveIcon"} />,
        isArchive && (
          <RowAction data={data} action={ResourceActions.removeFromArchive} image={"removeFromArchiveIcon"} />
        ),
        !isArchive && <RowAction data={data} action={ResourceActions.markDelete} image={"deleteIcon"} />
      ]
    }
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

const imageViewerWhiteBgStyle = {
  imageViewerStyle: {
    boxStyle: {
      viewStyle: {
        flex: 1,
        backgroundColor: primaryBg5
      },
      direction: "row"
    },
    contentWrapperStyle: {
      viewStyle: {
        flex: 2
      }
    },
    leftArrowIconStyle: {
      viewStyle: {
        alignSelf: "center",
        position: "absolute",
        left: 30,
        cursor: "pointer",
        zIndex: 1
      },
      image: getImage("musicPreviousIcon")
    },
    rightArrowIconStyle: {
      viewStyle: {
        alignSelf: "center",
        position: "absolute",
        right: 30,
        cursor: "pointer"
      },
      image: getImage("musicNextIcon")
    },
    imageContainerStyle: {
      boxStyle: {
        viewStyle: {
          flex: 1
        },
        direction: "row"
      },
      imageViewConatinerStyle: {
        viewStyle: {
          flex: 1
        }
      }
    }
  }
};

class MusicDetailComponent extends React.Component {
  renderImage = () => {
    return (
      <Box
        viewStyle={{
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
        image={getImage("musicDetailCoverIcon")}
      />
    );
  };

  render() {
    let { data: allData = [], origin, onClose, detailId, ...restProps } = this.props;
    let data = allData.find(doc => doc._id === detailId) || {};
    return (
      <View style={{ flex: 1 }}>
        {renderHeader({ value: data, origin, onClose })}
        <ImageViewer
          theme={imageViewerWhiteBgStyle}
          {...restProps}
          images={allData}
          renderHeader={() => void 0}
          renderImage={this.renderImage}
        />
        {allData.length ? (
          <AudioPlayer
            data={allData}
            currentId={detailId}
            onChangeSong={id => {
              const { replaceUri, link } = this.props;
              replaceUri &&
                replaceUri({
                  ...link,
                  detailId: id
                });
            }}
          />
        ) : (
          void 0
        )}
      </View>
    );
  }
}

export default MusicDetailComponent;
