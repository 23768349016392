export default ({ colors, bgs, fonts, images, I18N }) => {
  const { darkBg, blackBg } = bgs;
  const { highlightColor, greyish } = colors;
  const { h1, h2 } = fonts;

  const imageBoxStyle = {
    valueContainerStyle: {
      direction: "row",
      viewStyle: {
        flexWrap: "wrap"
      }
    }
  };

  const documentViewerStyle = {
    documentDetailStyle: {
      boxStyle: {
        viewStyle: {
          flex: 1,
          padding: 10
        }
      },
      headerStyle: {
        boxStyle: {
          direction: "row",
          viewStyle: {
            justifyContent: "space-between",
            padding: 10
          }
        },
        titleStyle: {
          textStyle: {
            ...h1,
            color: highlightColor
          },
          text: () => I18N.t("detail")
        },
        crossStyle: {
          viewStyle: {
            cursor: "pointer"
          },
          textStyle: {
            color: highlightColor
          },
          text: "X"
        }
      },
      descriptionStyle: {
        boxStyle: {
          viewStyle: {
            padding: 10
          }
        },
        labelStyle: {
          textStyle: {
            ...h1,
            color: highlightColor
          }
        },
        valueStyle: {
          textStyle: {
            ...h2,
            color: highlightColor
          },
          viewStyle: {
            marginTop: 10
          }
        }
      },
      generalInfoStyleSM: {
        viewStyle: {
          padding: 10
        }
      },
      genaralInfoStyleMD: {
        viewStyle: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 18,
          paddingRight: 18
        }
      },
      generalInfoHeaderStyle: {
        viewStyle: {
          borderBottomWidth: 1,
          borderColor: greyish,
          paddingTop: 9,
          paddingBottom: 9
        },
        textStyle: {
          ...h1,
          color: highlightColor
        },
        text: () => I18N.t("generalInfoCaps")
      },

      generalInfoItemStyleMD: {
        direction: "row",
        viewStyle: {
          justifyContent: "space-between",
          paddingTop: 9,
          paddingBottom: 9
        }
      },
      generalInfoItemStyleSM: {
        viewStyle: {
          justifyContent: "space-between",
          paddingTop: 9,
          paddingBottom: 9
        }
      },
      generalInfoItemLabelStyle: {
        textStyle: {
          ...h2,
          color: highlightColor
        }
      },
      generalInfoItemValueStyle: {
        textStyle: {
          ...h2,
          color: highlightColor
        }
      }
    },
    boxStyleSM: {
      viewStyle: {
        backgroundColor: blackBg,
        flex: 1
      },
      direction: "row"
    },
    boxStyleMD: {
      viewStyle: {
        backgroundColor: blackBg,
        flex: 1
      },
      direction: "row"
    },
    contentWrapperStyle: {
      viewStyle: {
        flex: 2
      }
    },
    headerStyle: {
      containerStyle: {
        direction: "row",
        viewStyle: {
          height: 50,
          padding: 10,
          backgroundColor: darkBg,
          alignItems: "center"
        },
        itemStyle: {
          padding: 10
        }
      },
      backIconStyle: {
        image: images.backIcon
      },
      downloadIconStyle: {
        image: images.downloadWhiteIcon
      },
      imageNameStyle: {
        viewStyle: {
          backgroundColor: darkBg
        },
        textStyle: {
          color: highlightColor,
          numberOfLines: 2
        }
      }
    },
    leftArrowIconStyleSM: {
      viewStyle: {
        alignSelf: "center",
        position: "absolute",
        left: 12,
        cursor: "pointer",
        zIndex: 1
      },
      image: images.previousIcon
    },
    leftArrowIconStyleMD: {
      viewStyle: {
        alignSelf: "center",
        position: "absolute",
        left: 30,
        cursor: "pointer",
        zIndex: 1
      },
      image: images.previousIcon
    },
    rightArrowIconStyleSM: {
      viewStyle: {
        alignSelf: "center",
        position: "absolute",
        right: 12,
        cursor: "pointer"
      },
      image: images.nextIcon
    },
    rightArrowIconStyleMD: {
      viewStyle: {
        alignSelf: "center",
        position: "absolute",
        right: 30,
        cursor: "pointer"
      },
      image: images.nextIcon
    },
    imageContainerStyle: {
      boxStyle: {
        viewStyle: {
          flex: 1
        },
        direction: "row"
      },
      imageViewConatinerStyle: {
        viewStyle: {
          flex: 1
        }
      }
    }
  };

  const imageViewerStyle = {
    modalStyle: {
      height: 1,
      width: 1,
      animationType: "none",
      maxHeight: "100%",
      noBackdrop: true,
      parentStyle: {
        zIndex: 4 /**prev it was 1. need to set it to 4 as modal has default 4. we need to hide uploaderDiaglogBox in fscloud.  rohit bansal 04-05-19 */
      }
    }
  };

  const imageViewerWhiteBgStyle = {
    modalStyle: {
      height: 1,
      width: 1,
      animationType: "none",
      maxHeight: "100%",
      noBackdrop: true,
      parentStyle: {
        zIndex: 1
      }
    },
    imageViewerStyle: {
      boxStyle: {
        viewStyle: {
          flex: 1
        },
        direction: "row"
      },
      contentWrapperStyle: {
        viewStyle: {
          flex: 2
        }
      },
      leftArrowIconStyleSM: {
        viewStyle: {
          alignSelf: "center",
          position: "absolute",
          left: 12,
          cursor: "pointer",
          zIndex: 1
        },
        image: images.previousIcon
      },
      leftArrowIconStyleMD: {
        viewStyle: {
          alignSelf: "center",
          position: "absolute",
          left: 30,
          cursor: "pointer",
          zIndex: 1
        },
        image: images.previousIcon
      },
      rightArrowIconStyleSM: {
        viewStyle: {
          alignSelf: "center",
          position: "absolute",
          right: 12,
          cursor: "pointer"
        },
        image: images.nextIcon
      },
      rightArrowIconStyleMD: {
        viewStyle: {
          alignSelf: "center",
          position: "absolute",
          right: 30,
          cursor: "pointer"
        },
        image: images.nextIcon
      },
      imageContainerStyle: {
        boxStyle: {
          viewStyle: {
            flex: 1
          },
          direction: "row"
        },
        imageViewConatinerStyle: {
          viewStyle: {
            flex: 1
          }
        }
      }
    }
  };

  return {
    imageBoxStyle,
    documentViewerStyle,
    imageViewerWhiteBgStyle,
    imageViewerStyle
  };
};
