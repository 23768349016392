import React from "react";

export default ({
  resolveMQ,
  getComponent,
  renderChildren,
  LoadingIndicator,
  theme: themeHoc = {},
  DecryptedImage
}) => {
  class ImageViewer extends React.Component {
    setImageViewerState = state => {
      const { index } = state;
      let { onIndexChange } = this.props;
      onIndexChange && onIndexChange({ index });
    };

    renderImageComponent = ({ value, ...props } = {}) => {
      return <DecryptedImage {...props} data={value} decryptionSourceProps={this.props.decryptionSourceProps} />;
    };

    render() {
      let { onClose, images = [], origin, fromShare, user, activeMQ, theme, index } = this.props;

      const { imageViewerStyle } = resolveMQ(theme || themeHoc, ["modalStyle", "imageViewerStyle"], activeMQ);
      const { renderHeader, renderFooter, renderImage } = resolveMQ(
        this.props,
        ["renderHeader", "renderFooter", "renderImage"],
        activeMQ
      );
      const renderProps = {
        origin,
        fromShare,
        onClose,
        user
      };
      let DocumentViewer = getComponent("documentViewer");

      return (
        <DocumentViewer
          theme={imageViewerStyle}
          values={images}
          index={index}
          state={{ index }}
          showVideoBar={fromShare}
          setState={this.setImageViewerState}
          imageRenderProps={{
            decryptionSourceProps: this.props.decryptionSourceProps,
            renderComponent: this.renderImageComponent
          }}
          loadingRender={() => <LoadingIndicator />}
          renderHeader={
            renderHeader
              ? props => {
                  let render = renderChildren(renderHeader, {
                    ...renderProps,
                    ...props
                  });
                  return (
                    render && {
                      viewStyle: { overflow: void 0 },
                      render
                    }
                  );
                }
              : void 0
          }
          renderFooter={
            renderFooter
              ? props => {
                  let render = renderChildren(renderFooter, {
                    ...renderProps,
                    ...props
                  });
                  return (
                    render && {
                      viewStyle: { overflow: void 0 },
                      render
                    }
                  );
                }
              : void 0
          }
          renderBody={
            renderImage
              ? props => {
                  return renderChildren(renderImage, {
                    ...renderProps,
                    ...props
                  });
                }
              : void 0
          }
        />
      );
    }
  }
  return ImageViewer;
};
