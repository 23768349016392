import React from "react";
import validDDDNumber from "./DDDNumberList";

export default ({ Editor, Box, theme, ErrorComponent, I18N, Platform, isValidEmail }) => {
  let { colors, bgs } = theme;
  const { errorColor } = colors;
  const { loginOtpBg } = bgs;

  const otpBoxTheme =
    Platform.OS === "web"
      ? {
          editorContainerStyle: {
            paddingTop: 16,
            paddingBottom: 15,
            paddingLeft: 20,
            borderRadius: 2.9,
            backgroundColor: loginOtpBg
          },
          errorEditorContainerStyle: {
            borderWidth: 1,
            borderColor: errorColor
          }
        }
      : {};

  const otpProps = {
    inputProps: {
      inputmode: "numeric",
      autoFocus: true,
      onKeyPress: evt => {
        let e = evt || window.event;
        let keyCode = e.keyCode || e.which;
        if (keyCode < 48 || keyCode > 57) {
          e.returnValue = false;
          e.preventDefault && e.preventDefault();
        }
        return true;
      }
    },
    modifySetValue:
      Platform.OS === "web"
        ? (props, setValue) => {
            let values = props.value.match(/\d+/g);
            props.value = values && values.length ? values.join("") : "";
            if (props.value.length <= 6) {
              setValue && setValue(props);
            }
          }
        : void 0
  };

  const pinProps = {
    inputProps: {
      inputmode: "numeric",
      autoFocus: true,
      securityEnabled: Platform.OS === "web" ? void 0 : true,
      onKeyPress: evt => {
        let e = evt || window.event;
        let keyCode = e.keyCode || e.which;
        if (keyCode < 48 || keyCode > 57) {
          e.returnValue = false;
          e.preventDefault && e.preventDefault();
        }
        return true;
      }
    },
    modifySetValue:
      Platform.OS === "web"
        ? (props, setValue) => {
            let values = props.value.match(/\d+/g);
            props.value = values && values.length ? values.join("") : "";
            if (props.value.length <= 6) {
              setValue && setValue(props);
            }
          }
        : void 0
  };

  const fieldInfos = {
    email: {
      type: "text",
      validation: data => {
        let { email } = data;
        if (!email) {
          return I18N.t("emailError");
        }
        if (!isValidEmail(email)) {
          return I18N.t("emailValidationError");
        }
      },
      inputProps: {
        maxLength: 55,
        autoFocus: true,
        autoCapitalize: "none",
        autoCompleteType: "off",
        textContentType: "none"
      }
    },
    mobile: {
      type: "phoneNumber",
      validation: data => {
        let { mobile } = data;
        if (!mobile) {
          return I18N.t("mobileMandatoryMessage");
        }
        if (typeof mobile === "number") {
          mobile = mobile.toString();
        }
        if (mobile.length !== 11) {
          return I18N.t("mobileCountInvalidMessage");
        }
        let firstTwoDigit = parseInt(mobile.substring(0, 2));
        if (validDDDNumber.indexOf(firstTwoDigit) === -1) {
          return I18N.t("mobileDDDinvalidMessage");
        }
        let thirdDigit = parseInt(mobile.charAt(2));
        if (thirdDigit !== 9) {
          return I18N.t("mobileStart9Message");
        }
      },
      inputProps: {
        maxLength: 15,
        autoFocus: true
      }
    },
    captchaVerify: {
      type: "reCaptcha",
      validation: data => {
        let { captchaVerify } = data;
        if (captchaVerify === "expired") {
          return I18N.t("captchaExpired");
        } else if (captchaVerify === undefined) {
          return I18N.t("verifyCaptcha");
        }
      }
    },
    otp: {
      type: Platform.OS === "web" ? "text" : "otpBox",
      validation: data => {
        if (!data.otp) {
          return I18N.t("otpMandatoryMessage");
        }
        if (data.otp.length !== 6) {
          return I18N.t("otpCountInvalidMessage");
        }
      },
      ...otpProps
    },
    pin: {
      type: Platform.OS === "web" ? "password" : "otpBox",
      validation: data => {
        if (!data.pin) {
          return I18N.t("pinMandatoryMessage");
        }
        if (data.pin.length !== 6) {
          return I18N.t("pinCountInvalidMessage");
        }
      },
      ...pinProps
    },
    oldPin: {
      type: Platform.OS === "web" ? "password" : "otpBox",
      validation: data => {
        if (!data.oldPin) {
          return I18N.t("pinMandatoryMessage");
        }
        if (data.oldPin.length !== 6) {
          return I18N.t("pinCountInvalidMessage");
        }
      },
      ...pinProps
    },
    newPin: {
      type: Platform.OS === "web" ? "password" : "otpBox",
      validation: data => {
        if (!data.newPin) {
          return I18N.t("pinMandatoryMessage");
        }
        if (data.newPin.length !== 6) {
          return I18N.t("pinCountInvalidMessage");
        }
      },
      ...pinProps
    },
    confirmPin: {
      type: Platform.OS === "web" ? "password" : "otpBox",
      validation: data => {
        if (!data.confirmPin) {
          return I18N.t("pinMandatoryMessage");
        }
        if (data.confirmPin.length !== 6) {
          return I18N.t("pinCountInvalidMessage");
        }
        if (data.newPin && data.newPin !== data.confirmPin) {
          return I18N.t("user/pin-mismatch");
        }
      },
      ...pinProps
    },
    documentId: {
      type: "number",
      inputProps: {
        maxLength: 15,
        inputmode: "numeric",
        autoFocus: true
      },
      modifySetValue: (props, setValue) => {
        if (props.value.toString().length <= 14) {
          setValue && setValue(props);
        }
      },
      validation: data => {
        if (!data.documentId) {
          return I18N.t("documentNumberMandatoryMessage");
        } else if (data.documentId.length < 11 || (data.documentId.length < 14 && data.documentId.length > 11)) {
          return I18N.t("documentNumberCountInvalidMessage");
        }
      }
    }
  };

  class LoginEditor extends React.Component {
    render() {
      let {
        loading,
        setValue,
        state = {},
        setState,
        onClick,
        field,
        icon,
        placeholder,
        placeholderTextColor,
        editable,
        editorTheme,
        inputStyle,
        activeMQ,
        inputProps: fieldInputProps,
        ...restProps
      } = this.props;
      let fieldInfo = fieldInfos[field];
      let { type, validation, inputProps, modifySetValue } = fieldInfo;
      let editorInputProps = {
        onSubmitEditing: onClick,
        onBlur: () => {},
        ...inputProps,
        ...fieldInputProps
      };
      if (editable !== undefined) {
        editorInputProps.editable = editable;
      }
      if (inputStyle !== undefined) {
        editorInputProps.style = inputStyle;
      }
      if (placeholder !== undefined && type !== "otpBox") {
        editorInputProps.placeholder = I18N.t(placeholder) || placeholder;
        if (placeholderTextColor !== undefined && Platform.OS !== "web") {
          editorInputProps.placeholderTextColor = placeholderTextColor;
        }
      }
      if (editorTheme === undefined) {
        editorTheme =
          (field === "otp" || field === "pin" || field === "oldPin" || field === "newPin" || field === "confirmPin") &&
          activeMQ.mq === "SM"
            ? otpBoxTheme
            : theme.loginEditorContainerStyle;
      }
      return (
        <Box
          render={
            <Editor
              {...restProps}
              setValue={props => {
                if (loading) {
                  return;
                }
                if (state.error) {
                  setState &&
                    setState({
                      error: void 0
                    });
                }
                let { validationData } = this.props;
                if (validationData && validationData[field]) {
                  validationData[field] = void 0;
                }
                if (setValue) {
                  if (modifySetValue) {
                    modifySetValue(props, setValue);
                  } else {
                    setValue(props);
                  }
                } else {
                  setState &&
                    setState({
                      [field]: props.value
                    });
                }
              }}
              error={state.error}
              column={{
                field,
                type,
                icon,
                validation,
                containerProps: {
                  theme: editorTheme,
                  inputProps: editorInputProps,
                  ErrorComponent
                }
              }}
            />
          }
        />
      );
    }
  }
  return LoginEditor;
};
