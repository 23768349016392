const sound0 = "/sounds/1.mp3";
const sound1 = "/sounds/2.mp3";
const sound2 = "/sounds/3.mp3";
const sound3 = "/sounds/4.mp3";

export default () => ({
  sound0,
  sound1,
  sound2,
  sound3
});
