import axios from "axios";
import FSComponentsHoc from "./components";
import uuid from "uuid/v4";
import SplashScreen from "./SplashScreen";
import {
  AppStore as AppStoreHoc,
  addListeners,
  removeListeners,
  ActiveMQ as ActiveMQHoc,
  ScreenWidth
} from "./components/appstore";
import { Platform, NavStatusBar as NavStatusBarHoc, AsyncStorage } from "react-components";
import { withContext, Modal, View } from "./components/client-app-boilerplate/lib/BasicComponents";
import AppComponents from "./components/client-app-boilerplate/lib/AppComponents";
import UserStoreHoc from "./components/appstore/lib/AUserStore";
import ToastHoc from "./components/toast";
import ConnectServicesCqrsHoc from "./components/ConnectServicesCqrs";
import { getDeviceInfo } from "./DeviceInfo";
import { getTimeStringFromSeconds } from "client-utility/lib/DateUtility";
import { isValidEmail } from "client-utility/lib/TextUtility";
import DeviceGalleryUtility from "./components/DeviceGalleryUtility";
import DeviceUtility from "./components/DeviceUtility";
import PrivacyToolHoc from "./PrivacyTool";

import {
  fetchUrl,
  postUrl,
  socketUrl,
  uploadUrl,
  userUrl,
  cqrs,
  firebaseAuth,
  firebaseUploadFile,
  firebaseConfig,
  pincodeSendUrl,
  pincodeResendUrl,
  pincodeVerifyUrl,
  authorizeUrl,
  forgotEmailUrl,
  proxyLoginEnabled,
  androidLoginClientSecret,
  androidLoginClientId,
  iosLoginClientSecret,
  iosLoginClientId,
  brandName,
  loginClientSecret,
  loginClientId,
  firestorePostUrl,
  urls,
  googleAppId,
  googleAndroidAppId,
  googleIosAppId,
  downloadZipURL,
  privacyConsentUrl,
  REGISTRY_HASHES,
  globalApp
} from "./FsCloudAppServices";
import { resolveValue, isJSONObject, getExportColumns, shallowCompare } from "app-utility-functions";
import { getUserMenus, onBackPress } from "./metadata/menus";
import { landingUrl } from "./metadata/nav";
import firebase from "./Firebase";
import ImagesHoc from "./../images/images";
import SoundHoc from "./../sounds/sounds";
import fonts from "./../theme/fonts";
import ColorAndBgs from "./../theme/colors";
import shadows from "./../theme/shadows";
import gradients from "./../theme/gradients";
import CommonThemeHoc from "./components/theme/lib/theme";
import { Spinner as SpinnerHoc } from "statusbar";
import FsCloudThemeHoc from "./../theme/theme";
import Swipeout from "table/lib/Swipeout";
import FormUtilHoc from "form/lib/FormUtil";
import UtilityHoc, {
  getToInsertMetadata,
  insertMetadataInSqlRecursively,
  updateMetadataInSqlite,
  _updateSqliteFromLocalChanges,
  getUploadedUris,
  getSqlExistStatusRecursively,
  typeCastSchema,
  isNetworkUrl,
  isPublicUrl,
  isEncrypted,
  getResourceNameFromUrl,
  cancelQueuedItemsRecursively
} from "./Utility";
import { FirebaseStoreClient } from "firebase-store-client";
import I18NHoc from "I18N";
import translations from "./translations";
import { mergeQueryOld } from "app-utility-functions";
import ConnectHelperHoc from "./ConnectHelper";
import {
  getQueuedAndInProgressDownloadFiles,
  getDownloadDataById,
  updateDownloadTable,
  DOWNLOAD_DATA_KEY_TYPES,
  UPLOAD_DATA_KEY_TYPES,
  dropTable,
  getQueuedAndInProgressUploadFiles,
  updateUploadTable,
  PAUSE_REASON_ENUM,
  insertSettingInformation,
  getSettingInfo as getSettingInfoSqlite,
  updateSettingInformation as updateSettingInformationSqlite,
  clearUploadAndDownloadQueue,
  getUploadedFiles,
  deleteAllContact,
  removeUploadItem,
  getFailedUploadIds,
  removeUploadByIds,
  deleteAllMetadata,
  deleteAllSyncedDirectories,
  getExistingMetadataIds,
  getMetadata,
  getUploadCount as getUploadCountSqlite,
  emptyTrash as emptyTrashSqlite,
  emptyNonUploaded as emptyNonUploadedSqlite,
  clearUploadAndDownloadInfoById,
  cancelQueuedUploads as cancelQueuedUploadsSqliite,
  cancelQueuedDownloads as cancelQueuedDownloadsSqliite,
  resetUploadQueueMemory as resetUploadQueueMemorySqlite,
  resetDownloadQueueMemory as resetDownloadQueueMemorySqlite,
  convertMetadataToDownloadData,
  clearUploadQueue,
  clearDownloadQueue,
  addEntryInDownloadTableAfterComplete
} from "./Sqlite";
import { renderProps, renderChildren } from "inject";
import Orientation from "./components/Orientation";
import ActionModalHoc from "./components/actions/lib/ActionModal";
import { resolveMQ as resolveMQHoc } from "style-utility";
import CacheManagerHoc from "./CacheManager";
import FSListHoc from "table/lib/FSList";
import FSListItemHoc from "table/lib/FSListItem";
import AnimatedListHoc from "./AnimatedList";
import AnimatedScrollViewHoc from "./AnimatedScrollView";
import ReactNativeDownloadHoc from "./ReactNativeDownload";
import DownloadListenerHoc from "./DownloadListenerHandler";
import ContactSyncHoc from "./ContactSync";
import BlockerStatusBarHoc from "./components/BlockerStatusBar";
import {
  METADATA_TABLE,
  DOWNLOAD_TOKENS,
  VIDEO_UNSUPPORTED,
  GALLERY_ALL_SORT,
  DOCS_ALL_SORT,
  MUSIC_ALL_SORT,
  UPLOAD_GALLERY_SORT,
  UPLOAD_DOCS_SORT,
  UPLOAD_MUSIC_SORT,
  GALLERY_ALL_LOCAL_CHANGE_KEY,
  DOCS_ALL_LOCAL_CHANGE_KEY,
  MUSIC_ALL_LOCAL_CHANGE_KEY,
  CONTACT_LAST_RESET_SETTING,
  CONTACT_LAST_SYNCED_DATE,
  CONTACT_SERVER_LAST_SYNCED_DATE,
  LAST_AUTO_SYNCED_FILE_TIME_IN_SEC,
  VAULT_GALLERY_LOCAL_CHANGE_KEY,
  VAULT_DOCS_LOCAL_CHANGE_KEY,
  SECURE_TYPES,
  NON_UPLOAD_LOCAL_CHANGE_KEY,
  UPLOAD_QUEUE_LOCAL_CHANGE_KEY,
  DOWNLOAD_QUEUE_LOCAL_CHANGE_KEY,
  CONNECTIONS_TABLE,
  DUPLICATE_GALLERY_SORT
} from "./Constants";
import FSLoginApiHoc from "./FSLoginAPI";
import SmsReader from "./SmsReader";
import AppConfig from "../config";
import orderBy from "lodash/orderBy";
import { createAndUpdateLogs, getLogPath, LOGS_FILE_NAME } from "./Logger";
import FirebaseAnalyticsUtilsHoc from "./FirebaseAnalyticsUtils";
import EncryptionUtilityHoc from "./EncryptionUtility";
import DownloadUtilityHoc from "./DownloadUtility";
import DeviceCacheUtilityHoc from "./DeviceCacheUtility";
import NativeUtilityHoc from "./NativeUtility";
import moment from "moment";
moment.locale("en"); // fix all to English. As we convert this using translation

const COMMON_VERSION = 3;

export const versionData = {
  appVersion: AppConfig.APP_VERSION,
  commonVersion: COMMON_VERSION,
  platform: Platform.OS
};

export const MAX_MOBILE_NETWORK_FILE_LIMIT = 15728640;

let DownloadListeners;

export const deviceInfo = getDeviceInfo();
export const deviceId = deviceInfo && deviceInfo.deviceId;
const navHeaderHeight = 60;
const tabBarHeight = 37;
let loginUrl = { uri: "/landing", ignoreDataChange: true };
let fsTrackId = uuid();
let viewRefs = {};

let lastUser = void 0;
let userSpaceData = void 0;
let userSessionTimer = void 0;
let lastUnlinkDevice_Id = void 0;
let afterLoginLink = void 0;
let joinInfo = void 0;
let deviceSnapshot;
let userSessionEnabled;
let webLogoutSnapshot;
let vaultMode = void 0;
let lastSharedOn = void 0;

let userContextInfo = void 0;
let routerContextInfo = void 0;
let _updateCacheFromLocalChanges = void 0;
let populate_interval;

let currentUploadProgress = void 0;
let currentDownloadProgress = void 0;

let encUserMap = {};
let galleryFolders = {};
let uriInfoMap = {};
let allUploadedData = [];
let fetchingGalleryFolders = false;
let refetchingGalleryFoldersRequired = false;
let fetchedSqliteData = void 0;
let metadataPopulatedForCurrentSession = void 0;
let lastServerUpdateTime = void 0;
let serverMetadataSyncStatus = void 0;
let settingInfo = {};

let uploadSnapshot = [];
let downloadSnapshot = [];
let localDataChangeListeners = {};
let listRowUpdateListeners = {};
let resourcestoUpdateDeviceId = [];
let contactSyncInProgress = false;
let contactSyncDescription = void 0;
let defaultContactSyncOnMessage = true; //true== for hide ,false==show progress

let logs = [`${JSON.stringify(new Date())} - App Started..${JSON.stringify(versionData)}`];
let isLogEnabled = void 0;

const MetadataTable = "metadata";
const IssuesTable = "issues";
const CollectionTable = "collections";
const CollectionItemsTable = "metadata";
const UserQuotaTable = "user_quota";
const GroupMembersTable = "group_members";
const UserTable = "users";
const DeviceTable = "device";
const ContactTable = "contact";
const AppVersionTable = "version";
const LogsTable = "user_logs";
const RemovedMetadataTable = "removed_metadata";

export const mappings = {
  jpg: "image",
  jpeg: "image",
  png: "image",
  bmp: "image",
  gif: "image",
  heic: "image",
  heif: "image",
  webp: "image",
  tiff: "image",
  tif: "image",
  wav: "audio",
  mp3: "audio",
  m4a: "audio",
  aac: "audio",
  aud: "audio",
  mpega: "audio",
  raw: "audio",
  ac3: "audio",
  aiff: "audio",
  aif: "audio",
  aifc: "audio",
  mid: "audio",
  mka: "audio",
  ogg: "audio",
  wma: "audio",
  flv: "video",
  mkv: "video",
  webm: "video",
  mp4: "video",
  "3gp": "video",
  wmv: "video",
  avi: "video",
  mov: "video",
  m4v: "video",
  mpg: "video",
  mpeg: "video",
  swf: "video",
  doc: "doc",
  docx: "doc",
  xls: "doc",
  xlsx: "doc",
  ppt: "doc",
  pptx: "doc",
  csv: "doc",
  pdf: "doc",
  txt: "doc",
  odt: "doc",
  ods: "doc",
  odp: "doc",
  psd: "doc",
  html: "doc",
  htm: "doc",
  xml: "doc",
  xsd: "doc"
};

const oiFirebaseIosConfig = {
  "apiKey": "AIzaSyA6cr-B1TZ5hOpSdbD_DZzOzybwoabFBQM",
  "appId": "1:975019086999:ios:3518cf5824bdb330",
  "authDomain": "fs-cloud-oi.firebaseapp.com",
  "databaseURL": "https://fs-cloud-oi.firebaseio.com",
  "measurementId": "G-TSTDKR2TXG",
  "messagingSenderId": "975019086999",
  "projectId": "fs-cloud-oi",
  "storageBucket": "fs-cloud-oi.appspot.com"
};

const oiFirebaseAndroidConfig = {
  "apiKey": "AIzaSyCFPSVYSMQ_FeGjYVsgmaSsqdlQsDcQ_lI",
  "appId": "1:975019086999:android:7cdce7cb3c672668",
  "authDomain": "fs-cloud-oi.firebaseapp.com",
  "databaseURL": "https://fs-cloud-oi.firebaseio.com",
  "measurementId": "G-TSTDKR2TXG",
  "messagingSenderId": "975019086999",
  "projectId": "fs-cloud-oi",
  "storageBucket": "fs-cloud-oi.appspot.com"
};

const timFirebaseIosConfig = {
  "apiKey": "AIzaSyDzIY-JNXhB_hHHHbDOrCokORNPthykzBQ",
  "appId": "1:152113248378:ios:d00eaff5db8fdee1",
  "authDomain": "fs-cloud-tim-248615.firebaseapp.com",
  "databaseURL": "https://fs-cloud-tim-248615.firebaseio.com",
  "measurementId": "G-WZT957NPB0",
  "messagingSenderId": "152113248378",
  "projectId": "fs-cloud-tim-248615",
  "storageBucket": "fs-cloud-tim-248615.appspot.com"
};

const timFirebaseAndroidConfig = {
  "apiKey": "AIzaSyCbSC5JRil2PG2svn5Abom3eBevahyle4E",
  "appId": "1:152113248378:android:d00eaff5db8fdee1",
  "authDomain": "fs-cloud-tim-248615.firebaseapp.com",
  "databaseURL": "https://fs-cloud-tim-248615.firebaseio.com",
  "measurementId": "G-WZT957NPB0",
  "messagingSenderId": "152113248378",
  "projectId": "fs-cloud-tim-248615",
  "storageBucket": "fs-cloud-tim-248615.appspot.com"
};

const configOi = Platform.OS === "android" ? oiFirebaseAndroidConfig : oiFirebaseIosConfig;
const configTim = Platform.OS === "android" ? timFirebaseAndroidConfig : timFirebaseIosConfig;
if (Platform.OS !== "web") {
  firebase.initializeApp(configOi, "SECONDARY_APP");
  firebase.initializeApp(configTim, "TERTIARY_APP");
}
if (Platform.OS === "web") {
  const heroFirebaseConfig = {
    "apiKey": "AIzaSyAIVdfzXsFyKueeX-I6jNqZh7bzJxw550w",
    "appId": "1:911089821999:web:13e21f294464ae91",
    "authDomain": "fs-cloud-hero.firebaseapp.com",
    "databaseURL": "https://fs-cloud-hero.firebaseio.com",
    "measurementId": "G-P5EBEGLF6V",
    "messagingSenderId": "911089821999",
    "projectId": "fs-cloud-hero",
    "storageBucket": "fs-cloud-hero.appspot.com"
  };

  const oiFirebaseConfig = {
    "apiKey": "AIzaSyCFPSVYSMQ_FeGjYVsgmaSsqdlQsDcQ_lI",
    "appId": "1:975019086999:web:545dfc165b29c896",
    "authDomain": "fs-cloud-oi.firebaseapp.com",
    "databaseURL": "https://fs-cloud-oi.firebaseio.com",
    "measurementId": "G-TSTDKR2TXG",
    "messagingSenderId": "975019086999",
    "projectId": "fs-cloud-oi",
    "storageBucket": "fs-cloud-oi.appspot.com"
  };

  const timFirebaseConfig = {
    "apiKey": "AIzaSyCbSC5JRil2PG2svn5Abom3eBevahyle4E",
    "appId": "1:152113248378:web:6dd7537b8434ef99",
    "authDomain": "fs-cloud-tim-248615.firebaseapp.com",
    "databaseURL": "https://fs-cloud-tim-248615.firebaseio.com",
    "measurementId": "G-WZT957NPB0",
    "messagingSenderId": "152113248378",
    "projectId": "fs-cloud-tim-248615",
    "storageBucket": "fs-cloud-tim-248615.appspot.com"
  };

  firebase.initializeApp(heroFirebaseConfig);
  firebase.initializeApp(oiFirebaseConfig, "SECONDARY_APP");
  firebase.initializeApp(timFirebaseConfig, "TERTIARY_APP");
}

const firebaseInstance = () => {
  return firebase.app(globalApp);
};

const firebaseDB = () => firebaseInstance() && firebaseInstance().firestore();
const {
  logFirebaseAnalyticsEvent,
  setFirebaseAnalyticsUser,
  logFirebaseAnalyticsScreenEvent,
  logUploadAnalytics,
  logDownloadAnalytics,
  logSearchAnalytics
} = FirebaseAnalyticsUtilsHoc({ firebaseInstance, configOi, Platform, SECURE_TYPES });

/**
 * @description used to provide token of current authenticated user.
 */
const getToken = async () => {
  const currentUser = firebaseInstance().auth().currentUser;
  if (currentUser) {
    let token = await currentUser.getIdToken();
    return token;
  }
};

export const getUser = () => lastUser;

const messagesToCheck = {
  "User does not exist": "userNotExist",
  "User/firebaseUid not found": "userNotFound",
  "Client not found": "clientNotFound",
  "Custom Attributes not found": "customAttributesNotFound"
};

const resetPrivacyConsents = async () => {
  for (let hash in REGISTRY_HASHES) {
    try {
      await AsyncStorage.removeItem(`${hash}_consent_added`);
    } catch (err) {}
  }
};

const resetStorage = async () => {
  resetMemoryData();
  clearSharedPreference && clearSharedPreference();
  await AsyncStorage.removeItem("populateSqliteMetadataAgain"); // only in iOS - refill Metadata SQLite
  await AsyncStorage.removeItem("lastSyncedRecord");
  await AsyncStorage.removeItem("lastSyncedRemovedMetadataRecord");
  await AsyncStorage.removeItem("lastDuplicateDoc");
  await AsyncStorage.removeItem("initialAutoBackupCompleted");
  await AsyncStorage.removeItem(LAST_AUTO_SYNCED_FILE_TIME_IN_SEC);
  await AsyncStorage.removeItem(CONTACT_LAST_RESET_SETTING);
  await AsyncStorage.removeItem(CONTACT_LAST_SYNCED_DATE);
  await AsyncStorage.removeItem(CONTACT_SERVER_LAST_SYNCED_DATE);
  await resetSqlite();
};

/**
 * @description used to parsed error message
 * @param {*} err
 * @return {String} provide translated message
 */
export const getErrorMessage = err => {
  if (typeof err.message === "string") {
    try {
      let parsedError = JSON.parse(err.message);
      if (parsedError && typeof parsedError === "object") {
        err = parsedError;
      }
    } catch (e) {
      // try to parse error
    }
  }
  if (err.message) {
    if (err.message.indexOf("network error") >= 0 || err.message.indexOf("Network Error") >= 0) {
      err.code = "no_internet";
    }
    if (err.message.indexOf("Link expired") !== -1) {
      err.code = "user/link-expired";
    }
  }
  let message = err.code ? I18N.t(err.code.toString()) : void 0;
  if (err.message && messagesToCheck[err.message]) {
    message = I18N.t(messagesToCheck[err.message]);
  }
  if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
    message = err.message;
  }
  return message;
};

/**
 * @description check if user is using any mobile or not.
 */
const detectMob = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getDesktopUrl = () => {
  if (Platform.OS !== "web") {
    return;
  }
  let os = null,
    url;
  if (navigator.appVersion.indexOf("Win") !== -1) os = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) os = "MacOS";

  if (os === "Windows") {
    url = AppConfig.APP_WINDOWS_DOWNLOAD_LINK;
  } else if (os === "MacOS") {
    url = AppConfig.APP_MAC_DOWNLOAD_LINK;
  }
  return url;
};

const breakPoints =
  Platform.OS !== "web" || detectMob()
    ? [{ minWidth: 200, mq: "SM" }]
    : [
        { minWidth: 200, mq: "SM" },
        { minWidth: 550, mq: "MD" },
        { minWidth: 900, mq: "LG" }
      ];

/**
 * @description Persist the user logs
 */
const persistLog = () => {
  let logsToPersist = logs.join("\n");
  logs = [];
  //persist log here
  createAndUpdateLogs(logsToPersist);
};

/**
 * @description Add log in persist logs.
 */
export const pushLog = (log, persist) => {
  logs.push(`${JSON.stringify(new Date())} - ${log}`);
  if (persist || logs.length >= 20) {
    persistLog();
  }
};

export const addLog = (log, persist) => {
  if (!isLogEnabled) {
    return;
  }
  pushLog(log, persist);
};

const resolveMQ = resolveMQHoc({ breakPoints });
const ActiveMQ = ActiveMQHoc({ breakPoints, renderProps });
const I18N = I18NHoc({ fallbacks: true, translations, defaultLanguage: "pt" });

let images = ImagesHoc({ resolveImage: ActiveMQ.resolveImage });
const { colors, bgs } = ColorAndBgs;

const FormUtil = FormUtilHoc();
const { getMandatoryMessage, getSortValue, mergeTwoSortedArrays, modifyUrls, formatLocalDataToResourceData } =
  UtilityHoc({
    urls,
    getToken,
    MetadataTable,
    CollectionTable,
    mappings,
    getUser,
    deviceId
  });

export const getSettingInfo = () => {
  return settingInfo;
};

/**
 * @description Check if metadata is populated for current session or not.
 * Based on this value, data is fetched from sqlite or firebase database in mobile for metadata table.
 * Also the value is retained across session, ie, if on initial opening of app, data was coming from firebase then for whole session,
 * data will be fetched from firebase only irrespective of data population in sqlite.
 */
export const isMetadataPopulatedForCurrentSession = async () => {
  if (Platform.OS === "web") {
    return false;
  }
  try {
    if (metadataPopulatedForCurrentSession === undefined) {
      let dataFetchedVariableHandled = await AsyncStorage.getItem("dataFetchedVariableHandled");
      if (!dataFetchedVariableHandled) {
        metadataPopulatedForCurrentSession = false;
      } else {
        const { dataFetchedForSync } = getSettingInfo();
        metadataPopulatedForCurrentSession = dataFetchedForSync;
      }
    }
    return metadataPopulatedForCurrentSession;
  } catch (err) {
    return false;
  }
};

export const setVaultMode = value => {
  vaultMode = value;
};

export const getSecureType = () => (vaultMode ? SECURE_TYPES.VAULT : SECURE_TYPES.DEFAULT);
const find = obj => {
  const { find } = FirebaseStoreClient(firebaseDB(), {
    Platform,
    getToken,
    firestorePostUrl: firestorePostUrl,
    modifyUrls,
    addLog,
    isMetadataPopulatedForCurrentSession,
    getMetadata
  });
  return find(obj);
};

const firebaseSave = obj => {
  const { save } = FirebaseStoreClient(firebaseDB(), {
    Platform,
    getToken,
    firestorePostUrl: firestorePostUrl,
    modifyUrls,
    addLog,
    isMetadataPopulatedForCurrentSession,
    getMetadata
  });

  return save(obj);
};

export const addLogsOnServer = async (log, moduleName) => {
  try {
    if (!deviceId || !lastUser) {
      return;
    }
    let insert = {
      device: deviceId,
      log,
      moduleName,
      user: {
        firebaseUid: lastUser.uid
      },
      _createdOn: new Date()
    };
    await firebaseSave({
      table: LogsTable,
      insert
    });
  } catch (err) {}
};

/**
 * @description Fetch and returns user device info from firebase DB.
 */
export const getUserDeviceTableInfo = async () => {
  if (!deviceId || !lastUser) {
    return;
  }
  return firebaseDB()
    .collection(DeviceTable)
    .where("user.firebaseUid", "==", lastUser.uid)
    .where("device", "==", deviceId)
    .limit(1)
    .get()
    .then(queryResult => {
      let result = [];
      if (queryResult.exists) {
        result.push({ _id: queryResult.id, ...queryResult.data() });
      } else {
        queryResult.empty === false &&
          queryResult.forEach(e => {
            result.push({ _id: e.id, ...e.data() });
          });
      }
      return result[0];
    });
};

/**
 * @description Returns the default user device settings info.
 */
export const getUserDeviceTableSettingInfo = async () => {
  let userDeviceTableInfo = {};
  // try {
  //   userDeviceTableInfo = (await getUserDeviceTableInfo()) || {};
  // } catch (err) {}
  let {
    roamingEnabled = false,
    troubleshootEnabled = false,
    contactSync = false,
    networkMode = "WiFi",
    uploadEnabled = false,
    folders = "",
    allowLargeFilesOnWifiOnly = true
  } = userDeviceTableInfo;
  return {
    roamingEnabled,
    troubleshootEnabled,
    contactSync,
    networkMode,
    uploadEnabled,
    userSynced: false,
    dataFetchedForSync: false,
    folders,
    allowLargeFilesOnWifiOnly
  };
};

/**
 * @description Updates the user device settings in device table of firebase DB.
 * @param {*} updates
 */
export const updateSettingOnServer = async updates => {
  updates = {
    ...updates
  };
  const { folders } = updates || {};
  if (folders && typeof folders == "object") {
    let foldersToUpdate = {};
    for (let key in folders) {
      if (folders[key] && folders[key].sync) {
        foldersToUpdate[key] = folders[key];
      }
    }
    updates.folders = foldersToUpdate;
  }
  try {
    let userDeviceTableInfo = await getUserDeviceTableInfo();
    if (!userDeviceTableInfo || !userDeviceTableInfo._id) {
      return;
    }
    await firebaseSave({
      table: DeviceTable,
      filter: { _id: userDeviceTableInfo._id },
      updates
    });
  } catch (err) {
    console.warn("!!!!error in update setting on server >>>>", err.message);
  }
};

const CommonTheme = CommonThemeHoc({
  fonts,
  images,
  colors,
  bgs,
  gradients,
  shadows,
  Platform,
  I18N,
  navStyle: {
    flex: 1,
    backgroundColor: colors.highlightColor
  }
});

const fsCloudTheme = FsCloudThemeHoc({
  fonts,
  images,
  colors,
  bgs,
  gradients,
  shadows,
  Platform,
  I18N
});

const theme = {
  ...CommonTheme,
  ...fsCloudTheme,
  colors,
  bgs,
  gradients,
  shadows,
  fonts,
  images,
  breakPoints,
  listContentContainerStyle: { paddingTop: navHeaderHeight + tabBarHeight }
};
theme.visibleView = {
  visibleView: 1
};

const { show: showMessage, showError, Toast } = ToastHoc({ theme: theme.toastStyle });
const NavStatusBar = NavStatusBarHoc({ theme: theme.navStatusBarStyle });

export const uploadErrorCall = async data => {
  let result = await urlFetch({
    url: urls["uploadError"],
    props: {
      data
    }
  });
  return result;
};

/**
 * @description Reset the memory data.
 */
const resetMemoryData = () => {
  resourcestoUpdateDeviceId = [];
  allUploadedData = [];
  galleryFolders = {};
  fetchedSqliteData = void 0;
  fetchingGalleryFolders = false;
  refetchingGalleryFoldersRequired = false;
  contactSyncInProgress = false;
  contactSyncDescription = void 0;
  metadataPopulatedForCurrentSession = void 0;
  serverMetadataSyncStatus = void 0;
};

export const ensureSettingInfo = async () => {
  insertSettingInformation && (await insertSettingInformation());
  settingInfo = (await getSettingInfoSqlite()) || {};
  console.warn("settingInfo: ", settingInfo);
};

/**
 * @description Used to update device settings on server and SQlite.
 * and call listener on change setting.
 */
export const updateSettingInformation = async (updates, errorCallback, fireSettingChangeListener) => {
  let oldSettingInfo = getSettingInfo();
  settingInfo = { ...oldSettingInfo, ...updates };
  try {
    await updateSettingInformationSqlite(updates);
    updateSettingOnServer(updates);
  } catch (err) {
    pushLog(`Error in updateSettingInformation >>>> ${err.message}`);
    showMessage && showMessage(err.message, 3000);
    settingInfo = oldSettingInfo;
    errorCallback && errorCallback(err);
    return;
  }
  if (fireSettingChangeListener && DownloadListeners) {
    const { appSettingChangeListener } = DownloadListeners;
    updates.oldBatteryLimit = oldSettingInfo.batteryLimit;
    appSettingChangeListener && appSettingChangeListener(updates);
  }
};

export const isFetchUploadCompleted = () => fetchedSqliteData === "completed";

/**
 * @description This will wait untill the data has been fetched from sqlite to app's memory.
 */
export const waitForUploadedData = () => {
  if (Platform.OS === "web" || fetchedSqliteData === "completed") {
    return;
  }
  return new Promise(resolve => {
    let interval = setInterval(_ => {
      if (fetchedSqliteData === "completed") {
        clearInterval(interval);
        resolve();
      }
    }, 200);
  });
};

/**
 * @description Used to set warning ref in viewRefs.
 * @param {Class} ref -> class reference of AppWarning
 */
export const setWarningRef = ref => {
  viewRefs["warning"] = ref;
};

/**
 * @description Used to get warning ref from viewRefs.
 */
export const getWarningRef = () => viewRefs["warning"];

/**
 * @description Used to load warning on user change.
 * @param {*} user
 * @param {*} oldUser
 */
const onFSUserUpdate = (user, oldUser = {}) => {
  enableLogs(user && user.errorLogs);
  let warningRef = getWarningRef();
  let { loadWarnings } = warningRef || {};
  if (!loadWarnings) {
    return;
  }
  let { suspended, rollbackOn, suspendedOn } = user;
  let { suspended: oldSuspended, rollbackOn: oldRollbackOn } = oldUser;
  if ((!oldSuspended && suspended && suspendedOn) || (rollbackOn && !oldRollbackOn)) {
    loadWarnings();
  }
};

const enableLogs = enable => {
  isLogEnabled = enable;
  addLog(`Logs Enabled >>>> ${enable}`);
};

const addLocalDataChangeListener = ({ key, callback }) => {
  localDataChangeListeners = localDataChangeListeners || {};
  localDataChangeListeners[key] = localDataChangeListeners[key] || [];
  localDataChangeListeners[key].push(callback);
};

const removeLocalDataChangeListener = ({ key, callback }) => {
  let keyListeners = localDataChangeListeners && localDataChangeListeners[key];
  let index = keyListeners && keyListeners.indexOf(callback);
  if (index >= 0) {
    keyListeners.splice(index, 1);
  }
};

const fireLocalDataChangeListenerForKey = ({ key, localChanges }) => {
  let keyListeners = localDataChangeListeners && localDataChangeListeners[key];
  console.log("100101010101010", keyListeners);
  keyListeners &&
    keyListeners.forEach(listener => {
      console.log("before", { key, localChanges });
      return listener({ key, localChanges });
    });
  console.log("after", { key, localChanges });
  if (_updateCacheFromLocalChanges) {
    _updateCacheFromLocalChanges({ key, localChanges });
  }
};

const fireLocalDataChangeListener = async ({ key, localChanges, updateSqlite }) => {
  try {
    // console.log("{key, localChanges, updateSqlite}", "!23", key, "!2355", localChanges, updateSqlite);
    if (localChanges["remove"] === true) return;
    if (Array.isArray(key)) {
      for (let k of key) {
        fireLocalDataChangeListenerForKey({ key: k, localChanges });
      }
    } else {
      fireLocalDataChangeListenerForKey({ key, localChanges });
    }
    if (updateSqlite && Platform.OS !== "web") {
      await _updateSqliteFromLocalChanges({ localChanges });
    }
  } catch (error) {
    console.warn("fireLocalDataChangeListener error: ", error);
  }
};

const addListRowUpdateListener = ({ _id, callback }) => {
  listRowUpdateListeners = listRowUpdateListeners || {};
  listRowUpdateListeners[_id] = listRowUpdateListeners[_id] || [];
  listRowUpdateListeners[_id].push(callback);
};

const removeListRowUpdateListener = ({ _id, callback }) => {
  if (!listRowUpdateListeners) {
    return;
  }
  let appliedListeners = listRowUpdateListeners[_id];
  if (!appliedListeners) {
    return;
  }
  let index = appliedListeners.indexOf(callback);
  if (index >= 0) {
    appliedListeners.splice(index, 1);
  }
};

const fireListRowUpdateListener = ({ _id, changes }) => {
  let appliedListeners = listRowUpdateListeners && listRowUpdateListeners[_id];
  appliedListeners && appliedListeners.forEach(listener => listener({ _id, changes }));
};

export const isPendingBlocker = () => {
  let pendingBlockerRef = viewRefs["pendingBlocker"];
  return pendingBlockerRef && pendingBlockerRef.isPending && pendingBlockerRef.isPending();
};

/**
 * @description Used to set StartUpWrapper ref in viewRefs.
 * @param {Class} ref -> class reference of StartUpWrapper.js.
 */
export const setStartUpWrapperRef = ref => {
  viewRefs["StartUpWrapper"] = ref;
};

/**
 * @description Used to get StartUpWrapper ref from viewRefs.
 */
export const getStartUpWrapperRef = () => viewRefs["StartUpWrapper"];

/**
 * @description Used to set contact ref in viewRefs.
 * @param {Class} ref -> class reference of ContactsViewSM.
 */
export const setContactRef = ref => {
  viewRefs["contact"] = ref;
};

/**
 * @description Used to get contact ref from viewRefs.
 */
export const getContactRef = () => viewRefs["contact"];

/**
 * @description Used to set gallery folder ref in viewRefs.
 * @param {Class} ref -> class reference of UploadGalleryList.native.
 */
export const setGalleryFolderRef = ref => {
  viewRefs["galleryFolders"] = ref;
};

/**
 * @description Used to set share action ref in viewRefs.
 * @param {Class} ref -> class reference of AppShare.
 */
export const setShareActionRef = ref => {
  viewRefs["shareAction"] = ref;
};

/**
 * @description Used to set download action ref in viewRefs.
 * @param {Class} ref -> class reference of AppDownload.
 */
export const setDownloadActionRef = ref => {
  viewRefs["downloadAction"] = ref;
};

/**
 * @description Used to set upload action ref in viewRefs.
 * @param {Class} ref -> class reference of AppUpload.
 */
export const setUploadActionRef = ref => {
  viewRefs["uploadAction"] = ref;
};

export const setUploadFromCameraRef = ref => {
  viewRefs["uploadFromCamera"] = ref;
};

/**
 * @description Used to set user space ref in viewRefs.
 * @param {Class} ref -> class reference of DrawerMenuHeader.
 */
export const setUserSpaceRef = ref => {
  viewRefs["userSpace"] = ref;
};

/**
 * @description Used to set user space information.
 */
const setUserSpaceInfo = props => {
  let userSpaceRef = viewRefs["userSpace"];
  userSpaceRef && userSpaceRef.setInfo && userSpaceRef.setInfo(props);
};

/**
 * @description Used to invoke share action.
 */
export const invokeShareAction = props => {
  let shareActionRef = viewRefs["shareAction"];
  shareActionRef && shareActionRef.doAction(props);
};

/**
 * @description Used to invoke download action.
 */
export const invokeDownloadAction = props => {
  let downloadActionRef = viewRefs["downloadAction"];
  downloadActionRef && downloadActionRef.doAction(props);
};

/**
 * @description Used to invoke downloadZip action.
 */
export const invokeDownloadZip = props => {
  let downloadActionRef = viewRefs["downloadAction"];
  downloadActionRef && downloadActionRef.downloadZip(props);
};

export const invokeAddImageToPdfGen = props => {
  let docScannerRef = viewRefs["docScanner"];
  docScannerRef && docScannerRef.moveIntoPdfGen(props);
};

/**
 * @description Used to invoke upload action.
 */
export const invokeUploadAction = props => {
  let uploadActionRef = viewRefs["uploadAction"];
  return uploadActionRef && uploadActionRef.uploadItem(props);
};

export const invokeUploadFromCamera = props => {
  let uploadRef = viewRefs["uploadFromCamera"];
  return uploadRef && uploadRef.uploadItem(props);
};

/**
 * @description Used to invoke pending blocker action.
 */
export const onPendingBlocker = pending => {
  let pendingBlockerRef = viewRefs["pendingBlocker"];
  pendingBlockerRef && pendingBlockerRef.onPending(pending);
};

/**
 * @description Used to set pending blocker ref in viewRefs.
 * @param {Class} ref -> class reference of BlockerStatusBar.
 */
export const getPendingBlockerRef = ref => {
  viewRefs["pendingBlocker"] = ref;
};

/**
 * @description Used to set upload card ref in viewRefs.
 * @param {Class} ref -> class reference of HighlightUploadCard.
 */
export const setUploadCardRef = ref => {
  viewRefs["uploadCard"] = ref;
};

/**
 * @description Used to get upload card ref from viewRefs.
 */
export const getUploadCardRef = () => viewRefs["uploadCard"];

export const setDocScannerRef = ref => {
  viewRefs["docScanner"] = ref;
};

export const getDocScannerRef = () => viewRefs["docScanner"];

/**
 * @description Used to set download card ref in viewRefs.
 * @param {Class} ref -> class reference of HighlightDownloadCard.
 */
export const setDownloadCardRef = ref => {
  viewRefs["downloadCard"] = ref;
};

/**
 * @description Used to get download card ref from viewRefs.
 */
export const getDownloadCardRef = () => viewRefs["downloadCard"];

/**
 * @description Used to set last share time.
 * @param {Boolean} value
 */
export const setShareProceessing = value => {
  lastSharedOn = value ? new Date().getTime() : void 0;
};

/**
 * @description Used to check if share is processing or not.
 */
export const isShareProcessing = () => {
  if (lastSharedOn && new Date().getTime() - lastSharedOn < 10 * 60 * 1000) {
    return true;
  }
};

/**
 * @description Used to show message on connection change
 * @param {Boolean} isConnected
 */
export const onConnectionChange = isConnected => {
  if (!isConnected) {
    if (Platform.OS !== "web" && isShareProcessing()) {
      setTimeout(_ => {
        if (isShareProcessing()) {
          setShareProceessing(false);
          showMessage && showMessage(I18N.t("shareNetworkErrorMessage"), 2000);
        }
      }, 1000);
    }
  }
};

/**
 * @description Used to fetch Encryption Key and IV from server
 * @param {*} reqData
 */
const fetchEncryptionKeyAndIVFromServer = async (reqData = {}) => {
  let invoke = ConnectAppServices.invoke;
  const service = {
    service: {
      url: urls["fetchEncKeyIV"],
      uriProps: {
        data: reqData
      }
    },
    allowParallelInvoke: true
  };

  const { EC: fetchedEncKey } = await invoke(service);
  let key = fetchedEncKey;
  let iv = fetchedEncKey && fetchedEncKey.substring(0, 16);
  return { key, iv };
};

/**
 * @description Used to get encryption key and IV
 * @param {*} params
 */
export const getEncryptionKeyAndIV = async (params = {}) => {
  let { firebaseUid, collectionId, groupId, sharedToken } = params;
  let lastUser = getUser && getUser() && getUser().uid;
  firebaseUid = firebaseUid || lastUser;
  if (!firebaseUid) {
    throw new Error(I18N.t("userNotFound"));
  }
  const encKeyIV = encUserMap && encUserMap[firebaseUid];
  if (encKeyIV) {
    return encKeyIV;
  }

  let reqData = {};
  if (sharedToken) {
    reqData = { firebaseUid, token: sharedToken };
  } else if (groupId) {
    reqData = { firebaseUid, groupId };
  } else if (collectionId) {
    reqData = { firebaseUid, collectionId };
  } else if (firebaseUid !== lastUser) {
    throw new Error(I18N.t("userNotAuthorized"));
  }

  let { key, iv } = (await fetchEncryptionKeyAndIVFromServer(reqData)) || {};
  if (!key || !iv) {
    throw new Error(I18N.t("encryptionKeyError"));
  }
  encUserMap[firebaseUid] = { key, iv };
  return { key, iv };
};

/**
 *@description Used to get decryption props to fetch key and iv from server
 * @param {*} data
 * @param {*} {sharedToken, decryptionSource}
 * @returns {*} decryption props.
 */
export const getDecryptionProps = (data = {}, { sharedToken, decryptionSource } = {}) => {
  // console.log("@@@@ get decryptionprops", data, sharedToken, decryptionSource)
  const { group, _createdBy, encrypted, collection, resource_lastModified } = data;
  if (!encrypted) {
    return;
  }
  let decryptionProps = { encrypted, firebaseUid: _createdBy && _createdBy.firebaseUid, resource_lastModified };

  if (sharedToken) {
    decryptionProps["sharedToken"] = sharedToken;
  } else if (group && group._id && decryptionSource === "group") {
    decryptionProps["groupId"] = group._id;
  } else if (collection && collection._id && decryptionSource === "collection") {
    decryptionProps["collectionId"] = collection._id;
  }
  return decryptionProps;
};

const ReactNativeDownload = ReactNativeDownloadHoc({
  updateDownloadTable,
  getQueuedAndInProgressDownloadFiles,
  getDownloadDataById,
  PAUSE_REASON_ENUM,
  DOWNLOAD_DATA_KEY_TYPES,
  getQueuedAndInProgressUploadFiles,
  UPLOAD_DATA_KEY_TYPES,
  updateUploadTable,
  uploadUrl,
  urls,
  firebase: firebaseInstance,
  firebaseLib: firebase,
  getSettingInfo,
  showMessage,
  getUser,
  uploadRequest: props => {
    return ConnectAppServices && ConnectAppServices.uploadRequest(props);
  },
  uploadErrorCall,
  removeUploadItem,
  theme: {
    images: {
      docIcon: images["highlightDocIcon"],
      musicIcon: images["highlightMusicIcon"]
    },
    colors: { themeColor: colors.themeColor },
    versionData
  },
  addLog,
  brandName,
  getEncryptionKeyAndIV,
  getDecryptionProps
});

let {
  isFileExists,
  stopServices,
  clearSharedPreference,
  scheduleImageDetectingService,
  scheduleAutoSyncService,
  onAssetChange,
  decryptAndDownload: getBase64Image,
  showPowerSaverNotification,
  getFileUriFromContentUri,
  refreshUploadNotification,
  refreshDownloadNotification,
  addPrivacyConsentAndroid
} = ReactNativeDownload;

let { encryptFile, decryptFile, encryptDecryptWeb, cancelEncryptDecryptWeb, cancelDecryption, releaseAllObjectUrls } =
  EncryptionUtilityHoc({
    getEncryptionKeyAndIV,
    Platform,
    getBase64Image,
    isNetworkUrl,
    I18N
  });

let { downloadFile, DOWNLOAD_UTILS } = DownloadUtilityHoc({
  encryptDecryptWeb,
  getEncryptionKeyAndIV,
  isPublicUrl,
  isNetworkUrl,
  getDecryptionProps,
  decryptFile
});

let {
  validateResizeInfo,
  getCachePath,
  clearCache,
  updateCachePath,
  updateCacheTime,
  getCacheFileName,
  getTotalCacheSize,
  updateCacheErrorEntries
} = DeviceCacheUtilityHoc({
  getResourceNameFromUrl,
  I18N,
  showMessage,
  getUser,
  versionData,
  firebaseSave
});

/**
 * @description Used to clear Memory Data
 * this was required as in android if we had background service running for upload,
 * then even though the app was unmount but memory of the application was being retained.
 */
export const clearMemoryData = () => {
  metadataPopulatedForCurrentSession = void 0;
  serverMetadataSyncStatus = void 0;
  releaseAllObjectUrls && releaseAllObjectUrls();
};

const toDeleteLocalUris = {};
let deleteLocalUriTimer = void 0;

/**
 * @description Use to unset local Uris.
 */
const unsetLocalUris = (props = {}) => {
  let { path } = props;
  if (!path) {
    return;
  }
  toDeleteLocalUris[path] = props;
  if (deleteLocalUriTimer) {
    return;
  }
  deleteLocalUriTimer = setTimeout(async () => {
    for (let key in toDeleteLocalUris) {
      let info = toDeleteLocalUris[key];
      try {
        if (!(await isFileExists(key))) {
          await clearUploadAndDownloadInfoById(info._id);
        }
        delete toDeleteLocalUris[key];
      } catch (err) {}
    }
    clearTimeout(deleteLocalUriTimer);
    deleteLocalUriTimer = void 0;
  }, 30 * 1000);
};

/**
 * @description Used to schedule auto Backup.
 */
export const scheduleAutoBackup = async () => {
  if (Platform.OS === "web") {
    return;
  }
  pushLog && pushLog("scheduleAutoBackup called..", true);
  await waitForUploadedData();
  if (Platform.OS === "android") {
    let { uploadEnabled, folders, scheduleInterval, scheduleStartFrom } = getSettingInfo();
    if (uploadEnabled && folders) {
      if (typeof folders === "object") {
        const syncedUris = [];
        const folderUris = Object.keys(folders);
        folderUris.forEach(uri => {
          if (folders[uri]["sync"]) {
            syncedUris.push(uri);
          }
        });
        scheduleInterval = Number(scheduleInterval) || 0;
        logFirebaseAnalyticsEvent &&
          logFirebaseAnalyticsEvent({ event: "auto_upload", params: { scheduleInterval, scheduleStartFrom } });
        // stop/start imageDetecting // EveryTime when image is uploaded
        scheduleImageDetectingService({ observedUris: scheduleInterval ? [] : syncedUris });
        // start/stop imageDetecting
        scheduleAutoSyncService({ start: scheduleInterval ? true : false });
      }
    } else if (!uploadEnabled) {
      // Stop the services
      scheduleImageDetectingService({ observedUris: [] });
      scheduleAutoSyncService({ start: false });
    }
  } else if (Platform.OS === "ios") {
    let { fetchAssets } = DownloadListeners;
    fetchAssets &&
      fetchAssets().then(_ => {
        onAssetChange && onAssetChange(fetchAssets);
      });
  }
  pushLog && pushLog("scheduleAutoBackup done..", true);
};

DownloadListeners = DownloadListenerHoc({
  ReactNativeDownload,
  fireLocalDataChangeListener,
  uploadUrl,
  invokeUploadAction,
  getUploadedUris,
  MetadataTable,
  AsyncStorage,
  getUser,
  isFetchUploadCompleted,
  getSettingInfo,
  LAST_AUTO_SYNCED_FILE_TIME_IN_SEC,
  scheduleAutoBackup,
  pushLog,
  logFirebaseAnalyticsEvent
});

let sounds = SoundHoc({ resolveImage: ActiveMQ.resolveImage });
// let soundUrls = {};
// for (let i = 0; i < montageSoundFileNames.length; i++) {
//   soundUrls[`sound${i}`] = `${urls["montageSounds"]}/${montageSoundFileNames[0]}`;
// }
// if (Platform.OS === "ios") {
//   const downloadMontageSounds = async () => {
//     let dirs = RNFetchBlob.fs.dirs;
//     for (let i = 0; i < montageSoundFileNames.length; i++) {
//       let localPath = dirs.DocumentDir + `/${montageSoundFileNames[i]}`;
//       let fileUrl = soundUrls[`sound${i}`];
//       if (await isFileExists(localPath)) {
//         soundUrls[`sound${i}`] = "file://" + localPath;
//       } else {
//         let res = void 0;
//         try {
//           res = await RNFetchBlob.config({
//             path: localPath
//           }).fetch("GET", fileUrl, {
//             //some headers ..
//           });
//         } catch (err) {}
//         if (res && res.path) {
//           soundUrls[`sound${i}`] = "file://" + res.path();
//         }
//       }
//     }
//   };
//   downloadMontageSounds();
// }
// if (Platform.OS === "ios") {
//   sounds = soundUrls;
// }

/**
 * @description Used to set the state of contact sync process.
 * @param {Boolean} value
 */
export const changeContactSyncProgress = value => {
  let contactRef = viewRefs["contact"];
  contactRef && contactRef.setLoading && contactRef.setLoading(value);
  contactSyncInProgress = value;
};

/**
 * @description Used to check if contact sync progress is true or false.
 */
export const getContactSyncProgress = () => {
  return contactSyncInProgress;
};

/**
 * @description Use to set the contact sync description message.
 * @param {String} description
 * @param {Boolean} skipDefault
 */
export const setContactSyncDescription = (description, skipDefault) => {
  let contactRef = viewRefs["contact"];
  if (!defaultContactSyncOnMessage || !description || skipDefault) {
    contactSyncDescription = description;
  } else {
    contactSyncDescription = I18N.t("contactSyncProgressMessage");
  }
  contactRef && contactRef.setDescription && contactRef.setDescription(contactSyncDescription);
};

/**
 * @description Used to get the contact sync description message.
 */
export const getContactSyncDescription = () => {
  return contactSyncDescription;
};

/**
 * @description Used to set the user context information.
 * @param {*} contextInfo
 */
export const setUserContextInfo = contextInfo => {
  userContextInfo = contextInfo;
};

/**
 * @description Used to set the route context information.
 * @param {*} contextInfo
 */
export const setRouterContextInfo = contextInfo => {
  routerContextInfo = contextInfo;
};

const getUserFromUserContext = () => {
  let userState = userContextInfo && userContextInfo._getState && userContextInfo._getState();
  let user = userState && userState.user;
  return user;
};

const { addPrivacyConsent } = PrivacyToolHoc({
  privacyConsentUrl,
  REGISTRY_HASHES,
  getUserFromUserContext,
  AsyncStorage,
  Platform,
  addPrivacyConsentAndroid
});

const waitForUserAndRouterContext = () => {
  const isContextLoaded = () => {
    let userState = userContextInfo && userContextInfo._getState && userContextInfo._getState();
    if (userState && userState.userLoaded && routerContextInfo) {
      return true;
    }
  };
  return new Promise(resolve => {
    if (isContextLoaded()) {
      resolve();
    }
    let interval = setInterval(() => {
      if (isContextLoaded()) {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });
};

/**
 * @description Use to redirect on route screen.
 * @param {String} routeName
 */
export const onClickNotification = async (routeName, payload) => {
  await waitForUserAndRouterContext();
  let userState = userContextInfo && userContextInfo._getState && userContextInfo._getState();
  let user = userState && userState.user;
  if (!user) {
    return;
  }
  const uriMapping = {
    MEMORY_OF_DAY: { uri: "/home#highlights" },
    PICTURE_OF_DAY: { uri: "/home#highlights" },
    STORAGE_ALERT: { uri: "/home#highlights/freeUpSpace" },
    SAFE_AREA: {
      uri: user.vaultEnabled ? "/home#highlights/enter-vault-pin" : "/home#highlights/create-vault-pin"
    }
  };
  if (routeName === "NOTIFICATION_MODAL") {
    let { title: title2, message: body2, data: { body, title } = {} } = payload || {};
    title = title || title2;
    body = body || body2;
    const StartUpWrapper = getStartUpWrapperRef();
    if (body && StartUpWrapper && StartUpWrapper.showNotificationModal) {
      StartUpWrapper.showNotificationModal({ title, body });
    }
  }
  let { uri = "/home#highlights" } = uriMapping[routeName] || {};
  let { replaceUri } = routerContextInfo || {};
  replaceUri &&
    replaceUri({
      uri,
      when: new Date().getTime()
    });
};

/**
 * @description Used to get user space data.
 */
export const getUserSpaceData = () => {
  return userSpaceData;
};

/**
 * @description Used to check if migration is required or not.
 * @param {*} user
 */
export const isMigrationRequired = user => {
  if (user && user.request_id && !user.migrated) {
    return true;
  }
};

/**
 * @description Used to update sync device information in metadata.
 * @param {*} resource
 * @param {Boolean} ensureUpdate
 */
export const updateSyncDeviceInfoInMetadata = async (resource, ensureUpdate) => {
  if (resource) {
    resourcestoUpdateDeviceId.push(resource);
  }
  if (ensureUpdate || resourcestoUpdateDeviceId.length === 100) {
    let invoke = ConnectAppServices.invoke;
    let dataToUpdate = resourcestoUpdateDeviceId;
    resourcestoUpdateDeviceId = [];
    await invoke({
      service: {
        url: urls["updateResourceDeviceId"],
        uriProps: {
          data: dataToUpdate
        }
      }
    });
  }
};

/**
 * @description Used to update user profile information.
 * @param {*} data
 * @param {*} param1
 */
export const updateUserProfile = async (data, { invoke } = {}) => {
  try {
    if (!invoke) {
      invoke = ConnectAppServices.invoke;
    }
    let result = await invoke({
      service: {
        url: urls["updateUserProfile"],
        uriProps: {
          data
        }
      },
      allowParallelInvoke: true
    });
    return result;
  } catch (e) {
    let message = e.code ? I18N.t(e.code.toString()) : void 0;
    if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
      message = e.message;
    }
    let newError = new Error(message);
    newError.code = e.code;
    throw newError;
  }
};

/**
 * @description Fetch duplicate contacts of the user.
 */
const getDuplicateContact = async () => {
  let invoke = ConnectAppServices.invoke;
  let service = {
    service: {
      url: urls["getDuplicateContact"],
      uriProps: {
        data: {}
      }
    }
  };
  try {
    let result = await invoke(service);
    return { result };
  } catch (e) {
    //ignore error
  }
};

const clearUserSessionTimer = () => {
  if (userSessionTimer) {
    clearTimeout(userSessionTimer);
    userSessionTimer = void 0;
  }
};

/**
 * @description Used to set device information in firebase database.
 * @param {*} props
 */
const setDeviceInformation = async (props = {}) => {
  try {
    if (!deviceInfo) {
      return;
    }
    let { deviceName, type, deviceId } = deviceInfo;
    let updates = {
      deviceName,
      type,
      device: deviceId,
      logout: false,
      appVersion: AppConfig.APP_BUILD_VERSION
    };

    let fcmToken = getNotificationToken && getNotificationToken();
    if (fcmToken) {
      updates.fcmToken = fcmToken;
    }
    let invoke = ConnectAppServices.invoke;
    let service = {
      service: {
        url: urls["setDeviceInfo"](),
        uriProps: {
          data: updates
        }
      },
      allowParallelInvoke: true
    };
    return await invoke(service);
  } catch (e) {
    //ignore error
  }
};

/**
 * @description Used to remove user information and redirect to login page.
 */
const removeUserAndRedirectToLogin = () => {
  userContextInfo && userContextInfo.removeUserInfo && userContextInfo.removeUserInfo();
  routerContextInfo && routerContextInfo.replaceUri && routerContextInfo.replaceUri(loginUrl);
};

/**
 * @description used to set the last server update time.
 */
export const setlastServerUpdateTime = () => {
  lastServerUpdateTime = new Date().getTime();
};

/**
 * @description sync sqlite with server in every 10 minutes and updates the changes(if any).
 */
const syncSQLWithServerUpdatesIfRequired = () => {
  if (lastServerUpdateTime && new Date().getTime() - lastServerUpdateTime < 10 * 60 * 1000) {
    populateAndUpdateMetadataInSqlite();
  }
};

/**
 * @description used to update user's session information on server.
 * @param {*} props
 */
const updateUserSessionInfo = async props => {
  clearUserSessionTimer();
  if (Platform.OS === "web" || !userSessionEnabled) {
    return;
  }
  let response = await setDeviceInformation(props);
  if (response && response.status === "logout") {
    if (response._id && response._id === lastUnlinkDevice_Id) {
      // already process by snapshot, ignore remove user
    } else {
      if (response._id) {
        lastUnlinkDevice_Id = response._id;
      }
      removeUserAndRedirectToLogin();
    }
  } else {
    syncSQLWithServerUpdatesIfRequired();
    userSessionTimer = setTimeout(async () => {
      await updateUserSessionInfo();
    }, 120000);
  }
};

const watchDevice = async ({ user }) => {
  if (deviceSnapshot) {
    return;
  }
  if (!deviceId) {
    return;
  }
  deviceSnapshot = firebaseDB()
    .collection(DeviceTable)
    .where("user.firebaseUid", "==", user.uid)
    .where("device", "==", deviceId)
    .limit(1)
    .onSnapshot(
      async snapshot => {
        try {
          let documents = typeof snapshot.docChanges === "function" ? snapshot.docChanges() : snapshot.docChanges;
          if (documents && documents.length) {
            let result = documents[0];
            const _id = result.doc.id;
            let _op = result.type;
            let doc = { _id, _op, ...result.doc.data() };
            if (_id !== lastUnlinkDevice_Id && doc.logout && _op === "modified") {
              lastUnlinkDevice_Id = _id;
              removeUserAndRedirectToLogin();
              if (doc.blocked) {
                await resetStorage();
                await clearCache();
              }
            }
          }
        } catch (err) {}
      },
      error => {}
    );
};

const logoutWebListener = async ({ user } = {}) => {
  if (webLogoutSnapshot || !user || !user.uid) {
    return;
  }

  webLogoutSnapshot = firebaseDB()
    .collection(CONNECTIONS_TABLE)
    .where("firebaseUid", "==", user.uid)
    .onSnapshot(
      snapshot => {
        try {
          let documents = typeof snapshot.docChanges === "function" ? snapshot.docChanges() : snapshot.docChanges;
          if (documents && documents.length) {
            for (let e of documents) {
              const _id = e.doc.id;
              let _op = e.type;
              let doc = { _id, _op, ...e.doc.data() };
              if (doc.revoked && _op === "modified") {
                removeUserAndRedirectToLogin();
                break;
              }
            }
          }
        } catch (err) {}
      },

      error => {
        console.log("Revoke token snapshot error--->", error);
      }
    );
};

const loadUserSpace = async ({ user }) => {
  try {
    let { result = [], queryRef } = await find({
      view: {
        table: UserQuotaTable,
        filter: {
          firebaseUid: user.uid
        }
      }
    });
    userSpaceData = result.length > 0 ? result[0] : void 0;
    setUserSpaceInfo();
    queryRef &&
      queryRef.onSnapshot(
        async snapshot => {
          const result = [];
          if (snapshot.exists) {
            result.push({ _id: snapshot.id, ...snapshot.data() });
          } else {
            snapshot.empty === false &&
              snapshot.forEach(e => {
                result.push({ _id: e.id, ...e.data() });
              });
          }
          userSpaceData = result.length > 0 ? result[0] : void 0;
          setUserSpaceInfo();
        },
        error => {}
      );
  } catch (err) {
    console.warn("!!!!!!Error in Load user space data >>>>>", err.message);
  }
};

const normalizeUser = async ({ user, recursive }) => {
  try {
    const appUsedBefore = await AsyncStorage.getItem("appUsedBefore");
    if (!appUsedBefore) {
      AsyncStorage.setItem("appUsedBefore", "true");
    }
    const token = await AsyncStorage.getItem("token");
    if (token) {
      AsyncStorage.removeItem("token");
    }
    let { fsUser = {} } = await loadFsUser();

    fsUser = fsUser.userData || {};
    fsUser["uid"] = user.uid;
    let requiredUserProps = {
      ...fsUser
    };
    loadUserSpace({ user });
    setFirebaseAnalyticsUser({ uid: user.uid }); //USER LOGIN OR REFRESH PAGE
    if (Platform.OS !== "web") {
      userSessionEnabled = true;
      updateUserSessionInfo();
      watchDevice({ user });
    } else {
      logoutWebListener({ user });
    }
    let userChangeListener = firebaseInstance()
      .auth()
      .onAuthStateChanged(async user => {
        try {
          if (user) {
            lastUser = user;
          }
          if (lastUser && !user) {
            //case of signout
            userChangeListener && userChangeListener();
            removeUserAndRedirectToLogin();
          }
        } catch (err) {
          // console.error("Error is ", err);
        }
      });

    return requiredUserProps;
  } catch (err) {
    if (err.code === 3005 && !recursive) {
      return await normalizeUser({ user, recursive: true });
    } else {
      throw err;
    }
  }
};

/**
 * @description Used to get profile Url.
 * @param {String} userId
 */
export const getProfileUrl = userId => {
  return urls && `${urls["profilePic"]}/${userId}?context=${JSON.stringify({ brand: brandName })}`;
};

/**
 * @description Used to get contact picture Url.
 * @param {String} userId
 */
export const getContactPicUrl = photoId => {
  return urls && `${urls["contactPic"]}/${photoId}?context=${JSON.stringify({ brand: brandName })}`;
};

export const isIntroductionRequired = ({ user }) => {
  if (user && !(user.name && user.email)) {
    return true;
  }
};

/**
 * @description Used to get introduction link.
 * @param {*} param0
 */
export const getIntroductionLink = ({ user, activeMQ }) => {
  if (isIntroductionRequired({ user })) {
    let { introductionLink } =
      (activeMQ &&
        resolveMQ &&
        resolveMQ(
          {
            introductionLinkSM: {
              uri: "/introduce-yourself"
            },
            introductionLinkMD: null
          },
          ["introductionLink"],
          activeMQ
        )) ||
      {};
    if (introductionLink) {
      return introductionLink;
    }
  }
};

/**
 * @description Used to cancel upload/download items one by one.
 */
const cancelItem = async ({ props } = {}) => {
  try {
    let { data, action: { source } = {} } = props || {};
    if (!data) {
      return;
    }
    if (source === "upload") {
      if (Platform.OS === "ios" && data.status === "queued") {
        return;
      }
      let { cancelUpload: RNCancelUpload } = ReactNativeDownload;
      RNCancelUpload && (await RNCancelUpload(data));
    } else if (source === "download") {
      if (data.status === "queued" && !data.jobId) {
        return;
      }
      let { cancelDownload: RNCancelDownload } = ReactNativeDownload;
      if (Platform.OS === "ios" && !data.jobId && data.resource) {
        if (currentDownloadProgress) {
          let currentDownload = Object.values(currentDownloadProgress) && Object.values(currentDownloadProgress)[0];
          let { id, resource } = currentDownload || {};
          if (id && resource && resource === data.resource) {
            data.jobId = id;
          }
        }
      }
      RNCancelDownload && (await RNCancelDownload(data));
    }
  } catch (err) {
    console.warn("error in cancelItem >>> ", err && err.message);
  }
};

/**
 * @description Used to cancel all upload/download items at once.
 */
const cancelAllItems = async params => {
  let { props: { action: { source } = {} } = {} } = params || {};
  onPendingBlocker(1);
  try {
    if (source === "upload") {
      const deletedIdsMap = await clearUploadQueue();
      refreshUploadNotification && refreshUploadNotification();
      let uploadCardRef = getUploadCardRef && getUploadCardRef();
      uploadCardRef && uploadCardRef.refreshState();
      allUploadedData = allUploadedData.filter(item => !deletedIdsMap[item.resource]);
    } else if (source === "download") {
      await clearDownloadQueue();
      refreshDownloadNotification && refreshDownloadNotification();
      let downloadCardRef = getDownloadCardRef && getDownloadCardRef();
      downloadCardRef && downloadCardRef.refreshState();
    }
    await cancelItem(params);
  } catch (err) {
    console.warn("error in cancel all", err && err.message);
  }
  onPendingBlocker(-1);
};

/**
 * @description Used to cancel upload/download queued items at once.
 */
const cancelQueuedItem = async ({ props } = {}) => {
  let { selectedIds = [], data = [], unSelectAll, action: { source } = {} } = props || {};
  if (!selectedIds.length) {
    return;
  }

  let selectedIdsMap = {};
  selectedIds.forEach(selectedId => {
    selectedIdsMap[selectedId] = 1;
  });
  let queuedIds = [],
    inProgressFiles = [];

  for (let file of data) {
    let { _id, status } = file;
    if (selectedIdsMap[_id]) {
      if (status === "queued") {
        queuedIds.push(_id);
      } else {
        inProgressFiles.push({ ...file, resource: _id });
      }
    }
  }
  onPendingBlocker(1);
  if (queuedIds.length) {
    try {
      if (source === "upload") {
        let successfulyCancelledIds = await cancelQueuedItemsRecursively(queuedIds, cancelQueuedUploadsSqliite);
        await resetUploadQueueMemorySqlite();
        refreshUploadNotification && refreshUploadNotification();
        let uploadCardRef = getUploadCardRef && getUploadCardRef();
        uploadCardRef && uploadCardRef.refreshState();
        let localChanges = [];
        let idMap = {};
        for (let id of successfulyCancelledIds) {
          localChanges.push({
            _id: id,
            source: "upload",
            remove: true
          });
          idMap[id] = 1;
        }
        allUploadedData = allUploadedData.filter(item => !idMap[item.resource]);
        fireLocalDataChangeListener({
          key: [NON_UPLOAD_LOCAL_CHANGE_KEY, UPLOAD_QUEUE_LOCAL_CHANGE_KEY, MetadataTable],
          localChanges
        });
      } else if (source === "download") {
        let successfulyCancelledIds = await cancelQueuedItemsRecursively(queuedIds, cancelQueuedDownloadsSqliite);
        await resetDownloadQueueMemorySqlite();
        refreshDownloadNotification && refreshDownloadNotification();
        let downloadCardRef = getDownloadCardRef && getDownloadCardRef();
        downloadCardRef && downloadCardRef.refreshState();
        let downloadQueueChanges = [];
        let metadataChanges = [];
        for (let id of successfulyCancelledIds) {
          downloadQueueChanges.push({
            _id: id,
            source: "download",
            remove: true
          });
          metadataChanges.push({
            _id: id,
            source: "download",
            changes: {
              downloading: false,
              status: void 0,
              actionType: void 0,
              localUri: void 0,
              downloadJobId: void 0,
              pauseReason: void 0
            }
          });
        }
        fireLocalDataChangeListener({
          key: DOWNLOAD_QUEUE_LOCAL_CHANGE_KEY,
          localChanges: downloadQueueChanges
        });
        fireLocalDataChangeListener({
          key: MetadataTable,
          localChanges: metadataChanges
        });
      }
    } catch (err) {
      console.warn("err in cancelQueuedItem >>>> ", err && err.message);
    }
  }
  if (inProgressFiles.length) {
    try {
      for (let file of inProgressFiles) {
        if (source === "download") {
          file = convertMetadataToDownloadData(file);
        }
        await cancelItem({ props: { data: file, action: { source } } });
      }
    } catch (err) {
      console.warn("error in cancel inProgress file >>>> ", err && err.message);
    }
    await new Promise(res => setTimeout(res, 2000)); // avoid parallel queries
  }
  unSelectAll && unSelectAll();
  onPendingBlocker(-1);
};

const { DeviceSetting, fetchDocuments, fetchMusic } = DeviceUtility({ getUploadedUris, mappings, addPrivacyConsent });
const {
  checkPhotoPermissions,
  fetchGalleryFolders,
  fetchAssetsForFolder,
  fetchProfileGallery,
  fetchAssetSize,
  fetchAssetData
} = DeviceGalleryUtility({
  ReactNativeDownload,
  addPrivacyConsent
});

/**
 * @description used to return token and props for api call.
 * @param {*} param0
 */
export const preFetch = async ({ url, props } = {}) => {
  let token = await getToken();
  // console.warn("@@ token", token);
  return {
    url,
    props: {
      ...props,
      context: { versionData, brand: brandName(), skipSuspended: true }
    },
    headers: { token }
  };
};

/**
 * @description Used to fetch the data from server and return the data.
 * @param {*} param0
 */
export const urlFetch = async ({ url, props }) => {
  const prefetchResult = await preFetch({
    url: url(),
    props
  });
  url = prefetchResult.url;
  props = prefetchResult.props;
  let headers = prefetchResult.headers;
  if (url.indexOf("/fetch") >= 0 || url.indexOf("/post") >= 0) {
    throw new Error(`Invalid urlFetch [${url}]`);
  }
  addLog &&
    addLog(
      `Url fetch called url : ${url} props :${JSON.stringify({
        ...props,
        context: void 0
      })}`
    );
  return axios
    .post(url, props, { headers })
    .then(res => {
      let resultData = res.data;
      if (resultData && resultData.result) {
        if (Array.isArray(resultData.result)) {
          addLog && addLog(`Url fetch called url : ${url} done result :${resultData.result.length}`);
        } else {
          addLog && addLog(`Url fetch called url : ${url} done result : ${resultData.result._id}`);
        }
      } else {
        addLog && addLog(`Url fetch called url : ${url} done `);
      }
      return resultData;
    })
    .catch(e => {
      const originalErrorMessage = e.message;
      let err = void 0;
      if (originalErrorMessage === "Network Error") {
        err = new Error(`Unable to connect with server[${url}]`);
        err.code = "no_internet";
      } else if (e.response && e.response.data) {
        let responseData = e.response.data;
        if (responseData.error) {
          err = new Error(responseData.error.message);
          err.code = responseData.error.code || responseData.error.status_code;
        } else if (responseData.message) {
          err = new Error(responseData.message);
          err.code = responseData.code || responseData.status_code;
        } else {
          err = new Error(JSON.stringify(responseData));
        }
      } else {
        err = new Error(originalErrorMessage);
      }
      addLog && addLog(`Url fetch error url : ${url} error code : ${err.code} error message : ${err.message}`);
      throw err;
    });
};

/**
 * @description Used to load picture of the day and return the same.
 */
export const loadPicOfDay = async () => {
  let data = await urlFetch({ url: urls["picOfDay"], props: {} });
  let result = data && data.result;
  if (result === "Picture of the day not found.") {
    return [];
  }
  if (result && (result.converted_jpeg_url || result.thumbnail_url)) {
    return [result];
  }
};

/**
 * @description used to fetch search result from metadata.
 * @param {*} param0
 */
const searchMetadata = async ({ query, uid, user, uri }) => {
  let subscribe = uri && uri.props && uri.props.subscribe;
  let cache = (uri && uri.props && uri.props.cache) || subscribe;
  let { dataParams, params, view: viewName, secureType, sqlite } = query;
  let filter, fts, skipCount;
  if (dataParams && dataParams.ftsString) {
    if (sqlite && (await isMetadataPopulatedForCurrentSession())) {
      filter = { deleted: false, "_createdBy.firebaseUid": user.uid };
      let $or = [];
      if (viewName === "searchGallery") {
        filter.gallery = true;
        $or = [{ lower_name: { $contains: dataParams.ftsString } }];
      } else if (viewName === "searchMusic") {
        /**
         * Server matches lower_name, resource_artist, resource_album and ext for search in audio
         * files. (lower_name is resource_title for audio files and is updated after upload completes)
         *
         * But in sqlite, lower_name is updated after server updates are synced which may take time,
         * so lower_name is not considered here.
         */
        filter.type = "audio";
        $or = [
          { resource_artist: { $contains: dataParams.ftsString } },
          { resource_album: { $contains: dataParams.ftsString } },
          { resource_title: { $contains: dataParams.ftsString } },
          { ext: { $contains: dataParams.ftsString } }
        ];
      } else if (viewName === "searchDocuments") {
        filter.type = "doc";
        $or = [{ lower_name: { $contains: dataParams.ftsString } }];
      }
      query.sqliteFilter = {
        ...(query.sqliteFilter || {}),
        $or
      };
    } else {
      filter = { name: dataParams.ftsString };
      fts = true;
    }
  } else {
    filter = { deleted: false, "_createdBy.firebaseUid": user.uid };
    let typeFilterRequired = true;
    const from = params && params.from;
    if (from === "favourite") {
      filter["favourite"] = true;
      typeFilterRequired = false;
    }
    if (from === "shared") {
      filter["private"] = false;
      typeFilterRequired = false;
    }
    if (from === "dateFilter") {
      let range = params && params.dateRange;
      if (range) {
        let { from, to } = range;
        if (from) {
          filter["$and"] = filter["$and"] || [];
          filter["$and"].push({ resource_lastModified: { $gte: from } });
        }
        if (to) {
          filter["$and"] = filter["$and"] || [];
          filter["$and"].push({ resource_lastModified: { $lte: to } });
        }
      }
      typeFilterRequired = false;
    }
    if (viewName === "searchGallery") {
      filter.gallery = true;
      if (typeFilterRequired) {
        filter["type"] = from;
      }
    } else if (viewName === "searchMusic") {
      filter.type = "audio";
      if (typeFilterRequired) {
        filter["ext"] = from;
      }
    } else if (viewName === "searchDocuments") {
      filter.type = "doc";
      if (typeFilterRequired) {
        filter["docType"] = from;
      }
    }
  }
  filter.secureType = secureType || SECURE_TYPES.DEFAULT;
  // console.log("@@@ search data Filter :", filter, " - fts :", fts, " - subscribe :", subscribe);
  const view = {
    ...query,
    table: MetadataTable,
    filter
  };

  if (fts && query.skip && query.skip.length) {
    skipCount = query.skip.length;
  }

  return await fetchFromCache({
    fetch: !fts
      ? void 0
      : () => {
          let queryProps = {
            data: { ...query, skip: skipCount }
          };
          return urlFetch({
            url: urls["searchMetadata"](),
            props: queryProps
          });
        },
    view,
    uid,
    subscribe,
    fromCache: cache
  });
};

/**
 * @description Used to set after login link.
 * @param {String} link
 */
export const setAfterLoginLink = link => {
  afterLoginLink = link;
};

/**
 * @description Used to get after login link.
 * @param {*} props
 */
export const getAfterLoginLink = props => {
  let introductionLink = getIntroductionLink(props);
  if (introductionLink) {
    return introductionLink;
  }
  let _afterLoginLink = afterLoginLink;
  afterLoginLink = void 0;
  return _afterLoginLink;
};

/**
 * @description This function is called after login and used to redirect at home page.
 * @param {*} result
 * @param {*} param1
 */
export const afterLogin = (result, { replaceUri, activeMQ }) => {
  let afterLoginLink = getAfterLoginLink && getAfterLoginLink({ user: result.user, activeMQ });
  replaceUri && replaceUri(landingUrl({ user: result.user, afterLoginLink, activeMQ }));
};

/**
 * @description Used to set join information.
 * @param {*} info
 */
export const setJoinInfo = info => {
  joinInfo = info;
};

/**
 * @description Used to get join information.
 */
export const getJoinInfo = () => joinInfo;

/**
 * @description Used to clear join information.
 */
export const clearJoinInfo = () => {
  joinInfo = void 0;
};

/**
 * @description Used to get fs track ID.
 */
export const getFsTrackId = () => fsTrackId;

/**
 * @description Used to logout the user and reset data.
 */
const removeUser = async () => {
  let userId = lastUser && lastUser.uid;
  logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "logout", params: { user: userId } });
  fsTrackId = uuid();
  lastUser = void 0;
  contactSyncDescription = void 0;
  userSessionEnabled = false;
  clearUserSessionTimer();
  unsubscribeAll();
  enableLogs(false);
  if (deviceSnapshot) {
    deviceSnapshot();
    deviceSnapshot = void 0;
  }
  if (webLogoutSnapshot) {
    webLogoutSnapshot();
    webLogoutSnapshot = void 0;
  }
  if (uploadSnapshot) {
    uploadSnapshot.forEach(e => e());
    uploadSnapshot = [];
  }
  if (downloadSnapshot) {
    downloadSnapshot.forEach(e => e());
    downloadSnapshot = [];
  }
  try {
    userId &&
      deviceId &&
      (await urlFetch({
        url: urls["setDeviceInfo"],
        props: {
          data: {
            status: "logout",
            logout: true,
            device: deviceId,
            lastLogoutOn: new Date(),
            _updatedOn: new Date(),
            fcmToken: null,
            updatedOn: JSON.parse(JSON.stringify(new Date()))
          }
        }
      }));
  } catch (err) {
    console.warn("!!!!!error in logout in device table .....", err.message);
    // do nothing..
  }
  firebaseInstance()
    .auth()
    .signOut()
    .catch(() => {
      console.log("error in signout");
    });
  setFirebaseAnalyticsUser({ uid: void 0 });
  try {
    stopServices && stopServices();
    await AsyncStorage.removeItem(LAST_AUTO_SYNCED_FILE_TIME_IN_SEC);
    await AsyncStorage.removeItem("rollbackWarningShown");
    await AsyncStorage.removeItem("lastWarningTime");
    await resetPrivacyConsents();
    clearSharedPreference && clearSharedPreference();
    clearUploadAndDownloadQueue && (await clearUploadAndDownloadQueue());
    allUploadedData = allUploadedData.filter(item => item.status === "success");
  } catch (err) {
    pushLog && pushLog(`Error in remove user >>>> ${err.message}`, true);
  }
};

/**
 * @description Used to delete all metadata, contacts and synced directories from sqlite.
 */
const resetSqlite = async () => {
  deleteAllMetadata && (await deleteAllMetadata());
  try {
    deleteAllContact && (await deleteAllContact());
  } catch (err) {
    pushLog && pushLog(`Error in deleteAllContact >>>>>${err.message}`, true);
  }
  try {
    deleteAllSyncedDirectories && (await deleteAllSyncedDirectories());
  } catch (err) {
    pushLog && pushLog(`Error in deleteAllSyncedDirectories >>>>>${err.message}`, true);
  }
};

/**
 * @description Used to clear cache and reset all data on user change.
 * This is called when user logs in and is different from previous logged user
 * @param {*} user
 */
export const clearDataOnUserChange = async user => {
  try {
    let currentUserId = user && user.uid;
    if (!currentUserId) {
      return;
    }
    const { userId: prevUserId } = getSettingInfo();
    if (prevUserId !== currentUserId) {
      settingInfo = await getUserDeviceTableSettingInfo();
      settingInfo.userId = currentUserId;
      if (prevUserId) {
        await resetStorage();
      }
      await updateSettingInformationSqlite(settingInfo);
      await AsyncStorage.setItem("dataFetchedVariableHandled", "true");
    } else {
      try {
        let dataFetchedVariableHandled = await AsyncStorage.getItem("dataFetchedVariableHandled");
        if (!dataFetchedVariableHandled) {
          await updateSettingInformation({ dataFetchedForSync: false });
          deleteAllSyncedDirectories && (await deleteAllSyncedDirectories());
          await AsyncStorage.setItem("dataFetchedVariableHandled", "true");
        }
      } catch (err) {}
    }
  } catch (err) {
    pushLog && pushLog(`Error in clear data on user change >>>>>${err.message}`, true);
    // TODO handle error
  }
};

const FIREBASE_DATA_LIMIT = 500;

/**
 * @description Fetch data of removed items and delete the same from local.
 */
const fetchAndDeleteRemovedMetadata = async () => {
  let lastDoc;
  try {
    lastDoc = await AsyncStorage.getItem("lastSyncedRemovedMetadataRecord");
    if (lastDoc) {
      lastDoc = JSON.parse(lastDoc);
    }
  } catch (err) {}
  while (true) {
    try {
      if (!lastUser || !lastUser.uid) {
        break;
      }
      let result = await find({
        view: {
          table: "removed_metadata",
          filter: {
            "_createdBy.firebaseUid": lastUser.uid
          },
          sort: {
            _removedOn: 1,
            _id: 1
          },
          limit: FIREBASE_DATA_LIMIT,
          lastDoc
        }
      });
      result = result && result.result;
      if (result && result.length) {
        try {
          let removedIds = result.map(row => (row ? row.resourceId : null));
          let existingIds = await getExistingMetadataIds(removedIds);
          if (existingIds.length) {
            let localChanges = [];
            existingIds.forEach(_id => {
              removeCurrentUploadData({ resource: _id });
              localChanges.push({ _id, remove: true });
            });
            await _updateSqliteFromLocalChanges({ localChanges });
          }
        } catch (err) {}
        lastDoc = result[result.length - 1];
        await AsyncStorage.setItem(
          "lastSyncedRemovedMetadataRecord",
          JSON.stringify({ _id: lastDoc._id, _removedOn: lastDoc._removedOn })
        );
      }
      if (!result || result.length < FIREBASE_DATA_LIMIT) {
        break;
      }
    } catch (err) {
      console.log("error in fetch and delete removed metadata: ", err && err.message);
      break;
    }
  }
};

/**
 * @description Fetch the data from metadata and sync the sqlite with metadata.
 */
export const populateAndUpdateMetadataInSqlite = async ({ ignoreLastDoc } = {}) => {
  if (Platform.OS === "web") {
    serverMetadataSyncStatus = "completed";
    return;
  }
  if (serverMetadataSyncStatus === "processing") {
    return;
  }
  serverMetadataSyncStatus = "processing";
  let lastDoc;
  if (!ignoreLastDoc) {
    try {
      lastDoc = await AsyncStorage.getItem("lastSyncedRecord");
      if (lastDoc) {
        lastDoc = JSON.parse(lastDoc);
      }
    } catch (err) {}
    if (!lastDoc) {
      if (populate_interval) {
        clearInterval(populate_interval);
        populate_interval = void 0;
      }
      AsyncStorage.setItem("populateSqliteMetadataAgain", JSON.stringify({ _updatedOn: new Date() }));
    }
  }
  while (true) {
    try {
      if (!lastUser || !lastUser.uid) {
        break;
      }
      let metadataResult = await find({
        view: {
          table: MetadataTable,
          filter: {
            "_createdBy.firebaseUid": lastUser.uid,
            completed: true
          },
          sort: {
            _updatedOn: 1,
            _id: 1
          },
          limit: FIREBASE_DATA_LIMIT,
          lastDoc
        }
      });
      metadataResult = metadataResult && metadataResult.result;

      if (metadataResult && metadataResult.length) {
        try {
          let { toUpdateData, toInsertData } = await getToInsertMetadata(metadataResult);
          let changes = {
            gallery: {
              insert: [],
              updates: [],
              removes: [],
              sort: GALLERY_ALL_SORT,
              key: GALLERY_ALL_LOCAL_CHANGE_KEY
            },
            doc: { insert: [], updates: [], removes: [], sort: DOCS_ALL_SORT, key: DOCS_ALL_LOCAL_CHANGE_KEY },
            audio: { insert: [], updates: [], removes: [], sort: MUSIC_ALL_SORT, key: MUSIC_ALL_LOCAL_CHANGE_KEY }
          };

          for (let doc of toUpdateData) {
            let type = doc.type;
            if (type === "image" || type === "video") {
              Object.assign(doc, {
                height: doc.converted_height || doc.thumbnail_height,
                width: doc.converted_width || doc.thumbnail_width,
                url: doc.converted_jpeg_url || doc.thumbnail_url
              });
              type = "gallery";
            } else if (type === "doc") {
              Object.assign(doc, { url: doc.thumbnail_url });
            }
            let { day, month, year } = doc;
            if (day && month && year) {
              Object.assign(doc, { dateStr: `${day}-${month}-${year}` });
            }
            let localChanges;
            if (doc.empty) {
              localChanges = { _id: doc._id, remove: true };
              removeCurrentUploadData({ resource: doc._id });
              changes[type].removes.push(localChanges);
            } else if (doc.deleted) {
              localChanges = { _id: doc._id, remove: true, sqliteChanges: doc };
              removeCurrentUploadData({ resource: doc._id });
              changes[type].removes.push(localChanges);
            } else {
              localChanges = { _id: doc._id, changes: doc, source: "action" };
              changes[type].updates.push(localChanges);
            }
          }
          for (let doc of toInsertData) {
            let { type, empty } = doc;
            if (empty) {
              continue;
            }
            if (type === "image" || type === "video") {
              Object.assign(doc, {
                height: doc.converted_height || doc.thumbnail_height,
                width: doc.converted_width || doc.thumbnail_width,
                url: doc.converted_jpeg_url || doc.thumbnail_url
              });
              type = "gallery";
            } else if (type === "doc") {
              Object.assign(doc, { url: doc.thumbnail_url });
            }
            let { day, month, year } = doc;
            if (day && month && year) {
              Object.assign(doc, { dateStr: `${day}-${month}-${year}` });
            }
            changes[type].insert.push(doc);
          }

          for (let type in changes) {
            let { updates, insert, removes, sort, key } = changes[type];
            if (updates.length) {
              await fireLocalDataChangeListener({ key, localChanges: updates, updateSqlite: true });
            }
            if (removes.length) {
              await _updateSqliteFromLocalChanges({ localChanges: removes });
            }
            if (insert.length) {
              insert = insert.sort((a, b) => getSortValue(a, b, sort));
              await _updateSqliteFromLocalChanges({ localChanges: { insert } });
            }
          }
        } catch (err) {}
        lastDoc = metadataResult[metadataResult.length - 1];
        await AsyncStorage.setItem(
          "lastSyncedRecord",
          JSON.stringify({ _id: lastDoc._id, _updatedOn: lastDoc._updatedOn })
        );
      }
      if (!metadataResult || metadataResult.length < FIREBASE_DATA_LIMIT) {
        await fetchAndDeleteRemovedMetadata();
        let { dataFetchedForSync } = getSettingInfo();
        if (!dataFetchedForSync) {
          await updateSettingInformation({ dataFetchedForSync: true });
        }
        if (metadataPopulatedForCurrentSession === undefined) {
          metadataPopulatedForCurrentSession = 1;
        }
        break;
      }
    } catch (err) {
      break;
    }
  }
  serverMetadataSyncStatus = "completed";
};

export const forceUpdateMetadataInSqlite = async user => {
  if (populate_interval) {
    return;
  }
  populate_interval = setInterval(() => {
    if (serverMetadataSyncStatus !== "processing") {
      clearInterval(populate_interval);
      populate_interval = void 0;
      populateAndUpdateMetadataInSqlite({ ignoreLastDoc: true });
      AsyncStorage.setItem("populateSqliteMetadataAgain", JSON.stringify({ user, _updatedOn: new Date() }));
    }
  }, 2000);
};

/**
 * @description Wait for the sync completion.
 */
export const waitForServerMetadataPopulation = () => {
  console.log("waitForServerMetadataPopulation");
  if (serverMetadataSyncStatus === "completed") {
    console.log("serverMetadataSyncStatus");
    return Promise.resolve();
  }
  if (!serverMetadataSyncStatus) {
    console.log("populateAndUpdateMetadataInSqlite");
    populateAndUpdateMetadataInSqlite();
  }
  return new Promise(resolve => {
    console.log("last condition", serverMetadataSyncStatus);
    let interval = setInterval(_ => {
      console.log("last condition", serverMetadataSyncStatus);
      if (serverMetadataSyncStatus === "completed") {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });
};

const loadFsUser = async () => {
  const userDataUrl = urls["currentUserData"];
  let fsUserData = await urlFetch({ url: userDataUrl, props: { data: { skipFSToken: true } } });
  return {
    fsUser: fsUserData
  };
};

const loadUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      //in case of native app we can get currentUsre on app reload but in case of web we need to add listener
      // in case of native app listener was double fired
      let cuser = firebaseInstance().auth().currentUser;
      if (cuser) {
        console.log("here1");
        const appUsedBefore = await AsyncStorage.getItem("appUsedBefore");
        if (!appUsedBefore) {
          console.log("here2");
          firebaseInstance()
            .auth()
            .signOut()
            .catch(() => {
              console.log("error in signout");
            });
          console.log("here3");
          AsyncStorage.setItem("appUsedBefore", "true");
          resolve(null);
        } else {
          console.log("here4");
          resolve({ user: await normalizeUser({ user: cuser }) });
        }
      } else {
        const unsubscribe = firebaseInstance()
          .auth()
          .onAuthStateChanged(async user => {
            try {
              unsubscribe && unsubscribe();
              if (user) {
                resolve({ user: await normalizeUser({ user }) });
              } else {
                resolve(null);
              }
            } catch (err) {
              reject(err);
            }
          });
      }
    } catch (err) {
      console.log("here10");
      if (
        err.code === "auth/id-token-revoked" ||
        err.code === "auth/id-token-expired" ||
        err.code === "auth/internal-error"
      ) {
        console.log("here11");
        resolve(null);
      }
      console.log("here12");
      reject(err);
    }
  });
};

let CacheManager = CacheManagerHoc({ fetch: find, Platform, modifyUrls, mergeTwoSortedArrays, typeCastSchema });

export const typeCastDateField = value => {
  if (value instanceof Date) {
    return value;
  }
  if (new Date(value) !== "Invalid Date") {
    return new Date(value);
  }
  if (typeof value === "string") {
    value = new Date(value);
  } else if (value.hasOwnProperty("_seconds") || value.hasOwnProperty("seconds")) {
    if (value.toDate) {
      value = value.toDate();
    } else if (value.hasOwnProperty("seconds")) {
      if (value.hasOwnProperty("nanoseconds")) {
        value = new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
      } else {
        value = new Date(value.seconds * 1000);
      }
    } else if (value.hasOwnProperty("_seconds")) {
      if (value.hasOwnProperty("_nanoseconds")) {
        value = new Date(value._seconds * 1000 + value._nanoseconds / 1000000);
      } else {
        value = new Date(value._seconds * 1000);
      }
    }
  }
  return value;
};

let {
  fetch: fetchFromCache,
  createSnapshot: subscribe,
  removeSnapshot: unsubscribe,
  removeAllSnapshot: unsubscribeAll,
  onRealTimeUpdate,
  addRealTimeListener,
  removeRealTimeListener,
  addTableListener,
  removeTableListener,
  updateCacheFromLocalChanges
} = CacheManager;
_updateCacheFromLocalChanges = updateCacheFromLocalChanges;

/**
 * @description Used to return query for contact data.
 * @param {*} param0
 */
const deviceContactSync = ({ user, skip, limit, lastSynced }) => {
  let filter = {
    user: user.uid
  };
  let extraSort = {};
  if (lastSynced) {
    filter["_modifiedOn"] = { $gte: parseInt(lastSynced) };
    extraSort["_modifiedOn"] = 1;
  } else {
    filter["deleted"] = false;
  }
  return {
    query: {
      view: "contactDataForDevice",
      filter,
      sort: { ...extraSort, _id: 1 },
      skip,
      limit,
      firebaseOptions: { source: "server" }
    }
  };
};

/**
 * @description Used to remove duplicates from the server.
 * @param {*} resourceIds
 */
const removeDuplicatesFromServer = async resourceIds => {
  try {
    pushLog && pushLog(`Remove duplicates from server: ${resourceIds && resourceIds.join(", ")}`, true);
    await urlFetch({
      url: urls["removeDuplicateFiles"],
      props: {
        data: resourceIds
      }
    });
    pushLog && pushLog(`Duplicates removed from server successfully...`, true);
  } catch (err) {
    pushLog && pushLog(`Error in delete duplicate from server call: ${err && err.message}`, true);
  }
};

/**
 * @description Remove duplicate upload resources recursively.
 */
const removeDuplicateUploadRecursively = async (resourceWiseUploads, lastDoc) => {
  try {
    let userUid = lastUser && lastUser._user && lastUser._user.uid;
    if (!userUid) {
      throw new Error("User not found");
    }
    if (!lastDoc) {
      lastDoc = await AsyncStorage.getItem("lastDuplicateDoc");
    }
    let duplicateData = await find({
      view: {
        table: MetadataTable,
        filter: {
          "_createdBy.firebaseUid": userUid,
          duplicateFound: true,
          deviceId
        },
        sort: {
          _createdOn: 1,
          _id: 1
        },
        limit: 500,
        lastDoc
      }
    });
    let serverData = duplicateData && duplicateData.result;
    if (!serverData || !serverData.length) {
      return;
    }
    let hasNext = serverData.length >= 500;
    let lastRecord = serverData[serverData.length - 1];
    if (hasNext) {
      serverData.pop();
    }
    let toDeleteResources = [];
    for (let row of serverData) {
      let resourceId = row && row._id;
      if (resourceId) {
        toDeleteResources.push(resourceId);
        if (resourceWiseUploads[resourceId]) {
          delete resourceWiseUploads[resourceId];
        }
      }
    }
    if (toDeleteResources.length) {
      pushLog && pushLog(`Remove duplicates from device: ${toDeleteResources.join(", ")}`, true);
      await removeUploadByIds(toDeleteResources);
      pushLog && pushLog(`Removed duplicates from device successfully...`, true);
    }
    removeDuplicatesFromServer(toDeleteResources);
    await AsyncStorage.setItem("lastDuplicateDoc", JSON.stringify(lastRecord));
    if (hasNext) {
      await removeDuplicateUploadRecursively(resourceWiseUploads, lastRecord);
    }
  } catch (err) {
    throw err;
  }
};

/**
 * @description Used to remove duplicates upload resources.
 * @param {*} uploadedFiles
 */
const removeDuplicateUploads = async uploadedFiles => {
  try {
    pushLog && pushLog("Remove duplicate files called..", true);
    let resourceWiseUploads = {};
    for (let row of uploadedFiles) {
      if (row && row.resource) {
        resourceWiseUploads[row.resource] = row;
      }
    }
    await removeDuplicateUploadRecursively(resourceWiseUploads);
    pushLog && pushLog("Remove duplicate files done..", true);
    uploadedFiles = Object.values(resourceWiseUploads);
  } catch (err) {
    pushLog && pushLog(`Remove duplicate files error..${err && err.message}`, true);
  }
  return uploadedFiles;
};

/**
 * @description Used to remove failed upload resources iteratively.
 * @param {*} resourceIds
 * @param {Number} startIndex
 */
const removeFailedUploadsIteratively = async (resourceIds, startIndex = 0) => {
  if (!resourceIds || !resourceIds.length || startIndex >= resourceIds.length) {
    return;
  }
  let nextIndex = startIndex + 50;
  if (resourceIds.length < nextIndex) {
    nextIndex = resourceIds.length;
  }
  try {
    pushLog && pushLog(`removeFailedUploadsIteratively batch index : ${startIndex}`, true);
    let currentBatchResourceIds = resourceIds.slice(startIndex, nextIndex);
    let uploadErrorArray = currentBatchResourceIds.map(resourceId => ({
      resourceId,
      upload_error: "Manual failed on startup"
    }));
    await uploadErrorCall(uploadErrorArray);
    await removeUploadByIds(currentBatchResourceIds);
    pushLog && pushLog(`removeFailedUploadsIteratively done batch index : ${startIndex}`, true);
  } catch (err) {
    pushLog &&
      pushLog(`removeFailedUploadsIteratively error batch index: ${startIndex}, error: ${err && err.message}`, true);
  }
  removeFailedUploadsIteratively(resourceIds, nextIndex);
};

/**
 * @description Remove failed upload resources.qsd
 */
const removeFailedUploads = async () => {
  try {
    pushLog && pushLog(`Remove failed uploads start`, true);
    let resourceIds = (await getFailedUploadIds()) || [];
    pushLog &&
      pushLog(`Remove failed uploads count : ${resourceIds.length} , resourceIds : ${resourceIds.join(", ")}`, true);
    removeFailedUploadsIteratively(resourceIds);
    pushLog && pushLog(`Remove failed uploads done`, true);
  } catch (err) {
    pushLog && pushLog(`Error in removeFailedUploads >> ${err && err.message}`, true);
  }
};

/**
 * @description Start uploading queued data.
 */
export const startQueuedUpload = async () => {
  try {
    pushLog && pushLog(`startQueuedUpload start...`, true);
    const { uploadQueuedData } = DownloadListeners;
    uploadQueuedData && (await uploadQueuedData());
    let uploadCardRef = getUploadCardRef();
    uploadCardRef && uploadCardRef.fetchUploadData && uploadCardRef.fetchUploadData(true);
    pushLog && pushLog(`startQueuedUpload done...`, true);
  } catch (err) {
    pushLog && pushLog(`Error in startQueuedUpload >> ${err && err.message}`, true);
  }
};

/**
 * @description Used to get uploaded data from sqlite after removing duplicates.
 */
const getSqliteUploadedData = async () => {
  let uploadedFiles = [];
  if (!(await isMetadataPopulatedForCurrentSession())) {
    pushLog && pushLog(`getUploadedFiles called...`, true);
    uploadedFiles = (await getUploadedFiles()) || [];
    pushLog && pushLog(`uploadedFiles count : ${uploadedFiles.length}`, true);
  }
  uploadedFiles = await removeDuplicateUploads(uploadedFiles);
  pushLog && pushLog(`uploadedFiles after remove duplicate uploads count: ${uploadedFiles.length}`, true);
  return uploadedFiles;
};

/**
 * @description Fetch sqlite data and populate on server.
 */
export const populateSqliteUploadData = async () => {
  if (fetchedSqliteData === "processing") {
    return;
  }
  if (fetchedSqliteData === "completed") {
    allUploadedData = [];
    fetchedSqliteData = void 0;
  }
  fetchedSqliteData = "processing";
  removeFailedUploads();
  try {
    pushLog && pushLog(`populateSqliteUploadData start >>>> fetchedSqliteData : ${fetchedSqliteData}`, true);
    let uploadedFiles = await getSqliteUploadedData();
    if (uploadedFiles && uploadedFiles.length) {
      uploadedFiles.forEach(uploadedFile => {
        allUploadedData.push(uploadedFile);
      });
      allUploadedData.sort((a, b) => getSortValue(a, b, UPLOAD_GALLERY_SORT)); // default sorted by gallery sort
    }
    pushLog && pushLog(`populateSqliteUploadData end >>> fetchedSqliteData : ${fetchedSqliteData}`, true);
  } catch (err) {
    showMessage && showMessage(I18N.t("unexpectedErrorToast"));
    pushLog(`UNEXPECTED ERROR in populateSqliteUploadData: ${(err && err.message) || "No error message found"}`, true);
  }
  fetchedSqliteData = "completed";
  startQueuedUpload();
};

/**
 * @description Mapped the uploaded file and returns the same.
 */
export const getUploadedFileMap = async ({ keyField = "uri" } = {}) => {
  let allUploadedData = await getAllUploadedData();
  let uploadedFileMap = {};
  allUploadedData &&
    allUploadedData.length &&
    allUploadedData.forEach(row => {
      let keyFieldValue = row[keyField];
      if (keyFieldValue) {
        uploadedFileMap[keyFieldValue] = row;
      }
    });
  return uploadedFileMap;
};

/**
 * @description Used to get all uploaded data.
 */
export const getAllUploadedData = async () => {
  if (Platform.OS === "web") {
    return [];
  }
  await waitForUploadedData();
  return allUploadedData;
};

/**
 * @description Used to remove Images from gallery folders.
 * @param {*} data
 */
export const removeImageFromGalleryFolders = data => {
  for (let row of data) {
    for (let groupId in galleryFolders) {
      delete galleryFolders[groupId].images[row.uri];
    }
  }
};

/**
 *@description Used to set current upload progress.
 * @param {data,process}
 */
export const setCurrentUploadProgress = ({ data = {}, progress } = {}) => {
  currentUploadProgress = {
    [data.resource]: progress
  };
};

/**
 * @description Used to set current download progress.
 */
export const setCurrentDownloadProgress = ({ id, progress, resource } = {}) => {
  currentDownloadProgress = {
    [id]: { progress, resource, id }
  };
};

/**
 * @Description Used to get current upload progress.
 * @param {String} id
 */
export const getCurrentUploadProgress = id => currentUploadProgress && currentUploadProgress[id];

/**
 * @description Used to get current download progress.
 * @param {String} id
 */
export const getCurrentDownloadProgress = id =>
  currentDownloadProgress && currentDownloadProgress[id] && currentDownloadProgress[id]["progress"];

/**
 * @description Merge current uploaded data with original data with sorting.
 * @param {*} data
 */
export const mergeCurrentUploadData = async (data, { fireListener } = {}) => {
  console.log("333333333333333333333", data, fireListener);
  await waitForUploadedData();
  if (!Array.isArray(data)) {
    data = [data];
  }
  let { fileType, secureType } = data[0] || {}; // assuming all files in single event of same type
  let isVaultType = secureType === SECURE_TYPES.VAULT;
  let localFileSort = void 0,
    resourceFileSort = void 0,
    localListenerKey = void 0;
  if (fileType === "gallery") {
    localFileSort = UPLOAD_GALLERY_SORT;
    resourceFileSort = GALLERY_ALL_SORT;
    localListenerKey = isVaultType ? VAULT_GALLERY_LOCAL_CHANGE_KEY : GALLERY_ALL_LOCAL_CHANGE_KEY;
  } else if (fileType === "doc") {
    localFileSort = UPLOAD_DOCS_SORT;
    resourceFileSort = DOCS_ALL_SORT;
    localListenerKey = isVaultType ? VAULT_DOCS_LOCAL_CHANGE_KEY : DOCS_ALL_LOCAL_CHANGE_KEY;
  } else if (fileType === "audio") {
    localFileSort = UPLOAD_MUSIC_SORT;
    resourceFileSort = MUSIC_ALL_SORT;
    localListenerKey = MUSIC_ALL_LOCAL_CHANGE_KEY;
  }
  data.sort((a, b) => getSortValue(a, b, localFileSort));
  removeImageFromGalleryFolders(data);
  let insertArray = [];
  for (let dataToMerge of data) {
    dataToMerge = { ...dataToMerge };
    let { status, fileType, orientedHeight, orientedWidth } = dataToMerge;
    if (status !== "success" && (fileType !== "gallery" || (orientedHeight && orientedWidth))) {
      console.log("4444444444444444444444444", dataToMerge);
      insertArray.push(formatLocalDataToResourceData(dataToMerge));
    }
  }
  if (fireListener && insertArray.length) {
    console.log("66666666666666", fireListener, insertArray);
    fireLocalDataChangeListener({
      key: localListenerKey,
      localChanges: {
        insert: insertArray,
        sort: resourceFileSort,
        source: "upload"
      }
    });
    fireLocalDataChangeListener({
      key: UPLOAD_QUEUE_LOCAL_CHANGE_KEY,
      localChanges: {
        insert: insertArray,
        source: "upload"
      }
    });
  }
  /* UPLOAD_GALLERY_SORT is used bcoz
     allUploadedData is kept with gallery
     sort by default. In case of music,
     we sort it again.
  */
  if (!(await isMetadataPopulatedForCurrentSession())) {
    allUploadedData = mergeTwoSortedArrays(allUploadedData, data, UPLOAD_GALLERY_SORT, "resource");
  }
};

/**
 * @description fire local data change listener on removed item from upload queue.
 * @param {{*}} resource
 */
export const removeResourceFromUploadQueue = ({ resource } = {}) => {
  if (resource) {
    // NON_UPLOAD_LOCAL_CHANGE_KEY -> Used to remove resource from non upload view
    fireLocalDataChangeListener({
      key: [NON_UPLOAD_LOCAL_CHANGE_KEY, UPLOAD_QUEUE_LOCAL_CHANGE_KEY],
      localChanges: { _id: resource, remove: true, source: "upload" }
    });
  }
};

/**
 * @description fire local data change listener on removed item from download queue.
 * @param {{*}} resource
 */
export const removeResourceFromDownloadQueue = ({ resource } = {}) => {
  if (resource) {
    fireLocalDataChangeListener({
      key: DOWNLOAD_QUEUE_LOCAL_CHANGE_KEY,
      localChanges: { _id: resource, remove: true, source: "download" }
    });
  }
};

/**
 * @description On upload success update the status and fire local data change listener.
 * @param {*} resource
 */
export const updateUploadDataOnSuccess = ({ resource } = {}, { fireListener } = {}) => {
  if (resource) {
    let matchingFile = allUploadedData.find(item => item.resource === resource);
    if (matchingFile) {
      matchingFile["status"] = "success";
    }
    if (fireListener) {
      fireLocalDataChangeListener({
        key: METADATA_TABLE,
        localChanges: { _id: resource, changes: { status: "success", uploaded: true }, source: "upload" }
      });
    }
  }
};

/**
 * @description Removed uploaded data and fire local data change listener.
 * called when current uploading file fails to upload
 * @param {*} resource
 */
export const removeCurrentUploadData = ({ resource } = {}, { fireListener } = {}) => {
  if (resource) {
    let matchingIndex = allUploadedData.findIndex(item => item.resource === resource);
    if (matchingIndex >= 0) {
      allUploadedData.splice(matchingIndex, 1);
    }
    if (fireListener) {
      fireLocalDataChangeListener({
        key: METADATA_TABLE,
        localChanges: { _id: resource, remove: true, source: "upload" }
      });
    }
  }
};

/**
 * @description Used to sort images on the basis of resource_lastModified..
 */
export const getSortedImages = (images = []) => {
  return images.sort((a, b) => {
    if (!a.resource_lastModified || !b.resource_lastModified) {
      return 0;
    }
    return -(a.resource_lastModified - b.resource_lastModified);
  });
};

/**
 * @description Used to recursively fetch assets for folders.
 * @param {*} folderInfo
 */
export const fetchAssetForFolderRecursively = async (
  folderInfo,
  { limit, endCursor, maxCount, skipData, afterFetch, oldFolderInfo }
) => {
  addLog && addLog(`fetchAssetForFolderRecursively ${folderInfo.groupName}`);
  let { result = [], page_info } = await fetchAssetsForFolder(
    {
      query: {
        addOnFilter: {
          groupId: folderInfo.groupId,
          groupName: folderInfo.groupName
        },
        limit,
        endCursor
      }
    },
    addLog
  );
  addLog && addLog(`fetchAssetForFolderRecursively Done ${folderInfo.groupName} Fetched ${result.length}`);
  if (result.length) {
    let { images } = folderInfo;
    result.forEach(doc => {
      let { node: { timestamp, image: { uri, size = 0 } = {} } = {} } = doc;
      size = Number(size) || 0;
      folderInfo.groupSize = (folderInfo.groupSize || 0) + size;
      if (oldFolderInfo) {
        oldFolderInfo.groupSize = (oldFolderInfo.groupSize || 0) + size;
      }
      if (uri) {
        if (!folderInfo.groupImage) {
          folderInfo.groupImage = uri;
          if (oldFolderInfo) {
            oldFolderInfo.groupImage = uri;
          }
        }
        images[uri] = skipData ? {} : { ...doc, resource_lastModified: timestamp && timestamp * 1000 };
      }
    });
    if (maxCount && Object.keys(images).length >= maxCount) {
      return;
    }
  }
  afterFetch && afterFetch(page_info);
  if (page_info && page_info.has_next_page) {
    await fetchAssetForFolderRecursively(folderInfo, {
      limit,
      maxCount,
      endCursor: page_info.end_cursor,
      skipData,
      afterFetch,
      oldFolderInfo
    });
  }
};

/**
 * @description Used To update gallery folder view.
 */
const updateGalleryFolderView = () => {
  let galleryFoldersRef = viewRefs["galleryFolders"];
  galleryFoldersRef && galleryFoldersRef.loadData && galleryFoldersRef.loadData();
  if (fetchingGalleryFolders) {
    let timeout = setTimeout(_ => {
      clearTimeout(timeout);
      updateGalleryFolderView();
    }, 2000);
  }
};

/**
 * @description Used to populate all gallery folders.
 */
export const populateGalleryFolders = async () => {
  addLog && addLog(`populateGalleryFolders start >>> ${fetchingGalleryFolders}`);
  if (fetchingGalleryFolders) {
    refetchingGalleryFoldersRequired = true;
    addLog && addLog(`populateGalleryFolders end >>> ${fetchingGalleryFolders}`);
    return;
  }
  refetchingGalleryFoldersRequired = false;
  fetchingGalleryFolders = true;
  updateGalleryFolderView();
  try {
    let reloadData = Object.keys(galleryFolders).length;
    let newGalleryFolders = reloadData ? {} : galleryFolders;
    addLog && addLog(`fetchGalleryFolders start`);
    let fetchedGalleryFolders = (await fetchGalleryFolders()) || [];
    addLog && addLog(`fetchGalleryFolders done >>>> ${fetchedGalleryFolders.length}`);
    fetchedGalleryFolders.forEach(folder => {
      let newFolderInfo = {
        ...folder,
        groupImage: void 0,
        images: {},
        status: "pending"
      };
      let folderId = folder.groupId;
      newGalleryFolders[folderId] = newFolderInfo;
      if (reloadData) {
        let oldFolderInfo = galleryFolders[folderId];
        if (!oldFolderInfo) {
          galleryFolders[folderId] = { ...newFolderInfo };
        } else if (Object.keys(oldFolderInfo.images).length) {
          oldFolderInfo.status = "pending";
        }
      }
    });
    if (reloadData) {
      for (let key in galleryFolders) {
        if (!newGalleryFolders[key]) {
          delete galleryFolders[key];
        }
      }
    }
    addLog && addLog(`fetchAssetForFolder start`);
    for (let folderId in newGalleryFolders) {
      let folderInfo = newGalleryFolders[folderId];
      try {
        await fetchAssetForFolderRecursively(folderInfo, {
          limit: 1000,
          skipData: true,
          oldFolderInfo: galleryFolders[folderId]
        });
      } catch (err) {
        addLog && addLog(`fetchAssetForFolderRecursively Error >>> ${err.message}`);
      }
      folderInfo.status = "completed";
      galleryFolders[folderId] = folderInfo;
    }
    addLog && addLog(`fetchAssetForFolder done`);
  } catch (err) {
    addLog && addLog(`populateGalleryFolders Error >>> ${err.message}`);
    console.warn("Error in fetch gallery folders>>>>>", err.message);
  }
  addLog && addLog(`populateGalleryFolders end >>> ${fetchingGalleryFolders}`);
  fetchingGalleryFolders = false;
  refetchingGalleryFoldersRequired && populateGalleryFolders();
};

/**
 * @description used to get all gallery folders.
 */
export const getGalleryFolders = ({ fetchImages } = {}) => {
  let data = [];
  let loadingMore = false;
  Object.keys(galleryFolders).forEach(folderId => {
    let { groupId, groupName, status, images, groupImage, groupSize } = galleryFolders[folderId];
    let groupCount = Object.keys(images).length;
    if (status === "completed" && groupCount === 0) {
      return;
    }
    if (!loadingMore && status !== "completed") {
      loadingMore = true;
    }
    let folderData = {
      groupId,
      groupName,
      groupImage,
      groupCount,
      groupSize
    };
    if (fetchImages) {
      folderData.images = images;
    }
    data.push(folderData);
  });
  data = orderBy(data, [doc => doc.groupName.toLowerCase()], ["asc"]);
  return { result: data, loadingMore: fetchingGalleryFolders || loadingMore };
};

/**
 * @description used to Fetch group data.
 */
const getGroupMetadataData = async ({ uri, query, uid, user }, { filter } = {}) => {
  let subscribe = uri && uri.props && uri.props.subscribe;
  let cache = (uri && uri.props && uri.props.cache) || subscribe;
  let isIgnoreResult = uri && uri.props && uri.props.isIgnoreResult;
  const view = {
    ...query,
    table: MetadataTable,
    filter: {
      ...filter,
      deleted: false,
      secureType: SECURE_TYPES.DEFAULT,
      "group._id": user && user.group && user.group._id
    }
  };
  let data = await fetchFromCache({
    view,
    uid,
    subscribe,
    fromCache: cache,
    schema: {
      postedOn: "date"
    },
    isIgnoreResult
  });
  if (query.mergeAvatar) {
    data = await modifyDataWithAvatars({ user, data });
  }
  return data;
};

const modifyDataWithAvatars = async ({ user: { group = {} } = {}, data = {} }) => {
  const resources = data.result;
  try {
    if (Array.isArray(resources) && group && group._id) {
      const { result: groupMembersData = [] } = await find({
        view: {
          table: GroupMembersTable,
          filter: { "group._id": group._id },
          sort: { email_lower: 1 }
        }
      });
      let groupAvatars = {};
      for (let memberInfo of groupMembersData) {
        let {
          member: { _id: userId, name: userName } = {},
          avatar: { initials, color } = {},
          profile_pic
        } = memberInfo;
        // convert this similar to collection's avatar data
        groupAvatars[userId] = [userName, initials, color, profile_pic];
      }
      if (Platform.OS !== "web") {
        // for mobile
        data.result = resources.map(resource => {
          let userId = resource._createdBy && resource._createdBy._id;
          const avatar = groupAvatars[userId] || [];
          return {
            ...resource,
            avatar: { initials: avatar[1], color: avatar[2], profile_pic: avatar[3] }
          };
        });
      }
      data.specialProps = { ...data.specialProps, groupAvatars };
    }
  } catch (error) {}
  return data;
};

/**
 *@description Used to fetch data from metadata table.
 */
const getMetadataData = async ({ uri, uid, query, user }, { source, secureType, matchRequired } = {}) => {
  let subscribe = uri && uri.props && uri.props.subscribe;
  let cache = (uri && uri.props && uri.props.cache) || subscribe;
  let isIgnoreResult = uri && uri.props && uri.props.isIgnoreResult;
  let filter = { type: source };
  let snapshotFilter = void 0;
  if (source === "gallery") {
    filter = { gallery: true };
    snapshotFilter = {
      gallery: true,
      "_createdBy.firebaseUid": user.uid,
      secureType: secureType || SECURE_TYPES.DEFAULT,
      _updatedOn: { $gte: new Date() }
    };
  }
  const view = {
    ...query,
    table: MetadataTable,
    filter: {
      ...filter,
      deleted: false,
      "_createdBy.firebaseUid": user.uid,
      secureType: secureType || SECURE_TYPES.DEFAULT
    }
  };
  if (snapshotFilter) {
    view.snapshotFilter = snapshotFilter;
  }

  let queryProps = { view, subscribe, fromCache: cache, uid, isIgnoreResult, schema: { uploadedOn: "date" } };
  if (matchRequired) {
    queryProps.matchRequired = matchRequired;
  }
  let data = await fetchFromCache(queryProps);
  const { params, limit } = view;
  if (
    params &&
    (params.excludeGroup || (params.excludeCollectionItems && params.collection && params.collection._id))
  ) {
    let result = data && data.result;
    let length = result && result.length;
    if (limit !== undefined && length && length >= limit) {
      data["hasNext"] = true;
      data["lastDoc"] = result[length - 1];
      data["dataIds"] = result.map(doc => ({ _id: doc._id }));
    }
    //we need to exclude collection items from this documents
    if (result && Array.isArray(result)) {
      if (params.excludeGroup) {
        result = result.filter(row => !(row.group && row.group._id));
      } else {
        let collectionId = params.collection._id;
        result = result.filter(
          row =>
            row && (!row.collections || !Array.isArray(row.collections) || row.collections.indexOf(collectionId) === -1)
        );
      }
      data.result = result;
    }
  }

  if (query.mergeAvatar) {
    data = await modifyDataWithAvatars({ user, data });
  }
  return data;
};

/**
 * @description Used to modify collection result.
 * @param {*} result
 */
export const modifyCollectionResult = (result, { params, user, skipOwnerAndModeText }) => {
  let { collection, excludeCollection, excludeReadOnlyCollections } = params || {};
  let modifiedResult = [];
  result.forEach(row => {
    if (excludeCollection && collection && collection._id && row._id === collection._id) {
      return;
    }
    if (!skipOwnerAndModeText) {
      let { owner, modeText } = getOwnerAndModeText(row, { user });
      if (excludeReadOnlyCollections && !owner && row.colloborator_mode === "read") {
        return;
      }
      row = {
        ...row,
        owner,
        modeText
      };
    }
    modifiedResult.push(row);
  });
  return modifiedResult;
};

/**
 * @description Used to fetch collection data.
 */
const getCollectionData = async ({ uri, query, user, uid }, { source, schema, skipOwnerAndModeText } = {}) => {
  let filter = void 0;
  if (source === "playlist" || source === "artist" || source === "vaultAlbum" || source === "vaultSet") {
    filter = { "_createdBy.firebaseUid": user.uid, type: source, deleted: false };
  } else {
    filter = {
      collabrators: { $contains: user.uid },
      deleted: false,
      system: false,
      [source === "Shared" ? "mode" : "type"]: source
    };
  }
  let subscribe = uri && uri.props && uri.props.subscribe;
  const view = {
    ...query,
    filter,
    table: CollectionTable,
    sort: { lower_name: 1, _createdOn: 1 }
  };
  let queryProps = {
    view,
    uid,
    subscribe,
    fromCache: subscribe
  };
  if (schema) {
    queryProps.schema = schema;
  }
  let data = await fetchFromCache(queryProps);
  let { result } = data;
  result = modifyCollectionResult(result, { params: query.params, user, skipOwnerAndModeText });
  return { ...data, result };
};

/**
 * @description Used to update uri information iteratively.
 * @param {*} data
 * @param {Number} startIndex
 */
const populateUriInfoIteratively = async (data, startIndex = 0) => {
  if (startIndex >= data.length) {
    return;
  }
  let nextIndex = startIndex + 1000;
  if (data.length < nextIndex) {
    nextIndex = data.length;
  }
  let uris = [];
  for (let i = startIndex; i < nextIndex; i++) {
    let { node: { image: { uri } = {} } = {} } = data[i] || {};
    if (uri && uriInfoMap[uri] === undefined) {
      uris.push(uri.replace("ph://", ""));
    }
  }
  if (uris.length) {
    let urisSizeResult = await fetchAssetSize(uris);
    for (let uri in urisSizeResult) {
      uriInfoMap[`ph://${uri}`] = urisSizeResult[uri];
    }
  }
  await populateUriInfoIteratively(data, nextIndex);
};

/**
 * @description Used to populate uri information.
 * @param {*} data
 */
export const populateUriInfo = async data => {
  if (!data || !data.length || Platform.OS !== "ios") {
    return;
  }
  await populateUriInfoIteratively(data);
};

/**
 * @description Used to get uri information.
 * @param {String} uri
 */
export const getUriInfo = uri => {
  return uri ? uriInfoMap[uri] : void 0;
};

/**
 * @description Used to fetch local device data.
 */
export const getDeviceData = async ({ groupNames, maxCount, limit = 1000 } = {}) => {
  let deviceData = {};
  try {
    let deviceGalleryFolders = (await fetchGalleryFolders()) || [];
    deviceGalleryFolders.forEach(folder => {
      if (!groupNames || groupNames.indexOf(folder.groupName) !== -1) {
        deviceData[folder.groupId] = {
          ...folder,
          images: {}
        };
      }
    });
    for (let folderId in deviceData) {
      let folderInfo = deviceData[folderId];
      try {
        await fetchAssetForFolderRecursively(folderInfo, { limit, maxCount });
      } catch (err) {
        pushLog && pushLog(`getDeviceData -- fetchAssetForFolderRecursively Error >>> ${err.message}`);
      }
    }
  } catch (err) {
    pushLog && pushLog(`getDeviceData Error >>> ${err.message}`);
  }
  return deviceData;
};

/**
 * @description used to auto Backup on initial login.
 */
export const startInitialAutoBackup = async () => {
  let deviceData = await getDeviceData({
    groupNames: ["Camera", "Camera Roll", "All Photos", "Recently Added", "Recents"],
    limit: 100,
    maxCount: 5
  });
  let imagesArray = [];
  for (let c_id in deviceData) {
    let { images } = deviceData[c_id];
    if (images) {
      images = getSortedImages(Object.values(images));
      for (let image of images) {
        imagesArray.push(image);
        if (imagesArray.length >= 5) {
          break;
        }
      }
    }
    if (imagesArray.length >= 5) {
      break;
    }
  }
  if (imagesArray.length) {
    await invokeUploadAction({
      props: {
        getSelectedData: () => imagesArray,
        action: { uploadType: "gallery", uploadSource: "initialAutoBackup", background: true }
      }
    });
  }
};

/**
 * @description Used to count uploading resources.
 */
export const getUploadCount = async () => {
  try {
    return await getUploadCountSqlite();
  } catch (err) {
    return 0;
  }
};

const nativeViews = {
  _fetchProfileGallery: fetchProfileGallery,
  _fetchUploadDocument: fetchDocuments,
  _fetchUploadMusic: fetchMusic,
  _fetchGalleryFolders: async () => {
    let result = await fetchGalleryFolders();
    return { result: result || [] };
  },
  _fetchNativeSetting: async () => {
    let result = getSettingInfo();
    if (!Object.keys(result).length) {
      await ensureSettingInfo();
      result = getSettingInfo();
    }
    return { result: [result] };
  },
  appVersion: async ({ query, uid }) => {
    const view = {
      ...query,
      table: AppVersionTable,
      filter: {
        type: Platform.OS,
        brand: brandName()
      }
    };
    let data = await fetchFromCache({ view, uid });
    return data && data.result && data.result.length ? data.result[0] : void 0;
  },

  /**
   * @description: Fetch all deleted items of the user using method fetchFromCache.
   */
  trashedData: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    let sqlite = query.sqlite;
    const view = {
      ...query,
      table: MetadataTable,
      filter: {
        deleted: true,
        empty: false,
        secureType: SECURE_TYPES.DEFAULT,
        "_createdBy.firebaseUid": user.uid
      }
    };
    if (sqlite && (await isMetadataPopulatedForCurrentSession())) {
      delete view.filter.empty;
    }
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe, schema: { deletedOn: "date" } });
  },

  /**
   * @description: Fetch all failed items of the user using method fetchFromCache.
   */
  failedData: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: RemovedMetadataTable,
      filter: {
        uploaded: false,
        "_createdBy.firebaseUid": user.uid
      }
    };
    return await fetchFromCache({
      view,
      uid,
      subscribe,
      fromCache: subscribe,
      schema: { _createdOn: "date" }
    });
  },

  nonUploadedData: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: MetadataTable,
      filter: {
        "_createdBy.firebaseUid": user.uid,
        uploaded: false
      }
    };
    return await fetchFromCache({
      view,
      uid,
      subscribe,
      fromCache: subscribe,
      schema: { _createdOn: "date" }
    });
  },

  /**
   * @description fetch all infected items of the user using method fetchFromCache.
   */
  infectedData: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: RemovedMetadataTable,
      filter: {
        infected: true,
        "_createdBy.firebaseUid": user.uid
      }
    };
    return await fetchFromCache({
      view,
      uid,
      subscribe,
      fromCache: subscribe,
      schema: { _createdOn: "date" }
    });
  },

  /**
   * @description: Fetch all uploading items of the user using method fetchFromCache.
   */
  uploadQueue: async ({ query, uid, user }) => {
    const view = {
      ...query,
      table: MetadataTable,
      filter: {
        "_createdBy.firebaseUid": user.uid
      }
    };
    return await fetchFromCache({ view, uid });
  },

  /**
   * @description: Fetch all downloading items of the user using method fetchFromCache.
   */
  downloadQueue: async ({ query, uid, user }) => {
    const view = {
      ...query,
      table: MetadataTable,
      filter: {
        "_createdBy.firebaseUid": user.uid
      }
    };
    return await fetchFromCache({ view, uid });
  },

  /**
   * @description: Fetch all devices from which the user is Log-In.
   */
  allDevice: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: DeviceTable,
      filter: {
        "user.firebaseUid": user.uid,
        logout: false
      }
    };
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe });
  },
  contactDataForDevice: async ({ query }) => {
    let subscribe = false;
    const view = {
      ...query,
      table: ContactTable
    };
    return await fetchFromCache({ view, subscribe, fromCache: subscribe });
  },

  /**
   * @description Fetch contact details of the user.
   */
  contactDetail: async ({ uri, query, uid }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: ContactTable
    };
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe });
  },

  /**
   * @description Fetch contact for the user.
   */
  contactData: async ({ uri, query, uid, user, extraFilters = { deleted: false } }) => {
    let { dataParams = {} } = query;
    let filter = {
      user: user.uid,
      deleted: false,
      ...extraFilters
    };
    let ftsString;
    if (dataParams && dataParams.ftsString) {
      filter.ftsString = dataParams.ftsString;
      ftsString = dataParams.ftsString;
    }
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: ContactTable,
      filter: filter
    };
    return await fetchFromCache({
      fetch: ftsString
        ? () => {
            let queryProps = {
              data: {
                ...query,
                dataParams: {
                  ftsString
                }
              }
            };
            return urlFetch({
              url: urls["searchContacts"],
              props: queryProps
            });
          }
        : find,
      view,
      uid,
      subscribe,
      fromCache: subscribe
    });
  },

  /**
   * @description: Fetch data of current day from previous years of the user using method fetchFromCache.
   */
  memoryOfDay: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: MetadataTable,
      filter: {
        deleted: false,
        type: "image",
        "_createdBy.firebaseUid": user.uid,
        thumbnail_uploaded: true,
        defaultThumbnail: false,
        converted_jpeg_uploaded: true,
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: { $lt: new Date().getFullYear() },
        secureType: SECURE_TYPES.DEFAULT
      }
    };
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe });
  },

  /**
   * @description Fetch members of the collection.
   */
  groupMembers: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: GroupMembersTable,
      filter: {
        "group._id": user && user.group && user.group._id
      },
      sort: { email_lower: 1 }
    };
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe, schema: { _expireOn: "date" } });
  },

  /**
   * @description Fetch group gallery data by passing props to getGroupMetadataData.
   */
  groupGallery: async props => {
    return await getGroupMetadataData(props, { filter: { gallery: true } });
  },

  /**
   * @description Fetch group docs data by passing props to getGroupMetadataData.
   */
  groupDocuments: async props => {
    return await getGroupMetadataData(props, { filter: { type: "doc" } });
  },

  /**
   * @description Fetch gallery data by passing props to getMetadataData.
   */
  allGallery: async props => {
    return await getMetadataData(props, { source: "gallery", matchRequired: true });
  },

  /**
   * @description Fetch story highlights data.
   */
  allHighlights: async ({ uri, uid, query, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    let cache = (uri && uri.props && uri.props.cache) || subscribe;
    let isIgnoreResult = uri && uri.props && uri.props.isIgnoreResult;
    let from = (query && query.params.from) || void 0;
    let to = (query && query.params.to) || void 0;
    if (!from || !to) {
      return {};
    }
    const view = {
      ...query,
      table: MetadataTable,
      filter: {
        deleted: false,
        type: "image",
        "_createdBy.firebaseUid": user.uid,
        completed: true,
        defaultThumbnail: false,
        secureType: SECURE_TYPES.DEFAULT,
        $and: [{ resource_lastModified: { $gte: from } }, { resource_lastModified: { $lte: to } }]
      }
    };
    return await fetchFromCache({
      view,
      subscribe,
      fromCache: cache,
      uid,
      isIgnoreResult
    });
  },

  /**
   * @description Fetch docs data by passing props to getGroupMetadataData.
   */
  allDocuments: async props => {
    return await getMetadataData(props, { source: "doc" });
  },

  /**
   * @description Fetch music data by passing props to getGroupMetadataData.
   */
  allMusic: async props => {
    return await getMetadataData(props, { source: "audio" });
  },

  /**
   * @description Fetch vault gallery data by passing props to getMetadataData.
   */
  vaultGallery: async props => {
    return await getMetadataData(props, { source: "gallery", matchRequired: true, secureType: SECURE_TYPES.VAULT });
  },

  /**
   * @description Fetch vault docs data by passing props to getMetadataData.
   */
  vaultDocuments: async props => {
    return await getMetadataData(props, { source: "doc", secureType: SECURE_TYPES.VAULT });
  },

  /**
   * @description Fetch archive gallery data by passing props to getMetadataData.
   */
  archiveGallery: async props => {
    return await getMetadataData(props, { source: "gallery", matchRequired: true, secureType: SECURE_TYPES.ARCHIVE });
  },

  /**
   * @description Fetch archive docs data by passing props to getMetadataData.
   */
  archiveDocuments: async props => {
    return await getMetadataData(props, { source: "doc", secureType: SECURE_TYPES.ARCHIVE });
  },

  /**
   * @description Fetch archive music data by passing props to getMetadataData.
   */
  archiveMusic: async props => {
    return await getMetadataData(props, { source: "audio", secureType: SECURE_TYPES.ARCHIVE });
  },

  /**
   * @description Fetch all reported issues.
   */
  allIssues: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: IssuesTable,
      filter: {
        "_createdBy.firebaseUid": user.uid
      },
      sort: { _createdOn: -1, _id: -1 }
    };
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe, schema: { _createdOn: "date" } });
  },

  /**
   * @description Delete contact from database.
   */
  deletedContact: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      ...query,
      table: ContactTable,
      filter: {
        deleted: true,
        user: user.uid
      }
    };
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe });
  },
  mergeDuplicateContact: getDuplicateContact,
  searchGallery: searchMetadata,
  searchDocuments: searchMetadata,
  searchMusic: searchMetadata,

  /**
   * @description Fetch collaborators of the collection.
   */
  collaborators: async ({ uri, query, uid }) => {
    let { params } = query;
    let subscribe = uri && uri.props && uri.props.subscribe;
    let CollectionId = params && params.collection && params.collection._id;
    const view = {
      ...query,
      filter: {
        _id: CollectionId
      },
      table: CollectionTable
    };
    return await fetchFromCache({ view, uid, subscribe, fromCache: subscribe });
  },

  /**
   * @description Fetch user's all space information.
   */
  userSpace: async ({ uri, user, uid }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    const view = {
      table: UserQuotaTable,
      filter: {
        firebaseUid: user.uid
      }
    };
    return await fetchFromCache({ view, subscribe, fromCache: subscribe, uid });
  },

  /**
   * @description Fetch all albums of the user.
   */
  albums: async props => {
    return await getCollectionData(props, { source: "album", schema: { minDate: "date", maxDate: "date" } });
  },
  vaultAlbums: async props => {
    return await getCollectionData(props, { source: "vaultAlbum", schema: { minDate: "date", maxDate: "date" } });
  },
  /**
   * @description Fetch duplicate resources.
   */
  duplicateResources: async ({ uri, uid, query, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    let cache = (uri && uri.props && uri.props.cache) || subscribe;
    let isIgnoreResult = uri && uri.props && uri.props.isIgnoreResult;
    const view = {
      ...query,
      table: MetadataTable,
      filter: {
        isDuplicate: true,
        "_createdBy.firebaseUid": user.uid,
        deleted: false,
        secureType: SECURE_TYPES.DEFAULT
      },
      sort: DUPLICATE_GALLERY_SORT
    };
    return await fetchFromCache({
      view,
      subscribe,
      fromCache: cache,
      uid,
      isIgnoreResult,
      schema: { duplicateMarkedOn: "date" }
    });
  },

  /**
   * @description: Fetch all locations of the user using method fetchFromCache.
   */
  places: async props => {
    const { query, uid, user, uri } = props;
    let subscribe = uri && uri.props && uri.props.subscribe;
    let cache = (uri && uri.props && uri.props.cache) || subscribe;
    let { view: viewName, limit, fields, lastDoc } = query;
    let filter = { deleted: false, "_createdBy.firebaseUid": user.uid, secureType: SECURE_TYPES.DEFAULT };
    const view = {
      ...query,
      table: "Places", // used just for cache key in cache manager, (if changing this then change localchangekey also for this view)
      filter
    };
    let data = {
      groupBy: "city_lower",
      aggsOptions: {
        fields,
        limit,
        skip: lastDoc?.city_lower
      },
      view: viewName
    };
    let fetchResult = await fetchFromCache({
      fetch: () => {
        let queryProps = {
          data: data
        };
        return urlFetch({
          url: urls["placeCollection"],
          props: queryProps
        });
      },
      view,
      uid,
      subscribe,
      fromCache: cache
    });
    let { result = [] } = fetchResult || {};
    result = result.map(doc => ({ ...doc, _id: doc.city_lower, name: doc.city_lower, itemCount: doc.count }));
    return { ...fetchResult, result };
  },

  /**
   * @description: Fetch all device folders of the user using method fetchFromCache.
   */
  deviceFolder: async props => {
    try {
      const { query, uid, user, uri } = props;
      let subscribe = uri && uri.props && uri.props.subscribe;
      let cache = (uri && uri.props && uri.props.cache) || subscribe;
      let { view: viewName, limit, fields, lastDoc, filter } = query;
      filter = { ...filter, deleted: false, "_createdBy.firebaseUid": user.uid, secureType: SECURE_TYPES.DEFAULT };
      const view = {
        ...query,
        table: "DeviceFolder", // used just for cache key in cache manager, (if changing this then change localchangekey also for this view)
        filter
      };
      let data = {
        groupBy: "deviceFolder",
        aggsOptions: {
          fields,
          limit,
          skip: lastDoc?.deviceFolder
        },
        view: viewName
      };
      let fetchResult = await fetchFromCache({
        fetch: () => {
          let queryProps = {
            data: data
          };
          return urlFetch({
            url: urls["deviceFolderCollection"],
            props: queryProps
          });
        },
        view,
        uid,
        subscribe,
        fromCache: cache
      });
      let { result = [] } = fetchResult || {};
      result = result.map(doc => ({
        ...doc,
        _id: doc.deviceFolder,
        name: doc.deviceFolder.substring(doc.deviceFolder.lastIndexOf("/") + 1),
        itemCount: doc.count
      }));
      return { ...fetchResult, result };
    } catch (err) {
      // FIXME: Remove try catch
      // Having issue on hero-stag because of elasticDB mapping
      // showMessage && showMessage("UNABLE TO LOAD LIBRARY TAB DATA");
      return {};
    }
  },

  /**
   * @description Fetch sets by passing props to getCollectionData method.
   */
  sets: async props => {
    return await getCollectionData(props, { source: "set" });
  },
  vaultSets: async props => {
    return await getCollectionData(props, { source: "vaultSet" });
  },
  /**
   * @description Fetch playlists by passing props to getCollectionData method.
   */
  playlists: async props => {
    return await getCollectionData(props, { source: "playlist", skipOwnerAndModeText: true });
  },
  /**
   * @description Fetch artists by passing props to getCollectionData method.
   */
  artists: async props => {
    return await getCollectionData(props, { source: "artist", skipOwnerAndModeText: true });
  },
  /**
   * @description Fetch shared collections by passing props to getCollectionData method.
   */
  SharedCollections: async props => {
    return await getCollectionData(props, { source: "Shared", schema: { minDate: "date", maxDate: "date" } });
  },

  /**
   * @description Fetch collection items.
   */
  collectionItems: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    let cache = (uri && uri.props && uri.props.cache) || subscribe;
    let isIgnoreResult = uri && uri.props && uri.props.isIgnoreResult;
    let { filter = {}, params = {}, origin } = query;
    filter = { ...filter, deleted: false };
    filter.secureType = origin === "vault" ? SECURE_TYPES.VAULT : SECURE_TYPES.DEFAULT;
    let collectionId = undefined;
    if (filter && filter["collection._id"]) {
      collectionId = filter["collection._id"];
      delete filter["collection._id"];
      filter.collections = { $contains: collectionId };
    }
    const view = {
      ...query,
      filter,
      table: CollectionItemsTable
    };
    let data = await fetchFromCache({ view, uid, subscribe, fromCache: cache, isIgnoreResult });
    let { result } = data || {};
    if (result) {
      result = normalizeCollectionItems({
        result,
        user,
        collectionId,
        collection: params && params.collection
      });
    }
    return { ...data, result };
  },

  /**
   * @description: Fetch all items of particular location of the user using method fetchFromCache.
   */
  placeItems: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    let cache = (uri && uri.props && uri.props.cache) || subscribe;
    let isIgnoreResult = uri && uri.props && uri.props.isIgnoreResult;
    let { filter = {} } = query;
    filter = { ...filter, deleted: false, "_createdBy.firebaseUid": user.uid };
    filter.secureType = SECURE_TYPES.DEFAULT;
    const view = {
      ...query,
      filter,
      table: MetadataTable
    };
    let data = await fetchFromCache({ view, uid, subscribe, fromCache: cache, isIgnoreResult });
    let { result } = data || {};
    if (result) {
      result = normalizeCollectionItems({
        result,
        user
      });
    }
    return { ...data, result };
  },
  /**
   * @description: Fetch all items of particular location of the user using method fetchFromCache.
   */
  deviceFolderItems: async ({ uri, query, uid, user }) => {
    let subscribe = uri && uri.props && uri.props.subscribe;
    let cache = (uri && uri.props && uri.props.cache) || subscribe;
    let isIgnoreResult = uri && uri.props && uri.props.isIgnoreResult;
    let { filter = {} } = query;
    filter = { ...filter, deleted: false, "_createdBy.firebaseUid": user.uid, secureType: SECURE_TYPES.DEFAULT };
    const view = {
      ...query,
      filter,
      table: MetadataTable
    };
    let data = await fetchFromCache({ view, uid, subscribe, fromCache: cache, isIgnoreResult });
    let { result } = data || {};
    if (result) {
      result = normalizeCollectionItems({
        result,
        user
      });
    }
    return { ...data, result };
  }
};

/**
 * @description Used to get collection mode.
 * @param {*} item
 */
export const getModeText = item => {
  let mode = item.mode;
  let modeText = mode;
  let creatorName = item._createdBy && item._createdBy.name;
  if (mode === "Private") {
    modeText = I18N.t("private");
  } else if (mode === "Shared") {
    if (item.owner) {
      modeText = I18N.t("shared");
    } else {
      modeText = I18N.t("sharedByName");
      modeText = modeText.replace("__name__", creatorName);
    }
  }
  return modeText || item.modeText;
};

/**
 * @description Used to get owner and mode of the collection.
 * @param {*} item
 * @param {*} user
 */
export const getOwnerAndModeText = (item, { user }) => {
  let owner = item._createdBy && item._createdBy.firebaseUid === user.uid;
  let modeText = getModeText({ mode: item.mode, _createdBy: item._createdBy, owner });
  return { owner, modeText };
};

/**
 * @description Populate the collection's collaborators data.
 * @param {*} param0
 */
export const normalizeCollaboration = ({ data, user, is_OwnerLogin }) => {
  let { collabrators = [], collaboratorInfo = {}, collaboratorData = {} } = data || {};
  let ownerId = data && data._createdBy && data._createdBy.firebaseUid;
  return collabrators.map(rowId => {
    let rowData = {
      uid: rowId,
      ownerLogin: is_OwnerLogin,
      myProfile: user && user.uid === rowId,
      owner: rowId === ownerId,
      name: collaboratorInfo[rowId],
      collaboratorData: collaboratorData[rowId] || []
    };
    return rowData;
  });
};

/**
 * @description Populate collection data also check if current user is owner or not of the collection.
 * @param {*} param0
 */
export const normalizeCollectionItems = ({ result, user, collectionId, collection }) => {
  //url is added due to document slider
  let collectionFields = void 0;
  if (collection) {
    let { owner: collectionOwner, mode } = collection;
    collectionFields = {
      collectionOwner,
      sharedCollection: mode === "Shared"
    };
  }
  result = result.map(doc => {
    let newDoc = {
      ...doc,
      ...collectionFields,
      resource: { _id: doc._id },
      collection: collectionId ? { _id: collectionId } : void 0,
      height: doc.converted_height || doc.thumbnail_height,
      width: doc.converted_width || doc.thumbnail_width,
      url: doc.converted_jpeg_url || doc.thumbnail_url
    };
    if (collection && user) {
      let resourceOwner = doc._createdBy && doc._createdBy.firebaseUid === user.uid;
      newDoc.resourceOwner = resourceOwner;
    }
    return newDoc;
  });
  return result;
};

/**
 * @description Wait for scanning after upload  and remove if infected.
 */
const waitForScanning = ({ resourceId }) => {
  return new Promise((resolve, reject) => {
    let snapshotRef = firebaseDB()
      .collection(MetadataTable)
      .doc(resourceId)
      .onSnapshot(
        snapshot => {
          if (snapshot.exists) {
            let data = snapshot.data();
            if (data) {
              let error = data.converted_file_error || (data.gallery && data.thumbnail_error) || data.extractionError;
              if (
                error ||
                (data.scanned &&
                  (data.thumbnail_url || data.type === "doc" || (data.type === "audio" && data.resource_title)))
              ) {
                try {
                  const snapshotIndex = uploadSnapshot.indexOf(snapshotRef);
                  snapshotIndex >= 0 && uploadSnapshot.splice(snapshotIndex, 1);
                  snapshotRef(); // remove snapshot
                  resolve({
                    thumbnail_url: data.thumbnail_url,
                    resource_title: data.resource_title,
                    error
                  });
                } catch (err) {
                  // do nothing
                }
              }
            }
          } else {
            // called wheen document does not exists.
            // currently only one case:: in case of infected files, file is removed from metadata table
            // TODO:: need to review error message in case of other such cases
            try {
              const snapshotIndex = uploadSnapshot.indexOf(snapshotRef);
              snapshotIndex >= 0 && uploadSnapshot.splice(snapshotIndex, 1);
              snapshotRef(); // remove snapshot
              resolve({
                error: {
                  code: "somethingWentWrong",
                  message: "Something Went Wrong"
                }
              });
            } catch (err) {
              // do nothing
            }
          }
        },
        error => {}
      );
    uploadSnapshot.push(snapshotRef);
  });
};

export const getDownloadSnapshot = ({ token }) => {
  return new Promise((resolve, reject) => {
    let snapshotRef = firebaseDB()
      .collection(DOWNLOAD_TOKENS)
      .doc(token)
      .onSnapshot(
        snapshot => {
          try {
            if (snapshot.exists) {
              const data = snapshot.data();
              const resourceInfo = (data && data.resourceInfo && data.resourceInfo[0]) || {};
              const error = resourceInfo.error || (data && data.error);
              if (error || resourceInfo.status === "COMPLETED") {
                const snapshotIndex = downloadSnapshot.indexOf(snapshotRef);
                snapshotIndex >= 0 && downloadSnapshot.splice(snapshotIndex, 1);
                snapshotRef(); // remove snapshot
                resolve({ error: error, status: resourceInfo.status });
              }
            } else {
              // called wheen document does not exists.
              // currently only one case:: in case file is downloaded, server remove this token
              // TODO:: need to review error message in case of other such cases
              const snapshotIndex = downloadSnapshot.indexOf(snapshotRef);
              snapshotIndex >= 0 && downloadSnapshot.splice(snapshotIndex, 1);
              snapshotRef(); // remove snapshot
              resolve({ status: "COMPLETED" });
            }
          } catch (err) {
            // do nothing
          }
        },
        error => resolve({ error: error })
      );
    downloadSnapshot.push(snapshotRef);
  });
};

/**
 * @description check if file exists in sqlite or not.
 * @param {*} row
 */
const isFileExistsInSql = row => {
  let { actionType, localUri, type, status, _id } = row;
  if (localUri && type) {
    if (type === "image" || type === "video") {
      type = "gallery";
    }
    if (actionType === "upload" || (actionType === "download" && status === "downloaded")) {
      return { uri: localUri, fileType: type, resource: _id };
    }
  }
};

/**
 *
 * @param {*} resources
 * @param {Boolean} localUriRequired
 */
const checkFileExistsLocally = async (resources, localUriRequired = true) => {
  if (!resources || (Array.isArray(resources) && !resources.length)) {
    return resources;
  }
  let resourcesArray = resources;
  if (!Array.isArray(resources)) {
    resourcesArray = [resources];
  }
  if (typeof resourcesArray[0] !== "object") {
    throw new Error("Input should either be an array of object or a single object");
  }
  let resourceWiseResult = {},
    toCheckResourceIds = [],
    uriWiseData = [];
  for (let row of resourcesArray) {
    if (row && row._id) {
      let sqlExistsInfo = isFileExistsInSql(row);
      if (sqlExistsInfo) {
        uriWiseData.push(sqlExistsInfo);
      } else {
        toCheckResourceIds.push(row._id);
      }
    }
  }

  if (toCheckResourceIds.length) {
    const sqlResult = await getSqlExistStatusRecursively(toCheckResourceIds);
    for (let row of sqlResult) {
      if (row && row.resource) {
        let { resource, exists, data } = row;
        let newResourceInfo = { resource, exists: false };
        if (exists && data) {
          let { actionType, status } = data;
          let sqlExistsInfo = isFileExistsInSql(data);
          if (sqlExistsInfo) {
            uriWiseData.push(sqlExistsInfo);
            continue;
          } else if (actionType === "download" && ["paused", "queued", "downloading"].indexOf(status) !== -1) {
            newResourceInfo.downloadExists = true;
          }
        }
        resourceWiseResult[resource] = newResourceInfo;
      }
    }
  }

  let fileExistsResult = await isFileExists(uriWiseData, void 0, localUriRequired);
  if (fileExistsResult) {
    for (let resultData of fileExistsResult) {
      let { resource, uri, exists, localUri, sourceType } = resultData;
      if (resource) {
        let resourceResult = { resource, exists, uri, sourceType };
        if (localUriRequired) {
          resourceResult.localUri = localUri || uri;
        }
        resourceWiseResult[resource] = resourceResult;
      }
    }
  }
  if (resourcesArray.length !== Object.keys(resourceWiseResult).length) {
    resourcesArray.forEach(row => {
      let id = row && row._id;
      if (id && !resourceWiseResult[id]) {
        resourceWiseResult[id] = {
          resource: id,
          exists: false
        };
      }
    });
  }
  if (Array.isArray(resources)) {
    return resourceWiseResult;
  } else {
    return resourceWiseResult[resources && resources._id];
  }
};

/**
 * @description A higher order component that provides native level functionalities only.
 */
const {
  saveAsPdf: saveAsPdfNative,
  openCameraAndUpload,
  downloadPicOfDay,
  getLocalPathOfResource
} = NativeUtilityHoc({
  checkFileExistsLocally,
  isNetworkUrl,
  getResourceNameFromUrl,
  getCachePath,
  isFileExists,
  downloadFile,
  updateCacheTime,
  showMessage,
  I18N,
  invokeUploadAction,
  Platform,
  ReactNativeDownload,
  addEntryInDownloadTableAfterComplete
});

/**
 * @description Fetch more items on scroll.
 * @param {*} eventValues
 * @param {Function} fetchMore
 */
const fetchMoreOnScroll = (eventValues, { fetchMore } = {}) => {
  if (fetchMore && eventValues) {
    let { scrollHeight, scrollTop, offsetHeight } = eventValues;
    let scroll_threshold = scrollHeight - scrollTop - offsetHeight;
    if (scrollTop !== 0 && scroll_threshold <= 400) {
      fetchMore({ fetchInBackground: false });
    }
  }
};

/**
 * @description A higher order component that provides native level functionalities only.
 */
const { beforeFetch, afterFetch } = ConnectHelperHoc({ mergeQueryOld });

/**
 * @description A higher order component that provides user store component..
 */
const UserStore = UserStoreHoc({
  onUserUpdate: onFSUserUpdate,
  loadUser,
  removeUser,
  renderChildren,
  setUserContextInfo,
  hideSplashScreen: () => {
    if (Platform.OS !== "web" && SplashScreen) {
      SplashScreen.hide();
    }
  },
  getUserData: userId => {
    if (userId) {
      return firebaseDB().collection(UserTable).doc(userId);
    }
  }
});

/**
 * @description A higher order component that provides modal feature.
 */
const WithModal = withContext(
  ActionModalHoc({
    Modal,
    resolveMQ,
    renderChildren,
    onContextMenuClick: props => {
      let { selectRow, data, isSelected } = props;
      if (data && selectRow && isSelected && !isSelected(data)) {
        selectRow(data);
      }
    }
  }),
  {
    activeMQ: "ActiveMQ",
    isSelected: "SelectionStore.isSelected",
    selectRow: "SelectionStore.selectRow"
  }
);
const appServices = {
  fetchUrl,
  socketUrl,
  postUrl,
  uploadUrl,
  userUrl,
  firebase: firebaseInstance,
  mappings,
  getErrorMessage: err => {
    return err.message;
  },
  preFetch,
  urls,
  normalizeUser,
  deviceId
};

const ConnectAppServices = ConnectServicesCqrsHoc({
  isJSONObject,
  uuid,
  showMessage,
  showError,
  resolveValue,
  getExportColumns,
  firebaseAuth,
  firebaseUploadFile,
  nativeViews,
  I18N,
  brandName,
  ...appServices
});

/**
 * @description A higher order component that provides FS login API.
 */
const FSLoginApi = FSLoginApiHoc({
  ...ConnectAppServices,
  deviceId,
  getFsTrackId,
  normalizeUser,
  firebase: firebaseInstance,
  userUrl,
  pincodeSendUrl,
  proxyLoginEnabled,
  pincodeResendUrl,
  forgotEmailUrl,
  pincodeVerifyUrl,
  authorizeUrl,
  uuid,
  androidLoginClientSecret,
  androidLoginClientId,
  iosLoginClientSecret,
  iosLoginClientId,
  brandName: brandName === "b2c" ? "vivo" : brandName,
  isB2BorB2C: brandName === "b2c" || brandName === "vivob2b",
  loginClientSecret,
  loginClientId,
  Platform,
  addPrivacyConsent
});
const { uploadRequest, getNotificationToken, invoke } = ConnectAppServices;

const AppStore = AppStoreHoc({
  View,
  screenWidth: ScreenWidth,
  ActiveMQ,
  addListeners,
  removeListeners,
  App: ConnectAppServices,
  Platform,
  onBackPress,
  Orientation,
  setLanguage: I18N.setLanguage,
  getLanguage: () => I18N.language,
  getStatusBarHeight: NavStatusBar.getHeight,
  downloadListeners: DownloadListeners,
  clearMemoryData,
  unsubscribeAll
});

/**
 * @description A higher order component that provides contacts related functionalities.
 */
const { syncContacts, resetFromServer, resetFromDevice } = ContactSyncHoc({
  deviceId,
  nativeViews,
  invoke: ConnectAppServices.invoke,
  dropTable,
  getSettingInfo,
  urls,
  changeContactSyncProgress,
  getContactSyncProgress,
  deviceContactSync,
  Platform,
  showMessage,
  setContactSyncDescription,
  I18N,
  getUser,
  addLog,
  CONTACT_LAST_RESET_SETTING,
  CONTACT_LAST_SYNCED_DATE,
  CONTACT_SERVER_LAST_SYNCED_DATE
});

/**
 * @description A higher order component that provides basic generic components.
 */
const appComponents = AppComponents({
  images,
  sounds,
  theme,
  WithModal,
  showMessage,
  showError,
  Toast,
  beforeFetch,
  afterFetch,
  I18N,
  unsubscribe,
  subscribe,
  onRealTimeUpdate,
  Swipeout,
  getUserMenus,
  cqrs,
  fetchTimeout: 120000,
  getFieldHeader: FormUtil.getFieldHeader,
  getMandatoryMessage,
  getPlaceholder: ({ fieldDef = {} }) => {
    let { placeholder, field, header } = fieldDef;
    if (placeholder !== undefined) {
      return placeholder;
    }
    return header || field;
  },

  /**
   * @description A higher order component that provides loader functionalities.
   */
  LoadingIndicator: ({ View }) => SpinnerHoc({ View, theme: theme.spinnerStyle }),
  ImageLoader: ({ View }) => SpinnerHoc({ View, theme: theme.imageLoaderSpinnerStyle }),
  ActiveMQ,
  appServices,
  addLocalDataChangeListener,
  removeLocalDataChangeListener,
  fireLocalDataChangeListener,
  mergeTwoSortedArrays,
  fireListRowUpdateListener,
  onPendingBlocker
});

/**
 * @description Used to get video stream URL.
 * @param {String} token
 */
const getVideoStreamUrl = token => `${urls["streamVideo"]}?token=${token}&brand=${brandName}`;

/**
 * @description Used to get audio stream URL.
 * @param {String} token
 * @param {String} id
 */
const getAudioStreamUrl = (token, id) => `${urls["streamAudio"]}?token=${token}&id=${id}&brand=${brandName}`;

/**
 * @description Used to get data stream URL.
 * @param {String} token
 * @param {String} id
 */
export const getDataStreamingUrl = (token, id) => `${urls["dataStreaming"]}?token=${token}&id=${id}&brand=${brandName}`;

/**
 * @description Used to remove stream URL.
 * @param {*} data
 */
export const removeStreamToken = data => {
  return urlFetch({
    url: urls["removeStreamToken"],
    props: data
  });
};

export const FSListItem = FSListItemHoc({ shallowCompare });

const {
  NotificationHandler,
  Walkthrough,
  LoginErrorComponent,
  ChatLinkComponent,
  LoginContainer,
  LoginEditor,
  LoginButton,
  Register,
  TextInput,
  TextAreaInput,
  TextBox,
  TextAreaBox,
  NumberBox,
  CheckBox,
  ValidateOtp,
  LoginOption,
  Login,
  ResendOtp,
  WebLoginOption,
  WebLogin,
  WebValidateOtp,
  WebLandingScreen,
  RenderNoData,
  RenderPromptMessage,
  GroupTabsNoData,
  UploadWrapper,
  NetworkListener,
  MusicStore,
  DocsImageComponent,
  SDocsImageComponent,
  AnimatedRecyclerList,
  DrawerMenuHeader,
  WebAppUpload,
  Table,
  RecyclerList,
  ImageViewer,
  googleCast,
  DocumentSlider,
  WebMasonaryList,
  InviteMember,
  shareLink,
  SlideShowComponent,
  PopupHeader,
  MultipleText,
  SearchType,
  Switch,
  fromNowDateFormatter,
  getSizeFormat,
  ImageEditor,
  AnimatedRecyclerListRowImage,
  RowUpdateListenerWrapper,
  LinkBox,
  DecryptedImage,
  RecyclerListWithHeader
} = FSComponentsHoc(appComponents, {
  FSListItem,
  typeCastDateField,
  waitForScanning,
  firebase: firebaseInstance,
  addTableListener,
  removeTableListener,
  MetadataTable,
  afterLogin,
  checkFileExistsLocally,
  loadNotificationToken: ConnectAppServices.loadNotificationToken,
  updateDownloadTable,
  ReactNativeDownload,
  fireLocalDataChangeListener,
  addListRowUpdateListener,
  removeListRowUpdateListener,
  VIDEO_UNSUPPORTED,
  FSLoginApi,
  SmsReader,
  AppConfig,
  languages: {
    en: "English",
    pt: "Português"
  },
  isValidEmail,
  uploadErrorCall,
  getUserSpaceData,
  setUserSpaceRef,
  addLog,
  deviceId,
  getUser,
  fetchMoreOnScroll,
  logUploadAnalytics,
  logSearchAnalytics,
  logFirebaseAnalyticsEvent,
  Orientation,
  uploadRequest,
  onPendingBlocker,
  formatLocalDataToResourceData,
  fetchAssetData,
  fetchDocuments,
  fetchMusic,
  DeviceSetting,
  checkPhotoPermissions,
  encryptFile,
  cancelEncryptDecryptWeb,
  decryptFile,
  cancelDecryption,
  getDecryptionProps,
  firebaseSave,
  getEncryptionKeyAndIV,
  getVideoStreamUrl,
  getAudioStreamUrl,
  getDataStreamingUrl,
  removeStreamToken,
  isNetworkUrl,
  isEncrypted,
  getCacheFileName,
  getErrorMessage,
  getModeText,
  onConnectionChange,
  downloadFile,
  isFileExists,
  clearCache,
  cancelItem,
  cancelAllItems,
  cancelQueuedItem,
  saveAsPdfNative,
  invokeUploadFromCamera,
  validateResizeInfo,
  getCachePath,
  updateCachePath,
  updateCacheTime,
  isPublicUrl,
  unsetLocalUris,
  updateCacheErrorEntries,
  onClickNotification,
  getResourceNameFromUrl,
  invokeUploadAction,
  getLocalPathOfResource
});

const {
  getImage,
  getInput,
  getAction,
  mergeJsonComponents,
  enhancePropWithMQ,
  resolveActions,
  resolveActionLayout,
  mergeActions,
  Connect,
  AppConnect,
  SelectionStore,
  getNewId,
  Box,
  Text,
  Image,
  ScrollView,
  Dimensions,
  getImageSize,
  iterator,
  Location,
  WithState,
  WithStateRouter,
  Close,
  Link,
  Invoke,
  Save,
  Insert,
  Remove,
  Fts,
  DrawerMenuView,
  DrawerLayout,
  Nav,
  NavBody,
  NavHeader,
  NavTitle,
  NavDrawerMenu,
  NavFooter,
  NavLeft,
  StatusBar,
  ImageLoader,
  PanelTitle,
  PanelHeader,
  Panel,
  VerticalEditorContainer,
  Editor,
  EditorMap,
  Form,
  GridTable,
  ImageBackground,
  List,
  RowAction,
  ConfirmBox,
  PermissionConfirmBox,
  KeyboardAvoidingView,
  dataFormat,
  getTheme,
  getComponent,
  getJsonComponent,
  mergeComponents,
  mergeCardComponents,
  CoreAction,
  FSRowAction,
  upload,
  TabForm,
  Keyboard
} = appComponents;

const AnimatedList = AnimatedListHoc({ List });
export const AnimatedScrollView = AnimatedScrollViewHoc({ ScrollView });
export const FSList = FSListHoc({ shallowCompare, List: AnimatedList, Platform, theme, fetchMoreOnScroll });
export const BlockerStatusBar = BlockerStatusBarHoc({ StatusBar });

export {
  AnimatedRecyclerListRowImage,
  RowUpdateListenerWrapper,
  fromNowDateFormatter,
  Switch,
  upload,
  Toast,
  getImage,
  getInput,
  getAction,
  theme,
  gradients,
  breakPoints,
  resolveMQ,
  enhancePropWithMQ,
  resolveActions,
  resolveActionLayout,
  ActiveMQ,
  UserStore,
  mergeActions,
  mergeJsonComponents,
  NotificationHandler,
  AppStore,
  Connect,
  AppConnect,
  SelectionStore,
  getNewId,
  withContext,
  Box,
  Text,
  View,
  Platform,
  Image,
  ScrollView,
  Dimensions,
  Modal,
  getImageSize,
  renderChildren,
  iterator,
  Location,
  WithState,
  AsyncStorage,
  WithStateRouter,
  Close,
  Link,
  Invoke,
  Save,
  Insert,
  Remove,
  Fts,
  DrawerMenuView,
  DrawerLayout,
  Nav,
  NavBody,
  NavHeader,
  NavTitle,
  NavDrawerMenu,
  NavStatusBar,
  ConfirmBox,
  PermissionConfirmBox,
  NavFooter,
  NavLeft,
  StatusBar,
  ImageLoader,
  PanelTitle,
  PanelHeader,
  Panel,
  VerticalEditorContainer,
  Editor,
  TextInput,
  TextAreaInput,
  TextBox,
  TextAreaBox,
  NumberBox,
  CheckBox,
  EditorMap,
  Form,
  Table,
  GridTable,
  WithModal,
  resolveValue,
  ImageBackground,
  List,
  RowAction,
  KeyboardAvoidingView,
  showError,
  showMessage,
  dataFormat,
  shallowCompare,
  Walkthrough,
  LoginErrorComponent,
  ChatLinkComponent,
  LoginContainer,
  LoginEditor,
  LoginButton,
  Register,
  ValidateOtp,
  fetchDocuments,
  fetchAssetsForFolder,
  fetchMusic,
  DeviceSetting,
  getTheme,
  getComponent,
  LoginOption,
  Login,
  ResendOtp,
  WebLoginOption,
  WebValidateOtp,
  WebLandingScreen,
  WebLogin,
  RenderNoData,
  RenderPromptMessage,
  GroupTabsNoData,
  UploadWrapper,
  NetworkListener,
  MusicStore,
  I18N,
  DocsImageComponent,
  SDocsImageComponent,
  AnimatedRecyclerList,
  PopupHeader,
  MultipleText,
  Orientation,
  DrawerMenuHeader,
  WebAppUpload,
  RecyclerList,
  ImageViewer,
  googleCast,
  DocumentSlider,
  getJsonComponent,
  mergeComponents,
  mergeCardComponents,
  sounds,
  WebMasonaryList,
  InviteMember,
  shareLink,
  SlideShowComponent,
  addRealTimeListener,
  removeRealTimeListener,
  addTableListener,
  removeTableListener,
  CollectionTable,
  MetadataTable,
  CollectionItemsTable,
  nativeViews,
  SearchType,
  getQueuedAndInProgressDownloadFiles,
  getQueuedAndInProgressUploadFiles,
  UPLOAD_DATA_KEY_TYPES,
  getDownloadDataById,
  DOWNLOAD_DATA_KEY_TYPES,
  PAUSE_REASON_ENUM,
  FSRowAction,
  checkFileExistsLocally,
  updateDownloadTable,
  firebaseInstance as firebase,
  CoreAction,
  TabForm,
  loginUrl,
  ReactNativeDownload,
  fireLocalDataChangeListener,
  _updateCacheFromLocalChanges,
  getTimeStringFromSeconds,
  AppConfig,
  getSizeFormat,
  ImageEditor,
  syncContacts,
  resetFromServer,
  resetFromDevice,
  updateUploadTable,
  urls,
  uploadRequest,
  LinkBox,
  FSLoginApi,
  SmsReader,
  isValidEmail,
  checkPhotoPermissions,
  find as firebaseFind,
  getLogPath,
  LOGS_FILE_NAME,
  isFileExists,
  getSortValue,
  modifyUrls,
  mergeTwoSortedArrays,
  getToInsertMetadata,
  insertMetadataInSqlRecursively,
  updateMetadataInSqlite,
  formatLocalDataToResourceData,
  _updateSqliteFromLocalChanges,
  emptyTrashSqlite,
  emptyNonUploadedSqlite,
  invoke,
  logUploadAnalytics,
  logDownloadAnalytics,
  logFirebaseAnalyticsEvent,
  logFirebaseAnalyticsScreenEvent,
  setFirebaseAnalyticsUser,
  firebaseSave,
  DeviceTable,
  getBase64Image,
  Keyboard,
  brandName,
  isNetworkUrl,
  isPublicUrl,
  getResourceNameFromUrl,
  getCacheFileName,
  getCachePath,
  decryptFile,
  cancelDecryption,
  encryptFile,
  downloadFile,
  DOWNLOAD_UTILS,
  DecryptedImage,
  showPowerSaverNotification,
  getTotalCacheSize,
  clearCache,
  getFileUriFromContentUri,
  updateCacheTime,
  RecyclerListWithHeader,
  downloadZipURL,
  saveAsPdfNative,
  downloadPicOfDay,
  addPrivacyConsent,
  openCameraAndUpload,
  getLocalPathOfResource
};
