import React from "react";

export default ({ Box, theme, I18N, resolveMQ, getComponent, LoginLanguageSelector, getImage, AppConfig }) => {
  const { colors, fonts } = theme;
  const { themeColor, highlightColor } = colors;
  const { h2, h16_18 } = fonts;
  const styles = {
    styleLG: {
      containerStyle: {
        direction: "row",
        viewStyle: {
          height: 72,
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: themeColor,
          paddingLeft: 12,
          paddingRight: 12
        },
        textStyle: { color: highlightColor, ...h16_18 }
      },
      iconContainerStyle: {
        viewStyle: { paddingRight: 3, height: 18 }
      },
      privacyPolicyContainerStyle: {
        viewStyle: {
          paddingRight: 7.5,
          paddingLeft: 7.5
        }
      },
      termsOfUseContainerStyle: {
        viewStyle: { paddingLeft: 7.5 }
      },
      linkTextStyle: {
        color: highlightColor,
        ...h16_18,
        textDecoration: "none"
      }
    },
    styleMD: {
      containerStyle: {
        viewStyle: {
          height: 66,
          alignItems: "center",
          justifyContent: "space-evenly",
          backgroundColor: themeColor,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12
        },
        textStyle: { color: highlightColor, ...h2 }
      },
      iconContainerStyle: {
        viewStyle: { paddingRight: 3 }
      },
      privacyPolicyContainerStyle: {
        viewStyle: {
          paddingRight: 12,
          paddingLeft: 12
        }
      },
      termsOfUseContainerStyle: {
        viewStyle: { paddingLeft: 12 }
      },
      linkTextStyle: {
        color: highlightColor,
        ...h2,
        textDecoration: "none"
      }
    }
  };

  class WebLoginCommonFooter extends React.Component {
    render() {
      const { activeMQ } = this.props;
      const { style } = resolveMQ(styles, ["style"], activeMQ);
      const LinkBox = getComponent("anchor");
      return (
        <Box
          {...style.containerStyle}
          render={[
            {
              direction: "row",
              viewStyle: { alignItems: "center", paddingLeft: 12, paddingRight: 12 },
              render: [
                { ...style.iconContainerStyle, image: getImage("copyRightIcon") },
                { text: `2020-2024 ${I18N.t("copyrightText")}` }
              ]
            },
            {
              direction: "row",
              viewStyle: { alignItems: "center", paddingLeft: 12, paddingRight: 12 },
              render: [
                { viewStyle: { paddingRight: 9 }, render: <LoginLanguageSelector /> },
                { text: "|" },
                {
                  ...style.privacyPolicyContainerStyle,
                  render: (
                    <LinkBox style={{ ...style.linkTextStyle }} url={AppConfig.APP_TERMS_CONDITION_URL}>
                      <Box text={I18N.t("termsAndPolicyWeb")} />
                    </LinkBox>
                  )
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return WebLoginCommonFooter;
};
