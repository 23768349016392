import React from "react";
import {
  Box,
  I18N,
  theme,
  getImage,
  dataFormat,
  FSListItem,
  SDocsImageComponent,
  View,
  Text,
  Image,
  FSRowAction,
  getTimeStringFromSeconds,
  RowUpdateListenerWrapper,
  Platform
} from "../../../FsCloudComponent";
import { commonLayout } from "../../layouts";
import CollectionActions from "../collection/CollectionActions";
import { isResourceUploading, isResourceDownloading } from "../resource/ResourceUtility";
const { numberFormatter } = dataFormat;
let iconMenu = getImage("iconMenuGrey");

const iconMenuContainerStyle = {
  height: 44,
  width: 44,
  alignItems: "center",
  justifyContent: "center"
};
let { colors, shadows, fonts, listContentContainerStyle, cardHeight } = theme;
let { h1_16, h10, h2_l_18, h9_18, h20_24, h9_20 } = fonts;
let { brownGrey, primaryColor, highlightColor } = colors;
let { cardShadow } = shadows;
const secondaryText = { ...h2_l_18, color: brownGrey, numberOfLines: 1 };
const style1 = {
  height: cardHeight,
  marginLeft: 12,
  marginRight: 12,
  marginTop: 5,
  marginBottom: 5,
  backgroundColor: highlightColor,
  padding: 12,
  alignItems: "center",
  flexDirection: "row",
  borderRadius: 4,
  ...cardShadow
};
const paddingStyle = { padding: 2 };
const flexStyle = { flex: 1, ...paddingStyle };
const style2 = { paddingRight: 12 };
const style3 = { ...h1_16, color: primaryColor, numberOfLines: 1 };
const preTextStyle = { ...style3, whiteSpace: "pre" };
const style4 = { flex: 1, padding: 4 };
const style5 = { flexDirection: "row" };
const style5withPadding = { flexDirection: "row", paddingTop: 4 };
const uploadingIcon = getImage("uploadingIcon");
const downloadingIcon = getImage("downloadingIcon");
const playlistIcon = getImage("playlistIcon");
const artistIcon = getImage("artistIcon");
const secondaryTextPadding = { ...secondaryText, paddingLeft: 2 };
const commonSMListProps = {
  style: { flex: 1, marginTop: 5 },
  contentContainerStyle: listContentContainerStyle,
  getItemLayout: (data, index) => {
    return { length: cardHeight, offset: index * cardHeight + 10, index };
  }
};

const MusicCardSM = {
  render: [
    {
      direction: "row",
      viewStyle: {
        padding: 12,
        alignItems: "center"
      },
      render: [
        {
          viewStyle: { paddingRight: 12 },
          value: { field: "resource_name", type: "imageDoc", componentName: "musicImage" }
        },
        {
          width: "1fr",
          viewStyle: { padding: 4 },
          itemStyle: { padding: 2 },
          render: [
            {
              direction: "row",
              render: [
                {
                  width: "1fr",
                  textStyle: {
                    ...h1_16,
                    color: primaryColor,
                    numberOfLines: 1
                  },
                  value: { field: "resource_title", type: "text" }
                },
                {
                  value: ({ data }) => {
                    return isResourceUploading(data) ? { image: uploadingIcon } : {};
                  }
                },
                {
                  value: ({ data }) => {
                    return isResourceDownloading(data) ? { image: downloadingIcon } : {};
                  }
                }
              ]
            },
            {
              textStyle: { ...h10, color: brownGrey, numberOfLines: 1 },
              value: { field: "resource_artist", type: "text" }
            }
          ]
        }
      ]
    }
  ]
};

const MusicRowComponent = ({ onClick, onLongPress, isSelected, activeMQ, data }) => {
  let selected = isSelected(data);
  return (
    <View style={style1} onClick={onClick} onLongPress={onLongPress}>
      <View style={style2}>
        <SDocsImageComponent
          selected={selected}
          field={"resource_name"}
          data={data}
          fieldDef={{ componentName: "musicImage" }}
          activeMQ={activeMQ}
        />
      </View>
      <View style={style4}>
        <View style={style5}>
          <View style={flexStyle}>
            <Text style={style3}>{data.resource_title}</Text>
          </View>
          {isResourceUploading(data) && (
            <View style={paddingStyle}>
              <Image source={uploadingIcon} />
            </View>
          )}
          {isResourceDownloading(data) && (
            <View style={paddingStyle}>
              <Image source={downloadingIcon} />
            </View>
          )}
        </View>
        <View style={style5}>
          <Text style={secondaryText}>{data.resource_artist}</Text>
        </View>
      </View>
    </View>
  );
};

const MusicCardSMRender = ({
  item,
  index,
  lastSelected,
  currentSelected,
  isSelected,
  activeMQ,
  isSelectionMode,
  selectRow,
  onClick
}) => {
  return (
    <FSListItem
      lastSelected={lastSelected}
      currentSelected={currentSelected}
      data={item}
      index={index}
      onClick={onClick}
      selectRow={selectRow}
      isSelectionMode={isSelectionMode}
    >
      {({ onClick, onLongPress }) => {
        let component = (
          <MusicRowComponent
            isSelected={isSelected}
            activeMQ={activeMQ}
            onClick={onClick}
            onLongPress={onLongPress}
            data={item}
          />
        );
        if (Platform.OS !== "web" && RowUpdateListenerWrapper) {
          component = <RowUpdateListenerWrapper data={item}>{component}</RowUpdateListenerWrapper>;
        }
        return component;
      }}
    </FSListItem>
  );
};

const MusicCardSMListProps = {
  ...commonSMListProps,
  renderItem: MusicCardSMRender
};

const MusicCardMD = {
  columns: [
    {
      width: "1fr",
      header: () => I18N.t("name"),
      field: "resource_title",
      type: "audioTitle",
      sortable: "lower_name",
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("duration"),
      field: data => {
        if (!data) {
          return;
        }
        let { resource_duration } = data;
        resource_duration = getTimeStringFromSeconds(resource_duration / 1000);
        return resource_duration;
      },
      type: "text",
      width: "120px",
      sortable: "resource_duration",
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("artist"),
      field: "resource_artist",
      type: "text",
      defaultText: "-",
      width: "200px",
      sortable: "lower_artist",
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("album"),
      field: "resource_album",
      type: "text",
      defaultText: "-",
      width: "200px",
      sortable: "lower_album",
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("sources"),
      type: "text",
      field: "source",
      defaultText: "-",
      width: "200px"
    }
  ]
};

const SearchMusicCardMD = {
  columns: [
    {
      width: "1fr",
      header: () => I18N.t("name"),
      field: "resource_title",
      type: "audioTitle"
    },
    {
      header: () => I18N.t("duration"),
      field: data => {
        if (!data) {
          return;
        }
        let { resource_duration } = data;
        resource_duration = getTimeStringFromSeconds(resource_duration / 1000);
        return resource_duration;
      },
      type: "text",
      width: "120px"
    },
    {
      header: () => I18N.t("artist"),
      field: "resource_artist",
      type: "text",
      defaultText: "-",
      width: "200px"
    },
    {
      header: () => I18N.t("album"),
      field: "resource_album",
      type: "text",
      defaultText: "-",
      width: "200px"
    },
    {
      header: () => I18N.t("sources"),
      type: "text",
      field: "source",
      defaultText: "-",
      width: "200px"
    }
  ]
};

const MusicCard = commonLayout({
  SM: MusicCardSM,
  MD: MusicCardMD
});

const SearchMusicCard = commonLayout({
  SM: MusicCardSM,
  MD: SearchMusicCardMD
});

const PlaylistCardSMRender = ({ item, index, activeMQ, onClick, language }) => {
  return (
    <FSListItem data={item} index={index} onClick={onClick} isSelectionMode={false} language={language}>
      {({ onClick }) => {
        const itemCount = numberFormatter(item.itemCount, "0.0i");
        return (
          <View style={style1} onClick={onClick}>
            <View style={style2}>
              <Image source={playlistIcon} />
            </View>
            <View style={style4}>
              <Text style={preTextStyle}>{item.name}</Text>
              <View style={style5withPadding}>
                <Text style={secondaryText}>{itemCount}</Text>
                <Text style={secondaryTextPadding}>{I18N.t("items")}</Text>
              </View>
            </View>
            <FSRowAction
              actions={CollectionActions}
              action={["addItemsToPlaylistLink", "renamePlaylist", "markDeletePlaylist"]}
              data={item}
              activeMQ={activeMQ}
            >
              <View style={iconMenuContainerStyle}>
                <Image source={iconMenu} />
              </View>
            </FSRowAction>
          </View>
        );
      }}
    </FSListItem>
  );
};

const PlaylistCardSMListProps = {
  ...commonSMListProps,
  renderItem: PlaylistCardSMRender
};

const PlaylistCard = commonLayout({
  primaryAction: "playlistDetailLink",
  SM: {
    render: [
      {
        direction: "row",
        viewStyle: {
          height: 74,
          alignItems: "center"
        },
        render: [
          {
            viewStyle: { paddingLeft: 12, paddingRight: 16 },
            value: { image: "playlistIcon" }
          },
          {
            width: "1fr",
            render: [
              {
                textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1, whiteSpace: "pre" },
                value: { field: "name" }
              },
              {
                direction: "row",
                textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                viewStyle: { paddingTop: 4 },
                render: [
                  {
                    direction: "row",
                    render: [
                      {
                        value: ({ data }) => {
                          return { text: numberFormatter(data.itemCount, "0.0i") };
                        }
                      },
                      { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                    ]
                  }
                ]
              }
            ]
          },
          {
            viewStyle: {
              paddingLeft: 8,
              paddingRight: 8,
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 40
            },
            value: {
              image: "iconMenuGrey",
              action: ["addItemsToPlaylistLink", "renamePlaylist", "markDeletePlaylist"]
            }
          }
        ]
      }
    ]
  },
  MD: {
    viewStyle: { margin: 15, borderRadius: 4 },
    render: [
      {
        viewStyle: {
          height: 98,
          alignItems: "center",
          ...cardShadow,
          backgroundColor: highlightColor,
          cursor: "pointer"
        },
        direction: "row",
        render: [
          {
            viewStyle: { paddingLeft: 16, paddingRight: 16 },
            value: { image: "playlistIcon" }
          },
          {
            width: "1fr",
            viewStyle: { paddingLeft: 12, paddingRight: 16 },
            render: [
              {
                direction: "row",
                render: [
                  {
                    viewStyle: { flex: 1, paddingBottom: 8 },
                    textStyle: {
                      ...h20_24,
                      color: primaryColor,
                      numberOfLines: 1,
                      whiteSpace: "pre"
                    },
                    value: { field: "name" }
                  },
                  {
                    viewStyle: {
                      height: 24,
                      width: 24,
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    },
                    value: {
                      image: "iconMenuGrey",
                      action: [
                        "addItemsToPlaylistFormWithGreyImage",
                        "renamePlaylistWithGreyImage",
                        "markDeletePlaylistWithGreyImage"
                      ]
                    }
                  }
                ]
              },
              {
                direction: "row",
                textStyle: {
                  ...h9_18,
                  color: brownGrey
                },
                render: [
                  {
                    direction: "row",
                    render: [
                      {
                        value: ({ data }) => {
                          return { text: numberFormatter(data.itemCount, "0.0i") };
                        }
                      },
                      { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
});

const SelectPlaylistCard = commonLayout({
  primaryAction: "addItemsSelectCollection",
  SM: {
    render: [
      {
        direction: "row",
        viewStyle: {
          height: 74,
          alignItems: "center"
        },
        render: [
          {
            viewStyle: { paddingLeft: 12, paddingRight: 16 },
            value: { image: "playlistIcon" }
          },
          {
            width: "1fr",
            render: [
              {
                textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                value: { field: "name" }
              },
              {
                direction: "row",
                textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                viewStyle: { paddingTop: 4 },
                render: [
                  {
                    direction: "row",
                    render: [
                      {
                        value: ({ data }) => {
                          return { text: numberFormatter(data.itemCount, "0.0i") };
                        }
                      },
                      { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
});

const SelectPlaylistHeader = data => {
  if (!data || !data.length) {
    return null;
  }
  return (
    <Box
      viewStyle={{
        paddingTop: 12,
        paddingBottom: 7,
        paddingLeft: 10,
        paddingRight: 10
      }}
      textStyle={{
        ...h9_20,
        color: brownGrey
      }}
      text={I18N.t("addToPlaylistMessage")}
    />
  );
};

const ArtistCardSMRender = ({ item, index, isSelectionMode, selectRow, onClick, language }) => {
  return (
    <FSListItem
      data={item}
      index={index}
      onClick={onClick}
      selectRow={selectRow}
      isSelectionMode={isSelectionMode}
      language={language}
    >
      {({ onClick }) => {
        const itemCount = numberFormatter(item.itemCount, "0.0i");
        return (
          <View style={style1} onClick={onClick}>
            <View style={style2}>
              <Image source={artistIcon} />
            </View>
            <View style={style4}>
              <Text style={preTextStyle}>{item.name}</Text>
              <View style={style5withPadding}>
                <Text style={secondaryText}>{itemCount}</Text>
                <Text style={secondaryTextPadding}>{I18N.t("musicTrack")}</Text>
              </View>
            </View>
          </View>
        );
      }}
    </FSListItem>
  );
};

const ArtistCardSMListProps = {
  ...commonSMListProps,
  renderItem: ArtistCardSMRender
};

export {
  SelectPlaylistCard,
  SelectPlaylistHeader,
  MusicCard,
  SearchMusicCard,
  PlaylistCard,
  MusicCardSMListProps,
  PlaylistCardSMListProps,
  ArtistCardSMListProps
};
