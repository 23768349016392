import React from "react";

export default ({ TabBar, Box }) => {
  class LeftTabBar extends React.Component {
    render() {
      let { Screen, ...restProps } = this.props;
      let selectedTab = Screen && Screen.SelectedTab;
      let selectedTabState = selectedTab && Screen.Tab && Screen.Tab[selectedTab];
      let selectionMode =
        selectedTabState && selectedTabState.SelectionStore && selectedTabState.SelectionStore.selectionMode;
      if (selectionMode) {
        return <Box width={81} style={{ backgroundColor: "transparent" }} />;
      } else {
        return <TabBar {...restProps} />;
      }
    }
  }
  return LeftTabBar;
};
