import React from "react";
import { reformatMobileNumber } from "../Utility";

export default ({
  LoginHeader,
  Box,
  Editor,
  theme,
  I18N,
  resolveMQ,
  getComponent,
  ResendOtp,
  Button,
  showMessage,
  afterLogin,
  resendPincode,
  AppConfig,
  ChatLinkComponent,
  getErrorMessage
}) => {
  const { fonts, colors } = theme;
  const { h16_l, h20, h16_l_24, h16, h9, h3, h2_l_18, h2_l_24 } = fonts;
  const { themeColor, highlightColor, primaryColor, brownGrey } = colors;
  const styles = {
    styleLG: {
      bodyContainerStyle: {
        viewStyle: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingRight: 15,
          paddingLeft: 15,
          backgroundColor: highlightColor
        }
      },
      primaryStyle: {
        viewStyle: {
          marginTop: 12,
          marginBottom: 4
        },
        textStyle: {
          ...h20,
          color: primaryColor,
          textAlign: "center"
        }
      },
      mobileNumberStyle: {
        viewStyle: {
          marginTop: 4,
          marginBottom: 8
        },
        textStyle: {
          ...h16_l,
          color: brownGrey,
          textAlign: "center"
        }
      },
      otpLabelStyle: {
        viewStyle: {
          marginTop: 8,
          marginBottom: 8
        },
        textStyle: {
          ...h16_l,
          color: primaryColor,
          textAlign: "center"
        }
      },
      editorContainerStyle: {
        viewStyle: {
          marginTop: 8
        }
      },
      inputStyle: { ...h16_l, color: primaryColor },
      agreementTextStyle: {
        textStyle: {
          ...h16_l_24,
          color: brownGrey
        }
      },
      termsOfServiceStyle: {
        textStyle: {
          ...h16_l_24,
          color: themeColor,
          paddingLeft: 2
        }
      },
      resendContainerStyle: {
        viewStyle: {
          alignItems: "center",
          marginTop: 12
        }
      },
      resendMessageStyle: {
        textStyle: {
          ...h16_l_24,
          color: primaryColor
        }
      },
      timerStyle: {
        viewStyle: {
          paddingLeft: 4
        },
        textStyle: {
          ...h16_l_24,
          color: themeColor
        }
      },
      resendTextStyle: {
        viewStyle: {
          cursor: "pointer"
        },
        textStyle: {
          ...h16_l_24,
          color: themeColor
        }
      },
      buttonContainerStyle: {
        viewStyle: {
          marginTop: 12,
          marginBottom: 18
        }
      }
    },
    styleMD: {
      bodyContainerStyle: {
        viewStyle: {
          paddingTop: 40,
          paddingBottom: 18,
          paddingRight: 30,
          paddingLeft: 30,
          backgroundColor: highlightColor
        }
      },
      primaryStyle: {
        viewStyle: {
          marginBottom: 11
        },
        textStyle: {
          ...h16,
          color: primaryColor,
          textAlign: "center"
        }
      },
      mobileNumberStyle: {
        viewStyle: {
          marginBottom: 12
        },
        textStyle: {
          ...h9,
          color: brownGrey,
          textAlign: "center"
        }
      },
      otpLabelStyle: {
        textStyle: {
          ...h3,
          color: primaryColor,
          textAlign: "center"
        }
      },
      editorContainerStyle: {
        viewStyle: {
          paddingTop: 40
        }
      },
      inputStyle: { ...h3, color: primaryColor },
      agreeTermsContainerStyle: {
        direction: "row",
        viewStyle: {
          justifyContent: "center"
        }
      },
      agreementTextStyle: {
        textStyle: {
          ...h2_l_18,
          color: brownGrey
        }
      },
      termsOfServiceStyle: {
        textStyle: {
          ...h2_l_18,
          color: themeColor,
          paddingLeft: 2
        }
      },
      resendContainerStyle: {
        viewStyle: {
          alignItems: "center",
          marginTop: 27
        }
      },
      resendMessageStyle: {
        textStyle: {
          ...h2_l_24,
          color: primaryColor
        }
      },
      timerStyle: {
        viewStyle: {
          paddingLeft: 4
        },
        textStyle: {
          ...h2_l_24,
          color: themeColor
        }
      },
      resendTextStyle: {
        viewStyle: {
          cursor: "pointer"
        },
        textStyle: {
          ...h2_l_24,
          color: themeColor
        }
      },
      buttonContainerStyle: {
        viewStyle: {
          marginTop: 16,
          marginBottom: 31
        }
      }
    }
  };
  class WebValidateOtp extends React.Component {
    state = {};

    _setState = state => {
      this.setState(state);
    };

    onError = err => {
      console.log("-==-==--=-=-=-=-=-=-=---=+_+_+_+_+-=-=-=-=-: ", err);
      let message = getErrorMessage(err);

      this.setState({ error: message });
    };

    validateOtp = () => {
      let { state: { transaction_id, fromLandline, mobile } = {}, loginContainerProps: { data, onSubmit } = {} } =
        this.props;
      if (this.state.error) {
        this.setState({ error: void 0 });
      }
      onSubmit &&
        onSubmit(
          "validateOtp",
          {
            otp: data.otp,
            transaction_id,
            fromLandline,
            mobile
          },
          {
            afterSubmit: result => {
              afterLogin && afterLogin(result, this.props);
            },
            throwError: false
          }
        ).catch(err => {
          this.onError(err);
        });
    };

    resendOtp = () => {
      const { state: { transaction_id } = {} } = this.props;
      if (this.isResend || !resendPincode) {
        return;
      }
      this.isResend = true;
      resendPincode({ transaction_id })
        .then(_ => {
          showMessage && showMessage(I18N.t("codeSentMessage"), 2000);
          this.isResend = false;
        })
        .catch(err => {
          this.isResend = false;
          this.onError(err);
        });
    };

    render() {
      const { activeMQ, loginContainerProps = {}, onClose } = this.props;
      const { style } = resolveMQ(styles, ["style"], activeMQ);
      let editorProps = {
        ...loginContainerProps,
        state: this.state,
        setState: this._setState
      };
      let { data } = loginContainerProps;
      const MultipleText = getComponent("multipleText");
      return (
        <Box
          render={[
            LoginHeader && <LoginHeader onClose={onClose} />,
            {
              ...style.bodyContainerStyle,
              render: [
                {
                  ...style.primaryStyle,
                  text: I18N.t("otpPrimaryText")
                },
                {
                  ...style.mobileNumberStyle,
                  text: reformatMobileNumber(data.mobile)
                },
                {
                  ...style.otpLabelStyle,
                  text: I18N.t("otpLabel")
                },
                {
                  ...style.editorContainerStyle,
                  render: (
                    <Editor
                      {...editorProps}
                      field={"otp"}
                      placeholder={"otpPlaceholder"}
                      inputStyle={style.inputStyle}
                      onClick={this.validateOtp}
                    />
                  )
                },
                {
                  ...style.buttonContainerStyle,
                  render: (
                    <Button
                      loading={loginContainerProps.loading}
                      onClick={this.validateOtp}
                      text={"continue"}
                      loaderText={"validating"}
                    />
                  )
                },
                {
                  ...style.agreeTermsContainerStyle,
                  render: (
                    <MultipleText
                      value={[
                        {
                          ...style.agreementTextStyle,
                          text: `${I18N.t("agreementText1")} `
                        },
                        {
                          ...style.termsOfServiceStyle,
                          text: I18N.t("termsOfUseText"),
                          link: { uri: AppConfig.APP_TERMS_CONDITION_URL },
                          external: true
                        },
                        {
                          ...style.agreementTextStyle,
                          text: ` ${I18N.t("agreementText2")}`
                        }
                      ]}
                    />
                  )
                },
                {
                  ...style.resendContainerStyle,
                  render: (
                    <ResendOtp
                      resendMessageStyle={style.resendMessageStyle}
                      timerStyle={style.timerStyle}
                      resendStyle={style.resendTextStyle}
                      onClick={this.resendOtp}
                      time={30}
                    />
                  )
                },
                {
                  viewStyle: { alignItems: "center", marginTop: 18 },
                  render: <ChatLinkComponent />
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return WebValidateOtp;
};
