import ResourceDetailComponent from "../resource/ResourceDetailComponent";
import {
  musicDetailQuery,
  playlistMusicDetailQuery,
  artistMusicDetailQuery,
  searchMusicDetailQuery,
  archiveMusicDetailQuery
} from "../queries";

let commonDetailProps = {
  component: "table",
  Component: ResourceDetailComponent,
  panelProps: {
    showHeader: false
  },
  hideNavHeader: true
};

export default {
  MusicDetail: {
    ...commonDetailProps,
    ...musicDetailQuery
  },
  PlaylistMusicDetail: {
    ...commonDetailProps,
    ...playlistMusicDetailQuery
  },
  ArtistMusicDetail: {
    ...commonDetailProps,
    ...artistMusicDetailQuery
  },
  SearchMusicDetail: {
    ...commonDetailProps,
    ...searchMusicDetailQuery
  },
  ArchiveMusicDetail: {
    ...commonDetailProps,
    ...archiveMusicDetailQuery
  }
};
