import React from "react";
export default ({ renderChildren }) => {
  class Location extends React.Component {
    getUrl = () => {
      let { pathname, hash, search } = typeof window !== undefined ? window.location : { pathname: "/" };
      if (hash) {
        pathname = pathname + hash;
      }
      if (search) {
        pathname = pathname + search;
      }
      return pathname;
    };
    setUrl = ({ url, replace, remove, link }) => {
      let { pathname: currentPathname, search: currentSearch } = window.location;
      let currentUrl = currentPathname + currentSearch;
      let history = window.history;
      if (history && currentUrl !== url) {
        if (remove) {
          history.go(remove);
        } else if (replace) {
          history.replaceState({ link }, "", url);
        } else {
          history.pushState({ link }, "", url);
        }
      }
    };
    render() {
      const propsToProvide = {
        getUrl: this.getUrl,
        setUrl: this.setUrl
      };
      return renderChildren(this.props.children, propsToProvide);
    }
  }
  return Location;
};
