import React from "react";

export default ({ Box, theme, languages, getImage, resolveMQ }) => {
  const { colors, fonts } = theme;
  const { highlightColor } = colors;
  const { h2, h16_18 } = fonts;

  const styles = {
    styleLG: {
      languageTextStyle: {
        textStyle: {
          ...h16_18,
          color: highlightColor
        }
      },
      iconStyle: {
        viewStyle: {
          paddingLeft: 8
        },
        imageStyle: {
          transitionProperty: "transform",
          transitionDuration: "500ms"
        }
      }
    },
    styleMD: {
      languageTextStyle: {
        textStyle: {
          ...h2,
          color: highlightColor
        }
      },
      iconStyle: {
        viewStyle: {
          paddingLeft: 8
        },
        imageStyle: {
          transitionProperty: "transform",
          transitionDuration: "50ms"
        }
      }
    }
  };

  class LoginLanguageDisplayComponent extends React.Component {
    render() {
      const { getLanguage, activeMQ, isModalOpen } = this.props;
      const { style = {} } = resolveMQ(styles, ["style"], activeMQ);
      let { iconStyle = {}, languageTextStyle = {} } = style;
      iconStyle.imageStyle = {
        ...iconStyle.imageStyle,
        transform: `rotate(${isModalOpen ? 180 : 0}deg)`
      };
      return (
        <Box
          viewStyle={{ alignItems: "center" }}
          direction="row"
          {...languageTextStyle}
          render={[
            { text: languages[getLanguage && getLanguage()] },
            {
              ...iconStyle,
              image: getImage("loginLanguageSelectorDropdown")
            }
          ]}
        />
      );
    }
  }
  return LoginLanguageDisplayComponent;
};
