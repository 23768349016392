export default ({ images }) => {
  const { calenderIcon, timecalenderIcon } = images;
  const dateInputStyle = {
    boxStyle: {
      direction: "row",
      viewStyle: { alignItems: "center" }
    },
    inputContainerStyle: {
      width: "1fr"
    }
  };

  const dateCalendarStyle = {
    calendarIconStyle: {
      image: calenderIcon,
      imageStyle: { width: 15, height: 15 },
      viewStyle: { paddingLeft: 6, paddingRight: 6 }
    },
    timeCalendarIconStyle: {
      image: timecalenderIcon,
      imageStyle: { width: 15, height: 15 },
      viewStyle: { paddingLeft: 6, paddingRight: 6 }
    },

    datePickerCalenderStyle: {
      spinner: "spinner",
      confirmBtnText: "Ok",
      cancelBtnText: "Cancel"
    },
    modalStyleSM: {
      position: "screenBottom",
      height: 250,
      autoHide: true
    },
    modalStyleMD: {
      width: 300,
      height: 200,
      position: "bottom",
      autoHide: true
    },
    calendarStyle: {
      monthViewStyle: {
        boxStyle: {
          paddingTop: 10,
          paddingBottom: 10,
          cursor: "pointer"
        },
        textStyle: {
          padding: 7,
          align: "center"
        }
      },
      yearViewStyle: {
        boxStyle: {
          cursor: "pointer"
        },
        textStyle: {
          padding: 10,
          align: "center"
        }
      }
    }
  };
  return {
    dateCalendarStyle,
    dateInputStyle
  };
};
