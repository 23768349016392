import React from "react";
import {
  Box,
  I18N,
  getInput,
  MultipleText,
  theme,
  PopupHeader as Header,
  getComponent,
  resolveMQ,
  withContext,
  View,
  StatusBar as LoadingIndicator,
  showMessage,
  AppConfig,
  fireLocalDataChangeListener,
  logFirebaseAnalyticsEvent,
  isValidEmail
} from "../../../FsCloudComponent";
import { generateShareLink } from "./shareUtility";

let { colors, fonts, bgs } = theme;
let { themeColor, brownGrey, highlightColor, primaryColor, tomato } = colors;
let { lightPink } = bgs;
let { h16_l, h1_21, h9, h9_21 } = fonts;

const { APP_TERMS_CONDITION_URL: termsOfUseUrl } = AppConfig;

const componentTheme = {
  containerStyleMD: {
    viewStyle: {
      width: 539
    }
  },
  containerStyleSM: {},
  bodyComponent: {
    containerStyle: {
      viewStyle: {
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 12
      }
    },
    secHeaderStyle: {
      viewStyle: {
        paddingTop: 24,
        paddingBottom: 12
      },
      textStyle: {
        ...h16_l,
        color: primaryColor
      }
    },

    termAndConditionTextContainerStyle: {
      viewStyle: {
        paddingTop: 8,
        paddingBottom: 12,
        cursor: "pointer"
      }
    }
  },
  buttonBoxStyle: {
    direction: "row",
    viewStyle: {
      alignItems: "center",
      justifyContent: "flex-end",
      paddingTop: 12,
      paddingBottom: 12
    }
  },
  errorStyle: {
    viewStyle: {
      flex: 1,
      paddingRight: 12
    },
    textStyle: {
      ...h9,
      color: tomato,
      numberOfLines: 2
    }
  },
  cancelButtonStyle: {
    viewStyle: {
      borderRadius: 4,
      cursor: "pointer",
      paddingTop: 9,
      paddingBottom: 9,
      paddingRight: 23,
      paddingLeft: 23
    },
    textStyle: { ...h9, color: themeColor }
  },
  submitButtonStyle: {
    viewStyle: {
      borderRadius: 4,
      cursor: "pointer",
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: themeColor
    },
    textStyle: { ...h9, color: highlightColor }
  }
};
class ShareViaEmailPopup extends React.Component {
  constructor(props) {
    super(props);
    let { action, data } = props;
    let subject = I18N.t("shareMailSubject");
    if (action && action.origin && action.origin === "collection") {
      subject = data && data.name;
    }
    this.state = { subject, emails: [] };
  }

  onChangeSubject = value => {
    this.setState({ subject: value, error: void 0 });
  };

  onChangeEmail = () => {
    if (this.state.inValidEmailError || this.state.error) {
      this.setState({ inValidEmailError: void 0, error: void 0 });
    }
  };

  setValue = ({ field, value }) => {
    this.setState({ [field]: value });
  };

  validateEmail = ({ textValue, field }) => {
    if (field === "emails" && isValidEmail(textValue)) {
      if (this.state.inValidEmailError) {
        this.setState({ inValidEmailError: void 0 });
      }
      return true;
    } else {
      this.setState({ inValidEmailError: I18N.t("emailValidationError") });
      return false;
    }
  };

  onBlur = () => {};

  onSubmit = async () => {
    let { subject, emails = [], inValidEmailError } = this.state;
    if (inValidEmailError) {
      return;
    }
    const { invoke, urls, selectedIds, action: { origin } = {}, data, collaborator_mode, onClose } = this.props;
    subject = subject && subject.trim();
    this.setState({ subject });
    if (!subject) {
      this.setState({ error: I18N.t("subjectEmptyError") });
    } else if (emails.length < 1) {
      this.setState({ error: I18N.t("emailEmptyError") });
    } else if (emails.length > 25) {
      this.setState({ error: I18N.t("share/email-ids-limit") });
    } else {
      try {
        this.setState({ loading: true });
        await generateShareLink({
          fireLocalDataChangeListener,
          logFirebaseAnalyticsEvent,
          selectedIds,
          data,
          origin,
          invoke,
          urls,
          collaborator_mode,
          emails,
          subject,
          viaEmail: true
        });
        this.setState({ loading: false });
        onClose && onClose();
        showMessage && showMessage(I18N.t("itemSharedMessage"), 2000);
      } catch (e) {
        let message = e.code ? I18N.t(e.code.toString()) : void 0;
        if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
          message = e.message;
        }
        this.setState({ loading: false, error: message });
      }
    }
  };

  render() {
    const TextBoxMultiple = getComponent("multiText");
    const TextInput = getInput("text");
    let { onClose, action: { origin } = {}, data, activeMQ } = this.props;

    const {
      containerStyle,
      buttonBoxStyle,
      cancelButtonStyle,
      submitButtonStyle,
      bodyComponent,
      errorStyle
    } = resolveMQ(
      componentTheme,
      ["containerStyle", "buttonBoxStyle", "cancelButtonStyle", "submitButtonStyle", "bodyComponent", "errorStyle"],
      activeMQ
    );
    let shareHeader = "shareEmailheader";
    let uploadHeader = "uploadEmailHeader";
    if (origin === "collection" && data.type === "album") {
      shareHeader = "shareAlbumHeader";
      uploadHeader = "uploadAlbumHeader";
    }
    return (
      <View style={{ ...containerStyle.viewStyle }}>
        {this.state.loading && <LoadingIndicator />}
        <Header title={I18N.t(shareHeader)} onClose={onClose} />
        <Box
          {...bodyComponent.containerStyle}
          render={[
            {
              ...bodyComponent.secHeaderStyle,
              text: I18N.t(uploadHeader)
            },
            {
              viewStyle: {
                marginTop: 12,
                marginBottom: 15,
                backgroundColor: lightPink,
                borderRadius: 4,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 14,
                paddingBottom: 13
              },
              render: (
                <TextInput
                  placeholder={I18N.t("subjectMatter")}
                  value={this.state.subject}
                  onChangeValue={this.onChangeSubject}
                  style={{ ...h9, color: primaryColor }}
                  onDragStart={e => {
                    e && e.preventDefault && e.preventDefault();
                    e && e.stopPropagation && e.stopPropagation();
                  }}
                  maxLength={150}
                />
              )
            },
            {
              viewStyle: {
                marginTop: 15,
                marginBottom: 8,
                backgroundColor: lightPink,
                borderRadius: 4,
                paddingLeft: 12,
                paddingRight: 4,
                paddingTop: 6,
                paddingBottom: 6,
                maxHeight: 112,
                overflow: "auto"
              },
              render: (
                <TextBoxMultiple
                  data={this.state}
                  setValue={this.setValue}
                  field="emails"
                  validateField={this.validateEmail}
                  onChangeValue={this.onChangeEmail}
                  toLowerCase={true}
                  fieldDef={this.state.emails.length < 1 && { placeholder: I18N.t("addEmail") }}
                  inputProps={{
                    onBlur: this.onBlur,
                    autoFocus: true,
                    autoCapitalize: "none",
                    style: {
                      ...h9,
                      minWidth: 190,
                      paddingBottom: 7,
                      paddingTop: 8,
                      paddingRight: 8,
                      color: primaryColor
                    }
                  }}
                />
              )
            },
            {
              ...bodyComponent.termAndConditionTextContainerStyle,
              render: (
                <MultipleText
                  value={[
                    {
                      text: `${I18N.t("shareAgreementText")} `,
                      textStyle: { ...h9_21, color: brownGrey }
                    },
                    {
                      text: I18N.t("termsOfUseText"),
                      textStyle: { ...h1_21, color: themeColor },
                      link: { uri: termsOfUseUrl },
                      external: true
                    }
                  ]}
                />
              )
            },
            {
              ...buttonBoxStyle,
              render: [
                {
                  ...errorStyle,
                  text: this.state.inValidEmailError || this.state.error || ""
                },
                {
                  ...cancelButtonStyle,
                  text: I18N.t("cancel"),
                  onClick: onClose
                },
                {
                  ...submitButtonStyle,
                  text: I18N.t("submit"),
                  onClick: this.onSubmit
                }
              ]
            }
          ]}
        />
      </View>
    );
  }
}

ShareViaEmailPopup = withContext(ShareViaEmailPopup, {
  activeMQ: "ActiveMQ",
  selectedIds: "SelectionStore.selectedIds",
  invoke: "App.invoke",
  urls: "App.urls"
});

export default ShareViaEmailPopup;
