import React from "react";
import { reformatMobileNumber } from "./Utility";

export default ({ getInput, inputType = "text", theme, resolveMQ, Platform }) => {
  class PhoneNumberBox extends React.Component {
    getFormattedValue = (text = "", oldValue = "") => {
      let oldValueLength = oldValue.length;
      let textLength = text.length;
      if (oldValueLength > textLength) {
        text = oldValue.substring(0, oldValueLength - 1);
        textLength = text.length;
        if (textLength === 1 || textLength === 10) {
          text = text.substring(0, textLength - 1);
        } else if (textLength === 4) {
          text = text.substring(0, textLength - 2);
        }
      } else {
        if (textLength === 1) {
          text = "(" + text;
        } else if (textLength === 3) {
          text = text + ") ";
        } else if (textLength === 10) {
          text = text + "-";
        }
      }
      return text;
    };

    onKeyPress = evt => {
      var e = evt || window.event;
      var keyCode = e.keyCode || e.which;
      if (keyCode < 48 || keyCode > 57) {
        e.returnValue = false;
        e.preventDefault && e.preventDefault();
      }
      return true;
    };

    render() {
      let { data, field, setValue, inputProps, activeMQ } = this.props;
      const { textStyle } = resolveMQ(theme, ["textStyle"], activeMQ);
      let value = data[field];
      let Input = getInput(inputType);
      let extraInputProps = {};
      if (Platform.OS === "web") {
        extraInputProps.inputMode = "numeric";
      } else {
        extraInputProps.keyboardType = "number-pad";
      }
      value = reformatMobileNumber(value);
      return (
        <Input
          style={{ ...textStyle }}
          value={value}
          onChangeValue={text => {
            let values = text.match(/\d+/g);
            text = values && values.length ? values.join("") : "";
            setValue && setValue({ data, field, value: text });
          }}
          onKeyPress={this.onKeyPress}
          {...extraInputProps}
          {...inputProps}
        />
      );
    }
  }
  return PhoneNumberBox;
};
