export default ({ colors }) => {
  let { themeColor, highlightColor, greyish } = colors;
  const switchStyleMD = {
    leftBackgroundColor: greyish,
    rightBackgroundColor: themeColor,
    knobColor: highlightColor,
    borderWidth: "0px",
    knobHeight: "10px",
    knobWidth: "10px",
    knobRadius: "5px",
    height: "14px",
    width: "28px",
    knobGap: "2px"
  };

  const switchStyleSM = {
    leftBackgroundColor: greyish,
    rightBackgroundColor: themeColor,
    knobColor: highlightColor,
    borderWidth: "0px",
    knobHeight: "15px",
    knobWidth: "15px",
    knobRadius: "8px",
    height: "22px",
    width: "40px",
    knobGap: "3px"
  };

  return {
    switchStyleSM,
    switchStyleMD
  };
};
