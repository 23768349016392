import React from "react";
import moment from "moment";
import { ImageFloatingActions } from "./ImageDetailActions";
import GalleryAllWrapper from "./GalleryAllWrapper";
import {
  GalleryAllNoData,
  VaultGalleryAllNoData,
  SearchGalleryTypeNoData,
  GroupGalleryNoDataMD,
  GroupGalleryNoDataSM,
  MemoriesOfDayNoData,
  ArchiveGalleryAllNoData,
  DuplicateResourceNoData
} from "../noData/NoData";
import ResourceActions from "../resource/ResourceActions";
import {
  memoriesOfDayQuery,
  galleryAllQuery,
  searchGalleryQuery,
  groupGalleryQuery,
  vaultGalleryQuery,
  archiveGalleryQuery,
  duplicateResourcesQuery,
  highlightsAllQuery
} from "../queries";
import {
  Platform,
  I18N,
  SearchType,
  AnimatedRecyclerList,
  RecyclerListWithHeader,
  setVaultMode,
  typeCastDateField
} from "../../../FsCloudComponent";

import {
  isRemoveFromGroupVisible,
  isImportFromGroupVisible,
  makePrivateActionVisibility,
  uploadResourceActionVisibility,
  getSelectedData
} from "../resource/ResourceUtility";
import GalleryAllRowSM from "./GalleryAllRowSM";

const closeVault = {
  type: "close",
  onClose: () => {
    setVaultMode && setVaultMode();
  }
};

const searchGalleryWithTypeQuery = {
  ...searchGalleryQuery
};

const searchGalleryAllQuery = {
  ...searchGalleryQuery,
  connectProps: {
    ...searchGalleryQuery.connectProps,
    skipFetchOnMount: true,
    discardOldResult: true
  }
};

const detailLink = ({ data }) => {
  return {
    uri: `/gallery-detail`,
    detailId: data._id,
    origin: "gallery",
    isCastPresent: Platform.OS !== "web"
  };
};

const duplicateResourcesDetailLink = ({ data }) => {
  return {
    uri: `/duplicate-resource-detail`,
    detailId: data._id,
    origin: "gallery"
  };
};

const memoryOfDayDetailLink = ({ data }) => {
  return {
    uri: `/gallery-detail`,
    detailId: data._id,
    fromHighlight: "memoryOfDay",
    origin: "gallery"
  };
};

const groupDetailLink = ({ data }) => {
  return {
    uri: `/group-gallery-detail`,
    decryptionSourceProps: { decryptionSource: "group" },
    detailId: data._id,
    origin: "gallery",
    fromGroup: true
  };
};

const searchDetailLink = ({ data }) => {
  return {
    uri: `/search-gallery-detail`,
    detailId: data._id,
    origin: "gallery"
  };
};

const searchGroupGalleryDetailLink = ({ data }) => {
  return {
    uri: `/search-gallery-detail`,
    decryptionSourceProps: { decryptionSource: "group" },
    detailId: data._id,
    origin: "gallery",
    fromGroup: true
  };
};

const vaultGalleryDetailLink = ({ data }) => {
  return {
    uri: `/vault-gallery-detail`,
    detailId: data._id,
    origin: "vaultGallery"
  };
};

const archiveGalleryDetailLink = ({ data }) => {
  return {
    uri: `/archive-gallery-detail`,
    detailId: data._id,
    origin: "gallery"
  };
};

const getRowPropsSM = source => {
  let decryptionSourceProps = { decryptionSource: source };
  return Platform.OS === "web"
    ? {
        rowProps: {
          imageField: "thumbnail_url",
          renderHeader: props => ImageFloatingActions(props, source),
          decryptionSourceProps
        }
      }
    : {
        rowRenderer: props => {
          return (
            <GalleryAllRowSM
              {...props}
              imageField={"thumbnail_url"}
              renderHeader={props => ImageFloatingActions(props, source)}
              decryptionSourceProps={decryptionSourceProps}
            />
          );
        }
      };
};

const commonPropsSM = {
  component: "table",
  dataObject: "Resource",
  ComponentSM: AnimatedRecyclerList,
  componentPropsSM: {
    ...getRowPropsSM(),
    imageField: "thumbnail_url",
    imageHeight: "thumbnail_height",
    imageWidth: "thumbnail_width",
    indicator_field: "resource_lastModified",
    headerField: ({ data, indicator_field }) =>
      data && data[indicator_field] && moment(typeCastDateField(data[indicator_field])).format("D MMM YYYY"),
    headerLabel: ({ data, indicator_field }) => {
      let lastModified = data && data[indicator_field] && new Date(typeCastDateField(data[indicator_field]));
      let headerText = I18N.t("albumDateLabel");
      headerText =
        lastModified &&
        headerText
          .replace("__month__", I18N.t(`month_${lastModified.getMonth()}`))
          .replace("__date__", lastModified.getDate())
          .replace("__year__", lastModified.getFullYear());
      return headerText;
    },
    addMinHeight: 60,
    style: { flex: 1 },
    detailLink
  }
};

const commonPropsMD = {
  component: "table",
  dataObject: "Resource",
  listProps: {
    list: "webMasonaryList",
    pageSize: 10000,
    imageField: "thumbnail_url",
    imageHeight: "thumbnail_height",
    imageWidth: "thumbnail_width",
    rowProps: {
      renderHeader: ImageFloatingActions
    },
    detailLink
  },
  notifyNavOnScroll: true
};

const searchCommonPropsSM = {
  ...commonPropsSM,
  componentPropsSM: {
    ...commonPropsSM.componentPropsSM,
    indicator_field: void 0,
    detailLink: searchDetailLink
  },
  pageSize: 1000
};

const searchCommonPropsMD = {
  ...commonPropsMD,
  listProps: {
    ...commonPropsMD.listProps,
    hideDateHeader: true,
    detailLink: searchDetailLink
  },
  pageSize: 1000
};

const headerActionsSM = [
  {
    type: "headerAction",
    image: "shareGreyIcon",
    title: () => I18N.t("toShareTitle"),
    visible: props => {
      let selectedData = getSelectedData(props);
      return uploadResourceActionVisibility({ selectedData });
    },
    action: ["postToGroup", "webShareGallery", "nativeShareGallery", "getLink", "getLinkNative"]
  }
];

const headerActionsMD = [
  {
    type: "headerAction",
    image: "shareIcon",
    title: () => I18N.t("toShareTitle"),
    visible: props => {
      let selectedData = getSelectedData(props);
      return uploadResourceActionVisibility({ selectedData });
    },
    action: "webShareGallery"
  },
  {
    type: "headerAction",
    image: "addToCollectionIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "addImagesToAlbumForm"
  },
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewGallery"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "editIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "rename"
  },
  {
    type: "headerAction",
    image: "makePrivateIcon",
    title: () => I18N.t("makePrivate"),
    visible: props => makePrivateActionVisibility(props),
    action: "makePrivate"
  },
  {
    type: "headerAction",
    image: "moveToVaultIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "moveToVault"
  },
  {
    type: "headerAction",
    image: "moveToArchiveIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "moveToArchive"
  },
  {
    type: "headerAction",
    image: "deleteIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "markDelete"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone", "downloadZip", "saveAsPdfWeb", "saveAsGif"]
  }
];

const VaultHeaderActionsMD = [
  {
    type: "headerAction",
    image: "addToCollectionIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "addImagesToVaultAlbumForm"
  },
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewVaultGallery"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "editIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "rename"
  },
  {
    type: "headerAction",
    image: "removeFromVaultIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "removeFromVault"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone"]
  }
];
const ArchiveHeaderActionsMD = [
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewArchiveGallery"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "editIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "rename"
  },
  {
    type: "headerAction",
    image: "removeFromArchiveIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "removeFromArchive"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone"]
  }
];

const groupGalleryHeaderActionsSM = [
  {
    type: "headerAction",
    image: "importGreyIcon",
    visible: isImportFromGroupVisible,
    action: "importItemFromGroup"
  },
  {
    type: "headerAction",
    image: "pinRemoveGreyIcon",
    visible: isRemoveFromGroupVisible,
    action: "removeItemFromGroup"
  }
];

const groupGalleryHeaderActionsMD = [
  {
    type: "headerAction",
    image: "importIcon",
    visible: isImportFromGroupVisible,
    action: "importItemFromGroup"
  },
  {
    type: "headerAction",
    image: "pinRemoveIcon",
    visible: isRemoveFromGroupVisible,
    action: "removeItemFromGroup"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone"]
  }
];

const duplicateResourceHeaderActionsMD = [
  {
    type: "headerAction",
    image: "shareIcon",
    title: () => I18N.t("toShareTitle"),
    visible: props => {
      let selectedData = getSelectedData(props);
      return uploadResourceActionVisibility({ selectedData });
    },
    action: "webShareGallery"
  },
  {
    type: "headerAction",
    image: "addToCollectionIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "addImagesToAlbumForm"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "editIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "rename"
  },
  {
    type: "headerAction",
    image: "makePrivateIcon",
    title: () => I18N.t("makePrivate"),
    visible: props => makePrivateActionVisibility(props),
    action: "makePrivate"
  },
  {
    type: "headerAction",
    image: "moveToVaultIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "moveToVault"
  },
  {
    type: "headerAction",
    image: "deleteIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "duplicateMarkDelete"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone", "downloadZip"]
  }
];

export default {
  DuplicateResourcesMD: {
    title: () => I18N.t("duplicateResources"),
    ...commonPropsMD,
    ...duplicateResourcesQuery,
    listProps: {
      ...commonPropsMD.listProps,
      list: "virtualMasonaryList",
      customHeaderField: "resource_md5Hash",
      customHeaderLabelField: "name", // will select top item from array & show's its key value
      detailLink: duplicateResourcesDetailLink
    },
    renderNoData: <DuplicateResourceNoData />,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ data, selectionMode }) => data && !selectionMode,
        action: ["selectItemsWithIcon"]
      },
      ...duplicateResourceHeaderActionsMD
    ]
  },
  DuplicateResourcesSM: {
    title: () => I18N.t("duplicateResources"),
    ...commonPropsSM,
    componentPropsSM: {
      ...commonPropsSM.componentPropsSM,
      headerField: ({ data } = {}) => data && data.resource_md5Hash,
      headerLabel: ({ data } = {}) => data && data.name, //will select top item from array & show's its key value
      detailLink: duplicateResourcesDetailLink
    },
    ...duplicateResourcesQuery,
    ComponentSM: RecyclerListWithHeader,
    // ComponentWrapper: GalleryAllWrapper,
    renderNoData: <DuplicateResourceNoData />,
    headerActions: [
      ...headerActionsSM,
      {
        type: "multiActions",
        action: [
          "selectNone",
          "selectItems",
          "selectAll",
          "addImagesToAlbumLink",
          "download",
          "rename",
          "makePrivate",
          "moveToVault",
          "duplicateMarkDelete",
          "layout"
        ]
      }
    ]
  },
  GalleryAll: {
    ...commonPropsSM,
    isCastPresent: Platform.OS !== "web",
    ComponentSM: RecyclerListWithHeader,
    ComponentWrapper: GalleryAllWrapper,
    ...galleryAllQuery,
    renderNoData: <GalleryAllNoData />,
    headerActions: [
      "searchGallery",
      ...headerActionsSM,
      {
        type: "multiActions",
        action: [
          "uploadGalleryMobileLink",
          "cameraUpload",
          "webUpload",
          "selectNone",
          "selectItems",
          "selectAll",
          "addImagesToAlbumLink",
          "download",
          "saveAsPdf",
          "saveAsGif",
          "downloadAsPdf",
          "saveAsPdfWeb",
          "printFile",
          "rename",
          "makePrivate",
          "moveToVault",
          "moveToArchive",
          "markDelete",
          "layout",
          "sortingCreatedOn",
          "sortingLastModified",
          "sortingSize"
        ]
      }
    ]
  },
  GalleryAllWeb: {
    ...commonPropsMD,
    ...galleryAllQuery,
    listProps: {
      ...commonPropsMD.listProps,
      list: "virtualMasonaryList",
      showCalender: true
    },
    renderNoData: <GalleryAllNoData />,
    headerActions: [
      "searchGallery",
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => data && !selectionMode,
        menuModalStyle: {
          modal: {
            stopPropagation: true,
            width: 270,
            position: "cover",
            autoHide: true,
            style: { borderRadius: 4 }
          }
        },
        action: [
          "webUploadWithIcon",
          { ...ResourceActions.downloadZipAll, resourceType: "gallery" },
          "selectItemsWithIcon",
          "sortingCreatedOn",
          "sortingLastModified",
          "sortingSize"
        ]
      },
      ...headerActionsMD
    ]
  },
  MemoriesOfDaySM: {
    ...commonPropsSM,
    componentPropsSM: {
      ...commonPropsSM.componentPropsSM,
      detailLink: memoryOfDayDetailLink
    },
    ...memoriesOfDayQuery,
    titleSM: () => I18N.t("memories"),
    renderNoData: <MemoriesOfDayNoData />,
    headerActions: [
      ...headerActionsSM,
      {
        type: "multiActions",
        action: ["selectNone", "selectItems", "selectAll", "layout"]
      }
    ]
  },
  ViewAllStoriesMD: {
    ...commonPropsMD,
    ...highlightsAllQuery,
    listProps: {
      ...commonPropsMD.listProps,
      list: "virtualMasonaryList",
      scrollViewStyle: {
        marginLeft: 81,
        marginTop: 24
      },
      detailLink: memoryOfDayDetailLink
    },
    titleMD: () => I18N.t("highlights"),
    headerActions: [
      {
        type: "headerAction",
        image: "shareIcon",
        title: () => I18N.t("toShareTitle"),
        visible: props => {
          let selectedData = getSelectedData(props);
          return uploadResourceActionVisibility({ selectedData });
        },
        action: "webShareGallery"
      },
      {
        type: "headerAction",
        image: "iconMenu",
        visible: ({ data }) => data && data.length,
        action: ["selectNone", "selectItems", "selectAll"]
      }
    ]
  },
  ViewAllStoriesSM: {
    ...commonPropsSM,
    ComponentSM: RecyclerListWithHeader,

    componentPropsSM: {
      ...commonPropsSM.componentPropsSM,

      detailLink: memoryOfDayDetailLink
    },

    ...highlightsAllQuery,
    titleSM: () => I18N.t("highlights"),
    visible: ({ data }) => data && data.length > 0,

    headerActions: [
      ...headerActionsSM,
      {
        type: "multiActions",

        action: ["selectNone", "selectItems", "selectAll", "layout"]
      }
    ]
  },
  MemoriesOfDayMD: {
    ...commonPropsMD,
    ...memoriesOfDayQuery,
    listProps: {
      ...commonPropsMD.listProps,
      list: "virtualMasonaryList",
      scrollViewStyle: {
        marginLeft: 81,
        marginTop: 24
      },
      detailLink: memoryOfDayDetailLink
    },
    renderNoData: <MemoriesOfDayNoData />,
    titleMD: () => I18N.t("memories"),
    headerActions: [
      {
        type: "headerAction",
        image: "shareIcon",
        title: () => I18N.t("toShareTitle"),
        visible: props => {
          let selectedData = getSelectedData(props);
          return uploadResourceActionVisibility({ selectedData });
        },
        action: "webShareGallery"
      },
      {
        type: "headerAction",
        image: "iconMenu",
        visible: ({ data, selectionMode }) => selectionMode && data && data.length,
        action: ["selectAll", "selectNone"]
      }
    ]
  },
  SearchGalleryAll: {
    ...searchCommonPropsSM,
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...headerActionsSM,
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: [
          "selectNone",
          "selectItems",
          "selectAll",
          "addImagesToAlbumLink",
          "download",
          "saveAsPdf",
          "downloadAsPdf",
          "rename",
          "makePrivate",
          "moveToVault",
          "moveToArchive",
          "markDelete",
          "layout"
        ]
      }
    ]
  },
  SearchGalleryAllWithType: {
    ...searchCommonPropsSM,
    ...searchGalleryWithTypeQuery,
    renderNoData: <SearchGalleryTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActions: [
      ...headerActionsSM,
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: [
          "selectNone",
          "selectItems",
          "selectAll",
          "addImagesToAlbumLink",
          "download",
          "saveAsPdf",
          "downloadAsPdf",
          "rename",
          "makePrivate",
          "moveToVault",
          "moveToArchive",
          "markDelete",
          "layout"
        ]
      }
    ]
  },
  SearchGalleryAllWeb: {
    ...searchCommonPropsMD,
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActions: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...headerActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchGalleryAllWithTypeWeb: {
    ...searchCommonPropsMD,
    ...searchGalleryWithTypeQuery,
    renderNoData: <SearchGalleryTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActions: [
      ...headerActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  GroupGallery: {
    ...commonPropsSM,
    // ComponentSM: RecyclerListWithHeader,
    componentPropsSM: {
      ...commonPropsSM.componentPropsSM,
      ...getRowPropsSM("group"),
      indicator_field: "postedOn",
      detailLink: groupDetailLink
    },
    pageSize: 1000,
    renderNoData: <GroupGalleryNoDataSM />,
    notifyNavOnScroll: true,
    ...groupGalleryQuery,
    headerActions: [
      "searchGroupGallery",
      {
        type: "headerAction",
        image: "groupPinGreyIcon",
        visible: ({ selectionMode }) => !selectionMode,
        action: "selectImagesForGroup"
      },
      ...groupGalleryHeaderActionsSM,
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => !selectionMode && data && data.length,
        action: ["selectItems", "selectAll", "selectNone"]
      }
    ]
  },
  GroupGalleryWeb: {
    ...commonPropsMD,
    listProps: {
      ...commonPropsMD.listProps,
      decryptionSourceProps: { decryptionSource: "group" },
      detailLink: groupDetailLink
    },
    renderNoData: <GroupGalleryNoDataMD />,
    ...groupGalleryQuery,
    headerActions: [
      "searchGroupGallery",
      {
        type: "multiActions",
        image: "groupPinIcon",
        title: () => I18N.t("post"),
        action: "selectImagesForGroupWeb",
        visible: ({ data, selectionMode }) => !selectionMode && data && data.length
      },
      ...groupGalleryHeaderActionsMD,
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => !selectionMode && data && data.length,
        action: ["selectItemsWithIcon"]
      }
    ]
  },
  SearchGroupGallery: {
    ...searchCommonPropsSM,
    componentPropsSM: {
      ...searchCommonPropsSM.componentPropsSM,
      ...getRowPropsSM("group"),
      detailLink: searchGroupGalleryDetailLink
    },
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActions: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...groupGalleryHeaderActionsSM
    ]
  },
  SearchGroupGalleryWeb: {
    ...searchCommonPropsMD,
    listProps: {
      ...searchCommonPropsMD.listProps,
      decryptionSourceProps: { decryptionSource: "group" },
      detailLink: searchGroupGalleryDetailLink
    },
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActions: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...groupGalleryHeaderActionsMD
    ]
  },
  VaultGallery: {
    ...commonPropsSM,
    ComponentSM: RecyclerListWithHeader,
    componentPropsSM: {
      ...commonPropsSM.componentPropsSM,
      detailLink: vaultGalleryDetailLink
    },
    ...vaultGalleryQuery,
    renderNoData: <VaultGalleryAllNoData />,
    headerActions: [
      closeVault,
      "searchVaultGallery",
      {
        type: "multiActions",
        action: [
          "uploadVaultGalleryMobileLink",
          "webUpload",
          "selectImagesForSafe",
          "selectNone",
          "selectItems",
          "selectAll",
          "addImagesToVaultAlbumLink",
          "download",
          "rename",
          "removeFromVault",
          "layout"
        ]
      }
    ]
  },
  VaultGalleryWeb: {
    ...commonPropsMD,
    ...vaultGalleryQuery,
    listProps: {
      ...commonPropsMD.listProps,
      list: "virtualMasonaryList",
      showCalender: true,
      detailLink: vaultGalleryDetailLink
    },
    renderNoData: <VaultGalleryAllNoData />,
    headerActions: [
      closeVault,
      {
        ...ResourceActions.searchVaultGallery,
        containerStyle: { flex: 5, alignItems: "center", overflow: void 0 }
      },
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => data && !selectionMode,
        menuModalStyle: {
          modal: {
            stopPropagation: true,
            width: 270,
            position: "cover",
            autoHide: true,
            style: { borderRadius: 4 }
          }
        },
        action: [
          "webUploadWithIcon",
          "selectImagesForSafeWeb",
          "selectItemsWithIcon",
          "sortingCreatedOn",
          "sortingLastModified"
        ]
      },
      ...VaultHeaderActionsMD,
      {
        type: "webAppUpload",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchVaultGallery: {
    ...searchCommonPropsSM,
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: ["selectNone", "selectItems", "selectAll", "download", "rename", "removeFromVault", "layout"]
      }
    ]
  },
  SearchVaultGalleryWithType: {
    ...searchCommonPropsSM,
    ...searchGalleryWithTypeQuery,
    renderNoData: <SearchGalleryTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActions: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectNone", "selectItems", "selectAll", "download", "rename", "removeFromVault", "layout"]
      }
    ]
  },
  SearchVaultGalleryWeb: {
    ...searchCommonPropsMD,
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActions: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...VaultHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchVaultGalleryWithTypeWeb: {
    ...searchCommonPropsMD,
    ...searchGalleryWithTypeQuery,
    renderNoData: <SearchGalleryTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActions: [
      ...VaultHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  ArchiveGallery: {
    ...commonPropsSM,
    ComponentSM: RecyclerListWithHeader,
    componentPropsSM: {
      ...commonPropsSM.componentPropsSM,
      detailLink: archiveGalleryDetailLink
    },
    ...archiveGalleryQuery,
    renderNoData: <ArchiveGalleryAllNoData />,
    headerActions: [
      "searchArchiveGallery",
      {
        type: "multiActions",
        action: [
          "selectImagesForArchive",
          "selectNone",
          "selectItems",
          "selectAll",
          "download",
          "rename",
          "removeFromArchive",
          "layout"
        ]
      }
    ]
  },
  ArchiveGalleryWeb: {
    ...commonPropsMD,
    ...archiveGalleryQuery,
    listProps: {
      ...commonPropsMD.listProps,
      list: "virtualMasonaryList",
      showCalender: true,
      detailLink: archiveGalleryDetailLink
    },
    renderNoData: <ArchiveGalleryAllNoData />,
    headerActions: [
      {
        ...ResourceActions.searchArchiveGallery,
        containerStyle: { flex: 5, alignItems: "center", overflow: void 0 }
      },
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => data && !selectionMode,
        menuModalStyle: {
          modal: {
            stopPropagation: true,
            width: 270,
            position: "cover",
            autoHide: true,
            style: { borderRadius: 4 }
          }
        },
        action: ["selectImagesForArchiveWeb", "selectItemsWithIcon", "sortingCreatedOn", "sortingLastModified"]
      },
      ...ArchiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchArchiveGallery: {
    ...searchCommonPropsSM,
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: ["selectNone", "selectItems", "selectAll", "download", "rename", "removeFromArchive", "layout"]
      }
    ]
  },
  SearchArchiveGalleryWithType: {
    ...searchCommonPropsSM,
    ...searchGalleryWithTypeQuery,
    renderNoData: <SearchGalleryTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActions: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectNone", "selectItems", "selectAll", "download", "rename", "removeFromArchive", "layout"]
      }
    ]
  },
  SearchArchiveGalleryWeb: {
    ...searchCommonPropsMD,
    ...searchGalleryAllQuery,
    renderNoData: <SearchType />,
    headerActions: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...ArchiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchArchiveGalleryWithTypeWeb: {
    ...searchCommonPropsMD,
    ...searchGalleryWithTypeQuery,
    renderNoData: <SearchGalleryTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActions: [
      ...ArchiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  }
};
