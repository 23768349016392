export default ({ resolveImage }) => {
  const walkthroughLogo = "/webImages/logo/walkthroughLogo.png";
  const landingLogo = "/webImages/logo/landingLogo.png";
  const loginOptionLogo = () =>
    resolveImage({
      SM: "/webImages/logo/loginOptionLogo.png",
      MD: "/webImages/logo/loginOptionLogoMD.png",
      LG: "/webImages/logo/loginOptionLogoLG.png"
    });
  const headerLogo = () =>
    resolveImage({
      SM: "/webImages/logo/headerLogo.png",
      MD: "/webImages/logo/headerLogoMD.png",
      LG: "/webImages/logo/headerLogoMD.png"
    });
  const headerLogoWhiteText = () =>
    resolveImage({
      SM: "/webImages/logo/headerLogo.png",
      MD: "/webImages/logo/headerLogoMD.png",
      LG: "/webImages/logo/headerLogoWhiteTextLG.png"
    });
  const loginLogo = "/webImages/logo/loginLogo.png";
  const menuHeaderLogo = "/webImages/logo/menuHeaderLogo.png";
  const aboutLogo = "/webImages/logo/aboutLogo.png";

  return {
    walkthroughLogo,
    landingLogo,
    loginLogo,
    loginOptionLogo,
    headerLogo,
    headerLogoWhiteText,
    menuHeaderLogo,
    aboutLogo
  };
};
