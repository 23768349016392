import React from "react";
import { googleCast } from "../../../FsCloudComponent";

export default class GalleryAllWrapper extends React.Component {
  removeToken = async () => {
    try {
      googleCast.removeToken();
      await googleCast.endSession();
    } catch (err) {
      // console.warn(err, "[removeToken] [RecyclerList] [RecyclerListWithHeader.native.js] failed.");
    }
  };

  componentDidMount() {
    let { isCastPresent } = this.props;
    if (isCastPresent && googleCast) {
      googleCast.EventEmitter.addListener(googleCast.SESSION_ENDED, this.removeToken);
    }
  }

  componentWillUnmount() {
    let { isCastPresent } = this.props;
    if (isCastPresent && googleCast) {
      this.removeToken();
      googleCast.EventEmitter.removeListener(googleCast.SESSION_ENDED, this.removeToken);
    }
  }

  render() {
    return this.props.children;
  }
}
