import React from "react";
import { SelectPlaylistCard, SelectPlaylistHeader } from "./MusicCards";
import { PlaylistNoData } from "../noData/NoData";
import { selectPlaylistQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

export default {
  SelectPlaylist: {
    component: "table",
    dataObject: "Collection",
    title: () => I18N.t("select"),
    selectable: false,
    renderNoData: <PlaylistNoData selectPlaylist />,
    ...selectPlaylistQuery,
    headerActions: [
      {
        type: "headerAction",
        text: () => I18N.t("newPlaylistCaps"),
        action: "createPlaylist"
      }
    ],
    renderHeader: SelectPlaylistHeader,
    rowProps: {
      ...SelectPlaylistCard
    }
  }
};
