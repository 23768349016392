export default ({ colors, bgs, fonts, images, I18N }) => {
  const { darkBg, darkOverlayBg } = bgs;
  const { highlightColor, greyish } = colors;
  const { h1, h2 } = fonts;

  const documentSliderStyle = {
    boxStyle: {
      viewStyle: {
        backgroundColor: darkOverlayBg,
        flex: 1
      },
      direction: "row"
    },
    contentWrapperStyle: {
      viewStyle: {
        flex: 2
      }
    },
    leftArrowIconStyle: {
      viewStyle: {
        justifyContent: "center",
        alignSelf: "center",
        padding: 10
      },
      image: images.previousIcon
    },
    rightArrowIconStyle: {
      viewStyle: {
        justifyContent: "center",
        alignSelf: "center",
        padding: 10
      },
      image: images.nextIcon
    },
    imageContainerStyle: {
      boxStyle: {
        viewStyle: {
          flex: 1
        },
        direction: "row"
      },
      imageViewConatinerStyle: {
        viewStyle: {
          flex: 1,
          padding: 10
        }
      }
    },
    documentDetailStyle: {
      boxStyle: {
        viewStyle: {
          flex: 1,
          padding: 10
        }
      },
      headerStyle: {
        boxStyle: {
          direction: "row",
          viewStyle: {
            justifyContent: "space-between",
            padding: 10
          }
        },
        titleStyle: {
          textStyle: {
            ...h1,
            color: highlightColor
          },
          text: () => I18N.t("detail")
        },
        crossStyle: {
          viewStyle: {
            cursor: "pointer"
          },
          textStyle: {
            color: highlightColor
          },
          text: "X"
        }
      },
      descriptionStyle: {
        boxStyle: {
          viewStyle: {
            padding: 10
          }
        },
        labelStyle: {
          textStyle: {
            ...h1,
            color: highlightColor
          }
        },
        valueStyle: {
          textStyle: {
            ...h2,
            color: highlightColor
          },
          viewStyle: {
            marginTop: 10
          }
        }
      },
      generalInfoStyleSM: {
        viewStyle: {
          padding: 10
        }
      },
      genaralInfoStyleMD: {
        viewStyle: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 18,
          paddingRight: 18
        }
      },
      generalInfoHeaderStyle: {
        viewStyle: {
          borderBottomWidth: 1,
          borderColor: greyish,
          paddingTop: 9,
          paddingBottom: 9
        },
        textStyle: {
          ...h1,
          color: highlightColor
        },
        text: () => I18N.t("generalInfoCaps")
      },

      generalInfoItemStyleMD: {
        direction: "row",
        viewStyle: {
          justifyContent: "space-between",
          paddingTop: 9,
          paddingBottom: 9
        }
      },
      generalInfoItemStyleSM: {
        viewStyle: {
          justifyContent: "space-between",
          paddingTop: 9,
          paddingBottom: 9
        }
      },
      generalInfoItemLabelStyle: {
        textStyle: {
          ...h2,
          color: highlightColor
        }
      },
      generalInfoItemValueStyle: {
        textStyle: {
          ...h2,
          color: highlightColor
        }
      }
    },
    headerStyle: {
      containerStyle: {
        direction: "row",
        viewStyle: {
          height: 50,
          padding: 10,
          backgroundColor: darkBg,
          alignItems: "center"
        },
        itemStyle: {
          padding: 10
        }
      },
      imageNameStyle: {
        textStyle: {
          color: highlightColor,
          numberOfLines: 2
        }
      },
      downloadIconStyle: {
        image: images.downloadWhiteIcon
      }
    }
  };
  return {
    documentSliderStyle
  };
};
