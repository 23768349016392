import React from "react";

const modalProps = {
  modalStyleSM: {
    position: "center",
    width: 0.9,
    animationType: "none",
    style: {
      borderRadius: 4,
      overflow: "hidden"
    },
    parentStyle: {
      zIndex: 100
    },
    escEnabled: false
  },
  modalStyleMD: {
    position: "center",
    style: {
      borderRadius: 4,
      overflow: "hidden"
    },
    parentStyle: {
      zIndex: 100
    },
    escEnabled: false
  }
};

const propmtMessages = {
  music: "musicUploadPromptMessage",
  doc: "docUploadPromptMessage"
};

export default ({ Modal, I18N, ConfirmBox, AsyncStorage, resolveMQ }) => {
  class RenderPromptMessage extends React.Component {
    state = {};

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    componentWillUnmount() {
      this._unmounted = true;
    }

    async componentDidMount() {
      let { source } = this.props;
      let promptDisplayed = await AsyncStorage.getItem(`${source}_prompt_displayed`);
      if (promptDisplayed !== "true") {
        this._setState({ showPrompt: true });
      }
    }

    onConfirm = () => {
      let { source } = this.props;
      AsyncStorage.setItem(`${source}_prompt_displayed`, "true");
      this._setState({ showPrompt: false });
    };

    render() {
      let { showPrompt } = this.state;
      if (!showPrompt) {
        return null;
      }
      let { source, activeMQ } = this.props;
      let { modalStyle } = resolveMQ(modalProps, ["modalStyle"], activeMQ);
      return (
        <Modal key={`${source}_prompt`} {...modalStyle} isOpen={true}>
          <ConfirmBox
            {...{
              message: I18N.t(propmtMessages[source]),
              confirmText: I18N.t("okCaps"),
              onConfirm: this.onConfirm
            }}
          />
        </Modal>
      );
    }
  }
  return RenderPromptMessage;
};
