import React from "react";
export default ({ Box, getLabelValue, theme }) => ({ fieldDef, item, removeItem }) => {
  let labelValue = item;
  if (getLabelValue) {
    labelValue = getLabelValue({ item: labelValue, fieldDef });
  }
  return (
    <Box
      {...theme.boxStyle}
      render={[
        {
          ...theme.labelStyle,
          text: labelValue
        },
        {
          ...theme.crossStyle,
          onClick: removeItem
        }
      ]}
    />
  );
};
