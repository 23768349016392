import React from "react";
import { Box, getImage, Link, theme, I18N, ScrollView } from "../../../FsCloudComponent";

const { colors, fonts, bgs } = theme;
const { primaryColor } = colors;
const { borderBg5 } = bgs;
const { h16_l } = fonts;

class ConnectionView extends React.Component {
  rowCard = ({ image, title, link, skipBorder }) => {
    let borderStyle = skipBorder ? void 0 : { borderBottomWidth: 1, borderBottomColor: borderBg5 };
    return {
      render: [
        {
          direction: "row",
          Container: Link,
          containerProps: {
            action: {
              link: {
                ...link
              }
            }
          },
          viewStyle: {
            alignItems: "center",
            paddingTop: 20,
            paddingBottom: 20,
            marginLeft: 15,
            marginRight: 15,
            ...borderStyle
          },
          render: [
            {
              width: "1fr",
              direction: "row",
              viewStyle: { alignItems: "center" },
              render: [
                {
                  viewStyle: { paddingRight: 12 },
                  image: getImage(image)
                },
                {
                  viewStyle: { paddingLeft: 12 },
                  textStyle: {
                    color: primaryColor,
                    ...h16_l
                  },
                  text: title
                }
              ]
            },
            {
              viewStyle: { height: 24, width: 24, alignItems: "center", justifyContent: "center" },
              image: getImage("rightArrowIcon")
            }
          ]
        }
      ]
    };
  };

  render() {
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          render={[
            {
              viewStyle: {
                padding: 18
              },
              textStyle: {
                ...h16_l,
                color: primaryColor
              },
              text: I18N.t("viewContent")
            },
            this.rowCard({
              title: "Facebook",
              image: "facebookIcon",
              link: {
                uri: "/facebook"
              }
            }),
            this.rowCard({
              title: "Gmail",
              image: "gmailIcon",
              link: {
                uri: "/gmail"
              },
              skipBorder: true
            })
          ]}
        />
      </ScrollView>
    );
  }
}

export default {
  MyConnections: {
    component: "custom",
    Component: ConnectionView,
    title: () => I18N.t("myConnections")
  }
};
