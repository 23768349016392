import { I18N } from "../../../FsCloudComponent";

const actions = {
  editProfile: {
    type: "link",
    text: () => I18N.t("editMyProfile"),
    visible: data => data && data.myProfile,
    link: { uri: "/profile#profile" }
  },
  leaveCollection: {
    type: "invoke",
    service: (props, { urls }) => {
      const { link } = props;
      let collectionId =
        link && link.props && link.props.params && link.props.params.collection && link.props.params.collection._id;
      const commandToPost = {
        url: urls["leftSharedCollection"],
        uriProps: {
          data: { collection: { _id: collectionId } }
        }
      };
      return commandToPost;
    },
    afterInvoke: (result, props) => {
      let { deleteUri, link, getPath } = props;
      deleteUri && deleteUri(getPath && getPath(), link);
    }
  },
  makePrivate: {
    type: "invoke",
    text: () => I18N.t("makeItPrivate"),
    title: () => I18N.t("makeItPrivate"),
    visible: (data, { link }) => {
      let { props: { params: { collection = {} } } = {} } = link || {};
      if (collection && collection.owner) {
        return true;
      }
    },
    confirm: {
      titleMD: () => I18N.t("makePrivatePopupHeaderCaps"),
      confirmText: () => I18N.t("makePrivate"),
      cancelText: () => I18N.t("cancel"),
      message: () => I18N.t("makePrivateResourcePopupMessage")
    },
    postMessage: () => I18N.t("collectionMakePrivate"),
    service: (props, { urls }) => {
      const { link } = props;
      let { props: { params: { collection = {} } } = {} } = link || {};
      let collectionId = collection && collection._id;
      if (collectionId) {
        const commandToPost = {
          url: urls["markPrivate"],
          uriProps: {
            data: { collectionId }
          }
        };
        return commandToPost;
      }
    },
    afterInvoke: (result, props) => {
      let { deleteUri, link, getPath } = props;
      deleteUri && deleteUri(getPath && getPath(), link);
    }
  }
};

actions.leaveAlbum = {
  ...actions.leaveCollection,
  text: () => I18N.t("leaveAlbum"),
  title: () => I18N.t("leaveAlbum"),
  postMessage: () => I18N.t("albumLeaveMessage"),
  visible: (data, { link }) => {
    let { props: { params: { collection } = {} } = {} } = link || {};
    if (collection && collection.type === "album" && !collection.owner) {
      return true;
    }
  },
  afterInvoke: (result, props) => {
    let { deleteUri, link, getPath } = props;
    deleteUri && deleteUri(getPath && getPath(), link);
  },
  confirm: {
    titleMD: () => I18N.t("leaveAlbum"),
    message: () => I18N.t("leaveAlbumConfirmMessage"),
    confirmText: () => I18N.t("leave"),
    cancelText: () => I18N.t("cancel")
  }
};

actions.leaveSet = {
  ...actions.leaveCollection,
  text: () => I18N.t("leaveSet"),
  title: () => I18N.t("leaveSet"),
  postMessage: () => I18N.t("setLeaveMessage"),
  visible: (data, { link }) => {
    let { props: { params: { collection } = {} } = {} } = link || {};
    if (collection && collection.type === "set" && !collection.owner) {
      return true;
    }
  },
  afterInvoke: (result, props) => {
    let { deleteUri, link, getPath } = props;
    deleteUri && deleteUri(getPath && getPath(), link);
  },
  confirm: {
    titleMD: () => I18N.t("leaveSet"),
    message: () => I18N.t("leaveSetConfirmMessage"),
    confirmText: () => I18N.t("leave"),
    cancelText: () => I18N.t("cancel")
  }
};

export default actions;
