export default {
  SpaceAllocation: {
    fields: {
      user: {
        header: "User",
        field: "user",
        width: "100px",
        type: "reference",
        fetch: {
          props: {
            service: "_find",
            query: {
              view: "User"
            }
          }
        },
        searchField: "_id",
        display: "_id"
      },
      totalSpace: {
        header: "Total space",
        field: "totalSpace",
        type: "number",
        width: "100px"
      },
      freeSpace: {
        header: "Free space",
        field: "freeSpace",
        type: "number",
        width: "100px"
      }
    },
    actions: {
      detail: {
        type: "link",
        width: "100px",
        link: {
          uri: "/space-allocation-detail",
          props: {
            params: {
              SpaceAllocation: { _id: "{_id}" }
            },
            title: "{name}"
          }
        }
      }
    },
    linkFilter: {
      SpaceAllocation: {
        filter: {
          _id: "{_id}"
        }
      }
    },
    updateProps: {
      model: "SpaceAllocation"
    }
  }
};
