import React from "react";
import moment from "moment";
import { Box, theme, getImage, I18N, RowAction, ScrollView, RenderNoData, deviceId } from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import { allDevicesQuery } from "../queries";

const { colors, fonts, bgs } = theme;
const { brownGrey, themeColor } = colors;
const { h2_l_18 } = fonts;
const { lightPink } = bgs;

const DevicesNoData = props => {
  const info = {
    image: "shareNoDataIcon",
    primaryText: I18N.t("devicesNoDataHeader")
  };
  return <RenderNoData {...info} {...props} />;
};

const MyDevicesComponent = props => {
  let { data } = props;
  let currDevice = {};
  if (data) {
    for (let item of data) {
      if (item.device === deviceId) {
        if (data.indexOf(item) !== 0) {
          currDevice = item;
          data.splice(data.indexOf(item), 1);
          data.unshift(currDevice);
          break;
        }
      }
    }
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <Box
        render={
          data &&
          data.map(deviceData => {
            const deviceImage = deviceData.type === "Android" ? getImage("androidGreyIcon") : getImage("iosGreyIcon");
            let deviceDate = moment(new Date(deviceData.updatedOn));
            if (deviceDate.isValid()) {
              const day = deviceDate.format("D");
              const month = deviceDate.format("M") - 1;
              const year = deviceDate.format("YYYY");
              const hour = "" + deviceDate.format("HH");
              const minute = "" + deviceDate.format("mm");
              let dateLabel = I18N.t("albumDateLabel");
              let modifiedDate = dateLabel
                .replace("__month__", I18N.t(`month_${month}`))
                .replace("__date__", day)
                .replace("__year__", year);
              modifiedDate += ` ${hour}:${minute}`;
              deviceDate = modifiedDate;
            } else {
              deviceDate = null;
            }

            if (deviceData.device === deviceId) {
              currDevice = deviceData;
            }

            return {
              direction: "row",
              viewStyle: {
                padding: 10,
                margin: 10,
                alignItems: "center",
                justifyContent: "center",
                borderBottomWidth: 1,
                borderColor: lightPink
              },
              render: [
                { width: 32, viewStyle: { paddingLeft: 10, paddingRight: 10 }, image: deviceImage },
                {
                  width: "1fr",
                  viewStyle: { paddingLeft: 20 },
                  textStyle: { color: brownGrey, ...h2_l_18 },
                  render: [
                    { textStyle: { fontWeight: "bold" }, text: deviceData.deviceName },
                    deviceData.appVersion && { text: `${I18N.t("appVersion")}- ${deviceData.appVersion}` },
                    { text: deviceDate },
                    data.indexOf(deviceData) === 0 &&
                      deviceId && { textStyle: { color: themeColor, ...h2_l_18 }, text: I18N.t("loggedIn") }
                  ]
                },
                !(data.indexOf(deviceData) === 0) ? (
                  <RowAction
                    data={deviceData}
                    style={{ padding: 10 }}
                    action={[
                      {
                        image: getImage("menuLogoutIcon"),
                        ...ResourceActions.unlinkDevice
                      },
                      {
                        image: getImage("menuLogoutIcon"),
                        ...ResourceActions.blockDevices
                      }
                    ]}
                    image={getImage("iconMenuGrey")}
                  />
                ) : null
              ]
            };
          })
        }
      />
    </ScrollView>
  );
};

export default {
  MyDevices: {
    component: "table",
    Component: MyDevicesComponent,
    renderNoData: <DevicesNoData />,
    ...allDevicesQuery
  }
};
