import React from "react";

export default ({ Box, resolveMQ, resolveActions, getScreenProps, resolveStyle, ScreenContextProvider, theme }) => {
  class NavHeader extends React.Component {
    render() {
      const { activeMQ, screenState, actions, index, title, link, user, components, navHeaderStyleProps } = this.props;
      let { boxStyle, separatorStyle } = resolveMQ(theme, ["boxStyle", "separatorStyle"], activeMQ);
      let screenProps = getScreenProps && getScreenProps(screenState);
      let resolvedActions =
        (resolveActions &&
          resolveActions({ actions, activeMQ, index, title, link, user, components, ...screenProps })) ||
        [];

      if (resolveStyle) {
        boxStyle = resolveStyle(boxStyle, { Screen: screenState, ...navHeaderStyleProps });
      }
      return (
        <ScreenContextProvider state={screenState}>
          <Box
            {...boxStyle}
            getSeparator={({ separator, textStyle }) => {
              return <Box textStyle={textStyle} {...separatorStyle} text={separator} />;
            }}
            render={[...resolvedActions]}
          />
        </ScreenContextProvider>
      );
    }
  }
  return NavHeader;
};
