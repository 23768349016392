import ContactActions from "../screens/configuration/contacts/ContactActions";

export default {
  Contact: {
    // fields: {},
    actions: {
      ...ContactActions
    }
  }
};
