import React from "react";

export default ({ Box, resolveExp, resolveMQ, dataFormat, theme = {} }) => props => {
  let { activeMQ, style, action, data, aggregates } = props;
  let { title } = action;
  if (Array.isArray(title)) {
    title = title
      .map(titleDoc => {
        return resolveExp({ ...titleDoc, dataFormat, data: { data, aggregates }, returnWhenNotAvailable: true });
      })
      .filter(titleDoc => titleDoc)
      .join(" ");
  } else if (typeof title === "object" && title.exp) {
    title = resolveExp({ ...title, dataFormat, data: { data, aggregates } });
  }
  if (typeof title === "function") {
    title = title(props);
  }
  if (!title) {
    return null;
  }
  let { boxStyle } = resolveMQ(theme, ["boxStyle"], activeMQ);
  return <Box style={style} {...boxStyle} text={title} />;
};
