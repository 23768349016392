import React from "react";
import { Box, Modal, PermissionConfirmBox, I18N } from "../../../FsCloudComponent";

const confirmModalProps = {
  stopPropagation: true,
  position: "center",
  width: 0.85,
  animationType: "none",
  style: {
    borderRadius: 4,
    overflow: "hidden"
  },
  adjustOnKeyboard: true
};

class PermissionConfirmBoxComponent extends React.Component {
  renderConfirm = () => {
    let { onConfirm, onCancel } = this.props;
    return (
      <PermissionConfirmBox
        message={I18N.t("permissionConfirmBoxMessage")}
        cancelText={I18N.t("noThanks")}
        confirmText={I18N.t("takeMeToSettings")}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  };

  render() {
    return (
      <Modal isOpen {...confirmModalProps}>
        <Box render={this.renderConfirm()} />
      </Modal>
    );
  }
}

export default PermissionConfirmBoxComponent;
