import React from "react";
import { resolveValue } from "app-utility-functions";

export default ({ Box, Avatar, resolveMQ, ...hocProps }) => {
  class AvatarCard extends React.Component {
    getTextComponent = (textValue, theme, textColor) => {
      let { textStyle } = theme || {};
      if (textColor) {
        textStyle = { ...textStyle, color: textColor };
      }
      if (Array.isArray(textValue)) {
        textValue = textValue[0];
      }
      return {
        textStyle: textStyle,
        width: "1fr",
        text: textValue
      };
    };

    render() {
      let { data, field, theme, activeMQ, textColor } = this.props;
      const { theme: themeHoc = {} } = resolveMQ(hocProps, ["theme"], activeMQ);
      let { viewStyle, containerViewStyle } = theme || themeHoc || {};
      let textValue = resolveValue(data, field);
      let avatarDisplay = void 0;
      if (Array.isArray(textValue) && textValue.length > 1) {
        avatarDisplay = `${textValue.length}+`;
      }
      let TextComponent = this.getTextComponent(textValue, theme || themeHoc, textColor);
      let title = Array.isArray(textValue) ? textValue.join(", ") : textValue;
      let Component = {
        render: [
          {
            direction: "row",
            viewStyle: containerViewStyle,
            title: title,
            render: [
              {
                viewStyle: viewStyle,
                render: <Avatar {...this.props} display={avatarDisplay} />
              },
              TextComponent
            ]
          }
        ]
      };

      return <Box {...Component} />;
    }
  }
  return AvatarCard;
};
