import PropTypes from "prop-types";
import createShareButton from "./CreateShareButton";

const objectToGetParams = object => {
  return (
    "?" +
    Object.keys(object)
      .filter(key => !!object[key])
      .map(key => `${key}=${encodeURIComponent(object[key])}`)
      .join("&")
  );
};
function twitterLink(url, { title, via, hashtags = [] }) {
  return (
    "https://twitter.com/share" +
    objectToGetParams({
      url,
      text: title,
      via,
      hashtags: hashtags.join(",")
    })
  );
}

const TwitterShareButton = createShareButton(
  "twitter",
  twitterLink,
  props => ({
    hashtags: props.hashtags,
    title: props.title,
    via: props.via
  }),
  {
    hashtags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    via: PropTypes.string
  },
  {
    windowWidth: 550,
    windowHeight: 400
  }
);

export default TwitterShareButton;
