import PropTypes from "prop-types";
import createShareButton from "./CreateShareButton";
const objectToGetParams = object => {
  return (
    "?" +
    Object.keys(object)
      .filter(key => !!object[key])
      .map(key => `${key}=${encodeURIComponent(object[key])}`)
      .join("&")
  );
};

function facebookLink(url, { quote, hashtag }) {
  return (
    "https://www.facebook.com/sharer/sharer.php" +
    objectToGetParams({
      u: url,
      quote,
      hashtag
    })
  );
}

const FacebookShareButton = createShareButton(
  "facebook",
  facebookLink,
  props => {
    return {
      quote: props.quote,
      hashtag: props.hashtag
    };
  },
  {
    quote: PropTypes.string,
    hashtag: PropTypes.string
  },
  {
    windowWidth: 550,
    windowHeight: 400
  }
);

export default FacebookShareButton;
