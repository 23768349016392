export default props => {
  const { resolveImage } = props;

  const noDataSprite = {
    uri: "/webImages/noDataIcons/1_sprite.png",
    highlightsNoDataIcon: {
      width: 107,
      height: 98,
      objectPosition: "-337px -162px",
      objectFit: "none"
    },
    highlightsNoDataMDIcon: {
      width: 156,
      height: 142,
      objectPosition: "-337px -10px",
      objectFit: "none"
    },
    photosNoDataIcon: {
      width: 102,
      height: 102,
      objectPosition: "-503px -349px",
      objectFit: "none"
    },
    photosNoDataMDIcon: {
      width: 141,
      height: 141,
      objectPosition: "-503px -198px",
      objectFit: "none"
    },
    albumNoDataIcon: {
      width: 109,
      height: 102,
      objectPosition: "-10px -270px",
      objectFit: "none"
    },
    albumNoDataMDIcon: {
      width: 147,
      height: 138,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    albumNoDataAddItemsIcon: {
      width: 109,
      height: 102,
      objectPosition: "-10px -158px",
      objectFit: "none"
    },
    docsNoDataIcon: {
      width: 104,
      height: 102,
      objectPosition: "-167px -298px",
      objectFit: "none"
    },
    docsNoDataMDIcon: {
      width: 150,
      height: 148,
      objectPosition: "-167px -140px",
      objectFit: "none"
    },
    setNoDataIcon: {
      width: 108,
      height: 102,
      objectPosition: "-663px -504px",
      objectFit: "none"
    },
    setNoDataMDIcon: {
      width: 156,
      height: 148,
      objectPosition: "-663px -234px",
      objectFit: "none"
    },
    setNoDataAddItemsIcon: {
      width: 109,
      height: 102,
      objectPosition: "-663px -392px",
      objectFit: "none"
    },
    musicNoDataIcon: {
      width: 109,
      height: 102,
      objectPosition: "-337px -418px",
      objectFit: "none"
    },
    musicNoDataMDIcon: {
      width: 147,
      height: 138,
      objectPosition: "-337px -270px",
      objectFit: "none"
    },
    playlistNoDataIcon: {
      width: 109,
      height: 102,
      objectPosition: "-663px -122px",
      objectFit: "none"
    },
    playlistNoDataMDIcon: {
      width: 150,
      height: 141,
      objectPosition: "-503px -465px",
      objectFit: "none"
    },
    playlistNoDataAddItemsIcon: {
      width: 109,
      height: 102,
      objectPosition: "-663px -10px",
      objectFit: "none"
    },
    artistsNoDataIcon: {
      width: 102,
      height: 102,
      objectPosition: "-10px -382px",
      objectFit: "none"
    },
    groupNoDataIcon: {
      width: 102,
      height: 90,
      objectPosition: "-167px -561px",
      objectFit: "none"
    },
    groupNoDataMDIcon: {
      width: 160,
      height: 141,
      objectPosition: "-167px -410px",
      objectFit: "none"
    },
    sharedNoDataIcon: {
      width: 102,
      height: 102,
      objectPosition: "-829px -161px",
      objectFit: "none"
    },
    sharedNoDataMDIcon: {
      width: 141,
      height: 141,
      objectPosition: "-829px -10px",
      objectFit: "none"
    },
    trashNoDataIcon: {
      width: 141,
      height: 141,
      objectPosition: "-829px -446px",
      objectFit: "none"
    },
    contactNoDataIcon: {
      width: 145,
      height: 141,
      objectPosition: "-12px -494px",
      objectFit: "none"
    },
    contactSearchNoDataIcon: {
      width: 120,
      height: 120,
      objectPosition: "-167px -10px",
      objectFit: "none"
    },
    noGalleryItemsIcon: {
      width: 101,
      height: 88,
      objectPosition: "-337px -624px",
      objectFit: "none"
    },
    noSetItemsIcon: {
      width: 69,
      height: 84,
      objectPosition: "-503px -104px",
      objectFit: "none"
    },
    noPlaylistItemsIcon: {
      width: 91,
      height: 84,
      objectPosition: "-503px -10px",
      objectFit: "none"
    },
    noDataIcon: {
      width: 75,
      height: 84,
      objectPosition: "-337px -530px",
      objectFit: "none"
    },
    shareNoDataIcon: {
      width: 180,
      height: 159,
      objectPosition: "-829px -277px",
      objectFit: "none"
    }
  };

  const highlightsNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.highlightsNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.highlightsNoDataMDIcon
        }
      }
    });
  const photosNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.photosNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.photosNoDataMDIcon
        }
      }
    });
  const albumNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.albumNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.albumNoDataMDIcon
        }
      }
    });
  const albumNoDataAddItemsIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.albumNoDataAddItemsIcon
    }
  };
  const docsNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.docsNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.docsNoDataMDIcon
        }
      }
    });
  const setNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.setNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.setNoDataMDIcon
        }
      }
    });
  const setNoDataAddItemsIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.setNoDataAddItemsIcon
    }
  };
  const musicNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.musicNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.musicNoDataMDIcon
        }
      }
    });
  const playlistNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.playlistNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.playlistNoDataMDIcon
        }
      }
    });
  const playlistNoDataAddItemsIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.playlistNoDataAddItemsIcon
    }
  };
  const artistsNoDataIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.artistsNoDataIcon
    }
  };
  const groupNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.groupNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.groupNoDataMDIcon
        }
      }
    });
  const sharedNoDataIcon = () =>
    resolveImage({
      SM: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.sharedNoDataIcon
        }
      },
      MD: {
        uri: noDataSprite.uri,
        spriteProps: {
          ...noDataSprite.sharedNoDataMDIcon
        }
      }
    });
  const trashNoDataIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.trashNoDataIcon
    }
  };
  const contactNoDataIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.contactNoDataIcon
    }
  };
  const contactSearchNoDataIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.contactSearchNoDataIcon
    }
  };
  const noGalleryItemsIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.noGalleryItemsIcon
    }
  };
  const noSetItemsIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.noSetItemsIcon
    }
  };
  const noPlaylistItemsIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.noPlaylistItemsIcon
    }
  };
  const noDataIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.noDataIcon
    }
  };
  const noPreviewDocIcon = () =>
    resolveImage({
      SM: "/webImages/noDataIcons/noPreviewDoc.png",
      MD: "/webImages/noDataIcons/noPreviewDocMD.png"
    });
  const shareNoDataIcon = {
    uri: noDataSprite.uri,
    spriteProps: {
      ...noDataSprite.shareNoDataIcon
    }
  };
  const previewDocIcon = "/webImages/noDataIcons/noPreviewDoc.png";
  const failedNoDataIcon = "/webImages/noDataIcons/failedNoData.png";
  const infectedNoDataIcon = "/webImages/noDataIcons/infectedNoData.png";
  const duplicateNoDataIcon = "/webImages/noDataIcons/duplicateNoData.png";

  return {
    highlightsNoDataIcon,
    photosNoDataIcon,
    albumNoDataIcon,
    albumNoDataAddItemsIcon,
    docsNoDataIcon,
    setNoDataIcon,
    setNoDataAddItemsIcon,
    musicNoDataIcon,
    playlistNoDataIcon,
    playlistNoDataAddItemsIcon,
    artistsNoDataIcon,
    groupNoDataIcon,
    sharedNoDataIcon,
    trashNoDataIcon,
    contactNoDataIcon,
    contactSearchNoDataIcon,
    noGalleryItemsIcon,
    noSetItemsIcon,
    noPlaylistItemsIcon,
    noDataIcon,
    noPreviewDocIcon,
    shareNoDataIcon,
    previewDocIcon,
    failedNoDataIcon,
    infectedNoDataIcon,
    duplicateNoDataIcon
  };
};
