import React from "react";
import { ImageFloatingActions } from "./ImageDetailActions";
import { SelectImageNoData } from "../noData/NoData";
import { selectImageQuery, albumDetailQuery, selectVaultImageQuery, vaultAlbumDetailQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

const commonProps = {
  component: "table",
  dataObject: "Resource",
  listProps: {
    list: "recyclerList",
    imageField: "thumbnail_url",
    imageHeight: "thumbnail_height",
    imageWidth: "thumbnail_width",
    rowProps: {
      imageField: "thumbnail_url",
      localImageField: "localUri",
      fields: {
        name: "thumbnail_name",
        resource_name: "resource_name",
        size: "thumbnail_size",
        resource_size: "resource_size",
        height: "thumbnail_height",
        width: "thumbnail_width",
        lastModified: "resource_lastModified"
      },
      imagePrefix: "thumbnail",
      renderHeader: ImageFloatingActions
    },
    indicator_field: "resource_lastModified"
  },
  notifyNavOnScroll: true,
  pageSize: 1000,
  selectionMode: true,
  renderNoData: <SelectImageNoData />,
  bodyProps: {
    bodyStyle: {
      flex: 1
    }
  }
};

export default {
  SelectImages: {
    ...commonProps,
    ...selectImageQuery,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "album" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("addCaps"),
        action: "selectCollectionAddItems"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "groupImage" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("postCaps"),
        action: "selectPostToGroupItems"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "vaultGallery" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("moveCaps"),
        action: "selectMoveToSafe"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "archiveGallery" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("moveCaps"),
        action: "selectMoveToArchive"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "pdfGen" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("moveCaps"),
        action: "addImagePdfGenerator"
      },
      {
        type: "multiActions",
        visible: ({ data, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin !== "groupImage" && data && data.length > 0;
        },
        action: ["selectNone", "selectAll", "layout"]
      }
    ]
  },
  SelectVaultImages: {
    ...commonProps,
    ...selectVaultImageQuery,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "vaultAlbum" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("addCaps"),
        action: "selectCollectionAddItems"
      },
      {
        type: "multiActions",
        visible: ({ data, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin !== "groupImage" && data && data.length > 0;
        },
        action: ["selectNone", "selectAll", "layout"]
      }
    ]
  },
  SelectCoverImage: {
    ...commonProps,
    listProps: {
      ...commonProps.listProps,
      decryptionSourceProps: { decryptionSource: "collection" },
      rowProps: { ...commonProps.listProps.rowProps, decryptionSourceProps: { decryptionSource: "collection" } },
      singleSelection: true
    },
    ...albumDetailQuery,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        text: () => I18N.t("okCaps"),
        action: "selectImageSetAlbumCover"
      }
    ]
  },
  SelectCoverImageVault: {
    ...commonProps,
    listProps: {
      ...commonProps.listProps,
      decryptionSourceProps: { decryptionSource: "collection" },
      rowProps: { ...commonProps.listProps.rowProps, decryptionSourceProps: { decryptionSource: "collection" } },
      singleSelection: true
    },
    ...vaultAlbumDetailQuery,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        text: () => I18N.t("okCaps"),
        action: "selectImageSetAlbumCover"
      }
    ]
  }
};
