import React from "react";
import { Toggle } from "react-toggle-component";

export default ({ resolveMQ, ...hocProps }) => {
  class Switch extends React.Component {
    render() {
      let { onChangeValue, value = false, activeMQ, name } = this.props;
      let { theme } = resolveMQ(hocProps, ["theme"], activeMQ);
      return (
        <Toggle
          name={name || "toggle"}
          {...theme}
          checked={value}
          onToggle={e => {
            onChangeValue && onChangeValue(e.target.checked);
          }}
        />
      );
    }
  }
  return Switch;
};
