import React from "react";
import Box from "box";
export default ({ theme = {} }) => {
  class NestedAction extends React.Component {
    onClick = () => {
      let { data, setValue } = this.props;
      setValue({ data, updates: { remove: true } });
    };

    render() {
      let { boxStyle, iconStyle, addTextStyle } = theme;
      return (
        <Box
          {...boxStyle}
          render={[iconStyle && { ...iconStyle }, addTextStyle && { ...addTextStyle }]}
          onClick={this.onClick}
        />
      );
    }
  }
  return NestedAction;
};
