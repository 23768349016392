import React from "react";

export const renderChildren = (children, props = {}) => {
  let component = children;
  if (children) {
    if (typeof children === "function") {
      component = children({
        ...props
      });
    } else if (React.isValidElement(children)) {
      component = React.cloneElement(children, {
        ...props
      });
    }
  }
  return component;
};
