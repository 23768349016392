/**
 *
 * Position cursor is defined to show modal at cursor click position - Rohit Garg 4-May-19
 *
 */
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { addListener, removeListener } from "./DomEventListeners";
import styles from "./theme";
import getModalStyleFromProps from "./getModalStyleFromProps";
import TransitionGroup from "react-addons-transition-group";
import { TweenMax } from "gsap";

class BodyModal extends React.Component {
  constructor(props) {
    super(props);
    this.node = document.createElement("div");
    document.getElementById("root").appendChild(this.node);
  }

  componentWillUnmount() {
    let node = this.parent || this.node;
    if (node) {
      document.getElementById("root").removeChild(node);
    }
  }

  updatePosition = ref => {
    let { parentRef, position, top: propTop, left: propLeft } = this.props;
    if (position === "screenBottom" || position === "screenRight") {
      return;
    }
    if (parentRef && position && ref) {
      parentRef = ReactDOM.findDOMNode(parentRef).getBoundingClientRect();
      let top, left;
      let childDimensions = ref.getBoundingClientRect();
      switch (position) {
        case "top": {
          top = parentRef.top + window.pageYOffset - childDimensions.height;
          if (top < 0) {
            top = parentRef.bottom + window.pageYOffset;
          }
          left = parentRef.left + window.pageXOffset;
          if (left < 0) {
            left = parentRef.left + parentRef.width;
          } else if (parentRef.left + childDimensions.width > window.innerWidth) {
            left = parentRef.left + window.pageXOffset - childDimensions.width;
          }
          this.node.setAttribute("style", `position:absolute;top:${top}px;left:${left}px`);
          break;
        }
        case "left": {
          top = parentRef.top + window.pageYOffset;
          if (top < 0) {
            top = parentRef.bottom + window.pageYOffset;
          } else if (parentRef.top + childDimensions.height > window.innerHeight) {
            top = parentRef.top + window.pageYOffset - childDimensions.height;
          }
          left = parentRef.left + window.pageXOffset - childDimensions.width;
          if (left < 0) {
            left = parentRef.left + window.pageXOffset + parentRef.width;
          }
          this.node.setAttribute("style", `position:absolute;top:${top}px;left:${left}px`);
          break;
        }
        case "right": {
          top = parentRef.top + window.pageYOffset;
          if (top < 0) {
            top = parentRef.bottom;
          } else if (parentRef.top + childDimensions.height > window.innerHeight) {
            top = parentRef.top + window.pageYOffset - childDimensions.height;
          }
          left = parentRef.left + window.pageXOffset + parentRef.width;
          if (left + childDimensions.width > window.innerWidth) {
            left = parentRef.left + window.pageXOffset - childDimensions.width;
          }
          this.node.setAttribute("style", `position:absolute;top:${top}px;left:${left}px`);
          break;
        }
        case "bottom": {
          top = parentRef.bottom + window.pageYOffset;
          if (parentRef.bottom + childDimensions.height > window.innerHeight) {
            top = parentRef.top + window.pageYOffset - childDimensions.height;
          }
          left = parentRef.left + window.pageXOffset;
          if (left < 0) {
            left = parentRef.left + window.pageXOffset + parentRef.width;
          } else if (parentRef.left + childDimensions.width > window.innerWidth) {
            left = parentRef.left + window.pageXOffset - childDimensions.width;
          }
          this.node.setAttribute("style", `position:absolute;top:${top}px;left:${left}px`);
          break;
        }
        case "cover": {
          top = parentRef.bottom + window.pageYOffset - parentRef.height;
          if (parentRef.bottom + childDimensions.height > window.innerHeight) {
            top = parentRef.top + window.pageYOffset - childDimensions.height + parentRef.height;
          }
          left = parentRef.left + window.pageXOffset;
          if (left < 0) {
            left = parentRef.left + window.pageXOffset;
          } else if (parentRef.left + childDimensions.width > window.innerWidth) {
            left = parentRef.left + window.pageXOffset - childDimensions.width + parentRef.width;
          }
          this.node.setAttribute("style", `position:absolute;top:${top}px;left:${left}px`);
          break;
        }
        case "cursor": {
          top = propTop;
          if (top + childDimensions.height > window.innerHeight) {
            top = propTop - childDimensions.height;
          }
          left = propLeft;
          if (propLeft + childDimensions.width > window.innerWidth) {
            left = propLeft - childDimensions.width;
          }
          this.node.setAttribute("style", `position:absolute;top:${top + 1}px;left:${left + 1}px`);
          break;
        }
        default: {
          return;
        }
      }
    }
  };
  render() {
    let component = <TargetModal key="group" {...this.props} updatePosition={this.updatePosition} />;
    if (this.props.animate) {
      component = <TransitionGroup>{this.props.isOpen && component}</TransitionGroup>;
    }
    return ReactDOM.createPortal(component, this.node);
  }
}

class TargetModal extends React.Component {
  constructor(props) {
    super(props);
    var { parentRef } = props;
    if (parentRef) {
      this.parentDimensions = ReactDOM.findDOMNode(parentRef).getBoundingClientRect();
    }
  }

  animateModal = (type, callback) => {
    const { animate } = this.props;
    if (!animate) {
      return;
    }
    const { duration, from, to } = animate[type] || {};
    TweenMax.fromTo(this.modalRef, duration, from, { ...to, onComplete: callback });
  };

  componentWillEnter(callback) {
    this.animateModal("enter", callback);
  }

  componentWillLeave(callback) {
    this.animateModal("leave", callback);
  }

  isInValid = () => {
    const { escEnabled, autoHide, hideOnScroll, onClose } = this.props;
    if ((escEnabled || autoHide || hideOnScroll) && !onClose) {
      return `OnClose must be defined when escEnabled/autoHide/hideOnScroll is true `;
    }
  };

  componentDidUpdate() {
    // to change the modal position on update : Pankaj 5 Apr 2018
    this.onLayout();
  }

  componentDidMount() {
    let { getRef, escEnabled, autoHide, hideOnScroll } = this.props;
    getRef && getRef(this.modalRef);
    if (escEnabled) {
      addListener({ type: "keydown", listener: this.handleKey });
    }
    if (autoHide) {
      addListener({ type: "mousedown", listener: this.handleClick });
    }
    if (hideOnScroll) {
      addListener({ type: "scroll", listener: this.handleClick });
    }
    this.onLayout();
  }

  componentWillUnmount() {
    let { escEnabled, autoHide, hideOnScroll } = this.props;
    if (escEnabled) {
      removeListener({ type: "keydown", listener: this.handleKey });
    }
    if (autoHide) {
      removeListener({ type: "mousedown", listener: this.handleClick });
    }
    if (hideOnScroll) {
      removeListener({ type: "scroll", listener: this.handleClick });
    }
  }

  handleClick = event => {
    if (this.modalRef) {
      const modal = this.modalRef;
      const { target } = event;
      if (target !== modal && !modal.contains(target)) {
        const { isOpen, onClose } = this.props;
        if (isOpen) {
          onClose(event);
        }
      }
    }
  };
  handleKey = event => {
    const { isOpen, onClose } = this.props;
    const keyCode = event.keyCode;
    if (keyCode === 27 && isOpen && onClose) {
      onClose(event);
    }
  };

  //root Ref
  initializeModalRef = ref => {
    this.modalRef = ref;
  };

  isScreenPosition = () => {
    let { position } = this.props;
    return position && (position === "screenBottom" || position === "screenRight");
  };

  onLayout = () => {
    let { updatePosition } = this.props;
    if (!this.isScreenPosition()) {
      updatePosition && updatePosition(this.modalRef);
    }
  };

  getFractionValue = (windowValue, value) => {
    if (typeof value === "string" && value.indexOf("%") >= 0) {
      value = parseFloat(value.substring(0, value.indexOf("%")) / 100, 1);
    }
    if (typeof value === "number") {
      if (value <= 1 && value > 0) {
        value = windowValue * value;
      }
    }
    return value;
  };

  render() {
    const invalid = this.isInValid();
    if (invalid) {
      return <div>{invalid}</div>;
    }
    var {
      pointerEvents,
      position = "center",
      noBackdrop,
      style,
      parentStyle,
      size,
      children,
      className = "modalbody",
      stopPropagation
    } = this.props;
    //className by default set to modalbody for test cases: paritosh 7/04/2018
    var {
      positionModalStyle,
      positionModalParentStyle: { ...positionModalParentStyle },
      modalParentStyle: { ...modalParentStyle },
      modalStyle: { ...modalStyle }
    } = styles;
    let _style = getModalStyleFromProps(this.props);
    var modalSize = (size && styles.modalSizeStyle[size]) || {};
    if (position === "center") {
      _style = { ...modalStyle, ...modalSize, ..._style, ...style };
    } else {
      _style = {
        ..._style,
        width:
          _style.width ||
          ((position === "top" || position === "bottom") && this.parentDimensions && this.parentDimensions.width) ||
          void 0,
        ...positionModalStyle,
        ...style
      };
      if (position === "screenBottom") {
        _style.width = _style.width || "100%";
        _style.height = _style.height || 0.5;
        if (_style.height) {
          _style.height = this.getFractionValue(window.innerHeight, _style.height);
        }
        _style.top = window.innerHeight - _style.height;
      } else if (position === "screenRight") {
        _style.height = _style.height || "100%";
        _style.width = _style.width || 0.5;
        if (_style.width) {
          _style.width = this.getFractionValue(window.innerWidth, _style.width);
        }
        _style.left = window.innerWidth - _style.width;
      }
    }
    if (_style.width) {
      _style.width = this.getFractionValue(window.innerWidth, _style.width);
    }
    if (_style.height) {
      _style.height = this.getFractionValue(window.innerHeight, _style.height);
    }

    /*pointer event property is used by toast to avoid on click event on back drop japesh 1 june, 2017*/
    if (pointerEvents) {
      modalParentStyle.pointerEvents = pointerEvents;
      positionModalParentStyle.pointerEvents = pointerEvents;
    }
    var backdropStyle = !noBackdrop ? styles.backdropStyle : styles.no_backdrop_modal_syle;
    backdropStyle = pointerEvents ? { ...backdropStyle, pointerEvents } : backdropStyle;

    let idProps = {};
    if (this.props.id) {
      idProps.id = this.props.id;
    }

    let component = (
      <div className={className} {...idProps} ref={this.initializeModalRef} style={{ ..._style }}>
        {children}
      </div>
    );
    if (this.isScreenPosition()) {
      component = (
        <div style={{ ...positionModalParentStyle }}>
          {component}
          <div style={backdropStyle} />
        </div>
      );
    } else if (position === "center") {
      component = (
        <div style={{ ...modalParentStyle, ...parentStyle }}>
          {component}
          <div style={backdropStyle} />
        </div>
      );
    }
    if (stopPropagation) {
      component = React.cloneElement(component, {
        onClick: e => {
          e && e.stopPropagation && e.stopPropagation();
        }
      });
    }
    return component;
  }
}

BodyModal.propTypes = {
  parentRef: PropTypes.object,
  position: PropTypes.string
};

TargetModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  style: PropTypes.object /*style is used by tost for deffining top japesh 12 may,2017*/,
  position: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.string,
  parentRef: PropTypes.object,
  noBackdrop: PropTypes.bool,
  autoHide: PropTypes.bool
};

TargetModal.defaultProps = {
  noBackdrop: false,
  autoHide: false,
  style: {},
  escEnabled: true
};

export default props => {
  if (props.animate || props.isOpen) {
    return <BodyModal {...props} />;
  } else {
    return null;
  }
};
