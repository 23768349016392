import React from "react";

export default ({
  Box,
  View,
  theme,
  I18N,
  Header,
  LoadingIndicator,
  showMessage,
  getComponent,
  isValidEmail,
  resolveMQ,
  logFirebaseAnalyticsEvent
}) => {
  const { colors, fonts, bgs } = theme;
  const { themeColor, highlightColor, primaryColor, tomato } = colors;
  const { lightPink } = bgs;
  const { h9 } = fonts;

  const componentTheme = {
    containerStyleSM: {
      viewStyle: {
        flex: 1
      }
    },
    containerStyleMD: {
      viewStyle: {
        width: 539
      }
    },
    inputStyleSM: {
      containerStyle: {
        viewStyle: {
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 4,
          backgroundColor: lightPink,
          maxHeight: 150,
          overflow: "auto"
        }
      },
      textInputStyle: { ...h9, color: primaryColor }
    },
    inputStyleMD: {
      containerStyle: {
        viewStyle: {
          marginTop: 24,
          marginLeft: 30,
          marginRight: 30,
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 4,
          backgroundColor: lightPink,
          maxHeight: 120,
          overflow: "auto"
        }
      },
      textInputStyle: { ...h9, color: primaryColor }
    },

    footerStyle: {
      containerStyle: {
        viewStyle: {
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 24,
          paddingBottom: 24,
          alignItems: "center"
        },
        direction: "row"
      },
      errorStyle: {
        viewStyle: {
          flex: 1,
          paddingRight: 12
        },
        textStyle: {
          ...h9,
          color: tomato,
          numberOfLines: 2
        }
      },
      inviteActionStyle: {
        viewStyle: {
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: themeColor,
          borderRadius: 4,
          cursor: "pointer"
        },
        textStyle: {
          ...h9,
          color: highlightColor
        }
      }
    }
  };

  class AddGroupMember extends React.Component {
    state = { error: void 0, emails: [] };

    setValue = ({ field, value }) => {
      let newState = { [field]: value };
      if (this.state.error) {
        newState["error"] = void 0;
      }
      this.setState(newState);
    };

    validateEmail = ({ textValue, field }) => {
      if (field === "emails" && isValidEmail(textValue)) {
        this.setState({ error: "" });
        return true;
      } else {
        this.setState({ error: I18N.t("emailValidationError") });
        return false;
      }
    };

    onInviteClick = async () => {
      try {
        let { emails } = this.state;
        if (emails && emails.length === 0) {
          this.setState({
            error: I18N.t("emailMandatoryError")
          });
          return;
        }
        let { onClose, invoke, urls, user, replaceUri } = this.props;
        this.setState({ loading: true });
        let groupId = user && user.group && user.group._id;

        let successMessage = I18N.t("memberInvited");
        const membersLength = emails.length;
        if (membersLength > 1) {
          successMessage = `${membersLength} ${I18N.t("membersInvited")}`;
        }

        if (groupId) {
          await invoke({
            service: {
              url: urls["addGroupMembers"],
              uriProps: {
                data: {
                  _id: groupId,
                  members: emails
                }
              }
            }
          });
          showMessage && showMessage(successMessage, 2000);
          onClose ? onClose() : this.setState({ loading: false });
        } else {
          logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "group_created" });
          let userGroupResult = await invoke({
            service: {
              url: urls["createGroup"],
              uriProps: {
                data: {
                  members: emails
                }
              }
            }
          });
          showMessage && showMessage(successMessage, 2000);
          replaceUri &&
            replaceUri({
              uri: "/home#group",
              nestedTab: "groupMembers",
              _user: {
                group: userGroupResult.group
              }
            });
        }
      } catch (err) {
        this.setState({
          error: err.message,
          loading: false
        });
      }
    };

    render() {
      let { onClose, activeMQ } = this.props;
      let { loading, error, emails } = this.state;
      const TextBoxMultiple = getComponent("multiText");
      let headerTitle = () => I18N.t("addMembersCaps");
      const { containerStyle, footerStyle, inputStyle } = resolveMQ(
        componentTheme,
        ["containerStyle", "footerStyle", "inputStyle"],
        activeMQ
      );

      return (
        <View>
          {loading && <LoadingIndicator />}
          <Box
            {...containerStyle}
            render={[
              <Header title={headerTitle} onClose={onClose} />,
              {
                ...inputStyle.containerStyle,
                render: (
                  <TextBoxMultiple
                    data={this.state}
                    setValue={this.setValue}
                    field="emails"
                    toLowerCase={true}
                    validateField={this.validateEmail}
                    fieldDef={emails && emails.length < 1 && { placeholder: I18N.t("addEmail") }}
                    inputProps={{
                      onBlur: this.onBlur,
                      autoFocus: true,
                      autoCapitalize: "none",
                      style: { ...h9, minWidth: 180, paddingBottom: 4, paddingTop: 4, color: primaryColor }
                    }}
                  />
                )
              },
              {
                ...footerStyle.containerStyle,
                render: [
                  {
                    ...footerStyle.errorStyle,
                    text: error || ""
                  },
                  {
                    ...footerStyle.inviteActionStyle,
                    text: () => I18N.t("invite"),
                    onClick: this.onInviteClick
                  }
                ]
              }
            ]}
          />
        </View>
      );
    }
  }

  return AddGroupMember;
};
