import React from "react";

export default ({ Box, resolveActions, resolveMQ, theme }) => ({ actions, activeMQ, index }) => {
  const resolvedActions = resolveActions && resolveActions({ actions, activeMQ, index });
  if (!resolvedActions || !resolvedActions.length) {
    return null;
  }
  const { boxStyle } = resolveMQ(theme, ["boxStyle"], activeMQ);
  return <Box {...boxStyle} render={[...resolvedActions]} />;
};
