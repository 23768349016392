import { Platform, getIntroductionLink } from "../FsCloudComponent";

export const urlMappings = {
  landing: "/landing",
  public: [
    "/join-group",
    "/be-part-album",
    "/be-part-set",
    "/my-shared-data",
    "/montage-view",
    "/landing",
    "/custom-login",
    "/landing/login-option",
    "/landing/login-option/login",
    "/reset-vault-pin-mail",
    "/block-device-mail"
  ], // accessible without user
  direct: [
    "/be-part-album",
    "/be-part-set",
    "/my-shared-data",
    "/montage-view",
    "/landing",
    "/custom-login",
    "/landing/login-option",
    "/landing/login-option/login",
    "/reset-vault-pin-mail",
    "/block-device-mail"
  ] // for hiding nav header. used in render nav
};

const getSharedDataUri = uri => {
  let indexOfHash = uri.indexOf("#");
  let pre = uri.substring(0, indexOfHash);
  let hash = uri.substring(indexOfHash + 1);
  let nextUriInHashIndex = hash.indexOf("/");
  if (nextUriInHashIndex > 0) {
    hash = hash.substring(0, nextUriInHashIndex);
  }
  uri = pre + "#" + hash;
  return uri;
};

export const landingUrl = ({ path, user, afterLoginLink, activeMQ }) => {
  if (path && path.indexOf("ui/html/fb.html") >= 0) {
    path = path.replace("ui/html/fb.html", "custom-login");
  }
  // return {
  //   uri: "/web-upload-sample"
  // };
  let uri = path;
  // console.log("@@ uri in nav", uri);
  let uriToCheck = uri;
  if (uriToCheck) {
    const searchIndex = uriToCheck.indexOf("?");
    if (searchIndex >= 0) {
      uriToCheck = uri.substring(0, searchIndex);
    }
    const hashIndex = uriToCheck.indexOf("#");
    if (hashIndex >= 0) {
      uriToCheck = uri.substring(0, hashIndex);
    }
  }
  if (!user) {
    if (urlMappings.public.indexOf(uriToCheck) !== -1) {
      if (
        uri &&
        (uri.indexOf("/my-shared-data") >= 0 ||
          uri.indexOf("montage-view") >= 0 ||
          uri.indexOf("reset-vault-pin-mail") >= 0 ||
          uri.indexOf("block-device-mail") >= 0)
      ) {
        uri = getSharedDataUri(uri);
      } else {
        uri = path;
      }
    } else {
      uri = urlMappings.landing;
    }
  } else if (
    uri &&
    (uri.indexOf("/my-shared-data") >= 0 ||
      uri.indexOf("montage-view") >= 0 ||
      uri.indexOf("reset-vault-pin-mail") >= 0 ||
      uri.indexOf("block-device-mail") >= 0)
  ) {
    uri = getSharedDataUri(uri);
  } else if (uri && (uri.indexOf("/be-part") >= 0 || uri.indexOf("/join-group") >= 0)) {
    uri = path;
  } else {
    let introductionLink = getIntroductionLink({ user, activeMQ });
    if (introductionLink) {
      return introductionLink;
    } else if (afterLoginLink) {
      return afterLoginLink;
    } else if (!uri || uri === "/" || uri.indexOf("/landing") === 0) {
      uri = Platform.OS === "web" ? "/home#gallery" : "/home#highlights";
      // uri = "/upload-gallery";
      // uri = "/resources-temp-realtime";
    } else {
      if (uri.indexOf("/home#highlights") >= 0) {
        uri = "/home#highlights";
      } else if (uri.indexOf("/home#gallery") >= 0) {
        uri = "/home#gallery";
      } else if (uri.indexOf("/home#docs") >= 0) {
        uri = "/home#docs";
      } else if (uri.indexOf("/home#music") >= 0) {
        uri = "/home#music";
      } else if (uri.indexOf("/home#group") >= 0) {
        uri = "/home#group";
      } else {
        uri = "/home#gallery";
      }
    }
  }
  return { uri };
};
