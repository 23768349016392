import React from "react";
import { MusicCard } from "./MusicCards";
import { SelectMusicNoData } from "../noData/NoData";
import { selectMusicQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

export default {
  SelectMusic: {
    component: "table",
    dataObject: "Resource",
    listProps: {
      list: "scrollIndicatorList",
      indicator_field: "resource_lastModified"
    },
    ...selectMusicQuery,
    selectionMode: true,
    renderNoData: <SelectMusicNoData />,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin !== "archiveMusic" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("addCaps"),
        action: "selectCollectionAddItems"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "archiveMusic" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("moveCaps"),
        action: "selectMoveToArchive"
      },
      {
        type: "multiActions",
        visible: ({ data }) => (data && data.length ? true : false),
        action: ["selectNone", "selectAll"]
      }
    ],
    rowProps: {
      ...MusicCard
    }
  }
};
