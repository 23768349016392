import React from "react";
import { theme, Box, Platform, LinkBox, I18N, getImage } from "../../../FsCloudComponent";
import { getFileInfo, getFileType } from "./issuesUtility";
const { fonts, bgs } = theme;
const { lightPink } = bgs;
let { h9 } = fonts;

const defaultImagePreview = {
  audio: {
    previewImage: getImage("musicPreviewIcon")
  },
  video: {
    previewText: "videoPreviewMessage"
  },
  doc: {
    previewImage: getImage("previewDocIcon")
  },
  unknown: {
    previewText: "imagePreviewError"
  }
};

export default class FileViewerComponent extends React.Component {
  renderData = ({ data }) => {
    let attachments = data.attachments || [];
    let newArray = [];
    for (let i = 0; i < attachments.length; i++) {
      let item = attachments[i];
      let { imgUri, imgName } = getFileInfo(item);

      let type = getFileType(imgName);
      let typeData =
        type === "image"
          ? {
              previewImage: { uri: imgUri }
            }
          : defaultImagePreview[type || "unknown"];
      newArray.push({ url: imgUri, name: imgName, type, ...typeData });
    }

    return newArray.map(item => {
      let { url, previewImage, previewText, name } = item || {};
      let component = (
        <Box
          viewStyle={{
            margin: 5,
            cursor: "pointer",
            width: 100
          }}
          render={[
            previewImage
              ? {
                  viewStyle: { borderRadius: 5, backgroundColor: lightPink },
                  image: previewImage,
                  imageStyle: { height: 100, width: 100 },
                  imageProps: {
                    resizeMode: "cover"
                  }
                }
              : {
                  viewStyle: {
                    padding: 6,
                    borderRadius: 5,
                    backgroundColor: lightPink,
                    height: 100,
                    alignItems: "center",
                    justifyContent: "center"
                  },
                  textStyle: { ...h9, color: "#5c5c5c" },
                  text: I18N.t(previewText || "imagePreviewError")
                },
            name && {
              viewStyle: { paddingTop: 4 },
              textStyle: { ...h9, numberOfLines: 2, color: "#5c5c5c" },
              text: name
            }
          ]}
        />
      );
      if (Platform.OS === "web") {
        return (
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href={url}
            style={{ textDecoration: "none" }}
            onClick={e => e && e.stopPropagation && e.stopPropagation()}
          >
            {component}
          </a>
        );
      } else {
        return <LinkBox url={url}>{component}</LinkBox>;
      }
    });
  };

  render() {
    let { data } = this.props;
    return (
      <Box
        direction="row"
        viewStyle={{
          flexWrap: "wrap"
        }}
        render={this.renderData({ data })}
      />
    );
  }
}
