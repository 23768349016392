import React from "react";

export default ({ Box, resolveValue, getInput, inputType, ValueComponent, theme = {} }) => {
  class FileBox extends React.Component {
    onChangeValue = value => {
      const { data, setValue, field } = this.props;
      setValue && setValue({ data, field, value });
    };

    onRemove = () => {
      const { data, setValue, field } = this.props;
      setValue({ data, field, value: null });
    };

    render() {
      const { data, field, editable } = this.props;
      let fieldValue = resolveValue(data, field);
      let Input = getInput && getInput(inputType);
      return (
        <Box
          {...theme.boxStyle}
          render={[
            fieldValue
              ? {
                  ...theme.valueContainerStyle,
                  render: <ValueComponent value={fieldValue} onRemove={this.onRemove} editable={editable} />
                }
              : editable !== false &&
                Input && {
                  ...theme.inputContainerStyle,
                  render: <Input {...this.props} onChangeValue={this.onChangeValue} />
                }
          ]}
        />
      );
    }
  }
  return FileBox;
};
