import { commonLayout } from "../../../layouts";
import { I18N, getImage, getContactPicUrl, theme } from "../../../../FsCloudComponent";
import moment from "moment";

const { colors, fonts } = theme;
const { h16_l } = fonts;
const { themeColor } = colors;

const ContactsCardMD = {
  columns: [
    {
      width: "62px",
      field: "name",
      imageField: data => {
        if (data && data.photo_id) {
          return getContactPicUrl(data.photo_id);
        }
      },
      image: getImage("profileImageSmallIcon"),
      type: "avatarBox"
    },
    {
      width: "1fr",
      header: () => I18N.t("name"),
      field: "name",
      type: "text"
    },
    {
      header: () => I18N.t("date"),
      field: "deletedOn",
      type: "date",
      format: "DD/MM/YYYY",
      width: "202px"
    },
    {
      header: () => I18N.t("time"),
      type: "text",
      field: "deletedOn",
      width: "148px",
      fieldValue: props => {
        return (
          (props.deletedOn &&
            moment(new Date(props.deletedOn))
              .format("hh:mm a")
              .replace("am", I18N.t("am"))
              .replace("pm", I18N.t("pm"))) ||
          "-"
        );
      },
      textStyle: {
        ...h16_l,
        color: themeColor
      }
    }
  ]
};

const DuplicateContactsCardMD = {
  columns: [
    {
      width: "62px",
      field: "name",
      imageField: data => {
        if (data && data.photo_id) {
          return getContactPicUrl(data.photo_id);
        }
      },
      image: getImage("profileImageSmallIcon"),
      type: "avatarBox"
    },
    {
      width: "1fr",
      header: () => I18N.t("name"),
      field: "name",
      type: "text"
    },
    {
      header: () => I18N.t("similarContacts"),
      field: "matchCount",
      align: "left",
      textStyle: {
        ...h16_l,
        color: themeColor
      },
      type: "number"
    }
  ]
};

const ContactsCard = commonLayout({
  //SM: ContactsCardSM,
  MD: ContactsCardMD
});

const DuplicateContactsCard = commonLayout({
  //SM: ContactsCardSM,
  MD: DuplicateContactsCardMD
});

export { ContactsCard, DuplicateContactsCard };
