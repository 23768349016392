import { I18N } from "../../../FsCloudComponent";

export default {
  MusicTab: {
    component: "tab",
    titleSM: () => I18N.t("app-title"),
    titleMD: () => I18N.t("music"),
    showTitleInTabSM: true,
    swipeableSM: true,
    swipeableMD: false,
    tabsSM: {
      musicAll: {
        tab: {
          text: () => I18N.t("all")
        },
        screen: "MusicAll",
        absoluteHeader: true
      },
      playlists: {
        tab: {
          text: () => I18N.t("playlists")
        },
        screen: "Playlist",
        absoluteHeader: true
      },
      artists: {
        tab: {
          text: () => I18N.t("artists")
        },
        screen: "Artist",
        absoluteHeader: true
      }
    },
    tabsMD: {
      musicAll: {
        tab: {
          text: () => I18N.t("all")
        },
        screen: "MusicAll"
      },
      playlists: {
        tab: {
          text: () => I18N.t("playlists")
        },
        screen: "Playlist"
      }
    }
  }
};
