export default () => {

  const highLightSprite = {
    uri: "/webImages/highlightIcons/1_sprite.png",
    highlightFreeUpSpaceIcon: {
      width: 89,
      height: 133,
      objectPosition: "-179px -110px",
      objectFit: "none"
    },
    highlightBackUpIcon:{
      width: 99,
      height:90,
      objectPosition: "-174px -10px",
      objectFit: "none"
    },
    highlightClockIcon:{
      width: 99,
      height:90,
      objectPosition: "-174px -10px",
      objectFit: "none"
    },
    highlightUploadIcon:{
      width: 99,
      height:90,
      objectPosition: "-174px -10px",
      objectFit: "none"
    },
    highlightSocialMediaIcon:{
      width: 115,
      height:82,
      objectPosition: "-49px -10px",
      objectFit: "none"
    },
    highlightGroupIcon:{
      width: 108,
      height:81,
      objectPosition: "-49px -102px",
      objectFit: "none"
    },
    highlightStarIcon:{
      width: 14,
      height:14,
      objectPosition: "-10px -192px",
      objectFit: "none"
    },
    highlightImportGreyIcon:{
      width: 22,
      height:15,
      objectPosition: "-10px -42px",
      objectFit: "none"
    },
    highlightShareGreyIcon:{
      width: 16,
      height:17,
      objectPosition: "-10px -165px",
      objectFit: "none"
    },
    highlightShareBlackIcon:{
      width: 23,
      height:24,
      objectPosition: "-10px -131px",
      objectFit: "none"
    },
    highlightLinkBlackIcon:{
      width: 20,
      height:20,
      objectPosition: "-10px -67px",
      objectFit: "none"
    },
    highlightImportBlackIcon:{
      width: 30,
      height:22,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    highlightPinBlackIcon:{
      width: 24,
      height:24,
      objectPosition: "-10px -97px",
      objectFit: "none"
    }
  
  }

  const highlightFreeUpSpaceIcon = {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightFreeUpSpaceIcon
    }
  };
  const highlightBackUpIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightBackUpIcon
    }
  };
  const highlightClockIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightClockIcon
    }
  };
  const highlightUploadIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightUploadIcon
    }
  };
  const highlightSocialMediaIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightSocialMediaIcon
    }
  };
  const highlightGroupIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightGroupIcon
    }
  };
  const highlightStarIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
    ...highLightSprite.highlightStarIcon
    }
  };
  const highlightImportGreyIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightImportGreyIcon
    }
  };
  const highlightShareGreyIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightShareGreyIcon
    }
  };
  const highlightShareBlackIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightShareBlackIcon
    }
  };
  const highlightLinkBlackIcon = {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightLinkBlackIcon
    }
  };
  const highlightImportBlackIcon =  {
    uri: highLightSprite.uri,
    spriteProps: {
      ...highLightSprite.highlightImportBlackIcon
    }
  };
  const highlightPinBlackIcon = "/webImages/highlightIcons/highlightPinBlack.png";
  const viewAllIcon = "/webImages/highlightIcons/viewall.png";

  return {
    highlightFreeUpSpaceIcon,
    highlightBackUpIcon,
    highlightSocialMediaIcon,
    highlightGroupIcon,
    highlightStarIcon,
    highlightImportGreyIcon,
    highlightShareGreyIcon,
    highlightShareBlackIcon,
    highlightLinkBlackIcon,
    highlightImportBlackIcon,
    highlightPinBlackIcon,
    viewAllIcon,
    highlightClockIcon,
    highlightUploadIcon,
  };
};
