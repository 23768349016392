import React from "react";

export default ({ Box, getImage, EqualizerComponent }) => {
  class MusicImageComponent extends React.Component {
    constructor(props) {
      super(props);
      let { isPlaying, getPlayerState, data } = props;
      this.state = {
        isPlaying: isPlaying ? isPlaying(data) : false,
        playerState: getPlayerState ? getPlayerState(data) || "Buffering" : "Buffering"
      };
    }

    onChangeMusic = () => {
      let { isPlaying, data } = this.props;
      if (isPlaying && isPlaying(data)) {
        this.setState({
          isPlaying: true
        });
      } else if (this.state.isPlaying) {
        this.setState({
          isPlaying: false
        });
      }
    };

    onChangePlayerState = ({ playerState }) => {
      if (this.state.isPlaying) {
        this.setState({ playerState: playerState });
      }
    };

    componentDidMount() {
      let { addChangeSongListener, addPlayerStateListener } = this.props;
      addChangeSongListener && addChangeSongListener(this.onChangeMusic);
      addPlayerStateListener && addPlayerStateListener(this.onChangePlayerState);
    }

    componentWillUnmount() {
      const { removeChangeSongListener, removePlayerStateListener } = this.props;
      removeChangeSongListener && removeChangeSongListener(this.onChangeMusic);
      removePlayerStateListener && removePlayerStateListener(this.onChangePlayerState);
    }

    render() {
      let { playerState, isPlaying } = this.state;

      return (
        <Box
          viewStyle={{
            width: 51,
            height: 50,
            alignItems: "center",
            justifyContent: "center"
          }}
          render={[
            isPlaying ? (
              <EqualizerComponent playerState={playerState} />
            ) : (
              {
                imageStyle: { height: 50, width: 51 },
                image: getImage("musicIcon")
              }
            )
          ]}
        />
      );
    }
  }
  return MusicImageComponent;
};
