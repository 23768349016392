import UploadGalleryList from "./UploadGalleryList";

export default {
  UploadGallery: {
    component: "table",
    Component: UploadGalleryList,
    hideNavHeader: true,
    panelProps: {
      showHeader: false
    },
    connectProps: {
      data: []
    },
    listProps: {
      imageField: "groupImage",
      imageHeight: "groupHeight",
      imageWidth: "groupWidth",
      rowProps: {
        imageField: "groupImage"
      },
      selectionRequired: false
    }
  }
};
