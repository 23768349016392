import React from "react";
import { renderChildren } from "./Utility";

export default ({
  Box,
  FileDownloadLink,
  NetworkImageRender,
  DetailComponent,
  resolveValue,
  resolveMQ,
  dateFormatter,
  theme: themeHoc
}) => {
  class DocumentSlider extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showDetail: false, detailId: this.props.detailId };
    }

    onPrevClick = () => {
      if (this.props.prev) {
        this.props.prev();
        return;
      }
      let { value } = this.props;
      let index = this.getSelectedItemIndex();
      if (index === undefined) {
        index = value.length - 1;
      }
      if (index > 0) {
        index--;
      }
      if (index >= 0) {
        this.setState({ index, detailId: value[index]._id });
      }
    };

    UNSAFE_componentWillReceiveProps() {
      this.setState({ index: 0 });
    }

    onNextClick = () => {
      if (this.props.next) {
        this.props.next();
        return;
      }
      let { value } = this.props;
      let index = this.getSelectedItemIndex();
      if (index === undefined) {
        index = 0;
      }
      if (index >= 0 && index < value.length - 1) {
        index++;
      }
      if (index >= 0) {
        this.setState({ index, detailId: value[index]._id });
      }
    };

    getSelectedItemIndex = () => {
      const { value = [], detailFromProps } = this.props;
      if (!value.length) {
        return;
      }
      let detailId;
      if (detailFromProps) {
        detailId = this.props.detailId;
      } else {
        detailId = this.state.detailId;
      }
      for (let index = 0; index < value.length; index++) {
        if (value[index]._id === detailId) {
          return index;
        }
      }
    };

    render() {
      let { imageField, createdOnField, height, value = [], showHeader, activeMQ, theme = themeHoc } = this.props;
      let index = this.getSelectedItemIndex();
      let indexValue = value[index] || {};

      let { key, name } = indexValue;
      const {
        boxStyle,
        contentWrapperStyle,
        headerStyle,
        imageContainerStyle,
        leftArrowIconStyle,
        rightArrowIconStyle,
        createdOnStyle,
        documentDetailStyle
      } = resolveMQ(
        theme,
        [
          "boxStyle",
          "contentWrapperStyle",
          "headerStyle",
          "imageContainerStyle",
          "leftArrowIconStyle",
          "rightArrowIconStyle",
          "createdOnStyle",
          "documentDetailStyle"
        ],
        activeMQ
      );
      let { renderHeader, renderFooter, renderBody } = resolveMQ(
        this.props,
        ["renderHeader", "renderFooter", "renderBody"],
        activeMQ
      );
      let headerComponent = renderHeader
        ? renderChildren(renderHeader, { index, value: indexValue })
        : {
            ...headerStyle.containerStyle,
            render: [
              {
                width: "1fr",
                ...headerStyle.imageNameStyle,
                text: name || key || ""
              },
              FileDownloadLink && (
                <FileDownloadLink value={imageField ? resolveValue(indexValue, imageField) : indexValue}>
                  <Box {...headerStyle.downloadIconStyle} />
                </FileDownloadLink>
              ),
              {
                ...headerStyle.detailIconStyle,
                onClick: () => this.setState({ showDetail: !this.state.showDetail })
              }
            ]
          };
      let footerComponent = renderFooter ? renderChildren(renderFooter, { index, value: indexValue }) : void 0;
      let bodyComponent = (
        <NetworkImageRender
          theme={imageContainerStyle.imageViewStyle}
          value={imageField ? resolveValue(indexValue, imageField) : indexValue}
        />
      );
      if (renderBody) {
        bodyComponent = renderChildren(renderBody, {
          index,
          value: indexValue
        });
      }
      let views = [
        {
          ...contentWrapperStyle,
          height,
          render: [
            headerStyle && showHeader !== false && headerComponent,
            value.length && {
              ...imageContainerStyle.boxStyle,
              render: [
                {
                  ...leftArrowIconStyle,
                  viewStyle: {
                    ...leftArrowIconStyle.viewStyle,
                    visibility: index > 0 ? "visible" : "hidden"
                  },
                  onClick: this.onPrevClick
                },
                {
                  ...imageContainerStyle.imageViewConatinerStyle,
                  render: bodyComponent
                },
                {
                  ...rightArrowIconStyle,
                  viewStyle: {
                    ...rightArrowIconStyle.viewStyle,
                    visibility: index < value.length - 1 ? "visible" : "hidden"
                  },
                  onClick: this.onNextClick
                }
              ]
            },
            createdOnField && {
              ...createdOnStyle,
              text: `Created On - ${dateFormatter(indexValue[createdOnField], "DD MMM, YY HH:mm")}`
            },
            footerComponent
          ]
        }
      ];

      return (
        <Box
          {...boxStyle}
          render={[
            ...views,
            this.state.showDetail &&
              DetailComponent && {
                width: "1fr",
                render: (
                  <DetailComponent
                    onClose={() => {
                      this.setState({ showDetail: false });
                    }}
                    theme={documentDetailStyle}
                  />
                )
              }
          ]}
        />
      );
    }
  }

  return DocumentSlider;
};
