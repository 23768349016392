import React from "react";

export default ({ Box }) => {
  class PlayMusicAction extends React.Component {
    render() {
      let { style, children, allData, getSelectedData, unSelectAll, onPlayMusic } = this.props;
      return (
        <Box
          style={style}
          onClick={e => {
            let selectedData = (getSelectedData && getSelectedData()) || [];
            let playlist = selectedData;
            let currentTrackId = selectedData[0]._id;
            if (selectedData.length === 1) {
              playlist = allData;
            }
            onPlayMusic &&
              onPlayMusic({
                playlist,
                currentTrackId
              });
            unSelectAll && unSelectAll();
          }}
          render={children}
        />
      );
    }
  }
  return PlayMusicAction;
};
