export default ({ bgs }) => {
  const { primaryBg2 } = bgs;

  const drawerLayoutStyle = {
    drawerViewStyle: {
      height: "100%",
      position: "fixed",
      zIndex: 1001,
      top: 0,
      left: 0,
      overflowX: "hidden",
      backgroundColor: primaryBg2,
      transition: "0.5s"
    },
    mainViewStyle: {
      flex: 1
    },
    overlayStyle: {
      backgroundColor: "rgba(0,0,0,0.5)",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 1000,
      transition: "0.5s"
    }
  };

  return { drawerLayoutStyle };
};
