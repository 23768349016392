import {
  getExistingMetadataIds,
  addMetadataInBatch,
  updateMetadata,
  deleteMetadata,
  getDataUsingUriArray,
  getSqlExistStatusUsingResource
} from "./Sqlite";
import { SECURE_TYPES } from "./Constants";
import { Platform } from "react-components";

const METADATA_SQL_INSERT_LIMIT = 12;

export default ({ urls, MetadataTable, CollectionTable, getUser, mappings, deviceId }) => {
  const getMandatoryMessage = (data, { fieldDef }) => {
    let { header, mandatoryLabel, field, mandatory } = fieldDef;
    let message = `Please enter ${header || mandatoryLabel || field}`;
    if (typeof mandatory == "string") {
      message = mandatory;
    } else if (typeof mandatory === "function") {
      message = mandatory(data);
    }
    return message;
  };

  const getSortValue = (elem1, elem2, sort) => {
    if (sort && Object.keys(sort).length) {
      for (const field in sort) {
        let asc = sort[field] === 1;
        if (elem1[field] === elem2[field]) {
          continue;
        } else if (elem1[field] === undefined) {
          return 1;
        } else if (elem2[field] === undefined) {
          return -1;
        } else if (elem1[field] < elem2[field]) {
          return asc ? -1 : 1;
        } else if (elem1[field] > elem2[field]) {
          return asc ? 1 : -1;
        }
      }
    }
    return 0;
  };

  // array of objects
  const mergeTwoSortedArrays = (arr1, arr2, sort, primaryField = "_id") => {
    let arr2map = {};
    for (let i = 0; i < arr2.length; i++) {
      let primaryValue = arr2[i][primaryField];
      if (primaryValue) {
        arr2map[primaryValue] = arr2[i];
      }
    }

    let merged = [];
    let index1 = 0;
    let index2 = 0;
    let current = 0;

    while (current < arr1.length + arr2.length) {
      let isArr1Depleted = index1 >= arr1.length;
      let isArr2Depleted = index2 >= arr2.length;

      let elem1 = arr1[index1];
      let elem2 = arr2[index2];

      // DUPLICACY check
      if (elem1 && elem1[primaryField] && arr2map[elem1[primaryField]]) {
        index1++;
      } else if (isArr1Depleted) {
        merged.push(elem2);
        index2++;
      } else if (isArr2Depleted) {
        merged.push(elem1);
        index1++;
      } else {
        let sortValue = getSortValue(elem1, elem2, sort);
        if (sortValue === 1) {
          merged.push(elem2);
          index2++;
        } else {
          merged.push(elem1);
          index1++;
        }
      }

      current++;
    }
    return merged;
  };

  // const modifySharedMetadataDataUrl = (metadata, sharedToken) => {
  //   let metadataId = metadata && metadata._id;
  //   if (!metadataId || !sharedToken) {
  //     return;
  //   }
  //   let postUrl = `/${metadataId}/${sharedToken}`;
  //   if (metadata.thumbnail_url) {
  //     metadata.thumbnail_url = `${urls["shared_thumbnail_url"]}${postUrl}`;
  //   }
  //   if (metadata.converted_jpeg_url) {
  //     metadata.converted_jpeg_url = `${urls["shared_converted_jpeg_url"]}${postUrl}`;
  //   }
  //   if (metadata.converted_mp4_url) {
  //     metadata.converted_mp4_url = `${urls["shared_converted_mp4_url"]}${postUrl}`;
  //   }
  //   if (metadata.resource_url) {
  //     metadata.resource_url = `${urls["shared_resource_url"]}${postUrl}`;
  //   }
  // };

  // const modifyMetadataUrl = async (metadata, token) => {
  //   // change from fsToken to token for token expiry case. Need to do work again when in use - currenty not in use - 30 Apr, 2020 sachin
  //   let metadataId = metadata && metadata._id;
  //   if (!metadataId || !token) {
  //     return;
  //   }
  //   let postUrl = `/${metadataId}/${token}`;
  //   if (metadata.thumbnail_url) {
  //     metadata.thumbnail_url = `${urls["thumbnail_url"]}${postUrl}`;
  //   }
  //   if (metadata.converted_jpeg_url) {
  //     metadata.converted_jpeg_url = `${urls["converted_jpeg_url"]}${postUrl}`;
  //   }
  //   if (metadata.converted_mp4_url) {
  //     metadata.converted_mp4_url = `${urls["converted_mp4_url"]}${postUrl}`;
  //   }
  //   if (metadata.resource_url) {
  //     metadata.resource_url = `${urls["resource_url"]}${postUrl}`;
  //   }
  //   if (metadata.converted_mp3_url) {
  //     metadata.converted_mp3_url = `${urls["converted_mp3_url"]}${postUrl}`;
  //   }
  // };

  const modifyUrls = (data, { table, sharedToken, token }) => {
    // return bcoz video and audio not playing in ios and web safari
    return;
    // if (!data) {
    //   return;
    // }
    // if (sharedToken) {
    //   if (data.resource && data.resource._id) {
    //     modifySharedMetadataDataUrl(data.resource, sharedToken);
    //   } else if (data.collectionItems) {
    //     data.collectionItems.forEach(metadata => {
    //       modifySharedMetadataDataUrl(metadata, sharedToken);
    //     });
    //   }
    // } else {
    //   let metadata = void 0;
    //   if (table === CollectionTable && data.coverImage) {
    //     metadata = data.coverImage;
    //   } else if (table === MetadataTable) {
    //     metadata = data;
    //   }
    //   if (metadata) {
    //     modifyMetadataUrl(metadata, token);
    //   }
    // }
  };

  const formatLocalDataToResourceData = localFile => {
    let user = getUser && getUser();
    let {
      uri,
      resource,
      fileName,
      lastModified,
      size,
      duration,
      orientedHeight,
      orientedWidth,
      title,
      album,
      artist,
      genre,
      status,
      skipNetworkCheck,
      onlyOnWifi,
      spaceReserved,
      height,
      width,
      MD5,
      path,
      metadata,
      pauseReason,
      uploadSource,
      secureType,
      uploaded = false,
      latitude,
      longitude
    } = localFile;
    fileName = fileName || "";
    const extensionIndex = fileName.lastIndexOf(".");
    let extension = "";
    if (extensionIndex >= 0) {
      extension = fileName.substring(extensionIndex + 1);
      extension = extension.toLowerCase();
    }
    let type = mappings[extension] || "doc";
    let lower_name = title ? title.trim().toLowerCase() : fileName.trim().toLowerCase();
    let currentTimeInMS = new Date().getTime();
    let timePrefix = Platform.OS === "android" ? "_" : "";
    let secondsField = timePrefix + "seconds";
    let nanoSecondsField = timePrefix + "nanoseconds";
    let currentTime = {
      [secondsField]: Math.floor(currentTimeInMS / 1000),
      [nanoSecondsField]: (currentTimeInMS % 1000) * 1000000
    };
    let modifiedDate = new Date(lastModified);
    let dateStr = `${modifiedDate.getDate()}-${modifiedDate.getMonth() + 1}-${modifiedDate.getFullYear()}`;

    let resourceData = {
      deleted: false,
      private: true,
      uploaded,
      completed: false,
      _id: resource,
      _createdOn: currentTime,
      _updatedOn: currentTime,
      uploadedOn: currentTime, // for gallerySort by uploadedOn Mobile
      type,
      name: fileName,
      resource_name: fileName,
      lower_name,
      ext: extension,
      resource_size: size,
      resource_lastModified: lastModified,
      resource_duration: duration,
      deviceId,
      localUri: uri,
      fileUri: uri,
      resource_url: uri,
      resource_height: height,
      resource_width: width,
      status,
      _createdBy: { firebaseUid: user && user.uid },
      spaceReserved,
      skipNetworkCheck,
      onlyOnWifi,
      resource_album: album ? album : void 0,
      resource_artist: artist ? artist : void 0,
      resource_genre: genre ? genre : void 0,
      actionType: "upload",
      resource_md5Hash: MD5,
      resource_path: path,
      metadata,
      pauseReason,
      dateStr,
      uploadSource,
      secureType: secureType || SECURE_TYPES.DEFAULT,
      location: { latitude, longitude }
    };
    if (Platform.OS === "android") {
      //eslint-disable-next-line no-useless-escape
      resourceData["deviceFolder"] = uri.match(/(.*)[\/\\]/)[1];
    }
    if (type === "image" || type === "video") {
      resourceData.gallery = true;
      resourceData.localThumbnail = true;
      resourceData.thumbnail_url = uri;
      resourceData.thumbnail_height = orientedHeight;
      resourceData.thumbnail_width = orientedWidth;
      resourceData.url = uri;
      resourceData.height = orientedHeight;
      resourceData.width = orientedWidth;
    } else if (type === "audio") {
      resourceData.resource_title = title || fileName;
    }
    return resourceData;
  };
  return {
    getMandatoryMessage,
    getSortValue,
    mergeTwoSortedArrays,
    modifyUrls,
    formatLocalDataToResourceData
  };
};

export const getToInsertMetadata = async (metadataResult = []) => {
  let ids = [],
    idWiseDataMap = {};
  for (let row of metadataResult) {
    if (row && row._id) {
      ids.push(row._id);
      idWiseDataMap[row._id] = row;
    }
  }
  if (!ids || !ids.length) {
    return { toInsertData: [], toUpdateData: [] };
  }
  let alreadyExistingIds = [];
  try {
    alreadyExistingIds = await getExistingMetadataIds(ids);
  } catch (err) {}
  let toUpdateData = [];
  for (let id of alreadyExistingIds) {
    toUpdateData.push(idWiseDataMap[id]);
    delete idWiseDataMap[id];
  }
  return { toUpdateData, toInsertData: Object.values(idWiseDataMap) };
};

export const insertMetadataInSqlRecursively = async (props, options = {}, startIndex = 0) => {
  let { data, errors, successfulInsertedIds } = props;
  if (!data || !data.length || startIndex >= data.length) {
    return data;
  }
  let nextIndex = startIndex + METADATA_SQL_INSERT_LIMIT;
  if (nextIndex > data.length) {
    nextIndex = data.length;
  }
  const currentBatchData = data.slice(startIndex, nextIndex);
  try {
    await addMetadataInBatch({ data: currentBatchData, ...options });
    if (successfulInsertedIds) {
      for (let doc of currentBatchData) {
        successfulInsertedIds[doc._id] = 1;
      }
    }
  } catch (err) {
    if (errors && Array.isArray(errors) && err && errors.indexOf(err.message) === -1) {
      errors.push(err.message);
    }
    console.warn("insert metadata error: ", err && err.message);
  }
  await insertMetadataInSqlRecursively(props, options, nextIndex);
};

export const updateMetadataInSqlite = async ({ data, errors }) => {
  if (!data || !data.length) {
    return;
  }
  for (let row of data) {
    if (row && row._id) {
      try {
        await updateMetadata({ _id: row._id, changes: row });
      } catch (err) {
        if (errors && Array.isArray(errors) && err && errors.indexOf(err.message) === -1) {
          errors.push(err.message);
        }
        console.warn("update metadata error: ", err && err.message);
      }
    }
  }
};

export const _updateSqliteFromLocalChanges = async ({ localChanges }) => {
  if (Platform.OS === "web") {
    return;
  }
  if (!Array.isArray(localChanges)) {
    localChanges = [localChanges];
  }
  let toInsertData = [],
    toUpdateData = [],
    toDeleteData = [];
  for (let index = 0; index < localChanges.length; index++) {
    let localChange = localChanges[index];
    const { _id, changes, insert, remove, sqliteChanges } = localChange;
    if (insert && Array.isArray(insert) && insert.length) {
      toInsertData = insert;
    } else if (remove) {
      if (sqliteChanges) {
        toUpdateData.push({ _id, ...sqliteChanges });
      } else {
        toDeleteData.push(_id);
      }
    } else if (changes) {
      toUpdateData.push({ _id, ...changes });
    }
  }
  if (toInsertData.length) {
    try {
      await insertMetadataInSqlRecursively({ data: toInsertData });
    } catch (e) {
      // console.warn("@@@@ error in inserting in sqlite");
    }
  }
  if (toUpdateData.length) {
    try {
      await updateMetadataInSqlite({ data: toUpdateData });
    } catch (e) {
      // console.warn("@@@@ erorr in updating in sqlite");
    }
  }
  if (toDeleteData.length) {
    try {
      await deleteMetadata(toDeleteData);
    } catch (e) {
      // console.warn("@@@@ error in deleting in sqlite");
    }
  }
};

export const getUploadedUris = async (uris, startIndex = 0, result = []) => {
  if (!uris || !uris.length || startIndex >= uris.length) {
    return result;
  }
  let nextIndex = startIndex + 900;
  if (uris.length < nextIndex) {
    nextIndex = uris.length;
  }
  let currentBatchUris = uris.slice(startIndex, nextIndex);
  const uploadedData = (await getDataUsingUriArray(currentBatchUris)) || {};
  for (let row of uploadedData) {
    let { localUri } = row;
    if (localUri) {
      result.push(localUri);
    }
  }
  return await getUploadedUris(uris, nextIndex, result);
};

export const getSqlExistStatusRecursively = async (resourceIds, startIndex = 0, result = []) => {
  if (!resourceIds || !resourceIds.length || startIndex >= resourceIds.length) {
    return result;
  }
  let nextIndex = startIndex + 900;
  if (nextIndex > resourceIds.length) {
    nextIndex = resourceIds.length;
  }

  let currentBatchResourceIds = resourceIds.slice(startIndex, nextIndex);
  let sqlResult = [];
  try {
    sqlResult = await getSqlExistStatusUsingResource(currentBatchResourceIds);
  } catch (err) {
    currentBatchResourceIds.forEach(id => {
      sqlResult.push({ resource: id, exists: false });
    });
  }
  result = [...result, ...sqlResult];
  return await getSqlExistStatusRecursively(resourceIds, nextIndex, result);
};

export const cancelQueuedItemsRecursively = async (ids, cancelMethod, startIndex = 0, successfulIds = []) => {
  if (!ids || !ids.length || startIndex >= ids.length) {
    return successfulIds;
  }
  let nextIndex = startIndex + 100;
  if (nextIndex > ids.length) {
    nextIndex = ids.length;
  }
  const currentBatchIds = ids.slice(startIndex, nextIndex);
  try {
    await cancelMethod(currentBatchIds);
    successfulIds.push(...currentBatchIds);
  } catch (err) {}
  return await cancelQueuedItemsRecursively(ids, cancelMethod, nextIndex, successfulIds);
};

export const typeCastSchema = ({ value, schema }) => {
  if (!schema || !Object.keys(schema).length) {
    return value;
  }
  let newValue = void 0;
  if (Array.isArray(value)) {
    newValue = value.map(v => typeCastSchema({ value: v, schema }));
  } else {
    newValue = {};
    for (let key in value) {
      let keyValue = value[key];
      const schemaDef = schema[key];
      if (schemaDef && keyValue) {
        if (schemaDef === "date") {
          if (typeof keyValue === "string") {
            keyValue = new Date(keyValue);
          } else if (keyValue._seconds || keyValue.seconds) {
            if (keyValue.toDate) {
              keyValue = keyValue.toDate();
            } else if (keyValue.seconds) {
              if (keyValue.nanoseconds) {
                keyValue = new Date(keyValue.seconds * 1000 + keyValue.nanoseconds / 1000000);
              } else {
                keyValue = new Date(keyValue.seconds * 1000);
              }
            } else if (keyValue._seconds) {
              if (keyValue._nanoseconds) {
                keyValue = new Date(keyValue._seconds * 1000 + keyValue._nanoseconds / 1000000);
              } else {
                keyValue = new Date(keyValue._seconds * 1000);
              }
            }
          }
        } else if (typeof schemaDef === "object") {
          keyValue = typeCastSchema({ value: keyValue, schema: schemaDef.schema });
        }
      }
      newValue[key] = keyValue;
    }
  }
  return newValue;
};

export const isNetworkUrl = url => {
  if (typeof url === "string") {
    return url.startsWith("https://") || url.startsWith("http://");
  }
};

export const isPublicUrl = url => {
  if (url && typeof url === "string") {
    url = decodeURIComponent(url);
    let index = url.indexOf("/PUBLIC_IMAGES/");
    if (index > 0) {
      return true;
    }
  }
};

export const getResourceNameFromUrl = ({ _id, url }) => {
  if (!url || !_id) {
    return;
  }
  url = decodeURIComponent(url);
  let keyToCheck = `/${_id}/`;
  let startIndex = url.indexOf(keyToCheck);
  if (startIndex === -1) {
    keyToCheck = `/PUBLIC_IMAGES/`;
    startIndex = url.indexOf(keyToCheck);
  }
  if (startIndex === -1) {
    return;
  }
  let fileName = url.substring(startIndex + keyToCheck.length);
  let lastIndex = fileName.lastIndexOf("?");
  if (lastIndex !== -1) {
    fileName = fileName.substring(0, lastIndex);
  }
  return fileName;
};

export const isEncrypted = (data, url) => {
  return url && data && data.encrypted && !isPublicUrl(url) && isNetworkUrl(url);
};
