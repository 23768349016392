import React from "react";

class AppShare extends React.Component {
  doAction = () => ({});

  render() {
    return null;
  }
}
export default AppShare;
