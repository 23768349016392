/**
 * screenProps : we can pass screenProps along with screen while defining tabScreen so that we do not need to create different screens when query changed. required by sushil, done by rohit bansal - 21-02-19
 *
 */
import React from "react";

export default ({ resolveMQ, screens = {}, errorComponent, screenLoaders = {} }) => {
  const screenLoader = ({ screen, screenProps, ...restProps }) => {
    // console.warn("@@4 screenLoader called", screen);
    const { activeMQ } = restProps;
    const resolvedScreens = resolveMQ(screens, [screen], activeMQ);
    let screenDef = resolvedScreens[screen];
    let component = void 0;
    if (!screenDef) {
      component = errorComponent({ message: `Screen not found for screen [${screen}]` });
      return {
        component
      };
    }
    // console.warn("@@4 screenLoader screenDef", screenDef);
    if (!screenDef.component) {
      component = errorComponent({ message: `Component must be defined in screen named [${screen}]` });
      return {
        component
      };
    }
    if (React.isValidElement(screenDef.component)) {
      return {
        component: screenDef.component
      };
    }
    const { component: componentName } = screenDef;
    try {
      const screenLoaderComponent = screenLoaders[componentName];
      if (!screenLoaderComponent) {
        throw new Error(
          `Screen Loader not found for screen named [${screen}]. Component name is [${componentName}]. Available screen loaders are [${JSON.stringify(
            Object.keys(screenLoaders)
          )}]`
        );
      }
      if (screenProps) {
        screenDef = { ...screenDef, ...screenProps };
      }
      let screenComponent = screenLoaderComponent({ screen, screenDef, screenLoader, ...restProps });
      if (!screenComponent || !screenComponent.component) {
        throw new Error(
          `No component returned by Screen Loader for screen [${screen}] and type of screen Loader is [${component}]`
        );
      }
      return {
        ...screenComponent
      };
    } catch (e) {
      component = errorComponent({
        message: `Error in loading Screen named [${screen}]. Error is ${e.message}`,
        stack: e.stack
      });
      return {
        component
      };
    }
  };
  return {
    screenLoader
  };
};
