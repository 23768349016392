import { I18N } from "../../../FsCloudComponent";

export default {
  DocsTab: {
    component: "tab",
    titleSM: () => I18N.t("app-title"),
    titleMD: () => I18N.t("docs"),
    showTitleInTabSM: true,
    swipeableSM: true,
    swipeableMD: false,
    tabs: {
      docsAll: {
        tab: {
          text: () => I18N.t("all")
        },
        screen: "DocsAll",
        absoluteHeader: true
      },
      docsSets: {
        tab: {
          text: () => I18N.t("sets")
        },
        screen: "DocsSet",
        absoluteHeader: true
      }
    }
  }
};
