import React from "react";
export default ({ Box, getImage, I18N, theme, getComponent, AppConfig }) => {
  const { colors, fonts } = theme;
  const { h9 } = fonts;
  const { themeColor } = colors;

  class ChatLinkComponent extends React.Component {
    render() {
      const LinkBox = getComponent("anchor");
      return (
        <LinkBox url={AppConfig.APP_TALK_TO_US_URL}>
          <Box
            viewStyle={{ cursor: "pointer", alignItems: "center" }}
            direction={"row"}
            render={[
              {
                image: getImage("questionIcon"),
                viewStyle: {
                  paddingRight: 8
                }
              },
              {
                textStyle: {
                  ...h9,
                  color: themeColor
                },
                text: "Dúvidas? Clique Aqui."
              }
            ]}
          />
        </LinkBox>
      );
    }
  }
  return ChatLinkComponent;
};
