import React from "react";
import {
  Box,
  getImage,
  theme,
  I18N,
  showMessage,
  resolveMQ,
  logDownloadAnalytics,
  DOWNLOAD_UTILS,
  urls,
  preFetch,
  urlFetch,
  getDownloadSnapshot
} from "./FsCloudComponent";
// import moment from "moment";
// import ProgressIndicatorHoc from "progress-indicator";

const { CANCEL_REASONS, clearResumableData } = DOWNLOAD_UTILS;
const { colors, fonts, bgs } = theme;
const { highlightColor, primaryColor, brownGrey, errorColor, bluish } = colors;
const { themeBg, lightPink } = bgs;
const { h16_l, h3MD, h5 } = fonts;

const DOWNLOADING_STATUS = {
  queued: "QUEUED",
  downloading: "DOWNLOADING",
  paused: "PAUSED",
  canceled: "CANCELED",
  downloaded: "COMPLETED",
  error: "ERROR"
};

// const ProgressIndicator = ProgressIndicatorHoc({ Box, getImage, View, theme });

const progressContainerStyle = {
  containerStyleSM: {
    width: "100%",
    borderRadius: 4,
    maxHeight: 300,
    borderColor: themeBg,
    position: "absolute",
    bottom: 60,
    zIndex: 100,
    backgroundColor: highlightColor,
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  },
  containerStyleMD: {
    width: 350,
    borderRadius: 4,
    maxHeight: 300,
    borderColor: themeBg,
    position: "absolute",
    left: 40,
    bottom: 30,
    zIndex: 100,
    backgroundColor: highlightColor,
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  }
};

class DownloadProgressComponent extends React.Component {
  render() {
    let {
      onCancelAll,
      onCancel,
      addResourceRef,
      addMainRef,
      index: activeResourceIndex,
      resources,

      error,
      expanded,
      onExpandClick,
      onCloseModal,
      activeMQ
    } = this.props;
    let { containerStyle } = resolveMQ(progressContainerStyle, ["containerStyle"], activeMQ);
    let headerComponent = {
      direction: "row",
      viewStyle: {
        height: 64,
        backgroundColor: themeBg,
        paddingLeft: 12,
        paddingRight: 12,
        justifyContent: "center",
        alignItems: "center"
      },
      render: [
        {
          width: "1fr",
          viewStyle: {
            marginRight: 12,
            marginLeft: 12
          },
          textStyle: {
            ...h16_l,
            color: highlightColor
          },
          text: `${I18N.t("downloading")} ${activeResourceIndex + 1} ${I18N.t("of")} ${resources.length}`
        },
        onExpandClick && {
          viewStyle: {
            width: 48,
            height: 48,
            paddingLeft: 12,
            paddingRight: 12,
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
          },
          image: expanded ? getImage("toggleDownIcon") : getImage("toggleUpIcon"),
          onClick: onExpandClick
        },
        onCloseModal && {
          viewStyle: {
            width: 48,
            height: 48,
            paddingLeft: 12,
            paddingRight: 12,
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
          },
          image: getImage("crossWhiteIcon"),
          onClick: onCloseModal
        }
      ]
    };
    return (
      <Box
        viewStyle={containerStyle}
        render={[
          headerComponent,
          expanded !== false && {
            // CANCEL ALL BUTTON
            direction: "row",
            viewStyle: {
              borderBottomColor: lightPink,
              borderBottomWidth: 1,
              paddingTop: 12,
              paddingBottom: 12,
              marginLeft: 12,
              marginRight: 12,
              justifyContent: "flex-end"
            },
            render: [
              {
                viewStyle: {
                  marginLeft: 12,
                  marginRight: 12,
                  cursor: "pointer"
                },
                textStyle: { ...h3MD, color: bluish },
                onClick: () => onCancelAll && onCancelAll(),
                text: I18N.t("cancelAll")
              }
            ]
          },
          expanded !== false && {
            // ALL RESOURCES
            viewStyle: {
              flex: 1,
              overflow: "auto"
            }, // body
            getRef: ref => addMainRef && addMainRef(ref),

            render: resources.map(({ resource, status }, index) => {
              let statusText = "";
              let statusColor = brownGrey;
              // const isPaused = status === DOWNLOADING_STATUS.paused;
              const isDownloading = status === DOWNLOADING_STATUS.downloading;
              const isQueued = status === DOWNLOADING_STATUS.queued;
              // const isActive = isPaused || isDownloading;

              if (status === DOWNLOADING_STATUS.canceled) {
                statusText = I18N.t("cancelled");
              } else if (status === DOWNLOADING_STATUS.downloaded) {
                statusText = I18N.t("completed");
              } else if (isDownloading) {
                statusText = "Downloading";
              } else if (status === DOWNLOADING_STATUS.error) {
                statusText = I18N.t("error");
                statusColor = errorColor;
              } else if (error) {
                statusText = error; // Already translated in _downloadFile
                statusColor = errorColor;
              }

              return {
                getRef: ref => addResourceRef && addResourceRef(index, ref),
                direction: "row",
                viewStyle: { marginLeft: 12, marginRight: 12, borderBottomColor: lightPink, borderBottomWidth: 1 },
                render: [
                  {
                    direction: "row",
                    viewStyle: {
                      paddingTop: 12,
                      paddingBottom: 12,
                      alignItems: "center",
                      flex: 1
                    },
                    render: [
                      {
                        viewStyle: {
                          paddingLeft: 12,
                          paddingRight: 6,
                          alignItems: "center",
                          justifyContent: "center"
                        },
                        imageStyle: { height: 24, width: 24 },
                        image: getImage("uploadCoverIcon")
                      },
                      {
                        width: "1fr",
                        viewStyle: {
                          paddingLeft: 6,
                          paddingRight: 8,
                          height: 35,
                          justifyContent: "center"
                        },
                        render: [
                          {
                            textStyle: { numberOfLines: 1, ...h3MD, color: primaryColor },
                            text: resource.resource_name
                          },
                          {
                            viewStyle: { paddingTop: 6 },
                            textStyle: { numberOfLines: 1, ...h5, color: statusColor },
                            text: statusText
                          }
                        ]
                      },
                      isQueued && {
                        viewStyle: {
                          width: 44,
                          height: 24,
                          paddingLeft: 8,
                          paddingRight: 12,
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer"
                        },
                        onClick: () => {
                          onCancel && onCancel({ index: index });
                        },
                        image: getImage("infoCrossWebIcon")
                      }
                    ]
                  }
                ]
              };
            })
          }
        ]}
      />
    );
  }
}

const downloadAllRequest = async ({ resourceType = "gallery" }) => {
  let url = urls["downloadZipAll"]();
  let form = document.createElement("form");
  form.setAttribute("method", "GET");
  form.setAttribute("action", url);

  let {
    props: { context },
    headers: { token }
  } = await preFetch();
  context.token = token; // TODO: see if can be given in headers

  let contextElm = document.createElement("input");
  contextElm.setAttribute("name", "context");
  contextElm.setAttribute("value", JSON.stringify(context));
  contextElm.setAttribute("type", "hidden");
  form.appendChild(contextElm);

  let resourceTypeElm = document.createElement("input");
  resourceTypeElm.setAttribute("name", "type");
  resourceTypeElm.setAttribute("value", resourceType);
  resourceTypeElm.setAttribute("type", "hidden");
  form.appendChild(resourceTypeElm);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const downloadRequest = async ({ selectedData = [] }) => {
  let selectedIds = selectedData.map(({ _id }) => _id);
  if (selectedIds.length) {
    let { downloadKey } = (await urlFetch({ url: urls["downloadZipKey"], props: { resources: selectedIds } })) || {};
    if (downloadKey) {
      let form = document.createElement("form");
      form.setAttribute("method", "GET");
      form.setAttribute("action", `${urls["downloadZip"]()}/${downloadKey}`);

      let {
        props: { context },
        headers: { token }
      } = await preFetch();
      context.token = token; // TODO: see if can be given in headers

      let contextElm = document.createElement("input");
      contextElm.setAttribute("name", "context");
      contextElm.setAttribute("value", JSON.stringify(context));
      contextElm.setAttribute("type", "hidden");
      form.appendChild(contextElm);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  }
};

const downloadFileFromServer = async ({ resource, decryptionSourceProps }) => {
  // console.log("{ resource, decryptionSourceProps }: ", { resource, decryptionSourceProps });
  const { sharedToken } = decryptionSourceProps || {};
  const { _createdBy: { firebaseUid } = {}, collection = {} } = resource || {};
  let reqData = {
    firebaseUid,
    token: sharedToken,
    collection: collection._id
  };
  console.log({
    url: urls["downloadZipKey"](),
    props: { resources: [resource._id], ...reqData }
  });
  let { downloadKey } = await urlFetch({
    url: urls["downloadZipKey"],
    props: { resources: [resource._id], ...reqData }
  });

  console.log(downloadKey);
  if (downloadKey) {
    const form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", `${urls["downloadZip"]()}/${downloadKey}`);
    console.log(`${urls["downloadZip"]()}/${downloadKey}`);
    let {
      props: { context },
      headers: { token }
    } = await preFetch();
    context.token = token; // TODO: see if can be given in headers
    reqData["context"] = context;
    reqData["archive"] = false;

    for (let item in reqData) {
      console.log("formreqData: ", reqData);
      reqData[item] = typeof reqData[item] === "string" ? reqData[item] : JSON.stringify(reqData[item] || false);
      const formElem = document.createElement("input");
      formElem.setAttribute("name", item);
      formElem.setAttribute("value", reqData[item]);
      formElem.setAttribute("type", "hidden");
      form.appendChild(formElem);

      console.log("form: ", item, reqData[item]);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  } else {
    throw new Error("Download Key not generated!");
  }
  return downloadKey;
};

class AppDownload extends React.Component {
  state = { expanded: true, showModal: true };

  _setState = (state, callback) => {
    if (this._unmounted) {
      return;
    }
    this.setState(state, callback);
  };

  componentWillUnmount() {
    this._unmounted = true;
  }

  onExpandClick = () => {
    this._setState({
      expanded: !this.state.expanded
    });
  };

  onCloseModal = () => {
    this._setState({
      showModal: false
    });
  };

  onError = err => {
    let errorCode = err.code;
    let errorMessage = I18N && errorCode && typeof errorCode === "string" && I18N.t(errorCode);
    if (!errorMessage || (errorMessage.indexOf("missing") !== -1 && errorMessage.indexOf("translation") !== -1)) {
      errorMessage = err.message;
    }
    showMessage(errorMessage, 3000);
  };

  handleDownloadProgress = progress => {
    if (this.state.progress !== progress) {
      this._setState({ progress, error: void 0 });
    }
  };

  scrollToIndex = async (index = 0) => {
    if (this.currentRef && this.resources) {
      let bounceHeight = 0;
      if (this.resources[index] && this.resources[index].boxRef) {
        bounceHeight = this.resources[index].boxRef.getBoundingClientRect().height;
      }
      bounceHeight *= index;
      if (bounceHeight > 0) {
        this.currentRef.scrollTop = bounceHeight;
      }
    }
  };

  _downloadFile = async ({ index = 0, progress } = {}) => {
    if (!this.resources) return;
    if (index >= this.resources.length) {
      this.resources = void 0;
      this._setState({ index: void 0, progress: void 0 });
      return;
    }
    let timeout_Seconds = void 0;
    let isDownloadAgain = false;
    const { resource, decryptionSourceProps, status } = this.resources[index] || {};
    if (resource) {
      this.scrollToIndex(index);
      this._setState({ index, progress: progress || void 0 });
      // if (status === DOWNLOADING_STATUS.paused) return;
      if (status === DOWNLOADING_STATUS.canceled) {
        timeout_Seconds = 10;
      } else {
        // Downloading File
        this.setResourceData({ index, status: DOWNLOADING_STATUS.downloading });
        try {
          this._setState({ forceRender: !this.state.forceRender });
          console.log("{ resource, decryptionSourceProps }: ", { resource, decryptionSourceProps });
          let token = await downloadFileFromServer({ resource, decryptionSourceProps });
          console.log("token: ", token);
          let result = await getDownloadSnapshot({ token });
          console.log("result: ", result);
          if (result.error) {
            throw result.error;
          }
          this.setResourceData({ index, status: DOWNLOADING_STATUS.downloaded });
          this._setState({ forceRender: !this.state.forceRender });
        } catch (err) {
          if (err && err.message === "Network Error") {
            isDownloadAgain = true;
            timeout_Seconds = 10000;
            this._setState({ error: I18N.t("no_internet") });
          }
          // else if (
          //   !retryDownload &&
          //   err &&
          //   (err.message === "Request failed with status code 416" || err.message === "offset is out of bounds")
          // ) {
          //   /**
          //    * EITHER resource_size IS GREATER OR LESS THEN ACTUAL SIZE...
          //    * Case is produces in iOS simulator 11 pro max
          //    * There are some cases where resource_size is different from actual resource size
          //    * Therefor, firebase send 416 error for those files.
          //    * We can't download those files in chunks.
          //    * We maintaining retryDownload variable as we only try to download file one time. If error encounter again,
          //    * then we mark download as failed
          //    */
          //   this.setResourceData({ index, retryDownload: true });
          //   isDownloadAgain = true;
          //   timeout_Seconds = 10;
          // }
          else {
            this.setResourceData({ index, status: DOWNLOADING_STATUS.error });
          }
        }
      }
    }
    setTimeout(_ => {
      if (isDownloadAgain) {
        this._downloadFile({ index: index, progress: this.state.progress });
      } else {
        this._downloadFile({ index: index + 1 });
      }
    }, timeout_Seconds || 1000);
  };

  // Used to download items as a zip file.
  downloadZip = async ({ props = {} }) => {
    let { getSelectedData, unSelectAll } = props;
    try {
      let selectedData = (getSelectedData && getSelectedData()) || [];
      showMessage && showMessage(I18N.t("downloadingText"), 1000);
      if (selectedData.length) {
        await downloadRequest({ selectedData });
      } else {
        let { action: { resourceType } = {} } = props; // resourceType:: "gallery", "doc", "audio"
        await downloadAllRequest({ resourceType });
      }
    } catch (err) {
      let errorCode = err.code;
      let errorMessage = I18N && errorCode && typeof errorCode === "string" && I18N.t(errorCode);
      if (!errorMessage || (errorMessage.indexOf("missing") !== -1 && errorMessage.indexOf("translation") !== -1)) {
        errorMessage = err.message;
      }
      showMessage(errorMessage, 3000);
    }
    unSelectAll && unSelectAll();
  };

  doAction = async ({ props }) => {
    try {
      let { getSelectedData, unSelectAll, data, link, action = {} } = props;
      let selectedData = (getSelectedData && getSelectedData()) || [];
      let { sharedToken, origin, collectionItems } = action;
      let linkParams = (link && link.props && link.props.params) || {};
      if (!sharedToken && linkParams.sharedToken) {
        sharedToken = linkParams.sharedToken;
      }
      let resources = [];
      if (selectedData && selectedData.length) {
        resources = selectedData;
      } else if (data && !Array.isArray(data)) {
        if (!data.collection && (origin === "album" || origin === "set" || origin === "playlist")) {
          if (sharedToken && collectionItems) {
            resources = collectionItems;
          }
        } else {
          resources = [data];
        }
      }
      if (resources.length) {
        logDownloadAnalytics(resources);
        this._setState({ showModal: true, expanded: true });
        resources = resources.map(resource => ({
          resource,
          status: DOWNLOADING_STATUS.queued,
          decryptionSourceProps: {
            sharedToken,
            decryptionSource: "collection" // since group doesn't has download, collection and normal download will be similar here.
          }
        }));
        if (this.resources) {
          this.resources = this.resources.concat(resources);
          showMessage && showMessage(I18N.t("downloadQueued"), 1000);
        } else {
          this.resources = resources;
          showMessage && showMessage(I18N.t("downloadingText"), 1000);
        }
        setTimeout(_ => {
          this.state.index === undefined && this._downloadFile();
        }, 1000);
      }
      unSelectAll && unSelectAll();
    } catch (err) {
      this.onError(err);
    }
  };

  // Used to cancel downloads
  onCancelAll = () => {
    const { index } = this.state;
    let activeResource = this.resources[index] || {};
    let { cancelRef, resource: { resource_url } = {} } = activeResource;
    cancelRef && cancelRef.cancel(CANCEL_REASONS.CANCELLED);
    clearResumableData(resource_url);
    this.resources = void 0;
    this._setState({ index: void 0, progress: void 0 });
  };

  // Used to cancel resource downloading at given index
  onCancel = ({ index }) => {
    if (index < this.state.index) {
      return;
    }
    const { cancelRef, status, resource: { resource_url } = {} } = this.resources[index] || {};
    let isSkipRender = false;
    if (index === this.state.index) {
      isSkipRender = true;
      //  cancel current item
      if (status !== DOWNLOADING_STATUS.downloaded) {
        cancelRef && cancelRef.cancel(CANCEL_REASONS.CANCELLED);
        clearResumableData(resource_url);
        this.setResourceData({ index, status: DOWNLOADING_STATUS.canceled }); // mark status canceled
      }
      if (status === DOWNLOADING_STATUS.paused) {
        this._downloadFile({ index: index + 1 });
      }
    } else {
      this.setResourceData({ index, status: DOWNLOADING_STATUS.canceled }); // mark status canceled
    }
    if (!isSkipRender) {
      this._setState({ forceRender: !this.state.forceRender });
    }
  };

  // Used to populate resource data at specific index
  setResourceData = ({ index, ...rest }) => {
    try {
      if (this.resources && this.resources[index]) {
        this.resources[index] = { ...this.resources[index], ...rest };
      }
    } catch (err) {
      console.log("@@@@ ERROR IN UPDATE RESOURCES [CLASS LEVEL VARIABLE] AT INDEX: ", index);
    }
  };

  // Used to pause resume downloading item
  // state index is not updated here
  toggleDownloading = pausedIndex => {
    const { index } = this.state;
    if (pausedIndex !== index) {
      return;
    }
    const { status, cancelRef } = this.resources[pausedIndex] || {};
    if (status === DOWNLOADING_STATUS.paused) {
      // will resume downloading
      this.setResourceData({ index: pausedIndex, status: DOWNLOADING_STATUS.downloading });
      this._downloadFile({ index, progress: this.state.progress });
    } else if (status === DOWNLOADING_STATUS.downloading) {
      // will pause downloading
      cancelRef && cancelRef.cancel(CANCEL_REASONS.PAUSED);
      this.setResourceData({ index: pausedIndex, status: DOWNLOADING_STATUS.paused });
      this._setState({ forceRender: !this.state.forceRender });
    }
  };

  addResourceRef = (index, ref) => {
    this.setResourceData({ index, boxRef: ref });
  };

  render() {
    if (this.state.index !== undefined && (this.props.componentsCount === 1 || this.state.showModal)) {
      return (
        <DownloadProgressComponent
          resources={this.resources}
          index={this.state.index}
          progress={this.state.progress}
          error={this.state.error}
          expanded={this.state.expanded}
          onExpandClick={this.onExpandClick}
          onCancelAll={this.onCancelAll}
          onCancel={this.onCancel}
          addResourceRef={this.addResourceRef}
          addMainRef={ref => (this.currentRef = ref)}
          onCloseModal={this.props.componentsCount === 1 ? void 0 : this.onCloseModal}
          {...this.props}
        />
      );
    } else {
      return null;
    }
  }
}
export default AppDownload;
