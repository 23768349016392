import React from "react";
import FacebookShareButton from "./FacebookShareButton";
import TwitterShareButton from "./TwitterShareButton";
import {
  Box,
  View,
  I18N,
  getImage,
  theme,
  PopupHeader as Header,
  resolveMQ,
  withContext,
  StatusBar as LoadingIndicator,
  fireLocalDataChangeListener,
  logFirebaseAnalyticsEvent,
  RowAction,
  Switch
} from "../../../FsCloudComponent";
import { generateShareLink } from "./shareUtility";
import GetLinkPopupComponent from "./GetLinkPopupComponent";
import ShareViaEmailPopup from "./ShareViaEmailPopup";
import ResourceActions from "../resource/ResourceActions";

let { colors, fonts } = theme;
let { lightPink, primaryColor, brownGrey } = colors;
let { h16_l } = fonts;

const componentTheme = {
  containerStyleMD: {
    viewStyle: {
      width: 351
    }
  },
  bodyStyle: {
    containerStyle: {
      viewStyle: {
        marginBottom: 18
      }
    },
    rowStyle: {
      viewStyle: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 12,
        paddingBottom: 12,
        cursor: "pointer",
        alignItems: "center"
      },
      direction: "row"
    },
    rowIconStyle: {
      viewStyle: {
        height: 32,
        width: 32,
        justifyContent: "center",
        alignItems: "center"
      },
      imageStyle: {
        objectFit: "contain"
      },
      imageProps: {
        resizeMode: "contain"
      }
    },
    rowTextStyle: {
      viewStyle: { paddingLeft: 21 },
      textStyle: {
        ...h16_l,
        color: primaryColor,
        numberOfLines: 1
      }
    }
  }
};
class SharePopupComponent extends React.Component {
  constructor(props) {
    super(props);
    let { action: { origin } = {}, data } = this.props;
    let collaborator_mode = void 0;
    if (origin === "collection") {
      collaborator_mode = data && data["colloborator_mode"];
    }
    this.state = { url: null, viewType: "options", collaborator_mode };
  }

  onClose = () => {
    let { onClose, unSelectAll } = this.props;
    unSelectAll && unSelectAll();
    onClose && onClose();
  };

  _generateLink = async () => {
    try {
      this.setState({ loading: true });
      let { selectedIds, data, action: { origin } = {}, invoke, urls } = this.props;
      let { url } = await generateShareLink({
        fireLocalDataChangeListener,
        logFirebaseAnalyticsEvent,
        selectedIds,
        data,
        origin,
        invoke,
        urls,
        collaborator_mode: this.state.collaborator_mode
      });
      this.setState({ url, loading: false });
      this.onClose();
      return { url };
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  render() {
    let { activeMQ, action, data, user } = this.props;
    let { origin } = action || {};
    let { url, viewType, collaborator_mode } = this.state;
    const { containerStyle, bodyStyle } = resolveMQ(componentTheme, ["containerStyle", "bodyStyle"], activeMQ);
    const getRowComponent = ({ text, image }) => {
      return {
        ...bodyStyle.rowStyle,
        render: [
          {
            ...bodyStyle.rowIconStyle,
            image: getImage(image)
          },
          {
            ...bodyStyle.rowTextStyle,
            text: I18N.t(text)
          }
        ]
      };
    };

    if (viewType === "options") {
      return (
        <View>
          {this.state.loading && <LoadingIndicator />}
          <Box
            {...containerStyle}
            render={[
              <Header title={I18N.t("toShare")} onClose={this.onClose} />,
              origin === "collection" &&
                data &&
                data.owner &&
                (collaborator_mode === "read" || collaborator_mode === "write") && {
                  direction: "row",
                  viewStyle: {
                    marginLeft: 30,
                    marginRight: 30,
                    marginTop: 30,
                    alignItems: "center"
                  },
                  render: [
                    <Switch
                      onChangeValue={value => {
                        this.setState({ collaborator_mode: value ? "write" : "read" });
                      }}
                      value={collaborator_mode === "write"}
                    />,
                    {
                      width: "1fr",
                      text: I18N.t("collaborationMessageSharePopup"),
                      viewStyle: {
                        paddingLeft: 12
                      },
                      textStyle: {
                        ...h16_l,
                        color: brownGrey
                      }
                    }
                  ]
                },
              {
                viewStyle: {
                  marginLeft: 30,
                  marginRight: 30,
                  paddingTop: 24,
                  paddingBottom: 18,
                  backgroundColor: lightPink
                },
                textStyle: {
                  ...h16_l,
                  color: primaryColor
                },
                text: I18N.t("shareOptionsHeader")
              },
              {
                ...bodyStyle.containerStyle,
                render: [
                  {
                    ...getRowComponent({ text: "getLink", image: "sharePopupLinkIcon" }),
                    onClick: () => this.setState({ viewType: "getLink" })
                  },
                  {
                    ...getRowComponent({ text: "email", image: "sharePopupEmailIcon" }),
                    onClick: () => this.setState({ viewType: "shareViaEmail" })
                  },
                  origin !== "collection" && user && user.group && (
                    <RowAction
                      source={"Header"}
                      data={data}
                      action={{
                        ...ResourceActions.postToGroup,
                        skipPending: true,
                        beforeInvoke: () => this.setState({ loading: true }),
                        afterInvoke: () => {
                          this.setState({ loading: false });
                          this.onClose();
                        }
                      }}
                    >
                      <Box {...getRowComponent({ text: "sendToGroup", image: "sharePopupPinIcon" })} />
                    </RowAction>
                  ),
                  <FacebookShareButton beforeOnClick={this._generateLink} url={url}>
                    <Box {...getRowComponent({ text: "facebook", image: "sharePopupFacebookIcon" })} />
                  </FacebookShareButton>,
                  <TwitterShareButton beforeOnClick={this._generateLink} url={url}>
                    <Box {...getRowComponent({ text: "twitter", image: "sharePopupTwitterIcon" })} />
                  </TwitterShareButton>
                ]
              }
            ]}
          />
        </View>
      );
    } else if (viewType === "getLink") {
      return <GetLinkPopupComponent {...this.props} {...this.state} onClose={this.onClose} />;
    } else if (viewType === "shareViaEmail") {
      return <ShareViaEmailPopup {...this.props} {...this.state} onClose={this.onClose} />;
    }
  }
}

SharePopupComponent = withContext(SharePopupComponent, {
  activeMQ: "ActiveMQ",
  selectedIds: "SelectionStore.selectedIds",
  unSelectAll: "SelectionStore.unSelectAll",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user"
});

export default SharePopupComponent;
