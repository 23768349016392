import React from "react";
import { getSource } from "./Utility";

export default ({ Image, isEncrypted, decryptFile, getDecryptionProps, cancelDecryption }) => {
  class DecryptedImageInternal extends React.Component {
    state = {};

    componentDidMount() {
      let source = getSource(this.props.source);
      if (this.isEncrypted(source)) {
        this.decryptImage(source);
      }
    }

    _setState = (state, cb) => {
      if (this._unmounted) {
        return;
      }
      this.setState(state, cb);
    };

    isEncrypted = url => isEncrypted(this.props.decryptionProps, url);

    componentDidUpdate(prevProps) {
      let source = getSource(this.props.source);
      if (this.isEncrypted(source)) {
        if (getSource(prevProps.source) !== source) {
          this._setState({ source: void 0 }, () => {
            this.cancelDecryption(prevProps);
            this.decryptImage(source);
          });
        }
      }
    }

    componentWillUnmount() {
      this.cancelDecryption(this.props);
      this._unmounted = true;
    }

    cancelDecryption = props => {
      // let { source } = props;
      // let url = getSource(source);
      // cancelDecryption && cancelDecryption({ url, callback: this.decryptCallback, origin: "DecryptedImage" });
    };

    decryptCallback = (err, source) => {
      source && this._setState({ source });
    };

    decryptImage = url => {
      let { decryptionProps } = this.props;
      // uncomment for queue implementation
      // decryptFile({ url, callback: this.decryptCallback, decryptionProps, origin: "DecryptedImage" });
      decryptFile({ url, callback: this.decryptCallback, decryptionProps });
    };

    render() {
      let { source, decryptionProps, renderComponent, ...rest } = this.props;
      let imageKey = getSource(source);
      if (this.isEncrypted(imageKey)) {
        source = this.state.source;
        if (!source) {
          imageKey = "no_source";
        }
      }
      if (renderComponent && typeof renderComponent === "function") {
        return renderComponent({ ...rest, source: source || this.props.source, key: imageKey });
      }
      return <Image {...rest} source={source} key={imageKey} />;
    }
  }

  class DecryptedImage extends React.Component {
    render() {
      let { data, decryptionSourceProps, ...restProps } = this.props;
      return (
        <DecryptedImageInternal {...restProps} decryptionProps={getDecryptionProps(data, decryptionSourceProps)} />
      );
    }
  }

  return DecryptedImage;
};
