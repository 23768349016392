export default ({ fonts, colors, shadows }) => {
  const { h1, h2 } = fonts;
  const { primaryColor, highlightColor } = colors;

  const panelTitleStyleSM = {
    viewStyle: {
      padding: 6
    },
    textStyle: {
      ...h2,
      color: primaryColor
    }
  };

  const panelTitleStyleMD = {
    viewStyle: {
      padding: 12
    },
    textStyle: {
      ...h1,
      color: primaryColor
    }
  };

  const panelFooterStyleSM = {
    viewStyle: {
      height: 48,
      marginTop: 8
    }
  };

  const panelFooterStyleMD = {
    viewStyle: {
      height: 48,
      marginTop: 16
    }
  };

  const panelTitleStyle = {
    boxStyleSM: {
      viewStyle: {
        ...panelTitleStyleSM.viewStyle
      },
      textStyle: {
        ...panelTitleStyleSM.textStyle
      }
    },
    boxStyleMD: {
      viewStyle: {
        ...panelTitleStyleMD.viewStyle
      },
      textStyle: {
        ...panelTitleStyleMD.textStyle
      }
    }
  };

  const panelHeaderStyle = {
    boxStyleSM: {
      direction: "row",
      viewStyle: {
        height: 60,
        paddingLeft: 4,
        paddingRight: 12,
        paddingTop: 16,
        paddingBottom: 12,
        alignItems: "center",
        backgroundColor: highlightColor,
        ...shadows.navHeaderShadowSM
      }
    },
    boxStyleMD: {
      direction: "row",
      viewStyle: {
        height: 72,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 12,
        paddingBottom: 12,
        alignItems: "center",
        backgroundColor: highlightColor,
        overflow: void 0,
        marginBottom: 2,
        ...shadows.navHeaderShadowMD
      }
    },
    separatorStyleSM: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    separatorStyleMD: {
      viewStyle: {
        paddingLeft: 15,
        paddingRight: 15
      }
    }
  };

  const panelFooterStyle = {
    boxStyleSM: {
      direction: panelFooterStyleSM.direction || "row",
      viewStyle: {
        justifyContent: "center",
        ...panelFooterStyleSM.viewStyle
      }
    },
    boxStyleMD: {
      direction: panelFooterStyleMD.direction || "row",
      viewStyle: {
        justifyContent: "center",
        ...panelFooterStyleMD.viewStyle
      }
    }
  };

  const panelStyle = {
    style: {
      flex: 1
    }
  };

  return {
    panelTitleStyle,
    panelHeaderStyle,
    panelFooterStyle,
    panelStyle
  };
};
