import home from "./home";
import highlight from "./highlight";
import gallery from "./gallery";
import profile from "./profile";
import configuration from "./configuration";
import music from "./music";
import docs from "./docs";
import uploadResource from "./uploadResource";
import groups from "./groups";
import login from "./login";
import blog from "./blog";
import allFiles from "./allFiles";
import shared from "./shared";
import trashCan from "./trashCan";
import backup from "./backup";
import freeUpSpace from "./freeUpSpace";
import collaboration from "./collaboration";
import connections from "./connections";
import issues from "./issues";
import vault from "./vault";
import failed from "./failed";
import archive from "./archive";
import documentScanner from "./documentScanner";
import localStats from "./localstats";
import nonUploaded from "./nonUploaded";

export default {
  ...home,
  ...collaboration,
  ...highlight,
  ...gallery,
  ...profile,
  ...configuration,
  ...music,
  ...docs,
  ...groups,
  ...uploadResource,
  ...login,
  ...blog,
  ...allFiles,
  ...shared,
  ...trashCan,
  ...backup,
  ...freeUpSpace,
  ...connections,
  ...issues,
  ...vault,
  ...failed,
  ...archive,
  ...documentScanner,
  ...localStats,
  ...nonUploaded
};
