import { DocsCard } from "./DocsCards";
import DetailCollectionComponent from "../collection/DetailCollection";
import { setDetailQuery, vaultSetDetailQuery } from "../queries";

export default {
  SetDetail: {
    component: "table",
    dataObject: "Resource",
    Component: DetailCollectionComponent,
    listProps: {
      list: "scrollIndicatorList",
      indicator_field: "resource_lastModified",
      detailLink: ({ data = {}, link = {} }) => {
        let { props: { params: { collection = {} } = {} } = {} } = link;
        let { name, colloborator_mode, mode, owner } = collection;
        return {
          uri: `/set-doc-detail`,
          decryptionSourceProps: { decryptionSource: "collection" },
          detailId: data._id,
          props: {
            params: {
              collection: { ...data.collection, name, colloborator_mode, mode, owner }
            }
          },
          origin: "set"
        };
      }
    },
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true,
    ...setDetailQuery,
    rowProps: {
      ...DocsCard
    }
  },
  VaultSetDetail: {
    component: "table",
    dataObject: "Resource",
    Component: DetailCollectionComponent,
    listProps: {
      list: "scrollIndicatorList",
      indicator_field: "resource_lastModified",
      detailLink: ({ data = {}, link = {} }) => {
        let { props: { params: { collection = {} } = {} } = {} } = link;
        let { name, colloborator_mode, mode, owner } = collection;
        return {
          uri: `/set-doc-detail`,
          decryptionSourceProps: { decryptionSource: "collection" },
          detailId: data._id,
          props: {
            params: {
              collection: { ...data.collection, name, colloborator_mode, mode, owner }
            }
          },
          origin: "vaultSet"
        };
      }
    },
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true,
    ...vaultSetDetailQuery,
    rowProps: {
      ...DocsCard
    }
  }
};
