import ScanDocument from "./ScanDocument";

export default {
  DocumentScanner: {
    component: "table",
    dataObject: "Resource",
    Component: ScanDocument,
    panelProps: {
      showHeader: false
    },
    // hideNavHeader: true,
    listProps: {
      selectionRequired: false,
      imageField: "resource_url",
      imageHeight: "height",
      imageWidth: "width",
      rowProps: {
        imageField: "resource_url"
      }
    }
  }
};
