import { I18N } from "../../../FsCloudComponent";

export default {
  VaultGalleryTab: {
    component: "tab",
    titleSM: () => I18N.t("app-title"),
    titleMD: () => I18N.t("gallery"),
    showTitleInTabSM: true,
    swipeableSM: true,
    swipeableMD: false,
    tabs: {
      vaultGalleryAll: {
        tab: {
          text: () => I18N.t("all")
        },
        absoluteHeader: true,
        fastScrollerRequire: true,
        screenSM: "VaultGallery",
        screenMD: "VaultGalleryWeb"
      },
      vaultAlbums: {
        tab: {
          text: () => I18N.t("albums")
        },
        absoluteHeader: true,
        screen: "VaultAlbum"
      }
    }
  },
  VaultDocsTab: {
    component: "tab",
    titleSM: () => I18N.t("app-title"),
    titleMD: () => I18N.t("gallery"),
    showTitleInTabSM: true,
    swipeableSM: true,
    swipeableMD: false,
    tabs: {
      vaultDocs: {
        tab: {
          text: () => I18N.t("all")
        },
        absoluteHeader: true,
        fastScrollerRequire: true,
        screen: "VaultDocs"
        // screenSM: "GalleryAll",
        // screenMD: "GalleryAllWeb"
      },
      vaultSets: {
        tab: {
          text: () => I18N.t("sets")
        },
        absoluteHeader: true,
        screen: "VaultSet"
      }
    }
  }
};
