import React from "react";
import { Box, I18N, ScrollView } from "../../../FsCloudComponent";

class DeleteAccount extends React.Component {
  render() {
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box text={"DeleteAccount"} />
      </ScrollView>
    );
  }
}

export default {
  DeleteAccount: {
    component: "custom",
    title: () => I18N.t("deleteAccount"),
    Component: DeleteAccount
  }
};
