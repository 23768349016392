const colors = {
  darkBlackColor: "#444444",
  blackColor: "#1a1a1a",
  brownGrey: "#919191",
  charcoal: "#353530",
  greyishBrownTwo: "#4c4c4c",
  primaryColor: "#585858", // rgb(88, 88, 88)
  secondaryColor: "#8b8b8b", // rgb (139 139 139)
  highlightColor: "#ffffff", // rgb (255 255 255)
  themeColor: "#0048C7", //rgb(45, 188, 181)
  actionColor: "#0048C7", //  rgb(93 82 244)
  errorColor: "#e30e0e",
  greyish: "#b2b2b2", //rgb rgb 178 178 178     //for placeholder
  transparent: "transparent",
  tabBlackColor: "#0f0f0f",
  bluish: "#3389d1",
  paleLavender: "#f2eaf7",
  tomato: "#f13f3f",
  paleGrey: "#F4F7FB",
  turtleGreen: "#84ba5f",
  lightPurple: "#ece0f2",
  whiteTwo: "#d8d8d8",
  charcoalGrey: "#393a3d",
  logoColor: "#0048C7"
};

const bgs = {
  lightRed: "rgba(245, 166, 35, 0.07)",
  lightGray: "#fbfbfb",
  lightPink: "#f2f2f2",
  veryLightPink: "#e8e8e8",
  yellowBg: "#f5a623",
  primaryBg: "#f7f7f7", // nav background, rgb 247 247 247
  overlayBg: "(rgba(0, 0, 0, 0.4))",
  darkOverlayBg: "rgba(0,0,0,0.7)",
  lightBlueBg: "rgba(129, 207, 224, 0.2)",
  darkBg: "rgb(0, 0, 0)",
  themeBg: "#0048C7" /**rgb(45, 188, 181) */,
  lightThemeBg: "rgba(45, 188, 181, 0.1)",
  primaryBg1: "#e7e7e7" /* rgb(231,231,231) */,
  primaryBg2: "#ffffff", // active workitem - open card  bg
  primaryBg3: "rgb(247,246,254)", // space filter background
  primaryBg4: "#ebeafc",
  primaryBg5: "#fafafa", // all files or doc screen web backgroundColor in MD/LG
  rowActionBgMD: "#f7f7f7",
  ovalThemeBg: "rgba(45, 188, 181, 0.15)",
  ovalOrangeBg: "rgba(244,182,9,0.15)",
  ovalGreenBg: "rgba(106,194,90,0.15)",
  paleGrey: "#e7e6fe",
  themeBorder: "#0048C7" /**rgb(45, 188, 181) */,
  primaryBorder: "#979797", // rgb (239 239 239)
  secondaryBorder: "#d1d1d1", // rgb (209 209 209)
  primaryBorder2: "#f2f2f2", //all files or doc screen web borderbottom in MD/LG
  highlightBorder: "rgba(239, 239, 239, 0.1)",
  borderBg1: "rgb(224, 224, 224)",
  borderBg2: "rgb(246, 246, 246)",
  borderBg3: "rgb( 239, 239, 239)",
  borderBg4: "#dedede",
  borderBg5: "rgb(226, 226, 226)",
  borderBg6: "rgb(45, 188, 181)",
  loginOtpBg: "#e8e8e8",
  blackBg: "#000000",
  charcoalBlack: "#1c1c1c"
};

export default {
  colors,
  bgs
};
