import { getAvatarDisplay } from "app-utility-functions";
import AvatarHoc from "./selectionbox/lib/Avatar";
import AvatarBoxHoc from "./selectionbox/lib/AvatarBox";
import AvatarCardHoc from "./selectionbox/lib/AvatarCard";

export default components => {
  const { theme, resolveMQ, withContext, Box } = components;
  const CircularAvatar = withContext(
    AvatarHoc({ getAvatarDisplay, themeSM: theme.circularAvatar, themeMD: theme.circularAvatarMD, Box, resolveMQ }),
    {
      getImageUrl: "App.getImageUrl",
      activeMQ: "ActiveMQ"
    }
  );

  const AvatarCard = withContext(
    AvatarCardHoc({ Avatar: CircularAvatar, Box, resolveMQ, theme: theme.avatarCardStyle }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const AvatarBox = withContext(AvatarBoxHoc({ AvatarMD: AvatarCard, AvatarSM: CircularAvatar, resolveMQ }), {
    activeMQ: "ActiveMQ"
  });

  return {
    AvatarCard,
    AvatarBox
  };
};
