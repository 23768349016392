import React from "react";
import { I18N, Switch, showMessage } from "../../../FsCloudComponent";

export default class SwitchCollaborator extends React.Component {
  onChangeValue = async value => {
    try {
      let { invoke, urls, link: { props: { params } = {} } = {} } = this.props;
      let collectionId = params && params.collection && params.collection._id;
      await invoke({
        service: {
          url: urls["updateColloboratorMode"],
          uriProps: { data: { _id: collectionId, colloborator_mode: value ? "write" : "read" } }
        }
      });
      let message = value ? "turningOnCollaboration" : "turningOffCollaboration";
      showMessage && showMessage(I18N.t(message), 2000);
    } catch (err) {
      showMessage && showMessage(err.message, 2000);
    }
  };

  render() {
    let { link: { props: { params } = {} } = {} } = this.props;
    let { colloborator_mode } = (params && params.collection) || {};
    return <Switch onChangeValue={this.onChangeValue} value={colloborator_mode === "read" ? false : true} />;
  }
}
