import React from "react";
import moment from "moment";
import {
  Box,
  theme,
  getImage,
  I18N,
  RowAction,
  fromNowDateFormatter,
  AppConfig,
  getComponent,
  RenderNoData,
  getAction
} from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import { allDevicesQuery } from "../queries";

const { fonts, colors, bgs } = theme;
const { h16_l } = fonts;
const { primaryColor, themeColor, brownGrey } = colors;
const { lightPink } = bgs;

const { APP_IOS_URL, APP_ANDROID_URL } = AppConfig;
const LinkBox = getComponent("anchor");

const DevicesNoData = props => {
  const info = {
    image: "shareNoDataIcon",
    primaryText: I18N.t("devicesNoDataHeader")
  };
  return <RenderNoData {...info} {...props} />;
};

class MyDevicesComponent extends React.Component {
  render() {
    let { data } = this.props;
    return (
      <Box
        viewStyle={{ flex: 1, paddingLeft: 110, paddingBottom: 100, paddingRight: 110 }}
        render={[
          {
            viewStyle: {
              paddingLeft: 6
            },
            textStyle: {
              ...h16_l,
              color: primaryColor
            },
            text: I18N.t("myDeviceMessage")
          },
          {
            viewStyle: {
              marginLeft: 142,
              marginTop: 34,
              marginBottom: 21
            },
            textStyle: {
              ...h16_l,
              color: primaryColor
            },
            direction: "row",
            render: [
              {
                viewStyle: { width: 271 },
                text: I18N.t("deviceName")
              },
              {
                viewStyle: { width: 243 },
                text: I18N.t("lastSeen")
              }
            ]
          },
          {
            viewStyle: {
              flex: 1,
              overflow: "auto"
            },
            textStyle: {
              ...h16_l,
              color: brownGrey
            },
            render: [
              data &&
                data.map(row => ({
                  direction: "row",
                  viewStyle: {
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 19,
                    paddingBottom: 19,
                    alignItems: "center",
                    borderBottomWidth: 1,
                    borderColor: lightPink
                  },
                  render: [
                    {
                      width: 32,
                      viewStyle: {
                        marginLeft: 30,
                        marginRight: 30,
                        paddingLeft: 8,
                        paddingRight: 8
                      },
                      image: row && row.type === "Android" ? getImage("androidGreyIcon") : getImage("iosGreyIcon")
                    },
                    {
                      viewStyle: {
                        paddingLeft: 20,
                        paddingRight: 20,
                        width: 271
                      },
                      text: row.deviceName
                    },
                    {
                      viewStyle: {
                        paddingLeft: 20,
                        paddingRight: 20,
                        width: 243
                      },
                      text: () => {
                        let lastseen = fromNowDateFormatter(row.updatedOn);
                        if (lastseen === "Today") {
                          lastseen = `${lastseen}, ${moment(new Date(row.updatedOn)).format("hh:mm a")}`;
                        }
                        return lastseen;
                      }
                    },
                    {
                      viewStyle: {
                        paddingLeft: 20,
                        paddingRight: 20,
                        cursor: "pointer"
                      },
                      textStyle: {
                        ...h16_l,
                        color: themeColor
                      },
                      text: I18N.t("unlink"),
                      Container: RowAction,
                      containerProps: {
                        data: row,
                        action: ResourceActions.unlinkDevice
                      }
                    },
                    {
                      viewStyle: {
                        paddingLeft: 20,
                        paddingRight: 20,
                        cursor: "pointer"
                      },
                      textStyle: {
                        ...h16_l,
                        color: themeColor
                      },
                      text: I18N.t("block"),
                      Container: getAction("BlockDeviceWebComponent"),
                      containerProps: {
                        action: { ...row }
                      }
                    }
                  ]
                }))
            ]
          },
          // {
          //   direction: "row",
          //   viewStyle: {
          //     paddingLeft: 6,
          //     paddingTop: 11
          //   },
          //   render: [
          //     {
          //       viewStyle: {
          //         paddingLeft: 16,
          //         paddingRight: 16,
          //         paddingTop: 10,
          //         paddingBottom: 10,
          //         borderWidth: 1,
          //         borderRadius: 4,
          //         borderColor: themeColor
          //       },
          //       textStyle: {
          //         ...h16_l,
          //         color: themeColor
          //       },
          //       text: I18N.t("osDownloadMessage")
          //     }
          //   ]
          // },
          {
            direction: "row",
            viewStyle: {
              paddingLeft: 6,
              paddingTop: 16,
              paddingBottom: 16
            },
            render: [
              {
                textStyle: {
                  ...h16_l,
                  color: primaryColor
                },
                text: I18N.t("availableMessage")
              },
              {
                Container: LinkBox,
                containerProps: { url: APP_ANDROID_URL },
                viewStyle: { paddingLeft: 2 },
                textStyle: {
                  ...h16_l,
                  color: themeColor
                },
                text: "Android,"
              },
              {
                Container: LinkBox,
                containerProps: { url: APP_IOS_URL },
                viewStyle: { paddingLeft: 2 },
                textStyle: {
                  ...h16_l,
                  color: themeColor
                },
                text: " Iphone,"
              },
              {
                Container: LinkBox,
                containerProps: { url: APP_IOS_URL },
                viewStyle: { paddingLeft: 2 },
                textStyle: {
                  ...h16_l,
                  color: themeColor
                },
                text: " Ipad"
              }
              // {
              //   viewStyle: { paddingLeft: 2 },
              //   textStyle: {
              //     ...h16_l,
              //     color: themeColor
              //   },
              //   text: "Windows Phone"
              // }
            ]
          }
        ]}
      />
    );
  }
}

export default {
  MyDevicesWeb: {
    component: "table",
    Component: MyDevicesComponent,
    renderNoData: <DevicesNoData />,
    ...allDevicesQuery
  }
};
