import React from "react";

export default ({ Box, View, theme, getInput, I18N, Header, LoadingIndicator, showMessage, getImage, resolveMQ }) => {
  const { colors, fonts, bgs } = theme;
  const { themeColor, brownGrey, tomato, highlightColor, primaryColor } = colors;
  const { lightPink } = bgs;
  const { h2_18, h10, h5_16, h18_l_22, h2, h9 } = fonts;

  const messages = {
    headerTitle: () => I18N.t("saveAsGif"),
    mandatoryError: () => I18N.t("saveGIFValidationMessage"),
    nameLengthError: () => I18N.t("gifNameLengthMessage")
  };

  const saveAsGif = async ({ props } = {}) => {
    let { getSelectedData, closeModal, invoke, urls, name } = props;
    try {
      let selectedData = getSelectedData && getSelectedData();
      let resourceIds = [];
      selectedData.forEach(({ _id, ext }) => {
        let ext_small = ext && ext.toLowerCase();
        if (ext_small !== "gif") {
          resourceIds.push(_id);
        }
      });
      await invoke({
        service: {
          url: urls["saveAsGif"],
          uriProps: {
            data: { resourceIds, name }
          }
        }
      });
      showMessage && showMessage(I18N.t("gifMessage"), 3000);
    } catch (err) {
      let errorCode = err && err.code;
      let errorMessage = I18N && errorCode && typeof errorCode === "string" && I18N.t(errorCode);
      if (!errorMessage || (errorMessage.indexOf("missing") !== -1 && errorMessage.indexOf("translation") !== -1)) {
        errorMessage = err.message;
      }
      showMessage(errorMessage, 3000);
    }
    closeModal && closeModal();
  };

  const componentThemeMD = {
    containerStyle: {
      viewStyle: {
        width: 357
      }
    },
    inputStyle: {
      parentContainerStyle: {
        viewStyle: {
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 23
        },
        direction: "row"
      },
      containerStyle: {
        viewStyle: {
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 4,
          backgroundColor: lightPink
        }
      },
      textInputStyle: { ...h9, color: brownGrey },
      extensionStyle: {
        viewStyle: {
          marginLeft: 8,
          padding: 14,
          borderRadius: 4,
          backgroundColor: lightPink
        },
        textStyle: {
          ...h9,
          color: brownGrey
        }
      }
    },
    footerStyle: {
      containerStyle: {
        viewStyle: {
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 15,
          paddingBottom: 24,
          alignItems: "center"
        },
        direction: "row"
      },
      errorStyle: {
        viewStyle: {
          flex: 1,
          paddingRight: 12
        },
        textStyle: {
          ...h9,
          color: tomato,
          numberOfLines: 2
        }
      },
      saveActionStyle: {
        viewStyle: {
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: themeColor,
          borderRadius: 4,
          cursor: "pointer"
        },
        textStyle: {
          ...h9,
          color: highlightColor
        }
      }
    }
  };

  const componentThemeSM = {
    containerStyle: {
      viewStyle: {
        paddingLeft: 12,
        paddingRight: 12
      }
    },
    headerStyle: {
      viewStyle: {
        paddingTop: 25,
        paddingBottom: 25
      },
      textStyle: { ...h18_l_22, color: primaryColor }
    },
    inputHeaderStyle: {
      viewStyle: {
        paddingTop: 8,
        paddingBottom: 3
      },
      textStyle: { ...h5_16, color: brownGrey }
    },
    inputStyle: {
      containerStyle: {
        viewStyle: {
          paddingBottom: 8,
          borderBottomWidth: 2,
          borderBottomColor: themeColor
        },
        direction: "row"
      },
      textInputStyle: {
        ...h2,
        color: primaryColor,
        height: 24,
        paddingTop: 6
      },
      extensionStyle: {
        viewStyle: {
          marginLeft: 8,
          paddingLeft: 14,
          paddingRight: 14,
          borderRadius: 4,
          backgroundColor: lightPink,
          height: 24,
          justifyContent: "center"
        },
        textStyle: {
          ...h2,
          color: primaryColor
        }
      }
    },
    errorStyle: {
      containerStyle: {
        direction: "row",
        viewStyle: {
          paddingTop: 12
        }
      },
      errorMessageStyle: {
        viewStyle: {
          flex: 1,
          paddingLeft: 8
        },
        textStyle: {
          ...h10,
          color: tomato,
          numberOfLines: 2
        }
      }
    },
    footerStyle: {
      containerStyle: {
        viewStyle: {
          paddingBottom: 8,
          justifyContent: "flex-end"
        },
        direction: "row"
      },
      saveActionStyle: {
        viewStyle: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24,
          cursor: "pointer"
        },
        textStyle: {
          ...h2_18,
          color: themeColor
        }
      },
      cancelActionStyle: {
        viewStyle: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 16,
          paddingRight: 16,
          cursor: "pointer"
        },
        textStyle: {
          ...h2_18,
          color: brownGrey
        }
      }
    }
  };

  const extension = ".gif";
  const maxLength = 55;
  const minLength = 3;

  class ComponentSM extends React.Component {
    onSaveClick = () => {
      this._inputRef && this._inputRef.blur && this._inputRef.blur();
      this.props.onSaveClick && this.props.onSaveClick();
    };
    render() {
      let { name, error, loading, onChangeName, onClose } = this.props;
      const { containerStyle, inputStyle, errorStyle, footerStyle, headerStyle, inputHeaderStyle } = componentThemeSM;
      let { headerTitle } = messages;
      const TextInput = getInput("text");

      return (
        <View>
          {loading && <LoadingIndicator />}
          <Box
            {...containerStyle}
            render={[
              {
                ...headerStyle,
                text: headerTitle
              },
              {
                ...inputHeaderStyle,
                text: () => I18N.t("inputTitle")
              },
              {
                ...inputStyle.containerStyle,
                render: [
                  {
                    width: "1fr",
                    render: (
                      <TextInput
                        getRef={_ => (this._inputRef = _)}
                        value={name}
                        onChangeValue={onChangeName}
                        style={{ ...inputStyle.textInputStyle }}
                        autoFocus
                        onSubmitEditing={this.onSaveClick}
                        maxLength={maxLength}
                      />
                    )
                  },
                  {
                    ...inputStyle.extensionStyle,
                    text: extension
                  }
                ]
              },
              error && {
                ...errorStyle.containerStyle,
                render: [
                  {
                    image: getImage("errorIcon")
                  },
                  {
                    ...errorStyle.errorMessageStyle,
                    text: error
                  }
                ]
              },
              {
                viewStyle: {
                  marginTop: 10
                },
                textStyle: { color: "grey", fontSize: 12 },
                text: () => I18N.t("saveGifIgnoreMessage")
              },
              {
                ...footerStyle.containerStyle,
                render: [
                  {
                    ...footerStyle.cancelActionStyle,
                    onClick: onClose,
                    text: () => I18N.t("cancel")
                  },
                  {
                    ...footerStyle.saveActionStyle,
                    text: () => I18N.t("save"),
                    onClick: this.onSaveClick
                  }
                ]
              }
            ]}
          />
        </View>
      );
    }
  }

  class ComponentMD extends React.Component {
    render() {
      let { name, error, loading, onSaveClick, onChangeName, onClose } = this.props;
      const { containerStyle, inputStyle, footerStyle } = componentThemeMD;
      let { headerTitle } = messages;
      const TextInput = getInput("text");

      return (
        <View>
          {loading && <LoadingIndicator />}
          <Box
            {...containerStyle}
            render={[
              <Header title={headerTitle} onClose={onClose} />,
              {
                direction: "row",
                ...inputStyle.parentContainerStyle,
                render: [
                  {
                    ...inputStyle.containerStyle,
                    width: "1fr",
                    render: (
                      <TextInput
                        value={name}
                        onChangeValue={onChangeName}
                        style={{ ...inputStyle.textInputStyle }}
                        autoFocus
                        onSubmitEditing={onSaveClick}
                        maxLength={maxLength}
                      />
                    )
                  },
                  {
                    ...inputStyle.extensionStyle,
                    text: extension
                  }
                ]
              },
              {
                viewStyle: {
                  paddingTop: 15,
                  marginLeft: 35,
                  marginRight: 30
                },
                textStyle: { color: "grey", fontSize: 14 },
                text: () => I18N.t("saveGifIgnoreMessage")
              },
              {
                ...footerStyle.containerStyle,
                render: [
                  {
                    ...footerStyle.errorStyle,
                    text: error || ""
                  },
                  {
                    ...footerStyle.saveActionStyle,
                    text: () => I18N.t("save"),
                    onClick: onSaveClick
                  }
                ]
              }
            ]}
          />
        </View>
      );
    }
  }

  class SaveGIFName extends React.Component {
    state = { error: void 0, loading: false, name: `GIF_${new Date().getTime()}` };

    componentWillUnmount() {
      this._unmounted = true;
    }

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    onChangeName = name => {
      let newState = { name };
      if (!this.state.loading) {
        if (this.state.error) {
          newState["error"] = void 0;
        }
        this._setState(newState);
      }
    };

    onClose = () => {
      let { onClose, unSelectAll } = this.props;
      this._setState({ loading: false });
      unSelectAll && unSelectAll();
      onClose && onClose();
    };

    onSaveClick = async () => {
      let { getSelectedData, urls, invoke } = this.props;
      let { name } = this.state;
      let { mandatoryError, nameLengthError } = messages;

      name = name && name.trim();
      if (!name || name.length === 0) {
        this._setState({ error: mandatoryError });
        return;
      }
      if ((name.length < minLength || name.length > maxLength) && nameLengthError) {
        this._setState({ error: nameLengthError });
        return;
      }
      this._setState({ loading: true });
      name = name + (extension || "");
      let saveMethod = saveAsGif;
      await saveMethod({ props: { invoke, urls, name, getSelectedData, closeModal: this.onClose } });
    };

    render() {
      let { activeMQ } = this.props;
      const { Component } = resolveMQ({ ComponentSM, ComponentMD }, ["Component"], activeMQ);
      return (
        <Component
          {...this.state}
          onChangeName={this.onChangeName}
          onSaveClick={this.onSaveClick}
          onClose={this.onClose}
        />
      );
    }
  }
  return SaveGIFName;
};
