export default () => {
  const defaultTextProps = {
    type: "text"
  };

  const defaultFileProps = {
    type: "file"
  };

  const defaultNumberProps = {
    type: "number"
  };

  const defaultPasswordProps = {
    type: "password"
  };

  return { defaultTextProps, defaultFileProps, defaultNumberProps, defaultPasswordProps };
};
