import React from "react";

export default ({
  CheckedComponent,
  UncheckedComponent,
  Component,
  uncheckedProps: hocUncheckedProps,
  checkedProps: hocCheckedProps
}) => {
  class SelectionBox extends React.Component {
    onClick = () => {
      let { selectRow, data } = this.props;
      selectRow(data);
    };

    render() {
      let { selected, highlighted, data, checkedProps, uncheckedProps, selectedIcon } = this.props;
      const propsToPass = selected
        ? { ...hocCheckedProps, ...checkedProps }
        : { ...hocUncheckedProps, ...uncheckedProps };
      let SelectedComponent = Component || (selected ? CheckedComponent : UncheckedComponent);
      return (
        <SelectedComponent
          data={data}
          selected={selected}
          onClick={this.onClick}
          {...propsToPass}
          selectedIcon={selectedIcon}
          highlighted={highlighted}
        />
      );
    }
  }
  return SelectionBox;
};
