import React from "react";
import {
  ConfirmBox,
  I18N,
  StatusBar as LoadingIndicator,
  withContext,
  Modal,
  resolveMQ,
  showMessage
} from "../../../FsCloudComponent";

const componentTheme = {
  modalPropsSM: {
    width: 0.9,
    animationType: "none",
    stopPropagation: true,
    position: "center",
    style: {
      borderRadius: 4,
      overflow: "hidden"
    },
    parentStyle: {
      zIndex: 100
    }
  },
  modalPropsMD: {
    stopPropagation: true,
    position: "center",
    style: {
      borderRadius: 4
    },
    escEnabled: false,
    parentStyle: {
      zIndex: 100
    }
  },
  headerTitleMD: "joinCaps"
};

class JoinCollectionPopup extends React.Component {
  state = {};

  onButtonClick = async () => {
    let { sharedToken, invoke, urls, origin, onClose } = this.props;
    try {
      const indexOfUnderScores = sharedToken.indexOf("__");
      if (indexOfUnderScores >= 0) {
        sharedToken = sharedToken.substring(0, indexOfUnderScores);
      }
      this.setState({ loading: true });
      await invoke({
        service: {
          url: urls["bePartOfCollection"],
          uriProps: {
            data: { token: sharedToken }
          }
        }
      });
      this.setState({ loading: false });
    } catch (err) {
      if (err) {
        let message = err.code ? I18N.t(err.code.toString()) : void 0;
        if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
          message = err.message;
        }
        if (message && origin) {
          let type = I18N.t(origin) ? I18N.t(origin).toLowerCase() : origin;
          message = message.replace("__type__", type);
          showMessage && showMessage(message, 2000);
        }
      }
    }
    onClose && onClose();
  };

  render() {
    let { onClose, origin, sharedToken, activeMQ } = this.props;
    if (!sharedToken) {
      return null;
    }
    const { modalProps, headerTitle } = resolveMQ(componentTheme, ["modalProps", "headerTitle"], activeMQ);
    let collectionName = void 0;
    const indexOfUnderScores = sharedToken.indexOf("__");
    if (indexOfUnderScores >= 0) {
      collectionName = sharedToken.substring(indexOfUnderScores + 2);
      collectionName = decodeURIComponent && decodeURIComponent(collectionName);
      sharedToken = sharedToken.substring(0, indexOfUnderScores);
    }
    let message = I18N.t("joinCollectionMessage");
    let type = I18N.t(origin) ? I18N.t(origin).toLowerCase() : "";
    message = message.replace("__type__", type);
    message = message.replace("__name__", `${collectionName ? `"${collectionName}"` : ""}`);
    return (
      <Modal isOpen={true} {...modalProps} onClose={onClose}>
        {this.state.loading && <LoadingIndicator />}
        <ConfirmBox
          title={headerTitle ? I18N.t(headerTitle) : void 0}
          message={message}
          confirmText={I18N.t("join")}
          onConfirm={this.onButtonClick}
          onCancel={onClose}
        />
      </Modal>
    );
  }
}

JoinCollectionPopup = withContext(JoinCollectionPopup, {
  invoke: "App.invoke",
  urls: "App.urls",
  activeMQ: "ActiveMQ"
});

export default JoinCollectionPopup;
