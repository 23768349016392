import { I18N } from "../../../FsCloudComponent";

export default {
  SupportTab: {
    component: "tab",
    titleSM: () => I18N.t("support"),
    titleMD: () => I18N.t("support"),
    showTitleInTabSM: true,
    tabs: {
      open: {
        tab: {
          text: () => I18N.t("open")
        },
        screen: "OpenSupportIssues"
      },
      in_Progress: {
        tab: {
          text: () => I18N.t("in_progress")
        },
        screen: "InProgressSupportIssues"
      },
      resolved: {
        tab: {
          text: () => I18N.t("resolved")
        },
        screen: "ResolvedSupportIssues"
      },
      fs: {
        tab: {
          text: "FS"
        },
        screen: "FsSupportIssues"
      },
      server: {
        tab: {
          text: () => I18N.t("server")
        },
        screen: "ServerSupportIssues"
      },
      mobile: {
        tab: {
          text: () => I18N.t("mobile")
        },
        screen: "MobileSupportIssues"
      }
    }
  }
};
