import React from "react";
import { Box, View, getImage, RowAction, theme, ScrollView, I18N, Platform } from "../../../FsCloudComponent";
import AudioPlayer from "./DetailAudioPlayer";
import { getSizeFormat } from "client-utility/lib/FileUtility";
import { getTimeStringFromSeconds } from "client-utility/lib/DateUtility";
import ResourceActions from "../resource/ResourceActions";
import { isResourceUploading } from "../resource/ResourceUtility";
import { SECURE_TYPES } from "../../../Constants";

const { colors, fonts, shadows } = theme;
const { themeColor, brownGrey, primaryColor, highlightColor } = colors;
const { h16, h1_16, h10, h2_16 } = fonts;

const renderHeaderStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      alignItems: "center",
      height: 60,
      paddingRight: 8,
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowSM
    },
    textStyle: {
      ...h16,
      color: themeColor,
      numberOfLines: 1
    }
  },
  actionStyle: {
    viewStyle: {
      width: 44,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const renderHeader = ({ onClose, value: data = {}, origin, fromArtist }) => {
  let { secureType } = data;
  let isArchive = secureType === SECURE_TYPES.ARCHIVE;
  let isUploading = isResourceUploading(data);
  let { actionStyle, containerStyle } = renderHeaderStyle;
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backIcon"),
      onClick: onClose
    },
    {
      width: "1fr",
      text: () => I18N.t("info"),
      ...actionStyle,
      viewStyle: {
        ...actionStyle.viewStyle,
        alignItems: void 0
      }
    },
    data && !data.favourite && !isUploading && (
      <RowAction data={data} action={ResourceActions.markFavourite} image={"favouriteGreyIcon"} />
    ),
    data && data.favourite && !isUploading && (
      <RowAction data={data} action={ResourceActions.unmarkFavourite} image={"favouriteGreySelectedIcon"} />
    ),
    {
      render: (
        <RowAction
          data={data}
          action={[
            { ...ResourceActions.addMusicToPlaylistLink, visible: () => !isUploading && !isArchive },
            !fromArtist &&
              data.collection &&
              data.collection._id && { ...ResourceActions.removeFromPlaylist, visible: true },
            { ...ResourceActions.download, visible: () => !isUploading && Platform.OS !== "ios" },
            {
              ...ResourceActions.moveToArchive,
              visible: () => !isUploading && !isArchive
            },
            {
              ...ResourceActions.removeFromArchive,
              visible: () => !isUploading && isArchive
            },
            { ...ResourceActions.markDelete, visible: !isUploading && !isArchive },
            { ...ResourceActions.infoMobile, origin }
          ]}
        >
          <Box {...actionStyle} image={getImage("iconMenuGrey")} />
        </RowAction>
      )
    }
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

class MusicDetailComponent extends React.Component {
  render() {
    let { data: allData = [], origin, detailId, onClose, fromArtist } = this.props;
    let data = allData.find(doc => doc._id === detailId) || {};
    return (
      <View style={{ flex: 1 }}>
        {renderHeader({ value: data, origin, onClose, fromArtist })}
        {allData.length ? (
          <Box
            style={{ flex: 1 }}
            render={[
              {
                viewStyle: { padding: 8, flex: 1 },
                render: [
                  {
                    direction: "row",
                    viewStyle: { alignItems: "center", padding: 8 },
                    render: [
                      { viewStyle: { paddingRight: 16 }, image: getImage("audioIcon") },
                      {
                        width: "1fr",
                        textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                        text: data.resource_title
                      }
                    ]
                  },
                  {
                    viewStyle: { paddingTop: 6, paddingBottom: 6, flex: 1 },
                    render: (
                      <ScrollView style={{ flex: 1 }}>
                        <Box
                          itemStyle={{ paddingTop: 5, paddingBottom: 5 }}
                          render={[
                            {
                              direction: "row",
                              itemStyle: { paddingLeft: 8, paddingRight: 8 },
                              render: [
                                {
                                  width: "1fr",
                                  itemStyle: { paddingTop: 3, paddingBottom: 3 },
                                  render: [
                                    { textStyle: { ...h2_16, color: primaryColor }, text: () => I18N.t("artist") },
                                    {
                                      textStyle: { ...h10, color: brownGrey },
                                      text:
                                        data.resource_artist === "unknown" ? I18N.t("Unknown") : data.resource_artist
                                    }
                                  ]
                                },
                                {
                                  width: "1fr",
                                  itemStyle: { paddingTop: 3, paddingBottom: 3 },
                                  render: [
                                    { textStyle: { ...h2_16, color: primaryColor }, text: () => I18N.t("album") },
                                    {
                                      textStyle: { ...h10, color: brownGrey },
                                      text: data.resource_album === "unknown" ? I18N.t("Unknown") : data.resource_album
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              direction: "row",
                              itemStyle: { paddingLeft: 8, paddingRight: 8 },
                              render: [
                                {
                                  width: "1fr",
                                  itemStyle: { paddingTop: 3, paddingBottom: 3 },
                                  render: [
                                    { textStyle: { ...h2_16, color: primaryColor }, text: () => I18N.t("duration") },
                                    {
                                      textStyle: { ...h10, color: brownGrey },
                                      text:
                                        data.resource_duration &&
                                        getTimeStringFromSeconds(data.resource_duration / 1000)
                                    }
                                  ]
                                },
                                {
                                  width: "1fr",
                                  itemStyle: { paddingTop: 3, paddingBottom: 3 },
                                  render: [
                                    { textStyle: { ...h2_16, color: primaryColor }, text: () => I18N.t("genre") },
                                    {
                                      textStyle: { ...h10, color: brownGrey },
                                      text: data.resource_genre === "unknown" ? I18N.t("Unknown") : data.resource_genre
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              direction: "row",
                              itemStyle: { paddingLeft: 8, paddingRight: 8 },
                              render: [
                                {
                                  width: "1fr",
                                  itemStyle: { paddingTop: 3, paddingBottom: 3 },
                                  render: [
                                    { textStyle: { ...h2_16, color: primaryColor }, text: () => I18N.t("file") },
                                    { textStyle: { ...h10, color: brownGrey }, text: data.name }
                                  ]
                                },
                                {
                                  width: "1fr",
                                  itemStyle: { paddingTop: 3, paddingBottom: 3 },
                                  render: [
                                    { textStyle: { ...h2_16, color: primaryColor }, text: () => I18N.t("size") },
                                    {
                                      textStyle: { ...h10, color: brownGrey },
                                      text: data.resource_size && getSizeFormat(data.resource_size)
                                    }
                                  ]
                                }
                              ]
                            }
                          ]}
                        />
                      </ScrollView>
                    )
                  }
                ]
              },
              <AudioPlayer
                data={allData}
                currentId={detailId}
                onChangeSong={id => {
                  const { replaceUri, link } = this.props;
                  replaceUri &&
                    replaceUri({
                      ...link,
                      detailId: id
                    });
                }}
              />
            ]}
          />
        ) : (
          void 0
        )}
      </View>
    );
  }
}

export default MusicDetailComponent;
