import { I18N, logFirebaseAnalyticsEvent } from "../../../FsCloudComponent";

const AddGroupMemberForm = {
  component: "addGroupMember",
  modalProps: {
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};

const isGroupOwner = user => {
  return user && user.group && user.group.is_group_owner;
};

const actions = {
  editProfile: {
    type: "link",
    text: () => I18N.t("editMyProfile"),
    visible: (data, { user }) => {
      if (user && user.uid && data && data.member && data.member.firebaseUid === user.uid) {
        return true;
      }
    },
    link: { uri: "/profile#profile" }
  },
  removeMember: {
    type: "invoke",
    text: () => I18N.t("removeMember"),
    postMessage: () => I18N.t("removeMemberMessage"),
    visible: (data, { user }) => isGroupOwner(user) && !data.owner && data.accepted,
    confirm: {
      titleMD: () => I18N.t("removeMemberCaps"),
      message: ({ data }) => {
        let translateMsg = I18N.t("removeMemberConfirmMessage");
        translateMsg = translateMsg.replace("_Member_", `${data.email}`);
        return translateMsg;
      },
      confirmText: () => I18N.t("remove"),
      cancelText: () => I18N.t("cancel")
    },
    service: (props, { urls }) => {
      let { data } = props || {};
      let commandToPost;
      commandToPost = {
        url: urls["removeGroupMember"],
        uriProps: {
          data: {
            _id: data && data._id
          }
        }
      };
      return commandToPost;
    }
  },
  resendInvite: {
    type: "invoke",
    text: () => I18N.t("resendInvite"),
    postMessage: () => I18N.t("invitationResendMessage"),
    visible: (data, { user }) => isGroupOwner(user) && !data.accepted,
    service: (props, { urls }) => {
      let { data, user } = props || {};
      let email = data && data.email;
      let commandToPost;
      commandToPost = {
        url: urls["addGroupMembers"],
        uriProps: {
          data: {
            _id: user && user.group && user.group._id,
            members: [email]
          }
        }
      };
      return commandToPost;
    }
  },
  cancelInvite: {
    type: "invoke",
    text: () => I18N.t("cancelInvite"),
    postMessage: () => I18N.t("invitationCancelMessage"),
    visible: (data, { user }) => isGroupOwner(user) && !data.accepted,
    service: (props, { urls }) => {
      let { data } = props || {};
      let commandToPost;
      commandToPost = {
        url: urls["removeInvitationMember"],
        uriProps: {
          data: {
            _id: data && data._id
          }
        }
      };
      return commandToPost;
    }
  },
  removeGroup: {
    type: "invoke",
    text: () => I18N.t("removeGroup"),
    title: () => I18N.t("removeGroup"),
    visible: (data, { user }) => isGroupOwner(user),
    confirm: {
      titleMD: () => I18N.t("removeGroupCaps"),
      message: () => I18N.t("removeGroupMessage"),
      confirmText: () => I18N.t("remove"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("groupRemoveSuccessfully"),
    service: (props, { urls }) => {
      let { user } = props || {};
      logFirebaseAnalyticsEvent({ event: "group_removed" });
      let commandToPost;
      commandToPost = {
        url: urls["removeGroup"],
        uriProps: {
          data: {
            _id: user && user.group && user.group._id
          }
        }
      };
      return commandToPost;
    },
    afterInvoke: (result, { updateUserInfo }) => {
      updateUserInfo &&
        updateUserInfo({
          group: void 0
        });
    }
  },
  leaveGroup: {
    type: "invoke",
    text: () => I18N.t("leaveGroup"),
    title: () => I18N.t("leaveGroup"),
    visible: (data, { user }) => !isGroupOwner(user),
    confirm: {
      titleMD: () => I18N.t("leaveGroupCaps"),
      message: () => I18N.t("leaveGroupMessage"),
      confirmText: () => I18N.t("leave"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("groupLeaveSuccessfully"),
    service: (props, { urls }) => {
      let { user } = props || {};
      let commandToPost;
      commandToPost = {
        url: urls["leftGroupMember"],
        uriProps: {
          data: {
            group: { _id: user && user.group && user.group._id }
          }
        }
      };
      return commandToPost;
    },
    afterInvoke: (result, { updateUserInfo }) => {
      updateUserInfo &&
        updateUserInfo({
          group: void 0
        });
    }
  },
  addMemberWeb: {
    text: () => I18N.t("addMembers"),
    title: () => I18N.t("addMembers"),
    visible: (data, { user }) => isGroupOwner(user),
    form: AddGroupMemberForm
  }
};

export default actions;
