import React from "react";

export default ({ Box, resolveMQ, FileDownloadLink, theme: themeHoc }) => {
  class FileRenderBox extends React.Component {
    render() {
      let { value, onRemove, theme, editable } = this.props;
      theme = theme || themeHoc || {};
      const { boxStyle, documentImageStyle, downloadLinkContainerStyle, crossStyle } = resolveMQ(
        theme,
        ["boxStyle", "documentImageStyle", "downloadLinkContainerStyle", "crossStyle"],
        "activeMQ"
      );
      return (
        <Box
          {...boxStyle}
          render={[
            { ...documentImageStyle },
            FileDownloadLink && {
              ...downloadLinkContainerStyle,
              render: <FileDownloadLink value={value} />
            },
            editable !== false && { ...crossStyle, onClick: onRemove }
          ]}
        />
      );
    }
  }
  return FileRenderBox;
};
