export default {
  "/album-resources": {
    screen: "AlbumResources"
  },
  "/album-resource-detail": {
    screen: "AlbumResourceDetail"
  },
  "/album-resource-new": {
    screen: "AlbumResourceDetail",
    props: {
      dataMode: "insert",
      viewTitle: "Upload"
    }
  }
};
