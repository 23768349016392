import React from "react";

const filterHeaderActions = (headerActions, { editable }) => {
  if (editable === false && headerActions) {
    headerActions = headerActions.filter(headerAction => {
      if (typeof headerAction === "string") {
        headerAction = { type: headerAction };
      }
      if (headerAction.type === "save") {
        return false;
      } else {
        return true;
      }
    });
  }
  return headerActions;
};

export default ({
  dataObjects = {},
  views = {},
  getUri,
  resolveHeaderActions,
  resolveActionLayout,
  ConnectPanelTable,
  Form,
  resolveLinkDefaults
}) => {
  const loader = ({
    user,
    screenDef,
    routeScreenProps: { dataMode, viewTitle } = {},
    state,
    link,
    panelProps = {},
    panelActionsRequired,
    activeMQ,
    mergeHeaderActions,
    route
  }) => {
    let { headerActions, title, navTitle, expanded, tabType = "detailTab" } = screenDef;
    title = viewTitle || title;

    let {
      dataObject,
      fetch,
      updatable,
      updateProps,
      fields,
      actions,
      linkFilter,
      linkDefaults,
      connectProps: screenConnectProps,
      notifyOnChange,
      dataMode: screenDataMode,
      editable,
      panelProps: screenPanelProps,
      ...restScreenDef
    } = screenDef;
    if (dataObject) {
      const schema = dataObjects[dataObject];
      fields = fields || schema.fields;
      actions = actions || schema.actions;
      linkDefaults = linkDefaults || schema.linkDefaults;
      linkFilter = linkFilter || schema.linkFilter;
      updateProps = updateProps || schema.updateProps;
    }

    if (link.props && link.props.editable === false) {
      editable = false;
    }
    if (editable && typeof editable === "function") {
      editable = editable(void 0, {
        link,
        user
      });
    }
    const formProps = { ...restScreenDef, tabType, editable, fields, actions };

    const uri = getUri({ dataObject, views, fetch, link, linkFilter });
    const defaultValues = resolveLinkDefaults({ link, linkDefaults });
    if (notifyOnChange === undefined && dataObject) {
      notifyOnChange = [dataObject];
    }
    dataMode = dataMode || screenDataMode;
    const connectProps = {
      uri,
      updatable,
      updateProps,
      dataMode,
      defaultValues,
      notifyOnChange,
      ...screenConnectProps
    };
    let linkTitle = link && link.props && link.props.title;
    const screenProps = { screenState: state, connect: connectProps, title: linkTitle || navTitle || title, expanded };
    if (mergeHeaderActions) {
      headerActions = mergeHeaderActions(headerActions);
    }
    headerActions = filterHeaderActions(headerActions, { editable });
    headerActions = resolveHeaderActions({
      headerActions,
      actions,
      fields
    });
    let { panelFooter, panelHeader, navHeader, navFooter, navLeft } = resolveActionLayout({
      actions: headerActions,
      activeMQ
    });

    panelProps = { ...panelProps, ...screenPanelProps, title };
    if (restScreenDef.tabs) {
      panelProps.showHeader = false;
      formProps.tabActions = panelHeader;
    }

    if (panelFooter) {
      panelProps.footerActions = panelFooter;
    }
    if (panelHeader) {
      if (panelActionsRequired) {
        screenProps.panelHeaderActions = panelHeader;
      } else {
        panelProps.headerActions = panelHeader;
      }
    }
    if (navLeft) {
      screenProps.navLeftActions = navLeft;
    }
    if (navFooter) {
      screenProps.navFooterActions = navFooter;
    }
    if (navHeader) {
      screenProps.navHeaderActions = navHeader;
    }
    screenProps.component = (
      <ConnectPanelTable
        componentType="form"
        connectProps={connectProps}
        panelProps={panelProps}
        Component={Form}
        componentProps={formProps}
        route={route}
      />
    );

    return screenProps;
  };
  return {
    loader
  };
};
