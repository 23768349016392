import React from "react";
import AppContext from "./AppContext";

export default ({
  View,
  addListeners,
  removeListeners,
  ActiveMQ,
  App,
  onBackPress,
  setLanguage: setLanguageHoc,
  getLanguage: getLanguageHoc,
  Orientation,
  onWebBackPress,
  Platform,
  downloadListeners,
  clearMemoryData,
  unsubscribeAll
}) => {
  class AppStore extends React.Component {
    listeners = {};
    backHandlerListeners = [];

    setOrientation = (orientation, skipWidthCalculation) => {
      // if (orientation) {
      //   this.orientation = orientation;
      // }
      // if (!skipWidthCalculation) {
      //   this.setActiveWidth();
      // }
      // if (getStatusBarHeight && orientation === "LANDSCAPE") {
      //   getStatusBarHeight().then(statusBarHeight => {
      //     if (statusBarHeight) {
      //       // this.width = this.width - statusBarHeight;
      //     }
      //     this.setState({});
      //   });
      // } else {
      //   this.setState({});
      // }
    };

    setActiveWidth = () => {
      ActiveMQ.setWidth(this.width);
    };

    constructor(props) {
      super(props);
      this.setActiveWidth();
      if (Orientation) {
        Orientation.getOrientation((err, orientation) => {
          this.setOrientation(orientation, true);
        });
      }
      addListeners &&
        addListeners(
          {
            resize: orientation => {
              this.setOrientation(orientation);
            },
            backPress: this.handleBackPress
          },
          {
            Orientation
          }
        );
      setLanguageHoc && setLanguageHoc(this.getSelectedLanguage());
    }

    getSelectedLanguage = () => {
      let pstate = this._getPState();
      return pstate && pstate.language;
    };

    componentDidMount() {
      const { addListeners: addDownloadListeners } = downloadListeners || {};
      addDownloadListeners && addDownloadListeners({ user: this.props.user });
    }

    componentWillUnmount() {
      const { removeListeners: removeDownloadListeners } = downloadListeners || {};
      removeListeners &&
        removeListeners(
          {
            resize: () => {},
            backPress: this.handleBackPress
          },
          {
            Orientation
          }
        );
      removeDownloadListeners && removeDownloadListeners();
      unsubscribeAll && unsubscribeAll();
      clearMemoryData && clearMemoryData();
    }

    handleBackPress = callback => {
      if (Platform && Platform.OS === "web") {
        // on web history back, we just need to reload
        let _onWebBackPress = this.props.onWebBackPress || onWebBackPress;
        let invokeSetState = _onWebBackPress && _onWebBackPress({ user: this.props.user });

        if (invokeSetState) {
          this._setState({});
        }
      } else {
        let listenerCount = this.backHandlerListeners.length;
        if (listenerCount > 1) {
          this.backHandlerListeners[listenerCount - 1]();
          return true;
        } else if (listenerCount === 1 && onBackPress) {
          return onBackPress(callback);
        }
      }
      return false;
    };

    addBackHandlerListener = callback => {
      this.backHandlerListeners.push(callback);
    };

    removeBackHandlerListener = callback => {
      const index = this.backHandlerListeners.indexOf(callback);
      if (index >= 0) {
        this.backHandlerListeners.splice(index, 1);
      }
    };

    addListener = (key, callback) => {
      if (!Array.isArray(key)) {
        key = [key];
      }

      key.forEach(prop => {
        let propKey = prop;
        let propCallback = callback;

        if (prop && prop.key && prop.callback) {
          propKey = prop.key;
          propCallback = prop.callback;
        }
        this.listeners[propKey] = this.listeners[propKey] || [];
        this.listeners[propKey].push(propCallback);
      });
    };

    removeListener = (key, callback) => {
      if (!Array.isArray(key)) {
        key = [key];
      }

      key.forEach(prop => {
        let propKey = prop;
        let propCallback = callback;

        if (prop && prop.key && prop.callback) {
          propKey = prop.key;
          propCallback = prop.callback;
        }

        this.listeners[propKey] = this.listeners[propKey] || [];
        const index = this.listeners[propKey].indexOf(propCallback);
        if (index >= 0) {
          this.listeners[propKey].splice(index, 1);
        }
      });
    };

    notifyListner = (key, value) => {
      if (!Array.isArray(key)) {
        key = [key];
      }
      key.forEach(prop => {
        this.listeners[prop] && this.listeners[prop].forEach(listener => listener(prop, value));
      });
    };

    _getState = () => {
      return this.props.state;
    };

    _getPState = () => {
      const state = this._getState();
      return state.pstate;
    };

    _setState = state => {
      this.props.setState(state);
    };

    setLanguage = language => {
      setLanguageHoc && setLanguageHoc(language);
      let pstate = this._getPState();
      pstate.language = language;
      this._setState({
        pstate
      });
    };

    getLanguage = () => {
      return getLanguageHoc && getLanguageHoc();
    };

    setParams = params => {
      this._setState(params);
    };

    render() {
      let { children } = this.props;
      let width = this.width || 0;
      let height = this.height || 0;
      let state = this._getState();
      for (let key in App) {
        state[key] = App[key];
      }
      state.screenWidth = width;
      state.screenHeight = height;
      state.orientation = this.orientation;
      state.setOrientation = this.setOrientation;
      state.setParams = this.setParams;
      state.addListener = this.addListener;
      state.removeListener = this.removeListener;
      state.notifyListner = this.notifyListner;
      state.addBackHandlerListener = this.addBackHandlerListener;
      state.removeBackHandlerListener = this.removeBackHandlerListener;
      state.setLanguage = this.setLanguage;
      state.getLanguage = this.getLanguage;

      return (
        <View
          style={{ flex: 1 }}
          onLayout={({
            nativeEvent: {
              layout: { height, width }
            }
          }) => {
            if (this.height !== height || this.width !== width) {
              this.height = height;
              this.width = width;
              this.setActiveWidth();
              this.setState({});
            }
          }}
        >
          {this.height && this.width && (
            <AppContext.Provider value={state}>
              <ActiveMQ.Component width={width}>{children}</ActiveMQ.Component>
            </AppContext.Provider>
          )}
        </View>
      );
    }
  }
  return AppStore;
};
