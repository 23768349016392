import React from "react";

export default ({ renderChildren }) => {
  class NavBodyItem extends React.Component {
    onBackPress = () => {
      let { deleteUri, url, link, isPendingBlocker } = this.props;
      if (isPendingBlocker && isPendingBlocker()) {
        return;
      }
      deleteUri && deleteUri(url, link);
    };

    componentDidMount() {
      let { addBackHandlerListener } = this.props;
      addBackHandlerListener && addBackHandlerListener(this.onBackPress);
    }

    componentWillUnmount() {
      let { removeBackHandlerListener } = this.props;
      removeBackHandlerListener && removeBackHandlerListener(this.onBackPress);
    }

    render() {
      let { children } = this.props;
      return renderChildren(children);
    }
  }
  return NavBodyItem;
};
