import React from "react";
import {
  StatusBar as LoadingIndicator,
  withContext,
  setAfterLoginLink,
  getIntroductionLink,
  setJoinInfo
} from "../../../FsCloudComponent";

class JoinGroup extends React.Component {
  constructor(props) {
    super(props);
    const { user, activeMQ, replaceUri, hashMap: { sharedToken } = {} } = props;
    let link = {
      uri: "/home#group",
      nestedTab: "groupGallery"
    };
    setJoinInfo && setJoinInfo({ sharedToken, origin: "joinGroup" });
    let introductionLink = getIntroductionLink && getIntroductionLink({ user, activeMQ });
    if (introductionLink || !user) {
      setAfterLoginLink(link);
      replaceUri(introductionLink || { uri: "/landing" });
    } else {
      replaceUri(link);
    }
  }

  render() {
    return <LoadingIndicator />;
  }
}

JoinGroup = withContext(JoinGroup, {
  activeMQ: "ActiveMQ",
  user: "User.user",
  replaceUri: "Router.replaceUri"
});

export default {
  JoinGroup: {
    Component: JoinGroup,
    component: "custom",
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true
  }
};
