import React from "react";

export default ({ theme, Box, getImage, Swiper, AppConfig, I18N, getComponent, getInput }) => {
  const { walkthroughStyle = {}, walkthroughImageStyle = {} } = theme || {};
  const { APP_REGISTRATION_SOURCE } = AppConfig;

  const walkthroughInfo = [
    {
      primaryText: "firstWalkthroughPrimaryText",
      secondaryText: "firstWalkthroughSecondaryText",
      image: "firstOnboarding"
    },
    {
      primaryText: "secondWalkthroughPrimaryText",
      secondaryText: "secondWalkthroughSecondaryText",
      image: "secondOnboarding"
    },
    {
      primaryText: "thirdWalkthroughPrimaryText",
      secondaryText: "thirdWalkthroughSecondaryText",
      image: "thirdOnboarding"
    },
    {
      primaryText: "fourthWalkthroughPrimaryText",
      secondaryText: "fourthWalkthroughSecondaryText",
      image: "fourthOnboarding"
    }
  ];

  class ImageRender extends React.Component {
    render() {
      let { value } = this.props;
      let { image, primaryText, secondaryText } = value;
      let {
        boxStyle,
        imageConatinerStyle,
        primaryStyle,
        secondaryStyle,
        descriptionContainerStyle
      } = walkthroughImageStyle;
      primaryText = I18N.t(primaryText);
      if (!primaryText || (primaryText.indexOf("missing") !== -1 && primaryText.indexOf("translation") !== -1)) {
        primaryText = void 0;
      }
      secondaryText = I18N.t(secondaryText);
      if (!secondaryText || (secondaryText.indexOf("missing") !== -1 && secondaryText.indexOf("translation") !== -1)) {
        secondaryText = void 0;
      }

      return (
        <Box
          {...boxStyle}
          render={[
            {
              image: getImage(image),
              ...imageConatinerStyle
            },
            {
              ...descriptionContainerStyle,
              render: [
                primaryText && {
                  ...primaryStyle,
                  text: primaryText
                },
                secondaryText && {
                  ...secondaryStyle,
                  text: secondaryText
                }
              ]
            }
          ]}
        />
      );
    }
  }

  class Walkthrough extends React.Component {
    state = {
      index: 0
    };

    handleCheckboxChange = termsAccepted => this.setState({ termsAccepted, errMsg: void 0 });

    renderPagination = (index, total) => {
      let dots = [];
      for (let i = 0; i < total; i++) {
        dots.push(
          <Box
            key={i}
            viewStyle={{
              ...walkthroughStyle.dotStyle,
              ...((i === index && walkthroughStyle.activeDotStyle) || {})
            }}
          />
        );
      }
      return dots;
    };

    onClick = () => {
      let { index } = this.state;
      this.setState({ index: index + 1 });
    };

    renderFooter = () => {
      let { index } = this.state;
      let { addUri, getPath } = this.props;
      const MultipleText = getComponent("multipleText");
      const Checked = getInput("boolean");
      let actionprops = void 0;
      if (APP_REGISTRATION_SOURCE === "landline") {
        actionprops = { link: { uri: "/login-option" } };
      } else {
        actionprops = {
          link: {
            uri: "/login",
            props: {
              params: {
                registrationRequired: APP_REGISTRATION_SOURCE === "mobile"
              }
            }
          }
        };
      }
      let total = walkthroughInfo.length;
      let { paginationStyle, loginButtonStyle } = walkthroughStyle;
      return [
        { ...paginationStyle, render: this.renderPagination(index, total) },
        {
          ...loginButtonStyle,
          text: I18N.t("walkthroughButtonText"),
          onClick: () => {
            if (!this.state.termsAccepted) {
              this.setState({ errMsg: I18N.t("acceptTermsErrorMsg") });
            } else {
              this.setState({ errMsg: "" });
              addUri && getPath && addUri(getPath(), actionprops.link);
            }
          }
        },
        {
          direction: "row",
          viewStyle: { flex: 1 },
          render: [
            {
              viewStyle: {
                marginRight: 7,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: this.state.errMsg ? "red" : "transparent",
                borderRadius: 2,
                height: 16
              },
              render: <Checked value={this.state.termsAccepted} onChangeValue={this.handleCheckboxChange} />
            },
            {
              viewStyle: { flex: 1 },
              render: (
                <MultipleText
                  value={[
                    {
                      textStyle: { color: '#fff', fontFamily: 'Lexend-Regular' },
                      text: I18N.t("acceptTerms")
                    },
                    {
                      textStyle: {
                        color: '#fff',
                        textDecorationLine: "underline",
                        fontFamily: 'Lexend-Regular'
                      },
                      text: I18N.t("termsAndPolicyWeb"),
                      link: { uri: AppConfig.APP_TERMS_CONDITION_URL },
                      external: true
                    }
                  ]}
                />
              )
            }
          ]
        }
      ];
    };

    render() {
      let { index } = this.state;
      let { footerContainerStyle, logoContainerStyle } = walkthroughStyle;
      return (
        <Box
          viewStyle={{ flex: 1 }}
          render={[
            {
              width: "1fr",
              render: (
                <Swiper
                  index={index}
                  swipeByTouchEvent={true}
                  onIndexChanged={newIndex => {
                    if (index !== newIndex) {
                      this.setState({ index: newIndex });
                    }
                  }}
                >
                  {walkthroughInfo.map((value, index) => (
                    <ImageRender key={index} value={value} />
                  ))}
                </Swiper>
              )
            },
            {
              image: getImage("walkthroughLogo"),
              ...logoContainerStyle
            },
            {
              ...footerContainerStyle,
              render: this.renderFooter()
            }
          ]}
        />
      );
    }
  }
  return Walkthrough;
};
