import React from "react";
import { addItems, getErrorMessage } from "./CollectionUtility";

export default ({ View, Box, theme, getImage, getInput, I18N, Header, LoadingIndicator, showMessage }) => {
  const { colors, fonts, bgs } = theme;
  const { themeColor, brownGrey, tomato, highlightColor, primaryColor } = colors;
  const { lightPink } = bgs;
  const { h20_l, h9, h16_l_22 } = fonts;

  const metadata = {
    gallery: {
      headerTitle: () => I18N.t("addToAlbumCaps"),
      icon: "albumNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewAlbum"),
      secondaryText: () => I18N.t("createNewAlbumSecondaryMessage"),
      mandatoryError: () => I18N.t("addToAlbumValidationMessage"),
      inputPlaceholder: () => I18N.t("createAlbumInputPlaceholder"),
      nameLengthError: () => I18N.t("albumNameLengthMessage"),
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToAlbum"),
      successMessageMultiple: () => I18N.t("itemsAddedToAlbum"),
      duplicateError: () => I18N.t("albumDuplicateNameError")
    },
    doc: {
      headerTitle: () => I18N.t("addToSetCaps"),
      icon: "setNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewSet"),
      secondaryText: () => I18N.t("createNewSetSecondaryMessage"),
      mandatoryError: () => I18N.t("addToSetValidationMessage"),
      inputPlaceholder: () => I18N.t("createSetInputPlaceholder"),
      nameLengthError: () => I18N.t("setNameLengthMessage"),
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToSet"),
      successMessageMultiple: () => I18N.t("itemsAddedToSet"),
      duplicateError: () => I18N.t("setDuplicateNameError")
    },
    music: {
      headerTitle: () => I18N.t("addToPlaylistCaps"),
      icon: "playlistNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewPlaylist"),
      secondaryText: () => I18N.t("createNewPlayListSecondaryMessage"),
      mandatoryError: () => I18N.t("addToPlaylistValidationMessage"),
      inputPlaceholder: () => I18N.t("createPlaylistInputPlaceholder"),
      nameLengthError: () => I18N.t("playlistNameLengthMessage"),
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToPlaylist"),
      successMessageMultiple: () => I18N.t("itemsAddedToPlaylist"),
      duplicateError: () => I18N.t("playlistDuplicateNameError")
    },
    vaultAlbum: {
      headerTitle: () => I18N.t("addToAlbumCaps"),
      icon: "albumNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewAlbum"),
      secondaryText: () => I18N.t("createNewAlbumSecondaryMessage"),
      mandatoryError: () => I18N.t("addToAlbumValidationMessage"),
      inputPlaceholder: () => I18N.t("createAlbumInputPlaceholder"),
      nameLengthError: () => I18N.t("albumNameLengthMessage"),
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToAlbum"),
      successMessageMultiple: () => I18N.t("itemsAddedToAlbum"),
      duplicateError: () => I18N.t("albumDuplicateNameError")
    },
    vaultSet: {
      headerTitle: () => I18N.t("addToSetCaps"),
      icon: "setNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewSet"),
      secondaryText: () => I18N.t("createNewSetSecondaryMessage"),
      mandatoryError: () => I18N.t("addToSetValidationMessage"),
      inputPlaceholder: () => I18N.t("createSetInputPlaceholder"),
      nameLengthError: () => I18N.t("setNameLengthMessage"),
      successMessage: () => I18N.t("itemsAddedSuccessfully"),
      successMessageSingle: () => I18N.t("itemAddedToSet"),
      successMessageMultiple: () => I18N.t("itemsAddedToSet"),
      duplicateError: () => I18N.t("setDuplicateNameError")
    }
  };

  const componentTheme = {
    iconStyle: {
      viewStyle: { paddingTop: 40, alignSelf: "center" },
      imageStyle: { width: 109, height: 102 }
    },
    informationStyle: {
      containerStyle: {
        viewStyle: { height: 134, alignItems: "center", paddingLeft: 30, paddingRight: 30 }
      },
      onlyPrimaryTextStyle: {
        viewStyle: {
          paddingTop: 44
        },
        textStyle: { ...h20_l, color: themeColor }
      },
      primaryTextStyle: {
        viewStyle: {
          paddingTop: 20,
          paddingBottom: 16
        },
        textStyle: { ...h20_l, color: themeColor, textAlign: "center" }
      },
      secondaryTextStyle: {
        viewStyle: { width: "100%" },
        textStyle: { ...h16_l_22, color: primaryColor, textAlign: "center" }
      }
    },
    inputStyle: {
      containerStyle: {
        viewStyle: {
          marginLeft: 30,
          marginRight: 30,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 4,
          backgroundColor: lightPink
        }
      },
      textInputStyle: { ...h9, color: brownGrey }
    },
    footerStyle: {
      containerStyle: {
        viewStyle: {
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 24,
          paddingBottom: 24,
          alignItems: "center"
        },
        direction: "row"
      },
      errorStyle: {
        viewStyle: {
          flex: 1,
          paddingRight: 12
        },
        textStyle: {
          ...h9,
          color: tomato,
          numberOfLines: 2
        }
      },
      addActionStyle: {
        viewStyle: {
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: themeColor,
          borderRadius: 4,
          cursor: "pointer"
        },
        textStyle: {
          ...h9,
          color: highlightColor
        },
        text: () => I18N.t("add")
      }
    }
  };

  class AddItemsCreateCollection extends React.Component {
    state = { error: void 0, loading: false };

    componentWillUnmount() {
      this._unmounted = true;
    }

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    onChangeName = name => {
      let newState = { name };
      if (!this.state.loading) {
        if (this.state.error) {
          newState["error"] = void 0;
        }
        this._setState(newState);
      }
    };

    render() {
      let { onClose, origin, showSecondaryText } = this.props;
      const TextInput = getInput("text");
      const { iconStyle, informationStyle, inputStyle, footerStyle } = componentTheme;
      const {
        headerTitle,
        icon,
        primaryText,
        secondaryText,
        inputPlaceholder,
        mandatoryError,
        nameLengthError,
        successMessage,
        successMessageSingle,
        successMessageMultiple,
        duplicateError
      } = metadata[origin];

      let { name, error, loading } = this.state;

      const onAddClick = async () => {
        name = name && name.trim();
        if (!name || name.length === 0) {
          this._setState({ error: mandatoryError });
          return;
        }
        if (name.length < 3 || name.length > 55) {
          this._setState({ error: nameLengthError });
          return;
        }
        let { onClose, invoke, urls, rowData, selectedIds = [], origin } = this.props;
        try {
          this._setState({ loading: true });
          if (!selectedIds.length && rowData && rowData._id) {
            selectedIds = [rowData._id];
          }
          await addItems({
            invoke,
            urls,
            selectedIds,
            origin,
            collectionName: name
          });
          const itemsLength = selectedIds && selectedIds.length;
          let message = itemsLength > 1 ? `${itemsLength} ${successMessageMultiple()}` : successMessageSingle();
          if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
            message = successMessage();
          }
          message && showMessage && showMessage(message, 2000);
          onClose ? onClose() : this._setState({ loading: false });
        } catch (e) {
          let message = getErrorMessage(e, { duplicateError, nameLengthError, I18N });
          this._setState({ error: message, loading: false });
        }
      };

      return (
        <View>
          {loading && <LoadingIndicator />}
          <Box
            render={[
              <Header title={headerTitle} onClose={onClose} />,
              {
                ...iconStyle,
                image: getImage(icon)
              },
              {
                ...informationStyle.containerStyle,
                render: [
                  {
                    ...(showSecondaryText ? informationStyle.primaryTextStyle : informationStyle.onlyPrimaryTextStyle),
                    text: primaryText
                  },
                  showSecondaryText && {
                    ...informationStyle.secondaryTextStyle,
                    text: secondaryText
                  }
                ]
              },
              {
                ...inputStyle.containerStyle,
                render: (
                  <TextInput
                    value={name}
                    onChangeValue={this.onChangeName}
                    placeholder={inputPlaceholder()}
                    style={{ ...inputStyle.textInputStyle }}
                    autoFocus
                    maxLength={55}
                    onSubmitEditing={onAddClick}
                  />
                )
              },
              {
                ...footerStyle.containerStyle,
                render: [
                  {
                    ...footerStyle.errorStyle,
                    text: error || ""
                  },
                  {
                    ...footerStyle.addActionStyle,
                    onClick: onAddClick
                  }
                ]
              }
            ]}
          />
        </View>
      );
    }
  }

  return AddItemsCreateCollection;
};
