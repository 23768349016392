export default () => {
  const sharePopupSprite = {
    uri: "/webImages/sharePopupIcons/1_sprite.png",
    sharePopupEmailIcon: {
      width: 26,
      height: 18,
      objectPosition: "-10px -13px",
      objectFit: "none"
    },
    sharePopupFacebookIcon: {
      width: 24,
      height: 24,
      objectPosition: "-10px -44px",
      objectFit: "none"
    },
    sharePopupLinkIcon: {
      width: 22,
      height: 22,
      objectPosition: "-11px -79px",
      objectFit: "none"
    },
    sharePopupPinIcon: {
      width: 22,
      height: 22,
      objectPosition: "-47px -11px",
      objectFit: "none"
    },
    sharePopupTwitterIcon: {
      width: 24,
      height: 24,
      objectPosition: "-44px -44px",
      objectFit: "none"
    }
  };
  const sharePopupEmailIcon = {
    uri: sharePopupSprite.uri,
    spriteProps: {
      ...sharePopupSprite.sharePopupEmailIcon
    }
  };
  const sharePopupFacebookIcon = {
    uri: sharePopupSprite.uri,
    spriteProps: {
      ...sharePopupSprite.sharePopupFacebookIcon
    }
  };
  const sharePopupLinkIcon = {
    uri: sharePopupSprite.uri,
    spriteProps: {
      ...sharePopupSprite.sharePopupLinkIcon
    }
  };
  const sharePopupPinIcon = "/webImages/sharePopupIcons/sharePopupPin.png";
  const sharePopupTwitterIcon = {
    uri: sharePopupSprite.uri,
    spriteProps: {
      ...sharePopupSprite.sharePopupTwitterIcon
    }
  };

  return {
    sharePopupEmailIcon,
    sharePopupFacebookIcon,
    sharePopupLinkIcon,
    sharePopupPinIcon,
    sharePopupTwitterIcon
  };
};
