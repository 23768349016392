import React from "react";

export default ({ ImageRow, Image, View, resolveValue, shallowCompare }) => {
  class ImageRender extends React.Component {
    shouldComponentUpdate(nextProps) {
      let equal = shallowCompare(nextProps, this.props, {
        matchProps: { imageUrl: 1, lastSelected: 1, currentSelected: 1 }
      });
      return !equal;
    }

    render() {
      let { imageUrl } = this.props;
      return (
        <View
          style={{
            flex: 1,
            margin: 1,
            backgroundColor: "lightgrey"
          }}
        >
          <Image
            key={imageUrl}
            style={{
              flex: 1
            }}
            resizeMode={"cover"}
            onLoad={this.handleOnLoad}
            source={{ uri: imageUrl }}
            resizeMethod={"resize"}
          />
        </View>
      );
    }
  }

  class RecyclerListRowImage extends React.Component {
    render() {
      let { data = {}, imageField } = this.props;
      let imageComponent = <ImageRender imageUrl={resolveValue(data, imageField)} />;
      if (ImageRow) {
        imageComponent = <ImageRow {...this.props} viewWrapperStyle={{ flex: 1 }} imageComponent={imageComponent} />;
      }
      return imageComponent;
    }
  }
  return RecyclerListRowImage;
};
