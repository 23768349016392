import React from "react";
import {
  View,
  Platform,
  Box,
  theme,
  withContext,
  getImage,
  ScrollView,
  I18N,
  AsyncStorage,
  setWarningRef
} from "./FsCloudComponent";

let { fonts, colors } = theme;
let { h16_18, h9_24 } = fonts;
let { highlightColor } = colors;

// noSpaceAssign, completelyUsed, accountDeleted, accountRollback
const ACCOUNT_DELETED = "accountDeleted";
const ACCOUNT_ROLLBACK = "accountRollback";
const WARNING_TIME = 1000 * 60 * 60 * 12;

class AppWarning extends React.Component {
  state = {};
  warnings = [];

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  componentDidMount() {
    setWarningRef && setWarningRef(this);
    this.loadWarnings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.loadWarnings();
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
    setWarningRef && setWarningRef();
  }

  // Used to Fetch app warnings from the server.
  loadWarnings = async () => {
    try {
      let { invoke, urls } = this.props;

      let result = await invoke({
        service: {
          url: urls["getAppWarnings"],
          uriProps: {
            data: {
              language: this.props.language || I18N.t("locale-string")
            }
          }
        },
        allowParallelInvoke: true
      });

      if (result && result.length) {
        this.warnings = [];
        let lastWarningTime = await AsyncStorage.getItem("lastWarningTime");
        lastWarningTime = (lastWarningTime && new Date(JSON.parse(lastWarningTime))) || "";
        for (let warningInfo of result) {
          let { warning, code } = warningInfo;
          if (warning) {
            if (code === ACCOUNT_ROLLBACK) {
              let { rollbackOn } = warningInfo;
              rollbackOn = rollbackOn && new Date(rollbackOn);
              if (rollbackOn && (!lastWarningTime || rollbackOn - lastWarningTime > 0)) {
                this.warnings.push(warningInfo);
              }
            } else if (code === ACCOUNT_DELETED) {
              let { suspendedOn } = warningInfo;
              suspendedOn = suspendedOn && new Date(suspendedOn);
              if (
                suspendedOn &&
                (!lastWarningTime || suspendedOn - lastWarningTime > 0 || new Date() - lastWarningTime > WARNING_TIME)
              ) {
                this.warnings.push(warningInfo);
              }
            } else if (!lastWarningTime || new Date() - lastWarningTime > WARNING_TIME) {
              this.warnings.push(warningInfo);
            }
          }
        }
        this.warnings.length && (await AsyncStorage.setItem("lastWarningTime", JSON.stringify(new Date())));
        this._setState({});
      }
    } catch (e) {
      console.warn("!!!!!err in loadWarnings>>>>>", e.message);
      // do nothing
    }
  };

  render() {
    if (!this.warnings || !this.warnings.length || this.props.componentsCount !== 1) {
      return null;
    }
    return (
      <View
        style={{
          maxHeight: 250,
          backgroundColor: "#f5a623"
        }}
      >
        <ScrollView style={Platform.OS === "web" ? { flex: 1 } : {}} bounces={false}>
          <Box
            viewStyle={{ paddingTop: 8, paddingBottom: 8 }}
            render={this.warnings.map((warningInfo, index) => {
              return [
                index > 0 ? { viewStyle: { height: 1, backgroundColor: highlightColor } } : void 0,
                {
                  viewStyle: { paddingTop: 8, paddingBottom: 8 },
                  direction: "row",
                  render: [
                    {
                      viewStyle: { paddingLeft: 12, paddingRight: 12, flex: 1 },
                      render: [
                        warningInfo.title && {
                          viewStyle: { paddingBottom: 4 },
                          textStyle: { ...h16_18, color: highlightColor },
                          text: warningInfo.title
                        },
                        {
                          textStyle: { ...h9_24, color: highlightColor },
                          text: warningInfo.warning
                        }
                      ]
                    },
                    {
                      viewStyle: { paddingLeft: 8, paddingRight: 8, alignSelf: "flex-start", cursor: "pointer" },
                      onClick: () => {
                        this.warnings.splice(index, 1);
                        this._setState({});
                      },
                      render: [
                        {
                          viewStyle: warningInfo.title ? {} : { paddingTop: 6, paddingBottom: 6 },
                          imageStyle: warningInfo.title ? { height: 16, width: 16 } : { height: 12, width: 12 },
                          image: getImage("crossWhiteIcon")
                        }
                      ]
                    }
                  ]
                }
              ];
            })}
          />
        </ScrollView>
      </View>
    );
  }
}

AppWarning = withContext(AppWarning, {
  language: "App.pstate.language",
  invoke: "App.invoke",
  urls: "App.urls"
});

export default AppWarning;
