import React from "react";

export default ({ Box, theme, resolveMQ, getImage }) => {
  const { fonts, colors, bgs } = theme;
  const { h16, h24, h16_l, h9, h9_18 } = fonts;
  const { primaryColor, highlightColor, brownGrey } = colors;
  const { themeBg } = bgs;

  const renderNoDataStyle = {
    containerStyleSM: {
      viewStyle: { flex: 1, alignItems: "center", justifyContent: "center", padding: 24 }
    },
    containerStyleMD: {
      viewStyle: { flex: 1, alignItems: "center", justifyContent: "center", padding: 24 }
    },
    iconStyleSM: {
      viewStyle: { paddingBottom: 29 }
    },
    iconStyleMD: {
      viewStyle: { paddingBottom: 16 }
    },
    primaryStyleSM: {
      viewStyle: { paddingTop: 6, paddingBottom: 6 },
      textStyle: {
        ...h16,
        color: primaryColor,
        textAlign: "center"
      }
    },
    primaryStyleMD: {
      viewStyle: {},
      textStyle: {
        ...h24,
        color: primaryColor,
        textAlign: "center"
      }
    },
    secondaryStyleSM: {
      viewStyle: { paddingTop: 8, paddingBottom: 8 },
      textStyle: {
        ...h9_18,
        color: brownGrey,
        textAlign: "center"
      }
    },
    secondaryStyleMD: {
      viewStyle: { paddingTop: 8, paddingBottom: 4 },
      textStyle: {
        ...h16_l,
        color: primaryColor,
        textAlign: "center"
      }
    },
    notifyMessageStyleMD: {
      viewStyle: { paddingTop: 4, paddingBottom: 4 },
      textStyle: {
        ...h16_l,
        color: primaryColor,
        textAlign: "center"
      }
    },
    infoMessageStyleMD: {
      viewStyle: { paddingTop: 4, paddingBottom: 8 },
      textStyle: {
        ...h16_l,
        color: primaryColor,
        textAlign: "center"
      }
    },
    buttonStyleSM: {
      viewStyle: {
        marginTop: 8,
        marginBottom: 8,
        backgroundColor: themeBg,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 11,
        paddingBottom: 10,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center"
      },
      textStyle: {
        ...h9,
        color: highlightColor
      }
    },
    buttonStyleMD: {
      viewStyle: {
        marginTop: 8,
        backgroundColor: themeBg,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 11,
        paddingBottom: 10,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      },
      textStyle: {
        ...h9,
        color: highlightColor
      }
    }
  };

  class GroupTabsNoData extends React.Component {
    render() {
      let { onClick, activeMQ } = this.props;
      let {
        image,
        primaryText,
        notifyMessageText,
        infoMessageText,
        secondaryText,
        buttonText,
        buttonContainer,
        buttonContainerProps
      } = resolveMQ(
        this.props,
        [
          "image",
          "primaryText",
          "secondaryText",
          "notifyMessageText",
          "infoMessageText",
          "buttonText",
          "buttonContainer",
          "buttonContainerProps"
        ],
        activeMQ
      );
      let {
        containerStyle,
        iconStyle,
        primaryStyle,
        secondaryStyle,
        buttonStyle,
        notifyMessageStyle,
        infoMessageStyle
      } = resolveMQ(
        renderNoDataStyle,
        [
          "containerStyle",
          "iconStyle",
          "primaryStyle",
          "secondaryStyle",
          "buttonStyle",
          "notifyMessageStyle",
          "infoMessageStyle"
        ],
        activeMQ
      );
      let button = buttonText && {
        ...buttonStyle,
        text: buttonText,
        onClick
      };
      if (buttonContainer && button) {
        button.Container = buttonContainer;
        button.containerProps = {
          ...buttonContainerProps
        };
      }
      return (
        <Box
          {...containerStyle}
          render={[
            image && { ...iconStyle, image: getImage(image) },
            primaryText && {
              ...primaryStyle,
              text: primaryText
            },
            secondaryText && {
              ...secondaryStyle,
              text: secondaryText
            },
            notifyMessageText && {
              ...notifyMessageStyle,
              text: notifyMessageText
            },
            infoMessageText && {
              ...infoMessageStyle,
              text: infoMessageText
            },
            button
          ]}
        />
      );
    }
  }
  return GroupTabsNoData;
};
