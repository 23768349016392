/* eslint eqeqeq: "off" */
/* eslint react/no-direct-mutation-state: "off" */
import React from "react";
import groupBy from "lodash/groupBy";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import { shallowCompare } from "app-utility-functions";

export default ({
  Box,
  ScrollView,
  View,
  theme,
  getTimeStringFromSeconds,
  getImage,
  I18N,
  FSListItem,
  DecryptedImage
}) => {
  const { colors, fonts, gradients } = theme;
  const { primaryColor, highlightColor, brownGrey, themeColor, whiteTwo } = colors;
  const { h16, h1, h2 } = fonts;
  const { topBlackGradient, leftBlackGradient } = gradients;

  const populateFormattedDate = ({ images = [], hideDateHeader, sortingOrder }) => {
    return images.map(doc => {
      doc = { ...doc };
      let lastModifiedDate = new Date();
      if (!hideDateHeader) {
        if (sortingOrder === "uploadedOn") {
          lastModifiedDate = new Date(doc[sortingOrder].seconds * 1000);
        } else {
          lastModifiedDate = doc[sortingOrder];
        }
      }
      let { resource_url } = doc;
      lastModifiedDate = moment(lastModifiedDate).format("D MMM YYYY");
      doc["groupDate"] = lastModifiedDate;
      doc["formattedDate"] = lastModifiedDate;
      doc["url"] = resource_url;
      doc["uri"] = resource_url;
      return doc;
    });
  };

  const extractImages = ({ images = [], number }) => {
    if (!images.length) {
      return;
    }
    if (images.length <= number) {
      return {
        extractedImages: [...images],
        pendingImages: []
      };
    } else {
      return {
        extractedImages: images.slice(0, number),
        pendingImages: images.slice(number)
      };
    }
  };

  const getExpectedWidth = ({ images, height, remainingWidth }) => {
    let accumWidth = 0;
    let imagesLength = images.length;
    for (let i = 0; i < imagesLength; i++) {
      let doc = images[i];
      accumWidth = accumWidth + (height * doc.thumbnail_width) / doc.thumbnail_height;
      if (accumWidth >= (remainingWidth * 80) / 100) {
        return { expectedWidth: accumWidth, settleImage: i };
      }
    }
    return { expectedWidth: accumWidth, settleImage: imagesLength - 1 };
  };

  const isSameGroupImages = ({ images, group }) => {
    for (let index = 0; index < images.length; index++) {
      if (images[index]["groupDate"] !== group) {
        return false;
      }
    }
    return true;
  };

  const pullOtherGroupImages = ({ images, group }) => {
    let sameGroupImages = [];
    let otherGroupImages = [];
    images.forEach(doc => {
      if (doc["groupDate"] === group) {
        sameGroupImages.push(doc);
      } else {
        otherGroupImages.push(doc);
      }
    });
    return { sameGroupImages, otherGroupImages };
  };

  const pullOtherGroupImagesIfNotFullyContained = ({ images, pendingImages }) => {
    let sameGroupImages = [];
    let otherGroupImages = [];
    if (!pendingImages || !pendingImages.length) {
      return {
        sameGroupImages: images,
        otherGroupImages: []
      };
    }
    let topPendingGroup = pendingImages[0]["groupDate"];
    images.forEach(doc => {
      if (doc["groupDate"] === topPendingGroup) {
        otherGroupImages.push(doc);
      } else {
        sameGroupImages.push(doc);
      }
    });
    return { sameGroupImages, otherGroupImages };
  };

  const adjustHeightWidth = ({ imageArray, height }) => {
    if (!imageArray || !imageArray.length) {
      return;
    }
    let remainingPixel = 0;
    imageArray.forEach(image => {
      const { thumbnail_width, thumbnail_height, converted_jpeg_url, thumbnail_url } = image;
      let width = (height / thumbnail_height) * thumbnail_width;
      if (width > thumbnail_width && ((width - thumbnail_width) * 100) / thumbnail_width > 10) {
        image.viewImageUrl = converted_jpeg_url ? converted_jpeg_url : thumbnail_url;
      } else {
        image.viewImageUrl = thumbnail_url;
      }
      image.thumbnail_width = Math.floor(width);
      image.thumbnail_height = Math.floor(height);
      remainingPixel += width - image.thumbnail_width;
    });
    imageArray[imageArray.length - 1].thumbnail_width += Math.floor(remainingPixel);
    return imageArray;
  };

  const isLastImageOfGroup = ({ extractedImages, pendingImages }) => {
    return !(
      pendingImages.length && extractedImages[extractedImages.length - 1]["groupDate"] === pendingImages[0]["groupDate"]
    );
  };

  let noOfColumn = 0;
  const adjustImages = ({
    accumImages,
    images,
    expectedHeight,
    expectedColumn,
    minColumn,
    minHeight,
    maxHeight,
    availableWidth,
    padding
  }) => {
    const extractResult = extractImages({ images, number: expectedColumn });
    if (!extractResult) {
      return accumImages;
    }
    let remainingWidth = availableWidth - padding * expectedColumn;
    let { extractedImages, pendingImages } = extractResult;
    const { expectedWidth, settleImage } = getExpectedWidth({
      images: extractedImages,
      height: expectedHeight,
      remainingWidth
    });
    remainingWidth = availableWidth - padding * (settleImage + 1);
    if (settleImage !== extractedImages.length - 1) {
      let deletedItems = extractedImages.splice(settleImage + 1, extractedImages.length - 1 - settleImage);
      pendingImages.unshift(...deletedItems);
    }
    let adjustedHeight = (expectedHeight * remainingWidth) / expectedWidth;
    if (adjustedHeight < minHeight && noOfColumn !== expectedColumn) {
      noOfColumn = expectedColumn - 1;
      return adjustImages({
        images: images,
        accumImages,
        expectedHeight,
        expectedColumn: noOfColumn,
        minColumn,
        minHeight,
        maxHeight,
        availableWidth,
        padding
      });
    } else if (adjustedHeight > maxHeight && noOfColumn !== expectedColumn) {
      noOfColumn = expectedColumn + 1;
      return adjustImages({
        images: images,
        accumImages,
        expectedHeight,
        expectedColumn: noOfColumn,
        minColumn,
        minHeight,
        maxHeight,
        availableWidth,
        padding
      });
    } else {
      let isLastImage = isLastImageOfGroup({ extractedImages, pendingImages });
      if (isLastImage && extractedImages.length === 1) {
        adjustedHeight =
          adjustedHeight <= maxHeight && adjustedHeight >= minHeight
            ? adjustedHeight
            : adjustedHeight >= maxHeight
            ? maxHeight
            : minHeight;
      }
      const topImage = extractedImages[0];
      const topGroup = topImage["groupDate"];
      const isGroupExist = accumImages[topGroup];
      if (isGroupExist) {
        let sameGroup = isSameGroupImages({ images: extractedImages, group: topGroup });
        if (sameGroup) {
          extractedImages = adjustHeightWidth({ imageArray: extractedImages, height: adjustedHeight });
          accumImages[topGroup].push(extractedImages);
          return { pendingImages };
        } else {
          let { sameGroupImages, otherGroupImages } = pullOtherGroupImages({
            images: extractedImages,
            group: topGroup
          });
          sameGroupImages = adjustHeightWidth({ imageArray: sameGroupImages, height: adjustedHeight });
          accumImages[topGroup].push(sameGroupImages);
          if (otherGroupImages) {
            pendingImages = pendingImages || [];
            pendingImages = [...otherGroupImages, ...pendingImages];
          }
          if (pendingImages && pendingImages.length) {
            return { pendingImages };
          }
        }
      } else {
        let sameGroup = isSameGroupImages({ images: extractedImages, group: topGroup });
        accumImages[topGroup] = accumImages[topGroup] || [];
        if (sameGroup) {
          extractedImages = adjustHeightWidth({ imageArray: extractedImages, height: adjustedHeight });
          accumImages[topGroup].push(extractedImages);
          return { pendingImages };
        } else {
          let { sameGroupImages, otherGroupImages } = pullOtherGroupImagesIfNotFullyContained({
            images: extractedImages,
            pendingImages
          });
          sameGroupImages = adjustHeightWidth({ imageArray: sameGroupImages, height: adjustedHeight });
          accumImages[topGroup].push(sameGroupImages);
          if (otherGroupImages) {
            pendingImages = pendingImages || [];
            pendingImages = [...otherGroupImages, ...pendingImages];
          }
          return { pendingImages };
        }
      }
    }
  };

  const _adjustImages = ({
    images,
    accumImages,
    expectedHeight,
    expectedColumn,
    minColumn,
    maxHeight,
    minHeight,
    availableWidth,
    padding
  }) => {
    let pendingImages = images;
    while (pendingImages && pendingImages.length) {
      const { pendingImages: _pendingImages } = adjustImages({
        images: pendingImages,
        accumImages,
        expectedHeight,
        expectedColumn,
        minColumn,
        minHeight,
        maxHeight,
        availableWidth,
        padding
      });
      pendingImages = _pendingImages;
    }
  };

  let yearMinimumHeight = 12;

  class Calender extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showCalender: false, cursorOnCalender: false };
      this.dateHeightOnCalenderObject = {};
      this.remainingHeight = 0;
      this.dateHeightObject = {};
      this.heightToSubtract = void 0;
    }

    componentDidUpdate() {
      const { dateHeightObject } = this.props;
      let equal = shallowCompare(this.dateHeightObject, dateHeightObject);
      if (!equal) {
        this.dateHeightObject = dateHeightObject;
        this.setState({});
      }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      const { dateHeightObject } = newProps;
      if (Object.keys(dateHeightObject).length && this.state.viewDate === undefined) {
        let viewDate = moment(new Date(Object.keys(this.props.dateHeightObject)[0])).format("MMM D YYYY");
        this.setState({ viewDate });
      }
      let total = 0;
      Object.keys(dateHeightObject).length &&
        Object.keys(dateHeightObject).forEach(param => {
          total += dateHeightObject[param];
        });
      this.total = total;
    }

    initialSeparator = () => {
      if (!Object.keys(this.dateHeightObject).length) {
        return;
      }
      let ongoingYear = void 0;
      let heightToSubtract = 0;

      Object.keys(this.dateHeightObject).forEach(param => {
        let currentYear = new Date(param).getFullYear();
        let currentValue = (this.dateHeightObject[param] / this.total) * this.calenderheight;
        if (ongoingYear !== currentYear) {
          if (currentValue < yearMinimumHeight) {
            heightToSubtract += yearMinimumHeight;
          }
          ongoingYear = currentYear;
        }
      });
      this.heightToSubtract = heightToSubtract;
    };

    getDateHeightRatio = ({ dateHeight, viewHeight, date }) => {
      if (this.total === 0) {
        let total = 0;
        Object.keys(this.dateHeightObject).length &&
          Object.keys(this.dateHeightObject).forEach(param => {
            total += this.dateHeightObject[param];
          });
        this.total = total;
      }
      if (typeof this.heightToSubtract !== "number") {
        this.initialSeparator();
      }
      let heightToReturn = (dateHeight / this.total) * viewHeight;
      if (this.currentYear === undefined) {
        heightToReturn = Math.max(heightToReturn, yearMinimumHeight);
      } else if (this.year !== this.currentYear) {
        heightToReturn = Math.max(heightToReturn, yearMinimumHeight);
      } else if (this.year === this.currentYear) {
        heightToReturn = (dateHeight / this.total) * (viewHeight - this.heightToSubtract);
      }
      this.dateHeightOnCalenderObject[date] = heightToReturn;
      return heightToReturn;
    };

    onCalenderCursorClick = e => {
      const { viewDate } = this.state;
      /*
      existing work for click event

      let position = e.clientY - this.calenderTop;
      position = (position * 100) / this.calenderheight;
      position = (position * this.total) / 100;
      this.props.parentRef.scrollTop = position;

      */

      let position = e.clientY - this.calenderTop;
      let dateElement = document.getElementById(viewDate);
      let calenderElement = document.getElementById(`${viewDate}_calender`);
      let pointerPositionOnCalender = position - calenderElement.offsetTop;
      pointerPositionOnCalender = (pointerPositionOnCalender * 100) / calenderElement.clientHeight;
      let pointerPositionOnList = (dateElement.clientHeight * pointerPositionOnCalender) / 100;
      pointerPositionOnList += dateElement.offsetTop;
      this.props.parentRef.scrollTop = pointerPositionOnList;
    };

    onCalenderCursorScroll = ({ e, viewDate }) => {
      let newViewDate = moment(new Date(viewDate)).format("MMM D YYYY");
      let cursorPosition = e.clientY - this.calenderTop;
      if (this.ref && this.toolTipRef) {
        this.ref.style["top"] = `${cursorPosition}px`;
        this.toolTipRef.style["top"] = `${cursorPosition - 15}px`;
      }
      if (newViewDate !== this.state.viewDate) {
        this.setState({ viewDate: newViewDate });
      }
    };

    onMouseEnterCalender = () => {
      this.setState({ showCalender: true });
      if (this.state.showCalender) {
        clearTimeout(this.controlBarInterval);
      }
    };

    onMouseLeaveCalender = () => {
      if (this.state.showCalender) {
        this.controlBarInterval = setTimeout(() => {
          this.setState({ showCalender: false });
        }, 2000);
      }
    };
    onMouseEnterCalenderView = () => {
      !this.state.cursorOnCalender && this.setState({ cursorOnCalender: true });
    };

    onMouseLeaveCalenderView = () => {
      this.state.cursorOnCalender && this.setState({ cursorOnCalender: false });
    };

    onParentScroll = scrollPosition => {
      const { dateHeightObject } = this.props;
      const { cursorOnCalender } = this.state;
      let date;
      let total = 0;
      let calenderCursor = (scrollPosition / this.total) * this.calenderheight;

      if (!cursorOnCalender) {
        if (this.controlBarInterval) {
          clearTimeout(this.controlBarInterval);
          this.controlBarInterval = void 0;
        }
        if (!this.state.showCalender) {
          this.setState({ showCalender: true });
        }
        this.controlBarInterval = setTimeout(() => {
          this.setState({ showCalender: false });
        }, 2000);

        for (let i = 0; i < Object.keys(dateHeightObject).length; i++) {
          date = Object.keys(dateHeightObject)[i];
          total += dateHeightObject[date];
          if (total >= scrollPosition) {
            break;
          }
        }
        this.state.viewDate = moment(new Date(date)).format("MMM D YYYY");
        if (this.ref && this.toolTipRef) {
          this.ref.style["top"] = `${calenderCursor}px`;
          this.toolTipRef.style["top"] = `${calenderCursor - 15}px`;
        }
      }
    };

    render() {
      const { ref } = this.props;
      const { showCalender, viewDate } = this.state;
      if (this.dateHeightObject == {}) {
        return null;
      }
      let dataHeightObjectKeys = Object.keys(this.dateHeightObject);
      this.year = new Date(dataHeightObjectKeys[0]).getFullYear();
      let modifiedDate = `${I18N.t(`month_${new Date(viewDate).getMonth()}`)} ${new Date(viewDate).getDate()}`;
      return (
        <View
          ref={ref}
          onMouseEnter={this.onMouseEnterCalender}
          onMouseLeave={this.onMouseLeaveCalender}
          onLayout={({ nativeEvent: { layout } }) => {
            let { height, top } = layout;
            this.calenderTop = top;
            this.calenderheight = height;
          }}
          style={{
            marginTop: 10,
            bottom: 5,
            overflow: "visible",
            cursor: "row-resize",
            position: "absolute",
            top: 0,
            right: 10,
            width: 70
          }}
        >
          {showCalender ? (
            <View
              style={{
                flex: 1,
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <Box
                id={`${dataHeightObjectKeys[0]}_calender`}
                onMouseMove={e => this.onCalenderCursorScroll({ e, viewDate: dataHeightObjectKeys[0] })}
                viewStyle={{
                  width: "100%",
                  alignItems: "center"
                }}
                render={[
                  {
                    viewStyle: {
                      height: this.getDateHeightRatio({
                        dateHeight: this.dateHeightObject[dataHeightObjectKeys[0]],
                        viewHeight: this.calenderheight,
                        date: dataHeightObjectKeys[0]
                      })
                    },
                    text: this.year,
                    textStyle: { color: primaryColor, ...h2 }
                  }
                ]}
              />

              {dataHeightObjectKeys.map((param, index) => {
                if (index === 0) {
                  return null;
                } else {
                  this.currentYear = new Date(param).getFullYear();
                  if (this.currentYear === this.year) {
                    let height = this.getDateHeightRatio({
                      dateHeight: this.dateHeightObject[param],
                      viewHeight: this.calenderheight,
                      date: param
                    });
                    if (height < 4 && height + this.remainingHeight < 4) {
                      this.remainingHeight += height;
                      return void 0;
                    } else {
                      height = height + this.remainingHeight;
                      this.remainingHeight = 0;
                      return (
                        <Box
                          id={`${param}_calender`}
                          onMouseMove={e => this.onCalenderCursorScroll({ e, viewDate: param })}
                          viewStyle={{
                            width: "100%",
                            alignItems: "center"
                          }}
                          render={[
                            {
                              viewStyle: {
                                borderTopWidth: 2,
                                borderTopColor: brownGrey,
                                width: 9,
                                height: height
                              }
                            }
                          ]}
                        />
                      );
                    }
                  } else {
                    let height = this.getDateHeightRatio({
                      dateHeight: this.dateHeightObject[param],
                      viewHeight: this.calenderheight,
                      date: param
                    });
                    this.year = this.currentYear;
                    return (
                      <Box
                        id={`${param}_calender`}
                        onMouseMove={e => this.onCalenderCursorScroll({ e, viewDate: param })}
                        viewStyle={{
                          width: "100%",
                          alignItems: "center"
                        }}
                        render={[
                          {
                            viewStyle: {
                              height
                            },
                            text: this.year,
                            textStyle: { color: primaryColor, ...h2 }
                          }
                        ]}
                      />
                    );
                  }
                }
              })}
            </View>
          ) : (
            void 0
          )}
          {showCalender ? (
            <Box
              onMouseDown={e => this.onCalenderCursorClick(e)}
              getRef={ref => (this.toolTipRef = ref)}
              style={{
                position: "absolute",
                cursor: "auto",
                right: 75,
                paddingLeft: 19,
                paddingRight: 19,
                paddingBottom: 10,
                paddingTop: 10,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: themeColor,
                borderRadius: 4,
                zIndex: 3
              }}
              text={modifiedDate}
              textStyle={{ color: highlightColor, ...h2, numberOfLines: 1 }}
            />
          ) : (
            void 0
          )}
          {showCalender ? (
            <Box
              onMouseEnter={this.onMouseEnterCalenderView}
              onMouseLeave={this.onMouseLeaveCalenderView}
              getRef={ref => (this.ref = ref)}
              onMouseDown={e => this.onCalenderCursorClick(e)}
              style={{
                position: "absolute",
                right: 0,
                left: 0,
                width: 70,
                justifyContent: "center",
                alignItems: "center"
              }}
              render={[
                {
                  viewStyle: {
                    height: 2,
                    width: 35,
                    backgroundColor: themeColor
                  }
                }
              ]}
            />
          ) : (
            void 0
          )}
        </View>
      );
    }
  }

  class ImageComponent extends React.Component {
    state = { activeImageId: void 0 };
    onMouseEnterImage = imageId => {
      this.setState({ activeImageId: imageId });
    };
    onMouseMoveImage = imageId => {
      if (this.state.activeImageId !== imageId) {
        this.setState({ activeImageId: imageId });
      }
    };
    onMouseLeaveImage = image => {
      if (this.state.activeImageId === image._id) {
        this.setState({ activeImageId: void 0 });
      }
    };

    shouldComponentUpdate(nextProps, nextState) {
      let equal = shallowCompare(nextProps, this.props, { matchProps: { image: 1, selected: 1 } });
      if (equal) {
        equal = shallowCompare(nextState, this.state, { matchProps: { activeImageId: 1 } });
      }
      return !equal;
    }
    render() {
      const {
        selected,
        onClick,
        selectRow,
        image,
        decryptionSourceProps = {},
        rowData: { collaboratorData = {} } = {},
        userAvatarMap,
        defaultColors = {}
      } = this.props;
      const { activeImageId } = this.state;
      let { _createdBy: { firebaseUid, name: userName, _id: userId } = {} } = image;
      let backgroundColor = whiteTwo;
      let color, initials, profile_pic;
      if (decryptionSourceProps.decryptionSource == "collection" || decryptionSourceProps.decryptionSource == "group") {
        // modify group avatar's map into array, ti simplify this check
        const avatar = userAvatarMap[userId] || collaboratorData[firebaseUid] || [];
        initials = avatar[1];
        color = avatar[2];
        profile_pic = avatar[3];
        if (!color) {
          if (!defaultColors[userId]) {
            const x = Math.floor(Math.random() * 200);
            const y = Math.floor(Math.random() * 200);
            const z = Math.floor(Math.random() * 200);
            const defaultColor = "rgb(" + x + "," + y + "," + z + ")"; // default value for color (randomly generated)
            defaultColors[userId] = defaultColor;
          }
          color = defaultColors[userId];
        }
        // user name is resource's data doesn't replicate
        if (!initials && userName) {
          initials = userName
            .split(" ")
            .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
            .join("");
        }
      }

      return (
        <Box
          key={image._id}
          style={{
            height: image.thumbnail_height,
            width: image.thumbnail_width,
            backgroundColor,
            borderRadius: 4,
            marginRight: 4,
            marginBottom: 4
          }}
          render={
            <Box
              render={
                <LazyLoad offsetVertical={200} height={image.thumbnail_height} width={image.thumbnail_width}>
                  <Box
                    onMouseMove={() => this.onMouseMoveImage(image._id)}
                    onMouseEnter={() => this.onMouseEnterImage(image._id)}
                    onMouseLeave={() => this.onMouseLeaveImage(image)}
                    style={{
                      cursor: "pointer"
                    }}
                    render={[
                      {
                        onClick: () => onClick(image),
                        render: (
                          <DecryptedImage
                            source={image.viewImageUrl}
                            data={image}
                            decryptionSourceProps={decryptionSourceProps}
                            style={{
                              height: image.thumbnail_height,
                              width: image.thumbnail_width,
                              borderRadius: 4,
                              opacity: selected ? 0.4 : 1,
                              backgroundColor
                            }}
                            onLoad={() => {
                              backgroundColor = "transparent";
                            }}
                          />
                        )
                      },
                      profile_pic && {
                        viewStyle: {
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                          height: 40,
                          width: 40,
                          backgroundColor: whiteTwo,
                          borderRadius: 20,
                          zIndex: 1
                        },
                        imageStyle: {
                          width: 56,
                          height: 56,
                          objectFit: "cover"
                        },
                        image: { uri: profile_pic }
                      },
                      !profile_pic &&
                        initials && {
                          viewStyle: {
                            position: "absolute",
                            bottom: 8,
                            right: 8,
                            height: 40,
                            width: 40,
                            backgroundColor: color,
                            borderRadius: 20,
                            zIndex: 1
                          },
                          textStyle: { textAlign: "center", marginTop: 11, color: "white" },
                          text: initials.toUpperCase()
                        },
                      image.type === "video"
                        ? {
                            viewStyle: {
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              zIndex: 1
                            },
                            onClick: () => onClick(image),
                            render: [
                              {
                                viewStyle: {
                                  position: "absolute",
                                  left: image.thumbnail_width / 2 - 40,
                                  top: image.thumbnail_height / 2 - 40,
                                  padding: 12
                                },
                                image: getImage("videoIcon"),
                                imageStyle: {
                                  height: 40,
                                  width: 40
                                }
                              },
                              {
                                viewStyle: {
                                  position: "absolute",
                                  bottom: 0,
                                  opacity: 0.6,
                                  width: (image.thumbnail_width * 2) / 3,
                                  ...leftBlackGradient
                                },
                                direction: "row",
                                render: [
                                  {
                                    viewStyle: { paddingTop: 4, paddingBottom: 4, paddingLeft: 12 },
                                    textStyle: { textAlign: "center", ...h1, color: highlightColor },
                                    text: getTimeStringFromSeconds(image["resource_duration"] / 1000)
                                  }
                                ]
                              }
                            ]
                          }
                        : {},
                      image._id === activeImageId || selected
                        ? {
                            viewStyle: {
                              position: "absolute",
                              left: 0,
                              top: 0,
                              padding: 12,
                              zIndex: 4
                            },
                            onClick: () => selectRow(image),
                            image: getImage(selected ? "imageCheckedSelectedIcon" : "imageCheckedHightlightIcon")
                          }
                        : {},
                      image._id === activeImageId || selected
                        ? {
                            gradient: {
                              ...topBlackGradient
                            },
                            onClick: () => onClick(image),
                            gradientStyle: {
                              position: "absolute",
                              left: 0,
                              top: 0,
                              height: 50,
                              width: image.thumbnail_width
                            },
                            viewStyle: {
                              flex: 1
                            }
                          }
                        : void 0
                    ]}
                  />
                </LazyLoad>
              }
            />
          }
        />
      );
    }
  }

  class DateHeader extends React.Component {
    translateDate = dateObjTemp => {
      return moment(dateObjTemp).format("DD/MM/YY");
    };

    shouldComponentUpdate(nextProps) {
      let equal = shallowCompare(nextProps, this.props, {
        matchProps: { allSelected: 1, isActiveDate: 1, imageValue: 1 }
      });
      return !equal;
    }

    render() {
      const { singleSelection, isActiveDate, allSelected, unSelectMultiRow, selectMultiRow, imageValue } = this.props;
      const { date, images } = imageValue;
      let headerTransitionsStyle = singleSelection
        ? void 0
        : {
            transitionDuration: "500ms",
            transitionProperty: "left",
            left: isActiveDate || allSelected ? 0 : "-28px"
          };
      return (
        <Box
          direction="row"
          viewStyle={{
            marginBottom: 12,
            alignItems: "center",
            ...headerTransitionsStyle
          }}
          render={[
            !singleSelection && {
              viewStyle: { paddingLeft: 6, paddingRight: 6, cursor: "pointer" },
              image: getImage(allSelected ? "imageCheckedSelectedIcon" : "imageCheckedIcon"),
              onClick: () => {
                if (allSelected) {
                  unSelectMultiRow && unSelectMultiRow(images);
                } else {
                  selectMultiRow && selectMultiRow(images);
                }
              }
            },
            {
              viewStyle: {
                ...h16,
                color: allSelected ? themeColor : primaryColor
              },
              text: this.translateDate(date)
            }
          ]}
        />
      );
    }
  }

  class MasonaryList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        imageGroup: [],
        imageArray: [],
        width: 0
      };
      this.dateHeightObject = {};
      this.dateImageCountObject = {};
      this.selectedRows = {};
    }
    defaultColors = {};

    isSelectedIdsEqual = ({ selectedIds = [] }) => {
      let currentSelectedIds = Object.keys(this.selectedRows);
      let newSize = selectedIds.length;
      let equals = newSize === currentSelectedIds.length;
      if (!equals) {
        this.lastSelectedRows = this.selectedRows;
        this.selectedRows = {};
        for (let index = 0; index < newSize; index++) {
          this.selectedRows[selectedIds[index]] = true;
        }
      }
      return equals;
    };

    shouldComponentUpdate(nextProps, nextState) {
      if (this.props.shouldComponentUpdate) {
        return this.props.shouldComponentUpdate(nextProps);
      }
      let equal = shallowCompare(nextProps, this.props, {
        matchProps: { data: 1, openViews: 1, selectedIds: 1 },
        name: this.props.screenName
      });
      if (equal) {
        equal = shallowCompare(nextState, this.state, {
          name: this.props.screenName
        });
      }
      this.isSelectedIdsEqual({ selectedIds: nextProps.selectedIds });
      return !equal;
    }

    selectRow = row => {
      let { selectRow, singleSelection } = this.props;
      let id = row._id;
      let isSelected = !this.selectedRows[id];
      if (!isSelected) {
        delete this.selectedRows[id];
      } else {
        this.selectedRows[id] = true;
      }
      selectRow && selectRow(row, singleSelection);
    };

    onClick = (e, { item: image }) => {
      let { isSelectionMode, detailLink, addUri, getPath, getUri, link } = this.props;
      if (isSelectionMode && isSelectionMode()) {
        this.selectRow(image);
      } else if (detailLink) {
        if (typeof detailLink === "function") {
          detailLink = detailLink({ data: image, link });
        }
        let currentUri = getUri();
        let { query, subscribe, cache } = (currentUri && currentUri.props && currentUri.props) || {};
        detailLink = { ...detailLink, query, subscribe, cache };
        addUri && addUri(getPath(), detailLink);
      }
    };

    getImageArray = ({ data, width }) => {
      const accumImages = {};
      _adjustImages({
        accumImages,
        images: data,
        expectedHeight: 200,
        expectedColumn: 5,
        minColumn: 3,
        minHeight: 150,
        maxHeight: 250,
        availableWidth: width,
        padding: 5
      });
      let newdata = [];
      let newImageArray = [];
      let sum = [];
      let accumImagesKeys = Object.keys(accumImages);
      accumImagesKeys.forEach(rowValue => {
        accumImages[rowValue].forEach(rowDate => {
          newImageArray.push(...rowDate);
        });
      });
      let imageGroup = groupBy(newImageArray, "formattedDate");
      let imageGroupKeys = Object.keys(imageGroup);
      imageGroupKeys.forEach((imageGroupKey, index) => {
        sum[index] = 0;
        let groupImages = imageGroup[imageGroupKey];
        groupImages.forEach(image => {
          sum[index] += image.thumbnail_width;
        });
      });

      for (let i = 0; i < imageGroupKeys.length; i++) {
        if (sum[i] >= width - imageGroup[imageGroupKeys[i]].length * 5) {
          newdata.push({
            date: imageGroup[imageGroupKeys[i]][0].formattedDate,
            images: imageGroup[imageGroupKeys[i]]
          });
        } else if (
          sum[i] + sum[i + 1] >
          width -
            (imageGroup[imageGroupKeys[i]].length + imageGroup[imageGroupKeys[i + 1]]
              ? imageGroup[imageGroupKeys[i + 1]].length
              : 0) *
              5
        ) {
          newdata.push({
            date: imageGroup[imageGroupKeys[i]][0].formattedDate,
            images: imageGroup[imageGroupKeys[i]]
          });
        } else {
          let total = sum[i];
          let tempArr = [];
          let totalImage = imageGroup[imageGroupKeys[i]].length;
          while (total <= width - totalImage * 5) {
            tempArr.push({
              date: imageGroup[imageGroupKeys[i]][0].formattedDate,
              images: imageGroup[imageGroupKeys[i]]
            });
            total += sum[i + 1];
            totalImage += imageGroup[imageGroupKeys[i + 1]] ? imageGroup[imageGroupKeys[i + 1]].length : 0;
            if (total <= width - totalImage * 5) {
              i++;
            }
          }
          newdata.push(tempArr);
        }
      }
      return newdata;
    };

    isAllSelected = values => {
      let { isSelected } = this.props;
      for (var i = 0; i < values.length; i++) {
        let selected = isSelected && isSelected(values[i]);
        if (!selected) {
          return false;
        }
      }
      return true;
    };

    onMouseEnterDate = date => {
      this.setState({ activeDate: date });
    };
    onMouseMoveDate = date => {
      if (this.state.activeDate !== date) {
        this.setState({ activeDate: date });
      }
    };
    onMouseLeaveDate = date => {
      if (this.state.activeDate === date) {
        this.setState({ activeDate: void 0 });
      }
    };
    isSelected = item => {
      let selected = this.selectedRows[item._id];
      return selected;
    };

    getImageComponent = imageValue => {
      let {
        selectMultiRow,
        unSelectMultiRow,
        hideDateHeader,
        singleSelection,
        isSelectionMode,
        decryptionSourceProps,
        dataParams: { specialProps: { groupAvatars = {} } = {} } = {},
        rowData
      } = this.props;
      let { date, images } = imageValue;
      const { activeDate } = this.state;
      let allSelected = this.isAllSelected(images);
      return {
        onMouseEnter: () => {
          this.onMouseEnterDate(date);
        },
        onMouseMove: () => {
          this.onMouseMoveDate(date);
        },
        onMouseLeave: () => {
          this.onMouseLeaveDate(date);
        },
        viewStyle: { marginBottom: 20 },
        render: [
          !hideDateHeader && (
            <DateHeader
              isActiveDate={activeDate === date}
              allSelected={allSelected}
              singleSelection={singleSelection}
              unSelectMultiRow={unSelectMultiRow}
              selectMultiRow={selectMultiRow}
              imageValue={imageValue}
            />
          ),
          {
            direction: "row",
            viewStyle: { flexWrap: "wrap" },
            render: images.map((innerImageValue, index) => {
              let lastSelected = this.lastSelectedRows && this.lastSelectedRows[innerImageValue._id];
              let currentSelected = this.selectedRows && this.selectedRows[innerImageValue._id];
              return (
                <FSListItem
                  data={innerImageValue}
                  index={index}
                  onClick={this.onClick}
                  selectRow={this.selectRow}
                  lastSelected={lastSelected}
                  currentSelected={currentSelected}
                  isSelectionMode={isSelectionMode}
                >
                  {({ onClick, selectRow }) => {
                    let selected = this.isSelected(innerImageValue);
                    return (
                      <ImageComponent
                        image={innerImageValue}
                        selected={selected}
                        onClick={onClick}
                        selectRow={selectRow}
                        decryptionSourceProps={decryptionSourceProps}
                        rowData={rowData}
                        userAvatarMap={groupAvatars}
                        defaultColors={this.defaultColors}
                      />
                    );
                  }}
                </FSListItem>
              );
            })
          }
        ]
      };
    };

    render() {
      let {
        data,
        onLayout,
        getRef,
        onScroll,
        dataParams,
        scrollViewStyle,
        hideDateHeader,
        hideScroll = false,
        showCalender = false
      } = this.props;
      if (!data || !data.length) {
        return null;
      }
      let sortingOrder = "resource_lastModified";
      if (dataParams && dataParams.sort) {
        sortingOrder = Object.keys(dataParams.sort)[0];
        if (this.sortingOrder !== sortingOrder) {
          this.dateHeightObject = {};
          this.sortingOrder = sortingOrder;
        }
      }

      if (
        (this.state.width !== this.oldWidth || this.props.data !== this.oldData || !this.groupedImages) &&
        this.state.width !== 0
      ) {
        this.oldWidth = this.state.width;
        this.oldData = data;
        this.dateHeightObject = {};
        this.images = populateFormattedDate({ images: data, hideDateHeader, sortingOrder });
        this.groupedImages = this.getImageArray({ data: this.images, width: this.state.width });
      }

      let style = !hideScroll ? { flex: 1 } : {};
      let styleToPass = hideDateHeader
        ? { paddingLeft: 110, paddingRight: 110, paddingTop: 30, overflow: void 0, ...style }
        : { flex: 1, paddingLeft: 30, paddingRight: 97, ...scrollViewStyle };

      return (
        <View style={{ flex: 1, overflow: !hideScroll ? "hidden" : "visible" }}>
          <ScrollView
            style={styleToPass}
            onLayout={onLayout}
            getRef={ref => {
              this.imageScrollRef = ref;
              getRef && getRef(ref);
            }}
            onScroll={e => {
              this.calenderRef && this.calenderRef.onParentScroll(e.scrollTop);
              onScroll && onScroll(e);
            }}
          >
            <View
              onLayout={({ nativeEvent: { layout } }) => {
                let { width, height } = layout;
                if (width > 0 && (width !== this.state.width || height !== this.state.height)) {
                  this.setState({ width, height });
                }
              }}
            >
              {this.state.width
                ? this.groupedImages.map(image => {
                    return Array.isArray(image) ? (
                      <Box
                        id={moment(image[0]["date"]).format("MMM D YYYY")}
                        onLayout={({ nativeEvent: { layout } }) => {
                          let { height } = layout;
                          this.dateImageCountObject[moment(image[0]["date"]).format("MMM D YYYY")] = 1;
                          this.dateHeightObject[moment(image[0]["date"]).format("MMM D YYYY")] = height;
                        }}
                        direction="row"
                        render={image.map(imageValue => this.getImageComponent(imageValue))}
                      />
                    ) : (
                      <Box
                        id={moment(image["date"]).format("MMM D YYYY")}
                        onLayout={({ nativeEvent: { layout } }) => {
                          let { height } = layout;
                          this.dateImageCountObject[moment(image["date"]).format("MMM D YYYY")] =
                            image["images"].length;
                          this.dateHeightObject[moment(image["date"]).format("MMM D YYYY")] = height;
                        }}
                        render={[this.getImageComponent(image)]}
                      />
                    );
                  })
                : void 0}
            </View>
          </ScrollView>
          {showCalender && (
            <Calender
              ref={child => {
                this.calenderRef = child;
              }}
              dateHeightObject={this.dateHeightObject}
              dateImageCountObject={this.dateImageCountObject}
              parentRef={this.imageScrollRef}
            />
          )}
        </View>
      );
    }
  }

  return MasonaryList;
};
