import React from "react";
import moment from "moment";

export default ({ Box, theme, getImage, getSizeFormat, I18N }) => {
  const { colors, fonts, bgs } = theme;
  const { h16_l, h9, h20 } = fonts;
  const { primaryColor, brownGrey, themeColor } = colors;
  const { lightPink } = bgs;

  class WebImageInfoComponent extends React.Component {
    getDetailRow = ({ image, primaryText, secondaryText1, secondaryText2 }) => {
      return {
        direction: "row",
        viewStyle: { alignItems: "center", paddingTop: 8, paddingBottom: 8 },
        render: [
          {
            viewStyle: { paddingLeft: 12, paddingRight: 12 },
            image: getImage(image)
          },
          {
            width: "1fr",
            viewStyle: { paddingLeft: 12, paddingRight: 12 },
            render: [
              {
                textStyle: { ...h16_l, color: primaryColor },
                text: primaryText
              },
              (secondaryText1 || secondaryText2) && {
                direction: "row",
                viewStyle: { paddingTop: 6, alignItems: "center" },
                textStyle: { ...h9, color: brownGrey },
                render: [
                  secondaryText1 && {
                    text: secondaryText1
                  },
                  secondaryText1 &&
                    secondaryText2 && {
                      viewStyle: {
                        marginLeft: 5,
                        marginRight: 5,
                        width: 3,
                        height: 3,
                        backgroundColor: brownGrey,
                        borderRadius: 1.5
                      }
                    },
                  secondaryText2 && {
                    text: secondaryText2
                  }
                ]
              }
            ]
          }
        ]
      };
    };

    render() {
      let { onClose, data: value } = this.props;
      let dimension =
        value.resource_height && value.resource_width ? `${value.resource_height} x ${value.resource_width}` : "";
      let size = value.initialSize;
      if (size !== undefined) {
        size = getSizeFormat(size);
      }
      let dateObj = new Date(value.resource_lastModified);
      let modifiedDate = `${dateObj.getDate()} ${I18N.t(`month_${dateObj.getMonth()}`)} ${dateObj.getFullYear()}`;
      let modifiedDay = I18N.t(moment(value.resource_lastModified).format("ddd"));
      let city = value?.location?.city?.name;
      return (
        <Box
          render={[
            {
              direction: "row",
              viewStyle: {
                height: 75,
                paddingLeft: 18,
                paddingRight: 18,
                alignItems: "center",
                borderBottomWidth: 2,
                borderColor: lightPink
              },
              textStyle: {
                ...h20,
                color: themeColor
              },
              render: [
                { viewStyle: { paddingLeft: 12, paddingRight: 12 }, width: "1fr", text: I18N.t("infoCaps") },
                {
                  viewStyle: {
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: 48,
                    height: 48,
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  },
                  image: getImage("infoCrossWebIcon"),
                  onClick: onClose
                }
              ]
            },
            {
              viewStyle: { paddingLeft: 18, paddingRight: 18, paddingTop: 8, paddingBottom: 16 },
              render: [
                {
                  render: [
                    {
                      viewStyle: { paddingTop: 16, paddingBottom: 16, paddingLeft: 12, paddingRight: 12 },
                      textStyle: {
                        ...h16_l,
                        color: primaryColor
                      },
                      text: I18N.t("details")
                    },
                    this.getDetailRow({
                      image: "infoCalenderGreyIcon",
                      primaryText: modifiedDate,
                      secondaryText1: modifiedDay,
                      secondaryText2: moment(value.resource_lastModified)
                        .format("hh:mm a")
                        .replace("am", I18N.t("am"))
                        .replace("pm", I18N.t("pm")),
                      separator: "."
                    }),
                    this.getDetailRow({
                      image: "infoImageGreyIcon",
                      primaryText: value.resource_name,
                      secondaryText1: size,
                      secondaryText2: dimension
                    }),
                    this.getDetailRow({
                      image: "infoLinkGreyIcon",
                      primaryText: I18N.t("throughPublicLink")
                    }),
                    city &&
                      this.getDetailRow({
                        image: "locationIcon",
                        primaryText: city
                      })
                  ]
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return WebImageInfoComponent;
};
