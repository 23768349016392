import {
  Panel as PanelHoc,
  PanelHeader as PanelHeaderHoc,
  PanelTitle as PanelTitleHoc,
  PanelFooter as PanelFooterHoc
} from "../../panel";

export default ({
  theme = {},
  Box,
  View,
  withContext,
  resolveExp,
  renderProps,
  resolveMQ,
  resolveActions,
  StatusBar
}) => {
  const PanelTitle = withContext(
    PanelTitleHoc({
      Box,
      resolveExp,
      resolveMQ,
      theme: theme.panelTitleStyle
    }),
    {
      activeMQ: "ActiveMQ",
      aggregates: "Screen.Connect.aggregates",
      data: "Screen.Connect.data",
      link: "ScreenRoute.link"
    }
  );

  const PanelHeader = withContext(
    PanelHeaderHoc({
      Box,
      resolveMQ,
      resolveActions,
      theme: theme.panelHeaderStyle
    }),
    {
      activeMQ: "ActiveMQ",
      visibleView: "NavBody.visibleView",
      isExpanded: "NavBody.isExpanded",
      selectionMode: "Screen.SelectionStore.selectionMode",
      selectedIds: "Screen.SelectionStore.selectedIds",
      link: "ScreenRoute.link",
      user: "User.user"
    }
  );

  const PanelFooter = withContext(
    PanelFooterHoc({
      Box,
      resolveMQ,
      resolveActions,
      theme: theme.panelFooterStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const Panel = withContext(
    PanelHoc({
      View,
      resolveMQ,
      renderProps,
      HeaderSM: PanelHeader,
      HeaderMD: PanelHeader,
      Footer: PanelFooter,
      LoadingBar: StatusBar,
      theme: theme.panelStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );
  return { StatusBar, PanelTitle, PanelHeader, PanelFooter, Panel };
};
