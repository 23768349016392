import React from "react";
import { Box, getImage, theme, withContext, RowAction, I18N } from "../../../FsCloudComponent";
import { getSizeFormat } from "client-utility/lib/FileUtility";
import ResourceActions from "./../resource/ResourceActions";
import SpaceInfo from "./SpaceInformation";

const { fonts, colors } = theme;
const { h16_l, h24_xl } = fonts;
const { primaryColor, themeColor } = colors;

class MySignatureComponent extends React.Component {
  render() {
    let { data } = this.props;
    if (data && Array.isArray(data)) {
      data = data[0];
    }
    let totalSpace = "0 B";
    let trashSpace = "0 B";
    let usedSpace = "0 B";
    let nonUploadedSize = "0 B";
    if (data) {
      totalSpace = data.nolimit ? I18N.t("unlimited") : getSizeFormat(data.totalSpace || 0);
      usedSpace = getSizeFormat((data.usedSpace || 0) + (data.trash || 0));
      trashSpace = getSizeFormat(data.trash || 0);
      nonUploadedSize = getSizeFormat(data.nonUploadedSize || 0);
      totalSpace = totalSpace.replace(".", ",");
      usedSpace = usedSpace.replace(".", ",");
      trashSpace = trashSpace.replace(".", ",");
      nonUploadedSize = nonUploadedSize.replace(".", ",");
    }

    return (
      <Box
        render={[
          {
            direction: "row",
            viewStyle: {
              paddingLeft: 110,
              paddingRight: 110
            },
            render: [
              {
                image: getImage("storageFolderIcon")
              },
              {
                width: 458,
                viewStyle: {
                  paddingLeft: 30,
                  paddingRight: 30
                },
                render: [
                  {
                    textStyle: {
                      ...h16_l,
                      color: primaryColor
                    },
                    text: I18N.t("totalStorage")
                  },
                  {
                    viewStyle: {
                      paddingTop: 6
                    },
                    textStyle: {
                      ...h24_xl,
                      color: themeColor
                    },
                    text: totalSpace
                  }
                ]
              },
              {
                render: [
                  {
                    viewStyle: { paddingTop: 8 },
                    textStyle: {
                      ...h16_l,
                      textAlign: "right",
                      color: primaryColor
                    },
                    text: `${usedSpace} ${I18N.t("inUse")}`
                  },
                  {
                    direction: "row",
                    viewStyle: {
                      paddingTop: 8,
                      justifyContent: "flex-end"
                    },
                    render: [
                      {
                        textStyle: {
                          ...h16_l,
                          color: primaryColor
                        },
                        text: `${nonUploadedSize} ${I18N.t("inNonUploaded")}`
                      },
                      nonUploadedSize !== "0 B" && {
                        direction: "row",
                        render: [
                          {
                            viewStyle: { paddingLeft: 2, paddingRight: 2 },
                            textStyle: {
                              ...h16_l,
                              color: primaryColor
                            },
                            text: `-`
                          },
                          {
                            viewStyle: { cursor: "pointer" },
                            textStyle: {
                              ...h16_l,
                              color: themeColor
                            },
                            text: I18N.t("empty"),
                            Container: RowAction,
                            containerProps: {
                              data: [],
                              action: ResourceActions.clearNonUpload
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    direction: "row",
                    viewStyle: { paddingTop: 8, justifyContent: "flex-end" },
                    render: [
                      {
                        textStyle: {
                          ...h16_l,
                          color: primaryColor
                        },
                        text: `${trashSpace} ${I18N.t("inTheBin")}`
                      },
                      trashSpace !== "0 B" && {
                        direction: "row",
                        render: [
                          {
                            viewStyle: { paddingLeft: 2, paddingRight: 2 },
                            textStyle: {
                              ...h16_l,
                              color: primaryColor
                            },
                            text: `-`
                          },
                          {
                            viewStyle: { cursor: "pointer" },
                            textStyle: {
                              ...h16_l,
                              color: themeColor
                            },
                            text: I18N.t("empty"),
                            Container: RowAction,
                            containerProps: {
                              data: [],
                              action: ResourceActions.clearTrash
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            direction: "row",
            viewStyle: {
              paddingLeft: 100,
              paddingRight: 100,
              paddingTop: 20,
              flex: 1
            },
            render: [
              {
                viewStyle: { flex: 1 },
                render: <SpaceInfo data={data} source="usedSpace" pieChartWidth={180} />
              },
              {
                viewStyle: { flex: 1 },
                render: <SpaceInfo data={data} source="trashedSpace" pieChartWidth={180} />
              }
            ]
          }
        ]}
      />
    );
  }
}

MySignatureComponent = withContext(MySignatureComponent, {
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user"
});

export default {
  MySignature: {
    component: "custom",
    Component: MySignatureComponent,
    connectProps: {
      uri: {
        props: {
          query: {
            view: "userSpace"
          },
          subscribe: true
        }
      }
    }
  }
};
