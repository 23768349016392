export default ({ resolveImage }) => {
  const docExtShadowSMSprite = {
    uri: "/webImages/docExtShadowIcons/SM/1_sprite.png",
    shadow3dsIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    aacShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -56px",
      objectFit: "none"
    },
    aiShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -102px",
      objectFit: "none"
    },
    aviShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -148px",
      objectFit: "none"
    },
    bmpShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -194px",
      objectFit: "none"
    },
    cadShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -240px",
      objectFit: "none"
    },
    cdrShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -286px",
      objectFit: "none"
    },
    cssShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -332px",
      objectFit: "none"
    },
    csvShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -378px",
      objectFit: "none"
    },
    datShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -424px",
      objectFit: "none"
    },
    dllShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -10px",
      objectFit: "none"
    },
    dmgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -56px",
      objectFit: "none"
    },
    docShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -102px",
      objectFit: "none"
    },
    docxShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -148px",
      objectFit: "none"
    },
    epsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -194px",
      objectFit: "none"
    },
    excelShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -240px",
      objectFit: "none"
    },
    flaShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -286px",
      objectFit: "none"
    },
    flvShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -332px",
      objectFit: "none"
    },
    gifShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -378px",
      objectFit: "none"
    },
    htmlShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -424px",
      objectFit: "none"
    },
    inddShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -10px",
      objectFit: "none"
    },
    isoShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -56px",
      objectFit: "none"
    },
    jpgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -102px",
      objectFit: "none"
    },
    jsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -148px",
      objectFit: "none"
    },
    midiShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -194px",
      objectFit: "none"
    },
    movShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -240px",
      objectFit: "none"
    },
    mp3ShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -286px",
      objectFit: "none"
    },
    mpgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -332px",
      objectFit: "none"
    },
    odpShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -378px",
      objectFit: "none"
    },
    odsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -424px",
      objectFit: "none"
    },
    odtShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -10px",
      objectFit: "none"
    },
    pdfShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -56px",
      objectFit: "none"
    },
    phpShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -102px",
      objectFit: "none"
    },
    pngShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -148px",
      objectFit: "none"
    },
    pptShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -194px",
      objectFit: "none"
    },
    psdShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -240px",
      objectFit: "none"
    },
    psShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -286px",
      objectFit: "none"
    },
    rawShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -332px",
      objectFit: "none"
    },
    sqlShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -378px",
      objectFit: "none"
    },
    svgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -242px",
      objectFit: "none"
    },
    tifShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -10px",
      objectFit: "none"
    },
    txtShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -56px",
      objectFit: "none"
    },
    unknownShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -102px",
      objectFit: "none"
    },
    wmvShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -148px",
      objectFit: "none"
    },
    wordShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -240px",
      objectFit: "none"
    },
    xlsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -240px",
      objectFit: "none"
    },
    xlsxShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -286px",
      objectFit: "none"
    },
    xmlShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -332px",
      objectFit: "none"
    },
    zipShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -378px",
      objectFit: "none"
    }
  };

  const docExtShadowMDSprite = {
    uri: "/webImages/docExtShadowIcons/MD/1_sprite.png",
    shadow3dsIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    aacShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -68px",
      objectFit: "none"
    },
    aiShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -126px",
      objectFit: "none"
    },
    aviShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -184px",
      objectFit: "none"
    },
    bmpShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -242px",
      objectFit: "none"
    },
    cadShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -300px",
      objectFit: "none"
    },
    cdrShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -358px",
      objectFit: "none"
    },
    cssShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-10px -416px",
      objectFit: "none"
    },
    csvShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -10px",
      objectFit: "none"
    },
    datShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -68px",
      objectFit: "none"
    },
    dllShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-298px -10px",
      objectFit: "none"
    },
    dmgShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -126px",
      objectFit: "none"
    },
    docShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -184px",
      objectFit: "none"
    },
    docxShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -242px",
      objectFit: "none"
    },
    epsShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -300px",
      objectFit: "none"
    },
    excelShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -358px",
      objectFit: "none"
    },
    flaShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-58px -416px",
      objectFit: "none"
    },
    flvShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -10px",
      objectFit: "none"
    },
    gifShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -68px",
      objectFit: "none"
    },
    htmlShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -126px",
      objectFit: "none"
    },
    inddShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -184px",
      objectFit: "none"
    },
    isoShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -242px",
      objectFit: "none"
    },
    jpgShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -300px",
      objectFit: "none"
    },
    jsShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -358px",
      objectFit: "none"
    },
    midiShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-106px -416px",
      objectFit: "none"
    },
    movShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -10px",
      objectFit: "none"
    },
    mp3ShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -68px",
      objectFit: "none"
    },
    mpgShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -126px",
      objectFit: "none"
    },
    odpShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -184px",
      objectFit: "none"
    },
    odsShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -242px",
      objectFit: "none"
    },
    odtShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -300px",
      objectFit: "none"
    },
    pdfShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -358px",
      objectFit: "none"
    },
    phpShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-154px -416px",
      objectFit: "none"
    },
    pngShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -10px",
      objectFit: "none"
    },
    pptShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -68px",
      objectFit: "none"
    },
    psdShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -126px",
      objectFit: "none"
    },
    psShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -184px",
      objectFit: "none"
    },
    rawShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -242px",
      objectFit: "none"
    },
    sqlShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -300px",
      objectFit: "none"
    },
    svgShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -358px",
      objectFit: "none"
    },
    tifShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-202px -416px",
      objectFit: "none"
    },
    txtShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -10px",
      objectFit: "none"
    },
    unknownShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -68px",
      objectFit: "none"
    },
    wmvShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -126px",
      objectFit: "none"
    },
    wordShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -184px",
      objectFit: "none"
    },
    xlsShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -242px",
      objectFit: "none"
    },
    xlsxShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -300px",
      objectFit: "none"
    },
    xmlShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -358px",
      objectFit: "none"
    },
    zipShadowIcon: {
      width: 38,
      height: 48,
      objectPosition: "-250px -416px",
      objectFit: "none"
    }
  };

  const shadow3dsIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.shadow3dsIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.shadow3dsIcon
        }
      }
    });
  const aacShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.aacShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.aacShadowIcon
        }
      }
    });
  const aiShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.aiShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.aiShadowIcon
        }
      }
    });
  const aviShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.aviShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.aviShadowIcon
        }
      }
    });
  const bmpShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.bmpShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.bmpShadowIcon
        }
      }
    });
  const cadShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.cadShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.cadShadowIcon
        }
      }
    });
  const cdrShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.cdrShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.cdrShadowIcon
        }
      }
    });
  const cssShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.cssShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.cssShadowIcon
        }
      }
    });
  const csvShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.csvShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.csvShadowIcon
        }
      }
    });
  const datShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.datShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.datShadowIcon
        }
      }
    });
  const dllShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.dllShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.dllShadowIcon
        }
      }
    });
  const dmgShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.dmgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.dmgShadowIcon
        }
      }
    });
  const docShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.docShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.docShadowIcon
        }
      }
    });
  const docxShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.docxShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.docxShadowIcon
        }
      }
    });
  const epsShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.epsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.epsShadowIcon
        }
      }
    });
  const excelShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.excelShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.excelShadowIcon
        }
      }
    });
  const flaShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.flaShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.flaShadowIcon
        }
      }
    });
  const flvShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.flvShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.flvShadowIcon
        }
      }
    });
  const gifShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.gifShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.gifShadowIcon
        }
      }
    });
  const htmlShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.htmlShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.htmlShadowIcon
        }
      }
    });
  const inddShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.inddShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.inddShadowIcon
        }
      }
    });
  const isoShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.isoShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.isoShadowIcon
        }
      }
    });
  const jpgShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.jpgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.jpgShadowIcon
        }
      }
    });
  const jsShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.jsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.jsShadowIcon
        }
      }
    });
  const midiShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.midiShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.midiShadowIcon
        }
      }
    });
  const movShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.movShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.movShadowIcon
        }
      }
    });
  const mp3ShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.mp3ShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.mp3ShadowIcon
        }
      }
    });
  const mpgShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.mpgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.mpgShadowIcon
        }
      }
    });
  const odpShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.odpShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.odpShadowIcon
        }
      }
    });
  const odsShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.odsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.odsShadowIcon
        }
      }
    });
  const odtShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.odtShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.odtShadowIcon
        }
      }
    });
  const pdfShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.pdfShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.pdfShadowIcon
        }
      }
    });
  const phpShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.phpShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.phpShadowIcon
        }
      }
    });
  const pngShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.pngShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.pngShadowIcon
        }
      }
    });
  const pptShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.pptShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.pptShadowIcon
        }
      }
    });
  const psShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.psShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.psShadowIcon
        }
      }
    });
  const psdShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.psdShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.psdShadowIcon
        }
      }
    });
  const rawShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.rawShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.rawShadowIcon
        }
      }
    });
  const sqlShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.sqlShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.sqlShadowIcon
        }
      }
    });
  const svgShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.svgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.svgShadowIcon
        }
      }
    });
  const tifShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.tifShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.tifShadowIcon
        }
      }
    });
  const txtShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.txtShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.txtShadowIcon
        }
      }
    });
  const unknownShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.unknownShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.unknownShadowIcon
        }
      }
    });
  const wmvShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.wmvShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.wmvShadowIcon
        }
      }
    });
  const wordShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.wordShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.wordShadowIcon
        }
      }
    });
  const xlsShadowIcon=()=>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.xlsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.xlsShadowIcon
        }
      }
    });
  const xlsxShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.xlsxShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.xlsxShadowIcon
        }
      }
    });
  const xmlShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.xmlShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.xmlShadowIcon
        }
      }
    });
  const zipShadowIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.zipShadowIcon
        }
      },
      MD: {
        uri: docExtShadowMDSprite.uri,
        spriteProps: {
          ...docExtShadowMDSprite.zipShadowIcon
        }
      }
    });

  return {
    shadow3dsIcon,
    aacShadowIcon,
    aiShadowIcon,
    aviShadowIcon,
    bmpShadowIcon,
    cadShadowIcon,
    cdrShadowIcon,
    cssShadowIcon,
    csvShadowIcon,
    datShadowIcon,
    dllShadowIcon,
    dmgShadowIcon,
    docShadowIcon,
    docxShadowIcon,
    epsShadowIcon,
    excelShadowIcon,
    flaShadowIcon,
    flvShadowIcon,
    gifShadowIcon,
    htmlShadowIcon,
    inddShadowIcon,
    isoShadowIcon,
    jpgShadowIcon,
    jsShadowIcon,
    midiShadowIcon,
    movShadowIcon,
    mp3ShadowIcon,
    mpgShadowIcon,
    odpShadowIcon,
    odsShadowIcon,
    odtShadowIcon,
    pdfShadowIcon,
    phpShadowIcon,
    pngShadowIcon,
    pptShadowIcon,
    psdShadowIcon,
    psShadowIcon,
    rawShadowIcon,
    sqlShadowIcon,
    svgShadowIcon,
    tifShadowIcon,
    txtShadowIcon,
    unknownShadowIcon,
    wmvShadowIcon,
    wordShadowIcon,
    xlsShadowIcon,
    xlsxShadowIcon,
    xmlShadowIcon,
    zipShadowIcon
  };
};
