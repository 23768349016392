import React from "react";
import { Box, ImageViewer, getImage, RowAction, theme } from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import { isImportFromGroupDetailVisible, isRemoveFromGroupDetailVisible } from "../resource/ResourceUtility";
import { SECURE_TYPES } from "../../../Constants";

const { colors, fonts, gradients } = theme;
const { highlightColor } = colors;
const { h16 } = fonts;

const metadata = {
  gallery: {
    addItemToCollectionForm: "addImagesToAlbumForm",
    resourceShareViaAction: "webShareGallery",
    infoWeb: "imageInfoWeb"
  },
  album: {
    addItemToCollectionForm: "addImagesToAlbumForm",
    resourceShareViaAction: "webShareGallery",
    removeFromCollection: "removeFromAlbum",
    infoWeb: "imageInfoWeb"
  },
  places: {
    addItemToCollectionForm: "addImagesToAlbumForm",
    resourceShareViaAction: "webShareGallery",
    infoWeb: "imageInfoWeb"
  },
  doc: {
    addItemToCollectionForm: "addDocsToSetForm",
    resourceShareViaAction: "webShareDoc",
    infoWeb: "infoWeb"
  },
  set: {
    addItemToCollectionForm: "addDocsToSetForm",
    resourceShareViaAction: "webShareDoc",
    removeFromCollection: "removeFromSet",
    infoWeb: "infoWeb"
  },
  vaultGallery: {
    addItemToCollectionForm: "addImagesToVaultAlbumForm",
    infoWeb: "imageInfoWeb"
  },
  vaultDoc: {
    addItemToCollectionForm: "addDocsToVaultSetForm",
    infoWeb: "infoWeb"
  },
  vaultAlbum: {
    addItemToCollectionForm: "addImagesToVaultAlbumForm",
    removeFromCollection: "removeFromAlbum",
    infoWeb: "imageInfoWeb"
  },
  vaultSet: {
    addItemToCollectionForm: "addDocsToVaultSetForm",
    removeFromCollection: "removeFromSet",
    infoWeb: "infoWeb"
  }
};

const renderHeaderStyle = {
  containerStyle: {
    direction: "row",
    gradient: {
      ...gradients.blackGradient
    },
    gradientStyle: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      elevation: 1,
      zIndex: 2
    },
    viewStyle: {
      alignItems: "center",
      height: 64,
      paddingLeft: 18,
      paddingRight: 18
    },
    textStyle: {
      ...h16,
      color: highlightColor,
      numberOfLines: 1
    }
  },
  actionStyle: {
    viewStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      width: 48,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const renderHeader = ({ onClose, value: data = {}, origin, fromShare }) => {
  let { containerStyle, actionStyle } = renderHeaderStyle;
  let originMetadata = metadata[origin];
  let { removeFromCollection, addItemToCollectionForm, resourceShareViaAction, infoWeb } = originMetadata;
  let {
    sharedCollection,
    resourceOwner,
    collectionOwner,
    type,
    secureType,
    converted_mp4_url,
    converted_jpeg_url
  } = data;
  let isVault = secureType === SECURE_TYPES.VAULT;
  let isArchive = secureType === SECURE_TYPES.ARCHIVE;
  let isCollection = data.collection && data.collection._id;
  resourceOwner = !sharedCollection || resourceOwner; // if not shared collection, then current user will be resource owner
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backArrowWhiteIcon"),
      onClick: onClose
    },
    { width: "1fr" },
    {
      direction: "row",
      render: Object.keys(data).length
        ? [
            !fromShare &&
              !isVault &&
              !isArchive &&
              ((type === "video" ? converted_mp4_url : converted_jpeg_url) || type === "doc") &&
              resourceOwner && (
                <RowAction data={data} action={ResourceActions[resourceShareViaAction]} image={"shareWhiteIcon"} />
              ),
            resourceOwner && !fromShare && !isArchive && (
              <RowAction
                data={data}
                action={{
                  ...ResourceActions[addItemToCollectionForm],
                  collection: data.collection
                }}
                image={"addToCollectionWhiteIcon"}
              />
            ),
            !fromShare && isCollection && (resourceOwner || collectionOwner) && (
              <RowAction
                data={data}
                action={{
                  ...ResourceActions[removeFromCollection]
                }}
                image={"removeToCollectionWhiteIcon"}
              />
            ),
            resourceOwner && !fromShare && (
              <RowAction data={data} action={ResourceActions.rename} image={"editWhiteIcon"} />
            ),
            !isCollection && !data.private && !fromShare && !isVault && !isArchive && (
              <RowAction data={data} action={ResourceActions.makePrivate} image={"makePrivateWhiteIcon"} />
            ),
            <RowAction data={data} action={ResourceActions.download} image={"downloadWhiteIcon"} />,
            resourceOwner && !fromShare && (
              <RowAction
                data={data}
                action={{
                  ...ResourceActions[infoWeb],
                  origin
                }}
                image={"infoWhiteIcon"}
              />
            ),
            data && (!isCollection || (resourceOwner && collectionOwner)) && !data.favourite && !fromShare && (
              <RowAction data={data} action={ResourceActions.markFavourite} />
            ),
            data && (!isCollection || (resourceOwner && collectionOwner)) && data.favourite && !fromShare && (
              <RowAction data={data} action={ResourceActions.unmarkFavourite} />
            ),
            resourceOwner && !fromShare && !isVault && !isArchive && (
              <RowAction data={data} action={ResourceActions.moveToVault} image={"moveToVaultWhiteIcon"} />
            ),
            resourceOwner && !fromShare && isVault && (
              <RowAction data={data} action={ResourceActions.removeFromVault} image={"removeFromVaultWhiteIcon"} />
            ),
            resourceOwner && !fromShare && !isVault && !isArchive && (
              <RowAction data={data} action={ResourceActions.moveToArchive} image={"moveToArchiveWhiteIcon"} />
            ),
            resourceOwner && !fromShare && isArchive && (
              <RowAction data={data} action={ResourceActions.removeFromArchive} image={"removeFromArchiveWhiteIcon"} />
            ),
            resourceOwner && !fromShare && !isVault && !isArchive && (
              <RowAction data={data} action={ResourceActions.markDelete} image={"deleteWhiteIcon"} />
            )
          ]
        : []
    }
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

const groupGalleryRenderHeader = ({ onClose, value: data, origin, user }) => {
  let { containerStyle, actionStyle } = renderHeaderStyle;
  let originMetadata = metadata[origin];
  let { infoWeb } = originMetadata;
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backArrowWhiteIcon"),
      onClick: onClose
    },
    { width: "1fr" },
    {
      direction: "row",
      render: Object.keys(data).length
        ? [
            <RowAction
              data={data}
              action={{
                ...ResourceActions[infoWeb],
                origin
              }}
              image={"infoWhiteIcon"}
            />,
            isImportFromGroupDetailVisible({ data, user }) && (
              <RowAction data={data} action={ResourceActions.importItemFromGroup} image={"importWhiteIcon"} />
            ),
            isRemoveFromGroupDetailVisible({ data, user }) && (
              <RowAction data={data} action={ResourceActions.removeItemFromGroup} image={"pinRemoveWhiteIcon"} />
            )
          ]
        : []
    }
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

class HighlightGalleryRenderHeader extends React.Component {
  state = {};

  render() {
    let { onClose, value: data, origin, fromHighlight } = this.props;
    let { containerStyle, actionStyle } = renderHeaderStyle;
    let originMetadata = metadata[origin];
    let { infoWeb } = originMetadata;
    let renderToShow = [
      {
        ...actionStyle,
        image: getImage("backArrowWhiteIcon"),
        onClick: onClose
      },
      { width: "1fr" },
      data && fromHighlight === "picOfDay" && !data.exported && (
        <RowAction
          data={data}
          action={{
            ...ResourceActions.importPhotoToGallery,
            updateStateOnInvoke: (_, props) => {
              let { invokeResult } = props;
              if (invokeResult && !invokeResult.error) {
                data.exported = true;
                data.deleted = false;
                this.setState({});
              }
            }
          }}
        >
          <Box {...actionStyle} image={getImage("importWhiteIcon")} />
        </RowAction>
      ),
      data && (fromHighlight !== "picOfDay" || data.exported) && (
        <RowAction data={data} action={ResourceActions.webShareGallery}>
          <Box {...actionStyle} image={getImage("shareWhiteIcon")} />
        </RowAction>
      ),
      <RowAction
        data={data}
        action={{
          ...ResourceActions[infoWeb],
          origin
        }}
        image={"infoWhiteIcon"}
      />
    ];
    return <Box {...containerStyle} render={renderToShow} />;
  }
}

export default class ImageDetailComponent extends React.Component {
  render() {
    let { data, fromGroup, fromHighlight, ...restProps } = this.props;
    return (
      <ImageViewer
        {...restProps}
        images={data}
        renderHeader={
          fromGroup
            ? groupGalleryRenderHeader
            : fromHighlight
            ? props => {
                return <HighlightGalleryRenderHeader {...props} fromHighlight={fromHighlight} />;
              }
            : renderHeader
        }
      />
    );
  }
}
