export default ({ images }) => {
  const { checkedIcon, uncheckedIcon } = images;
  const checkedStyle = {
    checkedIcon: checkedIcon,
    uncheckedIcon: uncheckedIcon,
    boxStyle: {
      imageStyle: {
        height: 16,
        width: 16
      },
      imageProps: { resizeMode: "contain" }
    }
  };
  return {
    checkedStyle
  };
};
