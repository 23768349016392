import {
  ReactNativeDownload,
  formatLocalDataToResourceData,
  insertMetadataInSqlRecursively,
  getToInsertMetadata,
  updateMetadataInSqlite,
  Platform,
  deviceInfo,
  showMessage,
  I18N,
  getUser,
  firebaseSave
} from "../../../../FsCloudComponent";
import {
  getFileUploadsTableCount,
  getFileDownloadsTableCount,
  getFileUploadsFromUploadTable,
  getFileDownloadsFromDownloadTable,
  deleteFileUploadsFromUploadTable,
  deleteFileDownloadsFromDownloadTable
} from "../../../../Sqlite";
import uuid from "uuid/v4";

const { getBooleanFromLocalStorage, setBooleanInLocalStorage } = ReactNativeDownload;

let SQL_FETCH_LIMIT = 500;
let portedMetadataCount = 0;

const portFileUploadsRecursively = async (progressCallback, errors) => {
  let fileUploadData = await getFileUploadsFromUploadTable({ limit: SQL_FETCH_LIMIT });
  if (fileUploadData && fileUploadData.length) {
    let metadata = [],
      toDeleteUploadIds = [];
    for (let uploadRow of fileUploadData) {
      if (uploadRow && uploadRow.resource) {
        if (uploadRow.status === "preparing") {
          uploadRow.status = "failed";
        }
        metadata.push(formatLocalDataToResourceData(uploadRow));
        toDeleteUploadIds.push(uploadRow.resource);
      }
    }
    let { toInsertData } = await getToInsertMetadata(metadata);
    toInsertData.length &&
      (await insertMetadataInSqlRecursively({
        data: toInsertData,
        errors
      }));
    toDeleteUploadIds.length && (await deleteFileUploadsFromUploadTable(toDeleteUploadIds));
    portedMetadataCount += fileUploadData.length;
    progressCallback && progressCallback(portedMetadataCount);
    if (fileUploadData.length >= SQL_FETCH_LIMIT) {
      await portFileUploadsRecursively(progressCallback, errors);
    }
  }
};

const portFileDownloadsRecursively = async (progressCallback, errors) => {
  let fileDownloadData = await getFileDownloadsFromDownloadTable({ limit: SQL_FETCH_LIMIT });

  if (fileDownloadData && fileDownloadData.length) {
    let metadata = [],
      toDeleteDownloadIds = [];
    for (let downloadRow of fileDownloadData) {
      if (downloadRow && downloadRow.resource) {
        let {
          resource: _id,
          path: resource_url,
          uri: localUri,
          thumbnail_url,
          fileName: resource_name,
          fileType: type,
          status,
          pauseReason,
          stopDownload,
          jobId: downloadJobId,
          skipNetworkCheck,
          onlyOnWifi
        } = downloadRow;
        if (Platform.OS === "android" && localUri && !localUri.startsWith("file://")) {
          localUri = `file://${localUri}`;
        }
        metadata.push({
          _id,
          resource_url,
          localUri,
          thumbnail_url,
          resource_name,
          type,
          status,
          pauseReason,
          stopDownload,
          downloadJobId,
          skipNetworkCheck,
          onlyOnWifi,
          actionType: "download"
        });
        toDeleteDownloadIds.push(_id);
      }
    }
    let { toInsertData, toUpdateData } = await getToInsertMetadata(metadata);
    toInsertData.length &&
      (await insertMetadataInSqlRecursively({
        data: toInsertData,
        errors
      }));
    toUpdateData.length && (await updateMetadataInSqlite({ data: toUpdateData, errors }));
    toDeleteDownloadIds.length && (await deleteFileDownloadsFromDownloadTable(toDeleteDownloadIds));
    portedMetadataCount += fileDownloadData.length;
    progressCallback && progressCallback(portedMetadataCount);
    if (fileDownloadData.length >= SQL_FETCH_LIMIT) {
      await portFileDownloadsRecursively(progressCallback, errors);
    }
  }
};

export const onPortSuccess = async () => {
  await setBooleanInLocalStorage("metadataPorted", true);
};

export const onPortError = async err => {
  let errorMessage = (err && err.message) || "Unknown error in porting metadata.";
  await setBooleanInLocalStorage("metadataPortingError", true);
  await setBooleanInLocalStorage("metadataPorted", true);
  try {
    let user = getUser && getUser();
    if (user) {
      let insert = {
        _id: uuid(),
        deviceId: deviceInfo.deviceId,
        deviceName: deviceInfo.deviceName,
        error: errorMessage,
        _createdOn: new Date(),
        _updatedOn: new Date(),
        user: {
          firebaseUid: user.uid
        }
      };
      await firebaseSave({ table: "upload_porting_logs", insert });
    }
  } catch (err) {
    // Error in onPortError firebaseSave
  }
};

export const getUploadPortingCount = async () => {
  try {
    let metadataPorted = await getBooleanFromLocalStorage("metadataPorted");
    if (metadataPorted) {
      return;
    }
    let uploadCount = await getFileUploadsTableCount();
    let downloadCount = await getFileDownloadsTableCount();
    let totalCount = (uploadCount || 0) + (downloadCount || 0);
    if (!totalCount) {
      await onPortSuccess();
      return;
    }
    return totalCount;
  } catch (err) {
    console.warn("Error in checking count from sqlite", err && err.message);
    await onPortError(err);
  }
};

export const portUploadAndDownloadToMetadata = async progressCallback => {
  let errors = [];
  try {
    await portFileUploadsRecursively(progressCallback, errors);
    await portFileDownloadsRecursively(progressCallback, errors);
    if (errors.length) {
      throw new Error(errors.join(", "));
    }
    await onPortSuccess();
  } catch (err) {
    console.warn("Error in porting upload and download data", err && err.message);
    showMessage && showMessage(I18N.t("metadataPortError"), 5000);
    await onPortError(err);
  }
};
