import React from "react";
import { onShare } from "./Share";

export default ({
  Box,
  View,
  ScrollView,
  theme,
  getImage,
  I18N,
  Platform,
  showMessage,
  LoadingIndicator,
  logFirebaseAnalyticsEvent
}) => {
  const { fonts, colors, bgs } = theme;
  const { h16, h9_18 } = fonts;
  const { primaryColor, highlightColor, brownGrey } = colors;
  const { themeBg } = bgs;

  const metadata = {
    image: "inviteMemberIcon",
    primaryText: () => I18N.t("oneMoreStep"),
    secondaryText: () => I18N.t("inviteMemberMessage"),
    buttonText: () => I18N.t("shareLink")
  };

  const inviteMemberStyle = {
    containerStyle: {
      viewStyle: {
        justifyContent: "center",
        paddingTop: 80,
        paddingBottom: 30,
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    iconStyle: {
      viewStyle: { paddingTop: 10, paddingBottom: 10, alignSelf: "center" }
    },
    primaryStyle: {
      viewStyle: { paddingTop: 35, paddingBottom: 6, alignSelf: "center" },
      textStyle: {
        ...h16,
        color: primaryColor,
        textAlign: "center"
      }
    },
    secondaryStyle: {
      viewStyle: {
        paddingTop: 6,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        alignSelf: "center"
      },
      textStyle: {
        ...h9_18,
        color: brownGrey,
        textAlign: "center"
      }
    },
    buttonStyle: {
      viewStyle: {
        marginTop: 18,
        marginBottom: 16,
        backgroundColor: themeBg,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 2,
        alignItems: "center",
        justifyContent: "center"
      },
      textStyle: {
        ...h16,
        color: highlightColor
      }
    }
  };

  class InviteMember extends React.Component {
    state = {};
    onClick = async () => {
      if (this.inProgress) {
        return;
      }
      this.inProgress = true;
      try {
        this.setState({ loading: true });
        let { invoke, urls, replaceUri } = this.props;
        let userGroupResult = await invoke({
          service: {
            url: urls["createGroup"],
            uriProps: {
              data: {}
            }
          }
        });
        this.setState({ loading: false });
        logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "group_created" });
        if (Platform.OS !== "web") {
          let joinLink = userGroupResult && userGroupResult.joinLink;
          await onShare(joinLink);
        }
        replaceUri &&
        replaceUri({
          uri: "/home#group",
          nestedTab: "groupMembers",
          _user: {
            group: userGroupResult.group
          }
        });
      } catch (err) {
        showMessage && showMessage(err.message, 2000);
        this.setState({
          loading: false
        });
      }
      this.inProgress = false;
    };

    render() {
      let { image, primaryText, secondaryText, buttonText } = metadata;
      let { containerStyle, iconStyle, primaryStyle, secondaryStyle, buttonStyle } = inviteMemberStyle;

      return (
        <View style={{ flex: 1 }}>
          {this.state.loading && <LoadingIndicator />}
          <ScrollView style={{ flex: 1 }} bounces={false}>
            <Box
              {...containerStyle}
              render={[
                image && { ...iconStyle, image: getImage(image) },
                primaryText && {
                  ...primaryStyle,
                  text: primaryText
                },
                secondaryText && {
                  ...secondaryStyle,
                  text: secondaryText
                },
                buttonText && {
                  ...buttonStyle,
                  text: buttonText,
                  onClick: this.onClick
                }
              ]}
            />
          </ScrollView>
        </View>
      );
    }
  }
  return InviteMember;
};
