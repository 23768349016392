import React from "react";

export default ({ Box, ScrollView, getAction, getImage, theme, I18N, AppConfig, Platform, ChatLinkComponent }) => {
  let { fonts, colors, bgs } = theme;
  let { themeBg } = bgs;
  let { h1, h16_l } = fonts;
  let { highlightColor, brownGrey } = colors;

  const { APP_REGISTRATION_SOURCE } = AppConfig;

  class LoginOption extends React.Component {
    getButtonComponent = ({ text, action }) => {
      return {
        viewStyle: {
          marginTop: 6,
          marginBottom: 6,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 2,
          backgroundColor: themeBg,
          alignItems: "center",
          justifyContent: "center"
        },
        text: I18N.t(text),
        textStyle: {
          ...h1,
          color: highlightColor
        },
        Container: getAction("link"),
        containerProps: {
          action
        }
      };
    };

    render() {
      let contentContainerStyle = { flex: 1, minHeight: 450 };
      return (
        <ScrollView style={{ flex: 1 }} contentContainerStyle={Platform.OS === "web" ? {} : contentContainerStyle}>
          <Box
            viewStyle={{
              ...(Platform.OS !== "web" ? { flex: 1 } : contentContainerStyle),
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 24,
              paddingBottom: 18
            }}
            render={[
              {
                viewStyle: {
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center"
                },
                image: getImage("loginOptionLogo")
              },
              {
                text: `${I18N.t("loginOptionTitle")}:`,
                viewStyle: {
                  alignItems: "center",
                  paddingBottom: 12
                },
                textStyle: {
                  ...h16_l,
                  color: brownGrey
                }
              },
              this.getButtonComponent({
                text: "mobileLoginTitle",
                action: {
                  link: {
                    uri: "/login",
                    props: {
                      params: {
                        registrationRequired: APP_REGISTRATION_SOURCE === "mobile"
                      }
                    }
                  }
                }
              }),
              APP_REGISTRATION_SOURCE === "landline" &&
                this.getButtonComponent({
                  text: "landlineLoginTitle",
                  action: {
                    link: {
                      uri: "/login",
                      props: {
                        params: {
                          fromLandline: true,
                          registrationRequired: true
                        }
                      }
                    }
                  }
                }),
              {
                viewStyle: { alignItems: "center", marginTop: 18 },
                render: <ChatLinkComponent />
              }
            ]}
          />
        </ScrollView>
      );
    }
  }
  return LoginOption;
};
