import {
  Walkthrough,
  WebLandingScreen,
  Login,
  LoginOption,
  ValidateOtp,
  I18N,
  AppConfig
} from "../../../FsCloudComponent";
import {
  RegisterEmail,
  ValidateEmailOtp,
  RegisterMobile,
  ValidateMobileOtp,
  RegisterSuccess,
  ForgotEmail,
  ShowForgotEmail
} from "./RegisterComponents";
import CustomLogin from "./CustomLogin";

const statusBarTheme = AppConfig.APP_TITLE !== "EXA Cloud" && {
  barStyle: "light-content"
};

export default {
  Walkthrough: {
    component: "custom",
    Component: Walkthrough,
    hideNavHeader: true,
    hideStatusBar: true,
    statusBarTheme,
    panelProps: {
      showHeader: false
    }
  },
  WebLandingScreen: {
    component: "custom",
    Component: WebLandingScreen,
    hideNavHeader: true,
    panelProps: {
      showHeader: false
    }
  },
  Login: {
    component: "custom",
    Component: Login,
    hideNavHeader: true,
    hideStatusBar: true,
    panelProps: {
      showHeader: false
    }
  },
  CustomLogin: {
    component: "custom",
    Component: CustomLogin,
    hideNavHeader: true,
    hideStatusBar: true,
    panelProps: {
      showHeader: false
    }
  },
  LoginOption: {
    component: "custom",
    Component: LoginOption,
    hideNavHeader: true,
    hideStatusBar: true,
    panelProps: {
      showHeader: false
    }
  },
  ValidateOtp: {
    component: "custom",
    Component: ValidateOtp,
    title: () => I18N.t("app-title")
  },
  RegisterEmail: {
    component: "custom",
    Component: RegisterEmail,
    title: () => I18N.t("app-title")
  },
  ValidateEmailOtp: {
    component: "custom",
    Component: ValidateEmailOtp,
    title: () => I18N.t("app-title")
  },
  RegisterMobile: {
    component: "custom",
    Component: RegisterMobile,
    title: () => I18N.t("app-title")
  },
  ValidateMobileOtp: {
    component: "custom",
    Component: ValidateMobileOtp,
    title: () => I18N.t("app-title")
  },
  RegisterSuccess: {
    component: "custom",
    Component: RegisterSuccess,
    title: () => I18N.t("app-title"),
    headerActions: [{ type: "close", visible: false }]
  },
  ForgotEmail: {
    component: "custom",
    Component: ForgotEmail,
    title: () => I18N.t("app-title")
  },
  ShowForgotEmail: {
    component: "custom",
    Component: ShowForgotEmail,
    title: () => I18N.t("app-title")
  }
};
