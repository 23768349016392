import {
  normalizeCollectionItems,
  normalizeCollaboration,
  modifyCollectionResult,
  Platform,
  getSortedImages
} from "../../FsCloudComponent";
import {
  onSnapshotGallery,
  afterFetchGallery,
  onSnapshotDocs,
  afterFetchDocs,
  onSnapshotMusic,
  afterFetchMusic,
  getGalleryExtraData,
  getDocsExtraData,
  getMusicExtraData,
  ensureAndGetDownloadingFilesMap
} from "./resource/ResourceUtility";
import {
  METADATA_TABLE,
  GALLERY_ALL_SORT,
  DEFAULT_ALL_SORT,
  DOCS_ALL_SORT,
  MUSIC_ALL_SORT,
  GROUP_GALLERY_SORT,
  GROUP_DOC_SORT,
  GALLERY_ALL_LOCAL_CHANGE_KEY,
  DOCS_ALL_LOCAL_CHANGE_KEY,
  MUSIC_ALL_LOCAL_CHANGE_KEY,
  GROUP_GALLERY_LOCAL_CHANGE_KEY,
  GROUP_DOC_LOCAL_CHANGE_KEY,
  ALBUM_DETAIL_LOCAL_CHANGE_KEY,
  SET_DETAIL_LOCAL_CHANGE_KEY,
  PLAYLIST_DETAIL_LOCAL_CHANGE_KEY,
  VAULT_GALLERY_LOCAL_CHANGE_KEY,
  VAULT_DOCS_LOCAL_CHANGE_KEY,
  UPLOAD_QUEUE_LOCAL_CHANGE_KEY,
  NON_UPLOAD_LOCAL_CHANGE_KEY,
  DOWNLOAD_QUEUE_LOCAL_CHANGE_KEY,
  PLACE_DETAIL_LOCAL_CHANGE_KEY,
  PLACES_LOCAL_CHANGE_KEY,
  DEVICE_FOLDER_LOCAL_CHANGE_KEY,
  FAILED_ITEMS_LOCAL_CHANGE_KEY,
  INFECTED_ITEMS_LOCAL_CHANGE_KEY,
  REMOVED_METADATA_TABLE,
  ARCHIVE_GALLERY_LOCAL_CHANGE_KEY,
  ARCHIVE_DOCS_LOCAL_CHANGE_KEY,
  ARCHIVE_MUSIC_LOCAL_CHANGE_KEY,
  DUPLICATED_ITEMS_LOCAL_CHANGE_KEY,
  DUPLICATE_GALLERY_SORT,
  VAULT_ALBUM_DETAIL_LOCAL_CHANGE_KEY,
  VAULT_SET_DETAIL_LOCAL_CHANGE_KEY,
  HIGHLIGHTS_ALL_SORT
} from "../../Constants";

const DEFAULT_QUERY_LIMIT = 500;

const commonMetadataFields = [
  "_id",
  "deviceId",
  "type",
  "resource_lastModified",
  "uploaded",
  "private",
  "completed",
  "ext",
  "name",
  "resource_url",
  "resource_name",
  "resource_size",
  "_updatedOn",
  "favourite",
  "lower_name",
  "_createdBy",
  "localUri",
  "status",
  "actionType",
  "collections",
  "secureType",
  "group",
  "postedOn",
  "encrypted"
];

const galleryFields = [
  ...commonMetadataFields,
  "resource_duration",
  "thumbnail_url",
  "thumbnail_name",
  "thumbnail_size",
  "thumbnail_width",
  "thumbnail_height",
  "resource_width",
  "resource_height",
  "converted_height",
  "converted_width",
  "converted_jpeg_url",
  "converted_jpeg_size",
  "converted_mp4_url",
  "converted_mp4_size",
  "location",
  "uploadedOn"
];
const docsFields = [...commonMetadataFields, "thumbnail_url", "docType"];
const musicFields = [
  ...commonMetadataFields,
  "resource_duration",
  "converted_mp3_url",
  "converted_mp3_size",
  "resource_album",
  "resource_artist",
  "resource_genre",
  "resource_title"
];

const webSelectItemsQuery = ({ view, sort, afterFetch }) => ({
  // fetchInBackground required as loadMore not work for WebMasonaryList. can be removed for Doc list
  connectProps: (props = {}) => {
    let { action: { origin } = {} } = props;
    return {
      uri: {
        props: {
          query: {
            view,
            sort,
            limit: DEFAULT_QUERY_LIMIT,
            pagination: true,
            params: origin === "groupImage" || origin === "groupDoc" ? { excludeGroup: true } : void 0
          }
        }
      },
      afterFetch,
      fetchInBackground: true
    };
  },
  defaultConnectState: { dataParams: { sort } }
});

export const gallerySelectItemsProps = webSelectItemsQuery({
  view: "allGallery",
  sort: GALLERY_ALL_SORT,
  afterFetch: afterFetchGallery
});
export const docSelectItemsProps = webSelectItemsQuery({
  view: "allDocuments",
  sort: DOCS_ALL_SORT,
  afterFetch: afterFetchDocs
});
export const musicSelectItemsProps = webSelectItemsQuery({
  view: "allMusic",
  sort: MUSIC_ALL_SORT,
  afterFetch: afterFetchMusic
});

const commonMetadataDetailQuery = ({ view, sort, onSnapshot, afterFetch, localChangeKey }) => ({
  connectProps: ({ link = {} }) => {
    let { query, subscribe = true, cache, parentInfo } = link;
    let fetchInBackground = false;
    if (!query) {
      // case of upload file and open
      query = {
        view,
        sort,
        pagination: true,
        limit: DEFAULT_QUERY_LIMIT
      };
      fetchInBackground = true;
    }
    let connectProps = {};
    if (Platform.OS !== "web" && cache === false && parentInfo) {
      let { data, ...restInitialState } = parentInfo;
      connectProps.data = data;
      connectProps.initialState = restInitialState;
    }
    return {
      fetch: {
        query,
        subscribe,
        cache
      },
      onSnapshot,
      afterFetch,
      fetchInBackground,
      notifyOnLocalChanges: { key: localChangeKey ? [METADATA_TABLE, localChangeKey] : [METADATA_TABLE] },
      ...connectProps
    };
  }
});

const commonGalleryDetailQuery = props =>
  commonMetadataDetailQuery({
    view: "allGallery",
    sort: GALLERY_ALL_SORT,
    onSnapshot: onSnapshotGallery,
    afterFetch: afterFetchGallery,
    ...props
  });
// direct gallery all images click or albums detail then its items detail
export const galleryDetailQuery = commonGalleryDetailQuery();
export const searchGalleryDetailQuery = commonGalleryDetailQuery();
export const groupGalleryDetailQuery = commonGalleryDetailQuery({
  view: "groupGallery",
  sort: GROUP_GALLERY_SORT
});
export const vaultGalleryDetailQuery = commonGalleryDetailQuery({
  view: "vaultGallery",
  localChangeKey: VAULT_GALLERY_LOCAL_CHANGE_KEY
});
export const archiveGalleryDetailQuery = commonGalleryDetailQuery({
  view: "archiveGallery",
  localChangeKey: ARCHIVE_GALLERY_LOCAL_CHANGE_KEY
});
export const duplicateResourceDetailQuery = commonGalleryDetailQuery({
  view: "duplicateResources",
  localChangeKey: DUPLICATED_ITEMS_LOCAL_CHANGE_KEY,
  sort: DUPLICATE_GALLERY_SORT
});

const commonDocDetailQuery = props =>
  commonMetadataDetailQuery({
    view: "allDocuments",
    sort: DOCS_ALL_SORT,
    onSnapshot: onSnapshotDocs,
    afterFetch: afterFetchDocs,
    ...props
  });
// direct docs all images click or set detail then its items detail
export const docDetailQuery = commonDocDetailQuery();
export const searchDocDetailQuery = commonDocDetailQuery();
export const groupDocDetailQuery = commonDocDetailQuery({
  view: "groupDocuments",
  sort: GROUP_DOC_SORT
});
export const vaultDocDetailQuery = commonDocDetailQuery({
  view: "vaultDocuments",
  localChangeKey: VAULT_DOCS_LOCAL_CHANGE_KEY
});
export const archiveDocDetailQuery = commonDocDetailQuery({
  view: "archiveDocuments",
  localChangeKey: ARCHIVE_DOCS_LOCAL_CHANGE_KEY
});

const commonMusicDetailQuery = props =>
  commonMetadataDetailQuery({
    view: "allMusic",
    sort: MUSIC_ALL_SORT,
    onSnapshot: onSnapshotMusic,
    afterFetch: afterFetchMusic,
    ...props
  });
// direct music all  click or playlist detail then its items detail
export const musicDetailQuery = commonMusicDetailQuery();
export const searchMusicDetailQuery = commonMusicDetailQuery({
  view: "allMusic",
  sort: { resource_lastModified: -1, _id: -1 } // sort required on resource_lastmodified instead of lower_name due to custom date filter
});
export const archiveMusicDetailQuery = commonMusicDetailQuery({
  view: "archiveMusic",
  localChangeKey: ARCHIVE_MUSIC_LOCAL_CHANGE_KEY
});

// sets list -> set detail -> item list -> item detail (this query will run)
const collectionItemsDetailQuery = {
  connectProps: ({ link = {} } = {}) => {
    const { props, query, subscribe = true, cache, parentInfo } = link;
    let collection = props && props.params && props.params.collection;
    let { localChangeKey } = query || {};
    let connectProps = {};
    if (Platform.OS !== "web" && cache === false && parentInfo) {
      let { data, ...restInitialState } = parentInfo;
      connectProps.data = data;
      connectProps.initialState = restInitialState;
    }
    return {
      fetch: {
        query,
        subscribe,
        cache
      },
      fetchInBackground: false,
      ...connectProps,
      onSnapshot: ({ result, user }) => {
        result = normalizeCollectionItems({ result, user, collectionId: collection && collection._id, collection });
        return { result };
      },
      notifyOnLocalChanges: { key: localChangeKey ? [METADATA_TABLE, localChangeKey] : [METADATA_TABLE] }
      // TODO afterFetch and snapshot need to review
    };
  }
};
export const albumGalleryDetailQuery = collectionItemsDetailQuery;
export const setDocDetailQuery = collectionItemsDetailQuery;
export const playlistMusicDetailQuery = collectionItemsDetailQuery;
export const artistMusicDetailQuery = collectionItemsDetailQuery;
export const placeGalleryDetailQuery = collectionItemsDetailQuery;
export const deviceFolderGalleryDetailQuery = collectionItemsDetailQuery;

// sets list -> set detail (to fetch item list)
const collectionDetailQuery = ({ defaultConnectState, localChangeKey, rowLocalChangeListenerAdded, origin }) => {
  return {
    connectProps: ({ link }) => {
      let collectionId = link && link.props && link.props.filter && link.props.filter._id;
      let { props: { params: { collection = {} } = {} } = {} } = link;
      return {
        fetch: {
          query: {
            view: "collectionItems",
            filter: {
              "collection._id": collectionId,
              deleted: false
            },
            localChangeKey,
            params: { collection },
            limit: DEFAULT_QUERY_LIMIT,
            pagination: true,
            origin
          },
          subscribe: Platform.OS === "web",
          cache: Platform.OS === "web"
        },
        onSnapshot: params => {
          let { result, user } = params;
          result = normalizeCollectionItems({ result, user, collectionId, collection });
          return { result };
        },
        afterFetch: async state => {
          let { data = [] } = state;
          let downloadingFilesMap = await ensureAndGetDownloadingFilesMap(state);
          if (downloadingFilesMap && Object.keys(downloadingFilesMap).length) {
            data = data.map(doc => ({
              ...doc,
              ...downloadingFilesMap[doc._id]
            }));
          }
          return { state: { ...state, data } };
        },
        notifyOnLocalChanges: { key: localChangeKey ? [METADATA_TABLE, localChangeKey] : [METADATA_TABLE] },
        rowLocalChangeListenerAdded,
        fetchInBackground: Platform.OS === "web" ? true : void 0
      };
    },
    defaultConnectState
  };
};

// it will fetch images for that album from collectionItems
export const albumDetailQuery = {
  ...collectionDetailQuery({
    defaultConnectState: { dataParams: { sort: DEFAULT_ALL_SORT } },
    localChangeKey: ALBUM_DETAIL_LOCAL_CHANGE_KEY,
    rowLocalChangeListenerAdded: true
  })
};

export const vaultAlbumDetailQuery = {
  ...collectionDetailQuery({
    defaultConnectState: { dataParams: { sort: DEFAULT_ALL_SORT } },
    localChangeKey: VAULT_ALBUM_DETAIL_LOCAL_CHANGE_KEY,
    rowLocalChangeListenerAdded: true,
    origin: "vault"
  })
};
// it will fetch items for that places from collectionItems
export const placeDetailQuery = {
  connectProps: ({ link }) => {
    let { props: { params: { collection = {} } = {} } = {} } = link;
    return {
      fetch: {
        query: {
          view: "placeItems",
          filter: {
            "location.city.lower_name": link.props.filter.location
          },
          localChangeKey: PLACE_DETAIL_LOCAL_CHANGE_KEY,
          params: { collection },
          limit: DEFAULT_QUERY_LIMIT,
          pagination: true
        },
        subscribe: Platform.OS === "web",
        cache: Platform.OS === "web"
      },
      onSnapshot: params => {
        let { result, user } = params;
        result = normalizeCollectionItems({ result, user });
        return { result };
      },
      afterFetch: async state => {
        let { data = [] } = state;
        let downloadingFilesMap = await ensureAndGetDownloadingFilesMap(state);
        if (downloadingFilesMap && Object.keys(downloadingFilesMap).length) {
          data = data.map(doc => ({
            ...doc,
            ...downloadingFilesMap[doc._id]
          }));
        }
        return { state: { ...state, data } };
      },
      notifyOnLocalChanges: { key: [METADATA_TABLE, PLACE_DETAIL_LOCAL_CHANGE_KEY] },
      rowLocalChangeListenerAdded: true,
      fetchInBackground: Platform.OS === "web" ? true : void 0
    };
  },
  defaultConnectState: { dataParams: { sort: DEFAULT_ALL_SORT } }
};

// it will fetch items for that device folder from collectionItems
export const deviceFolderDetailQuery = {
  connectProps: ({ link }) => {
    let { props: { params: { collection = {} } = {} } = {} } = link;
    return {
      fetch: {
        query: {
          view: "deviceFolderItems",
          filter: {
            deviceFolder: link.props.filter.deviceFolder
          },
          localChangeKey: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
          params: { collection },
          limit: DEFAULT_QUERY_LIMIT,
          pagination: true
        },
        subscribe: Platform.OS === "web",
        cache: Platform.OS === "web"
      },
      onSnapshot: params => {
        let { result, user } = params;
        result = normalizeCollectionItems({ result, user });
        return { result };
      },
      afterFetch: async state => {
        let { data = [] } = state;
        let downloadingFilesMap = await ensureAndGetDownloadingFilesMap(state);
        if (downloadingFilesMap && Object.keys(downloadingFilesMap).length) {
          data = data.map(doc => ({
            ...doc,
            ...downloadingFilesMap[doc._id]
          }));
        }
        return { state: { ...state, data } };
      },
      notifyOnLocalChanges: { key: [METADATA_TABLE, DEVICE_FOLDER_LOCAL_CHANGE_KEY] },
      rowLocalChangeListenerAdded: true,
      fetchInBackground: Platform.OS === "web" ? true : void 0
    };
  },
  defaultConnectState: { dataParams: { sort: DEFAULT_ALL_SORT } }
};

// it will fetch documents for that set from collectionItems
export const setDetailQuery = {
  ...collectionDetailQuery({
    defaultConnectState: { dataParams: { sort: DOCS_ALL_SORT } },
    localChangeKey: SET_DETAIL_LOCAL_CHANGE_KEY
  })
};
// it will fetch documents for that vault-set from collectionItems
export const vaultSetDetailQuery = {
  ...collectionDetailQuery({
    defaultConnectState: { dataParams: { sort: DOCS_ALL_SORT } },
    localChangeKey: VAULT_SET_DETAIL_LOCAL_CHANGE_KEY,
    origin: "vault"
  })
};
// it will fetch songs for that playlist from collectionItems
export const playlistDetailQuery = {
  ...collectionDetailQuery({
    defaultConnectState: { dataParams: { sort: MUSIC_ALL_SORT } },
    localChangeKey: PLAYLIST_DETAIL_LOCAL_CHANGE_KEY
  })
};
// it will fetch songs for that artist from collectionItems
export const artistDetailQuery = {
  ...collectionDetailQuery({
    defaultConnectState: { dataParams: { sort: MUSIC_ALL_SORT } }
  })
};

const commonMetadataQuery = ({
  view,
  sort,
  fields,
  extraFields,
  localChangeKey,
  onSnapshot,
  afterFetch,
  sqlite,
  sqliteFilter,
  mergeLocalWithSecureType,
  fetchInBackground,
  mergeAvatar
}) => ({
  fetch: ({ link }) => {
    const params = link && link.props && link.props.params;
    let cache = params ? false : true;
    let subscribe = cache;
    let query = {
      view,
      sort,
      mergeAvatar,
      limit: DEFAULT_QUERY_LIMIT,
      localChangeKey,
      params,
      pagination: true
    };
    if (Platform.OS !== "web") {
      subscribe = false;
      cache = false;
      query.mergeLocalWithSecureType = mergeLocalWithSecureType;
      query.fields = fields;
      query.extraFields = extraFields;
      query.sqlite = sqlite;
      query.sqliteFilter = sqliteFilter;
    }
    return {
      service: "_find",
      query,
      subscribe,
      cache
    };
  },
  defaultConnectState: { dataParams: { sort } },
  connectProps: {
    onSnapshot,
    afterFetch,
    notifyOnLocalChanges: { key: [METADATA_TABLE, localChangeKey] },
    fetchInBackground: Platform.OS === "web" ? true : fetchInBackground,
    rowLocalChangeListenerAdded: true
  }
});
export const duplicateResourcesQuery = {
  fetch: ({ link }) => {
    const params = link && link.props && link.props.params;
    let cache = params ? false : true;
    let subscribe = cache;
    let query = {
      view: "duplicateResources",
      sort: DUPLICATE_GALLERY_SORT,
      limit: DEFAULT_QUERY_LIMIT,
      localChangeKey: DUPLICATED_ITEMS_LOCAL_CHANGE_KEY,
      params,
      pagination: true
    };
    return {
      service: "_find",
      query,
      subscribe,
      cache
    };
  },
  defaultConnectState: { dataParams: { sort: DUPLICATE_GALLERY_SORT } },
  connectProps: {
    onSnapshot: onSnapshotGallery,
    afterFetch: afterFetchGallery,
    notifyOnLocalChanges: { key: [METADATA_TABLE, DUPLICATED_ITEMS_LOCAL_CHANGE_KEY] },
    fetchInBackground: true,
    rowLocalChangeListenerAdded: true
  }
};

export const galleryAllQuery = commonMetadataQuery({
  view: "allGallery",
  sort: GALLERY_ALL_SORT,
  localChangeKey: GALLERY_ALL_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotGallery,
  afterFetch: afterFetchGallery,
  fields: galleryFields,
  extraFields: data => getGalleryExtraData(data, { mergeDownloading: true }),
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }], // actionType!="upload" was not including null values so two filters applied
    $AND: [{ $NOT: { thumbnail_url: null } }, { $NOT: { thumbnail_width: 0 } }, { $NOT: { thumbnail_height: 0 } }]
  },
  mergeLocalWithSecureType: "default",
  fetchInBackground: true
});

export const highlightsAllQuery = {
  fetch: ({ link }) => {
    const params = link && link.props && link.props.params;
    let cache = params ? false : true;
    let subscribe = cache;
    let query = {
      view: "allHighlights",
      sort: HIGHLIGHTS_ALL_SORT,
      limit: DEFAULT_QUERY_LIMIT,
      params,
      pagination: true
    };
    return {
      service: "_find",
      query,
      subscribe,
      cache
    };
  },
  defaultConnectState: {
    dataParams: {
      sort: HIGHLIGHTS_ALL_SORT
    }
  },
  connectProps: {
    onSnapshot: onSnapshotGallery,
    afterFetch: afterFetchGallery,
    fetchInBackground: true,
    rowLocalChangeListenerAdded: true
  }
};

export const docsAllQuery = commonMetadataQuery({
  view: "allDocuments",
  sort: DOCS_ALL_SORT,
  localChangeKey: DOCS_ALL_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotDocs,
  afterFetch: afterFetchDocs,
  fields: docsFields,
  extraFields: data => getDocsExtraData(data, { mergeDownloading: true }),
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }]
  },
  mergeLocalWithSecureType: "default"
});
export const musicAllQuery = commonMetadataQuery({
  view: "allMusic",
  sort: MUSIC_ALL_SORT,
  localChangeKey: MUSIC_ALL_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotMusic,
  afterFetch: afterFetchMusic,
  fields: musicFields,
  extraFields: getMusicExtraData,
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }],
    $NOT: { resource_title: null }
  },
  mergeLocalWithSecureType: "default",
  fetchInBackground: true
});
export const vaultGalleryQuery = commonMetadataQuery({
  view: "vaultGallery",
  sort: GALLERY_ALL_SORT,
  localChangeKey: VAULT_GALLERY_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotGallery,
  afterFetch: afterFetchGallery,
  fields: galleryFields,
  extraFields: data => getGalleryExtraData(data, { mergeDownloading: true }),
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }],
    $AND: [{ $NOT: { thumbnail_url: null } }, { $NOT: { thumbnail_width: 0 } }, { $NOT: { thumbnail_height: 0 } }]
  },
  mergeLocalWithSecureType: "vault"
});
export const vaultDocsQuery = commonMetadataQuery({
  view: "vaultDocuments",
  sort: DOCS_ALL_SORT,
  localChangeKey: VAULT_DOCS_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotDocs,
  afterFetch: afterFetchDocs,
  fields: docsFields,
  extraFields: data => getDocsExtraData(data, { mergeDownloading: true }),
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }]
  },
  mergeLocalWithSecureType: "vault"
});
export const archiveGalleryQuery = commonMetadataQuery({
  view: "archiveGallery",
  sort: GALLERY_ALL_SORT,
  localChangeKey: ARCHIVE_GALLERY_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotGallery,
  afterFetch: afterFetchGallery,
  fields: galleryFields,
  extraFields: data => getGalleryExtraData(data, { mergeDownloading: true }),
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }],
    $AND: [{ $NOT: { thumbnail_url: null } }, { $NOT: { thumbnail_width: 0 } }, { $NOT: { thumbnail_height: 0 } }]
  }
});
export const archiveDocsQuery = commonMetadataQuery({
  view: "archiveDocuments",
  sort: DOCS_ALL_SORT,
  localChangeKey: ARCHIVE_DOCS_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotDocs,
  afterFetch: afterFetchDocs,
  fields: docsFields,
  extraFields: data => getDocsExtraData(data, { mergeDownloading: true }),
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }]
  }
});
export const archiveMusicQuery = commonMetadataQuery({
  view: "archiveMusic",
  sort: MUSIC_ALL_SORT,
  localChangeKey: ARCHIVE_MUSIC_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotMusic,
  afterFetch: afterFetchMusic,
  fields: musicFields,
  extraFields: getMusicExtraData,
  sqlite: true,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { $NOT: { status: "failed" } }],
    $NOT: { resource_title: null }
  },
  fetchInBackground: true
});

export const groupGalleryQuery = commonMetadataQuery({
  view: "groupGallery",
  sort: GROUP_GALLERY_SORT,
  mergeAvatar: true,
  localChangeKey: GROUP_GALLERY_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotGallery,
  afterFetch: afterFetchGallery
});

export const groupDocsQuery = commonMetadataQuery({
  view: "groupDocuments",
  sort: GROUP_DOC_SORT,
  localChangeKey: GROUP_DOC_LOCAL_CHANGE_KEY,
  onSnapshot: onSnapshotDocs,
  afterFetch: afterFetchDocs
});

const commonSearchMetadataQuery = ({
  view,
  sort,
  onSnapshot,
  afterFetch,
  sqlite,
  sqliteFilter,
  fields,
  extraFields
}) => ({
  fetch: ({ link }) => {
    const params = link && link.props && link.props.params;
    let { secureType } = link || {};
    let { from } = params || {};
    let cache =
      from === "search-gallery" ||
      from === "search-vault-gallery" ||
      from === "search-docs" ||
      from === "search-vault-docs" ||
      from === "search-music" ||
      from === "search-group-gallery" ||
      from === "search-group-docs" ||
      from === "search-archive-gallery" ||
      from === "search-archive-docs" ||
      from === "search-archive-music"
        ? false
        : true;
    let query = {
      view,
      sort,
      limit: 1000,
      params,
      secureType,
      pagination: true
    };
    if (Platform.OS !== "web" && from !== "search-group-gallery" && from !== "search-group-docs") {
      Object.assign(query, { sqlite, sqliteFilter, fields, extraFields });
    }
    return {
      service: "_find",
      query,
      subscribe: Platform.OS === "web" ? cache : false,
      cache: Platform.OS === "web" ? cache : false
    };
  },
  connectProps: {
    onSnapshot,
    afterFetch,
    notifyOnLocalChanges: { key: METADATA_TABLE },
    rowLocalChangeListenerAdded: true
  }
});

export const searchGalleryQuery = commonSearchMetadataQuery({
  view: "searchGallery",
  sort: GALLERY_ALL_SORT,
  onSnapshot: onSnapshotGallery,
  afterFetch: afterFetchGallery,
  fields: galleryFields,
  extraFields: data => getGalleryExtraData(data, { mergeDownloading: true }),
  sqlite: false,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { status: "success" }],
    $AND: [{ $NOT: { thumbnail_url: null } }, { $NOT: { thumbnail_width: 0 } }, { $NOT: { thumbnail_height: 0 } }]
  }
});
export const searchDocsQuery = commonSearchMetadataQuery({
  view: "searchDocuments",
  sort: DOCS_ALL_SORT,
  onSnapshot: onSnapshotDocs,
  afterFetch: afterFetchDocs,
  fields: docsFields,
  extraFields: data => getDocsExtraData(data, { mergeDownloading: true }),
  sqlite: false,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { status: "success" }]
  }
});
export const searchMusicQuery = commonSearchMetadataQuery({
  view: "searchMusic",
  sort: { resource_lastModified: -1, _id: -1 }, // sort required on resource_lastmodified instead of lower_name due to custom date filter
  onSnapshot: onSnapshotMusic,
  afterFetch: afterFetchMusic,
  fields: musicFields,
  extraFields: getMusicExtraData,
  sqlite: false,
  sqliteFilter: {
    $OR: [{ actionType: null }, { actionType: "download" }, { status: "success" }],
    $NOT: { resource_title: null }
  }
});

const commonCollectionQuery = ({ view, onSnapshot }) => ({
  fetch: {
    service: "_find",
    query: {
      view,
      pagination: true,
      limit: DEFAULT_QUERY_LIMIT
    },
    subscribe: true
  },
  connectProps: {
    onSnapshot
  }
});
//all albums
export const albumQuery = commonCollectionQuery({
  view: "albums",
  onSnapshot: ({ result, user }) => {
    if (result) {
      for (let index = 0; index < result.length; index++) {
        let newDoc = result[index];
        if (newDoc.minDate && newDoc.minDate.seconds && !(newDoc.minDate instanceof Date)) {
          newDoc.minDate = new Date(newDoc.minDate.seconds * 1000);
        }
        if (newDoc.maxDate && newDoc.maxDate.seconds && !(newDoc.maxDate instanceof Date)) {
          newDoc.maxDate = new Date(newDoc.maxDate.seconds * 1000);
        }
      }
    }
    result = modifyCollectionResult(result, { user });
    return { result };
  }
});
// all Places
export const placesQuery = {
  fetch: {
    service: "_find",
    query: {
      view: "places",
      pagination: true,
      fields: ["aggregateId", "city_lower"],
      limit: DEFAULT_QUERY_LIMIT
    },
    cache: true
  },
  connectProps: {
    notifyOnLocalChanges: { key: PLACES_LOCAL_CHANGE_KEY }
  }
};

//All device folders
export const deviceFolderQuery = {
  fetch: {
    service: "_find",
    query: {
      view: "deviceFolder",
      pagination: true,
      fields: ["aggregateId", "deviceFolder"],
      limit: DEFAULT_QUERY_LIMIT
    },
    cache: true
  },
  connectProps: {
    notifyOnLocalChanges: { key: DEVICE_FOLDER_LOCAL_CHANGE_KEY }
  }
};

//all set
export const setQuery = commonCollectionQuery({
  view: "sets",
  onSnapshot: ({ result, user }) => {
    result = modifyCollectionResult(result, { user });
    return { result };
  }
});

export const vaultSetQuery = commonCollectionQuery({
  view: "vaultSets",
  onSnapshot: ({ result, user }) => {
    result = modifyCollectionResult(result, { user });
    return { result };
  }
});
//all playlist
export const playlistQuery = commonCollectionQuery({ view: "playlists" });
//all artist
export const artistQuery = commonCollectionQuery({ view: "artists" });

export const sharedCollectionsQuery = commonCollectionQuery({
  view: "SharedCollections",
  onSnapshot: ({ result, user }) => {
    result = modifyCollectionResult(result, { user });
    return { result };
  }
});

//all Vault albums
export const vaultAlbumQuery = commonCollectionQuery({
  view: "vaultAlbums",
  onSnapshot: ({ result, user }) => {
    if (result) {
      for (let index = 0; index < result.length; index++) {
        let newDoc = result[index];
        if (newDoc.minDate && newDoc.minDate.seconds && !(newDoc.minDate instanceof Date)) {
          newDoc.minDate = new Date(newDoc.minDate.seconds * 1000);
        }
        if (newDoc.maxDate && newDoc.maxDate.seconds && !(newDoc.maxDate instanceof Date)) {
          newDoc.maxDate = new Date(newDoc.maxDate.seconds * 1000);
        }
      }
    }
    result = modifyCollectionResult(result, { user });
    return { result };
  }
});

const selectMetadataQuery = ({ view, sort, afterFetch, fields, extraFields, sqlite, sqliteFilter, mergeAvatar }) => ({
  fetch: ({ link }) => {
    const params = link && link.props && link.props.params;
    let query = {
      view,
      mergeAvatar,
      sort,
      params,
      limit: DEFAULT_QUERY_LIMIT,
      pagination: true
    };
    let { origin } = params || {};
    if (Platform.OS !== "web" && origin !== "groupDoc" && origin !== "groupImage") {
      query.fields = fields;
      query.extraFields = extraFields;
      query.sqlite = sqlite;
      query.sqliteFilter = sqliteFilter;
    }
    return {
      service: "_find",
      query
    };
  },
  connectProps: {
    afterFetch
  }
});
export const selectImageQuery = selectMetadataQuery({
  view: "allGallery",
  sort: GALLERY_ALL_SORT,
  afterFetch: afterFetchGallery,
  mergeAvatar: true,
  fields: galleryFields,
  extraFields: getGalleryExtraData,
  sqlite: true,
  sqliteFilter: {
    uploaded: true,
    $AND: [{ $NOT: { thumbnail_url: null } }, { $NOT: { thumbnail_width: 0 } }, { $NOT: { thumbnail_height: 0 } }]
  }
});
export const selectDocsQuery = selectMetadataQuery({
  view: "allDocuments",
  sort: DOCS_ALL_SORT,
  afterFetch: afterFetchDocs,
  fields: docsFields,
  extraFields: getDocsExtraData,
  sqlite: true,
  sqliteFilter: {
    uploaded: true
  }
});
export const selectMusicQuery = selectMetadataQuery({
  view: "allMusic",
  sort: MUSIC_ALL_SORT,
  afterFetch: afterFetchMusic,
  fields: musicFields,
  extraFields: getMusicExtraData,
  sqlite: true,
  sqliteFilter: {
    uploaded: true,
    $NOT: { resource_title: null }
  }
});
export const selectVaultImageQuery = selectMetadataQuery({
  view: "vaultGallery",
  sort: GALLERY_ALL_SORT,
  afterFetch: afterFetchGallery,
  fields: galleryFields,
  extraFields: getGalleryExtraData,
  sqlite: true,
  sqliteFilter: {
    uploaded: true,
    $AND: [{ $NOT: { thumbnail_url: null } }, { $NOT: { thumbnail_width: 0 } }, { $NOT: { thumbnail_height: 0 } }]
  }
});
export const selectVaultDocsQuery = selectMetadataQuery({
  view: "vaultDocuments",
  sort: DOCS_ALL_SORT,
  afterFetch: afterFetchDocs,
  fields: docsFields,
  extraFields: getDocsExtraData,
  sqlite: true,
  sqliteFilter: {
    uploaded: true
  }
});

const selectCollectionQuery = ({ view }) => ({
  fetch: ({ link } = {}) => {
    let params = { excludeReadOnlyCollections: true };
    let linkParams = link && link.props && link.props.params;
    if (linkParams && linkParams.collection) {
      params = {
        ...params,
        collection: linkParams.collection,
        excludeCollection: true
      };
    }
    return {
      service: "_find",
      query: {
        view,
        params,
        pagination: true,
        limit: DEFAULT_QUERY_LIMIT
      }
    };
  }
});
export const selectAlbumQuery = selectCollectionQuery({ view: "albums" });
export const selectSetQuery = selectCollectionQuery({ view: "sets" });
export const selectPlaylistQuery = selectCollectionQuery({ view: "playlists" });
export const selectVaultAlbumQuery = selectCollectionQuery({ view: "vaultAlbums" });
export const selectVaultSetQuery = selectCollectionQuery({ view: "vaultSets" });

const collectionWebAddItemsQuery = ({ view, sort, afterFetch, cache }) => ({
  // cache required for doc and music memory sorting done by CacheManager
  connectProps: props => {
    const { collectionName, data } = props;
    let params;
    if (data && !Array.isArray(data) && !collectionName && data._id) {
      params = { collection: { _id: data._id }, excludeCollectionItems: true };
    }
    return {
      uri: {
        props: {
          query: {
            view,
            sort,
            params,
            limit: DEFAULT_QUERY_LIMIT,
            pagination: true
          },
          cache
        }
      },
      afterFetch,
      fetchInBackground: true
    };
  },
  defaultConnectState: { dataParams: { sort } }
});
export const albumAddItemsQuery = collectionWebAddItemsQuery({
  view: "allGallery",
  sort: GALLERY_ALL_SORT,
  afterFetch: afterFetchGallery
});
export const setAddItemsQuery = collectionWebAddItemsQuery({
  view: "allDocuments",
  sort: DOCS_ALL_SORT,
  afterFetch: afterFetchDocs,
  cache: true
});
export const playlistAddItemsQuery = collectionWebAddItemsQuery({
  view: "allMusic",
  sort: MUSIC_ALL_SORT,
  afterFetch: afterFetchMusic,
  cache: true
});
export const vaultAlbumAddItemsQuery = collectionWebAddItemsQuery({
  view: "vaultGallery",
  sort: GALLERY_ALL_SORT,
  afterFetch: afterFetchGallery
});
export const vaultSetAddItemsQuery = collectionWebAddItemsQuery({
  view: "vaultDocuments",
  sort: DOCS_ALL_SORT,
  afterFetch: afterFetchDocs,
  cache: true
});

export const getIssuesQuery = {
  fetch: () => {
    return {
      service: "_find",
      query: {
        view: "allIssues"
      },
      subscribe: true
    };
  }
};

const memoryOfDayCommonQuery = ({ limit, subscribe }) => ({
  connectProps: {
    uri: {
      props: {
        query: {
          view: "memoryOfDay",
          sort: {
            year: -1,
            resource_lastModified: -1,
            _id: -1
          },
          limit,
          pagination: true
        },
        subscribe
      }
    },
    fetchInBackground: true
  }
});

export const memoryOfDayQuery = memoryOfDayCommonQuery({ limit: 1 });

export const memoriesOfDayQuery = memoryOfDayCommonQuery({ limit: DEFAULT_QUERY_LIMIT, subscribe: true });

export const trashItemsQuery = {
  fetch: () => {
    return {
      service: "_find",
      query: {
        view: "trashedData",
        sort: { deletedOn: -1, _id: -1 },
        sqlite: Platform.OS !== "web",
        limit: DEFAULT_QUERY_LIMIT,
        pagination: true
      },
      subscribe: Platform.OS === "web"
    };
  },
  defaultConnectState: { dataParams: { sort: { deletedOn: -1, _id: -1 } } },
  connectProps: {
    fetchInBackground: Platform.OS === "web" ? true : void 0,
    notifyOnLocalChanges: { key: "trashedData" }
  }
};
export const failedItemsQuery = {
  fetch: () => {
    return {
      service: "_find",
      query: {
        view: "failedData",
        sort: { _createdOn: -1, _id: 1 },
        limit: DEFAULT_QUERY_LIMIT,
        pagination: true
      },
      subscribe: Platform.OS === "web"
    };
  },
  defaultConnectState: { dataParams: { sort: { _createdOn: -1, _id: 1 } } },
  connectProps: {
    fetchInBackground: Platform.OS === "web" ? true : void 0,
    notifyOnLocalChanges: { key: [FAILED_ITEMS_LOCAL_CHANGE_KEY, REMOVED_METADATA_TABLE] }
  }
};

export const nonUploadedItemsQuery = {
  fetch: () => {
    return {
      service: "_find",
      query: {
        view: "nonUploadedData",
        sort: { _createdOn: -1, _id: 1 },
        limit: DEFAULT_QUERY_LIMIT,
        pagination: true
      },
      subscribe: Platform.OS === "web"
    };
  },
  defaultConnectState: { dataParams: { sort: { _createdOn: -1, _id: 1 } } },
  connectProps: {
    fetchInBackground: Platform.OS === "web" ? true : void 0,
    notifyOnLocalChanges: { key: [NON_UPLOAD_LOCAL_CHANGE_KEY] }
  }
};
export const infectedItemsQuery = {
  fetch: () => {
    return {
      service: "_find",
      query: {
        view: "infectedData",
        sort: { _createdOn: -1, _id: 1 },
        limit: DEFAULT_QUERY_LIMIT,
        pagination: true
      },
      subscribe: Platform.OS === "web"
    };
  },
  defaultConnectState: { dataParams: { sort: { _createdOn: -1, _id: 1 } } },
  connectProps: {
    fetchInBackground: Platform.OS === "web" ? true : void 0,
    notifyOnLocalChanges: { key: [INFECTED_ITEMS_LOCAL_CHANGE_KEY, REMOVED_METADATA_TABLE] }
  }
};
export const uploadQueueQuery = {
  fetch: () => {
    return {
      service: "_find",
      query: {
        view: "uploadQueue",
        sqlite: true,
        limit: 1000,
        fields: ["_id", "status", "thumbnail_url", "type", "resource_size", "name", "pauseReason"],
        forceSqlite: true,
        sqliteFilter: {
          $AND: [
            { actionType: "upload" },
            { $OR: [{ status: "queued" }, { status: "paused" }, { status: "uploading" }] }
          ]
        },
        pagination: true
      },
      subscribe: Platform.OS === "web"
    };
  },
  connectProps: {
    fetchInBackground: true,
    notifyOnLocalChanges: { key: UPLOAD_QUEUE_LOCAL_CHANGE_KEY }
  }
};

export const downloadQueueQuery = {
  fetch: () => {
    return {
      service: "_find",
      query: {
        view: "downloadQueue",
        sqlite: true,
        limit: 1000,
        fields: [
          "_id",
          "status",
          "thumbnail_url",
          "type",
          "resource_size",
          "name",
          "resource_name",
          "downloadJobId",
          "pauseReason"
        ],
        forceSqlite: true,
        sqliteFilter: {
          $AND: [
            { actionType: "download" },
            { $OR: [{ status: "queued" }, { status: "paused" }, { status: "downloading" }] }
          ]
        },
        pagination: true
      },
      subscribe: Platform.OS === "web"
    };
  },
  connectProps: {
    fetchInBackground: true,
    notifyOnLocalChanges: { key: DOWNLOAD_QUEUE_LOCAL_CHANGE_KEY }
  }
};

export const allDevicesQuery = {
  fetch: ({ link }) => {
    const params = link && link.props && link.props.params;
    return {
      service: "_find",
      query: {
        view: "allDevice",
        sort: { updatedOn: -1 },
        limit: DEFAULT_QUERY_LIMIT,
        params
      },
      subscribe: params ? false : true
    };
  },
  connectProps: {
    afterFetch: state => {
      let { data: result } = state;
      if (result) {
        result = result.filter(row => row.status !== "logout");
      }
      return { state: { ...state, data: result } };
    },
    onSnapshot: ({ result }) => {
      if (result) {
        result = result.filter(row => row.status !== "logout");
      }
      return { result };
    }
  },
  defaultConnectState: { dataParams: { sort: { updatedOn: -1 } } }
};

export const getContactQuery = {
  fetch: ({ link }) => {
    let params = link && link.props && link.props.params;
    return {
      service: "_find",
      query: {
        view: "contactData",
        sort: { name_lower: 1, _id: 1 },
        limit: DEFAULT_QUERY_LIMIT,
        params,
        pagination: true
      },
      subscribe: true
    };
  },
  connectProps: {
    discardOldResult: true,
    fetchInBackground: true
  }
};

export const contactDetailQuery = connectProps => {
  return {
    connectProps: ({ link = {} }) => {
      let filter = link && link.props && link.props.filter;
      return {
        uri: {
          props: {
            query: {
              view: "contactDetail",
              limit: 1,
              filter
            },
            subscribe: true
          }
        },
        ...connectProps
      };
    }
  };
};

export const setCoverQuery = {
  connectProps: props => {
    let { data, action: { origin } = {} } = props;
    return {
      uri: {
        props: {
          query: {
            view: "collectionItems",
            filter: {
              "collection._id": data._id,
              deleted: false
            },
            limit: DEFAULT_QUERY_LIMIT,
            pagination: true,
            sort: DEFAULT_ALL_SORT,
            origin: origin
          }
        }
      },
      fetchInBackground: true
    };
  },
  defaultConnectState: { dataParams: { sort: DEFAULT_ALL_SORT } }
};

export const montageItemQuery = {
  connectProps: ({ link = {} }) => {
    let data = link && link.props && link.props.params && link.props.params.data;
    return {
      uri: {
        props: {
          query: {
            view: "collectionItems",
            filter: {
              "collection._id": data._id,
              deleted: false
            },
            sort: DEFAULT_ALL_SORT,
            limit: 1000
          }
        }
      }
    };
  }
};

export const placeMontageItemQuery = {
  connectProps: ({ link = {} }) => {
    let data = link && link.props && link.props.params && link.props.params.data;
    return {
      uri: {
        props: {
          query: {
            view: "placeItems",
            filter: {
              "location.city.lower_name": data.city_lower
            },
            sort: DEFAULT_ALL_SORT,
            limit: 1000
          }
        }
      }
    };
  }
};

export const deviceFolderMontageItemQuery = {
  connectProps: ({ link = {} }) => {
    let data = link && link.props && link.props.params && link.props.params.data;
    return {
      uri: {
        props: {
          query: {
            view: "deviceFolderItems",
            filter: {
              deviceFolder: data.deviceFolder
            },
            sort: DEFAULT_ALL_SORT,
            limit: 1000
          }
        }
      }
    };
  }
};

export const vaultMontageItemQuery = {
  connectProps: ({ link = {} }) => {
    let data = link && link.props && link.props.params && link.props.params.data;
    return {
      uri: {
        props: {
          query: {
            view: "collectionItems",
            filter: {
              "collection._id": data._id,
              deleted: false
            },
            sort: DEFAULT_ALL_SORT,
            limit: 1000,
            origin: "vault"
          }
        }
      }
    };
  }
};

export const groupMembersQuery = {
  fetch: {
    service: "_find",
    query: {
      view: "groupMembers",
      limit: DEFAULT_QUERY_LIMIT
    },
    subscribe: true
  },
  defaultConnectState: { dataParams: { sort: { email_lower: 1 } } }
};

export const collaborationQuery = {
  fetch: ({ link }) => {
    const params = link && link.props && link.props.params;
    return {
      service: "_find",
      query: {
        view: "collaborators",
        limit: DEFAULT_QUERY_LIMIT,
        params
      },
      subscribe: true
    };
  },
  connectProps: {
    afterFetch: (state, { user }) => {
      const { data: result } = state;
      let is_OwnerLogin = true;
      let data = result && result.length > 0 && result[0];
      let response = normalizeCollaboration({
        data: data,
        user,
        is_OwnerLogin
      });
      return { state: { ...state, data: response } };
    },
    onSnapshot: ({ result, user }) => {
      let data = result && result.length > 0 && result[0];
      let is_OwnerLogin = user && data && data._createdBy && data._createdBy.firebaseUid === user.uid;
      let response = normalizeCollaboration({
        data: data,
        user,
        is_OwnerLogin
      });
      return { updatedData: response };
    }
  }
};

export const recoverContactQuery = {
  connectProps: {
    uri: {
      props: {
        query: {
          view: "deletedContact",
          sort: { deletedOn: -1 },
          limit: DEFAULT_QUERY_LIMIT,
          pagination: true
        }
      }
    },
    fetchInBackground: true
  }
};

export const mergeDuplicateContactQuery = {
  connectProps: {
    uri: {
      props: {
        query: {
          view: "mergeDuplicateContact"
        }
      }
    }
  }
};

export const fetchProfilePictureQuery = {
  connectProps: {
    fetchInBackground: true,
    beforeFetch: (state, { uri, afterState, fetchMore }) => {
      let newUri = {
        ...uri,
        props: {
          ...uri.props,
          query: {
            ...uri.props.query,
            limit: DEFAULT_QUERY_LIMIT,
            pagination: true,
            endCursor: state.endCursor
          }
        }
      };
      let newAfterState = { ...afterState, endCursor: state.endCursor };
      return { uri: newUri, state: { ...state, pending: true, loadingMore: fetchMore }, afterState: newAfterState };
    },
    afterFetch: async (state, { result }) => {
      let hasNext, endCursor;
      if (result && result.page_info) {
        hasNext = result.page_info.has_next_page || false;
        endCursor = result.page_info.end_cursor || void 0;
      }
      let { data = [] } = state;
      if (data[0] && data[0]["permissionDenied"]) {
        return { state: { ...state, data, hasNext, endCursor } };
      }
      data = data.map(doc => {
        let { node: { timestamp } = {} } = doc;
        return { ...doc, resource_lastModified: timestamp && timestamp * 1000 };
      });
      data = getSortedImages(data);
      return { state: { ...state, data, hasNext, endCursor } };
    }
  },
  fetch: {
    props: {
      query: {
        view: "_fetchProfileGallery",
        addOnFilter: {
          assetType: "Photos"
        }
      }
    }
  }
};

export const fetchGalleryForUploadSettings = {
  connectProps: {
    uri: {
      props: {
        query: {
          view: "_fetchGalleryFolders"
        }
      }
    }
  }
};

export const fetchNativeUploadSettings = {
  connectProps: {
    uri: {
      props: {
        query: {
          view: "_fetchNativeSetting"
        }
      }
    }
  }
};

export const fetchUploadDocumentQuery = {
  fetch: {
    props: {
      query: {
        view: "_fetchUploadDocument",
        limit: -1
      }
    }
  }
};

export const fetchUploadMusicQuery = {
  fetch: {
    props: {
      query: {
        view: "_fetchUploadMusic",
        limit: -1
      }
    }
  }
};
