import React from "react";

export default ({ onClick: actionClick, Box, Modal, theme, resolveMQ }) => {
  class LinkAction extends React.Component {
    state = { isHover: false };
    onClick = () => {
      const { onClick, ...restProps } = this.props;
      if (onClick) {
        onClick({ onClick: actionClick, props: restProps });
      } else {
        actionClick && actionClick({ props: restProps });
      }
    };

    onMouseEnter = () => {
      this.setState({ isHover: true });
    };
    onMouseLeave = () => {
      this.setState({ isHover: false });
    };

    render() {
      let { activeMQ, action, getPath } = this.props;
      let {
        tooltipStyle = {},
        actionContainerStyle = {},
        selectedActionContainerStyle = {},
        boxStyle = {}
      } = resolveMQ(theme, ["tooltipStyle", "actionContainerStyle", "selectedActionContainerStyle"], activeMQ);
      let { modal, boxStyle: titleBoxStyle } = tooltipStyle;
      let { link: { uri }, title, imageProps = {} } = action;
      let path = getPath();
      let isSelected = path.indexOf(uri) > -1;
      let { image, selectedImage, imageStyle } = imageProps;

      let render = [
        <Box
          getRef={_ => (this.ref = _)}
          viewStyle={
            isSelected ? { ...actionContainerStyle, ...selectedActionContainerStyle } : { ...actionContainerStyle }
          }
          onClick={this.onClick}
          image={(isSelected && selectedImage) || image}
          imageStyle={imageStyle}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
      ];
      if (title) {
        render.push(
          <Modal parentRef={this.ref} isOpen={this.state.isHover} onClose={this.onMouseLeave} {...modal}>
            <Box {...titleBoxStyle} text={title} />
          </Modal>
        );
      }
      return <Box {...boxStyle} render={render} />;
    }
  }
  return LinkAction;
};
