import React from "react";
import { UploadDocumentNoData } from "../noData/NoData";
import { fetchUploadDocumentQuery } from "../queries";
import PermissionConfirmBox from "./PermissionConfirmBox";
import {
  DeviceSetting,
  theme,
  getImage,
  Box,
  withContext,
  showMessage,
  getAction,
  DocsImageComponent,
  shallowCompare,
  I18N
} from "../../../FsCloudComponent";
import { SECURE_TYPES } from "../../../Constants";

const { fonts, colors, shadows } = theme;
let { h1_16, h9_20 } = fonts;
let { primaryColor, brownGrey } = colors;
let { cardShadow } = shadows;

class UploadHeader extends React.Component {
  render() {
    let { data, link } = this.props;
    let headerPath =
      (link && link.props && link.props.filter && link.props.filter.path) ||
      (data && data.length > 0 && data[0] && data[0].path && data[0].path.substr(0, data[0].path.lastIndexOf("/")));
    headerPath = headerPath && headerPath.replace(/\//g, " > ");
    return (
      <Box
        viewStyle={{
          paddingLeft: 10,
          paddingRight: 10
        }}
        render={[
          {
            text: I18N.t("itemUploadHeaderText"),
            viewStyle: {
              paddingTop: 12,
              paddingBottom: 6
            },
            textStyle: {
              ...h9_20,
              color: brownGrey
            }
          },
          headerPath && {
            viewStyle: {
              paddingTop: 6,
              paddingBottom: 6
            },
            text: headerPath,
            textStyle: {
              ...h9_20,
              color: primaryColor,
              numberOfLines: 1
            }
          }
        ]}
      />
    );
  }
}

UploadHeader = withContext(UploadHeader, {
  link: "Screen.Route.link"
});

class UploadCardComponent extends React.Component {
  shouldComponentUpdate(nextProps) {
    let propEqual = shallowCompare(nextProps, this.props, {
      name: "UploadCardComponent",
      matchProps: { selected: 1 }
    });
    if (!propEqual) {
      return true;
    }
    return false;
  }

  render() {
    let { data, selectRow, selected, link: { secureType } = {} } = this.props;
    let boxExtraProps = {};
    if (data.directory) {
      boxExtraProps.Container = getAction("link");
      boxExtraProps.containerProps = {
        action: {
          link: {
            uri: "/upload-docs",
            props: {
              filter: {
                path: data.path
              }
            },
            secureType
          }
        }
      };
    } else {
      boxExtraProps.onClick = () => {
        selectRow && selectRow(data);
      };
    }
    let component = (
      <Box
        direction={"row"}
        viewStyle={{
          height: 74,
          marginLeft: 12,
          marginRight: 12,
          marginTop: 5,
          marginBottom: 5,
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: 4,
          ...cardShadow
        }}
        {...boxExtraProps}
        render={[
          data.directory || selected
            ? {
                viewStyle: { paddingLeft: 12, paddingRight: 8 },
                image: getImage(selected ? "docSelectedIcon" : "uploadFolderIcon")
              }
            : {
                viewStyle: { paddingLeft: 12, paddingRight: 8 },
                render: <DocsImageComponent data={data} field={"name"} />
              },
          {
            width: "1fr",
            viewStyle: { paddingLeft: 12 },
            render: [
              {
                textStyle: { ...h1_16, color: primaryColor },
                text: data && data.name
              }
            ]
          }
        ]}
      />
    );
    return component;
  }
}

class UploadCard extends React.Component {
  moveToHome = () => {
    let { url, deleteUri } = this.props;
    deleteUri && deleteUri(url, { uri: "/upload-docs" });
  };

  render() {
    let { data, isSelected } = this.props;
    if (data && typeof data === "object" && data["permissionDenied"]) {
      return (
        <PermissionConfirmBox
          onConfirm={() => {
            DeviceSetting.app();
            this.moveToHome();
          }}
          onCancel={() => {
            showMessage(I18N.t("filesPermissionMessage"));
            this.moveToHome();
          }}
        />
      );
    } else {
      let selected = isSelected && isSelected(data);
      return <UploadCardComponent {...this.props} selected={selected} />;
    }
  }
}

UploadCard = withContext(UploadCard, {
  isSelected: "SelectionStore.isSelected",
  selectRow: "SelectionStore.selectRow",
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri",
  link: "ScreenRoute.link"
});

export default {
  UploadDocument: {
    component: "table",
    dataObject: "Resource",
    title: () => I18N.t("selectItems"),
    renderHeader: data => <UploadHeader data={data} />,
    renderNoData: <UploadDocumentNoData />,
    bodyProps: {
      DataRow: UploadCard
    },
    ...fetchUploadDocumentQuery,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds, link: { secureType } = {} }) =>
          secureType !== SECURE_TYPES.VAULT && selectedIds && selectedIds.length > 0,
        action: "uploadDocumentMobileAction"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link: { secureType } = {} }) =>
          secureType === SECURE_TYPES.VAULT && selectedIds && selectedIds.length > 0,
        action: "uploadVaultDocumentMobileAction"
      },
      {
        type: "multiActions",
        visible: ({ data }) => (data && data.length ? true : false),
        action: ["selectNone", "uploadDocumentSelectAll"]
      }
    ]
  }
};
