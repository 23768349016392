import React from "react";

export default ({ Box, theme, languages, getImage }) => {
  const { colors, fonts } = theme;
  const { themeColor } = colors;
  const { h16_l } = fonts;
  class LanguageDisplayComponent extends React.Component {
    render() {
      const { getLanguage, action } = this.props;
      const { showText = true } = action || {};
      return (
        <Box
          viewStyle={{ alignItems: "center" }}
          direction="row"
          textStyle={{ ...h16_l, color: themeColor }}
          render={[
            { ...(showText ? { viewStyle: { paddingRight: 8 } } : {}), image: getImage("languageSelectorIcon") },
            showText && { text: languages[getLanguage && getLanguage()] },
            showText && {
              viewStyle: { paddingLeft: 8, width: 32, height: 44, alignItems: "center", justifyContent: "center" },
              image: getImage("languageSelectorDropdown")
            }
          ]}
        />
      );
    }
  }
  return LanguageDisplayComponent;
};
