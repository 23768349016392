export const getVisibilityParams = props => {
  let { getSelectedData, rowData, origin } = props;
  let selectedData = getSelectedData && getSelectedData();
  let collectionOwner = origin === "playlist" ? true : rowData && rowData["owner"];
  let selectedResourcesOwner = void 0;
  if (selectedData && Array.isArray(selectedData) && selectedData.length) {
    selectedResourcesOwner = true;
    if (origin !== "playlist" && origin !== "places" && origin !== "deviceFolder") {
      for (let index = 0; index < selectedData.length; index++) {
        if (!selectedData[index]["resourceOwner"]) {
          selectedResourcesOwner = false;
          break;
        }
      }
    }
  }
  return { collectionOwner, selectedResourcesOwner };
};
