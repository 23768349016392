import React from "react";
import {
  Box,
  View,
  resolveMQ,
  getComponent,
  getJsonComponent,
  theme,
  I18N,
  PopupHeader as Header,
  StatusBar as LoadingIndicator,
  showMessage,
  getImage,
  getContactPicUrl,
  withContext
} from "../../../../FsCloudComponent";
import moment from "moment";
import { ContactsCard, DuplicateContactsCard } from "./ContactCards";
import { getSelectedData } from "../../resource/ResourceUtility";
const { fonts, colors, bgs, shadows } = theme;
const { h16_l, h9, h9_21, h1_16, h2_l_18 } = fonts;
const { themeBg, whiteTwo } = bgs;
let { cardShadow } = shadows;
const { highlightColor, primaryColor, tomato, brownGrey } = colors;

const minHeightSM = 205;
const maxHeightSM = 500;

const noDataComponentTheme = {
  containerStyleSM: {
    viewStyle: {
      height: minHeightSM
    },
    textStyle: {
      ...h9_21,
      color: brownGrey
    }
  },
  containerStyleMD: {
    viewStyle: {
      width: 486,
      height: 205
    },
    textStyle: {
      ...h9_21,
      color: brownGrey
    }
  },
  buttonStyle: {
    viewStyle: {
      marginTop: 24,
      marginRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: themeBg,
      cursor: "pointer",
      alignSelf: "flex-end",
      borderRadius: 4
    },
    textStyle: {
      ...h16_l,
      color: highlightColor
    }
  },
  primaryTextStyle: {
    viewStyle: { paddingTop: 23, paddingLeft: 30 }
  }
};

const noDataMetadata = {
  recoverContact: {
    headerTitle: () => I18N.t("recoverDelete"),
    primaryText: () => I18N.t("recoverContactMessage"),
    buttonTextMD: () => I18N.t("close")
  },
  mergeDuplicate: {
    headerTitle: () => I18N.t("fixDuplicates"),
    primaryText: () => I18N.t("noDuplicatesFound"),
    buttonTextMD: () => I18N.t("close")
  }
};

class RenderNoData extends React.Component {
  render() {
    let { activeMQ, onClose, origin } = this.props;
    const { containerStyle, primaryTextStyle, buttonStyle } = resolveMQ(
      noDataComponentTheme,
      ["containerStyle", "primaryTextStyle", "buttonStyle"],
      activeMQ
    );
    const { headerTitle, primaryText, buttonText } = resolveMQ(
      noDataMetadata[origin],
      ["headerTitle", "primaryText", "buttonText"],
      activeMQ
    );
    return (
      <Box
        {...containerStyle}
        render={[
          <Header title={headerTitle} onClose={onClose} />,
          {
            ...primaryTextStyle,
            text: primaryText
          },
          buttonText &&
            onClose && {
              ...buttonStyle,
              text: buttonText,
              onClick: onClose
            }
        ]}
      />
    );
  }
}

const DataRowSM = props => {
  let { data, selectRow, isSelected, origin } = props;
  let selected = isSelected && isSelected(data);
  return (
    <Box
      onClick={() => {
        selectRow && selectRow(data);
      }}
      render={[
        {
          direction: "row",
          viewStyle: {
            height: 54,
            marginLeft: 30,
            marginRight: 30,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 5,
            marginBottom: 5,
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 4,
            ...cardShadow
          },
          render: [
            {
              viewStyle: { paddingRight: 12 },
              imageStyle: { width: 32, height: 32, borderRadius: 16, backgroundColor: whiteTwo },
              image: selected
                ? getImage("contactSelectedIcon")
                : data["photo_id"]
                ? { uri: getContactPicUrl(data["photo_id"]) }
                : getImage("profileImageIcon")
            },
            {
              width: "1fr",
              render: [
                {
                  render: [
                    {
                      textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                      text: data.name
                    }
                  ]
                },
                {
                  direction: "row",
                  textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                  viewStyle: { paddingTop: 4 },
                  render:
                    origin === "mergeDuplicate"
                      ? [
                          {
                            text: `${I18N.t("similarContacts")} - ${data.matchCount}`
                          }
                        ]
                      : [
                          {
                            text: `${moment(new Date(data.deletedOn)).format("DD/MM/YYYY")} | ${moment(
                              new Date(data.deletedOn)
                            ).format("hh:mm a")}`
                          }
                        ]
                }
              ]
            }
          ]
        }
      ]}
    />
  );
};

class SimpleTable extends React.Component {
  render() {
    let { data, rowProps, selectRow, isSelected, origin } = this.props;
    const tableProps = {
      componentType: "table",
      component: getComponent("table"),
      data,
      componentProps: {
        selectable: true,
        showHeaderMD: true,
        rowProps,
        dataRowProps: {
          selectRow,
          isSelected,
          origin
        },
        bodyPropsSM: {
          DataRow: DataRowSM
        },
        bodyPropsMD: {
          bodyStyle: {
            flex: 1
          }
        },
        headerRowProps: {
          theme: {
            ...theme.tableHeaderRowStyleMD,
            viewStyle: {
              ...theme.tableHeaderRowStyleMD.viewStyle,
              marginRight: 15
            }
          }
        }
      }
    };
    const Table = getJsonComponent("jsonTable");
    return <Table {...tableProps} />;
  }
}

const RecoverDeletedContactSelector = props => <SimpleTable {...props} rowProps={{ ...ContactsCard }} />;
const MergeDuplicateContactSelector = props => <SimpleTable {...props} rowProps={{ ...DuplicateContactsCard }} />;

const metadata = {
  recoverContact: {
    containerStyleSM: { minHeight: minHeightSM, maxHeight: maxHeightSM },
    containerStyleMD: { width: 626, height: 409 },
    headerTitle: () => I18N.t("recoverDelete"),
    ListComponent: RecoverDeletedContactSelector,
    buttonText: () => I18N.t("recover"),
    selectionHeaderMessageSM: () => I18N.t("recoverDeleteSelectMessageSM"),
    selectionHeaderMessageMD: () => I18N.t("recoverDeleteSelectMessageMD"),
    successMessageSingle: () => I18N.t("contactRestoredMessgae"),
    successMessageMultiple: () => I18N.t("contactsRestoredMessgae")
  },
  mergeDuplicate: {
    containerStyleSM: { minHeight: minHeightSM, maxHeight: maxHeightSM },
    containerStyleMD: { width: 520, height: 409 },
    headerTitle: () => I18N.t("fixDuplicates"),
    ListComponent: MergeDuplicateContactSelector,
    buttonText: () => I18N.t("merge"),
    selectionHeaderMessage: () => I18N.t("mergeDuplicateSelectMessage"),
    successMessageSingle: () => I18N.t("contactMergedMessgae"),
    successMessageMultiple: () => I18N.t("contactsMergedMessgae")
  }
};

class ContactSelector extends React.Component {
  state = {};

  onButtonClick = async ({ data }) => {
    const { selectedIds = [] } = this.state.SelectionStore || {};
    let selectedData;
    if (!selectedIds.length) {
      this.setState({
        error: I18N.t("selectErrorMesage")
      });
      return;
    } else {
      selectedData = getSelectedData({ data, selectedIds });
    }
    try {
      const { urls, invoke, action, onClose } = this.props;
      this.setState({ loading: true });
      let origin = action && action.origin;
      let service;
      let { successMessageSingle, successMessageMultiple } = metadata[origin];
      const itemsLength = selectedIds && selectedIds.length;
      let message = successMessageSingle();
      if (itemsLength > 1) {
        message = `${itemsLength} ${successMessageMultiple()}`;
      }
      if (origin === "recoverContact") {
        service = {
          service: {
            url: urls["restoreContacts"],
            uriProps: {
              data: {
                contacts: selectedIds
              }
            }
          }
        };
      } else {
        service = {
          service: {
            url: urls["removeDuplicateContact"],
            uriProps: {
              data: selectedData
            }
          }
        };
      }
      await invoke(service);
      showMessage && showMessage(message, 2000);
      this.setState({ loading: false });
      onClose && onClose();
    } catch (e) {
      let message = I18N.t(e.code);
      if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
        message = e.message;
      }
      this.setState({ error: message, loading: false });
    }
  };

  render() {
    const { action, activeMQ, onClose } = this.props;
    let { origin, connectProps, defaultConnectState } = action;
    let { containerStyle, headerTitle, ListComponent, buttonText, selectionHeaderMessage } = resolveMQ(
      metadata[origin],
      ["containerStyle", "headerTitle", "ListComponent", "buttonText", "selectionHeaderMessage"],
      activeMQ
    );
    if (connectProps && typeof connectProps === "function") {
      connectProps = connectProps(this.props);
    }
    const ConnectPanelTable = getJsonComponent("connectPanelTable");
    if (defaultConnectState) {
      defaultConnectState = {
        ...defaultConnectState,
        dataParams: {
          ...defaultConnectState.dataParams
        }
      };
    }
    return (
      <ConnectPanelTable
        defaultConnectState={defaultConnectState}
        connectProps={connectProps}
        state={this.state}
        setState={state => this.setState(state)}
        componentProps={{ selectionMode: true }}
        renderComponent={connectResult => {
          let { data, pending } = connectResult;
          let noData = !pending && (!data || (Array.isArray(data) && !data.length));
          if (noData) {
            return <RenderNoData activeMQ={activeMQ} onClose={onClose} origin={origin} />;
          } else {
            let { SelectionStore } = this.state;
            let { selectedIds, selectRow, isSelected } = SelectionStore || {};
            let selectionCount = selectedIds && selectedIds.length;
            let listComponentProps = {
              data,
              selectRow,
              isSelected,
              origin
            };
            if (origin === "mergeDuplicate") {
              let translateMessage = selectionHeaderMessage();
              selectionHeaderMessage = translateMessage.replace("_duplicateCount_", `${data && data.length}`);
            }
            return (
              <View style={{ flex: 1, ...containerStyle }}>
                {(pending || this.state.loading) && <LoadingIndicator />}
                <Header title={headerTitle} onClose={onClose} />
                {data && data.length ? (
                  <Box
                    viewStyle={{ flex: 1 }}
                    render={[
                      {
                        viewStyle: {
                          paddingLeft: 30,
                          paddingRight: 30,
                          paddingTop: 24
                        },
                        textStyle: {
                          ...h9_21,
                          color: primaryColor
                        },
                        text: selectionCount ? `${selectionCount} ${I18N.t("selected")} ` : selectionHeaderMessage
                      },
                      {
                        viewStyle: { flex: 1 },
                        render: <ListComponent {...listComponentProps} />
                      },
                      {
                        viewStyle: {
                          paddingTop: 14,
                          paddingBottom: 14,
                          paddingRight: 30,
                          paddingLeft: 30,
                          alignItems: "center"
                        },
                        direction: "row",
                        render: [
                          {
                            viewStyle: {
                              flex: 1,
                              paddingRight: 12
                            },
                            textStyle: {
                              ...h9,
                              color: tomato,
                              numberOfLines: 2
                            },
                            text: this.state.error || ""
                          },
                          {
                            viewStyle: {
                              paddingTop: 9,
                              paddingBottom: 9,
                              paddingLeft: 16,
                              paddingRight: 16,
                              backgroundColor: themeBg,
                              cursor: "pointer",
                              borderRadius: 4
                            },
                            textStyle: {
                              ...h16_l,
                              color: highlightColor
                            },
                            text: buttonText,
                            onClick: e => {
                              this.onButtonClick({ data });
                            }
                          }
                        ]
                      }
                    ]}
                  />
                ) : (
                  void 0
                )}
              </View>
            );
          }
        }}
      />
    );
  }
}

ContactSelector = withContext(ContactSelector, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user"
});

export default ContactSelector;
