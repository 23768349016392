import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// import cx from "classnames";
// import isInternetExplorerBefore from "./ieDetection";

const isPromise = obj =>
  !!obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function";

function windowOpen(url, { name, height = 400, width = 550 }, onShareWindowClose) {
  /* eslint-disable no-mixed-operators */
  const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2;
  const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2;
  /* eslint-enable no-mixed-operators */

  const config = {
    height,
    width,
    left,
    top,
    location: "no",
    toolbar: "no",
    status: "no",
    directories: "no",
    menubar: "no",
    scrollbars: "yes",
    resizable: "no",
    centerscreen: "yes",
    chrome: "yes"
  };
  const shareDialog = window.open(
    url,
    name,
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(", ")
  );

  if (onShareWindowClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onShareWindowClose(shareDialog);
        }
      } catch (e) {
        /* eslint-disable no-console */
        // console.error(e);
        /* eslint-enable no-console */
      }
    }, 1000);
  }

  return shareDialog;
}

class ShareButton extends PureComponent {
  static propTypes = {
    additionalProps: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledStyle: PropTypes.object,
    name: PropTypes.string,
    network: PropTypes.string.isRequired,
    networkLink: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    opts: PropTypes.object,
    openWindow: PropTypes.bool,
    url: PropTypes.string.isRequired,
    role: PropTypes.string,
    style: PropTypes.object,
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
    beforeOnClick: PropTypes.func,
    onShareWindowClose: PropTypes.func,
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    disabledStyle: {
      opacity: 0.6
    },
    openWindow: true,
    role: "button",
    tabIndex: "0"
  };

  onClick = e => {
    const { disabled, onClick, openWindow, beforeOnClick } = this.props;

    if (disabled) {
      return;
    }

    e.preventDefault();

    let link = this.link();

    let clickHandler = openWindow ? () => this.openWindow(link) : () => onClick(link);

    if (beforeOnClick) {
      const returnVal = beforeOnClick();

      if (isPromise(returnVal)) {
        returnVal.then(res => {
          if (res && res.url) {
            link = this.link(res.url);
          }
          clickHandler();
        });
      } else {
        clickHandler();
      }
    } else {
      clickHandler();
    }
  };

  onKeyPress = e => {
    if (e.key === "Enter" || e.key === 13 || e.key === " " || e.key === 32) {
      this.onClick(e);
    }
  };

  openWindow = link => {
    const { onShareWindowClose, windowWidth, windowHeight } = this.props;

    const windowOptions = {
      height: windowHeight,
      width: windowWidth
    };

    windowOpen(link, windowOptions, onShareWindowClose);
  };

  link(resUrl) {
    const { url, opts, networkLink } = this.props;
    return networkLink(resUrl ? resUrl : url, opts);
  }

  render() {
    const { additionalProps, children, disabled, disabledStyle, style } = this.props;

    // const classes = cx(
    //   "SocialMediaShareButton",
    //   `SocialMediaShareButton--${network}`,
    //   {
    //     "SocialMediaShareButton--disabled": !!disabled,
    //     disabled: !!disabled
    //   },
    //   className
    // );

    return (
      <div
        {...additionalProps}
        onClick={this.onClick}
        onKeyPress={this.onKeyPress}
        style={{
          ...style,
          ...(disabled ? disabledStyle : {})
        }}
      >
        {children}
      </div>
    );
  }
}

function createShareButton(network, link, optsMap = () => ({}), propTypes, defaultProps = {}) {
  const CreatedButton = props => <ShareButton {...props} network={network} networkLink={link} opts={optsMap(props)} />;

  CreatedButton.propTypes = propTypes;
  CreatedButton.defaultProps = defaultProps;

  return CreatedButton;
}

export default createShareButton;
