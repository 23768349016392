import React from "react";
import App from "./components/client-app-boilerplate";

import {
  getImage,
  breakPoints,
  enhancePropWithMQ,
  resolveMQ,
  resolveActionLayout,
  View,
  Table,
  GridTable,
  Panel,
  Form,
  UserStore,
  NotificationHandler,
  AppStore,
  StatusBar,
  Nav,
  Box,
  withContext,
  Connect,
  SelectionStore,
  theme,
  mergeActions,
  NavHeader,
  NavBody,
  NavStatusBar,
  showMessage,
  showError,
  dataFormat,
  getTheme,
  mergeJsonComponents,
  DrawerLayout,
  getComponent,
  UploadWrapper,
  NetworkListener,
  MusicStore,
  Orientation,
  Platform,
  mergeComponents,
  mergeCardComponents,
  Toast,
  CoreAction as CoreActionHoc,
  invokeShareAction,
  invokeDownloadAction,
  invokeDownloadZip,
  invokeAddImageToPdfGen,
  invokeUploadAction,
  setRouterContextInfo,
  BlockerStatusBar,
  getPendingBlockerRef,
  isPendingBlocker,
  addLog,
  pushLog,
  urls,
  versionData,
  brandName,
  ensureSettingInfo,
  logFirebaseAnalyticsScreenEvent,
  getUser,
  firebaseSave
} from "./FsCloudComponent";
import dataObjects from "./metadata/dataObjects";
import routes from "./metadata/routes";
import screens from "./metadata/screens";

import { urlMappings, landingUrl } from "./metadata/nav";
import {
  RenderNav as RenderNavHoc,
  NavLayoutWeb as NavLayoutWebHoc,
  NavLayoutMobile as NavLayoutMobileHoc
} from "./renderNav";

import routeLoaderHoc from "./components/client-app-boilerplate/lib/routeLoader";
import DetailCollection from "./metadata/screens/collection/DetailCollection";
import AlbumSlideShow from "./metadata/screens/gallery/AlbumSlideShow";
import {
  AlbumItemSelector,
  SetItemSelector,
  PlaylistItemSelector
} from "./metadata/screens/collection/CollectionItemSelector";
import {
  SharePopupComponent,
  ShareViaEmailPopup,
  GetLinkPopupComponent,
  ShareMedia,
  NativeGetLink,
  AllowAddContentForm,
  ResourceShareOptionsPopup,
  JoinCollectionPopup
} from "./metadata/screens/share";
import JoinGroupPopup from "./metadata/screens/groups/JoinGroupPopup";
import UpdateProfileAction from "./metadata/screens/profile/UpdateProfileAction";
import AddContact from "./metadata/screens/configuration/contacts/AddContact";
import ContactSelector from "./metadata/screens/configuration/contacts/ContactSelector";
import { VaultPinWebComponent } from "./metadata/screens/vault/VaultPinComponent";
import BlockDeviceWebComponent from "./metadata/screens/profile/BlockDevice";
import LogoutPopup from "./metadata/screens/logout/LogoutPopup";
import InvokePrint from "./components/print";

const AppShare = withContext(
  CoreActionHoc({
    onClick: invokeShareAction
  }),
  {
    getSelectedData: "SelectionStore.getSelectedData",
    unSelectAll: "SelectionStore.unSelectAll",
    activeMQ: "ActiveMQ",
    selectedIds: "SelectionStore.selectedIds",
    invoke: "App.invoke",
    connectInvoke: "Screen.Connect.invoke",
    urls: "App.urls"
  }
);

const AppDownload = withContext(CoreActionHoc({ onClick: invokeDownloadAction }), {
  getSelectedIds: "SelectionStore.getSelectedIds",
  getSelectedData: "SelectionStore.getSelectedData",
  unSelectAll: "SelectionStore.unSelectAll",
  invoke: "App.invoke",
  urls: "App.urls",
  link: "ScreenRoute.link"
});

const PrintFile = withContext(CoreActionHoc({ onClick: InvokePrint }), {
  getSelectedData: "SelectionStore.getSelectedData",
  unSelectAll: "SelectionStore.unSelectAll"
});

const DownloadZip = withContext(CoreActionHoc({ onClick: invokeDownloadZip }), {
  getSelectedIds: "SelectionStore.getSelectedIds",
  getSelectedData: "SelectionStore.getSelectedData",
  unSelectAll: "SelectionStore.unSelectAll",
  invoke: "App.invoke",
  urls: "App.urls",
  link: "ScreenRoute.link"
});

const AddImageToPdfGen = withContext(CoreActionHoc({ onClick: invokeAddImageToPdfGen }), {
  getSelectedIds: "SelectionStore.getSelectedIds",
  getSelectedData: "SelectionStore.getSelectedData",
  link: "ScreenRoute.link",
  replaceUri: "Router.replaceUri"
});

const AppUpload = withContext(CoreActionHoc({ onClick: invokeUploadAction }), {
  uploadFile: "App.upload",
  uploadRequest: "App.uploadRequest",
  getSelectedIds: "SelectionStore.getSelectedIds",
  getSelectedData: "SelectionStore.getSelectedData",
  onPending: "Screen.Connect.onPending",
  getPath: "Router.getPath",
  deleteUri: "Router.deleteUri",
  replaceUri: "Router.replaceUri",
  user: "User.user"
});

mergeComponents({
  detailCollection: DetailCollection,
  albumItemSelector: AlbumItemSelector,
  setItemSelector: SetItemSelector,
  playlistItemSelector: PlaylistItemSelector,
  sharePopup: SharePopupComponent,
  resourceShareOptionPopup: ResourceShareOptionsPopup,
  shareViaEmailPopup: ShareViaEmailPopup,
  getLinkPopup: GetLinkPopupComponent,
  allowAddContentForm: AllowAddContentForm,
  joinCollectionPopup: JoinCollectionPopup,
  joinGroupPopup: JoinGroupPopup,
  addContact: AddContact,
  contactSelector: ContactSelector
});

mergeCardComponents({
  albumSlideShow: AlbumSlideShow
});

mergeActions({
  logoutPopup: LogoutPopup,
  vaultPin: VaultPinWebComponent,
  BlockDeviceWebComponent: BlockDeviceWebComponent.BlockDeviceWebComponent,
  shareMedia: ShareMedia,
  printFile: PrintFile,
  appShare: AppShare,
  download: AppDownload,
  downloadZip: DownloadZip,
  addImageToPdfGen: AddImageToPdfGen,
  upload: AppUpload,
  nativeGetLink: NativeGetLink,
  updateProfile: UpdateProfileAction
});

const { getRoute, JsonTable, ConnectPanelTable } = routeLoaderHoc({
  withContext,
  dataObjects,
  theme,
  dataFormat,
  getTheme,
  routes: {
    ...routes
  },
  screens: {
    ...screens
  },
  breakPoints,
  enhancePropWithMQ,
  resolveMQ,
  resolveActionLayout,
  Table,
  GridTable,
  Panel,
  Form,
  getImage,
  Connect,
  getComponent,
  SelectionStore,
  sortHeader: "sortableHeader",
  addLog,
  logFirebaseAnalyticsScreenEvent
});

mergeJsonComponents({
  jsonTable: JsonTable,
  connectPanelTable: ConnectPanelTable
});

const AppLoader = () => {
  if (Platform.OS !== "web") {
    return null;
  }
  return (
    <View style={{ flex: 1 }}>
      <StatusBar />
    </View>
  );
};

const renderNav = RenderNavHoc({
  urlMappings,
  resolveMQ,
  NavLayoutSM: withContext(
    NavLayoutMobileHoc({
      Box,
      Header: NavHeader,
      Body: NavBody,
      NavStatusBar,
      Orientation
    }),
    {
      orientation: "App.orientation",
      setOrientation: "App.setOrientation"
    }
  ),
  NavLayoutMD: NavLayoutWebHoc({
    Box,
    Header: NavHeader,
    Body: NavBody
  }),
  DrawerLayout,
  Platform,
  UploadWrapper,
  NetworkListener,
  MusicStore
});

export default App({
  Toast,
  UserStore,
  NotificationHandler,
  AppStore,
  StatusBar,
  landingUrl,
  renderNav,
  Nav,
  resolveMQ,
  getRoute,
  showError,
  showMessage,
  AppLoader,
  setRouterContextInfo,
  BlockerStatusBar,
  getPendingBlockerRef,
  isPendingBlocker,
  pushLog,
  urls,
  versionData,
  brandName,
  ensureSettingInfo,
  Platform,
  getUser,
  firebaseSave
});
