import React from "react";
import { Box, getImage, ScrollView, View, theme, withContext, getContactPicUrl } from "../../../../FsCloudComponent";
import { ContactsSearchNoData } from "../../noData/NoData";
import { getContactQuery } from "../../queries";
const { fonts, colors, bgs } = theme;
const { primaryColor, whiteTwo, brownGrey } = colors;
const { lightPink } = bgs;
const { h3MD, h9 } = fonts;

class ContactsList extends React.Component {
  onClick = data => {
    let { addUri, getPath } = this.props;
    addUri &&
      addUri(getPath(), {
        uri: "/contact-detail",
        props: { filter: { _id: data._id } }
      });
  };

  render() {
    let { data } = this.props;
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          viewStyle={{ paddingLeft: 16, paddingRight: 16 }}
          render={[
            data.map(contact => {
              if (!contact) {
                return void 0;
              }
              return {
                viewStyle: {
                  marginTop: 12,
                  marginBottom: 12
                },
                direction: "row",
                onClick: () => this.onClick(contact),
                render: [
                  {
                    viewStyle: { paddingRight: 8 },
                    imageStyle: { width: 32, height: 32, borderRadius: 16, backgroundColor: whiteTwo },
                    image: contact["photo_id"]
                      ? { uri: getContactPicUrl(contact["photo_id"]) }
                      : getImage("profileImageIcon")
                  },
                  {
                    viewStyle: { paddingLeft: 8, justifyContent: "center", alignItems: "center" },
                    textStyle: {
                      ...h9,
                      color: primaryColor
                    },
                    text: contact["name"]
                  }
                ]
              };
            })
          ]}
        />
        <View
          style={{
            marginLeft: 48,
            marginTop: 18,
            borderBottomWidth: 1,
            borderBottomColor: lightPink
          }}
        />
      </ScrollView>
    );
  }
}

class SearchContact extends React.Component {
  render() {
    let { data, pending, searchText } = this.props;
    if (!data || !data.length) {
      return null;
    }
    return (
      <View style={{ flex: 1 }}>
        {searchText && (
          <Box
            viewStyle={{
              paddingTop: 16,
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 4
            }}
            textStyle={{
              ...h3MD,
              color: brownGrey
            }}
            text={`Search result for '${searchText}' ${!pending && data && data.length ? "(" + data.length + ")" : ""}`}
          />
        )}
        <ContactsList {...this.props} />
      </View>
    );
  }
}

SearchContact = withContext(SearchContact, {
  pending: "Screen.Connect.pending",
  searchText: "Screen.Connect.dataParams.ftsString"
});

export default {
  SearchContact: {
    component: "table",
    dataObject: "Contact",
    ComponentSM: SearchContact,
    headerActionsSM: [
      {
        type: "searchInput",
        loadDataOnReset: true
      }
    ],
    renderNoData: <ContactsSearchNoData />,
    ...getContactQuery
  }
};
