import React from "react";
import { renameCollection, renameResource, getErrorMessage } from "./CollectionUtility";

export default ({ Box, View, theme, getInput, I18N, Header, LoadingIndicator, showMessage }) => {
  const { colors, fonts, bgs } = theme;
  const { themeColor, brownGrey, tomato, highlightColor } = colors;
  const { lightPink } = bgs;
  const { h9 } = fonts;

  const metadata = {
    image: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("imageRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("image")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameResource,
      nameLengthError: () => I18N.t("imageNameLengthMessage")
    },
    video: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("videoRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("video")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameResource,
      nameLengthError: () => I18N.t("videoNameLengthMessage")
    },
    doc: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("docRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("doc")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameResource,
      nameLengthError: () => I18N.t("docNameLengthMessage")
    },
    audio: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("audioRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("music")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameResource,
      nameLengthError: () => I18N.t("audioNameLengthMessage")
    },
    album: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("albumRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("album")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameCollection,
      nameLengthError: () => I18N.t("albumNameLengthMessage")
    },
    set: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("setRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("set")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameCollection,
      nameLengthError: () => I18N.t("setNameLengthMessage")
    },
    playlist: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("playlistRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("playlist")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameCollection,
      nameLengthError: () => I18N.t("playlistNameLengthMessage")
    },
    vaultAlbum: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("albumRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("album")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameCollection,
      nameLengthError: () => I18N.t("albumNameLengthMessage")
    },
    vaultSet: {
      headerTitle: () => I18N.t("renameCaps"),
      mandatoryError: () => I18N.t("renameValidationMessage"),
      successMessage: () => I18N.t("setRenameSuccessMessage"),
      duplicateError: () => `${I18N.t("set")} ${I18N.t("nameAlreadyExists")}`,
      renameMethod: renameCollection,
      nameLengthError: () => I18N.t("setNameLengthMessage")
    }
  };

  const componentTheme = {
    containerStyle: {
      viewStyle: {
        width: 357
      }
    },
    inputStyle: {
      parentContainerStyle: {
        viewStyle: {
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 23
        },
        direction: "row"
      },
      containerStyle: {
        viewStyle: {
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 4,
          backgroundColor: lightPink
        }
      },
      textInputStyle: { ...h9, color: brownGrey },
      extensionStyle: {
        viewStyle: {
          marginLeft: 8,
          padding: 14,
          borderRadius: 4,
          backgroundColor: lightPink
        },
        textStyle: {
          ...h9,
          color: brownGrey
        }
      }
    },
    footerStyle: {
      containerStyle: {
        viewStyle: {
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 24,
          paddingBottom: 24,
          alignItems: "center"
        },
        direction: "row"
      },
      errorStyle: {
        viewStyle: {
          flex: 1,
          paddingRight: 12
        },
        textStyle: {
          ...h9,
          color: tomato,
          numberOfLines: 2
        }
      },
      saveActionStyle: {
        viewStyle: {
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: themeColor,
          borderRadius: 4,
          cursor: "pointer"
        },
        textStyle: {
          ...h9,
          color: highlightColor
        }
      }
    }
  };

  class RenameCollection extends React.Component {
    state = { error: void 0, loading: false };

    componentWillUnmount() {
      this._unmounted = true;
    }

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    onChangeName = name => {
      let newState = { name };
      if (!this.state.loading) {
        if (this.state.error) {
          newState["error"] = void 0;
        }
        this._setState(newState);
      }
    };

    onClose = () => {
      let { onClose, unSelectAll } = this.props;
      onClose ? onClose() : this._setState({ loading: false });
      unSelectAll && unSelectAll();
    };

    invoke = async (serviceProps, options = {}) => {
      const { invoke } = this.props;
      if (invoke) {
        return await invoke(serviceProps, { skipPending: true, ...options });
      }
    };

    render() {
      let { action, data, getSelectedData, urls } = this.props;
      const invoke = this.invoke;
      let { name, error, loading } = this.state;
      let { origin, form: { formSource } = {} } = action;
      if (formSource === "Resource" && (!data || Array.isArray(data))) {
        let selectedData = getSelectedData && getSelectedData();
        if (selectedData && selectedData.length) {
          data = selectedData[0];
        } else if (data && data.length === 1) {
          data = data[0];
        }
      }
      data = data || {};
      if (origin === undefined) {
        origin = data.type;
      }
      if (name === undefined) {
        name = data.name;
        if (name && formSource === "Resource") {
          let nameWithoutExtension = name;
          let indexOfDot = name.lastIndexOf(".");
          if (indexOfDot >= 0) {
            nameWithoutExtension = name.substring(0, indexOfDot);
            if (!this.extension) {
              this.extension = name.substring(indexOfDot);
            }
          }
          name = nameWithoutExtension;
        }
      }
      const TextInput = getInput("text");

      const { containerStyle, inputStyle, footerStyle } = componentTheme;
      let { headerTitle, mandatoryError, duplicateError, nameLengthError, successMessage, renameMethod } = metadata[
        origin
      ];
      if (!headerTitle) {
        headerTitle = I18N.t("renameCaps");
      }

      let maxLength = 55;
      let minLength = 3;
      if (formSource === "Resource" && this.extension) {
        maxLength = maxLength - this.extension.length;
        minLength = minLength - this.extension.length;
      }

      const onSaveClick = async () => {
        name = name && name.trim();
        if (!name || name.length === 0) {
          this._setState({ error: mandatoryError });
          return;
        }
        if ((name.length < minLength || name.length > maxLength) && nameLengthError) {
          this._setState({ error: nameLengthError });
          return;
        }
        try {
          this._setState({ loading: true });
          if (formSource === "Resource") {
            name = name + (this.extension || "");
          }
          await renameMethod({ invoke, urls, data, name, origin });
          let message = successMessage && successMessage();
          message && showMessage && showMessage(message, 2000);
          this.onClose();
        } catch (e) {
          let message = getErrorMessage(e, { duplicateError, nameLengthError, I18N });
          this._setState({ error: message, loading: false });
        }
      };

      return (
        <View>
          {loading && <LoadingIndicator />}
          <Box
            {...containerStyle}
            render={[
              <Header title={headerTitle} onClose={this.onClose} />,
              {
                direction: "row",
                ...inputStyle.parentContainerStyle,
                render: [
                  {
                    ...inputStyle.containerStyle,
                    width: "1fr",
                    render: (
                      <TextInput
                        value={name}
                        onChangeValue={this.onChangeName}
                        style={{ ...inputStyle.textInputStyle }}
                        autoFocus
                        onSubmitEditing={onSaveClick}
                        maxLength={maxLength}
                      />
                    )
                  },
                  this.extension && {
                    ...inputStyle.extensionStyle,
                    text: this.extension
                  }
                ]
              },
              {
                ...footerStyle.containerStyle,
                render: [
                  {
                    ...footerStyle.errorStyle,
                    text: error || ""
                  },
                  {
                    ...footerStyle.saveActionStyle,
                    text: () => I18N.t("save"),
                    onClick: onSaveClick
                  }
                ]
              }
            ]}
          />
        </View>
      );
    }
  }

  return RenameCollection;
};
