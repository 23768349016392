import React from "react";
import PermissionConfirmBox from "./PermissionConfirmBox";
import { UploadMusicNoData } from "../noData/NoData";
import { fetchUploadMusicQuery } from "../queries";
import { DeviceSetting, theme, getImage, Box, withContext, showMessage, I18N } from "../../../FsCloudComponent";

const { fonts, colors, shadows } = theme;
let { h1_16, h10, h9_20 } = fonts;
let { primaryColor, brownGrey } = colors;
let { cardShadow } = shadows;

const UploadHeader = () => {
  return (
    <Box
      viewStyle={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 12,
        paddingBottom: 6
      }}
      textStyle={{
        ...h9_20,
        color: brownGrey
      }}
      text={I18N.t("itemUploadHeaderText")}
    />
  );
};

class UploadCardComponent extends React.Component {
  render() {
    let { data, selectRow, selected } = this.props;
    let boxExtraProps = {
      onClick: () => {
        selectRow && selectRow(data);
      }
    };
    let component = (
      <Box
        direction={"row"}
        viewStyle={{
          marginLeft: 12,
          marginRight: 12,
          marginTop: 5,
          marginBottom: 5,
          padding: 12,
          alignItems: "center",
          borderRadius: 4,
          backgroundColor: "white",
          ...cardShadow
        }}
        {...boxExtraProps}
        render={[
          selected
            ? {
                viewStyle: { paddingRight: 12 },
                image: getImage("docSelectedIcon")
              }
            : {
                viewStyle: { paddingRight: 12 },
                image: getImage("musicIcon")
              },
          {
            width: "1fr",
            viewStyle: { padding: 4 },
            itemStyle: { padding: 2 },
            render: [
              {
                textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                text: data && (data.title || data.name)
              },
              {
                textStyle: { ...h10, color: brownGrey, numberOfLines: 1 },
                text: data && data.author
              }
            ]
          }
        ]}
      />
    );
    return component;
  }
}

class UploadCard extends React.Component {
  moveToHome = () => {
    let { url, deleteUri } = this.props;
    deleteUri && deleteUri(url, { uri: "/upload-music" });
  };

  render() {
    let { data, isSelected } = this.props;
    if (data && typeof data === "object" && data["permissionDenied"]) {
      return (
        <PermissionConfirmBox
          onConfirm={() => {
            DeviceSetting.app();
            this.moveToHome();
          }}
          onCancel={() => {
            showMessage(I18N.t("audioPermissionMessage"));
            this.moveToHome();
          }}
        />
      );
    } else {
      let selected = isSelected && isSelected(data);
      return <UploadCardComponent {...this.props} selected={selected} />;
    }
  }
}

UploadCard = withContext(UploadCard, {
  isSelected: "SelectionStore.isSelected",
  selectRow: "SelectionStore.selectRow",
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri"
});

export default {
  UploadMusic: {
    component: "table",
    dataObject: "Resource",
    renderNoData: <UploadMusicNoData />,
    title: () => I18N.t("selectItems"),
    renderHeader: UploadHeader,
    bodyProps: {
      DataRow: UploadCard
    },
    ...fetchUploadMusicQuery,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        action: "uploadMusicMobileAction"
      },
      {
        type: "multiActions",
        visible: ({ data }) => (data && data.length ? true : false),
        action: ["selectNone", "uploadSelectAll"]
      }
    ]
  }
};
