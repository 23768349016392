import React from "react";
import { resolveValue } from "app-utility-functions";

export default ({ Box, getAvatarDisplay, resolveMQ, ...hocProps }) => {
  class Avatar extends React.Component {
    getImage = () => {
      let { data, image, imageField, getImageUrl } = this.props;
      if (imageField && getImageUrl) {
        let imageValue = resolveValue(data, imageField);
        if (Array.isArray(imageValue)) {
          if (imageValue.length > 0) {
            imageValue = imageValue[0];
          } else {
            imageValue = null;
          }
        }
        if (imageValue) {
          image = {
            uri: getImageUrl && getImageUrl(imageValue)
          };
        }
      }
      return image;
    };

    render() {
      let { data, field, text, onClick, theme, display, activeMQ, renderImage } = this.props;
      const { theme: themeHoc = {} } = resolveMQ(hocProps, ["theme"], activeMQ);
      let { boxStyle } = theme || themeHoc || {};
      let renderComponent = void 0;
      if (display) {
        renderComponent = { text: display };
      } else {
        let image = this.getImage();
        if (image) {
          if (renderImage && typeof renderImage === "function") {
            renderImage = renderImage({
              data,
              source: image,
              imageStyle: boxStyle.imageStyle
            });
          }
          renderComponent = renderImage || { image };
        } else {
          if (field) {
            text = resolveValue(data, field);
          }
          renderComponent = { text: getAvatarDisplay(text) };
        }
      }
      return <Box {...boxStyle} onClick={onClick} render={[renderComponent]} />;
    }
  }
  return Avatar;
};
