export default {
  "/my-shared-data": {
    screen: "SharedData",
    hash: ({ hash }) => {
      if (hash) {
        return {
          sharedToken: hash
        };
      }
    }
  },
  "/montage-view": {
    screen: "SharedData",
    hash: ({ hash }) => {
      if (hash) {
        return {
          sharedMontage: true,
          sharedToken: hash
        };
      }
    }
  },
  "/be-part-album": {
    screen: "BePart",
    hash: ({ hash }) => {
      if (hash) {
        return {
          sharedToken: hash,
          origin: "album"
        };
      }
    }
  },
  "/be-part-set": {
    screen: "BePart",
    hash: ({ hash }) => {
      if (hash) {
        return {
          sharedToken: hash,
          origin: "set"
        };
      }
    }
  },
  "/gallery": {
    screen: "GalleryTab"
  },
  "/duplicate-resources": {
    screenSM: "DuplicateResourcesSM",
    screenMD: "DuplicateResourcesMD"
  },
  "/album-new": {
    screen: "AlbumNew",
    props: {
      dataMode: "insert",
      viewTitle: "New Album"
    }
  },
  "/album-detail": {
    screen: "AlbumDetail"
  },
  "/place-detail": {
    screen: "PlacesDetail"
  },
  "/device-folder-detail": {
    screen: "DeviceFolderDetail"
  },
  "/select-image": {
    screen: "SelectImages"
  },
  "/select-album": {
    screen: "SelectAlbum"
  },
  "/gallery-detail": {
    screen: "GalleryDetail"
  },
  "/duplicate-resource-detail": {
    screen: "DuplicateResourceDetail"
  },
  "/album-gallery-detail": {
    screen: "AlbumGalleryDetail"
  },
  "/place-gallery-detail": {
    screen: "PlaceGalleryDetail"
  },
  "/device-folder-gallery-detail": {
    screen: "DeviceFolderGalleryDetail"
  },
  "/search-gallery-detail": {
    screen: "SearchGalleryDetail"
  },
  "/group-gallery-detail": {
    screen: "GroupGalleryDetail"
  },
  "/vault-gallery-detail": {
    screen: "VaultGalleryDetail"
  },
  "/archive-gallery-detail": {
    screen: "ArchiveGalleryDetail"
  },
  "/select-cover-image": {
    screen: "SelectCoverImage"
  },
  "/montage": {
    screen: "AlbumSlideShowComponent"
  },
  "/montage-place": {
    screen: "PlaceSlideShowComponent"
  },
  "/montage-folder": {
    screen: "DeviceFolderSlideShowComponent"
  },
  "/memories-of-the-day": {
    screenSM: "MemoriesOfDaySM",
    screenMD: "MemoriesOfDayMD"
  },
  "/montage-highlights": {
    screen: "HighlightsSlideShowComponent"
  },
  "/all-stories": {
    screenMD: "ViewAllStoriesMD",
    screenSM:"ViewAllStoriesSM"
  }
};
