import React from "react";

export default ({ Box, resolveMQ, getImage, theme: themeHoc }) => {
  class OnlyAction extends React.Component {
    render() {
      let { action, activeMQ, theme, ...restProps } = this.props;
      const { containerStyle } = resolveMQ(theme || themeHoc, ["containerStyle"], activeMQ);
      let { text, image, title } = action;
      let boxProps = {};
      if (image) {
        boxProps = {
          image: getImage(image) || image,
          ...containerStyle.imageStyle
        };
      } else if (text) {
        boxProps = {
          text,
          ...containerStyle.textStyle
        };
      }
      return <Box title={title} {...boxProps} {...restProps} />;
    }
  }
  return OnlyAction;
};
