import React from "react";
import {
  ConfirmBox,
  I18N,
  StatusBar as LoadingIndicator,
  Modal,
  resolveMQ,
  showMessage,
  withContext,
  clearJoinInfo,
  logFirebaseAnalyticsEvent
} from "../../../FsCloudComponent";

const modalStyle = {
  modalSM: {
    width: 0.9,
    animationType: "none",
    stopPropagation: true,
    position: "center",
    style: {
      borderRadius: 4,
      overflow: "hidden"
    },
    parentStyle: {
      zIndex: 100
    }
  },
  modalMD: {
    stopPropagation: true,
    position: "center",
    style: {
      borderRadius: 4
    },
    escEnabled: false,
    parentStyle: {
      zIndex: 100
    }
  }
};

const metadata = {
  joinGroup: {
    headerTitleMD: "joinGroupCaps",
    primaryText: "joinGroupMessage",
    buttonText: "join"
  },
  alreadyMember: {
    headerTitleMD: "joinGroupCaps",
    primaryText: "joinGroupAlreadyMemberMessage",
    buttonText: "ok"
  },
  linkExpired: {
    headerTitleMD: "linkExpiredCaps",
    primaryText: "linkExpiredMessage",
    buttonText: "ok"
  }
};

class JoinGroupPopup extends React.Component {
  state = {};

  onJoinGroup = async () => {
    let { invoke, urls, sharedToken, replaceUri, onClose } = this.props;
    try {
      this.setState({ loading: true });
      let joinResult = await invoke({
        service: {
          url: urls["acceptInvitation"],
          uriProps: {
            data: { token: sharedToken }
          }
        }
      });
      logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "group_created" });
      this.setState({ loading: false });
      clearJoinInfo && clearJoinInfo();
      replaceUri &&
        replaceUri({
          uri: "/home#group",
          nestedTab: "groupGallery",
          _user: {
            group: joinResult.group
          }
        });
    } catch (err) {
      if (err.code === "group/multiple") {
        this.setState({
          type: "alreadyMember",
          loading: false
        });
      } else if (err.code === "invitation/unknown" || err.code === "Invitation/expired") {
        this.setState({
          type: "linkExpired",
          loading: false
        });
      } else {
        this.setState({ loading: false });
        showMessage && showMessage(err.message, 2000);
        onClose && onClose();
      }
    }
  };

  render() {
    let { onClose, activeMQ, sharedToken } = this.props;
    if (!sharedToken) {
      return null;
    }
    let { type = "joinGroup" } = this.state;
    const { modal } = resolveMQ(modalStyle, ["modal"], activeMQ);
    const { headerTitle, primaryText, buttonText } = resolveMQ(
      metadata[type],
      ["headerTitle", "primaryText", "buttonText"],
      activeMQ
    );
    return (
      <Modal isOpen={true} {...modal} onClose={onClose}>
        {this.state.loading && <LoadingIndicator />}
        <ConfirmBox
          title={headerTitle ? I18N.t(headerTitle) : void 0}
          message={I18N.t(primaryText)}
          confirmText={I18N.t(buttonText)}
          onConfirm={type === "joinGroup" ? this.onJoinGroup : onClose}
          onCancel={type === "joinGroup" ? onClose : void 0}
        />
      </Modal>
    );
  }
}

JoinGroupPopup = withContext(JoinGroupPopup, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user",
  replaceUri: "Router.replaceUri"
});

export default JoinGroupPopup;
