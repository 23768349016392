import axios from "axios";
import fileDownload from "js-file-download";

const threshold = 1024 * 1024 * 2; // 2 mb
let resumableData = {};
export default ({ encryptDecryptWeb, isPublicUrl, isNetworkUrl, getDecryptionProps, getEncryptionKeyAndIV }) => {
  const clearResumableData = url => {
    if (url && resumableData[url]) {
      delete resumableData[url];
    }
  };
  const CANCEL_REASONS = {
    PAUSED: "PAUSED",
    CANCELLED: "CANCELED"
  };
  const DOWNLOAD_UTILS = {
    CANCEL_REASONS,
    clearResumableData
  };
  const onDownloadProgress = (progressCallback, params) => progressEvent => {
    const { initialOffset = 0, total = progressEvent.total } = params || {};
    if (progressEvent.lengthComputable && progressCallback) {
      var percentCompleted = Math.round(((initialOffset + progressEvent.loaded) * 100) / total);
      progressCallback(percentCompleted);
    }
  };
  const downloadResource = async params => {
    let { key, iv, isResumable, resource_size, progressCallback, ...rest } = params || {};
    console.log("---------909090909------------",params);
    let { url } = params || {};
    try {
      let response = void 0;
      if (isResumable) { 
        let { end = 0 } = resumableData[url] || {};
        while (end <= resource_size) {
          // console.log("@@@@@ download end is ", end, " out of ", resource_size);
          let { end: prev_end = 0, data = new Blob([]) } = resumableData[url] || {};
          let start = prev_end;
          console.log(prev_end);
          end = start + threshold;
          rest.headers = { ...rest.headers, range: `bytes=${start}-${end - 1}` };
          if (end > resource_size) {
            rest.headers.range = `bytes=${start}-`;
          }
          // for loader
          rest.onDownloadProgress = onDownloadProgress(progressCallback, {
            initialOffset: start,
            total: resource_size
          });
          response = await axios(rest);
          if (key) {
            response.data = await encryptDecryptWeb(response.data, {
              key,
              iv,
              method: "decrypt",
              ivIncrement: start / 16
            });
          }
          data = new Blob([data, response.data], { type: response.data.type });
          resumableData[url] = { ...resumableData[url], start, end, data };
        }
        response.data = resumableData[url].data;
        delete resumableData[url];
      } else {
        response = await axios(rest);
        if (key) {
          response.data = await encryptDecryptWeb(response.data, { key, iv, method: "decrypt" });
        }
      }
      return response && response.data;
    } catch (error) {
      if (!(error.message === CANCEL_REASONS.PAUSED || error.message === "Network Error")) {
        delete resumableData[url];
      }
      throw error;
    }
  };

  const downloadFile = async props => {
    let { fileName, resource, decryptionSourceProps, progressCallback, cancelToken, isResumable } = props;
    let { resource_url, encrypted, resource_size } = resource;
    if (!resource_url) {
      throw new Error("resource_url is mandatory in downloadFile");
    }
    if (!isNetworkUrl(resource_url)) {
      throw new Error("resource_url can't be networkUrl in downloadFile");
    }
    if (isPublicUrl(resource_url)) {
      encrypted = false;
    }
    let key, iv;
    if (encrypted) {
      let decryptionProps = getDecryptionProps(resource, decryptionSourceProps);
      let { key: encryptionKey, iv: encryptionIV } = (await getEncryptionKeyAndIV(decryptionProps)) || {};
      key = encryptionKey;
      iv = encryptionIV;
      console.log("{}{}{}{}{}{}: keykeykey: ", {
        url: resource_url,
        method: "GET",
        responseType: "blob",
        cancelToken,
        isResumable,
        resource_size
      });
    }
    let response = await downloadResource({
      key,
      iv,
      url: resource_url,
      method: "GET",
      responseType: "blob",
      progressCallback,
      onDownloadProgress: onDownloadProgress(progressCallback),
      cancelToken,
      isResumable,
      resource_size
    });
    fileDownload(response, fileName);
  };
  return {
    downloadFile,
    DOWNLOAD_UTILS
  };
};
