import React from "react";
export default ({ StatusBar }) => {
  class BlockerStatusBar extends React.Component {
    state = {};
    onPending = pending => {
      this.setState(state => {
        let _pending = state.pending || 0;
        if (pending !== undefined) {
          _pending = _pending + pending;
          if (_pending < 0) {
            _pending = 0;
          }
        }

        return { pending: _pending };
      });
    };

    isPending = () => {
      return this.state.pending;
    };

    render() {
      let { pending } = this.state;
      if (pending) {
        return <StatusBar />;
      } else {
        return null;
      }
    }
  }
  return BlockerStatusBar;
};
