import React from "react";
import { commonLayout } from "../../layouts";
import CollectionActions from "../collection/CollectionActions";
import { getDocExtIcon } from "../../../components/collection/CollectionUtility";
import { isResourceUploading, isResourceDownloading } from "../resource/ResourceUtility";
import {
  Box,
  getImage,
  ImageBackground,
  theme,
  I18N,
  dataFormat,
  FSListItem,
  SDocsImageComponent,
  View,
  Text,
  Image,
  FSRowAction,
  RowUpdateListenerWrapper,
  Platform,
  getModeText
} from "../../../FsCloudComponent";
const { numberFormatter } = dataFormat;

let { colors, shadows, fonts, listContentContainerStyle, cardHeight } = theme;
let { h1_16, h2_l_18, h9_18, h20_24, h9_20 } = fonts;
let { brownGrey, primaryColor, highlightColor } = colors;
let { cardShadow } = shadows;
let backgroundImageStyle = {
  height: 50,
  width: 50,
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat"
};
let docBackgroundIcon = getImage("docsImageBackground");
let iconMenu = getImage("iconMenuGrey");
let imageProps = { resizeMode: "contain" };
const secondaryText = { ...h2_l_18, color: brownGrey, numberOfLines: 1 };
const secondaryTextPadding = { ...secondaryText, paddingLeft: 2 };
const separatorTextStyle = { ...h2_l_18, color: brownGrey, numberOfLines: 1, paddingLeft: 5, paddingRight: 5 };
const secondaryTextFlex = { flex: 1, ...h2_l_18, color: brownGrey, numberOfLines: 1 };
const style1 = {
  height: cardHeight,
  marginLeft: 12,
  marginRight: 12,
  marginTop: 5,
  marginBottom: 5,
  backgroundColor: highlightColor,
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 12,
  paddingBottom: 12,
  alignItems: "center",
  flexDirection: "row",
  borderRadius: 4,
  ...cardShadow
};
const iconMenuContainerStyle = {
  height: 44,
  width: 44,
  alignItems: "center",
  justifyContent: "center"
};
const style2 = { paddingRight: 16 };
const style3 = { ...h1_16, color: primaryColor, numberOfLines: 1 };
const style4 = { flex: 1 };
const absStyle = { position: "absolute", top: 0, right: 8 };
const uploadingIcon = getImage("uploadingIcon");
const downloadingIcon = getImage("downloadingIcon");
const style5 = { paddingTop: 4, flexDirection: "row" };
const commonSMListProps = {
  style: { flex: 1, marginTop: 5 },
  contentContainerStyle: listContentContainerStyle,
  getItemLayout: (data, index) => {
    return { length: cardHeight, offset: index * (cardHeight + 10), index };
  }
};

const DocsCardSM = {
  render: [
    {
      direction: "row",
      viewStyle: {
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 12,
        paddingBottom: 12,
        alignItems: "center"
      },
      render: [
        {
          viewStyle: { paddingRight: 16 },
          value: { field: "name", type: "imageDoc" }
        },
        {
          width: "1fr",
          render: [
            {
              direction: "row",
              render: [
                {
                  width: "1fr",
                  textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                  value: { field: "name", type: "text" }
                },
                {
                  value: ({ data }) => {
                    return isResourceUploading(data) ? { image: uploadingIcon } : {};
                  }
                },
                {
                  value: ({ data }) => {
                    return isResourceDownloading(data) ? { image: downloadingIcon } : {};
                  }
                }
              ]
            },
            {
              direction: "row",
              textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
              viewStyle: { paddingTop: 4 },
              separator: "-",
              render: [
                {
                  value: { field: "resource_size", type: "fileSize" }
                },
                {
                  viewStyle: { flex: 1 },
                  value: ({ data }) => {
                    let lastModifiedDate = dataFormat.date({
                      data,
                      field: "resource_lastModified",
                      fieldDef: { format: "DD/MM/YYYY" }
                    });
                    return {
                      text: lastModifiedDate ? `${I18N.t("lastModified")} ${lastModifiedDate}` : ""
                    };
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

const DocRowComponent = ({ onClick, onLongPress, isSelected, activeMQ, data }) => {
  const size = dataFormat.fileSize({ data, field: "resource_size" });
  const lastModified = dataFormat.date({
    data,
    field: "resource_lastModified",
    fieldDef: { format: "DD/MM/YYYY" }
  });
  let selected = isSelected(data);
  return (
    <View style={style1} onClick={onClick} onLongPress={onLongPress}>
      <View style={style2}>
        <SDocsImageComponent selected={selected} data={data} field="name" activeMQ={activeMQ} />
      </View>
      <View style={style4}>
        <View>
          <View>
            <Text style={style3}>{data.name}</Text>
          </View>
          {isResourceUploading(data) && (
            <View style={absStyle}>
              <Image source={uploadingIcon} />
            </View>
          )}
          {isResourceDownloading(data) && (
            <View style={absStyle}>
              <Image source={downloadingIcon} />
            </View>
          )}
        </View>
        <View style={style5}>
          <Text style={secondaryText}>{size}</Text>
          <Text style={separatorTextStyle}>-</Text>
          <Text style={secondaryTextFlex}>
            {I18N.t("lastModified")} {lastModified}
          </Text>
        </View>
      </View>
    </View>
  );
};

const GroupDocRowComponent = ({ onClick, onLongPress, isSelected, activeMQ, data }) => {
  const size = dataFormat.fileSize({ data, field: "resource_size" });
  const postedOn = dataFormat.date({
    data,
    field: "postedOn",
    fieldDef: { format: "DD/MM/YY" }
  });
  const postedBy = data.postedBy.name;
  let selected = isSelected(data);
  return (
    <View style={style1} onClick={onClick} onLongPress={onLongPress}>
      <View style={style2}>
        <SDocsImageComponent selected={selected} data={data} field="name" activeMQ={activeMQ} />
      </View>
      <View style={style4}>
        <View>
          <View>
            <Text style={style3}>{data.name}</Text>
          </View>
          {isResourceUploading(data) && (
            <View style={absStyle}>
              <Image source={uploadingIcon} />
            </View>
          )}
          {isResourceDownloading(data) && (
            <View style={absStyle}>
              <Image source={downloadingIcon} />
            </View>
          )}
        </View>
        <View style={style5}>
          <Text style={secondaryText}>{size}</Text>
          <Text style={separatorTextStyle}>-</Text>
          <Text style={secondaryTextFlex}>
            {I18N.t("postedOn")} {postedOn} {I18N.t("by")} {postedBy}
          </Text>
        </View>
      </View>
    </View>
  );
};

const DocsCardSMRender = RowComponent => ({
  lastSelected,
  currentSelected,
  item,
  index,
  isSelected,
  activeMQ,
  isSelectionMode,
  selectRow,
  onClick,
  language
}) => {
  return (
    <FSListItem
      data={item}
      lastSelected={lastSelected}
      currentSelected={currentSelected}
      index={index}
      onClick={onClick}
      language={language}
      selectRow={selectRow}
      isSelectionMode={isSelectionMode}
    >
      {({ onClick, onLongPress }) => {
        let component = (
          <RowComponent
            isSelected={isSelected}
            activeMQ={activeMQ}
            onClick={onClick}
            onLongPress={onLongPress}
            data={item}
          />
        );
        if (Platform.OS !== "web" && RowUpdateListenerWrapper) {
          component = <RowUpdateListenerWrapper data={item}>{component}</RowUpdateListenerWrapper>;
        }
        return component;
      }}
    </FSListItem>
  );
};

const DocsCardSMListProps = {
  ...commonSMListProps,
  indicator_field: "resource_lastModified",
  renderItem: DocsCardSMRender(DocRowComponent)
};

const GroupDocsCardSMListProps = {
  ...commonSMListProps,
  indicator_field: "postedOn",
  renderItem: DocsCardSMRender(GroupDocRowComponent)
};

const DocsCardMD = {
  primaryAction: ["markDelete"],
  primaryActionProps: {
    openModalOnRightClick: true,
    source: "Header"
  },
  columns: [
    {
      width: "1fr",
      header: () => I18N.t("name"),
      field: "name",
      type: "imageDoc",
      sortable: "lower_name",
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("size"),
      field: "resource_size",
      type: "fileSize",
      width: "150px",
      sortable: true,
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("modified"),
      field: "resource_lastModified",
      type: "text",
      width: "150px",
      fieldValue: props => {
        let dateObj = new Date(props.resource_lastModified);
        let date, month, year;
        date = dateObj.getDate();
        month = dateObj.getMonth();
        year = dateObj.getFullYear();
        month = I18N.t(`month_${month}`);
        return `${date} ${month} ${year}`;
      },
      sortable: true,
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("type"),
      type: "text",
      field: "docType",
      width: "210px",
      fieldValue: props => {
        return I18N.t(props.docType);
      },
      sortable: true,
      addOnSort: "_id"
    },
    {
      header: () => I18N.t("source"),
      type: "text",
      field: "source",
      defaultText: "-",
      width: "150px"
    }
  ]
};

const SearchDocsCardMD = {
  primaryAction: ["markDelete"],
  primaryActionProps: {
    openModalOnRightClick: true,
    source: "Header"
  },
  columns: [
    {
      width: "1fr",
      header: () => I18N.t("name"),
      field: "name",
      type: "imageDoc"
    },
    {
      header: () => I18N.t("size"),
      field: "resource_size",
      type: "fileSize",
      width: "175px"
    },
    {
      header: () => I18N.t("modified"),
      field: "resource_lastModified",
      type: "text",
      fieldValue: props => {
        let dateObj = new Date(props.resource_lastModified);
        let date, month, year;
        date = dateObj.getDate();
        month = dateObj.getMonth();
        year = dateObj.getFullYear();
        month = I18N.t(`month_${month}`);
        return `${date} ${month} ${year}`;
      },
      width: "202px"
    },
    {
      header: () => I18N.t("type"),
      type: "text",
      field: "docType",
      width: "148px"
    },
    {
      header: () => I18N.t("source"),
      type: "text",
      field: "source",
      defaultText: "-",
      width: "170px"
    }
  ]
};

const DocsCard = commonLayout({
  SM: DocsCardSM,
  MD: DocsCardMD
});

const SearchDocsCard = commonLayout({
  SM: DocsCardSM,
  MD: SearchDocsCardMD
});

const GroupDocsCard = commonLayout({
  SM: {
    render: [
      {
        direction: "row",
        viewStyle: {
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 12,
          paddingBottom: 12,
          alignItems: "center"
        },
        render: [
          {
            viewStyle: { paddingRight: 16 },
            value: { field: "name", type: "imageDoc" }
          },
          {
            width: "1fr",
            render: [
              {
                direction: "row",
                render: [
                  {
                    width: "1fr",
                    textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                    value: { field: "name", type: "text" }
                  },
                  {
                    value: ({ data }) => {
                      return isResourceUploading(data) ? { image: uploadingIcon } : {};
                    }
                  },
                  {
                    value: ({ data }) => {
                      return isResourceDownloading(data) ? { image: downloadingIcon } : {};
                    }
                  }
                ]
              },
              {
                direction: "row",
                textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                viewStyle: { paddingTop: 4 },
                separator: "-",
                render: [
                  {
                    value: { field: "resource_size", type: "fileSize" }
                  },
                  { width: "1fr", value: { exp: `Posted on {postedOn}` } }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  MD: {
    primaryAction: ["markDelete"],
    primaryActionProps: {
      openModalOnRightClick: true,
      source: "Header"
    },
    columns: [
      {
        width: "1fr",
        header: () => I18N.t("name"),
        field: "name",
        type: "imageDoc",
        sortable: "lower_name",
        addOnSort: "_id"
      },
      {
        header: () => I18N.t("size"),
        field: "resource_size",
        type: "fileSize",
        width: "175px",
        sortable: true,
        addOnSort: "_id"
      },
      {
        header: () => I18N.t("postedOn"),
        field: "postedOn",
        type: "text",
        fieldValue: props => {
          let dateObj = new Date(props.resource_lastModified);
          let date, month, year;
          date = dateObj.getDate();
          month = dateObj.getMonth();
          year = dateObj.getFullYear();
          month = I18N.t(`month_${month}`);
          return `${date} ${month} ${year}`;
        },
        width: "202px",
        sortable: true,
        addOnSort: "_id"
      },
      {
        header: () => I18N.t("type"),
        type: "text",
        field: "docType",
        width: "148px",
        sortable: true,
        addOnSort: "_id"
      },
      {
        header: () => I18N.t("postedBy"),
        type: "text",
        fieldValue: data => data && data.postedBy && data.postedBy.name,
        defaultText: "-",
        width: "170px",
        sortable: "postedBy.name",
        addOnSort: "_id"
      }
    ]
  }
});

const DocsSetSMRender = ({ item, index, onClick, activeMQ, language, ...rest }) => {
  return (
    <FSListItem data={item} index={index} isSelectionMode={false} onClick={onClick} language={language}>
      {({ onClick }) => {
        const itemCount = numberFormatter(item.itemCount, "0.0i");
        return (
          <View style={style1} onClick={onClick}>
            <View style={style2}>
              <ImageBackground style={backgroundImageStyle} imageStyle={imageProps} source={docBackgroundIcon}>
                <Box image={getDocExtIcon({ data: item, getImage })} />
              </ImageBackground>
            </View>
            <View style={style4}>
              <View>
                <View>
                  <Text style={style3}>{item.name}</Text>
                </View>
              </View>
              <View style={style5}>
                <Text style={secondaryText}>{itemCount}</Text>
                <Text style={secondaryTextPadding}>{I18N.t("items")}</Text>
                <Text style={separatorTextStyle}>|</Text>
                <Text style={secondaryTextFlex}>{getModeText(item)}</Text>
              </View>
            </View>
            <FSRowAction
              actions={CollectionActions}
              action={
                item.type === "vaultSet"
                  ? ["addItemsToVaultSetLink", "renameSet", "markDeleteSet"]
                  : [
                      "manageCollabration",
                      "makePrivateSet",
                      "share",
                      "shareNative",
                      "getLinkNative",
                      "addItemsToSetLink",
                      "renameSet",
                      "markDeleteSet"
                    ]
              }
              data={item}
              activeMQ={activeMQ}
            >
              <View style={iconMenuContainerStyle}>
                <Image source={iconMenu} />
              </View>
            </FSRowAction>
          </View>
        );
      }}
    </FSListItem>
  );
};

const DocsSetSMListProps = {
  ...commonSMListProps,
  renderItem: DocsSetSMRender
};

const SetCard = origin =>
  commonLayout({
    primaryAction: origin === "vaultSet" ? "vaultSetDetailLink" : "setDetailLink",
    SM: {
      render: [
        {
          direction: "row",
          viewStyle: {
            height: 74,
            alignItems: "center"
          },
          render: [
            {
              viewStyle: { paddingLeft: 12, paddingRight: 16 },
              value: ({ data }) => ({
                render: (
                  <ImageBackground
                    style={{
                      height: 50,
                      width: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                    imageStyle={{ resizeMode: "contain" }}
                    source={getImage("docsImageBackground")}
                  >
                    <Box image={getDocExtIcon({ data, getImage })} />
                  </ImageBackground>
                )
              })
            },
            {
              width: "1fr",
              render: [
                {
                  textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1, whiteSpace: "pre" },
                  value: { field: "name" }
                },
                {
                  direction: "row",
                  textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                  viewStyle: { paddingTop: 4 },
                  separator: "|",
                  render: [
                    {
                      direction: "row",
                      render: [
                        {
                          value: ({ data }) => {
                            return { text: numberFormatter(data.itemCount, "0.0i") };
                          }
                        },
                        { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                      ]
                    },
                    {
                      width: "1fr",
                      value: ({ data }) => {
                        return { text: getModeText(data) };
                      }
                    }
                  ]
                }
              ]
            },
            {
              viewStyle: {
                paddingLeft: 8,
                paddingRight: 8,
                alignItems: "center",
                justifyContent: "center",
                height: 40,
                width: 40
              },
              value: {
                image: "iconMenuGrey",
                action:
                  origin === "vaultSet"
                    ? ["addItemsToVaultSetLink", "renameSet", "markDeleteSet"]
                    : [
                        "manageCollabration",
                        "makePrivateSet",
                        "share",
                        "shareNative",
                        "getLinkNative",
                        "addItemsToSetLink",
                        "renameSet",
                        "markDeleteSet"
                      ]
              }
            }
          ]
        }
      ]
    },
    MD: {
      viewStyle: { margin: 15, borderRadius: 4 },
      render: [
        {
          viewStyle: {
            height: 98,
            alignItems: "center",
            ...cardShadow,
            backgroundColor: highlightColor,
            cursor: "pointer"
          },
          direction: "row",
          render: [
            {
              viewStyle: { paddingLeft: 16, paddingRight: 16 },
              value: ({ data }) => ({
                image: getDocExtIcon({ data, getImage })
              })
            },
            {
              width: "1fr",
              viewStyle: { paddingLeft: 12, paddingRight: 16 },
              render: [
                {
                  direction: "row",
                  render: [
                    {
                      viewStyle: { flex: 1, paddingBottom: 8 },
                      textStyle: {
                        ...h20_24,
                        color: primaryColor,
                        numberOfLines: 1,
                        whiteSpace: "pre"
                      },
                      value: { field: "name" }
                    },
                    {
                      viewStyle: {
                        height: 24,
                        width: 24,
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer"
                      },
                      value: {
                        image: "iconMenuGrey",
                        action:
                          origin === "vaultSet"
                            ? [
                                "addItemsToVaultSetFormWithGreyImage",
                                "renameSetWithGreyImage",
                                "markDeleteSetWithGreyImage"
                              ]
                            : [
                                "manageCollabrationWithGreyImage",
                                "makePrivateSetWithGreyImage",
                                "shareWithGreyImage",
                                "addItemsToSetFormWithGreyImage",
                                "renameSetWithGreyImage",
                                "markDeleteSetWithGreyImage"
                              ]
                      }
                    }
                  ]
                },
                {
                  direction: "row",
                  separator: "|",
                  textStyle: {
                    ...h9_18,
                    color: brownGrey,
                    numberOfLines: 1
                  },
                  render: [
                    {
                      direction: "row",
                      render: [
                        {
                          value: ({ data }) => {
                            return { text: numberFormatter(data.itemCount, "0.0i") };
                          }
                        },
                        { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                      ]
                    },
                    {
                      width: "1fr",
                      value: ({ data }) => {
                        return { text: getModeText(data) };
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  });

const DocsSetCard = SetCard("docsSet");
const VaultDocsSetCard = SetCard("vaultSet");

const SelectSetCard = commonLayout({
  primaryAction: "addItemsSelectCollection",
  SM: {
    render: [
      {
        direction: "row",
        viewStyle: {
          height: 74,
          alignItems: "center"
        },
        render: [
          {
            viewStyle: { paddingLeft: 12, paddingRight: 16 },
            value: { image: "docSetIcon" }
          },
          {
            width: "1fr",
            render: [
              {
                textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                value: { field: "name" }
              },
              {
                direction: "row",
                textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                viewStyle: { paddingTop: 4 },
                separator: "|",
                render: [
                  {
                    direction: "row",
                    render: [
                      {
                        value: ({ data }) => {
                          return { text: numberFormatter(data.itemCount, "0.0i") };
                        }
                      },
                      { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                    ]
                  },
                  {
                    width: "1fr",
                    value: ({ data }) => {
                      return { text: getModeText(data) };
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
});

const SelectSetHeader = data => {
  if (!data || !data.length) {
    return null;
  }
  return (
    <Box
      viewStyle={{
        paddingTop: 12,
        paddingBottom: 7,
        paddingLeft: 10,
        paddingRight: 10
      }}
      textStyle={{
        ...h9_20,
        color: brownGrey
      }}
      text={I18N.t("addToSetMessage")}
    />
  );
};

export {
  DocsCard,
  GroupDocsCard,
  SearchDocsCard,
  DocsSetCard,
  VaultDocsSetCard,
  SelectSetCard,
  SelectSetHeader,
  DocsCardSMRender,
  DocsCardSMListProps,
  DocsSetSMListProps,
  GroupDocsCardSMListProps
};
