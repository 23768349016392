import React from "react";
import { MusicCard, SearchMusicCard, MusicCardSMListProps } from "./MusicCards";
import { MusicNoData, SearchMusicTypeNoData, ArchiveMusicNoData } from "../noData/NoData";
import { musicAllQuery, searchMusicQuery, archiveMusicQuery } from "../queries";
import { theme, SearchType, FSList, RenderPromptMessage, Platform } from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import { isDownloadActionVisible } from "../resource/ResourceUtility";

const downloadActionSM = {
  ...ResourceActions.download,
  visible: isDownloadActionVisible
};

const headerActionsMD = [
  {
    type: "headerAction",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    image: "playMusicIcon",
    action: "playMusic"
  },
  {
    type: "headerAction",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    image: "addToCollectionIcon",
    action: "addMusicToPlaylistForm"
  },
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewMusic"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "moveToArchiveIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "moveToArchive"
  },
  {
    type: "headerAction",
    image: "deleteIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "markDelete"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone", "downloadZip"]
  }
];

const archiveHeaderActionsMD = [
  {
    type: "headerAction",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    image: "playMusicIcon",
    action: "playMusic"
  },
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewArchiveMusic"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "removeFromArchiveIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "removeFromArchive"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone"]
  }
];
const SearchHeaderActionsSM = [
  {
    type: "multiActions",
    visible: ({ data }) => data && data.length > 0,
    action: [
      "selectItems",
      "selectNone",
      "selectAll",
      "addMusicToPlaylistLink",
      downloadActionSM,
      "moveToArchive",
      "markDelete"
    ]
  }
];

const SearchHeaderActionsMD = [
  ...headerActionsMD,
  {
    type: "navLogo",
    visible: ({ selectionMode }) => !selectionMode
  }
];

const detailLink = ({ data }) => {
  return {
    uri: `/music-detail`,
    detailId: data._id,
    origin: "music"
  };
};

const archiveDetailLink = ({ data }) => {
  return {
    uri: `/archive-music-detail`,
    detailId: data._id,
    origin: "music"
  };
};

const searchDetailLink = ({ data }) => {
  return {
    uri: `/music-detail`,
    detailId: data._id,
    origin: "music"
  };
};

const commonProps = {
  component: "table",
  dataObject: "Resource",
  ComponentSM: FSList,
  ComponentMD: null,
  componentPropsSM: {
    ...MusicCardSMListProps,
    detailLink
  },
  listProps: {
    list: "scrollIndicatorList",
    detailLink
  },
  notifyNavOnScroll: true,
  showHeaderMD: true
};

const searchCommonProps = {
  ...searchMusicQuery,
  componentPropsSM: {
    ...MusicCardSMListProps,
    detailLink: searchDetailLink
  },
  listProps: {
    list: "scrollIndicatorList",
    detailLink: searchDetailLink
  },
  rowProps: {
    ...SearchMusicCard
  },
  headerRowPropsMD: {
    theme: theme.searchtableHeaderRowStyleMD
  },
  bodyPropsMD: {
    bodyStyle: {
      flex: 1,
      paddingLeft: 81,
      paddingRight: 80
    }
  }
};

export default {
  MusicAll: {
    ...commonProps,
    ...musicAllQuery,
    rowProps: {
      ...MusicCard
    },
    renderNoData: <MusicNoData />,
    renderPromptMessageSM: Platform.OS === "web" ? void 0 : <RenderPromptMessage source="music" />,
    renderPromptMessageMD: false,
    headerActionsSM: [
      "searchMusic",
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        image: "playMusicGreyIcon",
        action: "playMusic"
      },
      {
        type: "multiActions",
        action: [
          "UploadMusicMobileLink",
          "webUpload",
          "selectItems",
          "selectNone",
          "selectAll",
          "addMusicToPlaylistLink",
          downloadActionSM,
          "moveToArchive",
          "markDelete"
        ]
      }
    ],
    headerActionsMD: [
      "searchMusic",
      {
        type: "multiActions",
        visible: ({ selectionMode }) => !selectionMode,
        action: [
          "webUploadWithIcon",
          { ...ResourceActions.downloadZipAll, resourceType: "audio" },
          "selectItemsWithIcon"
        ]
      },
      ...headerActionsMD
    ]
  },
  SearchMusicAll: {
    ...commonProps,
    ...searchCommonProps,
    hideHeaderNoData: true,
    connectProps: {
      ...searchMusicQuery.connectProps,
      skipFetchOnMount: true,
      discardOldResult: true
    },
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...SearchHeaderActionsSM
    ],
    headerActionsMD: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...SearchHeaderActionsMD
    ]
  },
  SearchMusicType: {
    ...commonProps,
    ...searchCommonProps,
    renderNoData: <SearchMusicTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActionsSM: [...SearchHeaderActionsSM],
    headerActionsMD: [...SearchHeaderActionsMD]
  },
  ArchiveMusic: {
    ...commonProps,
    componentPropsSM: {
      ...commonProps.componentPropsSM,
      detailLink: archiveDetailLink
    },
    listProps: {
      ...commonProps.listProps,
      detailLink: archiveDetailLink
    },
    ...archiveMusicQuery,
    rowProps: {
      ...MusicCard
    },
    renderNoData: <ArchiveMusicNoData />,
    headerActionsSM: [
      "searchArchiveMusic",
      {
        type: "multiActions",
        visible: ({ data }) => (Platform.OS === "ios" ? data && data.length !== 0 : true),
        action: [
          "selectMusicsForArchive",
          "selectItems",
          "selectNone",
          "selectAll",
          downloadActionSM,
          "removeFromArchive"
        ]
      }
    ],
    headerActionsMD: [
      {
        ...ResourceActions.searchArchiveMusic,
        containerStyle: { flex: 5, alignItems: "center", overflow: void 0 }
      },
      {
        type: "multiActions",
        visible: ({ selectionMode }) => !selectionMode,
        action: ["selectMusicsForArchiveWeb", "selectItemsWithIcon"]
      },
      ...archiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchArchiveMusicAll: {
    ...commonProps,
    ...searchCommonProps,
    hideHeaderNoData: true,
    connectProps: {
      ...searchMusicQuery.connectProps,
      skipFetchOnMount: true,
      discardOldResult: true
    },
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectItems", "selectNone", "selectAll", downloadActionSM, "removeFromArchive"]
      }
    ],
    headerActionsMD: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...archiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchArchiveMusicType: {
    ...commonProps,
    ...searchCommonProps,
    renderNoData: <SearchMusicTypeNoData />,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    headerActionsSM: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectItems", "selectNone", "selectAll", downloadActionSM, "removeFromArchive"]
      }
    ],
    headerActionsMD: [
      ...archiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  }
};
