import React from "react";
import "./date";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default ({ Box, Image, View, getDisplayText, Text, theme = {} }) => {
  class PrevHandler extends React.Component {
    render() {
      let { icon, onClick, textStyle } = this.props;
      return (
        <View onClick={onClick} style={{ padding: 6, cursor: "pointer" }}>
          {icon ? (
            <Image source={icon} />
          ) : (
            <Text align={"center"} {...textStyle}>
              {"<"}
            </Text>
          )}
        </View>
      );
    }
  }

  class NextHandler extends React.Component {
    render() {
      let { icon, onClick, textStyle } = this.props;
      return (
        <View onClick={onClick} style={{ padding: 6, cursor: "pointer" }}>
          {icon ? (
            <Image source={icon} />
          ) : (
            <Text align={"center"} {...textStyle}>
              {">"}
            </Text>
          )}
        </View>
      );
    }
  }

  class Week extends React.Component {
    render() {
      let { children, primary, ...rest } = this.props;
      let color = primary ? "red" : "#000";
      return (
        <Text
          style={{
            align: "center",
            color: color,
            padding: 3,
            fontSize: 13,
            ...rest
          }}
        >
          {children}
        </Text>
      );
    }
  }

  class DateView extends React.Component {
    onChange = e => {
      e && e.preventDefault && e.preventDefault();
      e && e.stopPropagation && e.stopPropagation();
      let { onChange, value, year, month, date } = this.props;
      value = new Date();
      if (!value) {
        value = new Date(); /// here we have to make changes
      }
      value.setFullYear(year);
      value.setMonth(month, date);
      onChange && onChange(value);
    };
    render() {
      let { children, deActive, selected, onChange, date, month, year, renderDate, ...rest } = this.props;
      if (renderDate) {
        let renderDateProps = { date, year, month, deActive, selected };
        if (React.isValidElement(renderDate)) {
          renderDate = React.cloneElement(renderDate, renderDateProps);
        } else if (typeof renderDate === "function") {
          renderDate = renderDate(renderDateProps);
        }
      }
      let color = deActive ? "#d7d3d3" : selected ? "red" : "#000";
      return (
        <View onClick={this.onChange} style={{ cursor: deActive ? void 0 : "pointer", padding: 3 }}>
          {renderDate || (
            <Text
              style={{
                alignItems: "center",
                textAlign: "center",
                height: 15,
                width: 18,
                whiteSpace: "pre-wrap",
                color: color
              }}
              {...rest}
            >
              {children}
            </Text>
          )}
        </View>
      );
    }
  }

  class SelectDate extends React.Component {
    constructor(props) {
      super(props);
      let currentDate = props.value ? props.value : new Date();
      currentDate = typeof currentDate === "string" ? new Date(currentDate) : currentDate;
      this.state = this.getState(currentDate);
    }
    getState(currentDate) {
      return {
        date: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear(),
        visibleView: "dateView",
        yearViewBase: currentDate.getFullYear()
      };
    }
    UNSAFE_componentWillReceiveProps({ value }) {
      if (value && JSON.stringify(value) !== JSON.stringify(this.oldDate)) {
        let currentDate = value ? new Date(value) : new Date();
        this.setState(this.getState(currentDate));
      }
      this.oldDate = new Date(this.props.value);
    }
    getDates({ date, month, year }) {
      const { onChange, value, renderDate } = this.props;
      let dates = [];
      let currMonLstDtObj = new Date(year, month + 1, 0);
      let currMonLstDt = currMonLstDtObj.getDate();
      let currMonLstDay = currMonLstDtObj.getDay();
      let prevMonlstDtObj = new Date(year, month, 0);
      let prevMonLstDt = prevMonlstDtObj.getDate();
      let prevMonLstDay = prevMonlstDtObj.getDay();
      let perRowDate = 0;
      let dateToRender = [];
      while (prevMonLstDay >= 0 && prevMonLstDay < 6) {
        dates.unshift({
          render: (
            <DateView deActive key={"prev" + currMonLstDt} date={prevMonLstDt} renderDate={renderDate}>
              {prevMonLstDt}
            </DateView>
          )
        });
        perRowDate++;
        if (perRowDate === 7) {
          perRowDate = 0;
          dateToRender.unshift([
            {
              direction: "row",

              viewStyle: { justifyContent: "space-around", fontSize: 13 },
              render: dates
            }
          ]);
          dates = [];
        }
        prevMonLstDt--;
        prevMonLstDay--;
      }
      let currMonFstDt = 1;
      while (currMonFstDt <= currMonLstDt) {
        dates.push({
          render: (
            <DateView
              key={currMonFstDt}
              selected={date === currMonFstDt}
              date={currMonFstDt}
              month={month}
              year={year}
              value={value}
              onChange={onChange}
              renderDate={renderDate}
            >
              {currMonFstDt}
            </DateView>
          )
        });
        perRowDate++;
        if (perRowDate === 7) {
          perRowDate = 0;
          dateToRender.push([
            {
              direction: "row",
              viewStyle: {
                justifyContent: "space-around",
                fontSize: 13
              },
              render: dates
            }
          ]);
          dates = [];
        }
        currMonFstDt++;
      }
      currMonLstDay = 7 - currMonLstDay;
      let i = 1;
      while (i < currMonLstDay) {
        dates.push({
          render: (
            <DateView deActive key={"next" + i} date={i} renderDate={renderDate}>
              {i}
            </DateView>
          )
        });
        perRowDate++;

        if (perRowDate === 7) {
          perRowDate = 0;
          dateToRender.push([
            {
              direction: "row",
              viewStyle: { justifyContent: "space-around", fontSize: 13 },
              render: dates
            }
          ]);
          dates = [];
        }
        i++;
      }
      return dateToRender;
    }

    onDateChange = params => {
      // if (this.props.onDateChange) {
      // let { date, month, year } = params || {};
      // let value = new Date();
      // value.setFullYear(year);
      // value.setMonth(month, date);
      // this.props.onDateChange(value);
      // }
    };

    nextMonth = () => {
      let { date, month, year } = this.state;
      if (month === 11) {
        month = 0;
        year++;
      } else {
        month++;
      }
      this.onDateChange({ date, month, year });
      this.setState({ month, year });
    };
    prevMonth = () => {
      let { date, month, year } = this.state;
      if (month === 0) {
        month = 11;
        year--;
      } else {
        month--;
      }
      this.onDateChange({ date, month, year });
      this.setState({ month, year });
    };

    onMonthClick = month => {
      let { date, year } = this.state;
      this.onDateChange({ date, month, year });
      this.setState({ month, visibleView: "dateView" });
    };

    onYearClick = year => {
      let { date, month } = this.state;
      this.onDateChange({ date, month, year });
      this.setState({ year, visibleView: "dateView" });
    };

    getYearsArray = () => {
      let { yearViewStyle } = theme;

      let { boxStyle: yearBoxStyle, textStyle: yearTextStyle } = yearViewStyle;

      let yearBase = this.state.yearViewBase;
      let yearArray = [];
      let yearArrayToRender = [];
      for (let i = 0; i < 3; i++) {
        yearArray[i] = [];

        for (let j = 0; j < 4; j++) {
          yearArray[i][j] = yearBase + i * 3 + j;
          yearArrayToRender.push([
            {
              viewStyle: {
                ...yearBoxStyle,
                cursor: "pointer"
              },
              textStyle: yearTextStyle,
              text: yearArray[i][j],
              onClick: () => {
                this.onYearClick(yearArray[i][j]);
              }
            }
          ]);
        }
      }

      return yearArrayToRender;
    };

    getMonthSelectRow = (from, to) => {
      let { monthViewStyle = {} } = theme;
      let monthRender = [];
      for (let i = from; i <= to; i++) {
        monthRender.push({
          viewStyle: {
            ...monthViewStyle.viewStyle,
            cursor: "pointer"
          },
          textStyle: monthViewStyle.textStyle,
          text: (getDisplayText && getDisplayText("month", i)) || months[i],
          onClick: () => {
            this.onMonthClick(i);
          }
        });
      }
      return {
        direction: "row",
        viewStyle: { justifyContent: "space-around" },
        render: monthRender
      };
    };

    render() {
      const { date, month, year, visibleView } = this.state;
      let { weekNames, monthNames, prevIcon, nextIcon } = this.props;
      let { monthViewStyle, yearViewStyle } = theme;
      let { boxStyle: monthBoxStyle, textStyle: monthTextStyle } = monthViewStyle;
      let { boxStyle: yearBoxStyle, textStyle: yearTextStyle } = yearViewStyle;
      weekNames = weekNames || ["S", "M", "T", "W", "T", "F", "S"];
      monthNames = monthNames || [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      if (visibleView === "dateView") {
        let render = [
          {
            direction: "row",
            viewStyle: {
              justifyContent: "space-between",
              alignItems: "center"
            },
            render: [
              <PrevHandler onClick={this.prevMonth} icon={prevIcon} />,
              {
                viewStyle: { cursor: "pointer", padding: 6 },
                text: year,
                onClick: () => {
                  this.setState({ visibleView: "yearView" });
                }
              },
              {
                viewStyle: { cursor: "pointer", padding: 6 },
                text: (getDisplayText && getDisplayText("month", month)) || monthNames[month],
                onClick: () => {
                  this.setState({ visibleView: "monthView" });
                }
              },
              <NextHandler onClick={this.nextMonth} icon={nextIcon} />
            ]
          },
          {
            direction: "row",
            viewStyle: {
              justifyContent: "space-around",
              alignItems: "center",
              paddingTop: 6,
              paddingBottom: 6
            },

            render: [
              <Week primary>{(getDisplayText && getDisplayText("week", 0)) || weekNames[0]}</Week>,
              <Week>{(getDisplayText && getDisplayText("week", 1)) || weekNames[1]}</Week>,
              <Week>{(getDisplayText && getDisplayText("week", 2)) || weekNames[2]}</Week>,
              <Week>{(getDisplayText && getDisplayText("week", 3)) || weekNames[3]}</Week>,
              <Week>{(getDisplayText && getDisplayText("week", 4)) || weekNames[4]}</Week>,
              <Week>{(getDisplayText && getDisplayText("week", 5)) || weekNames[5]}</Week>,
              <Week>{(getDisplayText && getDisplayText("week", 6)) || weekNames[6]}</Week>
            ]
          },
          {
            viewStyle: {
              justifyContent: "center"
            },
            render: [this.getDates({ date, month, year })]
          }
        ];

        return <Box viewStyle={{ padding: 10, fontSize: 13 }} render={render} />;
      } else if (visibleView === "monthView") {
        let render = [
          {
            direction: "row",
            viewStyle: {
              justifyContent: "space-between",
              padding: "3px 20px 3px 20px",
              alignItems: "center"
            },
            render: [
              <PrevHandler
                icon={prevIcon}
                textStyle={monthTextStyle}
                onClick={() => this.setState({ year: year - 1, yearViewBase: year - 1 })}
              />,
              {
                viewStyle: { cursor: "pointer", padding: 6 },
                textStyle: monthTextStyle,
                text: year,
                onClick: () => {
                  this.setState({ visibleView: "yearView" });
                }
              },
              <NextHandler
                icon={nextIcon}
                textStyle={monthTextStyle}
                onClick={() => this.setState({ year: year + 1, yearViewBase: year + 1 })}
              />
            ]
          },
          this.getMonthSelectRow(0, 2),
          this.getMonthSelectRow(3, 5),
          this.getMonthSelectRow(6, 8),
          this.getMonthSelectRow(9, 11)
        ];
        return <Box viewStyle={{ ...monthBoxStyle, fontSize: 13 }} render={render} />;
      } else {
        let yearArray = this.getYearsArray();
        let yearArrayItems = [];
        let yearArrayPerRow = [];
        yearArray.forEach(year => {
          yearArrayItems.push(year);
          if (yearArrayItems.length === 3) {
            yearArrayPerRow.push([
              {
                viewStyle: { justifyContent: "space-around" },
                direction: "row",
                render: yearArrayItems
              }
            ]);
            yearArrayItems = [];
          }
        });

        let render = [
          {
            direction: "row",
            viewStyle: {
              justifyContent: "space-around",
              alignItems: "center",
              ...yearBoxStyle
            },
            render: [
              <PrevHandler
                icon={prevIcon}
                textStyle={yearTextStyle}
                onClick={() => this.setState({ yearViewBase: this.state.yearViewBase - 9 })}
              />,
              {
                textStyle: yearTextStyle,
                text: "Select Year"
              },
              <NextHandler
                icon={nextIcon}
                textStyle={yearTextStyle}
                onClick={() => this.setState({ yearViewBase: this.state.yearViewBase + 9 })}
              />
            ]
          },
          yearArrayPerRow
        ];
        return <Box viewStyle={{ ...monthBoxStyle, fontSize: 13 }} render={render} />;
      }
    }
  }
  return SelectDate;
};
