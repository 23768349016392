import { firebaseFind, MetadataTable, getUser, loadPicOfDay as loadPicOfDay2 } from "../../../FsCloudComponent";
import { SECURE_TYPES, HIGHLIGHTS_ALL_SORT } from "../../../Constants";

export const fetchData = async ({ to, from }) => {
  try {
    const user = getUser();
    if (!user) {
      return [];
    }
    const data = await firebaseFind({
      view: {
        table: MetadataTable,
        filter: {
          deleted: false,
          type: "image",
          "_createdBy.firebaseUid": user.uid,
          completed: true,
          defaultThumbnail: false,
          secureType: SECURE_TYPES.DEFAULT,
          $and: [{ resource_lastModified: { $gte: from } }, { resource_lastModified: { $lte: to } }]
        },
        sort: HIGHLIGHTS_ALL_SORT,
        limit: 10
      }
    });
    return (data && data.result) || [];
  } catch (err) {
    console.warn(`[WebStory.js] [fetchData] Failed. to:${to} from:${from} Error: `, err);
    return [];
  }
};
export const fetchWeekData = async () => {
  let to = Date.now();
  let from = to - 21 * 24 * 60 * 60 * 1000;
  return { to, from, data: await fetchData({ to, from }) };
};

export const fetchMonthData = async () => {
  let fetchDate = new Date().setMonth(new Date().getMonth() - 1);
  var curr = new Date(fetchDate); // get current date
  var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6
  var from = new Date(curr.setDate(first)).getTime(); // 06-Jul-2014
  var to = new Date(curr.setDate(last)).getTime(); //12-Jul-2014
  return { to, from, data: await fetchData({ to, from }) };
};

export const fetchYearData = async () => {
  let fetchDate = new Date().setFullYear(new Date().getFullYear() - 1);
  var curr = new Date(fetchDate);
  var first = curr.getDate() - curr.getDay();
  var last = first + 6;
  var from = new Date(curr.setDate(first)).getTime();
  var to = new Date(curr.setDate(last)).getTime();
  return { to, from, data: await fetchData({ to, from }) };
};

export const fetchMoreData = async () => {
  let fetchDate = new Date().setFullYear(new Date().getFullYear() - 2);
  var curr = new Date(fetchDate);
  let first = curr.getDate() - curr.getDay();
  var last = first + 6;
  var from = new Date(curr.setDate(first)).getTime();
  var to = new Date(curr.setDate(last)).getTime();
  return { to, from, data: await fetchData({ to, from }) };
};

let timeout;
const fetchPicOfDayData = async (resolve, reject) => {
  try {
    let data = await loadPicOfDay2();
    if (data) {
      data = (data && data[0]) || {};
      data.url = data.converted_jpeg_url || data.thumbnail_url;
      data.picDay = true;
      if (!data.url || data.discarded) {
        data = []; // picOfDay is discard
      } else {
        data = [data];
      }
      resolve({ data });
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        timeout = void 0;
        fetchPicOfDayData(resolve, reject);
      }, 1000);
    }
  } catch (err) {
    reject(err);
  }
};

export const loadPicOfDay = () => new Promise(fetchPicOfDayData);
