/*
* created by japesh on 1 june 2017, for managing handleKey event of TargetModal and fire last added 
* listener on pressing esc key by default.
* will manage only esc key, if any other key pressed, it has no effect
* use case : two autoHide modal added (manaze - > modalView and autoSuggest)(esc enabled)-> now esc key pressed - both get hidden, we required only last to get hide.
*/
const listeners = {};

const eventListener = event => {
  const type = event.type;
  const typeListeners = listeners[type];
  const lastListener = typeListeners && typeListeners[typeListeners.length - 1];

  lastListener && lastListener(event);
};

export const addListener = ({ type, listener }) => {
  listeners[type] = listeners[type] || [];

  if (!listeners[type].length) {
    //add Listener for the first time
    document.body.addEventListener(type, eventListener);
  }
  listeners[type].push(listener);
};

export const removeListener = ({ type, listener }) => {
  const typeListeners = listeners[type];
  if (typeListeners) {
    const index = typeListeners.indexOf(listener);
    if (index >= 0) {
      typeListeners.splice(index, 1);
    }
    if (!listeners[type].length) {
      //remove registered event listener
      document.body.removeEventListener(type, eventListener);
    }
  }
};
