import React from "react";
import { Connect } from "../../../../FsCloudComponent";
import AddContactForm from "./AddContactForm";

class AddContact extends React.Component {
  render() {
    let { data = {}, action: { form } = {}, onClose } = this.props;
    let { connectProps = {} } = form;
    let isNew = connectProps && connectProps.dataMode === "insert";
    return (
      <Connect {...connectProps} data={isNew ? {} : data} updatable>
        {({ data, setValue, updatableData, validationData }) => {
          return (
            <AddContactForm
              isNew={isNew}
              data={data}
              setValue={setValue}
              updatableData={updatableData}
              validationData={validationData}
              showHeader
              buttonText="save"
              onClose={onClose}
            />
          );
        }}
      </Connect>
    );
  }
}

export default AddContact;
