import React from "react";
import { DocsCard, GroupDocsCard, SearchDocsCard, DocsCardSMListProps, GroupDocsCardSMListProps } from "./DocsCards";
import {
  GroupDocsNoDataSM,
  GroupDocsNoDataMD,
  DocsNoData,
  VaultDocsNoData,
  SearchDocsTypeNoData,
  ArchiveDocsNoData
} from "../noData/NoData";
import { docsAllQuery, groupDocsQuery, searchDocsQuery, vaultDocsQuery, archiveDocsQuery } from "../queries";

import {
  I18N,
  theme,
  SearchType,
  FSList,
  Platform,
  setVaultMode,
  RenderPromptMessage
} from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import {
  isRemoveFromGroupVisible,
  isImportFromGroupVisible,
  makePrivateActionVisibility,
  uploadResourceActionVisibility,
  getSelectedData,
  isDownloadActionVisible
} from "../resource/ResourceUtility";

const closeVault = {
  type: "close",
  onClose: () => {
    setVaultMode && setVaultMode();
  }
};

const downloadActionSM = {
  ...ResourceActions.download,
  visible: isDownloadActionVisible
};

const headerActionsSM = [
  {
    type: "headerAction",
    image: "shareGreyIcon",
    title: () => I18N.t("toShareTitle"),
    visible: props => {
      let selectedData = getSelectedData(props);
      return uploadResourceActionVisibility({ selectedData });
    },
    action: ["postToGroup", "webShareDoc", "nativeShareDoc", "getLinkDoc", "getLinkDocNative"]
  }
];

const headerActionsMD = [
  {
    type: "headerAction",
    image: "shareIcon",
    title: () => I18N.t("toShareTitle"),
    visible: props => {
      let selectedData = getSelectedData(props);
      return uploadResourceActionVisibility({ selectedData });
    },
    action: "webShareDoc"
  },
  {
    type: "headerAction",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    image: "addToCollectionIcon",
    action: "addDocsToSetForm"
  },
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewDoc"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "editIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "rename"
  },
  {
    type: "headerAction",
    image: "makePrivateIcon",
    title: () => I18N.t("makePrivate"),
    visible: props => makePrivateActionVisibility(props),
    action: "makePrivate"
  },
  {
    type: "headerAction",
    image: "moveToVaultIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "moveToVault"
  },
  {
    type: "headerAction",
    image: "moveToArchiveIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "moveToArchive"
  },
  {
    type: "headerAction",
    image: "deleteIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "markDelete"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone", "downloadZip"]
  }
];

const GroupHeaderActionsSM = [
  {
    type: "headerAction",
    image: "pinRemoveGreyIcon",
    visible: isRemoveFromGroupVisible,
    action: "removeItemFromGroup"
  },
  {
    type: "headerAction",
    image: "importGreyIcon",
    visible: isImportFromGroupVisible,
    action: "importItemFromGroup"
  }
];

const GroupHeaderActionsMD = [
  {
    type: "headerAction",
    image: "importIcon",
    visible: isImportFromGroupVisible,
    action: "importItemFromGroup"
  },
  {
    type: "headerAction",
    image: "pinRemoveIcon",
    visible: isRemoveFromGroupVisible,
    action: "removeItemFromGroup"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone"]
  }
];

const SearchHeaderActionsSM = [
  ...headerActionsSM,
  {
    type: "multiActions",
    visible: ({ data }) => data && data.length,
    action: [
      "selectItems",
      "selectNone",
      "selectAll",
      "addDocsToSetLink",
      downloadActionSM,
      "rename",
      "moveToVault",
      "moveToArchive",
      "markDelete"
    ]
  }
];

const SearchHeaderActionsMD = [
  ...headerActionsMD,
  {
    type: "navLogo",
    visible: ({ selectionMode }) => !selectionMode
  }
];

const VaultHeaderActionsMD = [
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewVaultDoc"
  },
  {
    type: "headerAction",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    image: "addToCollectionIcon",
    action: "addDocsToVaultSetForm"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "editIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "rename"
  },
  {
    type: "headerAction",
    image: "removeFromVaultIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "removeFromVault"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone"]
  }
];

const ArchiveHeaderActionsMD = [
  {
    type: "headerAction",
    image: "detailViewIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "toViewArchiveDoc"
  },
  {
    type: "headerAction",
    image: "downloadIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "download"
  },
  {
    type: "headerAction",
    image: "editIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length === 1,
    action: "rename"
  },
  {
    type: "headerAction",
    image: "removeFromArchiveIcon",
    visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
    action: "removeFromArchive"
  },
  {
    type: "headerAction",
    image: "iconMenu",
    visible: ({ data, selectionMode }) => selectionMode && data && data.length,
    action: ["selectAll", "selectNone"]
  }
];

const detailLink = ({ data }) => {
  return {
    uri: `/doc-detail`,
    detailId: data._id,
    origin: "doc"
  };
};

const groupDetailLink = ({ data }) => {
  return {
    uri: `/group-doc-detail`,
    decryptionSourceProps: { decryptionSource: "group" },
    detailId: data._id,
    origin: "doc",
    fromGroup: true
  };
};
const vaultDetailLink = ({ data }) => {
  return {
    uri: `/vault-doc-detail`,
    detailId: data._id,
    origin: "vaultDoc"
  };
};

const archiveDetailLink = ({ data }) => {
  return {
    uri: `/archive-doc-detail`,
    detailId: data._id,
    origin: "doc"
  };
};

const searchDetailLink = ({ data }) => {
  return {
    uri: `/search-doc-detail`,
    detailId: data._id,
    origin: "doc"
  };
};

const searchGroupDetailLink = ({ data }) => {
  return {
    uri: `/search-doc-detail`,
    decryptionSourceProps: { decryptionSource: "group" },
    detailId: data._id,
    origin: "doc",
    fromGroup: true
  };
};

const docCommonProps = {
  component: "table",
  dataObject: "Resource",
  ComponentSM: FSList,
  ComponentMD: null,
  componentPropsSM: {
    ...DocsCardSMListProps,
    detailLink
  },
  listProps: {
    list: "scrollIndicatorList",
    detailLink
  },
  notifyNavOnScroll: true,
  showHeaderMD: true
};

const searchCommonProps = {
  componentPropsSM: {
    ...DocsCardSMListProps,
    detailLink: searchDetailLink
  },
  listProps: {
    list: "scrollIndicatorList",
    detailLink: searchDetailLink
  },
  rowProps: {
    ...SearchDocsCard
  },
  headerRowPropsMD: {
    theme: theme.searchtableHeaderRowStyleMD
  },
  bodyPropsMD: {
    bodyStyle: {
      flex: 1,
      paddingLeft: 81,
      paddingRight: 80
    }
  }
};

export default {
  DocsAll: {
    ...docCommonProps,
    ...docsAllQuery,
    rowProps: {
      ...DocsCard
    },
    renderNoData: <DocsNoData />,
    renderPromptMessageSM: Platform.OS === "web" ? void 0 : <RenderPromptMessage source="doc" />,
    renderPromptMessageMD: false,
    headerActionsSM: [
      "searchDocs",
      ...headerActionsSM,
      {
        type: "multiActions",
        visible: ({ data }) => (Platform.OS === "ios" ? data && data.length !== 0 : true),
        action: [
          "uploadDocMobileLink",
          "webUpload",
          "selectItems",
          "selectNone",
          "selectAll",
          "addDocsToSetLink",
          downloadActionSM,
          "rename",
          "makePrivate",
          "moveToVault",
          "moveToArchive",
          "markDelete"
        ]
      }
    ],
    headerActionsMD: [
      "searchDocs",
      {
        type: "multiActions",
        visible: ({ selectionMode }) => !selectionMode,
        action: ["webUploadWithIcon", { ...ResourceActions.downloadZipAll, resourceType: "doc" }, "selectItemsWithIcon"]
      },
      ...headerActionsMD
    ]
  },
  GroupDocs: {
    ...docCommonProps,
    componentPropsSM: {
      ...GroupDocsCardSMListProps,
      detailLink: groupDetailLink
    },
    listProps: {
      list: "scrollIndicatorList",
      detailLink: groupDetailLink
    },
    ...groupDocsQuery,
    rowProps: {
      ...GroupDocsCard
    },
    renderNoDataSM: <GroupDocsNoDataSM />,
    renderNoDataMD: <GroupDocsNoDataMD />,
    headerActionsSM: [
      "searchGroupDocs",
      {
        type: "headerAction",
        image: "groupPinGreyIcon",
        visible: ({ selectionMode }) => !selectionMode,
        action: "selectDocsForGroup"
      },
      ...GroupHeaderActionsSM,
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => !selectionMode && data && data.length,
        action: ["selectItems", "selectAll", "selectNone"]
      }
    ],
    headerActionsMD: [
      "searchGroupDocs",
      {
        type: "multiActions",
        image: "groupPinIcon",
        title: () => I18N.t("post"),
        visible: ({ data, selectionMode }) => !selectionMode && data && data.length,
        action: "selectDocsForGroupWeb"
      },
      ...GroupHeaderActionsMD,
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => !selectionMode && data && data.length,
        action: ["selectItemsWithIcon"]
      }
    ]
  },
  SearchDocsAll: {
    ...docCommonProps,
    ...searchCommonProps,
    ...searchDocsQuery,
    connectProps: {
      ...searchDocsQuery.connectProps,
      skipFetchOnMount: true,
      discardOldResult: true
    },
    hideHeaderNoData: true,
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...SearchHeaderActionsSM
    ],
    headerActionsMD: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...SearchHeaderActionsMD
    ]
  },
  SearchDocsType: {
    ...docCommonProps,
    ...searchCommonProps,
    ...searchDocsQuery,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    renderNoData: <SearchDocsTypeNoData />,
    headerActionsSM: [...SearchHeaderActionsSM],
    headerActionsMD: [...SearchHeaderActionsMD]
  },
  SearchGroupDocs: {
    ...docCommonProps,
    ...searchCommonProps,
    componentPropsSM: {
      ...GroupDocsCardSMListProps,
      detailLink: searchGroupDetailLink
    },
    listProps: {
      list: "scrollIndicatorList",
      detailLink: searchGroupDetailLink
    },
    rowProps: {
      ...GroupDocsCard
    },
    ...searchDocsQuery,
    connectProps: {
      ...searchDocsQuery.connectProps,
      skipFetchOnMount: true,
      discardOldResult: true
    },
    defaultConnectState: groupDocsQuery.defaultConnectState, // required to show sort on postedOn in list
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...GroupHeaderActionsSM
    ],
    headerActionsMD: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...GroupHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  VaultDocs: {
    ...docCommonProps,
    componentPropsSM: {
      ...docCommonProps.componentPropsSM,
      detailLink: vaultDetailLink
    },
    listProps: {
      ...docCommonProps.listProps,
      detailLink: vaultDetailLink
    },
    ...vaultDocsQuery,
    rowProps: {
      ...DocsCard
    },
    renderNoData: <VaultDocsNoData />,
    headerActionsSM: [
      closeVault,
      "searchVaultDocs",
      {
        type: "multiActions",
        visible: ({ data }) => (Platform.OS === "ios" ? data && data.length !== 0 : true),
        action: [
          "uploadVaultDocMobileLink",
          "selectDocsForSafe",
          "webUpload",
          "selectItems",
          "selectNone",
          "selectAll",
          "addDocsToVaultSetLink",
          downloadActionSM,
          "rename",
          "removeFromVault"
        ]
      }
    ],
    headerActionsMD: [
      closeVault,
      {
        ...ResourceActions.searchVaultDocs,
        containerStyle: { flex: 5, alignItems: "center", overflow: void 0 }
      },
      {
        type: "multiActions",
        visible: ({ selectionMode }) => !selectionMode,
        action: ["webUploadWithIcon", "selectDocsForSafeWeb", "selectItemsWithIcon"]
      },
      ...VaultHeaderActionsMD,
      {
        type: "webAppUpload",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchVaultDocs: {
    ...docCommonProps,
    ...searchCommonProps,
    ...searchDocsQuery,
    connectProps: {
      ...searchDocsQuery.connectProps,
      skipFetchOnMount: true,
      discardOldResult: true
    },
    hideHeaderNoData: true,
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectItems", "selectNone", "selectAll", downloadActionSM, "rename", "removeFromVault"]
      }
    ],
    headerActionsMD: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...VaultHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchVaultDocsType: {
    ...docCommonProps,
    ...searchCommonProps,
    ...searchDocsQuery,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    renderNoData: <SearchDocsTypeNoData />,
    headerActionsSM: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectItems", "selectNone", "selectAll", downloadActionSM, "rename", "removeFromVault"]
      }
    ],
    headerActionsMD: [
      ...VaultHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  ArchiveDocs: {
    ...docCommonProps,
    componentPropsSM: {
      ...docCommonProps.componentPropsSM,
      detailLink: archiveDetailLink
    },
    listProps: {
      ...docCommonProps.listProps,
      detailLink: archiveDetailLink
    },
    ...archiveDocsQuery,
    rowProps: {
      ...DocsCard
    },
    renderNoData: <ArchiveDocsNoData />,
    headerActionsSM: [
      "searchArchiveDocs",
      {
        type: "multiActions",
        visible: ({ data }) => (Platform.OS === "ios" ? data && data.length !== 0 : true),
        action: [
          "selectDocsForArchive",
          "selectItems",
          "selectNone",
          "selectAll",
          downloadActionSM,
          "rename",
          "removeFromArchive"
        ]
      }
    ],
    headerActionsMD: [
      {
        ...ResourceActions.searchArchiveDocs,
        containerStyle: { flex: 5, alignItems: "center", overflow: void 0 }
      },
      {
        type: "multiActions",
        visible: ({ selectionMode }) => !selectionMode,
        action: ["selectDocsForArchiveWeb", "selectItemsWithIcon"]
      },
      ...ArchiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchArchiveDocs: {
    ...docCommonProps,
    ...searchCommonProps,
    ...searchDocsQuery,
    connectProps: {
      ...searchDocsQuery.connectProps,
      skipFetchOnMount: true,
      discardOldResult: true
    },
    hideHeaderNoData: true,
    renderNoData: <SearchType />,
    headerActionsSM: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectItems", "selectNone", "selectAll", downloadActionSM, "rename", "removeFromArchive"]
      }
    ],
    headerActionsMD: [
      {
        type: "searchInput",
        visible: ({ selectionMode }) => !selectionMode
      },
      ...ArchiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  },
  SearchArchiveDocsType: {
    ...docCommonProps,
    ...searchCommonProps,
    ...searchDocsQuery,
    title: ({ link }) => {
      let { fromTitle } = link.props.params;
      return fromTitle;
    },
    renderNoData: <SearchDocsTypeNoData />,
    headerActionsSM: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length,
        action: ["selectItems", "selectNone", "selectAll", downloadActionSM, "rename", "removeFromArchive"]
      }
    ],
    headerActionsMD: [
      ...ArchiveHeaderActionsMD,
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ]
  }
};
