export default ({ images, colors, fonts, shadows, I18N }) => {
  const { h2 } = fonts;

  const { highlightColor, secondaryColor } = colors;

  const { crossIcon } = images;

  const fileAndImageRenderStyle = {
    boxStyle: { viewStyle: { flex: 1, justifyContent: "center" } },
    messageStyle: {
      viewStyle: {
        alignItems: "center"
      },
      text: () => I18N.t("noFilePreviewAvailableText"),
      textStyle: {
        font: h2,
        color: highlightColor,
        textAlign: "center"
      }
    },
    openLinkStyle: {
      text: () => I18N.t("clickHereToOpen"),
      viewStyle: { cursor: "pointer" },
      textStyle: { textDecoration: "underline", color: highlightColor, textAlign: "center" }
    },
    imageRenderStyle: {
      viewStyle: { flex: 1, alignItems: "center", justifyContent: "center" },
      imageStyle: { maxWidth: "100%", maxHeight: "100%" }
    }
  };

  const imageRenderBoxStyleForFileRender = {
    boxStyle: {
      viewStyle: {
        padding: 12
      },
      direction: "row"
    },
    imageNameStyle: {
      viewStyle: {
        width: 150,
        paddingTop: 6
      },
      textStyle: {
        fontSize: 14,
        numberOfLines: 2
      }
    },
    imageRenderStyle: {
      boxStyle: { viewStyle: { flex: 1, justifyContent: "center" } },
      messageStyle: {
        viewStyle: {
          height: 150,
          width: 150,
          alignItems: "center",
          justifyContent: "center"
        },
        text: () => I18N.t("noFilePreviewAvailableText"),
        textStyle: {
          font: h2,
          color: secondaryColor,
          textAlign: "center"
        }
      },
      imageRenderStyle: {
        viewStyle: { height: 150, width: 150, alignItems: "center", justifyContent: "center" },
        imageStyle: { maxWidth: "100%", maxHeight: "100%" }
      }
    },
    crossStyle: {
      viewStyle: {
        ...shadows.shadow2,
        backgroundColor: highlightColor,
        position: "absolute",
        left: 142,
        top: 12,
        width: 20,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      },
      image: crossIcon
    }
  };

  return {
    fileAndImageRenderStyle,
    imageRenderBoxStyleForFileRender
  };
};
