import React from "react";

export default ({ Box, ImageRender, isImage, theme: themeHoc }) => {
  class FileAndImageRender extends React.Component {
    onOpen = () => {
      const { value = {}, getImageUrl } = this.props;
      if (value && value.key) {
        window.open(getImageUrl(value));
      }
    };

    render() {
      const { value = {}, theme, hideOpen, ...rest } = this.props;
      const { boxStyle, messageStyle, openLinkStyle, imageRenderStyle } = theme || themeHoc || {};
      if (isImage(value && value.name)) {
        return <ImageRender value={value} theme={imageRenderStyle} {...rest} />;
      } else {
        return (
          <Box
            {...boxStyle}
            render={[
              { ...messageStyle },
              !hideOpen && {
                ...openLinkStyle,
                onClick: this.onOpen
              }
            ]}
          />
        );
      }
    }
  }
  return FileAndImageRender;
};
