import React from "react";

export default ({ Box, Input, resolveMQ, theme: themeHoc }) => {
  class FileInputBox extends React.Component {
    render() {
      let { activeMQ, hideInput, inputProps, getRef, multiple, onChangeValue, theme, fieldDef } = this.props;
      theme = theme || themeHoc || {};
      let { boxStyle, uploadImageStyle, uploadTextStyle } = resolveMQ(
        theme,
        ["boxStyle", "uploadImageStyle", "uploadTextStyle"],
        activeMQ
      );
      if (fieldDef && fieldDef.placeholder) {
        uploadTextStyle = {
          ...uploadTextStyle,
          text: fieldDef.placeholder
        };
      }
      return (
        <Box
          {...boxStyle}
          render={[
            uploadImageStyle,
            uploadTextStyle,
            !hideInput && {
              width: 0,
              render: <Input {...inputProps} getRef={getRef} multiple={multiple} onChangeValue={onChangeValue} />
            }
          ]}
        />
      );
    }
  }
  return FileInputBox;
};
