import UploadGallery from "./UploadGallery";
import UploadDocument from "./UploadDocument";
import UploadMusic from "./UploadMusic";
import GalleryFolderAssets from "./GalleryFolderAssets";
import UploadQueue from "./UploadQueue";
import DownloadQueue from "./DownloadQueue";

export default {
  ...UploadGallery,
  ...UploadDocument,
  ...UploadMusic,
  ...GalleryFolderAssets,
  ...UploadQueue,
  ...DownloadQueue
};
