import React from "react";
import { Box, theme, withContext, resolveMQ, I18N, DecryptedImage } from "../../../FsCloudComponent";
let { colors, fonts } = theme;
let { themeColor, highlightColor, transparent } = colors;
const { h16_16 } = fonts;
const fileViewerStyle = {
  buttonStyleSM: {
    viewStyle: {
      backgroundColor: themeColor,
      color: transparent,
      borderWidth: 1,
      borderRadius: 4,
      height: 46,
      marginBottom: 12,
      marginLeft: 12,
      marginRight: 12,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    },
    textStyle: {
      ...h16_16,
      color: highlightColor
    },
    text: () => I18N.t("open")
  },
  buttonStyleMD: {
    viewStyle: {
      backgroundColor: themeColor,
      borderWidth: 1,
      borderRadius: 4,
      height: 46,
      marginTop: 12,
      marginBottom: 12,
      marginLeft: 30,
      marginRight: 30,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    },
    textStyle: {
      ...h16_16,
      color: highlightColor
    },
    text: () => I18N.t("open")
  }
};
class FileViewerComponent extends React.Component {
  render() {
    let { url, activeMQ, data } = this.props;
    if (!url) {
      return null;
    }
    let { buttonStyle } = resolveMQ(fileViewerStyle, ["buttonStyle"], activeMQ);
    let component = (
      <a
        rel="noopener noreferrer"
        target={"_blank"}
        href={url}
        style={{ textDecoration: "none" }}
        onClick={e => e && e.stopPropagation && e.stopPropagation()}
      >
        <Box {...buttonStyle} />
      </a>
    );
    if (data.encrypted) {
      return (
        <DecryptedImage
          data={data}
          source={url}
          decryptionSourceProps={this.props.decryptionSourceProps}
          renderComponent={({ source }) => {
            return React.cloneElement(component, {
              href: source
              // download: data.resource_name,
            });
          }}
        />
      );
    } else {
      return component;
    }
  }
}

FileViewerComponent = withContext(FileViewerComponent, {
  activeMQ: "ActiveMQ"
});

export default FileViewerComponent;
