/**
 * rowProps is passed as function to pass data for user to create columns at per data - use case revenue analysis -
 * Rohit Garg, Aman Jindal - 8 March, 2019
 *
 * renderHeader is type of function is used to show custom list header on top of list - Rohit Garg - 30 March, 2019
 *
 * primaryActionProps is passed from layout to pass prop to primaryRowAction - use case - parimary action should be working on right click - fsCloud Project - Rohit Garg - 4 May,  2019
 *
 * */
import React from "react";

export default ({ resolveMQ, theme, resolveColumn, separateNumber, getComponent, sortHeader }) => {
  const getRenderColumns = ({
    primaryAction,
    primaryActionProps,
    selectable,
    columns,
    fields,
    activeMQ,
    minWidthFr,
    typeStyle,
    columnTheme,
    selectedIcon
  }) => {
    //if active then we need to hide some columns to accomodate actions
    let renderColumns = [];
    if (selectable) {
      let { width } = (typeStyle && typeStyle["Selection"]) || {};
      let selectionColumn = {
        value: {
          type: "Selection",
          componentProps: {
            selectedIcon
          }
        },
        width
      };
      renderColumns.push(selectionColumn);
    }
    let frColumns = [];
    columns.forEach((column, index) => {
      let columnToCheck = column;
      if (typeof column === "object") {
        columnToCheck = column.field;
      }
      let { width, defaultTextStyle, align, ...value } = resolveColumn({
        primaryAction,
        primaryActionProps,
        fields,
        column,
        typeStyle
      });
      const { unit } = separateNumber(width);
      let minWidth = void 0;
      if (unit === "fr") {
        frColumns.push(columnToCheck);
        minWidth = minWidthFr;
      }
      let { textStyle } = resolveMQ(value, ["textStyle"], activeMQ);
      if (!textStyle) {
        textStyle = "columnTextMD";
      }
      if (!width) {
        throw new Error(
          `Width must be defined either in fr or px in table column. but no width defined for column ${JSON.stringify(
            column
          )}`
        );
      }
      renderColumns.push({
        key: columnToCheck || index,
        textStyle,
        defaultTextStyle,
        align,
        theme: columnTheme,
        width,
        minWidth,
        ensureView: true,
        value
      });
    });

    if (frColumns.length > 1) {
      throw new Error(
        `1fr columns can not be more than one in table row but found [${
          frColumns.length
        }] and columns are ${JSON.stringify(frColumns)}`
      );
    }

    return { dataRowColumns: renderColumns };
  };

  const getHeaderColumns = ({ minWidthFr, typeStyle, selectable, actions, fields, columns }) => {
    let renderColumns = [];
    if (selectable) {
      let { width } = (typeStyle && typeStyle["HeaderSelection"]) || {};
      let headerSelectionColumn = {
        value: {
          type: "HeaderSelection",
          componentProps: {
            actions
          }
        },
        width
      };
      renderColumns.push(headerSelectionColumn);
    }
    for (let index = 0; index < columns.length; index++) {
      const column = columns[index];
      let columnDef = resolveColumn({ fields, column, typeStyle });
      let { field, width, header, headerType, defaultTextStyle, align } = columnDef;
      if (!width) {
        throw new Error(
          `Width must be defined either in fr or px in table column. but no width defined for column ${JSON.stringify(
            column
          )}`
        );
      }
      let headerColumn = { width };
      if (headerType) {
        headerColumn["type"] = headerType;
      } else {
        let SortableComponent = getComponent && sortHeader && getComponent(sortHeader);
        if (SortableComponent) {
          headerColumn["render"] = <SortableComponent columnDef={columnDef} />;
        } else {
          headerColumn["text"] = header;
        }
      }
      let minWidth = void 0;
      const { unit } = separateNumber(width);
      minWidth = unit === "fr" && minWidthFr ? minWidthFr : void 0;
      // key is passed to manaze horizontal scroll on change activeMQ
      renderColumns.push({
        key: field || index,
        value: headerColumn,
        width,
        minWidth,
        defaultTextStyle,
        align,
        ensureView: true
      });
    }

    return { headerColumns: renderColumns };
  };

  const getRowProps = ({ data, setValue, rowProps, activeMQ, cellProps, fields, actions, selectable, user }) => {
    let dataRowProps = { cellProps };
    let headerRowProps = {};
    let { render } = resolveMQ(rowProps, ["render"], activeMQ);

    if (!render) {
      //case when direct layout or columns are given in rowProps
      render = rowProps;
    }

    let {
      layout,
      columns,
      rowTheme,
      columnTheme,
      textColor,
      gradient,
      primaryAction,
      primaryActionProps,
      gradientStyle,
      selectedViewStyle,
      viewStyle,
      highlightViewStyle,
      highLightTextColor,
      itemStyle,
      selectedIcon
    } = resolveMQ(
      render,
      [
        "layout",
        "columns",
        "rowTheme",
        "columnTheme",
        "textColor",
        "selectedIcon",
        "gradient",
        "primaryAction",
        "primaryActionProps",
        "gradientStyle",
        "selectedViewStyle",
        "viewStyle",
        "itemStyle",
        "highlightViewStyle",
        "highLightTextColor"
      ],
      activeMQ
    );
    if (layout) {
      dataRowProps = {
        ...dataRowProps,
        selectable,
        layout,
        primaryAction,
        gradient,
        gradientStyle,
        rowTheme,
        actions,
        fields,
        setValue
      };
    } else if (columns) {
      const { minWidthFr, typeStyle, typeFormat } = resolveMQ(
        theme,
        ["minWidthFr", "typeStyle", "typeFormat"],
        activeMQ
      );

      const { headerColumns } = getHeaderColumns({
        selectable,
        actions,
        fields,
        columns,
        activeMQ,
        data,
        minWidthFr,
        typeStyle,
        typeFormat
      });
      const { dataRowColumns } = getRenderColumns({
        selectable,
        cellProps,
        fields,
        columns,
        activeMQ,
        minWidthFr,
        actions,
        typeStyle,
        typeFormat,
        primaryAction: primaryAction,
        primaryActionProps,
        columnTheme,
        textColor,
        selectedIcon,
        user
      });
      headerRowProps["columns"] = headerColumns;
      dataRowProps["theme"] = rowTheme;
      dataRowProps["columns"] = dataRowColumns;
      dataRowProps["actions"] = actions;
      dataRowProps["fields"] = fields;
      dataRowProps["setValue"] = setValue;
      dataRowProps["viewStyle"] = viewStyle;
      dataRowProps["itemStyle"] = itemStyle;
      dataRowProps["selectedViewStyle"] = selectedViewStyle;
      dataRowProps["highlightViewStyle"] = highlightViewStyle;
      dataRowProps["highLightTextColor"] = highLightTextColor;
      dataRowProps["gradientStyle"] = gradientStyle;
      dataRowProps["gradient"] = gradient;
      dataRowProps["textColor"] = textColor;
    }
    return { headerRowProps, dataRowProps };
  };

  const resolveTableProps = ({ data, setValue, tableProps, cellProps, fields, actions, activeMQ, user, listProps }) => {
    if (typeof tableProps === "function") {
      tableProps = tableProps({});
    }
    let {
      rowProps,
      dataRowProps,
      headerRowProps,
      bodyProps,
      selectable,
      showHeader,
      pageSize,
      renderHeader,
      hideHeaderNoData
    } = resolveMQ(
      tableProps,
      [
        "rowProps",
        "dataRowProps",
        "headerRowProps",
        "bodyProps",
        "selectable",
        "showHeader",
        "pageSize",
        "renderHeader",
        "hideHeaderNoData"
      ],
      activeMQ
    );

    if (rowProps && typeof rowProps === "function") {
      rowProps = rowProps({ data });
    }

    listProps = {
      ...listProps
    };
    dataRowProps = {
      ...dataRowProps
    };
    headerRowProps = {
      ...headerRowProps
    };
    const { headerRowProps: simpleHeaderRowProps, dataRowProps: simpleDataRowProps } = getRowProps({
      data,
      setValue,
      rowProps,
      activeMQ,
      cellProps,
      fields,
      actions,
      selectable,
      user
    });
    dataRowProps = { ...dataRowProps, ...simpleDataRowProps };
    headerRowProps = { ...headerRowProps, ...simpleHeaderRowProps };
    if (pageSize) {
      listProps.pageSize = pageSize;
    }
    return {
      showHeader,
      dataRowProps,
      headerRowProps,
      listProps,
      renderHeader,
      hideHeaderNoData,
      bodyProps
    };
  };

  class JsonTable extends React.Component {
    tableProps = void 0;
    oldProps = void 0;

    getCacheTableProps = () => {
      const { data, activeMQ } = this.props;
      if (!this.oldProps || !this.tableProps || data !== this.oldProps.data || activeMQ !== this.oldProps.activeMQ) {
        this.oldProps = this.props;
        this.tableProps = void 0;
        return void 0;
      } else {
        return this.tableProps;
      }
    };

    getSubComponentProps = () => {
      const { subComponentType, activeMQ, componentProps } = this.props;
      if (subComponentType === "gridTable") {
        const { cardPerRow, bodyStyle } = resolveMQ(componentProps, ["cardPerRow", "bodyStyle"], activeMQ);
        return {
          cardPerRow,
          bodyStyle
        };
      } else if (subComponentType === "custom") {
        return componentProps;
      }
    };

    tableComponent = () => {
      let {
        setValue,
        data,
        fetchMore,
        state,
        setState,
        activeMQ,
        schema = {},
        component: Component,
        componentProps,
        user,
        extraData,
        hasNext,
        dataIds,
        lastDoc,
        language
      } = this.props;

      const { fields, actions } = schema;
      let listProps = {};
      if (componentProps) {
        listProps = {
          ...componentProps.listProps
        };
      }

      let tableProps = this.getCacheTableProps();
      if (!tableProps) {
        tableProps = componentProps;
        tableProps = resolveTableProps({
          data,
          setValue,
          tableProps,
          fields,
          actions,
          activeMQ,
          user,
          listProps
        });
        this.tableProps = tableProps;
      }
      const subComponentProps = this.getSubComponentProps();
      return (
        <Component
          data={data}
          setValue={setValue}
          fetchMore={fetchMore}
          state={state}
          setState={setState}
          schema={schema}
          {...tableProps}
          {...subComponentProps}
          extraData={extraData}
          hasNext={hasNext}
          dataIds={dataIds}
          lastDoc={lastDoc}
          language={language}
        />
      );
    };

    formComponent = () => {
      const {
        fetch,
        updatableData,
        validationData,
        component: Component,
        setValue,
        data,
        schema,
        componentProps,
        user
      } = this.props;
      return (
        <Component
          validationData={validationData}
          updatableData={updatableData}
          setValue={setValue}
          data={data}
          user={user}
          fetch={fetch}
          schema={schema}
          {...componentProps}
        />
      );
    };
    render() {
      const { componentType } = this.props;
      let component = void 0;
      if (componentType === "table") {
        component = this.tableComponent();
      } else {
        component = this.formComponent();
      }
      return component;
    }
  }
  return JsonTable;
};
