import React from "react";
import UploadContext from "./UploadContext";

export default ({ Box, getImage, theme, renderChildren, Platform, I18N }) => {
  const { colors, fonts } = theme;
  const { themeColor } = colors;
  const { h16_l } = fonts;
  class WebAppUpload extends React.Component {
    render() {
      if (Platform.OS !== "web") {
        return null;
      }

      let { style, children, source, action, onClick } = this.props;
      const { showText = true, showChildren } = action || {};

      let component = ((source === "menuItem" || showChildren) && children) || (
        <Box
          style={style}
          direction={"row"}
          title={I18N.t("uploadIconTitle")}
          render={[
            {
              viewStyle: { height: 24, width: 24, alignItems: "center", justifyContent: "center" },
              image: getImage("uploadIcon")
            },
            showText && {
              viewStyle: {
                paddingLeft: 8
              },
              textStyle: {
                ...h16_l,
                color: themeColor
              },
              text: I18N.t("upload")
            }
          ]}
        />
      );
      return (
        <UploadContext.Consumer>
          {context => {
            let { onSelectUpload } = context;
            return renderChildren(component, {
              onClick: () => {
                onSelectUpload && onSelectUpload();
                onClick && onClick();
              }
            });
          }}
        </UploadContext.Consumer>
      );
    }
  }
  return WebAppUpload;
};
