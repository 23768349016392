import { AlbumCardSMListProps, PlaceCardSMListProps, VaultAlbumCardSMListProps } from "./GalleryCards";
import { FSList } from "../../../FsCloudComponent";
import CollectionActions from "../collection/CollectionActions";

export const AlbumSM = {
  ComponentSM: FSList,
  componentPropsSM: {
    ...AlbumCardSMListProps,
    detailLink: CollectionActions.albumDetailLink.link
  }
};

export const PlaceSM = {
  ComponentSM: FSList,
  componentPropsSM: {
    ...PlaceCardSMListProps,
    detailLink: CollectionActions.placeDetailLink.link
  }
};

export const DeviceFolderSM = {};

export const VaultAlbumSM = {
  ComponentSM: FSList,
  componentPropsSM: {
    ...VaultAlbumCardSMListProps,
    detailLink: CollectionActions.vaultAlbumDetailLink.link
  }
};
