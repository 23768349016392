import _ from "lodash";

export default ({ fetch: dbFetch, Platform, modifyUrls, mergeTwoSortedArrays, typeCastSchema }) => {
  const cache = {};
  let listeners = {};
  let tableListeners = {};

  const getFormattedSort = ({ sort }) => {
    if (!sort) {
      return;
    }
    const fields = [];
    const directions = [];
    const dottedMemoryFormattedSort = (sort, fields, directions, pkey) => {
      for (let f in sort) {
        let val = sort[f];
        let key = pkey ? pkey + "." + f : f;
        if (val === 1) {
          fields.push(key);
          directions.push("asc");
        } else if (val === -1) {
          fields.push(key);
          directions.push("desc");
        } else if (typeof val === "object") {
          dottedMemoryFormattedSort(val, fields, directions, key);
        } else {
          throw new Error(`Not supported value [${JSON.stringify(val)}] with key [${f}] in sort.`);
        }
      }
    };
    dottedMemoryFormattedSort(sort, fields, directions);
    return { fields, directions };
  };

  const resolveValue = (doc, key) => {
    if (!doc || !key) {
      return doc;
    }
    if (Array.isArray(doc)) {
      var result = [];
      for (var i = 0; i < doc.length; i++) {
        var row = doc[i];
        var resolvedValue = resolveValue(row, key);
        if (resolvedValue !== undefined) {
          if (Array.isArray(resolvedValue)) {
            for (var j = 0; j < resolvedValue.length; j++) {
              if (key !== "_id" || result.indexOf(resolvedValue[j]) === -1) {
                result.push(resolvedValue[j]);
              }
            }
          } else {
            if (key !== "_id" || result.indexOf(resolvedValue) === -1) {
              result.push(resolvedValue);
            }
          }
        }
      }
      return result;
    } else {
      var value = doc[key];
      if (value !== undefined) {
        return value;
      }
      var indexOf = key.indexOf(".");
      if (indexOf === -1) {
        return;
      }
      var firstPart = key.substring(0, indexOf);
      var nextPart = key.substring(indexOf + 1);
      return resolveValue(doc[firstPart], nextPart);
    }
  };

  const sorting = ({ sort, data }) => {
    if (!sort || !data || !Array.isArray(data) || data.length === 0) {
      return data;
    }
    let { fields, directions } = getFormattedSort({ sort });
    // lodash used for sorting the data
    let orderedData = _.orderBy(
      data,
      fields.map(key => {
        return doc => {
          let value = resolveValue(doc, key);
          return _.isNull(value) ? "" : value;
        };
      }),
      directions
    );
    return orderedData;
  };

  const getRemoveIndex = ({ data = [], row }) => {
    let index = -1;
    let matched = false;
    if (!Array.isArray(data)) {
      data = [data];
    }
    for (const doc of data) {
      if (row._id === doc._id) {
        index++;
        matched = true;
        break;
      } else {
        index++;
      }
    }
    return matched ? index : -1;
  };

  const getInsertIndex = ({ sort, row, data, hasNext, oldIndex }) => {
    let index = 0;
    if (sort && Object.keys(sort).length) {
      for (let i = 0; i < data.length; i++) {
        for (const field in sort) {
          const asc = sort[field] === 1;
          const newValue = row[field];
          const value = data[i][field];
          if (asc) {
            if (value < newValue) {
              index++;
              break;
            } else if (value > newValue) {
              return index;
            }
          } else {
            if (value > newValue) {
              index++;
              break;
            } else if (value < newValue) {
              return index;
            }
          }
        }
      }
      return hasNext ? -1 : index;
    }
    return oldIndex >= 0 ? oldIndex : data.length;
  };

  const getModifiedData = ({ prevResult = [], newResult = [] }) => {
    let oldResultIds, singleResult, updatedResult;
    if (Array.isArray(prevResult)) {
      oldResultIds = prevResult.map(e => e._id);
      updatedResult = [...prevResult];
    } else {
      singleResult = true;
      oldResultIds = [prevResult._id];
      updatedResult = [prevResult];
    }
    for (let i = 0; i < newResult.length; i++) {
      const { _op, _newIndex = -1, _id, ...element } = newResult[i];
      const _oldIndex = oldResultIds.indexOf(_id);
      if (_op === "removed") {
        if (_oldIndex >= 0) {
          updatedResult.splice(_oldIndex, 1);
          oldResultIds.splice(_oldIndex, 1);
        }
      } else if (_op === "added") {
        if (_oldIndex < 0 && _newIndex >= 0) {
          updatedResult.splice(_newIndex, 0, { _id, ...element });
          oldResultIds.splice(_newIndex, 0, _id);
        }
      } else if (_op === "modified") {
        if (_oldIndex < _newIndex) {
          if (_newIndex >= 0) {
            updatedResult.splice(_newIndex, 0, { _id, ...element });
            oldResultIds.splice(_newIndex, 0, _id);
          }
          if (_oldIndex >= 0) {
            updatedResult.splice(_oldIndex, 1);
            oldResultIds.splice(_oldIndex, 1);
          }
        } else {
          if (_oldIndex >= 0) {
            updatedResult.splice(_oldIndex, 1);
            oldResultIds.splice(_oldIndex, 1);
          }
          if (_newIndex >= 0) {
            updatedResult.splice(_newIndex, 0, { _id, ...element });
            oldResultIds.splice(_newIndex, 0, _id);
          }
        }
      }
    }
    if (singleResult && updatedResult && updatedResult.length === 1) {
      return updatedResult[0];
    }
    return updatedResult;
  };

  const getCacheInfo = ({ uid }) => {
    let cacheInfo;
    for (const key in cache) {
      const cacheUids = cache[key] && cache[key]["uids"];
      if (cacheUids) {
        for (let cacheUid of cacheUids) {
          if (cacheUid.uid === uid) {
            cacheInfo = { ...cache[key], key, uid, sort: cacheUid.sort };
          }
        }
      }
      if (cacheInfo) break;
    }
    return cacheInfo || {};
  };

  const onRealTimeUpdate = async ({ event: rows = [], props, onUpdateState, onSnapshot, user }) => {
    const { state: { data = [] } = {}, uid, getConnectValue } = props;
    // console.log("@@@ onRealTime Result:::", rows.length, " - uid :", uid);
    if (onSnapshot) {
      const snapShotResponse = await onSnapshot({ result: rows, user, data, getConnectValue });
      if (snapShotResponse && snapShotResponse.updatedData) {
        onUpdateState({ data: snapShotResponse.updatedData });
        return;
      }
      rows = snapShotResponse.result;
    }
    // console.log("@@@ onRealTime Result after onSnapshot:::", rows.length);
    let { key, sort: currentSort } = getCacheInfo({ uid });
    if (!key) {
      // console.warn("@@@ createSnapshot key not found of uid :", uid);
      // TODO check if it is error or not
      return;
    }
    // console.log("@@@ current :", JSON.stringify(currentSort));
    for (const row of rows) {
      const { _op } = row;
      let oldIndex;
      if (_op === "removed" || _op === "modified") {
        oldIndex = getRemoveIndex({ data, row });
        row["_oldIndex"] = oldIndex;
      }
      if (_op === "added" || _op === "modified") {
        // removed hasNext that has passed to getInsertIndex
        // issue is when we mark favourite and unfavourite to last resource than resource is removed from array
        row["_newIndex"] = getInsertIndex({ sort: currentSort, data, row, oldIndex });
      }
    }
    // console.log("@@@ final updates :", rows);
    let updatedRows = getModifiedData({ prevResult: data, newResult: rows });
    // console.log("@@@ state after updates :", updatedRows);
    onUpdateState({ data: updatedRows });
  };

  // This functoin removes all the snapshots stored for a uid
  const removeSnapshot = ({ uid }) => {
    // console.log("@@@ remove snapshot call for uid :", uid);
    let found = false;
    for (const cacheKey in cache) {
      const cacheUids = cache[cacheKey] && cache[cacheKey]["uids"];
      if (cacheUids && cacheUids.length) {
        for (let index = 0; index < cacheUids.length; index++) {
          if (cacheUids[index].uid === uid && !cache[cacheKey].persistCache) {
            // console.log("@@@ remove uid :", uid);
            cacheUids.splice(index, 1);
            if (!cacheUids || !cacheUids.length) {
              cache[cacheKey].snapshot && cache[cacheKey].snapshot();
              delete cache[cacheKey];
              // console.log("@@@ cleared snapshot and cache for key :", cacheKey);
            }
            found = true;
            break;
          }
        }
      }
      if (found) {
        break;
      }
    }
  };

  // remove all snapshot ( used at logout )
  const removeAllSnapshot = () => {
    for (const cacheKey in cache) {
      cache[cacheKey].snapshot && cache[cacheKey].snapshot();
      delete cache[cacheKey];
    }
  };

  const updateSnapshotData = async ({ result = [], key }) => {
    const { uids = [] } = cache[key] || {};
    for (const subscription of uids) {
      const { callback } = subscription;
      if (callback) {
        await callback(result);
      }
    }
  };

  const createSnapshot = async ({ ref, uid, callback }) => {
    if (!ref) {
      return;
    }
    let { key, uids = [] } = getCacheInfo({ uid });
    // console.log("@@@### current uid of view :", uid, " - key :", key, " - query ref :", ref);
    if (!key) {
      // console.warn("@@@### createSnapshot key not found of uid :", uid);
      //todo check if it is error or not
      return;
    }
    uids.forEach(record => {
      if (record.uid === uid) {
        record["callback"] = callback;
      }
    });
    if (cache[key] && cache[key].queryRef && !cache[key].snapshot) {
      const { schema, queryRef } = cache[key];
      // console.log("@@@### key :", key, " - query ref :", queryRef);
      let snapshotRef = queryRef.onSnapshot(
        async snapshot => {
          const documents = typeof snapshot.docChanges === "function" ? snapshot.docChanges() : snapshot.docChanges;
          let result = [];
          const { result: oldData = [], defaultSort, filter, matchRequired } = cache[key] || {};
          const oldResultIds = oldData.map(e => e._id);
          // console.log("@@@### snapshot update key :", key);
          // console.log("@@@### snapshot old result :", oldResultIds && oldResultIds.length);
          if (documents && documents.length) {
            // adding updates - added/modified/removed with verify using old result
            documents.forEach(e => {
              const _id = e.doc.id;
              let _op = e.type;
              let doc = { _id, _op, ...e.doc.data(), _newIndex: e.newIndex, _oldIndex: e.oldIndex };
              modifyUrls && modifyUrls(doc, { table: cache[key] && cache[key].table });
              doc = typeCastSchema({ value: doc, schema });
              const _oldIndex = oldResultIds.indexOf(_id);
              let matched = matchRequired ? matchFilterInMemory({ filter, record: doc }) : true;
              // console.log("@@@### filter matched: ", matched, " -- key :", key);
              if (!matched && _oldIndex >= 0) {
                // if record already exists in data but filter is not matched then should be removed from data
                // so set the operator = removed
                _op = "removed";
                doc._op = "removed";
                matched = true;
              }
              if (matched) {
                if (_op === "modified" || _op === "added") {
                  // removed hasNext that has passed to getInsertIndex
                  // issue is when we mark favourite and unfavourite to last resource than resource is removed from array
                  const _newIndex = getInsertIndex({
                    sort: defaultSort,
                    data: oldData,
                    row: doc,
                    oldIndex: _oldIndex
                  });
                  doc["_oldIndex"] = _oldIndex;
                  doc["_newIndex"] = _newIndex;
                  if (_oldIndex >= 0) {
                    doc["_op"] = "modified";
                  }
                  result.push(doc);
                } else if (_op === "removed") {
                  doc["_oldIndex"] = _oldIndex;
                  result.push(doc);
                }
              }
            });
            let tableListenerId = cache[key] && cache[key].table;
            if (tableListenerId && tableListeners && tableListeners[tableListenerId]) {
              tableListeners[tableListenerId].forEach(listner => listner({ data: result }));
            }
          } else if (!documents && snapshot.exists) {
            const _id = snapshot.id;
            let doc = { _id, ...snapshot.data() };
            doc = typeCastSchema({ value: doc, schema });
            const _oldIndex = oldResultIds.indexOf(_id);
            // removed hasNext that has passed to getInsertIndex
            // issue is when we mark favourite and unfavourite to last resource than resource is removed from array
            const _newIndex = getInsertIndex({
              sort: defaultSort,
              data: oldData,
              row: doc,
              oldIndex: _oldIndex
            });
            doc["_oldIndex"] = _oldIndex;
            if (_oldIndex >= 0 && _newIndex >= 0) {
              doc["_newIndex"] = _newIndex;
              doc["_op"] = "modified";
              result.push(doc);
            } else if (_oldIndex >= 0 && _newIndex < 0) {
              doc["_op"] = "removed";
              result.push(doc);
            } else if (_oldIndex < 0 && _newIndex >= 0) {
              doc["_newIndex"] = _newIndex;
              doc["_op"] = "added";
              result.push(doc);
            }
          }
          // console.log("@@@### snapshot updated result :", result.length);
          let newResult = [];
          // console.log("@@@ running snapshot update for key :", key);
          if (cache[key]) {
            const prevResult = cache[key].result || [];
            if (cache[key].snapshot && result.length) {
              // modify cache data
              // console.log("@@@ snapshot result :", result);
              const cacheResult = getModifiedData({ prevResult, newResult: result });
              // console.log("@@@### snapshot cache result after updation :", cacheResult.length);
              if (cache[key]) {
                cache[key].result = cacheResult;
                newResult = cache[key].result.map(doc => ({ ...doc }));
                await updateSnapshotData({ result: [...result], key });
              }
            } else if (!cache[key].snapshot) {
              // adding snapshot if not found
              cache[key].snapshot = snapshotRef;
            }
            // notify Listeners
            newResult.forEach(document => {
              const { _op, _newIndex, _oldIndex, ...doc } = document;
              if (doc._id && cache[key]) {
                let listenerId = cache[key] && cache[key].table + "_" + doc._id;
                if (listeners && listeners[listenerId]) {
                  listeners[listenerId].forEach(listner => listner({ data: doc }));
                }
              }
            });
          }
        },
        error => {
          // when snapshot called for load data in gallery and we logout error(app crash)
        }
      );
    }
  };

  const matchFilterInMemory = ({ filter = {}, record = {} }) => {
    try {
      // console.log("@@@### filter: ", filter, " - doc :", record);
      for (const field in filter) {
        const value = filter[field];
        const dottedFields = field.split(".");
        if (dottedFields.length === 2) {
          if (value !== record[dottedFields[0]][dottedFields[1]]) {
            return false;
          }
        } else if (field === "collections" || field === "collabrators" || field === "members") {
          const newValue = value.$contains;
          if (!record[field] || record[field].indexOf(newValue) < 0) {
            return false;
          }
        } else if (field !== "_search" && value !== record[field]) {
          return false;
        }
      }
      return true;
    } catch (error) {
      // console.warn("@@ error in filter match >>> ", error);
      return false;
    }
  };

  const fetch = async ({
    fetch: urlFetch,
    view,
    schema,
    uid,
    subscribe,
    fromCache: useCache,
    matchRequired,
    isIgnoreResult
  }) => {
    // console.log("@@@@ cache is >>>", cache);
    const { table, filter = {}, sort = {}, skip, limit, localChangeKey, persistCache } = view;
    let key = table + "__filter_" + JSON.stringify(filter);
    let data = useCache && cache[key];
    let hasDataInCache = data && Object.keys(data).length > 0;
    if (skip || !hasDataInCache) {
      let fetchToUse = urlFetch || dbFetch;
      if (useCache || subscribe) {
        cache[key] = cache[key] || {};
      }
      let newData = await fetchToUse({ view, schema });
      if (isIgnoreResult && isIgnoreResult()) {
        return {};
      }
      if (useCache) {
        // console.log("@@@ added data in cache for key: ", key);
        const { result: newResult = [] } = newData;
        if (skip) {
          let { result: preResult = [] } = data || {};
          // console.log("@@@### old result :", preResult.length, preResult);
          // console.log("@@@### new result :", newResult.length, newResult);
          preResult = mergeTwoSortedArrays(preResult, newResult, sort);

          data["result"] = newResult;
          data["hasNext"] = limit ? newResult.length > 0 : false;
          if (cache[key]) {
            cache[key] = { ...cache[key], result: preResult, hasNext: data.hasNext };
          }
        } else {
          data = newData || {};
          data["hasNext"] = limit > newResult.length ? false : true;
          if (cache[key]) {
            cache[key] = {
              ...data,
              table,
              defaultSort: sort,
              sort,
              schema,
              filter,
              matchRequired,
              localChangeKey,
              persistCache
            };
          }
          // console.log("@@@### without skip - new result :", newData);
        }
        // console.log("@@@ add data in cache with key :", key, " -- default sort :", sort, " -- data :", data);
      } else {
        data = newData;
      }
    } else {
      // console.warn("@@@ data is in cache for key", key);
    }

    if ((useCache || subscribe) && cache[key]) {
      // console.log("@@@### subscribed for key :", key, " - uid :", uid, " - with sort :", sort);
      cache[key] = cache[key] || {};
      const uids = cache[key]["uids"] || [];
      let found = false;
      for (let index = 0; index < uids.length; index++) {
        const element = uids[index];
        if (element.uid === uid) {
          element.sort = sort;
          found = true;
          break;
        }
      }
      cache[key]["uids"] = uids;
      if (!found) {
        cache[key]["uids"].push({ uid, sort });
      }
    }
    let { queryRef, result } = data;
    let newResult;
    const { defaultSort = {} } = cache[key] || {};
    if (JSON.stringify(defaultSort) !== JSON.stringify(sort)) {
      // console.log("@@@ resort called in fetch data key :", key, " -- sort :", sort, " -- default : ", defaultSort);
      newResult = sorting({ sort, data: result });
    } else {
      newResult = result.map(doc => ({ ...doc }));
    }
    return { queryRef: skip && skip !== 0 ? void 0 : queryRef, key, result: newResult };
  };

  const addRealTimeListener = ({ table, _id, callback }) => {
    listeners = listeners || {};
    let key = table + "_" + _id;
    listeners[key] = listeners[key] || [];
    listeners[key].push(callback);
  };

  const removeRealTimeListener = ({ table, _id, callback }) => {
    let key = table + "_" + _id;
    let keyListeners = listeners && listeners[key];
    let index = keyListeners && keyListeners.indexOf(callback);
    if (index >= 0) {
      keyListeners.splice(index, 1);
    }
  };

  const addTableListener = ({ table, callback }) => {
    tableListeners = tableListeners || {};
    let key = table;
    tableListeners[key] = tableListeners[key] || [];
    tableListeners[key].push(callback);
  };

  const removeTableListener = ({ table, callback }) => {
    let key = table;
    let keyListeners = tableListeners && tableListeners[key];
    let index = keyListeners && keyListeners.indexOf(callback);
    if (index >= 0) {
      keyListeners.splice(index, 1);
    }
  };

  const updateCacheFromLocalChanges = ({ key: localChangeKey, localChanges }) => {
    // console.log("@@@@ updateCacheFromLocalChanges", localChanges);
    if (!Array.isArray(localChanges)) {
      localChanges = [localChanges];
    }
    let table = localChangeKey;
    let onlyLocalKeyChange = false;
    let indexOf = localChangeKey.indexOf("__");
    if (indexOf > 0) {
      table = localChangeKey.substring(0, indexOf);
      onlyLocalKeyChange = true;
    }
    let localChangeMap = {};
    let removeMap = {};
    let insertArray = void 0;
    let emptyCache = void 0;
    for (let index = 0; index < localChanges.length; index++) {
      let localChange = localChanges[index];
      const { _id, changes, insert, remove, clearData } = localChange;
      if (clearData) {
        emptyCache = clearData;
      } else if (insert && Array.isArray(insert) && insert.length) {
        insertArray = insert;
      } else if (remove) {
        removeMap[_id] = 1;
      } else if (changes) {
        localChangeMap[_id] = { changes, _id };
      }
    }
    // console.log("@@@@ actions to perform>>>", insertArray, removeMap, localChangeMap);
    for (let key in cache) {
      if (cache[key] && cache[key].table === table) {
        if (!onlyLocalKeyChange || (cache[key].localChangeKey && cache[key].localChangeKey === localChangeKey)) {
          let { result = [], sort } = cache[key];
          if (emptyCache) {
            result = [];
          }
          if (insertArray) {
            // console.log("@@@@ before insert", result.length);
            result = mergeTwoSortedArrays(result, insertArray, sort);
            // console.log("@@@@ after insert", cache[key].result.length);
          }
          if (Object.keys(removeMap).length && result.length) {
            result = result.filter(item => !removeMap[item._id]);
          }
          let updatedResult = [];
          if (result.length && Object.keys(localChangeMap).length) {
            // console.log("@@@@ before update", result.length);
            for (let dataIndex = 0; dataIndex < result.length; dataIndex++) {
              let doc = result[dataIndex];
              if (doc && doc._id && localChangeMap[doc._id] && localChangeMap[doc._id].changes) {
                let changes = localChangeMap[doc._id].changes;
                if (typeof changes === "function") {
                  changes = changes(doc);
                }
                doc = { ...doc, ...changes };
              }
              if (!doc.deleted) {
                updatedResult.push(doc);
              }
            }
          } else {
            updatedResult = result;
          }
          cache[key].result = updatedResult;
        }
      }
    }
  };

  return {
    fetch,
    createSnapshot,
    removeSnapshot,
    removeAllSnapshot,
    onRealTimeUpdate,
    addRealTimeListener,
    removeRealTimeListener,
    addTableListener,
    removeTableListener,
    updateCacheFromLocalChanges
  };
};
