import React from "react";
import { AlbumCard, PlaceCard, VaultAlbumCard } from "./GalleryCards";
import { GalleryAlbumNoData, GalleryPlaceNoData, VaultAlbumNoData, GalleryFolderNoData } from "../noData/NoData";
import { albumQuery, placesQuery, vaultAlbumQuery, deviceFolderQuery } from "../queries";
import ResourceActions from "../resource/ResourceActions";
import { AlbumSM, PlaceSM, VaultAlbumSM, DeviceFolderSM } from "./AlbumSM";

const commonProps = {
  component: "table",
  dataObject: "Collection",
  notifyNavOnScroll: true,
  selectable: false,
  ComponentMD: null,
  componentTypeMD: {
    gridTable: {
      cardPerRowLG: 4,
      cardPerRowMD: 2,
      bodyStyleMD: {
        flex: 1,
        paddingLeft: 28,
        paddingRight: 102
      }
    }
  },
  bodyProps: {
    bodyStyle: {
      flex: 1
    }
  }
};

export default {
  Album: {
    ...commonProps,
    ...albumQuery,
    ...AlbumSM,
    renderNoData: <GalleryAlbumNoData />,
    headerActionsSM: [
      ResourceActions.searchGallery,
      {
        type: "headerAction",
        image: "createGreyIcon",
        action: "createAlbum"
      }
    ],
    headerActionsMD: [
      ResourceActions.searchGallery,
      {
        type: "multiActions",
        image: "createWithCircleGreyIcon",
        action: "createAlbum",
        visible: ({ data }) => (data && data.length ? true : false)
      }
    ],
    rowProps: {
      ...AlbumCard
    }
  },
  Places: {
    ...commonProps,
    ...placesQuery,
    ...PlaceSM,
    renderNoData: <GalleryPlaceNoData />,
    headerActions: [ResourceActions.searchGallery],
    rowProps: {
      ...PlaceCard
    }
  },
  DeviceFolder: {
    ...commonProps,
    ...deviceFolderQuery,
    ...DeviceFolderSM,
    renderNoData: <GalleryFolderNoData />,
    headerActions: [ResourceActions.searchGallery]
  },
  VaultAlbum: {
    ...commonProps,
    ...VaultAlbumSM,
    ...vaultAlbumQuery,
    renderNoData: <VaultAlbumNoData />,
    headerActionsSM: [
      ResourceActions.searchVaultGallery,
      {
        type: "headerAction",
        image: "createGreyIcon",
        action: "createVaultAlbum"
      }
    ],
    headerActionsMD: [
      {
        ...ResourceActions.searchVaultGallery,
        containerStyle: { flex: 5, alignItems: "center", overflow: void 0 }
      },
      {
        type: "multiActions",
        image: "createWithCircleGreyIcon",
        action: "createVaultAlbum",
        visible: ({ data }) => (data && data.length ? true : false)
      },
      {
        type: "webAppUpload",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ],
    rowProps: {
      ...VaultAlbumCard
    }
  }
};
