import React from "react";
import {
  getImage,
  theme,
  showMessage,
  Platform,
  I18N,
  DrawerMenuHeader,
  getComponent,
  Box,
  AppConfig,
  getDesktopUrl
} from "../FsCloudComponent";

export const getUserMenus = ({ user, closeDrawer, getActionContainer }) => {
  const { APP_SHORT_NAME } = AppConfig;
  return {
    replaceLink: false,
    renderHeaderMD: () => {
      return user ? (
        <DrawerMenuHeader showHeader editProfile closeDrawer={closeDrawer} getActionContainer={getActionContainer} />
      ) : (
        void 0
      );
    },
    renderHeaderSM: () => {
      return user ? <DrawerMenuHeader closeDrawer={closeDrawer} getActionContainer={getActionContainer} /> : void 0;
    },
    renderFooterSM:
      APP_SHORT_NAME === "Tim" && Platform.OS !== "web"
        ? () => {
            const LinkBox = getComponent("anchor");
            let appUrl = Platform.OS === "android" ? AppConfig.APP_PLAYSTORE_URL : AppConfig.APP_APPSTORE_URL;
            return (
              <LinkBox url={appUrl}>
                <Box
                  image={getImage("menuBannerIcon")}
                  imageStyle={{
                    width: "100%",
                    height: 54
                  }}
                />
              </LinkBox>
            );
          }
        : false,
    renderFooterMD: false,
    menusMD: [
      {
        title: I18N.t("gallery"),
        icon: getImage("menuGalleryIcon"),
        selectedIcon: getImage("menuGallerySelectedIcon"),
        link: { uri: "/home#gallery" },
        replace: true
      },
      {
        title: I18N.t("highlights"),
        icon: getImage("menuHighlightIcon"),
        selectedIcon: getImage("menuHighlightSelectedIcon"),
        link: {
          uri: "/home#highlights"
        },
        replace: true
      },
      {
        title: I18N.t("docs"),
        icon: getImage("menuDocIcon"),
        selectedIcon: getImage("menuDocSelectedIcon"),
        link: { uri: "/home#docs" },
        replace: true
      },
      {
        title: I18N.t("music"),
        icon: getImage("menuMusicIcon"),
        selectedIcon: getImage("menuMusicSelectedIcon"),
        link: { uri: "/home#music" },
        replace: true
      },
      {
        render: [
          {
            width: "1fr",
            viewStyle: { borderBottomWidth: 1, borderColor: theme.bgs.lightPink }
          }
        ]
      },
      {
        title: I18N.t("safeArea"),
        icon: getImage("menuSafeAreaIcon"),
        selectedIcon: getImage("menuSafeAreaSelectedIcon"),
        action: {
          type: "vaultPin"
        }
      },
      {
        title: I18N.t("archive"),
        icon: getImage("menuArchiveIcon"),
        selectedIcon: getImage("menuArchiveSelectedIcon"),
        link: { uri: "/archive" }
      },
      {
        title: I18N.t("contacts"),
        icon: getImage("menuContactIcon"),
        selectedIcon: getImage("menuContactSelectedIcon"),
        link: { uri: "/contacts" }
      },
      {
        title: I18N.t("duplicateResources"),
        icon: getImage("menuDuplicateIcon"),
        selectedIcon: getImage("menuDuplicateSelectedIcon"),
        link: { uri: "/duplicate-resources" }
      },
      {
        title: I18N.t("trashCan"),
        icon: getImage("menuTrashIcon"),
        selectedIcon: getImage("menuTrashSelectedIcon"),
        link: { uri: "/trashCan" }
      },
      {
        title: I18N.t("failedItems"),
        icon: getImage("menuFailedIcon"),
        selectedIcon: getImage("menuFailedSelectedIcon"),
        link: { uri: "/failed-items" }
      },
      {
        title: user && user.support ? I18N.t("reported_issues") : I18N.t("report_an_issues"),
        icon: getImage("reportIcon"),
        selectedIcon: getImage("reportSelectedIcon"),
        link: user && user.support ? { uri: "/support-issues" } : { uri: "/user-issues" }
      },
      {
        title: I18N.t("logout"),
        icon: getImage("menuLogoutIcon"),
        selectedIcon: getImage("menuLogoutSelectedIcon"),
        action: {
          type: "logoutPopup"
        },
        resetIndexOnSelect: true
      },
      {
        render: [
          {
            width: "1fr",
            text: I18N.t("buildVersion"),
            viewStyle: {
              padding: 20,
              justifyContent: "center",
              cursor: "default"
            },
            textStyle: {
              ...theme.fonts.h9,
              color: "rgb(88, 88, 88)"
            }
          }
        ]
      }
    ],
    menusSM: [
      {
        title: I18N.t("home"),
        icon: getImage("menuHomeIcon"),
        selectedIcon: getImage("menuHomeSelectedIcon"),
        link: { uri: "/home#highlights" },
        replace: true
      },
      {
        title: I18N.t("safeArea"),
        icon: getImage("menuSafeAreaIcon"),
        selectedIcon: getImage("menuSafeAreaSelectedIcon"),
        link: ({ user }) => {
          return {
            uri: user.vaultEnabled ? "/enter-vault-pin" : "/create-vault-pin"
          };
        }
      },
      {
        title: I18N.t("archive"),
        icon: getImage("menuArchiveIcon"),
        selectedIcon: getImage("menuArchiveSelectedIcon"),
        link: { uri: "/archive" }
      },
      Platform.OS !== "web" && {
        title: I18N.t("freeUpSpace"),
        icon: getImage("menuAllFilesIcon"),
        selectedIcon: getImage("menuAllFilesSelectedIcon"),
        link: { uri: "/freeUpSpace" }
      },
      {
        title: I18N.t("configuration"),
        icon: getImage("menuSettingIcon"),
        selectedIcon: getImage("menuSettingSelectedIcon"),
        link: { uri: "/configuration" }
      },
      {
        title: I18N.t("duplicateResources"),
        icon: getImage("menuDuplicateIcon"),
        selectedIcon: getImage("menuDuplicateSelectedIcon"),
        link: { uri: "/duplicate-resources" }
      },
      {
        title: I18N.t("trashCan"),
        icon: getImage("menuTrashIcon"),
        selectedIcon: getImage("menuTrashSelectedIcon"),
        link: { uri: "/trashCan" }
      },
      {
        title: I18N.t("failedItems"),
        icon: getImage("menuFailedIcon"),
        selectedIcon: getImage("menuFailedSelectedIcon"),
        link: { uri: "/failed-items" }
      },
      {
        title: user && user.support ? I18N.t("reported_issues") : I18N.t("report_an_issues"),
        icon: getImage("reportIcon"),
        selectedIcon: getImage("reportSelectedIcon"),
        link: user && user.support ? { uri: "/support-issues" } : { uri: "/user-issues" }
      },
      {
        title: I18N.t("logout"),
        icon: getImage("menuLogoutIcon"),
        selectedIcon: getImage("menuLogoutSelectedIcon"),
        action: {
          type: "logoutPopup"
        },
        resetIndexOnSelect: true
      },
      {
        render: [
          {
            width: "1fr",
            text: I18N.t("buildVersion"),
            viewStyle: {
              padding: 12,
              justifyContent: "center"
            },
            textStyle: {
              ...theme.fonts.h3,
              color: "rgb(88, 88, 88)"
            }
          }
        ]
      }
    ]
  };
};

let lastPressTime = void 0;

export const onBackPress = exit => {
  if (Platform.OS !== "android") {
    return;
  }
  let currentTime = new Date().getTime();
  if (lastPressTime + 3000 >= currentTime) {
    exit();
    return false;
  } else {
    lastPressTime = currentTime;
    showMessage && showMessage(I18N.t("pressAgainToExitApp"));
    return true;
  }
};
