/**
 * it will be called when we add item to some existing collection or  a new collection
 *
 */

import { METADATA_TABLE } from "../../Constants";
export const addItems = async ({
  collectionName,
  origin,
  collection,
  selectedIds,
  invoke,
  urls,
  notifyLocalOnInvoke
}) => {
  const types = { doc: "set", music: "playlist", gallery: "album" };
  let type = types[origin] || origin;
  const items = selectedIds.map(_id => ({ _id }));
  let serviceProps;
  if (collectionName) {
    serviceProps = {
      service: {
        url: urls["createCollection"],
        uriProps: {
          data: {
            name: collectionName,
            type,
            items
          }
        }
      }
    };
  } else {
    serviceProps = {
      service: {
        url: urls["addItemToCollection"],
        uriProps: {
          data: {
            _id: collection._id,
            items
          }
        }
      }
    };
  }
  return await invoke(serviceProps, { notifyLocalOnInvoke });
};

export const renameCollection = async ({ invoke, urls, data, name, origin }) => {
  return await invoke({
    service: {
      url: urls["renameCollection"],
      uriProps: {
        data: {
          name,
          _id: data._id,
          type: origin
        }
      }
    }
  });
};

export const renameResource = async ({ invoke, urls, data, name }) => {
  return await invoke(
    {
      service: {
        url: urls["renameResource"],
        uriProps: {
          data: {
            name,
            resourceId: data._id
          }
        }
      }
    },
    {
      notifyLocalOnInvoke: (_, { invokeProps, fireLocalDataChangeListener }) => {
        let { service: { uriProps: { data: { name: updateName, resourceId } = {} } = {} } = {} } = invokeProps;
        fireLocalDataChangeListener({
          key: METADATA_TABLE,
          localChanges: {
            _id: resourceId,
            changes: { name: updateName, resource_name: updateName, lower_name: updateName.toLowerCase() },
            source: "action"
          },
          updateSqlite: true
        });
      }
    }
  );
};

export const checkCollectionExists = async ({ invoke, urls, name, type }) => {
  return await invoke({
    service: {
      url: urls["checkCollectionExists"],
      uriProps: {
        data: {
          name,
          type
        }
      }
    }
  });
};

export const getDocExtIcon = ({ data, getImage }) => {
  let ext = data.ext || "unknown";
  let extImageName = ext + "ShadowIcon";
  if (ext === "3ds") {
    extImageName = "shadow3dsIcon";
  }
  if (ext === "pptx") {
    extImageName = "pptShadowIcon";
  }
  let extIcon = getImage(extImageName);
  if (!extIcon) {
    extIcon = getImage("unknownShadowIcon");
  }
  return extIcon;
};
export const getDocPopUpExtIcon = ({ data, getImage }) => {
  let ext = data.ext || "unknown";
  let extImageName = ext + "ShadowpopupIcon";
  if (ext === "3ds") {
    extImageName = "shadowpopup3dsIcon";
  }
  if (ext === "pptx") {
    extImageName = "pptShadowpopupIcon";
  }
  let extIcon = getImage(extImageName);
  if (!extIcon) {
    extIcon = getImage("unknownShadowpopupIcon");
  }
  return extIcon;
};

export const getErrorMessage = (e, { I18N, nameLengthError, duplicateError } = {}) => {
  if (e.code === "collection/duplicate" && duplicateError) {
    return duplicateError;
  } else if (e.code === "metadata/name-length" && nameLengthError) {
    return nameLengthError;
  } else if (e.code === "invoke_already_in_progress") {
    //ignore error
  } else {
    let message = I18N && e.code ? I18N.t(e.code.toString()) : void 0;
    if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
      message = e.message;
    }
    return message;
  }
};
