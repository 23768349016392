import React from "react";
import GroupMemberActions from "./GroupMemberActions";
import {
  Box,
  View,
  getImage,
  theme,
  I18N,
  RowAction,
  shareLink,
  withContext,
  Platform,
  getJsonComponent,
  getComponent,
  getAction,
  StatusBar as LoadingIndicator,
  showMessage,
  getProfileUrl
} from "../../../FsCloudComponent";
import { getZeroTimeDate } from "client-utility/lib/DateUtility";

let { colors, fonts, shadows, listContentContainerStyle } = theme;
let { h1_16, h2_l_18, h9, h16_l, h9_18 } = fonts;
let { brownGrey, primaryColor, highlightColor, themeColor, tomato, whiteTwo } = colors;

// eslint-disable-next-line
const getMemberProfileUrl = member => {
  let firebaseUid = member && member.firebaseUid;
  return `${getProfileUrl(firebaseUid)}${member && member.updatedOn ? "&" + member.updatedOn : ""}`;
};

class GroupMemberCard extends React.Component {
  onInviteMember = async () => {
    if (this.inProgress) {
      return;
    }
    try {
      let { invoke, urls, user } = this.props;
      if (Platform.OS !== "web") {
        this.inProgress = true;
        let groupLinkResult = await invoke({
          service: {
            url: urls["groupLink"],
            uriProps: {
              data: {
                _id: user && user.group && user.group._id
              }
            }
          },
          allowParallelInvoke: true
        });
        this.inProgress = false;
        let joinLink = groupLinkResult && groupLinkResult.joinLink;
        shareLink(joinLink);
      }
    } catch (err) {
      showMessage && showMessage(err.message, 2000);
    }
  };

  render() {
    let { data, user, index } = this.props;
    let isGroupOwner = user && user.group && user.group.is_group_owner;
    let { member, owner, accepted, _expireOn, avatar, profile_pic } = data || {};
    let isMyProfile = user && user.uid && member && member.firebaseUid === user.uid;
    let secondaryText = "";
    if (owner) {
      secondaryText = I18N.t("owner");
    } else if (!isMyProfile) {
      if (accepted) {
        secondaryText = I18N.t("member");
      } else if (isGroupOwner && _expireOn) {
        let expire_days = 0;
        let daysDifference = getZeroTimeDate(_expireOn) - getZeroTimeDate(new Date());
        if (daysDifference > 0) {
          daysDifference = parseInt(daysDifference / (1000 * 60 * 60 * 24));
          expire_days = daysDifference;
        }
        if (expire_days) {
          secondaryText = I18N.t("inviteExpireMessage").replace("_days_", `${expire_days}`);
        }
      }
    }
    let imageStyle = {
      width: 50,
      height: 50,
      borderRadius: 25
    };
    if (Platform.OS === "web") {
      imageStyle.objectFit = "cover";
    }

    return (
      <Box
        render={[
          isGroupOwner &&
            index === 0 &&
            Platform.OS !== "web" && {
              viewStyle: {
                marginLeft: 12,
                marginRight: 12,
                marginTop: 5,
                marginBottom: 5,
                borderRadius: 4,
                ...shadows.cardShadow,
                alignItems: "center",
                height: 74,
                backgroundColor: themeColor
              },
              direction: "row",
              onClick: this.onInviteMember,
              activeOpacity: 0.8,
              render: [
                {
                  viewStyle: { paddingLeft: 12, paddingRight: 16 },
                  image: getImage("createMemberIcon")
                },
                {
                  width: "1fr",
                  viewStyle: {
                    paddingRight: 12
                  },
                  render: [
                    {
                      textStyle: { ...h1_16, color: highlightColor },
                      text: I18N.t("inviteMembers")
                    },
                    {
                      textStyle: { ...h2_l_18, color: highlightColor },
                      viewStyle: { paddingTop: 4 },
                      text: I18N.t("inviteMessage")
                    }
                  ]
                }
              ]
            },
          {
            direction: "row",
            viewStyle: {
              backgroundColor: highlightColor,
              marginLeft: 12,
              marginRight: 12,
              marginTop: 5,
              marginBottom: 5,
              borderRadius: 4,
              ...shadows.cardShadow,
              height: 74,
              alignItems: "center"
            },
            render: [
              !profile_pic &&
                avatar && {
                  viewStyle: {
                    marginLeft: 12,
                    marginRight: 16,
                    height: 50,
                    width: 50,
                    borderRadius: 25,
                    backgroundColor: avatar.color
                  },
                  textStyle: { textAlign: "center", marginTop: 15, color: "white" },
                  text: avatar.initials ? avatar.initials.toUpperCase() : ""
                },
              profile_pic && {
                viewStyle: {
                  marginLeft: 12,
                  marginRight: 16,
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: whiteTwo
                },
                imageStyle,
                imageProps: {
                  resizeMode: "cover"
                },
                image: { uri: profile_pic }
              },
              {
                width: "1fr",
                render: [
                  {
                    textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                    text: data.email
                  },
                  secondaryText && {
                    textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                    viewStyle: { paddingTop: 4 },
                    text: secondaryText
                  }
                ]
              },
              (isMyProfile || (isGroupOwner && accepted)) && {
                viewStyle: {
                  paddingLeft: 12,
                  paddingRight: 12,
                  alignItems: "center",
                  justifyContent: "center",
                  height: 44,
                  width: 44
                },
                image: getImage("iconMenuGrey"),
                Container: RowAction,
                containerProps: {
                  data,
                  action: [GroupMemberActions.editProfile, GroupMemberActions.removeMember]
                }
              }
            ]
          }
        ]}
      />
    );
  }
}

GroupMemberCard = withContext(GroupMemberCard, {
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user"
});

const GroupDocsCardSMRender = ({ item, index }) => {
  return <GroupMemberCard data={item} index={index} />;
};

const GroupMemberCardSMListProps = {
  style: { flex: 1, marginTop: 5 },
  contentContainerStyle: listContentContainerStyle,
  renderItem: GroupDocsCardSMRender
};

class GroupMembersWebComponent extends React.Component {
  state = { error: void 0 };
  onResendInvitation = async ({ data }) => {
    const { urls, invoke, user } = this.props;
    this.setState({ loading: true });
    try {
      let service;
      let email = data && data.email;
      service = {
        service: {
          url: urls["addGroupMembers"],
          uriProps: {
            data: {
              _id: user && user.group && user.group._id,
              members: [email]
            }
          }
        }
      };
      await invoke(service);
      this.setState({ loading: false });
      showMessage && showMessage(I18N.t("invitationResendMessage"), 2000);
    } catch (e) {
      this.setState({
        loading: false,
        error: e.message
      });
    }
  };
  render() {
    let { loading } = this.state;
    let { data, user } = this.props;
    let isGroupOwner = user && user.group && user.group.is_group_owner;
    if (!isGroupOwner && data && data.length) {
      data = data.filter(row => (row.accepted ? true : false));
    }
    let Link = getAction("link");
    const JsonTable = getJsonComponent("jsonTable");
    return (
      <View style={{ flex: 1 }}>
        {loading && <LoadingIndicator />}
        <JsonTable
          data={data}
          componentType="table"
          component={getComponent("table")}
          componentProps={{
            showHeader: false,
            selectable: false,
            bodyProps: {
              DataRow: props => {
                let { data: rowData } = props;
                let { email, member, owner, accepted, _expireOn, avatar, profile_pic } = rowData;
                let isMyProfile = user && user.uid && member && member.firebaseUid === user.uid;

                let primaryTextInfo = void 0;
                if (owner) {
                  primaryTextInfo = {
                    textStyle: { ...h9, color: brownGrey },
                    text: () => I18N.t("owner")
                  };
                } else if (!isMyProfile) {
                  if (accepted) {
                    primaryTextInfo = {
                      textStyle: { ...h9, color: themeColor },
                      text: () => I18N.t("member")
                    };
                  } else if (isGroupOwner && _expireOn) {
                    let expire_days = 0;
                    let daysDifference = getZeroTimeDate(_expireOn) - getZeroTimeDate(new Date());
                    if (daysDifference > 0) {
                      daysDifference = parseInt(daysDifference / (1000 * 60 * 60 * 24));
                      expire_days = daysDifference;
                    }
                    if (expire_days) {
                      let translateMsg = I18N.t("inviteExpireMessage").replace("_days_", `${expire_days}`);
                      primaryTextInfo = {
                        textStyle: { ...h9, color: brownGrey },
                        text: translateMsg
                      };
                    }
                  }
                }
                let secondaryTextInfo = void 0;
                if (isMyProfile) {
                  secondaryTextInfo = {
                    text: () => I18N.t("editMyProfile"),
                    Container: Link,
                    containerProps: {
                      action: {
                        link: { uri: "/profile#profile" }
                      }
                    }
                  };
                } else if (isGroupOwner && !accepted) {
                  secondaryTextInfo = {
                    text: () => I18N.t("resendInvitation"),
                    onClick: _ => this.onResendInvitation({ data: rowData })
                  };
                }

                return (
                  <Box
                    direction="row"
                    viewStyle={{
                      marginLeft: 15,
                      paddingTop: 12,
                      paddingBottom: 12,
                      alignItems: "center"
                    }}
                    render={[
                      !profile_pic &&
                        avatar && {
                          viewStyle: {
                            height: 56,
                            width: 56,
                            marginLeft: 25,
                            marginRight: 25,
                            borderRadius: 28,
                            backgroundColor: avatar.color
                          },
                          textStyle: { textAlign: "center", marginTop: 19, color: "white" },
                          text: avatar.initials ? avatar.initials.toUpperCase() : ""
                        },
                      profile_pic && {
                        viewStyle: {
                          height: 56,
                          width: 56,
                          marginLeft: 25,
                          marginRight: 25,
                          borderRadius: 28,
                          backgroundColor: whiteTwo
                        },
                        imageStyle: {
                          width: 56,
                          height: 56,
                          borderRadius: 28,
                          objectFit: "cover"
                        },
                        image: profile_pic
                      },
                      {
                        viewStyle: {
                          marginLeft: 25,
                          marginRight: 25,
                          width: 400
                        },
                        render: [
                          {
                            text: email,
                            textStyle: { ...h16_l, color: brownGrey, numberOfLines: 1 }
                          },
                          {
                            direction: "row",
                            viewStyle: { paddingTop: 6, cursor: "pointer" },
                            render: [
                              primaryTextInfo,
                              primaryTextInfo &&
                                secondaryTextInfo && {
                                  viewStyle: { paddingLeft: 3, paddingRight: 3 },
                                  text: "-"
                                },
                              secondaryTextInfo && {
                                textStyle: { ...h9, color: themeColor },
                                ...secondaryTextInfo
                              }
                            ]
                          }
                        ]
                      },
                      isGroupOwner &&
                        !owner && {
                          viewStyle: {
                            height: 36,
                            width: 137,
                            justifyContent: "center",
                            alignItems: "center",
                            borderWidth: 1,
                            borderColor: tomato,
                            borderRadius: 4,
                            cursor: "pointer"
                          },
                          text: () => I18N.t(accepted ? "remove" : "cancelInvitation"),
                          textStyle: { ...h9_18, color: tomato },
                          Container: RowAction,
                          containerProps: {
                            data: rowData,
                            action: accepted ? GroupMemberActions.removeMember : GroupMemberActions.cancelInvite
                          }
                        }
                    ]}
                  />
                );
              }
            }
          }}
        />
      </View>
    );
  }
}

GroupMembersWebComponent = withContext(GroupMembersWebComponent, {
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user"
});

export { GroupMemberCard, GroupMembersWebComponent, GroupMemberCardSMListProps };
