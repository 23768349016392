import React from "react";
import { commonLayout } from "../../layouts";
import {
  Box,
  theme,
  I18N,
  dataFormat,
  FSListItem,
  View,
  Text,
  Image,
  FSRowAction,
  getImage,
  Platform,
  getModeText,
  DecryptedImage
} from "../../../FsCloudComponent";
import AlbumSlideShow from "./AlbumSlideShow";
import CollectionActions from "../collection/CollectionActions";
import { getAlbumDateLabel } from "./dateUtility";

const { numberFormatter } = dataFormat;

const { fonts, colors, listContentContainerStyle } = theme;
const { h9, h16_l, h3, h2, h9_20, h1_16, h2_l_18, h20_22 } = fonts;
const { highlightColor, brownGrey, primaryColor } = colors;

const albumCardHeight = 150;
let albumCardIconMenu = getImage("iconMenuWhite");
const iconMenuContainerStyle = {
  height: 32,
  width: 40,
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: 4,
  paddingRight: 4
};
const albumCardRowActions = [
  "manageCollabration",
  "makePrivateAlbum",
  "share",
  "shareNative",
  "getLinkNative",
  "addItemsToAlbumLink",
  "setCoverLink",
  "renameAlbum",
  "markDeleteAlbum"
];
const vaultAlbumCardRowActions = ["addItemsToVaultAlbumLink", "setCoverLinkVault", "renameAlbum", "markDeleteAlbum"];

const getCoverImageDecryptionProps = (data = {}, origin = "album") => {
  let coverData = {
    ...data.coverImage,
    collection: (origin === "album" || origin === "vaultAlbum") && { _id: data._id }
  };
  return {
    data: coverData,
    source: { uri: coverData.thumbnail_url },
    decryptionSourceProps: (origin === "album" || origin === "vaultAlbum") && { decryptionSource: "collection" }
  };
};

const commonSMCardRender = origin => ({ item, index, activeMQ, onClick }) => {
  const dateLabel = origin !== "places" && origin !== "deviceFolder" && getAlbumDateLabel(item);
  let itemCount = numberFormatter(item.itemCount, "0.0i");
  let imageProps = {
    style: { height: 150 }
  };
  let additionViewStyle = { height: 150 };
  if (Platform.OS !== "web") {
    additionViewStyle = { marginLeft: 1, marginRight: 1, flex: 1, height: 150 };
    imageProps.resizeMode = "cover";
  } else {
    imageProps.style.objectFit = "cover";
  }

  return (
    <FSListItem data={item} index={index} onClick={onClick} isSelectionMode={false}>
      {({ onClick }) => {
        return (
          <View
            style={{ marginTop: 1, marginBottom: 1, ...additionViewStyle, backgroundSize: "cover" }}
            onClick={onClick}
            activeOpacity={0.8}
          >
            <DecryptedImage {...getCoverImageDecryptionProps(item, origin)} {...imageProps} />
            <View
              style={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  flex: 1,
                  paddingLeft: 8,
                  paddingRight: 8,
                  justifyContent: "center",
                  alignItems: "stretch",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: "absolute"
                }}
              >
                <View>
                  <Text
                    style={{
                      paddingLeft: 22,
                      paddingRight: 22,
                      color: highlightColor,
                      ...h20_22,
                      textAlign: "center",
                      numberOfLines: 1,
                      whiteSpace: "pre"
                    }}
                  >
                    {item.name}
                  </Text>
                </View>
                {dateLabel && (
                  <View>
                    <Text style={{ paddingTop: 4, color: highlightColor, ...h3, textAlign: "center" }}>
                      {dateLabel}
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  height: 36,
                  paddingLeft: 4,
                  paddingRight: 4,
                  alignItems: "center",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: "absolute",
                  flexDirection: "row"
                }}
              >
                <View style={{ flex: 1, paddingLeft: 4, paddingRight: 4, flexDirection: "row" }}>
                  <Text style={{ color: highlightColor, ...h2, numberOfLines: 1 }}>{itemCount}</Text>
                  <Text style={{ color: highlightColor, ...h2, numberOfLines: 1, paddingLeft: 2 }}>
                    {I18N.t("items")}
                  </Text>
                  {origin === "album" && (
                    <>
                      <Text style={{ color: highlightColor, ...h2, numberOfLines: 1, paddingLeft: 5, paddingRight: 5 }}>
                        |
                      </Text>
                      <Text style={{ color: highlightColor, ...h2, numberOfLines: 1, flex: 1 }}>
                        {getModeText(item)}
                      </Text>
                    </>
                  )}
                </View>
                <View>
                  <AlbumSlideShow
                    style={{ paddingTop: 9, paddingBottom: 9, paddingLeft: 10, paddingRight: 10 }}
                    data={item}
                    origin={origin}
                  />
                </View>
                {origin !== "places" && origin !== "deviceFolder" && (
                  <FSRowAction
                    actions={CollectionActions}
                    action={origin === "album" ? albumCardRowActions : vaultAlbumCardRowActions}
                    data={item}
                    activeMQ={activeMQ}
                  >
                    <View style={iconMenuContainerStyle}>
                      <Image source={albumCardIconMenu} />
                    </View>
                  </FSRowAction>
                )}
              </View>
            </View>
          </View>
        );
      }}
    </FSListItem>
  );
};

const AlbumCardSMRender = commonSMCardRender("album");
const PlaceCardSMRender = commonSMCardRender("places");
const DeviceFolderCardSMRender = commonSMCardRender("deviceFolder");
const VaultAlbumCardSMRender = commonSMCardRender("vaultAlbum");

const commonCardSMListProps = {
  style: { flex: 1 },
  contentContainerStyle: listContentContainerStyle,
  getItemLayout: (data, index) => {
    return { length: albumCardHeight, offset: index * albumCardHeight + 2, index };
  }
};

const AlbumCardSMListProps = {
  ...commonCardSMListProps,
  renderItem: props => <AlbumCardSMRender {...props} />
};

const PlaceCardSMListProps = {
  ...commonCardSMListProps,
  renderItem: props => <PlaceCardSMRender {...props} />
};

const VaultAlbumCardSMListProps = {
  ...commonCardSMListProps,
  renderItem: props => <VaultAlbumCardSMRender {...props} />
};

const getCollectionCardMD = origin =>
  commonLayout({
    primaryAction:
      origin === "album" ? "albumDetailLink" : origin === "vaultAlbum" ? "vaultAlbumDetailLink" : "placeDetailLink",
    MD: {
      viewStyle: { height: 195, borderRadius: 4, margin: 2, cursor: "pointer" },
      render: [
        {
          viewStyle: { flex: 1 },
          value: {
            render: [
              {
                value: ({ data }) => {
                  return {
                    render: (
                      <DecryptedImage
                        {...getCoverImageDecryptionProps(data, origin)}
                        style={{ height: 195, objectFit: "cover" }}
                      />
                    )
                  };
                }
              },
              {
                viewStyle: {
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: "absolute",
                  backgroundColor: "rgba(0, 0, 0, 0.6)"
                },
                render: [
                  {
                    viewStyle: {
                      flex: 1,
                      paddingLeft: 8,
                      paddingRight: 8,
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      position: "absolute",
                      alignItems: "stretch",
                      justifyContent: "center"
                    },
                    render: [
                      {
                        viewStyle: { paddingLeft: 12, paddingRight: 12 },
                        textStyle: {
                          textAlign: "center",
                          color: highlightColor,
                          ...h20_22,
                          numberOfLines: 1,
                          whiteSpace: "pre"
                        },
                        value: { field: "name" }
                      },
                      origin !== "places" && {
                        viewStyle: { paddingTop: 8 },
                        textStyle: { color: highlightColor, ...h16_l, textAlign: "center" },
                        value: ({ data }) => {
                          return {
                            text: getAlbumDateLabel(data)
                          };
                        }
                      }
                    ]
                  },
                  {
                    viewStyle: {
                      height: 40,
                      paddingLeft: 8,
                      paddingRight: 4,
                      alignItems: "center",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      position: "absolute"
                    },
                    textStyle: { color: highlightColor, ...h9, numberOfLines: 1 },
                    direction: "row",
                    render: [
                      {
                        direction: "row",
                        viewStyle: { flex: 1, paddingLeft: 4, paddingRight: 4 },
                        separator: origin === "album" ? "|" : void 0,
                        render: [
                          {
                            direction: "row",
                            render: [
                              {
                                value: ({ data }) => {
                                  return { text: numberFormatter(data.itemCount, "0.0i") };
                                }
                              },
                              { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                            ]
                          },
                          origin === "album" && {
                            width: "1fr",
                            value: ({ data }) => {
                              return { text: getModeText(data) };
                            }
                          }
                        ]
                      },
                      {
                        viewStyle: origin !== "album" && { paddingRight: 10 },
                        value: {
                          type: "albumSlideShow",
                          origin
                        }
                      },
                      origin !== "places" && {
                        viewStyle: {
                          height: 32,
                          width: 32,
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: 4,
                          paddingRight: 4,
                          cursor: "pointer"
                        },
                        value: {
                          image: "iconMenuWhite",
                          action:
                            origin === "album"
                              ? [
                                  "manageCollabrationWithGreyImage",
                                  "makePrivateAlbumWithGreyImage",
                                  "shareWithGreyImage",
                                  "addItemsToAlbumFormWithGreyImage",
                                  "setCoverWithGreyImage",
                                  "renameAlbumWithGreyImage",
                                  "markDeleteAlbumWithGreyImage"
                                ]
                              : [
                                  "addItemsToVaultAlbumFormWithGreyImage",
                                  { ...CollectionActions.setCoverWithGreyImage, origin: "vault" },
                                  "renameAlbumWithGreyImage",
                                  "markDeleteAlbumWithGreyImage"
                                ]
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  });

const AlbumCard = getCollectionCardMD("album");

const PlaceCard = getCollectionCardMD("places");

const VaultAlbumCard = getCollectionCardMD("vaultAlbum");

const SelectAlbumCard = commonLayout({
  primaryAction: "addItemsSelectCollection",
  SM: {
    render: [
      {
        direction: "row",
        viewStyle: {
          height: 74,
          alignItems: "center"
        },
        render: [
          {
            viewStyle: { paddingLeft: 12, paddingRight: 16 },
            value: {
              imageField: "coverImage.thumbnail_url",
              componentProps: {
                renderImage: ({ data, source, imageStyle }) => {
                  let imageProps = {
                    source,
                    style: imageStyle
                  };
                  if (Platform.OS !== "web") {
                    imageProps.resizeMode = "cover";
                  }
                  return <DecryptedImage {...getCoverImageDecryptionProps(data)} {...imageProps} />;
                }
              },
              type: "avatarBox"
            }
          },
          {
            width: "1fr",
            render: [
              {
                viewStyle: { paddingRight: 12 },
                textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1 },
                value: { field: "name" }
              },
              {
                direction: "row",
                textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                viewStyle: { paddingTop: 4 },
                separator: "|",
                render: [
                  {
                    direction: "row",
                    render: [
                      {
                        value: ({ data }) => {
                          return { text: numberFormatter(data.itemCount, "0.0i") };
                        }
                      },
                      { viewStyle: { paddingLeft: 2 }, text: () => I18N.t("items") }
                    ]
                  },
                  {
                    width: "1fr",
                    value: ({ data }) => {
                      return { text: getModeText(data) };
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
});

const SelectAlbumHeader = data => {
  if (!data || !data.length) {
    return null;
  }
  return (
    <Box
      viewStyle={{
        paddingTop: 12,
        paddingBottom: 7,
        paddingLeft: 12,
        paddingRight: 12
      }}
      textStyle={{
        ...h9_20,
        color: brownGrey
      }}
      text={I18N.t("addToAlbumMessage")}
    />
  );
};

export {
  AlbumCard,
  AlbumCardSMListProps,
  SelectAlbumCard,
  SelectAlbumHeader,
  AlbumCardSMRender,
  PlaceCard,
  PlaceCardSMListProps,
  PlaceCardSMRender,
  DeviceFolderCardSMRender,
  VaultAlbumCard,
  VaultAlbumCardSMRender,
  VaultAlbumCardSMListProps
};
