import React from "react";
import {
  List,
  TableHeaderRow as TableHeaderRowHoc,
  TableRow as TableRowHoc,
  Card as CardHoc,
  ScrollNotifier as ScrollNotifierHoc,
  TableBody as TableBodyHoc,
  TableGridBody as TableGridBodyHoc,
  InfiniteScrollList as InfiniteScrollListHoc,
  Table as TableHoc
} from "table";
import {
  SelectionCheckBox as SelectionCheckBoxHoc,
  HeaderSelectionBox as HeaderSelectionBoxHoc,
  SelectionBox as SelectionBoxHoc
} from "../../selectionbox";
import { Checked as CheckedHoc } from "react-components";

export default ({
  theme = {},
  Box,
  View,
  withContext,
  ScrollContext,
  resolveValue,
  resolveVisibleExp,
  resolveField,
  resolveColumn,
  getAggregateValue,
  separateNumber,
  renderProps,
  getTheme,
  dataFormat,
  resolveMQ,
  getImage,
  getAction,
  getCardComponent,
  Swipeout,
  Modal,
  getComponent
}) => {
  const ScrollNotifier = ScrollNotifierHoc({ renderProps });

  const InfiniteScrollList = withContext(
    InfiniteScrollListHoc({ getList: getComponent, pageSize: 20, ScrollContext }),
    {
      addScrollListener: "ScrollView.addScrollListener"
    }
  );

  const RowSelectionBox = withContext(
    SelectionBoxHoc({
      Component: SelectionCheckBoxHoc({
        CheckBox: CheckedHoc({ Box, theme: theme.checkedStyle }),
        Box,
        resolveTheme: getTheme,
        theme: theme.tableRowSelectionBoxStyle,
        checkedTheme: theme.checkedStyle
      })
    })
  );

  // const SelectAllBox = withContext(SelectAllBoxHoc({ Box, resolveMQ, Modal, theme: theme.selectAllBoxStyle }), {
  //   isAllSelected: "SelectionStore.isAllSelected",
  //   selectAll: "SelectionStore.selectAll",
  //   unSelectAll: "SelectionStore.unSelectAll",
  //   selectedIds: "SelectionStore.selectedIds",
  //   selectAllPage: "SelectionStore.selectAllPage",
  //   allPageSelected: "SelectionStore.allPageSelected",
  //   hasNext: "Screen.Connect.hasNext",
  //   totalCount: "Screen.Connect.aggregates._count",
  //   activeMQ: "ActiveMQ"
  // });

  const HeaderSelectionBox = withContext(
    HeaderSelectionBoxHoc({
      Box,
      resolveMQ,
      Modal,
      getComponent: getCardComponent,
      Component: SelectionCheckBoxHoc({
        CheckBox: CheckedHoc({ Box, theme: theme.checkedStyle }),
        Box
      }),
      theme: theme.tableHeaderSelectionBoxStyle
    }),
    {
      isAllSelected: "SelectionStore.isAllSelected",
      selectAll: "SelectionStore.selectAll",
      unSelectAll: "SelectionStore.unSelectAll",
      activeMQ: "ActiveMQ"
    }
  );

  const HeaderRowAction = () => <Box {...theme.tableHeaderRowStyle.rowActionStyle} />;

  const Card = withContext(
    CardHoc({
      Box,
      Swipeout,
      getTheme,
      getImage,
      getAction,
      resolveMQ,
      getComponent: getCardComponent,
      dataFormat,
      resolveField,
      resolveValue,
      resolveVisibleExp
    }),
    {
      getImageUrl: "App.getImageUrl"
    }
  );

  const tableRowProps = {
    Card,
    View,
    resolveColumn,
    resolveMQ,
    getTheme,
    resolveVisibleExp,
    minWidthFrMD: theme.minWidthFrMD,
    minWidthFrSM: theme.minWidthFrSM
  };

  const HeaderRow = withContext(
    TableHeaderRowHoc({
      ...tableRowProps,
      getAggregateValue,
      dataFormat,
      typeFormat: theme.typeFormat,
      typeStyle: theme.typeStyle,
      themeSM: theme.tableHeaderRowStyle,
      themeMD: theme.tableHeaderRowStyleMD
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const DataRow = withContext(
    TableRowHoc({
      ...tableRowProps,
      separateNumber,
      typeStyle: theme.typeStyle,
      themeSM: theme.tableRowStyle,
      themeMD: theme.tableRowStyleMD
    }),
    {
      isSelected: "SelectionStore.isSelected",
      selectRow: "SelectionStore.selectRow",
      selectionMode: "SelectionStore.selectionMode",
      activeMQ: "ActiveMQ"
    }
  );

  const DataGridRow = withContext(
    TableRowHoc({
      Card,
      resolveColumn,
      separateNumber,
      resolveVisibleExp,
      resolveMQ,
      typeStyle: theme.typeStyle,
      theme: theme.tableRowStyle
    }),
    { activeMQ: "ActiveMQ" }
  );

  const TableBody = withContext(
    TableBodyHoc({
      List: InfiniteScrollList,
      DataRow,
      bodyStyle: theme.tableBodyStyle,
      resolveMQ,
      getTheme,
      horizontalScrollEnable: true //pass props to indicate the list may be enable horizontal scroll
    }),
    {
      activeMQ: "ActiveMQ",
      invoke: "Screen.Connect.invoke",
      _draggedId: "SelectionStore._draggedId",
      selectedIds: "SelectionStore.selectedIds",
      removeDraggedRow: "SelectionStore.removeDraggedRow"
    }
  );

  const Table = withContext(
    TableHoc({
      View,
      resolveMQ,
      ScrollNotifier,
      HeaderRow,
      Body: TableBody,
      style: { flex: 1, flexDirection: "column" }
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const GridTable = withContext(
    TableGridBodyHoc({
      Box,
      resolveMQ,
      List: InfiniteScrollList,
      DataRow: DataGridRow,
      bodyStyle: theme.tableBodyStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  return {
    RowSelectionBox,
    HeaderSelectionBox,
    HeaderRowAction,
    Table,
    GridTable,
    Card,
    List
  };
};
