import React from "react";

export default ({ Box, ScreenContextProvider, resolveActions, resolveMQ, theme = {} }) => ({
  screenState,
  activeMQ,
  actions,
  index
}) => {
  const resolvedActions = resolveActions && resolveActions({ actions, activeMQ, index });
  if (!resolvedActions || !resolvedActions.length) {
    return null;
  }
  const { boxStyle } = resolveMQ(theme, ["boxStyle"], activeMQ);
  return (
    <ScreenContextProvider state={screenState}>
      <Box {...boxStyle} render={[...resolvedActions]} />
    </ScreenContextProvider>
  );
};
