import React from "react";
import {
  SimpleTextInput as SimpleTextInputHoc,
  SimpleTextAreaInput as SimpleTextAreaInputHoc,
  SimpleFileInput as SimpleFileInputHoc,
  TextInput as TextInputHoc,
  Checked as CheckedHoc,
  LinkBox as LinkBoxHoc
} from "react-components";
import TextBoxHoc from "react-components/lib/FieldBox/TextBox";
import MultiTextBoxHoc from "react-components/lib/FieldBox/MultiTextBox";

import { dateFormatter } from "client-utility/lib/DateUtility";
import {
  FileBox as FileBoxHoc,
  MultiFileBox as MultiFileBoxHoc,
  MultiImageViewer as MultiImageViewerHoc,
  FileInput as FileInputHoc,
  FileRender as FileRenderHoc,
  ImageRender as ImageRenderHoc,
  DocumentDetail as DocumentDetailHoc,
  DocumentSlider as DocumentSliderHoc,
  DocumentViewer as DocumentViewerHoc,
  FileRenderBox as FileRenderBoxHoc,
  ImageRenderBox as ImageRenderBoxHoc,
  NetworkImageRender as NetworkImageRenderHoc,
  FileAndImageRender as FileAndImageRenderHoc,
  FileInputBox as FileInputBoxHoc
} from "./file-box";

import RenderSuggestionLabelHoc from "./RenderSuggestionLabel";
import IssueNetworkImageRenderHoc from "./IssueNetworkImageRender";
import { FieldRender } from "table";
import { checkImage, isImage } from "client-utility/lib/FileUtility";
import ViewAR from "./file-box/lib/ViewAR";
import { OtpInput as OtpInputHoc } from "otp-input";
import PhoneNumberBoxHoc from "./PhoneNumberBox";

export default ({
  theme,
  Box,
  View,
  withContext,
  Modal,
  Keyboard,
  Platform,
  resolveValue,
  iterator,
  renderChildren,
  resolveMQ,
  getImage,
  getPlaceholder,
  StatusBar,
  getComponent,
  getInput,
  showMessage,
  I18N
}) => {
  const SimpleTextInput = SimpleTextInputHoc({ defaultProps: theme.defaultTextProps });
  const SimpleNumberInput = SimpleTextInputHoc({ defaultProps: theme.defaultNumberProps });
  const SimplePasswordInput = SimpleTextInputHoc({ defaultProps: theme.defaultPasswordProps });
  const SimpleTextAreaInput = SimpleTextAreaInputHoc({ defaultProps: theme.defaultTextProps });
  const SimpleFileInput = SimpleFileInputHoc({ defaultProps: theme.defaultFileProps });

  const TextInput = withContext(
    TextInputHoc({ Input: SimpleTextInput, themeSM: theme.inputStyleSM, themeMD: theme.inputStyleMD, resolveMQ }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const OtpInput = OtpInputHoc({ Box, TextInput, Platform, Keyboard });

  const TextAreaInput = withContext(
    TextInputHoc({ Input: SimpleTextAreaInput, themeSM: theme.inputStyleSM, themeMD: theme.inputStyleMD, resolveMQ }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const PasswordInput = withContext(
    TextInputHoc({ Input: SimplePasswordInput, themeSM: theme.inputStyleSM, themeMD: theme.inputStyleMD, resolveMQ }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const NumberInput = withContext(
    TextInputHoc({
      Input: SimpleNumberInput,
      themeSM: theme.inputStyleSM,
      themeMD: theme.inputStyleMD,
      resolveMQ,
      typecast: value => {
        if (typeof value == "string") {
          value = value.trim();
          if (value.length) {
            value = Number(value);
          }
        }
        return value;
      }
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const Checked = CheckedHoc({ Box, theme: theme.checkedStyle });

  const NetworkImageRender = withContext(NetworkImageRenderHoc({ Box, View, theme: theme.networkImageRenderStyle }), {
    getImageUrl: "App.getImageUrl"
  });

  const FileAndImageRender = withContext(
    FileAndImageRenderHoc({
      Box,
      ImageRender: NetworkImageRender,
      isImage,
      theme: theme.fileAndImageRenderStyle
    }),
    {
      getImageUrl: "App.getImageUrl"
    }
  );

  const DocumentDetail = withContext(DocumentDetailHoc({ Box, resolveMQ, theme: theme.documentDetailStyle }), {
    activeMQ: "ActiveMQ"
  });

  const DocumentSlider = withContext(
    DocumentSliderHoc({
      Box,
      resolveMQ,
      resolveValue,
      renderChildren,
      NetworkImageRender: FileAndImageRender,
      dateFormatter,
      DetailComponent: DocumentDetail,
      theme: theme.documentSliderStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const ImageRenderCom = withContext(
    ImageRenderHoc({ Box, theme: theme.fileAndImageRenderStyle.imageRenderStyle, I18N }),
    {
      getImageUrl: "App.getImageUrl"
    }
  );

  const DocumentViewer = withContext(
    DocumentViewerHoc({
      Box,
      resolveMQ,
      renderChildren,
      Platform,
      ImageRender: props => {
        const { value = {} } = props;
        let Component = ImageRenderCom;
        let componentProps = {};
        if (value.type) {
          if (value.type === "video") {
            Component = getComponent("decryptedVideo");
            componentProps = {
              key: value.converted_mp4_url || value.resource_url,
              onError: () => {
                showMessage(I18N.t("videoLoadError"), 3000);
              }
            };
          } else if (value.type === "doc") {
            componentProps = {
              renderNoData: () => ({ image: getImage("noPreviewDocIcon") })
            };
          }
        }
        return <Component {...props} {...componentProps} />;
      },
      theme: theme.documentViewerStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const ImageRender = withContext(ImageRenderHoc({ Box, theme: theme.imageRenderStyle }), {
    getImageUrl: "App.getImageUrl"
  });

  const FileRender = FileRenderHoc({ Box, isImage, ImageComponent: ImageRender });

  const FileRenderBox = withContext(FileRenderBoxHoc({ Box, resolveMQ, theme: theme.fileRenderBoxStyle }), {
    activeMQ: "ActiveMQ"
  });

  const ImageRenderBox = ImageRenderBoxHoc({ Box, ImageRender, theme: theme.imageRenderBoxStyle });

  const FileInputBox = withContext(
    FileInputBoxHoc({
      Box,
      resolveMQ,
      Input: TextInputHoc({
        Input: SimpleFileInput,
        theme: theme.fileTextInputStyle
      }),
      theme: theme.fileInputStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const FileInput = withContext(
    FileInputHoc({ StatusBar, View, iterator, Component: FileInputBox, showMessage, I18N }),
    { upload: "App.upload" }
  );
  const ImageInput = withContext(
    FileInputHoc({
      StatusBar,
      View,
      iterator,
      showMessage,
      I18N,
      Component: FileInputBox,
      validateValue: checkImage,
      inputProps: {
        accept: "image/*"
      }
    }),
    {
      upload: "App.upload"
    }
  );

  const basicInputProps = {
    Box,
    resolveValue,
    getPlaceholder,
    getInput,
    inputType: "text"
  };

  const TextBox = TextBoxHoc({ ...basicInputProps });
  const TextAreaBox = TextBoxHoc({ ...basicInputProps, inputType: "textArea" });
  const PasswordBox = TextBoxHoc({ ...basicInputProps, inputType: "password" });
  const NumberBox = TextBoxHoc({ ...basicInputProps, inputType: "number" });

  const CheckBox = TextBoxHoc({ ...basicInputProps, inputType: "boolean" });

  const MultiTextBox = MultiTextBoxHoc({
    ...basicInputProps,
    inputType: "text",
    renderLabel: RenderSuggestionLabelHoc({
      Box,
      theme: theme.renderSuggestionLabelStyle
    }),
    theme: theme.textBoxMultipleStyle
  });

  const PhoneNumberBox = withContext(
    PhoneNumberBoxHoc({ ...basicInputProps, theme: theme.phoneNumberBoxStyle, resolveMQ, Platform }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const FileBox = FileBoxHoc({
    ...basicInputProps,
    inputType: "file",
    ValueComponent: FileRenderBox,
    theme: theme.fileBoxStyle
  });

  const ImageBox = FileBoxHoc({
    ...basicInputProps,
    inputType: "image",
    ValueComponent: ImageRenderBox,
    theme: theme.imageBoxStyle
  });

  const MultiImageViewer = withContext(
    MultiImageViewerHoc({
      Box,
      resolveValue,
      Modal,
      ViewComponent: DocumentViewer,
      ImageComponent: MultiFileBoxHoc({
        ...basicInputProps,
        inputType: "file",
        ValueComponent: ImageRenderBoxHoc({
          Box,
          ImageRender: FileAndImageRender,
          theme: theme.imageRenderBoxStyleForFileRender
        }),
        theme: theme.imageBoxStyle
      }),
      isImage,
      theme: theme.multiImageViewerStyle
    }),
    {
      getImageUrl: "App.getImageUrl"
    }
  );

  const IssuesFileAndImageRender = IssueNetworkImageRenderHoc({ Box, isImage, View, theme: theme, I18N });

  const IssuesMultiImageViewer = withContext(
    MultiImageViewerHoc({
      Box,
      resolveValue,
      Modal,
      ViewComponent: DocumentViewer,
      ImageComponent: MultiFileBoxHoc({
        ...basicInputProps,
        inputType: "file",
        ValueComponent: ImageRenderBoxHoc({
          Box,
          ImageRender: IssuesFileAndImageRender,
          theme: theme.imageRenderBoxStyleForFileRender
        }),
        theme: theme.imageBoxStyle
      }),
      isImage,
      theme: theme.multiImageViewerStyle
    }),
    {
      getImageUrl: "App.getImageUrl"
    }
  );

  const LinkBox = withContext(LinkBoxHoc({ Box, resolveValue, resolveMQ, theme: theme.linkBoxStyle }), {
    activeMQ: "ActiveMQ"
  });

  const ImageViewAR = ViewAR({
    Box,
    Component: FieldRender({
      resolveValue,
      Component: FileAndImageRender,
      theme: theme.fileAdndImageRenderStyleForDocumentSlider
    })
  });

  return {
    SimpleFileInput,
    TextInput,
    TextAreaInput,
    PasswordInput,
    NumberInput,
    Checked,
    FileInput,
    ImageInput,
    TextBox,
    TextAreaBox,
    PasswordBox,
    NumberBox,
    CheckBox,
    FileBox,
    ImageBox,
    MultiImageViewer,
    NetworkImageRender,
    FileAndImageRender,
    ImageRender,
    FileRender,
    PhoneNumberBox,
    LinkBox,
    MultiTextBox,
    ImageViewAR,
    DocumentViewer,
    OtpInput,
    IssuesMultiImageViewer,
    DocumentSlider
  };
};
