import React from "react";
import { I18N, withContext } from "../../../../FsCloudComponent";
import AddContactForm from "./AddContactForm";
import { contactDetailQuery } from "../../queries";

class AddContactSM extends React.Component {
  onClose = () => {
    let { deleteUri, url, link } = this.props;
    deleteUri && deleteUri(url, link);
  };

  render() {
    let { data, setValue, updatableData, validationData, isNew } = this.props;
    if (Array.isArray(data)) {
      data = data[0];
    }
    return (
      <AddContactForm
        isNew={isNew}
        data={data}
        setValue={setValue}
        updatableData={updatableData}
        validationData={validationData}
        swipeable
        buttonText="add"
        onClose={this.onClose}
      />
    );
  }
}
AddContactSM = withContext(AddContactSM, {
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri",
  data: "Screen.Connect.data",
  setValue: "Screen.Connect.setValue",
  updatableData: "Screen.Connect.updatableData",
  validationData: "Screen.Connect.validationData",
  link: "ScreenRoute.link"
});

export default {
  AddContactSM: {
    component: "custom",
    title: () => I18N.t("addToContact"),
    Component: () => <AddContactSM isNew />,
    connectProps: {
      data: {},
      dataMode: "insert",
      updatable: true
    }
  },
  EditContactSM: {
    component: "custom",
    title: () => I18N.t("editContact"),
    ...contactDetailQuery({
      updatable: true
    }),
    Component: AddContactSM
  }
};
