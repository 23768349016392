import GroupTab from "./GroupTab";
import GroupMembers from "./GroupMembers";
import InviteMember from "./InviteMember";
import JoinGroup from "./JoinGroup";

export default {
  ...GroupTab,
  ...GroupMembers,
  ...InviteMember,
  ...JoinGroup
};
