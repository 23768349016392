import React from "react";
import { cloneDeep } from "lodash";
import { DeleteAction as DeleteActionHoc, CoreAction as CoreActionHoc } from "./actions";
import AnchorActionHoc from "./anchorAction";
import { setGallerySort } from "../Constants";

export default (
  { theme = {}, withContext, getNewId, getComponent, RowAction },
  { clearCache, cancelItem, cancelQueuedItem, cancelAllItems, saveAsPdfNative, invokeUploadFromCamera }
) => {
  const DeleteAction = DeleteActionHoc({ getNewId, theme: theme.deleteActionStyle });

  const SortingCreatedOnAction = withContext(
    CoreActionHoc({
      onClick: ({ props }) => {
        let { setParams } = props;
        const sortValue = setGallerySort("createdOn");
        setParams({ type: "sort", value: sortValue });
      }
    }),
    {
      setParams: "Screen.Connect.setParams"
    }
  );

  const SortingLastModifiedAction = withContext(
    CoreActionHoc({
      onClick: ({ props }) => {
        let { setParams } = props;
        const sortValue = setGallerySort("lastModified");
        setParams({ type: "sort", value: sortValue });
      }
    }),
    {
      setParams: "Screen.Connect.setParams"
    }
  );

  const SortingSizeAction = withContext(
    CoreActionHoc({
      onClick: ({ props }) => {
        let { setParams } = props;
        const sortValue = setGallerySort("size");
        setParams({ type: "sort", value: sortValue });
      }
    }),
    {
      setParams: "Screen.Connect.setParams"
    }
  );

  class CollectionDetailHeaderAction extends React.Component {
    render() {
      let {
        action: { selectionRequired = true, ...restActionProps },
        children,
        data,
        link,
        ...restProps
      } = this.props;
      let linkParams = link && link.props && link.props.params;
      if (linkParams && linkParams.collection) {
        data = linkParams.collection;
      }
      return (
        <RowAction
          source="Header"
          selectionRequired={selectionRequired}
          data={data}
          {...restActionProps}
          {...restProps}
          theme={{
            ...theme.rowActionStyle,
            ...theme.headerActionStyle
          }}
        />
      );
    }
  }

  CollectionDetailHeaderAction = withContext(CollectionDetailHeaderAction, {
    link: "ScreenRoute.link"
  });

  const AnchorAction = AnchorActionHoc({ getComponent });

  const Edit = withContext(
    CoreActionHoc({
      onClick: ({ props }) => {
        let { Screen: { setComponentState, Connect: { data } = {} } = {} } = props;
        setComponentState && setComponentState({ editMode: true, oldData: cloneDeep(data) });
      }
    }),
    {
      Screen: "Screen"
    }
  );

  const NonEditMode = withContext(
    CoreActionHoc({
      onClick: ({ props }) => {
        let {
          Screen: {
            setComponentState,
            Component: { oldData } = {},
            Connect: { setState, updatableData: { resetUpdates } = {} } = {}
          } = {}
        } = props;
        setComponentState && setComponentState({ editMode: false, oldData: void 0 });
        if (oldData) {
          setState && setState({ data: oldData });
          resetUpdates && resetUpdates();
        }
      }
    }),
    {
      Screen: "Screen"
    }
  );

  const ClearCache = CoreActionHoc({
    onClick: () => {
      clearCache && clearCache();
    }
  });

  const CancelItem = CoreActionHoc({ onClick: cancelItem });

  const CancelAllItems = CoreActionHoc({ onClick: cancelAllItems });

  const CancelQueuedItem = withContext(CoreActionHoc({ onClick: cancelQueuedItem }), {
    selectedIds: "Screen.SelectionStore.selectedIds",
    unSelectAll: "Screen.SelectionStore.unSelectAll"
  });

  const SaveAsPdf = withContext(CoreActionHoc({ onClick: saveAsPdfNative }), {
    getSelectedData: "SelectionStore.getSelectedData",
    unSelectAll: "SelectionStore.unSelectAll"
  });
  const OpenCameraAndUpload = CoreActionHoc({ onClick: invokeUploadFromCamera });

  return {
    DeleteAction,
    SortingCreatedOnAction,
    SortingLastModifiedAction,
    SortingSizeAction,
    CollectionDetailHeaderAction,
    AnchorAction,
    Edit,
    NonEditMode,
    ClearCache,
    CancelItem,
    CancelAllItems,
    CancelQueuedItem,
    SaveAsPdf,
    OpenCameraAndUpload
  };
};
