import React from "react";
import {
  Box,
  I18N,
  theme,
  getImage,
  withContext,
  RecyclerList,
  shallowCompare,
  fetchAssetForFolderRecursively,
  getSortedImages,
  populateUriInfo
} from "../../../FsCloudComponent";
import { getTimeStringFromSeconds } from "client-utility/lib/DateUtility";
import { GalleryFolderAssetsHeader } from "./GalleryFolderHeader";
import { UploadGalleryNoData } from "../noData/NoData";

const { fonts, colors, gradients } = theme;
const { h9_20, h2 } = fonts;
const { highlightColor, brownGrey } = colors;
const { bottomBlackGradient } = gradients;

class GalleryFolderAssetList extends React.Component {
  render() {
    let { listProps, rowData, ...rest } = this.props;
    return (
      <Box
        viewStyle={{ flex: 1 }}
        render={[
          {
            viewStyle: { paddingTop: 12, paddingBottom: 6, paddingRight: 10, paddingLeft: 10 },
            textStyle: {
              color: brownGrey,
              ...h9_20
            },
            text: I18N.t("itemUploadHeaderText")
          },
          {
            viewStyle: { flex: 1 },
            render: <RecyclerList extraData={rowData} {...listProps} {...rest} />
          }
        ]}
      />
    );
  }
}
class GalleryFolderAssets extends React.Component {
  loadingMore = true;
  images = {};

  afterFetchData = (pageInfo = {}) => {
    if (this._unmounted) {
      pageInfo.has_next_page = false;
      return;
    }
    let { connectSetState } = this.props;
    let data = getSortedImages(Object.values(this.images));
    this.loadingMore = pageInfo.has_next_page;
    connectSetState &&
      connectSetState({
        data
      });
  };

  fetchAssets = async () => {
    if (this._unmounted) {
      return;
    }
    let { link: { props: { filter: { groupId, groupName } } = {} } = {} } = this.props;
    await fetchAssetForFolderRecursively(
      {
        groupId,
        groupName,
        images: this.images
      },
      {
        limit: 1000,
        afterFetch: this.afterFetchData
      }
    );
    populateUriInfo && populateUriInfo(Object.values(this.images));
  };

  shouldComponentUpdate(nextProps) {
    let isEqual = shallowCompare(nextProps, this.props, {
      matchProps: { data: 1, language: 1, orientation: 1, selectedIds: 1, screenWidth: 1, columnsPerRow: 1 }
    });
    return !isEqual;
  }

  componentDidMount() {
    this.fetchAssets();
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  onClose = () => {
    let { deleteUri, url, link } = this.props;
    deleteUri && deleteUri(url, link);
  };

  render() {
    let { data, selectedIds, unSelectAll, link: { secureType } = {} } = this.props;
    let component =
      data && data.length ? (
        <GalleryFolderAssetList {...this.props} />
      ) : (
        (!this.loadingMore && <UploadGalleryNoData />) || void 0
      );
    return (
      <Box
        viewStyle={{ flex: 1 }}
        render={[
          <GalleryFolderAssetsHeader
            data={data}
            onClose={this.onClose}
            loadingMore={this.loadingMore}
            selectedIds={selectedIds}
            unSelectAll={unSelectAll}
            secureType={secureType}
          />,
          {
            viewStyle: { flex: 1 },
            render: component
          }
        ]}
      />
    );
  }
}

GalleryFolderAssets = withContext(GalleryFolderAssets, {
  url: "ScreenRoute.url",
  link: "ScreenRoute.link",
  deleteUri: "Router.deleteUri",
  screenWidth: "App.screenWidth",
  selectedIds: "Screen.SelectionStore.selectedIds",
  unSelectAll: "Screen.SelectionStore.unSelectAll",
  connectSetState: "Screen.Connect.setState"
});

export default {
  GalleryFolderAssets: {
    component: "table",
    Component: GalleryFolderAssets,
    hideNavHeader: true,
    connectProps: {
      data: []
    },
    selectionMode: true,
    panelProps: {
      showHeader: false
    },
    listProps: {
      imageField: "node.image.uri",
      imageHeight: "node.image.orientedHeight",
      imageWidth: "node.image.orientedWidth",
      indicator_field: "resource_lastModified",
      rowProps: {
        imageField: "node.image.uri",
        fields: {
          resource_name: "node.image.fileName",
          resource_size: "node.image.size",
          height: "node.image.orientedHeight",
          width: "node.image.orientedWidth",
          lastModified: "node.timestamp"
        },
        lastModifiedUnit: "seconds",
        imagePrefix: "thumbnail",
        renderHeader: ({ data }) => {
          let image = data && data.node && data.node.image;
          if (!image || image.playableDuration === undefined) {
            return null;
          }
          return {
            viewStyle: {
              position: "absolute",
              top: 1,
              left: 1,
              right: 1,
              bottom: 1,
              zIndex: 1,
              justifyContent: "flex-end"
            },
            render: [
              {
                gradient: {
                  ...bottomBlackGradient
                },
                gradientStyle: {
                  padding: 6
                },
                direction: "row",
                textStyle: { ...h2, color: highlightColor, numberOfLines: 1 },
                render: [
                  {
                    width: "1fr",
                    image: getImage("videoBottomIcon"),
                    imageStyle: {
                      height: 9,
                      width: 15
                    },
                    viewStyle: {
                      justifyContent: "center"
                    }
                  },
                  { text: getTimeStringFromSeconds(image.playableDuration) }
                ]
              }
            ]
          };
        }
      }
    }
  }
};
