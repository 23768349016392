import React from "react";
import {
  Box,
  Modal,
  withContext,
  showMessage,
  theme,
  I18N,
  getImage,
  LoginEditor as Editor,
  LoginButton as Button,
  LoginContainer as Container,
  Platform,
  Connect,
  StatusBar as LoadingIndicator,
  setVaultMode,
  getFsTrackId,
  FSLoginApi,
  SmsReader,
  ResendOtp,
  getErrorMessage,
  ReactNativeDownload,
  DeviceSetting,
  ConfirmBox,
  AsyncStorage,
  addPrivacyConsent
} from "../../../FsCloudComponent";
import { FingerprintPopup, FingerprintScanner } from "./fingerprintScanner";

const openFingerprintSettings = () => {
  try {
    if (Platform.OS === "android") {
      ReactNativeDownload.openFingerprintSettings && ReactNativeDownload.openFingerprintSettings();
    } else if (Platform.OS === "ios") {
      DeviceSetting && DeviceSetting.open();
    }
  } catch (err) {}
};

let { fonts, colors, bgs } = theme;
let { h9, h20, h16_l, h16 } = fonts;
let { themeColor, primaryColor, highlightColor, errorColor, blackBg } = colors;
let { veryLightPink, primaryBg2 } = bgs;

const permissionModalStyle = {
  stopPropagation: true,
  position: "center",
  width: 0.85,
  animationType: "none",
  autoHide: true,
  style: {
    boxShadow: "0 0px 7px 0 rgba(0, 0, 0, 0.13)",
    shadowColor: blackBg,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    borderRadius: 4
  },
  adjustOnKeyboard: true
};

const { startListener, removeListener } = SmsReader;

const vaultPinInfo = {
  "create-pin": {
    field: "newPin",
    title: "createPinCaps",
    primaryText: "createPinMessage",
    placeholder: "createPinPlaceholder",
    submitText: "create",
    cancelText: "cancel"
  },
  "confirm-pin": {
    field: "confirmPin",
    title: "confirmPinCaps",
    primaryText: "confirmPinMessage",
    placeholder: "confirmPinPlaceholder",
    submitText: "confirm",
    cancelText: "cancel"
  },
  "enter-pin": {
    field: "pin",
    title: "enterPinCaps",
    primaryText: "enterPinMessage",
    placeholder: "enterPinPlaceholder",
    submitText: "confirm",
    cancelText: "cancel"
  },
  "forgot-pin": {
    field: "mobile",
    icon: getImage("telephoneIcon"),
    inputStyle: {},
    title: "forgotPinCaps",
    primaryText: "loginSecondaryText",
    placeholder: "mobilePlaceholder",
    submitText: "continue",
    cancelText: "cancel"
  },
  "reset-pin": {
    fields: [
      { field: "otp", label: "otpFieldLabel", placeholder: "otpPlaceholder" },
      { field: "newPin", label: "newPinLabel", placeholder: "createPinPlaceholder" },
      { field: "confirmPin", label: "confirmPinLabel", placeholder: "confirmPinPlaceholder" }
    ],
    title: "resetPinCaps",
    primaryText: "resetPinMessage",
    submitText: "continue",
    cancelText: "cancel"
  },
  "change-pin": {
    fields: [
      { field: "oldPin", label: "oldPinLabel", placeholder: "oldPinPlaceholder" },
      { field: "newPin", label: "newPinLabel", placeholder: "createPinPlaceholder" },
      { field: "confirmPin", label: "confirmPinLabel", placeholder: "confirmPinPlaceholder" }
    ],
    title: "changePinCaps",
    primaryText: "changePinMessage",
    submitText: "continue",
    cancelText: "cancel"
  }
};

let modalStyle = {
  position: "center",
  width: 486,
  style: {
    borderRadius: 4
  },
  parentStyle: {
    zIndex: 100
  },
  escEnabled: false
};

const createVaultPin = async (data, props) => {
  if (data.newPin !== data.confirmPin) {
    let error = new Error(I18N.t("user/pin-mismatch"));
    error.code = "user/pin-mismatch";
    throw error;
  }
  let { invoke, urls } = props;
  await invoke({
    service: {
      url: urls["createVaultPin"],
      uriProps: {
        data: {
          pin: data.newPin
        }
      }
    },
    allowParallelInvoke: true
  });
  showMessage && showMessage(I18N.t("pinCreatedMessage"), 2000);
};

const authenticateVaultPin = async (data, props) => {
  let { invoke, urls } = props;
  await invoke({
    service: {
      url: urls["authenticateVault"],
      uriProps: {
        data: {
          pin: data.pin
        }
      }
    },
    allowParallelInvoke: true
  });
};

const sendSMS = async data => {
  let { sendSMS } = FSLoginApi;
  if (!sendSMS) {
    return;
  }
  let params = {
    mobile: data.mobile
  };
  if (Platform.OS === "android") {
    params.smshash = await SmsReader.getHash();
  }
  return await sendSMS(params);
};

const forgotVaultPin = async props => {
  try {
    let { invoke, urls } = props;
    await invoke({
      service: {
        url: urls["forgotVaultPin"],
        uriProps: {
          data: {}
        }
      },
      allowParallelInvoke: true
    });
  } catch (err) {
    // do nothing
  }
};

const resendPincode = async transaction_id => {
  let { resendPincode } = FSLoginApi;
  if (!resendPincode) {
    return;
  }
  await resendPincode({ transaction_id });
  showMessage && showMessage(I18N.t("codeSentMessage"), 2000);
};

const updateVaultPin = async (data, props) => {
  if (data.oldPin === data.newPin) {
    let error = new Error(I18N.t("user/same-pin-error"));
    error.code = "user/same-pin-error";
    throw error;
  }
  let { invoke, urls } = props;
  await invoke({
    service: {
      url: urls["updateVaultPin"],
      uriProps: {
        data: {
          oldPin: data.oldPin,
          newPin: data.newPin
        }
      }
    },
    allowParallelInvoke: true
  });
  showMessage && showMessage(I18N.t("pinUpdatedMessage"), 2000);
};

const resetVaultPin = async (data, transaction_id, props) => {
  let { invoke, urls } = props;
  let res = await invoke({
    service: {
      url: urls["resetVaultPin"],
      uriProps: {
        data: {
          otp: data.otp,
          newPin: data.newPin,
          transaction_id,
          fsTrackId: getFsTrackId()
        }
      }
    },
    allowParallelInvoke: true
  });
  if (res && res.error) {
    throw res.error;
  }
  showMessage && showMessage(I18N.t("pinResetMessage"), 2000);
};

class AutoSMSReadWrapper extends React.Component {
  componentDidMount() {
    if (Platform.OS === "android") {
      this.startListener();
    }
  }

  onMessageReceived = message => {
    removeListener();
    if (message.includes("Timeout Error.")) {
      this.startListener();
    } else {
      let code = message.match(/\d+/) && message.match(/\d+/)[0];
      if (code) {
        let { dataProps: { data, setValue } = {}, field } = this.props;
        setValue && setValue({ data, field, value: code });
      }
    }
  };

  startListener = async () => {
    try {
      await startListener(this.onMessageReceived);
    } catch (err) {
      this.props.onError(err, "viewError");
    }
  };

  componentWillUnmount() {
    if (Platform.OS === "android") {
      removeListener();
    }
  }

  render() {
    return this.props.children;
  }
}

class PinComponent extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.clickEvents = {
      "create-pin": this.onCreateClick,
      "confirm-pin": this.onConfirmClick,
      "enter-pin": this.onEnterClick,
      "forgot-pin": this.onForgotClick,
      "reset-pin": this.onResetClick,
      "change-pin": this.onChangeClick
    };
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  onError = (err, errorField) => {
    let message = getErrorMessage(err);
    this._setState({ [errorField || "error"]: message, loading: false });
  };

  addLink = link => {
    let { addUri, getPath } = this.props;
    addUri && getPath && addUri(getPath(), link);
  };

  replaceLink = (link, uriToReplace) => {
    let { deleteUri, replaceUri, getPath } = this.props;
    let path = getPath && getPath();
    if (path) {
      if (Platform.OS === "web") {
        deleteUri && deleteUri(path, { uri: uriToReplace });
        setTimeout(_ => {
          this.addLink(link);
        }, 0);
      } else if (replaceUri) {
        let indexOf = path.indexOf(uriToReplace);
        if (indexOf >= 0) {
          link.uri = path.substring(0, indexOf) + link.uri;
        }
        replaceUri && replaceUri(link);
      }
    }
  };

  deleteLink = () => {
    let { getPath, deleteUri, link } = this.props;
    let path = getPath && getPath();
    path && deleteUri && deleteUri(path, link);
  };

  moveToVault = uriToReplace => {
    this.replaceLink({ uri: "/vault" }, uriToReplace);
    setVaultMode && setVaultMode(true);
  };

  onCreateClick = () => {
    let { data } = this.dataProps;
    this.addLink({ uri: "/confirm-vault-pin", props: { params: { newPin: data.newPin } } });
  };

  onConfirmClick = async () => {
    let { data } = this.dataProps;
    let { link: { props: { params: { newPin } = {} } = {} } = {} } = this.props;
    await createVaultPin({ newPin, confirmPin: data.confirmPin }, this.props);
    this.moveToVault("/create-vault-pin/confirm-vault-pin");
  };

  onEnterClick = async () => {
    let { data } = this.dataProps;
    await authenticateVaultPin(data, this.props);
    this.moveToVault("/enter-vault-pin");
  };

  onForgotClick = async () => {
    let { data } = this.dataProps;
    let result = await sendSMS(data);
    forgotVaultPin(this.props);
    this.replaceLink(
      { uri: "/reset-vault-pin", props: { params: { transaction_id: result.transaction_id } } },
      "/forgot-vault-pin"
    );
  };

  onResetClick = async () => {
    let { data } = this.dataProps;
    try {
      let { link: { props: { params: { transaction_id } = {} } = {} } = {} } = this.props;
      await resetVaultPin(data, transaction_id, this.props);
      this.deleteLink();
    } catch (err) {
      this.onError(err, "viewError");
    }
  };

  onForgotPinClick = () => {
    this.addLink({ uri: "/forgot-vault-pin" });
  };

  onChangeClick = async () => {
    let { data } = this.dataProps;
    try {
      await updateVaultPin(data, this.props);
      this.deleteLink();
    } catch (err) {
      this.onError(err, "viewError");
    }
  };

  onChangePinClick = () => {
    this.addLink({ uri: "/change-vault-pin" });
  };

  resendOtp = async () => {
    try {
      if (this.isResend) {
        return;
      }
      this.isResend = true;
      let { link: { props: { params: { transaction_id } = {} } = {} } = {} } = this.props;
      await resendPincode(transaction_id);
      this.isResend = false;
    } catch (err) {
      this.onError(err, "viewError");
    }
  };

  onSubmitClick = async e => {
    try {
      let { updatableData } = this.dataProps;
      let validateData = updatableData && updatableData.validateData;
      if (validateData) {
        let validations = await validateData();
        if (validations && Object.keys(validations).length) {
          return;
        }
      }
      let { source } = this.props;
      this._setState({ loading: true, error: void 0, viewError: void 0 });
      let onSubmit = this.clickEvents[source];
      onSubmit && (await onSubmit(e));
      this._setState({ loading: false });
    } catch (err) {
      this.onError(err);
    }
  };

  enableBiometrics = () => {
    let { declineFingerprintUse } = this.props;
    if (declineFingerprintUse && typeof declineFingerprintUse === "function") {
      declineFingerprintUse();
    } else {
      openFingerprintSettings();
      setTimeout(_ => this.deleteLink(), 0);
    }
  };

  render() {
    let {
      source,
      minHeight,
      showFingerprintPopup,
      onFingerprintError,
      showEnableFingerprint,
      declineFingerprintUse,
      biometricType
    } = this.props;
    let { submitText, primaryText, field, placeholder, icon, fields } = vaultPinInfo[source] || {};
    let contentContainerStyle = { flex: 1, minHeight: minHeight || 400 };
    let enableBiometricsText = "";
    if (showEnableFingerprint || declineFingerprintUse) {
      enableBiometricsText = showEnableFingerprint ? I18N.t("enableBiometrics") : I18N.t("useBiometrics");
      let textToReplace = I18N.t("fingerprint");
      if (Platform.OS === "ios") {
        if (biometricType) {
          textToReplace = biometricType;
        } else {
          textToReplace = I18N.t("biometrics");
        }
      }
      enableBiometricsText = enableBiometricsText.replace("__biometricType__", textToReplace);
    }
    return (
      <Container
        key={source}
        scrollViewStyle={{
          style: { flex: 1 },
          contentContainerStyle: Platform.OS === "web" ? {} : contentContainerStyle
        }}
      >
        {props => {
          this.dataProps = props;
          let editorProps = {
            ...props,
            state: this.state,
            setState: this._setState,
            onClick: this.onSubmitClick
          };
          if (source === "enter-pin" && Platform.OS !== "web") {
            editorProps.inputProps = { ...editorProps.inputProps, autoFocus: false };
          }
          let component = (
            <>
              {showFingerprintPopup ? (
                <FingerprintPopup
                  onAuthenticate={() => {
                    this._setState({ showloader: true });
                    setTimeout(_ => {
                      this.moveToVault("/enter-vault-pin");
                    }, 100);
                  }}
                  onError={onFingerprintError}
                />
              ) : null}
              {this.state.showloader ? <LoadingIndicator /> : null}
              <Box
                viewStyle={{ ...(Platform.OS !== "web" ? { flex: 1 } : contentContainerStyle) }}
                render={[
                  {
                    viewStyle: {
                      flex: 1,
                      paddingLeft: 27,
                      paddingRight: 27,
                      paddingTop: 18
                    },
                    render: [
                      {
                        image: getImage("safeAreaIcon"),
                        viewStyle: {
                          alignItems: "center",
                          paddingTop: 18
                        },
                        imageProps: {
                          resizeMode: "contain"
                        }
                      },
                      {
                        viewStyle: {
                          paddingTop: 18,
                          paddingBottom: 18,
                          alignSelf: "center"
                        },
                        textStyle: {
                          ...h16,
                          color: primaryColor
                        },
                        text: I18N.t(primaryText)
                      },
                      field
                        ? {
                            viewStyle: {
                              marginBottom: 18
                            },
                            render: <Editor {...editorProps} field={field} icon={icon} placeholder={placeholder} />
                          }
                        : fields &&
                          fields.map((fieldInfo, index) => {
                            return {
                              viewStyle: {
                                marginBottom: 18
                              },
                              render: [
                                Platform.OS !== "web" &&
                                  fieldInfo.label && {
                                    textStyle: {
                                      ...h16,
                                      color: primaryColor
                                    },
                                    text: I18N.t(fieldInfo.label)
                                  },
                                <Editor
                                  {...editorProps}
                                  inputProps={{
                                    autoFocus: index === 0
                                  }}
                                  field={fieldInfo.field}
                                  placeholder={fieldInfo.placeholder}
                                />
                              ]
                            };
                          }),
                      source === "reset-pin" && {
                        viewStyle: {
                          marginBottom: 18,
                          alignItems: "center"
                        },
                        render: <ResendOtp onClick={this.resendOtp} time={30} />
                      },
                      this.state.viewError && {
                        viewStyle: {
                          marginBottom: 18
                        },
                        textStyle: {
                          ...h9,
                          color: errorColor
                        },
                        text: this.state.viewError
                      },
                      {
                        viewStyle: {
                          marginBottom: 18
                        },
                        render: <Button loading={this.state.loading} onClick={this.onSubmitClick} text={submitText} />
                      },
                      source === "enter-pin" && {
                        viewStyle: {
                          marginBottom: 18,
                          alignItems: "center"
                        },
                        textStyle: {
                          ...h16_l,
                          color: themeColor
                        },
                        render: [
                          { text: `${I18N.t("forgotPin")}`, onClick: this.onForgotPinClick },
                          {
                            viewStyle: { paddingTop: 18 },
                            text: `${I18N.t("changePin")}`,
                            onClick: this.onChangePinClick
                          },
                          (showEnableFingerprint || declineFingerprintUse) && {
                            viewStyle: { paddingTop: 18 },
                            text: enableBiometricsText,
                            onClick: this.enableBiometrics
                          }
                        ]
                      },
                      source === "create-pin" &&
                        (showEnableFingerprint || declineFingerprintUse) && {
                          viewStyle: {
                            marginBottom: 18,
                            alignItems: "center"
                          },
                          textStyle: {
                            ...h16_l,
                            color: themeColor
                          },
                          text: enableBiometricsText,
                          onClick: this.enableBiometrics
                        }
                    ]
                  }
                ]}
              />
            </>
          );
          if (source === "reset-pin") {
            component = (
              <AutoSMSReadWrapper onError={this.onError} dataProps={this.dataProps} field={fields[0].field}>
                {component}
              </AutoSMSReadWrapper>
            );
          }
          return component;
        }}
      </Container>
    );
  }
}

PinComponent = withContext(PinComponent, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user",
  addUri: "Router.addUri",
  replaceUri: "Router.replaceUri",
  deleteUri: "Router.deleteUri",
  getPath: "Router.getPath",
  link: "ScreenRoute.link"
});

const WithFingerprint = RenderComponent => {
  const IS_FINGERPRINT_CHECK_REQUIRED =
    ((Platform.OS === "android" && Platform.Version >= 23) || Platform.OS === "ios") && FingerprintScanner;
  class FingerprintWrapper extends React.Component {
    state = { showLoader: true };

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    async initializeFingerPrintScanner() {
      if (IS_FINGERPRINT_CHECK_REQUIRED) {
        const IS_FINGERPRINT_ENABLED = await AsyncStorage.getItem("IS_FINGERPRINT_ENABLED");
        if (IS_FINGERPRINT_ENABLED === "APPROVED") {
          addPrivacyConsent && addPrivacyConsent("biometrics");
          FingerprintScanner.isSensorAvailable()
            .then(async biometryType => {
              // let biometricModalShown = await AsyncStorage.getItem("biometricModalShown");
              this._setState({
                fingerprintAvailable: true,
                showLoader: false,
                showPermissionModal: false
              });
            })
            .catch(err => {
              if (err) {
                console.warn("Error in FingerprintScanner >>>> ", err.name, err.message, err.biometric);
                let stateUpdates = { showLoader: false };
                if (err.name === "FingerprintScannerNotEnrolled") {
                  stateUpdates.fingerprintNotEnrolled = true;
                  stateUpdates.biometricType = err.biometric;
                }
                this._setState(stateUpdates);
              }
            });
        } else if (IS_FINGERPRINT_ENABLED === "DECLINE") {
          this.setState({
            showLoader: false,
            declineFingerprintUse: async () => {
              await AsyncStorage.setItem("IS_FINGERPRINT_ENABLED", "APPROVED");
              this.setState({ showPermissionModal: false, showLoader: true, declineFingerprintUse: false });
              this.initializeFingerPrintScanner();
            }
          });
        } else {
          this.setState({ showLoader: false, showPermissionModal: true });
        }
      } else {
        this.setState({ showLoader: false });
      }
    }

    componentDidMount() {
      setTimeout(_ => this.initializeFingerPrintScanner(), 300);
    }

    componentWillUnmount() {
      this._unmounted = true;
      if (Platform.OS === "android") {
        FingerprintScanner && FingerprintScanner.release && FingerprintScanner.release();
      }
    }

    closeModal = ({ showLoader = false } = {}) => {
      this.setState({ showLoader, showPermissionModal: false });
    };

    renderModal = () => {
      let title = I18N.t("biometricPermissionTitle");
      let message = I18N.t("biometricPermissionMessage");
      let textToReplace = I18N.t("fingerprint");
      if (Platform.OS === "ios") {
        textToReplace = I18N.t("biometrics");
      }
      title = title.replace("__biometricType__", textToReplace);
      message = message.replace("__biometricType__", textToReplace);

      return (
        <ConfirmBox
          title={title}
          message={message}
          confirmText={I18N.t("ok")}
          cancelText={I18N.t("cancel")}
          onConfirm={async () => {
            await AsyncStorage.setItem("IS_FINGERPRINT_ENABLED", "APPROVED");
            this.closeModal({ showLoader: true });
            this.initializeFingerPrintScanner();
          }}
          onCancel={async () => {
            await AsyncStorage.setItem("IS_FINGERPRINT_ENABLED", "DECLINE");
            this.closeModal();
            this.initializeFingerPrintScanner();
          }}
        />
      );
    };

    render() {
      const { showPermissionModal, showLoader, ...rest } = this.state;
      if (showLoader) return <LoadingIndicator />;
      return (
        <>
          <Modal isOpen={showPermissionModal} {...permissionModalStyle} onClose={this.closeModal}>
            {this.renderModal()}
          </Modal>
          <RenderComponent {...rest} />
        </>
      );
    }
  }
  return FingerprintWrapper;
};

class CreatePinComponent extends React.Component {
  render() {
    return <PinComponent source={"create-pin"} />;
  }
}

class ConfirmPinComponent extends React.Component {
  render() {
    return <PinComponent source={"confirm-pin"} />;
  }
}

class EnterPinComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFingerprintPopup: props.fingerprintAvailable,
      showPermissionModal: props.showPermissionModal
    };
  }

  handleFingerprintCallback = err => {
    if (err) {
      console.warn("Error in fingerprint authenticate >>>> ", err.name, err.message, err.biometric);
    }
    this.setState({ showFingerprintPopup: false });
  };

  closeModal = () => {
    this.setState({ showPermissionModal: false });
  };

  renderModal = () => {
    let { biometricType } = this.props;
    let title = I18N.t("biometricPermissionTitle");
    let message = I18N.t("biometricPermissionMessage");
    let textToReplace = I18N.t("fingerprint");
    if (Platform.OS === "ios") {
      if (biometricType) {
        textToReplace = biometricType;
      } else {
        textToReplace = I18N.t("biometrics");
      }
    }
    title = title.replace("__biometricType__", textToReplace);
    message = message.replace("__biometricType__", textToReplace);
    return (
      <ConfirmBox
        title={title}
        message={message}
        confirmText={I18N.t("ok")}
        onConfirm={async () => {
          await AsyncStorage.setItem("biometricModalShown", "true");
          this.closeModal();
        }}
      />
    );
  };

  render() {
    let { biometricType, fingerprintNotEnrolled, declineFingerprintUse } = this.props;
    let { showFingerprintPopup, showPermissionModal } = this.state;
    if (showPermissionModal) {
      return (
        <Modal isOpen={showPermissionModal} {...permissionModalStyle} onClose={this.closeModal}>
          {this.renderModal()}
        </Modal>
      );
    } else {
      return (
        <PinComponent
          source={"enter-pin"}
          showFingerprintPopup={showFingerprintPopup}
          showEnableFingerprint={fingerprintNotEnrolled}
          declineFingerprintUse={declineFingerprintUse}
          biometricType={biometricType}
          onFingerprintError={this.handleFingerprintCallback}
        />
      );
    }
  }
}

EnterPinComponent = WithFingerprint(EnterPinComponent);

class ForgotPinComponent extends React.Component {
  render() {
    return <PinComponent source={"forgot-pin"} />;
  }
}

class ResetPinComponent extends React.Component {
  render() {
    return <PinComponent source={"reset-pin"} minHeight={650} />;
  }
}

class ChangePinComponent extends React.Component {
  render() {
    return <PinComponent source={"change-pin"} minHeight={650} />;
  }
}

class VaultPinWebComponent extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    this.modalTypeClickEvents = {
      "create-pin": this.onCreateClick,
      "confirm-pin": this.onConfirmClick,
      "enter-pin": this.onEnterClick,
      "forgot-pin": this.onForgotClick,
      "reset-pin": this.onResetClick,
      "change-pin": this.onChangeClick
    };
    this.modalTypeCancelTypes = {
      "create-pin": void 0,
      "confirm-pin": "create-pin",
      "enter-pin": void 0,
      "forgot-pin": "enter-pin",
      "reset-pin": "enter-pin",
      "change-pin": "enter-pin"
    };
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  onError = (err, errorField) => {
    let message = getErrorMessage(err);
    this._setState({ [errorField || "error"]: message, loading: false });
  };

  setModalType = (modalType, _state) => {
    this._setState({
      modalType,
      error: void 0,
      modalError: void 0,
      ..._state
    });
  };

  actionClick = () => {
    let { user } = this.props;
    this.setModalType(user.vaultEnabled ? "enter-pin" : "create-pin");
  };

  renderModal = ({
    title,
    primaryText,
    placeholder,
    submitText,
    cancelText,
    onConfirm,
    onCancel,
    field,
    inputStyle,
    fields
  }) => {
    return (
      <Connect data={{}} updatable>
        {props => {
          this.dataProps = props;
          let editorProps = {
            ...this.dataProps,
            state: this.state,
            setState: this._setState,
            onClick: this.onSubmitClick
          };
          return [
            this.state.loading && <LoadingIndicator />,
            <Box
              viewStyle={{
                backgroundColor: primaryBg2
              }}
              render={[
                title && {
                  direction: "row",
                  viewStyle: {
                    height: 77,
                    paddingLeft: 30,
                    alignItems: "center",
                    borderBottomWidth: 2,
                    borderColor: veryLightPink
                  },
                  textStyle: {
                    ...h20,
                    color: themeColor
                  },
                  render: [
                    { width: "1fr", text: I18N.t(title) },
                    onCancel && {
                      viewStyle: {
                        paddingLeft: 24,
                        paddingRight: 30,
                        width: 78,
                        height: 48,
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer"
                      },
                      image: getImage("crossIcon"),
                      onClick: onCancel
                    }
                  ]
                },
                {
                  viewStyle: { marginLeft: 30, marginRight: 30 },
                  render: [
                    {
                      viewStyle: { paddingTop: 24, paddingBottom: 24, alignSelf: "center" },
                      image: getImage("safeAreaIcon")
                    },
                    primaryText && {
                      viewStyle: {
                        paddingBottom: 24,
                        paddingLeft: 30,
                        paddingRight: 30,
                        alignSelf: "center"
                      },
                      textStyle: {
                        ...h16_l,
                        color: primaryColor
                      },
                      text: I18N.t(primaryText)
                    },
                    field
                      ? {
                          viewStyle: {
                            paddingBottom: 12
                          },
                          render: (
                            <Editor {...editorProps} field={field} inputStyle={inputStyle} placeholder={placeholder} />
                          )
                        }
                      : fields &&
                        fields.map((fieldInfo, index) => {
                          return {
                            viewStyle: {
                              paddingBottom: 12
                            },
                            render: (
                              <Editor
                                {...editorProps}
                                inputProps={{
                                  autoFocus: index === 0
                                }}
                                field={fieldInfo.field}
                                placeholder={fieldInfo.placeholder}
                              />
                            )
                          };
                        })
                  ]
                },
                this.state.modalType === "reset-pin" && {
                  viewStyle: {
                    paddingTop: 12,
                    alignItems: "center",
                    cursor: "pointer"
                  },
                  render: <ResendOtp onClick={this.resendOtp} time={30} />
                },
                this.state.modalType === "enter-pin" && {
                  itemStyle: {
                    cursor: "pointer",
                    alignSelf: "center",
                    paddingTop: 12
                  },
                  textStyle: {
                    ...h9,
                    color: themeColor
                  },
                  render: [
                    { text: `${I18N.t("forgotPin")}`, onClick: this.onForgotPinClick },
                    { text: `${I18N.t("changePin")}`, onClick: this.onChangePinClick }
                  ]
                },
                {
                  direction: "row",
                  viewStyle: {
                    paddingTop: 12,
                    paddingBottom: 24,
                    paddingLeft: 30,
                    paddingRight: 30
                  },
                  render: [
                    {
                      viewStyle: {
                        flex: 1,
                        alignSelf: "center",
                        paddingRight: 24
                      },
                      textStyle: {
                        ...h9,
                        color: errorColor
                      },
                      text: this.state.modalError || ""
                    },
                    {
                      viewStyle: {
                        cursor: "pointer",
                        marginRight: 24,
                        alignSelf: "center"
                      },
                      textStyle: {
                        ...h9,
                        color: themeColor
                      },
                      text: I18N.t(cancelText),
                      onClick: onCancel
                    },
                    {
                      viewStyle: {
                        cursor: "pointer",
                        paddingTop: 9,
                        paddingBottom: 9,
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: themeColor,
                        borderRadius: 4
                      },
                      textStyle: {
                        ...h9,
                        color: highlightColor
                      },
                      text: I18N.t(submitText),
                      onClick: onConfirm
                    }
                  ]
                }
              ]}
            />
          ];
        }}
      </Connect>
    );
  };

  moveToVault = () => {
    let { addUri, getPath } = this.props;
    this.setModalType();
    addUri && getPath && addUri(getPath(), { uri: "/vault" });
    setVaultMode && setVaultMode(true);
  };

  onClick = event => {
    event && event.stopPropagation && event.stopPropagation();
    const { onClick } = this.props;
    if (onClick) {
      onClick({ onClick: this.actionClick });
    } else {
      this.actionClick();
    }
  };

  onCreateClick = () => {
    let { data } = this.dataProps;
    this.setModalType("confirm-pin", {
      newPin: data.newPin
    });
  };

  onConfirmClick = async () => {
    let { data } = this.dataProps;
    await createVaultPin({ newPin: this.state.newPin, confirmPin: data.confirmPin }, this.props);
    this.moveToVault();
  };

  onEnterClick = async () => {
    let { data } = this.dataProps;
    await authenticateVaultPin(data, this.props);
    this.moveToVault();
  };

  onResetClick = async () => {
    let { data } = this.dataProps;
    try {
      await resetVaultPin(data, this.state.transaction_id, this.props);
      this.setModalType("enter-pin");
    } catch (err) {
      this.onError(err, "modalError");
    }
  };

  onForgotClick = async () => {
    let { data } = this.dataProps;
    let result = await sendSMS(data);
    forgotVaultPin(this.props);
    this.setModalType("reset-pin", {
      transaction_id: result.transaction_id
    });
  };

  onForgotPinClick = () => {
    this.setModalType("forgot-pin");
  };

  onChangeClick = async () => {
    let { data } = this.dataProps;
    try {
      await updateVaultPin(data, this.props);
      this.setModalType("enter-pin");
    } catch (err) {
      this.onError(err, "modalError");
    }
  };

  onChangePinClick = () => {
    this.setModalType("change-pin");
  };

  resendOtp = async () => {
    try {
      if (this.isResend) {
        return;
      }
      this.isResend = true;
      await resendPincode(this.state.transaction_id);
      this.isResend = false;
    } catch (err) {
      this.onError(err, "modalError");
    }
  };

  onSubmitClick = async e => {
    try {
      let { updatableData } = this.dataProps;
      let validateData = updatableData && updatableData.validateData;
      if (validateData) {
        let validations = await validateData();
        if (validations && Object.keys(validations).length) {
          return;
        }
      }
      let { modalType } = this.state;
      this._setState({ loading: true, error: void 0, modalError: void 0 });
      let onSubmit = this.modalTypeClickEvents[modalType];
      onSubmit && (await onSubmit(e));
      this._setState({ loading: false });
    } catch (err) {
      this.onError(err);
    }
  };

  onCancel = () => {
    let { modalType } = this.state;
    this.setModalType(this.modalTypeCancelTypes[modalType]);
  };

  render() {
    let { children } = this.props;
    let modalComponent = void 0;
    let { modalType } = this.state;
    if (modalType) {
      modalComponent = (
        <Modal key={modalType} isOpen={true} {...modalStyle}>
          {this.renderModal({
            ...vaultPinInfo[modalType],
            onConfirm: this.onSubmitClick,
            onCancel: this.onCancel
          })}
        </Modal>
      );
    }
    return (
      <>
        {React.cloneElement(children, { onClick: this.onClick })}
        {modalComponent}
      </>
    );
  }
}

VaultPinWebComponent = withContext(VaultPinWebComponent, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user",
  addUri: "Router.addUri",
  getPath: "Router.getPath",
  link: "ScreenRoute.link"
});

export {
  CreatePinComponent,
  ConfirmPinComponent,
  EnterPinComponent,
  ForgotPinComponent,
  ResetPinComponent,
  ChangePinComponent,
  VaultPinWebComponent
};
