import UserIssues from "./Issues";
import AddIssue from "./AddIssue";
import AddComment from "./AddComment";
import CommentList from "./CommentList";
import SupportTab from "./SupportTab";
import SupportIssues from "./SupportIssues";

export default {
  ...AddComment,
  ...UserIssues,
  ...AddIssue,
  ...CommentList,
  ...SupportTab,
  ...SupportIssues
};
