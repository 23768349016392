import LocalDataStats from "./LocalDataStats";
import { I18N } from "../../../FsCloudComponent";
export default {
  LocalStats: {
    title: () => I18N.t("statsTitle"),
    component: "table",
    dataObject: "Resource",
    Component: LocalDataStats,
    panelProps: {
      showHeader: true
    },
    // hideNavHeader: true,
    listProps: {
      selectionRequired: false,
      imageField: "url",
      imageHeight: "height",
      imageWidth: "width",
      rowProps: {
        imageField: "url"
      }
    }
  }
};
