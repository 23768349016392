/* eslint no-unused-vars: "off" */
import axios from "axios";
export default ({ getEncryptionKeyAndIV, Platform, getBase64Image, isNetworkUrl, I18N }) => {
  let decryptedObjectUrls = {};

  const decryptionQueueMap = {};
  const DECRYPTION_LIMIT = 5;

  const currentEncDecMap = {};

  const cancelEncryptDecryptWeb = resourceId => {
    if (resourceId && currentEncDecMap[resourceId]) {
      delete currentEncDecMap[resourceId];
    }
  };

  const encryptDecryptWeb = async (file, { ivIncrement, chunkSize, key, iv, method = "encrypt", resourceId } = {}) => {
    console.log("encryptDecryptWeb: ", file);
    let dataArray = [];
    try {
      if (resourceId) {
        currentEncDecMap[resourceId] = true;
      }
      if (method !== "encrypt" && method !== "decrypt") {
        let err = new Error();
        err.message = `[encryptDecryptWeb] Failed. Method must be one of the ['encrypt','decrypt']`;
        throw err;
      }
      const DEFAULT_CHUNK_SIZE = 1024 * 1024 * 128; // KB MB GB // 128 MB
      const CHUNK_SIZE = ivIncrement ? Infinity : chunkSize || DEFAULT_CHUNK_SIZE;
      let start = 0,
        end = 0;
      const actualFileSize = file.size;
      if (!key || !iv) {
        // we assume that given file is uploading & current user's key & iv is required
        let { key: encryptionKey, iv: encryptionIV } = (await getEncryptionKeyAndIV()) || {};
        key = encryptionKey;
        iv = encryptionIV;
      }
      while (end < actualFileSize) {
        if (resourceId && !currentEncDecMap[resourceId]) {
          let error = new Error();
          error.message = I18N.t("upload_user_cancelled");
          error.code = "upload_user_cancelled";
          throw error;
        }
        // console.log("@@@@ end is ", end, " out of ", actualFileSize);
        start = end;
        end = end + CHUNK_SIZE;
        if (end > actualFileSize) {
          end = actualFileSize;
        }
        if (!ivIncrement) {
          ivIncrement = start / 16;
        }
        let bufferChunk = await file.slice(start, end);
        bufferChunk = await convertBlobToBuffer(bufferChunk); // get buffer of given slice of file
        if (method === "decrypt") {
          bufferChunk = await aesDecrypt(bufferChunk, { key, iv, ivIncrement });
        } else {
          bufferChunk = await aesEncrypt(bufferChunk, { key, iv, ivIncrement }); // encrypt given buffer
        }
        const blobChunk = new Blob([bufferChunk]); // create blob of encrypted buffer bcozz blob does not take much space as taken by arrayBuffer
        bufferChunk = null; // buffer dereference
        ivIncrement = null;
        dataArray.push(blobChunk);
      }

      const encryptedFile = new Blob(dataArray, { type: file.type });
      return encryptedFile;
    } catch (err) {
      throw err;
    } finally {
      dataArray = null; //array Dereference
      cancelEncryptDecryptWeb(resourceId);
    }
  };

  const releaseAllObjectUrls = () => {
    if (Platform.OS !== "web") {
      return;
    }
    let objectUrls = Object.values(decryptedObjectUrls);
    for (let url of objectUrls) {
      try {
        if (URL.releaseObjectUrl) {
          URL.releaseObjectUrl(url);
        } else if (URL.revokeObjectURL) {
          URL.revokeObjectURL(url);
        }
      } catch (err) {
        console.log("@@@@ error in URL.releaseObjectUrl", err && err.message);
      }
    }
    decryptedObjectUrls = {};
  };

  const convertBase64ToFile = (dataurl, name, options) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], name, options);
  };

  const convertBufferToBinary = buffer => {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary;
  };

  const convertBlobToBuffer = blob => {
    if (blob.arrayBuffer && typeof blob.arrayBuffer === "function") {
      return blob.arrayBuffer();
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = err => reject(err || reader.error);
      reader.readAsArrayBuffer(blob);
    });
  };

  const convertBinaryToFile = (bstr, name, options) => {
    let n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], name, options);
  };

  const convertBufferToFile = (buffer, name, options) => {
    let array = new Uint8Array(buffer);
    return new Blob([array], name, options);
  };

  const strToBuffer = str => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  };

  const aesEncrypt = async (buffer, { key, iv, ivIncrement } = {}) => {
    if (!key || !iv) {
      let { key: encryptionKey, iv: encryptionIV } = (await getEncryptionKeyAndIV()) || {};
      key = encryptionKey;
      iv = encryptionIV;
    }
    iv = Buffer.from(iv);
    if (ivIncrement && ivIncrement > 0) {
      incrementIV(iv, ivIncrement);
    }
    let algorithm = {
      name: "AES-CTR",
      length: 128,
      counter: iv
    };
    return crypto.subtle
      .importKey("raw", strToBuffer(key), "AES-CTR", true, ["encrypt", "decrypt"]) // TODO : review
      .then(keyObj => crypto.subtle.encrypt(algorithm, keyObj, buffer));
  };

  /**
   * @summary Used to increase iv by given value
   * @param {*} : { iv, increment }
   */
  const incrementIV = (iv, increment) => {
    if (iv.length !== 16) {
      throw new Error("Only implemented for 16 bytes IV");
    }
    const MAX_UINT32 = 0xffffffff;
    let incrementBig = ~~(increment / MAX_UINT32);
    let incrementLittle = (increment % MAX_UINT32) - incrementBig;

    // split the 128bits IV in 4 numbers, 32bits each
    let overflow = 0;
    for (let idx = 0; idx < 4; ++idx) {
      let num = iv.readUInt32BE(12 - idx * 4);

      let inc = overflow;
      if (idx === 0) inc += incrementLittle;
      if (idx === 1) inc += incrementBig;

      num += inc;

      let numBig = ~~(num / MAX_UINT32);
      let numLittle = (num % MAX_UINT32) - numBig;
      overflow = numBig;

      iv.writeUInt32BE(numLittle, 12 - idx * 4);
    }
  };

  /**
   * Used in web
   * Reads buffer from file, encrypts buffer, create file instance from encrypted buffer
   *
   * params: file object
   * returns: encryptedFile object
   */
  const encryptFile = async (file, props) => {
    try {
      if (Platform.OS !== "web") {
        return Promise.resolve();
      }
      return await encryptDecryptWeb(file, props);
    } catch (err) {
      console.log("[EncryptionUtility.js] [encryptFile] FAILED:: ,", err);
      throw err;
    }
  };

  const convertBufferToBase64 = (buffer, contentType) => {
    let image = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ""));
    return `data:${contentType};base64,${image}`;
  };

  const aesDecrypt = async (buffer, { key, iv, ivIncrement }) => {
    iv = Buffer.from(iv);
    if (ivIncrement && ivIncrement > 0) {
      incrementIV(iv, ivIncrement);
    }
    let algorithm = {
      name: "AES-CTR",
      length: 128,
      counter: iv
    };
    return crypto.subtle
      .importKey("raw", strToBuffer(key), "AES-CTR", true, ["encrypt", "decrypt"]) // TODO : review
      .then(keyObj => crypto.subtle.decrypt(algorithm, keyObj, buffer));
  };

  const getAlreadyDecryptedUrl = url => {
    if (Platform.OS === "web") {
      return decryptedObjectUrls[url];
    }
  };
  /**
   * Used for only images in web
   * Takes url, extracts buffer from it, decrypts buffer, converts decrypted buffer to file and then to object url and returns object url
   *
   * params: url: url to decrypt, data: other data of the resource
   * returns: Object URL
   *  * The Object URL lifetime is tied to the document in the window on which it was created.
   *  * To release an object URL, call revokeObjectURL().
   */
  const decryptFile = async (url, decryptionProps, downloadProps) => {
    if (!downloadProps) {
      let decryptedUrl = getAlreadyDecryptedUrl(url);
      if (decryptedUrl) {
        return decryptedUrl;
      }
    }

    let { key, iv } = (await getEncryptionKeyAndIV(decryptionProps)) || {};
    if (Platform.OS === "web") {
      let response = await axios.get(url, { responseType: "blob", ...downloadProps });
      let decryptedFile = await encryptDecryptWeb(response.data, { key, iv, method: "decrypt" });
      if (downloadProps) {
        return decryptedFile;
      } else {
        let objectUrl = URL.createObjectURL(decryptedFile);
        decryptedObjectUrls[url] = objectUrl;
        // console.log("@@@@ object url", objectUrl);
        return objectUrl;
      }
    } else {
      let base64Str = await getBase64Image({
        fromUrl: url,
        key,
        iv,
        ...downloadProps
      });
      return `data:image/png;base64,${base64Str}`;
    }
  };

  const cancelDecryption = ({ url, callback, origin }) => {
    if (!origin || !url || !callback) {
      return;
    }
    let queue = (decryptionQueueMap[origin] && decryptionQueueMap[origin]["queue"]) || [];
    if (!queue.length) {
      return;
    }
    let index = queue.findIndex(_ => _.url === url);
    if (index !== -1) {
      let item = queue[index];
      let { callbacks = [] } = item;
      let cbIndex = callbacks.findIndex(cb => cb === callback);
      if (cbIndex !== -1) {
        if (callbacks.length > 1 || item.status === "inProgress") {
          callbacks.splice(cbIndex, 1);
        } else {
          queue.splice(index, 1);
        }
      }
    }
  };

  const decryptInQueue = ({ url, callback, origin, decryptionProps }, downloadProps) => {
    if (!url || !isNetworkUrl(url)) {
      callback && callback(void 0, url);
      return url;
    }
    if (!downloadProps) {
      let decryptedUrl = getAlreadyDecryptedUrl(url);
      if (decryptedUrl) {
        callback && callback(void 0, decryptedUrl);
        return decryptedUrl;
      }
    }

    // if origin or cb is skipped, file is decrypted out of queue and promise is returned
    if (!origin || !callback) {
      return decryptFile(url, decryptionProps, downloadProps)
        .then(res => {
          if (callback) {
            callback(void 0, res);
          } else {
            return res;
          }
        })
        .catch(err => {
          if (callback) {
            callback(err);
          } else {
            throw err;
          }
        });
    }

    decryptionQueueMap[origin] = decryptionQueueMap[origin] || { inProgress: 0, queue: [] };
    let { queue } = decryptionQueueMap[origin];
    let matchingIndex = queue.findIndex(_ => _.url === url);
    if (matchingIndex !== -1) {
      queue[matchingIndex].callbacks = queue[matchingIndex].callbacks || [];
      queue[matchingIndex].callbacks.push(callback);
    } else {
      queue.push({ url, callbacks: [callback], status: "pending", decryptionProps, downloadProps });
    }
    startDecryptionInQueue(origin);
  };

  const startDecryptionInQueue = async origin => {
    let originMap = decryptionQueueMap[origin];
    let { inProgress } = originMap;
    let queue = originMap["queue"];
    if (inProgress >= DECRYPTION_LIMIT) {
      return;
    }
    let topPendingFile = queue.find(_ => _.status === "pending");
    if (!topPendingFile) {
      return;
    }
    let { url, decryptionProps, downloadProps } = topPendingFile;

    try {
      topPendingFile.status = "inProgress";
      topPendingFile.startTime = new Date().getTime();
      originMap["inProgress"]++;

      let decryptResult = await decryptFile(url, decryptionProps, downloadProps);
      topPendingFile.callbacks && topPendingFile.callbacks.forEach(cb => cb && cb(void 0, decryptResult));
    } catch (err) {
      topPendingFile.callbacks && topPendingFile.callbacks.forEach(cb => cb && cb(err));
    }
    let topPendingIndex = queue.indexOf(topPendingFile);
    if (topPendingIndex >= 0) {
      queue.splice(topPendingIndex, 1);
    }
    originMap["inProgress"]--;
    startDecryptionInQueue(origin);
  };

  return {
    encryptFile,
    decryptFile: decryptInQueue,
    cancelDecryption,
    releaseAllObjectUrls,
    encryptDecryptWeb,
    cancelEncryptDecryptWeb
  };
};
