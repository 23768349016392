import React from "react";
export default ({ Box, I18N, theme }) => {
  let { fonts, colors, bgs } = theme;
  let { h16_l } = fonts;
  let { highlightColor } = colors;
  let { charcoalBlack } = bgs;
  class NoInternetConnection extends React.Component {
    render() {
      return (
        <Box
          viewStyle={{
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 60,
            paddingRight: 60,
            paddingTop: 14,
            paddingBottom: 14,
            alignSelf: "center",
            borderRadius: 4,
            backgroundColor: charcoalBlack,
            position: "absolute",
            top: 20,
            elevation: 1,
            zIndex: 15
          }}
          textStyle={{ ...h16_l, color: highlightColor }}
          text={I18N.t("no_internet")}
        />
      );
    }
  }
  return NoInternetConnection;
};
