import React from "react";
import TabPanelHoc, {
  TabSwiper as TabSwiperHoc,
  TabContainer as TabContainerHoc,
  TabContainerMD as TabContainerMDHoc,
  TabBar as TabBarHoc,
  TabScrollAnimation as TabScrollAnimationHoc,
  TabBarMore as TabBarMoreHoc,
  TabItem as TabItemHoc,
  TabComponentContainer as TabComponentContainerHoc,
  TabTitle as TabTitleHoc,
  TabActions as TabActionsHoc,
  Swiper as SwiperHoc,
  renderTabItem
} from "tab";
import LeftTabBarHoc from "./LeftTabBar";

export default ({
  theme = {},
  Box,
  ResizedBox,
  View,
  ScrollView,
  withContext,
  resolveExp,
  renderChildren,
  resolveActions,
  resolveMQ,
  resolveTabBarStyle,
  setStateHoc,
  getComponent,
  Platform,
  getImage,
  fromNowDateFormatter,
  Image
}) => {
  const Swiper = SwiperHoc({ View, ScrollView });
  const TabScrollAnimation = withContext(
    TabScrollAnimationHoc({
      Box,
      theme: theme.tabScrollAnimationStyle
    }),
    {
      addScrollListener: "Nav.addScrollListener",
      removeScrollListener: "Nav.removeScrollListener"
    }
  );

  const TabActions = withContext(
    TabActionsHoc({
      Box,
      resolveMQ,
      resolveActions,
      theme: theme.tabActionsStyle
    }),
    {
      activeMQ: "ActiveMQ",
      visibleView: "NavBody.visibleView",
      selectionMode: "SelectionStore.selectionMode",
      isAllSelected: "SelectionStore.isAllSelected",
      selectedIds: "SelectionStore.selectedIds",
      isExpanded: "NavBody.isExpanded",
      index: "ScreenRoute.index",
      data: "Screen.Connect.data",
      user: "User.user"
    }
  );

  const TabContainer = withContext(
    TabContainerHoc({
      style: { flex: 1 },
      View,
      renderChildren,
      TabScrollAnimation,
      Platform,
      TabActions,
      theme,
      getImage,
      fromNowDateFormatter
    }),
    {
      notifyScrollListener: "Nav.notifyScrollListener"
    }
  );
  const TabContainerMD = TabContainerMDHoc({ style: { flex: 1 }, View, renderChildren });

  const TabSwiper = TabSwiperHoc({ Swiper, renderChildren });
  const TabComponentContainer = TabComponentContainerHoc({
    style: { flex: 1 },
    View,
    TabSwiper,
    renderChildren
  });

  const TabItem = withContext(
    TabItemHoc({
      Box,
      resolveMQ,
      renderItem: renderTabItem
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const TabTitle = withContext(
    TabTitleHoc({
      Box,
      resolveMQ,
      resolveExp,
      theme: theme.panelTitleStyle
    }),
    {
      activeMQ: "ActiveMQ",
      aggregates: "Screen.Connect.aggregates",
      data: "Screen.Connect.data"
    }
  );

  const TabBarMore = withContext(
    TabBarMoreHoc({
      Box: ResizedBox,
      resolveMQ,
      getContainer: getComponent,
      TabItem,
      TabActionsSM: null,
      TabActionsMD: TabActions,
      theme: theme.tabMoreStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const TabBar = withContext(
    TabBarHoc({
      Box,
      getContainer: getComponent,
      resolveMQ,
      TabItem,
      TabActions,
      resolveStyle: resolveTabBarStyle,
      renderComponent: ({ tabBarComponent, tabActionComponent }) => {
        return [tabActionComponent, tabBarComponent];
      },
      theme: theme.tabStyle
    }),
    {
      activeMQ: "ActiveMQ",
      link: "ScreenRoute.link"
    }
  );

  const TabPanel = withContext(
    TabPanelHoc({
      TabContainerSM: TabContainer,
      TabContainerMD: TabContainerMD,
      TabComponentContainer,
      resolveMQ,
      TabBarSM: TabBar,
      TabBarMD: TabBarMore,
      setState: setStateHoc
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const ProfileTabPanel = withContext(
    TabPanelHoc({
      TabContainer,
      TabComponentContainer,
      resolveMQ,
      TabBarSM: TabBar,
      TabBarMD: TabBarMore,
      setState: setStateHoc,
      theme: {
        ...theme.tabMoreStyle,
        containerTypeMD: "profileTabBorder"
      }
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const ContactTabPanel = withContext(
    TabPanelHoc({
      TabContainer,
      TabComponentContainer,
      resolveMQ,
      TabBarSM: TabBar,
      TabBarMD: TabBarMore,
      setState: setStateHoc,
      themeSM: {
        ...theme.contactTabStyleSM
      },
      themeMD: {
        ...theme.tabMoreStyle,
        containerTypeMD: "contactTabBorder"
      }
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const BottomTabPanel = withContext(
    TabPanelHoc({
      TabContainer,
      TabComponentContainer,
      resolveMQ,
      TabBar: TabBar,
      setState: setStateHoc,
      position: "bottom",
      theme: theme.bottomTabStyle
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const LeftTabBar = withContext(
    LeftTabBarHoc({
      Box,
      TabBar
    }),
    {
      Screen: "Screen"
    }
  );

  const LeftTabPanel = withContext(
    TabPanelHoc({
      TabContainerSM: TabContainer,
      TabContainerMD: TabContainerMD,
      TabComponentContainer,
      resolveMQ,
      TabBar: LeftTabBar,
      setState: setStateHoc,
      position: "left",
      theme: theme.leftTabStyle,
      componentContainerStyleMD: {
        flex: 1
      }
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const TabBorder = props => {
    let { children } = props;
    return (
      <Box
        viewStyle={{ height: 60, paddingLeft: 30, paddingRight: 80, marginBottom: 24, justifyContent: "flex-end" }}
        render={[children, { viewStyle: { borderTopWidth: 1, borderColor: "#eeeeee", width: "40%" } }]}
      />
    );
  };

  const ProfileTabBorder = props => {
    let { children } = props;
    return (
      <Box
        viewStyle={{ height: 60, paddingLeft: 116, paddingRight: 80, marginBottom: 24, justifyContent: "flex-end" }}
        render={[children, { viewStyle: { borderTopWidth: 1, borderColor: "#eeeeee", width: "50%" } }]}
      />
    );
  };

  const ContactTabBorder = props => {
    let { children } = props;
    return (
      <Box
        viewStyle={{ height: 60, paddingLeft: 30, paddingRight: 30, marginBottom: 24, justifyContent: "flex-end" }}
        render={[children, { viewStyle: { borderTopWidth: 1, borderColor: "#eeeeee", width: "448px" } }]}
      />
    );
  };

  return {
    TabContainer,
    TabComponentContainer,
    TabItem,
    TabTitle,
    TabActions,
    TabBarMore,
    TabBar,
    TabPanel,
    BottomTabPanel,
    LeftTabPanel,
    ProfileTabPanel,
    TabScrollAnimation,
    TabBorder,
    ProfileTabBorder,
    ContactTabPanel,
    ContactTabBorder
  };
};
