import { MusicCard } from "./MusicCards";
import DetailCollectionComponent from "../collection/DetailCollection";
import { playlistDetailQuery } from "../queries";

export default {
  PlaylistDetail: {
    component: "table",
    dataObject: "Resource",
    Component: DetailCollectionComponent,
    listProps: {
      list: "scrollIndicatorList",
      detailLink: ({ data = {}, link = {} }) => {
        let { props: { params: { collection: { name: collectionName = "" } = {} } = {} } = {} } = link;
        return {
          uri: `/playlist-music-detail`,
          detailId: data._id,
          props: {
            params: {
              collection: { ...data.collection, name: collectionName }
            }
          },
          origin: "playlist"
        };
      }
    },
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true,
    ...playlistDetailQuery,
    rowProps: {
      ...MusicCard
    }
  }
};
