import React, { Component } from "react";

class WebBrandList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBrandLabel: null
    };
  }

  renderBrandItem = item => (
    <div
      onClick={() => {
        this.props.setFirebaseApp(item.firebaseName);
        this.setState({ selectedBrandLabel: item.label });
      }}
      style={{
        border: `2px solid ${this.state.selectedBrandLabel === item.label ? "#003C96" : "#E3E3E3"}`,
        backgroundColor: "#FDFDFD",
        padding: "5px",
        borderRadius: "12px",
        alignItems: "center",
        marginBottom: "9px",
        cursor: "pointer"
      }}
    >
      <img
        style={{
          width: "80px",
          height: "50px",
          objectFit: "contain"
        }}
        src={item.logo}
        alt={item.label}
      />
    </div>
  );

  render() {
    const data = [
      {
        firebaseName: "TERTIARY_APP",
        brand: "tim",
        logo: require("./../images/tim_logo.png"),
        label: "tim"
      },
      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/agility_logo.png"),
        label: "agility"
      },
      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/algar_logo.png"),
        label: "algar"
      },
      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/bradesco_logo.png"),
        label: "bradesco"
      },
      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/brisanet_logo.png"),
        label: "brisanet"
      },
      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/desktop.png"),
        label: "desktop"
      },
      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/exa_logo.png"),
        label: "exa"
      },
      {
        firebaseName: "SECONDARY_APP",
        brand: "oi",
        logo: require("./../images/logo_oi.png"),
        label: "oi"
      },
      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/sumicity_logo.png"),
        label: "sumicity"
      },

      {
        firebaseName: "[DEFAULT]",
        brand: "hero",
        logo: require("./../images/outro_logo.png"),
        label: "outro"
      }
    ];

    return (
      !this.props.chosen && (
        <>
          <div style={{ padding: "20px" }}>{this.props.data.map(item => this.renderBrandItem(item))}</div>
        </>
      )
    );
  }
}

export default WebBrandList;
