const getTypeWiseActions = actions => {
  return actions.reduce((accum, action) => {
    if (action) {
      if (typeof action === "string") {
        action = { type: action };
      }
      let type = action.type;
      accum[type] = accum[type] || [];
      accum[type].push(action);
    }
    return accum;
  }, {});
};

const populateLayoutActions = (layoutActions, typeWiseActions) => {
  let resolvedActions = [];
  if (layoutActions) {
    for (var action of layoutActions) {
      if (typeof action === "string") {
        action = { type: action };
      }
      if (action.actions) {
        let innerResolvedActions = populateLayoutActions(action.actions, typeWiseActions);
        if (innerResolvedActions && innerResolvedActions.length) {
          resolvedActions.push({
            ...action,
            actions: innerResolvedActions
          });
        }
      } else {
        let actionType = action.type;
        let actionInfos = actionType && typeWiseActions[actionType];
        if (actionInfos) {
          for (var actionInfo of actionInfos) {
            resolvedActions.push({
              ...action,
              ...actionInfo
            });
          }
        } else {
          let { defaultAction, ...restActionInfo } = action;
          if (action.defaultAction || actionType === "flex") {
            resolvedActions.push({ ...restActionInfo });
          }
        }
      }
    }
  }
  return resolvedActions;
};

export const resolveActionLayout = ({ resolveMQ, actionLayout: actionLayoutHoc }) => ({ actions = [], activeMQ }) => {
  const { actionLayout = {} } = resolveMQ(actionLayoutHoc, ["actionLayout"], activeMQ);
  let typeWiseActions = getTypeWiseActions(actions);
  let resolvedActionLayout = Object.keys(actionLayout).reduce((accum, layoutKey) => {
    let layoutInfo = actionLayout[layoutKey];
    if (layoutInfo) {
      let resolvedLayoutInfo = void 0;
      if (Array.isArray(layoutInfo)) {
        resolvedLayoutInfo = populateLayoutActions(layoutInfo, typeWiseActions);
      } else {
        resolvedLayoutInfo = {};
        for (var layoutInnerKey in layoutInfo) {
          resolvedLayoutInfo[layoutInnerKey] = populateLayoutActions(layoutInfo[layoutInnerKey], typeWiseActions);
        }
      }
      accum[layoutKey] = resolvedLayoutInfo;
    }
    return accum;
  }, {});
  return resolvedActionLayout;
};

export const resolveActions = ({
  Platform,
  getAction,
  resolveMQ,
  renderChildren,
  actionStyle: actionStyleHoc
}) => props => {
  const { actions, ...restProps } = props;
  if (!actions) {
    return;
  }
  const { actionStyle } = resolveMQ(actionStyleHoc, ["actionStyle"], props.activeMQ);

  const populateActionComponents = (layoutActions = []) => {
    let actionComponents = [];
    let hasComponent = false;
    for (var index = 0; index < layoutActions.length; index++) {
      let action = layoutActions[index];
      if (typeof action === "string") {
        action = { type: action };
      }
      let { visible = true, value, ...restAction } = action;
      if (typeof visible === "function") {
        visible = visible({
          ...restProps,
          Platform
        });
      }
      if (!visible) {
        continue;
      }
      if (value) {
        restAction = {
          ...restAction,
          ...value(restProps)
        };
      }
      let { type, ...restActionProps } = restAction;
      let { render, text, image, viewStyle, imageStyle, textStyle } = restActionProps;
      if (typeof text === "function") {
        text = text(restAction);
      }
      let actionInfo = void 0;
      if (type === "flex") {
        actionInfo = { width: "1fr", ...restActionProps };
      } else {
        let actionTypeStyle = (type && actionStyle[type]) || actionStyle["default"] || {};
        if (typeof actionTypeStyle === "function") {
          actionTypeStyle = actionTypeStyle(restProps);
        }
        actionInfo = {
          ...actionTypeStyle.boxStyle,
          text,
          image,
          render
        };
        if (textStyle) {
          actionInfo.textStyle = textStyle;
        }
        if (imageStyle) {
          actionInfo.imageStyle = imageStyle;
        }
        if (viewStyle) {
          actionInfo.viewStyle = viewStyle;
        }
        const ActionComponent = type && getAction(type);
        if (ActionComponent) {
          actionInfo.Container = ActionComponent;
          let containerProps = {
            action: { ...restActionProps }
          };
          if (restProps.data) {
            containerProps.data = restProps.data;
          }
          actionInfo.containerProps = containerProps;
        } else if (render) {
          actionInfo.render = renderChildren(render, { ...restActionProps });
        } else {
          actionInfo = {
            ...actionInfo,
            ...restActionProps
          };
        }
        hasComponent = true;
      }
      if (actionInfo.key === undefined) {
        actionInfo.key = `${index}_action`;
      }
      actionComponents.push(actionInfo);
    }
    return hasComponent ? actionComponents : [];
  };

  if (Array.isArray(actions)) {
    return populateActionComponents(actions);
  } else {
    let resolvedActions = {};
    for (var key in actions) {
      resolvedActions[key] = populateActionComponents(actions[key]);
    }
    return resolvedActions;
  }
};
