import React from "react";
import { PlaylistCard, PlaylistCardSMListProps } from "./MusicCards";
import { PlaylistNoData } from "../noData/NoData";
import { playlistQuery } from "../queries";
import ResourceActions from "../resource/ResourceActions";
import CollectionActions from "../collection/CollectionActions";
import { FSList } from "../../../FsCloudComponent";

export default {
  Playlist: {
    component: "table",
    dataObject: "Collection",
    notifyNavOnScroll: true,
    selectable: false,
    ComponentSM: FSList,
    ComponentMD: null,
    componentPropsSM: {
      ...PlaylistCardSMListProps,
      detailLink: CollectionActions.playlistDetailLink.link
    },
    componentTypeMD: {
      gridTable: {
        cardPerRowLG: 3,
        cardPerRowMD: 2,
        bodyStyleMD: {
          flex: 1,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 15,
          paddingRight: 85
        }
      }
    },
    renderNoData: <PlaylistNoData />,
    ...playlistQuery,
    headerActionsSM: [
      ResourceActions.searchMusic,
      {
        type: "headerAction",
        image: "createGreyIcon",
        action: "createPlaylist"
      }
    ],
    headerActionsMD: [
      ResourceActions.searchMusic,
      {
        type: "multiActions",
        image: "createWithCircleGreyIcon",
        action: "createPlaylist",
        visible: ({ data }) => (data && data.length ? true : false)
      }
    ],
    rowProps: {
      ...PlaylistCard
    }
  }
};
