import {
  I18N,
  Platform,
  MetadataTable,
  getImage,
  showMessage,
  normalizeCollectionItems,
  getUser,
  getSortValue,
  removeCurrentUploadData,
  removeImageFromGalleryFolders,
  deviceId as myDeviceId,
  emptyTrashSqlite,
  emptyNonUploadedSqlite,
  logFirebaseAnalyticsEvent,
  populateAndUpdateMetadataInSqlite,
  downloadPicOfDay
} from "../../../FsCloudComponent";
import { typeCastSchema } from "../../../Utility";
import {
  isRemoveFromGroupVisible,
  isRemoveFromGroupDetailVisible,
  isImportFromGroupVisible,
  isImportFromGroupDetailVisible,
  makePrivateActionVisibility,
  uploadResourceActionVisibility,
  getSelectedData,
  isResourceUploading
} from "../resource/ResourceUtility";

import { docSelectItemsProps, gallerySelectItemsProps, musicSelectItemsProps } from "../queries";
import {
  GALLERY_ALL_SORT,
  DOCS_ALL_SORT,
  MUSIC_ALL_SORT,
  GROUP_GALLERY_LOCAL_CHANGE_KEY,
  GROUP_DOC_LOCAL_CHANGE_KEY,
  GROUP_GALLERY_SORT,
  GROUP_DOC_SORT,
  ALBUM_DETAIL_LOCAL_CHANGE_KEY,
  SET_DETAIL_LOCAL_CHANGE_KEY,
  PLAYLIST_DETAIL_LOCAL_CHANGE_KEY,
  GALLERY_ALL_LOCAL_CHANGE_KEY,
  DOCS_ALL_LOCAL_CHANGE_KEY,
  MUSIC_ALL_LOCAL_CHANGE_KEY,
  VAULT_GALLERY_LOCAL_CHANGE_KEY,
  VAULT_DOCS_LOCAL_CHANGE_KEY,
  PLACES_LOCAL_CHANGE_KEY,
  SECURE_TYPES,
  METADATA_TABLE,
  FAILED_ITEMS_LOCAL_CHANGE_KEY,
  INFECTED_ITEMS_LOCAL_CHANGE_KEY,
  REMOVED_METADATA_TABLE,
  ARCHIVE_GALLERY_LOCAL_CHANGE_KEY,
  ARCHIVE_DOCS_LOCAL_CHANGE_KEY,
  ARCHIVE_MUSIC_LOCAL_CHANGE_KEY,
  VAULT_ALBUM_DETAIL_LOCAL_CHANGE_KEY,
  VAULT_SET_DETAIL_LOCAL_CHANGE_KEY,
  DEVICE_FOLDER_LOCAL_CHANGE_KEY,
  NON_UPLOAD_LOCAL_CHANGE_KEY
} from "../../../Constants";

export const RESOURCE_SHARE_TYPE_ENUM = {
  ORIGINAL: "original",
  CONVERTED: "converted",
  LINK: "LINK"
};

const confirmMessageResolver = ({ singleItemMsg, multipleItemsMsg, selectedIds = [] }) => {
  let translateMsg = I18N.t(singleItemMsg);
  if (selectedIds.length > 1) {
    translateMsg = I18N.t(multipleItemsMsg);
    translateMsg = translateMsg.replace("__itemCount__", `${selectedIds.length}`);
  }
  return translateMsg;
};

const renameResourceForm = {
  componentMD: "renameCollection",
  componentSM: "addCollectionNameMobile",
  formType: "rename",
  formSource: "Resource",
  modalProps: {
    modalSM: {
      stopPropagation: true,
      position: "center",
      width: 0.85,
      animationType: "none",
      style: {
        borderRadius: 4
      },
      adjustOnKeyboard: true
    },
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};
const savePDFNameForm = {
  component: "savePDFName",
  modalProps: {
    modalSM: {
      stopPropagation: true,
      position: "center",
      width: 0.85,
      animationType: "none",
      style: {
        borderRadius: 4
      },
      adjustOnKeyboard: true
    },
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};
const saveGIFNameForm = {
  component: "saveGIFName",
  modalProps: {
    modalSM: {
      stopPropagation: true,
      position: "center",
      width: 0.85,
      animationType: "none",
      style: {
        borderRadius: 4
      },
      adjustOnKeyboard: true
    },
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};

const selectItemsForm = {
  component: "collectionItemSelector",
  modalProps: {
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};

const addItemsToCollectionLinkProps = (origin, uri) => {
  return {
    type: "link",
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return selectedIds.length;
      } else {
        let selectedData = getSelectedData({ data, selectedIds });
        return uploadResourceActionVisibility({ selectedData });
      }
    },
    origin,
    link: ({ data, getSelectedIds, unSelectAll, link, getSelectedData } = {}) => {
      let selectedIds = (getSelectedIds && getSelectedIds()) || [];
      let selectedData = (getSelectedData && getSelectedData()) || [];
      if (!selectedIds.length && data && !Array.isArray(data) && data._id) {
        selectedIds = [data._id];
        selectedData = [data];
      }
      let params = void 0;
      let linkParams = link && link.props && link.props.params;
      if (linkParams && linkParams.collection) {
        params = {
          collection: linkParams.collection
        };
      }
      return {
        uri,
        origin,
        props: {
          params,
          parentInfo: {
            selectedIds,
            unSelectAll,
            selectedData
          }
        }
      };
    }
  };
};

const addItemsToCollectionFormProps = (origin, view) => {
  return {
    visible: (data, { selectedIds }) => selectedIds.length > 0,
    origin,
    connectProps: props => {
      let params = { excludeReadOnlyCollections: true };
      if (props && props.action && props.action.collection) {
        params = {
          ...params,
          collection: props.action.collection,
          excludeCollection: true
        };
      }
      return {
        uri: {
          props: {
            query: {
              view,
              pagination: true,
              params,
              limit: 500
            }
          }
        }
      };
    },
    form: {
      component: "addItemsToCollection",
      modalProps: {
        modalSM: {
          stopPropagation: true,
          position: "center",
          width: 0.9,
          height: 483,
          animationType: "none"
        },
        modalMD: {
          stopPropagation: true,
          position: "center",
          width: 440,
          height: 483,
          style: {
            borderRadius: 4
          }
        }
      }
    }
  };
};

const uploadMobileLink = (uri, { waitForSync, secureType } = {}) => {
  console.log("--------------------: ", uri);
  return {
    type: "link",
    text: () => I18N.t("uploadFile"),
    link: ({ isSyncProcessing } = {}) => {
      if (waitForSync && isSyncProcessing && isSyncProcessing()) {
        showMessage && showMessage(I18N.t("syncProcessingMessage"));
        return;
      }
      return {
        uri,
        secureType
      };
    },
    visible: (data, { selectionMode, Platform }) => Platform.OS !== "web" && !selectionMode
  };
};

const toViewLink = ({ uri, origin }) => {
  return {
    type: "link",
    text: () => I18N.t("toView"),
    title: () => I18N.t("toView"),
    visible: (data, { selectedIds }) => selectedIds && selectedIds.length === 1,
    link: ({ getSelectedData, unSelectAll }) => {
      let selectedData = (getSelectedData && getSelectedData()) || [];
      unSelectAll && unSelectAll();
      return {
        uri: uri,
        detailId: selectedData[0]._id,
        origin
      };
    }
  };
};

const searchLink = ({ uri, from, secureType }) => ({
  type: "searchLink",
  visible: ({ selectionMode }) => !selectionMode,
  link: {
    uri,
    props: {
      params: {
        from
      }
    },
    secureType
  }
});

const searchGroupLink = ({ uri, from }) => ({
  type: "searchLink",
  visible: ({ selectionMode }) => !selectionMode,
  link: ({ user }) => {
    let {
      group: { _id }
    } = user;
    return {
      uri,
      props: {
        params: {
          from,
          group: { _id }
        }
      }
    };
  }
});

const notifyLocalInserts = async (props, localChanges, localKey = MetadataTable, updateSqlite) => {
  if (updateSqlite === undefined) {
    updateSqlite = localKey === MetadataTable;
  }
  const { fireLocalDataChangeListener } = props;
  fireLocalDataChangeListener &&
    (await fireLocalDataChangeListener({
      key: localKey,
      localChanges: {
        source: "action",
        ...localChanges
      },
      updateSqlite
    }));
};

const notifyLocalUpdates = (props, localChanges, localKey = MetadataTable, updateSqlite) => {
  if (updateSqlite === undefined) {
    updateSqlite = localKey === MetadataTable;
  }
  const { invokeProps, fireLocalDataChangeListener } = props;
  const { selectedIds = [] } = invokeProps;
  const localChangesArray = selectedIds.map(id => {
    return {
      _id: id,
      source: "action",
      ...localChanges
    };
  });
  localChangesArray.length &&
    fireLocalDataChangeListener &&
    fireLocalDataChangeListener({
      key: localKey,
      localChanges: localChangesArray,
      updateSqlite
    });
};

const addItemsToGroupOnLocalChange = props => {
  let { invokeProps, fireLocalDataChangeListener, user } = props;
  let { selectedIds, selectedData, data } = invokeProps;
  if (!selectedIds || !selectedIds.length || !fireLocalDataChangeListener) {
    return;
  }
  if (!selectedData || selectedData.length !== selectedIds.length) {
    selectedData = data.filter(row => selectedIds.indexOf(row._id) !== -1);
  }
  let groupGallery = [],
    groupDocs = [],
    updateData = [];
  let timePrefix = Platform.OS === "android" ? "_" : "";
  let secondsField = timePrefix + "seconds";
  let nanoSecondsField = timePrefix + "nanoseconds";
  for (let doc of selectedData) {
    let currentTime = new Date();
    let ms = currentTime.getTime();
    let timeInSecNanoSec = {
      [secondsField]: Math.floor(ms / 1000),
      [nanoSecondsField]: (ms % 1000) * 1000000
    };
    updateData.push({
      _id: doc._id,
      changes: { postedOn: timeInSecNanoSec, group: user && user.group }
    });
    if (doc.type === "image" || doc.type === "video") {
      groupGallery.push({ ...doc, postedOn: currentTime, group: user && user.group });
    } else if (doc.type === "doc") {
      groupDocs.push({ ...doc, postedOn: currentTime, group: user && user.group });
    }
  }

  updateData.length &&
    fireLocalDataChangeListener({ key: MetadataTable, localChanges: updateData, updateSqlite: true });
  if (groupGallery.length) {
    groupGallery = groupGallery.sort((a, b) => getSortValue(a, b, GROUP_GALLERY_SORT));
    notifyLocalInserts(props, { insert: groupGallery, sort: GROUP_GALLERY_SORT }, GROUP_GALLERY_LOCAL_CHANGE_KEY);
  }
  if (groupDocs.length) {
    groupDocs = groupDocs.sort((a, b) => getSortValue(a, b, GROUP_DOC_SORT));
    notifyLocalInserts(props, { insert: groupDocs, sort: GROUP_DOC_SORT }, GROUP_DOC_LOCAL_CHANGE_KEY);
  }
};

const getModalFormComponent = ({ component, modalKey }) => ({
  component,
  modalProps: {
    modalSM: {
      key: modalKey,
      stopPropagation: true,
      position: "center",
      width: 0.9,
      animationType: "none",
      style: {
        borderRadius: 4,
        overflow: "hidden"
      },
      escEnabled: false
    },
    modalMD: {
      key: modalKey,
      position: "center",
      stopPropagation: true,
      style: {
        borderRadius: 4
      },
      escEnabled: false
    }
  }
});

export const shareForm = {
  text: () => I18N.t("shareVia"),
  title: () => I18N.t("toShareTitle"),
  form: getModalFormComponent({ component: "sharePopup", modalKey: "shareForm" })
};

export const getLinkActionProps = {
  text: () => I18N.t("getLink"),
  form: getModalFormComponent({ component: "getLinkPopup", modalKey: "getLink" })
};

const shareViaEmailActionProps = {
  form: getModalFormComponent({ component: "shareViaEmailPopup", modalKey: "shareViaEmail" })
};

export const getLinkNativeActionProps = origin => {
  return {
    text: () => I18N.t("getLink"),
    image: "linkGreyIcon",
    type: "nativeGetLink",
    origin,
    visible: () => Platform.OS !== "web"
  };
};

const isNativeShareActionVisibile = (data, { getSelectedData }) => {
  if (Platform.OS === "web") {
    return false;
  }
  let selectedData = (getSelectedData && getSelectedData()) || [];
  if (!selectedData.length && data && !Array.isArray(data)) {
    selectedData = [data];
  }
  for (let doc of selectedData) {
    if (!doc.completed) {
      return false;
    }
  }
  return true;
};

const uploadMobileAction = {
  type: "upload",
  text: () => I18N.t("upload"),
  confirm: {
    titleMD: I18N.t("uploadPopupHeaderCaps"),
    message: ({ getSelectedIds }) => {
      let selectedIds = (getSelectedIds && getSelectedIds()) || [];
      return confirmMessageResolver({
        singleItemMsg: "uploadSingleItemPopupMessage",
        multipleItemsMsg: "uploadMultipleItemPopupMessage",
        selectedIds
      });
    },
    confirmText: I18N.t("yesContinue")
  },
  uploadSource: "manual",
  afterUpload: props => {
    let { replaceUri, getPath } = props;
    let url = getPath && getPath();
    if (!url) {
      return;
    }
    let pathAfterFirstHash = url.split("/home#")[1];
    if (pathAfterFirstHash) {
      let replacePath = "/home#" + pathAfterFirstHash.substring(0, pathAfterFirstHash.indexOf("/"));
      replaceUri && replaceUri({ uri: replacePath });
    } else {
      replaceUri && replaceUri({ uri: url.substring(0, url.lastIndexOf("/")) });
    }
  }
};

const actions = {
  selectItems: {
    type: "enableSelectionMode",
    text: () => I18N.t("selectItems"),
    visible: (data, { selectionMode }) => !selectionMode && data && data.length
  },
  cameraUpload: {
    type: "openCameraAndUpload",
    text: () => I18N.t("uploadFromCamera"),
    visible: (data, { selectionMode, Platform }) => Platform.OS !== "web" && !selectionMode
  },
  selectItemsWithIcon: {
    image: getImage("selectGreyIcon"),
    type: "enableSelectionMode",
    text: () => I18N.t("select"),
    visible: (data, { selectionMode }) => !selectionMode && data && data.length
  },
  selectAll: {
    type: "selectAll",
    text: () => I18N.t("selectAll"),
    visible: (data, { selectionMode, allSelected }) => !allSelected && selectionMode && data && data.length
  },
  selectNone: {
    type: "unSelectAll",
    text: () => I18N.t("selectNone"),
    visible: (data, { selectedIds }) => selectedIds.length > 0
  },
  uploadSelectAll: {
    type: "selectAll",
    text: () => I18N.t("selectAll"),
    visible: (data, { allSelected }) => !allSelected && data && data.length
  },
  uploadDocumentSelectAll: {
    type: "selectAll",
    text: () => I18N.t("selectAll"),
    modifyData: data => data.filter(row => !row.directory),
    visible: (data, { allSelected }) => !allSelected && data && data.length
  },
  uploadGalleryMobileLink: {
    ...uploadMobileLink("/upload-gallery", { waitForSync: true })
  },
  uploadVaultGalleryMobileLink: {
    ...uploadMobileLink("/upload-gallery", { waitForSync: true, secureType: SECURE_TYPES.VAULT })
  },
  uploadDocMobileLink: {
    ...uploadMobileLink("/upload-docs"),
    visible: (data, { selectionMode, Platform }) => Platform.OS === "android" && !selectionMode
  },
  uploadVaultDocMobileLink: {
    ...uploadMobileLink("/upload-docs", { secureType: SECURE_TYPES.VAULT }),
    visible: (data, { selectionMode, Platform }) => Platform.OS === "android" && !selectionMode
  },
  UploadMusicMobileLink: {
    ...uploadMobileLink("/upload-music"),
    visible: (data, { selectionMode, Platform }) => Platform.OS !== "web" && !selectionMode
  },
  uploadProfileMobileAction: {
    type: "upload",
    text: () => I18N.t("confirmCaps"),
    width: "100px",
    uploadType: "profile_pic",
    afterUpload: props => {
      let { deleteUri, getPath } = props;
      deleteUri && deleteUri(getPath && getPath(), { uri: "/upload-profile-picture" });
      showMessage && showMessage(I18N.t("updateProfilePicMessage"), 2000);
    }
  },
  uploadGalleryMobileAction: {
    ...uploadMobileAction,
    uploadType: "gallery"
  },
  uploadVaultGalleryMobileAction: {
    ...uploadMobileAction,
    uploadType: "gallery",
    secureType: SECURE_TYPES.VAULT,
    afterUpload: props => {
      let { getPath, deleteUri } = props;
      let path = getPath && getPath();
      if (path && deleteUri) {
        let vaultUri = "/vault";
        let indexOfVault = path.indexOf(vaultUri);
        if (indexOfVault >= 0) {
          let uriToRemove = path.substring(indexOfVault + vaultUri.length, path.length);
          deleteUri(path, { uri: uriToRemove });
        }
      }
    }
  },
  uploadDocumentMobileAction: {
    ...uploadMobileAction,
    uploadType: "doc"
  },
  uploadVaultDocumentMobileAction: {
    ...uploadMobileAction,
    uploadType: "doc",
    secureType: SECURE_TYPES.VAULT,
    afterUpload: props => {
      let { getPath, deleteUri } = props;
      let path = getPath && getPath();
      if (path && deleteUri) {
        let vaultUri = "/vault";
        let indexOfVault = path.indexOf(vaultUri);
        if (indexOfVault >= 0) {
          let uriToRemove = path.substring(indexOfVault + vaultUri.length, path.length);
          deleteUri(path, { uri: uriToRemove });
        }
      }
    }
  },
  uploadMusicMobileAction: {
    ...uploadMobileAction,
    uploadType: "audio"
  },
  markFavourite: {
    type: "invoke",
    image: "favouriteWhiteIcon",
    title: () => I18N.t("markFavouriteTitle"),
    notifyLocalOnInvoke: (_, props) => {
      notifyLocalUpdates(props, { changes: { favourite: true } });
    },
    postMessage: () => I18N.t("markFavouriteMessage"),
    service: (props, { urls }) => {
      const { selectedIds } = props;
      const resourceId = selectedIds[0];
      return {
        url: urls["markFavourite"],
        uriProps: {
          data: {
            resourceId
          }
        }
      };
    }
  },
  unmarkFavourite: {
    type: "invoke",
    image: "favouriteWhiteSelectedIcon",
    title: () => I18N.t("unmarkFavouriteTitle"),
    notifyLocalOnInvoke: (_, props) => {
      notifyLocalUpdates(props, { changes: { favourite: false } });
    },
    postMessage: () => I18N.t("unmarkFavouriteMessage"),
    service: (props, { urls }) => {
      const { selectedIds } = props;
      const resourceId = selectedIds[0];
      return {
        url: urls["unmarkFavourite"],
        uriProps: {
          data: {
            resourceId
          }
        }
      };
    }
  },
  moveToVault: {
    type: "invoke",
    text: () => I18N.t("moveToSafeArea"),
    title: () => I18N.t("moveToSafeArea"),
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return selectedIds.length;
      } else {
        let selectedData = getSelectedData({ data, selectedIds });
        return uploadResourceActionVisibility({ selectedData });
      }
    },
    confirm: {
      titleMD: () => I18N.t("moveToSafeAreaCaps"),
      message: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        return confirmMessageResolver({
          singleItemMsg: "moveToSafeAreaSingleMessage",
          multipleItemsMsg: "moveToSafeAreaMultipleMessage",
          selectedIds
        });
      },
      confirmText: () => I18N.t("moveToSafeArea"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: ({ selectedIds }) => {
      let message;
      if (selectedIds && selectedIds.length === 1) {
        message = I18N.t("item_moved_to_safe_area");
      } else if (selectedIds && selectedIds.length > 1) {
        message = I18N.t("items_moved_to_safe_area");
      }
      return message;
    },
    notifyLocalOnInvoke: (_, props) => {
      logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "move_to_safearea" });
      let vaultChanges = {
        remove: true,
        sqliteChanges: { secureType: SECURE_TYPES.VAULT, private: true }
      };
      const { invokeProps: { selectedData = [] } = {}, fireLocalDataChangeListener } = props;
      let vaultChangesArray = [];
      const locationMapping = {}; // maintain count of city
      const deviceFolderMapping = {};
      for (let doc of selectedData) {
        let { _id, location: { city: { lower_name: cityName } = {} } = {}, deviceFolder } = doc;
        vaultChangesArray.push({
          _id,
          source: "action",
          ...vaultChanges
        });
        if (cityName) {
          locationMapping[cityName] = locationMapping[cityName] || {
            _id: cityName,
            decrementCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount -= locationMapping[cityName].decrementCount;
              if (itemCount <= 0) {
                setTimeout(
                  () =>
                    fireLocalDataChangeListener({
                      key: PLACES_LOCAL_CHANGE_KEY,
                      localChanges: [{ _id: cityName, source: "action", remove: true }]
                    }),
                  0
                );
                return { itemCount: 0, count: 0 };
              }
              return { itemCount, count: itemCount };
            }
          };
          locationMapping[cityName].decrementCount++;
        }
        if (deviceFolder) {
          deviceFolderMapping[deviceFolder] = deviceFolderMapping[deviceFolder] || {
            _id: deviceFolder,
            decrementCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount -= deviceFolderMapping[deviceFolder].decrementCount;
              if (itemCount <= 0) {
                setTimeout(
                  () =>
                    fireLocalDataChangeListener({
                      key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
                      localChanges: [{ _id: deviceFolder, source: "action", remove: true }]
                    }),
                  0
                );
                return { itemCount: 0, count: 0 };
              }
              return { itemCount, count: itemCount };
            }
          };
          deviceFolderMapping[deviceFolder].decrementCount++;
        }
      }
      const locationChangesArray = Object.values(locationMapping);
      const deviceFolderChangesArray = Object.values(deviceFolderMapping);
      if (fireLocalDataChangeListener) {
        vaultChangesArray.length &&
          fireLocalDataChangeListener({
            key: MetadataTable,
            localChanges: vaultChangesArray,
            updateSqlite: true
          });
        locationChangesArray.length &&
          fireLocalDataChangeListener({
            key: PLACES_LOCAL_CHANGE_KEY,
            localChanges: locationChangesArray
          });
        deviceFolderChangesArray.length &&
          fireLocalDataChangeListener({
            key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
            localChanges: deviceFolderChangesArray
          });
      }
    },
    service: (props, { urls }) => {
      const { selectedIds } = props;
      return {
        url: urls["moveToVault"],
        uriProps: {
          data: {
            resourceIds: selectedIds
          }
        }
      };
    }
  },
  removeFromVault: {
    type: "invoke",
    text: () => I18N.t("removeFromSafeArea"),
    title: () => I18N.t("removeFromSafeArea"),
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return selectedIds.length;
      } else {
        let selectedData = getSelectedData({ data, selectedIds });
        return uploadResourceActionVisibility({ selectedData });
      }
    },
    confirm: {
      titleMD: () => I18N.t("removeFromSafeAreaCaps"),
      message: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        return confirmMessageResolver({
          singleItemMsg: "removeFromSafeAreaSingleMessage",
          multipleItemsMsg: "removeFromSafeAreaMultipleMessage",
          selectedIds
        });
      },
      confirmText: () => I18N.t("removeFromSafeArea"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: ({ selectedIds }) => {
      let message;
      if (selectedIds && selectedIds.length === 1) {
        message = I18N.t("item_removed_from_safe_area");
      } else if (selectedIds && selectedIds.length > 1) {
        message = I18N.t("items_removed_from_safe_area");
      }
      return message;
    },
    notifyLocalOnInvoke: (_, props) => {
      logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "remove_from_safearea" });
      let { invokeProps: { selectedData } = {}, fireLocalDataChangeListener } = props;
      if (!selectedData || !selectedData.length || !fireLocalDataChangeListener) {
        return;
      }
      let removeVaultGallery = [],
        removeVaultDocs = [],
        insertGallery = [],
        insertDocs = [];

      const locationMapping = {};
      const deviceFolderMapping = {};
      for (let data of selectedData) {
        let { type, _id, location: { city: { lower_name: cityName } = {} } = {}, deviceFolder } = data;
        if (cityName) {
          locationMapping[cityName] = locationMapping[cityName] || {
            _id: cityName,
            incCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount += locationMapping[cityName].incCount;
              return { itemCount, count: itemCount };
            }
          };
          locationMapping[cityName].incCount++;
        }
        if (deviceFolder) {
          deviceFolderMapping[deviceFolder] = deviceFolderMapping[deviceFolder] || {
            _id: deviceFolder,
            incCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount += deviceFolderMapping[deviceFolder].incCount;
              return { itemCount, count: itemCount };
            }
          };
          deviceFolderMapping[deviceFolder].incCount++;
        }
        if (type === "image" || type === "video") {
          removeVaultGallery.push({ _id, remove: true, sqliteChanges: { secureType: SECURE_TYPES.DEFAULT } });
          insertGallery.push({ ...data, secureType: SECURE_TYPES.DEFAULT });
        } else if (type === "doc") {
          removeVaultDocs.push({ _id, remove: true, sqliteChanges: { secureType: SECURE_TYPES.DEFAULT } });
          insertDocs.push({ ...data, secureType: SECURE_TYPES.DEFAULT });
        }
      }
      let locationChangesArray = Object.values(locationMapping);
      let deviceFolderChangesArray = Object.values(deviceFolderMapping);
      if (locationChangesArray.length) {
        fireLocalDataChangeListener({
          key: PLACES_LOCAL_CHANGE_KEY,
          localChanges: locationChangesArray
        });
      }
      if (deviceFolderChangesArray.length) {
        fireLocalDataChangeListener({
          key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
          localChanges: deviceFolderChangesArray
        });
      }
      if (removeVaultGallery.length) {
        fireLocalDataChangeListener({
          key: METADATA_TABLE,
          localChanges: removeVaultGallery,
          updateSqlite: true
        });
      }
      if (removeVaultDocs.length) {
        fireLocalDataChangeListener({
          key: METADATA_TABLE,
          localChanges: removeVaultDocs,
          updateSqlite: true
        });
      }
      if (insertGallery.length) {
        insertGallery.sort((a, b) => getSortValue(a, b, GALLERY_ALL_SORT));
        notifyLocalInserts(props, { insert: insertGallery, sort: GALLERY_ALL_SORT }, GALLERY_ALL_LOCAL_CHANGE_KEY);
      }
      if (insertDocs.length) {
        insertDocs.sort((a, b) => getSortValue(a, b, DOCS_ALL_SORT));
        notifyLocalInserts(props, { insert: insertDocs, sort: DOCS_ALL_SORT }, DOCS_ALL_LOCAL_CHANGE_KEY);
      }
    },
    service: (props, { urls }) => {
      const { selectedIds } = props;
      return {
        url: urls["removeFromVault"],
        uriProps: {
          data: {
            resourceIds: selectedIds
          }
        }
      };
    }
  },
  moveToArchive: {
    type: "invoke",
    text: () => I18N.t("moveToArchive"),
    title: () => I18N.t("moveToArchive"),
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return selectedIds.length;
      } else {
        let selectedData = getSelectedData({ data, selectedIds });
        return uploadResourceActionVisibility({ selectedData });
      }
    },
    confirm: {
      titleMD: () => I18N.t("moveToArchiveCaps"),
      message: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        return confirmMessageResolver({
          singleItemMsg: "moveToArchiveSingleMessage",
          multipleItemsMsg: "moveToArchiveMultipleMessage",
          selectedIds
        });
      },
      confirmText: () => I18N.t("moveToArchive"),
      cancelText: () => I18N.t("cancel")
    },
    // postMessage: ({ selectedIds,...rest }) => {
    //   let message;
    //   console.log("RESTTTTTTTTTTTTTTTTTTTTTTTTTTTTT",rest);
    //   if (selectedIds && selectedIds.length === 1) {
    //     message = I18N.t("item_moved_to_archive");
    //   } else if (selectedIds && selectedIds.length > 1) {
    //     message = I18N.t("items_moved_to_archive");
    //   }
    //   return message;
    // },
    service: (props, { urls }) => {
      const { selectedIds } = props;
      return {
        url: urls["moveToArchive"],
        uriProps: {
          data: {
            resourceIds: selectedIds
          }
        }
      };
    },
    afterInvoke: result => {
      let { archived, ignored } = result;
      let message = I18N.t("items_moved_to_archive");
      message = message.replace("__archived__", archived);
      message = message.replace("__total__", archived + ignored);
      showMessage && showMessage(message, 3000);
    },
    notifyLocalOnInvoke: (_, props) => {
      logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "move_to_archive" });
      let changes = {
        remove: true,
        sqliteChanges: { secureType: SECURE_TYPES.ARCHIVE }
      };
      const { invokeProps: { selectedData = [] } = {}, fireLocalDataChangeListener } = props;
      let changesArray = [];
      const locationMapping = {}; // maintain count of city
      const deviceFolderMapping = {}; // maintain count of city
      for (let doc of selectedData) {
        let { _id, location: { city: { lower_name: cityName } = {} } = {}, deviceFolder } = doc;
        changesArray.push({
          _id,
          source: "action",
          ...changes
        });
        if (cityName) {
          locationMapping[cityName] = locationMapping[cityName] || {
            _id: cityName,
            decrementCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount -= locationMapping[cityName].decrementCount;
              if (itemCount <= 0) {
                setTimeout(
                  () =>
                    fireLocalDataChangeListener({
                      key: PLACES_LOCAL_CHANGE_KEY,
                      localChanges: [{ _id: cityName, source: "action", remove: true }]
                    }),
                  0
                );
                return { itemCount: 0, count: 0 };
              }
              return { itemCount, count: itemCount };
            }
          };
          locationMapping[cityName].decrementCount++;
        }
        if (deviceFolder) {
          deviceFolderMapping[deviceFolder] = deviceFolderMapping[deviceFolder] || {
            _id: deviceFolder,
            decrementCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount -= deviceFolderMapping[deviceFolder].decrementCount;
              if (itemCount <= 0) {
                setTimeout(
                  () =>
                    fireLocalDataChangeListener({
                      key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
                      localChanges: [{ _id: deviceFolder, source: "action", remove: true }]
                    }),
                  0
                );
                return { itemCount: 0, count: 0 };
              }
              return { itemCount, count: itemCount };
            }
          };
          deviceFolderMapping[deviceFolder].decrementCount++;
        }
      }
      const locationChangesArray = Object.values(locationMapping);
      const deviceFolderChangesArray = Object.values(deviceFolderMapping);
      if (fireLocalDataChangeListener) {
        changesArray.length &&
          fireLocalDataChangeListener({
            key: MetadataTable,
            localChanges: changesArray,
            updateSqlite: true
          });
        locationChangesArray.length &&
          fireLocalDataChangeListener({
            key: PLACES_LOCAL_CHANGE_KEY,
            localChanges: locationChangesArray
          });
        deviceFolderChangesArray.length &&
          fireLocalDataChangeListener({
            key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
            localChanges: deviceFolderChangesArray
          });
      }
    }
  },
  removeFromArchive: {
    type: "invoke",
    text: () => I18N.t("removeFromArchive"),
    title: () => I18N.t("removeFromArchive"),
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return selectedIds.length;
      } else {
        let selectedData = getSelectedData({ data, selectedIds });
        return uploadResourceActionVisibility({ selectedData });
      }
    },
    confirm: {
      titleMD: () => I18N.t("removeFromArchiveCaps"),
      message: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        return confirmMessageResolver({
          singleItemMsg: "removeFromArchiveSingleMessage",
          multipleItemsMsg: "removeFromArchiveMultipleMessage",
          selectedIds
        });
      },
      confirmText: () => I18N.t("removeFromArchive"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: ({ selectedIds }) => {
      let message;
      if (selectedIds && selectedIds.length === 1) {
        message = I18N.t("item_removed_from_archive");
      } else if (selectedIds && selectedIds.length > 1) {
        message = I18N.t("items_removed_from_archive");
      }
      return message;
    },
    service: (props, { urls }) => {
      const { selectedIds } = props;
      return {
        url: urls["removeFromArchive"],
        uriProps: {
          data: {
            resourceIds: selectedIds
          }
        }
      };
    },
    notifyLocalOnInvoke: (_, props) => {
      logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "remove_from_safearea" });
      let { invokeProps: { selectedData } = {}, fireLocalDataChangeListener } = props;
      if (!selectedData || !selectedData.length || !fireLocalDataChangeListener) {
        return;
      }
      let removeGallery = [],
        removeDocs = [],
        removeMusic = [],
        insertGallery = [],
        insertDocs = [],
        insertMusic = [];

      const locationMapping = {};
      const deviceFolderMapping = {};
      for (let data of selectedData) {
        let { type, _id, location: { city: { lower_name: cityName } = {} } = {}, deviceFolder } = data;
        if (cityName) {
          locationMapping[cityName] = locationMapping[cityName] || {
            _id: cityName,
            incCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount += locationMapping[cityName].incCount;
              return { itemCount, count: itemCount };
            }
          };
          locationMapping[cityName].incCount++;
        }
        if (deviceFolder) {
          deviceFolderMapping[deviceFolder] = deviceFolderMapping[deviceFolder] || {
            _id: deviceFolder,
            incCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount += deviceFolderMapping[deviceFolder].incCount;
              return { itemCount, count: itemCount };
            }
          };
          deviceFolderMapping[deviceFolder].incCount++;
        }
        if (type === "image" || type === "video") {
          removeGallery.push({ _id, remove: true, sqliteChanges: { secureType: SECURE_TYPES.DEFAULT } });
          insertGallery.push({ ...data, secureType: SECURE_TYPES.DEFAULT });
        } else if (type === "doc") {
          removeDocs.push({ _id, remove: true, sqliteChanges: { secureType: SECURE_TYPES.DEFAULT } });
          insertDocs.push({ ...data, secureType: SECURE_TYPES.DEFAULT });
        } else if (type === "audio") {
          removeMusic.push({ _id, remove: true, sqliteChanges: { secureType: SECURE_TYPES.DEFAULT } });
          insertMusic.push({ ...data, secureType: SECURE_TYPES.DEFAULT });
        }
      }
      let locationChangesArray = Object.values(locationMapping);
      let deviceFolderChangesArray = Object.values(deviceFolderMapping);
      if (locationChangesArray.length) {
        fireLocalDataChangeListener({
          key: PLACES_LOCAL_CHANGE_KEY,
          localChanges: locationChangesArray
        });
      }
      if (deviceFolderChangesArray.length) {
        fireLocalDataChangeListener({
          key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
          localChanges: deviceFolderChangesArray
        });
      }
      if (removeGallery.length) {
        fireLocalDataChangeListener({
          key: METADATA_TABLE,
          localChanges: removeGallery,
          updateSqlite: true
        });
      }
      if (removeDocs.length) {
        fireLocalDataChangeListener({
          key: METADATA_TABLE,
          localChanges: removeDocs,
          updateSqlite: true
        });
      }
      if (removeMusic.length) {
        fireLocalDataChangeListener({
          key: METADATA_TABLE,
          localChanges: removeMusic,
          updateSqlite: true
        });
      }
      if (insertGallery.length) {
        insertGallery.sort((a, b) => getSortValue(a, b, GALLERY_ALL_SORT));
        notifyLocalInserts(props, { insert: insertGallery, sort: GALLERY_ALL_SORT }, GALLERY_ALL_LOCAL_CHANGE_KEY);
      }
      if (insertDocs.length) {
        insertDocs.sort((a, b) => getSortValue(a, b, DOCS_ALL_SORT));
        notifyLocalInserts(props, { insert: insertDocs, sort: DOCS_ALL_SORT }, DOCS_ALL_LOCAL_CHANGE_KEY);
      }
      if (insertMusic.length) {
        insertMusic.sort((a, b) => getSortValue(a, b, MUSIC_ALL_SORT));
        notifyLocalInserts(props, { insert: insertDocs, sort: MUSIC_ALL_SORT }, MUSIC_ALL_LOCAL_CHANGE_KEY);
      }
    }
  },
  markDelete: {
    type: "invoke",
    text: () => I18N.t("delete"),
    title: () => I18N.t("delete"),
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return selectedIds.length;
      } else {
        let selectedData = getSelectedData({ data, selectedIds });
        return uploadResourceActionVisibility({ selectedData });
      }
    },
    confirm: {
      titleMD: () => I18N.t("deletePopupHeaderCaps"),
      messageMD: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        return confirmMessageResolver({
          singleItemMsg: "deleteSingleItemPopupMessageMD",
          multipleItemsMsg: "deleteMultipleItemPopupMessageMD",
          selectedIds
        });
      },
      messageSM: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        return confirmMessageResolver({
          singleItemMsg: "deleteSingleItemPopupMessageSM",
          multipleItemsMsg: "deleteMultipleItemPopupMessageSM",
          selectedIds
        });
      },
      confirmText: () => I18N.t("moveToTrash"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: ({ selectedIds }) => {
      let message;
      if (selectedIds && selectedIds.length === 1) {
        message = I18N.t("item_send_to_trash");
      } else if (selectedIds && selectedIds.length > 1) {
        message = I18N.t("items_send_to_trash");
      }
      return message;
    },
    notifyLocalOnInvoke: (_, props) => {
      let timePrefix = Platform.OS === "android" ? "_" : "";
      let secondsField = timePrefix + "seconds";
      let nanoSecondsField = timePrefix + "nanoseconds";
      let currentTimeInMS = new Date().getTime();
      let currentTime = {
        [secondsField]: Math.floor(currentTimeInMS / 1000),
        [nanoSecondsField]: (currentTimeInMS % 1000) * 1000000
      };
      let deleteChanges = {
        remove: true,
        sqliteChanges: { deleted: true, deletedOn: currentTime }
      };
      const { invokeProps: { selectedData = [] } = {}, fireLocalDataChangeListener } = props;
      let deleteChangesArray = [];
      const locationMapping = {}; // maintain count of city
      const deviceFolderMapping = {};
      for (let doc of selectedData) {
        let { _id, secureType, location: { city: { lower_name: cityName } = {} } = {}, deviceFolder } = doc;
        deleteChangesArray.push({
          _id,
          source: "action",
          ...deleteChanges
        });
        if (cityName && secureType === SECURE_TYPES.DEFAULT) {
          locationMapping[cityName] = locationMapping[cityName] || {
            _id: cityName,
            decrementCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount -= locationMapping[cityName].decrementCount;
              if (itemCount <= 0) {
                setTimeout(
                  () =>
                    fireLocalDataChangeListener({
                      key: PLACES_LOCAL_CHANGE_KEY,
                      localChanges: { _id: cityName, source: "action", remove: true }
                    }),
                  0
                );
                return { itemCount: 0, count: 0 };
              }
              return { itemCount, count: itemCount };
            }
          };
          locationMapping[cityName].decrementCount++;
        }
        if (deviceFolder && secureType === SECURE_TYPES.DEFAULT) {
          deviceFolderMapping[deviceFolder] = deviceFolderMapping[deviceFolder] || {
            _id: deviceFolder,
            decrementCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount -= deviceFolderMapping[deviceFolder].decrementCount;
              if (itemCount <= 0) {
                setTimeout(
                  () =>
                    fireLocalDataChangeListener({
                      key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
                      localChanges: { _id: deviceFolder, source: "action", remove: true }
                    }),
                  0
                );
                return { itemCount: 0, count: 0 };
              }
              return { itemCount, count: itemCount };
            }
          };
          deviceFolderMapping[deviceFolder].decrementCount++;
        }
      }

      const locationChangesArray = Object.values(locationMapping);
      const deviceFolderChangesArray = Object.values(deviceFolderMapping);
      if (fireLocalDataChangeListener) {
        deleteChangesArray.length &&
          fireLocalDataChangeListener({
            key: MetadataTable,
            localChanges: deleteChangesArray,
            updateSqlite: true
          });
        locationChangesArray.length &&
          fireLocalDataChangeListener({
            key: PLACES_LOCAL_CHANGE_KEY,
            localChanges: locationChangesArray
          });
        deviceFolderChangesArray.length &&
          fireLocalDataChangeListener({
            key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
            localChanges: deviceFolderChangesArray
          });
      }
    },
    service: (props, { urls }) => {
      const { selectedIds } = props;
      const commandToPost = {
        url: urls["markAsDelete"],
        uriProps: {
          data: selectedIds
        }
      };
      return commandToPost;
    },
    afterInvoke: deletedIds => {
      if (Platform.OS !== "web") {
        for (let resource of deletedIds) {
          removeCurrentUploadData({ resource });
        }
      }
    }
  },
  makePrivate: {
    type: "invoke",
    notifyLocalOnInvoke: (_, props) => {
      notifyLocalUpdates(props, { changes: { private: true } });
    },
    service: (props, { urls }) => {
      const { selectedIds } = props;
      const commandToPost = {
        url: urls["markPrivate"],
        uriProps: {
          data: { resourceIds: selectedIds }
        }
      };
      return commandToPost;
    },
    postMessage: () => I18N.t("collectionMakePrivate"),
    text: () => I18N.t("makePrivate"),
    title: () => I18N.t("makePrivate"),
    visible: (data, { selectedIds }) => {
      return makePrivateActionVisibility({ data, selectedIds });
    },
    confirm: {
      titleMD: () => I18N.t("makePrivatePopupHeaderCaps"),
      confirmText: () => I18N.t("makePrivate"),
      cancelText: () => I18N.t("cancel"),
      message: () => I18N.t("makePrivateResourcePopupMessage")
    }
  },
  saveAsPdf: {
    // type: "saveAsPdf",
    text: () => I18N.t("saveAsPdf"),
    title: () => I18N.t("saveAsPdf"),
    form: savePDFNameForm,
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return false;
      } else {
        if (!data.length || !selectedIds.length) {
          return false;
        }
        let selectedIdsMap = {};
        selectedIds.forEach(selectedId => {
          selectedIdsMap[selectedId] = 1;
        });
        for (let index = 0; index < data.length; index++) {
          let doc = data[index];
          if (selectedIdsMap[doc._id] && !doc["uploaded"]) {
            return false;
          }
        }
        return true;
      }
    }
  },
  saveAsPdfWeb: {
    // type: "invoke",
    // service: (props, { urls }) => {
    //   const { selectedIds } = props;
    //   const commandToPost = {
    //     url: urls["saveAsPdfWeb"],
    //     uriProps: {
    //       data: { resourceIds: selectedIds }
    //     }
    //   };
    //   return commandToPost;
    // },
    // postMessage: () => I18N.t("pdfMessage"),
    text: () => I18N.t("saveAsPdf"),
    title: () => I18N.t("saveAsPdf"),
    form: savePDFNameForm,
    visible: (data = [], { selectedIds, Platform }) => {
      if (Platform.OS !== "web") {
        return false;
      } else {
        if (!data.length || !selectedIds.length) {
          return false;
        }
        let selectedIdsMap = {};
        selectedIds.forEach(selectedId => {
          selectedIdsMap[selectedId] = 1;
        });
        for (let index = 0; index < data.length; index++) {
          let doc = data[index];
          if (selectedIdsMap[doc._id] && !doc["uploaded"]) {
            return false;
          }
        }
        return true;
      }
    }
  },
  saveAsGif: {
    text: () => I18N.t("saveAsGif"),
    title: () => I18N.t("saveAsGif"),
    form: saveGIFNameForm,
    visible: (data = [], { selectedIds = [] }) => {
      if (!data.length || selectedIds.length <= 1) {
        return false;
      }
      let selectedIdsMap = {};
      selectedIds.forEach(selectedId => {
        selectedIdsMap[selectedId] = 1;
      });
      let isNonGifSelected = false;
      for (let index = 0; index < data.length; index++) {
        let doc = data[index];
        if (selectedIdsMap[doc._id]) {
          if (doc["type"] !== "image" || !doc["uploaded"]) {
            return false;
          }
          if (!isNonGifSelected) {
            let ext_small = doc["ext"] && doc["ext"].toLowerCase();
            if (ext_small !== "gif") {
              isNonGifSelected = true;
            }
          }
        }
      }
      return isNonGifSelected;
    }
  },
  downloadAsPdf: {
    text: () => I18N.t("downloadAsPdf"),
    title: () => I18N.t("downloadAsPdf"),
    form: savePDFNameForm,
    downloadPdf: Platform.OS === "android",
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS !== "android") {
        return false;
      } else {
        if (!data.length || !selectedIds.length) {
          return false;
        }
        let selectedIdsMap = {};
        selectedIds.forEach(selectedId => {
          selectedIdsMap[selectedId] = 1;
        });
        for (let index = 0; index < data.length; index++) {
          let doc = data[index];
          if (selectedIdsMap[doc._id] && (doc["type"] !== "image" || !doc["uploaded"])) {
            return false;
          }
        }
        return true;
      }
    }
  },
  downloadZipAll: {
    type: "downloadZip",
    text: () => I18N.t("downloadAsZip"),
    title: () => I18N.t("downloadAsZip"),
    image: "downloadZipIcon",
    // visible: (data = [], { selectionMode, Platform }) =>
    //   downloadZipURL && Platform.OS === "web" && data.length && !selectionMode,
    visible: () => false
  },
  addImagePdfGenerator: {
    type: "addImageToPdfGen",
    text: () => I18N.t("moveToArchive"),
    title: () => I18N.t("moveToArchive"),
    visible: (data = [], { selectedIds = [] }) => {
      return true;
    }
  },
  // called for selected data
  downloadZip: {
    type: "downloadZip",
    text: () => I18N.t("downloadAsZip"),
    title: () => I18N.t("downloadAsZip"),
    // visible: (data = [], { Platform, selectedIds = [] }) =>
    //   downloadZipURL && Platform.OS === "web" && selectedIds.length,
    visible: () => false
  },
  download: {
    type: "download",
    text: () => I18N.t("download"),
    title: () => I18N.t("download"),
    visible: (data = [], { selectedIds = [] }) => {
      if (Platform.OS === "web") {
        return selectedIds.length;
      } else {
        let selectedData = getSelectedData({ data, selectedIds });
        return uploadResourceActionVisibility({ selectedData });
      }
    }
  },
  rename: {
    text: () => I18N.t("rename"),
    title: () => I18N.t("rename"),
    visible: (data, { selectedIds }) => {
      if (selectedIds.length !== 1) {
        return false;
      }
      let selectedData = (getSelectedData && getSelectedData({ data, selectedIds })) || [];
      return selectedData[0] && !isResourceUploading(selectedData[0]);
    },
    form: renameResourceForm
  },
  addImagesToAlbumForm: {
    text: () => I18N.t("addToAlbum"),
    title: () => I18N.t("addToAlbum"),
    ...addItemsToCollectionFormProps("gallery", "albums")
  },
  addDocsToSetForm: {
    text: () => I18N.t("addToSet"),
    title: () => I18N.t("addToSet"),
    ...addItemsToCollectionFormProps("doc", "sets")
  },
  addMusicToPlaylistForm: {
    text: () => I18N.t("addToPlaylist"),
    title: () => I18N.t("addToPlaylist"),
    ...addItemsToCollectionFormProps("music", "playlists")
  },
  addImagesToAlbumLink: {
    title: () => I18N.t("addToAlbum"),
    text: () => I18N.t("addToAlbum"),
    ...addItemsToCollectionLinkProps("gallery", "/select-album")
  },
  addDocsToSetLink: {
    title: () => I18N.t("addToSet"),
    text: () => I18N.t("addToSet"),
    ...addItemsToCollectionLinkProps("doc", "/select-set")
  },
  addMusicToPlaylistLink: {
    title: () => I18N.t("addToPlaylist"),
    text: () => I18N.t("addToPlaylist"),
    ...addItemsToCollectionLinkProps("music", "/select-playlist")
  },
  addImagesToVaultAlbumLink: {
    title: () => I18N.t("addToAlbum"),
    text: () => I18N.t("addToAlbum"),
    ...addItemsToCollectionLinkProps("vaultAlbum", "/select-vault-album")
  },
  addDocsToVaultSetLink: {
    title: () => I18N.t("addToSet"),
    text: () => I18N.t("addToSet"),
    ...addItemsToCollectionLinkProps("vaultSet", "/select-vault-set")
  },
  addImagesToVaultAlbumForm: {
    text: () => I18N.t("addToAlbum"),
    title: () => I18N.t("addToAlbum"),
    ...addItemsToCollectionFormProps("vaultAlbum", "vaultAlbums")
  },
  addDocsToVaultSetForm: {
    text: () => I18N.t("addToSet"),
    title: () => I18N.t("addToSet"),
    ...addItemsToCollectionFormProps("vaultSet", "vaultSets")
  },
  removeFromCollection: {
    text: () => I18N.t("remove"),
    visible: (data, { selectedIds }) => selectedIds.length > 0,
    origin: "doc",
    type: "invoke",
    confirm: {
      titleMD: () => I18N.t("removePopupHeaderCaps"),
      confirmText: () => I18N.t("remove"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: ({ selectedIds }) => `${selectedIds.length || ""} ${I18N.t("removeItemMessage")}`,
    notifyLocalOnInvoke: (_, props) => {
      let { invokeProps, fireLocalDataChangeListener } = props;
      const { link, selectedData } = invokeProps;
      let localListenerKey = void 0;
      let origin = link && link.origin;
      if (origin === "album") {
        localListenerKey = ALBUM_DETAIL_LOCAL_CHANGE_KEY;
      } else if (origin === "set") {
        localListenerKey = SET_DETAIL_LOCAL_CHANGE_KEY;
      } else if (origin === "playlist") {
        localListenerKey = PLAYLIST_DETAIL_LOCAL_CHANGE_KEY;
      } else if (origin === "vaultAlbum") {
        localListenerKey = VAULT_ALBUM_DETAIL_LOCAL_CHANGE_KEY;
      } else if (origin === "vaultSet") {
        localListenerKey = VAULT_SET_DETAIL_LOCAL_CHANGE_KEY;
      }
      if (!localListenerKey) {
        return;
      }
      // remove resources from collection detail
      notifyLocalUpdates(props, { remove: true }, localListenerKey);
      // update collections array for resources removed
      let { props: { params: { collection: { _id: collectionId } = {} } = {} } = {} } = link || {};
      if (collectionId && selectedData.length) {
        let localChanges = [];
        selectedData.forEach(doc => {
          let { collections, _id } = doc;
          collections = collections || [];
          if (collections.indexOf(collectionId) > -1) {
            collections.splice(collections.indexOf(collectionId), 1);
            localChanges.push({ _id, source: "action", changes: { collections } });
          }
        });
        localChanges.length &&
          fireLocalDataChangeListener &&
          fireLocalDataChangeListener({ key: MetadataTable, localChanges, updateSqlite: true });
      }
    },
    service: (props, { urls }) => {
      const { selectedIds, selectedData } = props;
      let firstData = selectedData[0];
      let items = selectedIds.map(selectedId => {
        return { _id: selectedId };
      });
      let data = {
        _id: firstData.collection._id,
        items
      };
      const commandToPost = {
        url: urls["removeItemFromCollection"],
        uriProps: {
          data
        }
      };
      return commandToPost;
    }
  },
  // called from addItems from collection or collectionDetail
  selectCollectionAddItems: {
    type: "invoke",
    afterInvoke: (result, props) => {
      let { deleteUri, link, getPath } = props;
      deleteUri && deleteUri(getPath && getPath(), link);
    },
    skipClearSelection: true,
    postMessage: props => {
      let { link, selectedIds } = props;
      let linkParams = link && link.props && link.props.params;
      let collectionName = linkParams && linkParams.collectionName;
      let collection = linkParams && linkParams.collection;
      let origin = linkParams && linkParams.origin;
      if (collectionName && origin) {
        let message = I18N.t(`${origin}Created`);
        if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
          message = I18N.t("createdSuccessfully");
        }
        return message;
      } else if (collection && origin) {
        let messageMapping = {
          album: I18N.t("itemAddedToAlbum"),
          set: I18N.t("itemAddedToSet"),
          playlist: I18N.t("itemAddedToPlaylist"),
          vaultAlbum: I18N.t("itemAddedToAlbum"),
          vaultSet: I18N.t("itemAddedToSet")
        };
        let message = messageMapping[origin];
        const itemsLength = selectedIds && selectedIds.length;
        if (itemsLength > 1) {
          messageMapping = {
            album: I18N.t("itemsAddedToAlbum"),
            set: I18N.t("itemsAddedToSet"),
            playlist: I18N.t("itemsAddedToPlaylist"),
            vaultAlbum: I18N.t("itemsAddedToAlbum"),
            vaultSet: I18N.t("itemsAddedToSet")
          };
          message = `${itemsLength} ${messageMapping[origin]}`;
        }
        if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
          message = I18N.t("itemsAddedSuccessfully");
        }
        return message;
      }
    },
    notifyLocalOnInvoke: (_, props) => {
      let { invokeProps, invokeResult, fireLocalDataChangeListener } = props;
      let { link, selectedIds, selectedData, data } = invokeProps;
      let localListenerKey = void 0;
      let localSort = void 0;
      let origin = link && link.props && link.props.params && link.props.params.origin;
      let collection = link && link.props && link.props.params && link.props.params.collection;
      let collectionId = collection && collection._id;

      if (origin === "album") {
        localListenerKey = ALBUM_DETAIL_LOCAL_CHANGE_KEY;
        localSort = GALLERY_ALL_SORT;
      } else if (origin === "set") {
        localListenerKey = SET_DETAIL_LOCAL_CHANGE_KEY;
        localSort = DOCS_ALL_SORT;
      } else if (origin === "playlist") {
        localListenerKey = PLAYLIST_DETAIL_LOCAL_CHANGE_KEY;
        localSort = MUSIC_ALL_SORT;
      } else if (origin === "vaultAlbum") {
        localListenerKey = VAULT_ALBUM_DETAIL_LOCAL_CHANGE_KEY;
      } else if (origin === "vaultSet") {
        localListenerKey = VAULT_SET_DETAIL_LOCAL_CHANGE_KEY;
      }
      if (!localListenerKey) {
        return;
      }
      if (!selectedIds || !selectedIds.length) {
        return;
      }
      if (!selectedData || selectedData.length !== selectedIds.length) {
        selectedData = data.filter(row => selectedIds.indexOf(row._id) !== -1);
      }
      let user = getUser && getUser();
      let insertData = normalizeCollectionItems({ result: selectedData, user, collectionId, collection });
      insertData = insertData.sort((a, b) => getSortValue(a, b, localSort));
      // listener to insert items on collection detail view
      notifyLocalInserts(props, { insert: insertData, sort: localSort }, localListenerKey);
      // update collections array for resources added
      collectionId = collectionId || (invokeResult && invokeResult.collectionId);
      if (collectionId) {
        let localChanges = [];
        insertData.forEach(doc => {
          let { collections, _id } = doc;
          collections = collections || [];
          if (!collections.includes(collectionId)) {
            collections.push(collectionId);
            localChanges.push({ _id, source: "action", changes: { collections } });
          }
        });
        localChanges.length &&
          fireLocalDataChangeListener &&
          fireLocalDataChangeListener({ key: MetadataTable, localChanges, updateSqlite: true });
      }
    },
    service: (props, { urls }) => {
      let { link, selectedIds } = props || {};
      let linkParams = link && link.props && link.props.params;

      let collectionName = linkParams && linkParams.collectionName;
      let origin = linkParams && linkParams.origin;
      let collection = linkParams && linkParams.collection;

      const items = selectedIds.map(_id => ({ _id }));
      let commandToPost;
      if (collectionName) {
        commandToPost = {
          url: urls["createCollection"],
          uriProps: {
            data: {
              name: collectionName,
              type: origin,
              items
            }
          }
        };
      } else {
        commandToPost = {
          url: urls["addItemToCollection"],
          uriProps: {
            data: {
              _id: collection._id,
              items
            }
          }
        };
      }
      return commandToPost;
    }
  },
  selectPostToGroupItems: {
    type: "invoke",
    afterInvoke: (result, props) => {
      let { replaceUri, link } = props;
      replaceUri &&
        replaceUri({
          uri: link.parentUrl
        });
    },
    notifyLocalOnInvoke: (_, props) => {
      addItemsToGroupOnLocalChange(props);
    },
    postMessage: props => {
      let { selectedIds } = props || {};
      const items = selectedIds.map(_id => ({ _id }));
      let message = I18N.t("itemPostToGroup");
      if (items.length > 1) {
        message = `${items.length} ${I18N.t("itemsPostToGroup")}`;
      }
      return message;
    },
    skipClearSelection: true,
    service: (props, { urls }) => {
      let { selectedIds, user } = props || {};
      const items = selectedIds.map(_id => ({ _id }));
      let commandToPost;
      commandToPost = {
        url: urls["addGroupItem"],
        uriProps: {
          data: {
            _id: user && user.group && user.group._id,
            items
          }
        }
      };
      return commandToPost;
    }
  },
  selectDocsForGroup: {
    type: "link",
    text: () => I18N.t("post"),
    link: () => {
      return {
        uri: "/select-docs",
        props: {
          params: {
            excludeGroup: true,
            origin: "groupDoc"
          }
        }
      };
    }
  },
  selectDocsForGroupWeb: {
    text: () => I18N.t("post"),
    ...docSelectItemsProps,
    origin: "groupDoc",
    form: selectItemsForm
  },
  selectDocsForSafeWeb: {
    text: () => I18N.t("moveToSafeArea"),
    image: "menuSafeAreaIcon",
    ...docSelectItemsProps,
    origin: "vaultDocs",
    form: selectItemsForm
  },
  selectDocsForArchiveWeb: {
    text: () => I18N.t("moveToArchive"),
    image: "menuArchiveIcon",
    ...docSelectItemsProps,
    origin: "archiveDocs",
    form: selectItemsForm
  },
  selectImagesForGroup: {
    type: "link",
    text: () => I18N.t("post"),
    link: () => {
      return {
        uri: "/select-image",
        props: {
          params: {
            excludeGroup: true,
            origin: "groupImage"
          }
        }
      };
    }
  },
  selectImagesForSafe: {
    type: "link",
    text: () => I18N.t("moveToSafeArea"),
    visible: (data, { selectedIds = [], selectionMode }) => !selectedIds.length && !selectionMode,
    link: () => ({
      uri: "/select-image",
      props: {
        params: {
          origin: "vaultGallery"
        }
      }
    })
  },
  selectDocsForSafe: {
    type: "link",
    text: () => I18N.t("moveToSafeArea"),
    visible: (data, { selectedIds = [], selectionMode }) => !selectedIds.length && !selectionMode,
    link: () => ({
      uri: "/select-docs",
      props: {
        params: {
          origin: "vaultDocs"
        }
      }
    })
  },
  selectImagesForArchive: {
    type: "link",
    text: () => I18N.t("moveToArchive"),
    visible: (data, { selectedIds = [], selectionMode }) => !selectedIds.length && !selectionMode,
    link: () => ({
      uri: "/select-image",
      props: {
        params: {
          origin: "archiveGallery"
        }
      }
    })
  },
  selectDocsForArchive: {
    type: "link",
    text: () => I18N.t("moveToArchive"),
    visible: (data, { selectedIds = [], selectionMode }) => !selectedIds.length && !selectionMode,
    link: () => ({
      uri: "/select-docs",
      props: {
        params: {
          origin: "archiveDocs"
        }
      }
    })
  },
  selectMusicsForArchive: {
    type: "link",
    text: () => I18N.t("moveToArchive"),
    visible: (data, { selectedIds = [], selectionMode }) => !selectedIds.length && !selectionMode,
    link: () => ({
      uri: "/select-music",
      props: {
        params: {
          origin: "archiveMusic"
        }
      }
    })
  },
  selectImagesForGroupWeb: {
    text: () => I18N.t("post"),
    ...gallerySelectItemsProps,
    origin: "groupImage",
    form: selectItemsForm
  },
  selectImagesForSafeWeb: {
    text: () => I18N.t("moveToSafeArea"),
    image: "menuSafeAreaIcon",
    ...gallerySelectItemsProps,
    origin: "vaultGallery",
    form: selectItemsForm
  },
  selectImagesForArchiveWeb: {
    text: () => I18N.t("moveToArchive"),
    image: "menuArchiveIcon",
    ...gallerySelectItemsProps,
    origin: "archiveGallery",
    form: selectItemsForm
  },
  selectMusicsForArchiveWeb: {
    text: () => I18N.t("moveToArchive"),
    image: "menuArchiveIcon",
    ...musicSelectItemsProps,
    origin: "archiveMusic",
    form: selectItemsForm
  },
  importItemFromGroup: {
    type: "invoke",
    title: () => I18N.t("import"),
    visible: (data, props) => isImportFromGroupVisible({ ...props, data }),
    afterInvoke: (result, props) => {
      let itemsCount = result && result.length;
      let importedItemsCount = 0;
      let count = 0;
      let message = I18N.t("itemImportedMessage");
      for (const row of result) {
        let errorCode = row && row.error && row.error.code && row.error.code;
        let res = row && row.result;
        if (res === "exported") {
          count = count + 1;
        }
        if (errorCode === "share/alreadyExported") {
          importedItemsCount = importedItemsCount + 1;
        }
        if (errorCode === "uploader/limit-exceeds") {
          message = I18N.t("notEnoughSpace");
          break;
        }
        if (errorCode === "uploader/name-length") {
          message = I18N.t("uploader/name-length");
          break;
        }
      }
      if (count === itemsCount) {
        message = I18N.t("importItem").replace("__itemcount__", count);
      } else if (importedItemsCount === itemsCount) {
        message = I18N.t("importItemExists").replace("__itemcount__", importedItemsCount);
      } else {
        message =
          I18N.t("importItem").replace("__itemcount__", count) +
          " " +
          I18N.t("and") +
          " " +
          I18N.t("importItemExists").replace("__itemcount__", importedItemsCount);
      }
      showMessage && showMessage(message, 5000);
    },
    notifyLocalOnInvoke: (_, props) => {
      populateAndUpdateMetadataInSqlite();
    },
    service: (props, { urls }) => {
      let { selectedData = [], user } = props || {};
      const items = selectedData
        .filter(row => isImportFromGroupDetailVisible({ data: row, user }))
        .map(row => ({ _id: row._id }));
      let commandToPost;
      commandToPost = {
        url: urls["exportFile"],
        uriProps: {
          data: {
            resources: items
          }
        }
      };
      return commandToPost;
    }
  },
  importPhotoToGallery: {
    type: "invoke",
    title: () => I18N.t("import"),
    notifyLocalOnInvoke: async (state, props) => {
      const { selectedData } = props.invokeProps;
      let { invokeResult } = props;
      if (!selectedData || !selectedData.length || (invokeResult && invokeResult.error)) {
        return;
      }
      if (state && state.data && Array.isArray(state.data) && state.data.length) {
        state.data[0].exported = true;
        state.data[0].deleted = false;
      }
      let insertData = selectedData.map(row => ({ ...row, exported: true, deleted: false }));
      await notifyLocalInserts(
        props,
        { insert: insertData, sort: GALLERY_ALL_SORT },
        GALLERY_ALL_LOCAL_CHANGE_KEY,
        true
      );
      downloadPicOfDay &&
        downloadPicOfDay(selectedData[0]).catch(err => {
          console.warn("error in pic of the download", err && err.message);
        });
    },
    afterInvoke: (result, props) => {
      let message = I18N.t("photoImportedMessage");
      let error = result && result.error;
      if (error && error.code) {
        if (error.code === "uploader/limit-exceeds") {
          message = I18N.t("notEnoughSpace");
        } else if (error.code === "already-added") {
          message = I18N.t("alreadyAddedIntoGallery");
        } else if (error.code === "user/deleted-user") {
          message = I18N.t("picOfDayUserDeleted");
        }
      }
      showMessage && showMessage(message, 2000);
    },
    service: (data, { urls }) => {
      let resourceId = data && data.data && data.data._id;
      let commandToPost;
      commandToPost = {
        url: urls["exportPictureOfDay"],
        uriProps: {
          data: {
            resource: resourceId
          }
        }
      };
      return commandToPost;
    }
  },
  backToDay: {
    type: "link",
    text: () => I18N.t("BackToDay"),
    link: ({ data } = {}) => {
      let link = {
        uri: "/home#gallery"
      };
      if (Platform.OS !== "web") {
        link.when = new Date().getTime();
        // link was being persist even on switching tabs
        let scroll_to_id = data._id;
        link.getScrollId = () => scroll_to_id;
        link.unsetScrollId = () => {
          scroll_to_id = void 0;
        };
      }

      return link;
    },
    replace: true,
    visible: data => data.exported
  },

  discardPictureOfDay: {
    type: "invoke",
    text: () => I18N.t("discard"),
    notifyLocalOnInvoke: (state, props) => {
      // if (state && state.data && Array.isArray(state.data) && state.data.length) {
      //   state.data[0].discard = true;
      // }
      // notifyLocalUpdates(props, { changes: { discarded: true } });
    },
    service: (data, { urls }) => {
      let resourceId = data && data.data && data.data._id;
      let commandToPost;
      commandToPost = {
        url: urls["discardPictureOfDay"],
        uriProps: {
          data: {
            resource: resourceId
          }
        }
      };
      return commandToPost;
    }
  },
  removeItemFromGroup: {
    type: "invoke",
    title: () => I18N.t("removeFromGroup"),
    visible: (data, props) => isRemoveFromGroupVisible({ ...props, data }),
    postMessage: () => I18N.t("itemRemovedMessage"),
    confirm: {
      titleMD: () => I18N.t("removePopupHeaderCaps"),
      message: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        return confirmMessageResolver({
          singleItemMsg: "removeGroupSingleItemMessage",
          multipleItemsMsg: "removeGroupMultipleItemMessage",
          selectedIds
        });
      },
      confirmText: () => I18N.t("remove"),
      cancelText: () => I18N.t("cancel")
    },
    service: (props, { urls }) => {
      let { selectedData = [], user } = props || {};
      const items = selectedData
        .filter(row => isRemoveFromGroupDetailVisible({ data: row, user }))
        .map(row => ({ _id: row._id }));
      let commandToPost;
      commandToPost = {
        url: urls["removeGroupItem"],
        uriProps: {
          data: {
            _id: user && user.group && user.group._id,
            items
          }
        }
      };
      return commandToPost;
    },
    notifyLocalOnInvoke: (_, props) => {
      let { invokeProps, fireLocalDataChangeListener, user } = props;
      let { uri: { props: { query: { localChangeKey } = {} } = {} } = {}, selectedData = [] } = invokeProps;
      let localChangesArray = [];
      let localChanges = { remove: true, sqliteChanges: { group: void 0, postedOn: void 0 } };
      for (let row of selectedData) {
        if (isRemoveFromGroupDetailVisible({ data: row, user })) {
          localChangesArray.push({
            _id: row._id,
            source: "action",
            ...localChanges
          });
        }
      }
      localChangesArray.length &&
        fireLocalDataChangeListener &&
        fireLocalDataChangeListener({
          key: localChangeKey,
          localChanges: localChangesArray,
          updateSqlite: true
        });
    }
  },
  viewAll: {
    type: "link",
    text: () => I18N.t("viewAll"),
    image: "viewAllIcon",
    link: props => {
      let { data = {} } = props;
      // FIXME: Check this
      data && data.onStoryModalClose && typeof data.onStoryModalClose === "function" && data.onStoryModalClose();
      return {
        uri: "/all-stories",
        props: {
          params: {
            origin: "highlightStories",
            to: data.endDate,
            from: data.startDate
          }
        }
      };
    }
  },
  postToGroup: {
    type: "invoke",
    text: () => I18N.t("postToGroup"),
    visible: (data, { user }) => user && user.group,
    postMessage: props => {
      let { invokeResult } = props || {};
      let { totalResources, addedResources } = invokeResult;
      let toastMessage = "";
      if (totalResources === addedResources) {
        toastMessage = I18N.t("itemsPostedToGroup").replace("__itemcount__", addedResources);
      } else if (addedResources === 0) {
        toastMessage = I18N.t("itemsExistsToGroup").replace("__itemcount__", totalResources);
      } else {
        toastMessage =
          I18N.t("itemsPostedToGroup").replace("__itemcount__", addedResources) +
          " " +
          I18N.t("and") +
          " " +
          I18N.t("itemsExistsToGroup").replace("__itemcount__", totalResources - addedResources);
      }
      return toastMessage;
    },
    image: "pinBlackIcon",
    notifyLocalOnInvoke: (_, props) => {
      addItemsToGroupOnLocalChange(props);
    },
    service: (props, { urls }) => {
      let { selectedIds, user } = props || {};
      const items = selectedIds.map(_id => ({ _id }));
      let commandToPost;
      commandToPost = {
        url: urls["addGroupItem"],
        uriProps: {
          data: {
            _id: user && user.group && user.group._id,
            items
          }
        }
      };
      return commandToPost;
    }
  },
  webShareGallery: {
    ...shareForm,
    image: "shareGreyIcon",
    origin: "gallery",
    visible: () => Platform.OS === "web"
  },
  webShareDoc: {
    ...shareForm,
    image: "shareGreyIcon",
    origin: "doc",
    visible: () => Platform.OS === "web"
  },
  nativeShareGallery: {
    text: I18N.t("shareVia"),
    type: "appShare",
    image: "shareGreyIcon",
    origin: "gallery",
    shareType: RESOURCE_SHARE_TYPE_ENUM.ORIGINAL,
    visible: isNativeShareActionVisibile
  },
  nativeShareDoc: {
    text: I18N.t("shareVia"),
    type: "appShare",
    image: "shareGreyIcon",
    origin: "doc",
    shareType: RESOURCE_SHARE_TYPE_ENUM.ORIGINAL,
    visible: isNativeShareActionVisibile
  },
  nativeShareConverted: {
    shareType: RESOURCE_SHARE_TYPE_ENUM.CONVERTED
  },
  nativeShareLink: {
    shareType: RESOURCE_SHARE_TYPE_ENUM.LINK
  },
  getLink: {
    ...getLinkActionProps,
    image: "linkGreyIcon",
    origin: "gallery",
    visible: () => Platform.OS === "web"
  },
  getLinkNative: {
    ...getLinkNativeActionProps("gallery")
  },
  getLinkDoc: {
    ...getLinkActionProps,
    image: "linkGreyIcon",
    origin: "doc",
    visible: () => Platform.OS === "web"
  },
  getLinkDocNative: {
    ...getLinkNativeActionProps("doc")
  },
  shareViaEmail: {
    ...shareViaEmailActionProps
  },
  imageInfoWeb: {
    image: "infoIcon",
    title: () => I18N.t("infoTitle"),
    form: {
      component: "webImageInfoComponent"
    }
  },
  infoWeb: {
    image: "infoIcon",
    title: () => I18N.t("infoTitle"),
    form: {
      component: "resourceInfoWeb"
    }
  },
  infoMobile: {
    text: () => I18N.t("info"),
    form: {
      component: "resourceInfo"
    }
  },
  printFile: {
    type: "printFile",
    text: () => I18N.t("print"),
    title: () => I18N.t("print"),
    visible: (data = [], { selectedIds = [], Platform }) => {
      if (Platform.OS === "web") {
        return false;
      }
      if (!data.length || !selectedIds.length) {
        return false;
      }
      let selectedIdsMap = {};
      selectedIds.forEach(selectedId => {
        selectedIdsMap[selectedId] = 1;
      });
      let isNonGifSelected = false;
      for (let index = 0; index < data.length; index++) {
        let doc = data[index];
        if (selectedIdsMap[doc._id]) {
          if (doc["type"] !== "image" || !doc["uploaded"]) {
            return false;
          }
          if (!isNonGifSelected) {
            let ext_small = doc["ext"] && doc["ext"].toLowerCase();
            if (ext_small !== "gif") {
              isNonGifSelected = true;
            }
          }
        }
      }
      return isNonGifSelected;
    }
  },
  webUpload: {
    type: "webAppUpload",
    text: () => I18N.t("upload"),
    visible: (data, { selectionMode, Platform }) => Platform.OS === "web" && !selectionMode
  },
  webUploadWithIcon: {
    type: "webAppUpload",
    text: () => I18N.t("upload"),
    image: "uploadGreyIcon",
    visible: (data, { selectionMode, Platform }) => Platform.OS === "web" && !selectionMode
  },
  playMusic: {
    type: "playMusicAction",
    title: () => I18N.t("playMusic")
  },
  selectImageSetAlbumCover: {
    type: "invoke",
    afterInvoke: (result, props) => {
      let { deleteUri, getPath, link } = props;
      deleteUri && deleteUri(getPath && getPath(), link);
    },
    postMessage: () => I18N.t("coverUpdatedSuccessMessage"),
    skipClearSelection: true,
    service: (props, { urls }) => {
      let { link, selectedIds } = props || {};
      let albumId = link && link.props && link.props.filter && link.props.filter._id;
      const itemId = selectedIds && selectedIds[0];
      if (albumId && itemId) {
        return {
          url: urls["updateAlbumCover"],
          uriProps: {
            data: {
              _id: albumId,
              item: { _id: itemId }
            }
          }
        };
      }
    }
  },
  layout: {
    text: () => I18N.t("changeLayout"),
    visible: (data, { selectionMode }) => !selectionMode && data && data.length,
    form: {
      component: "listLayoutSelector"
    }
  },
  searchGallery: searchLink({ uri: "/search-gallery", from: "search-gallery" }),
  searchDocs: searchLink({ uri: "/search-docs", from: "search-docs" }),
  searchMusic: searchLink({ uri: "/search-music", from: "search-music" }),
  searchVaultGallery: searchLink({
    uri: "/search-vault-gallery",
    from: "search-vault-gallery",
    secureType: SECURE_TYPES.VAULT
  }),
  searchVaultDocs: searchLink({ uri: "/search-vault-docs", from: "search-vault-docs", secureType: SECURE_TYPES.VAULT }),
  searchGroupGallery: searchGroupLink({ uri: "/search-group-gallery", from: "search-group-gallery" }),
  searchGroupDocs: searchGroupLink({ uri: "/search-group-docs", from: "search-group-docs" }),
  searchArchiveGallery: searchLink({
    uri: "/search-archive-gallery",
    from: "search-archive-gallery",
    secureType: SECURE_TYPES.ARCHIVE
  }),
  searchArchiveDocs: searchLink({
    uri: "/search-archive-docs",
    from: "search-archive-docs",
    secureType: SECURE_TYPES.ARCHIVE
  }),
  searchArchiveMusic: searchLink({
    uri: "/search-archive-music",
    from: "search-archive-music",
    secureType: SECURE_TYPES.ARCHIVE
  }),

  restoreTrash: {
    type: "invoke",
    image: "restoreIcon",
    text: () => I18N.t("restore"),
    title: () => I18N.t("restore"),
    postMessage: ({ selectedIds = [] }) => `${selectedIds.length} ${I18N.t("restoreSuccessMessage")}`,
    service: (props, { urls }) => {
      let { selectedIds } = props || {};
      if (selectedIds && selectedIds.length > 0) {
        return {
          url: urls["unmarkDeleteMultiple"],
          uriProps: {
            data: selectedIds
          }
        };
      }
    },
    notifyLocalOnInvoke: (_, props) => {
      let { invokeProps: { selectedData } = {}, fireLocalDataChangeListener, user } = props;
      if (!selectedData || !selectedData.length) {
        return;
      }
      let restoreGallery = [],
        restoreVaultGallery = [],
        restoreArchiveGallery = [],
        restoreDocs = [],
        restoreVaultDocs = [],
        restoreArchiveDocs = [],
        restoreMusic = [],
        restoreArchiveMusic = [],
        uploadGalleryLocalData = [],
        trashViewChanges = [],
        groupGallery = [],
        groupDocs = [];
      const locationMapping = {};
      const deviceFolderMapping = {};
      for (let data of selectedData) {
        data = { ...data, deleted: false };
        trashViewChanges.push({
          _id: data._id,
          remove: true,
          sqliteChanges: { deleted: false }
        });
        let { type, secureType, group, location: { city: { lower_name: cityName } = {} } = {}, deviceFolder } = data;
        if (cityName && secureType === SECURE_TYPES.DEFAULT) {
          locationMapping[cityName] = locationMapping[cityName] || {
            _id: cityName,
            incCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount += locationMapping[cityName].incCount;
              return { itemCount, count: itemCount };
            }
          };
          locationMapping[cityName].incCount++;
        }
        if (deviceFolder && secureType === SECURE_TYPES.DEFAULT) {
          deviceFolderMapping[deviceFolder] = deviceFolderMapping[deviceFolder] || {
            _id: deviceFolder,
            incCount: 0,
            source: "action",
            changes: ({ itemCount } = {}) => {
              itemCount += deviceFolderMapping[deviceFolder].incCount;
              return { itemCount, count: itemCount };
            }
          };
          deviceFolderMapping[deviceFolder].incCount++;
        }
        if (type === "image" || type === "video") {
          data = {
            ...data,
            url: data.converted_jpeg_url || data.thumbnail_url,
            height: data.converted_height || data.thumbnail_height,
            width: data.converted_width || data.thumbnail_width,
            collection: void 0
          };
          secureType === SECURE_TYPES.VAULT
            ? restoreVaultGallery.push(data)
            : SECURE_TYPES.ARCHIVE
            ? restoreArchiveGallery.push(data)
            : restoreGallery.push(data);
          if (data.fileUri && data.deviceId === myDeviceId) {
            uploadGalleryLocalData.push({ uri: data.fileUri });
          }
          if (user && user.group && user.group._id && group && group._id && group._id === user.group._id) {
            groupGallery.push(typeCastSchema({ value: data, schema: { postedOn: "date" } }));
          }
        } else if (type === "doc") {
          data = { ...data, url: data.thumbnail_url, collection: void 0 };
          secureType === SECURE_TYPES.VAULT
            ? restoreVaultDocs.push(data)
            : SECURE_TYPES.ARCHIVE
            ? restoreArchiveDocs.push(data)
            : restoreDocs.push(data);
          if (user && user.group && user.group._id && group && group._id && group._id === user.group._id) {
            groupDocs.push(typeCastSchema({ value: data, schema: { postedOn: "date" } }));
          }
        } else if (type === "audio") {
          data = { ...data, collection: void 0 };
          secureType === SECURE_TYPES.ARCHIVE ? restoreArchiveMusic.push(data) : restoreMusic.push(data);
        }
      }
      const locationChangesArray = Object.values(locationMapping);
      const deviceFolderChangesArray = Object.values(deviceFolderMapping);
      locationChangesArray.length &&
        fireLocalDataChangeListener({
          key: PLACES_LOCAL_CHANGE_KEY,
          localChanges: locationChangesArray
        });
      deviceFolderChangesArray.length &&
        fireLocalDataChangeListener({
          key: DEVICE_FOLDER_LOCAL_CHANGE_KEY,
          localChanges: deviceFolderChangesArray
        });

      trashViewChanges.length &&
        fireLocalDataChangeListener &&
        fireLocalDataChangeListener({ key: "trashedData", localChanges: trashViewChanges, updateSqlite: true });
      uploadGalleryLocalData.length && removeImageFromGalleryFolders(uploadGalleryLocalData);
      if (restoreGallery.length) {
        restoreGallery.sort((a, b) => getSortValue(a, b, GALLERY_ALL_SORT));
        notifyLocalInserts(props, { insert: restoreGallery, sort: GALLERY_ALL_SORT }, GALLERY_ALL_LOCAL_CHANGE_KEY);
      }
      if (restoreArchiveGallery.length) {
        restoreArchiveGallery.sort((a, b) => getSortValue(a, b, GALLERY_ALL_SORT));
        notifyLocalInserts(
          props,
          { insert: restoreArchiveGallery, sort: GALLERY_ALL_SORT },
          ARCHIVE_GALLERY_LOCAL_CHANGE_KEY
        );
      }
      if (restoreVaultGallery.length) {
        restoreVaultGallery.sort((a, b) => getSortValue(a, b, GALLERY_ALL_SORT));
        notifyLocalInserts(
          props,
          { insert: restoreVaultGallery, sort: GALLERY_ALL_SORT },
          VAULT_GALLERY_LOCAL_CHANGE_KEY
        );
      }
      if (restoreDocs.length) {
        restoreDocs.sort((a, b) => getSortValue(a, b, DOCS_ALL_SORT));
        notifyLocalInserts(props, { insert: restoreDocs, sort: DOCS_ALL_SORT }, DOCS_ALL_LOCAL_CHANGE_KEY);
      }
      if (restoreArchiveDocs.length) {
        restoreArchiveDocs.sort((a, b) => getSortValue(a, b, DOCS_ALL_SORT));
        notifyLocalInserts(props, { insert: restoreArchiveDocs, sort: DOCS_ALL_SORT }, ARCHIVE_DOCS_LOCAL_CHANGE_KEY);
      }
      if (restoreVaultDocs.length) {
        restoreVaultDocs.sort((a, b) => getSortValue(a, b, DOCS_ALL_SORT));
        notifyLocalInserts(props, { insert: restoreVaultDocs, sort: DOCS_ALL_SORT }, VAULT_DOCS_LOCAL_CHANGE_KEY);
      }
      if (restoreMusic.length) {
        restoreMusic.sort((a, b) => getSortValue(a, b, MUSIC_ALL_SORT));
        notifyLocalInserts(props, { insert: restoreMusic, sort: MUSIC_ALL_SORT }, MUSIC_ALL_LOCAL_CHANGE_KEY);
      }
      if (restoreArchiveMusic.length) {
        restoreArchiveMusic.sort((a, b) => getSortValue(a, b, MUSIC_ALL_SORT));
        notifyLocalInserts(
          props,
          { insert: restoreArchiveMusic, sort: MUSIC_ALL_SORT },
          ARCHIVE_MUSIC_LOCAL_CHANGE_KEY
        );
      }
      if (groupGallery.length) {
        groupGallery.sort((a, b) => getSortValue(a, b, GROUP_GALLERY_SORT));
        notifyLocalInserts(props, { insert: groupGallery, sort: GROUP_GALLERY_SORT }, GROUP_GALLERY_LOCAL_CHANGE_KEY);
      }
      if (groupDocs.length) {
        groupDocs.sort((a, b) => getSortValue(a, b, GROUP_DOC_SORT));
        notifyLocalInserts(props, { insert: groupDocs, sort: GROUP_DOC_SORT }, GROUP_DOC_LOCAL_CHANGE_KEY);
      }
    }
  },
  deleteTrash: {
    type: "invoke",
    image: "deleteIcon",
    text: () => I18N.t("delete"),
    title: () => I18N.t("delete"),
    confirm: {
      titleMD: () => I18N.t("deletePopupHeaderCaps"),
      message: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        let translateMsg = I18N.t("deleteItemsPermanent");
        translateMsg = translateMsg.replace("_itemCount_", `${selectedIds.length}`);
        return [{ text: translateMsg }, { viewStyle: { paddingTop: 6 }, text: I18N.t("trashUndoneMessage") }];
      },
      confirmText: () => I18N.t("empty"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: ({ selectedIds = [] }) => `${selectedIds.length} ${I18N.t("deleteTrashMessage")}`,
    service: (props, { urls }) => {
      let { selectedIds } = props || {};
      if (selectedIds && selectedIds.length > 0) {
        return {
          url: urls["removeResource"],
          uriProps: {
            data: selectedIds
          }
        };
      }
    },
    notifyLocalOnInvoke: (_, props) => {
      const { invokeProps, fireLocalDataChangeListener } = props;
      const { selectedIds = [] } = invokeProps;
      const localChanges = selectedIds.map(_id => ({
        _id,
        remove: true
      }));
      localChanges.length &&
        fireLocalDataChangeListener &&
        fireLocalDataChangeListener({ key: "trashedData", localChanges, updateSqlite: true });
    }
  },
  deleteFailedItems: {
    type: "invoke",
    image: "deleteIcon",
    text: () => I18N.t("delete"),
    title: () => I18N.t("delete"),
    confirm: {
      titleMD: () => I18N.t("deletePopupHeaderCaps"),
      message: ({ getSelectedIds }) => {
        let selectedIds = (getSelectedIds && getSelectedIds()) || [];
        let translateMsg = I18N.t("deleteItemsPermanent");
        translateMsg = translateMsg.replace("_itemCount_", `${selectedIds.length}`);
        return [{ text: translateMsg }, { viewStyle: { paddingTop: 6 }, text: I18N.t("trashUndoneMessage") }];
      },
      confirmText: () => I18N.t("delete"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: ({ selectedIds = [] }) => `${selectedIds.length} ${I18N.t("deleteTrashMessage")}`,
    service: (props, { urls }) => {
      let { selectedIds } = props || {};
      if (selectedIds && selectedIds.length > 0) {
        return {
          url: urls["removeFailedResources"],
          uriProps: {
            data: selectedIds
          }
        };
      }
    },
    notifyLocalOnInvoke: (_, props) => {
      const { invokeProps, fireLocalDataChangeListener } = props;
      const { selectedIds = [] } = invokeProps;
      const localChanges = selectedIds.map(_id => ({
        _id,
        remove: true
      }));
      localChanges.length &&
        fireLocalDataChangeListener &&
        fireLocalDataChangeListener({ key: REMOVED_METADATA_TABLE, localChanges });
    }
  },
  clearCache: {
    type: "clearCache",
    text: () => I18N.t("clearCache"),
    visible: () => Platform.OS !== "web",
    confirm: {
      message: () => I18N.t("clearCacheMessage"),
      confirmText: () => I18N.t("okCaps"),
      cancelText: () => I18N.t("cancel")
    }
  },
  clearNonUpload: {
    type: "invoke",
    image: "deleteIcon",
    text: () => `${I18N.t("empty")} ${I18N.t("item")}`,
    confirm: {
      titleMD: () => I18N.t("nonUploadedItems").toLocaleUpperCase(),
      message: () => {
        return [
          { text: I18N.t("emptyNonUploadMessage") },
          { viewStyle: { paddingTop: 6 }, text: I18N.t("nonUploadUndoneMessage") }
        ];
      },
      confirmText: () => I18N.t("empty"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("clearNonUploadMessage"),
    service: (props, { urls }) => {
      return {
        url: urls["removeNonUploaded"],
        uriProps: {
          data: { currentTime: new Date(), status: "removeAll" }
        }
      };
    },
    notifyLocalOnInvoke: (_, props) => {
      const { fireLocalDataChangeListener } = props;
      fireLocalDataChangeListener &&
        fireLocalDataChangeListener({ key: NON_UPLOAD_LOCAL_CHANGE_KEY, localChanges: { clearData: true } });
      emptyNonUploadedSqlite && emptyNonUploadedSqlite(); //FIXME
    }
  },
  clearTrash: {
    type: "invoke",
    text: () => I18N.t("emptyTrashCan"),
    confirm: {
      titleMD: () => I18N.t("deletePopupHeaderCaps"),
      message: () => {
        return [
          { text: I18N.t("emptyTrashMessage") },
          { viewStyle: { paddingTop: 6 }, text: I18N.t("trashUndoneMessage") }
        ];
      },
      confirmText: () => I18N.t("empty"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("clearTrashMessage"),
    service: (props, { urls }) => {
      return {
        url: urls["removeAllResource"],
        uriProps: {
          data: {}
        }
      };
    },
    notifyLocalOnInvoke: (_, props) => {
      let { fireLocalDataChangeListener } = props;
      fireLocalDataChangeListener &&
        fireLocalDataChangeListener({ key: "trashedData", localChanges: { clearData: true }, updateSqlite: false });
      emptyTrashSqlite();
    }
  },
  clearFailedItems: {
    type: "invoke",
    text: () => I18N.t("deleteAll"),
    title: () => I18N.t("deleteAll"),
    confirm: {
      titleMD: () => I18N.t("deletePopupHeaderCaps"),
      message: () => {
        return [
          { text: I18N.t("emptyFailedMessage") },
          { viewStyle: { paddingTop: 6 }, text: I18N.t("trashUndoneMessage") }
        ];
      },
      confirmText: () => I18N.t("empty"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("allItemsDeleted"),
    service: (props, { urls }) => {
      return {
        url: urls["removeAllFailedResources"],
        uriProps: {
          data: { failed: true }
        }
      };
    },
    notifyLocalOnInvoke: (_, props) => {
      let { fireLocalDataChangeListener } = props;
      fireLocalDataChangeListener &&
        fireLocalDataChangeListener({ key: FAILED_ITEMS_LOCAL_CHANGE_KEY, localChanges: { clearData: true } });
    }
  },
  clearInfectedItems: {
    type: "invoke",
    text: () => I18N.t("deleteAll"),
    title: () => I18N.t("deleteAll"),
    confirm: {
      titleMD: () => I18N.t("deletePopupHeaderCaps"),
      message: () => {
        return [
          { text: I18N.t("emptyInfectedMessage") },
          { viewStyle: { paddingTop: 6 }, text: I18N.t("trashUndoneMessage") }
        ];
      },
      confirmText: () => I18N.t("empty"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("allItemsDeleted"),
    service: (props, { urls }) => {
      return {
        url: urls["removeAllFailedResources"],
        uriProps: {
          data: { infected: true }
        }
      };
    },
    notifyLocalOnInvoke: (_, props) => {
      let { fireLocalDataChangeListener } = props;
      fireLocalDataChangeListener &&
        fireLocalDataChangeListener({ key: INFECTED_ITEMS_LOCAL_CHANGE_KEY, localChanges: { clearData: true } });
    }
  },
  unlinkDevice: {
    type: "invoke",
    text: I18N.t("unlink"),
    confirm: {
      titleMD: () => I18N.t("unlinkDevice"),
      message: () => {
        return [
          { text: I18N.t("unlinkPrimaryMessage") },
          { viewStyle: { paddingTop: 6 }, text: I18N.t("unlinkSecondaryMessage") }
        ];
      },
      confirmText: () => I18N.t("unlink"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("unlinkMessage"),
    service: (props, { urls }) => {
      let { data } = props || {};
      return {
        url: urls["setDeviceInfo"],
        uriProps: {
          data: {
            logout: true,
            status: "logout",
            device: data && data.device
          }
        }
      };
    }
  },

  blockDevices: {
    // Only for Mobile / SM View
    type: "link",
    text: () => I18N.t("block"),
    confirm: {
      titleMD: () => I18N.t("blockDevice"),
      message: () => {
        return [
          { text: I18N.t("blockDevicePrimaryMessage") },
          { viewStyle: { paddingTop: 6 }, text: I18N.t("blockDevicesSecondaryMessage") }
        ];
      },
      confirmText: () => I18N.t("block"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("blockMessage"),
    link: props => {
      let { data: { device } = {} } = props || {};
      return {
        uri: "/block-device",
        props: {
          params: {
            deviceId: device,
            origin: "block"
          }
        }
      };
    }
  }
};

const removeFromCollectionConfirmMessage = (
  { link = {}, getSelectedIds } = {},
  { singleItemMsg, multipleItemsMsg, nameKey }
) => {
  let { props: { params: { collection: { name = "" } = {} } = {} } = {} } = link;
  let selectedIds = (getSelectedIds && getSelectedIds()) || [];
  let translateMsg = confirmMessageResolver({
    singleItemMsg,
    multipleItemsMsg,
    selectedIds
  });
  translateMsg = translateMsg.replace(nameKey, `"${name}"`);
  return translateMsg;
};

actions.restoreTrashSM = {
  ...actions.restoreTrash,
  visible: (data, { selectedIds }) => selectedIds && selectedIds.length,
  image: void 0
};

actions.deleteTrashSM = {
  ...actions.deleteTrash,
  visible: (data, { selectedIds }) => selectedIds && selectedIds.length,
  image: void 0
};

actions.removeNonUpload = {
  ...actions.clearNonUpload,
  text: () => I18N.t("removeItems"),
  title: () => I18N.t("removeItems"),
  image: void 0,
  visible: (data, { selectedIds }) => selectedIds && selectedIds.length,
  service: (props, { urls }) => {
    let { selectedIds } = props || {};
    if (selectedIds && selectedIds.length > 0) {
      return {
        url: urls["removeNonUploaded"],
        uriProps: {
          data: { currentTime: new Date(), resourceIds: selectedIds, status: "remove" }
        }
      };
    }
  },
  notifyLocalOnInvoke: (_, props) => {
    const { invokeProps, fireLocalDataChangeListener } = props;
    const { selectedIds = [] } = invokeProps;
    const localChanges = selectedIds.map(_id => ({ _id, remove: true }));
    localChanges.length &&
      fireLocalDataChangeListener &&
      fireLocalDataChangeListener({ key: NON_UPLOAD_LOCAL_CHANGE_KEY, localChanges, updateSqlite: true });
  }
};
actions.clearNonUploadSM = {
  ...actions.clearNonUpload,
  text: () => I18N.t("removeNonUploaded"),
  title: () => I18N.t("removeNonUploaded"),
  image: void 0,
  visible: (data, { selectionMode }) => !selectionMode && data && data.length
};

actions.deleteFailedSM = {
  ...actions.deleteFailedItems,
  visible: (data, { selectedIds }) => selectedIds && selectedIds.length,
  image: void 0
};

actions.removeFromAlbum = {
  ...actions.removeFromCollection,
  text: () => I18N.t("removeFromAlbum"),
  title: () => I18N.t("removeFromAlbum"),
  confirm: {
    ...actions.removeFromCollection.confirm,
    message: props =>
      removeFromCollectionConfirmMessage(props, {
        singleItemMsg: "removeAlbumSingleItemPopupMessage",
        multipleItemsMsg: "removeAlbumMultipleItemPopupMessage",
        nameKey: "__albumName__"
      })
  }
};

actions.removeFromSet = {
  ...actions.removeFromCollection,
  text: () => I18N.t("removeFromSet"),
  title: () => I18N.t("removeFromSet"),
  confirm: {
    ...actions.removeFromCollection.confirm,
    message: props =>
      removeFromCollectionConfirmMessage(props, {
        singleItemMsg: "removeSetSingleItemPopupMessage",
        multipleItemsMsg: "removeSetMultipleItemPopupMessage",
        nameKey: "__setName__"
      })
  }
};

actions.removeFromPlaylist = {
  ...actions.removeFromCollection,
  text: () => I18N.t("removeFromPlaylist"),
  title: () => I18N.t("removeFromPlaylist"),
  confirm: {
    ...actions.removeFromCollection.confirm,
    message: props =>
      removeFromCollectionConfirmMessage(props, {
        singleItemMsg: "removePlaylistSingleItemPopupMessage",
        multipleItemsMsg: "removePlaylistMultipleItemPopupMessage",
        nameKey: "__playlistName__"
      })
  }
};

actions.toViewGallery = {
  ...toViewLink({ uri: "/gallery-detail", origin: "gallery" })
};

actions.toViewDoc = {
  ...toViewLink({ uri: "/doc-detail", origin: "doc" })
};

actions.toViewMusic = {
  ...toViewLink({ uri: "/music-detail", origin: "music" })
};

actions.toViewArchiveMusic = {
  ...toViewLink({ uri: "/archive-music-detail", origin: "music" })
};

actions.toViewVaultGallery = {
  ...toViewLink({ uri: "/vault-gallery-detail", origin: "gallery" })
};

actions.toViewArchiveGallery = {
  ...toViewLink({ uri: "/archive-gallery-detail", origin: "gallery" })
};

actions.toViewVaultDoc = {
  ...toViewLink({ uri: "/vault-doc-detail", origin: "doc" })
};

actions.toViewArchiveDoc = {
  ...toViewLink({ uri: "/archive-doc-detail", origin: "doc" })
};
actions.selectMoveToSafe = {
  ...actions.moveToVault,
  afterInvoke: (result, props) => {
    let { replaceUri, link } = props;
    replaceUri &&
      replaceUri({
        uri: link.parentUrl
      });
  },
  skipClearSelection: true,
  notifyLocalOnInvoke: (_, props) => {
    logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "move_to_safearea" });
    let { invokeProps, fireLocalDataChangeListener } = props;
    let { selectedIds, selectedData, data } = invokeProps;
    if (!selectedIds || !selectedIds.length || !fireLocalDataChangeListener) {
      return;
    }
    if (!selectedData || selectedData.length !== selectedIds.length) {
      selectedData = data.filter(row => selectedIds.indexOf(row._id) !== -1);
    }
    let vaultGallery = [],
      vaultDocs = [],
      metadataChanges = [];
    let changes = { secureType: SECURE_TYPES.VAULT, private: true };
    for (let doc of selectedData) {
      metadataChanges.push({
        _id: doc._id,
        source: "action",
        remove: true,
        sqliteChanges: changes
      });
      if (doc.type === "image" || doc.type === "video") {
        vaultGallery.push({ ...doc, ...changes });
      } else if (doc.type === "doc") {
        vaultDocs.push({ ...doc, ...changes });
      }
    }

    metadataChanges.length &&
      fireLocalDataChangeListener({ key: MetadataTable, localChanges: metadataChanges, updateSqlite: true });
    if (vaultGallery.length) {
      vaultGallery = vaultGallery.sort((a, b) => getSortValue(a, b, GALLERY_ALL_SORT));
      notifyLocalInserts(props, { insert: vaultGallery, sort: GALLERY_ALL_SORT }, VAULT_GALLERY_LOCAL_CHANGE_KEY);
    }
    if (vaultDocs.length) {
      vaultDocs = vaultDocs.sort((a, b) => getSortValue(a, b, DOCS_ALL_SORT));
      notifyLocalInserts(props, { insert: vaultDocs, sort: DOCS_ALL_SORT }, VAULT_DOCS_LOCAL_CHANGE_KEY);
    }
  }
};
actions.selectMoveToArchive = {
  ...actions.moveToArchive,
  afterInvoke: (result, props) => {
    let { replaceUri, link } = props;
    replaceUri &&
      replaceUri({
        uri: link.parentUrl
      });
  },
  skipClearSelection: true,
  notifyLocalOnInvoke: (_, props) => {
    logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ event: "move_to_archive" });
    let { invokeProps, fireLocalDataChangeListener } = props;
    let { selectedIds, selectedData, data } = invokeProps;
    if (!selectedIds || !selectedIds.length || !fireLocalDataChangeListener) {
      return;
    }
    if (!selectedData || selectedData.length !== selectedIds.length) {
      selectedData = data.filter(row => selectedIds.indexOf(row._id) !== -1);
    }
    let archiveGallery = [],
      archiveDocs = [],
      archiveMusic = [],
      metadataChanges = [];
    let changes = { secureType: SECURE_TYPES.ARCHIVE, private: true };
    for (let doc of selectedData) {
      metadataChanges.push({
        _id: doc._id,
        source: "action",
        remove: true,
        sqliteChanges: changes
      });
      if (doc.type === "image" || doc.type === "video") {
        archiveGallery.push({ ...doc, ...changes });
      } else if (doc.type === "doc") {
        archiveDocs.push({ ...doc, ...changes });
      } else if (doc.type === "audio") {
        archiveMusic.push({ ...doc, ...changes });
      }
    }

    metadataChanges.length &&
      fireLocalDataChangeListener({ key: MetadataTable, localChanges: metadataChanges, updateSqlite: true });
    if (archiveGallery.length) {
      archiveGallery = archiveGallery.sort((a, b) => getSortValue(a, b, GALLERY_ALL_SORT));
      notifyLocalInserts(props, { insert: archiveGallery, sort: GALLERY_ALL_SORT }, ARCHIVE_GALLERY_LOCAL_CHANGE_KEY);
    }
    if (archiveDocs.length) {
      archiveDocs = archiveDocs.sort((a, b) => getSortValue(a, b, DOCS_ALL_SORT));
      notifyLocalInserts(props, { insert: archiveDocs, sort: DOCS_ALL_SORT }, ARCHIVE_DOCS_LOCAL_CHANGE_KEY);
    }
    if (archiveMusic.length) {
      archiveMusic = archiveMusic.sort((a, b) => getSortValue(a, b, MUSIC_ALL_SORT));
      notifyLocalInserts(props, { insert: archiveMusic, sort: MUSIC_ALL_SORT }, ARCHIVE_MUSIC_LOCAL_CHANGE_KEY);
    }
  }
};
actions.duplicateMarkDelete = {
  ...actions.markDelete,
  confirm: {
    titleMD: () => I18N.t("deletePopupHeaderCaps"),
    messageMD: ({ getSelectedIds }) => {
      let selectedIds = (getSelectedIds && getSelectedIds()) || [];
      return confirmMessageResolver({
        singleItemMsg: "deleteSingleItemPopupMessageMD",
        multipleItemsMsg: "duplicateDeleteMultipleItemPopupMessage",
        selectedIds
      });
    },
    messageSM: ({ getSelectedIds }) => {
      let selectedIds = (getSelectedIds && getSelectedIds()) || [];
      return confirmMessageResolver({
        singleItemMsg: "deleteSingleItemPopupMessageSM",
        multipleItemsMsg: "duplicateDeleteMultipleItemPopupMessage",
        selectedIds
      });
    },
    confirmText: () => I18N.t("moveToTrash"),
    cancelText: () => I18N.t("cancel")
  }
};

export default actions;
