import React from "react";

export default ({
  LoginHeader,
  Box,
  getImage,
  theme,
  I18N,
  Editor,
  Button,
  afterLogin,
  ResendOtp,
  resendPincode,
  showMessage,
  ChatLinkComponent,
  getErrorMessage
}) => {
  const { colors, fonts } = theme;
  const { highlightColor, themeColor, brownGrey, primaryColor } = colors;
  const { h20, h16_l, h16 } = fonts;

  const registerTheme = {
    bodyContainerStyle: {
      viewStyle: {
        paddingTop: 13,
        paddingBottom: 16,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: highlightColor
      }
    },
    logoStyle: {
      viewStyle: { paddingTop: 12, paddingBottom: 12, alignItems: "center" }
    },
    titleStyle: {
      viewStyle: { paddingTop: 12 },
      textStyle: {
        color: themeColor,
        textAlign: "center",
        ...h20
      }
    },
    primaryTextStyle: {
      viewStyle: { paddingTop: 12 },
      textStyle: {
        color: brownGrey,
        textAlign: "center",
        ...h16_l
      }
    },
    secondaryTextStyle: {
      viewStyle: { paddingTop: 9 },
      textStyle: {
        color: brownGrey,
        textAlign: "center",
        ...h16_l
      }
    },
    editorContainerStyle: {
      viewStyle: { paddingTop: 30 }
    },
    buttonContainerStyle: {
      viewStyle: { paddingTop: 12 }
    },
    resendMessageStyle: {
      textStyle: {
        ...h16,
        color: primaryColor
      }
    },
    timerStyle: {
      viewStyle: {
        paddingLeft: 5
      },
      textStyle: {
        ...h16,
        color: themeColor
      }
    },
    resendTextStyle: {
      viewStyle: {
        cursor: "pointer"
      },
      textStyle: {
        ...h16,
        color: themeColor
      }
    }
  };

  const resendCode = ({ state, onError, message = "codeSentMessage" }) => {
    let isResend = false;
    return () => {
      if (isResend || !resendPincode) {
        return;
      }
      isResend = true;
      resendPincode({ transaction_id: state.transaction_id })
        .then(_ => {
          showMessage && showMessage(I18N.t(message), 2000);
          isResend = false;
        })
        .catch(err => {
          isResend = false;
          onError(err);
        });
    };
  };

  let registerViewInfos = {
    "register-email": {
      logo: "emailIcon",
      title: "informationRecord",
      primaryText: "enterEmailMessage",
      editor: {
        field: "email",
        placeholder: "enterEmailId"
      },
      button: {
        text: "submit",
        loaderText: "submitting"
      },
      onClick: ({ data, setState, state: { fromLandline } = {} }) => {
        let email = data.email;
        return {
          functionName: "sendEmail",
          functionParams: {
            email,
            fromLandline
          },
          afterSubmit: result => {
            setState &&
              setState({
                viewType: "register-email-otp",
                transaction_id: result.transaction_id
              });
          }
        };
      },
      // footer: ({ data, setState }) => {
      //   return {
      //     viewStyle: { paddingTop: 12, cursor: "pointer" },
      //     textStyle: { ...h16, color: themeColor, textAlign: "center" },
      //     text: I18N.t("forgetEmail"),
      //     onClick: () => {
      //       if (data && data.email) {
      //         data.email = void 0;
      //       }
      //       setState &&
      //         setState({
      //           viewType: "register-forgot-email"
      //         });
      //     }
      //   };
      // },
      onClose: ({ data, setState }) => {
        if (data && data.email) {
          data.email = void 0;
        }
        setState &&
          setState({
            viewType: "login"
          });
      }
    },
    "register-email-otp": {
      logo: "emailIcon",
      title: "checkTheEmailBox",
      primaryText: "enterTheCodeEmail",
      editor: {
        field: "otp",
        placeholder: "enterConfirmationCode"
      },
      button: {
        text: "validate",
        loaderText: "validating"
      },
      onClick: ({ data, state, setState }) => {
        let { email, otp } = data;
        return {
          functionName: "validateOtp",
          functionParams: {
            email,
            otp,
            transaction_id: state.transaction_id
          },
          afterSubmit: result => {
            if (data) {
              data.email = void 0;
              data.otp = void 0;
            }
            setState &&
              setState({
                viewType: "register-mobile",
                transaction_id: void 0,
                mergeToken: result["fs-custom-token"]
              });
          }
        };
      },
      footer: ({ data, state, setState, onError }) => {
        return [
          {
            viewStyle: { paddingBottom: 6, paddingTop: 12 },
            textStyle: { ...h16, color: themeColor, textAlign: "center" },
            text: I18N.t("didNotGetEmail")
          },
          {
            viewStyle: { paddingTop: 6, paddingBottom: 4, cursor: "pointer" },
            textStyle: { ...h16, color: themeColor, textAlign: "center" },
            onClick: resendCode({ state, onError }),
            text: I18N.t("resendEmail")
          }
          // {
          //   onClick: () => {
          //     if (data) {
          //       data.email = void 0;
          //       data.otp = void 0;
          //     }
          //     setState &&
          //       setState({
          //         viewType: "register-forgot-email"
          //       });
          //   },
          //   viewStyle: { paddingTop: 4, cursor: "pointer" },
          //   textStyle: { ...h9, color: themeColor, textDecoration: "underline", textAlign: "center" },
          //   text: I18N.t("forgetEmail")
          // }
        ];
      },
      onClose: ({ data, setState }) => {
        if (data && data.otp) {
          data.otp = void 0;
        }
        setState &&
          setState({
            viewType: "register-email",
            transaction_id: void 0
          });
      }
    },
    "register-mobile": {
      logo: "mobileRegistrationIcon",
      title: "itAlmostFinishing",
      primaryText: "loginMobileDescription",
      editor: {
        field: "mobile",
        placeholder: "loginPlaceholder"
      },
      button: {
        text: "submit",
        loaderText: "submitting"
      },
      onClick: ({ data, setState, state: { fromLandline } = {} }) => {
        let { mobile } = data;
        return {
          functionName: "sendSMS",
          functionParams: {
            mobile,
            fromLandline
          },
          afterSubmit: result => {
            setState &&
              setState({
                viewType: "register-mobile-otp",
                transaction_id: result.transaction_id
              });
          }
        };
      },
      onClose: ({ data, setState }) => {
        if (data && data.mobile) {
          data.mobile = void 0;
        }
        setState &&
          setState({
            viewType: "register-email",
            transaction_id: void 0,
            mergeToken: void 0
          });
      }
    },
    "register-mobile-otp": {
      logo: "mobileRegistrationIcon",
      title: "toFinish",
      primaryText: "loginOtpMobileDescription",
      editor: {
        field: "otp",
        placeholder: "typeCode"
      },
      button: {
        text: "validate",
        loaderText: "validating"
      },
      onClick: ({ data, state, activeMQ, replaceUri }) => {
        let { otp } = data;
        return {
          functionName: "validateOtp",
          functionParams: {
            otp,
            transaction_id: state.transaction_id,
            mergeToken: state.mergeToken,
            fromLandline: state.fromLandline
          },
          afterSubmit: result => {
            afterLogin && afterLogin({ user: result.user }, { activeMQ, replaceUri });
          }
        };
      },
      footer: ({ state, onError }) => {
        return {
          viewStyle: { alignItems: "center", paddingTop: 12 },
          render: (
            <ResendOtp
              resendMessageStyle={registerTheme.resendMessageStyle}
              timerStyle={registerTheme.timerStyle}
              resendStyle={registerTheme.resendTextStyle}
              onClick={resendCode({ state, onError })}
              time={30}
            />
          )
        };
      },
      onClose: ({ data, setState }) => {
        if (data && data.otp) {
          data.otp = void 0;
        }
        setState &&
          setState({
            viewType: "register-mobile",
            transaction_id: void 0
          });
      }
    },
    // "register-successful": {
    //   logo: "successfulRegistrationIcon",
    //   title: "successfulRegistration",
    //   button: {
    //     text: "continue"
    //   },
    //   onClick: ({ state, activeMQ, replaceUri }) => {
    //     afterLogin && afterLogin({ user: state.user }, { activeMQ, replaceUri });
    //   },
    //   hideChatLink: true
    // },
    "register-forgot-email": {
      logo: "emailQuestionIcon",
      title: "forgetEmail",
      primaryText: "forgetEmailMessage",
      editor: {
        field: "documentId",
        placeholder: "forgetEmailPlaceholder"
      },
      button: {
        text: "submit",
        loaderText: "submitting"
      },
      onClick: ({ data, setState }) => {
        let { documentId } = data;
        return {
          functionName: "forgotEmail",
          functionParams: {
            documentId
          },
          afterSubmit: result => {
            if (data) {
              data.email = result.email;
            }
            setState &&
              setState({
                viewType: "register-show-forgot-email"
              });
          }
        };
      },
      onClose: ({ data, setState }) => {
        if (data && data.documentId) {
          data.documentId = void 0;
        }
        setState &&
          setState({
            viewType: "register-email"
          });
      }
    },
    "register-show-forgot-email": {
      logo: "emailQuestionIcon",
      title: "emailReminder",
      primaryText: "showForgotEmailDescription",
      editor: {
        field: "email",
        editable: false,
        inputStyle: {
          color: themeColor,
          ...h16
        }
      },
      onClose: ({ data, setState }) => {
        if (data) {
          data.documentId = void 0;
          data.email = void 0;
        }
        setState &&
          setState({
            viewType: "register-email"
          });
      }
    }
  };

  class WebRegister extends React.Component {
    state = {};

    _setState = state => {
      this.setState(state);
    };

    onError = err => {
      let message = getErrorMessage(err);
      this.setState({ error: message });
    };

    onClick = () => {
      const { state = {}, setState, loginContainerProps: { data, onSubmit } = {}, activeMQ, replaceUri } = this.props;
      let registerViewInfo = registerViewInfos[state.viewType];
      let { onClick } = registerViewInfo;
      if (state.viewType === "register-successful") {
        onClick(this.props);
        return;
      }

      let { functionName, functionParams, afterSubmit } = onClick({ data, state, setState, activeMQ, replaceUri });
      return (
        onSubmit &&
        onSubmit(functionName, functionParams, {
          afterSubmit,
          throwError: true
        }).catch(err => {
          this.onError(err);
        })
      );
    };

    render() {
      const { state = {}, setState, loginContainerProps = {} } = this.props;
      let { viewType } = state;
      let registerViewInfo = registerViewInfos[viewType];
      let { logo, title, primaryText, secondaryText, editor, button, footer, onClose, hideChatLink } = registerViewInfo;
      let { data } = loginContainerProps;
      let editorProps = {
        ...loginContainerProps,
        state: this.state,
        setState: this._setState
      };
      return (
        <Box
          className={"registrationPlaceholder"}
          key={viewType}
          render={[
            LoginHeader && (
              <LoginHeader
                title={"app-title"}
                onClose={
                  onClose
                    ? () => {
                        if (this.state.error) {
                          this._setState({ error: void 0 });
                        }
                        onClose({ data, setState });
                      }
                    : void 0
                }
              />
            ),
            {
              ...registerTheme.bodyContainerStyle,
              render: [
                { ...registerTheme.logoStyle, image: getImage(logo) },
                {
                  ...registerTheme.titleStyle,

                  text: I18N.t(title)
                },
                {
                  ...registerTheme.primaryTextStyle,
                  text: I18N.t(primaryText)
                },
                secondaryText && {
                  ...registerTheme.secondaryTextStyle,
                  text: I18N.t(secondaryText)
                },
                editor && {
                  ...registerTheme.editorContainerStyle,
                  render: <Editor {...editorProps} onClick={this.onClick} {...editor} />
                },
                button && {
                  ...registerTheme.buttonContainerStyle,
                  render: <Button onClick={this.onClick} loading={loginContainerProps.loading} {...button} />
                },
                footer && footer({ data, state, setState, onError: this.onError }),
                {
                  viewStyle: { alignItems: "center", marginTop: 30 },
                  render: !hideChatLink ? <ChatLinkComponent /> : void 0
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return WebRegister;
};
