/**
 *
 *  what to do with rest props - rohit bansal - 09-12-18
 */
import React from "react";
export default ({ errorComponent, ensureState, writeLog, Panel, ScreenContextProvider }) => {
  class ErrorScreen extends React.Component {
    constructor(props) {
      super(props);
      ensureState(props);
    }

    render() {
      try {
        let { error, state } = this.props;
        return (
          <ScreenContextProvider state={state}>
            <Panel>{errorComponent({ message: error.message, stack: error.stack })}</Panel>
          </ScreenContextProvider>
        );
      } catch (e) {
        writeLog && writeLog({ type: "error", log: e });
        return errorComponent({ message: e.message });
      }
    }
  }

  return ErrorScreen;
};
