import React from "react";
import firebase from "../../Firebase";
const captchaHeight = 78;

export default ({ Box, I18N, getImage, StatusBar }) => {
  class ReCaptcha extends React.Component {
    state = { loading: true };

    componentDidMount() {
      this.recaptchaVerifier();
    }

    recaptchaVerifier = async () => {
      let { data, field, setValue } = this.props;

      const recaptchaVerifier = await new firebase.auth.RecaptchaVerifier("captcha", {
        size: "normal",
        hl: I18N.t("locale-string"),
        callback: response => {
          setValue && setValue({ data, field, value: "validated" });
        },
        "expired-callback": () => {
          setValue && setValue({ data, field, value: "expired" });
        }
      });

      recaptchaVerifier
        .render()
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false, error: true });
        });
    };

    onRefreshClick = () => {
      this.setState({ error: false, loading: true }, () => {
        this.recaptchaVerifier();
      });
    };

    errorComponent = () => {
      return (
        <Box
          viewStyle={{ alignItems: "center", justifyContent: "center", height: captchaHeight }}
          onClick={this.onRefreshClick}
          render={[
            { viewStyle: { cursor: "pointer" }, image: getImage("refreshIcon"), title: I18N.t("refresh") },
            { viewStyle: { marginTop: 12 }, textStyle: { color: "red", fontSize: 14 }, text: I18N.t("errorInCaptcha") }
          ]}
        />
      );
    };

    render() {
      let { loading, error } = this.state;
      return (
        <Box
          render={[
            loading ? (
              <Box viewStyle={{ height: captchaHeight }} render={<StatusBar />} />
            ) : error ? (
              this.errorComponent()
            ) : null,
            <div id='captcha' />
          ]}
        />
      );
    }
  }

  return ReCaptcha;
};
