import React from "react";
import { Card } from "./Cards";
import { FailedNoData, InfectedNoData } from "../noData/NoData";
import { I18N, theme } from "../../../FsCloudComponent";
import { failedItemsQuery, infectedItemsQuery } from "../queries";
import ResourceActions from "../resource/ResourceActions";

export default {
  FailedItems: {
    component: "table",
    title: () => I18N.t("failed"),
    dataObject: "Resource",
    renderNoData: <FailedNoData />,
    ...failedItemsQuery,
    listProps: {
      addMinHeightSM: 56,
      addMinHeightMD: false,
      list: "scrollIndicatorList",
      paddingTop: 94
    },
    showHeaderMD: true,
    headerRowPropsMD: {
      theme: {
        ...theme.tableHeaderRowStyleMD,
        viewStyle: {
          ...theme.tableHeaderRowStyleMD.viewStyle,
          marginRight: 95
        }
      }
    },
    bodyPropsMD: {
      bodyStyle: {
        flex: 1,
        paddingRight: 80
      }
    },
    rowProps: {
      ...Card({ origin: "failed" })
    },
    headerActionsSM: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: [
          "selectItems",
          "selectAll",
          {
            ...ResourceActions.clearFailedItems,
            visible: (data, { selectionMode }) => data && data.length && !selectionMode
          },
          "selectNone",
          "deleteFailedSM"
        ]
      }
    ],
    headerActionsMD: [
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        action: "deleteFailedItems"
      },
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => data && data.length && !selectionMode,
        action: ["clearFailedItems"]
      },
      {
        type: "headerAction",
        image: "iconMenu",
        visible: ({ data, selectionMode }) => selectionMode && data && data.length,
        action: ["selectAll", "selectNone"]
      }
    ]
  },
  InfectedItems: {
    component: "table",
    title: () => I18N.t("infected"),
    dataObject: "Resource",
    renderNoData: <InfectedNoData />,
    ...infectedItemsQuery,
    listProps: {
      addMinHeightSM: 56,
      addMinHeightMD: false,
      list: "scrollIndicatorList",
      paddingTop: 94
    },
    showHeaderMD: true,
    headerRowPropsMD: {
      theme: {
        ...theme.tableHeaderRowStyleMD,
        viewStyle: {
          ...theme.tableHeaderRowStyleMD.viewStyle,
          marginRight: 95
        }
      }
    },
    bodyPropsMD: {
      bodyStyle: {
        flex: 1,
        paddingRight: 80
      }
    },
    rowProps: {
      ...Card({ origin: "infected" })
    },
    headerActionsSM: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: [
          "selectItems",
          "selectAll",
          {
            ...ResourceActions.clearInfectedItems,
            visible: (data, { selectionMode }) => data && data.length && !selectionMode
          },
          "selectNone",
          "deleteFailedSM"
        ]
      }
    ],
    headerActionsMD: [
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        action: "deleteFailedItems"
      },
      {
        type: "multiActions",
        visible: ({ data, selectionMode }) => data && data.length && !selectionMode,
        action: ["clearInfectedItems"]
      },
      {
        type: "headerAction",
        image: "iconMenu",
        visible: ({ data, selectionMode }) => selectionMode && data && data.length,
        action: ["selectAll", "selectNone"]
      }
    ]
  }
};
