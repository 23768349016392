import { I18N } from "../../../FsCloudComponent";
import HighlightViewSM from "./HighlightViewSM";
import HighlightViewMD from "./HighlightViewMD";

export default {
  HighlightTab: {
    component: "tab",
    // swipeableSM: true,
    tabs: {
      highlights: {
        tab: {},
        absoluteHeader: true,
        screen: "Highlight"
      }
    },
    showTitleInTab: true,
    title: () => I18N.t("app-title")
  },
  Highlight: {
    component: "table",
    title: () => I18N.t("highlights"),
    ComponentSM: HighlightViewSM,
    ComponentMD: HighlightViewMD
  }
};
