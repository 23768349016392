import { I18N, Platform } from "../../../FsCloudComponent";
const GALLERY_TABS = {
  galleryAll: {
    tab: {
      text: () => I18N.t("all")
    },
    absoluteHeader: true,
    fastScrollerRequire: true,
    screenSM: "GalleryAll",
    screenMD: "GalleryAllWeb"
  },
  albums: {
    tab: {
      text: () => I18N.t("albums")
    },
    absoluteHeader: true,
    screen: "Album"
  },
  places: {
    tab: {
      text: () => I18N.t("places")
    },
    absoluteHeader: true,
    screen: "Places"
  }
};
if (Platform.OS === "android") {
  GALLERY_TABS.deviceFolder = {
    tab: {
      text: () => I18N.t("library")
    },
    absoluteHeader: true,
    screen: "DeviceFolder"
  };
}
export default {
  GalleryTab: {
    component: "tab",
    titleSM: () => I18N.t("app-title"),
    titleMD: () => I18N.t("gallery"),
    showTitleInTabSM: true,
    swipeableSM: true,
    swipeableMD: false,
    tabs: GALLERY_TABS
  }
};
