import React from "react";

export default ({ View, theme, resolveMQ, LoadingIndicator }) => {
  let { colors } = theme;
  let { themeColor } = colors;

  const containerStyle = {
    boxStyleSM: {
      height: 19,
      width: 40,
      marginRight: 6,
      marginLeft: 6,
      background: "transparent",
      position: "relative"
    },
    boxStyleMD: { display: "inline-block", height: 19, width: 40, background: "transparent", position: "relative" },
    spinnnerOuterStyleSM: {
      height: 19,
      width: 40,
      marginRight: 6,
      marginLeft: 6,
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
    barStyle: { backgroundColor: themeColor }
  };

  class EqualizerComponent extends React.Component {
    render() {
      const { activeMQ, playerState } = this.props;
      let { boxStyle, barStyle, spinnnerOuterStyle } = resolveMQ(
        containerStyle,
        ["boxStyle", "barStyle", "spinnnerOuterStyle"],
        activeMQ
      );
      const pauseAnimation = playerState === "Paused" ? "pause-animation" : "";
      let renderComponent = void 0;
      if (playerState === "Buffering") {
        renderComponent = (
          <View style={spinnnerOuterStyle}>
            <LoadingIndicator size={19} width={2} color={themeColor} />
          </View>
        );
      } else {
        renderComponent = (
          <View style={boxStyle}>
            <div className={`everlib-logo-first-bar ${pauseAnimation}`} style={barStyle} />
            <div className={`everlib-logo-second-bar ${pauseAnimation}`} style={barStyle} />
            <div className={`everlib-logo-third-bar ${pauseAnimation}`} style={barStyle} />
            <div className={`everlib-logo-fourth-bar ${pauseAnimation}`} style={barStyle} />
          </View>
        );
      }
      return renderComponent;
    }
  }
  return EqualizerComponent;
};
