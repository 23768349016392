const shadow1 = {
  boxShadow: "0px 1px 6px 0px rgba(144,164,183, 0.22)"
};

const shadow2 = {
  boxShadow: "0px 1px 6px 0px rgba(144,164,183, 0.22)"
};

const loginShadow = {
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
};
const shadow3 = {
  boxShadow: "-1pt -1pt 3pt 0pt rgba(184,179,249,0.59)"
};

const shadow4 = {
  boxShadow: "0pt 2px 10px 0px rgba(0, 0, 0, 0.03)"
};

const shadow5 = {
  boxShadow: "0px 4px 15px 0px rgba(0,0,0, 0.15)"
};
const feedbackshadow = {
  boxShadow: "0px 2px 8px 2px rgba(0, 0, 0, 0.1)"
};

const addToAlbumShadow = {
  boxShadow: "0px 1px 2px 0px rgba(16, 0, 0, 0.1)"
};

const cardShadow = {
  boxShadow: "0px 1px 2px 0px rgba( 0, 0 ,0, 0.16)"
};
const bottomTabShadow = {
  boxShadow: "0px -1px 4px 0px rgba(12, 0, 0, 0.1)"
};

const menuHeaderShadow = {
  boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.03)"
};

const navHeaderShadowSM = {
  boxShadow: "0px 0px 1px 0px rgba(47, 60, 72, 0.2)"
};

const navHeaderShadowMD = {
  boxShadow: "0pt 2px 10px 0px rgba(0, 0, 0, 0.03)"
};

const imageViewerHeaderShadowMD = {
  boxShadow: "0pt 2px 10px 0px rgba(0, 0, 0, 0.1)"
};

const musicDetailHeaderShadow = {
  boxShadow: "0pt 0px 9px 0px rgba(0, 0, 0, 0.1)"
};

const inputShadow = {
  boxShadow: "0pt 2px 4px 0px rgba(0, 0, 0, 0.2)"
};

export default {
  inputShadow,
  feedbackshadow,
  shadow1,
  shadow2,
  loginShadow,
  shadow3,
  shadow4,
  shadow5,
  addToAlbumShadow,
  bottomTabShadow,
  menuHeaderShadow,
  cardShadow,
  navHeaderShadowSM,
  navHeaderShadowMD,
  imageViewerHeaderShadowMD,
  musicDetailHeaderShadow
};
