import React from "react";

export default ({ Box, theme, I18N, resolveMQ, LoginSpinner, Platform }) => {
  const { colors, fonts, bgs } = theme;
  const { themeBg } = bgs;
  const { highlightColor } = colors;
  const { h16_18, h2_16, h9, h18, h16_l_18 } = fonts;

  const styles = {
    styleSM: {
      containerStyle: {
        paddingTop: 14,
        paddingBottom: 14,
        borderRadius: 4,
        backgroundColor: themeBg,
        justifyContent: "center",
        alignItems: "center"
      },
      loaderContainerStyle: {
        paddingTop: 9,
        paddingBottom: 9,
        borderRadius: 4,
        backgroundColor: themeBg,
        justifyContent: "center",
        alignItems: "center"
      },
      textContainerStyle: {
        textStyle: {
          ...h16_18,
          color: highlightColor
        }
      },
      loaderTextContainerStyle: {
        viewStyle: {
          paddingLeft: 12,
          paddingTop: 5,
          paddingBottom: 5
        },
        textStyle: {
          ...h16_l_18,
          color: highlightColor
        }
      },
      spinnerStyle: {
        size: Platform.OS === "ios" ? "small": 28,
      }
    },
    styleMD: {
      containerStyle: {
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 2.9,
        backgroundColor: themeBg,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      },
      loaderContainerStyle: {
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 2.9,
        backgroundColor: themeBg,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      },
      textContainerStyle: {
        textStyle: {
          ...h2_16,
          color: highlightColor
        }
      },
      loaderTextContainerStyle: {
        viewStyle: {
          paddingLeft: 18,
          paddingTop: 8,
          paddingBottom: 7
        },
        textStyle: {
          ...h9,
          color: highlightColor
        }
      },
      spinnerStyle: {
        size: 32,
        width: 3
      }
    },
    styleLG: {
      containerStyle: {
        paddingTop: 23,
        paddingBottom: 23,
        borderRadius: 4,
        backgroundColor: themeBg,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      },
      loaderContainerStyle: {
        paddingTop: 12,
        paddingBottom: 10,
        borderRadius: 4,
        backgroundColor: themeBg,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      },
      textContainerStyle: {
        textStyle: {
          ...h16_18,
          color: highlightColor
        }
      },
      loaderTextContainerStyle: {
        viewStyle: {
          paddingLeft: 24,
          paddingTop: 9,
          paddingBottom: 11
        },
        textStyle: {
          ...h18,
          color: highlightColor
        }
      }
    }
  };

  class LoginButton extends React.Component {
    render() {
      let { activeMQ, loading, onClick, text, loaderText } = this.props;
      let { style } = resolveMQ(styles, ["style"], activeMQ);
      text = (loading && loaderText) || text;
      return (
        <Box
          onClick={onClick}
          direction={"row"}
          viewStyle={{
            ...(loading ? style.loaderContainerStyle : style.containerStyle)
          }}
          render={[
            loading &&
              LoginSpinner && {
                render: <LoginSpinner {...style.spinnerStyle} />
              },
            {
              ...(loading ? style.loaderTextContainerStyle : style.textContainerStyle),
              text: I18N.t(text) || text
            }
          ]}
        />
      );
    }
  }
  return LoginButton;
};
