export default ({ fonts, bgs, colors, Platform }) => {
  const { h16, h24 } = fonts;
  const { errorColor, highlightColor, themeColor, primaryColor } = colors;
  const { loginOtpBg, themeBg, primaryBorder, borderBg4 } = bgs;

  const loginContainerStyle = {
    boxStyle: {
      viewStyle: {
        flex: 1,
        overflow: void 0
      }
    },
    containerStyle: {
      viewStyle: {
        flex: 1
      },
      itemStyle: {
        flex: 1
      }
    },
    scrollViewStyleSM: Platform.OS === "web" ? { style: { flex: 1 } } : { contentContainerStyle: { flexGrow: 0 } },
    scrollViewStyleMD: {
      contentContainerStyle: { flex: 1 }
    }
  };

  const loginEditorContainerStyle = {
    editorContainerStyleSM: {
      paddingBottom: 15,
      paddingTop: 14,
      borderWidth: 1,
      borderRadius: 4,
      borderColor: borderBg4
    },
    editorContainerStyleMD: {
      paddingTop: 16,
      paddingBottom: 15,
      paddingLeft: 20,
      borderRadius: 2.9,
      backgroundColor: loginOtpBg
    },
    editorContainerStyleLG: {
      paddingTop: 23,
      paddingBottom: 22,
      paddingLeft: 20,
      borderRadius: 4,
      backgroundColor: loginOtpBg
    },
    errorEditorContainerStyleSM: {
      borderColor: errorColor
    },
    errorEditorContainerStyleMD: {
      borderWidth: 1,
      borderColor: errorColor
    },
    iconStyle: {
      viewStyle: {
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 14,
        paddingRight: 16
      },
      imageProps: {
        resizeMode: "center"
      }
    }
  };
  const reCaptchaContainerStyle = {
    iconStyle: {
      viewStyle: {
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 14,
        paddingRight: 16
      },
      imageProps: {
        resizeMode: "center"
      }
    }
  };

  const loginSubmitButtonStyle = {
    viewStyle: {
      backgroundColor: themeBg,
      paddingTop: 14,
      paddingBottom: 13,
      marginRight: 27,
      marginLeft: 27,
      marginBottom: 23,
      borderRadius: 4,
      cursor: "pointer"
    },
    textStyle: {
      ...h16,
      color: highlightColor,
      textAlign: "center"
    }
  };

  const otpBoxStyle = {
    tintColor: themeColor,
    offTintColor: primaryBorder,
    cellGap: 8,
    cellTextStyle: {
      ...h24,
      color: primaryColor,
      textAlign: "center"
    },
    cellBoxStyle: {
      flex: 1,
      justifyContent: "flex-end",
      height: 50,
      paddingTop: 7,
      paddingBottom: 7,
      borderBottomWidth: 1.5
    }
  };

  return {
    loginContainerStyle,
    loginSubmitButtonStyle,
    loginEditorContainerStyle,
    otpBoxStyle,
   reCaptchaContainerStyle
  };
};
