/**
 * primaryOnActive is used to make row action as primary action of table row. Rohit Garg 2-Mar-19
 *
 * openModalOnRightClick is passed to ActionModal - used to show actions on right click - useCase - fsCloud project - Rohit Garg 3-May-19
 *
 * */

import React from "react";

export default ({
  Box,
  WithModal,
  resolveMQ,
  renderChildren,
  getComponent,
  resolveTheme,
  getAction,
  getImage,
  theme: themeHoc,
  menuItemsType = "menuItems",
  singleActionType = "onlyAction",
  confirmBoxType = "confirmBox",
  actionFormType = "actionForm"
}) => {
  class RowAction extends React.Component {
    state = {};

    getTheme = () => {
      return this.props.theme || themeHoc || {};
    };

    onActionDone = ({ closeModal } = {}) => {
      this.props.onActionDone && this.props.onActionDone();
      closeModal && closeModal();
    };

    onAction = () => {
      this.props.onActionStart && this.props.onActionStart();
    };

    onModalOpen = () => {
      this.onAction();
    };

    onModalClose = props => {
      if (this.state.isForm) {
        this.setState({ isForm: false, selectedAction: void 0 });
      } else if (this.state.isConfirm) {
        this.setState({ isConfirm: false, confirmProps: void 0 });
      }
      this.onActionDone(props);
    };

    onActionClick = (props = {}, { closeModal, action, actionDef } = {}) => {
      let { source, selectionRequired, updatePrimaryAction } = this.props;
      let { form, confirm, primaryOnActive } = actionDef;
      primaryOnActive && updatePrimaryAction && updatePrimaryAction(action);
      if (form) {
        let actionProps = { ...props, source, selectionRequired };
        this.setState({
          isForm: true,
          selectedAction: { action: actionDef, props: actionProps }
        });
      } else if (confirm) {
        if (confirm === true) {
          confirm = {};
        }
        this.onAction();
        this.setState({
          isConfirm: true,
          confirmProps: { ...confirm, props },
          selectedAction: { action: actionDef }
        });
      } else {
        const { onClick, ...restProps } = props;
        onClick && onClick({ ...restProps, source, selectionRequired });
        actionDef.onClick && actionDef.onClick();
        this.onActionDone({ closeModal });
      }
    };

    renderConfirm = ({ closeModal }) => {
      let { data } = this.props;
      let { confirmTitle, confirmMessage } = this.getTheme();
      let { confirmProps: { props, ...restConfirmProps } = {}, selectedAction: { action } = {} } = this.state;
      let ConfirmBox = getComponent(this.props.confirmBoxType || confirmBoxType);
      return (
        <ConfirmBox
          data={data}
          action={action}
          title={confirmTitle}
          message={confirmMessage}
          {...restConfirmProps}
          onConfirm={() => {
            const { onClick, ...restProps } = props;
            onClick && onClick({ ...restProps, source: this.props.source });
            this.onActionDone({ closeModal });
          }}
          onCancel={() => {
            this.onModalClose({ closeModal });
          }}
        />
      );
    };

    renderForm = ({ closeModal } = {}) => {
      let { selectedAction: { action, props } = {} } = this.state;
      return this.renderFormComponent({
        onClose: () => {
          this.onModalClose({ closeModal });
        },
        action,
        actionProps: props
      });
    };

    renderFormComponent = formProps => {
      let { data } = this.props;
      let {
        action: { form }
      } = formProps;
      const { formStyle = {} } = resolveMQ(this.getTheme(), ["formStyle"], this.props.activeMQ);
      const { component } = resolveMQ(form, ["component"], this.props.activeMQ);
      let FormComponent = getComponent(component || this.props.actionFormType || actionFormType);
      return <FormComponent data={data} boxStyle={formStyle} {...formProps} />;
    };

    renderMenu = ({ closeModal }) => {
      let { action, data, actions, user } = this.props;
      let onItemClick = (props, actionProps) => {
        this.onActionClick(props, {
          ...actionProps,
          closeModal
        });
      };
      let MenuItems = getComponent(this.props.menuItemsType || menuItemsType);
      return <MenuItems data={data} user={user} items={action} actions={actions} onItemClick={onItemClick} />;
    };

    getWithModalProps = () => {
      let { formModalStyle, menuModalStyle, confirmBoxModalStyle } = resolveMQ(
        this.getTheme(),
        ["formModalStyle", "confirmBoxModalStyle", "menuModalStyle"],
        this.props.activeMQ
      );
      if (this.props.menuModalStyle) {
        menuModalStyle = this.props.menuModalStyle;
      }
      let withModalProps;
      if (this.state.isForm) {
        withModalProps = {
          ...formModalStyle,
          renderModal: this.renderForm
        };
      } else if (this.state.isConfirm) {
        withModalProps = {
          ...confirmBoxModalStyle,
          renderModal: this.renderConfirm
        };
      } else {
        withModalProps = {
          ...menuModalStyle,
          renderModal: this.renderMenu
        };
      }
      withModalProps.onModalOpen = this.onModalOpen;
      withModalProps.onModalClose = this.onModalClose;
      return withModalProps;
    };

    render() {
      let { children, style, action, actions, image, text, title, data, openModalOnRightClick } = this.props;
      const { boxStyle = {} } = resolveMQ(this.getTheme(), ["boxStyle"], this.props.activeMQ);
      let actionDef = void 0;
      if (action && !Array.isArray(action)) {
        if (typeof action === "string") {
          actionDef = actions[action];
          if (!actionDef && getAction(action)) {
            actionDef = { type: action };
          }
        } else {
          actionDef = action;
        }
        if (!actionDef) {
          return <Box text={`RowAction not found for [${action}]`} />;
        }
      }
      let component = renderChildren(children);
      if (!component) {
        let actionInfo = {};
        if (title !== undefined) {
          actionInfo.title = title;
        }
        if (image) {
          actionInfo.image = getImage(image) || image;
        }
        if (text) {
          actionInfo.text = text;
        }
        if (Array.isArray(action)) {
          if (!this.props.highlighted && this.props.rowActionIcon) {
            //if it is highlighted then apply default image :- paritohs - 26.02.2018
            image = resolveTheme(this.props.rowActionIcon);
            if (image !== undefined) {
              actionInfo.image = image;
            }
          }
          component = <Box {...boxStyle} style={style} {...actionInfo} />;
        } else {
          let ActionComponent = getComponent(this.props.singleActionType || singleActionType);
          component = (
            <ActionComponent
              action={{
                ...actionDef,
                ...actionInfo
              }}
            />
          );
        }
      }
      let withModalProps = this.getWithModalProps();
      let { selectedAction } = this.state;
      let formModalProps =
        selectedAction && selectedAction.action && selectedAction.action.form && selectedAction.action.form.modalProps;

      if (formModalProps) {
        withModalProps = {
          ...withModalProps,
          ...formModalProps
        };
      }
      if (action && Array.isArray(action)) {
        component = (
          <WithModal {...withModalProps} data={data} openModalOnRightClick={openModalOnRightClick}>
            {component}
          </WithModal>
        );
      } else if (this.state.isForm || this.state.isConfirm) {
        component = (
          <WithModal {...withModalProps} showModal={true}>
            {component}
          </WithModal>
        );
      } else {
        let onClick = (props = {}) => {
          this.onActionClick(props, { action, actionDef });
        };
        const Action = getAction(actionDef.type);
        if (Action) {
          component = (
            <Action data={data} action={actionDef} onClick={onClick}>
              {component}
            </Action>
          );
        } else {
          component = renderChildren(component, {
            onClick: e => {
              e && e.stopPropagation && e.stopPropagation();
              onClick && onClick(e);
            }
          });
        }
      }
      return component;
    }
  }
  return RowAction;
};
