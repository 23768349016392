const getHash = () => {};

const startListener = () => {};

const removeListener = () => {};

export default {
  getHash,
  startListener,
  removeListener
};
