export default () => {
  const textBoxMultipleStyle = {
    boxStyle: {
      viewStyle: {
        flexWrap: "wrap"
      },
      direction: "row"
    },
    inputContainerStyle: {
      viewStyle: {
        minWidth: 100,
        flex: 1,
        justifyContent: "center"
      }
    }
  };

  return {
    textBoxMultipleStyle
  };
};
