import { I18N } from "../../../../FsCloudComponent";
import { recoverContactQuery, mergeDuplicateContactQuery } from "../../queries";
const addContactForm = {
  component: "addContact",
  modalProps: {
    modalMD: {
      stopPropagation: true,
      position: "center",
      width: 573,
      height: 0.96,
      maxHeight: "96%",
      style: {
        borderRadius: 4
      }
    }
  }
};

const contactSelectorForm = {
  component: "contactSelector",
  modalProps: {
    modalSM: {
      stopPropagation: true,
      position: "center",
      width: 0.9,
      style: {
        borderRadius: 4
      }
    },
    modalMD: {
      stopPropagation: true,
      position: "center",
      style: {
        borderRadius: 4
      }
    }
  }
};

const actions = {
  deleteContact: {
    type: "invoke",
    image: "deleteIcon",
    title: () => I18N.t("deleteContact"),
    confirm: {
      titleMD: () => I18N.t("deleteContactHeaderCaps"),
      message: props => {
        let { data, getSelectedData } = props;
        let name = void 0;
        if (Array.isArray(data)) {
          let selectedData = getSelectedData && getSelectedData();
          if (selectedData) {
            name = selectedData.length === 1 ? selectedData[0].name : `${selectedData.length} ${I18N.t("contacts")}`;
          }
        } else if (data) {
          name = data.name;
        }
        let translateMsg = I18N.t("deleteContactMessage1");
        translateMsg = translateMsg.replace("__contactName__", `"${name}"`);
        return [{ text: translateMsg }, { viewStyle: { paddingTop: 6 }, text: I18N.t("deleteContactMessage2") }];
      },
      confirmText: () => I18N.t("delete"),
      cancelText: () => I18N.t("cancel")
    },
    postMessage: () => I18N.t("contactDeletedMessgae"),
    service: (props, { urls }) => {
      const { data, selectedIds } = props;
      let contacts = void 0;
      if (Array.isArray(data) && selectedIds && selectedIds.length) {
        contacts = [...selectedIds];
      } else if (data) {
        contacts = [data._id];
      }
      const commandToPost = {
        url: urls["markContactsDeleted"],
        uriProps: {
          data: {
            contacts
          }
        }
      };
      return commandToPost;
    }
  },
  addContact: {
    type: "invoke",
    image: "createWithCircleIcon",
    title: () => I18N.t("addContact"),
    form: {
      ...addContactForm,
      connectProps: {
        dataMode: "insert"
      }
    }
  },
  addContactLink: {
    type: "link",
    link: {
      uri: "/add-contact"
    }
  },
  editContactLink: {
    type: "link",
    image: "editIcon",
    link: props => {
      let { data, getSelectedIds, unSelectAll } = props;
      let selectedIds = (getSelectedIds && getSelectedIds()) || [];
      let contactId = selectedIds.length ? selectedIds[0] : void 0;
      if (!contactId && data) {
        contactId = Array.isArray(data) ? data[0] && data[0]._id : data._id;
      }
      unSelectAll && unSelectAll();
      return {
        uri: "/edit-contact",
        props: {
          filter: { _id: contactId }
        }
      };
    }
  },
  searchContact: {
    type: "link",
    link: {
      uri: "/search-contact",
      props: {
        params: {
          from: "search-contact"
        }
      }
    }
  },
  editContact: {
    type: "invoke",
    image: "editIcon",
    title: () => I18N.t("editContact"),
    form: addContactForm
  },
  restoreContact: {
    type: "invoke",
    image: "restoreIcon",
    text: () => I18N.t("recover"),
    origin: "recoverContact",
    title: () => I18N.t("restoreContact"),
    form: contactSelectorForm,
    ...recoverContactQuery
  },
  mergeDuplicateContact: {
    type: "invoke",
    text: () => I18N.t("duplicate"),
    image: "contactGroupIcon",
    origin: "mergeDuplicate",
    title: () => I18N.t("mergeDuplicateContact"),
    form: contactSelectorForm,
    ...mergeDuplicateContactQuery
  }
};
actions["deleteContactDetail"] = {
  ...actions.deleteContact,
  afterInvoke: (result, props) => {
    let { deleteUri, link, getPath } = props;
    deleteUri && deleteUri(getPath && getPath(), link);
  }
};

export default actions;
