import { View, Text, ScreenContextProvider } from "./BasicComponents";
import { separateNumber } from "style-utility";
import {
  beforeFetch,
  resolveLinkFilter,
  resolveFilterDef,
  resolveLinkDefaults,
  resolveVisibleExp,
  resolveColumn,
  getAggregateValue,
  setState as setStateHoc
} from "app-utility-functions";
import {
  ScreenLoader as ScreenLoaderHoc,
  ScreenLoaderUtil as ScreenLoaderUtilHoc,
  TableScreenLoader as TableScreenLoaderHoc,
  FormScreenLoader as FormScreenLoaderHoc,
  CustomScreenLoader as CustomScreenLoaderHoc,
  TabScreenLoader as TabScreenLoaderHoc,
  ConnectPanelTable as ConnectPanelTableHoc,
  JsonTable as JsonTableHoc,
  GetRoute as GetRouteHoc,
  ErrorScreen as ErrorScreenHoc
} from "../../json-components";
const connectStateKey = "Connect";
const selectionStoreStateKey = "SelectionStore";
const routeStateKey = "Route";
const componentStateKey = "Component";
const tabStateKey = "Tab";
const tabParamsStateKey = "TabParams";
const selectedTabStateKey = "SelectedTab";

export default ({
  withContext,
  enhancePropWithMQ,
  breakPoints,
  dataObjects,
  views,
  theme,
  routes,
  screens,
  resolveMQ,
  resolveActionLayout,
  getImage,
  Table,
  GridTable,
  Panel,
  Form,
  dataFormat,
  getTheme,
  Connect,
  SelectionStore,
  getComponent,
  sortHeader,
  addLog,
  logFirebaseAnalyticsScreenEvent
}) => {
  const ScreenLoaderUtil = ScreenLoaderUtilHoc({
    Text,
    View,
    breakPoints,
    mergeUri: beforeFetch,
    resolveLinkFilter,
    resolveFilterDef
  });
  const { getUri, errorComponent, ensureState, writeLog, resolveHeaderActions } = ScreenLoaderUtil;

  const ErrorScreen = ErrorScreenHoc({
    errorComponent,
    ensureState,
    writeLog,
    Panel,
    ScreenContextProvider
  });

  const JsonTable = withContext(
    JsonTableHoc({
      resolveMQ,
      dataFormat,
      getTheme,
      theme,
      resolveColumn,
      getAggregateValue,
      separateNumber,
      resolveVisibleExp,
      getComponent,
      sortHeader
    }),
    {
      activeMQ: "ActiveMQ",
      user: "User.user",
      language: "App.pstate.language",
      orientation: "App.orientation"
    }
  );

  const ConnectPanelTable = withContext(
    ConnectPanelTableHoc({
      errorComponent,
      connectStateKey,
      componentStateKey,
      selectionStoreStateKey,
      JsonTable,
      ensureState,
      writeLog,
      Connect,
      Panel,
      SelectionStore,
      resolveMQ,
      ScreenContextProvider,
      setState: setStateHoc,
      getComponent,
      addLog,
      logFirebaseAnalyticsScreenEvent
    }),
    {
      activeMQ: "ActiveMQ",
      screenHeight: "App.screenHeight",
      screenWidth: "App.screenWidth",
      getPath: "Router.getPath",
      addUri: "Router.addUri",
      deleteUri: "Router.deleteUri",
      replaceUri: "Router.replaceUri",
      getParentInfo: "Router.getParentInfo",
      language: "App.pstate.language",
      orientation: "App.orientation"
    }
  );

  const tableTypes = {
    gridTable: GridTable
  };

  const { loader: tableScreenLoader } = TableScreenLoaderHoc({
    dataObjects,
    views,
    getUri,
    enhancePropWithMQ,
    errorComponent,
    ConnectPanelTable,
    Table,
    resolveHeaderActions,
    resolveActionLayout,
    resolveMQ,
    resolveLinkDefaults,
    getComponent: type => tableTypes[type]
  });

  const { loader: formScreenLoader } = FormScreenLoaderHoc({
    dataObjects,
    views,
    getUri,
    enhancePropWithMQ,
    errorComponent,
    ConnectPanelTable,
    resolveHeaderActions,
    resolveActionLayout,
    Form,
    resolveLinkDefaults
  });

  const { loader: customScreenLoader } = CustomScreenLoaderHoc({
    dataObjects,
    views,
    getUri,
    enhancePropWithMQ,
    errorComponent,
    ConnectPanelTable,
    resolveHeaderActions,
    resolveActionLayout,
    Form,
    resolveLinkDefaults,
    resolveMQ
  });

  const { loader: tabScreenLoader } = TabScreenLoaderHoc({
    getImage,
    errorComponent,
    routeStateKey,
    tabStateKey,
    tabParamsStateKey,
    selectedTabStateKey,
    ScreenContextProvider,
    ensureState,
    writeLog,
    resolveMQ,
    getComponent,
    withContext,
    changeHash: true,
    skipShouldComponentUpdate: true,
    logFirebaseAnalyticsScreenEvent
  });

  const screenLoaders = {
    table: tableScreenLoader,
    form: formScreenLoader,
    tab: tabScreenLoader,
    custom: customScreenLoader
  };

  const { screenLoader } = ScreenLoaderHoc({ resolveMQ, screenLoaders, screens, errorComponent });

  const { getRoute: getRouteHoc } = GetRouteHoc({ resolveMQ, routes, screenLoader, ErrorScreen });

  const getRoute = getRouteHoc;

  return {
    getRoute,
    JsonTable,
    ConnectPanelTable
  };
};
