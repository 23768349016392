import React from "react";
import {
  Box,
  I18N,
  getInput,
  theme,
  resolveMQ,
  withContext,
  PopupHeader as Header,
  StatusBar as LoadingIndicator,
  ScrollView,
  Modal,
  Platform
} from "../../../FsCloudComponent";
import { getErrorMessage } from "./issuesUtility";

let { colors, fonts, bgs } = theme;
let { themeColor, highlightColor, primaryColor, tomato } = colors;
let { lightPink } = bgs;
let { h9 } = fonts;

const componentTheme = {
  containerStyle: { flex: 1 },
  bodyComponent: {
    containerStyle: {
      viewStyle: {
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 12
      }
    }
  },
  buttonBoxStyle: {
    direction: "row",
    viewStyle: {
      alignItems: "center",
      justifyContent: "flex-end",
      paddingTop: 12,
      paddingBottom: 12
    }
  },
  errorStyle: {
    viewStyle: {
      flex: 1,
      paddingRight: 12
    },
    textStyle: {
      ...h9,
      color: tomato,
      numberOfLines: 2
    }
  },
  submitButtonStyle: {
    viewStyle: {
      borderRadius: 4,
      cursor: "pointer",
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: themeColor
    },
    textStyle: { ...h9, color: highlightColor }
  }
};

let modalProps = {
  modalSM: {
    position: "center",
    width: 0.9,
    style: {
      height: Platform.OS !== "web" ? "72%" : "auto",
      borderRadius: 4,
      overflow: "hidden"
    },
    parentStyle: {
      zIndex: 12
    }
  },
  modalMD: {
    style: {
      width: 501,
      borderRadius: 4,
      escEnabled: false
    },
    parentStyle: {
      zIndex: 12
    }
  }
};
class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    const { item: { devFeedback, issue_eng } = {} } = props || {};
    this.state = {
      modalOpen: true,
      devFeedback,
      issue_eng
    };
  }

  onChangeValue = ({ value, field }) => {
    if (!value || (typeof value === "string" && value.trim().length === 0)) {
      value = "";
    }
    this.setState({ [field]: value });
    if (this.state.error) {
      this.setState({ error: void 0 });
    }
  };

  onSubmit = async () => {
    let { devFeedback, owner, status, issue_eng } = this.state;
    const { user, invoke, urls, item, onClose, updatedResult } = this.props || {};

    if (!user || !item || !item._id) {
      return;
    }

    let data = {
      issueId: item._id
    };
    if (devFeedback) {
      data = { ...data, devFeedback };
    }
    if (owner) {
      data = { ...data, owner };
    }
    if (status) {
      data = { ...data, status };
    }
    if (issue_eng) {
      data = { ...data, issue_eng };
    }

    try {
      this.setState({ loading: true });
      let result = await invoke({
        service: {
          url: urls["updateIssueUrl"],
          uriProps: {
            data
          }
        }
      });

      if (result) {
        updatedResult && updatedResult(result);
      }

      this.setState({ loading: false, modalOpen: false });
      onClose && onClose();
    } catch (err) {
      let errMessage = getErrorMessage(err);
      this.setState({ loading: false, error: errMessage });
    }
  };

  modalClose = async () => {
    const { onClose } = this.props || {};
    await this.setState({ modalOpen: false });
    onClose && onClose();
  };
  render() {
    const TextAreaInput = getInput("textArea");
    const TextInput = getInput("text");
    let { activeMQ } = this.props;
    let { devFeedback, owner, status, issue_eng, error } = this.state;
    const { buttonBoxStyle, submitButtonStyle, bodyComponent, errorStyle } = resolveMQ(
      componentTheme,
      ["buttonBoxStyle", "submitButtonStyle", "bodyComponent", "errorStyle"],
      activeMQ
    );

    const { modal } = resolveMQ(modalProps, ["modal"], activeMQ);
    return (
      <Modal isOpen={this.state.modalOpen} {...modal} onClose={this.modalClose}>
        {this.state.loading && <LoadingIndicator />}
        <ScrollView style={{ flex: 1 }}>
          <Box
            {...bodyComponent.containerStyle}
            render={[
              <Header title={"Edit Detail"} onClose={this.modalClose} />,
              {
                viewStyle: {
                  marginTop: 12,
                  marginBottom: 15,
                  backgroundColor: lightPink,
                  borderRadius: 4,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 14,
                  paddingBottom: 13
                },
                render: (
                  <TextAreaInput
                    placeholder={"Developer Feedback"}
                    value={devFeedback}
                    onChangeValue={value => this.onChangeValue({ value, field: "devFeedback" })}
                    style={{ ...h9, color: primaryColor }}
                    onDragStart={e => {
                      e && e.preventDefault && e.preventDefault();
                      e && e.stopPropagation && e.stopPropagation();
                    }}
                    maxLength={1000}
                  />
                )
              },
              {
                viewStyle: {
                  marginTop: 12,
                  marginBottom: 15,
                  backgroundColor: lightPink,
                  borderRadius: 4,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 14,
                  paddingBottom: 13
                },
                render: (
                  <TextAreaInput
                    placeholder={"Issue In Eng"}
                    value={issue_eng}
                    onChangeValue={value => this.onChangeValue({ value, field: "issue_eng" })}
                    style={{ ...h9, color: primaryColor }}
                    onDragStart={e => {
                      e && e.preventDefault && e.preventDefault();
                      e && e.stopPropagation && e.stopPropagation();
                    }}
                    maxLength={1000}
                  />
                )
              },
              {
                viewStyle: {
                  marginTop: 12,
                  marginBottom: 15,
                  backgroundColor: lightPink,
                  borderRadius: 4,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 14,
                  paddingBottom: 13
                },
                render: (
                  <TextInput
                    placeholder={"Owner: Must be fs/server/mobile"}
                    value={owner}
                    onChangeValue={value => this.onChangeValue({ value, field: "owner" })}
                    style={{ ...h9, color: primaryColor }}
                    onDragStart={e => {
                      e && e.preventDefault && e.preventDefault();
                      e && e.stopPropagation && e.stopPropagation();
                    }}
                  />
                )
              },
              {
                viewStyle: {
                  marginTop: 12,
                  marginBottom: 15,
                  backgroundColor: lightPink,
                  borderRadius: 4,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 14,
                  paddingBottom: 13
                },
                render: (
                  <TextInput
                    placeholder={"Status: Must be open/in_progress/done"}
                    value={status}
                    onChangeValue={value => this.onChangeValue({ value, field: "status" })}
                    style={{ ...h9, color: primaryColor }}
                    onDragStart={e => {
                      e && e.preventDefault && e.preventDefault();
                      e && e.stopPropagation && e.stopPropagation();
                    }}
                  />
                )
              },
              {
                ...buttonBoxStyle,
                render: [
                  {
                    ...errorStyle,
                    text: error || ""
                  },
                  {
                    ...submitButtonStyle,
                    text: I18N.t("submit"),
                    onClick: this.onSubmit
                  }
                ]
              }
            ]}
          />
        </ScrollView>
      </Modal>
    );
  }
}

export default (FormComponent = withContext(FormComponent, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  upload: "App.upload",
  user: "User.user",
  uploadRequest: "App.uploadRequest",
  replaceUri: "Router.replaceUri"
}));
