import UserDataObject from "./UserDataObject";
import MyProfileDataObject from "./MyProfileDataObject";
import CollectionDataObject from "./CollectionDataObject";
import ResourceDataObject from "./ResourceDataObject";
import SpaceAllocationDataObject from "./SpaceAllocationDataObject";
import GroupMemberDataObject from "./GroupMemberDataObject";
import CollaborationDataObject from "./CollaborationDataObject";
import ContactDataObject from "./ContactDataObject";
import IssuesDataObject from "./IssuesDataObject";

export default {
  ...UserDataObject,
  ...MyProfileDataObject,
  ...CollectionDataObject,
  ...ResourceDataObject,
  ...GroupMemberDataObject,
  ...CollaborationDataObject,
  ...SpaceAllocationDataObject,
  ...ContactDataObject,
  ...IssuesDataObject
};
