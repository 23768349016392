import React from "react";
import {
  Box,
  ImageViewer,
  getImage,
  RowAction,
  theme,
  Platform,
  checkFileExistsLocally,
  googleCast,
  ImageEditor
} from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import {
  isImportFromGroupDetailVisible,
  isRemoveFromGroupDetailVisible,
  uploadResourceActionVisibility,
  isResourceUploading
} from "../resource/ResourceUtility";
import { SECURE_TYPES } from "../../../Constants";

const { colors, fonts } = theme;
const { highlightColor } = colors;
const { h16 } = fonts;

const metadata = {
  gallery: {
    addItemToCollectionLink: "addImagesToAlbumLink"
  },
  album: {
    addItemToCollectionLink: "addImagesToAlbumLink",
    removeFromCollection: "removeFromAlbum"
  },
  places: {
    addItemToCollectionLink: "addImagesToAlbumLink"
  },
  deviceFolder: {
    addItemToCollectionLink: "addImagesToAlbumLink"
  },
  doc: {
    addItemToCollectionLink: "addDocsToSetLink"
  },
  set: {
    addItemToCollectionLink: "addDocsToSetLink",
    removeFromCollection: "removeFromSet"
  },
  vaultGallery: {
    addItemToCollectionLink: "addImagesToVaultAlbumLink"
  },
  vaultDoc: {
    addItemToCollectionLink: "addDocsToVaultSetLink"
  },
  vaultAlbum: {
    addItemToCollectionLink: "addImagesToVaultAlbumLink",
    removeFromCollection: "removeFromAlbum"
  },
  vaultSet: {
    addItemToCollectionLink: "addDocsToVaultSetLink",
    removeFromCollection: "removeFromSet"
  }
};

const renderHeaderStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      alignItems: "center",
      height: 64,
      paddingRight: 8,
      backgroundColor: "rgba(0,0,0,0.4)",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2
    },
    textStyle: {
      ...h16,
      color: highlightColor,
      numberOfLines: 1
    }
  },
  actionStyle: {
    viewStyle: {
      width: 44,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const renderFooterStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      justifyContent: "space-between",
      backgroundColor: "rgba(0,0,0,0.4)",
      ...(Platform.OS === "web"
        ? {
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0
          }
        : void 0)
    }
  },
  actionStyle: {
    viewStyle: {
      width: 44,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const renderVaultFooterStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.4)",
      ...(Platform.OS === "web"
        ? {
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0
          }
        : void 0)
    }
  },
  actionStyle: {
    viewStyle: {
      width: 62,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const groupGalleryRenderFooterStyle = {
  containerStyle: {
    direction: "row",
    viewStyle: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.4)",
      ...(Platform.OS === "web"
        ? {
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0
          }
        : void 0)
    }
  },
  actionStyle: {
    viewStyle: {
      width: 62,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }
  }
};

const renderHeader = ({ isCastPresent, onClose, origin, value: data = {}, isDownloadVisible, fromShare }) => {
  let { actionStyle, containerStyle } = renderHeaderStyle;
  const { removeFromCollection, addItemToCollectionLink } = metadata[origin];
  let { sharedCollection, resourceOwner, collectionOwner } = data;
  resourceOwner = !sharedCollection || resourceOwner; // if not shared collection, then current user will be resource owner
  let isUploading = isResourceUploading(data);
  let isVault = data.secureType === SECURE_TYPES.VAULT;
  let isArchive = data.secureType === SECURE_TYPES.ARCHIVE;
  let isCollection = data.collection && data.collection_id;
  const { CastButton } = googleCast || {};
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backWhiteIcon"),
      onClick: onClose
    },
    {
      width: "1fr"
    },
    {
      render: isCastPresent && CastButton && <CastButton />
    },
    {
      render: (
        <RowAction
          data={data}
          action={[
            {
              ...ResourceActions[addItemToCollectionLink],
              visible: () => !fromShare && resourceOwner && !isUploading && !isArchive
            },
            {
              ...ResourceActions[removeFromCollection],
              visible: () => isCollection && !fromShare && (resourceOwner || collectionOwner)
            },
            { ...ResourceActions.download, visible: !isUploading && isDownloadVisible },
            { ...ResourceActions.rename, visible: () => !fromShare && resourceOwner && !isUploading },
            {
              ...ResourceActions.makePrivate,
              visible: () => !isCollection && !fromShare && !data.private && !isVault && !isArchive
            },
            {
              ...ResourceActions.moveToVault,
              visible: () => !isUploading && resourceOwner && !fromShare && !isVault && !isArchive
            },
            {
              ...ResourceActions.removeFromVault,
              visible: () => !isUploading && resourceOwner && !fromShare && isVault
            },
            {
              ...ResourceActions.moveToArchive,
              visible: () => !isUploading && resourceOwner && !fromShare && !isArchive && !isVault
            },
            {
              ...ResourceActions.removeFromArchive,
              visible: () => !isUploading && resourceOwner && !fromShare && isArchive
            },
            {
              ...ResourceActions.printFile,
              origin,
              visible: () => !fromShare && data.type === "image" && data.ext !== "gif" && resourceOwner
            },
            { ...ResourceActions.infoMobile, origin, visible: () => !fromShare && resourceOwner }
          ]}
        >
          <Box {...actionStyle} image={getImage("iconMenuWhite")} />
        </RowAction>
      )
    }
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

const groupGalleryRenderHeader = ({ onClose, origin, value: data = {} }) => {
  let { actionStyle, containerStyle } = renderHeaderStyle;
  let renderToShow = [
    {
      ...actionStyle,
      image: getImage("backWhiteIcon"),
      onClick: onClose
    }
  ];
  return <Box {...containerStyle} render={renderToShow} />;
};

const renderFooter = props => {
  let { value: data, fromShare } = props;
  if (fromShare || !data || !Object.keys(data).length || !uploadResourceActionVisibility({ selectedData: [data] })) {
    return null;
  }
  let { type, secureType } = data;
  let isVault = secureType === SECURE_TYPES.VAULT;
  let isArchive = secureType === SECURE_TYPES.ARCHIVE;
  let isCollection = data.collection && data.collection._id;
  const { containerStyle, actionStyle } = ((isVault || isArchive) && renderVaultFooterStyle) || renderFooterStyle;
  let { sharedCollection, resourceOwner, collectionOwner } = data;
  resourceOwner = !sharedCollection || resourceOwner; // if not shared collection, then current user will be resource owner
  return (
    <Box
      {...containerStyle}
      itemStyle={{ alignItems: "center", justifyContent: "center" }}
      render={[
        resourceOwner && !isVault && !isArchive && (
          <RowAction
            data={data}
            action={[
              ResourceActions.postToGroup,
              ResourceActions.webShareGallery,
              ResourceActions.nativeShareGallery,
              ResourceActions.getLink,
              ResourceActions.getLinkNative
            ]}
          >
            <Box {...actionStyle} image={getImage("shareWhiteIcon")} />
          </RowAction>
        ),
        (!isCollection || (resourceOwner && collectionOwner)) && !data.favourite && (
          <RowAction data={data} action={ResourceActions.markFavourite} />
        ),
        (!isCollection || (resourceOwner && collectionOwner)) && data.favourite && (
          <RowAction data={data} action={ResourceActions.unmarkFavourite} />
        ),
        resourceOwner &&
          type === "image" &&
          Platform.OS !== "web" &&
          ImageEditor && {
            ...actionStyle,
            render: <ImageEditor data={data} />
          },
        resourceOwner && !isVault && !isArchive && (
          <RowAction data={data} action={ResourceActions.markDelete} image={"deleteWhiteIcon"} />
        )
      ]}
    />
  );
};

const groupGalleryRenderFooter = props => {
  let { value: data, origin, user } = props;
  const { containerStyle, actionStyle } = groupGalleryRenderFooterStyle;
  return (
    <Box
      {...containerStyle}
      itemStyle={{ alignItems: "center", justifyContent: "center" }}
      render={
        Object.keys(data).length
          ? [
              {
                ...actionStyle,
                render: (
                  <RowAction
                    data={data}
                    action={{
                      ...ResourceActions.infoMobile,
                      origin
                    }}
                    image={"infoWhiteIcon"}
                  />
                )
              },
              isImportFromGroupDetailVisible({ data, user }) && {
                ...actionStyle,
                render: <RowAction data={data} image={"importWhiteIcon"} action={ResourceActions.importItemFromGroup} />
              },
              isRemoveFromGroupDetailVisible({ data, user }) && {
                ...actionStyle,
                render: (
                  <RowAction data={data} image={"pinRemoveWhiteIcon"} action={ResourceActions.removeItemFromGroup} />
                )
              }
            ]
          : []
      }
    />
  );
};

const HighlightGalleryRenderHeader = ({ onClose, value: data = {}, fromHighlight }) => {
  let { actionStyle, containerStyle } = renderHeaderStyle;
  let backAction = {
    ...actionStyle,
    image: getImage("backWhiteIcon"),
    onClick: onClose
  };
  let renderToShow =
    fromHighlight === "picOfDay"
      ? [
          backAction,
          {
            width: "1fr"
          },
          {
            render: (
              <RowAction
                data={data}
                action={[
                  ResourceActions.backToDay,
                  {
                    ...ResourceActions.discardPictureOfDay,
                    afterInvoke: (result, props) => {
                      data.discarded = true;
                      let { deleteUri, link, getPath } = props;
                      deleteUri && deleteUri(getPath && getPath(), link);
                    }
                  }
                ]}
              >
                <Box {...actionStyle} image={getImage("iconMenuWhite")} />
              </RowAction>
            )
          }
        ]
      : [backAction];
  return <Box {...containerStyle} render={renderToShow} />;
};

class HighlightGalleryRenderFooter extends React.Component {
  state = {};

  render() {
    let { value: data, origin, fromHighlight } = this.props;
    const { containerStyle, actionStyle } = groupGalleryRenderFooterStyle;
    return (
      <Box
        {...containerStyle}
        itemStyle={{ alignItems: "center", justifyContent: "center" }}
        render={[
          data && fromHighlight === "picOfDay" && !data.exported && (
            <RowAction
              data={data}
              action={{
                ...ResourceActions.importPhotoToGallery,
                updateStateOnInvoke: (_, props) => {
                  let { invokeResult } = props;
                  if (invokeResult && !invokeResult.error) {
                    data.exported = true;
                    data.deleted = false;
                    this.setState({});
                  }
                }
              }}
            >
              <Box {...actionStyle} image={getImage("importWhiteIcon")} />
            </RowAction>
          ),
          data && (fromHighlight !== "picOfDay" || data.exported) && (
            <RowAction
              data={data}
              action={[
                ResourceActions.postToGroup,
                ResourceActions.webShareGallery,
                ResourceActions.nativeShareGallery,
                ResourceActions.getLink,
                ResourceActions.getLinkNative
              ]}
            >
              <Box {...actionStyle} image={getImage("shareWhiteIcon")} />
            </RowAction>
          ),
          {
            ...actionStyle,
            render: (
              <RowAction
                data={data}
                action={{
                  ...ResourceActions.infoMobile,
                  origin
                }}
                image={"infoWhiteIcon"}
              />
            )
          }
        ]}
      />
    );
  }
}

class ImageDetailComponent extends React.Component {
  downloadStatus = {};

  isDownloadVisible = data => {
    if (data && data._id && this.downloadStatus[data._id]) {
      return false;
    }
    return true;
  };

  renderHeader = headerProps => {
    let { fromGroup } = this.props;
    let { value } = headerProps || {};
    if (!fromGroup && value && value._id && !this.downloadStatus[value._id]) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(_ => {
        checkFileExistsLocally &&
          checkFileExistsLocally(value, false)
            .then(resp => {
              if (resp && (resp.exists || resp.downloadExists)) {
                this.downloadStatus[value._id] = true;
              }
            })
            .catch(e => {
              /*ignore error*/
            });
      }, 1000);
    }
    const { link: { isCastPresent } = {} } = this.props;
    return fromGroup
      ? groupGalleryRenderHeader(headerProps)
      : renderHeader({ ...headerProps, isCastPresent, isDownloadVisible: this.isDownloadVisible });
  };
  render() {
    let { data, fromGroup, fromHighlight, ...restProps } = this.props;
    return (
      <ImageViewer
        {...restProps}
        images={data}
        renderHeader={
          fromHighlight
            ? props => <HighlightGalleryRenderHeader {...props} fromHighlight={fromHighlight} />
            : this.renderHeader
        }
        renderFooter={
          fromGroup
            ? groupGalleryRenderFooter
            : fromHighlight
            ? props => {
                return <HighlightGalleryRenderFooter {...props} fromHighlight={fromHighlight} />;
              }
            : renderFooter
        }
      />
    );
  }
}

export default ImageDetailComponent;
