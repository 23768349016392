import React from "react";

export default ({ renderProps }) => {
  class WithState extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      props.getPersistState().then(pstate => {
        // console.warn("@@1212 WithState read state from local storage pstate", pstate);

        pstate = pstate || {};
        this.setState({ pstate, _loaded: true });
      });
    }

    _setState = _state => {
      this.setState(state => (typeof _state === "function" ? _state(state) : _state));
    };
    render() {
      if (!this.state._loaded) {
        return null;
      }
      return renderProps(this.props, {
        perisistState: this._persistState,
        setState: this._setState,
        state: this.state
      });
    }
    componentDidUpdate() {
      const pstate = this.state.pstate;
      // console.warn("@@1212 saving state to local storage pstate", pstate);
      if (pstate) {
        this.props.persistState(pstate);
      }
    }
  }
  return WithState;
};
