import React from "react";
import moment from "moment";

export default ({ Box, theme, getImage, getSizeFormat, getTimeStringFromSeconds, I18N }) => {
  const { colors, fonts, bgs } = theme;
  const { h16_l, h9, h20, h3MD } = fonts;
  const { primaryColor, brownGrey, themeColor } = colors;
  const { lightPink } = bgs;

  const getInfoToShow = ({ data, origin }) => {
    let size = data.initialSize;
    if (size !== undefined) {
      size = getSizeFormat(size);
    }
    let lastModifiedDate = moment(data.resource_lastModified).format("YYYY-MM-DD");
    let info = {};
    if (origin === "gallery" || origin === "album" || origin === "places") {
      let dimension =
        data.resource_height && data.resource_width ? `${data.resource_height} x ${data.resource_width} pixels` : "";
      if (dimension) {
        info["resolution"] = dimension;
      }
      info["size"] = size;
      info["dateTaken"] = lastModifiedDate;
    } else if (origin === "doc" || origin === "set") {
      if (data.resource_name) {
        info["fileName"] = data.resource_name;
      }
      info["size"] = size;
      info["lastChange"] = lastModifiedDate;
    } else if (origin === "music" || origin === "playlist") {
      if (data.resource_name) {
        info["fileName"] = data.resource_name;
      }
      if (data.resource_title) {
        info["name"] = data.resource_title;
      }
      if (data.resource_artist) {
        info["artist"] = data.resource_artist;
      }
      if (data.resource_album) {
        info["album"] = data.resource_album;
      }
      if (data.resource_duration) {
        info["duration"] = getTimeStringFromSeconds(data.resource_duration / 1000);
      }
      if (data.resource_genre) {
        info["genre"] = data.resource_genre;
      }
      if (data.resource_artist) {
        info["artist"] = data.resource_artist;
      }
      info["size"] = size;
    }
    return info;
  };

  class WebResourceInfoComponent extends React.Component {
    getDetailRow = ({ image, primaryText, secondaryText1, secondaryText2 }) => {
      return {
        direction: "row",
        viewStyle: { alignItems: "center", paddingTop: 8, paddingBottom: 8 },
        render: [
          {
            viewStyle: { paddingLeft: 12, paddingRight: 12 },
            image: getImage(image)
          },
          {
            width: "1fr",
            viewStyle: { paddingLeft: 12, paddingRight: 12 },
            render: [
              {
                textStyle: { ...h16_l, color: primaryColor },
                text: primaryText
              },
              (secondaryText1 || secondaryText2) && {
                direction: "row",
                viewStyle: { paddingTop: 6, alignItems: "center" },
                textStyle: { ...h9, color: brownGrey },
                render: [
                  secondaryText1 && {
                    text: secondaryText1
                  },
                  secondaryText1 &&
                    secondaryText2 && {
                      viewStyle: {
                        marginLeft: 5,
                        marginRight: 5,
                        width: 3,
                        height: 3,
                        backgroundColor: brownGrey,
                        borderRadius: 1.5
                      }
                    },
                  secondaryText2 && {
                    text: secondaryText2
                  }
                ]
              }
            ]
          }
        ]
      };
    };

    render() {
      let { onClose, data, action } = this.props;
      let origin = action && action.origin;
      let info = getInfoToShow({ data, origin });
      return (
        <Box
          render={[
            {
              direction: "row",
              viewStyle: {
                height: 75,
                paddingLeft: 18,
                paddingRight: 18,
                alignItems: "center",
                borderBottomWidth: 2,
                borderColor: lightPink
              },
              textStyle: {
                ...h20,
                color: themeColor
              },
              render: [
                { viewStyle: { paddingLeft: 12, paddingRight: 12 }, width: "1fr", text: I18N.t("infoCaps") },
                {
                  viewStyle: {
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: 48,
                    height: 48,
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  },
                  image: getImage("infoCrossWebIcon"),
                  onClick: onClose
                }
              ]
            },
            {
              viewStyle: {
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 16,
                paddingBottom: 16
              },
              render: Object.keys(info).map(key => ({
                viewStyle: {
                  paddingTop: 12,
                  paddingBottom: 12
                },
                render: [
                  {
                    textStyle: {
                      ...h16_l,
                      color: primaryColor
                    },
                    text: I18N.t(key)
                  },
                  {
                    viewStyle: {
                      paddingTop: 6
                    },
                    textStyle: {
                      ...h3MD,
                      color: brownGrey,
                      numberOfLines: 2
                    },
                    text: info[key]
                  }
                ]
              }))
            }
          ]}
        />
      );
    }
  }
  return WebResourceInfoComponent;
};
