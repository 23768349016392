import React from "react";

export default ({ CheckBox, Box, resolveTheme, theme = {}, checkedTheme }) => {
  class SelectionCheckBox extends React.Component {
    render() {
      let { onClick, selected, selectedIcon, highlighted } = this.props;
      let checkBoxProps = { value: selected, onChangeValue: onClick };
      if (checkedTheme) {
        //if highlighted true then apply default checkbox icon - paritosh 26.02.12018
        if (!highlighted && resolveTheme && selectedIcon) {
          checkBoxProps.theme = {
            ...checkedTheme,
            ...resolveTheme(selectedIcon)
          };
        } else {
          checkBoxProps.theme = { ...checkedTheme };
        }
      }
      return <Box {...theme.boxStyle} render={<CheckBox {...checkBoxProps} />} />;
    }
  }
  return SelectionCheckBox;
};
