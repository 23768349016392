const gradients = {
  themeGradient: {
    backgroundImage: "linear-gradient(to right, #4a00e0, #7a27a8)"
  },
  errorGradient: {
    backgroundImage: "linear-gradient(to right, #ff7569, #ff4e54)"
  },
  backgroundGradient: {
    backgroundImage: "linear-gradient(to right, #f7f7f7, #f7f7f7)"
  },
  cardGradient: {
    backgroundImage: "linear-gradient(to right, #ffffff, #ffffff)"
  },
  feedbackGradient: {
    backgroundImage: "linear-gradient(to bottom, #fad961, #f76b1c)"
  },
  topToBottomGradient: {
    backgroundImage: "linear-gradient(to bottom, #ffffff, #000000)"
  },
  bottomToTopGradient: {
    backgroundImage: "linear-gradient(to top, #ffffff, #000000)"
  },
  leftToRightGradient: {
    backgroundImage: "linear-gradient(to right, #ffffff, #000000)"
  },
  rightToLeftGradient: {
    backgroundImage: "linear-gradient(to left, #ffffff, #000000)"
  },
  blackGradient: {
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.03))"
  },
  bottomTopBlackGradient: {
    backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.03))"
  },
  topBlackGradient: {
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.69), rgba(216, 216, 216, 0))"
  },
  bottomBlackGradient: {
    backgroundImage: "linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0))"
  },
  leftBlackGradient: {
    backgroundImage: "linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0))"
  },
  topToBottomTransparentToBlackGradient: {
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000)"
  },
  topToBottomBlackToTransparentGradient: {
    backgroundImage: "linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0))"
  }
};

export default gradients;
