import React from "react";
import { withContext, StatusBar, FSLoginApi, afterLogin } from "../../../FsCloudComponent";

class CustomLogin extends React.Component {
  render() {
    return <StatusBar />;
  }

  componentDidMount() {
    let firebaseCustomToken, extRefField;
    let { search } = typeof window !== undefined ? window.location : {};
    if (search) {
      let urlParams = new URLSearchParams(search);
      if (urlParams.has("code")) {
        firebaseCustomToken = urlParams.get("code");
      }
      if (urlParams.has("authfield")) {
        extRefField = urlParams.get("authfield");
      }
    }
    if (firebaseCustomToken && extRefField) {
      const { setUserInfo } = this.props;
      FSLoginApi.getUserFromCustomToken({
        custom_token: firebaseCustomToken,
        extref_field: extRefField
      })
        .then(result => {
          setUserInfo && setUserInfo(result);
          afterLogin && afterLogin(result, this.props);
        })
        .catch(err => {
          // console.log("error in custom login >> ", err.message);
        });
    }
  }
}

CustomLogin = withContext(CustomLogin, {
  setUserInfo: "User.setUserInfo",
  replaceUri: "Router.replaceUri",
  activeMQ: "ActiveMQ"
});

export default CustomLogin;
