export default ({ fonts, colors }) => {
  const { h3MD, h16_l, h3 } = fonts;
  const { charcoal, primaryColor } = colors;

  const phoneNumberBoxStyle = {
    textStyleSM: {
      ...h3MD,
      color: charcoal
    },
    textStyleMD: {
      ...h3,
      color: primaryColor
    },
    textStyleLG: {
      ...h16_l,
      color: primaryColor
    }
  };

  return {
    phoneNumberBoxStyle
  };
};
