import CollectionActions from "../screens/collection/CollectionActions";

export default {
  Collection: {
    fields: {
      name: { field: "name", type: "text" },
      cover: { field: "cover", type: "image" },
      itemCount: { field: "itemCount", type: "number" },
      type: { field: "type", type: "text" },
      mode: { field: "mode", type: "text" },
      modeText: { field: "modeText", type: "text" },
      minDate: { field: "minDate", type: "date" },
      maxDate: { field: "maxDate", type: "date" }
    },
    actions: {
      ...CollectionActions
    },
    updateProps: {
      model: "Album"
    }
  }
};
