import React from "react";
import numeral from "numeral";
export default ({ Box, theme, defaultTime, I18N }) => {
  const { colors, fonts } = theme;
  const { h9_47 } = fonts;
  const { themeColor, primaryColor } = colors;

  class ResendOtp extends React.Component {
    constructor(props) {
      super(props);
      this.defaultTime = defaultTime;
      const time = this.defaultTime;
      this.state = { time };
    }
    componentDidMount() {
      this.applyInterval();
    }

    applyInterval() {
      this.interval = setInterval(
        () =>
          this.setState(({ time }) => {
            if (time === 1) {
              clearInterval(this.interval);
            }
            return { time: time - 1 };
          }),
        1000
      );
    }

    resendOtp = () => {
      const { onClick, time } = this.props;
      onClick && onClick();
      this.setState({ time: time || this.defaultTime }, this.applyInterval);
    };

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    render() {
      const { time } = this.state;
      const { resendMessageStyle, timerStyle, resendStyle } = this.props;
      return time ? (
        <Box
          direction="row"
          render={[
            {
              text: `${I18N.t("resendInTimeText")}:`,
              textStyle: {
                ...h9_47,
                color: primaryColor
              },
              ...resendMessageStyle
            },
            {
              viewStyle: { paddingLeft: 4 },
              textStyle: {
                ...h9_47,
                color: themeColor
              },
              ...timerStyle,
              text: numeral(time).format("00:00").slice(2)
            }
          ]}
        />
      ) : (
        <Box
          text={I18N.t("resendCode")}
          textStyle={{
            ...h9_47,
            color: themeColor
          }}
          {...resendStyle}
          onClick={() => this.resendOtp()}
        />
      );
    }
  }

  return ResendOtp;
};
