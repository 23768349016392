import React from "react";

export default ({ Box, getImage, resolveMQ, theme, getInput, I18N, ...hocProps }) => {
  let { fonts, colors, bgs, shadows } = theme;
  let { borderBg5, lightPink } = bgs;
  let { h9, h16_l } = fonts;
  let { inputShadow } = shadows;
  let { greyish, themeColor, highlightColor, primaryColor } = colors;

  const componentStyle = {
    wrapperContainerStyleMD: {
      viewStyle: {
        flex: 5,
        alignItems: "center",
        overflow: void 0
      }
    },
    containerStyleSM: {
      flex: 1,
      marginRight: 4,
      marginLeft: 7,
      paddingLeft: 1,
      paddingBottom: 8,
      borderBottomWidth: 2,
      alignItems: "center"
    },
    containerStyleMD: {
      width: 385,
      height: 44,
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      alignItems: "center",
      justifyContent: "center",
      overflow: void 0
    },
    imageContainerStyleMD: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8,
        height: 22,
        width: 38,
        alignItems: "center",
        justifyContent: "center"
      },
      image: getImage("searchIcon")
    },
    inputContainerStyleSM: {
      viewStyle: {
        flex: 1
      }
    },
    inputContainerStyleMD: {
      viewStyle: {
        flex: 1,
        paddingLeft: 12
      }
    },
    inputStyleSM: {
      style: {
        ...h9,
        color: themeColor
      }
    },
    inputStyleMD: {
      style: {
        ...h16_l,
        color: primaryColor
      }
    },
    crossIconStyleSM: {
      viewStyle: {
        paddingRight: 4,
        paddingLeft: 4,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      }
    },
    crossIconStyleMD: {
      viewStyle: {
        paddingRight: 12,
        paddingLeft: 12,
        cursor: "pointer"
      }
    }
  };

  class SearchInput extends React.Component {
    constructor(props) {
      super(props);
      this.searchText = props.value || "";
      this.state = { searchText: this.searchText };
    }

    onFocus = () => {
      this.setState({ focused: true });
    };

    resetData = () => {
      const { getConnectState, setConnectState, incFetchCount } = this.props;
      let { dataParams = {} } = (getConnectState && getConnectState()) || {};
      delete dataParams["ftsString"];
      incFetchCount && incFetchCount();
      setConnectState &&
        setConnectState({
          data: [],
          dataIds: [],
          lastDoc: void 0,
          dataParams,
          hasNext: false,
          pending: void 0
        });
    };

    searchData = searchText => {
      searchText = searchText.trim();
      if (this.timeout) {
        clearTimeout(this.timeout);
        delete this.timeout;
      }
      if (this.searchText === searchText) {
        return;
      }
      this.searchText = searchText;
      const { setParams, action: { loadDataOnReset } = {} } = this.props;
      if (searchText || loadDataOnReset) {
        setParams({ type: "ftsString", value: searchText, setOnFetch: true });
      } else {
        this.resetData();
      }
    };

    onBlur = () => {
      this.setState({ focused: false });
    };

    onSubmitEditing = () => {
      let { searchText } = this.state;
      this.searchData(searchText);
    };

    onChangeValue = value => {
      this.setState({ searchText: value }, () => {
        if (!this.timeout) {
          this.timeout = setTimeout(() => {
            this.searchData(this.state.searchText);
          }, 300);
        }
      });
    };

    onCross = () => {
      let searchText = "";
      this.searchData(searchText);
      this.setState({ searchText });
    };

    getPlaceholder = from => {
      if (from === "search-gallery" || from === "search-vault-gallery" || from === "search-archive-gallery") {
        return I18N.t("searchGalleryDot");
      } else if (from === "search-docs" || from === "search-vault-docs" || from === "search-archive-docs") {
        return I18N.t("searchDocumentsDot");
      } else if (from === "search-music" || from === "search-archive-music") {
        return I18N.t("searchMusicDot");
      } else if (from === "search-group-gallery" || from === "search-group-docs") {
        return I18N.t("searchGroupDot");
      } else {
        return I18N.t("searchDot");
      }
    };

    render() {
      const { activeMQ, data, link } = this.props;
      let { from } = (link && link.props && link.props.params) || {};
      const { focused, searchText } = this.state;
      let focusedStyle = {
        containerStyleSM: {
          borderColor: focused || (data && data.length) ? themeColor : borderBg5
        },
        containerStyleMD: {
          backgroundColor: focused ? highlightColor : lightPink,
          ...(focused ? inputShadow : void 0)
        }
      };

      let { autoFocus } = resolveMQ(hocProps, ["autoFocus"], activeMQ);
      if (this.props.autoFocus !== undefined) {
        autoFocus = this.props.autoFocus;
      }
      const {
        wrapperContainerStyle,
        containerStyle,
        inputContainerStyle,
        inputStyle,
        crossIconStyle,
        imageContainerStyle
      } = resolveMQ(
        componentStyle,
        [
          "wrapperContainerStyle",
          "containerStyle",
          "inputContainerStyle",
          "inputStyle",
          "crossIconStyle",
          "imageContainerStyle"
        ],
        activeMQ
      );
      const { containerStyle: focusedContainerStyle } = resolveMQ(focusedStyle, ["containerStyle"], activeMQ);
      let Input = getInput("text");
      let renderComponent = (
        <Box
          direction={"row"}
          viewStyle={{
            ...containerStyle,
            ...focusedContainerStyle
          }}
          render={[
            imageContainerStyle && {
              ...imageContainerStyle
            },
            {
              ...inputContainerStyle,
              render: (
                <Input
                  {...inputStyle}
                  value={searchText}
                  returnKeyType="search"
                  onChangeValue={this.onChangeValue}
                  placeholder={this.getPlaceholder(from)}
                  placeholderColor={greyish}
                  autoFocus={autoFocus}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSubmitEditing={this.onSubmitEditing}
                  onDragStart={e => {
                    e && e.preventDefault && e.preventDefault();
                    e && e.stopPropagation && e.stopPropagation();
                  }}
                />
              )
            },
            searchText && searchText.length
              ? {
                  ...crossIconStyle,
                  image: getImage("searchCrossIcon"),
                  onClick: this.onCross
                }
              : void 0
          ]}
        />
      );
      if (wrapperContainerStyle) {
        renderComponent = <Box {...wrapperContainerStyle} render={renderComponent} />;
      }
      return renderComponent;
    }
  }
  return SearchInput;
};
