import React from "react";

const getFileInfo = value => {
  let imgUri = void 0;
  let imgName = void 0;
  if (typeof value == "string") {
    let url = (decodeURIComponent && decodeURIComponent(value)) || "";
    let l = url.lastIndexOf("?");
    let f = url.lastIndexOf("/");
    if (l > -1 && f > -1) {
      imgName = url.substring(f + 1, l);
    }
    imgUri = value;
  } else if (typeof value === "object") {
    let { name, url } = value;
    imgUri = url;
    imgName = name;
  }
  return {
    imgName: imgName,
    imgUri: imgUri
  };
};

export default ({ Box, isImage, theme: themeHoc, I18N }) => {
  const IssueNetworkImageRender = props => {
    let { value = {} } = props;
    let { imgName, imgUri } = getFileInfo(value);

    let { bgs, fonts } = themeHoc;
    let { lightPink } = bgs;
    let { h9 } = fonts;

    return (
      <Box
        viewStyle={{
          margin: 6,
          cursor: "pointer",
          width: 140
        }}
        render={[
          imgUri && isImage(imgName)
            ? {
                viewStyle: { borderRadius: 5, backgroundColor: lightPink },
                image: { uri: imgUri },
                imageStyle: { height: 140, width: 140 },
                imageProps: { resizeMode: "cover" }
              }
            : {
                viewStyle: {
                  padding: 6,
                  borderRadius: 5,
                  backgroundColor: lightPink,
                  height: 140,
                  alignItems: "center",
                  justifyContent: "center"
                },
                textStyle: { ...h9, color: "#5c5c5c" },
                text: I18N.t("imagePreviewError")
              },
          imgName && {
            viewStyle: { paddingTop: 4 },
            textStyle: { ...h9, color: "#5c5c5c", numberOfLines: 2 },
            text: imgName
          }
        ]}
      />
    );
  };

  return IssueNetworkImageRender;
};
