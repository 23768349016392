import React from "react";
import { SelectAlbumCard, SelectAlbumHeader } from "./GalleryCards";
import { GalleryAlbumNoData, VaultAlbumNoData } from "../noData/NoData";
import { selectAlbumQuery, selectVaultAlbumQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

export default {
  SelectAlbum: {
    component: "table",
    dataObject: "Collection",
    title: () => I18N.t("select"),
    selectable: false,
    ...selectAlbumQuery,
    renderNoData: <GalleryAlbumNoData selectAlbum />,
    renderHeader: SelectAlbumHeader,
    headerActionsSM: [
      {
        type: "headerAction",
        text: () => I18N.t("newAlbumCaps"),
        action: "createAlbum"
      }
    ],
    rowProps: {
      ...SelectAlbumCard
    }
  },
  SelectVaultAlbum: {
    component: "table",
    dataObject: "Collection",
    title: () => I18N.t("select"),
    selectable: false,
    ...selectVaultAlbumQuery,
    renderNoData: <VaultAlbumNoData selectAlbum />,
    renderHeader: SelectAlbumHeader,
    headerActionsSM: [
      {
        type: "headerAction",
        text: () => I18N.t("newAlbumCaps"),
        action: "createVaultAlbum"
      }
    ],
    rowProps: {
      ...SelectAlbumCard
    }
  }
};
