import React from "react";
import StartUpContext from "./StartUpContext";
import {
  View,
  ConfirmBox,
  Platform,
  isIntroductionRequired,
  Modal,
  withContext,
  resolveMQ,
  I18N,
  MultipleText,
  theme,
  showMessage,
  StatusBar as LoadingIndicator,
  AppConfig,
  isMigrationRequired,
  setDownloadActionRef,
  setUploadActionRef,
  setUploadFromCameraRef,
  setShareActionRef,
  pushLog,
  getSettingInfo,
  populateGalleryFolders,
  scheduleAutoBackup,
  getComponent,
  getJoinInfo,
  clearJoinInfo,
  AsyncStorage,
  startInitialAutoBackup,
  setStartUpWrapperRef,
  getUploadCount,
  forceUpdateMetadataInSqlite,
  updateUserProfile
} from "./FsCloudComponent";
import AppShare from "./AppShare";
import AppDownload from "./AppDownload";
import AppUpload from "./AppUpload";
import AppUpdate from "./AppUpdate";
import PermissionChecker from "./PermissionChecker";
import { handleSyncService } from "./SyncUtility";
import { MigrationProgressComponent, MigrationSuccessComponent } from "./MigrationComponent";
import IntroducePopup from "./metadata/screens/profile/IntroducePopup";
import AppWarning from "./AppWarning";
import UploadFromCamera from "./UploadFromCamera";
import { exitApp } from "./BackHandler";

let { fonts, colors } = theme;
let { h9_24, h9_21 } = fonts;
let { themeColor, primaryColor, greyishBrownTwo } = colors;
const modalStyle = {
  modalSM: {
    position: "center",
    width: 0.9,
    animationType: "none",
    style: {
      borderRadius: 4,
      overflow: "hidden"
    },
    parentStyle: {
      zIndex: 100
    },
    escEnabled: false
  },
  modalMD: {
    position: "center",
    width: 486,
    style: {
      borderRadius: 4
    },
    parentStyle: {
      zIndex: 100
    },
    escEnabled: false
  }
};

const acceptPolicyStyle = {
  messageTextStyleSM: {
    ...h9_24,
    color: greyishBrownTwo
  },
  messageTextStyleMD: {
    ...h9_21,
    color: primaryColor
  },
  linkTextStyleSM: {
    ...h9_24,
    color: themeColor
  },
  linkTextStyleMD: {
    ...h9_21,
    color: themeColor
  }
};

class StartUpWrapper extends React.Component {
  state = { permissionChecked: false };

  constructor(props) {
    super(props);
    this.setContextValue();
  }

  setContextValue = () => {
    this.contextValue = {
      isSyncProcessing: this.isSyncProcessing,
      isSyncCompleted: this.isSyncCompleted
    };
  };

  mergeContextValue = newValues => {
    this.contextValue = {
      ...this.contextValue,
      ...newValues
    };
  };

  onCloseJoinPopup = () => {
    clearJoinInfo && clearJoinInfo();
    this.setState({});
  };

  onClickAutoBackupInfo = () => {
    this.setState({
      showAutoBackupInfo: false
    });
  };

  onClickInitialAutoBackup = () => {
    startInitialAutoBackup && startInitialAutoBackup();
    AsyncStorage.setItem("initialAutoBackupCompleted", "true");
    this.setState({
      initialAutoBackupRequired: false,
      showAutoBackupInfo: true
    });
  };

  setInitialAutoBackupRequired = async () => {
    let initialAutoBackupCompleted = await AsyncStorage.getItem("initialAutoBackupCompleted");
    if (initialAutoBackupCompleted === "true") {
      return;
    }
    const { uploadEnabled, userSynced } = getSettingInfo();
    if (!userSynced) {
      return;
    }
    if (uploadEnabled || (await getUploadCount())) {
      await AsyncStorage.setItem("initialAutoBackupCompleted", "true");
      return;
    }
    // check upload query
    this.setState({
      initialAutoBackupRequired: true
    });
  };

  componentDidMount() {
    setStartUpWrapperRef && setStartUpWrapperRef(this);
    this.checkAndUpdateTransferFileProgress();
  }

  componentWillUnmount() {
    setStartUpWrapperRef && setStartUpWrapperRef();
  }

  showNotificationModal = data => {
    if (data && data.body) {
      this.notification = {
        title: data.title,
        body: data.body
      };
      this.setState({ initialNotificationModal: true });
    }
  };

  hideNotificationModal = () => {
    this.setState({ initialNotificationModal: false });
  };

  isPopulateSqliteAgain = async user => {
    try {
      if (!user || Platform.OS !== "ios") {
        return;
      }
      const populateSqliteMetadataAgain = await AsyncStorage.getItem("populateSqliteMetadataAgain");
      if (!populateSqliteMetadataAgain) {
        pushLog && pushLog(`[StartupWrapper] [isPopulateSqliteAgain] - update SQLite iOS only. user : ${user}`, true);
        // set in forceUpdateMetadataInSqlite
        // await AsyncStorage.setItem("populateSqliteMetadataAgain", JSON.stringify({ user, _updatedOn: new Date() }));
        forceUpdateMetadataInSqlite && forceUpdateMetadataInSqlite(user); // refill SQLite data
      }
    } catch (err) {
      // do nothing
    }
  };

  componentDidUpdate(prevProps) {
    let prevUserId = prevProps.user && prevProps.user._id;
    let currentUserId = this.props.user && this.props.user._id;
    if (prevUserId !== currentUserId) {
      pushLog &&
        pushLog(
          `StartupWrapper componentDidUpdate called>>> prevUserId : ${prevUserId} , currentUserId : ${currentUserId}`,
          true
        );
      this.storagePermissionComplete = void 0;
      this.syncServiceStatus = void 0;
      if (currentUserId) {
        this.checkAndUpdateTransferFileProgress();
      }
    }
  }

  setPermissionChecked = () => {
    this.setState({ permissionChecked: true });
  };

  onStoragePermissionComplete = () => {
    this.storagePermissionComplete = true;
    let { user } = this.props;
    if (!isMigrationRequired(user)) {
      this.startSyncService();
    }
  };

  updateUserInfo = async data => {
    try {
      this.setState({ loading: true });
      let { updateUserInfo } = this.props;
      let userResult = await updateUserProfile(data);
      updateUserInfo && updateUserInfo(userResult);
      this.setState({ loading: false });
    } catch (e) {
      showMessage && showMessage(e.message, 2000);
      this.setState({
        loading: false
      });
    }
  };

  onAcceptMigrationNotification = async () => {
    try {
      this.setState({ loading: true });
      let { invoke, urls } = this.props;
      await invoke({
        service: {
          url: urls["migrationNotificationAcceptedUrl"]()
        },
        allowParallelInvoke: true
      });
      this.setState({ loading: false });
    } catch (e) {
      showMessage && showMessage(e.message, 2000);
      this.setState({
        loading: false
      });
    }
  };

  getProgress = async request_id => {
    let { invoke, urls } = this.props;
    let { result } = await invoke({
      service: {
        url: urls["migrationUrl"](),
        uriProps: {
          data: {
            request_id: request_id
          }
        },
        allowParallelInvoke: true
      }
    });
    return result;
  };

  isSyncProcessing = () => {
    return this.syncServiceStatus === "processing";
  };

  isSyncCompleted = () => {
    return this.syncServiceStatus === "completed";
  };

  startSyncService = async () => {
    console.log("Start sync");
    let { user } = this.props;
    if (Platform.OS === "web" || !user || !this.storagePermissionComplete || this.syncServiceStatus) {
      return;
    }
    pushLog && pushLog("startSyncService called");
    this.syncServiceStatus = "processing";
    this.isPopulateSqliteAgain(user._id);
    try {
      const { userSynced } = getSettingInfo();
      console.log("sync: ", userSynced);
      pushLog && pushLog(`User Synced >> ${userSynced}`, true);
      if (!userSynced) {
        handleSyncService && (await handleSyncService());
      }
    } catch (err) {
    console.log("sync error: ", err);
    }
    this.syncServiceStatus = "completed";
    pushLog && pushLog("startSyncService completed", true);
    this.setInitialAutoBackupRequired();
    populateGalleryFolders && populateGalleryFolders();
    scheduleAutoBackup && scheduleAutoBackup();
  };

  checkAndUpdateTransferFileProgress = async () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = void 0;
    }
    let { user } = this.props;
    if (!user) {
      return;
    }
    if (!isMigrationRequired(user)) {
      this.startSyncService();
      return;
    }
    try {
      let progressResult = await this.getProgress(user.request_id);
      if (typeof progressResult === "string") {
        progressResult = JSON.parse(progressResult);
      }
      if (progressResult && progressResult.migrated) {
        this.startSyncService();
        return;
      }
      let { files_copied = 0, files_total } = progressResult || {};
      let progress = files_total ? parseInt((files_copied / files_total) * 100) : 0;
      if (progress < 0) {
        progress = 0;
      } else if (progress >= 100) {
        progress = 99;
      }
      this.mergeContextValue({ migrationDetails: { progress, files_copied, files_total } });
      this.setState({});
    } catch (err) {
      // do nothing in case of error - check in recursion again
    }
    this.timeout = setTimeout(async () => {
      this.checkAndUpdateTransferFileProgress();
    }, 30000);
  };

  getAcceptPolicyText = () => {
    let { activeMQ } = this.props;
    let { messageTextStyle, linkTextStyle } = resolveMQ(
      acceptPolicyStyle,
      ["messageTextStyle", "linkTextStyle"],
      activeMQ
    );
    let serviceTermsAcceptMessage = I18N.t("serviceTermsAcceptMessage");
    let splitText = serviceTermsAcceptMessage.split("__");
    let textArray = [];
    splitText.forEach((text, index) => {
      if (index % 2 === 0) {
        textArray.push({
          textStyle: { ...messageTextStyle },
          text
        });
      } else {
        let link = void 0;
        if (text === "termsAndConditions") {
          link = {
            uri: AppConfig.APP_TERMS_CONDITION_URL
          };
        } else if (text === "privacyPolicy") {
          link = {
            uri: AppConfig.APP_TERMS_CONDITION_URL
          };
        }
        if (link) {
          textArray.push({
            textStyle: { ...linkTextStyle },
            text: I18N.t(text),
            link,
            external: true
          });
        }
      }
    });
    return textArray;
  };

  exitApp = () => {
    exitApp && exitApp();
  };

  isReportIssueView = () => {
    let { getPath } = this.props;
    let path = getPath && getPath();
    if (path && path.indexOf("/user-issues") >= 0) {
      return true;
    }
  };

  render() {
    let { children, componentsCount, user, activeMQ } = this.props;
    let { isMobileView } = resolveMQ(
      {
        isMobileViewSM: true,
        isMobileViewMD: false
      },
      ["isMobileView"],
      activeMQ
    );
    let joinInfo = getJoinInfo && getJoinInfo();
    let { modal } = resolveMQ(modalStyle, ["modal"], activeMQ);

    let startUpPopupComponent = void 0;
    let modalKey = void 0;
    let modalProps = void 0;
    if (!user) {
      this.setContextValue();
    } else if (Platform.OS === "web" || this.state.permissionChecked) {
      if (!this.contextValue.startUpProcessCompleted) {
        if (isIntroductionRequired({ user })) {
          startUpPopupComponent = isMobileView ? void 0 : <IntroducePopup />;
        } else if (!user.servicePolicyAccepted) {
          modalKey = "AcceptPolicy";
          startUpPopupComponent = (
            <ConfirmBox
              {...{
                titleSM: I18N.t("serviceTermsTitle"),
                titleMD: I18N.t("serviceTermsTitleCaps"),
                message: <MultipleText value={this.getAcceptPolicyText()} />,
                confirmText: I18N.t("accept"),
                onConfirm: () => {
                  this.updateUserInfo({ servicePolicyAccepted: true });
                }
              }}
            />
          );
        } else if (isMigrationRequired(user)) {
          if (user.transferAccepted) {
            modalKey = "MigrationProgress";
            startUpPopupComponent = <MigrationProgressComponent onClose={this.exitApp} />;
            modalProps = { onClose: this.exitApp };
          } else {
            modalKey = "WelComeMirgation";
            startUpPopupComponent = (
              <ConfirmBox
                {...{
                  title: I18N.t("welcomeTransferMessageCaps"),
                  message: I18N.t("transferInfoMessage"),
                  confirmText: I18N.t("nextCaps"),
                  onConfirm: () => {
                    this.updateUserInfo({ transferAccepted: true });
                  }
                }}
              />
            );
          }
        } else if (user.request_id && user.migrated && !user.migrationNotificationAccepted) {
          modalKey = "MigrationNotification";
          startUpPopupComponent = <MigrationSuccessComponent onAccept={this.onAcceptMigrationNotification} />;
        } else {
          this.mergeContextValue({ startUpProcessCompleted: true });
        }
      }
      if (this.contextValue.startUpProcessCompleted) {
        if (joinInfo && joinInfo.sharedToken) {
          let JoinPopup = getComponent(joinInfo.origin === "joinGroup" ? "joinGroupPopup" : "joinCollectionPopup");
          startUpPopupComponent = <JoinPopup onClose={this.onCloseJoinPopup} {...joinInfo} />;
        } else if (this.state.initialAutoBackupRequired) {
          modalKey = "InitialAutoBackup";
          startUpPopupComponent = (
            <ConfirmBox
              {...{
                title: I18N.t("autoBackup"),
                message: I18N.t("initialAutoBackupStartMessage"),
                confirmText: I18N.t("okCaps"),
                onConfirm: this.onClickInitialAutoBackup
              }}
            />
          );
        } else if (this.state.showAutoBackupInfo) {
          modalKey = "AutoBackupInfo";
          startUpPopupComponent = (
            <ConfirmBox
              {...{
                title: I18N.t("autoBackup"),
                message: I18N.t("autoBackupInfoMessage"),
                confirmText: I18N.t("okCaps"),
                onConfirm: this.onClickAutoBackupInfo
              }}
            />
          );
        } else if (this.state.initialNotificationModal && this.notification && this.notification.body) {
          modalKey = "NotificationHandlerModal";
          startUpPopupComponent = (
            <ConfirmBox
              {...{
                title: this.notification.title,
                message: this.notification.body,
                confirmText: I18N.t("okCaps"),
                onConfirm: this.hideNotificationModal
              }}
            />
          );
        }
      }
    }
    if (startUpPopupComponent && this.isReportIssueView()) {
      startUpPopupComponent = void 0;
    }

    return (
      <StartUpContext.Provider value={this.contextValue}>
        <View style={{ flex: 1 }}>
          {user && Platform.OS !== "web" && (
            <PermissionChecker
              user={user}
              onPermissionCheckComplete={this.setPermissionChecked}
              onStoragePermissionComplete={this.onStoragePermissionComplete}
            />
          )}
          {Platform.OS !== "web" && <AppUpdate />}
          {Platform.OS !== "web" && <AppShare ref={setShareActionRef} />}
          {Platform.OS !== "web" && <AppUpload ref={setUploadActionRef} />}
          {Platform.OS !== "web" && <UploadFromCamera ref={setUploadFromCameraRef} />}
          <AppDownload
            ref={setDownloadActionRef}
            componentsCount={componentsCount}
            activeMQ={activeMQ}
            isSyncProcessing={this.isSyncProcessing}
          />
          {startUpPopupComponent &&
            (modalKey ? (
              <Modal key={modalKey} {...modal} {...modalProps} isOpen={true}>
                {this.state.loading && <LoadingIndicator />}
                {startUpPopupComponent}
              </Modal>
            ) : (
              startUpPopupComponent
            ))}
          {children}
        </View>
        {user && <AppWarning componentsCount={componentsCount} />}
      </StartUpContext.Provider>
    );
  }
}

StartUpWrapper = withContext(StartUpWrapper, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user",
  updateUserInfo: "User.updateUserInfo",
  getPath: "Router.getPath"
});

export default StartUpWrapper;
