export default ({ showMessage, showError, getNewId, resolveParams, skipValidationToast, I18N } = {}) => {
  const resolveLink = ({ data, link, routeLink, globalDataParams = {}, user, ...restProps }) => {
    if (typeof link === "function") {
      link = link({ data, link: routeLink, globalDataParams, user, ...restProps });
    }
    if (!link) {
      return void 0;
    }
    const { uri, props, ...restLinkProps } = link;
    let newProps = void 0;
    if (typeof props === "function") {
      newProps = props({ data, link: routeLink, globalDataParams, user });
    } else {
      newProps = { ...props };
      if (props && props.params) {
        const params = resolveParams({ data, params: props.params });
        newProps["params"] = params;
      }
      if (props && props.relationValue) {
        const relationValue = resolveParams({
          data,
          params: props.relationValue
        });
        newProps["relationValue"] = relationValue;
      }

      if (props && props.filter) {
        const filter = resolveParams({ data, params: props.filter });
        newProps["filter"] = filter;
      }
      if (props && props.title) {
        let title = resolveParams({
          data,
          params: props.title
        });
        newProps["title"] = title;
      }
    }
    return {
      uri,
      props: newProps,
      ...restLinkProps
    };
  };

  const checkValidations = validateData => {
    if (!validateData) {
      return Promise.resolve();
    }
    return validateData().then(validations => {
      let validationMessage = "";
      for (var field in validations) {
        validationMessage += `${validationMessage ? ";" : ""}${validations[field]}`;
      }
      if (validationMessage) {
        if (skipValidationToast) {
          throw new Error("");
        } else throw new Error(validationMessage);
      }
    });
  };

  const remove = ({ props }) => {
    const { setValue, data } = props;
    setValue({ data, updates: { remove: true } });
  };

  const insert = ({ props }) => {
    const { setValue } = props;
    let _id = getNewId();
    let data = { _id };
    setValue({
      data,
      updates: {
        insert: true,
        set: { _id }
      }
    });
  };

  const save = ({ props }) => {
    try {
      const { dataMode, addNew, post, getUpdates, pendingCount, deleteUri, url, link, validateData } = props;
      let _pending = pendingCount && pendingCount();
      if (_pending) {
        showMessage("Computation in progress", 1000);
        return;
      }
      return checkValidations(validateData)
        .then(_ => {
          const updates = getUpdates();
          if (!updates) {
            showMessage("No changes", 1000);
            return;
          }
          showMessage("Saving...", 1000);
          return post({ props: updates, source: "save" }).then(() => {
            showMessage("Data Saved", 1000);
            if (deleteUri) {
              deleteUri(url, link);
            } else if (dataMode === "insert" && addNew) {
              addNew();
            }
          });
        })
        .catch(err => {
          showError(err, 3000);
        });
    } catch (err) {
      showError(err, 3000);
    }
  };

  const invoke = ({ source, selectionRequired, props, throwError, pendingCount }) => {
    //in function args - pendingCount will be set by ConnectedForm
    let {
      showNetworkError,
      unSelectAll,
      invoke,
      getUri,
      getSelectedIds,
      getSelectedData,
      allPageSelected,
      data,
      formData,
      reload: reloadData,
      pendingCount: propsPendingCount,
      validateData,
      action: {
        service,
        preMessage,
        postMessage,
        reload,
        beforeInvoke,
        afterInvoke,
        updateStateOnInvoke,
        skipClearSelection,
        skipPending,
        notifyLocalOnInvoke
      },
      ...restProps
    } = props;
    try {
      pendingCount = pendingCount || propsPendingCount;
      let _pending = pendingCount && pendingCount();
      if (_pending) {
        showMessage("Computation in progress", 1000);
        return;
      }
      const uri = getUri ? getUri() : void 0;
      let selectedIds = [];
      let selectedData = [];
      if (source === "Header") {
        selectedIds = (getSelectedIds && getSelectedIds()) || [];
        selectedData = (getSelectedData && getSelectedData()) || [];
        if (selectionRequired) {
          if (!selectedIds.length) {
            showMessage("Please select row");
            return;
          }
        } else if (!selectedIds.length && data) {
          selectedIds = [data._id];
          selectedData = [data];
        }
      } else if (data) {
        selectedIds = [data._id];
        selectedData = [data];
      } else {
        throw new Error("Data not found in invoke");
      }
      if (formData) {
        data = formData;
      }
      preMessage && showMessage(preMessage, 1000);
      selectedIds = [...selectedIds];
      return checkValidations(validateData)
        .then(_ => {
          if (beforeInvoke) {
            return beforeInvoke(props);
          }
        })
        .then(_ => {
          let invokeResult = void 0;
          return invoke(
            {
              uri,
              service,
              data,
              selectedData,
              selectedIds,
              showMessage,
              allPageSelected,
              ...restProps
            },
            { updateStateOnInvoke, skipPending, notifyLocalOnInvoke }
          )
            .then(result => {
              invokeResult = result;
              if (afterInvoke) {
                return afterInvoke(result, {
                  data,
                  ...restProps,
                  showMessage,
                  openLink: linkProps => {
                    openLink({
                      props: {
                        ...restProps,
                        data,
                        action: linkProps
                      }
                    });
                  }
                });
              }
            })
            .then(_ => {
              if (typeof postMessage === "function") {
                postMessage = postMessage({ invokeResult, selectedIds, ...restProps });
              }
              !skipClearSelection && source === "Header" && unSelectAll && unSelectAll();
              reload && reloadData && reloadData();
              postMessage && showMessage && showMessage(postMessage, 5000);
              return invokeResult;
            });
        })
        .catch(err => {
          let errorCode = err && err.code;
          if (errorCode === "no_internet" && showNetworkError) {
            showNetworkError(err, 10000);
          } else if (errorCode === "invoke_already_in_progress") {
            //ignore error
          } else {
            let errorMessage = I18N && errorCode && typeof errorCode === "string" && I18N.t(errorCode);
            if (
              !errorMessage ||
              (errorMessage.indexOf("missing") !== -1 && errorMessage.indexOf("translation") !== -1)
            ) {
              errorMessage = err.message;
            }
            showMessage(errorMessage, 3000);
            if (throwError) {
              throw err;
            }
          }
        });
    } catch (err) {
      showError(err, 3000);
      if (throwError) {
        throw err;
      }
    }
  };

  const openLink = ({ props }) => {
    let {
      link: routeLink,
      url,
      addUri,
      replaceUri,
      getPath,
      data,
      globalDataParams,
      user,
      action: { link, replace },
      ...restProps
    } = props;
    const newLink = resolveLink({ data, link, routeLink, globalDataParams, user, ...restProps });
    if (newLink) {
      if (replace) {
        replaceUri && replaceUri(newLink);
      } else {
        if (!url && getPath) {
          // url not found for drawer menu and user not want to replace route - case for agrowwave - sachin
          url = getPath();
        }
        addUri && addUri(url, newLink);
      }
    }
  };

  const close = ({ props }) => {
    let { url, link, deleteUri, action } = props;
    if (action && action.link) {
      if (typeof action.link === "function") {
        link = action.link({ url, link });
      } else {
        link = action.link;
      }
    }
    action && action.onClose && action.onClose();
    deleteUri && deleteUri(url, link);
  };

  const logout = ({ props }) => {
    let {
      removeUserInfo,
      replaceUri,
      action: { loginUrl }
    } = props;
    removeUserInfo && removeUserInfo();
    replaceUri && replaceUri(loginUrl);
  };

  const enableSelectionMode = ({ props }) => {
    /**
     * EnableSelectionMode is used to make view selectable.required for FS app - sachin
     */
    props.setSelectionMode && props.setSelectionMode();
  };

  const selectAll = ({ props }) => {
    let { action } = props;
    props.selectAll && props.selectAll(action);
  };

  const unSelectAll = ({ props }) => {
    props.unSelectAll && props.unSelectAll();
  };

  const unSupported = ({ props }) => {
    let { action: { message } = {} } = props;
    message = "Action will be supported in upcoming cycle.";
    showMessage && showMessage(message, 2000);
  };

  return {
    invoke,
    save,
    insert,
    openLink,
    close,
    remove,
    logout,
    enableSelectionMode,
    selectAll,
    unSelectAll,
    unSupported
  };
};
