import ProfileTab from "./ProfileTab";
import ProfileSM from "./ProfileSM";
import ProfileMD from "./ProfileMD";
import MyDevices from "./MyDevices";
import MyDevicesSM from "./MyDevicesSM";
import MySignature from "./MySignature";
import MyStorage from "./Storage";
import Intorduce from "./Introduce";
import UploadProfilePicture from "./UploadProfilePicture";
import BlockDevice from "./BlockDevice";

export default {
  ...ProfileTab,
  ...ProfileSM,
  ...MyDevices,
  ...MyDevicesSM,
  ...ProfileMD,
  ...MySignature,
  ...MyStorage,
  ...Intorduce,
  ...UploadProfilePicture,
  ...BlockDevice
};
