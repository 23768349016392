import React from "react";

export default ({ Box, resolveMQ, getImage, I18N, theme, Header }) => {
  const { fonts, colors } = theme;
  const { h9_21 } = fonts;
  const { brownGrey } = colors;

  const componentTheme = {
    containerStyleSM: {
      viewStyle: {
        height: 305
      },
      textStyle: {
        ...h9_21,
        color: brownGrey
      }
    },
    containerStyleMD: {
      viewStyle: {
        width: 483,
        height: 305
      },
      textStyle: {
        ...h9_21,
        color: brownGrey
      }
    },
    iconStyle: {
      viewStyle: { paddingTop: 23, height: 149, alignSelf: "center" }
    },
    primaryTextStyle: {
      viewStyle: { paddingBottom: 8, alignSelf: "center" }
    },
    secondaryTextStyle: {
      viewStyle: { paddingBottom: 30, alignSelf: "center" }
    }
  };

  const metadata = {
    album: {
      headerTitle: () => I18N.t("createNewAlbumHeader"),
      icon: "noGalleryItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    set: {
      headerTitle: () => I18N.t("createNewSetHeader"),
      icon: "noSetItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    playlist: {
      headerTitle: () => I18N.t("createNewPlaylistHeader"),
      icon: "noPlaylistItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    albumDetail: {
      headerTitle: () => I18N.t("addToAlbumCaps"),
      icon: "noGalleryItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    setDetail: {
      headerTitle: () => I18N.t("addToSetCaps"),
      icon: "noSetItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    playlistDetail: {
      headerTitle: () => I18N.t("addToPlaylistCaps"),
      icon: "noPlaylistItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    groupImage: {
      headerTitle: () => I18N.t("postToGroupCaps"),
      icon: "noDataIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    groupDoc: {
      headerTitle: () => I18N.t("postToGroupCaps"),
      icon: "noDataIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    vaultGallery: {
      headerTitle: () => I18N.t("moveToSafeAreaCaps"),
      icon: "noDataIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    vaultDocs: {
      headerTitle: () => I18N.t("moveToSafeAreaCaps"),
      icon: "noDataIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    archiveGallery: {
      headerTitle: () => I18N.t("moveToArchiveCaps"),
      icon: "noDataIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    archiveDocs: {
      headerTitle: () => I18N.t("moveToArchiveCaps"),
      icon: "noDataIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    archiveMusic: {
      headerTitle: () => I18N.t("moveToArchiveCaps"),
      icon: "noDataIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    vaultAlbum: {
      headerTitle: () => I18N.t("createNewAlbumHeader"),
      icon: "noGalleryItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    },
    vaultSet: {
      headerTitle: () => I18N.t("createNewSetHeader"),
      icon: "noSetItemsIcon",
      primaryText: () => I18N.t("noItemAvailable"),
      secondaryText: () => I18N.t("addContentMessage")
    }
  };

  class CreateCollectionNoData extends React.Component {
    render() {
      let { activeMQ, origin, onClose } = this.props;
      const { containerStyle, iconStyle, primaryTextStyle, secondaryTextStyle } = resolveMQ(
        componentTheme,
        ["containerStyle", "iconStyle", "primaryTextStyle", "secondaryTextStyle"],
        activeMQ
      );
      const { headerTitle, icon, primaryText, secondaryText } = resolveMQ(
        metadata[origin],
        ["headerTitle", "icon", "primaryText", "secondaryText"],
        activeMQ
      );
      return (
        <Box
          {...containerStyle}
          render={[
            <Header title={headerTitle} onClose={onClose} />,
            {
              ...iconStyle,
              image: getImage(icon)
            },
            {
              ...primaryTextStyle,
              text: primaryText
            },
            {
              ...secondaryTextStyle,
              text: secondaryText
            }
          ]}
        />
      );
    }
  }
  return CreateCollectionNoData;
};
