import React from "react";
import UserContext from "./UserContext";
import { setGlobal } from "../../../FsCloudAppServices";
import { Platform } from "../../../FsCloudComponent";
export default ({
  hideSplashScreen,
  renderChildren,
  loadUser,
  removeUser,
  setUserContextInfo,
  getUserData,
  onUserUpdate
}) => {
  class UserStore extends React.Component {
    constructor(props) {
      super(props);
      setUserContextInfo &&
        setUserContextInfo({
          setUserInfo: this.setUserInfo,
          removeUserInfo: this.removeUserInfo,
          updateUserInfo: this.updateUserInfo,
          _getState: this._getState
        });
    }

    _getState = () => {
      return this.props.state;
    };

    _setState = state => {
      this.props.setState(state);
    };

    setUser = (userInfo, error) => {
      const stateToSet = { error, userLoaded: true, ...userInfo };
      if (userInfo && userInfo.user && userInfo.user._id && !this.userQuerySnapshot) {
        const queryRef = getUserData && getUserData(userInfo.user._id);
        if (queryRef) {
          this.userQuerySnapshot = queryRef.onSnapshot(
            snapshot => {
              if (snapshot.exists) {
                let data = snapshot.data();
                let { user } = this._getState();
                onUserUpdate && onUserUpdate(data, user);
                this.updateUserInfo(data);
              }
            },
            () => {
              //ignore error
            }
          );
        }
      }
      this._setState(stateToSet);
    };

    setUserInfo = userInfo => {
      this.setUser(userInfo);
    };

    updateUserInfo = userInfo => {
      let { user } = this._getState();
      this._setState({
        user: {
          ...user,
          ...userInfo
        }
      });
    };

    removeUserInfo = () => {
      this.userQuerySnapshot && this.userQuerySnapshot();
      this.userQuerySnapshot = void 0;
      this.props.clearState && this.props.clearState();
      removeUser && removeUser();
    };

    loadUser = () => {
      console.log("loadUser");
      if (loadUser) {
        loadUser()
          .then(userInfo => {
            this.setUser(userInfo);
          })
          .catch(error => {
            if (error && error.code === "user/not-active") {
              this.removeUserInfo();
              this.setUser();
            } else {
              this.setUser(void 0, error);
            }
          })
          .then(_ => {
            hideSplashScreen && hideSplashScreen();
          });
      }
    };

    componentDidMount() {
      if (Platform.OS === "web") {
        const firebaseInstance = localStorage.getItem("firebaseInstance");
        if (firebaseInstance !== null && firebaseInstance !== undefined) {
          setGlobal(firebaseInstance);
        }
      }
      this.loadUser();
    }

    render() {
      let { children } = this.props;
      let state = this._getState();
      const { userLoaded, ...restState } = state;
      state.setUserInfo = this.setUserInfo;
      state.removeUserInfo = this.removeUserInfo;
      state.updateUserInfo = this.updateUserInfo;
      return (
        <UserContext.Provider value={state}>
          {renderChildren(children, {
            loading: userLoaded ? false : true,
            loadUser: this.loadUser,
            ...restState
          })}
        </UserContext.Provider>
      );
    }
  }
  return UserStore;
};
