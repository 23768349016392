import NavThemeHoc from "./navTheme";
import TabThemeHoc from "./tabTheme";
import TableThemeHoc from "./tableTheme";
import PanelThemeHoc from "./panelTheme";
import DrawerThemeHoc from "./drawerTheme";
import DrawerMenuThemeHoc from "./drawerMenuStyle";
import DefaultInputPropsHoc from "./defaultInputProps";
import InputThemeHoc from "./inputTheme";
import typeWiseThemeHoc from "./typeWiseTheme";
import spinnerThemeHoc from "./spinnerTheme";
import switchThemeHoc from "./switchTheme";
import ftsThemeHoc from "./ftsTheme";
import actionThemeHoc from "./actionTheme";
import formThemeHoc from "./formTheme";
import toastThemeHoc from "./toastTheme";
import fileBoxThemeHoc from "./fileBoxTheme";
import phoneNumberBoxThemeHoc from "./phoneNumberBoxTheme";
import fileAndImageRenderThemeHoc from "./fileAndImageRenderTheme";
import dateBoxThemeHoc from "./dateBoxTheme";
import checkBoxThemeHoc from "./checkBoxTheme";
import documentViewerThemeHoc from "./documentViewerTheme";
import documentSliderThemeHoc from "./documentSliderTheme";
import textRenderThemeHoc from "./textRenderTheme";
import avatarCardThemeHoc from "./avatarCardTheme";
import { scale } from "style-utility";
import textBoxMultipleThemeHoc from "./textBoxMultipleTheme";
import linkBoxThemeHoc from "./linkBoxTheme";

export default ({
  images,
  colors,
  bgs,
  gradients,
  fonts,
  shadows,
  Platform,
  I18N,
  navStyle,
  navLeftActionSeparatorStyle,
  navTitleTextStyle,
  navAvatarBackgroundColor,
  navAvatarTextColor,
  tableHeaderTextColor
}) => {
  const hocProps = { images, colors, bgs, gradients, fonts, shadows, Platform, I18N };

  const NavTheme = NavThemeHoc({
    ...hocProps,
    navStyle,
    navLeftActionSeparatorStyle,
    navTitleTextStyle,
    navAvatarBackgroundColor,
    navAvatarTextColor
  });
  const DrawerTheme = DrawerThemeHoc(hocProps);
  const DrawerMenuTheme = DrawerMenuThemeHoc(hocProps);
  const TabTheme = TabThemeHoc(hocProps);
  const TableTheme = TableThemeHoc({
    ...hocProps,
    tableHeaderTextColor
  });
  const PanelTheme = PanelThemeHoc(hocProps);

  const DefaultInputProps = DefaultInputPropsHoc(hocProps);
  const InputTheme = InputThemeHoc(hocProps);
  const typeWiseTheme = typeWiseThemeHoc(hocProps);
  const spinnerTheme = spinnerThemeHoc(hocProps);
  const switchTheme = switchThemeHoc(hocProps);
  const ftsTheme = ftsThemeHoc(hocProps);
  const actionTheme = actionThemeHoc(hocProps);
  const formTheme = formThemeHoc(hocProps);
  const toastTheme = toastThemeHoc(hocProps);
  const fileBoxTheme = fileBoxThemeHoc(hocProps);
  const phoneNumberBoxTheme = phoneNumberBoxThemeHoc(hocProps);
  const fileAndImageRenderTheme = fileAndImageRenderThemeHoc(hocProps);
  const dateBoxTheme = dateBoxThemeHoc(hocProps);
  const checkBoxTheme = checkBoxThemeHoc(hocProps);
  const documentViewerTheme = documentViewerThemeHoc(hocProps);
  const documentSliderTheme = documentSliderThemeHoc(hocProps);
  const textRenderTheme = textRenderThemeHoc(hocProps);
  const avatarCardTheme = avatarCardThemeHoc(hocProps);
  const textBoxMultipleTheme = textBoxMultipleThemeHoc(hocProps);
  const linkBoxTheme = linkBoxThemeHoc(hocProps);

  const { themeGradient, errorGradient, cardGradient } = gradients;
  const { h2, h3, h4, h5, h1, h9, h10, h16_l, h3MD, h4MD } = fonts;

  const { primaryColor, secondaryColor, highlightColor, actionColor, themeColor, brownGrey } = colors;
  const { themeBg } = bgs;

  const breakPoints = [{ minWidth: 200, mq: "SM" }, { minWidth: 550, mq: "MD" }, { minWidth: 1000, mq: "LG" }];

  const visibleView = {
    visibleViewSM: 1,
    visibleViewMD: 1,
    visibleViewLG: 2
  };
  const groupText = {
    ...h2,
    color: primaryColor
  };

  const primaryText = {
    ...h3,
    color: primaryColor
  };
  const primaryTextMD = {
    ...h9,
    numberOfLines: 1,
    color: primaryColor
  };
  const columnTextMD = {
    ...h1,
    numberOfLines: 1,
    color: primaryColor
  };
  const primaryHighlightText = {
    ...h3,
    color: highlightColor
  };

  const secondaryText = {
    ...h5,
    numberOfLines: 1,
    color: secondaryColor
  };
  const secondaryTextMD = {
    ...h10,
    numberOfLines: 1,
    color: secondaryColor
  };
  const secondaryHighlightText = {
    ...h5,
    numberOfLines: 1,
    color: highlightColor
  };

  const bottomText = {
    ...h4,
    numberOfLines: 1,
    color: secondaryColor
  };
  const sideText = {
    ...h4,
    numberOfLines: 1,
    color: themeColor
  };
  const bottomHighlightText = {
    ...h4,
    numberOfLines: 1,
    color: highlightColor
  };

  const actionText = {
    ...h2,
    color: actionColor
  };

  const themeGradientCard = {
    ...themeGradient
  };

  const errorGradientCard = {
    ...errorGradient
  };

  const gradientCard = {
    ...cardGradient
  };

  const circularAvatar = {
    boxStyle: {
      viewStyle: {
        backgroundColor: secondaryColor,
        alignItems: "center",
        justifyContent: "center",
        width: 50,
        height: 50,
        borderRadius: 25
      },
      textStyle: {
        ...h16_l,
        padding: 1,
        color: highlightColor
      },
      imageStyle: {
        width: 50,
        height: 50,
        borderRadius: 25,
        ...(Platform.OS === "web" ? { objectFit: "cover" } : {})
      },
      imageProps: Platform.OS === "web" ? {} : { resizeMode: "cover" }
    }
  };

  const circularAvatarMD = {
    boxStyle: {
      viewStyle: {
        backgroundColor: secondaryColor,
        alignItems: "center",
        justifyContent: "center",
        width: (scale(15) + scale(1)) * 2,
        height: (scale(15) + scale(1)) * 2,
        borderRadius: scale(15) + scale(1)
      },
      textStyle: {
        ...h2,
        padding: 1,
        color: highlightColor
      },
      imageStyle: {
        width: (scale(15) + scale(1)) * 2,
        height: (scale(15) + scale(1)) * 2,
        borderRadius: scale(15) + scale(1)
      },
      imageProps: Platform.OS === "web" ? {} : { resizeMode: "cover" }
    }
  };

  const renderSuggestionLabelStyle = {
    boxStyle: {
      direction: "row",
      viewStyle: {
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderColor: brownGrey,
        borderRadius: 12,
        marginRight: 8,
        marginTop: 4,
        marginBottom: 4
      }
    },
    labelStyle: {
      viewStyle: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 12,
        paddingRight: 4,
        flex: 1
      },
      textStyle: {
        ...h9,
        color: primaryColor,
        numberOfLines: 1
      }
    },
    crossStyle: {
      viewStyle: {
        marginLeft: 4,
        marginRight: 12,
        marginTop: 3,
        marginBottom: 3,
        padding: 2,
        cursor: "pointer"
      },
      image: images["crossSmallIcon"]
    }
  };

  const cardStyle = {
    viewStyle: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16
    },
    titleStyle: {
      ...h2,
      color: primaryColor
    },
    rightStyle: {
      ...h4,
      color: secondaryColor
    },
    contentStyle: {
      ...h4,
      color: secondaryColor
    },
    contentViewStyle: {
      paddingTop: 4
    }
  };

  const avatarStyle = {
    boxStyle: {
      viewStyle: {
        backgroundColor: themeBg,
        alignItems: "center",
        justifyContent: "center",
        width: 28,
        height: 28,
        borderRadius: 14
      },
      textStyle: {
        ...h2,
        color: highlightColor
      },
      imageStyle: {
        width: 28,
        height: 28,
        borderRadius: 14
      },
      imageProps: {
        resizeMode: "cover"
      }
    }
  };

  const iconMenuStyle = {
    imageStyle: {
      width: 7,
      height: 15
    }
  };

  const scheduleCardStyle = {
    viewStyle: {
      borderLeftWidth: 1,
      borderLeftColor: actionColor,
      padding: 10,
      backgroundColor: highlightColor,
      marginBottom: 4
    },
    titleStyle: {
      ...h3,
      color: actionColor
    },
    rightStyle: {
      ...h5,
      color: secondaryColor
    },
    primaryContainerStyle: {
      justifyContent: "space-between",
      alignItems: "center"
    },
    timeContainerStyle: {
      alignItems: "center"
    },
    secondaryContainerStyle: {
      paddingTop: 8
    },
    locationTextStyle: {
      ...h3,
      color: secondaryColor
    }
  };

  const userMenuStyle = {
    emailTextStyleSM: {
      viewStyle: { marginTop: 4 },
      textStyle: {
        ...h5,
        color: secondaryColor,
        numberOfLines: 1
      }
    },
    emailTextStyleMD: {
      viewStyle: { marginTop: 4 },
      textStyle: {
        ...h4MD,
        color: secondaryColor,
        numberOfLines: 1
      }
    },
    nameTextStyleSM: {
      textStyle: {
        ...h3,
        color: primaryColor
      }
    },
    nameTextStyleMD: {
      textStyle: {
        ...h3MD,
        color: primaryColor
      }
    },
    descriptionStyle: {
      viewStyle: {
        paddingLeft: 12
      }
    },
    avatarStyle: {
      boxStyle: {
        viewStyle: {
          backgroundColor: themeBg,
          alignItems: "center",
          justifyContent: "center",
          width: 44,
          height: 44,
          borderRadius: 22
        },
        textStyle: {
          ...h2,
          color: highlightColor
        },
        imageStyle: {
          width: 44,
          height: 44,
          borderRadius: 22
        },
        imageProps: {
          resizeMode: "cover"
        }
      }
    }
  };

  return {
    ...typeWiseTheme,
    ...NavTheme,
    ...TabTheme,
    ...TableTheme,
    ...PanelTheme,
    ...DefaultInputProps,
    ...InputTheme,
    ...DrawerTheme,
    ...DrawerMenuTheme,
    ...spinnerTheme,
    ...switchTheme,
    ...ftsTheme,
    ...actionTheme,
    ...formTheme,
    ...toastTheme,
    ...fileBoxTheme,
    ...phoneNumberBoxTheme,
    ...fileAndImageRenderTheme,
    ...dateBoxTheme,
    ...checkBoxTheme,
    ...documentViewerTheme,
    ...documentSliderTheme,
    ...textRenderTheme,
    ...avatarCardTheme,
    ...textBoxMultipleTheme,
    ...linkBoxTheme,
    scheduleCardStyle,
    visibleView,
    breakPoints,
    themeGradientCard,
    errorGradientCard,
    gradientCard,
    groupText,
    primaryText,
    primaryTextMD,
    columnTextMD,
    secondaryText,
    secondaryTextMD,
    primaryHighlightText,
    secondaryHighlightText,
    actionText,
    bottomText,
    sideText,
    bottomHighlightText,
    circularAvatar,
    circularAvatarMD,
    renderSuggestionLabelStyle,
    cardStyle,
    avatarStyle,
    iconMenuStyle,
    userMenuStyle
  };
};
