import React from "react";

export default ({
  ScreenContext,
  ScreenRouteContext,
  routeStateKey,
  SelectionStoreContext,
  selectionStoreStateKey,
  renderProps,
  deepEqual
}) => {
  class ScreenContextProvider extends React.Component {
    render() {
      const { state } = this.props;
      let routeInfo = state[routeStateKey];
      const lastRouteInfo = this.routeInfo;
      if (deepEqual(lastRouteInfo, routeInfo)) {
        routeInfo = lastRouteInfo;
      } else {
        this.routeInfo = routeInfo;
      }
      return (
        <ScreenContext.Provider value={state}>
          <ScreenRouteContext.Provider value={routeInfo}>
              <SelectionStoreContext.Provider value={state[selectionStoreStateKey]}>
                {renderProps(this.props)}
              </SelectionStoreContext.Provider>
          </ScreenRouteContext.Provider>
        </ScreenContext.Provider>
      );
    }

    // render() {
    //   const { state } = this.props;
    //   let routeInfo = state[routeStateKey];
    //   const lastRouteInfo = this.routeInfo;
    //   if (deepEqual(lastRouteInfo, routeInfo)) {
    //     routeInfo = lastRouteInfo;
    //   } else {
    //     this.routeInfo = routeInfo;
    //   }
    //   return (
    //     <SchemaContext value={state[schemaStateKey]}>
    //       <ScreenComponentContext value={state[componentStateKey]}>
    //         <ScreenRouteContext value={routeInfo}>
    //           <ConnectContext value={state[connectStateKey]}>
    //             <SelectionStoreContext value={state[selectionStoreStateKey]}>
    //               {renderProps(this.props)}
    //             </SelectionStoreContext>
    //           </ConnectContext>
    //         </ScreenRouteContext>
    //       </ScreenComponentContext>
    //     </SchemaContext>
    //   );
    // }
  }
  return ScreenContextProvider;
};
