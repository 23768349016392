import { METADATA_TABLE } from "../../../Constants";
export const generateShareLink = ({
  fireLocalDataChangeListener,
  logFirebaseAnalyticsEvent,
  selectedIds,
  data,
  origin,
  invoke,
  urls,
  collaborator_mode,
  viaEmail,
  emails,
  subject,
  montage
}) => {
  return new Promise((resolve, reject) => {
    if ((!selectedIds || !selectedIds.length) && data && data._id) {
      selectedIds = [data._id];
    }
    if (!selectedIds || !selectedIds.length || !origin) {
      resolve();
      return;
    }
    let uriProps = {};
    if (origin === "collection") {
      uriProps = {
        itemType: "collection",
        collectionId: selectedIds[0]
      };
      if (collaborator_mode === "read" || collaborator_mode === "write") {
        uriProps["colloborator_mode"] = collaborator_mode;
      }
    } else if (origin === "places" && selectedIds.length === 1) {
      uriProps.itemType = "multiple";
      uriProps.type = origin === "doc" ? "set" : "album";
      uriProps.resources = selectedIds.map(_id => ({ _id }));
    } else {
      if (selectedIds.length === 1) {
        uriProps.itemType = "single";
        uriProps.resourceId = selectedIds[0];
      } else {
        uriProps.itemType = "multiple";
        uriProps.type = origin === "doc" ? "set" : "album";
        uriProps.resources = selectedIds.map(_id => ({ _id }));
      }
    }
    let invokeUrl = urls["getLink"];
    if (viaEmail) {
      uriProps.to = emails;
      uriProps.subject = subject;
      invokeUrl = urls["shareViaEmail"];
      if (origin === "collection" && data && data.type) {
        uriProps.type = data.type;
      }
    }
    if (montage) {
      uriProps.montage = true;
    }
    invoke({
      service: {
        url: invokeUrl,
        uriProps: {
          data: uriProps
        }
      },
      allowParallelInvoke: true
    })
      .then(res => {
        logFirebaseAnalyticsEvent &&
          logFirebaseAnalyticsEvent({ event: "share_files", params: { sharedMode: viaEmail ? "email" : "url" } });
        let { resourceId, resources = [] } = uriProps;
        let localChangesArray = [];
        if (resourceId) {
          localChangesArray.push({ _id: resourceId, changes: { private: false }, source: "action" });
        } else if (resources.length) {
          localChangesArray = resources.map(({ _id }) => {
            return { _id, changes: { private: false }, source: "action" };
          });
        }
        localChangesArray.length &&
          fireLocalDataChangeListener &&
          fireLocalDataChangeListener({
            key: METADATA_TABLE,
            localChanges: localChangesArray,
            updateSqlite: true
          });
        if (viaEmail) {
          resolve(res);
        } else if (res.url) {
          resolve({ url: res.url });
        } else {
          reject({ message: "URL not found" });
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
