import React from "react";
import { Box, theme, getImage } from "../../../FsCloudComponent";

class NoInternet extends React.Component {
  render() {
    const { colors, fonts } = theme;
    const { error } = this.props;
    const { h18_l } = fonts;
    const { primaryColor } = colors;
    return (
      <Box
        style={{ flex: 1, padding: 30 }}
        render={[
          { viewStyle: { alignSelf: "center", paddingTop: 30, paddingBottom: 30 }, image: getImage("loginLogo") },
          { textStyle: { textAlign: "center", ...h18_l, color: primaryColor }, text: (error && error.message) || "" }
        ]}
      />
    );
  }
}

export default NoInternet;
