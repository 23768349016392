export default {
  "/upload-gallery": {
    screen: "UploadGallery"
  },
  "/gallery-folder-assets": {
    screen: "GalleryFolderAssets"
  },
  "/upload-queue": {
    screen: "UploadQueue"
  },
  "/download-queue": {
    screen: "DownloadQueue"
  }
};
