export default () => {
  const validateResizeInfo = () => {};
  const getCachePath = () => {};
  const getCacheFileName = () => {};
  const clearCache = () => {};
  const updateCachePath = () => {};
  const updateCacheTime = () => {};
  const getTotalCacheSize = () => {};

  return {
    validateResizeInfo,
    getCachePath,
    getCacheFileName,
    clearCache,
    updateCachePath,
    updateCacheTime,
    getTotalCacheSize
  };
};
