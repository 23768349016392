import React from "react";
import {
  Box,
  ScrollView,
  Switch,
  getImage,
  WithModal,
  theme,
  I18N,
  withContext,
  Platform,
  getAction,
  ConfirmBox,
  Modal,
  onPendingBlocker,
  getLogPath,
  LOGS_FILE_NAME,
  showMessage,
  isFileExists,
  updateSettingInformation,
  getSettingInfo
} from "../../../FsCloudComponent";
import { getFilesPausedDueToWifi } from "../../../Sqlite";
import uuid from "uuid/v4";
const { colors, fonts, bgs } = theme;
const { primaryColor, brownGrey, themeColor, highlightColor } = colors;
const { borderBg5, blackBg } = bgs;
const { h16, h3_21, h1, h3, h1_16 } = fonts;

const modalStyle = {
  position: "cover",
  autoHide: true,
  animationType: "none",
  noBackdrop: true,
  width: 220,
  height: 77,
  style: {
    boxShadow: "0 0px 7px 0 rgba(0, 0, 0, 0.13)",
    shadowColor: blackBg,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    borderRadius: 2
  }
};

const headerTextStyle = {
  textStyle: {
    color: primaryColor,
    ...h16
  }
};

const descriptionTextStyle = {
  textStyle: { color: brownGrey, ...h3_21 }
};

const confirmModalProps = {
  key: "shareConfirm",
  width: 0.9,
  animationType: "none",
  style: {
    borderRadius: 4,
    overflow: "hidden"
  }
};

class SettingView extends React.Component {
  constructor(props) {
    super(props);
    const { user: { errorLogs = false } = {} } = this.props;
    this.state = {
      troubleshootEnabled: errorLogs
    };
  }

  updateTroubleshootingInfo = async updates => {
    let { troubleshootEnabled } = updates;
    let { invoke, urls } = this.props;
    onPendingBlocker && onPendingBlocker(1);
    try {
      await invoke({
        service: {
          url: urls["toggleTroubleshootingLogsUrl"],
          uriProps: {
            data: { enable: troubleshootEnabled }
          }
        }
      });
      this.setState({ troubleshootEnabled, troubleshootErrorOn: void 0 });
    } catch (e) {
      showMessage && showMessage(I18N.t("somethingWentWrong"));
      this.setState({ troubleshootEnabled: !troubleshootEnabled, troubleshootErrorOn: new Date().getTime() });
    }
    onPendingBlocker && onPendingBlocker(-1);
  };

  updateUserSettingInfo = async updates => {
    updateSettingInformation(
      updates,
      () => {
        this.setState({});
      },
      true
    );
    this.setState({});
  };

  confirmSendLogs = () => {
    this.setState({ sendLogsConfirmModal: true });
  };

  closeSendLogsConfirmModal = () => {
    this.setState({ sendLogsConfirmModal: false });
  };

  closeNetworkChangeConfirmModal = () => {
    this.setState({ networkChangeConfirmModal: false, pendingUploads: void 0 });
  };

  confirmNetworkChange = async value => {
    if (value === "MobileNetwork") {
      let { pausedUploads = 0, queuedUploads = 0 } = getFilesPausedDueToWifi && (await getFilesPausedDueToWifi());
      if (pausedUploads) {
        this.setState({ networkChangeConfirmModal: true, pendingUploads: queuedUploads + pausedUploads });
        return;
      }
    }
    this.updateUserSettingInfo({
      networkMode: value
    });
  };

  _sendLogs = async () => {
    let userId = this.props.user && this.props.user._id;
    if (!userId || !isFileExists || !getLogPath) {
      return;
    }
    let logFilePath = getLogPath();
    if (!logFilePath) {
      return;
    }
    let isExist = await isFileExists(logFilePath);
    if (!isExist) {
      return;
    }
    let uri = logFilePath;
    if (Platform.OS === "android") {
      uri = "file://" + uri;
    }
    let aggregateId = uuid();
    let path = `error_logs/${userId}/${aggregateId}/${LOGS_FILE_NAME}`;

    const { upload, urls, invoke } = this.props;
    onPendingBlocker && onPendingBlocker(1);
    try {
      let uploadUrl =
        upload &&
        (await upload(uri, {
          path,
          customMetadata: {
            logs: "true"
          },
          uploadType: "logs"
        }));
      uploadUrl &&
        (await invoke({
          service: {
            url: urls["sendLogsToServerUrl"],
            uriProps: {
              data: { url: uploadUrl }
            }
          }
        }));
      showMessage && showMessage(I18N.t("sendLogSuccessMessage"));
    } catch (e) {
      showMessage && showMessage(I18N.t("somethingWentWrong"));
    }
    onPendingBlocker && onPendingBlocker(-1);
  };

  renderModal = ({ closeModal } = {}) => {
    return (
      <Box
        render={[
          {
            onClick: () => {
              this.confirmNetworkChange("WiFi");
              closeModal && closeModal();
            },
            viewStyle: { paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 8 },
            textStyle: { color: brownGrey, ...h3 },
            text: I18N.t("wifi")
          },
          {
            onClick: () => {
              this.confirmNetworkChange("MobileNetwork");
              closeModal && closeModal();
            },
            viewStyle: { paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 16 },
            textStyle: { color: brownGrey, ...h3 },
            text: I18N.t("wifiMobile")
          }
        ]}
      />
    );
  };

  render() {
    let { troubleshootEnabled, troubleshootErrorOn } = this.state;
    let settingInfo = getSettingInfo();
    let { roamingEnabled, networkMode = "WiFi", allowLargeFilesOnWifiOnly } = settingInfo;

    return (
      <ScrollView style={{ flex: 1 }} bounces={false}>
        {this.state.networkChangeConfirmModal && (
          <Modal
            {...confirmModalProps}
            key={"networkChangeConfirmModal"}
            onClose={this.closeNetworkChangeConfirmModal}
            isOpen={true}
          >
            <ConfirmBox
              message={() => {
                let message = I18N.t("networkChangeSettingConfirm");
                message = message.replace("__pendingUploads__", `${this.state.pendingUploads || ""}`);
                return message;
              }}
              confirmText={I18N.t("yesContinue")}
              onConfirm={() => {
                this.updateUserSettingInfo({
                  networkMode: "MobileNetwork"
                });
                this.closeNetworkChangeConfirmModal();
              }}
              onCancel={this.closeNetworkChangeConfirmModal}
            />
          </Modal>
        )}
        {this.state.sendLogsConfirmModal && (
          <Modal
            {...confirmModalProps}
            key={"sendLogsConfirmModal"}
            onClose={this.closeSendLogsConfirmModal}
            isOpen={true}
          >
            <ConfirmBox
              message={I18N.t("sendLogsConfirmMessage")}
              confirmText={I18N.t("ok")}
              onConfirm={() => {
                this._sendLogs();
                this.closeSendLogsConfirmModal();
              }}
              onCancel={this.closeSendLogsConfirmModal}
            />
          </Modal>
        )}
        <Box
          viewStyle={{ paddingLeft: 16, paddingRight: 16 }}
          itemStyle={{ paddingTop: 16, paddingBottom: 16, borderBottomWidth: 1, borderBottomColor: borderBg5 }}
          render={[
            [
              {
                ...headerTextStyle,
                text: I18N.t("autoBackup")
              },
              {
                direction: "row",
                viewStyle: { paddingTop: 9 },
                Container: getAction("link"),
                containerProps: {
                  action: {
                    link: {
                      uri: "/upload-setting"
                    }
                  }
                },
                render: [
                  {
                    ...descriptionTextStyle,
                    width: "1fr",
                    text: I18N.t("uploadDescriptionOnSetting")
                  },
                  {
                    viewStyle: {
                      paddingLeft: 8,
                      height: 24,
                      width: 24,
                      alignItems: "center",
                      justifyContent: "center"
                    },
                    image: getImage("settingUploadIcon")
                  }
                ]
              }
            ],
            [
              {
                ...headerTextStyle,
                text: I18N.t("network")
              },
              {
                ...descriptionTextStyle,
                viewStyle: {
                  paddingTop: 8,
                  paddingBottom: 10
                },
                text: I18N.t("networkdescription1")
              },
              {
                viewStyle: {
                  alignItems: "flex-start"
                },
                render: (
                  <WithModal modal={modalStyle} renderModal={this.renderModal}>
                    <Box
                      direction={"row"}
                      viewStyle={{ alignItems: "center" }}
                      render={[
                        {
                          textStyle: { color: themeColor, ...h1 },
                          text: networkMode === "WiFi" ? I18N.t("wifi") : I18N.t("wifiMobile")
                        },
                        {
                          viewStyle: { paddingLeft: 6, paddingRight: 12 },
                          image: getImage("networkSelectorDropDownIcon")
                        }
                      ]}
                    />
                  </WithModal>
                )
              },
              {
                ...descriptionTextStyle,
                viewStyle: {
                  paddingTop: 12
                },
                text: networkMode === "WiFi" ? I18N.t("networkdescriptionWifi") : I18N.t("networkdescriptionMobile")
              },
              networkMode === "WiFi"
                ? void 0
                : {
                    direction: "row",
                    viewStyle: {
                      alignItems: "center"
                    },
                    render: [
                      {
                        onClick: () => {
                          this.updateUserSettingInfo({
                            allowLargeFilesOnWifiOnly: !allowLargeFilesOnWifiOnly
                          });
                        },
                        image: allowLargeFilesOnWifiOnly
                          ? getImage("notifyCheckBoxSelected")
                          : getImage("notifyCheckBox")
                      },
                      {
                        ...descriptionTextStyle,
                        viewStyle: {
                          paddingTop: 8,
                          paddingBottom: 10,
                          marginStart: 8
                        },
                        text: I18N.t("largeFileOnWifiOnly")
                      }
                    ]
                  }
            ],
            Platform.OS === "android" &&
              networkMode !== "WiFi" && [
                {
                  direction: "row",
                  render: [
                    {
                      ...headerTextStyle,
                      width: "1fr",
                      text: I18N.t("roaming")
                    },
                    <Switch
                      name="roaming"
                      onChangeValue={() =>
                        this.updateUserSettingInfo({
                          roamingEnabled: !roamingEnabled
                        })
                      }
                      value={roamingEnabled}
                    />
                  ]
                },
                {
                  ...descriptionTextStyle,
                  viewStyle: {
                    paddingTop: 8
                  },
                  text: I18N.t("roamingDescription")
                },
                roamingEnabled && {
                  ...descriptionTextStyle,
                  viewStyle: {
                    paddingTop: 4
                  },
                  text: I18N.t("roamingDescriptionNote")
                }
              ],
            {
              ...descriptionTextStyle,
              viewStyle: {
                paddingTop: 16,
                paddingBottom: 19
              },
              render: [
                {
                  direction: "row",
                  render: [
                    {
                      ...headerTextStyle,
                      width: "1fr",
                      text: I18N.t("troubleshooting")
                    },
                    <Switch
                      key={`${troubleshootErrorOn}_troubleshoot`}
                      name="troubleshooting"
                      onChangeValue={() =>
                        this.updateTroubleshootingInfo({
                          troubleshootEnabled: !troubleshootEnabled
                        })
                      }
                      value={troubleshootEnabled}
                    />
                  ]
                },
                {
                  ...descriptionTextStyle,
                  viewStyle: {
                    paddingTop: 8
                  },
                  text: I18N.t("troubleshootingDescription")
                },
                {
                  ...descriptionTextStyle,
                  viewStyle: {
                    paddingTop: 4
                  },
                  text: I18N.t("troubleshootingDescriptionNote")
                },
                {
                  viewStyle: { alignItems: "flex-end", marginTop: 12 },
                  render: [
                    {
                      onClick: this.confirmSendLogs,
                      viewStyle: {
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 16,
                        paddingRight: 16,
                        borderRadius: 2,
                        backgroundColor: themeColor,
                      },
                      textStyle: { color: highlightColor, ...h1_16 },
                      text: I18N.t("sendLog")
                    }
                  ]
                }
              ]
            },
            Platform.OS !== "web" &&
            [
              {
                ...headerTextStyle,
                text: I18N.t("deleteAccount"),
                direction: "row",
                viewStyle: { paddingTop: 15, borderTopWidth: 1, borderTopColor: borderBg5 },
                Container: getAction("link"),
                containerProps: {
                  action: {
                    link: {
                      uri: "/delete-account"
                    }
                  }
                }                
              },
            ],         
          ]}
        />
      </ScrollView>
    );
  }
}

SettingView = withContext(SettingView, {
  upload: "App.upload",
  invoke: "App.invoke",
  urls: "App.urls",
  user: "User.user"
});

export default {
  Settings: {
    component: "custom",
    Component: SettingView,
    title: () => I18N.t("settings")
  }
};
