import React from "react";
import { OverlayView } from "react-google-maps";
import { fetchUrl } from "../../../FsCloudAppServices";
import { Box, resolveMQ, withContext } from "../../../FsCloudComponent";

const popupStyle = {
  boxStyleSM: {
    height: 51,
    width: 51,
    borderRadius: 25
  },
  boxStyleMD: {
    height: 102,
    width: 102,
    borderRadius: 50
  },
  imageStyleSM: {
    height: 45,
    width: 45,
    borderRadius: 23
  },
  imageStyleMD: {
    height: 90,
    width: 90,
    borderRadius: 45
  }
};

const getPixelPositionOffset = pixelOffset => (width, height) => ({
  x: -(width / 2) + pixelOffset.x,
  y: -(height / 2) + pixelOffset.y
});

let Popup = ({ imageId, user, anchorPosition, markerPixelOffset, activeMQ }) => {
  let { boxStyle, imageStyle } = resolveMQ(popupStyle, ["boxStyle", "imageStyle"], activeMQ);

  return (
    <OverlayView
      position={anchorPosition}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset(markerPixelOffset)}
    >
      <Box
        viewStyle={boxStyle}
        render={[
          {
            viewStyle: { alignItems: "center", justifyContent: "center", zIndex: -1 },
            imageStyle: imageStyle,
            image: `${fetchUrl}/thumbnail/${imageId}/${user._id}`
          }
        ]}
      />
    </OverlayView>
  );
};

Popup = withContext(Popup, {
  activeMQ: "ActiveMQ"
});
export default Popup;
