export default () => {
  const addListeners = () => {};
  const removeListeners = () => {};
  const appSettingChangeListener = () => {};
  const uploadQueuedData = () => {};
  const fetchAssets = () => {};
  return {
    addListeners,
    removeListeners,
    appSettingChangeListener,
    uploadQueuedData,
    fetchAssets
  };
};
