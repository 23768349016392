import React from "react";

export default ({ addListRowUpdateListener, removeListRowUpdateListener }) => {
  class RowUpdateListenerWrapper extends React.Component {
    state = {};
    componentDidMount() {
      const { data } = this.props;
      if (data && data._id && addListRowUpdateListener) {
        addListRowUpdateListener({ _id: data._id, callback: this.onDataChange });
      }
    }

    onDataChange = ({ _id, changes = {} } = {}) => {
      const { data } = this.props;
      if (_id && data && data._id === _id) {
        // RecyclerListWithHeader was changing data reference
        Object.assign(data, changes);
        this.setState({});
      }
    };

    componentWillUnmount() {
      const { data } = this.props;
      if (data && data._id && removeListRowUpdateListener) {
        removeListRowUpdateListener({ _id: data._id, callback: this.onDataChange });
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      let { data } = nextProps;
      let { data: oldData } = this.props;
      oldData = oldData || {};
      if (data && data._id && data._id !== oldData._id && addListRowUpdateListener) {
        if (oldData._id && removeListRowUpdateListener) {
          removeListRowUpdateListener({ _id: oldData._id, callback: this.onDataChange });
        }
        addListRowUpdateListener({ _id: data._id, callback: this.onDataChange });
      }
    }
    render() {
      let { children } = this.props;
      return React.cloneElement(children);
    }
  }
  return RowUpdateListenerWrapper;
};
