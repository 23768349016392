import React from "react";
import {
  I18N,
  theme,
  Box,
  View,
  withContext,
  StatusBar as LoadingIndicator,
  getImage,
  FSList
} from "../../../FsCloudComponent";
import FileViewerComponent from "./FileViewerComponent";
import { CommentNoData } from "../noData/NoData";
import { dateModifier, setCommentListRef } from "./issuesUtility";

const { fonts, colors, shadows } = theme;
const { highlightColor } = colors;
const { h16_l, h1_16, h9 } = fonts;
const { cardShadow } = shadows;

const style1 = {
  marginLeft: 12,
  marginRight: 12,
  marginTop: 6,
  marginBottom: 6,
  backgroundColor: highlightColor,
  padding: 12,
  ...cardShadow
};
class CommentList extends React.Component {
  state = { data: [], loading: true };

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  getData = async () => {
    try {
      const { invoke, urls, link: { props: { filter: { _id: issueId } = {} } = {} } = {} } = this.props || {};
      const result = await invoke({
        service: {
          url: urls["getCommentsUrl"],
          uriProps: {
            data: {
              limit: 1000,
              issueId
            }
          }
        }
      });
      this._setState({ data: result, loading: false });
    } catch (err) {
      this._setState({ loading: false, error: err.message });
    }
  };

  componentDidMount() {
    setCommentListRef && setCommentListRef(this);
    this.getData();
  }

  componentWillUnmount() {
    this._unmounted = true;
    setCommentListRef && setCommentListRef(void 0);
  }

  dateComponent = ({ createdOn }) => {
    if (createdOn) {
      let data = dateModifier && dateModifier({ date: createdOn });
      let { date, time } = data || {};
      let dateText = `${date} - ${time}`;
      return <Box textStyle={{ color: "#a7a7a7", ...h9 }} text={dateText} />;
    }
  };
  renderItem = ({ item, index }) => {
    return (
      <Box
        key={item._id}
        viewStyle={style1}
        render={[
          item.commentBy === "support" && {
            viewStyle: {
              paddingTop: 5,
              paddingBottom: 5
            },
            textStyle: {
              color: "#5c5c5c",
              ...h1_16
            },
            text: `${I18N.t("replyFromSupport")}`
          },
          {
            textStyle: {
              color: "#5c5c5c",
              ...h16_l,
              userSelect: "text"
            },
            text: item.comment
          },
          {
            viewStyle: {
              paddingTop: 5,
              paddingBottom: 5
            },
            render: this.dateComponent({ createdOn: item._createdOn })
          },
          item.attachments &&
            item.attachments.length && {
              direction: "row",
              viewStyle: {
                paddingTop: 5,
                paddingBottom: 5
              },
              render: [
                {
                  image: getImage("linkGreyIcon")
                },
                { width: "1fr", render: <FileViewerComponent data={item} /> }
              ]
            }
        ]}
      />
    );
  };

  getSelectedIds = () => {
    return [];
  };
  render() {
    const { data, loading } = this.state;
    let showEmpty = !loading && !data.length;
    let showLoading = loading && !data.length;
    return (
      <View
        style={{
          flex: 1,
          paddingTop: 10,
          paddingBottom: 12,
          backgroundColor: "#fafafa"
        }}
      >
        {showEmpty ? (
          <CommentNoData />
        ) : (
          [
            <FSList
              style={{ flex: 1 }}
              data={data}
              renderItem={this.renderItem}
              getSelectedIds={this.getSelectedIds}
            />,
            showLoading ? <LoadingIndicator /> : null
          ]
        )}
      </View>
    );
  }
}

CommentList = withContext(CommentList, {
  url: "ScreenRoute.url",
  urls: "App.urls",
  invoke: "App.invoke",
  link: "ScreenRoute.link"
});

export default {
  CommentList: {
    component: "custom",
    title: () => I18N.t("allComments"),
    dataObject: "Issues",
    Component: CommentList,
    headerActions: [
      {
        type: "headerAction",
        action: {
          type: "link",
          image: getImage("createGreyIcon"),
          link: props => {
            let { link: { props: { filter: { _id } = {} } = {} } = {} } = props || {};
            return {
              uri: "/add-comment",
              props: {
                filter: { _id: _id }
              }
            };
          }
        }
      }
    ]
  }
};
