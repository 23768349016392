import GroupMemberActions from "../screens/groups/GroupMemberActions";

export default {
  GroupMember: {
    fields: {
      name: { field: "name", type: "text" },
      type: { field: "type", type: "text" },
      memberType: { field: "memberType", type: "text" }
    },
    actions: {
      ...GroupMemberActions
    },
    updateProps: {
      model: "GroupMember"
    }
  }
};
