import React from "react";
import {
  Box,
  View,
  getImage,
  theme,
  I18N,
  RowAction,
  ScrollView,
  StatusBar as LoadingIndicator,
  withContext,
  DecryptedImage,
  Link
} from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import { HighlightNoData } from "../noData/NoData";
import { fetchWeekData, fetchMonthData, fetchYearData, fetchMoreData, loadPicOfDay } from "./storyViewUtility";
const { gradients } = theme;
const { topBlackGradient, bottomBlackGradient } = gradients;

class WebStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storiesData: {},
      importGallery: false
    };
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };
  onMouseEnterImage = imageId => {
    this._setState({ activeImageId: imageId });
  };

  onMouseMoveImage = imageId => {
    if (this.state.activeImageId !== imageId) {
      this._setState({ activeImageId: imageId });
    }
  };
  onMouseLeaveImage = imageId => {
    if (this.state.activeImageId === imageId) {
      this._setState({ activeImageId: null });
    }
  };

  // These names are used in translation.
  displaySequence = [
    { title: "photoOfTheDay" },
    { title: "recentHighlights" },
    { title: "OneMonthAgo" },
    { title: "OneYearAgo" },
    { title: "MoreThanAnYearAgo" }
  ];

  dataFound = false;
  handleStoriesData = (params = {}, name) => {
    if (!name) {
      return;
    }
    let { storiesData } = this.state;
    storiesData[name] = params;
    this._setState({ storiesData });
    if (!this.dataFound) {
      this.dataFound = (params.data && params.data.length !== 0) || false;
    }
    this.props.setInfo && this.props.setInfo({ isLoading: false, dataFound: this.dataFound });
  };

  fetchStories = async () => {
    // POD
    loadPicOfDay().then(res => this.handleStoriesData(res, "photoOfTheDay"));
    // Recent
    fetchWeekData().then(res => this.handleStoriesData(res, "recentHighlights"));
    // OneMonth
    fetchMonthData().then(res => this.handleStoriesData(res, "OneMonthAgo"));
    // OneYear
    fetchYearData().then(res => this.handleStoriesData(res, "OneYearAgo"));
    // More
    fetchMoreData().then(res => this.handleStoriesData(res, "MoreThanAnYearAgo"));
  };

  componentDidMount() {
    this.fetchStories();
  }
  render() {
    const { storiesData, activeImageId, importGallery } = this.state;
    return (
      <Box
        direction="row"
        style={{ margin: 20 }}
        render={this.displaySequence.map(({ title } = {}) => {
          const { to: endDate, from: startDate, data: stories = [] } = storiesData[title] || {};
          const data = stories && stories[0];
          if (!data) {
            return void 0;
          }
          data.startDate = startDate;
          data.endDate = endDate;
          let resourceMenuActions = [ResourceActions.viewAll];
          if (title === "photoOfTheDay") {
            if (data.exported || importGallery) {
              resourceMenuActions = [
                ResourceActions.postToGroup,
                ResourceActions.getLink,
                ResourceActions.webShareGallery
              ];
            } else {
              resourceMenuActions = [
                {
                  ...ResourceActions.importPhotoToGallery,
                  text: () => I18N.t("importToGallery"),
                  image: getImage("highlightImportGreyIcon"),
                  title: () => I18N.t("importToGallery"),
                  updateStateOnInvoke: (_, props) => {
                    let { invokeResult } = props;
                    if (invokeResult && !invokeResult.error) {
                      this._setState({ importGallery: true });
                    }
                  }
                }
              ];
            }
          }
          const uri = data.thumbnail_url;
          const loadingImageKey = `onLoad${data._id}`;
          return {
            onMouseMove: () => this.onMouseMoveImage(data._id),
            onMouseEnter: () => this.onMouseEnterImage(data._id),
            onMouseLeave: () => this.onMouseLeaveImage(data._id),
            viewStyle: {
              minHeight: 360,
              minWidth: 210,
              borderRadius: 30,
              marginRight: 8,
              marginLeft: 8
            },
            render: [
              <DecryptedImage
                data={data}
                style={{
                  height: 360,
                  width: 210,
                  objectFit: "cover"
                }}
                onLoad={() => {
                  this._setState({ [loadingImageKey]: true });
                }}
                source={{ uri }}
              />,
              {
                viewStyle: {
                  ...bottomBlackGradient,
                  position: "absolute",
                  bottom: 0,
                  width: 210,
                  height: 32,
                  alignItems: "center"
                },
                textStyle: { color: "white", display: "flex", flex: 1, alignItems: "flex-end", paddingBottom: 6 },
                text: I18N.t(title)
              },
              this.state[loadingImageKey]
                ? {
                    viewStyle: {
                      position: "absolute",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      alignSelf: "center",
                      top: 170,
                      borderRadius: 10,
                      padding: 8,
                      backgroundColor: "rgba(0,0,0,0.6)"
                    },
                    Container: Link,
                    containerProps: {
                      action: {
                        link: {
                          uri: "/montage-highlights",
                          props: {
                            parentInfo: { _connectProps: { data: stories } }
                          }
                        }
                      }
                    },
                    image: getImage("albumSlideshowTransparentIcon")
                  }
                : {
                    viewStyle: { flex: 1 },
                    render: <LoadingIndicator />
                  },
              activeImageId &&
                data._id === activeImageId && {
                  gradient: topBlackGradient,
                  gradientStyle: {
                    position: "absolute",
                    top: 0,
                    height: 150,
                    width: 210
                  },
                  viewStyle: {
                    flex: 1,
                    position: "absolute",
                    height: 32,
                    width: 32,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 4,
                    paddingRight: 4,
                    cursor: "pointer",
                    top: 6,
                    right: 6,
                    zIndex: 1
                  },
                  render: <RowAction data={data} action={resourceMenuActions} image={getImage("iconMenuWhite")} />
                }
            ]
          };
        })}
      />
    );
  }
}

class HighlightViewMD extends React.Component {
  state = { isLoading: true };

  setInfo = info => {
    if (!info.dataFound && this.state.dataFound) {
      info.dataFound = true;
    }
    this.setState(info);
  };

  render() {
    let { dataFound, isLoading } = this.state;
    let isRenderNoData = !isLoading && dataFound === false;
    return (
      <View style={{ flex: 1 }}>
        {isLoading && <LoadingIndicator />}
        {isRenderNoData ? (
          <HighlightNoData />
        ) : (
          <ScrollView style={{ flex: 1 }}>
            <WebStory {...this.props} setInfo={this.setInfo} />
          </ScrollView>
        )}
      </View>
    );
  }
}

HighlightViewMD = withContext(HighlightViewMD, {
  user: "User.user"
});

export default HighlightViewMD;
