const screenEventMappings = {
  Walkthrough: "on_boarding",
  FreeUpSpace: "free_up_space",
  Login: "login",
  Highlight: "highlight"
};
const tabEventMappings = {
  highlights: "highlight",
  galleryAll: "gallery",
  albums: "albums",
  docsAll: "docs",
  docsSets: "sets",
  musicAll: "music",
  playlists: "playlists",
  artists: "artists",
  groupGallery: "groupGallery",
  groupDocs: "groupDocs",
  groupMembers: "groupMembers",
  profile: "profile",
  storage: "storage",
  vaultGallery: "gallery_safearea",
  vaultDocs: "docs_safearea"
};

export default ({ firebase, firebaseConfig = {}, Platform, SECURE_TYPES }) => {
  let firebaseAnalytics = void 0;
  try {
    if (Platform.OS !== "web" || firebaseConfig.measurementId) {
      firebaseAnalytics = firebase && firebase.analytics();
    }
  } catch (err) {
    console.warn("Error in firebase Analytics instance >>>>>> ", err && err.message);
  }

  const logFirebaseAnalyticsEvent = async ({ screen, event, params }) => {
    try {
      // console.warn("log firebase screen : ", screen, " event: ", event, " params: ", params);
      if (!event || !firebaseAnalytics) {
        return;
      }
      params = params || {};
      let eventName = `fs${screen ? "_" + screen + "_" : ""}_${event}`;
      eventName = eventName.toUpperCase();
      await firebaseAnalytics.logEvent(eventName, params);
    } catch (err) {
      console.warn("err in logging analytics event: ", err && err.message);
    }
  };

  const setFirebaseAnalyticsUser = async ({ uid } = {}) => {
    try {
      firebaseAnalytics && (await firebaseAnalytics.setUserId(uid || null));
    } catch (err) {
      console.warn("err in setting analytics user: ", err && err.message);
    }
  };

  const logFirebaseAnalyticsScreenEvent = ({ screenName, tab, isNestedTab }) => {
    if (tab === "highlights" && !isNestedTab) {
      tab = void 0;
    }
    let screen = (screenName && screenEventMappings[screenName]) || (tab && tabEventMappings[tab]);
    if (screen) {
      logFirebaseAnalyticsEvent({
        screen,
        event: "opened"
      });
    }
  };

  const logUploadAnalytics = files => {
    const UPLOAD = "upload";
    const SAFE_UPLOAD = "safe_upload";
    let total = 0,
      vaultTotal = 0,
      gallery = 0,
      docs = 0,
      audio = 0,
      vaultGallery = 0,
      vaultDocs = 0;

    for (let file of files) {
      // in case of web, we get type in file.file.type instead of fileType
      let { fileType: type, secureType } = file;
      secureType = secureType || SECURE_TYPES.DEFAULT;
      total++;
      if (secureType === SECURE_TYPES.DEFAULT) {
        if (type === "image" || type === "video" || type === "gallery") {
          gallery++;
        } else if (type === "doc") {
          docs++;
        } else if (type === "audio") {
          audio++;
        }
      } else if (secureType === SECURE_TYPES.VAULT) {
        vaultTotal++;
        if (type === "image" || type === "video" || type === "gallery") {
          vaultGallery++;
        } else if (type === "doc") {
          vaultDocs++;
        }
      }
    }
    total &&
      logFirebaseAnalyticsEvent({
        event: UPLOAD,
        params: { count: total, value: total }
      });
    vaultTotal &&
      logFirebaseAnalyticsEvent({
        event: SAFE_UPLOAD,
        params: { count: vaultTotal, value: vaultTotal }
      });
    gallery &&
      logFirebaseAnalyticsEvent({
        screen: "gallery",
        event: UPLOAD,
        params: { count: gallery, value: gallery }
      });

    docs &&
      logFirebaseAnalyticsEvent({
        screen: "docs",
        event: UPLOAD,
        params: { count: docs, value: docs }
      });
    audio &&
      logFirebaseAnalyticsEvent({
        screen: "audio",
        event: UPLOAD,
        params: { count: audio, value: audio }
      });
    vaultGallery &&
      logFirebaseAnalyticsEvent({
        screen: "gallery",
        event: SAFE_UPLOAD,
        params: { count: vaultGallery, value: vaultGallery }
      });
    vaultDocs &&
      logFirebaseAnalyticsEvent({
        screen: "docs",
        event: SAFE_UPLOAD,
        params: { count: vaultDocs, value: vaultDocs }
      });
  };

  const logDownloadAnalytics = files => {
    const DOWNLOAD = "download";
    let total = 0,
      gallery = 0,
      docs = 0,
      audio = 0;

    for (let file of files) {
      let { type } = file;
      total++;
      if (type === "image" || type === "video") {
        gallery++;
      } else if (type === "doc") {
        docs++;
      } else if (type === "audio") {
        audio++;
      }
    }
    total &&
      logFirebaseAnalyticsEvent({
        event: DOWNLOAD,
        params: { count: total, value: total }
      });

    gallery &&
      logFirebaseAnalyticsEvent({
        screen: "gallery",
        event: DOWNLOAD,
        params: { count: gallery, value: gallery }
      });
    docs &&
      logFirebaseAnalyticsEvent({
        screen: "docs",
        event: DOWNLOAD,
        params: { count: docs, value: docs }
      });
    audio &&
      logFirebaseAnalyticsEvent({
        screen: "audio",
        event: DOWNLOAD,
        params: { count: audio, value: audio }
      });
  };

  /**
   * logSearchAnalytics*
   *
   * fromScreen: screen,
   * type: search-type (favourite, shared, dateFilter, video, (acc, mp3), (text and otehr doc types......))
   */
  const logSearchAnalytics = ({ fromScreen: from, type }) => {
    let screen = void 0,
      searchEventKey = void 0;
    if (from === "search-gallery" || from === "search-vault-gallery" || from === "search-archive-gallery") {
      screen = "gallery";
    } else if (from === "search-docs" || from === "search-vault-docs" || from === "search-archive-docs") {
      screen = "doc";
    } else if (from === "search-music" || from === "search-archive-music") {
      screen = "audio";
    }
    if (!screen) {
      return;
    }

    if (type === "favourite" || type === "shared" || type === "video") {
      searchEventKey = "search_" + type;
    } else if (type === "dateFilter") {
      searchEventKey = "search_date";
    } else if (screen === "doc") {
      searchEventKey = "search_docType";
    } else if (screen === "audio") {
      searchEventKey = "search_audioType";
    }
    if (!searchEventKey) {
      return;
    }
    logFirebaseAnalyticsEvent({
      screen,
      event: searchEventKey
    });
  };

  return {
    logFirebaseAnalyticsEvent,
    setFirebaseAnalyticsUser,
    logFirebaseAnalyticsScreenEvent,
    logUploadAnalytics,
    logDownloadAnalytics,
    logSearchAnalytics
  };
};
