export default ({ resolveImage }) => {
  const headerSprite = {
    uri: "/webImages/headerIcons/1_sprite.png",
    createIcon: {
      height: 17,
      width: 17,
      objectPosition: "-220px -92px",
      objectFit: "none"
    },
    createGreyIcon: {
      height: 17,
      width: 17,
      objectPosition: "-220px -119px",
      objectFit: "none"
    },
    saveIcon: {
      width: 20,
      height: 15,
      objectPosition: "-289px -45px",
      objectFit: "none"
    },
    backIcon: {
      width: 9,
      height: 16,
      objectPosition: "-220px -40px",
      objectFit: "none"
    },
    backWhiteIcon: {
      width: 9,
      height: 16,
      objectPosition: "-220px -66px",
      objectFit: "none"
    },
    backArrowGreyIcon: {
      width: 16,
      height: 16,
      objectPosition: "-14px -82px",
      objectFit: "none"
    },
    backArrowWhiteIcon: {
      width: 16,
      height: 16,
      objectPosition: "-14px -116px",
      objectFit: "none"
    },
    crossIcon: {
      width: 18,
      height: 18,
      objectPosition: "-13px -183px",
      objectFit: "none"
    },
    addToCollectionIcon: {
      height: 21,
      width: 21,
      objectPosition: "-12px -12px",
      objectFit: "none"
    },
    addToCollectionWhiteIcon: {
      width: 21,
      height: 21,
      objectPosition: "-13px -46px",
      objectFit: "none"
    },
    removeToCollectionIcon: {
      width: 21,
      height: 21,
      objectPosition: "-148px -148px",
      objectFit: "none"
    },
    removeToCollectionWhiteIcon: {
      width: 21,
      height: 21,
      objectPosition: "-148px -182px",
      objectFit: "none"
    },
    deleteWhiteIcon: {
      width: 17,
      height: 21,
      objectPosition: "-221px -146px",
      objectFit: "none"
    },
    deleteWhiteMDIcon: {
      width: 16,
      height: 19,
      objectPosition: "-48px -47px",
      objectFit: "none"
    },
    deleteIcon: {
      width: 16,
      height: 19,
      objectPosition: "-48px -13px",
      objectFit: "none"
    },
    downloadIcon: {
      width: 20,
      height: 18,
      objectPosition: "-46px -115px",
      objectFit: "none"
    },
    downloadWhiteIcon: {
      width: 20,
      height: 18,
      objectPosition: "-46px -149px",
      objectFit: "none"
    },
    detailViewIcon: {
      width: 20,
      height: 20,
      objectPosition: "-46px -80px",
      objectFit: "none"
    },
    editIcon: {
      width: 22,
      height: 21,
      objectPosition: "-147px -216px",
      objectFit: "none"
    },
    editWhiteIcon: {
      width: 20,
      height: 20,
      objectPosition: "-183px -12px",
      objectFit: "none"
    },
    makePrivateIcon: {
      width: 16,
      height: 21,
      objectPosition: "-150px -12px",
      objectFit: "none"
    },
    makePrivateWhiteIcon: {
      width: 16,
      height: 21,
      objectPosition: "-253px -185px",
      objectFit: "none"
    },
    playMusicIcon: {
      width: 20,
      height: 20,
      objectPosition: "-80px -148px",
      objectFit: "none"
    },
    playMusicGreyIcon: {
      width: 18,
      height: 18,
      objectPosition: "-81px -183px",
      objectFit: "none"
    },
    leaveCollectionIcon: {
      width: 19,
      height: 21,
      objectPosition: "-115px -148px",
      objectFit: "none"
    },
    restoreIcon: {
      width: 20,
      height: 19,
      objectPosition: "-182px -47px",
      objectFit: "none"
    },
    createWithCircleIcon: {
      width: 20,
      height: 20,
      objectPosition: "-148px -114px",
      objectFit: "none"
    },
    contactGroupIcon: {
      width: 20,
      height: 18,
      objectPosition: "-12px -149px",
      objectFit: "none"
    },
    headerLogoutIcon: {
      width: 16,
      height: 18,
      objectPosition: "-116px -217px",
      objectFit: "none"
    },
    pinRemoveIcon: {
      width: 22,
      height: 24,
      objectPosition: "-147px -44px",
      objectFit: "none"
    },
    pinRemoveGreyIcon: {
      width: 20,
      height: 19,
      objectPosition: "-253px -216px",
      objectFit: "none"
    },
    pinRemoveWhiteIcon: {
      width: 25,
      height: 25,
      objectPosition: "-289px -10px",
      objectFit: "none"
    },
    pinRemoveWhiteMDIcon: {
      width: 22,
      height: 24,
      objectPosition: "-147px -78px",
      objectFit: "none"
    },
    importIcon: {
      width: 23,
      height: 17,
      objectPosition: "-181px -216px",
      objectFit: "none"
    },
    importWhiteIcon: {
      width: 26,
      height: 18,
      objectPosition: "-253px -121px",
      objectFit: "none"
    },
    importWhiteMDIcon: {
      width: 23,
      height: 17,
      objectPosition: "-214px -13px",
      objectFit: "none"
    },
    importGreyIcon: {
      width: 20,
      height: 14,
      objectPosition: "-253px -97px",
      objectFit: "none"
    },
    editImageWhiteIcon: {
      width: 21,
      height: 21,
      objectPosition: "-222px -177px",
      objectFit: "none"
    },
    favouriteIcon: {
      width: 19,
      height: 17,
      objectPosition: "-47px -184px",
      objectFit: "none"
    },
    favouriteSelectedIcon: {
      width: 20,
      height: 19,
      objectPosition: "-80px -47px",
      objectFit: "none"
    },
    favouriteGreyIcon: {
      width: 18,
      height: 16,
      objectPosition: "-224px -208px",
      objectFit: "none"
    },
    favouriteGreyMDIcon: {
      width: 20,
      height: 19,
      objectPosition: "-46px -217px",
      objectFit: "none"
    },
    favouriteGreySelectedIcon: {
      width: 20,
      height: 19,
      objectPosition: "-81px -15px",
      objectFit: "none"
    },
    favouriteWhiteIcon: {
      width: 20,
      height: 19,
      objectPosition: "-253px -42px",
      objectFit: "none"
    },
    favouriteWhiteMDIcon: {
      width: 19,
      height: 17,
      objectPosition: "-81px -82px",
      objectFit: "none"
    },
    favouriteWhiteSelectedIcon: {
      width: 20,
      height: 19,
      objectPosition: "-253px -13px",
      objectFit: "none"
    },
    favouriteWhiteSelectedMDIcon: {
      width: 19,
      height: 17,
      objectPosition: "-81px -116px",
      objectFit: "none"
    },
    infoIcon: {
      width: 20,
      height: 20,
      objectPosition: "-114px -80px",
      objectFit: "none"
    },
    infoWhiteIcon: {
      width: 26,
      height: 26,
      objectPosition: "-253px -149px",
      objectFit: "none"
    },
    infoWhiteMDIcon: {
      width: 20,
      height: 20,
      objectPosition: "-114px -114px",
      objectFit: "none"
    },
    shareWhiteIcon: {
      width: 20,
      height: 21,
      objectPosition: "-289px -154px",
      objectFit: "none"
    },
    shareWhiteMDIcon: {
      width: 17,
      height: 18,
      objectPosition: "-184px -149px",
      objectFit: "none"
    },
    shareIcon: {
      width: 17,
      height: 18,
      objectPosition: "-289px -126px",
      objectFit: "none"
    },
    shareMDIcon: {
      width: 17,
      height: 19,
      objectPosition: "-184px -81px",
      objectFit: "none"
    },
    shareGreyIcon: {
      width: 16,
      height: 17,
      objectPosition: "-289px -99px",
      objectFit: "none"
    },
    shareGreyMDIcon: {
      width: 17,
      height: 19,
      objectPosition: "-289px -70px",
      objectFit: "none"
    },
    iconMenuWhite: {
      width: 4,
      height: 16,
      objectPosition: "-253px -71px",
      objectFit: "none"
    },
    iconMenuWhiteMD: {
      width: 4,
      height: 16,
      objectPosition: "-122px -14px",
      objectFit: "none"
    },
    iconMenuGrey: {
      width: 4,
      height: 16,
      objectPosition: "-122px -48px",
      objectFit: "none"
    },
    iconMenu: {
      width: 4,
      height: 16,
      objectPosition: "-88px -218px",
      objectFit: "none"
    },
    crossWhiteIcon: {
      height: 18,
      width: 18,
      objectPosition: "-13px -217px",
      objectFit: "none"
    },
    groupPinGreyIcon: {
      height: 19,
      width: 20,
      objectPosition: "-289px -185px",
      objectFit: "none"
    }
  };

  const createIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.createIcon
    }
  };
  const createGreyIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.createGreyIcon
    }
  };
  const saveIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.saveIcon
    }
  };
  const backIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.backIcon
    }
  };
  const backWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.backWhiteIcon
    }
  };
  const backArrowGreyIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.backArrowGreyIcon
    }
  };

  const backArrowWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.backArrowWhiteIcon
    }
  };
  const crossIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.crossIcon
    }
  };

  const addToCollectionIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.addToCollectionIcon
    }
  };
  const addToCollectionWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.addToCollectionWhiteIcon
    }
  };
  const removeToCollectionIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.removeToCollectionIcon
    }
  };
  const removeToCollectionWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.removeToCollectionWhiteIcon
    }
  };
  const deleteWhiteIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.deleteWhiteIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.deleteWhiteMDIcon
        }
      }
    });
  const deleteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.deleteIcon
    }
  };
  const downloadIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.downloadIcon
    }
  };
  const downloadWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.downloadWhiteIcon
    }
  };
  const detailViewIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.detailViewIcon
    }
  };
  const editIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.editIcon
    }
  };
  const editWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.editWhiteIcon
    }
  };
  const makePrivateIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.makePrivateIcon
    }
  };
  const makePrivateWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.makePrivateWhiteIcon
    }
  };
  const playMusicIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.playMusicIcon
    }
  };
  const playMusicGreyIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.playMusicGreyIcon
    }
  };
  const leaveCollectionIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.leaveCollectionIcon
    }
  };
  const restoreIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.restoreIcon
    }
  };
  const createWithCircleIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.createWithCircleIcon
    }
  };
  const contactGroupIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.contactGroupIcon
    }
  };
  const headerLogoutIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.headerLogoutIcon
    }
  };
  // const pinRemoveIcon = {
  //   uri: headerSprite.uri,
  //   spriteProps: {
  //     ...headerSprite.pinRemoveIcon
  //   }
  // };
  // const pinRemoveGreyIcon = {
  //   uri: headerSprite.uri,
  //   spriteProps: {
  //     ...headerSprite.pinRemoveGreyIcon
  //   }
  // };
  // const pinRemoveWhiteIcon = () =>
  //   resolveImage({
  //     SM: {
  //       uri: headerSprite.uri,
  //       spriteProps: {
  //         ...headerSprite.pinRemoveWhiteIcon
  //       }
  //     },
  //     MD: {
  //       uri: headerSprite.uri,
  //       spriteProps: {
  //         ...headerSprite.pinRemoveWhiteMDIcon
  //       }
  //     }
  //   });
  const importIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.importIcon
    }
  };

  const importWhiteIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.importWhiteIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.importWhiteMDIcon
        }
      }
    });
  const importGreyIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.importGreyIcon
    }
  };
  const editImageWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.editImageWhiteIcon
    }
  };
  const favouriteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.favouriteIcon
    }
  };
  const favouriteSelectedIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.favouriteSelectedIcon
    }
  };
  const favouriteGreyIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.favouriteGreyIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.favouriteGreyMDIcon
        }
      }
    });
  const favouriteGreySelectedIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.favouriteGreySelectedIcon
    }
  };
  const favouriteWhiteIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.favouriteWhiteIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.favouriteWhiteMDIcon
        }
      }
    });
  const favouriteWhiteSelectedIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.favouriteWhiteSelectedIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.favouriteWhiteSelectedMDIcon
        }
      }
    });
  const infoIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.infoIcon
    }
  };
  const infoWhiteIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.infoWhiteIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.infoWhiteMDIcon
        }
      }
    });
  const shareWhiteIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.shareWhiteIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.shareWhiteMDIcon
        }
      }
    });
  const shareIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.shareIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.shareMDIcon
        }
      }
    });
  const shareGreyIcon = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.shareGreyIcon
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.shareGreyMDIcon
        }
      }
    });
  const iconMenuWhite = () =>
    resolveImage({
      SM: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.iconMenuWhite
        }
      },
      MD: {
        uri: headerSprite.uri,
        spriteProps: {
          ...headerSprite.iconMenuWhiteMD
        }
      }
    });
  const iconMenuGrey = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.iconMenuGrey
    }
  };

  const iconMenu = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.iconMenu
    }
  };
  const crossWhiteIcon = {
    uri: headerSprite.uri,
    spriteProps: {
      ...headerSprite.crossWhiteIcon
    }
  };
  // const groupPinGreyIcon = {
  //   uri: headerSprite.uri,
  //   spriteProps: {
  //     ...headerSprite.groupPinGreyIcon
  //   }
  // };

  const groupPinGreyIcon = "/webImages/headerIcons/groupPinGrey.png";
  const pinRemoveWhiteIcon = () =>
    resolveImage({
      SM: "/webImages/headerIcons/pinRemoveWhite.png",
      MD: "/webImages/headerIcons/pinRemoveWhiteMD.png"
    });
  const pinRemoveIcon = "/webImages/headerIcons/pinRemove.png";
  const pinRemoveGreyIcon = "/webImages/headerIcons/pinRemoveGrey.png";

  const moveToVaultIcon = "/webImages/headerIcons/moveToVault.png";
  const moveToVaultWhiteIcon = "/webImages/headerIcons/moveToVaultWhite.png";
  const removeFromVaultIcon = "/webImages/headerIcons/removeFromVault.png";
  const removeFromVaultWhiteIcon = "/webImages/headerIcons/removeFromVaultWhite.png";
  const moveToArchiveIcon = "/webImages/headerIcons/moveToArchive.png";
  const moveToArchiveWhiteIcon = "/webImages/headerIcons/moveToArchiveWhite.png";
  const removeFromArchiveIcon = "/webImages/headerIcons/removeFromArchive.png";
  const removeFromArchiveWhiteIcon = "/webImages/headerIcons/removeFromArchiveWhite.png";

  return {
    createIcon,
    createGreyIcon,
    saveIcon,
    backIcon,
    backWhiteIcon,
    backArrowGreyIcon,
    backArrowWhiteIcon,
    crossIcon,
    addToCollectionIcon,
    addToCollectionWhiteIcon,
    removeToCollectionIcon,
    removeToCollectionWhiteIcon,
    deleteWhiteIcon,
    deleteIcon,
    downloadIcon,
    downloadWhiteIcon,
    detailViewIcon,
    editIcon,
    editWhiteIcon,
    makePrivateIcon,
    makePrivateWhiteIcon,
    playMusicIcon,
    playMusicGreyIcon,
    leaveCollectionIcon,
    restoreIcon,
    createWithCircleIcon,
    contactGroupIcon,
    headerLogoutIcon,
    pinRemoveIcon,
    pinRemoveGreyIcon,
    pinRemoveWhiteIcon,
    importIcon,
    importWhiteIcon,
    importGreyIcon,
    editImageWhiteIcon,
    favouriteIcon,
    favouriteSelectedIcon,
    favouriteGreyIcon,
    favouriteGreySelectedIcon,
    favouriteWhiteIcon,
    favouriteWhiteSelectedIcon,
    infoIcon,
    infoWhiteIcon,
    shareWhiteIcon,
    shareIcon,
    shareGreyIcon,
    iconMenuWhite,
    iconMenuGrey,
    iconMenu,
    crossWhiteIcon,
    groupPinGreyIcon,
    moveToVaultIcon,
    moveToVaultWhiteIcon,
    removeFromVaultIcon,
    removeFromVaultWhiteIcon,
    moveToArchiveIcon,
    moveToArchiveWhiteIcon,
    removeFromArchiveIcon,
    removeFromArchiveWhiteIcon
  };
};
