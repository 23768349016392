import React from "react";
import {
  Box,
  ScrollView,
  theme,
  I18N,
  getInput,
  getImage,
  withContext,
  StatusBar as LoadingIndicator,
  getAfterLoginLink,
  showMessage,
  Platform,
  isValidEmail,
  updateUserProfile
} from "../../../FsCloudComponent";
import { landingUrl } from "../../nav";
import ProfileImage from "./ProfileImage";
import keyboardType from "../../../keyboardType";

const { fonts, colors, bgs } = theme;
const { h16, h2_l_16, h14_24, h1, h9_18, h9_24, h5, h16_18, h9 } = fonts;
const { primaryColor, highlightColor, brownGrey, paleGrey, tomato } = colors;
const { themeBg } = bgs;

const styles = {
  textInputContainerStyle: {
    viewStyle: {
      paddingTop: 7,
      borderBottomWidth: 1,
      borderBottomColor: paleGrey
    }
  },
  textHeaderStyle: {
    textStyle: {
      ...h2_l_16,
      color: brownGrey,
      paddingTop: 16
    }
  },
  textInputStyle: {
    style: {
      ...(Platform.OS === "ios"
        ? {
            ...h1,
            height: 24
          }
        : { ...h14_24 }),
      color: primaryColor,
      paddingBottom: 8
    }
  },
  placeHolderStyle: {
    style: {
      ...(Platform.OS === "ios"
        ? {
            ...h9,
            height: 24
          }
        : { ...h9_24 }),
      paddingBottom: 8
    }
  },
  errorContainerStyle: {
    direction: "row",
    viewStyle: {
      height: 20,
      paddingTop: 6
    }
  },
  errorMessageStyle: {
    viewStyle: {
      paddingLeft: 6,
      flex: 1
    },
    textStyle: {
      ...h5,
      color: tomato,
      numberOfLines: 1
    }
  }
};

class IntroduceComponent extends React.Component {
  constructor(props) {
    super(props);
    let { user } = props;
    let userState = {};
    if (user.firstName) {
      userState.firstName = user.firstName;
    }
    if (user.lastName) {
      userState.lastName = user.lastName;
    }
    if (user.email) {
      userState.email = user.email;
    }
    this.state = userState;
  }

  onAddClick = async () => {
    let { user, activeMQ } = this.props;
    try {
      let { firstName = "", lastName, email = "" } = this.state;
      firstName = firstName.trim();
      email = email.trim();
      if (lastName !== undefined) {
        lastName = lastName.trim();
      }

      let errorState = {};
      if (firstName.length === 0) {
        errorState.firstNameError = I18N.t("nameMandatoryError");
      }
      if (email.length === 0) {
        errorState.emailError = I18N.t("emailError");
      } else if (!isValidEmail(email)) {
        errorState.emailError = I18N.t("emailValidationError");
      }
      if (errorState && Object.keys(errorState).length) {
        this.setState(errorState);
        return;
      }

      this.setState({ loading: true });
      let { replaceUri } = this.props;
      let data = {};
      if (user.firstName !== firstName) {
        data.firstName = firstName;
      }
      if (user.email !== email) {
        data.email = email;
      }
      if (lastName !== undefined && user.lastName !== lastName) {
        data.lastName = lastName;
      }
      let userResult = await updateUserProfile(data);
      let updatedUserInfo = {
        ...user,
        ...userResult
      };
      let afterLoginLink =
        getAfterLoginLink &&
        getAfterLoginLink({
          user: updatedUserInfo,
          activeMQ
        });
      let replaceLink = {
        ...landingUrl({ user: updatedUserInfo, afterLoginLink, activeMQ }),
        _user: userResult
      };
      replaceUri && replaceUri(replaceLink);
    } catch (err) {
      showMessage && showMessage(err.message, 2000);
      this.setState({
        loading: false
      });
    }
  };

  getInputComponent = (field, inputProps) => {
    const TextInput = getInput("text");
    let error = this.state[`${field}Error`];
    return [
      {
        ...styles.textHeaderStyle,
        text: I18N.t(field)
      },
      {
        ...styles.textInputContainerStyle,
        render: (
          <TextInput
            value={this.state[field]}
            onChangeValue={value => {
              let newState = { [field]: value };
              if (this.state[`${field}Error`]) {
                newState[`${field}Error`] = void 0;
              }
              this.setState(newState);
            }}
            {...(this.state[field] ? styles.textInputStyle : styles.placeHolderStyle)}
            {...inputProps}
          />
        )
      },
      error && {
        ...styles.errorContainerStyle,
        render: [
          {
            image: getImage("errorIcon")
          },
          {
            ...styles.errorMessageStyle,
            text: error
          }
        ]
      }
    ];
  };

  render() {
    return (
      <ScrollView style={{ flex: 1 }}>
        {this.state.loading && <LoadingIndicator />}
        <Box
          viewStyle={{
            paddingLeft: 16,
            paddingRight: 16
          }}
          render={[
            {
              viewStyle: { alignSelf: "center", paddingBottom: 18, paddingTop: 20 },
              render: (
                <ProfileImage
                  onUploadStart={() => this.setState({ loading: true })}
                  onUploadEnd={() => this.setState({ loading: false })}
                  onUploadError={() => this.setState({ loading: false })}
                  icon={"editProfileIcon"}
                />
              )
            },
            {
              viewStyle: {
                paddingTop: 6,
                paddingBottom: 6,
                alignSelf: "center"
              },
              textStyle: {
                ...h16,
                color: primaryColor,
                textAlign: "center"
              },
              text: I18N.t("enterYourDetails")
            },
            {
              viewStyle: {
                paddingTop: 6,
                paddingLeft: 25,
                paddingRight: 25,
                alignSelf: "center"
              },
              textStyle: {
                ...h9_18,
                color: brownGrey,
                textAlign: "center"
              },
              text: I18N.t("introductionMessage")
            },
            {
              direction: "row",
              viewStyle: { paddingTop: 32 },
              render: [
                {
                  width: "1fr",
                  render: this.getInputComponent("firstName", { placeholder: I18N.t("enterFirstName"), maxLength: 25 })
                },
                {
                  width: "1fr",
                  viewStyle: {
                    paddingLeft: 26
                  },
                  render: this.getInputComponent("lastName", { placeholder: I18N.t("enterLastName"), maxLength: 25 })
                }
              ]
            },
            this.getInputComponent("email", {
              ...keyboardType.email,
              placeholder: I18N.t("enterEmailId"),
              maxLength: 55,
              onSubmitEditing: this.onAddClick
            }),
            {
              viewStyle: {
                marginTop: 30,
                marginBottom: 24,
                backgroundColor: themeBg,
                paddingTop: 14,
                paddingBottom: 14,
                height: 46,
                borderRadius: 2,
                alignItems: "center",
                justifyContent: "center"
              },
              textStyle: {
                ...h16_18,
                color: highlightColor
              },
              text: I18N.t("add"),
              onClick: this.onAddClick
            }
          ]}
        />
      </ScrollView>
    );
  }
}

IntroduceComponent = withContext(IntroduceComponent, {
  replaceUri: "Router.replaceUri",
  getPath: "Router.getPath",
  user: "User.user",
  activeMQ: "ActiveMQ",
  addUri: "Router.addUri"
});

export default {
  IntroduceYourself: {
    component: "table",
    Component: IntroduceComponent,
    title: () => I18N.t("groupIntroduce"),
    headerActions: [
      {
        type: "menu",
        visible: false
      }
    ]
  }
};
