import React from "react";
import {
  Box,
  AnimatedScrollView,
  getImage,
  theme,
  I18N,
  Platform,
  getAction,
  RowAction,
  getJsonComponent,
  showMessage,
  loadPicOfDay,
  MetadataTable,
  DecryptedImage
} from "../../../FsCloudComponent";
import UserStory from "./UserStory";
import HighlightDownloadCard from "./HighlightDownloadCard";
import HighlightUploadCard from "./HighlightUploadCard";
import HighlightSyncProgressCard from "./HighlightSyncProgressCard";
import ResourceActions from "../resource/ResourceActions";
import { memoryOfDayQuery } from "../queries";

const { colors, fonts, shadows, bgs } = theme;
const { primaryColor, brownGrey, themeColor, highlightColor } = colors;
const { h2, h2_l_18, h1_18, h16 } = fonts;
const { lightThemeBg } = bgs;

const navHeight = 60;

const containerStyle = {
  direction: "row",
  viewStyle: {
    minHeight: 135,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 12,
    marginRight: 12,
    borderRadius: 2,
    backgroundColor: highlightColor,
    ...shadows.cardShadow
  }
};

const actionStyle = {
  viewStyle: {
    width: 44,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  }
};

const highlights = [
  Platform.OS !== "web" && {
    title: "unlimitedSpaceOnYourDevice",
    description: "unlimitedSpaceOnYourDeviceDescription",
    buttonText: "freeUpCaps",
    image: "highlightFreeUpSpaceIcon",
    link: {
      uri: "/freeUpSpace"
    }
  },
  Platform.OS !== "web" && {
    title: "yourMemoriesSafeSound",
    description: "yourMemoriesSafeSoundDescription",
    buttonText: "backupCaps",
    image: "highlightBackUpIcon",
    imageContainerStyle: {
      viewStyle: { marginTop: 31 }
    },
    link: ({ isSyncProcessing } = {}) => {
      if (isSyncProcessing && isSyncProcessing()) {
        showMessage && showMessage(I18N.t("syncProcessingMessage"));
        return;
      }
      return {
        uri: "/upload-gallery"
      };
    }
  },
  {
    title: "JustForTheGroup",
    description: "JustForTheGroupDescription",
    buttonText: "startCaps",
    image: "highlightGroupIcon",
    imageContainerStyle: {
      viewStyle: { marginTop: 36 }
    },
    link: () => {
      let link = {
        uri: "/home#group"
      };
      if (Platform.OS !== "web") {
        link.when = new Date().getTime();
      }
      return link;
    },
    replace: true
  }
];
// eslint-disable-next-line
class PicOfDayCard extends React.Component {
  state = {};
  timerCount = 0;

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  loadData = async () => {
    let data = void 0;
    try {
      data = await loadPicOfDay();
      if (data || this.timerCount > 10) {
        this._setState({ data });
      } else {
        this.timeout = setTimeout(() => {
          this.timerCount++;
          clearTimeout(this.timeout);
          this.timeout = void 0;
          this.loadData();
        }, 1000);
      }
    } catch (err) {
      // do nothing.
    }
  };

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._unmounted = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = void 0;
    }
  }

  render() {
    if (!this.state.data) {
      return null;
    }
    const ConnectPanelTable = getJsonComponent("connectPanelTable");
    return (
      <ConnectPanelTable
        screenName="picOfTheDay"
        connectProps={{
          data: this.state.data,
          notifyOnLocalChanges: { key: MetadataTable }
        }}
        state={this.state}
        setState={this._setState}
        renderComponent={connectResult => {
          let { data } = connectResult;
          if (data && Array.isArray(data)) {
            data = data.length ? data[0] : void 0;
          }
          if (!data || data.discarded) {
            return null;
          }
          data.url = data.converted_jpeg_url || data.thumbnail_url;
          if (!data.url) {
            return null;
          }
          let isExported = data.exported;
          data.height = data.converted_height || data.thumbnail_width;
          data.width = data.converted_width || data.thumbnail_height;
          let imageContainerHeight = 106;
          let imageHeight = data.thumbnail_height;
          if (imageHeight > imageContainerHeight) {
            imageHeight = imageContainerHeight;
          }
          let imageProps = {
            source: {
              uri: data.thumbnail_url
            },
            data,
            style: { height: imageHeight }
          };
          if (Platform.OS === "web") {
            imageProps.style.objectFit = "contain";
          } else {
            imageProps.resizeMode = "contain";
          }
          return (
            <Box
              viewStyle={{
                height: 155,
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 12,
                marginRight: 12,
                borderRadius: 2,
                ...shadows.cardShadow,
                backgroundColor: highlightColor
              }}
              render={[
                {
                  viewStyle: {
                    margin: 8,
                    height: imageContainerHeight,
                    justifyContent: "center"
                  },
                  render: <DecryptedImage {...imageProps} />,
                  Container: getAction("link"),
                  containerProps: {
                    action: {
                      link: {
                        uri: `/gallery-detail`,
                        detailId: data._id,
                        origin: "gallery",
                        fromHighlight: "picOfDay",
                        props: { parentInfo: { _connectProps: { data: [data] } } }
                      }
                    }
                  }
                },
                {
                  direction: "row",
                  viewStyle: { paddingLeft: 4, paddingBottom: 8, paddingRight: 4 },
                  render: [
                    {
                      viewStyle: { paddingLeft: 4, paddingRight: 4, justifyContent: "center" },
                      image: getImage("highlightStarIcon")
                    },
                    {
                      width: "1fr",
                      viewStyle: { paddingLeft: 4, paddingRight: 4, justifyContent: "center" },
                      textStyle: { color: brownGrey, ...h1_18 },
                      text: I18N.t("photoOfTheDay")
                    },
                    !isExported && (
                      <RowAction data={data} action={ResourceActions.importPhotoToGallery}>
                        <Box {...actionStyle} image={getImage("highlightImportGreyIcon")} />
                      </RowAction>
                    ),
                    isExported && (
                      <RowAction
                        data={data}
                        action={[
                          ResourceActions.postToGroup,
                          ResourceActions.webShareGallery,
                          ResourceActions.nativeShareGallery,
                          ResourceActions.getLink,
                          ResourceActions.getLinkNative
                        ]}
                      >
                        <Box {...actionStyle} image={getImage("highlightShareGreyIcon")} />
                      </RowAction>
                    ),
                    <RowAction data={data} action={[ResourceActions.backToDay, ResourceActions.discardPictureOfDay]}>
                      <Box {...actionStyle} image={getImage("iconMenuGrey")} />
                    </RowAction>
                  ]
                }
              ]}
            />
          );
        }}
      />
    );
  }
}
// eslint-disable-next-line
class MemoryOfDay extends React.Component {
  state = {};

  _setState = state => {
    this.setState(state);
  };

  render() {
    const ConnectPanelTable = getJsonComponent("connectPanelTable");
    return (
      <ConnectPanelTable
        screenName="memoryOfTheDay"
        {...memoryOfDayQuery}
        state={this.state}
        setState={this._setState}
        renderComponent={connectResult => {
          let { data } = connectResult;
          if (data && Array.isArray(data)) {
            data = data.length ? data[0] : void 0;
          }
          if (!data) {
            return null;
          }
          data.url = data.converted_jpeg_url || data.thumbnail_url;
          if (!data.url) {
            return null;
          }
          data.height = data.converted_height || data.thumbnail_width;
          data.width = data.converted_width || data.thumbnail_height;
          let imageContainerHeight = 106;
          let imageHeight = data.thumbnail_height;
          if (imageHeight > imageContainerHeight) {
            imageHeight = imageContainerHeight;
          }
          let imageProps = {
            source: {
              uri: data.thumbnail_url
            },
            data,
            style: { height: imageHeight }
          };
          if (Platform.OS === "web") {
            imageProps.style.objectFit = "contain";
          } else {
            imageProps.resizeMode = "contain";
          }
          return (
            <Box
              viewStyle={{
                height: 155,
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 12,
                marginRight: 12,
                borderRadius: 2,
                ...shadows.cardShadow,
                backgroundColor: highlightColor
              }}
              render={[
                {
                  viewStyle: {
                    margin: 8,
                    height: imageContainerHeight,
                    justifyContent: "center"
                  },
                  render: <DecryptedImage {...imageProps} />,
                  Container: getAction("link"),
                  containerProps: {
                    action: {
                      link: {
                        uri: `/memories-of-the-day`
                      }
                    }
                  }
                },
                {
                  direction: "row",
                  viewStyle: { paddingLeft: 4, paddingBottom: 8, paddingRight: 4 },
                  render: [
                    {
                      viewStyle: { paddingLeft: 4, paddingRight: 4, justifyContent: "center" },
                      image: getImage("highlightStarIcon")
                    },
                    {
                      width: "1fr",
                      viewStyle: { paddingLeft: 4, paddingRight: 4, justifyContent: "center" },
                      textStyle: { color: brownGrey, ...h1_18 },
                      text: I18N.t("memoryOfTheDay")
                    },
                    <RowAction
                      data={data}
                      action={[
                        ResourceActions.postToGroup,
                        ResourceActions.webShareGallery,
                        ResourceActions.nativeShareGallery,
                        ResourceActions.getLink,
                        ResourceActions.getLinkNative
                      ]}
                    >
                      <Box {...actionStyle} image={getImage("highlightShareGreyIcon")} />
                    </RowAction>
                  ]
                }
              ]}
            />
          );
        }}
      />
    );
  }
}

export default class HighlightView extends React.Component {
  state = { height: 0 };

  getCardComponent = row => {
    if (!row) {
      return;
    }
    let { title, description, buttonText, link, image, imageContainerStyle, replace } = row;
    let boxProps = {};
    if (link) {
      boxProps.Container = getAction("link");
      boxProps.containerProps = {
        action: {
          link,
          replace
        }
      };
    }
    return (
      <Box
        key={title}
        {...containerStyle}
        Container={getAction("link")}
        {...boxProps}
        render={[
          {
            width: "1fr",
            viewStyle: { paddingLeft: 12, paddingTop: 12, paddingRight: 5, paddingBottom: 11 },
            render: [
              {
                height: "1fr",
                render: [
                  {
                    textStyle: { color: primaryColor, ...h16 },
                    text: I18N.t(title)
                  },
                  {
                    viewStyle: { paddingTop: 5 },
                    textStyle: { color: brownGrey, ...h2_l_18 },
                    text: I18N.t(description)
                  }
                ]
              },
              {
                viewStyle: {
                  backgroundColor: lightThemeBg,
                  borderRadius: 2,
                  marginTop: 8,
                  paddingRight: 14,
                  paddingLeft: 14,
                  paddingTop: 5,
                  paddingBottom: 5,
                  alignSelf: "flex-start"
                },
                textStyle: { color: themeColor, ...h2 },
                text: I18N.t(buttonText)
              }
            ]
          },
          image && { ...imageContainerStyle, image: getImage(image) }
        ]}
      />
    );
  };

  onLayout = ({
    nativeEvent: {
      layout: { height }
    }
  }) => {
    if (height !== this.state.height && Platform.OS !== "web") {
      this.setState({ height });
    }
  };

  render() {
    let { height } = this.state;
    let contentContainerStyle = { paddingBottom: 12 };
    if (height) {
      contentContainerStyle = { ...contentContainerStyle, paddingTop: navHeight, minHeight: height + navHeight };
    }
    let { onMomentumScrollBegin, onScroll, onScrollEndDrag, onMomentumScrollEnd } = this.props;
    return (
      <AnimatedScrollView
        style={{ flex: 1, paddingTop: 7 }}
        contentContainerStyle={contentContainerStyle}
        scrollEventThrottle={1}
        bounces={false}
        onScroll={onScroll}
        onScrollEndDrag={onScrollEndDrag}
        onMomentumScrollBegin={onMomentumScrollBegin}
        onMomentumScrollEnd={onMomentumScrollEnd}
        onLayout={this.onLayout}
      >
        <Box
          render={[
            <HighlightSyncProgressCard key="highlightProgressCard" />,
            <HighlightUploadCard key="highlightUploadCard" />,
            <HighlightDownloadCard key="highlightDownloadCard" />,
            <UserStory />,
            // <PicOfDayCard key="highlightPicOfDayCard" />,
            // <MemoryOfDay key="memoryOfTheDayCard" />,
            ...highlights.map(highlight => this.getCardComponent(highlight)),
            Platform.OS !== "web" && <Box viewStyle={{ height: 56 }} />
            // This extra height view was added after storyview was introduced. Had to remove swipeable=true from highlight component as it was absorbing scroll events and horizonal scroll wasnt available.
            // tabbar height = 56, hence 56 height is given
          ]}
        />
      </AnimatedScrollView>
    );
  }
}
