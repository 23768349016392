import React from "react";
import { View, StatusBar as LoadingIndicator, FSList, showMessage, withContext } from "../../../FsCloudComponent";
import { IssuesNoData } from "../noData/NoData";
import RenderItemView from "./RenderItemView";
import { getSupportIssueRef } from "./issuesUtility";

class SupportIssuesView extends React.Component {
  state = { data: [], loading: true, markdone: [] };

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  getData = async (lastResult, limit) => {
    try {
      let { invoke, urls, filter: filterProps = {} } = this.props;
      let filter = filterProps;
      limit = limit || 5;
      let result = await invoke({
        service: {
          url: urls["getAllReportedIssuesUrl"],
          uriProps: {
            data: { limit, filter, lastResult }
          }
        },
        allowParallelInvoke: true
      });
      result = result || [];
      this._setState({ data: [...this.state.data, ...result], loading: false });
      if (result.length === limit && !this._unmounted) {
        this.getData(result[result.length - 1], 200);
      }
    } catch (err) {
      let errMessage = err.message || err || "";
      showMessage && showMessage(errMessage, 2000);
      this._setState({ loading: false, error: errMessage });
    }
  };

  componentDidMount() {
    getSupportIssueRef && getSupportIssueRef(this);
    this.getData();
  }

  setInfo = ({ data = {} } = {}) => {
    let { issueId } = data;
    let dataAll = this.state.data;
    let elemIndex = issueId && dataAll.findIndex(value => value._id === issueId);
    if (elemIndex !== -1) {
      if (dataAll[elemIndex].count) {
        dataAll[elemIndex].count = dataAll[elemIndex].count + 1;
      } else {
        dataAll[elemIndex].count = 1;
      }
      this._setState({ data: dataAll });
    }
  };

  componentWillUnmount() {
    this._unmounted = true;
    getSupportIssueRef && getSupportIssueRef(void 0);
  }

  markDone = ({ id, index }) => {
    const updates = { loading: false };
    if (id) {
      let dataAll = this.state.data;
      let elemIndex = index;
      if (!elemIndex) {
        elemIndex = dataAll.findIndex(value => value._id === id);
      }
      if (dataAll[elemIndex] && elemIndex !== -1) {
        dataAll[elemIndex] = { ...dataAll[elemIndex], status: "done" };
        updates.data = [...dataAll];
      }
    }
    this._setState(updates);
  };

  renderItem = ({ item, index }) => {
    return <RenderItemView item={item} index={index} markDone={this.markDone} {...this.props} />;
  };
  getSelectedIds = () => {
    return [];
  };
  render() {
    let { data = [], loading } = this.state;
    let showEmpty = !loading && !data.length;
    let showLoading = loading && !data.length;
    return (
      <View
        style={{
          flex: 1,
          paddingTop: 10,
          paddingBottom: 12,
          backgroundColor: "#fafafa"
        }}
      >
        {showEmpty ? (
          <IssuesNoData />
        ) : (
          [
            <FSList
              style={{ flex: 1 }}
              data={data}
              renderItem={this.renderItem}
              getSelectedIds={this.getSelectedIds}
            />,
            showLoading ? <LoadingIndicator /> : null
          ]
        )}
      </View>
    );
  }
}

SupportIssuesView = withContext(SupportIssuesView, {
  urls: "App.urls",
  invoke: "App.invoke",
  addUri: "Router.addUri",
  getPath: "Router.getPath"
});

export default {
  OpenSupportIssues: {
    component: "custom",
    Component: () => <SupportIssuesView filter={{ status: "open" }} />
  },
  FsSupportIssues: {
    component: "custom",
    Component: () => <SupportIssuesView filter={{ owner: "fs" }} />
  },
  InProgressSupportIssues: {
    component: "custom",
    Component: () => <SupportIssuesView filter={{ status: "in_progress" }} />
  },
  ServerSupportIssues: {
    component: "custom",
    Component: () => <SupportIssuesView filter={{ owner: "server" }} />
  },
  MobileSupportIssues: {
    component: "custom",
    Component: () => <SupportIssuesView filter={{ owner: "mobile" }} />
  },
  ResolvedSupportIssues: {
    component: "custom",
    Component: () => <SupportIssuesView filter={{ status: "done" }} />
  }
};
