import React from "react";
import {
  Box,
  getImage,
  theme,
  I18N,
  ImageBackground,
  dataFormat,
  RowAction,
  Platform,
  getModeText,
  DecryptedImage
} from "../../../FsCloudComponent";
import CollectionActions from "../collection/CollectionActions";
import { getDocExtIcon } from "../../../components/collection/CollectionUtility";

const { numberFormatter } = dataFormat;
let { colors, fonts, shadows } = theme;
let { h1_16, h2_l_18, h9_18, h20_24 } = fonts;
let { brownGrey, primaryColor, highlightColor, whiteTwo } = colors;

const getCoverImageDecryptionProps = (data = {}) => {
  let coverData = { ...data.coverImage, collection: { _id: data._id } };
  return {
    data: coverData,
    source: { uri: coverData.thumbnail_url },
    decryptionSourceProps: { decryptionSource: "collection" }
  };
};

class SharedSMComponent extends React.Component {
  render() {
    let { data } = this.props;
    let actions;
    let is_album = data && data.type === "album";
    if (is_album) {
      actions = [
        CollectionActions.manageCollabration,
        CollectionActions.makePrivateAlbum,
        CollectionActions.share,
        CollectionActions.shareNative,
        CollectionActions.getLinkNative,
        CollectionActions.addItemsToAlbumLink,
        CollectionActions.setCoverLink,
        CollectionActions.renameAlbum,
        CollectionActions.markDeleteAlbum
      ];
    } else {
      actions = [
        CollectionActions.manageCollabration,
        CollectionActions.makePrivateSet,
        CollectionActions.share,
        CollectionActions.shareNative,
        CollectionActions.getLinkNative,
        CollectionActions.addItemsToSetLink,
        CollectionActions.renameSet,
        CollectionActions.markDeleteSet
      ];
    }
    let imageStyle = {
      width: 50,
      height: 50,
      borderRadius: 25
    };
    if (Platform.OS === "web") {
      imageStyle.objectFit = "cover";
    }
    return (
      <Box
        render={[
          {
            direction: "row",
            Container: RowAction,
            containerProps: {
              data,
              action: is_album ? CollectionActions.albumDetailLink : CollectionActions.setDetailLink
            },
            viewStyle: {
              backgroundColor: highlightColor,
              marginLeft: 12,
              marginRight: 12,
              marginTop: 5,
              marginBottom: 5,
              borderRadius: 4,
              ...shadows.cardShadow,
              height: 74,
              alignItems: "center"
            },
            render: [
              !is_album && {
                viewStyle: { paddingLeft: 12, paddingRight: 24 },
                render: (
                  <ImageBackground
                    style={{
                      height: 50,
                      width: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                    imageStyle={{ resizeMode: "contain" }}
                    source={getImage("docsImageBackground")}
                  >
                    <Box image={getDocExtIcon({ data, getImage })} />
                  </ImageBackground>
                )
              },
              is_album && {
                viewStyle: {
                  marginLeft: 12,
                  marginRight: 24,
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: whiteTwo
                },
                render: (
                  <DecryptedImage {...getCoverImageDecryptionProps(data)} resizeMode={"cover"} style={imageStyle} />
                )
              },
              {
                width: "1fr",
                render: [
                  {
                    textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1, whiteSpace: "pre" },
                    text: data.name
                  },
                  {
                    direction: "row",
                    textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                    viewStyle: { paddingTop: 4 },
                    render: [
                      {
                        direction: "row",
                        render: [
                          {
                            text: numberFormatter(data.itemCount, "0.0i")
                          },
                          { viewStyle: { paddingLeft: 2 }, text: I18N.t("items") }
                        ]
                      },
                      {
                        viewStyle: { paddingLeft: 3, paddingRight: 3 },
                        text: "|"
                      },
                      {
                        width: "1fr",
                        text: getModeText(data)
                      }
                    ]
                  }
                ]
              },
              {
                viewStyle: {
                  paddingLeft: 8,
                  paddingRight: 8,
                  alignItems: "center",
                  justifyContent: "center",
                  height: 44,
                  width: 44
                },
                image: getImage("iconMenuGrey"),
                Container: RowAction,
                containerProps: {
                  data,
                  action: actions
                }
              }
            ]
          }
        ]}
      />
    );
  }
}

class SharedMDComponent extends React.Component {
  render() {
    let { data } = this.props;
    let actions;
    let is_album = data && data.type === "album";
    if (is_album) {
      actions = [
        CollectionActions.manageCollabrationWithGreyImage,
        CollectionActions.makePrivateAlbumWithGreyImage,
        CollectionActions.shareWithGreyImage,
        CollectionActions.addItemsToAlbumFormWithGreyImage,
        CollectionActions.setCoverWithGreyImage,
        CollectionActions.renameAlbumWithGreyImage,
        CollectionActions.markDeleteAlbumWithGreyImage
      ];
    } else {
      actions = [
        CollectionActions.manageCollabrationWithGreyImage,
        CollectionActions.makePrivateSetWithGreyImage,
        CollectionActions.shareWithGreyImage,
        CollectionActions.addItemsToSetFormWithGreyImage,
        CollectionActions.renameSetWithGreyImage,
        CollectionActions.markDeleteSetWithGreyImage
      ];
    }
    return is_album ? (
      <DecryptedImage
        {...getCoverImageDecryptionProps(data)}
        renderComponent={({ source }) => {
          return (
            <Box
              Container={RowAction}
              containerProps={{
                data,
                action: CollectionActions.albumDetailLink
              }}
              viewStyle={{
                margin: 15,
                borderRadius: 4,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat"
              }}
              backgroundImage={source}
              render={[
                {
                  viewStyle: {
                    height: 98,
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    cursor: "pointer"
                  },
                  direction: "row",
                  render: [
                    {
                      width: "1fr",
                      viewStyle: { paddingLeft: 16, paddingRight: 16 },
                      render: [
                        {
                          direction: "row",
                          render: [
                            {
                              viewStyle: { flex: 1, paddingBottom: 8 },
                              textStyle: {
                                ...h20_24,
                                color: highlightColor,
                                numberOfLines: 1,
                                whiteSpace: "pre"
                              },
                              text: data.name
                            },
                            {
                              viewStyle: {
                                height: 24,
                                width: 24,
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer"
                              },
                              image: getImage("iconMenuWhite"),
                              Container: RowAction,
                              containerProps: {
                                data,
                                action: actions
                              }
                            }
                          ]
                        },
                        {
                          direction: "row",
                          textStyle: {
                            ...h9_18,
                            color: highlightColor,
                            numberOfLines: 1
                          },
                          render: [
                            {
                              direction: "row",
                              render: [
                                {
                                  text: numberFormatter(data.itemCount, "0.0i")
                                },
                                { viewStyle: { paddingLeft: 2 }, text: I18N.t("items") }
                              ]
                            },
                            {
                              text: "|",
                              viewStyle: { paddingLeft: 3, paddingRight: 3 }
                            },
                            {
                              width: "1fr",
                              text: getModeText(data)
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]}
            />
          );
        }}
      />
    ) : (
      <Box
        Container={RowAction}
        containerProps={{
          data,
          action: CollectionActions.setDetailLink
        }}
        viewStyle={{
          margin: 15,
          borderRadius: 4
        }}
        render={[
          {
            viewStyle: {
              height: 98,
              alignItems: "center",
              ...shadows.cardShadow,
              backgroundColor: highlightColor,
              cursor: "pointer"
            },
            direction: "row",
            render: [
              {
                viewStyle: { paddingLeft: 16, paddingRight: 16 },
                image: getDocExtIcon({ data, getImage })
              },
              {
                width: "1fr",
                viewStyle: { paddingLeft: 12, paddingRight: 16 },
                render: [
                  {
                    direction: "row",
                    render: [
                      {
                        viewStyle: { flex: 1, paddingBottom: 8 },
                        textStyle: {
                          ...h20_24,
                          color: primaryColor,
                          numberOfLines: 1,
                          whiteSpace: "pre"
                        },
                        text: data.name
                      },
                      {
                        viewStyle: {
                          height: 24,
                          width: 24,
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer"
                        },
                        image: getImage("iconMenuGrey"),
                        Container: RowAction,
                        containerProps: {
                          data,
                          action: actions
                        }
                      }
                    ]
                  },
                  {
                    direction: "row",
                    textStyle: {
                      ...h9_18,
                      color: brownGrey,
                      numberOfLines: 1
                    },
                    render: [
                      {
                        direction: "row",
                        render: [
                          {
                            text: numberFormatter(data.itemCount, "0.0i")
                          },
                          { viewStyle: { paddingLeft: 2 }, text: I18N.t("items") }
                        ]
                      },
                      {
                        text: "|",
                        viewStyle: { paddingLeft: 3, paddingRight: 3 }
                      },
                      {
                        width: "1fr",
                        text: getModeText(data)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]}
      />
    );
  }
}

export { SharedSMComponent, SharedMDComponent };
