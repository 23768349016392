import React from "react";
import { commonLayout } from "../../layouts";
import { theme, I18N, getSizeFormat } from "../../../FsCloudComponent";
import { downloadQueueQuery } from "../queries";
import { DownloadQueueNoData } from "../noData/NoData";

const { fonts, colors } = theme;
const { h1_16, h2_l_18 } = fonts;
const { primaryColor, brownGrey } = colors;

const DownloadQueueCard = commonLayout({
  SM: {
    render: [
      {
        direction: "row",
        viewStyle: {
          height: 74,
          alignItems: "center"
        },
        render: [
          {
            viewStyle: { paddingLeft: 12, paddingRight: 16 },
            value: ({ data }) => {
              return {
                field: "name",
                type: "imageDoc",
                componentName: data.type === "audio" ? "musicImage" : void 0
              };
            }
          },
          {
            width: "1fr",
            render: [
              {
                textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1, whiteSpace: "pre" },
                value: { field: "name" }
              },
              {
                direction: "row",
                textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                viewStyle: { paddingTop: 4 },
                separator: "|",
                render: [
                  {
                    value: ({ data }) => {
                      return { text: getSizeFormat(data.resource_size) };
                    }
                  },
                  {
                    value: ({ data }) => {
                      let { type } = data || {};
                      return { text: I18N.t(type) || I18N.t("Unknown") };
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
});

export default {
  DownloadQueue: {
    component: "table",
    renderNoData: <DownloadQueueNoData />,
    dataObject: "Resource",
    ...downloadQueueQuery,
    selectionMode: true,
    rowProps: {
      ...DownloadQueueCard
    },
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        action: {
          text: () => I18N.t("cancel"),
          source: "download",
          type: "cancelQueuedItem"
        }
      },
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: ["uploadSelectAll", "selectNone"]
      }
    ]
  }
};
