import React from "react";

export default ({ Box, resolveValue, getInput, inputType = "file", ValueComponent, theme = {} }) => {
  class MultiFileBox extends React.Component {
    onChangeValue = _value => {
      if (!_value) {
        return;
      }
      if (!Array.isArray(_value)) {
        _value = [_value];
      }
      const { data, setValue, field } = this.props;
      let value = resolveValue(data, field) || [];
      value = [...value, ..._value];
      setValue && setValue({ data, field, value });
    };

    onRemove = ({ index }) => {
      const { data, field, setValue } = this.props;
      let value = resolveValue(data, field);
      if (value) {
        value = value.filter((item, itemIndex) => index !== itemIndex);
        setValue && setValue({ data, field, value });
      }
    };

    renderData = () => {
      const { data, field, onItemClick, editable } = this.props;
      const values = resolveValue(data, field) || [];
      return values.map((file, index) => {
        return (
          <ValueComponent
            value={file}
            onRemove={() => {
              this.onRemove({ index });
            }}
            onClick={
              onItemClick
                ? () => {
                    onItemClick({ item: file, index });
                  }
                : void 0
            }
            editable={editable}
            theme={theme.renderValueStyle}
          />
        );
      });
    };

    render() {
      let { editable } = this.props;
      let Input = getInput && getInput(inputType);
      return (
        <Box
          {...theme.boxStyle}
          render={[
            editable !== false && Input && <Input {...this.props} onChangeValue={this.onChangeValue} multiple />,
            { ...theme.valueContainerStyle, render: this.renderData() }
          ]}
        />
      );
    }
  }
  return MultiFileBox;
};
