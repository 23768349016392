import React from "react";
import { withContext } from "../../../FsCloudComponent";

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    let { isDetailView } = props;
    isDetailView && isDetailView(true);
  }

  componentDidMount() {
    const { addChangeSongListener, data, currentId, onPlayMusic } = this.props;
    addChangeSongListener && addChangeSongListener(this.onChangeSong);
    currentId &&
      onPlayMusic &&
      onPlayMusic({
        playlist: data,
        currentTrackId: currentId
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, currentId } = nextProps;
    if (currentId && this.props.currentId !== currentId) {
      const { onPlayMusic } = this.props;
      onPlayMusic &&
        onPlayMusic(
          {
            playlist: data,
            currentTrackId: currentId
          },
          true
        );
    }
  }

  onChangeSong = ({ data }) => {
    if (data) {
      let { onChangeSong } = this.props;
      onChangeSong && onChangeSong(data._id);
    }
  };

  componentWillUnmount() {
    const { removeChangeSongListener, isDetailView } = this.props;
    isDetailView && isDetailView(false);
    removeChangeSongListener && removeChangeSongListener(this.onChangeSong);
  }

  render() {
    return null;
  }
}

AudioPlayer = withContext(AudioPlayer, {
  onPlayMusic: "MusicPlayer.onPlayMusic",
  isDetailView: "MusicPlayer.isDetailView",
  removeChangeSongListener: "MusicPlayer.removeChangeSongListener",
  addChangeSongListener: "MusicPlayer.addChangeSongListener"
});

export default AudioPlayer;
