/**
 * TODO Review routeStateKey work
 *  */
import React from "react";
import RouterContext from "./RouterContext";

export default ({ renderProps, routeStateKey = "Router" }) => {
  class Router extends React.Component {
    state = {};

    constructor(props) {
      super(props);
      const { addUri, deleteUri, replaceUri, getParentInfo } = props;
      this.contextProviderValue = {
        addUri,
        deleteUri,
        replaceUri,
        getParentInfo,
        getPath: this.getPath
      };
    }

    getPath = () => {
      return this.props.path;
    };

    withoutHashUrl = url => {
      const splitted = url.split("/");

      let withHashUrl = ``;
      splitted.forEach(uri => {
        if (uri.trim().length === 0) {
          return;
        }

        uri = "/" + uri;

        let indexOfHash = uri.indexOf("#");
        if (indexOfHash > 0) {
          uri = uri.substring(0, indexOfHash);
        }
        withHashUrl = `${withHashUrl}${uri}`;
      });
      return withHashUrl;
    };

    onRouteError = () => {
      let { replaceUri, landingUrl, user, activeMQ } = this.props;
      let replaceLink = landingUrl({ user, path: "/", activeMQ });
      replaceUri && replaceUri(replaceLink);
    };

    getScreens() {
      const { uris, getRoute, user, activeMQ, isPendingBlocker } = this.props;
      const components = [];
      uris.forEach((_uri, index) => {
        const { url, link, state, setState } = _uri;
        const { uri } = link;

        const routeInfo = {
          url,
          link,
          index: components.length,
          isPendingBlocker
        };

        state[routeStateKey] = routeInfo;

        const route = getRoute({ user, url, link, state, activeMQ, onRouteError: this.onRouteError });
        if (!route || !route.component) {
          console.warn(`No route or component found for uri ${uri}`);
          return;
        }
        let { component, ...restProps } = route;
        let componentKey = this.withoutHashUrl(url);
        let componentProps = {
          key: componentKey,
          state,
          setState,
          route: routeInfo
        };
        component = React.cloneElement(component, componentProps);
        components.push({
          component,
          componentKey,
          ...restProps
        });
      });
      return {
        components
      };
    }

    render() {
      let { path } = this.props;
      const { components } = this.getScreens();
      return (
        <RouterContext.Provider value={this.contextProviderValue}>
          {renderProps(this.props, {
            components,
            path
          })}
        </RouterContext.Provider>
      );
    }
  }
  return Router;
};
