import React from "react";
import { getSizeFormat } from "client-utility/lib/FileUtility";

import {
  I18N,
  View,
  theme,
  Box,
  getImage,
  StatusBar as LoadingIndicator,
  RowAction,
  Text,
  showMessage
} from "../../../FsCloudComponent";
import FormComponent from "./FormComponent";
import FileViewerComponent from "./FileViewerComponent";
import { dateModifier } from "./issuesUtility";

const { fonts, colors, shadows } = theme;
const { highlightColor, themeColor } = colors;
const { h16_l, h9, h1_16 } = fonts;
let { cardShadow } = shadows;

let style = {
  componentStyle: {
    headingTextStyle: { color: "#5c5c5c", ...h9 },
    textStyle: { userSelect: "text", color: "#a7a7a7", ...h9 },
    viewStyle: {
      minWidth: 300,
      flexDirection: "row",
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  buttonStyle: {
    viewStyle: {
      padding: 5,
      margin: 5,
      backgroundColor: themeColor,
      borderRadius: 5,
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center"
    },
    textStyle: {
      ...h9,
      color: "#ffffff"
    }
  }
};

const ENC_MAP = {
  DONE: "completed",
  SKIPPED: "skip",
  IN_PROCESS: "enc_processing",
  ERROR: "completed",
  PENDING: "pending",
  ENC_PENDING: "completed"
};
class SmoothScrollView extends React.Component {
  componentDidMount() {
    // Scroll Behaviour
    const { parentRef } = this.props;
    if (parentRef) {
      const params = { behavior: "smooth" };
      if (parentRef.scrollIntoView) {
        parentRef.scrollIntoView(params);
      } else if (parentRef.current && parentRef.current.scrollIntoView) {
        parentRef.current.scrollIntoView(params);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default class RenderItemView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { item: props.item };
  }

  fsMigrationStatus = async () => {
    try {
      const { item } = this.state;
      const { _createdBy: { extref = void 0 } = {} } = item;
      if (!extref) {
        return;
      }
      const { invoke, urls } = this.props;
      this.setState({ refreshLoading: true });
      let result = await invoke({
        service: {
          url: urls["fsMigrationStatusUrl"],
          uriProps: { data: { extref } }
        }
      });
      this.setState({ refreshLoading: false });
      if (result) {
        this.setState({ fsMigrationStatusInfo: result });
      }
    } catch (err) {
      this.setState({ refreshLoading: false });
      let errMessage = err.message || err || "somethingWentWrong";
      showMessage && showMessage(errMessage, 2000);
    }
  };

  fsMigrationProcess = async () => {
    try {
      const { item } = this.state;
      let { mobile = void 0 } = item;
      if (!mobile) {
        return;
      }
      if (mobile && mobile.includes("+")) {
        mobile = mobile.substring(1);
      }
      const { invoke, urls } = this.props;
      this.setState({ refreshLoading: true });
      const result = await invoke({
        service: {
          url: urls["fsMigrationProcessUrl"],
          uriProps: { data: { mobile } }
        }
      });
      this.setState({ refreshLoading: false });
      if (result) {
        this.setState({ fsMigrationProcessInfo: result });
      }
    } catch (err) {
      this.setState({ refreshLoading: false });
      let errMessage = err.message || err || "somethingWentWrong";
      showMessage && showMessage(errMessage, 2000);
    }
  };

  refresh = async () => {
    try {
      const { item } = this.state;
      const issueId = item._id;
      const { invoke, urls } = this.props;
      this.setState({ refreshLoading: true });
      let result = await invoke({
        service: {
          url: urls["refreshStatsUrl"],
          uriProps: { data: { issueId } }
        }
      });
      this.setState({ refreshLoading: false });
      if (result) {
        this.setState({ item: { ...item, ...result } });
      }
    } catch (err) {
      this.setState({ refreshLoading: false });
      let errMessage = err.message || err || "";
      showMessage && showMessage(errMessage, 2000);
    }
  };

  onClick = () => {
    const { addUri, getPath, item: { _id: issueId } = {} } = this.props;
    addUri &&
      addUri(getPath(), {
        uri: "/comments",
        props: { filter: { _id: issueId } }
      });
  };

  userInfoComponent = () => {
    const { item } = this.state;
    let {
      componentStyle: { viewStyle, headingTextStyle, textStyle }
    } = style;
    const {
      mobile,
      firstName,
      lastName,
      email,
      _createdBy: { extref = void 0 } = {},
      userEncryptionInfo: { encryption_status, newEncrypted, oldEncrypted } = {}
    } = item || {};
    const totalEncrypted = Number(newEncrypted) + Number(oldEncrypted) || 0;
    return (
      <Box
        direction="row"
        viewStyle={{ flexWrap: "wrap" }}
        render={[
          {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `Extref: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: extref || "-" }
            ]
          },
          {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: () => `${I18N.t("firstName")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: firstName || "-" }
            ]
          },
          lastName && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: () => `${I18N.t("lastName")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: lastName }
            ]
          },
          {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: () => `${I18N.t("Email")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: email || "-" }
            ]
          },
          mobile && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: () => `${I18N.t("mobile")} :` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: mobile }
            ]
          },
          ENC_MAP[encryption_status] && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: () => `${I18N.t("encryption_status")} :` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: I18N.t(ENC_MAP[encryption_status]) }
            ]
          },
          ENC_MAP[encryption_status] &&
            encryption_status !== "SKIPPED" &&
            totalEncrypted && {
              viewStyle,
              render: [
                { textStyle: headingTextStyle, text: () => `${I18N.t("encryption_count")} :` },
                { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
                { textStyle, text: totalEncrypted }
              ]
            }
        ]}
      />
    );
  };

  spaceInfoComponent = () => {
    const { item } = this.state;
    const { totalSpace, usedSpace, freeSpace, trashSpace } = item || {};
    const { componentStyle: { viewStyle, headingTextStyle, textStyle } = {} } = style;
    return (
      <Box
        direction="row"
        viewStyle={{ flexWrap: "wrap" }}
        render={[
          totalSpace && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `${I18N.t("totalSpace")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              {
                textStyle,
                text: getSizeFormat(totalSpace || 0)
              }
            ]
          },
          usedSpace && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `${I18N.t("usedSpace")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              {
                textStyle,
                text: getSizeFormat(usedSpace || 0)
              }
            ]
          },
          freeSpace && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `${I18N.t("freeSpace")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              {
                textStyle,
                text: getSizeFormat(freeSpace || 0)
              }
            ]
          },
          trashSpace && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `${I18N.t("trashSpace")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              {
                textStyle,
                text: getSizeFormat(trashSpace || 0)
              }
            ]
          }
        ]}
      />
    );
  };

  migrationInfo = () => {
    const { item } = this.state;
    const { fsTotalFiles, fsMigratedFiles, migrated, migratedOn } = item || {};
    const { componentStyle: { viewStyle, headingTextStyle, textStyle } = {} } = style;
    return (
      <Box
        direction="row"
        viewStyle={{ flexWrap: "wrap" }}
        render={[
          {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `${I18N.t("migrated")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: migrated ? "true" : "false" }
            ]
          },
          fsTotalFiles && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `FS ${I18N.t("totalFiles")}` },
              { viewStyle: { paddingLeft: 5, paddingRight: 5 } },
              { textStyle, text: fsTotalFiles }
            ]
          },
          fsMigratedFiles && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `FS ${I18N.t("migratedFiles")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              { textStyle, text: fsMigratedFiles }
            ]
          },
          migratedOn && {
            viewStyle,
            render: [
              { textStyle: headingTextStyle, text: `${I18N.t("migratedOn")}: ` },
              { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
              this.dateComponent({ createdOn: migratedOn })
            ]
          }
        ]}
      />
    );
  };

  statsInfoComponent = () => {
    const { item } = this.state;
    const { userQuota } = item || {};
    const { componentStyle: { viewStyle, headingTextStyle, textStyle } = {} } = style;
    const { counts: { audio, doc, image, video, trashed_audio, trashed_doc, trashed_image, trashed_video } = {} } =
      userQuota || {};
    const totalTrashed = Number(trashed_audio) + Number(trashed_doc) + Number(trashed_image) + Number(trashed_video);
    return (
      <Box
        render={[
          {
            direction: "row",
            viewStyle: {
              flexWrap: "wrap"
            },
            render: [
              {
                viewStyle,
                render: [
                  { textStyle: headingTextStyle, text: `${I18N.t("image")} ${I18N.t("total")}: ` },
                  { viewStyle: { paddingLeft: 5, paddingRight: 5 } },
                  { textStyle, text: "" + (Number(image) || 0) }
                ]
              },
              {
                viewStyle,
                render: [
                  { textStyle: headingTextStyle, text: `${I18N.t("video")} ${I18N.t("total")}: ` },
                  { viewStyle: { paddingLeft: 5, paddingRight: 5 } },
                  { textStyle, text: "" + (Number(video) || 0) }
                ]
              },
              {
                viewStyle,
                render: [
                  { textStyle: headingTextStyle, text: `${I18N.t("doc")} ${I18N.t("total")}: ` },
                  { viewStyle: { paddingLeft: 5, paddingRight: 5 } },
                  { textStyle, text: "" + (Number(doc) || 0) }
                ]
              },
              {
                viewStyle,
                render: [
                  { textStyle: headingTextStyle, text: `${I18N.t("audio")} ${I18N.t("total")}: ` },
                  { viewStyle: { paddingLeft: 5, paddingRight: 5 } },
                  { textStyle, text: "" + (Number(audio) || 0) }
                ]
              }
            ]
          },
          {
            direction: "row",
            viewStyle: {
              flexWrap: "wrap"
            },
            render: [
              {
                viewStyle,
                render: [
                  { textStyle: headingTextStyle, text: `${I18N.t("deleted")} ${I18N.t("total")}: ` },
                  { viewStyle: { paddingLeft: 5, paddingRight: 5 } },
                  { textStyle, text: "" + (Number(totalTrashed) || 0) }
                ]
              }
            ]
          }
        ]}
      />
    );
  };

  editableForm = () => {
    const { item } = this.state;
    const { devFeedback, owner, status } = item || {};
    const { componentStyle: { viewStyle, headingTextStyle, textStyle } = {} } = style;
    return (
      <Box
        render={[
          devFeedback && {
            width: "1fr",
            viewStyle: { paddingRight: 5, paddingTop: 5, paddingBottom: 5 },
            render: [
              { textStyle: headingTextStyle, text: `${I18N.t("devFeedback")}: ` },
              { width: "1fr", textStyle: { ...textStyle }, text: devFeedback }
            ]
          },
          {
            direction: "row",
            viewStyle: { flexWrap: "wrap" },
            render: [
              owner && {
                viewStyle,
                render: [
                  { textStyle: headingTextStyle, text: `${I18N.t("owner")}: ` },
                  { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
                  {
                    textStyle,
                    text: owner
                  }
                ]
              },
              status && {
                viewStyle,
                render: [
                  { textStyle: headingTextStyle, text: `${I18N.t("status")}: ` },
                  { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
                  {
                    textStyle,
                    width: "1fr",
                    text: status
                  }
                ]
              }
            ]
          }
        ]}
      />
    );
  };

  expand = () => {
    this.setState({ expand: !this.state.expand });
  };

  updatedResult = result => {
    let { item } = this.state;
    let { devFeedback, owner, status, issue_eng } = result || {};
    item.devFeedback = devFeedback || item.devFeedback || void 0;
    item.owner = owner || item.owner || void 0;
    item.status = status || item.status || void 0;
    item.issue_eng = issue_eng || item.issue_eng || void 0;
    this.setState({ item: { ...item } });
  };

  onClickFormData = () => {
    this.setState({ formModal: true });
  };

  dateComponent = ({ createdOn }) => {
    if (createdOn) {
      let data = dateModifier && dateModifier({ date: createdOn });
      let { date, time } = data || {};
      let dateText = `${date} - ${time}`;
      return <Box textStyle={{ userSelect: "text", color: "#a7a7a7", ...h9 }} text={dateText} />;
    }
  };

  statusComponent = ({ status }) => {
    if (status === "done") {
      return (
        <Box
          viewStyle={{
            alignItems: "center",
            opacity: 0.8,
            justifyContent: "center",
            backgroundColor: "#99cb6b",
            padding: 5,
            borderRadius: 3
          }}
          render={[{ textStyle: { ...h9, color: "#ffffff" }, text: () => I18N.t("resolved") }]}
        />
      );
    }
  };

  render() {
    const { index } = this.props;
    const { item, expand, formModal, refreshLoading, fsMigrationStatusInfo, fsMigrationProcessInfo } = this.state;
    const {
      _id: issueId,
      _createdOn,
      _updatedOn,
      issue_number,
      title,
      issue_eng,
      description,
      count: commentCounts,
      status: issueStatus,
      mobile,
      _createdBy: { extref = void 0 } = {},
      attachments = []
    } = item || {};

    return (
      <View getRef={_ => (this.ref = _)}>
        <Box
          key={issueId + _updatedOn}
          viewStyle={{
            marginLeft: 12,
            marginRight: 12,
            marginTop: 12,
            backgroundColor: highlightColor,
            padding: 12,
            ...cardShadow
          }}
          render={[
            {
              viewStyle: { paddingBottom: 5 },
              textStyle: {
                color: "#5c5c5c",
                ...h1_16,
                userSelect: "text"
              },
              text: `${I18N.t("issueNumber")} : ${issue_number} ${I18N.t("title")} : ${title}`
            },
            {
              viewStyle: { paddingBottom: 5 },
              textStyle: {
                ...h9,
                color: "#a7a7a7",
                userSelect: "text"
              },
              text: issue_eng
            },
            {
              textStyle: {
                color: "#5c5c5c",
                ...h16_l,
                userSelect: "text"
              },
              text: description
            },
            {
              direction: "row",
              viewStyle: {
                paddingTop: 5,
                paddingBottom: 5,
                alignItems: "center"
              },
              render: [
                {
                  viewStyle: { flex: 1 },
                  render: [
                    {
                      textStyle: { color: "#a7a7a7", ...h9 },
                      text: `${I18N.t("totalComment")}:${Number(commentCounts) || 0}`
                    },
                    this.dateComponent({ createdOn: _createdOn })
                  ]
                },
                issueStatus === "done" ? (
                  {
                    viewStyle: { marginLeft: 5 },
                    render: this.statusComponent({ status: issueStatus })
                  }
                ) : (
                  <RowAction
                    data={item}
                    action={{
                      type: "invoke",
                      service: (props, { urls }) => {
                        const commandToPost = {
                          url: urls["markDoneIssueUrl"],
                          uriProps: {
                            data: { _id: issueId }
                          }
                        };
                        return commandToPost;
                      },
                      postMessage: () => {
                        this.setState({ item: { ...item, status: "done" } });
                        this.props.markDone({ id: issueId, index });
                        return I18N.t("markDoneSuccessMessage");
                      },
                      text: () => I18N.t("mark_done"),
                      title: () => I18N.t("mark_done"),
                      visible: true,
                      confirm: {
                        titleMD: () => I18N.t("mark_donePopupHeaderCaps"),
                        confirmText: () => I18N.t("mark_done"),
                        cancelText: () => I18N.t("cancel"),
                        message: () => I18N.t("markDonePopupMessage")
                      }
                    }}
                  >
                    <View
                      style={{
                        padding: 5,
                        opacity: 0.8,
                        backgroundColor: "grey",
                        borderRadius: 5,
                        cursor: "pointer"
                      }}
                    >
                      <Text style={{ ...h9, color: "#ffffff" }}>{I18N.t("mark_done")}</Text>
                    </View>
                  </RowAction>
                )
              ]
            },
            attachments.length && {
              direction: "row",
              viewStyle: {
                paddingTop: 5,
                paddingBottom: 5
              },
              render: [
                {
                  image: getImage("linkGreyIcon")
                },
                { width: "1fr", render: <FileViewerComponent data={item} /> }
              ]
            },
            {
              viewStyle: {
                padding: 5,
                alignSelf: "flex-start",
                backgroundColor: themeColor,
                marginBottom: 5,
                borderRadius: 5,
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center"
              },
              textStyle: {
                ...h9,
                color: "#ffffff"
              },
              text: I18N.t(expand ? "less" : "more"),
              onClick: this.expand
            }
          ]}
        />
        {expand && (
          <SmoothScrollView parentRef={this.ref}>
            <View>
              {refreshLoading ? <LoadingIndicator /> : null}
              <Box
                key={issueId}
                viewStyle={{
                  marginLeft: 12,
                  marginRight: 12,
                  backgroundColor: highlightColor,
                  padding: 12,
                  ...cardShadow
                }}
                render={[
                  this.userInfoComponent(),
                  this.editableForm(),
                  this.spaceInfoComponent(),
                  this.migrationInfo(),
                  this.statsInfoComponent(),
                  fsMigrationStatusInfo && {
                    direction: "row",
                    viewStyle: {
                      flex: 1,
                      paddingTop: 5,
                      paddingBottom: 5,
                      flexWrap: "wrap"
                    },
                    render: [
                      { textStyle: { color: "#5c5c5c", ...h9 }, text: `FS ${I18N.t("migrationStatusInfo")}` },
                      { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
                      {
                        width: "1fr",
                        textStyle: { userSelect: "text", color: "#a7a7a7", ...h9 },
                        text: fsMigrationStatusInfo
                      }
                    ]
                  },
                  fsMigrationProcessInfo && {
                    direction: "row",
                    viewStyle: {
                      flex: 1,
                      paddingTop: 5,
                      paddingBottom: 5,
                      flexWrap: "wrap"
                    },
                    render: [
                      { textStyle: { color: "#5c5c5c", ...h9 }, text: `FS ${I18N.t("migrationProgressInfo")}` },
                      { viewStyle: { paddingLeft: 3, paddingRight: 3 } },
                      {
                        width: "1fr",
                        textStyle: { userSelect: "text", color: "#a7a7a7", ...h9 },
                        text: fsMigrationProcessInfo
                      }
                    ]
                  },
                  {
                    direction: "row",
                    viewStyle: { flexWrap: "wrap" },
                    render: [
                      {
                        ...style.buttonStyle,
                        text: I18N.t("commentDetail"),
                        onClick: this.onClick
                      },
                      { ...style.buttonStyle, text: I18N.t("editDetail"), onClick: this.onClickFormData },
                      {
                        ...style.buttonStyle,
                        text: I18N.t("refresh"),
                        onClick: this.refresh
                      },
                      extref && {
                        ...style.buttonStyle,
                        text: `FS ${I18N.t("migrationStatus")}`,
                        onClick: this.fsMigrationStatus
                      },
                      mobile && {
                        ...style.buttonStyle,
                        text: `FS ${I18N.t("migrationProgress")}`,
                        onClick: this.fsMigrationProcess
                      }
                    ]
                  }
                ]}
              />
            </View>
          </SmoothScrollView>
        )}
        {formModal && (
          <FormComponent
            updatedResult={result => this.updatedResult(result)}
            onClose={() => {
              this.setState({ formModal: void 0 });
            }}
            item={item}
          />
        )}
      </View>
    );
  }
}
