import React from "react";
import { DocsSetCard, VaultDocsSetCard, DocsSetSMListProps } from "./DocsCards";
import { DocsSetNoData, VaultSetNoData } from "../noData/NoData";
import { setQuery, vaultSetQuery } from "../queries";
import ResourceActions from "../resource/ResourceActions";
import CollectionActions from "../collection/CollectionActions";
import { FSList } from "../../../FsCloudComponent";

export default {
  DocsSet: {
    component: "table",
    dataObject: "Collection",
    notifyNavOnScroll: true,
    selectable: false,
    ...setQuery,
    componentTypeMD: {
      gridTable: {
        cardPerRowLG: 3,
        cardPerRowMD: 2,
        bodyStyle: {
          flex: 1,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 15,
          paddingRight: 85
        }
      }
    },
    ComponentSM: FSList,
    ComponentMD: null,
    componentPropsSM: {
      ...DocsSetSMListProps,
      detailLink: CollectionActions.setDetailLink.link
    },
    renderNoData: <DocsSetNoData />,
    headerActionsSM: [
      ResourceActions.searchDocs,
      {
        type: "headerAction",
        image: "createGreyIcon",
        action: "createSet"
      }
    ],
    headerActionsMD: [
      ResourceActions.searchDocs,
      {
        type: "multiActions",
        image: "createWithCircleGreyIcon",
        action: "createSet",
        visible: ({ data }) => (data && data.length ? true : false)
      }
    ],
    rowProps: {
      ...DocsSetCard
    }
  },
  VaultSet: {
    component: "table",
    dataObject: "Collection",
    notifyNavOnScroll: true,
    selectable: false,
    ...vaultSetQuery,
    componentTypeMD: {
      gridTable: {
        cardPerRowLG: 3,
        cardPerRowMD: 2,
        bodyStyle: {
          flex: 1,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 15,
          paddingRight: 85
        }
      }
    },
    ComponentSM: FSList,
    ComponentMD: null,
    componentPropsSM: {
      ...DocsSetSMListProps,
      detailLink: CollectionActions.vaultSetDetailLink.link
    },
    renderNoData: <VaultSetNoData />,
    headerActionsSM: [
      ResourceActions.searchVaultDocs,
      {
        type: "headerAction",
        image: "createGreyIcon",
        action: "createVaultSet"
      }
    ],
    headerActionsMD: [
      {
        ...ResourceActions.searchVaultDocs,
        containerStyle: { flex: 5, alignItems: "center", overflow: void 0 }
      },
      {
        type: "multiActions",
        image: "createWithCircleGreyIcon",
        action: "createVaultSet",
        visible: ({ data }) => (data && data.length ? true : false)
      },
      {
        type: "webAppUpload",
        visible: ({ selectionMode }) => !selectionMode
      },
      {
        type: "navLogo",
        visible: ({ selectionMode }) => !selectionMode
      }
    ],
    rowProps: {
      ...VaultDocsSetCard
    }
  }
};
