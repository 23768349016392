export default () => {
  const audioRewindIcon = "/webImages/audioPlayerIcons/audioRewind.png";
  const audioForwardIcon = "/webImages/audioPlayerIcons/audioForward.png";
  const audioPlay = "/webImages/audioPlayerIcons/audioPlay.png";
  const audioPause = "/webImages/audioPlayerIcons/audioPause.png";
  const audioShuffle = "/webImages/audioPlayerIcons/audioShuffle.png";
  const audioForwardGreyIcon = "/webImages/audioPlayerIcons/audioForwardGrey.png";
  const audioRewindGreyIcon = "/webImages/audioPlayerIcons/audioRewindGrey.png";
  const audioShuffleGreyIcon = "/webImages/audioPlayerIcons/audioShuffleGrey.png";
  const audioShuffleEngagedIcon = "/webImages/audioPlayerIcons/audioShuffleEngaged.png";
  const audioVolumnOffGreyIcon = "/webImages/audioPlayerIcons/audioVolumnOffGrey.png";
  const audioVolumnUpGreyIcon = "/webImages/audioPlayerIcons/audioVolumnUpGrey.png";
  const audioPlayGreyIcon = "/webImages/audioPlayerIcons/audioPlayGrey.png";
  const audioPauseGreyIcon = "/webImages/audioPlayerIcons/audioPauseGrey.png";
  const audioCoverIcon = "/webImages/audioPlayerIcons/audioCover.png";

  return {
    audioRewindIcon,
    audioForwardIcon,
    audioPlay,
    audioPause,
    audioShuffle,
    audioForwardGreyIcon,
    audioRewindGreyIcon,
    audioShuffleGreyIcon,
    audioShuffleEngagedIcon,
    audioVolumnOffGreyIcon,
    audioVolumnUpGreyIcon,
    audioPlayGreyIcon,
    audioPauseGreyIcon,
    audioCoverIcon
  };
};
