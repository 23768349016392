import { I18N } from "../../../FsCloudComponent";

export const getAlbumDateLabel = data => {
  let minDateObj = new Date(data.minDate);
  let maxDateObj = new Date(data.maxDate);
  let dateLabel = I18N.t("albumDateLabel");
  let modifiedMinDate = dateLabel
    .replace("__month__", I18N.t(`month_${minDateObj.getMonth()}`))
    .replace("__date__", minDateObj.getDate())
    .replace("__year__", minDateObj.getFullYear());
  let modifiedMaxDate = dateLabel
    .replace("__month__", I18N.t(`month_${maxDateObj.getMonth()}`))
    .replace("__date__", maxDateObj.getDate())
    .replace("__year__", maxDateObj.getFullYear());
  return modifiedMinDate === modifiedMaxDate ? modifiedMinDate : `${modifiedMinDate} - ${modifiedMaxDate}`;
};
