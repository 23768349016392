import React from "react";
import { Box, withContext, getImage, getAction } from "../../../FsCloudComponent";

class ShareLogoComponent extends React.Component {
  render() {
    let { icon, viewStyle, user } = this.props;
    return (
      <Box
        viewStyle={
          viewStyle || {
            marginLeft: 15,
            marginRight: 15,
            cursor: "pointer"
          }
        }
        image={getImage(icon)}
        Container={getAction("link")}
        containerProps={{
          action: {
            link: user
              ? {
                  uri: "/home#gallery",
                  nestedTab: "galleryAll"
                }
              : {
                  uri: "/landing"
                },
            replace: true
          }
        }}
      />
    );
  }
}

ShareLogoComponent = withContext(ShareLogoComponent, {
  user: "User.user"
});

export default ShareLogoComponent;
