import { getImage } from "../../../FsCloudComponent";
import { isResourceUploading, isResourceDownloading } from "../resource/ResourceUtility";

let defaultColors = {};
const ImageFloatingActions = ({ data, videoProps }, source) => {
  if (!data) {
    return null;
  }
  const avatar = {};
  if (source === "group" && data.avatar) {
    let { initials, color, profile_pic } = data.avatar;
    let {
      _createdBy: { _id: userId }
    } = data || "";
    if (!color) {
      if (!defaultColors[userId]) {
        const x = Math.floor(Math.random() * 200);
        const y = Math.floor(Math.random() * 200);
        const z = Math.floor(Math.random() * 200);
        const defaultColor = "rgb(" + x + "," + y + "," + z + ")"; // default value for color (randomly generated)
        defaultColors[userId] = defaultColor;
      }
      color = defaultColors[userId];
    }
    if (!initials && data.postedBy && data.postedBy.name) {
      initials = data.postedBy.name
        .split(" ")
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join("");
    }
    avatar.profile_pic = profile_pic;
    avatar.color = color;
    avatar.initials = initials;
  }

  return {
    viewStyle: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1
    },
    render: [
      isResourceUploading(data) && {
        viewStyle: { position: "absolute", top: 8, left: 8, zIndex: 1 },
        image: getImage("uploadingIcon")
      },
      isResourceDownloading(data) && {
        viewStyle: { position: "absolute", top: 8, left: 8, zIndex: 1 },
        image: getImage("downloadingIcon")
      },
      avatar.profile_pic && {
        viewStyle: {
          position: "absolute",
          bottom: 8,
          right: 8,
          height: 40,
          width: 40,
          zIndex: 1
        },
        imageStyle: {
          width: 42,
          height: 42,
          borderRadius: 20,
          objectFit: "cover"
        },
        image: { uri: avatar.profile_pic }
      },
      !avatar.profile_pic &&
        avatar.initials && {
          viewStyle: {
            position: "absolute",
            bottom: 8,
            right: 8,
            height: 40,
            width: 40,
            backgroundColor: avatar.color,
            borderRadius: 20,
            zIndex: 1
          },
          textStyle: { textAlign: "center", marginTop: 9, color: "white" },
          text: avatar.initials.toUpperCase()
        },
      data.type === "video" && {
        viewStyle: {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          alignItems: "center",
          justifyContent: "center"
        },
        image: getImage("videoIcon"),
        imageStyle: {
          height: 15,
          width: 15
        },
        imageProps: {
          resizeMode: "contain"
        },
        ...videoProps
      }
    ]
  };
};

export { ImageFloatingActions };
