export default ({ images }) => {
  const { searchIcon } = images;
  const ftsStyle = {
    opened: {
      boxStyle: {
        direction: "row",
        viewStyle: {
          width: 385,
          height: 44,
          borderRadius: 4,
          marginLeft: 15,
          marginRight: 15,
          backgroundColor: "#efefef",
          alignItems: "center"
        }
      },
      imageContainerStyle: {
        image: searchIcon,
        viewStyle: {
          paddingLeft: 16,
          paddingRight: 4,
          height: 22,
          width: 42,
          alignItems: "center",
          justifyContent: "center"
        }
      },
      inputContainerStyle: {
        viewStyle: {
          flex: 1,
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      inputPlaceholder: "Search..."
    },
    boxStyle: {
      direction: "row"
    },
    imageContainerStyle: {
      image: searchIcon,
      viewStyle: {
        alignItems: "center",
        justifyContent: "center"
      },
      imageStyle: {
        width: 20,
        height: 20
      }
    },
    inputContainerStyle: {
      viewStyle: {
        width: 120,
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: 12,
        height: 24,
        borderRadius: 4,
        backgroundColor: "#efefef",
        justifyContent: "center"
      }
    },
    inputPlaceholder: "Type here..."
  };
  return {
    ftsStyle
  };
};
