import React from "react";
import { View, SlideShowComponent, theme } from "../../../FsCloudComponent";
import {
  montageItemQuery,
  placeMontageItemQuery,
  vaultMontageItemQuery,
  deviceFolderMontageItemQuery
} from "../queries";

const { bgs } = theme;
const { blackBg } = bgs;

class SlideShowComponentView extends React.Component {
  render() {
    let { link, data: images = [], origin } = this.props;
    let { fromShare, sharedBy, data = {}, sharedToken } = (link && link.props && link.props.params) || {};
    let collectionData = data;
    return (
      <View style={{ flex: 1, backgroundColor: blackBg }}>
        <SlideShowComponent
          {...this.props}
          sharedToken={sharedToken}
          isOwner={collectionData.owner}
          decryptionSourceProps={{ sharedToken, decryptionSource: origin === "album" && "collection" }}
          images={images}
          name={(collectionData.system && fromShare && sharedBy) || collectionData.name}
          albumId={collectionData._id}
        />
      </View>
    );
  }
}

const commonProps = {
  component: "custom",
  hideNavHeader: true,
  panelProps: {
    showHeader: false
  }
};

export default {
  AlbumSlideShowComponent: {
    ...commonProps,
    Component: props => <SlideShowComponentView {...props} origin="album" />,
    ...montageItemQuery
  },
  PlaceSlideShowComponent: {
    ...commonProps,
    Component: props => <SlideShowComponentView {...props} origin="places" />,
    ...placeMontageItemQuery
  },
  VaultSlideShowComponent: {
    ...commonProps,
    Component: props => <SlideShowComponentView {...props} origin="vaultAlbum" />,
    ...vaultMontageItemQuery
  },
  HighlightsSlideShowComponent: {
    ...commonProps,
    Component: props => <SlideShowComponentView {...props} origin="highlights" />
  },
  DeviceFolderSlideShowComponent: {
    ...commonProps,
    Component: props => <SlideShowComponentView {...props} origin="deviceFolder" />,
    ...deviceFolderMontageItemQuery
  }
};
