export default ({ images, fonts, colors, bgs, gradients, Platform, I18N }) => {
  const { menuIcon, backIcon, backArrowGreyIcon, createGreyIcon, iconMenuGrey, crossIcon } = images;
  const { primaryColor, secondaryColor, highlightColor, themeColor } = colors;
  const { lightThemeBg } = bgs;
  const { h2, h16, h16_l, h20 } = fonts;

  const removeInvalidSelectedIds = ({ data, selectedIds }) => {
    if (!data || !Array.isArray(data) || !selectedIds || !selectedIds.length) {
      return;
    }
    let dataMap = data.reduce((accum, row) => {
      if (row && row._id) {
        accum[row._id] = 1;
      }
      return accum;
    }, {});
    for (let i = selectedIds.length - 1; i >= 0; i--) {
      if (!dataMap[selectedIds[i]]) {
        selectedIds.splice(i, 1);
      }
    }
  };

  const actionLayout = {
    actionLayoutSM: {
      panelHeader: [
        {
          type: "close",
          image: backIcon,
          defaultAction: true,
          visible: ({ index, selectedIds = [] }) => !!index && !selectedIds.length
        },
        {
          type: "menu",
          image: menuIcon,
          defaultAction: true,
          visible: ({ index, selectionMode }) => !selectionMode && !index
        },
        {
          type: "unSelectAll",
          image: backIcon,
          defaultAction: true,
          visible: ({ index, selectionMode, selectedIds = [] }) => (index === 0 && selectionMode) || selectedIds.length
        },
        {
          type: "navTitle",
          defaultAction: true,
          value: ({ data, title, selectionMode, selectedIds, link }) => {
            if (link && (link.uri === "/select-cover-image" || link.uri === "/select-cover-image-vault")) {
              if (selectedIds && selectedIds.length) {
                title = () => I18N.t("setCover");
              } else {
                title = () => I18N.t("selectCover");
              }
            } else if (link && link.uri === "/upload-profile-picture") {
              if (selectedIds && selectedIds.length) {
                title = () => I18N.t("pictureSelected");
              } else {
                title = () => I18N.t("selectProfilePicture");
              }
            } else if (selectionMode) {
              if (link && (link.uri === "/upload-queue" || link.uri === "/download-queue")) {
                removeInvalidSelectedIds({ data, selectedIds });
              }
              if (selectedIds && selectedIds.length) {
                title = () => `${selectedIds.length} ${I18N.t("selected")}`;
              } else {
                title = () => I18N.t("selectItemsDot");
              }
            }
            return { title };
          }
        },
        {
          type: "languageSelector",
          defaultAction: true,
          showText: false,
          visible: ({ selectionMode, index }) => !selectionMode && Platform.OS === "web" && index === 0
        },
        { type: "searchInput" },
        { type: "searchLink" },
        { type: "headerAction" },
        { type: "collectionDetailHeaderAction" },
        { type: "insertLink", image: createGreyIcon },
        { type: "save", text: "Save" },
        {
          type: "multiActions"
        }
      ]
    },
    actionLayoutMD: {
      navHeader: [
        {
          type: "close",
          image: backArrowGreyIcon,
          defaultAction: true,
          visible: ({ index, selectedIds = [], editMode }) => !!index && !selectedIds.length && !editMode
        },
        {
          type: "unSelectAll",
          image: crossIcon,
          defaultAction: true,
          visible: ({ index, selectionMode, selectedIds = [] }) => (index === 0 && selectionMode) || selectedIds.length
        },
        {
          type: "nonEditMode",
          image: crossIcon,
          defaultAction: true,
          visible: ({ editMode }) => editMode
        },
        {
          type: "menu",
          image: menuIcon,
          defaultAction: true,
          visible: ({ index, selectionMode }) => !selectionMode && !index
        },
        {
          type: "navTitle",
          defaultAction: true,
          visible: ({ selectionMode }) => !selectionMode,
          value: ({ title }) => ({ title })
        },
        {
          type: "selectedItemCount",
          defaultAction: true,
          visible: ({ selectionMode }) => selectionMode,
          value: ({ data, selectedIds, link }) => {
            let text = void 0;
            if (link && (link.uri === "/upload-queue" || link.uri === "/download-queue")) {
              removeInvalidSelectedIds({ data, selectedIds });
            }
            if (selectedIds && selectedIds.length) {
              text = `${selectedIds.length} ${I18N.t("selected")}`;
            } else {
              text = () => I18N.t("selectItemsDot");
            }
            return { text };
          }
        },
        { type: "flex" },
        { type: "searchInput" },
        { type: "searchLink" },
        { type: "headerAction" },
        {
          type: "webAppUpload",
          defaultAction: true,
          showText: false,
          visible: ({ selectionMode, index }) => !selectionMode && index === 0
        },
        {
          type: "languageSelector",
          defaultAction: true,
          showText: false,
          visible: ({ selectionMode, index }) => !selectionMode && index === 0
        },
        {
          type: "navLogo",
          defaultAction: true,
          visible: ({ selectionMode, index }) => !selectionMode && index === 0
        }
      ],
      panelHeader: [
        { type: "flex" },
        { type: "insertLink", image: createGreyIcon },
        { type: "save", text: "Save" },
        { type: "multiActions", image: iconMenuGrey }
      ]
    },
    actionLayoutLG: {
      navHeader: [
        {
          type: "close",
          image: backArrowGreyIcon,
          defaultAction: true,
          visible: ({ index, selectedIds = [], editMode }) => !!index && !selectedIds.length && !editMode
        },
        {
          type: "unSelectAll",
          image: crossIcon,
          defaultAction: true,
          visible: ({ index, selectionMode, selectedIds = [] }) => (index === 0 && selectionMode) || selectedIds.length
        },
        {
          type: "nonEditMode",
          image: crossIcon,
          defaultAction: true,
          visible: ({ editMode }) => editMode
        },
        {
          type: "menu",
          image: menuIcon,
          defaultAction: true,
          visible: ({ index, selectionMode }) => !selectionMode && !index
        },
        {
          type: "navTitle",
          defaultAction: true,
          visible: ({ selectionMode }) => !selectionMode,
          value: ({ title }) => ({ title })
        },
        {
          type: "selectedItemCount",
          defaultAction: true,
          visible: ({ selectionMode }) => selectionMode,
          value: ({ selectedIds }) => {
            let text = void 0;
            if (selectedIds && selectedIds.length) {
              text = `${selectedIds.length} ${I18N.t("selected")}`;
            } else {
              text = () => I18N.t("selectItemsDot");
            }
            return { text };
          }
        },
        { type: "flex" },
        { type: "searchInput" },
        { type: "searchLink" },
        { type: "headerAction" },
        {
          type: "webAppUpload",
          defaultAction: true,
          visible: ({ selectionMode, index }) => !selectionMode && index === 0
        },
        {
          type: "languageSelector",
          defaultAction: true,
          visible: ({ selectionMode, index }) => !selectionMode && index === 0
        },
        { type: "navLogo", defaultAction: true, visible: ({ selectionMode, index }) => !selectionMode && index === 0 }
      ],
      panelHeader: [
        { type: "flex" },
        { type: "insertLink", image: createGreyIcon },
        { type: "save", text: "Save" },
        { type: "multiActions", image: iconMenuGrey }
      ]
    }
  };

  const saveSM = {
    boxStyle: {
      viewStyle: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 12,
        paddingRight: 12,
        cursor: "pointer",
        borderRadius: 2,
        marginLeft: 4,
        marginRight: 4,
        backgroundColor: lightThemeBg
      },
      textStyle: {
        ...h2,
        color: themeColor
      }
    }
  };

  const titleSM = {
    boxStyle: {
      viewStyle: {
        flex: 1,
        height: 32,
        paddingLeft: 12,
        paddingRight: 12,
        justifyContent: "center"
      },
      textStyle: {
        ...h16,
        color: themeColor,
        numberOfLines: 1
      }
    }
  };

  const titleMD = ({ index }) => {
    return !index
      ? {
          boxStyle: {
            viewStyle: {
              justifyContent: "center",
              paddingLeft: 43
            },
            textStyle: {
              ...h20,
              color: themeColor,
              numberOfLines: 1
            }
          }
        }
      : {
          boxStyle: {
            viewStyle: {
              justifyContent: "center",
              paddingLeft: 18,
              paddingRight: 15
            },
            textStyle: {
              ...h16_l,
              color: primaryColor,
              numberOfLines: 1
            }
          }
        };
  };

  const saveMD = {
    boxStyle: {
      viewStyle: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 12,
        paddingRight: 12,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      },
      gradient: {
        ...gradients.themeGradient
      },
      gradientStyle: {
        width: 120,
        height: 24,
        marginLeft: 12,
        marginRight: 12
      },
      textStyle: {
        ...h2,
        color: highlightColor
      }
    }
  };

  const imageAndTextActionMD = {
    boxStyle: {
      viewStyle: {
        height: 44,
        paddingLeft: 15,
        paddingRight: 15,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      },
      textStyle: {
        ...h2,
        color: secondaryColor
      }
    }
  };

  const actionStyle = {
    actionStyleSM: {
      default: {
        boxStyle: {
          viewStyle: {
            width: 44,
            height: 44,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          },
          textStyle: {
            ...h2,
            color: primaryColor
          }
        }
      },
      navTitle: titleSM,
      panelTitle: titleSM,
      invoke: saveSM,
      updateForm: saveSM,
      save: saveSM
    },
    actionStyleMD: {
      default: {
        boxStyle: {
          viewStyle: {
            height: 44,
            width: 48,
            paddingLeft: 12,
            paddingRight: 12,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          },
          textStyle: {
            ...h2,
            color: secondaryColor
          }
        }
      },
      navTitle: titleMD,
      updateForm: saveMD,
      save: saveMD,
      invoke: saveMD,
      webAppUpload: imageAndTextActionMD,
      languageSelector: imageAndTextActionMD,
      navLogo: imageAndTextActionMD,
      selectedItemCount: {
        boxStyle: {
          viewStyle: {
            height: 36,
            paddingLeft: 15,
            paddingRight: 15,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          },
          textStyle: {
            ...h16_l,
            color: primaryColor
          }
        }
      }
    }
  };

  return {
    actionLayout,
    actionStyle
  };
};
