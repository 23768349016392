import React from "react";
import AppDownloadMessageHoc from "./AppDownloadMessage";

export default ({
  Box,
  getImage,
  getAction,
  LoginContainer,
  theme,
  I18N,
  AppConfig,
  Editor,
  Button,
  ChatLinkComponent,
  Platform,
  SmsReader,
  logFirebaseAnalyticsEvent,
  getErrorMessage
}) => {
  const { colors, fonts } = theme;
  const { brownGrey, themeColor, registrationColor, highlightColor } = colors;
  const { h16_l, h16, h9, h16_18 } = fonts;
  const { APP_REGISTRATION_SOURCE } = AppConfig || {};
  const AppDownloadMessage = AppDownloadMessageHoc({ I18N, AppConfig });

  const translationExists = code => {
    let exists = false;
    if (code) {
      let message = I18N.t(code);
      if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
        exists = false;
      } else {
        exists = true;
      }
    }
    return exists;
  };

  class CreateAccountInformation extends React.Component {
    render() {
      let { link } = this.props;
      let { fromLandline } = (link && link.props && link.props.params) || {};
      return (
        <Box
          viewStyle={{ paddingLeft: 27, paddingRight: 27 }}
          render={[
            {
              viewStyle: {
                marginBottom: 4,
                alignItems: "center",
                justifyContent: "center"
              },
              textStyle: { ...h16, color: themeColor },
              text: I18N.t("noAccountYet")
            },
            {
              viewStyle: {
                marginTop: 4,
                marginBottom: 12,
                alignItems: "center",
                justifyContent: "center"
              },
              textStyle: { ...h9, color: brownGrey },
              text:
                fromLandline && translationExists("vivoFixaNeedSomeInformation")
                  ? I18N.t("vivoFixaNeedSomeInformation")
                  : I18N.t("needSomeInformation")
            },
            {
              Container: getAction("link"),
              containerProps: {
                action: {
                  link: {
                    uri: "/register-email",
                    props: {
                      fromLandline
                    }
                  }
                }
              },
              viewStyle: {
                marginTop: 12,
                marginBottom: 12,
                paddingTop: 14,
                paddingBottom: 14,
                borderRadius: 4,
                backgroundColor: registrationColor,
                alignItems: "center",
                justifyContent: "center"
              },
              textStyle: { ...h16_18, color: highlightColor },
              text:
                fromLandline && translationExists("vivoFixaEnterInformation")
                  ? I18N.t("vivoFixaEnterInformation")
                  : I18N.t("enterInformation")
            }
          ]}
        />
      );
    }
  }

  class Login extends React.Component {
    state = {};

    _setState = state => {
      this.setState(state);
    };

    onError = err => {
      let message = getErrorMessage(err);
      this._setState({ error: message });
    };

    sendSMS = async ({ data, onSubmit }) => {
      try {
        let { addUri, getPath, link } = this.props;
        if (this.state.error) {
          this.setState({ error: void 0 });
        }
        let { fromLandline } = (link && link.props && link.props.params) || {};
        let { mobile } = data;
        let params = { mobile, fromLandline };
        if (Platform.OS === "android") {
          params.smshash = await SmsReader.getHash();
        }
        logFirebaseAnalyticsEvent &&
          logFirebaseAnalyticsEvent({ screen: "login", event: "phone_no", params: { mobile } });
        onSubmit &&
          onSubmit("sendSMS", params, {
            afterSubmit: result => {
              addUri &&
                getPath &&
                addUri(getPath(), {
                  uri: "/validate-otp",
                  props: {
                    transaction_id: result.transaction_id,
                    mobile,
                    fromLandline
                  }
                });
            },
            throwError: true
          }).catch(err => {
            this.onError(err);
          });
      } catch (err) {
        this.onError(err);
      }
    };

    render() {
      let { link, deleteUri, getPath } = this.props;
      let { registrationRequired, fromLandline } = (link && link.props && link.props.params) || {};
      let contentContainerStyle = {
        flex: 1,
        minHeight: registrationRequired ? 550 : 450,
        maxHeight: registrationRequired ? 700 : void 0
      };
      return (
        <LoginContainer
          scrollViewStyle={{
            style: { flex: 1 },
            contentContainerStyle: Platform.OS === "web" ? {} : contentContainerStyle
          }}
        >
          {props => {
            let editorProps = {
              ...props,
              state: this.state,
              setState: this._setState
            };
            const onSubmit = () => this.sendSMS({ ...props });
            return (
              <Box
                viewStyle={{ ...(Platform.OS !== "web" ? { flex: 1 } : contentContainerStyle) }}
                render={[
                  {
                    viewStyle: {
                      flex: 1,
                      paddingLeft: 27,
                      paddingRight: 27,
                      paddingTop: 30
                    },
                    render: [
                      APP_REGISTRATION_SOURCE === "landline" && {
                        image: getImage("searchCrossIcon"),
                        onClick: () => getPath && deleteUri && deleteUri(getPath(), link),
                        viewStyle: { position: "absolute", top: Platform.OS === "web" ? 30 : 48, right: 27, zIndex: 10 }
                      },
                      {
                        image: getImage("walkthroughLogo"),
                        viewStyle: {
                          alignItems: "center",
                          paddingTop: 68,
                          paddingBottom: 18
                        },
                        imageProps: {
                          resizeMode: "contain"
                        }
                      },
                      {
                        viewStyle: {
                          paddingTop: 16,
                          paddingBottom: 16,
                          alignItems: "center"
                        },
                        textStyle: {
                          ...h16_l,
                          color: brownGrey
                        },
                        text:
                          fromLandline && translationExists("vivoFixaLoginSecondaryText")
                            ? I18N.t("vivoFixaLoginSecondaryText")
                            : I18N.t("loginSecondaryText")
                      },
                      <Editor
                        {...editorProps}
                        field={"mobile"}
                        icon={getImage("telephoneIcon")}
                        placeholder={"mobilePlaceholder"}
                        onClick={onSubmit}
                      />,
                      Platform.OS === "web"
                        ? {
                            viewStyle: {
                              marginTop: 16
                            },
                            render: (
                              <Editor
                                {...editorProps}
                                field={"captchaVerify"}
                                editorTheme={theme.reCaptchaContainerStyle}
                              />
                            )
                          }
                        : null,
                      {
                        viewStyle: {
                          marginTop: 16,
                          marginBottom: 8
                        },
                        render: (
                          <Button loading={props.loading} onClick={onSubmit} text={"login"} loaderText={"logging"} />
                        )
                      },
                      AppConfig &&
                        AppConfig.B2B_APP_DOWNLOAD_LINK &&
                        !registrationRequired && {
                          viewStyle: {
                            paddingTop: 16,
                            paddingBottom: 16,
                            alignItems: "center"
                          },
                          textStyle: {
                            ...h16_l,
                            color: brownGrey
                          },
                          render: <AppDownloadMessage />
                        }
                    ]
                  },
                  registrationRequired && <CreateAccountInformation {...this.props} />,
                  {
                    viewStyle: { alignItems: "center", marginBottom: 18 },
                    render: <ChatLinkComponent />
                  }
                ]}
              />
            );
          }}
        </LoginContainer>
      );
    }
  }
  return Login;
};
