import React from "react";
export default ({ dataObjects, ConnectPanelTable, resolveHeaderActions, resolveActionLayout, resolveMQ }) => {
  const loader = ({
    screen,
    user,
    screenDef,
    state,
    activeMQ,
    link,
    hashMap,
    panelActionsRequired,
    mergeHeaderActions,
    route
  }) => {
    let { icon, panelProps = {}, connectProps, dataObject, fields, actions } = screenDef;
    let { headerActions, Component, hideNavHeader, hideStatusBar, statusBarTheme, title } = resolveMQ
      ? resolveMQ(
          screenDef,
          ["headerActions", "Component", "hideNavHeader", "hideStatusBar", "statusBarTheme", "title", "navTitle"],
          activeMQ
        )
      : screenDef;
    let screenProps = {
      screenState: state,
      title: title,
      icon,
      hideNavHeader,
      hideStatusBar,
      statusBarTheme
    };
    let schema = {};
    if (dataObject) {
      schema = dataObjects[dataObject];
      if (schema) {
        fields = fields || schema.fields;
        actions = actions || schema.actions;
      }
    }

    const schemaToPass = {
      fields,
      actions
    };

    if (mergeHeaderActions) {
      headerActions = mergeHeaderActions(headerActions);
    }
    headerActions = resolveHeaderActions({
      headerActions,
      actions,
      fields
    });
    let { panelFooter, panelHeader, navHeader, navLeft, navFooter } = resolveActionLayout({
      actions: headerActions,
      activeMQ
    });
    panelProps.title = title;
    if (panelFooter) {
      panelProps.footerActions = panelFooter;
    }
    if (panelHeader) {
      if (panelActionsRequired) {
        screenProps.panelHeaderActions = panelHeader;
      } else {
        panelProps.headerActions = panelHeader;
      }
    }
    if (navLeft) {
      screenProps.navLeftActions = navLeft;
    }
    if (navFooter) {
      screenProps.navFooterActions = navFooter;
    }
    if (navHeader) {
      screenProps.navHeaderActions = navHeader;
    }
    if (connectProps && typeof connectProps === "function") {
      connectProps = connectProps({ link, user, hashMap });
    }
    let componentProps = { link, hashMap };
    screenProps.component = (
      <ConnectPanelTable
        link={link}
        schema={schemaToPass}
        connectProps={connectProps}
        panelProps={panelProps}
        componentProps={componentProps}
        Component={Component}
        screenName={screen}
        route={route}
      />
    );
    return screenProps;
  };
  return {
    loader
  };
};
