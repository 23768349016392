import ResourceDetailComponent from "../resource/ResourceDetailComponent";
import {
  docDetailQuery,
  setDocDetailQuery,
  searchDocDetailQuery,
  groupDocDetailQuery,
  vaultDocDetailQuery,
  archiveDocDetailQuery
} from "../queries";

const commonDetailProps = {
  component: "table",
  Component: ResourceDetailComponent,
  panelProps: {
    showHeader: false
  },
  hideNavHeader: true
};

export default {
  DocDetail: {
    ...commonDetailProps,
    ...docDetailQuery
  },
  SetDocDetail: {
    ...commonDetailProps,
    ...setDocDetailQuery
  },
  SearchDocDetail: {
    ...commonDetailProps,
    ...searchDocDetailQuery
  },
  GroupDocDetail: {
    ...commonDetailProps,
    ...groupDocDetailQuery
  },
  VaultDocDetail: {
    ...commonDetailProps,
    ...vaultDocDetailQuery
  },
  ArchiveDocDetail: {
    ...commonDetailProps,
    ...archiveDocDetailQuery
  }
};
