import React from "react";
import { View, I18N } from "../../../../FsCloudComponent";
class ResetContact extends React.Component {
  render() {
    return <View style={{ flex: 1 }} />;
  }
}

export default {
  ResetContact: {
    component: "custom",
    Component: ResetContact,
    title: () => I18N.t("resetContact")
  }
};
