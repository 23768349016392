import React from "react";

export default ({ Box, Connect, ScrollView, resolveMQ, Platform, theme, ImageBackground, FSLoginApi }) => {
  let { sendSMS, sendEmail, validateOtp, forgotEmail } = FSLoginApi;

  class LoginContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.loginMethods = {
        sendSMS: this.sendSMS,
        sendEmail: this.sendEmail,
        forgotEmail: this.forgotEmail,
        validateOtp: this.validateOtp
      };
    }

    componentWillUnmount() {
      this._unmounted = true;
    }

    sendSMS = async params => {
      return sendSMS && (await sendSMS(params));
    };

    sendEmail = async params => {
      return sendEmail && (await sendEmail(params));
    };

    forgotEmail = async params => {
      return forgotEmail && (await forgotEmail(params));
    };

    validateOtp = params => {
      return (
        validateOtp &&
        validateOtp(params).then(user => {
          return this.afterLogin(user);
        })
      );
    };

    afterLogin = user => {
      let { setUserInfo, registerNotificationToken } = this.props;
      return Promise.resolve()
        .then(_ => {
          return setUserInfo && setUserInfo(user);
        })
        .then(_ => {
          if (Platform.OS !== "web" && registerNotificationToken) {
            registerNotificationToken({ type: Platform.OS });
          }
          return user;
        });
    };

    checkValidations = (validateData, { skipValidation } = {}) => {
      if (!validateData || skipValidation) {
        return Promise.resolve();
      }
      return validateData().then(validations => {
        if (validations && Object.keys(validations).length) {
          throw new Error("");
        }
      });
    };

    onSubmit =
      ({ updatableData } = {}) =>
      (methodName, methodParams, options = {}) => {
        let { loading } = this.state;
        if (loading) {
          return Promise.resolve();
        }
        let { showError, showMessage } = this.props;
        let loginMethod = this.loginMethods[methodName];
        if (!loginMethod) {
          showMessage(`Method ${methodName} not registered`);
          return Promise.resolve();
        }
        this.setState({
          loading: true
        });
        let validateData = updatableData && updatableData.validateData;
        let { beforeSubmit, afterSubmit } = options;
        return this.checkValidations(validateData, options)
          .then(_ => {
            return beforeSubmit && beforeSubmit();
          })
          .then(_ => {
            return loginMethod(methodParams);
          })
          .then(result => {
            return afterSubmit && afterSubmit(result || {});
          })
          .then(_ => {
            if (!this._unmounted && this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch(err => {
            if (!this._unmounted && this.state.loading) {
              this.setState({ loading: false });
            }
            if (options.throwError) {
              throw err;
            } else {
              showError && showError(err);
            }
          });
      };

    render() {
      let { activeMQ, children } = this.props;
      let { containerStyle, scrollViewStyle, backgroundImageStyle, boxStyle } = resolveMQ(
        theme,
        ["scrollViewStyle", "containerStyle", "backgroundImageStyle", "boxStyle"],
        activeMQ
      );
      if (this.props.scrollViewStyle) {
        scrollViewStyle = this.props.scrollViewStyle;
      }
      let loginComponent = (
        <Connect data={{}} updatable>
          {props => {
            let component = (
              <Box
                {...boxStyle}
                render={
                  typeof children === "function"
                    ? children({
                        ...props,
                        loading: this.state.loading,
                        onSubmit: this.onSubmit(props),
                        setState: state => {
                          this.setState(state);
                        }
                      })
                    : children
                }
              />
            );
            if (backgroundImageStyle && ImageBackground) {
              component = <ImageBackground {...backgroundImageStyle}>{component}</ImageBackground>;
            }
            return (
              <ScrollView keyboardShouldPersistTaps={"always"} {...scrollViewStyle}>
                {component}
              </ScrollView>
            );
          }}
        </Connect>
      );
      return <Box {...containerStyle} render={[loginComponent]} />;
    }
  }
  return LoginContainer;
};
