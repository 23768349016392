import React from "react";

export default ({ LoginHeader, Box, getImage, theme, I18N, resolveMQ, AppConfig, Button, ChatLinkComponent }) => {
  const { colors, fonts } = theme;
  const { highlightColor, primaryColor } = colors;
  const { h20, h1 } = fonts;

  const { APP_REGISTRATION_SOURCE } = AppConfig;

  const styles = {
    styleLG: {
      bodyContainerStyle: {
        viewStyle: {
          paddingTop: 18,
          paddingBottom: 18,
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: highlightColor
        }
      },
      logoStyle: {
        viewStyle: {
          height: 106,
          width: 103,
          alignSelf: "center",
          marginTop: 6,
          marginBottom: 18
        }
      },
      messageStyle: {
        viewStyle: {
          alignItems: "center",
          paddingTop: 12,
          paddingBottom: 18
        },
        textStyle: {
          ...h20,
          color: primaryColor
        }
      },
      buttonContainerStyle: {
        viewStyle: {
          marginTop: 6,
          marginBottom: 6
        }
      }
    },
    styleMD: {
      bodyContainerStyle: {
        viewStyle: {
          paddingTop: 40,
          paddingBottom: 40,
          paddingLeft: 30,
          paddingRight: 30,
          backgroundColor: highlightColor
        }
      },
      logoStyle: {
        viewStyle: {
          height: 132,
          width: 128,
          alignSelf: "center",
          marginTop: 18,
          marginBottom: 18
        }
      },
      messageStyle: {
        viewStyle: {
          alignItems: "center",
          paddingTop: 20,
          paddingBottom: 9
        },
        textStyle: {
          ...h1,
          color: primaryColor
        }
      },
      buttonContainerStyle: {
        viewStyle: {
          marginTop: 12
        }
      }
    }
  };
  class WebLoginOption extends React.Component {
    render() {
      const { activeMQ, setState } = this.props;
      const { style } = resolveMQ(styles, ["style"], activeMQ);
      return (
        <Box
          render={[
            LoginHeader && <LoginHeader onClose={this.props.onClose} />,
            {
              ...style.bodyContainerStyle,
              render: [
                { ...style.logoStyle, image: getImage("loginOptionLogo") },
                {
                  ...style.messageStyle,
                  text: `${I18N.t("loginOptionTitle")}:`
                },
                {
                  ...style.buttonContainerStyle,
                  render: (
                    <Button
                      text={"mobileLoginTitle"}
                      onClick={() => {
                        setState &&
                          setState({ viewType: "login", registrationRequired: APP_REGISTRATION_SOURCE === "mobile" });
                      }}
                    />
                  )
                },
                APP_REGISTRATION_SOURCE === "landline" && {
                  ...style.buttonContainerStyle,
                  render: (
                    <Button
                      text={"landlineLoginTitle"}
                      onClick={() => {
                        setState && setState({ viewType: "login", registrationRequired: true, fromLandline:true });
                      }}
                    />
                  )
                },
                {
                  viewStyle: { alignItems: "center", marginTop: 18 },
                  render: <ChatLinkComponent />
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return WebLoginOption;
};
