import moment from "moment";
export default ({ types = {}, resolveValue, getSizeFormat }) => {
  const {
    date: dateProps,
    dateTime: dateTimeProps,
    number: numberProps,
    decimal: decimalProps,
    currency: currencyProps = {},
    googlePlace: googlePlaceProps = {}
  } = types;

  const formatDate = (props, dateProps) => {
    const { data, field, fieldValue, fieldDef: { format: propsFormat, classic: classicFormat } = {} } = props;
    const { format: defaultFormat, classic: defaultClassicFormat } = dateProps || {};
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    if (value === null || value === undefined) {
      return value;
    }
    let format = propsFormat || defaultFormat;
    if (value === "") {
      return value;
    }
    if (format === "classic") {
      value = classicDate({ value, classicFormat: classicFormat || defaultClassicFormat });
    } else {
      value = moment(value).format(format);
    }
    return value;
  };
  const getIndianNumberFormat = (value, format) => {
    let zeroCount = 0;
    var space = format.indexOf(" i") !== -1 ? " " : "";
    let fragments = format.split(".");
    if (fragments.length === 2) {
      zeroCount = (fragments[1].match(/0/g) || []).length;
    } else if (fragments.length > 2) {
      return "Invalid Format";
    }
    const crore = 10000000;
    const lakh = 100000;
    const thousand = 1000;

    let abbr;
    let abs = Math.abs(value);
    if (abs >= crore) {
      value = value / crore;
      abbr = "cr";
    } else if (abs < crore && abs >= lakh) {
      value = value / lakh;
      abbr = "l";
    } else if (abs < lakh && abs >= thousand) {
      value = value / thousand;
      abbr = "k";
    } else if (abs < thousand) {
      abbr = "";
    }
    /*when number is 5.0 make it 5 - akshay */
    value = value.toFixed(zeroCount);
    if (value % 1 === 0) {
      value = parseInt(value);
    }
    return value + space + abbr;
  };

  const formatNumber = (original, format) => {
    if (format) {
      format = format.trim();
    }
    let decimals = 0;
    let indexOfDot = format.indexOf(".");
    if (indexOfDot > 0) {
      decimals = format.substring(indexOfDot + 1).length;
    }
    var originalFloat = parseFloat(original);

    // Skip formatting if it's not a numeric value
    if (!isFinite(originalFloat)) {
      return original;
    }

    return _formatAfterCleanup(originalFloat.toFixed(decimals));
  };

  const _formatAfterCleanup = originalNumber => {
    var originalMag;
    var negative;

    if (originalNumber < 0) {
      originalMag = -1 * originalNumber;
      negative = true;
    } else {
      originalMag = originalNumber;
      negative = false;
    }

    var arr = String(originalMag)
      .split("")
      .reverse();
    var start = arr.indexOf(".") + 1; // start of full part
    var i; // source index
    var result = []; // array holder of the result

    // copy the fractional part and the decimal if present
    for (i = 0; i < start; i++) {
      result.push(arr[i]);
    }

    // main loop
    var c = 0; // digit counter
    for (i = start; i < arr.length; i++) {
      result.push(arr[i]);
      c++;
      if ((c === 3 || c === 5 || c === 7) && i < arr.length - 1) {
        result.push(",");
      }
      if (c === 7) {
        c = 0;
      }
    }

    if (negative) {
      result.push("-");
    }

    return result.reverse().join("");
  };

  const numberFormatter = (value, format) => {
    //const format can be 0,0.00 or i
    if (value === undefined || value === null || value === "" || !format) {
      return value;
    }
    if (format.indexOf("i") !== -1) {
      return getIndianNumberFormat(value, format);
    } else {
      return formatNumber(value, format);
    }
  };

  const classicDate = ({ value, classicFormat }) => {
    const { today: todayFormat, rest: restFormat } = classicFormat;
    let format = restFormat;
    let date = new Date(value);
    let today = new Date();
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      format = todayFormat;
    }
    return moment(value).format(format);
  };

  const lpad = ({ value, padString, padCount = 0 }) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    while (value.length < padCount) {
      value = padString + value;
    }
    return value;
  };

  const rpad = ({ value, padString, padCount = 0 }) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    while (value.length < padCount) {
      value = value + padString;
    }
    return value;
  };

  const reference = props => {
    const { data, field, fieldValue, fieldDef } = props;
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    value = value || {};
    let { display } = fieldDef;
    if (Array.isArray(display)) {
      display = display[0];
    }
    value = resolveValue(value, display);
    return value;
  };

  const currency = props => {
    let {
      data,
      fieldDef: { number, currency, format }
    } = props;
    number = {
      ...currencyProps.number,
      ...number
    };
    if (format) {
      number = { ...number, format };
    }
    currency = {
      ...currencyProps.currency,
      ...currency
    };
    let numberValue = Formatter[number.type]({ ...number, data });
    let currencyValue = Formatter[currency.type]({
      ...currency,
      fieldDef: { display: currency.display },
      data
    });
    if (numberValue === undefined && currencyValue === undefined) {
      return "";
    }
    return `${numberValue || 0}${currencyValue ? " " + currencyValue : ""}`;
  };

  const duration = props => {
    let numberField = "number";
    let unitField = "unit";

    let { data, field, fieldValue, fieldDef } = props;

    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);

    let numberValue = 0;
    let unitValue = "";

    if (typeof value === "number") {
      numberValue = value;
      unitValue = (fieldDef && fieldDef.baseUnit) || "Hours";
    } else if (!value) {
      return;
    } else {
      numberValue = value[numberField];
      unitValue = value[unitField];
    }

    let valueInMinutes = unitValue === "Hours" ? numberValue * 60 : numberValue;
    let hour = Math.floor(valueInMinutes / 60);
    let min = valueInMinutes % 60;

    if (hour) {
      value = Number(hour).toFixed(0);
    } else {
      value = "";
    }
    if (min) {
      min = Number(min).toFixed(0);
      if (value) {
        value = value + "h ";
      }
      value = value + min + "m";
    } else {
      if (value) {
        value = value + "h";
      }
    }
    return value;
  };

  const option = props => {
    const { data, field, fieldValue, fieldDef } = props;
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    if (value) {
      const { options = {} } = fieldDef;
      value = options[value] || value;
      if (value && typeof value !== "string") {
        value = JSON.stringify(value);
      }
    }
    return value;
  };

  const boolean = props => {
    const { data, field, fieldValue } = props;
    if (fieldValue !== undefined) {
      return fieldValue;
    }
    let value = resolveValue(data, field);
    if (value !== undefined && typeof value !== "string") {
      value = JSON.stringify(value);
    }
    return value;
  };
  const fileSize = props => {
    const { data, field, fieldValue } = props;
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    return getSizeFormat && getSizeFormat(value);
  };

  const googlePlace = props => {
    const { data, field, fieldValue, fieldDef = {} } = props;
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    if (value) {
      let searchField = fieldDef.searchField || googlePlaceProps.searchField;
      value = resolveValue(value, searchField);
    }
    return value;
  };

  const multiReference = props => {
    const { data, field, fieldValue, fieldDef } = props;
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    value = value || [];
    if (value && !Array.isArray(value)) {
      value = [value];
    }
    let { display } = fieldDef;
    if (Array.isArray(display)) {
      display = display[0];
    }
    value = value.map(item => resolveValue(item, display));
    value = value.join("; ");
    return value;
  };

  const text = props => {
    const { data, field, fieldValue } = props;
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    return value;
  };

  const date = props => {
    return formatDate(props, dateProps);
  };

  const dateTime = props => {
    return formatDate(props, dateTimeProps);
  };

  const number = props => {
    let {
      data,
      field,
      fieldValue,
      format,
      fieldDef: {
        decimalPlace: propsDecimalPlace,
        lpad: lPadCount,
        rpad: rpadCount,
        padString = " ",
        format: fieldDefFormat
      } = {}
    } = props;
    let { decimalPlace: defaultDecimalPlace, format: defaultFormat } = numberProps || {};
    let decimalPlace = propsDecimalPlace !== undefined ? propsDecimalPlace : defaultDecimalPlace;

    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    if (value === null || value === undefined) {
      return value;
    }
    format = format || fieldDefFormat || defaultFormat;

    if (format) {
      value = numberFormatter(value, format);
    } else {
      value = Number(value).toFixed(decimalPlace);
    }

    if (lPadCount) {
      value = lpad({ value, padCount: lPadCount, padString });
    } else if (rpadCount) {
      value = rpad({ value, padCount: rpadCount, padString });
    }
    return value;
  };

  const decimal = props => {
    let {
      data,
      field,
      fieldValue,
      format,
      fieldDef: { format: fieldDefFormat, decimalPlace: propsDecimalPlace } = {}
    } = props;
    let { decimalPlace: defaultDecimalPlace, format: defaultFormat } = decimalProps || {};
    let decimalPlace = propsDecimalPlace !== undefined ? propsDecimalPlace : defaultDecimalPlace;
    let value = fieldValue !== undefined ? fieldValue : resolveValue(data, field);
    if (value === null || value === undefined) {
      return value;
    }
    format = format || fieldDefFormat || defaultFormat;
    if (format) {
      value = numberFormatter(value, format);
    } else {
      value = Number(value).toFixed(decimalPlace);
    }
    return value;
  };

  const Formatter = {
    date,
    dateTime,
    number,
    decimal,
    text,
    reference,
    multiReference,
    option,
    currency,
    duration,
    googlePlace,
    boolean,
    fileSize,
    numberFormatter
  };
  return Formatter;
};
