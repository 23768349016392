export default () => {
  const openActionSprite = {
    uri: "/webImages/openActionIcons/1_sprite.png",
    addGroupMemberIcon: {
      width: 19,
      height: 21,
      objectPosition: "-13px -12px",
      objectFit: "none"
    },
    leaveGroupIcon: {
      width: 19,
      height: 21,
      objectPosition: "-47px -114px",
      objectFit: "none"
    },
    removeGroupIcon: {
      width: 23,
      height: 19,
      objectPosition: "-45px -81px",
      objectFit: "none"
    },
    createWithCircleGreyIcon: {
      width: 18,
      height: 18,
      objectPosition: "-13px -81px",
      objectFit: "none"
    },
    groupPinIcon: {
      width: 22,
      height: 22,
      objectPosition: "-44px -208px",
      objectFit: "none"
    }
  };

  const addGroupMemberIcon = {
    uri: openActionSprite.uri,
    spriteProps: {
      ...openActionSprite.addGroupMemberIcon
    }
  };
  const leaveGroupIcon = {
    uri: openActionSprite.uri,
    spriteProps: {
      ...openActionSprite.leaveGroupIcon
    }
  };
  const removeGroupIcon = {
    uri: openActionSprite.uri,
    spriteProps: {
      ...openActionSprite.removeGroupIcon
    }
  };
  const createWithCircleGreyIcon = {
    uri: openActionSprite.uri,
    spriteProps: {
      ...openActionSprite.createWithCircleGreyIcon
    }
  };
  // const groupPinIcon = {
  //   uri: openActionSprite.uri,
  //   spriteProps: {
  //     ...openActionSprite.groupPinIcon
  //   }
  // };
  const groupPinIcon = "/webImages/openActionIcons/groupPin.png";

  return {
    addGroupMemberIcon,
    leaveGroupIcon,
    removeGroupIcon,
    createWithCircleGreyIcon,
    groupPinIcon
  };
};
