import React from "react";
import {
  StatusBar as LoadingIndicator,
  withContext,
  setAfterLoginLink,
  getIntroductionLink,
  setJoinInfo
} from "../../../FsCloudComponent";

class BePart extends React.Component {
  constructor(props) {
    super(props);
    const { user, activeMQ, replaceUri, hashMap: { sharedToken, origin } = {} } = props;
    let link = void 0;
    if (origin === "album") {
      link = {
        uri: "/home#gallery",
        nestedTab: "albums"
      };
    } else if (origin === "set") {
      link = {
        uri: "/home#docs",
        nestedTab: "docsSets"
      };
    }
    setJoinInfo && setJoinInfo({ sharedToken, origin });
    let introductionLink = getIntroductionLink && getIntroductionLink({ user, activeMQ });
    if (introductionLink || !user) {
      if (link) {
        setAfterLoginLink(link);
      }
      replaceUri(introductionLink || { uri: "/landing" });
    } else {
      replaceUri(link || { uri: "/home#gallery" });
    }
  }

  render() {
    return <LoadingIndicator />;
  }
}

BePart = withContext(BePart, {
  activeMQ: "ActiveMQ",
  user: "User.user",
  replaceUri: "Router.replaceUri"
});

export default {
  BePart: {
    Component: BePart,
    component: "custom",
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true
  }
};
