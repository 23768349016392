import { GroupMemberCardSMListProps, GroupMembersWebComponent } from "./GroupCards";
import { groupMembersQuery } from "../queries";
import { FSList, Platform } from "../../../FsCloudComponent";

export default {
  GroupMembersSM: {
    component: "table",
    dataObject: "GroupMember",
    Component: FSList,
    componentPropsSM: {
      ...GroupMemberCardSMListProps
    },
    connectProps: {
      afterFetch: async (state, { user }) => {
        let { data = [] } = state;
        let isGroupOwner = user && user.group && user.group.is_group_owner;
        if (!isGroupOwner && data && data.length) {
          data = data.filter(row => (row.accepted ? true : false));
        }
        return { state: { ...state, data } };
      }
    },
    selectable: false,
    ...groupMembersQuery,
    headerActions: [
      {
        type: "multiActions",
        selectionRequired: false,
        action: Platform.OS === "web" ? ["leaveGroup", "removeGroup", "addMemberWeb"] : ["leaveGroup", "removeGroup"]
      }
    ]
  },
  GroupMembersMD: {
    component: "table",
    dataObject: "GroupMember",
    ...groupMembersQuery,
    Component: GroupMembersWebComponent,
    headerActions: [
      {
        type: "multiActions",
        image: "removeGroupIcon",
        visible: ({ user }) => user && user.group && user.group.is_group_owner,
        action: "removeGroup"
      },
      {
        type: "multiActions",
        image: "leaveGroupIcon",
        visible: ({ user }) => user && user.group && !user.group.is_group_owner,
        action: "leaveGroup"
      },
      {
        type: "multiActions",
        image: "addGroupMemberIcon",
        visible: ({ user }) => user && user.group && user.group.is_group_owner,
        action: "addMemberWeb"
      }
    ]
  }
};
