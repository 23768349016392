import React from "react";
import { shallowCompare } from "app-utility-functions";

export default ({ Box, ScreenContextProvider, resolveMQ, resolveActions, theme = {} }) => {
  class NavLeft extends React.Component {
    shouldComponentUpdate(nextProps) {
      let equal = shallowCompare(nextProps, this.props, { name: "NavLeft" });
      return !equal;
    }
    render() {
      const { screenState, activeMQ, actions, index } = this.props;
      const resolvedActions = resolveActions && resolveActions({ actions, activeMQ, index });
      if (!resolvedActions || !resolvedActions.length) {
        return null;
      }
      const { boxStyle, separatorStyle } = resolveMQ(theme, ["boxStyle", "separatorStyle"], activeMQ);
      return (
        <ScreenContextProvider state={screenState}>
          <Box
            {...boxStyle}
            getSeparator={({ separator, textStyle }) => {
              return <Box textStyle={textStyle} {...separatorStyle} text={separator} />;
            }}
            render={[...resolvedActions]}
          />
        </ScreenContextProvider>
      );
    }
  }
  return NavLeft;
};
