import React from "react";
import { Box, View, Text, getImage, I18N, theme, NavStatusBar } from "../../../../FsCloudComponent";
import { portUploadAndDownloadToMetadata } from "./PortingMetadataUtils";

const { fonts, colors, bgs, shadows } = theme;
const { h1, h3, h2, h2_l_18, h16 } = fonts;
const { themeColor, primaryColor, brownGrey, highlightColor } = colors;
const { primaryBorder, lightThemeBg } = bgs;

const highlights = [
  {
    title: "unlimitedSpaceOnYourDevice",
    description: "unlimitedSpaceOnYourDeviceDescription",
    buttonText: "freeUpCaps",
    image: "highlightFreeUpSpaceIcon"
  },
  {
    title: "yourMemoriesSafeSound",
    description: "yourMemoriesSafeSoundDescription",
    buttonText: "backupCaps",
    image: "highlightBackUpIcon",
    imageContainerStyle: {
      viewStyle: { marginTop: 31 }
    }
  },
  {
    title: "JustForTheGroup",
    description: "JustForTheGroupDescription",
    buttonText: "startCaps",
    image: "highlightGroupIcon",
    imageContainerStyle: {
      viewStyle: { marginTop: 36 }
    }
  }
];

class UploadPortingProgress extends React.Component {
  state = { portPercentage: 0 };

  portData = async () => {
    const { onComplete, totalCount } = this.props;
    await portUploadAndDownloadToMetadata(processedCount => {
      if (totalCount === 0) {
        return;
      }
      if (processedCount >= totalCount) {
        this.setState({ portPercentage: 99 });
        return;
      }
      let portPercentage = (processedCount / totalCount) * 100;
      if (portPercentage !== this.state.portPercentage) {
        this.setState({ portPercentage });
      }
    });
    onComplete && onComplete();
  };

  componentDidMount() {
    this.portData();
  }

  getCardComponent = row => {
    if (!row) {
      return;
    }
    let { title, description, buttonText, image, imageContainerStyle } = row;
    return (
      <Box
        key={title}
        direction="row"
        viewStyle={{
          minHeight: 135,
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 12,
          marginRight: 12,
          borderRadius: 2,
          ...shadows.cardShadow
        }}
        render={[
          {
            width: "1fr",
            viewStyle: { paddingLeft: 12, paddingTop: 12, paddingRight: 5, paddingBottom: 11 },
            render: [
              {
                height: "1fr",
                render: [
                  {
                    textStyle: { color: primaryColor, ...h16 },
                    text: I18N.t(title)
                  },
                  {
                    viewStyle: { paddingTop: 5 },
                    textStyle: { color: brownGrey, ...h2_l_18 },
                    text: I18N.t(description)
                  }
                ]
              },
              {
                viewStyle: {
                  backgroundColor: lightThemeBg,
                  borderRadius: 2,
                  marginTop: 8,
                  paddingRight: 14,
                  paddingLeft: 14,
                  paddingTop: 5,
                  paddingBottom: 5,
                  alignSelf: "flex-start"
                },
                textStyle: { color: themeColor, ...h2 },
                text: I18N.t(buttonText)
              }
            ]
          },
          image && { ...imageContainerStyle, image: getImage(image) }
        ]}
      />
    );
  };

  render() {
    let progress = parseInt(this.state.portPercentage || 0);
    if (progress >= 100) {
      progress = 99;
    }
    let progressComponent = (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          borderRadius: 4,
          padding: 20,
          margin: 20,
          backgroundColor: highlightColor
        }}
      >
        <View style={{ paddingBottom: 5 }}>
          <Text style={{ color: primaryColor, ...h1 }}>{`${progress}%`}</Text>
        </View>
        <View
          style={{
            width: "100%",
            height: 10,
            backgroundColor: "white",
            borderColor: primaryBorder,
            borderWidth: 1,
            borderRadius: 5,
            overflow: "hidden"
          }}
        >
          <View
            style={{
              width: `${progress}%`,
              height: 8,
              backgroundColor: themeColor
            }}
          />
        </View>
        <View style={{ paddingTop: 8 }}>
          <Text style={{ color: primaryColor, ...h3 }}>{`${I18N.t("portWaitMessage")}`}</Text>
        </View>
      </View>
    );
    return (
      <Box
        style={{ flex: 1 }}
        render={[
          <View style={{ position: "absolute", top: 350, left: 0, right: 0 }}>{progressComponent}</View>,
          {
            viewStyle: { flex: 1, backgroundColor: "rgba(0, 0, 0, 0.05)", zIndex: -1 },
            render: [
              <NavStatusBar.Component />,
              {
                direction: "row",
                viewStyle: {
                  height: 64,
                  alignItems: "center"
                },
                render: [
                  {
                    viewStyle: { paddingLeft: 16 },
                    image: getImage("menuIcon")
                  },
                  {
                    viewStyle: {
                      flex: 1,
                      height: 32,
                      paddingLeft: 12,
                      paddingRight: 12,
                      justifyContent: "center"
                    },
                    textStyle: {
                      ...h16,
                      color: themeColor,
                      numberOfLines: 1
                    },
                    text: I18N.t("app-title")
                  }
                ]
              },
              ...highlights.map(highlight => this.getCardComponent(highlight))
            ]
          }
        ]}
      />
    );
  }
}

export default UploadPortingProgress;
