const h1 = {
  fontSize: "14px",
  fontFamily: "Lexend-Bold"
};

const h1_21 = {
  fontSize: "14px",
  fontFamily: "Lexend-Bold",
  lineHeight: "21px"
};
const h1_16 = {
  fontSize: "14px",
  fontFamily: "Lexend-Bold",
  lineHeight: "16px"
};

const h1_18 = {
  fontSize: "14px",
  fontFamily: "Lexend-Bold",
  lineHeight: "18px"
};

const h1_78 = {
  fontSize: "14px",
  fontFamily: "Lexend-Bold",
  letterSpacing: 0.78
};

const h2 = {
  fontSize: "12px",
  fontFamily: "Lexend-Bold"
};
const h2_18 = {
  fontSize: "12px",
  fontFamily: "Lexend-Bold",
  lineHeight: "18px"
};
const h2_17 = {
  fontSize: "12px",
  fontFamily: "Lexend-Bold",
  letterSpacing: 0.17
};
const h3 = {
  fontSize: "12px",
  fontFamily: "Lexend-Regular"
};
const h3_21 = {
  fontSize: "12px",
  fontFamily: "Lexend-Regular",
  lineHeight: "21px"
};
const h3MD = {
  fontSize: "14px",
  fontFamily: "Lexend-Regular"
};
const h4 = {
  fontSize: "10px",
  fontFamily: "Lexend-Bold"
};

const h5 = {
  fontSize: "10px",
  fontFamily: "Lexend-Regular"
};

const h5_16 = {
  fontSize: "10px",
  fontFamily: "Lexend-Regular",
  lineHeight: "16px"
};

const h6 = {
  fontSize: "8px",
  fontFamily: "Lexend-Bold"
};
const h7 = {
  fontSize: "8px",
  fontFamily: "Lexend-Regular"
};
const h8 = {
  fontSize: "10px",
  fontFamily: "Lexend-Bold"
};
const h11_4 = {
  fontSize: "11.4px",
  fontFamily: "Lexend-Regular"
};
const h9 = {
  fontSize: "14px",
  fontFamily: "Lexend-Regular"
};

const h9_24 = {
  fontSize: "14px",
  fontFamily: "Lexend-Regular",
  lineHeight: "24px"
};

const h9_47 = {
  fontSize: "14px",
  fontFamily: "Lexend-Regular",
  letterSpacing: 0.47
};

const h9_18 = {
  fontSize: "14px",
  fontFamily: "Lexend-Regular",
  lineHeight: "18px"
};

const h9_20 = {
  fontSize: "14px",
  fontFamily: "Lexend-Regular",
  lineHeight: "20px"
};
const h9_21 = {
  fontSize: "14px",
  fontFamily: "Lexend-Regular",
  lineHeight: "21px"
};

const h10 = {
  fontSize: "12px",
  fontFamily: "Lexend-Regular"
};
const h16_l = {
  fontSize: "16px",
  fontFamily: "Lexend-Regular"
};
const h16_23 = {
  fontSize: "16px",
  fontFamily: "Lexend-Regular",
  letterSpacing: 0.23
};

const h16 = {
  fontSize: "16px",
  fontFamily: "Lexend-Bold"
};
const h20_l = {
  fontSize: "20px",
  fontFamily: "Lexend-Regular"
};
const h20 = {
  fontSize: "20px",
  fontFamily: "Lexend-Bold"
};
const h24_xl = {
  fontSize: "24px",
  fontFamily: "Lexend-Bold"
};
const h24 = {
  fontSize: "24px",
  fontFamily: "Lexend-Regular"
};
const h18 = {
  fontSize: "18px",
  fontFamily: "Lexend-Regular"
};
const h18_bl = {
  fontSize: "18px",
  fontFamily: "Lexend-Bold"
};
const h22 = {
  fontSize: "22px",
  fontFamily: "Lexend-Bold"
};

const h34_51 = {
  fontFamily: "Lexend-Bold",
  fontSize: "34px",
  lineHeight: "51px"
};

const h36_75 = {
  fontFamily: "Lexend-Bold",
  fontSize: "36px",
  lineHeight: "75px"
};

const h16_16 = {
  fontFamily: "Lexend-Bold",
  fontSize: "16px",
  lineHeight: "16px"
};

const h12_24 = {
  fontFamily: "Lexend-Bold",
  fontSize: "12px",
  lineHeight: "24px"
};

const h16_18 = {
  fontFamily: "Lexend-Bold",
  fontSize: "16px",
  lineHeight: "18px"
};

const h16_24 = {
  fontFamily: "Lexend-Bold",
  fontSize: "16px",
  lineHeight: "24px"
};

const h2_16 = {
  fontFamily: "Lexend-Bold",
  fontSize: "12px",
  lineHeight: "16px"
};

const h16_l_24 = {
  fontFamily: "Lexend-Regular",
  fontSize: "16px",
  lineHeight: "24px"
};

const h16_l_22 = {
  fontFamily: "Lexend-Regular",
  fontSize: "16px",
  lineHeight: "22px"
};

const h18_l_22 = {
  fontFamily: "Lexend-Regular",
  fontSize: "18px",
  lineHeight: "22px"
};

const h16_l_20 = {
  fontFamily: "Lexend-Regular",
  fontSize: "16px",
  lineHeight: "20px"
};

const h16_l_18 = {
  fontFamily: "Lexend-Regular",
  fontSize: "16px",
  lineHeight: "18px"
};

const h2_l_18 = {
  fontFamily: "Lexend-Regular",
  fontSize: "12px",
  lineHeight: "18px"
};

const h2_l_16 = {
  fontFamily: "Lexend-Regular",
  fontSize: "12px",
  lineHeight: "16px"
};

const h2_l_24 = {
  fontFamily: "Lexend-Regular",
  fontSize: "12px",
  lineHeight: "24px"
};

const h20_24 = {
  fontFamily: "Lexend-Bold",
  fontSize: "20px",
  lineHeight: "24px"
};

const h20_22 = {
  fontFamily: "Lexend-Bold",
  fontSize: "20px",
  lineHeight: "22px"
};

const h14_24 = {
  fontFamily: "Lexend-Bold",
  fontSize: "14px",
  lineHeight: "24px"
};

const h50 = {
  fontFamily: "Lexend-Bold",
  fontSize: "50px"
};
const h32 = {
  fontFamily: "Lexend-Bold",
  fontSize: "32px"
};

const h1_24 = {
  fontSize: "14px",
  fontFamily: "Lexend-Bold",
  lineHeight: "24px"
};

export default {
  h1_24,
  h18_bl,
  h22,
  h1,
  h1_78,
  h2,
  h2_18,
  h3,
  h3MD,
  h4,
  h5,
  h6,
  h7,
  h20_l,
  h20,
  h24_xl,
  h24,
  h16_l,
  h8,
  h9,
  h9_47,
  h10,
  h18,
  h16,
  h34_51,
  h36_75,
  h16_16,
  h16_18,
  h16_24,
  h2_16,
  h16_l_24,
  h16_l_22,
  h16_l_20,
  h16_l_18,
  h2_l_18,
  h2_l_24,
  h16_23,
  h2_17,
  h1_16,
  h9_20,
  h9_18,
  h9_21,
  h9_24,
  h11_4,
  h20_24,
  h5_16,
  h12_24,
  h18_l_22,
  h14_24,
  h2_l_16,
  h50,
  h20_22,
  h32,
  h1_21,
  h3_21,
  h1_18
};
