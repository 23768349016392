import React from "react";
import ActiveMQContext from "./ActiveMQContext";

const getActiveMQ = ({ breakPoints, width, breakPointMap }) => {
  if (breakPoints && breakPoints.length && width) {
    for (var index = breakPoints.length - 1; index >= 0; index--) {
      let breakPoint = breakPoints[index];
      if (width >= breakPoint.minWidth) {
        return breakPointMap[breakPoint.mq];
      }
    }
  }
};

export default ({ breakPoints = [], renderProps }) => {
  // eslint-disable-next-line
  let _width = void 0;
  let _activeMQ = void 0;

  const breakPointMap = breakPoints.reduce((accum, value, index) => {
    accum[value.mq] = { mq: value.mq, index };
    return accum;
  }, {});

  class ActiveMQ extends React.Component {
    render() {
      let { width } = this.props;
      let activeMQ = getActiveMQ({
        breakPoints,
        width,
        breakPointMap
      });
      return <ActiveMQContext.Provider value={activeMQ}>{renderProps(this.props)}</ActiveMQContext.Provider>;
    }
  }

  const setWidth = width => {
    _width = width;
    _activeMQ = getActiveMQ({
      breakPoints,
      width,
      breakPointMap
    });
  };

  const resolveImage = image => {
    if (!image || typeof image === "string") {
      return image;
    }
    let { mq, index } = _activeMQ;
    let mqValue = image[`${mq}`];
    if (mqValue === undefined && index !== undefined && breakPoints) {
      for (var i = index - 1; i >= 0; i--) {
        let breakPoint = breakPoints[i];
        let breakPointMQValue = image[`${breakPoint.mq}`];
        if (breakPointMQValue !== undefined) {
          mqValue = breakPointMQValue;
          break;
        }
      }
    }
    return mqValue;
  };

  return {
    Component: ActiveMQ,
    setWidth,
    resolveImage
  };
};
