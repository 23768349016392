import React from "react";
export default ({ Box, isImage, FileComponent, ImageComponent }) => {
  class FileRender extends React.Component {
    getCompopnent = ({ value }) => {
      if (!value) {
        return;
      }
      let propsToProvide = {
        ...this.props,
        value
      };
      if (isImage(value.name)) {
        return <ImageComponent {...propsToProvide} />;
      } else {
        return FileComponent ? <FileComponent {...propsToProvide} /> : <ImageComponent {...propsToProvide} />;
      }
    };

    render() {
      let { value } = this.props;
      if (!value) {
        return null;
      }
      if (Array.isArray(value)) {
        return (
          <Box
            direction="row"
            render={value.map(row => ({
              viewStyle: { padding: 12 },
              render: this.getCompopnent({ value: row })
            }))}
          />
        );
      } else {
        return this.getCompopnent({ value });
      }
    }
  }
  return FileRender;
};
