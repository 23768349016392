import React from "react";
import { Box, theme, getImage, I18N, RowAction, StatusBar } from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import { SECURE_TYPES } from "../../../Constants";

const { fonts, colors } = theme;
const { h16 } = fonts;
const { themeColor } = colors;

const headerContainerStyle = {
  ...theme.navHeaderStyle.boxStyleSM
};

const actionStyle = {
  viewStyle: {
    width: 44,
    height: 44,
    alignItems: "center",
    justifyContent: "center"
  }
};

class UploadGalleryHeader extends React.Component {
  render() {
    let { loadingMore, data, onClose } = this.props;
    return (
      <Box
        {...headerContainerStyle}
        render={[
          {
            ...actionStyle,
            image: getImage("backIcon"),
            onClick: onClose
          },
          {
            viewStyle: {
              paddingLeft: 8,
              paddingRight: 8
            },
            textStyle: {
              ...h16,
              color: themeColor
            },
            width: "1fr",
            text: () => I18N.t("selectItems")
          },
          loadingMore && <StatusBar size={"small"} position={"relative"} />,
          <RowAction data={data} action={[{ ...ResourceActions["layout"], visible: true }]}>
            <Box {...actionStyle} image={getImage("iconMenuGrey")} />
          </RowAction>
        ]}
      />
    );
  }
}

class GalleryFolderAssetsHeader extends React.Component {
  render() {
    let { loadingMore, selectedIds, data, onClose, unSelectAll, secureType } = this.props;
    let selectionCount = selectedIds && selectedIds.length;
    return (
      <Box
        {...headerContainerStyle}
        render={[
          {
            ...actionStyle,
            image: getImage("backIcon"),
            onClick: selectionCount ? unSelectAll : onClose
          },
          {
            viewStyle: {
              paddingLeft: 8,
              paddingRight: 8
            },
            textStyle: {
              ...h16,
              color: themeColor
            },
            width: "1fr",
            text: selectionCount ? `${selectionCount} ${I18N.t("selected")} ` : I18N.t("selectItemsDot")
          },
          loadingMore && <StatusBar size={"small"} position={"relative"} />,
          selectionCount && {
            viewStyle: {
              justifyContent: "center",
              marginLeft: 16
            },
            render: (
              <RowAction
                data={data}
                action={
                  secureType === SECURE_TYPES.VAULT
                    ? ResourceActions.uploadVaultGalleryMobileAction
                    : ResourceActions.uploadGalleryMobileAction
                }
              />
            )
          },
          <RowAction
            data={data}
            action={[
              ResourceActions.selectNone,
              {
                ...ResourceActions.uploadSelectAll,
                visible: (data, { allSelected }) => !loadingMore && !allSelected && data && data.length
              },
              {
                ...ResourceActions.layout,
                visible: !selectionCount
              }
            ]}
          >
            <Box {...actionStyle} image={getImage("iconMenuGrey")} />
          </RowAction>
        ]}
      />
    );
  }
}

export { UploadGalleryHeader, GalleryFolderAssetsHeader };
