import { STREAM_TOKEN_TABLE } from "./../../Constants";
import uuid from "uuid/v4";
import { AsyncStorage } from "react-components";

const VIDEO_STREAM_TOKEN_KEY = "video_token";

export default ({
  isEncrypted,
  getDecryptionProps,
  getEncryptionKeyAndIV,
  firebaseSave,
  getVideoStreamUrl,
  removeStreamToken,
  Platform,
  getUser
}) => {
  const resetToken = async () => {
    try {
      let token = await AsyncStorage.getItem(VIDEO_STREAM_TOKEN_KEY);
      token && (await unsetToken(token));
    } catch (err) {
      console.warn(err, "@@@:ERROR:: in reset token ");
    }
  };

  const fetchKeyAndIv = async (resource, decryptionSourceProps) => {
    let decryptionProps = getDecryptionProps(resource, decryptionSourceProps);
    console.log("{}{}{}{}{}{}: keykeykey: ", getEncryptionKeyAndIV(decryptionProps));
    return await getEncryptionKeyAndIV(decryptionProps);
  };

  const setToken = async (resource, decryptionSourceProps) => {
    let url, size, uri;
    try {
      await resetToken();
      const {
        uri: fileUri, // native
        type,
        encrypted,
        converted_mp4_url,
        converted_mp4_size,
        resource_url,
        resource_size,
        _id: resourceId
      } = resource;
      uri = fileUri;
      url = converted_mp4_url;
      size = converted_mp4_size;
      if (!url) {
        url = resource_url;
        size = resource_size;
      }
      if (isEncrypted(resource, url)) {
        const { key } = await fetchKeyAndIv(resource, decryptionSourceProps);
        const token = uuid();
        let { uid } = getUser() || {};
        let dataToInsert = {
          resourceId,
          Platform: Platform.OS,
          _id: token,
          url,
          size,
          type,
          key,
          encrypted,
          decryptionSourceProps,
          _createdOn: new Date(),
          _createdBy: { firebaseUid: uid }
        };

        dataToInsert = JSON.parse(JSON.stringify(dataToInsert));
        await firebaseSave({ table: STREAM_TOKEN_TABLE, insert: dataToInsert });
        await AsyncStorage.setItem(VIDEO_STREAM_TOKEN_KEY, token);
        return { token, url: getVideoStreamUrl(token) };
      }
    } catch (error) {
      console.warn(`@@@Error [${error}] at DecryptedUtility.js [setToken]`);
    }
    return { url: uri || url };
  };

  const unsetToken = async token => {
    try {
      if (token) {
        await removeStreamToken({ token });
        await AsyncStorage.removeItem(VIDEO_STREAM_TOKEN_KEY);
      }
    } catch (error) {
      console.warn(error, "@@@@unable to remove streaming token");
    }
  };

  return { setToken, unsetToken, fetchKeyAndIv };
};
