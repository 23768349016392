export default {
  "/music": {
    screen: "MusicTab"
  },
  "/upload-music": {
    screen: "UploadMusic"
  },
  "/select-music": {
    screen: "SelectMusic"
  },
  "/select-playlist": {
    screen: "SelectPlaylist"
  },
  "/playlist-detail": {
    screen: "PlaylistDetail"
  },
  "/music-detail": {
    screen: "MusicDetail"
  },
  "/playlist-music-detail": {
    screen: "PlaylistMusicDetail"
  },
  "/artist-music-detail": {
    screen: "ArtistMusicDetail"
  },
  "/search-music-detail": {
    screen: "SearchMusicDetail"
  },
  "/artist-detail": {
    screen: "ArtistDetail"
  },
  "/archive-music-detail": {
    screen: "ArchiveMusicDetail"
  }
};
