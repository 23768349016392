import React from "react";
import { reformatMobileNumber } from "../Utility";
import { resetFuncion } from "../../FsCloudAppServices"
export default ({
  Box,
  ResendOtp,
  theme,
  LoginContainer,
  Editor,
  I18N,
  Platform,
  showMessage,
  afterLogin,
  resendPincode,
  Button,
  SmsReader,
  ChatLinkComponent,
  logFirebaseAnalyticsEvent,
  getErrorMessage
}) => {
  const { images, colors, fonts } = theme;
  const { h1_78, h18_bl, h3 } = fonts;
  const { primaryColor, themeColor } = colors;

  const { startListener, removeListener } = SmsReader;
  class ValidateOtpView extends React.Component {
    state = {};

    _setState = state => {
      this.setState(state);
    };

    onError = (err, errState) => {
      let message = getErrorMessage(err);
      this.setState({ error: message, ...errState });
    };

    componentDidMount() {
      if (Platform.OS === "android") {
        this.startListener();
      }
    }

    onMessageReceived = message => {
      removeListener();
      if (message.includes("Timeout Error.")) {
        this.startListener();
      } else {
        let code = message.match(/\d+/) && message.match(/\d+/)[0];
        if (code) {
          let { data, setValue } = this.loadingContainerProps;
          setValue({ data: data, field: "otp", value: code });
          this.validateOtp();
        }
      }
    };

    startListener = async () => {
      try {
        await startListener(this.onMessageReceived);
      } catch (err) {
        this.onError(err);
      }
    };

    componentWillUnmount() {
      if (Platform.OS === "android") {
        removeListener();
      }
    }

    validateOtp = () => {
      const { link: { props: { transaction_id, fromLandline, mobile } = {} } = {} } = this.props;
      let { data, onSubmit } = this.loadingContainerProps;

      if (this.state.error) {
        this.setState({ error: void 0 });
      }
      logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ screen: "login", event: "otp" });
      onSubmit &&
        onSubmit(
          "validateOtp",
          {
            otp: data.otp,
            transaction_id,
            fromLandline,
            mobile
          },
          {
            afterSubmit: result => {
              afterLogin && afterLogin(result, this.props);
            },
            throwError: true
          }
        ).catch(err => {
          this.onError(err);
        });
    };

    resendOtp = () => {
      const { link: { props: { transaction_id } = {} } = {} } = this.props;
      if (this.isResend || !resendPincode) {
        return;
      }
      this.isResend = true;
      resendPincode({ transaction_id })
        .then(_ => {
          showMessage && showMessage(I18N.t("codeSentMessage"), 2000);
          this.isResend = false;
        })
        .catch(err => {
          this.isResend = false;
          this.onError(err);
        });
    };

    render() {
      const { link = {} } = this.props;
      const { props: { mobile } = {} } = link;
      let contentContainerStyle = { flex: 1, minHeight: 450 };
      return (
        <LoginContainer
          scrollViewStyle={{
            style: { flex: 1 },
            contentContainerStyle: Platform.OS === "web" ? {} : contentContainerStyle
          }}
        >
          {props => {
            this.loadingContainerProps = props;
            let editorProps = {
              ...props,
              state: this.state,
              setState: this._setState
            };
            return (
              <Box
                viewStyle={{
                  ...(Platform.OS !== "web" ? { flex: 1 } : contentContainerStyle),
                  paddingLeft: 27,
                  paddingRight: 27
                }}
                render={[
                  {
                    viewStyle: { flex: 1 },
                    render: [
                      {
                        image: images.mobileIcon,
                        viewStyle: {
                          paddingTop: 30,
                          paddingBottom: 12,
                          alignSelf: "center"
                        }
                      },
                      {
                        viewStyle: {
                          paddingTop: 6,
                          paddingBottom: 6
                        },
                        textStyle: {
                          ...h18_bl,
                          color: primaryColor,
                          textAlign: "center"
                        },
                        text: I18N.t("otpPrimaryText")
                      },
                      {
                        viewStyle: {
                          paddingTop: 6,
                          paddingBottom: 6
                        },
                        textStyle: {
                          ...h1_78,
                          color: themeColor,
                          textAlign: "center"
                        },
                        text: reformatMobileNumber(mobile)
                      },
                      {
                        viewStyle: { paddingTop: 16, paddingBottom: 8 },
                        render: (
                          <Editor
                            {...editorProps}
                            field={"otp"}
                            placeholder={"otpPlaceholder"}
                            inputStyle={Platform.OS === "web" ? { ...h3, color: primaryColor } : void 0}
                            onClick={this.validateOtp}
                          />
                        )
                      },
                      {
                        viewStyle: {
                          paddingTop: 8,
                          paddingBottom: 16,
                          alignItems: "center"
                        },
                        render: [<ResendOtp onClick={this.resendOtp} time={30} />]
                      },
                      {
                    viewStyle: { paddingBottom: 16 },
                     render: this.state.error && 
                      <Button
                      onClick={resetFuncion}
                      text={"changePartner"}
                    />},
                      <Button
                        loading={props.loading}
                        onClick={this.validateOtp}
                        text={"continue"}
                        loaderText={"validating"}
                      />,
                      {
                        viewStyle: { alignItems: "center", marginBottom: 18 },
                        render: <ChatLinkComponent />
                      }
                    ]
                  },
                ]}
              />
            );
          }}
        </LoginContainer>
      );
    }
  }
  return ValidateOtpView;
};
