import React from "react";

export default ({
  Box,
  getImage,
  getComponent,
  getInput,
  theme,
  I18N,
  resolveMQ,
  Footer,
  LoginContainer,
  WebLoginOption,
  WebLogin,
  WebValidateOtp,
  WebRegister,
  AppConfig
}) => {
  const { fonts, colors, bgs } = theme;
  const { highlightColor, themeColor } = colors;
  const { h34_51, h16_18, h36_75 } = fonts;
  const { themeBg } = bgs;

  const { APP_IOS_URL, APP_ANDROID_URL, APP_REGISTRATION_SOURCE } = AppConfig;

  const components = {
    loginOption: WebLoginOption,
    login: WebLogin,
    validateOtp: WebValidateOtp
  };

  const styles = {
    styleLG: {
      outerContainerStyle: {
        viewStyle: {
          flex: 1,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }
      },
      bodyStyle: {
        viewStyle: {
          alignItems: "center",
          flex: 1,
          overflow: "auto"
        }
      },
      containerStyle: {
        width: 370,
        viewStyle: {
          paddingTop: 65,
          paddingBottom: 65
        }
      },
      messageStyle: {
        viewStyle: {
          paddingTop: 34
        },
        textStyle: {
          ...h34_51,
          color: highlightColor,
          textAlign: "center"
        }
      },
      iconBoxStyle: {
        viewStyle: {
          paddingTop: 30,
          justifyContent: "center"
        }
      },
      iconCloudBoxStyle: {
        viewStyle: {
          paddingTop: 30,
          justifyContent: "center",
          width: 20
        }
      },
      loginButtonStyle: {
        viewStyle: {
          marginTop: 60,
          paddingTop: 15,
          paddingBottom: 15,
          backgroundColor: themeBg,
          borderRadius: 4,
          alignItems: "center",
          cursor: "pointer"
        },
        textStyle: { ...h16_18, color: highlightColor }
      },
      loginPopupContainerStyle: {
        viewStyle: {
          width: 400,
          borderBottomWidth: 4,
          borderBottomColor: themeColor,
          borderRadius: 4
        }
      }
    },
    styleMD: {
      outerContainerStyle: {
        viewStyle: {
          flex: 1,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }
      },
      bodyStyle: {
        viewStyle: {
          alignItems: "center",
          flex: 1,
          overflow: "auto"
        }
      },
      containerStyle: {
        viewStyle: {
          paddingTop: 158,
          paddingBottom: 158,
          paddingLeft: 60,
          paddingRight: 60,
          width: "100%"
        }
      },
      messageStyle: {
        viewStyle: {
          paddingTop: 129
        },
        textStyle: {
          ...h36_75,
          color: highlightColor,
          textAlign: "center"
        }
      },
      iconBoxStyle: {
        viewStyle: {
          paddingTop: 73,
          justifyContent: "center"
        }
      },
      loginButtonStyle: {
        viewStyle: {
          marginTop: 92,
          paddingTop: 14,
          paddingBottom: 14,
          backgroundColor: themeBg,
          borderRadius: 4,
          alignItems: "center",
          cursor: "pointer"
        },
        textStyle: { ...h16_18, color: highlightColor }
      },
      loginPopupContainerStyle: {
        viewStyle: {
          width: 440,
          borderRadius: 2.9
        }
      }
    }
  };

  class LoginPopup extends React.Component {
    componentDidMount() {
      setTimeout(() => {
        let elements = document.getElementsByClassName("loginPopup");
        if (elements[0] && elements[0].style) {
          elements[0].style.top = "50%";
          elements[0].style.opacity = 1;
        }
        let overlayElements = document.getElementsByClassName("loginOverlay");
        if (overlayElements && overlayElements.length) {
          for (let i = 0; i < overlayElements.length; i++) {
            if (overlayElements[i] && overlayElements[i].style) {
              overlayElements[i].style.opacity = 0.4;
            }
          }
        }
      }, 0);
    }

    render() {
      const { Component, containerStyle, ...restProps } = this.props;
      return (
        <div className="loginPopup">
          <Box {...containerStyle} render={<Component {...restProps} />} />
        </div>
      );
    }
  }

  class WebLandingScreen extends React.Component {
    state = {};

    _setState = state => {
      this.setState(state);
    };

    handleCheckboxChange = termsAccepted => this._setState({ termsAccepted, errMsg: void 0 });

    render() {
      const { activeMQ } = this.props;
      const { style } = resolveMQ(styles, ["style"], activeMQ);
      const LinkBox = getComponent("anchor");
      const MultipleText = getComponent("multipleText");
      const Checked = getInput("boolean");
      let { viewType } = this.state;
      let Component = viewType && components[viewType];
      if (!Component && viewType && viewType.indexOf("register-") === 0) {
        Component = WebRegister;
      }
      return (
        <LoginContainer>
          {({ loading, setValue, setState, ...props }) => {
            return (
              <Box
                {...style.outerContainerStyle}
                backgroundImage={getImage("landingBackground")}
                render={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      overflow: "auto",
                      position: "relative"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative"
                      }}
                    >
                      <Box
                        {...style.containerStyle}
                        render={[
                          {
                            viewStyle: { alignItems: "center", paddingTop: 6, paddingBottom: 6 },
                            image: getImage("landingLogo")
                          },
                          {
                            ...style.messageStyle,
                            text: I18N.t("webLandingTitle")
                          },
                          {
                            direction: "row",
                            ...style.iconBoxStyle,
                            render: [
                              <LinkBox url={APP_ANDROID_URL}>
                                <Box image={getImage("googlePlayIcon")} />
                              </LinkBox>,
                              {
                                viewStyle: { marginLeft: 21 },
                                render: (
                                  <LinkBox url={APP_IOS_URL}>
                                    <Box image={getImage("appStoreIcon")} />
                                  </LinkBox>
                                )
                              }
                            ]
                          },
                          {
                            ...style.loginButtonStyle,
                            text: I18N.t("login"),
                            onClick: () => {
                              if (!this.state.termsAccepted) {
                                this._setState({ errMsg: I18N.t("acceptTermsErrorMsg") });
                              } else {
                                if (APP_REGISTRATION_SOURCE === "landline") {
                                  this._setState({ viewType: "loginOption", errMsg: void 0 });
                                } else {
                                  this._setState({
                                    viewType: "login",
                                    registrationRequired: APP_REGISTRATION_SOURCE === "mobile",
                                    errMsg: void 0
                                  });
                                }
                              }
                            }
                          },
                          {
                            direction: "row",
                            viewStyle: { marginTop: 14, flex: 1 },
                            render: [
                              {
                                viewStyle: {
                                  marginRight: 7,
                                  borderWidth: 2,
                                  borderStyle: "solid",
                                  borderColor: this.state.errMsg ? "red" : "transparent",
                                  borderRadius: 2,
                                  height: 18
                                },
                                render: (
                                  <Checked value={this.state.termsAccepted} onChangeValue={this.handleCheckboxChange} />
                                )
                              },
                              {
                                viewStyle: { flex: 1 },
                                render: (
                                  <MultipleText
                                    value={[
                                      {
                                        textStyle: { color: "#fff", fontFamily: "Lexend-Regular" },
                                        text: I18N.t("acceptTerms")
                                      },
                                      {
                                        textStyle: {
                                          color: "#fff",
                                          textDecorationLine: "underline",
                                          marginLeft: 3,
                                          fontFamily: "Lexend-Regular"
                                        },
                                        text: I18N.t("termsAndPolicyWeb"),
                                        link: { uri: AppConfig.APP_TERMS_CONDITION_URL },
                                        external: true
                                      }
                                    ]}
                                  />
                                )
                              }
                            ]
                          }
                        ]}
                      />
                      {Component && <div className="loginOverlay" />}
                      {Component && (
                        <LoginPopup
                          containerStyle={style.loginPopupContainerStyle}
                          Component={Component}
                          state={this.state}
                          setState={this._setState}
                          loginContainerProps={{ loading, setValue, ...props }}
                          onClose={() => {
                            let { viewType } = this.state;
                            if (viewType === "validateOtp") {
                              viewType = "login";
                              if (props["data"]["otp"]) {
                                props["data"]["otp"] = void 0;
                              }
                              if (props["data"]["captchaVerify"] !== undefined) {
                                props["data"]["captchaVerify"] = void 0;
                              }
                              this._setState({ viewType });
                            } else {
                              let loginPopup = document.getElementsByClassName("loginPopup");
                              let overlayElements = document.getElementsByClassName("loginOverlay");
                              if (loginPopup && loginPopup.length) {
                                let elements = [];
                                if (overlayElements && overlayElements.length) {
                                  loginPopup = Array.prototype.slice.call(loginPopup);
                                  overlayElements = Array.prototype.slice.call(overlayElements);
                                  elements = [...loginPopup, ...overlayElements];
                                }
                                for (let i = 0; i < elements.length; i++) {
                                  if (elements[i] && elements[i].style) {
                                    elements[i].style.opacity = 0;
                                  }
                                }
                              }
                              setTimeout(() => {
                                const newState = {};
                                if (viewType === "login" && this.state.fromLandline) {
                                  newState.fromLandline = void 0;
                                }
                                newState.viewType = void 0;
                                if (props["data"]["mobile"]) {
                                  props["data"]["mobile"] = void 0;
                                }
                                if (props["data"]["captchaVerify"] !== undefined) {
                                  props["data"]["captchaVerify"] = void 0;
                                }
                                this._setState(newState);
                              }, 400);
                            }
                          }}
                        />
                      )}
                    </div>
                    <div style={{ position: "sticky", bottom: 0, right: 0, left: 0 }}>
                      <div style={{ position: "relative" }}>
                        <Footer />
                        {Component && <div className="loginOverlay" />}
                      </div>
                    </div>
                  </div>
                }
              />
            );
          }}
        </LoginContainer>
      );
    }
  }
  return WebLandingScreen;
};
