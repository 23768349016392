import React from "react";
import { ScrollView, Box, I18N, theme, getImage } from "../../../FsCloudComponent";
const { fonts, colors, bgs } = theme;
const { primaryColor, brownGrey } = colors;
const { borderBg5 } = bgs;
const { h16, h9_18, h1, h3_21 } = fonts;

class BackupComponent extends React.Component {
  state = { notifyMe: false };

  backupItemComponent = ({ image, name, itemToSelect, itemBackup, haveBorder = false }) => {
    let styleToPass = haveBorder ? { borderBottomWidth: 1, borderBottomColor: borderBg5 } : void 0;
    return (
      <Box
        direction="row"
        viewStyle={{ paddingRight: 4, paddingTop: 15, paddingBottom: 15, ...styleToPass }}
        render={[
          {
            viewStyle: { paddingRight: 12 },
            image
          },
          {
            width: "1fr",
            viewStyle: { justifyContent: "center" },
            render: [
              {
                viewStyle: { paddingBottom: 2 },
                textStyle: { color: primaryColor, ...h1 },
                text: `${name} (${itemToSelect} ${I18N.t("toSelect")})`
              },
              {
                viewStyle: { paddingTop: 2 },
                textStyle: { color: brownGrey, ...h3_21 },
                text: `${itemBackup} ${I18N.t("backedUp")}`
              }
            ]
          },
          // {
          //   viewStyle: { justifyContent: "center" },
          //   image: getImage("plusWithBorderIcon")
          // }
        ]}
      />
    );
  };

  render() {
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          viewStyle={{
            marginLeft: 16,
            marginRight: 16,
            marginBottom: 25
          }}
          render={[
            // {
            //   viewStyle: { paddingTop: 39, paddingBottom: 14, justifyContent: "center", alignItems: "center" },
            //   image: getImage("backupLogoIcon")
            // },
            {
              viewStyle: { paddingTop: 15, paddingBottom: 6, justifyContent: "center", alignItems: "center" },
              textStyle: { color: primaryColor, ...h16 },
              text: I18N.t("completeTheBackup")
            },
            {
              viewStyle: { paddingTop: 6, paddingBottom: 30, justifyContent: "center", alignItems: "center" },
              textStyle: { color: brownGrey, ...h9_18 },
              text: I18N.t("selectAllYourItems")
            },
            // this.backupItemComponent({
            //   image: getImage("galleryBackupIcon"),
            //   name: "Gallery",
            //   itemToSelect: 200,
            //   itemBackup: 4,
            //   haveBorder: true
            // }),
            // this.backupItemComponent({
            //   image: getImage("musicBackupIcon"),
            //   name: "Music",
            //   itemToSelect: 150,
            //   itemBackup: 6
            // }),
            {
              direction: "row",
              viewStyle: {
                paddingBottom: 24,
                paddingTop: 60,
                alignItems: "center"
              },
              render: [
                {
                  onClick: () => {
                    this.setState({ notifyMe: !this.state.notifyMe });
                  },
                  viewStyle: { paddingRight: 7 },
                  image: this.state.notifyMe ? getImage("notifyCheckBoxSelected") : getImage("notifyCheckBox")
                },
                {
                  textStyle: { color: primaryColor, ...h9_18 },
                  text: I18N.t("notifyUnselectedItemsMessage")
                }
              ]
            }
          ]}
        />
      </ScrollView>
    );
  }
}

export default {
  Backup: {
    component: "table",
    title: I18N.t("backupStatus"),
    Component: BackupComponent
  }
};
