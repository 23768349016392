const absoluteFillObject = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: "absolute"
};

const shadow1 = { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" };
export default {
  modalStyle: {
    // position: "fixed",//position fixed is removed beacause it was creating allignment problem in safari
    //zIndex is removed because their is no benifit of using it here Japesh 14 Aug, 2017
    //zIndex: 11, //Todo : zIndex:2 should not be here
    overflow: "auto",
    backgroundColor: "#fff",
    ...shadow1,
    display: "flex",
    maxHeight: "90%",
    flexDirection: "column"
  },
  backdropStyle: {
    ...absoluteFillObject,
    zIndex: -1,
    backgroundColor: "rgba(0, 0, 0, 0.3)"
  },
  positionModalStyle: {
    ...shadow1,
    // ...absoluteFillObject,
    position: "fixed",
    backgroundColor: "#fff",
    overflow: "auto",
    zIndex: 4,
    display: "flex",
    flexDirection: "column"
  },
  modalParentStyle: {
    ...absoluteFillObject,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    zIndex: 6 //zIndex increased from 4 to 6 as we want left menu to be hide under center position modal:Pankaj 15 Mar
  },
  positionModalParentStyle: {
    ...absoluteFillObject,
    display: "flex",
    position: "fixed",
    zIndex: 4
  },
  no_backdrop_modal_syle: {
    ...absoluteFillObject,
    backgroundColor: "transparent",
    position: "fixed",
    zIndex: -1
  },
  backDrop: {
    backgroundColor: "rgba(0, 0, 0, 0.54)"
  },
  scrollView: { position: "absolute", backgroundColor: "#fff" },
  modalSizeStyle: {
    lg: {
      maxHeight: "90%",
      maxWidth: "80%",
      width: "50%"
    },
    md: {
      maxHeight: "80%",
      maxWidth: "60%",
      width: "50%"
    },
    sm: {
      maxHeight: "60%",
      maxWidth: "40%",
      width: "40%"
    }
  }
};
