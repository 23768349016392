export const folkColumnsSM = [
  {
    label: "telResidential",
    field: "telephoneResidential"
  },
  {
    label: "cellPhone",
    field: "cellPhone"
  },
  {
    label: "fax",
    field: "fax"
  },
  {
    label: "emailMain",
    field: "mainEmail",
    isEmail: true
  },
  {
    label: "personalEmail",
    field: "personalEmail",
    isEmail: true
  },
  {
    label: "website",
    field: "website",
    isWebsite: true
  },
  {
    label: "address",
    field: "address"
  },
  {
    label: "city",
    field: "city"
  },
  {
    label: "state",
    field: "state"
  },
  {
    label: "zipCode",
    field: "zipCode"
  }
];
export const folkColumns = [
  {
    label: "telResidential",
    field: "telephoneResidential"
  },
  {
    label: "site",
    field: "website",
    isWebsite: true
  },
  {
    label: "cellPhone",
    field: "cellPhone"
  },
  {
    label: "address",
    field: "address"
  },
  {
    label: "fax",
    field: "fax"
  },
  {
    label: "city",
    field: "city"
  },
  {
    label: "emailMain",
    field: "mainEmail",
    isEmail: true
  },
  {
    label: "state",
    field: "state"
  },
  {
    label: "personalEmail",
    field: "personalEmail",
    isEmail: true
  },
  {
    label: "zipCode",
    field: "zipCode"
  }
];

export const jobColumnsSM = [
  {
    label: "tel1",
    field: "jobTel1"
  },
  {
    label: "tel2",
    field: "jobTel2"
  },
  {
    label: "telCommercial",
    field: "jobTelCommercial"
  },
  {
    label: "fax",
    field: "jobFax"
  },
  {
    label: "email",
    field: "jobEmailMain",
    isEmail: true
  },
  {
    label: "website",
    field: "jobWebsite",
    isWebsite: true
  },
  {
    label: "address",
    field: "jobAddress"
  },
  {
    label: "city",
    field: "jobCity"
  },
  {
    label: "state",
    field: "jobState"
  },
  {
    label: "zipCode",
    field: "jobZipCode"
  }
];
export const jobColumns = [
  {
    label: "tel1",
    field: "jobTel1"
  },
  {
    label: "website",
    field: "jobWebsite",
    isWebsite: true
  },
  {
    label: "tel2",
    field: "jobTel2"
  },
  {
    label: "address",
    field: "jobAddress"
  },
  {
    label: "telCommercial",
    field: "jobTelCommercial"
  },
  {
    label: "city",
    field: "jobCity"
  },
  {
    label: "fax",
    field: "jobFax"
  },
  {
    label: "state",
    field: "jobState"
  },
  {
    label: "email",
    field: "jobEmailMain",
    isEmail: true
  },
  {
    label: "zipCode",
    field: "jobZipCode"
  }
];

export const otherColumnsSM = [
  {
    label: "otherTel1",
    field: "otherTel1"
  },
  {
    label: "otherTel2",
    field: "otherTel2"
  },
  {
    label: "otherFax",
    field: "otherFax"
  },
  {
    label: "pager",
    field: "otherPager"
  },
  {
    label: "website",
    field: "otherWebsite",
    isWebsite: true
  },
  {
    label: "address",
    field: "otherAddress"
  },
  {
    label: "city",
    field: "otherCity"
  },
  {
    label: "state",
    field: "otherState"
  },
  {
    label: "zipCode",
    field: "otherZipCode"
  },
  {
    label: "comments",
    field: "otherComment"
  }
];
export const otherColumns = [
  {
    label: "otherTel1",
    field: "otherTel1"
  },
  {
    label: "address",
    field: "otherAddress"
  },
  {
    label: "otherTel2",
    field: "otherTel2"
  },
  {
    label: "city",
    field: "otherCity"
  },
  {
    label: "otherFax",
    field: "otherFax"
  },
  {
    label: "state",
    field: "otherState"
  },
  {
    label: "pager",
    field: "otherPager"
  },
  {
    label: "zipCode",
    field: "otherZipCode"
  },
  {
    label: "website",
    field: "otherWebsite",
    isWebsite: true
  },
  {
    label: "comments",
    field: "otherComment"
  }
];
