import React from "react";
import { checkCollectionExists, getErrorMessage } from "./CollectionUtility";

export default ({ Box, View, theme, getImage, getInput, resolveMQ, I18N, Header, LoadingIndicator, ItemSelector }) => {
  const { colors, fonts, bgs } = theme;
  const { themeColor, brownGrey, tomato, highlightColor } = colors;
  const { lightPink } = bgs;
  const { h20_l, h9 } = fonts;

  const metadata = {
    album: {
      headerTitle: () => I18N.t("newAlbumCaps"),
      icon: "albumNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewAlbum"),
      mandatoryError: () => I18N.t("addToAlbumValidationMessage"),
      duplicateNameError: () => I18N.t("albumDuplicateNameError"),
      inputPlaceholder: () => I18N.t("createAlbumInputPlaceholder"),
      nameLengthError: () => I18N.t("albumNameLengthMessage")
    },
    set: {
      headerTitle: () => I18N.t("newSetCaps"),
      icon: "setNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewSet"),
      mandatoryError: () => I18N.t("addToSetValidationMessage"),
      duplicateNameError: () => I18N.t("setDuplicateNameError"),
      inputPlaceholder: () => I18N.t("createSetInputPlaceholder"),
      nameLengthError: () => I18N.t("setNameLengthMessage")
    },
    playlist: {
      headerTitle: () => I18N.t("newPlaylistCaps"),
      icon: "playlistNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewPlaylist"),
      mandatoryError: () => I18N.t("addToPlaylistValidationMessage"),
      duplicateNameError: () => I18N.t("playlistDuplicateNameError"),
      inputPlaceholder: () => I18N.t("createPlaylistInputPlaceholder"),
      nameLengthError: () => I18N.t("playlistNameLengthMessage")
    },
    vaultAlbum: {
      headerTitle: () => I18N.t("newAlbumCaps"),
      icon: "albumNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewAlbum"),
      mandatoryError: () => I18N.t("addToAlbumValidationMessage"),
      duplicateNameError: () => I18N.t("albumDuplicateNameError"),
      inputPlaceholder: () => I18N.t("createAlbumInputPlaceholder"),
      nameLengthError: () => I18N.t("albumNameLengthMessage")
    },
    vaultSet: {
      headerTitle: () => I18N.t("newSetCaps"),
      icon: "setNoDataAddItemsIcon",
      primaryText: () => I18N.t("createNewSet"),
      mandatoryError: () => I18N.t("addToSetValidationMessage"),
      duplicateNameError: () => I18N.t("setDuplicateNameError"),
      inputPlaceholder: () => I18N.t("createSetInputPlaceholder"),
      nameLengthError: () => I18N.t("setNameLengthMessage")
    }
  };

  const componentTheme = {
    containerStyleSM: {
      viewStyle: {
        height: 483
      }
    },
    containerStyleMD: {
      viewStyle: {
        width: 428,
        height: 483
      }
    },
    iconStyle: {
      viewStyle: { paddingTop: 40, alignSelf: "center" },
      imageStyle: { width: 109, height: 102 }
    },
    primaryTextStyle: {
      viewStyle: {
        paddingTop: 44,
        paddingBottom: 66,
        alignSelf: "center"
      },
      textStyle: { ...h20_l, color: themeColor }
    },
    inputStyle: {
      containerStyle: {
        viewStyle: {
          marginLeft: 30,
          marginRight: 30,
          paddingLeft: 21,
          paddingRight: 21,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 4,
          backgroundColor: lightPink
        }
      },
      textInputStyle: { ...h9, color: brownGrey }
    },
    footerStyle: {
      containerStyle: {
        viewStyle: {
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 24,
          paddingBottom: 24,
          alignItems: "center"
        },
        direction: "row"
      },
      errorStyle: {
        viewStyle: {
          flex: 1,
          paddingRight: 12
        },
        textStyle: {
          ...h9,
          color: tomato,
          numberOfLines: 2
        }
      },
      addActionStyle: {
        viewStyle: {
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: themeColor,
          borderRadius: 4,
          cursor: "pointer"
        },
        textStyle: {
          ...h9,
          color: highlightColor
        },
        text: () => I18N.t("add")
      }
    }
  };

  class CreateCollectionName extends React.Component {
    state = { error: void 0, loading: false };

    componentWillUnmount() {
      this._unmounted = true;
    }

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    onChangeName = name => {
      let newState = { name };
      if (!this.state.loading) {
        if (this.state.error) {
          newState["error"] = void 0;
        }
        this._setState(newState);
      }
    };

    render() {
      let { onClose, action, activeMQ, urls, invoke } = this.props;
      let { origin } = action;
      let { name, error, loading, viewType } = this.state;
      if (viewType === "itemSelectorView") {
        return <ItemSelector origin={origin} collectionName={name} {...this.props} />;
      }

      const TextInput = getInput("text");
      const { containerStyle, iconStyle, primaryTextStyle, inputStyle, footerStyle } = resolveMQ(
        componentTheme,
        ["containerStyle", "iconStyle", "primaryTextStyle", "inputStyle", "footerStyle"],
        activeMQ
      );
      const {
        headerTitle,
        icon,
        primaryText,
        inputPlaceholder,
        mandatoryError,
        duplicateNameError,
        nameLengthError
      } = metadata[origin];
      const onAddClick = async () => {
        try {
          name = name && name.trim();
          if (!name || name.length === 0) {
            this._setState({ error: mandatoryError });
            return;
          }
          if (name.length < 3 || name.length > 55) {
            this._setState({ error: nameLengthError });
            return;
          }
          this._setState({ loading: true });
          const existsResult = await checkCollectionExists({ invoke, urls, name, type: origin });
          if (existsResult && !existsResult.exists) {
            this._setState({ loading: false, viewType: "itemSelectorView" });
          } else {
            this._setState({ error: duplicateNameError, loading: false });
          }
        } catch (e) {
          let message = getErrorMessage(e, { nameLengthError, I18N });
          this._setState({ error: message, loading: false });
        }
      };

      const addCollectionNameComponent = (
        <View>
          {loading && <LoadingIndicator />}
          <Box
            {...containerStyle}
            render={[
              <Header title={headerTitle} onClose={onClose} />,
              {
                ...iconStyle,
                image: getImage(icon)
              },
              {
                ...primaryTextStyle,
                text: primaryText
              },
              {
                ...inputStyle.containerStyle,
                render: (
                  <TextInput
                    value={name}
                    onChangeValue={this.onChangeName}
                    placeholder={inputPlaceholder()}
                    style={{ ...inputStyle.textInputStyle }}
                    autoFocus
                    maxLength={55}
                    onSubmitEditing={onAddClick}
                  />
                )
              },
              {
                ...footerStyle.containerStyle,
                render: [
                  {
                    ...footerStyle.errorStyle,
                    text: error || ""
                  },
                  {
                    ...footerStyle.addActionStyle,
                    onClick: onAddClick
                  }
                ]
              }
            ]}
          />
        </View>
      );
      return addCollectionNameComponent;
    }
  }

  return CreateCollectionName;
};
