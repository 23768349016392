import React from "react";
import FileInput from "./FileInput";
import {
  View,
  Box,
  I18N,
  getInput,
  theme,
  getComponent,
  resolveMQ,
  withContext,
  StatusBar as LoadingIndicator,
  showMessage,
  ScrollView,
  Platform,
  versionData,
  brandName
} from "../../../FsCloudComponent";
import { getDeviceInfo } from "../../../DeviceInfo";
import { onUploadFile, setSupportIssueRef, getCommentListRef, getErrorMessage } from "./issuesUtility";

let { colors, fonts, bgs } = theme;
let { themeColor, highlightColor, primaryColor, tomato } = colors;
let { lightPink } = bgs;
let { h9 } = fonts;

const fileInputStyle = {
  boxStyle: {
    viewStyle: {
      height: 48,
      padding: 16,
      alignItems: "center",
      backgroundColor: "rgb(239,239,239)",
      borderWidth: 1,
      borderStyle: "dashed",
      borderColor: "rgb(142,142,142)",
      cursor: "pointer",
      justifyContent: "center"
    },
    direction: "row"
  },
  uploadTextStyle: {
    width: "1fr",
    viewStyle: {
      marginRight: 6
    },
    text: () => I18N.t("insertAttachment"),
    textStyle: {
      ...h9,
      color: primaryColor
    }
  }
};

const componentTheme = {
  containerStyle: { flex: 1 },
  bodyComponent: {
    containerStyle: {
      viewStyle: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 12
      }
    }
  },
  buttonBoxStyle: {
    direction: "row",
    viewStyle: {
      alignItems: "center",
      justifyContent: "flex-end",
      paddingTop: 12,
      paddingBottom: 12
    }
  },
  errorStyle: {
    viewStyle: {
      flex: 1,
      paddingRight: 12
    },
    textStyle: {
      ...h9,
      color: tomato,
      numberOfLines: 2
    }
  },
  submitButtonStyle: {
    viewStyle: {
      borderRadius: 4,
      cursor: "pointer",
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: themeColor
    },
    textStyle: { ...h9, color: highlightColor }
  }
};

const ATTACHMENT_LIMIT = 3;
class AddComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { attachments: [], comment: "", uploading: false };
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  componentWillUnmount() {
    this._unmounted = true;
  }

  onChangecomment = value => {
    if (!value || (typeof value === "string" && value.trim().length === 0)) {
      value = "";
    }
    this._setState({ comment: value, error: void 0 });
  };

  onUploadFileNative = async attachment => {
    try {
      let data = await onUploadFile({ props: { ...this.props, attachment } });
      if (data) {
        let { attachments } = this.state;
        attachments = [...attachments, data];
        this._setState({ attachments: attachments });
        return data;
      }
    } catch (error) {
      showMessage && showMessage(error.message, 2000);
    }
  };

  upload = async attachment => {
    if (Platform.OS === "web") {
      try {
        let data = await onUploadFile({ props: { ...this.props, attachment } });
        if (data) {
          this.setValue({ field: attachment, value: data });
          return data;
        }
      } catch (error) {
        showMessage && showMessage(error.message, 2000);
      }
    }
  };

  setValue = ({ field, value }) => {
    this._setState({ [field]: value });
  };

  onSubmit = async () => {
    let { user } = this.props;
    if (!user) {
      return;
    }
    let { comment, attachments, uploading } = this.state;
    if (!comment) {
      this._setState({ error: I18N.t("commentError") });
      return;
    }
    if (uploading) {
      showMessage && showMessage(I18N.t("attachmentUploading"));
      return;
    }
    const { invoke, urls, deleteUri, url, link = {} } = this.props;
    let { props: { filter: { _id: issueId } = {} } = {} } = link;
    let deviceInfo = getDeviceInfo && (await getDeviceInfo());
    let { deviceId } = deviceInfo || {};

    let data = {
      issueId,
      comment,
      deviceId,
      brand: brandName,
      attachments,
      ...versionData
    };
    try {
      this._setState({ loading: true });
      await invoke({
        service: {
          url: urls[user.support ? "addCommentBySupportUrl" : "addCommentByUserUrl"],
          uriProps: {
            data
          }
        }
      });
      let commentListRef = getCommentListRef && getCommentListRef();
      commentListRef && commentListRef.getData && commentListRef.getData();
      this._setState({ loading: false });
      showMessage && showMessage(I18N.t("commentAddMessage"), 2000);
      if (user.support) {
        setSupportIssueRef && setSupportIssueRef({ data });
      }
      deleteUri && deleteUri(url, link);
    } catch (err) {
      let errMessage = getErrorMessage(err);
      if (err.code === "share/attachments-length") {
        errMessage = errMessage.replace("__attachmentLimit__", ATTACHMENT_LIMIT);
      }
      this._setState({ loading: false, error: errMessage });
    }
  };

  render() {
    const TextAreaInput = getInput("textArea");
    const FileInputWeb = getComponent("fileMultiWeb");
    let { activeMQ } = this.props;
    let { attachments } = this.state;

    const { buttonBoxStyle, submitButtonStyle, bodyComponent, errorStyle } = resolveMQ(
      componentTheme,
      ["buttonBoxStyle", "submitButtonStyle", "bodyComponent", "errorStyle"],
      activeMQ
    );
    return (
      <View style={{ flex: 1 }}>
        {this.state.loading && <LoadingIndicator />}
        <ScrollView style={{ flex: 1 }}>
          <Box
            {...bodyComponent.containerStyle}
            render={[
              {
                viewStyle: {
                  marginTop: 12,
                  marginBottom: 15,
                  backgroundColor: lightPink,
                  borderRadius: 4,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 14,
                  paddingBottom: 13
                },
                render: (
                  <TextAreaInput
                    placeholder={I18N.t("commentPlaceholder")}
                    value={this.state.comment}
                    onChangeValue={this.onChangecomment}
                    style={{ ...h9, color: primaryColor }}
                    onDragStart={e => {
                      e && e.preventDefault && e.preventDefault();
                      e && e.stopPropagation && e.stopPropagation();
                    }}
                    maxLength={1000}
                  />
                )
              },
              Platform.OS === "web" ? (
                {
                  viewStyle: {
                    marginTop: 15,
                    marginBottom: 8
                  },
                  render: (
                    <FileInputWeb
                      multiple
                      data={this.state}
                      theme={fileInputStyle}
                      setValue={this.setValue}
                      field="attachments"
                      upload={this.upload}
                      maxAttachmentsLimit={ATTACHMENT_LIMIT}
                    />
                  )
                }
              ) : (
                <FileInput
                  value={attachments}
                  setValue={this.setValue}
                  field={"attachments"}
                  data={this.state}
                  onUploadFile={this.onUploadFileNative}
                  maxAttachmentsLimit={ATTACHMENT_LIMIT}
                />
              ),
              {
                ...buttonBoxStyle,
                render: [
                  {
                    ...errorStyle,
                    text: this.state.error || ""
                  },
                  {
                    ...submitButtonStyle,
                    text: I18N.t("submit"),
                    onClick: this.onSubmit
                  }
                ]
              }
            ]}
          />
        </ScrollView>
      </View>
    );
  }
}

AddComment = withContext(AddComment, {
  activeMQ: "ActiveMQ",
  invoke: "App.invoke",
  urls: "App.urls",
  link: "ScreenRoute.link",
  upload: "App.upload",
  uploadRequest: "App.uploadRequest",
  url: "ScreenRoute.url",
  deleteUri: "Router.deleteUri",
  user: "User.user"
});

export default {
  AddComment: {
    component: "custom",
    title: () => I18N.t("addComment"),
    Component: AddComment
  }
};
