import { theme } from "../FsCloudComponent";
let { colors, shadows } = theme;
let { highlightColor } = colors;
let { cardShadow } = shadows;

const populateAvatarInColumns = (props, mq) => {
  const mqValue = props[mq];
  if (mqValue) {
    let {
      rowTheme: pRowTheme,
      textColor: pTextColor,
      rowActionIcon,
      selectedIcon,
      textStyle: pTextStyle,
      viewStyle: pViewStyle,
      highlightViewStyle: pHighlightViewStyle,
      gradient: pGradient,
      primaryAction: pPrimaryAction,
      highLightTextColor
    } = props;

    let { columns, backgroundColor, primaryAction, primaryActionProps, ...rest } = mqValue;

    pViewStyle = pViewStyle || {
      cursor: "pointer",
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 9,
      paddingBottom: 9
    };

    const columnTheme = (data, { resolveTheme, textStyle }) => {
      let finalTextStyle = void 0;
      if (pTextStyle) {
        finalTextStyle = pTextStyle;
        if (typeof finalTextStyle === "function") {
          finalTextStyle = finalTextStyle(data, { resolveTheme });
        }
        finalTextStyle = resolveTheme(finalTextStyle);
      }
      if (finalTextStyle) {
        textStyle = { ...textStyle, ...finalTextStyle };
      }
      return { textStyle };
    };

    const rowTheme = (data, { resolveTheme }) => {
      let resolvedTheme = pRowTheme;
      if (typeof resolvedTheme === "function") {
        resolvedTheme = resolvedTheme(data, { resolveTheme });
      }
      if (!resolvedTheme) {
        return void 0;
      }
      let { gradient: pGradient } = resolvedTheme;
      if (pGradient) {
        pGradient = resolveTheme(pGradient);
      }
      let finalTheme = void 0;
      if (pGradient) {
        finalTheme = finalTheme || {};
        finalTheme.gradient = pGradient;
      }
      return finalTheme;
    };

    props = { ...props };
    delete props[mq];

    props[`render${mq}`] = {
      ...rest,
      columns,
      primaryAction: primaryAction || pPrimaryAction,
      primaryActionProps,
      rowTheme,
      columnTheme,
      textColor: pTextColor,
      gradient: pGradient,
      viewStyle: pViewStyle,
      highlightViewStyle: pHighlightViewStyle,
      rowActionIcon,
      selectedIcon,
      highLightTextColor
    };
  }
  return props;
};

const populateAvatarInLayout = (props, mq) => {
  const mqValue = props[mq];
  if (mqValue) {
    let { selectedViewStyle, primaryAction: pPrimaryAction, gradient, rowTheme: pRowTheme } = props;
    let { viewStyle, render, direction, primaryAction = pPrimaryAction } = mqValue;
    const rowTheme = (data, { resolveTheme, textStyle }) => {
      let resolvedTheme = pRowTheme;
      if (typeof resolvedTheme === "function") {
        resolvedTheme = resolvedTheme(data, { resolveTheme });
      }
      if (!resolvedTheme) {
        return void 0;
      }
      let { gradient: pGradient, textStyle: pTextStyle, textColor: pTextColor } = resolvedTheme;
      if (pGradient) {
        pGradient = resolveTheme(pGradient);
      }
      if (pTextColor) {
        pTextColor = resolveTheme({ color: pTextColor });
      }
      let finalTheme = {};
      if (pGradient) {
        finalTheme.gradient = pGradient;
      }
      if (pTextColor) {
        pTextStyle = { ...textStyle, ...pTextColor };
      }
      if (pTextStyle) {
        finalTheme.textStyle = pTextStyle;
      }
    };

    let addOnInnerViewStyle = viewStyle || {
      backgroundColor: highlightColor,
      marginLeft: 12,
      marginRight: 12,
      marginTop: 5,
      marginBottom: 5,
      borderRadius: 4,
      ...cardShadow
    };
    let innerStyle = {};
    if (gradient) {
      innerStyle.gradient = gradient;
      innerStyle["gradientStyle"] = addOnInnerViewStyle;
    } else {
      innerStyle["viewStyle"] = { backgroundColor: "{primaryBg2}", ...addOnInnerViewStyle };
    }

    render = render.map((row, index) => {
      if (index > 0) {
        return {
          ...row,
          viewStyle: { paddingTop: 6, ...row.viewStyle }
        };
      } else {
        return row;
      }
    });

    const renderSM = {
      rowTheme,
      layout: {
        direction,
        ...innerStyle,
        selectedViewStyle: selectedViewStyle,
        value: {
          action: primaryAction,
          render
        }
      }
    };

    props = { ...props };
    delete props[mq];
    props[`render${mq}`] = renderSM;
  }
  return props;
};

export const populateLayoutOrColumn = (props, mq) => {
  if (props[mq]) {
    const mqProps = props[mq];
    if (mqProps.render) {
      props = populateAvatarInLayout(props, mq);
    } else {
      props = populateAvatarInColumns(props, mq);
    }
  }

  return { ...props };
};

export const commonLayout = props => {
  props = populateLayoutOrColumn({ ...props }, "SM");
  props = populateLayoutOrColumn({ ...props }, "MD");
  props = populateLayoutOrColumn({ ...props }, "LG");
  return { ...props };
};
