import React from "react";
import { Modal, withContext, I18N, loginUrl, ConfirmBox, resolveMQ, Platform } from "../../../FsCloudComponent";
import { resetFuncion } from "../../../FsCloudAppServices";
let ModalStyle = {
  logoutModalMD: {
    position: "center",
    width: 486,
    style: {
      borderRadius: 4
    },
    parentStyle: {
      zIndex: 100
    },
    escEnabled: true
  },
  logoutModalSM: {
    position: "center",
    width: 0.9,
    style: {
      borderRadius: 4
    },
    parentStyle: {
      zIndex: 100
    },
    escEnabled: true
  }
};

class LogoutPopup extends React.Component {
  state = {};

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  actionClick = () => {
    this._setState({ isModalOpen: true });
  };

  renderModal = () => {
    return (
      <ConfirmBox
        message={I18N.t("logoutConfirmMessage")}
        titleMD={I18N.t("logout")}
        cancelText={I18N.t("cancel")}
        confirmText={I18N.t("logout")}
        onConfirm={this.onSubmitClick}
        onCancel={this.onCancel}
      />
    );
  };

  onClick = event => {
    event && event.stopPropagation && event.stopPropagation();
    const { onClick } = this.props;
    if (onClick) {
      onClick({ onClick: this.actionClick });
    } else {
      this.actionClick();
    }
  };

  onCancel = () => {
    this._setState({ isModalOpen: false });
  };

  onSubmitClick = () => {
    try {
      let { removeUserInfo, replaceUri } = this.props;
      removeUserInfo && removeUserInfo();
      replaceUri && replaceUri(loginUrl);
      if(Platform.OS === 'web'){
        localStorage.clear()
      }
      resetFuncion()
    } catch (err) {
      console.warn("error: ", err );
      return err
    }
  };

  render() {
    let { children, activeMQ } = this.props;
    let { isModalOpen } = this.state;
    let { logoutModal } = resolveMQ(ModalStyle, ["logoutModal"], activeMQ);
    return (
      <>
        {React.cloneElement(children, { onClick: this.onClick })}
        <Modal isOpen={isModalOpen} {...logoutModal} onClose={this.onCancel}>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default withContext(LogoutPopup, {
  activeMQ: "ActiveMQ",
  removeUserInfo: "User.removeUserInfo",
  replaceUri: "Router.replaceUri"
});
