import {
  CreatePinComponent,
  ConfirmPinComponent,
  EnterPinComponent,
  ForgotPinComponent,
  ResetPinComponent,
  ChangePinComponent
} from "./VaultPinComponent";
import { I18N } from "../../../FsCloudComponent";
import ResetPin from "./ResetPin";

export default {
  CreateVaultPin: {
    component: "custom",
    Component: CreatePinComponent,
    title: () => I18N.t("createPin")
  },
  ConfirmVaultPin: {
    component: "custom",
    Component: ConfirmPinComponent,
    title: () => I18N.t("confirmPin")
  },
  EnterVaultPin: {
    component: "custom",
    Component: EnterPinComponent,
    title: () => I18N.t("enterPin")
  },
  ForgotVaultPin: {
    component: "custom",
    Component: ForgotPinComponent,
    title: () => I18N.t("forgotPin")
  },
  ResetVaultPin: {
    component: "custom",
    Component: ResetPinComponent,
    title: () => I18N.t("resetPin")
  },
  ChangeVaultPin: {
    component: "custom",
    Component: ChangePinComponent,
    title: () => I18N.t("changePin")
  },
  VaultSM: {
    component: "tab",
    componentType: "bottomTab",
    title: () => I18N.t("safeArea"),
    tabs: {
      gallery: {
        tab: {
          selectedText: () => I18N.t("gallery"),
          image: "homeGalleryIcon",
          selectedImage: "homeGallerySelectedIcon"
        },
        screen: "VaultGalleryTab",
        pushStateInHistory: true
      },
      docs: {
        tab: {
          selectedText: () => I18N.t("docs"),
          image: "homeDocIcon",
          selectedImage: "homeDocSelectedIcon"
        },
        screen: "VaultDocsTab",
        pushStateInHistory: true
      }
    }
  },
  VaultMD: {
    component: "tab",
    componentType: "leftTab",
    showSelectedTabTitle: true,
    tabs: {
      gallery: {
        tab: {
          image: "homeGalleryIcon",
          selectedImage: "homeGallerySelectedIcon",
          title: () => I18N.t("gallery")
        },
        screen: "VaultGalleryTab",
        pushStateInHistory: true
      },
      docs: {
        tab: {
          image: "homeDocIcon",
          selectedImage: "homeDocSelectedIcon",
          title: () => I18N.t("docs")
        },
        screen: "VaultDocsTab",
        pushStateInHistory: true
      }
    }
  },
  ResetVaultPinMail: {
    component: "custom",
    Component: ResetPin,
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true
  }
};
