import { I18N, getImage } from "../../FsCloudComponent";
import ResourceActions from "../screens/resource/ResourceActions";

export default {
  Resource: {
    fields: {
      resource: {
        header: "Image",
        field: "resource",
        type: "image",
        width: "100px",
        imageProps: { width: 60, height: 60 }
      },
      resourceUrl: {
        header: "Image Url",
        field: "resourceUrl",
        type: "image",
        width: "100px",
        imageProps: { width: 60, height: 60 }
      },
      name: { header: "Name", field: "name", width: "1fr", type: "text" },
      size: { header: "Size", field: "size", type: "text", width: "200px" },
      resource_lastModified: { field: "resource_lastModified", type: "date", format: "DD/MM/YYYY" },
      postedOn: { field: "postedOn", type: "date", format: "DD/MM/YYYY" },
      deletedOn: { field: "deletedOn", type: "date", format: "DD/MM/YYYY" }
    },
    actions: {
      ...ResourceActions,
      sortingCreatedOn: {
        type: "sortingCreatedOnAction",
        image: getImage("calenderGreyIcon"),
        text: () => I18N.t("viewByCreatedDate"),
        visible: (data, { Screen = {}, selectionMode }) => {
          let { Connect: { dataParams } = {} } = Screen;
          let { sort } = dataParams || {};
          if (selectionMode) {
            return false;
          }
          if (sort === undefined) {
            return true;
          }
          let keys = Object.keys(sort);
          return keys.indexOf("uploadedOn") !== 0;
        }
      },
      sortingLastModified: {
        type: "sortingLastModifiedAction",
        image: getImage("calenderGreyIcon"),
        text: () => I18N.t("viewBylastModified"),
        visible: (data, { Screen = {}, selectionMode }) => {
          let { Connect: { dataParams } = {} } = Screen;
          let { sort } = dataParams || {};
          if (selectionMode || sort === undefined) {
            return false;
          }
          let keys = Object.keys(sort);
          return keys.indexOf("resource_lastModified") !== 0;
        }
      },
      sortingSize: {
        type: "sortingSizeAction",
        image: getImage("sortingIcon"),
        text: () => I18N.t("viewBySize"),
        visible: (data, { Screen = {}, selectionMode }) => {
          let { Connect: { dataParams } = {} } = Screen;
          let { sort } = dataParams || {};
          if (selectionMode || sort === undefined) {
            return false;
          }
          let keys = Object.keys(sort);
          return keys.indexOf("resource_size") !== 0;
        }
      }
    },
    updateProps: {
      model: "Resource"
    }
  }
};
