import moment from "moment";
import { getZeroTimeDate } from "client-utility/lib/DateUtility";

export const reformatMobileNumber = value => {
  if (value) {
    if (typeof value === "number") {
      value = value.toString();
    }
    if (value.length >= 1) {
      value = "(" + value;
    }
    if (value.length >= 4) {
      let prev = value.substring(0, 3);
      let next = value.substring(3);
      value = prev + ") " + next;
    }
    if (value.length >= 11) {
      let prev = value.substring(0, 10);
      let next = value.substring(10);
      value = prev + "-" + next;
    }
  }
  return value;
};

export const fromNowDateFormatterWithI18N = (date, { I18N } = {}) => {
  date = new Date(date);
  let currentDate = getZeroTimeDate(new Date());
  let dateYear = date.getFullYear();
  let currentYear = currentDate.getFullYear();
  let dateMonth = date.getMonth();
  let currentMonth = currentDate.getMonth();
  let startWeek = moment().startOf("isoWeek").toDate();
  let dateStr;
  if (dateYear !== currentYear) {
    if (dateYear === currentYear - 1) {
      dateStr = I18N.t("lastYear");
    } else {
      dateStr = dateYear;
    }
  } else if (dateMonth !== currentMonth) {
    if (dateMonth === currentMonth - 1) {
      dateStr = I18N.t("lastMonth");
    } else {
      dateStr = I18N.t(moment(date).format("MMMM"));
    }
  } else if (date < startWeek) {
    let weekDiff = parseInt((startWeek.getDate() - date.getDate()) / 7);
    if (weekDiff === 0) {
      dateStr = I18N.t("lastWeek");
    } else {
      dateStr = `${weekDiff + 1} ${I18N.t("weekAgo")}`;
    }
  } else {
    let dateDiff = currentDate.getDate() - date.getDate();
    if (dateDiff > 1) {
      dateStr = I18N.t(moment(date).format("dddd"));
    } else if (dateDiff === 1) {
      dateStr = I18N.t("yesterday");
    } else {
      dateStr = I18N.t("today");
    }
  }
  return dateStr;
};
