import Config from "../config";
import translations from "../translations";

const { APP_BUILD_VERSION, APP_TITLE, APP_SHORT_NAME, APP_LINK } = Config;

const en_registration = {
  enterEmailMessage: `Enter the e-mail address in your ${APP_SHORT_NAME} account`,
  forgetEmail: "I forgot my registered email",
  submit: "Submit",
  Email: "E-mail",
  successfulRegistration: "Successful registration!",
  informationRecord: "Information record",
  enterInformation: "Enter information",
  needSomeInformation: "We just need some information",
  noAccountYet: "No account yet?",
  checkTheEmailBox: "Check the email box",
  enterTheCodeEmail: "Enter the code sent the email",
  enterConfirmationCode: "Enter the confirmation code",
  didNotGetEmail: "Did not get the email?",
  resendEmail: "Resend Email",
  itAlmostFinishing: "It's almost finishing…",
  loginMobileDescription: "Now enter the number of your cell phone It will be the number used for login",
  loginPlaceholder: "Type DDD + cellular",
  typeCode: "Type the code",
  toFinish: "To finish",
  loginOtpMobileDescription: "Now only the confirmation code is missing you received a code by SMS",
  forgetEmailMessage: "Enter your social security number to view the registered email",
  forgetEmailPlaceholder: "Enter CPF",
  emailReminder: "Email reminder",
  showForgotEmailDescription: `0 e-mail address in your ${APP_SHORT_NAME} account and:`,
  divideTalkToUs: "Do you need help?",
  submitting: "Submitting...",
  documentNumberMandatoryMessage: "Please enter CNPJ",
  validate: "Validate",
  documentNumberCountInvalidMessage: "Please fill 11 or 14 digit"
};

const pt_registration = {
  enterEmailMessage: `Digite o endereço de e-mail em sua conta do ${APP_SHORT_NAME}`,
  forgetEmail: "Esqueci meu e-mail cadastrado",
  submit: "Enviar",
  Email: "E-mail",
  successfulRegistration: "Registro bem sucedido!",
  informationRecord: "Registro de informações",
  enterInformation: "Insira informações",
  needSomeInformation: "Nós só precisamos de alguma informação",
  noAccountYet: "Ainda não tem conta?",
  checkTheEmailBox: "Verifique a caixa de email",
  enterTheCodeEmail: "Digite o código enviado no email",
  enterConfirmationCode: "Digite o código de confirmação",
  didNotGetEmail: "não recebeu o email?",
  resendEmail: "Reenviar email",
  itAlmostFinishing: "Está quase terminando ...",
  loginMobileDescription: "Agora digite o número do seu celular Será o número usado para o login",
  loginPlaceholder: "Digite DDD + celular",
  typeCode: "Digite o código",
  toFinish: "Terminar",
  loginOtpMobileDescription: "Agora apenas o código de confirmação está faltando você recebeu um código por SMS",
  forgetEmailMessage: "Digite seu CPF para visualizar o e-mail cadastrado",
  forgetEmailPlaceholder: "Digite o CPF",
  emailReminder: "Lembrete de e-mail",
  ShowForgotEmailDescription: `0 endereço de e-mail na sua conta do ${APP_SHORT_NAME} e:`,
  divideTalkToUs: "Dúvidas? Fale conosco.",
  submitting: "Enviando ...",
  documentNumberMandatoryMessage: "Por favor insira CNPJ",
  validate: "Validar",
  documentNumberCountInvalidMessage: "Por favor, digite 11 ou 14 dígitos"
};

const en = {
  "locale-string": "en",
  "app-title": `${APP_TITLE}`,
  login: "Login",
  loginCaps: "LOGIN",
  walkthroughButtonText: "LOGIN",
  logging: "Logging In...",
  copyrightText: `${APP_SHORT_NAME} All rights reserved`,
  privacyPolicyText: "Privacy policy",
  termsOfUseText: "Terms of Use",
  loginPrimaryText: `Mobile ${APP_SHORT_NAME} Customer`,
  loginPrimaryLandLineText: `Fixed ${APP_SHORT_NAME} Customer`,
  loginSecondaryText: `Phone number`,
  mobilePlaceholder: "Enter your DDD + Cell Number",
  mobileMandatoryMessage: "Please enter phone number",
  mobileCountInvalidMessage: "Phone number must be 11 digits",
  otpPrimaryText: `Enter the 6 digit code sent to`,
  otpLabel: "6-digit Code",
  otpPlaceholder: "Enter the code sent",
  otpMandatoryMessage: "Please enter Code",
  otpCountInvalidMessage: "Please fill 6 digit Code",
  mobileDDDinvalidMessage: "The DDD you entered does not exist",
  mobileStart9Message: "Your number should start with 9",
  no_internet: "No Internet Connections",
  "auth/invalid-verification-code": "The verification code for SMS is wrong",
  "auth/code-expired": "OTP is expired. Click resend button to receive a new one and try again.",
  "auth/captcha-expired": "Captcha expired. Please try again.",
  "auth/invalid-phone-number": "Phone Number is not valid",
  "auth/network-request-failed": "No Internet Connection",
  "auth/id-token-expired": "Token Expired",
  continue: "Continue",
  changePartner: "Trocar Parceiro",
  agreementText1: "By clicking Continue, I confirm that I agree with the",
  agreementText2: "of the service",
  resendCode: "Resend code",
  codeSentMessage: "Code has been successfully sent",
  loginOptionTitle: "You are a customer",
  mobileLoginTitle: `${APP_SHORT_NAME} Mobile`,
  landlineLoginTitle: `${APP_SHORT_NAME} Fixed`,
  resendInTimeText: "Resend code in",
  invalidNumber: "Number is not valid",
  validating: "Validating...",
  skip: "Pular",
  gallery: "Gallery",
  galleryCaps: "GALLERY",
  docs: "Docs",
  docsCaps: "DOCS",
  music: "Music",
  group: "Group",
  groups: "Group",
  all: "All",
  allCaps: "ALL",
  albums: "Albums",
  select: "Select",
  upload: "Upload",
  uploadFile: "Upload File",
  autoBackup: "Auto Backup",
  selectItems: "Select Items",
  layout: "Layout",
  changeLayout: "Change Layout",
  selectNone: "Select None",
  selectAll: "Select All",
  addToAlbum: "Add To Album",
  addToSet: "Add To Set",
  addToPlaylist: "Add To Playlist",
  download: "Download",
  downloadPaused: "Download paused",
  downloadPausedDueToRoaming: "Download paused due to roaming",
  downloadProgress: "Download in progress...",
  uploadInProgress: "Upload in progress...",
  uploadPaused: "Upload paused",
  tapToResume: "Tap to resume",
  itemsRemaining: "item(s) remaining",
  itemsQueued: "item(s) queued",
  delete: "Delete",
  selected: "Selected",
  search: "Search",
  types: "Types",
  favourite: "Favourite",
  aac: "AAC",
  mp3: "MP3",
  pdf: "PDF",
  document: "Document",
  presentation: "Presentation",
  spreadsheet: "Spreadsheet",
  media: "Media",
  text: "Text",
  webPage: "Web page",
  archive: "Archive",
  typeHere: "Type Here",
  layoutHuge: "Huge",
  layoutLarge: "Large",
  layoutMedium: "Medium",
  layoutSmall: "Small",
  layoutTiny: "Tiny",
  info: "Info",
  print: "Print",
  postToGroup: "Post to Group",
  postToGroupCaps: "POST TO GROUP",
  post: "Post",
  shareVia: "Share via",
  getLink: "Get link",
  removePopupHeaderCaps: "REMOVE ITEM",
  removeAlbumSingleItemPopupMessage: "Do you want to remove the selected item from the album __albumName__?",
  removeAlbumMultipleItemPopupMessage:
    "Do you want to remove __itemCount__ selected items from the album __albumName__?",
  removeSetSingleItemPopupMessage: "Do you want to remove the selected item from the set __setName__?",
  removeSetMultipleItemPopupMessage: "Do you want to remove __itemCount__ selected items from the set __setName__?",
  removePlaylistSingleItemPopupMessage: "Do you want to remove the selected item from the playlist __playlistName__?",
  removePlaylistMultipleItemPopupMessage:
    "Do you want to remove __itemCount__ selected items from the playlist __playlistName__?",
  removeAlbumPopupMessage:
    "Are you sure want to delete album __albumName__? The items will not be deleted. The album will no longer be available through the shared link.",
  removeSetPopupMessage:
    "Are you sure want to delete set __setName__? The items will not be deleted. The set will no longer be available through the shared link.",
  removeVaultAlbumPopupMessage: "Are you sure want to delete album __albumName__? The items will not be deleted.",
  removeVaultSetPopupMessage: "Are you sure want to delete set __setName__? The items will not be deleted.",
  removePlaylistPopupMessage: "Are you sure want to delete playlist __playlistName__? The items will not be deleted.",
  removeItemMessage: "Item(s) removed successfully",
  deletePopupHeader: "Delete Item",
  enterYourName: "Enter your name",
  enterYourDetails: "Enter your details",
  deletePopupHeaderCaps: "DELETE ITEM(S)",
  deleteAlbumCaps: "DELETE ALBUM",
  deleteSetCaps: "DELETE SET",
  deletePlaylistCaps: "DELETE PLAYLIST",
  deleteMultipleItemPopupMessageSM: "Would you like to move the __itemCount__ selected items to the trash bin?",
  duplicateDeleteMultipleItemPopupMessage:
    "Deleting all duplicates of an item might also delete the original one. Would you like to continue?",
  deleteSingleItemPopupMessageSM: "Would you like to move the selected item to the trash bin?",
  deletePopupMessageMD: "Do you want to move the selected item(s) to the trash?",
  deleteMultipleItemPopupMessageMD: "Do you want to move the __itemCount__ selected items to the trash?",
  deleteSingleItemPopupMessageMD: "Do you want to move the selected item to the trash?",
  deleteItemsPermanent: "Are you sure you want to permanently delete _itemCount_ item(s) from your account?",
  emptyTrashMessage: "Are you sure you want to permanently delete all trash item(s) from your account?",
  removeGroupSingleItemMessage: "Do you want to remove the selected item from group?",
  removeGroupMultipleItemMessage: "Do you want to remove __itemCount__ selected items from group?",
  makePrivatePopupHeaderCaps: "MAKE PRIVATE",
  makePrivateAlbumPopupMessage:
    "By removing the link, this album will no longer be accessible and no sharing will be made. You can share this album again at any time.",
  makePrivateSetPopupMessage:
    "By removing the link, this set will no longer be accessible and no sharing will be made. You can share this set again at any time.",
  makePrivateResourcePopupMessage:
    "Links to view content will no longer work. You can share this content again at any time.",
  makePrivate: "Make Private",
  makeItPrivate: "Make it Private",
  albumLeaveMessage: "Album left successfully",
  setLeaveMessage: "Set left successfully",
  uploadPopupHeader: "Upload Item",
  uploadPopupHeaderCaps: "UPLOAD ITEM",
  uploadSingleItemPopupMessage: "Would you like to upload the selected item?",
  uploadMultipleItemPopupMessage: "Would you like to upload __itemCount__ selected items?",
  cancel: "Cancel",
  ok: "Ok",
  yesContinue: "Yes continue",
  moveToTrash: "Move to Trash",
  highlightsNoDataHeader: "No Highlights!",
  devicesNoDataHeader: "No Devices!",
  highlightsNoDataMessage: "They will be automatically created and displayed here",
  galleryAllNoDataHeader: "No photos or videos!",
  galleryAllNoDataMobileMessage: "Upload some by choosing ‘Upload’ from the menu above",
  archiveNoDataMobileMessage: "Add files by choosing ‘Move to Archive’ from the menu above",
  galleryAllNoDataMessage: "Upload something by tapping the below add button or dropping them in here",
  vaultNoDataMessage: "Upload something by dropping them in here or chose files by tapping the below button",
  archiveNoDataMessage: "Add files by tapping the below button",
  shareNoDataHeader: "The items you’re accessing are no longer available for sharing",
  shareNoDataMessage: "They may not be shared or the address may be wrong. Please check and try again.",
  add: "Add",
  galleryAlbumNoDataHeader: "No Albums!",
  galleryPlaceNoDataHeader: "No Places!",
  galleryDeviceFolderNoDataHeader: "No Device Folder!",
  galleryAlbumNoDataMessage: "Create a new album by clicking the below button",
  createAlbum: "Create Album",
  sets: "Sets",
  docsAllNoDataHeader: "No Docs!",
  docsSetNoDataHeader: "No Sets!",
  docsNoDataMessage: "Upload something by tapping the below add button or dropping them in here",
  docsNoDataMobileMessage: "Upload some by choosing ‘Upload’ from the menu above",
  docsSetNoDataMessage: "Create a new set by clicking the below button",
  createSet: "Create Set",
  playlists: "Playlists",
  playlistsCaps: "PLAYLISTS",
  artist: "Artist",
  genre: "Genre",
  file: "File",
  "Track Order": "Track Order",
  artists: "Artists",
  artistsCaps: "ARTISTS",
  videoConversionProgress: "Please wait a moment. We are preparing the video",
  groupGalleryNoDataMobileMessage: "Tap the pin icon and choose any item form your Gallery to post here.",
  groupDocsNoDataMobileMessage: "Tap the pin icon and choose any item form your Docs to post here.",
  musicAllNoDataHeader: "No Music!",
  musicPlaylistNoDataHeader: "No Playlist!",
  musicPlaylistNoDataMobileHeader: "Create a new playlist",
  musicAllNoDataMessage: "Upload something by tapping the below add button or dropping them in here",
  musicAllNoDataMobileMessage: "Upload some by choosing ‘Upload’ from the menu above",
  musicPlaylistNoDataMessage: "Create a new playlist by clicking the below button",
  albumNoDataMobileMessage: "Tap on plus icon to create new album",
  selectAlbumNoDataMobileMessage: "Tap on new album to create new one",
  setsNoDataMobileMessage: "Tap on plus icon to create new set",
  selectSetsNoDataMobileMessage: "Tap on new set to create new one",
  playlistNoDataMobileMessage: "Tap on plus icon to create new playlist",
  selectPlaylistNoDataMobileMessage: "Tap on new playlist to create new one",
  createPlaylist: "Create Playlist",
  musicArtistsNoDataHeader: "No Artists!",
  musicArtistsNoDataMessage: "Upload some music, you will find your artists here",
  membersCaps: "MEMBERS",
  members: "Members",
  member: "Member",
  owner: "Owner",
  inviteExpireMessage: "Invite expires in _days_ days",
  blog: "Blog",
  shared: "Shared",
  allFiles: "All Files",
  contacts: "Contacts",
  trashCan: "Trash Can",
  trash: "Trash",
  home: "Home",
  freeUpSpace: "Free up space",
  freeUpSpaceTitle: "Free Up Space",
  backup: "Backup",
  backupCaps: "BACKUP",
  myConnection: "My Connection",
  configuration: "Configuration",
  used: "used",
  name: "Name",
  size: "Size",
  modifications: "Modifications",
  type: "Type",
  cancelAll: "Cancel All",
  resumeAll: "Resume All",
  uploading: "Uploading",
  item: "Item(s)",
  itemsUploaded: "Item(s) Uploaded",
  tryAgain: "Try Again",
  resume: "Resume",
  pause: "Pause",
  cancelled: "Cancelled",
  of: "of",
  uploadNewItem: "Upload New Item",
  dragAndDropQuote: "You can drag and drop your item here ...",
  albumDateLabel: "__month__ __date__, __year__",
  month_0: "Jan",
  month_1: "Feb",
  month_2: "Mar",
  month_3: "Apr",
  month_4: "May",
  month_5: "Jun",
  month_6: "Jul",
  month_7: "Aug",
  month_8: "Sep",
  month_9: "Oct",
  month_10: "Nov",
  month_11: "Dec",
  Sun: "Sun",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  am: "am",
  pm: "pm",
  pixels: "pixels",
  userNotExist: "User does not exist",
  userNotFound: "User/firebaseUid not found",
  "user/not-active": "Account Deactivated",
  "user/token-missing": "Contexto/Token está faltando",
  "user/phone-number-not-matched": "Usuário não encontrado com número de telefone salvo em firebase",
  "user/invalid-token": "Token inválido",
  clientNotFound: "Client not found",
  lastModified: "Last Modified",
  viewByCreatedDate: "View by Uploaded Date",
  viewBylastModified: "View by Last Modified Date",
  viewBySize: "View by Size",
  "uploader/limit-exceeds": "Insufficient Storage",
  "uploader/height-width-missing": "Resource height/width missing",
  "uploader/no-file-size": "No file size",
  uploadingFailed: "Uploading Failed",
  modified: "Modified",
  source: "Source",
  error: "Error",
  failed: "Failed",
  successFullyUploaded: "Successfully Uploaded",
  addToCaps: "ADD TO",
  createCollectionInputPlaceholder: "Type it...",
  createPlaylistInputPlaceholder: "Enter playlist name",
  createAlbumInputPlaceholder: "Enter album name",
  createSetInputPlaceholder: "Enter folder name",
  createNewAlbum: "Create a new album",
  createNewSet: "Create a new set",
  createNewPlaylist: "Create a new playlist",
  createNewAlbumHeader: "Create a New Album",
  createNewSetHeader: "Create a New Set",
  createNewPlaylistHeader: "Create a New Playlist",
  newAlbumCaps: "NEW ALBUM",
  newSetCaps: "NEW SET",
  newPlaylistCaps: "NEW PLAYLIST",
  addToAlbumValidationMessage: "Please enter a name for new album",
  addToSetValidationMessage: "Please enter a name for new set",
  addToAlbumMessage: "Select the Album to which you want to add the items, or choose New Album.",
  albumDuplicateNameError: "There is already another album with that name. Please choose another name",
  emailMandatoryError: "Please enter at least one valid email",
  emailValidationError: "Please enter valid email",
  emailError: "Please enter an email",
  setDuplicateNameError: "There is already another set with that name. Please choose another name",
  playlistDuplicateNameError: "There is already another playlist with that name. Please choose another name",
  noItemAvailable: "No Item Available",
  addContentMessage: "Add Some content to your account and try again.",
  createNewAlbumSecondaryMessage: "There is no album available, Please create new album to add the content",
  createNewSetSecondaryMessage: "There is no set available, Please create new set to add the content",
  createNewPlayListSecondaryMessage: "There is no playlist available, Please create new playlist to add the content",
  addToPlaylistValidationMessage: "Please enter a name for new playList",
  selectErrorMesage: "You must select at least one option.",
  nonUploadedItems: "Non Uploaded Items",
  addToAlbumCaps: "ADD TO ALBUM",
  addToSetCaps: "ADD TO SET",
  addToPlaylistCaps: "ADD TO PLAYLIST",
  createANewAlbumCaps: "CREATE A NEW ALBUM",
  createANewSetCaps: "CREATE A NEW SET",
  createANewPlaylistCaps: "CREATE A NEW PLAYLIST",
  addToSetMessage: "Select the Set to which you want to add items, or choose New Set.",
  addToPlaylistMessage: "Select the Playlist to which you want to add items or choose New Playlist.",
  item_send_to_trash: "Item has been sent to trash successfully",
  items_send_to_trash: "Items have been sent to trash successfully",
  today: "Today",
  rename: "Rename",
  toView: "To View",
  upload_no_internet: "Upload failed. Check your network connection. Please try again.",
  file_no_more_error: "File is no more available",
  EXTENSION_MISMATCH: "Type/Extension Mismatch",
  renameCaps: "RENAME",
  renameValidationMessage: "Please enter a name",
  inputTitle: "Enter Name",
  setHeader: "Set Name",
  playlistHeader: "Playlist Name",
  albumHeader: "Album Name",
  edit: "Edit",
  save: "Save",
  saveCaps: "SAVE",
  create: "Create",
  remove: "Remove",
  leave: "Leave",
  invite: "Invite",
  inviteMembers: "Invite Member",
  resendInvitation: "Resend Invitation",
  invitationResendMessage: "Invitation sent successfully",
  invitationCancelMessage: "Invitation cancelled successfully",
  removeMemberMessage: "Member removed successfully",
  cancelInvitation: "Cancel Invitation",
  accepted: "They Accepted",
  shareLink: "Share Link",
  oneMoreStep: "One more step",
  inviteMemberMessage: "Share an invitation link, and let your family member join in.",
  inviteMessage: "Share a link to your family",
  groupNoDataHeaderWeb: "Create a group to share your photos, videos and files securely with your friends.",
  groupNoDataMessageWeb: `Whenever you or another member of your group shares content, everyone will be notified via ${APP_TITLE}`,
  begin: "Begin",
  groupIntroduce: "Introduce Yourself",
  groupIntroduceMessage: "How would you like to appear?",
  groupIntroduceDiscription: "Your name will only be disclosed on media that you share or join.",
  groupNoDataHeaderMobile: "Create a Group",
  addMembersCaps: "ADD MEMBERS",
  addMembers: "Add Members",
  viewProfile: "View Profile",
  sendToGroup: "Send To Group",
  sendToGroupCaps: "SEND TO GROUP",
  removeGroupCaps: "REMOVE THE GROUP",
  leaveGroupCaps: "LEAVE THE GROUP",
  linkExpiredCaps: "LINK EXPIRED",
  linkExpiredMessage: "Unfortunately the link has been expired. Kindly ask sender to resend the invitation.",
  removeGroup: "Remove Group",
  leaveGroup: "Leave Group",
  leaveAlbum: "Leave Album",
  leaveSet: "Leave Set",
  leaveAlbumConfirmMessage:
    "You will no longer be able to access the contents of this album. Are you sure you want to leave?",
  leaveSetConfirmMessage:
    "You will no longer be able to access the contents of this set. Are you sure you want to leave?",
  removeGroupMessage:
    "The contents of all members of the group will be removed. You can set up a new group at any time.",
  leaveGroupMessage:
    "Leaving the group you will no longer have access to shared content. Are you sure you want to quit?",
  groupNoDataMessageMobile:
    "Share your memories with the people you love the most. Invite them to be part of your group.",
  start: "Start",
  startCaps: "START",
  toShare: "To Share",
  shareOptionsHeader: "Select how to share below :",
  email: "Email",
  facebook: "Facebook",
  twitter: "Twitter",
  shareAgreementText: `By clicking "Submit", I declare that I have the right to distribute these items and that they do not violate the`,
  uploadAlbumHeader: "Upload an album Link",
  subjectMatter: "Subject matter",
  shareAlbumHeader: "SHARE ALBUM BY EMAIL",
  submit: "Submit",
  emailAddress: "Email Address",
  phoneNumber: "Phone Number",
  userName: "Username",
  enterEmailId: "Enter email",
  enteruserName: "Enter username",
  enterPhoneNumber: "Enter phone number",
  addEmail: "Type an email and press enter",
  getLinkCaps: "GET LINK",
  getAlbumLink: "Get Album Link",
  close: "Close",
  linkAgreementText:
    "To post this sharing link, I certify that I am authorized to post these items and that they do not violate the",
  openLinkText1: "Open the link in a new tab",
  openLinkText2: "or copy and paste the link below into any web application:",
  firstName: "First Name",
  lastName: "Last Name",
  uploadIconTitle: "Upload new items",
  viewAll: "View all photos",
  markFavouriteTitle: "Mark Favourite",
  unmarkFavouriteTitle: "Unmark Favourite",
  toShareTitle: "To Share",
  removeFromAlbum: "Remove From Album",
  removeFromSet: "Remove From Set",
  removeFromPlaylist: "Remove From Playlist",
  infoTitle: "Info",
  createAlbumTitle: "Create a New Album",
  createSetTitle: "Create a New Set",
  createPlaylistTitle: "Create a New Playlist",
  slideshowTitle: "Play SlideShow",
  enterFirstName: "Enter first name",
  enterLastName: "Enter last name",
  noMatchHeader: "No Matches Found!",
  noMatchMessage: "Please change your criteria and try again",
  uploadMusicNoDataHeader: "No tracks to upload!",
  uploadMusicNoDataMessage: "Your tracks are already in your online account.",
  uploadDocumentNoDataHeader: "No files to upload!",
  uploadDocumentNoDataMessage: "Your files are already in your online account.",
  uploadGalleryNoDataHeader: "No media to upload!",
  uploadProfileGalleryNoDataHeader: "No image to upload",
  uploadGalleryNoDataMessage: "Your photos and videos are already in your online account.",
  itemAddedToAlbum: "Item added to Album",
  itemAddedToSet: "Item added to Set",
  itemAddedToPlaylist: "Item added to Playlist",
  itemsAddedToAlbum: "items added to Album",
  itemsCountAddedToAlbum: "__itemcount__ item(s) added in Album",
  itemsCountIgnoredToAlbum: "__itemcount__ item(s) already exists in Album",
  itemsCountAddedToSet: "__itemcount__ item(s) added in Set",
  itemsCountIgnoredToSet: "__itemcount__ item(s) already exists in Set",
  itemsCountAddedToPlaylist: "__itemcount__ item(s) added in Playlist",
  itemsCountIgnoredToPlaylist: "__itemcount__ item(s) already exists in Playlist",
  itemsAddedToSet: "items added to Set",
  itemsAddedToPlaylist: "items added to Playlist",
  createdSuccessfully: "Created successfully",
  itemPostToGroup: "Item posted to the Group",
  itemsPostToGroup: "Item(s) posted to the Group",
  itemsPostedToGroup: "__itemcount__ item(s) posted to the Group",
  itemsExistsToGroup: "__itemcount__ item(s) already exists in Group",
  albumCreated: "Album created successfully",
  setCreated: "Set created successfully",
  playlistCreated: "Playlist created successfully",
  galleryPermissionMessage: "Please provide storage permissions in settings to access photos and videos",
  filesPermissionMessage: "Please provide storage permissions in settings to access files",
  audioPermissionMessage: "Please provide storage permissions in settings to access audio",
  albumNameLengthMessage: "Album name length must be between 3-55",
  setNameLengthMessage: "Set name length must be between 3-55",
  playlistNameLengthMessage: "Playlist name length must be between 3-55",
  imageNameLengthMessage: "Image name length must be between 3-55",
  videoNameLengthMessage: "Video name length must be between 3-55",
  docNameLengthMessage: "Doc name length must be between 3-55",
  audioNameLengthMessage: "Audio name length must be between 3-55",
  setCover: "Set Cover",
  setCoverCaps: "SET COVER",
  setCoverImage: "Set Cover Image",
  changeCover: "Change Cover",
  coverUpdatedSuccessMessage: "Cover updated successfully",
  coverUpdateSelectErrorMessage: "Please select an item.",
  playMusic: "Play Music",
  addItems: "Add Item(s)",
  manageCollaboration: "Manage Collaboration",
  selectItemsDot: "Select Items...",
  set: "Set",
  items: "Item(s)",
  sources: "Sources",
  duration: "Duration",
  album: "Album",
  itemsAddedSuccessfully: "Item(s) added successfully",
  itemsPostSuccessfully: "Item(s) posted successfully",
  albumRenameSuccessMessage: "Album renamed successfully",
  setRenameSuccessMessage: "Set renamed successfully",
  playlistRenameSuccessMessage: "Playlist renamed successfully",
  imageRenameSuccessMessage: "Image renamed successfully",
  videoRenameSuccessMessage: "Video renamed successfully",
  docRenameSuccessMessage: "Document renamed successfully",
  audioRenameSuccessMessage: "Music renamed successfully",
  deleteSuccessMessage: "Deleted successfully",
  deleteTrashMessage: "item(s) deleted permanently",
  albumDeleted: "Album deleted successfully",
  setDeleted: "Set deleted successfully",
  playlistDeleted: "Playlist deleted successfully",
  trashed_item_can_not_view: "Items in trash cannot be opened",
  addCaps: "ADD",
  postCaps: "POST",
  import: "Import",
  importToGallery: "Import to Gallery",
  nameAlreadyExists: "name already exists.",
  removeFromGroup: "Remove from group",
  doc: "Doc",
  image: "Image",
  video: "Video",
  playlist: "Playlist",
  okCaps: "OK",
  selectCover: "Select Cover",
  groupGalleryNoDataMessage: "Choose photos to post here.",
  groupDocsNoDataMessage: "Choose document to post here.",
  groupNotifyMessage: "Your Group will be notified.",
  groupNoDataInfoMessage: "Everyone can enjoy and share content.",
  groupRemoveSuccessfully: "Group removed successfully",
  groupLeaveSuccessfully: "Group left successfully",
  groupCreated: "Group created successfully",
  memberInvited: "Member invited successfully",
  membersInvited: "members invited successfully",
  upload_user_cancelled: "User cancelled the upload",
  upload_storage_unknown: "Unknown error occurred",
  markFavouriteMessage: "Marked favourite successfully",
  unmarkFavouriteMessage: "Unmarked favourite successfully",
  introductionMessage: "Introduce yourself before proceeding.",
  introduceHeader: "INTRODUCE YOURSELF",
  nameMandatoryError: "Please enter first name",
  introductionMandatoryError: "Please enter first name and email",
  downloading: "Downloading",
  downloadingText: "Downloading...",
  downloadQueued: "Download Queued",
  yesterday: "Yesterday",
  lastWeek: "Last Week",
  lastMonth: "Last Month",
  lastYear: "Last Year",
  weekAgo: "week ago",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  galleryUploadFolderHeaderText: "Tap on a folder to choose the items to upload...",
  itemUploadHeaderText: "Select your items and tap on Upload to back them up in your account.",
  permissionConfirmBoxMessage:
    "The app needs all requested permissions to run properly. You can manage all your permissions from device Settings",
  noThanks: "No, Thanks",
  takeMeToSettings: "Take me to the setttings",
  storagePermissionTitle: `${APP_TITLE} requires Storage permissions`,
  contactPermissionTitle: `${APP_TITLE} requires Contacts permissions`,
  cameraPermissionTitle: `${APP_TITLE} requires Camera permissions`,
  locationPermissionTitle: `${APP_TITLE} requires Location permissions`,
  biometricPermissionTitle: `${APP_TITLE} uses __biometricType__ permissions`,
  biometrics: "biometrics",
  permissionWelcomeConfirmText: "Next",
  selectImageNoDataHeader: "No photos or videos!",
  selectImageNoDataText: "No Item Available. Add Some content to your account and try again.",
  selectDocsNoDataHeader: "No docs!",
  selectDocsNoDataText: "No Item Available. Add Some content to your account and try again.",
  selectPlaylistNoDataText: "No Item Available. Add Some content to your account and try again.",
  collaborationHeaderMessage: "Allow members to add their content",
  collaborationMessageSharePopup: "Allow participants to add content",
  "metadata/uploading-in-progess": "The items you selected are still uploading, so try again later.",
  markDeleteAlbum: "Delete Album",
  markDeleteSet: "Delete Set",
  markDeletePlaylist: "Delete Playlist",
  renameAlbum: "Rename Album",
  renameSet: "Rename Set",
  renamePlaylist: "Rename Playlist",
  itemRemovedMessage: "Item(s) removed successfully",
  itemImportedMessage: "Item(s) imported successfully",
  importItem: "Importing __itemcount__ Item(s)",
  importItemExists: "__itemcount__ item(s) are already imported",
  itemAlreadyImportedMessage: "Item(s) already imported",
  photoImportedMessage: "Photo imported successfully",
  alreadyAddedIntoGallery: "Already added into gallery",
  removeMember: "Remove Member",
  removeMemberCaps: "REMOVE MEMBER",
  removeMemberConfirmMessage: "Remove _Member_ from the group.",
  editMyProfile: "Edit my Profile",
  resendInvite: "Resend Invite",
  postedOn: "Posted on",
  postedBy: "Posted By",
  by: "by",
  cancelInvite: "Cancel Invite",
  joinGroupCaps: "JOIN GROUP",
  joinGroupMessage: "Do you want to join the group?",
  joinGroupAlreadyMemberMessage:
    "You are already a member of a different family. To join this family instead, leave your current family and accept this invite again.",
  join: "Join",
  joinCaps: "Join",
  sharingPage: "Sharing Page",
  joinCollectionMessage: `Do you want to join the shared __type__ __name__?`,
  searchDot: "Search...",
  searchGalleryDot: "Search Gallery...",
  searchDocumentsDot: "Search Documents...",
  searchMusicDot: "Search Music...",
  searchGroupDot: "Search Group...",
  searchByNamePublisher: "Search by name, publisher",
  private: "Private",
  sharedByName: "Shared by __name__",
  collectionMakePrivate: "The share links have been removed",
  be_part_of_it: "Be part of it",
  confirm: "Confirm",
  makePrivateMessage: "Make private successfully",
  turningOnCollaboration: "Enabling permission for members to add content",
  turningOffCollaboration: "Disabling permission for members to add content",
  settings: "Settings",
  help: "Help",
  about: "About",
  account: "Account",
  storage: "Storage",
  unlimited: "Unlimited",
  personalInfo: "Personal Info",
  uploadDescriptionOnSetting: "Auto-upload of pictures and videos from gallery and selected folders",
  network: "Network",
  networkdescription1: `You can use your files using ${APP_TITLE}:`,
  networkdescriptionWifi: "Your files will be updated only when Wi-Fi connection is available.",
  networkdescriptionMobile: "Your files can be updated using your data plan.",
  roaming: "Roaming",
  roamingDescription: `Turn ON if you want to connect to ${APP_LINK} while roaming.`,
  roamingDescriptionNote:
    "Note: Using data while roaming may incur additional charges. Check the rates on your data plan.",
    deleteAccount: "Delele account",    
  troubleshooting: "Troubleshooting",
  troubleshootingDescription: "Turn ON if you want to log data about your usage",
  troubleshootingDescriptionNote:
    "Send the log in case you encounter some problems, to help the support team in troubleshooting",
  wifi: "Wi-Fi only",
  wifiMobile: "Wi-Fi and mobile only",
  sendLog: "Send Log",
  sendLogSuccessMessage: "Log sent successfully",
  attachLog: "Attach Log",
  share: "Share",
  musicTrack: "Music Track",
  lowResolution: "Low Resolution (about __size__)",
  originalItems: "Original Items (about __size__)",
  linkToItems: "Link to the items",
  preparingContent: "Preparing Content...",
  preparingLink: "Preparing Link...",
  linkCopiedMessage: "Link copied to clipboard",
  getLinkErrorMessage: "There was a problem in generating the link. Please try again later",
  shareMaxFileExceededMessage:
    "The maximum file sharing limit has been exceeded. Please share through link for more than __shareFileLimit__ files",
  shareMaxSizeExceededMessage:
    "The maximum file sharing size limit has been exceeded. Please share through link for more than __shareFileSizeLimit__ MB",
  shareErrorMessage: "There was a problem in sharing. Please try again later",
  shareNetworkErrorMessage: "Sharing failed due to internet connectivity. Please try again later",
  downloadNetworkErrorMessage: "Downloading failed due to internet connectivity. Please try again later",
  uploadDescription1: `New Pictures and videos from gallery and selected folders are automatically uploaded to ${APP_LINK} as soon as the proper network conditions are met.`,
  uploadDescription2: `Press + to upload more pictures, videos, music tracks and file to ${APP_LINK}.`,
  uploadMoreFolder: "Upload from more folders",
  freeSpaceModalMessage1: `How can ${APP_TITLE} free up space on my device?`,
  freeSpaceModalMessage2: `${APP_TITLE} safely removes copies of the items from this device that are already saved in the cloud. Items inside your SD Card won’t be removed. Enjoy and never run out of space again on your device`,
  freeUpSpaceMessage1: `Safely Clean up items from this device that are already saved to ${APP_TITLE}`,
  calculatingFreeSpace: "Calculating how much space you can free up...",
  learnMore: "Learn More",
  freeSpaceConfirmMessage: `We are removing __itemCount__ item(s) from your device that are already secure in ${APP_TITLE}`,
  pictureSelected: "Picture Selected",
  selectProfilePicture: "Select Profile Picture",
  updateProfilePicMessage: "Profile picture updated successfully",
  notifySpaceMessage: "Notify me when there is space to free up",
  logout: "Logout",
  FreeUpSpace: "You can free up __size__ of space",
  unlimitedSpaceOnYourDevice: "Unlimited Space on your device",
  unlimitedSpaceOnYourDeviceDescription: "Safely free up space on your device and never run out again",
  yourMemoriesSafeSound: "Your Memories safe & sound",
  yourMemoriesSafeSoundDescription: "Got lots more picture and videos? Back them up and never lose anything",
  freeUp: "Free Up",
  freeUpCaps: "FREE UP",
  JustForTheGroup: "Just for the group",
  JustForTheGroupDescription: "Private sharing with your loved ones in one tap",
  AllUnderOneRoof: "All under one roof",
  AllUnderOneRoofDescription: "Easily see and organize content from your favorite services",
  view: "View",
  viewCaps: "VIEW",
  emptyTrashCan: "Empty Trash",
  restore: "Restore",
  restoreSuccessMessage: "item(s) restored successfully",
  SafeFreeUpSpace: `It’s safe! These __size__ are secured in you ${APP_TITLE} and ready to access any time`,
  CongratesFreeUpmessage: "Congrats! You have freed up  __size__!",
  aboutTitle: `About ${APP_TITLE}`,
  shareEmailheader: "SHARE ITEM(S) BY EMAIL",
  uploadEmailHeader: "Submit an Item(s) link",
  itemSharedMessage: "Item(s) shared successfully",
  emailEmptyError: "Please enter atleast one recipient",
  subjectEmptyError: "Subject can't be empty",
  contactSyncOffMessage: "Contact sync is off",
  contactSyncOnMessage: "Contact sync is on",
  importContact: "Import Contact",
  resetContacts: "Reset Contacts",
  viewOur: "View Our",
  termsAndConditionsText: "Terms & Conditions",
  termsAndPolicy: "Terms & Conditions / Privacy Policy",
  termsAndPolicyWeb: "Terms of Use / Privacy Policy",
  and: "and",
  myConnections: "My Connections",
  sharedNoDataHeader: "Nothing shared!",
  sharedNoDataMessage: "Albums, Sets or Collections that you shared or joined will show up here.",
  deleted: "Deleted",
  backupStatus: "Backup Status",
  completeTheBackup: "Complete the Backup",
  selectAllYourItems: "Select all your items",
  notifyUnselectedItemsMessage: "Notify me when there are unselected items",
  toSelect: "to select",
  backedUp: "backed Up",
  shareMailSubject: `I want to share something with you via ${APP_TITLE}`,
  folks: "Folks",
  telResidential: "Tel Residential",
  cellPhone: "Cell Phone",
  fax: "Fax",
  emailMain: "Email Main",
  personalEmail: "Personal Email",
  zipCode: "Zip Code",
  state: "State",
  city: "City",
  address: "Address",
  website: "Website",
  site: "Site",
  job: "Job",
  jobDetails: "Job Details",
  tel1: "Tel 1",
  tel2: "Tel 2",
  telCommercial: "Tel Commercial",
  tel3: "Tel 3",
  tel4: "Tel 4",
  otherTel1: "Other Tel 1",
  otherTel2: "Other Tel 2",
  otherFax: "Other Fax",
  pager: "Pager",
  instantMessage: "Instant Message",
  comments: "Comments",
  middleNameLabel: "Middle Name",
  editProfile: "Edit Profile",
  removePhoto: "Remove Photo",
  contactInformation: "Contact Information",
  firstNameLabel: "First Name",
  lastNameLabel: "Last Name",
  emailLabel: "E-mail Address",
  mobileLabel: "Phone Number",
  receiveEmailInAccountConfirmationMessage:
    "I would like to recieve periodice e-mails about upload activities in my account.",
  trashUndoneMessage: "This action cannot be undone.",
  myProfile: "My Profile",
  myDevices: "My Devices",
  mySignature: "My Signature",
  empty: "Empty",
  totalStorage: "Total Storage",
  inUse: "in use",
  inTheBin: "in the bin",
  inNonUploaded: "in non upload",
  contactNoDataHeader: "There are no contacts in your account!",
  contactNoDataMobileMessage: "Tap on plus icon to create new contact",
  contactNoDataMessage: "Create a contact by clicking the below button",
  addContact: "Add Contact",
  deleteContactHeaderCaps: "DELETE CONTACT",
  deleteContactMessage1:
    "Are you sure you want to delete __contactName__ from the contacts in your account and all your devices?",
  deleteContactMessage2: `Deleted items from ${APP_TITLE} will be deleted on mobile devices and computers on their next synchronization.`,
  mobile: "Mobile",
  notEnoughSpace: "Not enough free space.",
  noChangesFound: "No changes found",
  profileUpdatedMessage: "Profile updated successfully",
  middleName: "Middle Name",
  company: "Company",
  office: "Office",
  department: "Department",
  playOverCellNetwork: "Play over cell network",
  yesPlayAllTracks: "Yes, play all tracks",
  noStopPlaying: "No, stop playing",
  noPlayLocal: "No, play only tracks on my device",
  useInternetConfirmMessage:
    "This operation will use your data connection and it could consume your data plan. Do you want to continue?",
  myDeviceMessage: "All phones and tablets that access your account appear here:",
  unlinkPrimaryMessage: "Do you want to unlink the selected device from your account?",
  unlinkSecondaryMessage:
    "Unlinking the device will unplug the app and you will not be able to access or sync your cloud content from that device until you log in again.",
  deviceName: "Device Name",
  lastSeen: "Last Seen",
  unlink: "Unlink",
  unlinkDevice: "UNLINK DEVICE",
  unlinkMessage: "Device has been unlinked successfully",
  deviceMessage: "Android, Iphone, Ipad, Windows Phone",
  availableMessage: "Also available for",
  osDownloadMessage: "Free Download Mac OS",
  noItemsTrash: "No items in the recycle bin",
  deletedItemsPlaced: "Deleted items will be placed here",
  photoRemovedMessage: "Photo removed successfully",
  lowBatteryDownloadPrimaryMessage: "Low Battery to resume download",
  lowBatteryUploadPrimaryMessage: "Low Battery to resume backup",
  chargeDevice: "Charge your device",
  needWifiDownloadPrimaryMessage: "Need Wi-Fi to resume download",
  needWifiUploadPrimaryMessage: "Need Wi-Fi to resume backup",
  needWifiUploadLargeFilePrimaryMessage: "Copying files larger than 15 MB requires Wi-Fi connection",
  tapToChangeSettings: "Tap to change settings",
  notConnectedDownloadPrimaryMessage: "Not connected to internet",
  checkConnection: "Please check your connection",
  dataConsumeMessage:
    "This operation will use your data connection and it could consume your data plan. Do you want to continue?",
  networkChangeSettingConfirm:
    "You have __pendingUploads__ items queued for backup. They will be uploaded using your data connection and this could consume your data plan. Do you want to continue?",
  fileAlreadyExistMessage: "Item(s) already exists on this device",
  downloadingItems: "Downloading __itemcount__ items",
  createdPublicLink: "Created Public Link",
  copyLink: "Copy Link",
  createLink: "Create Link",
  linkCopied: "Link Copied",
  toSeePhotos: "To See Photos",
  other: "Other",
  editContact: "Edit Contact",
  otherInformation: "Other Information",
  personalInformation: "Personal Information",
  restoreContact: "Restore Contact",
  mergeDuplicateContact: "Merge Duplicate Contact",
  deleteContact: "Delete Contact",
  photoOfTheDay: "Photo of the Day",
  BackToDay: "Back To The Day",
  discard: "Discard",
  recoverDelete: "RECOVER DELETED",
  fixDuplicates: "FIX DUPLICATES",
  recoverDeleteSelectMessageSM: "Select Contacts",
  recoverDeleteSelectMessageMD: "Select the deleted contacts you want to recover:",
  mergeDuplicateSelectMessage: "We found _duplicateCount_ duplicate contact. Select the contact to merge:",
  recover: "Recover",
  similarContacts: "Similar Contacts",
  merge: "Merge",
  recoverContactMessage: "No deleted contact found.",
  noDuplicatesFound: "No duplicates found.",
  contactDeletedMessgae: "Contact deleted successfully",
  contactRestoredMessgae: "Contact restored successfully",
  contactsRestoredMessgae: "Contacts restored successfully",
  contactMergedMessgae: "Contact merged successfully",
  contactsMergedMessgae: "contacts merged successfully",
  contactAddedMessage: "Contact added successfully",
  contactUpdatedMessage: "Contact updated successfully",
  photograph: "Photograph",
  date: "Date",
  time: "Time",
  freeUpNoSpaceMessage:
    "You can secure more items in the cloud from the My Device screen and come back later to free up some more",
  memoryOfTheDay: "Memory of the Day",
  accept: "Accept",
  serviceTermsTitleCaps: "SERVICE TERMS",
  serviceTermsTitle: "Service Terms",
  termsAndConditions: "Terms and Conditions",
  privacyPolicy: "Privacy Policy",
  serviceTermsAcceptMessage:
    "By clicking on Accept you confirm that you have read and agree to the __termsAndConditions__ and __privacyPolicy__.",
  resetContact: "Reset Contact",
  startReset: "Start Reset",
  chooseDirection: "Choose Direction",
  resetMyDevice: "Reset my mobile device",
  resetMyAccount: "Reset my online account",
  resetContactMessage:
    "Reset is a powerful operation and should be used cautiously. Performing a reset will replace data on your mobile device with data from your online account or vice versa.",
  contactSyncPermissionToast: "Contacts permissison is required for Contacts sync.",
  addToContact: "Add To Contact",
  noContactFound: "No contact found!",
  backupInProgress: "Backup in progress...",
  backupPaused: "Backup paused",
  backupPausedDueToRoaming: "Backup paused due to roaming",
  downloadErrorMannualMessage: "There is some issue in downloading file. Please try again later",
  downloadingProgressMessage: "Item download already in progress",
  insufficientSpace: "Need storage to resume",
  insufficientSpaceSecondary: "Empty Trash and try again",
  tapToRetry: "Tap to Retry",
  generatingThumbnailMessage: "Generating Thumbnail",
  extractingDetailMessage: "Extracting Details",
  no_internet_message: "Please check you internet connection and try again…",
  contactSyncSingleViewMessage: "__count__ contact is synced with your online account and your other devices.",
  contactSyncMultipleViewMessage: "All __count__ contacts are synced with your online account and your other devices.",
  contactSyncToastMessage: "contact(s) synced successfully",
  SyncContactErrorMessage: "There is some error in sync contact. Please try again later.",
  ResetFromDeviceErrorMessage: "There is some error in reset my mobile device. Please try again later.",
  ResetFromServerErrorMessage: "There is some error in reset my online account. Please try again later.",
  startServerSync: "Start server syncing",
  addContactOnServer: "Adding _count_ contact(s) on server",
  deleteContactOnServer: "Deleting _count_ contact(s) on server",
  updateContactOnServer: "Updating _count_ contact(s) on server",
  startDeviceSync: "Start device syncing",
  contactSyncProgressMessage: "Contacts sync in progress...",
  addContactOnDevice: "Adding _count_ new contact(s) on device",
  deleteContactOnDevice: "Deleting _count_ contact(s) on device",
  updateContactOnDevice: "Updating _count_ contact(s) on device",
  populateDeviceChanges: "Populating device changes",
  getContactFromServer: "Getting all contacts from server",
  populateServerChanges: "Populating server changes",
  finalizing: "Finalizing",
  getAllContactFromDevice: "Getting all contacts from device",
  processAllContactFromDevice: "Processing all contacts on device",
  startSync: "Start Syncing",
  startResetDevice: "Starting reset from device",
  deleteAllContactFromDevice: "Deleting all contacts from device",
  startResetAccount: "Starting reset from online account",
  resettingContacts: "Resetting contacts on local table",
  "share/not-found": "The __type__ you are trying to be part of is no longer available",
  viewContent: `View content from other clouds in your ${APP_TITLE} apps`,
  aboutVersion: `${APP_TITLE} Version ${APP_BUILD_VERSION}`,
  buildVersion: `Build version ${APP_BUILD_VERSION}`,
  resolution: "Resolution",
  dateTaken: "Date Taken",
  fileName: "File Name",
  lastChange: "Last Change",
  details: "Details",
  throughPublicLink: "Through public link",
  requestInProgress: "Request Already in Progress",
  welcomeTransferMessageCaps: `WELCOME TO ${APP_TITLE.toUpperCase()}!`,
  transferFileTitle: "PREPARING THE APP…",
  transferInfoMessage:
    "Tap on NEXT to set up your new application.This should take some time. Meanwhile, you’ll be able to use your device normally!",
  transferInfoAcceptMessage: "This should take some time. Meanwhile, you’ll be able to use your device normally.",
  transferingMessage: "Transfering Your files",
  underStood: "UNDERSTOOD",
  update: "Update",
  appUpdateMessageExpire: `A "${APP_TITLE}" update is available to offer new features and more security. Please, update your app now before proceeding.`,
  appUpdateMessage: `A "${APP_TITLE}" update is available to offer new features and more security. This version will expire on __date__. Please, update your app now before proceeding.`,
  importantUpdate: "Important Update",
  no: "No",
  allow: "Allow",
  pressAgainToExitApp: "Press again to exit app",
  loopVideo: "Loop Video",
  detail: "Detail",
  generalInfoCaps: "GENERAL INFO",
  infoCaps: "INFO",
  duplicate: "Duplicate",
  clickHereToOpen: "Click here to Open",
  noFilePreviewAvailableText: "No Preview available for this file format.",
  albumDuplicateMessage: "Album name already exists.",
  setDuplicateMessage: "Set name already exists.",
  playlistDuplicateMessage: "Playlist name already exists.",
  confirmCaps: "CONFIRM",
  contactWaitingForNetwork: "waiting for internet...",
  Document: "Document",
  Spreadsheet: "Spreadsheet",
  Presentation: "Presentation",
  "Text Document": "Text Document",
  PDF: "PDF",
  "Photoshop Document": "Photoshop Document",
  "Web Page": "Web Page",
  Archive: "Archive",
  Media: "Media",
  Unknown: "Unknown",
  clearTrashMessage: "Trash emptied",
  clearCacheMessage: "Are you sure you want to delete all cache data? App will be restarted automatically.",
  clearCacheSuccessMessage: "Cache cleared successfully",
  loadImageErrorMessage: "Failed to load image",
  imageLoadNetworkErrorMessage: "Image Load failed due to internet connectivity",
  fileOpenFailedMessage: "There is no App available to open this file",
  videoLoadError: "Loading the video. It may take a few minutes. To view, download and try again.",
  imagePreviewError: "Image preview not available",
  unable_to_load_data: "Unable to load data",
  sharedItemAlreadyExist: "The item is already secured in your online account!",
  preparingYourFiles: "Preparing your files",
  issuesReported: "Reported Issues",
  mark_done: "Mark Done",
  in_progress: "In Progress",
  resolved: "Resolved",
  open: "Open",
  allComments: "All Comments",
  totalComment: "Comments",
  replyFromSupport: "Support Team Response",
  addIssues: "Add Issue",
  issuesDescription: "Issues Description",
  issuesTitle: "Issue Title",
  issuesAddMessage: "Issue added successfully",
  titleErrorMessage: "Title is mandatory",
  descriptionErrorMessage: "Description is  mandatory",
  addComment: "Add Comment",
  commentError: "Comment is mandatory",
  commentAddMessage: "Comment added successfully",
  commentPlaceholder: "Comment",
  insertAttachment: "Insert Attachment",
  issuesNoDataHeader: "There are no Issues in your account!",
  issuesNoDataMobileMessage: "Tap on add icon to create new issue",
  commentNoDataHeader: "There are no Comments in in this issue!",
  commentNoDataMobileMessage: "Tap on add comment icon to make new comment",
  report_an_issues: "Report An Issue",
  reported_issues: "Reported Issues",
  mark_donePopupHeaderCaps: "MARK DONE",
  markDoneSuccessMessage: "Issue marked successfully",
  markDonePopupMessage: "Are you sure to mark this issue as resolved",
  afterMigrationTitle: "Thank you for your patience!",
  afterMigrationMessage: `Your ${APP_TITLE} is ready to use now.__migrationStatusDetails__ If you are still facing any issue, you can choose "Report Issue" or you can connect with customer support using "Chat".`,
  migrationStatusMessage:
    "We have successfully transferred __transferredFiles__ files from the __totalFiles__.__migrationPendingDetails__",
  migrationPendingMessage: "We are processing __pendingCount__ files. After completion soon we will update you.",
  chat: "Chat",
  nextCaps: "NEXT",
  byDate: "By Date",
  thisWeek: "This Week",
  thisMonth: "This Month",
  thisYear: "This Year",
  selectDate: "Select Date",
  custom: "Custom",
  from: "From",
  to: "To",
  fromToValidation: "From must be less than To",
  syncProcessingMessage: "Syncing is in process. Please try after some time.",
  videoPreviewMessage: "Video preview not available",
  somethingWentWrong: "Something went wrong, please try again later",
  unexpectedErrorToast: "Some Unexpected Error occurred. Please send log to help the support team in troubleshooting.",
  syncProcessingCardMessage: "Sync in progress. This may take a few minutes. Please wait",
  largeFileOnWifiOnly: "For large items (more than 15 MB) use Wi-Fi only.",
  portWaitMessage: "Please wait while application is loading...",
  metadataPortError: "There is some issue in setting up your app. Please report issue from app or reinstall your app.",
  initialAutoBackupStartMessage: "Few files from your gallery will be uploaded for demo of Auto Backup feature.",
  autoBackupInfoMessage: "Auto Backup can be enabled from Configuration > Settings > Auto Backup",
  sendLogsConfirmMessage: "Do you want to Send Logs?",
  moveToSafeArea: "Move to Safe Area",
  moveToSafeAreaCaps: "MOVE TO SAFE AREA",
  moveToSafeAreaSingleMessage: "Do you want to move the selected item to safe area?",
  moveToSafeAreaMultipleMessage: "Do you want to move the __itemCount__ selected items to safe area?",
  item_moved_to_safe_area: "Item has been moved to safe area successfully",
  items_moved_to_safe_area: "Items have been moved to safe area successfully",
  removeFromSafeArea: "Remove from Safe Area",
  removeFromSafeAreaCaps: "REMOVE FROM SAFE AREA",
  removeFromSafeAreaSingleMessage: "Do you want to remove the selected item from safe area?",
  removeFromSafeAreaMultipleMessage: "Do you want to remove the __itemCount__ selected items from safe area?",
  item_removed_from_safe_area: "Item has been removed from safe area successfully",
  items_removed_from_safe_area: "Items have been remove from safe area successfully",
  safeArea: "Safe Area",
  moveToArchive: "Move to Archive",
  moveToArchiveCaps: "MOVE TO ARCHIVE",
  moveToArchiveSingleMessage: "Do you want to move the selected item to archive?",
  moveToArchiveMultipleMessage: "Do you want to move the __itemCount__ selected items to archive?",
  item_moved_to_archive: "Item has been moved to archive successfully",
  items_moved_to_archive: "__archived__ out of __total__ items have been moved to archive successfully",
  removeFromArchive: "Remove from Archive",
  removeFromArchiveCaps: "REMOVE FROM ARCHIVE",
  removeFromArchiveSingleMessage: "Do you want to remove the selected item from archive?",
  removeFromArchiveMultipleMessage: "Do you want to remove the __itemCount__ selected items from archive?",
  item_removed_from_archive: "Item has been removed from archive successfully",
  items_removed_from_archive: "Items have been removed from archive successfully",
  pinCreatedMessage: "PIN Created Successfully",
  pinUpdatedMessage: "PIN Updated Successfully",
  pinResetMessage: "PIN Reset Successfully",
  blockDeviceMessage: "Device Blocked Succesfully",
  loggedIn: "Currently Logged In!",
  createPin: "Create PIN",
  createPinCaps: "CREATE PIN",
  confirmPin: "Confirm PIN",
  confirmPinCaps: "CONFIRM PIN",
  enterPin: "Enter PIN",
  enterPinCaps: "ENTER PIN",
  createPinMessage: "Create PIN for Your Safe Area",
  createPinPlaceholder: "Enter the 6 digit PIN",
  confirmPinMessage: "Re-enter PIN",
  confirmPinPlaceholder: "Re-enter the 6 digit PIN",
  enterPinMessage: "Enter PIN",
  enterPinPlaceholder: "Enter the 6 digit PIN",
  forgotPin: "Forgot PIN",
  forgotPinCaps: "FORGOT PIN",
  resetPin: "Reset PIN",
  blockDevice: "Block Device",
  blockOtp: "Enter OTP to Block the device",
  resetPinCaps: "RESET PIN",
  resetPinMessage: "Reset PIN for Your Safe Area",
  "user/pin-mismatch": "PIN Mismatch",
  "user/invalid-vault-pin": "Invalid PIN, Please try again",
  "user/vault-pin-already-exists": "PIN already exists, Please try reset PIN to change the PIN",
  "user/vault-pin-does-not-exists": "PIN does not exists, Please create PIN and retry",
  "user/invalid-user": "Invalid user. Please try again",
  "user/same-pin-error": "Old PIN and New PIN must be different",
  "user/link-expired": "Link expired!",
  oldPinPlaceholder: "Enter the old 6 digit PIN",
  changePin: "Change PIN",
  changePinCaps: "CHANGE PIN",
  changePinMessage: "Change PIN for Your Safe Area",
  pinMandatoryMessage: "Please enter PIN",
  pinCountInvalidMessage: "Please fill 6 digit PIN",
  oldPinLabel: "Enter Old PIN",
  newPinLabel: "Enter New PIN",
  confirmPinLabel: "Enter Confirm PIN",
  otpFieldLabel: "Enter OTP",
  dataLoadingErrorMessage: "Data is still loading. Please try after some time.",
  resetPinLinkExpiredHeader: "The link you’re trying to visit is no longer available",
  resetPinLinkExpiredMessage:
    "You may have already used this link before or the address may be incorrect. Please check and try again.",
  fileTypeDetectError: "It wasn't possible to detect the type of the file",
  formatNotResolvedError: "The format of this file could not be opened",
  imageLoadErrorMessage: "Image could not be loaded",
  editImageNoChangeErrorMessage: "No changes detected",
  editImageNotFoundErrorMessage: "Could not detect the edited image. Please try again later",
  editImageErrorMessage: "There is some error is image editing. Please try again later",
  saveEditedImageErrorMessage: "Could not save edited image. Please try again later",
  uploadEditedImageErrorMessage:
    "Some error occurred in uploading the edited image. You can upload it manually from your gallery.",
  editImageSuccessMessage: "Image edited and uploaded to your gallery.",
  less: "Less",
  more: "More",
  allStats: "All Stats",
  commentDetail: "Comment Detail",
  editDetail: "Edit Detail",
  totalSpace: "Total Space",
  usedSpace: "Used Space",
  freeSpace: "Free Space",
  trashSpace: "Trash Space",
  progress: "Progress",
  status: "Status",
  devFeedback: "Dev Feedback",
  total: "Total",
  uploaded: "Uploaded",
  completed: "Completed",
  pending: "Pending",
  extracted: "Extracted",
  audio: "Audio",
  refresh: "Refresh",
  currentStats: "Current Stats",
  migrationStatus: "Migration Status",
  migrationProgress: "Migration Progress",
  reprocess: "Reprocess",
  totalFiles: "Total Files",
  migrationNotificationAccepted: "Migration Notification Accepted",
  migrationStartedOn: "Migration StartedOn",
  migrated: "Migrated",
  migratedFiles: "Migrated Files",
  migratedOn: "Migrated On",
  migrationStatusInfo: "Migration Status Info",
  migrationProgressInfo: "Migration Process Info",
  server: "Server",
  support: "Support",
  issueNumber: "Issue Number",
  title: "Title",
  musicUploadPromptMessage:
    "If the upload music option is not available for you, this should be happening due to an operational system restriction or a copy-protected content.",
  docUploadPromptMessage:
    "If the upload doc option is not available for you, this should be happening due to an operational system restriction or a copy-protected content.",
  customAttributesNotFound: "Custom Attributes not found",
  shareProcessingMessage: "Sharing is already in process",
  videoUnavailable: "Failed to play video",
  "share/title-length": "Title length must be between 5-100",
  "share/description-length": "Description length must be between 10-1000",
  "share/issue-in-english-length": "Issue in English length must be between 10-1000",
  "share/comment-length": "Comment length must be between 10-1000",
  "share/sharing-subject-length": "Subject length must be between 3-150",
  "share/email-ids-limit": "No of Emails must be between 1-25",
  "uploader/name-length": "Name length must be between 3-55",
  "user/first-name-length": "First Name length must be between 3-25",
  "user/last-name-length": "Last Name length must be between 3-25",
  "user/name-length": "Name length must be between 3-50",
  "user/email-length": "Email length must be between 5-55",
  "user/mobile-length": "Mobile length must be between 9-16",
  "user/pin-length": "Pin length must be 6",
  "metadata/name-length": "Name length must be between 3-55",
  "metadata/access-not-authorised": "You are not authorized to access this resource",
  VIRUS_DETECTED: "Virus Found",
  encryptionKeyError: "Encryption/Decryption key not found",
  encryptFileError: "There was a problem in encrypt file",
  userNotAuthorized: "Unauthorized User found",
  clearCache: "Clear Cache",
  cacheData: "Cache Data",
  queuedItems: "Queued Items",
  uploadQueueNoDataHeader: "No files queued for upload",
  downloadQueueNoDataHeader: "No files queued for download",
  moveCaps: "MOVE",
  move: "Move",
  itemsMovedSuccessfully: "Items(s) moved successfully",
  fingerprintScanMessage: "Scan your fingerprint on the device scanner to continue",
  enableBiometrics: "Enable __biometricType__",
  useBiometrics: "Use __biometricType__ to unlock",
  fingerprint: "Fingerprint",
  trashedSpace: "Trashed Space",
  photos: "Photos",
  videos: "Videos",
  audios: "Audios",
  documents: "Documents",
  errorInCaptcha: "Error in loading captcha",
  captchaExpired: "Captcha expired, please verify again",
  verifyCaptcha: "Please verify captcha",
  bySize: "By Size",
  byCount: "By Count",
  memories: "Memories",
  highlights: "Highlights",
  noMemories: "No Memories",
  picOfDayUserDeleted: "Can not import pic of day, user is marked deleted.",
  block: "Block",
  blockMessage: "Device has been blocked successfully",
  blockDevicePrimaryMessage: "Do you want to block this device?",
  blockDevicesSecondaryMessage:
    "Blocking the device will unplug the app and you will not be able to access or sync your cloud content from that device.",
  places: "Places",
  downloadAsZip: "Download as Zip",
  failedItems: "Failed Items",
  infected: "Infected",
  reason: "Reason",
  noItemsFailed: "You don't have any failed Items.",
  noItemsInfected: "You don't have any infected Items.",
  failedItemsPlaced: "Failed items will be placed here",
  infectedItemsPlaced: "Infected items will be placed here",
  deleteAll: "Delete All",
  emptyFailedMessage: "Are you sure you want to permanently delete all failed item(s) from your account?",
  emptyInfectedMessage: "Are you sure you want to permanently delete all infected item(s) from your account?",
  allItemsDeleted: "All items deleted successfully",
  Virus: "Virus",
  castConnectFailed: "Unable to connect to device",
  castFailed: "Unable to cast this file",
  saveAsPdf: "Save as PDF",
  saveAsGif: "Save as GIF",
  savePdfIgnoreMessage:
    "Your PDF will not include video and GIF. If any of these files is selected, it will be ignored at the time of PDF creation.",
  saveGifIgnoreMessage:
    "Your GIF will not include GIF Files. If any of these files are selected, they will be ignored at the time of creation.",
  generatingPdf: "Generating PDF...",
  pdfFailed: "Failed to generate PDF",
  PDFGenerated: "PDF Generated uploading to docs",
  scanningMessage: "Scanning for Virus",
  duplicateResources: "Duplicate Files",
  documentScanner: "PDF Generator",
  pdfMaxFileExceededMessage:
    "The maximum file limit has been exceeded. Cannot select more than __pdfFileLimit__ items.",
  pdfMaxSizeExceededMessage:
    "The maximum file size limit has been exceeded. Cannot select more than __pdfFileSizeLimit__ MB items.",
  // documentScanner: "Document Scanner",
  docScannerPrimaryText: "Add images to create PDF",
  docScannerSecondaryText: "Tap on plus icon to add images",
  captureImage: "Capture Image",
  captureVideo: "Capture Video",
  selectFromDeviceGallery: "Select from device gallery",
  selectFromCloudGallery: "Select from Cloud Gallery",
  editPhoto: "Edit Photo",
  uploadFromCamera: "Upload from camera",
  pdfMessage: "Pdf will be generated shortly and automatically upload to docs.",
  gifMessage: "Gif will be generated shortly and automatically upload to gallery.",
  "uploader/resouce-exceeds": "The maximum file limit has been exceeded. Cannot select more than 100 items.",
  someErrorOccurred: "Some error occurred, please try again.",
  savePDFValidationMessage: "Please enter a name",
  saveGIFValidationMessage: "Please enter a name",
  pdfNameLengthMessage: "Pdf name length must be between 3-55",
  gifNameLengthMessage: "Gif name length must be between 3-55",
  failedToUpdateProfilePicture: "Failed to update profile picture",
  failedToUploadIssueFile: "Failed to upload issue file",
  downloadAsPdf: "Download as PDF",
  PDFDownloaded: "PDF Downloaded Successfully",
  "share/attachments-length": "You can only add upto __attachmentLimit__ items.",
  attachmentLimitExceedMessage: "You can only add up to __attachmentLimit__ items. Remove items if already added.",
  attachmentUploading: "Please wait a moment. Adding attachments.",
  logoutConfirmMessage: "Are you sure want to logout?",
  cameraPermissionDenied:
    "Make sure the application has access to camera permissions for this functionality to work properly.",
  grantLocationPermissionsMessage: "",
  location: "Location",
  acceptTerms: "I have read and accepted the ",
  acceptTermsErrorMsg: "Please Accept the Terms of Use / Privacy Policy above!",
  next: "Next",
  profileCameraPermissionMessage:
    "By Giving this permission, the application takes photos from device's camera to update the profile picture on the servers. When you deny this permission, the functionality stops working.",
  profileStoragePermissionMessage:
    "By Giving this permission, the application takes photos from device gallery to update the profile picture on the servers. When you deny this permission, the functionality stops working.",
  docScannerCameraPermissionMessage:
    "By Giving this permission, the application takes photos from device's camera to create documents using Document Scanner. When you deny this permission, the functionality stops working.",
  docScannerStoragePermissionMessage:
    "By Giving this permission, the application takes photos from device gallery to create documents using Document Scanner. When you deny this permission, the functionality stops working.",
  cameraPermissionMessage:
    "By Giving this permission, the application copies and protects photos taken with the device's camera on its servers. When you deny this permission, the Upload from Camera functionality stops working.",
  appStoragePermissionMessage:
    "By Giving this permission, the application copies and protects the photo and video files on its servers. By denying this permission, the application may stop working as expected.",
  locationPermissionMessage:
    "By giving this permission, the application allows you to save the location by taking a photo with the device's camera on its servers. When you deny this permission, the Upload from Camera functionality may stop working as expected.",
  contactPermissionMessage:
    "By Giving this permission, the application copies and protects the contact data from the device on its servers. When you deny this permission, the Contacts feature stops working",
  biometricPermissionMessage:
    "The application uses __biometricType__ to more quickly protect and unlock files saved on its servers.",
  recentHighlights: "Recent Highlights",
  OneMonthAgo: "One month ago",
  OneYearAgo: "One year ago",
  MoreThanAnYearAgo: "More than an year ago",
  desktopLinks: "Download to PC",
  localStats: "UnSynced",
  statsTitle: "UnSynced data",
  everyTime: "Every time",
  everyDay: "Every day",
  everyWeek: "Every week",
  above: "above",
  battery: "Battery",
  fromText: "from",
  library: "Library",
  appVersion: "App Version",
  chooseAnotherFile: "Unable to upload. Please choose another file.",
  removeItems: "Remove Item(s)",
  removeNonUploaded: "Remove Non Uploaded",
  emptyNonUploadMessage: "Are you sure you want to permanently delete all non uploaded item(s) from your account?",
  clearNonUploadMessage: "Non Uploaded Item(s) removed",
  nonUploadUndoneMessage:
    "Your recently started upload also get removed by clean non uploaded files. Cancel if you want to ignore this removal.",
  noNonUploadedItems: "You don't have any Non Uploaded Items.",
  NonUploadedItemsPlaced: "Non Uploaded items will be placed here",
  enc_processing: "processing",
  encryption_status: "Encryption Status",
  encryption_count: "Old files that were encrypted",
  buttonContinue: "Continue",
  buttonKeepAccount: "Leep account",
  buttonGoBack: "Go to top",
  titleStepOne: "What you should know before deleting your account",
  title1StepTwo: "Why are you deleting this account?",
  title2StepTwo: "How can we improve?",
  titleStep1Three: "By deleting your account we will remove all your information from our database.",
  titleStep2Three: "This action is irreversible.",
  titleStep3Three: "Para confirmar, digite ”EXCLUIR” no campo abaixo:",
  titleStepSuccess: "To confirm, type ”DELETE” in the field below:",
  titleStepError: "Oops, something went wrong!",
  description1StepOne: "You can delete your account, but first you need to unsubscribe when the subscription is paid.",
  description2StepOne: "If your subscription is billed directly by Meet Me, after your account deletion request, you will continue to have access to Me Encontra and your account until the subscription billing cycle ends.",
  description3StepOne: "If your subscription is billed by a partner company, upon account deletion request, you will immediately lose access to Me Encontra and account. You must contact the partner company directly to cancel your subscription and avoid future charges.",
  description4StepOne: "By deleting your account, you declare that you are aware that the deletion of the account will imply the end of your access to Me Encounter and that, even in this case, the minimum information storage period determined by Brazilian legislation must be respected.",
  description1Success: "Thank you for using our product.",
  description2Success: "We hope you can return soon!",
  description1Error: "We were unable to complete your order at this time.",
  description2Error: "We are working to resolve this issue! Please try again later.",
  question1StepTwo: "I do not need any more",
  question2StepTwo: "Subscription value",
  question3StepTwo: "Other",
  incorrectWord: "Incorrect word.",
  incorrectAction: "Type EXCLUIR to complete your action."

};
const pt = {
  "locale-string": "pt",
  "app-title": `${APP_TITLE}`,
  login: "Entrar",
  loginCaps: "ENTRAR",
  walkthroughButtonText: "ENTRAR",
  logging: "Fazendo login ...",
  copyrightText: `${APP_SHORT_NAME} todos os direitos reservados`,
  privacyPolicyText: "Política de Privacidade",
  termsOfUseText: "Termos de uso",
  termsAndPolicyWeb: "Termos de uso / Política de Privacidade",
  termsAndPolicy: "Termos e Condições / Política de Privacidade",
  loginPrimaryText: `Cliente ${APP_SHORT_NAME} Móvel`,
  loginPrimaryLandLineText: `Cliente ${APP_SHORT_NAME} Fixo`,
  loginSecondaryText: `Número de telefone`,
  mobilePlaceholder: "Entre com seu DDD + Celular",
  mobileMandatoryMessage: "Por favor digite o número do telefone celular",
  mobileCountInvalidMessage: "O número de telefone deve ter 11 dígitos",
  otpPrimaryText: `Insira o código de 6 dígitos enviado para`,
  otpLabel: "Código de 6-dígitos",
  otpPlaceholder: "Digite o código enviado",
  otpMandatoryMessage: "Por favor digite o código",
  otpCountInvalidMessage: "Por favor, preencha com um código de 6 dígitos",
  mobileDDDinvalidMessage: "DDD Inválido",
  mobileStart9Message: "Seu número deve começar com 9",
  no_internet: "Sem conexão com a Internet",
  "auth/invalid-verification-code": "O código de verificação para SMS está errado",
  "auth/code-expired": "Seu código expirou. Clique no botão de reenvio para receber um novo e tente novamente.",
  "auth/captcha-expired": "O Captcha expirou. Por favor, tente novamente.",
  "auth/invalid-phone-number": "Número de telefone não é válido",
  "auth/network-request-failed": "Sem conexão com a Internet",
  "auth/id-token-expired": "Token Expirado",
  continue: "Continuar",
  changePartner: "Trocar Parceiro",
  agreementText1: "Ao clicar em Continuar, confirmo que concordo com o",
  agreementText2: "do serviço.",
  resendCode: "Reenviar código",
  codeSentMessage: "Código foi enviado com sucesso",
  loginOptionTitle: "Você é um cliente",
  mobileLoginTitle: `${APP_SHORT_NAME} Móvel`,
  landlineLoginTitle: `${APP_SHORT_NAME} Fixo`,
  resendInTimeText: "Reenviar código em",
  invalidNumber: "Número não é válido",
  validating: "Validando ...",
  skip: "Skip",
  highlights: "Destaques",
  gallery: "Galeria",
  galleryCaps: "GALERIA",
  docs: "Arquivos",
  docsCaps: "ARQUIVOS",
  music: "Música",
  group: "Grupo",
  groups: "Grupo",
  all: "Todos",
  allCaps: "TODOS",
  albums: "Álbuns",
  select: "Selecionar",
  upload: "Enviar",
  uploadFile: "Enviar arquivo",
  autoBackup: "Backup Automático",
  selectItems: "Selecionar itens",
  layout: "Layout",
  changeLayout: "Alterar layout",
  selectNone: "Selecione nenhum",
  selectAll: "Selecionar tudo",
  addToAlbum: "Adicionar ao álbum",
  addToSet: "Adicionar à pasta",
  addToPlaylist: "Lista de reprodução",
  download: "Baixar",
  downloadPaused: "Download pausado",
  downloadPausedDueToRoaming: "Download pausado devido a roaming",
  downloadProgress: "Download em progresso...",
  uploadInProgress: "Upload em andamento ...",
  uploadPaused: "Upload pausado",
  tapToResume: "Toque para continuar",
  itemsRemaining: "item(ns) restante(s)",
  itemsQueued: "item(ns) na fila",
  delete: "Excluir",
  selected: "Selecionado(s)",
  search: "Procurar",
  types: "Tipos",
  favourite: "Favorito",
  aac: "AAC",
  mp3: "MP3",
  pdf: "PDF",
  document: "Documento",
  presentation: "Apresentação",
  spreadsheet: "Planilha",
  media: "Mídia",
  text: "Texto",
  webPage: "Página da web",
  archive: "Itens Arquivados",
  typeHere: "Digite aqui",
  layoutHuge: "Muito grande",
  layoutLarge: "Grande",
  layoutMedium: "Médio",
  layoutSmall: "Pequeno",
  layoutTiny: "Muito pequeno",
  info: "Info",
  print: "Impressão",
  postToGroup: "Postar no grupo",
  postToGroupCaps: "POSTAR NO GRUPO",
  post: "Postar",
  shareVia: "Compartilhar via",
  getLink: "Obter link",
  removePopupHeaderCaps: "REMOVER ITEM",
  removeAlbumSingleItemPopupMessage: "Deseja remover o item selecionado do álbum __albumName__?",
  removeAlbumMultipleItemPopupMessage: "Deseja remover os __itemCount__ itens selecionados do álbum __albumName__?",
  removeSetSingleItemPopupMessage: "Deseja remover o item selecionado do conjunto __setName__?",
  removeSetMultipleItemPopupMessage: "Deseja remover os __itemCount__ itens selecionados do conjunto __setName__?",
  removePlaylistSingleItemPopupMessage: "Deseja remover o item selecionado da lista de reprodução __playlistName__?",
  removePlaylistMultipleItemPopupMessage:
    "Deseja remover os __itemCount__ itens selecionados da lista de reprodução __playlistName__?",
  removeAlbumPopupMessage:
    "Tem certeza de que deseja excluir o álbum __albumName__? Os itens não serão excluídos. O álbum não estará mais disponível através do link compartilhado.",
  removeSetPopupMessage:
    "Tem certeza de que deseja excluir a pasta __setName__? Os itens não serão excluídos. A pasta não estará mais disponível através do link compartilhado.",
  removeVaultAlbumPopupMessage:
    "Tem certeza de que deseja excluir o álbum __albumName__? Os itens não serão excluídos.",
  removeVaultSetPopupMessage: "Tem certeza de que deseja excluir a pasta __setName__? Os itens não serão excluídos.",
  removePlaylistPopupMessage:
    "Tem certeza de que deseja excluir a playlist __playlistName__? Os itens não serão excluídos",
  removeItemMessage: "Item(s) removido(s) com sucesso",
  deletePopupHeader: "Excluir item",
  enterYourName: "Digite seu nome",
  enterYourDetails: "Insira seus detalhes",
  deletePopupHeaderCaps: "DELETAR ITEM(S)",
  deleteAlbumCaps: "DELETAR ALBUM",
  deleteSetCaps: "DELETAR PASTA",
  deletePlaylistCaps: "DELETAR PLAYLIST",
  deleteMultipleItemPopupMessageSM: "Você gostaria de mover os __itemCount__ itens selecionados para a lixeira?",
  duplicateDeleteMultipleItemPopupMessage:
    "Excluir todas as duplicatas de um item também pode excluir o original. Você gostaria de continuar?",
  deleteSingleItemPopupMessageSM: "Você gostaria de mover o item selecionado para a lixeira?",
  deleteMultipleItemPopupMessageMD: "Você quer mover os __itemCount__ itens selecionados para o lixo?",
  deleteSingleItemPopupMessageMD: "Você quer mover o item selecionado para o lixo?",
  deleteItemsPermanent: "Tem certeza de que deseja excluir permanentemente _itemCount_ item(s) da sua conta?",
  removeGroupSingleItemMessage: "Você deseja remover o item selecionado do grupo?",
  removeGroupMultipleItemMessage: "Você deseja remover os  __itemCount__ itens selecionados do grupo?",
  emptyTrashMessage: "Tem certeza de que deseja excluir permanentemente todos os itens da sua conta?",
  makePrivatePopupHeaderCaps: "TORNAR PRIVADO",
  makePrivateAlbumPopupMessage: "Você tem certeza que deseja tornar esse álbum privado?",
  makePrivateSetPopupMessage: "Você tem certeza que deseja tornar essa pasta privada?",
  makePrivateResourcePopupMessage:
    "Links para visualizar esse conteúdo não funcionarão mais. Você pode compartilhar este conteúdo novamente a qualquer momento.",
  makePrivate: "Tornar privado",
  makeItPrivate: "Tornar privado",
  albumLeaveMessage: "Você saiu do álbum com sucesso",
  setLeaveMessage: "Você saiu da pasta com sucesso",
  uploadPopupHeader: "Adicionar Item",
  uploadPopupHeaderCaps: "ADICIONAR ITEM",
  uploadSingleItemPopupMessage: "Você gostaria de enviar o item selecionado?",
  uploadMultipleItemPopupMessage: "Você gostaria de enviar os __itemCount__ itens selecionados?",
  cancel: "Cancelar",
  ok: "Ok",
  yesContinue: "Sim continuar",
  moveToTrash: "Mover para lixeira",
  highlightsNoDataHeader: "Sem destaques!",
  devicesNoDataHeader: "Sem dispositivos!",
  highlightsNoDataMessage: "Eles serão automaticamente criados e exibidos aqui",
  galleryAllNoDataHeader: "Nenhuma foto ou vídeo",
  galleryAllNoDataMobileMessage: "Adicione conteúdos clicando no botão acima",
  archiveNoDataMobileMessage: "Adicione arquivos escolhendo ‘Mover para Arquivo’ no menu acima",
  galleryAllNoDataMessage: "Adicione conteúdos clicando no botão acima",
  vaultNoDataMessage: "Envie algo colocando-o aqui ou escolha os arquivos tocando no botão abaixo",
  archiveNoDataMessage: "Adicione arquivos tocando no botão abaixo",
  shareNoDataHeader: "O arquivo não está mais aqui",
  shareNoDataMessage: "Ele pode ter sido excluído ou deixou deixou de ser compartilhado",
  add: "Adicionar",
  galleryAlbumNoDataHeader: "Crie um novo álbum",
  galleryPlaceNoDataHeader: "Sem lugares",
  galleryDeviceFolderNoDataHeader: "Sem pasta de dispositivo!",
  galleryAlbumNoDataMessage: "Crie um novo, tocando no ícone + acima",
  createAlbum: "Criar álbum",
  sets: "Pastas",
  docsAllNoDataHeader: "Sem arquivos",
  docsSetNoDataHeader: "Crie uma nova pasta",
  docsNoDataMessage: "Carregue algo tocando no botão de adição abaixo ou soltando-o aqui",
  docsNoDataMobileMessage: "Adicione conteúdos clicando no botão acima",
  docsSetNoDataMessage: "Crie uma nova pasta clicando no botão acima",
  createSet: "Criar Pasta",
  playlists: "Playlists",
  playlistsCaps: "PLAYLISTS",
  artist: "Artista",
  genre: "Gênero",
  file: "Arquivo",
  account: "Conta",
  storage: "Armazenamento",
  unlimited: "Ilimitado",
  emptyTrashCan: "Excluir lixeira",
  restore: "Restaurar",
  restoreSuccessMessage: "item(s) restaurados(s) com sucesso",
  personalInfo: "Informação pessoal",
  "Track Order": "Acompanhar Pedido",
  artists: "Artistas",
  artistsCaps: "ARTISTAS",
  videoConversionProgress: "Por favor, aguarde um instante. Estamos preparando o vídeo",
  groupGalleryNoDataMobileMessage: "Toque no ícone acima e escolha qualquer item da sua Galeria para postar aqui.",
  groupDocsNoDataMobileMessage: "Toque no ícone acima e escolha qualquer arquivo para postar aqui.",
  musicAllNoDataHeader: "Não há música",
  musicPlaylistNoDataHeader: "Crie uma nova playlist",
  musicPlaylistNoDataMobileHeader: "Crie uma nova playlist",
  musicAllNoDataMessage: "Envie algo tocando no botão adicionar abaixo ou soltando-os aqui",
  musicAllNoDataMobileMessage: "Adicione conteúdos clicando no botão acima",
  musicPlaylistNoDataMessage: "Crie uma nova playlist clicando no botão acima",
  albumNoDataMobileMessage: "Toque no ícone + para criar novo álbum",
  selectAlbumNoDataMobileMessage: "Toque no novo álbum para criar um novo",
  setsNoDataMobileMessage: "Crie uma nova pasta, tocando no ícone + acima",
  selectSetsNoDataMobileMessage: "Toque em nova pasta para criar uma nova",
  playlistNoDataMobileMessage: "Crie uma nova, tocando no ícone + acima",
  selectPlaylistNoDataMobileMessage: "Toque na nova lista de reprodução para criar uma nova",
  createPlaylist: "Criar playlist",
  musicArtistsNoDataHeader: "Nenhum artista",
  musicArtistsNoDataMessage: "Faça upload de algumas músicas, você encontrará seus artistas aqui",
  membersCaps: "MEMBROS",
  members: "Membros",
  member: "Membro",
  owner: "Proprietário",
  inviteExpireMessage: "O convite expira em _days_ dias",
  blog: "Blog",
  shared: "Compartilhado",
  allFiles: "Todos os arquivos",
  contacts: "Contatos",
  trashCan: "Lixeira",
  trash: "Lixo",
  home: "Home",
  freeUpSpace: "Liberar espaço",
  backup: "Backup",
  backupCaps: "BACKUP",
  myConnection: "Minhas conexões",
  configuration: "Configurações",
  used: "usados",
  name: "Nome",
  size: "Tamanho",
  modifications: "Modificações",
  type: "Tipo",
  cancelAll: "Cancelar tudo",
  resumeAll: "Continuar tudo",
  uploading: "Fazendo Upload",
  item: "Item(s)",
  itemsUploaded: "Item(s) enviado",
  open: "Abrir",
  tryAgain: "Tente novamente",
  resume: "Continuar",
  pause: "Pausa",
  cancelled: "Cancelado",
  of: "de",
  uploadNewItem: "Enviar novo item",
  dragAndDropQuote: "Você pode arrastar e soltar o item aqui ...",
  albumDateLabel: "__date__ de __month__ de __year__",
  month_0: "Jan",
  month_1: "Fev",
  month_2: "Mar",
  month_3: "Abr",
  month_4: "Mai",
  month_5: "Jun",
  month_6: "Jul",
  month_7: "Ago",
  month_8: "Set",
  month_9: "Out",
  month_10: "Nov",
  month_11: "Dez",
  Sun: "Dom",
  Mon: "Seg",
  Tue: "Ter",
  Wed: "Qua",
  Thu: "Qui",
  Fri: "Sex",
  Sat: "Sab",
  am: "da manhã",
  pm: "da tarde",
  pixels: "píxeis",
  userNotExist: "Usuário não existe",
  userNotFound: "Usuário/ID Firebase não encontrado",
  "user/not-active": "Conta desativada",
  "user/token-missing": "Context/Token is missing",
  "user/phone-number-not-matched": "User not found with phone number saved in firebase",
  "user/invalid-token": "Invalid Token",
  clientNotFound: "Cliente não cadastrado",
  lastModified: "Última modificação",
  viewByCreatedDate: "Data de upload",
  viewBylastModified: "Data da última modificação",
  viewBySize: "Ver por tamanho",
  "uploader/limit-exceeds": "Armazenamento insuficiente",
  "uploader/height-width-missing": "Altura / largura do recurso ausente",
  "uploader/no-file-size": "Nenhum tamanho de arquivo",
  uploadingFailed: "Upload falhou",
  modified: "Modificado",
  source: "Fonte",
  error: "Erro",
  successFullyUploaded: "Enviado com sucesso",
  failed: "Falhou",
  addToCaps: "ADICIONAR À",
  createNewAlbum: "Criar um novo álbum",
  createNewSet: "Criar uma nova pasta",
  createNewPlaylist: "Criar uma nova playlist",
  createNewAlbumHeader: "Criar um novo álbum",
  createNewSetHeader: "Criar uma nova pasta",
  createNewPlaylistHeader: "Criar uma nova lista de reprodução",
  addToAlbumValidationMessage: "Por favor, digite um nome para o novo álbum",
  addToSetValidationMessage: "Por favor digite um nome para a nova pasta",
  addToAlbumMessage: "Selecione o álbum ao qual deseja adicionar os itens ou escolha Novo Álbum.",
  albumDuplicateNameError: "Já existe outro álbum com esse nome. Por favor, escolha outro nome",
  setDuplicateNameError: "Já existe outra pasta com esse nome. Por favor, escolha outro nome",
  playlistDuplicateNameError: "Já existe outra playlist com esse nome. Por favor, escolha outro nome",
  noItemAvailable: "Nenhum item disponível",
  addContentMessage: "Adicione algum conteúdo à sua conta e tente novamente.",
  createCollectionInputPlaceholder: "Digite",
  createPlaylistInputPlaceholder: "Digite o nome do lista de reprodução",
  createAlbumInputPlaceholder: "Digite o nome do álbum",
  createSetInputPlaceholder: "Digite o nome da pasta",
  createNewAlbumSecondaryMessage: "Não há álbum disponível, Por favor, crie um novo álbum para adicionar o conteúdo",
  createNewSetSecondaryMessage: "Não há uma pasta disponível. Por favor, crie uma nova pasta para adicionar o conteúdo",
  createNewPlayListSecondaryMessage:
    "Não há playlist disponível. Por favor, crie uma nova playlist para adicionar o conteúdo",
  addToPlaylistValidationMessage: "Por favor insira um nome para nova playlist",
  selectErrorMesage: "Você deve selecionar pelo menos uma opção.",
  nonUploadedItems: "Itens não carregados",
  addToAlbumCaps: "ADICIONAR AO ÁLBUM",
  addToSetCaps: "ADICIONAR À PASTA",
  addToPlaylistCaps: "ADICIONAR À PLAYLIST",
  createANewAlbumCaps: "CRIAR UM NOVO ÁLBUM",
  createANewSetCaps: "CRIAR UMA NOVA PASTA",
  createANewPlaylistCaps: "CRIAR UMA NOVA PLAYLIST",
  addToSetMessage: "Selecione a pasta para adicionar os itens, ou escolha nova pasta.",
  addToPlaylistMessage: "Selecione a playlist para adicionar os itens ou escolha nova playlist",
  item_send_to_trash: "Item enviado para o lixo com sucesso",
  items_send_to_trash: "Itens enviados para o lixo com sucesso",
  today: "Hoje",
  upload_no_internet: "Falha no envio. Verifique sua conexão com a Internet e tente novamente.",
  file_no_more_error: "O arquivo não está mais disponível",
  EXTENSION_MISMATCH: "Incompatibilidade de tipo / extensão",
  renameCaps: "RENOMEAR",
  rename: "Renomear",
  toView: "Ver",
  renameValidationMessage: "Por favor, insira um nome",
  removeFromGroup: "Remover do grupo",
  edit: "Editar",
  save: "Salvar",
  saveCaps: "SALVAR",
  create: "Criar",
  setHeader: "Nome da Pasta",
  playlistHeader: "Nome da Playlist",
  albumHeader: "Nome do álbum",
  inputTitle: "Insira o nome",
  remove: "Remover",
  invite: "Convidar",
  inviteMembers: "Convidar membro",
  inviteMessage: "Compartilhar um link de convite",
  groupNoDataHeaderWeb:
    "Crie um grupo para compartilhar suas fotos, vídeos e arquivos de forma segura com seus amigos.",
  groupNoDataMessageWeb:
    "Sempre que você ou outro membro do seu grupo compartilhar conteúdo, todos receberão uma notificação",
  begin: "Início",
  groupNoDataHeaderMobile: "Crie um grupo",
  groupNoDataMessageMobile: "Compartilhe seus arquivos com seus amigos. Convide-os para fazer parte do seu grupo.",
  start: "Começar",
  startCaps: "COMEÇAR",
  toShare: "Compartilhar",
  shareOptionsHeader: "Selecione como compartilhar abaixo",
  email: "E-mail",
  emailAddress: "Endereço de e-mail",
  phoneNumber: "Número de telefone",
  userName: "Nome de usuário",
  facebook: "Facebook",
  twitter: "Twitter",
  shareAgreementText: `Ao clicar em" Enviar ", declaro que tenho o direito de distribuir estes itens`,
  uploadAlbumHeader: "Enviar um link para o álbum",
  subjectMatter: "Assunto",
  shareAlbumHeader: "COMPARTILHAR ÁLBUM POR E-MAIL",
  submit: "Enviar",
  enterEmailId: "Insira um e-mail",
  enteruserName: "Insira o nome de usuário",
  enterPhoneNumber: "Insira o número de telefone",
  addEmail: "Insira um email e pressione enter",
  getLinkCaps: "OBTER LINK",
  close: "Fechar",
  linkAgreementText:
    "Ao usar este link de compartilhamento, certifico que estou autorizado a compartilhar esse conteúdo",
  openLinkText1: `Abra o link em uma nova aba`,
  openLinkText2: `ou copie e cole o link abaixo em um navegador`,
  getAlbumLink: "Obter link do álbum",
  groupIntroduce: "Apresente-se",
  sendToGroup: "Enviar para grupo",
  sendToGroupCaps: "ENVIAR PARA O GRUPO",
  groupIntroduceMessage: "Como você gostaria de aparecer?",
  groupIntroduceDiscription: "Seu nome só será divulgado na mídia que você compartilhar ou participar.",
  firstName: "Primeiro nome",
  lastName: "Último nome",
  uploadIconTitle: "Carregar novos itens",
  viewAll: "Ver todas as fotos",
  markFavouriteTitle: "Marcar Favorito",
  unmarkFavouriteTitle: "Desmarcar Favorito",
  toShareTitle: "Compartilhar",
  removeFromAlbum: "Remover do álbum",
  removeFromSet: "Remover da pasta",
  removeFromPlaylist: "Remover da playlist",
  infoTitle: "Info",
  createAlbumTitle: "Criar um novo álbum",
  createSetTitle: "Criar uma nova pasta",
  createPlaylistTitle: "Criar uma nova playlist",
  slideshowTitle: "Reproduzir apresentação de slides",
  enterFirstName: "Insira o primeiro nome",
  enterLastName: "Insira o último nome",
  editMyProfile: "Edite meu perfil",
  resendInvitation: "Reenviar convite",
  cancelInvitation: "Cancelar convite",
  accepted: "Eles Aceitaram",
  removeGroupCaps: "REMOVER O GRUPO",
  postedOn: "Postado em",
  postedBy: "Postado por",
  by: "por",
  leaveGroupCaps: "DEIXE O GRUPO",
  removeGroup: "Remover Grupo",
  leaveGroup: "Deixar o grupo",
  leaveAlbum: "Sair do álbum",
  leaveSet: "Sair da pasta",
  leaveAlbumConfirmMessage: "Você não poderá mais acessar o conteúdo deste álbum. Tem certeza de que deseja sair?",
  leaveSetConfirmMessage: "Você não poderá mais acessar o conteúdo desta pasta. Tem certeza de que deseja sair?",
  leave: "Sair",
  addMembersCaps: "ADICIONAR MEMBROS",
  addMembers: "Adicionar membros",
  viewProfile: "Ver perfil",
  removeGroupMessage:
    "O conteúdo de todos os membros do grupo será removido. Você pode configurar um novo grupo a qualquer momento.",
  leaveGroupMessage:
    "Deixando o grupo, você não terá mais acesso ao conteúdo compartilhado. Tem certeza de que deseja sair?",
  noMatchHeader: "Nenhum resultado encontrado!",
  noMatchMessage: "Por favor, mude os critérios de busca e tente novamente",
  uploadMusicNoDataHeader: "Nenhuma faixa para enviar!",
  uploadMusicNoDataMessage: "Suas faixas já estão na sua conta on-line",
  uploadDocumentNoDataHeader: "Nenhum arquivo para enviar!",
  uploadDocumentNoDataMessage: "Seus arquivos já estão na sua conta on-line.",
  uploadGalleryNoDataHeader: "Nenhuma mídia para enviar!",
  uploadProfileGalleryNoDataHeader: "Nenhuma imagem para enviar",
  uploadGalleryNoDataMessage: "Suas fotos e vídeos já estão na sua conta on-line.",
  galleryPermissionMessage:
    "Por favor, forneça permissões de armazenamento nas configurações para acessar fotos e vídeos",
  filesPermissionMessage: "Por favor, forneça permissões de armazenamento nas configurações para acessar arquivos",
  audioPermissionMessage: "Por favor, forneça permissões de armazenamento nas configurações para acessar o áudio",
  shareLink: "Compartilhar link",
  oneMoreStep: "Mais um passo",
  inviteMemberMessage: "Compartilhe um link para convidar outras pessoas para o grupo.",
  createdSuccessfully: "Criado com sucesso",
  albumCreated: "Álbum criado com sucesso",
  setCreated: "Pasta criada com sucesso",
  playlistCreated: "Playlist criada com sucesso",
  itemPostToGroup: "Item postado no Grupo",
  itemsPostToGroup: "itens postados no Grupo",
  itemAddedToAlbum: "Item adicionado ao Álbum",
  itemAddedToSet: "Item adicionado à pasta",
  itemAddedToPlaylist: "Item adicionado à playlist",
  itemsAddedToAlbum: "iten(s) adicionados ao Álbum",
  itemsCountAddedToAlbum: "__itemcount__ Iten(s) Adicionados Ao Álbum",
  itemsCountIgnoredToAlbum: "__itemcount__ Iten(s) Ja Existem No Álbum",
  itemsCountAddedToSet: "__itemcount__ Iten(s) Adicionados À Pasta",
  itemsCountIgnoredToSet: "__itemcount__ Iten(s) Ja Existem Na Pasta",
  itemsCountAddedToPlaylist: "__itemcount__ Iten(s) Adicionados À Playlist",
  itemsCountIgnoredToPlaylist: "__itemcount__ Iten(s) Ja Existem Na Playlist",
  itemsPostedToGroup: "__itemcount__ Iten(s) Postados No Grupo",
  itemsExistsToGroup: "__itemcount__ Iten(s) Ja Existem No Grupo",
  itemsAddedToSet: "itens adicionados à pasta",
  itemsAddedToPlaylist: "itens adicionados à playlist",
  albumNameLengthMessage: "O nome do álbum deve ter 3 e 55 caracteres",
  setNameLengthMessage: "O nome da pasta deve ter 3 e 55 caracteres",
  playlistNameLengthMessage: "O nome da playlist deve ter 3 e 55 caracteres",
  imageNameLengthMessage: "O nome do imagem deve ter 3 e 55 caracteres",
  videoNameLengthMessage: "O nome do vídeo deve ter 3 e 55 caracteres",
  docNameLengthMessage: "O nome do documento deve ter 3 e 55 caracteres",
  audioNameLengthMessage: "O nome do áudio deve ter 3 e 55 caracteres",
  setCover: "Definir capa",
  setCoverCaps: "Definir capa",
  setCoverImage: "Definir imagem da Capa",
  changeCover: "Trocar capa",
  coverUpdatedSuccessMessage: "Capa atualizada com sucesso",
  coverUpdateSelectErrorMessage: "Por favor, selecione um item.",
  playMusic: "Tocar música",
  addItems: "Adicionar itens",
  manageCollaboration: "Gerenciar compartilhamento",
  selectItemsDot: "Selecionar itens...",
  set: "Pasta",
  items: "iten(s)",
  newAlbumCaps: "NOVO ÁLBUM",
  newSetCaps: "NOVA PASTA",
  newPlaylistCaps: "NOVA PLAYLIST",
  sources: "Fontes",
  duration: "Duração",
  album: "Álbum",
  itemsAddedSuccessfully: "Item(s) adicionado(s) com sucesso",
  itemsPostSuccessfully: "Item(s) postado(s) com sucesso",
  albumRenameSuccessMessage: "Álbum renomeado com sucesso",
  setRenameSuccessMessage: "Pasta renomeada com sucesso",
  playlistRenameSuccessMessage: "Playlist renomeada com sucesso",
  imageRenameSuccessMessage: "Imagem renomeada com sucesso",
  videoRenameSuccessMessage: "Vídeo renomeado com sucesso",
  docRenameSuccessMessage: "Arquivo renomeado com sucesso",
  audioRenameSuccessMessage: "Música renomeada com sucesso",
  deleteSuccessMessage: "Apagado com sucesso",
  deleteTrashMessage: "item(s) eliminado(s) permanentemente",
  albumDeleted: "Álbum excluído com sucesso",
  setDeleted: "Pasta excluída com sucesso",
  playlistDeleted: "Playlist excluída com sucesso",
  trashed_item_can_not_view: "Itens na lixeira não podem ser abertos",
  addCaps: "ADICIONAR",
  postCaps: "POSTAR",
  nameAlreadyExists: "nome já existe.",
  image: "Imagem",
  video: "Vídeo",
  doc: "Documento",
  playlist: "Playlist",
  okCaps: "OK",
  selectCover: "Selecionar capa",
  groupGalleryNoDataMessage: "Escolha fotos para postar aqui",
  groupDocsNoDataMessage: "Escolha arquivos para postar aqui.",
  groupNotifyMessage: "Seu grupo será notificado.",
  groupNoDataInfoMessage: "Todos podem aproveitar e compartilhar conteúdo",
  groupRemoveSuccessfully: "Grupo removido com sucesso",
  groupLeaveSuccessfully: "Você saiu do grupo com sucesso",
  memberInvited: "Membro convidado com sucesso",
  membersInvited: "membros convidados com sucesso",
  groupCreated: "Grupo criado com sucesso",
  emailMandatoryError: "Por favor, insira pelo menos um e-mail válido",
  emailValidationError: "Por favor insira e-mail válido",
  emailError: "Por favor insira um e-mail",
  invitationResendMessage: "Convite enviado com sucesso",
  invitationCancelMessage: "Convite cancelado com sucesso",
  removeMemberMessage: "Membro removido com sucesso",
  upload_user_cancelled: "Usuário cancelou o upload",
  upload_storage_unknown: "Ocorreu um erro desconhecido",
  markFavouriteMessage: "Favorito marcado com sucesso",
  unmarkFavouriteMessage: "Favorito desmarcado com sucesso",
  introductionMessage: "Apresente-se antes de prosseguir.",
  introduceHeader: "APRESENTE-SE",
  nameMandatoryError: "Por favor, insira o primeiro nome",
  introductionMandatoryError: "Por favor insira o primeiro nome e email",
  import: "Importar",
  importToGallery: "Importar para a galeria",
  downloading: "Baixando",
  downloadingText: "Baixando ...",
  downloadQueued: "Download em fila",
  yesterday: "Ontem",
  lastWeek: "Semana Passada",
  lastMonth: "Mês Passado",
  lastYear: "Ano Passado",
  weekAgo: "Semanas Atrás",
  January: "Janeiro",
  February: "Fevereiro",
  March: "Março",
  April: "Abril",
  May: "Maio",
  June: "Junho",
  July: "Julho",
  August: "Agosto",
  September: "Setembro",
  October: "Outubro",
  November: "Novembro",
  December: "Dezembro",
  Sunday: "Domingo",
  Monday: "Segunda-feira",
  Tuesday: "Terça",
  Wednesday: "Quarta-feira",
  Thursday: "Quinta-feira",
  Friday: "Sexta-feira",
  Saturday: "Sábado",
  galleryUploadFolderHeaderText: "Toque em uma pasta para escolher os itens a serem enviados ...",
  itemUploadHeaderText: "Selecione seus itens e toque em Enviar para fazer o backup deles na sua conta",
  permissionConfirmBoxMessage:
    "O aplicativo precisa de todas as permissões solicitadas para ser executado corretamente. Você pode gerenciar todas as suas permissões nas Configurações do dispositivo",
  noThanks: "Não, obrigado",
  takeMeToSettings: "Leve-me para as configurações",
  storagePermissionTitle: `${APP_TITLE} requer permissões de armazenamento`,
  contactPermissionTitle: `${APP_TITLE} requer permissões de contatos`,
  cameraPermissionTitle: `${APP_TITLE} requer permissões de câmera`,
  locationPermissionTitle: `${APP_TITLE} requer permissões de localização`,
  biometricPermissionTitle: `${APP_TITLE} usa permissões __biometricType__`,
  biometrics: "biometria",
  permissionWelcomeConfirmText: "Próximo",
  selectImageNoDataHeader: "Sem fotos ou vídeos!",
  selectImageNoDataText: "Nenhum item disponível. Adicione algum conteúdo à sua conta e tente novamente.",
  selectDocsNoDataHeader: "Sem arquivos!",
  selectDocsNoDataText: "Nenhum item disponível. Adicione algum conteúdo à sua conta e tente novamente.",
  selectPlaylistNoDataText: "Nenhum item disponível. Adicione algum conteúdo à sua conta e tente novamente.",
  collaborationHeaderMessage: "Permitir que os membros adicionem conteúdo",
  collaborationMessageSharePopup: "Permitir que os participantes adicionem conteúdo",
  "metadata/uploading-in-progess": "Os itens selecionados ainda estão sendo enviados. Tente novamente mais tarde.",
  markDeleteAlbum: "Excluir álbum",
  markDeleteSet: "Excluir pasta",
  markDeletePlaylist: "Excluir playlist",
  renameAlbum: "Renomear Álbum",
  renameSet: "Renomear pasta",
  renamePlaylist: "Renomear playlist",
  itemRemovedMessage: "Item(s) removido(s) com sucesso",
  itemImportedMessage: "Item(s) importado com sucesso",
  importItem: "Importado __itemcount__ item(s)",
  importItemExists: "__itemcount__ item(s) já importado",
  itemAlreadyImportedMessage: "Item já importado",
  photoImportedMessage: "Foto importada com sucesso",
  alreadyAddedIntoGallery: "Já adicionado à galeria",
  removeMember: "Remover Membro",
  removeMemberCaps: "REMOVER MEMBRO",
  removeMemberConfirmMessage: "Remover _Member_ do grupo",
  resendInvite: "Reenviar convite",
  cancelInvite: "Cancelar convite",
  linkExpiredCaps: "LINK EXPIRADO",
  linkExpiredMessage: "Infelizmente, o link expirou. Por favor, peça ao remetente para reenviar um novo convite.",
  join: "Junte-se",
  joinCaps: "JUNTE-SE",
  sharingPage: "Itens compartilhados",
  joinCollectionMessage: `Você quer juntar o compartilhado __type__ __name__?`,
  joinGroupCaps: "JUNTAR-SE AO GRUPO",
  joinGroupMessage: "Você quer se juntar ao grupo?",
  joinGroupAlreadyMemberMessage:
    "Você já é membro de outro grupo. Para participar desse grupo deixe seu grupo atual e aceite esse convite novamente.",
  searchDot: "Procurar...",
  searchGalleryDot: "Pesquisar Galeria ...",
  searchDocumentsDot: "Pesquisar arquivos ...",
  searchMusicDot: "Pesquisar música ...",
  searchGroupDot: "Procurar grupo...",
  searchByNamePublisher: "Pesquisar por nome, publicações",
  private: "Privado",
  sharedByName: "Compartilhado por __name__",
  collectionMakePrivate: "Os links de compartilhamento foram removidos",
  be_part_of_it: "Seja parte disso",
  confirm: "Confirmar",
  makePrivateMessage: "Compartilhamento removido com sucesso",
  share: "Compartilhar",
  musicTrack: "Faixa de musica",
  lowResolution: "Baixa resolução (sobre __size__)",
  originalItems: "Itens originais (sobre __size__)",
  linkToItems: "Link para os itens",
  preparingContent: "Preparando Conteúdo ...",
  preparingLink: "Preparando Link ...",
  linkCopiedMessage: "Link copiado para a área de transferência",
  getLinkErrorMessage: "Houve um problema ao gerar o link. Por favor, tente novamente mais tarde",
  shareMaxFileExceededMessage:
    "O limite máximo de compartilhamento de arquivos foi excedido. Compartilhe no link mais de __shareFileLimit__ arquivos",
  shareMaxSizeExceededMessage:
    "O limite máximo de tamanho de compartilhamento de arquivo foi excedido. Compartilhe o link por mais de __shareFileSizeLimit__ MB",

  shareErrorMessage: "Houve um problema no compartilhamento. Por favor, tente novamente mais tarde",
  shareNetworkErrorMessage:
    "O compartilhamento falhou devido à conectividade com a Internet. Por favor, tente novamente mais tarde",
  downloadNetworkErrorMessage:
    "O download falhou devido à conectividade com a Internet. Por favor, tente novamente mais tarde",
  turningOnCollaboration: "Habilitando permissão para membros adicionarem conteúdo",
  turningOffCollaboration: "Desativando a permissão para membros adicionarem conteúdo",
  settings: "Configurações",
  help: "Ajuda",
  about: "Sobre",
  uploadDescriptionOnSetting: "Selecione as pastas para fazer backup",
  network: "Uso de dados",
  networkdescription1: `O ${APP_TITLE} pode atualizar seus arquivos usando:`,
  networkdescriptionWifi: "Seus arquivos serão atualizados somente quando a conexão Wi-Fi estiver disponível",
  networkdescriptionMobile: "Seus arquivos poderão ser atualizados utilizando seu plano de dados",
  roaming: "Roaming",
  roamingDescription: "Ative para atualizar seus arquivos mesmo quando estiver em roaming",
  roamingDescriptionNote:
    "Nota: Utilizar dados em roaming poderá gerar custos adicionais. Verifique as tarifas do seu plano de dados.",
    deleteAccount: "Excluir conta",
    troubleshooting: "Solução de problemas",
  troubleshootingDescription: "Acione o log caso encontre qualquer problema",
  troubleshootingDescriptionNote:
    "Envie o registro no caso de você encontrar alguns problemas, para ajudar a equipe de suporte na solução de problemas",
  wifi: "Somente Wi-Fi",
  wifiMobile: "Wi-Fi e seu Plano de Dados",
  sendLog: "Enviar log",
  sendLogSuccessMessage: "Log enviado com sucesso",
  attachLog: "Anexar log",
  uploadDescription1:
    "Novas fotos e vídeos da galeria e das pastas selecionadas são automaticamente enviados para sua conta assim que as condições de rede adequadas forem atendidas",
  uploadDescription2: "Pressione + para carregar mais fotos, vídeos, faixas de música e arquivo para sua conta",
  uploadMoreFolder: "Carregar de mais pastas",
  freeSpaceModalMessage1: `Como o ${APP_TITLE} pode liberar espaço no meu celular?`,
  freeSpaceModalMessage2: `O ${APP_TITLE} remove com segurança cópias dos itens deste celular que já estão salvos na nuvem.`,
  freeUpSpaceMessage1: `Limpe com segurança itens deste celular que já estão salvos no ${APP_TITLE}`,
  calculatingFreeSpace: "Calculando quanto espaço você pode liberar ...",
  learnMore: "Saiba mais",
  freeSpaceConfirmMessage: `Estamos removendo __itemCount__ item(s) do seu dispositivo que já estão seguros no ${APP_TITLE}`,
  pictureSelected: "Imagem selecionada",
  selectProfilePicture: "Selecionar imagem do perfil",
  updateProfilePicMessage: "Foto do perfil atualizada com sucesso",
  notifySpaceMessage: "Me notifique quando houver espaço para liberar",
  logout: "Sair",
  FreeUpSpace: "Você pode liberar __size__ de espaço",
  unlimitedSpaceOnYourDevice: "Espaço ilimitado no seu celular",
  unlimitedSpaceOnYourDeviceDescription: "Libere espaço no celular com segurança e nunca mais fique sem espaço",
  yourMemoriesSafeSound: "Suas memórias estão seguras",
  yourMemoriesSafeSoundDescription: "Você tem muitas fotos e vídeos? Faça o backup deles e nunca perca nada",
  freeUp: "Liberar",
  freeUpCaps: "LIBERAR",
  JustForTheGroup: "Só para o grupo",
  JustForTheGroupDescription: "Compartilhamento privado com seus entes queridos em um toque",
  AllUnderOneRoof: "Tudo num único lugar",
  AllUnderOneRoofDescription: "Veja e organize facilmente o conteúdo dos seus serviços favoritos",
  view: "Ver",
  viewCaps: "VER",
  SafeFreeUpSpace: `É seguro! Estes __size__ estão seguros em seu ${APP_TITLE} e prontos para acessar a qualquer hora`,
  CongratesFreeUpmessage: "Parabéns! Você liberou __size__!",
  aboutTitle: `Sobre o ${APP_TITLE}`,
  shareEmailheader: "COMPARTILHAR ITEM(s) POR E-MAIL",
  uploadEmailHeader: "Enviar um link de item(s)",
  itemSharedMessage: "Item(s) compartilhado(s) com sucesso",
  emailEmptyError: "Por favor, insira pelo menos um destinatário",
  subjectEmptyError: "Assunto não pode estar vazio",
  contactSyncOffMessage: "A sincronização de contatos está desativada",
  contactSyncOnMessage: "A sincronização de contatos está ativada",
  importContact: "Importar contato",
  resetContacts: "Redefinir Contatos",
  viewOur: "Ver nosso",
  termsAndConditionsText: "Termos e Condições",
  and: "e",
  myConnections: "Minhas conexões",
  sharedNoDataHeader: "Nada compartilhado!",
  sharedNoDataMessage: "Álbuns, pastas ou coleções que você compartilhou serão exibidos aqui.",
  deleted: "Excluído",
  backupStatus: "Status do backup",
  completeTheBackup: "Complete o Backup",
  selectAllYourItems: "Selecione todos os seus itens",
  notifyUnselectedItemsMessage: "Notifique-me quando houver itens não selecionados",
  toSelect: "selecionar",
  backedUp: "backup",
  shareMailSubject: `Eu quero compartilhar algo com você via ${APP_TITLE}`,
  folks: "Pessoal",
  telResidential: "Tel Residencial",
  cellPhone: "Celular",
  fax: "Fax",
  emailMain: "E-mail",
  personalEmail: "E-mail pessoal",
  zipCode: "Código postal",
  state: "Estado",
  city: "Cidade",
  address: "Endereço",
  website: "Site",
  site: "site",
  job: "Trabalho",
  jobDetails: "Trabalho",
  tel1: "Tel 1",
  tel2: "Tel 2",
  tel3: "Tel 3",
  tel4: "Tel 4",
  otherTel1: "Outro Tel 1",
  otherTel2: "Outro Tel 2",
  otherFax: "Outro Fax",
  pager: "Pager",
  instantMessage: "IM",
  comments: "Comentários",
  middleNameLabel: "Nome do meio",
  telCommercial: "Tel Commercial",
  editProfile: "Editar Perfil",
  removePhoto: "Remover foto",
  contactInformation: "Informações de contato",
  firstNameLabel: "Primeiro nome",
  lastNameLabel: "Último nome",
  emailLabel: "Endereço de e-mail",
  mobileLabel: "Número de telefone",
  receiveEmailInAccountConfirmationMessage:
    "Gostaria de receber por e-mail informações sobre as atualizações da minha conta",
  trashUndoneMessage: "Essa ação não pode ser desfeita.",
  myProfile: "Meu perfil",
  myDevices: "Meus dispositivos",
  mySignature: "Minha assinatura",
  empty: "Excluir",
  totalStorage: "Armazenamento Total",
  inUse: "em uso",
  inTheBin: "na lixeira",
  inNonUploaded: "no não upload",
  contactNoDataHeader: "Não há contatos em sua conta",
  contactNoDataMobileMessage: "Toque no ícone para criar um novo contato",
  contactNoDataMessage: "Crie um contato clicando no botão abaixo",
  addContact: "Adicionar contato",
  deleteContactHeaderCaps: "APAGAR CONTATO",
  deleteContactMessage1: "Você tem certeza que deseja deletar o contato __contactName__ de todos os seus dispositivos?",
  deleteContactMessage2: `Itens excluídos do ${APP_TITLE} serão excluídos em dispositivos móveis e computadores na próxima sincronização`,
  mobile: "Móvel",
  notEnoughSpace: "Não há espaço livre suficiente.",
  noChangesFound: "Nenhuma alteração encontrada",
  middleName: "Nome do meio",
  company: "Companhia",
  office: "Escritório",
  department: "Departamento",
  profileUpdatedMessage: "Perfil atualizado com sucesso",
  playOverCellNetwork: "Reproduzir via rede celular",
  yesPlayAllTracks: "Sim, reproduzir todas as faixas",
  noStopPlaying: "Não, pare de tocar",
  noPlayLocal: "Não, tocar apenas as faixas no meu dispositivo",
  useInternetConfirmMessage: "Esta operação usará sua conexão e poderá consumir seu plano de dados. Deseja continuar?",
  myDeviceMessage: "Todos os telefones, tablets e computadores que acessam sua conta aparecem aqui",
  deviceName: "Nome do dispositivo",
  lastSeen: "Visto pela última vez",
  unlink: "Desvincular",
  unlinkDevice: "Desvincular dispositivo",
  unlinkMessage: "Desvinculado com sucesso",
  deviceMessage: "Android, iPhone, iPad",
  availableMessage: "Também disponível para",
  osDownloadMessage: "Mac OS",
  noItemsTrash: "Nenhum item na lixeira",
  deletedItemsPlaced: "Itens excluídos serão colocados aqui",
  unlinkPrimaryMessage: "Deseja desvincular o dispositivo selecionado da sua conta?",
  unlinkSecondaryMessage:
    "Desvincular o dispositivo desconectará o aplicativo e você não poderá acessar ou sincronizar seu conteúdo na nuvem a partir desse dispositivo até efetuar login novamente.",
  photoRemovedMessage: "Foto removida com sucesso",
  lowBatteryDownloadPrimaryMessage: "Bateria fraca para continuar o download",
  lowBatteryUploadPrimaryMessage: "Bateria fraca para retomar o backup",
  chargeDevice: "Carregue seu dispositivo",
  needWifiDownloadPrimaryMessage: "É necessário estar conectado ao Wi-Fi para retormar o download",
  needWifiUploadPrimaryMessage: "Necessário Wi-Fi para retomar o backup",
  needWifiUploadLargeFilePrimaryMessage: "Copiar arquivos com mais de 15 MB requer conexão Wi-Fi",
  tapToChangeSettings: "Toque para mudar as configurações",
  notConnectedDownloadPrimaryMessage: "Não conectado à internet",
  checkConnection: "Por favor, verifique sua conexão",
  dataConsumeMessage: "Essa ação consumirá seus dados móveis. Deseja continuar?",
  networkChangeSettingConfirm:
    "Você possui __pendingUploads__ itens na fila para fazer backup. Eles serão enviados usando a conexão do seu celular e isso pode consumir seu plano de dados. Deseja continuar?",
  fileAlreadyExistMessage: "Item(s) já existe neste dispositivo",
  downloadingItems: "Baixando __itemcount__ Itens",
  createdPublicLink: "Criar link público",
  copyLink: "Copiar link",
  createLink: "Criar Link",
  linkCopied: "Link copiado",
  toSeePhotos: "Para ver fotos",
  other: "De outros",
  otherInformation: "Outras informações",
  personalInformation: "Informações pessoais",
  editContact: "Editar Contato",
  restoreContact: "Restaurar contato",
  mergeDuplicateContact: "Mesclar contato duplicado",
  deleteContact: "Excluir contato",
  photoOfTheDay: "Foto do dia",
  BackToDay: "De volta ao dia",
  discard: "Descartar",
  recoverDelete: "RECUPERAR ARQUIVO APAGADO",
  fixDuplicates: "CORRIGIR ARQUIVO DUPLICADO",
  recoverDeleteSelectMessageSM: "Selecionar Contatos",
  recoverDeleteSelectMessageMD: "Selecionar os contatos excluídos que você deseja recuperar:",
  mergeDuplicateSelectMessage: "Encontramos _duplicateCount_ contato duplicado. Selecione o contato para unificar:",
  recover: "Recuperar",
  similarContacts: "Contatos semelhantes",
  merge: "Unificar",
  recoverContactMessage: "Nenhum contato excluído foi encontrado.",
  noDuplicatesFound: "Nenhum arquivo duplicado encontrado.",
  contactDeletedMessgae: "Contato excluído com sucesso",
  contactRestoredMessgae: "Contato restaurado com sucesso",
  contactsRestoredMessgae: "Contatos restaurados com sucesso",
  contactMergedMessgae: "Contato unificado com sucesso",
  contactsMergedMessgae: "contatos unificados com sucesso",
  contactAddedMessage: "Contato adicionado com sucesso",
  contactUpdatedMessage: "Contato atualizado com sucesso",
  photograph: "Foto",
  date: "Data",
  time: "Hora",
  freeUpNoSpaceMessage: "Você pode proteger mais itens e voltar mais tarde para liberar mais espaço",
  memoryOfTheDay: "Lembranças do Dia",
  accept: "Aceitar",
  serviceTermsTitleCaps: "TERMOS DE USO",
  serviceTermsTitle: "Termos de uso",
  termsAndConditions: "Termos e Condições",
  privacyPolicy: "Política de Privacidade",
  serviceTermsAcceptMessage:
    "Ao clicar em Aceitar você confirma que leu e concorda com os __termsAndConditions__ e __privacyPolicy__.",
  resetContact: "Redefinir contatos",
  startReset: "Iniciar redefinição",
  chooseDirection: "Escolha uma opção:",
  resetMyDevice: "Apagar os dados do meu celular e usar os dados da nuvem",
  resetMyAccount: "Apagar os dados da nuvem e usar os dados do meu celular",
  resetContactMessage:
    "A redefinição deve ser usada com cautela. Realizar uma redefinição substituirá os contatos do seu celular por contatos de sua conta on-line ou vice-versa.",
  contactSyncPermissionToast: "A permissão de contato é necessária para a sincronização",
  addToContact: "Adicionar aos contatos",
  noContactFound: "Nenhum contato encontrado!",
  downloadErrorMannualMessage: "Há algum problema no download do arquivo. Por favor tente novamente mais tarde",
  backupInProgress: "Backup em andamento...",
  backupPaused: "Backup pausado",
  backupPausedDueToRoaming: "Backup pausado devido a roaming",
  downloadingProgressMessage: "Download de item em andamento",
  insufficientSpace: "Precisa de armazenamento para retomar",
  insufficientSpaceSecondary: "Esvazie o lixo e tente novamente",
  tapToRetry: "Toque para tentar novamente",
  generatingThumbnailMessage: "Gerando Miniatura",
  extractingDetailMessage: "Extraindo Detalhes",
  no_internet_message: "Por favor verifique sua conexão com a internet e tente novamente ...",
  contactSyncSingleViewMessage:
    "__count__ contato estão sincronizados com sua conta on-line e seus outros dispositivos",
  contactSyncMultipleViewMessage:
    "Os __count__ contatos estão sincronizados com sua conta on-line e seus outros dispositivos",
  contactSyncToastMessage: "Contato(s) sincronizado com sucesso",
  SyncContactErrorMessage: "Há algum erro no contato sincronizado. Por favor tente novamente mais tarde.",
  ResetFromDeviceErrorMessage: "Há algum erro ao redefinir o dispositivo móvel. Por favor tente novamente mais tarde.",
  ResetFromServerErrorMessage: "Há algum erro ao redefinir a conta on-line. Por favor tente novamente mais tarde.",
  startServerSync: "Iniciar sincronização do servidor",
  addContactOnServer: "Adicionando _count_ contato(s) no servidor",
  deleteContactOnServer: "Excluindo _count_ contato(s) no servidor",
  updateContactOnServer: "Atualizando _count_ contato(s) no servidor",
  startDeviceSync: "Iniciar sincronização do dispositivo",
  contactSyncProgressMessage: "Sincronização de contatos em andamento ...",
  addContactOnDevice: "Adicionando _count_ novo contato(s) no dispositivo",
  deleteContactOnDevice: "Excluindo _count_ contato(s) no dispositivo",
  updateContactOnDevice: "Atualizando _count_ contato(s) no dispositivo",
  populateDeviceChanges: "Incluindo alterações de dispositivos",
  getContactFromServer: "Obtendo todos os contatos do servidor",
  populateServerChanges: "Incluindo mudanças no servidor",
  finalizing: "Finalizando",
  getAllContactFromDevice: "Obtendo todos os contatos do dispositivo",
  processAllContactFromDevice: "Processando todos os contatos no dispositivo",
  startSync: "Iniciar sincronização",
  startResetDevice: "Iniciando a redefinição do dispositivo",
  deleteAllContactFromDevice: "Apagando todos os contatos do dispositivo",
  startResetAccount: "Iniciando a redefinição da conta on-line",
  resettingContacts: "Iniciando a redefinição do celular",
  "share/not-found": "O __type__ que você está tentando fazer não está mais disponível",
  aboutVersion: `${APP_TITLE} Versão ${APP_BUILD_VERSION}`,
  viewContent: `Veja o conteúdo de outras nuvens em seu aplicativo do ${APP_TITLE}`,
  buildVersion: `Versão Build ${APP_BUILD_VERSION}`,
  resolution: "Resolução",
  dateTaken: "Data",
  fileName: "Nome do arquivo",
  lastChange: "Última mudança",
  details: "Detalhes",
  throughPublicLink: "Através do Link Público",
  requestInProgress: "Solicitação já em andamento",
  welcomeTransferMessageCaps: `BEM-VINDO AO ${APP_TITLE.toUpperCase()}!`,
  transferFileTitle: "CONFIGURANDO O APLICATIVO…",
  transferInfoMessage:
    "Pressione AVANÇAR para configurar seu novo aplicativo.Isso pode levar algum tempo. Enquanto isso, você pode usar seu celular normalmente!",
  transferInfoAcceptMessage: "Isso pode levar algum tempo. Enquanto isso, você pode usar seu celular normalmente.",
  transferingMessage: "Transferindo seus arquivos",
  underStood: "ENTENDI",
  update: "Atualizar",
  appUpdateMessageExpire: `Uma atualização do "${APP_TITLE}" está disponível para oferecer novidades e mais segurança. Por favor, atualize seu app agora antes de prosseguir.`,
  appUpdateMessage: `Uma atualização do "${APP_TITLE}" está disponível para oferecer novidades e mais segurança. Esta versão expirará em __date__. Por favor, atualize seu app agora antes de prosseguir.`,
  importantUpdate: "Atualização Importante",
  freeUpSpaceTitle: "Liberar espaço",
  no: "Não",
  allow: "Permitir",
  pressAgainToExitApp: "Pressione novamente para sair do App",
  loopVideo: "Loop Video",
  detail: "Detalhe",
  generalInfoCaps: "INFORMAÇÕES GERAIS",
  infoCaps: "INFO",
  duplicate: "Duplicado",
  clickHereToOpen: "Clique aqui para abrir",
  noFilePreviewAvailableText: "Nenhuma visualização disponível para este formato de arquivo.",
  albumDuplicateMessage: "O nome do álbum já existe.",
  setDuplicateMessage: "O nome da pasta já existe.",
  playlistDuplicateMessage: "O nome da lista de reprodução já existe.",
  confirmCaps: "CONFIRMAR",
  contactWaitingForNetwork: "esperando internet ...",
  Document: "Documento",
  Spreadsheet: "Planilha",
  Presentation: "Apresentação",
  "Text Document": "Documento de texto",
  PDF: "PDF",
  "Photoshop Document": "Documento do Photoshop",
  "Web Page": "Página da web",
  Archive: "Arquivo",
  Media: "meios de comunicação",
  Unknown: "Desconhecida",
  clearTrashMessage: "Lixeira esvaziada",
  clearCacheMessage:
    "Tem certeza de que deseja excluir todos os dados do cache? O aplicativo será reiniciado automaticamente.",
  clearCacheSuccessMessage: "Cache limpo com sucesso",
  loadImageErrorMessage: "Falha ao carregar a imagem",
  imageLoadNetworkErrorMessage: "Image Load failed due to internet connectivity",
  fileOpenFailedMessage: "Não há nenhum App disponível para abrir este arquivo",
  videoLoadError:
    "Carregando o vídeo. Pode demorar alguns minutos. Para visualizar, faça o download e tente novamente.",
  imagePreviewError: "Visualização de imagem não disponível",
  unable_to_load_data: "Não foi possível carregar os dados",
  sharedItemAlreadyExist: "O item já está protegido na sua conta online!",
  preparingYourFiles: "Preparando seus arquivos",
  issuesReported: "Problemas Relatados",
  mark_done: "Marcar Concluído",
  in_progress: "Atendido",
  resolved: "Resolvida",
  allComments: "Todos os Comentários",
  totalComment: "Comentários",
  replyFromSupport: "Resposta do Time de Suporte",
  addIssues: "Relatar Problema",
  issuesDescription: "Descrição do problema",
  issuesTitle: "Título do problema",
  issuesAddMessage: "Problema adicionado com sucesso",
  titleErrorMessage: "O título é obrigatório",
  descriptionErrorMessage: "A descrição é obrigatória",
  addComment: "Adicionar Comentário",
  commentError: "O comentário é obrigatório",
  commentAddMessage: "Comentário adicionado com sucesso",
  commentPlaceholder: "Seu comentário",
  insertAttachment: "Inserir anexo",
  issuesNoDataHeader: "Nenhum problema relatado!",
  issuesNoDataMobileMessage: "Toque em ADICIONAR para relatar um novo problema",
  commentNoDataHeader: "Nenhum comentário no momento!",
  commentNoDataMobileMessage: "Toque em ADICIONAR COMENTÁRIO para enviar sua mensagem.",
  report_an_issues: "Relatar um problema",
  reported_issues: "Problemas relatados",
  mark_donePopupHeaderCaps: "MARCA CONCLUÍDA",
  markDoneSuccessMessage: "Problema marcado com sucesso",
  markDonePopupMessage: "Tem certeza de que marcará este problema como resolvido",
  afterMigrationTitle: "Obrigado pela paciência!",
  afterMigrationMessage: `Seu ${APP_TITLE} está pronto para usar. __migrationStatusDetails__ Se você ainda estiver enfrentando algum problema, poderá escolher "Relatar problema" ou acessar o suporte ao cliente usando "Bate-papo".`,
  migrationStatusMessage:
    "Transferimos com êxito arquivos __transferredFiles__ do __totalFiles__.__migrationPendingDetails__",
  migrationPendingMessage:
    "Estamos processando arquivos __pendingCount__. Após a conclusão, em breve, você será atualizado.",
  chat: "Bate-papo",
  nextCaps: "AVANÇAR",
  byDate: "Por Data",
  thisWeek: "Esta Semana",
  thisMonth: "Este Mês",
  thisYear: "Este Ano",
  selectDate: "Selecione a data",
  custom: "Personalizado:",
  from: "Data Inicial",
  to: "Data Final",
  fromToValidation: "Data de início deve ser anterior à data final",
  syncProcessingMessage: "A sincronização está em processo. Por favor, tente depois de algum tempo.",
  videoPreviewMessage: "Visualização de vídeo não disponível",
  somethingWentWrong: "Ocorreu um erro. Tente novamente mais tarde",
  unexpectedErrorToast:
    "Ocorreu um erro inesperado. Envie o log para ajudar a equipe de suporte na solução de problemas.",
  syncProcessingCardMessage: "Sincronização em andamento. Isso pode levar alguns minutos. Por favor, aguarde",
  largeFileOnWifiOnly: "Para itens grandes (mais de 15 MB), use apenas Wi-Fi.",
  portWaitMessage: "Aguarde enquanto o aplicativo está carregando...",
  metadataPortError:
    "Há algum problema na configuração do seu aplicativo. Relate um problema do aplicativo ou reinstale-o.",
  initialAutoBackupStartMessage:
    "Poucos arquivos da sua galeria serão enviados para demonstração do recurso Backup automático.",
  autoBackupInfoMessage: "O Backup automático pode ser ativado em Configuração > Configurações> Backup automático",
  sendLogsConfirmMessage: "Deseja enviar logs?",
  moveToSafeArea: "Mover para Cofre",
  moveToSafeAreaCaps: "MOVER PARA COFRE",
  moveToSafeAreaSingleMessage: "Você quer mover o item selecionado para o Cofre?",
  moveToSafeAreaMultipleMessage: "Você quer mover os __itemCount__ itens selecionados para o Cofre?",
  item_moved_to_safe_area: "O item foi movido para o Cofre com sucesso",
  items_moved_to_safe_area: "Os itens foram movidos para o Cofre com sucesso",
  removeFromSafeArea: "Remover do Cofre",
  removeFromSafeAreaCaps: "REMOVER DO COFRE",
  removeFromSafeAreaSingleMessage: "Você deseja remover o item selecionado do Cofre?",
  removeFromSafeAreaMultipleMessage: "Você deseja remover os __itemCount__ itens selecionado do Cofre?",
  item_removed_from_safe_area: "O item foi removido do Cofre com sucesso",
  items_removed_from_safe_area: "Os itens foram removidos do Cofre com sucesso",
  safeArea: "Cofre",
  moveToArchive: "Arquivar",
  moveToArchiveCaps: "ARQUIVAR",
  moveToArchiveSingleMessage: "Você quer mover o item selecionado para o Arquivo?",
  moveToArchiveMultipleMessage: "Você quer mover os __itemCount__ itens selecionados para o Arquivo?",
  item_moved_to_archive: "O item foi movido para o Arquivo com sucesso",
  items_moved_to_archive: "__archived__ de __total__ itens foram movidos para o arquivo com sucesso",
  removeFromArchive: "Desarquivar",
  removeFromArchiveCaps: "DESARQUIVAR",
  removeFromArchiveSingleMessage: "Você deseja remover o item selecionado do Arquivo?",
  removeFromArchiveMultipleMessage: "Você deseja remover os __itemCount__ itens selecionado do Arquivo?",
  item_removed_from_archive: "O item foi removido do Arquivo com sucesso",
  items_removed_from_archive: "Os itens foram removidos do Arquivo com sucesso",
  pinCreatedMessage: "PIN criado com sucesso",
  pinUpdatedMessage: "PIN atualizado com sucesso",
  pinResetMessage: "Redefinição de PIN bem-sucedida",
  blockDeviceMessage: "Dispositivo bloqueado com sucesso",
  blockOtp: "Digite OTP para bloquear o dispositivo",
  loggedIn: "Atualmente logado!",
  createPin: "Criar Código",
  createPinCaps: "CRIAR CÓDIGO",
  confirmPin: "Confirmar Código",
  confirmPinCaps: "CONFIRMAR CÓDIGO",
  enterPin: "Digitar Código",
  enterPinCaps: "DIGITAR CÓDIGO",
  createPinMessage: "Crie um código para o seu Cofre",
  createPinPlaceholder: "Digite um código de 6 dígitos",
  confirmPinMessage: "Digite novamente o código",
  confirmPinPlaceholder: "Digite novamente o código de 6 dígitos",
  enterPinMessage: "Digitar Código",
  enterPinPlaceholder: "Digite um código de 6 dígitos",
  forgotPin: "Esqueci meu código",
  forgotPinCaps: "ESQUECI CÓDIGO",
  resetPin: "Redefinir código",
  resetPinCaps: "REDEFINIR CÓDIGO",
  resetPinMessage: "Redefinir código do seu Cofre",
  "user/pin-mismatch": "Códigos não são iguais",
  "user/invalid-vault-pin": "Código inválido. Tente novamente",
  "user/vault-pin-already-exists": "Código já existente. Tente redefinir o código para alterá-lo",
  "user/vault-pin-does-not-exists": "O código não existente. Crie um código e tente novamente",
  "user/invalid-user": "Usuário Inválido. Por favor, tente novamente",
  "user/same-pin-error": "O código antigo e o novo código devem ser diferentes",
  "user/link-expired": "Link expirado!",
  oldPinPlaceholder: "Digite o código antigo de 6 dígitos",
  changePin: "Alterar Código",
  changePinCaps: "ALTERAR CÓDIGO",
  changePinMessage: "Alterar código do seu Cofre",
  pinMandatoryMessage: "Por favor, digite o código",
  pinCountInvalidMessage: "Por favor, preencha com um código de 6 dígitos",
  oldPinLabel: "Digite o código antigo",
  newPinLabel: "Digite o novo código",
  confirmPinLabel: "Confirmar o novo código",
  otpFieldLabel: "Digitar PIN enviado por SMS",
  dataLoadingErrorMessage: "Os dados ainda estão carregando. Por favor, tente depois de algum tempo.",
  resetPinLinkExpiredHeader: "O link que você está tentando acessar não está mais disponível",
  resetPinLinkExpiredMessage:
    "Você já pode ter usado esse link antes ou o endereço pode estar incorreto. Por favor verifique e tente novamente.",
  fileTypeDetectError: "Não foi possível detectar o tipo do arquivo",
  formatNotResolvedError: "O formato desse arquivo não pôde ser aberto",
  imageLoadErrorMessage: "Não foi possível carregar a imagem",
  editImageNoChangeErrorMessage: "Nenhuma alteração detectada",
  editImageNotFoundErrorMessage: "Não foi possível detectar a imagem editada. Por favor, tente novamente mais tarde",
  editImageErrorMessage: "Há algum erro na edição de imagens. Por favor, tente novamente mais tarde",
  saveEditedImageErrorMessage: "Não foi possível salvar a imagem editada. Por favor, tente novamente mais tarde",
  uploadEditedImageErrorMessage:
    "Ocorreu um erro no upload da imagem editada. Você pode enviá-lo manualmente da sua galeria.",
  editImageSuccessMessage: "Imagem editada e enviada para sua galeria.",
  less: "Menos",
  more: "Mais",
  allStats: "Todas as estatísticas",
  commentDetail: "Detalhe do comentário",
  editDetail: "Editar detalhes",
  totalSpace: "Espaço total",
  usedSpace: "Espaço utilizado",
  freeSpace: "Espaço livre",
  trashSpace: "Espaço do Lixo",
  progress: "Progresso",
  status: "Status",
  devFeedback: "Comentários do desenvolvedor",
  total: "Total",
  uploaded: "Carregado",
  completed: "Concluído",
  pending: "Pendente",
  extracted: "Extraído",
  audio: "Áudio",
  refresh: "Atualizar",
  currentStats: "Estatísticas atuais",
  migrationStatus: "Status da migração",
  migrationProgress: "Progresso da migração",
  reprocess: "Reprocessar",
  totalFiles: "Total de arquivos",
  migrationNotificationAccepted: "Notificação de migração aceita",
  migrationStartedOn: "Migração iniciada em",
  migrated: "Migrado",
  migratedFiles: "Arquivos Migrados",
  migratedOn: "Migrado em",
  migrationStatusInfo: "Informações de status de migração",
  migrationProgressInfo: "Informações do processo de migração",
  server: "Servidor",
  support: "Suporte",
  issueNumber: "Numero da edição",
  title: "Título",
  musicUploadPromptMessage:
    "Se a opção para guardar música na nuvem não estiver disponível, isso pode ser uma restrição do sistema operacional ou conteúdo com proteção contra cópias.",
  docUploadPromptMessage:
    "Se a opção para guardar Arquivos na nuvem não estiver disponível, isso pode ser uma restrição do sistema operacional ou conteúdo com proteção contra cópias.",
  customAttributesNotFound: "Atributos personalizados não encontrados",
  shareProcessingMessage: "O compartilhamento já está em andamento",
  videoUnavailable: "Falha ao reproduzir o vídeo",
  "share/title-length": "O título deve ter 5 e 100 caracteres",
  "share/description-length": "A descrição deve ter 10 e 1000 caracteres",
  "share/issue-in-english-length": "O problema em inglês deve ter 10 e 1000 caracteres",
  "share/comment-length": "O comentário deve ter 10 e 1000 caracteres",
  "share/sharing-subject-length": "O assunto deve ter 3 e 150 caracteres",
  "share/email-ids-limit": "O número de e-mails deve ter 1 e 25 caracteres",
  "uploader/name-length": "O nome deve ter 3 e 55 caracteres",
  "user/first-name-length": "O primeiro nome deve ter 3 e 25 caracteres",
  "user/last-name-length": "O sobrenome deve ter 3 e 25 caracteres",
  "user/name-length": "O nome deve ter 3 e 50 caracteres",
  "user/email-length": "O email deve ter 5 e 55 caracteres",
  "user/mobile-length": "O celular deve ter 9 e 16 caracteres",
  "user/pin-length": "O pino deve ter 6 caracteres",
  "metadata/name-length": "O nome deve ter 3 e 55 caracteres",
  "metadata/access-not-authorised": "Você não tem autorização para usar este recurso",
  VIRUS_DETECTED: "Vírus encontrado",
  encryptionKeyError: "Chave de criptografia / descriptografia não encontrada",
  encryptFileError: "Ocorreu um problema no arquivo criptografado",
  userNotAuthorized: "Usuário não autorizado encontrado",
  clearCache: "Limpar Cache",
  cacheData: "Dados Cache",
  queuedItems: "Iten(s) na fila",
  uploadQueueNoDataHeader: "Nenhum arquivo na fila para upload",
  downloadQueueNoDataHeader: "Nenhum arquivo na fila para download",
  moveCaps: "MOVER",
  move: "Mover",
  itemsMovedSuccessfully: "Item(s) movido com sucesso",
  fingerprintScanMessage: "Digitalize sua impressão digital no scanner do dispositivo para continuar",
  enableBiometrics: "Ativar __biometricType__",
  useBiometrics: "Usar __biometricType__ para desbloquear",
  fingerprint: "Impressão digital",
  trashedSpace: "Espaço na lixeira",
  photos: "Fotos",
  videos: "Vídeos",
  audios: "Áudios",
  documents: "Documentos",
  errorInCaptcha: "Erro ao carregar captcha",
  captchaExpired: "Captcha expirou, verifique novamente",
  verifyCaptcha: "Verifique o captcha",
  bySize: "Por tamanho",
  byCount: "Por Contagem",
  memories: "Recordações",
  noMemories: "Sem Memórias",
  picOfDayUserDeleted: "Não é possível importar a foto do dia, o usuário está marcado como excluído.",
  block: "Bloquear",
  blockDevice: "Dispositivo de bloqueio",
  blockMessage: "O dispositivo foi bloqueado com sucesso",
  blockDevicePrimaryMessage: "Você quer bloquear este dispositivo?",
  blockDevicesSecondaryMessage:
    "O bloqueio do dispositivo desconectará o aplicativo e você não poderá acessar ou sincronizar o conteúdo da nuvem a partir desse dispositivo.",
  places: "Lugares",
  downloadAsZip: "Baixe como Zip",
  failedItems: "Itens com falha",
  infected: "Infectado",
  reason: "Razão",
  noItemsFailed: "Você não tem itens com falha.",
  noItemsInfected: "Você não tem nenhum item infectado.",
  failedItemsPlaced: "Os itens com falha serão colocados aqui",
  infectedItemsPlaced: "Os itens infectados serão colocados aqui",
  deleteAll: "Apagar tudo",
  emptyFailedMessage: "Tem certeza de que deseja excluir permanentemente todos os itens com falha de sua conta?",
  emptyInfectedMessage: "Tem certeza de que deseja excluir permanentemente todos os itens infectados de sua conta?",
  allItemsDeleted: "Todos os itens excluídos com sucesso",
  Virus: "Vírus",
  castConnectFailed: "Incapaz de conectar ao dispositivo",
  castFailed: "Não é possível transmitir este arquivo",
  saveAsPdf: "Salvar como PDF",
  saveAsGif: "Salvar como GIF",
  savePdfIgnoreMessage:
    "Seu PDF não incluirá vídeos e GIF. Se arquivos deste tipo estiverem selecionados, eles serão ignorados na criação do PDF.",
  saveGifIgnoreMessage:
    "Seu GIF não incluirá arquivos GIF. Se algum desses arquivos for selecionado, eles serão ignorados no momento da criação.",
  generatingPdf: "Gerando PDF...",
  pdfFailed: "Falha ao gerar PDF",
  PDFGenerated: "PDF gerado enviando para documentos",
  scanningMessage: "Procurando vírus",
  duplicateResources: "Arquivos Duplicados",
  documentScanner: "Gerador de PDF",
  pdfMaxFileExceededMessage:
    "O limite máximo de arquivos foi excedido. Não é possível selecionar mais de __pdfFileLimit__ itens.",
  pdfMaxSizeExceededMessage:
    "O limite máximo de tamanho de arquivo foi excedido. Não é possível selecionar itens com mais de __pdfFileSizeLimit__ MB.",
  // documentScanner: "Scanner de Documentos",
  docScannerPrimaryText: "Adicione imagens para criar PDF",
  docScannerSecondaryText: "Toque no ícone de mais para adicionar imagens",
  captureImage: "Capturar Imagem",
  captureVideo: "Capturar vídeo",
  selectFromDeviceGallery: "Selecione na galeria do dispositivo",
  selectFromCloudGallery: "Selecione na Cloud Gallery",
  editPhoto: "Editar foto",
  uploadFromCamera: "Carregar da câmera",
  pdfMessage: "O PDF será gerado em breve e carregado automaticamente para o docs.",
  gifMessage: "O GIF será gerado em breve e carregado automaticamente para o galeria.",
  "uploader/resouce-exceeds": "O limite máximo de arquivos foi excedido. Não é possível selecionar mais de 100 itens.",
  someErrorOccurred: "Ocorreu algum erro, tente novamente.",
  savePDFValidationMessage: "Por favor, insira um nome",
  saveGIFValidationMessage: "Por favor, insira um nome",
  pdfNameLengthMessage: "O nome do pdf deve ter 3 e 55 caracteres",
  gifNameLengthMessage: "O nome do gif deve ter 3 e 55 caracteres",
  failedToUpdateProfilePicture: "Falha ao atualizar a foto do perfil",
  failedToUploadIssueFile: "Falha ao carregar o arquivo de problema",
  downloadAsPdf: "Baixar como PDF",
  PDFDownloaded: "PDF baixado com sucesso",
  "share/attachments-length": "Você só pode adicionar até __attachmentLimit__ itens.",
  attachmentLimitExceedMessage:
    "Você só pode adicionar até __attachmentLimit__ itens. Remova os itens se já foram adicionados.",
  attachmentUploading: "Por favor, aguarde um instante. Adicionando anexos.",
  logoutConfirmMessage: "Tem certeza que quer sair do aplicativo?",
  cameraPermissionDenied:
    "Certifique-se de que o aplicativo tenha acesso às permissões da câmera para que esta funcionalidade funcione corretamente.",
  grantLocationPermissionsMessage: "",
  location: "Localização",
  acceptTerms: "Eu li e aceitei o ",
  acceptTermsErrorMsg: "Aceite os Termos de Uso / Política de Privacidade acima!",
  next: "Próximo",
  profileCameraPermissionMessage:
    "Ao conceder essa permissão, o aplicativo tira fotos da câmera do dispositivo para atualizar a imagem do perfil nos servidores. Quando você nega essa permissão, a funcionalidade para de funcionar.",
  profileStoragePermissionMessage:
    "Ao conceder essa permissão, o aplicativo tira fotos da galeria do dispositivo para atualizar a imagem do perfil nos servidores. Quando você nega essa permissão, a funcionalidade para de funcionar.",
  docScannerCameraPermissionMessage:
    "Ao conceder essa permissão, o aplicativo tira fotos da câmera do dispositivo para criar documentos usando o Scanner de Documentos. Quando você nega essa permissão, a funcionalidade para de funcionar.",
  docScannerStoragePermissionMessage:
    "Ao conceder essa permissão, o aplicativo tira fotos da galeria do dispositivo para criar documentos usando o Scanner de Documentos. Quando você nega essa permissão, a funcionalidade para de funcionar.",
  cameraPermissionMessage:
    "Ao dar essa permissão, o aplicativo copia e protege fotos tiradas com a câmera do dispositivo em seus servidores. Ao negar essa permissão, a funcionalidade Carregar da Câmera deixa de funcionar.",
  appStoragePermissionMessage:
    "Ao dar essa permissão, o aplicativo copia e protege os arquivos do dispositivo em seus servidores. Ao negar essa permissão, o aplicativo pode deixar de funcionar conforme esperado.",
  locationPermissionMessage:
    "Ao dar essa permissão, o aplicativo permite salvar a localidade ao tirar uma foto com a câmera do dispositivo em seus servidores. Ao negar essa permissão, a funcionalidade Carregar da Câmera pode deixar de funcionar como esperado.",
  contactPermissionMessage:
    "Ao dar essa permissão, o aplicativo copia e protege dados de contatos do dispositivo em seus servidores. Ao negar essa permissão, a funcionalidade Contatos deixa de funcionar.",
  biometricPermissionMessage:
    "O aplicativo usa __biometricType__ para proteger e desbloquear mais rapidamente os arquivos salvos em seus servidores.",
  recentHighlights: "Destaques recentes",
  OneMonthAgo: "Há 1 mês ",
  OneYearAgo: "Há 1 ano",
  MoreThanAnYearAgo: "Há mais de 1 ano",
  desktopLinks: "Baixar para PC",
  localStats: "Não sincronizado",
  statsTitle: "Dados não sincronizados",
  everyTime: "Toda vez",
  everyDay: "Todo dia",
  everyWeek: "Toda semana",
  above: "acima de",
  battery: "Bateria",
  fromText: "a partir de",
  library: "Biblioteca",
  appVersion: "Versão do aplicativo",
  chooseAnotherFile: "Não foi possível fazer upload. Escolha outro arquivo.",
  removeItems: "Remover iten(s)",
  removeNonUploaded: "Remover itens não carregados",
  emptyNonUploadMessage:
    "Tem certeza de que deseja excluir permanentemente todos os iten(s) não carregados de sua conta?",
  clearNonUploadMessage: "Iten(s) não carregados removidos",
  nonUploadUndoneMessage:
    "Seu upload recentemente iniciado também será removido por arquivos não carregados limpos. Cancele se quiser ignorar esta remoção.",
  noNonUploadedItems: "Você não tem nenhum item não carregado.",
  NonUploadedItemsPlaced: "Os itens não carregados serão colocados aqui",
  enc_processing: "em processament",
  encryption_status: "Status de criptografia",
  encryption_count: "Arquivos antigos que foram criptografados",
  buttonContinue: "Continuar",
  buttonKeepAccount: "Manter conta",
  buttonGoBack: "Ir para o início",
  titleStepOne: "O que você deve saber antes de excluir sua conta",
  title1StepTwo: "Por que você está excluindo esta conta?",
  title2StepTwo: "Como podemos melhorar?",
  titleStep1Three: "Ao excluir sua conta removeremos todas as suas informações da nossa base de dados.",
  titleStep2Three: "Essa ação é irreversível.",
  titleStep3Three: "Para confirmar, digite ”EXCLUIR” no campo abaixo:",
  titleStepSuccess: "Sua conta foi apagada",
  titleStepError: "Ops, algo deu errado!",
  description1StepOne: "Você pode excluir a sua conta, mas primeiro você precisa cancelar a assinatura, quando essa for paga.",
  description2StepOne: "Se a sua assinatura for cobrada diretamente pelo Me Encontra, após a solicitação de exclusão de conta, você continuará com acesso ao Me Encontra e conta até o término do ciclo de cobrança da assinatura.",
  description3StepOne: "Se a sua assinatura for cobrada por uma empresa parceira, após a solicitação de exclusão da conta, você perderá imediatamente acesso ao Me Encontra e conta. Você deverá entrar em contato diretamente com a empresa parceira para realizar o cancelamento da sua assinatura e evitar futuras cobranças.",
  description4StepOne: "Ao excluir sua conta, você declara estar ciente de que a exclusão da conta implicará o fim do seu acesso ao Me Encontra e que, mesmo nesse caso, deverá ser respeitado o prazo de armazenamento mínimo de informações determinado pela legislação brasileira.",
  description1Success: "Obrigado por usar nosso produto.",
  description2Success: "Esperamos que possa retornar em breve!",
  description1Error: "Não foi possível concluir seu pedido neste momento.",
  description2Error: "Estamos trabalhando para resolver este problema! Por favor, tente novamente mais tarde.",
  question1StepTwo: "Não preciso mais",
  question2StepTwo: "Valor da assinatura",
  question3StepTwo: "Outro",
  incorrectWord: "Palavra incorreta.",
  incorrectAction: "Digite EXCLUIR para completar a sua ação."
};

export default {
  en: {
    ...en_registration,
    ...en,
    ...translations.en
  },
  pt: {
    ...pt_registration,
    ...pt,
    ...translations.pt
  }
};
