import React from "react";
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import { getImage } from "../../../FsCloudComponent";
import Popup from "./Popup";

class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: { lat: props.places[0].location.latitude, lng: props.places[0].location.longitude },
      imageId: props.places[0]._id,
      user: props.places[0]._createdBy
    };
    this.openPopup = this.openPopup.bind(this);
  }

  openPopup = place => e => {
    this.setState({ position: e.latLng, imageId: place._id, user: place._createdBy });
  };

  render() {
    let { places, center, zoom, defaultOptions, smView } = this.props;
    let { imageId, position, user } = this.state;
    return (
      <GoogleMap defaultZoom={zoom} defaultCenter={center} defaultOptions={defaultOptions}>
        {places.map(place => {
          return (
            <Marker
              key={place._id}
              zIndex={imageId === place._id ? -1 : 1}
              position={{ lat: Number(place.location.latitude), lng: Number(place.location.longitude) }}
              onClick={this.openPopup(place)}
              onMouseOver={this.openPopup(place)}
              icon={
                imageId === place._id
                  ? smView
                    ? getImage("markerSMIcon")
                    : getImage("markerIcon")
                  : getImage("dotIcon")
              }
            />
          );
        })}
        <Popup
          anchorPosition={position}
          markerPixelOffset={{ x: 0, y: smView ? -33 : -67 }}
          imageId={imageId}
          user={user}
        />
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(MapComponent));
