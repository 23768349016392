import React from "react";

export default ({ Box, getImage }) => {
  class SortableHeaderColumn extends React.Component {
    sortComponent = () => {
      let { setParams, dataParams, columnDef } = this.props;
      let { sortable, field, addOnSort } = columnDef;
      let sortField =
        sortable && typeof sortable === "boolean" ? field : typeof sortable === "string" ? sortable : void 0;
      if (sortField) {
        let existingSort = dataParams && dataParams["sort"];
        let sortDirection;
        let firstSortField = existingSort && Object.keys(existingSort).length && Object.keys(existingSort)[0];
        if (existingSort && existingSort[sortField] !== undefined) {
          //toggle it
          sortDirection = -1 * existingSort[sortField];
        } else if (firstSortField) {
          // check first field sort direction
          sortDirection = existingSort[firstSortField];
        } else {
          sortDirection = 1;
        }

        let sortValue = { [sortField]: sortDirection };
        if (addOnSort) {
          sortValue[addOnSort] = columnDef.addOnSortDirection || -1;
        }

        setParams && setParams({ type: "sort", value: sortValue });
      }
    };
    render() {
      let { columnDef, textStyle: style, dataParams } = this.props;
      let { header, field, sortable } = columnDef;
      let asc = void 0;
      if (sortable && dataParams) {
        let { sort } = dataParams;
        let key = sort && Object.keys(sort)[0];
        if (key !== undefined && (key === field || key === sortable)) {
          asc = sort[key] === 1 ? false : true;
        }
      }

      return (
        <Box
          direction="row"
          viewStyle={{ flex: 1, alignItems: "center", cursor: "pointer" }}
          onClick={this.sortComponent}
          render={[
            header && { width: "1fr", textStyle: style, text: header },
            asc !== undefined
              ? {
                  viewStyle: { alignItems: "center", justifyContent: "center", cursor: "pointer" },
                  image: asc ? getImage("accendingSortIcon") : getImage("descendingSortIcon")
                }
              : null
          ]}
        />
      );
    }
  }
  return SortableHeaderColumn;
};
