import { I18N, Platform } from "../../../FsCloudComponent";
const ACCOUNTS_TABS = {};
ACCOUNTS_TABS["profile"] = {
  tab: {
    text: () => I18N.t("personalInfo")
  },
  screen: "ProfileSM"
};
if (Platform.OS !== "web") {
  ACCOUNTS_TABS["MyDevices"] = {
    tab: {
      text: () => I18N.t("myDevices")
    },
    screen: "MyDevices"
  };
}
ACCOUNTS_TABS["storage"] = {
  tab: {
    text: () => I18N.t("storage")
  },
  screen: "Storage"
};

export default {
  ProfileTab: {
    component: "tab",
    componentType: "profileTab",
    navTitle: ({ user = {}, editMode }) => (editMode ? I18N.t("editProfile") : user.name),
    tabs: {
      myProfile: {
        tab: {
          text: () => I18N.t("myProfile")
        },
        screen: "ProfileMD"
      },
      myDevices: {
        tab: {
          text: () => I18N.t("myDevices")
        },
        screen: "MyDevicesWeb"
      },
      mySignature: {
        tab: {
          text: () => I18N.t("mySignature")
        },
        screen: "MySignature"
      }
    }
  },
  AccountTab: {
    component: "tab",
    title: () => I18N.t("account"),
    showTitleInTab: true,
    swipeableSM: true,
    swipeableMD: false,
    isNestedTab: true,
    tabs: ACCOUNTS_TABS
  }
};
