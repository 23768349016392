import React from "react";
import { theme, Box, getAction, I18N } from "../../../FsCloudComponent";
import CollectionActions from "../collection/CollectionActions";
const { fonts, colors } = theme;
const { themeColor, brownGrey, greyishBrownTwo } = colors;
const { h9_24, h1 } = fonts;

class AllowAddContentForm extends React.Component {
  render() {
    const { onClose, data } = this.props;
    return (
      <Box
        render={[
          {
            viewStyle: {
              paddingTop: 24,
              marginLeft: 16,
              marginRight: 18
            },
            textStyle: { ...h9_24, color: greyishBrownTwo },
            text: () => `${I18N.t("collaborationMessageSharePopup")}?`
          },
          {
            viewStyle: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 20,
              paddingBottom: 10,
              justifyContent: "flex-end"
            },
            direction: "row",
            render: [
              {
                viewStyle: {
                  paddingLeft: 18,
                  paddingRight: 18,
                  paddingTop: 10,
                  paddingBottom: 10
                },
                textStyle: {
                  ...h1,
                  color: brownGrey
                },
                text: () => I18N.t("no"),
                Container: getAction("shareMedia"),
                containerProps: {
                  ...this.props,
                  autoClick: false,
                  action: { ...CollectionActions.shareNative, visible: true },
                  data,
                  onClick: () => onClose && onClose(),
                  collaborator_mode: "read"
                }
              },
              {
                viewStyle: {
                  paddingLeft: 18,
                  paddingRight: 18,
                  paddingTop: 10,
                  paddingBottom: 10
                },
                textStyle: {
                  ...h1,
                  color: themeColor
                },
                text: I18N.t("allow"),
                Container: getAction("shareMedia"),
                containerProps: {
                  ...this.props,
                  autoClick: false,
                  action: { ...CollectionActions.shareNative, visible: true },
                  data,
                  onClick: () => onClose && onClose(),
                  collaborator_mode: "write"
                }
              }
            ]
          }
        ]}
      />
    );
  }
}

export default AllowAddContentForm;
