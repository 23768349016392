import React from "react";

export default ({ Box, resolveMQ, theme: themeHoc = {} }) => {
  class DocumentDetail extends React.Component {
    render() {
      const { onClose, activeMQ, theme = themeHoc } = this.props;
      const { generalInfoStyle, generalInfoItemStyle } = resolveMQ(
        theme,
        ["generalInfoStyle", "generalInfoItemStyle"],
        activeMQ
      );

      const getGeneralInfoItem = (label, value = "N/A") => {
        return {
          ...generalInfoItemStyle,
          render: [
            {
              ...theme.generalInfoItemLabelStyle,
              text: label
            },
            {
              ...theme.generalInfoItemValueStyle,
              text: value
            }
          ]
        };
      };

      return (
        <Box
          {...theme.boxStyle}
          render={[
            {
              ...theme.headerStyle.boxStyle,
              render: [{ ...theme.headerStyle.titleStyle }, { ...theme.headerStyle.crossStyle, onClick: onClose }]
            },
            {
              ...theme.descriptionStyle.boxStyle,
              render: [
                {
                  ...theme.descriptionStyle.labelStyle,
                  text: "Description"
                },
                {
                  ...theme.descriptionStyle.valueStyle,
                  text: "N/A"
                }
              ]
            },
            {
              ...generalInfoStyle,
              render: [
                { ...theme.generalInfoHeaderStyle },
                {
                  render: [
                    getGeneralInfoItem("TYPE"),
                    getGeneralInfoItem("DIMENSIONS"),
                    getGeneralInfoItem("SIZE"),
                    getGeneralInfoItem("MODIFY"),
                    getGeneralInfoItem("CREATED"),
                    getGeneralInfoItem("CREATED BY")
                  ]
                }
              ]
            }
          ]}
        />
      );
    }
  }
  return DocumentDetail;
};
