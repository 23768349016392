import React from "react";
import {
  Box,
  View,
  ScrollView,
  Dimensions,
  getImage,
  theme,
  WebMasonaryList,
  StatusBar,
  resolveMQ,
  RecyclerList,
  I18N,
  renderChildren,
  dataFormat,
  Link,
  getModeText,
  DecryptedImage,
  StatusBar as LoadingIndicator
} from "../../../FsCloudComponent";
import MapComponent from "./Map";

import { getAlbumDateLabel } from "./dateUtility";
import { googleMapUrl } from "../../../FsCloudAppServices";
const { numberFormatter } = dataFormat;
const { fonts, colors, shadows } = theme;
const { h32, h20_l, h9, h3, h20_22, h16_l } = fonts;
const { highlightColor, whiteTwo } = colors;

const MAX_HEIGHTMD = 277;
const MAX_HEIGHTSM = 150;

const headerStyle = {
  containerStyleSM: showHeaderBackground => {
    return {
      direction: "row",
      viewStyle: {
        height: 60,
        paddingLeft: 4,
        paddingRight: 12,
        alignItems: "center",
        ...(showHeaderBackground
          ? {
              backgroundColor: highlightColor,
              overflow: void 0,
              marginBottom: 2,
              ...shadows.navHeaderShadowSM
            }
          : {
              backgroundColor: "transparent"
            })
      }
    };
  },
  containerStyleMD: (showHeaderBackground, origin) => {
    return {
      direction: "row",
      viewStyle: {
        zIndex: 1,
        position: "fixed",
        top: 0,
        left: 0,
        right: 8,
        height: 72,
        alignItems: "center",
        paddingLeft: 18,
        paddingRight: 18,
        ...(showHeaderBackground
          ? {
              backgroundColor: "#00000080",
              overflow: void 0,
              marginBottom: 2,
              ...shadows.navHeaderShadowMD
            }
          : {
              backgroundColor: origin === "places" ? "#00000080" : "transparent"
            })
      }
    };
  },
  titleStyleMD: {
    width: "1fr",
    viewStyle: {
      height: 44,
      paddingLeft: 18,
      paddingRight: 18,
      justifyContent: "center"
    },
    textStyle: {
      ...h16_l,
      color: highlightColor
    }
  }
};

const getCoverImageDecryptionProps = (data = {}, decryptionSourceProps, origin) => {
  let coverData = { ...data.coverImage, collection: origin === "album" && { _id: data._id } };
  return {
    data: coverData,
    source: { uri: coverData.converted_jpeg_url },
    decryptionSourceProps
  };
};

class CoverCardMD extends React.Component {
  render() {
    let { rowData: data, data: images = [], sharedBy, fromShare, decryptionSourceProps, origin } = this.props;
    let { link: { props: { sharedToken } = {} } = {} } = this.props;
    let { name, itemCount, coverImage } = data;
    if (origin !== "album") {
      itemCount = images.length || 0;
    }
    const defaultMapOptions = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    };
    return (
      <View style={{ height: MAX_HEIGHTMD, width: Dimensions.get("window").width }}>
        {origin === "places"
          ? images.length && (
              <MapComponent
                googleMapURL={googleMapUrl()}
                loadingElement={<LoadingIndicator />}
                containerElement={<div style={{ height: `350px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                center={{ lat: Number(images[0].location.latitude), lng: Number(images[0].location.longitude) }}
                zoom={12}
                places={images}
                defaultOptions={defaultMapOptions}
              />
            )
          : coverImage && (
              <DecryptedImage
                {...getCoverImageDecryptionProps(data, decryptionSourceProps, origin)}
                style={{
                  height: MAX_HEIGHTMD,
                  width: Dimensions.get("window").width,
                  objectFit: "cover",
                  borderRadius: 4,
                  backgroundColor: whiteTwo
                }}
              />
            )}
        {origin !== "places" && (
          <Box
            style={{
              flex: 1,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.2)"
            }}
            render={[
              {
                viewStyle: { flex: 1, alignItems: "stretch" },
                render: [
                  {
                    viewStyle: { paddingTop: 82, paddingLeft: 30, paddingRight: 30 },
                    textStyle: {
                      color: highlightColor,
                      ...h32,
                      numberOfLines: 1,
                      textAlign: "center",
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      minWidth: 0
                    },
                    text: (data.system && fromShare && sharedBy) || name
                  },
                  origin === "album" && {
                    direction: "row",
                    viewStyle: { paddingTop: 5, paddingBottom: 15, alignSelf: "center" },
                    textStyle: {
                      color: highlightColor,
                      ...h20_l
                    },
                    text: getAlbumDateLabel(data)
                  },
                  {
                    viewStyle: {
                      width: 48,
                      height: 48,
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      alignSelf: "center"
                    },
                    Container: Link,
                    containerProps: {
                      action: {
                        link: {
                          uri:
                            origin === "album"
                              ? "/montage"
                              : origin === "vaultAlbum"
                              ? "/montage-vault"
                              : "/montage-place",
                          props: {
                            params: { data, sharedBy, fromShare, sharedToken },
                            parentInfo: { _connectProps: { data: images } }
                          }
                        }
                      }
                    },
                    title: () => I18N.t("slideshowTitle"),
                    image: getImage("albumSlideshowTransparentIcon")
                  }
                ]
              },
              {
                viewStyle: {
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingBottom: 15,
                  paddingTop: 15,
                  alignItems: "center"
                },
                textStyle: { color: highlightColor, ...h9, numberOfLines: 1 },
                direction: "row",
                render: [
                  itemCount && { text: `${numberFormatter(itemCount, "0.0i")} ${I18N.t("items")}` },
                  origin === "album" &&
                    !data.system && { viewStyle: { paddingLeft: 5.5, paddingRight: 5.5 }, text: "|" },
                  origin === "album" &&
                    !data.system && { width: "1fr", text: (fromShare && sharedBy) || getModeText(data) }
                ]
              }
            ]}
          />
        )}
      </View>
    );
  }
}

class AlbumListSM extends React.Component {
  renderHeader = () => {
    let { rowData: data, data: images = {}, sharedBy, fromShare, decryptionSourceProps, origin } = this.props;
    let { link: { props: { sharedToken } = {} } = {} } = this.props;
    let { name, itemCount, coverImage } = data;
    if (origin !== "album") {
      itemCount = images.length || 0;
    }
    const defaultMapOptions = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    };
    return (
      <View style={{ height: MAX_HEIGHTSM, width: Dimensions.get("window").width }}>
        {origin === "places"
          ? images.length && (
              <MapComponent
                googleMapURL={googleMapUrl}
                loadingElement={<LoadingIndicator />}
                containerElement={<div style={{ height: `150px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                center={{ lat: Number(images[0].location.latitude), lng: Number(images[0].location.longitude) }}
                zoom={12}
                places={images}
                defaultOptions={defaultMapOptions}
                smView={true}
              />
            )
          : coverImage && (
              <DecryptedImage
                {...getCoverImageDecryptionProps(data, decryptionSourceProps, origin)}
                style={{
                  height: MAX_HEIGHTSM,
                  width: Dimensions.get("window").width,
                  objectFit: "cover",
                  borderRadius: 4,
                  backgroundColor: whiteTwo
                }}
              />
            )}
        {origin !== "places" && (
          <Box
            style={{
              flex: 1,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.2)"
            }}
            render={[
              {
                viewStyle: { flex: 1, alignSelf: "center", justifyContent: "center" },
                render: [
                  {
                    viewStyle: { alignSelf: "center", paddingBottom: 4, paddingLeft: 12, paddingRight: 12 },
                    textStyle: {
                      color: highlightColor,
                      ...h20_22,
                      numberOfLines: 2,
                      textAlign: "center",
                      whiteSpace: "preWrap"
                    },
                    text: (data.system && fromShare && sharedBy) || name
                  },
                  origin === "album" && {
                    direction: "row",
                    viewStyle: { paddingTop: 4, alignSelf: "center" },
                    textStyle: {
                      color: highlightColor,
                      ...h3
                    },
                    text: getAlbumDateLabel(data)
                  }
                ]
              },
              {
                viewStyle: { paddingLeft: 8, paddingRight: 8, alignItems: "center" },
                textStyle: { color: highlightColor, ...h9 },
                direction: "row",
                render: [
                  itemCount && { text: `${numberFormatter(itemCount, "0.0i")} ${I18N.t("items")}` },
                  origin === "album" &&
                    !data.system && { viewStyle: { paddingLeft: 5.5, paddingRight: 5.5 }, text: "|" },
                  origin === "album" && !data.system && { text: (fromShare && sharedBy) || getModeText(data) },
                  { width: "1fr" },
                  {
                    viewStyle: {
                      width: 40,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer"
                    },
                    Container: Link,
                    containerProps: {
                      action: {
                        link: {
                          uri: origin === "album" ? "/montage" : "/montage-place",
                          props: {
                            params: { data, sharedBy, fromShare, sharedToken },
                            parentInfo: { _connectProps: { data: images } }
                          }
                        }
                      }
                    },
                    title: () => I18N.t("slideshowTitle"),
                    image: getImage("slideShowIcon")
                  }
                ]
              }
            ]}
          />
        )}
      </View>
    );
  };

  render() {
    let { data, listProps, state, setState, decryptionSourceProps, rowData = {} } = this.props;
    return (
      <View>
        <RecyclerList
          state={state}
          setState={setState}
          data={data}
          columns={2}
          screenWidth={Dimensions.width}
          renderHeader={this.renderHeader}
          {...listProps}
          rowProps={{
            ...listProps.rowProps,
            decryptionSourceProps,
            isShowVideoIcon: true,
            collaboratorData: rowData.collaboratorData || {}
          }}
        />
      </View>
    );
  }
}

class AlbumListMD extends React.Component {
  render() {
    let { data, listProps, decryptionSourceProps, rowData } = this.props;
    return (
      <WebMasonaryList
        hideDateHeader
        hideScroll={true}
        data={data}
        {...listProps}
        rowData={rowData}
        decryptionSourceProps={decryptionSourceProps}
      />
    );
  }
}

class AlbumDetailView extends React.Component {
  state = {};

  onScroll = e => {
    let { showHeaderBackground } = this.state;
    if (e.scrollTop > this.MAX_HEIGHT - 60) {
      if (!showHeaderBackground) {
        this.setState({ showHeaderBackground: true });
      }
    } else if (showHeaderBackground) {
      this.setState({ showHeaderBackground: false });
    }
  };

  render() {
    let { pending, activeMQ, renderHeader, origin } = this.props;
    let { link: { props: { sharedToken } = {} } = {} } = this.props;
    const { MAX_HEIGHT, CoverCard, AlbumList } = resolveMQ(
      {
        MAX_HEIGHTSM: this.props.MAX_HEIGHTSM || MAX_HEIGHTSM,
        MAX_HEIGHTMD: this.props.MAX_HEIGHTMD || MAX_HEIGHTMD,
        CoverCardMD: this.props.CoverCardMD || CoverCardMD,
        AlbumListSM,
        AlbumListMD
      },
      ["AlbumHeader", "MAX_HEIGHT", "CoverCard", "AlbumList"],
      activeMQ
    );
    let { containerStyle, titleStyle } = resolveMQ(headerStyle, ["containerStyle", "titleStyle"], activeMQ);
    this.MAX_HEIGHT = MAX_HEIGHT;
    let decryptionSourceProps = {
      sharedToken,
      decryptionSource: (origin === "album" || origin === "vaultAlbum") && "collection"
    };
    return (
      <ScrollView style={{ flex: 1 }} onScroll={this.onScroll}>
        {pending && <StatusBar />}
        {CoverCard && <CoverCard {...this.props} decryptionSourceProps={decryptionSourceProps} />}
        {renderHeader &&
          renderChildren(renderHeader, {
            ...this.props,
            skipTitle: !this.state.showHeaderBackground,
            containerStyle: containerStyle(this.state.showHeaderBackground, origin),
            titleStyle
          })}
        <AlbumList {...this.props} decryptionSourceProps={decryptionSourceProps} />
      </ScrollView>
    );
  }
}

export default AlbumDetailView;
