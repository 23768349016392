import React from "react";
import { Box, getImage, Link, theme, I18N, ScrollView, Platform, AppConfig, LinkBox } from "../../../FsCloudComponent";

const { colors, fonts, bgs } = theme;
const { primaryColor } = colors;
const { borderBg5 } = bgs;
const { h16 } = fonts;
const { APP_HELP_URL: helpUrl } = AppConfig;
class ConfigurationView extends React.Component {
  rowCard = ({ image, title, link, anchor, skipBorder }) => {
    let borderStyle = skipBorder ? void 0 : { borderBottomWidth: 1, borderBottomColor: borderBg5 };
    return {
      render: [
        {
          direction: "row",
          Container: anchor ? LinkBox : Link,
          containerProps: anchor || {
            action: {
              link: {
                ...link
              }
            }
          },
          viewStyle: {
            alignItems: "center",
            paddingTop: 20,
            paddingBottom: 20,
            marginLeft: 15,
            marginRight: 15,
            ...borderStyle
          },
          render: [
            {
              width: "1fr",
              direction: "row",
              viewStyle: { alignItems: "center" },
              render: [
                {
                  viewStyle: { paddingRight: 12 },
                  image: getImage(image)
                },
                {
                  viewStyle: { paddingLeft: 12 },
                  textStyle: {
                    color: primaryColor,
                    ...h16
                  },
                  text: I18N.t(title)
                }
              ]
            },
            {
              viewStyle: { height: 24, width: 24, alignItems: "center", justifyContent: "center" },
              image: getImage("rightArrowIcon")
            }
          ]
        }
      ]
    };
  };

  render() {
    return (
      <ScrollView style={{ flex: 1 }} bounces={false}>
        <Box
          render={[
            this.rowCard({
              title: "contacts",
              image: "contactConfigurationIcon",
              link: {
                uri: "/contacts"
              }
            }),
            Platform.OS !== "web" &&
              this.rowCard({
                title: "settings",
                image: "settingsConfigurationIcon",
                link: {
                  uri: "/settings"
                }
              }),
            this.rowCard({
              title: "help",
              image: "helpConfigurationIcon",
              anchor: {
                url: helpUrl,
                style: { marginLeft: 0, marginRight: 0 }
              }
            }),
            this.rowCard({
              title: "about",
              image: "aboutConfigurationIcon",
              link: {
                uri: "/about"
              },
              skipBorder: true
            })
          ]}
        />
      </ScrollView>
    );
  }
}

export default {
  Configuration: {
    component: "custom",
    Component: ConfigurationView,
    title: () => I18N.t("configuration")
  }
};
