import React from "react";
import {
  Box,
  I18N,
  Editor,
  PopupHeader as Header,
  View,
  theme,
  TabForm,
  ScrollView,
  showMessage,
  withContext,
  resolveMQ,
  StatusBar as LoadingIndicator,
} from "../../../../FsCloudComponent";
import ContactPhotoEditor from "./ContactPhotoEditor";

const MODIFIED_SERVER_FIELD = "_modifiedOn";

const { colors, fonts, bgs } = theme;
const { brownGrey, themeColor, tomato, highlightColor, charcoalGrey } = colors;
const { secondaryBorder } = bgs;
const { h3MD, h9, h3 } = fonts;

const editorStyle = {
  editorThemeSM: {
    containerStyle: {
      viewStyle: {
        paddingRight: 16,
        paddingLeft: 16,
        paddingTop: 10,
        paddingBottom: 10
      }
    },
    headerContainerStyle: {
      viewStyle: {
        paddingBottom: 6
      },
      textStyle: {
        ...h3,
        color: brownGrey
      }
    },
    editorContainerStyle: {
      paddingBottom: 9,
      borderColor: secondaryBorder,
      borderBottomWidth: 1,
      borderBottomStyle: "solid"
    },
    activeEditorContainerStyle: {
      borderColor: themeColor
    },
    errorStyle: {
      viewStyle: {
        paddingTop: 5
      },
      textStyle: {
        color: tomato,
        ...h3
      }
    }
  },
  editorThemeMD: {
    containerStyle: {
      viewStyle: {
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 10,
        paddingBottom: 10
      }
    },
    headerContainerStyle: {
      viewStyle: {
        paddingBottom: 6
      },
      textStyle: {
        ...h3,
        color: brownGrey
      }
    },
    editorContainerStyle: {
      paddingBottom: 9,
      borderColor: secondaryBorder,
      borderBottomWidth: 1,
      borderBottomStyle: "solid"
    },
    activeEditorContainerStyle: {
      borderColor: themeColor
    },
    errorStyle: {
      viewStyle: {
        paddingTop: 5
      },
      textStyle: {
        color: tomato,
        ...h3
      }
    }
  }
};

class EditorComponent extends React.Component {
  render() {
    let { data, setValue, updatableData, validationData, column, theme } = this.props;
    let { header, autoFocus } = column;
    if (header) {
      header = I18N.t(header);
    }
    return (
      <Editor
        data={data}
        setValue={setValue}
        updatableData={updatableData}
        validationData={validationData}
        column={{
          type: "text",
          ...column,
          header,
          containerProps: {
            theme,
            inputProps: {
              autoFocus,
              style: { ...h9, color: charcoalGrey }
            }
          }
        }}
      />
    );
  }
}

class FolksSM extends React.Component {
  render() {
    let { getEditorComponent, isNew } = this.props;
    return (
      <ScrollView style={{ flex: 1, paddingTop: 6 }}>
        <Box
          render={[
            getEditorComponent({ field: "firstName", header: "firstNameLabel", autoFocus: isNew }),
            getEditorComponent({ field: "middleName", header: "middleNameLabel" }),
            getEditorComponent({ field: "lastName", header: "lastNameLabel" }),
            getEditorComponent({ field: "telephoneResidential", header: "telResidential" }),
            getEditorComponent({ field: "cellPhone", header: "cellPhone" }),
            getEditorComponent({ field: "fax", header: "fax" }),
            getEditorComponent({ field: "mainEmail", header: "emailMain" }),
            getEditorComponent({ field: "personalEmail", header: "personalEmail" }),
            getEditorComponent({ field: "website", header: "site" }),
            getEditorComponent({ field: "address", header: "address" }),
            getEditorComponent({ field: "city", header: "city" }),
            getEditorComponent({ field: "state", header: "state" }),
            getEditorComponent({ field: "zipCode", header: "zipCode" })
          ]}
        />
      </ScrollView>
    );
  }
}

class JobsSM extends React.Component {
  render() {
    let { getEditorComponent } = this.props;
    return (
      <ScrollView style={{ flex: 1, paddingTop: 6 }}>
        <Box
          render={[
            getEditorComponent({ field: "company", header: "company" }),
            getEditorComponent({ field: "office", header: "office" }),
            getEditorComponent({ field: "department", header: "department" }),
            getEditorComponent({ field: "jobTel1", header: "tel1" }),
            getEditorComponent({ field: "jobTelCommercial", header: "telCommercial" }),
            getEditorComponent({ field: "jobFax", header: "fax" }),
            getEditorComponent({ field: "jobEmailMain", header: "emailMain" }),
            getEditorComponent({ field: "jobWebsite", header: "website" }),
            getEditorComponent({ field: "jobAddress", header: "address" }),
            getEditorComponent({ field: "jobCity", header: "city" }),
            getEditorComponent({ field: "jobState", header: "state" }),
            getEditorComponent({ field: "jobZipCode", header: "zipCode" })
          ]}
        />
      </ScrollView>
    );
  }
}

class OthersSM extends React.Component {
  render() {
    let { getEditorComponent } = this.props;
    return (
      <ScrollView style={{ flex: 1, paddingTop: 6 }}>
        <Box
          render={[
            getEditorComponent({ field: "otherTel1", header: "otherTel1" }),
            getEditorComponent({ field: "otherTel2", header: "otherTel2" }),
            getEditorComponent({ field: "otherFax", header: "otherFax" }),
            getEditorComponent({ field: "otherPager", header: "pager" }),
            getEditorComponent({ field: "otherInstantMessage", header: "instantMessage" }),
            getEditorComponent({ field: "otherWebsite", header: "website" }),
            getEditorComponent({ field: "otherAddress", header: "address" }),
            getEditorComponent({ field: "otherCity", header: "city" }),
            getEditorComponent({ field: "otherState", header: "state" }),
            getEditorComponent({ field: "otherZipCode", header: "zipCode" }),
            getEditorComponent({ field: "otherComment", header: "comments" })
          ]}
        />
      </ScrollView>
    );
  }
}

class FolksMD extends React.Component {
  render() {
    let { getEditorComponent, isNew } = this.props;
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          viewStyle={{ paddingLeft: 18, paddingRight: 18 }}
          render={[
            {
              direction: "row",
              render: [
                getEditorComponent({
                  field: "firstName",
                  header: "firstNameLabel",
                  autoFocus: isNew
                }),
                getEditorComponent({ field: "middleName", header: "middleNameLabel" }),
                getEditorComponent({ field: "lastName", header: "lastNameLabel" })
              ]
            },
            getEditorComponent({ field: "telephoneResidential", header: "telResidential" }),
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "cellPhone", header: "cellPhone" }),
                getEditorComponent({ field: "fax", header: "fax" })
              ]
            },
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "mainEmail", header: "emailMain" }),
                getEditorComponent({ field: "personalEmail", header: "personalEmail" })
              ]
            },
            getEditorComponent({ field: "website", header: "site" }),
            getEditorComponent({ field: "address", header: "address" }),
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "city", header: "city" }),
                getEditorComponent({ field: "state", header: "state" }),
                getEditorComponent({ field: "zipCode", header: "zipCode" })
              ]
            }
          ]}
        />
      </ScrollView>
    );
  }
}

class JobsMD extends React.Component {
  render() {
    let { getEditorComponent } = this.props;
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          viewStyle={{ paddingLeft: 18, paddingRight: 18 }}
          render={[
            getEditorComponent({ field: "company", header: "company" }),
            getEditorComponent({ field: "office", header: "office" }),
            getEditorComponent({ field: "department", header: "department" }),
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "jobTel1", header: "tel1" }),
                getEditorComponent({ field: "jobTel2", header: "tel2" })
              ]
            },
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "jobTelCommercial", header: "telCommercial" }),
                getEditorComponent({ field: "jobFax", header: "fax" })
              ]
            },
            getEditorComponent({ field: "jobEmailMain", header: "emailMain" }),
            getEditorComponent({ field: "jobWebsite", header: "website" }),
            getEditorComponent({ field: "jobAddress", header: "address" }),
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "jobCity", header: "city" }),
                getEditorComponent({ field: "jobState", header: "state" }),
                getEditorComponent({ field: "jobZipCode", header: "zipCode" })
              ]
            }
          ]}
        />
      </ScrollView>
    );
  }
}

class OthersMD extends React.Component {
  render() {
    let { getEditorComponent } = this.props;
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          viewStyle={{ paddingLeft: 18, paddingRight: 18 }}
          render={[
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "otherTel1", header: "otherTel1" }),
                getEditorComponent({ field: "otherTel2", header: "otherTel2" })
              ]
            },
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "otherFax", header: "otherFax" }),
                getEditorComponent({ field: "otherPager", header: "pager" })
              ]
            },
            getEditorComponent({ field: "otherInstantMessage", header: "instantMessage" }),
            getEditorComponent({ field: "otherWebsite", header: "website" }),
            getEditorComponent({ field: "otherAddress", header: "address" }),
            {
              direction: "row",
              render: [
                getEditorComponent({ field: "otherCity", header: "city" }),
                getEditorComponent({ field: "otherState", header: "state" }),
                getEditorComponent({ field: "otherZipCode", header: "zipCode" })
              ]
            },
            getEditorComponent({ field: "otherComment", header: "comments" })
          ]}
        />
      </ScrollView>
    );
  }
}

const tabs = {
  folks: {
    tab: {
      text: () => I18N.t("folks")
    },
    ComponentSM: FolksSM,
    ComponentMD: FolksMD
  },
  job: {
    tab: {
      text: () => I18N.t("job")
    },
    ComponentSM: JobsSM,
    ComponentMD: JobsMD
  },
  photograph: {
    tab: {
      text: () => I18N.t("photograph")
    },
    Component: ContactPhotoEditor
  },
  other: {
    tab: {
      text: () => I18N.t("other")
    },
    ComponentSM: OthersSM,
    ComponentMD: OthersMD
  }
};

class AddContact extends React.Component {
  state = { loading: false };

  _setState = state => {
    this.setState(state);
  };

  onSave = async ({ updatableData, isNew, data }) => {
    if (this.state.loading) {
      return;
    }
    let { firstName } = data;
    if (firstName) {
      firstName = firstName.trim();
    }
    if (!firstName) {
      this.setState({ firstNameMandatoryError: I18N.t("nameMandatoryError") });
      return;
    }
    this.setState({ loading: true });
    let { getUpdates, validateData } = updatableData;
    if (validateData) {
      let validations = await validateData();
      if (validations && Object.keys(validations).length) {
        this.setState({ loading: false });
        return;
      }
    }
    let { invoke, urls, onClose } = this.props;
    let { updates } = (getUpdates && getUpdates()) || {};
    let update = updates && updates.length ? updates[0].update : void 0;
    let updatedData = update && update.data && update.data.set;
    if (!updatedData) {
      showMessage && showMessage(I18N.t("noChangesFound"), 1000);
      this.setState({ loading: false });
      return;
    }
    if (updatedData.firstName !== undefined) {
      updatedData.firstName = updatedData.firstName.trim();
    }
    if (updatedData.middleName !== undefined) {
      updatedData.middleName = updatedData.middleName.trim();
    }
    if (updatedData.lastName !== undefined) {
      updatedData.lastName = updatedData.lastName.trim();
    }
    if (isNew) {
      updatedData["deleted"] = false;
      updatedData[MODIFIED_SERVER_FIELD] = Date.now();
      await invoke({
        service: {
          url: urls["addContact"],
          uriProps: {
            data: updatedData
          }
        }
      });
      showMessage && showMessage(I18N.t("contactAddedMessage"), 1000);
    } else {
      updatedData["_id"] = data._id;
      updatedData[MODIFIED_SERVER_FIELD] = Date.now();
      await invoke({
        service: {
          url: urls["updateContact"],
          uriProps: {
            data: updatedData
          }
        }
      });
      showMessage && showMessage(I18N.t("contactUpdatedMessage"), 1000);
    }
    this.setState({ loading: false });
    onClose && onClose();
  };

  render() {
    let {
      showHeader,
      isNew,
      swipeable,
      buttonText,
      data,
      activeMQ,
      setValue,
      updatableData,
      validationData
    } = this.props;
    let { editorTheme } = resolveMQ(editorStyle, ["editorTheme"], activeMQ);
    return (
      <View style={{ flex: 1 }}>
        {this.state.loading && <LoadingIndicator />}
        {showHeader && <Header title={I18N.t(isNew ? "addContact" : "editContact")} onClose={this.props.onClose} />}
        <View style={{ flex: 1 }} className="contactPlaceholder">
          <TabForm
            tabType={"contactTab"}
            swipeable={swipeable}
            tabs={tabs}
            data={data}
            setValue={setValue}
            isNew={isNew}
            setState={this._setState}
            getEditorComponent={column => {
              return {
                width: "1fr",
                render: (
                  <EditorComponent
                    theme={editorTheme}
                    column={column}
                    data={data}
                    setValue={params => {
                      let { field } = params;
                      if (field === "firstName" && this.state.firstNameMandatoryError) {
                        this.setState({
                          firstNameMandatoryError: void 0
                        });
                      }
                      setValue && setValue(params);
                    }}
                    updatableData={updatableData}
                    validationData={validationData}
                  />
                )
              };
            }}
          />
        </View>
        <Box
          direction="row"
          viewStyle={{
            marginLeft: 30,
            marginRight: 30,
            marginBottom: 24,
            marginTop: 12,
            alignItems: "center",
            justifyContent: "space-between"
          }}
          render={[
            {
              viewStyle: {
                flex: 1,
                paddingRight: 12
              },
              textStyle: {
                ...h9,
                color: tomato,
                numberOfLines: 2
              },
              text: this.state.firstNameMandatoryError || this.state.error
            },
            {
              viewStyle: {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 4,
                backgroundColor: themeColor,
                cursor: "pointer"
              },
              textStyle: { ...h3MD, textAlign: "center", color: highlightColor },
              text: I18N.t(buttonText),
              onClick: () => {
                this.onSave({
                  updatableData,
                  data,
                  isNew
                });
              }
            }
          ]}
        />
      </View>
    );
  }
}
export default withContext(AddContact, {
  urls: "App.urls",
  invoke: "App.invoke",
  activeMQ: "ActiveMQ"
});
