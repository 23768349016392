import React from "react";
import { AnimatedRecyclerListRowImage, FSListItem } from "../../../FsCloudComponent";

export default ({
  imageField,
  renderHeader,
  item,
  onClick,
  index,
  isSelected,
  activeMQ,
  lastSelected,
  currentSelected,
  selectRow,
  isSelectionMode,
  decryptionSourceProps
}) => {
  return (
    <FSListItem
      data={item}
      lastSelected={lastSelected}
      currentSelected={currentSelected}
      index={index}
      onClick={onClick}
      selectRow={selectRow}
      isSelectionMode={isSelectionMode}
    >
      {({ onClick, onLongPress }) => (
        <AnimatedRecyclerListRowImage
          data={item}
          isSelected={isSelected}
          onClick={onClick}
          onLongPress={onLongPress}
          imageField={imageField}
          localImageField={"localUri"}
          fields={{
            name: "thumbnail_name",
            resource_name: "resource_name",
            size: "thumbnail_size",
            resource_size: "resource_size",
            height: "thumbnail_height",
            width: "thumbnail_width",
            lastModified: "resource_lastModified"
          }}
          decryptionSourceProps={decryptionSourceProps}
          imagePrefix={"thumbnail"}
          renderHeader={renderHeader}
          lastSelected={lastSelected}
          currentSelected={currentSelected}
          activeMQ={activeMQ}
        />
      )}
    </FSListItem>
  );
};
