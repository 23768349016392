import React from "react";
import * as Animatable from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { generateShareLink } from "../../metadata/screens/share/shareUtility";
import UtilityHoc from "./Utility";

export default hocProps => {
  const { detectMob, TopHeaderComponent, ControlBarComponent, LinkComponent } = UtilityHoc(hocProps);
  const {
    View,
    Image,
    getImage,
    Box,
    sounds,
    theme,
    getInput,
    I18N,
    StatusBar,
    fireLocalDataChangeListener,
    logFirebaseAnalyticsEvent,
    isEncrypted,
    decryptFile,
    getDecryptionProps
  } = hocProps;

  const INITIAL_LOAD_LIMIT = 1;

  const { colors, bgs, fonts } = theme;
  const { blackBg } = bgs;
  const { highlightColor } = colors;
  const { h24_xl, h1 } = fonts;

  const mountAnimation = [
    "bounce",
    // "flash", out
    "jello",
    "pulse",
    // "rotate",
    // "rubberBand", out
    "shake",
    "swing",
    "wobble",
    "bounceIn",
    "bounceInDown",
    "bounceInUp",
    "bounceInLeft",
    "bounceInRight",
    "fadeIn",
    "fadeInDown",
    "fadeInDownBig",
    "fadeInUp",
    "fadeInUpBig",
    "fadeInLeft",
    "fadeInLeftBig",
    "fadeInRight",
    "fadeInRightBig",
    // "flipInX",
    // "flipInY",
    // "lightSpeedIn", out
    "slideInDown",
    "slideInUp",
    "slideInLeft",
    "slideInRight"
    // "zoomIn",
    // "zoomInDown",
    // "zoomInUp",
    // "zoomInLeft",
    // "zoomInRight"
  ];
  const unmountAnimation = [
    "bounceOut",
    "bounceOutDown",
    "bounceOutUp",
    "bounceOutLeft",
    "bounceOutRight",
    "fadeOut",
    "fadeOutDown",
    "fadeOutDownBig",
    "fadeOutUp",
    "fadeOutUpBig",
    "fadeOutLeft",
    "fadeOutLeftBig",
    "fadeOutRight",
    "fadeOutRightBig",
    // "flipOutX",
    // "flipOutY",
    "lightSpeedOut",
    "slideOutDown",
    "slideOutUp",
    "slideOutLeft",
    "slideOutRight"
    // "zoomOut",
    // "zoomOutDown",
    // "zoomOutUp",
    // "zoomOutLeft",
    // "zoomOutRight"
  ];

  class SlideShow extends React.Component {
    constructor(props) {
      super(props);
      let { images = [] } = props;
      this.state = {
        index: 0,
        animationIndex: Math.floor(Math.random() * mountAnimation.length),
        soundIndex: Math.floor(Math.random() * Object.keys(sounds).length),
        playStarted: false,
        musicPlaying: false,
        showControlBar: false,
        videoEnd: false,
        initialImageLoaded: false,
        volumeMute: true,
        linkUrl: ""
      };
      this.showNameInitially = true;
      this.pauseVideo = false;
      if (images.length) {
        this.loadInitialImages(images);
      }
    }

    componentDidMount() {
      this.showNameTimeout = setTimeout(() => {
        this.showNameInitially = false;
      }, 5000);
    }

    componentWillUnmount() {
      this._unmounted = true;
      clearTimeout(this.showNameTimeout);
      clearTimeout(this.controlBarTimeout);
    }

    _setState = (state, cb) => {
      if (this._unmounted) {
        return;
      }
      this.setState(state, cb);
    };

    decryptImage = async index => {
      let { images = [], decryptionSourceProps } = this.props;
      if (!images.length || index === undefined || index < 0 || index >= images.length) {
        return;
      }
      let image = images[index];
      if (!image) {
        return;
      }
      let url = image.converted_jpeg_url || image.thumbnail_url;
      if (!url || !isEncrypted(image, url)) {
        return url;
      }
      try {
        url = await decryptFile({
          url,
          decryptionProps: getDecryptionProps(image, decryptionSourceProps)
        });
      } catch (err) {
        console.warn("@@@@ error in decrypt in slideshow >>>> ", err && err.message);
      }
      return url;
    };

    loadInitialImages = async images => {
      let initialLimit = Math.min(images.length, INITIAL_LOAD_LIMIT);
      let mountSource = void 0;
      for (let i = 0; i < initialLimit; i++) {
        let decryptedUrl = await this.decryptImage(i);
        if (!mountSource && decryptedUrl) {
          mountSource = decryptedUrl;
        }
      }
      this._setState({ initialImageLoaded: true, mountSource }, () => {
        if (this.audioRef && this.audioRef.volume === 1) {
          this.audioRef.volume = 0;
        }
      });
    };

    ensureUrlAndChangeIndex = async (stateUpdates = {}) => {
      const { index: mountIndex, unmountIndex } = stateUpdates;

      let unmountSource = await this.decryptImage(unmountIndex);
      let mountSource = await this.decryptImage(mountIndex);

      if (mountIndex !== undefined && unmountIndex !== undefined) {
        let decryptImageIndex =
          mountIndex > unmountIndex ? mountIndex + INITIAL_LOAD_LIMIT - 1 : mountIndex - INITIAL_LOAD_LIMIT + 1;
        // call next decryption required without waiting
        this.decryptImage(decryptImageIndex);
      }
      this._setState({
        ...stateUpdates,
        mountSource,
        unmountSource
      });
    };

    getStyle = (animation, duration = 4000) => {
      return StyleSheet.create({
        [animation]: {
          animationName: Animatable[animation],
          animationDuration: `${duration}ms`
        }
      });
    };

    getAnimation = animationArray => Math.floor(Math.random() * animationArray.length);

    onAnimationEnd = (flag = true) => {
      let { images } = this.props;
      if (!images || !images.length || this.pauseVideo) {
        return null;
      }
      let { index, animationIndex, unmountAnimationIndex } = this.state;
      let newIndex;
      if (flag) {
        if (index === images.length - 1) {
          this.audioRef && this.audioRef.pause && this.audioRef.pause();
          this._setState({ unmountIndex: void 0, unmountAnimationIndex: void 0, videoEnd: true, showControlBar: true });
          return;
        }
        newIndex = index + 1;
      } else {
        if (index === 0) {
          return;
        }
        newIndex = index - 1;
      }
      if (newIndex < images.length) {
        let newAnimationIndex = void 0;
        let newUnmountAnimationIndex = void 0;
        do {
          newAnimationIndex = this.getAnimation(mountAnimation);
        } while (animationIndex === newAnimationIndex);
        do {
          newUnmountAnimationIndex = this.getAnimation(unmountAnimation);
        } while (unmountAnimationIndex === newUnmountAnimationIndex);
        this.ensureUrlAndChangeIndex({
          index: newIndex,
          unmountIndex: index,
          animationIndex: newAnimationIndex,
          unmountAnimationIndex: newUnmountAnimationIndex
        });
      } else {
        this.audioRef && this.audioRef.pause && this.audioRef.pause();
        this._setState({ unmountIndex: void 0, unmountAnimationIndex: void 0, videoEnd: true, showControlBar: true });
      }
    };

    onStartAnimation = () => {
      let { images } = this.props;
      if (!images || !images.length || this.pauseVideo) {
        return null;
      }
      let { index, animationIndex, unmountAnimationIndex } = this.state;
      let newIndex = 0;
      let newAnimationIndex = void 0;
      let newUnmountAnimationIndex = void 0;
      do {
        newAnimationIndex = this.getAnimation(mountAnimation);
      } while (animationIndex === newAnimationIndex);
      do {
        newUnmountAnimationIndex = this.getAnimation(unmountAnimation);
      } while (unmountAnimationIndex === newUnmountAnimationIndex);
      this.ensureUrlAndChangeIndex({
        index: newIndex,
        unmountIndex: index,
        animationIndex: newAnimationIndex,
        unmountAnimationIndex: newUnmountAnimationIndex
      });
    };

    pause = () => {
      const { animationIndex, videoEnd } = this.state;
      if (videoEnd) {
        let newAnimationIndex = void 0;
        do {
          newAnimationIndex = this.getAnimation(mountAnimation);
        } while (animationIndex === newAnimationIndex);
        this.ensureUrlAndChangeIndex({
          index: 0,
          videoEnd: false,
          showControlBar: false,
          animationIndex: newAnimationIndex
        });
        this.audioRef && this.audioRef.play && this.audioRef.play();
      } else {
        if (!this.pauseVideo) {
          this.pauseVideo = true;
          this.audioRef && this.audioRef.pause && this.audioRef.pause();
          this._setState({ musicPlaying: false });
        } else {
          this.pauseVideo = false;
          this.audioRef && this.audioRef.play && this.audioRef.play();
          this._setState({ musicPlaying: true });
          this.onAnimationEnd();
        }
      }
    };

    startPlayMusic = () => {
      if (!this.state.playStarted) {
        this._setState({ playStarted: true, musicPlaying: true });
      }
    };

    setControlBarTimeout = () => {
      if (this.controlBarTimeout) {
        clearTimeout(this.controlBarTimeout);
      }
      this.controlBarTimeout = setTimeout(() => {
        this._setState({ showControlBar: false });
      }, 2000);
    };

    toggleControlBar = () => {
      if (!this.state.showControlBar) {
        this._setState({ showControlBar: true });
        this.setControlBarTimeout();
      }
    };

    onMouseEnterControlBar = () => {
      if (this.state.showControlBar && this.controlBarTimeout) {
        clearTimeout(this.controlBarTimeout);
      }
    };

    onMouseLeaveControlBar = () => {
      if (this.state.showControlBar) {
        this.setControlBarTimeout();
      }
    };

    componentDidUpdate(prevProps) {
      let { images = [] } = this.props;
      let { images: oldImages = [] } = prevProps;
      if (!oldImages.length && images.length) {
        this.loadInitialImages(images);
      }
    }

    toggleVolume = () => {
      if (this.state.musicPlaying) {
        // Done for initial play of music when user tap on unmute
        // musicPlaying variable showing wheather music is playing or not. True means music is playing
      } else {
        this.audioRef && this.audioRef.play && this.audioRef.play();
      }
      let platformWeb = detectMob();
      if (this.state.volumeMute) {
        this._setState({ volumeMute: false });
        if (this.audioRef) {
          if (platformWeb) {
            this.audioRef.muted = false;
          } else {
            this.audioRef.volume = 1;
          }
        }
      } else {
        this._setState({ volumeMute: true });
        if (this.audioRef) {
          if (platformWeb) {
            this.audioRef.muted = true;
          } else {
            this.audioRef.volume = 0;
          }
        }
      }
    };

    getShareUrl = async () => {
      try {
        let { invoke, urls, albumId, backToAlbum, albumLink, link, data, origin } = this.props;
        let selectedIds = [];
        if (origin === "places") {
          for (const item of data) {
            selectedIds.push(item._id);
          }
        }

        if (backToAlbum || (link && link.parentUrl && link.parentUrl.indexOf("my-shared-data") >= 0)) {
          let url;
          if (albumLink) {
            let baseUrl = window.location.origin;
            let suburl = albumLink.replace("my-shared-data", "montage-view");
            url = baseUrl + suburl;
          } else {
            let baseUrl = window.location.origin;
            let suburl = link && link.parentUrl && link.parentUrl.replace("my-shared-data", "montage-view");
            url = baseUrl + suburl;
          }
          this._setState({ linkUrl: url });
        } else {
          this._setState({ loading: true });
          let obj;
          if (origin === "places") {
            obj = {
              selectedIds,
              origin,
              invoke,
              urls,
              montage: true
            };
          } else {
            obj = {
              data: { _id: albumId },
              origin: "collection",
              invoke,
              urls,
              montage: true
            };
          }

          let { url } = await generateShareLink({ fireLocalDataChangeListener, logFirebaseAnalyticsEvent, ...obj });
          this._setState({ loading: false, linkUrl: url });
        }
      } catch (err) {
        this._setState({ loading: false });
      }
    };

    copyLinkUrl = () => {
      if (this.inputRef) {
        this.inputRef.select();
      }
      document.execCommand("copy");
      this._setState({ linkCopied: "true", linkUrl: "" });
    };

    onCrossClick = () => {
      this.audioRef && this.audioRef.pause && this.audioRef.pause();
      const { replaceUri, albumLink, getPath, deleteUri, link } = this.props;
      if (window.location.href.indexOf("montage-view") > -1) {
        replaceUri && replaceUri({ uri: albumLink });
      } else {
        deleteUri && deleteUri(getPath && getPath(), link);
      }
    };

    onBackToAlbumClick = () => {
      this.audioRef && this.audioRef.pause && this.audioRef.pause();
      const { replaceUri, albumLink } = this.props;
      replaceUri && albumLink && replaceUri({ uri: albumLink });
    };

    getInputRef = ref => (this.inputRef = ref);

    render() {
      let { name, duration = 4000, backToAlbum, origin = "album", isOwner } = this.props;
      let {
        index,
        width,
        unmountIndex,
        unmountAnimationIndex,
        animationIndex,
        soundIndex,
        videoEnd,
        showControlBar,
        initialImageLoaded,
        linkUrl,
        loading,
        linkCopied,
        mountSource,
        unmountSource,
        volumeMute
      } = this.state;
      let mountStyle = this.getStyle(mountAnimation[animationIndex], duration);
      let unmountStyle = this.getStyle(unmountAnimation[unmountAnimationIndex], duration);

      let audioPlayer = (
        <audio
          src={sounds[`sound${soundIndex}`]}
          ref={audioRef => {
            this.audioRef = audioRef;
          }}
          // autoplay={"autoplay"}
          loop={"loop"}
          onPlay={this.startPlayMusic}
        />
      );

      const TextInput = getInput("text");
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: blackBg
          }}
          onLayout={({ nativeEvent: { layout } }) => {
            let { width } = layout;
            if (this.state.width !== width) {
              this._setState({ width });
            }
          }}
          onClick={this.toggleControlBar}
        >
          {(loading || !initialImageLoaded) && <StatusBar />}
          {initialImageLoaded && (
            <View style={{ flex: 1 }}>
              {unmountIndex !== undefined && !this.pauseVideo ? (
                <AnimationView
                  key={"unmount_" + String(unmountIndex)}
                  animation={css(unmountStyle[unmountAnimation[unmountAnimationIndex]])}
                  source={unmountSource}
                  duration={duration}
                  videoEnd={videoEnd || this.pauseVideo}
                />
              ) : (
                void 0
              )}
              {mountSource ? (
                <AnimationView
                  key={String(index)}
                  animation={css(mountStyle[mountAnimation[animationIndex]])}
                  onAnimationEnd={this.onAnimationEnd}
                  source={mountSource}
                  duration={duration}
                  videoEnd={videoEnd || this.pauseVideo}
                />
              ) : (
                void 0
              )}
              {audioPlayer}
            </View>
          )}
          {videoEnd || this.pauseVideo || !initialImageLoaded ? (
            <TopHeaderComponent onCrossClick={this.onCrossClick} />
          ) : (
            void 0
          )}
          {videoEnd || this.pauseVideo || this.showNameInitially || !initialImageLoaded ? (
            <Box
              style={{
                position: "absolute",
                elevation: 5,
                zIndex: 5,
                justifyContent: "center",
                alignItems: "center",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }}
              render={[
                width && {
                  viewStyle: { paddingLeft: 12, paddingRight: 12, width },
                  textStyle: {
                    numberOfLines: 1,
                    textAlign: "center",
                    color: highlightColor,
                    ...h24_xl
                  },
                  text: name
                },
                videoEnd || this.pauseVideo
                  ? {
                      render: [
                        ((origin === "album" && (isOwner || linkUrl)) || origin === "places") && (
                          <LinkComponent
                            linkUrl={linkUrl}
                            linkCopied={linkCopied}
                            copyLinkUrl={this.copyLinkUrl}
                            getShareUrl={this.getShareUrl}
                          />
                        ),
                        <TextInput
                          getRef={this.getInputRef}
                          value={linkUrl}
                          readonly={"readonly"}
                          style={{ width: 1, height: 1 }}
                        />,
                        backToAlbum && {
                          onClick: this.onBackToAlbumClick,
                          viewStyle: {
                            cursor: "pointer",
                            alignItems: "center"
                          },
                          render: [
                            {
                              viewStyle: { paddingTop: 30, paddingBottom: 18 },
                              imageStyle: { objectFit: "contain" },
                              image: getImage("montageToSeeAlbumPhotosIcon")
                            },
                            { textStyle: { color: highlightColor, ...h1 }, text: I18N.t("toSeePhotos") }
                          ]
                        }
                      ]
                    }
                  : void 0
              ]}
            />
          ) : (
            void 0
          )}
          {initialImageLoaded && (showControlBar || this.pauseVideo) ? (
            <ControlBarComponent
              videoEnd={videoEnd}
              pauseVideo={this.pauseVideo}
              volumeMute={volumeMute}
              onMouseEnter={this.onMouseEnterControlBar}
              onMouseLeave={this.onMouseLeaveControlBar}
              onPlayClick={this.pause}
              onBackwardClick={this.onStartAnimation}
              onPrevClick={this.onAnimationEnd}
              onNextClick={this.onAnimationEnd}
              onVolumeClick={this.toggleVolume}
            />
          ) : (
            void 0
          )}
        </View>
      );
    }
  }

  class AnimationView extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        opacity: 0,
        zIndex: 1
      };
    }
    componentDidMount() {
      this.setState({
        opacity: 1
      });
    }

    componentDidUpdate(prevProps) {
      if (this.props.animation !== prevProps.animation) {
        this.setState({ opacity: 0, zIndex: -1 });
      }
    }

    render() {
      let { animation, onAnimationEnd, source, videoEnd, duration = 4000 } = this.props;
      return (
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: "100%",
            width: "100%",
            transition: `all ${duration}ms linear`,
            ...this.state
          }}
        >
          <View className={animation} style={{ height: "100%", width: "100%" }} onAnimationEnd={onAnimationEnd}>
            <Image
              source={source}
              style={{ objectFit: "cover", height: "100%", width: "100%", opacity: videoEnd ? 0.4 : 1 }}
            />
          </View>
        </View>
      );
    }
  }
  return SlideShow;
};
