import React from "react";
import { PieChart } from "react-minimal-pie-chart";

export default class Chart extends React.Component {
  render() {
    let { stats = [] } = this.props;

    return (
      <PieChart
        animate
        data={stats}
        labelPosition={50}
        lengthAngle={360}
        paddingAngle={0}
        radius={48}
        startAngle={0}
        center={[50, 50]}
        viewBoxSize={[100, 100]}
      />
    );
  }
}
