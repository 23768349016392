import { I18N } from "../../../FsCloudComponent";

const FailedItemsTab = {
  component: "tab",
  title: () => I18N.t("failedItems"),
  showTitleInTab: true,
  swipeable: true,
  isNestedTab: true,
  tabs: {
    failed: {
      tab: {
        text: () => I18N.t("failed")
      },
      screen: "FailedItems",
      absoluteHeader: true
    },
    infected: {
      tab: {
        text: () => I18N.t("infected")
      },
      screen: "InfectedItems",
      absoluteHeader: true
    }
  }
};
export default {
  FailedItemsTabsSM: FailedItemsTab,
  FailedItemsTabsMD: {
    ...FailedItemsTab,
    navComponentContainerStyle: {
      marginLeft: 81
    }
  }
};
