import WalkthroughHoc from "./Walkthrough";
import { Swiper as SwiperHoc } from "swiper";
import LoginContainerHoc from "./LoginContainer";
import ValidateOtpHoc from "./ValidateOtp";
import WebLoginCommonFooterHoc from "./WebLoginCommonFooter";
import WebLoginOptionHoc from "./WebLoginOption";
import LoginOptionHoc from "./LoginOption";
import LoginHoc from "./Login";
import WebLoginHoc from "./WebLogin";
import ReCaptchaHoc from "./ReCaptcha";
import ResendOtpHoc from "./ResendOtp";
import OtpBoxHoc from "./OtpBox";
import LoginErrorComponentHoc from "./LoginErrorComponent";
import { Spinner as SpinnerHoc } from "statusbar";
import LoginLanguageDisplayComponentHoc from "./LoginLanguageDisplayComponent";
import LanguageSelectorHoc from "../LanguageSelector";
import WebLandingScreenHoc from "./WebLandingScreen";
import WebValidateOtpHoc from "./WebValidateOtp";
import WebLoginHeaderHoc from "./WebLoginHeader";
import LoginButtonHoc from "./LoginButton";
import LoginEditorHoc from "./LoginEditor";
import WebRegisterHoc from "./WebRegister";
import RegisterHoc from "./Register";
import ChatLinkComponentHoc from "./ChatLinkComponent";

export default (components, otherProps) => {
  const {
    View,
    ScrollView,
    Box,
    getImage,
    getComponent,
    getInput,
    getAction,
    Editor,
    withContext,
    AppConnect: Connect,
    Platform,
    resolveMQ,
    theme,
    ImageBackground,
    I18N,
    WithModal,
    showMessage,
    AsyncStorage,
    Keyboard,
    StatusBar
  } = components;
  const {
    FSLoginApi,
    languages,
    AppConfig,
    afterLogin,
    SmsReader,
    isValidEmail,
    logFirebaseAnalyticsEvent,
    getErrorMessage,
    firebase
  } = otherProps;

  const ResendOtp = ResendOtpHoc({ Box, theme, defaultTime: 90, I18N });

  const Walkthrough = withContext(
    WalkthroughHoc({
      Box,
      Swiper: SwiperHoc({ View, ScrollView }),
      getImage,
      getAction,
      theme,
      AppConfig,
      I18N,
      getComponent,
      getInput
    }),
    {
      addUri: "Router.addUri",
      getPath: "Router.getPath"
    }
  );

  const ChatLinkComponent = ChatLinkComponentHoc({
    Box,
    getImage,
    getAction,
    I18N,
    theme,
    getComponent,
    AppConfig
  });

  const LoginContainer = withContext(
    LoginContainerHoc({
      Box,
      Connect,
      ScrollView,
      resolveMQ,
      theme: theme.loginContainerStyle,
      ImageBackground,
      Platform,
      FSLoginApi
    }),
    {
      setUserInfo: "User.setUserInfo",
      showError: "Toast.showError",
      showMessage: "Toast.showMessage",
      activeMQ: "ActiveMQ"
    }
  );

  const LoginErrorComponent = LoginErrorComponentHoc({ Box, theme, Platform });

  const LoginEditor = withContext(
    LoginEditorHoc({
      Editor,
      theme,
      ErrorComponent: LoginErrorComponent,
      I18N,
      Box,
      Platform,
      isValidEmail
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const LoginButton = withContext(
    LoginButtonHoc({
      Box,
      theme,
      I18N,
      resolveMQ,
      LoginSpinner: SpinnerHoc({ View, theme: theme.loginSpinnerStyle }),
      Platform
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const Register = withContext(
    RegisterHoc({
      Box,
      LoginContainer,
      theme,
      getImage,
      I18N,
      Editor: LoginEditor,
      Button: LoginButton,
      ChatLinkComponent,
      Platform,
      Keyboard,
      getErrorMessage
    }),
    {
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      replaceUri: "Router.replaceUri",
      link: "Screen.Route.link",
      url: "Screen.Route.url",
      activeMQ: "ActiveMQ"
    }
  );

  const LoginOption = LoginOptionHoc({
    Box,
    getAction,
    getImage,
    theme,
    I18N,
    AppConfig,
    ScrollView,
    ChatLinkComponent,
    Platform
  });

  const ReCaptcha = ReCaptchaHoc({ Box, firebase, I18N, getImage, StatusBar });

  const Login = withContext(
    LoginHoc({
      Box,
      getImage,
      getAction,
      LoginContainer,
      theme,
      I18N,
      AppConfig,
      AsyncStorage,
      Platform,
      Editor: LoginEditor,
      Button: LoginButton,
      ChatLinkComponent,
      SmsReader,
      logFirebaseAnalyticsEvent,
      Keyboard,
      getErrorMessage
    }),
    {
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      deleteUri: "Router.deleteUri",
      activeMQ: "ActiveMQ"
    }
  );

  const ValidateOtp = withContext(
    ValidateOtpHoc({
      Box,
      Editor: LoginEditor,
      ResendOtp,
      theme,
      LoginContainer,
      I18N,
      Platform,
      showMessage,
      resendPincode: FSLoginApi.resendPincode,
      afterLogin,
      Button: LoginButton,
      ChatLinkComponent,
      SmsReader,
      logFirebaseAnalyticsEvent,
      Keyboard,
      getErrorMessage
    }),
    {
      activeMQ: "ActiveMQ",
      replaceUri: "Router.replaceUri"
    }
  );

  const LoginLanguageDisplayComponent = withContext(
    LoginLanguageDisplayComponentHoc({
      Box,
      getImage,
      theme,
      resolveMQ,
      languages
    }),
    {
      getLanguage: "App.getLanguage",
      activeMQ: "ActiveMQ"
    }
  );

  const LoginLanguageSelector = withContext(
    LanguageSelectorHoc({
      Box,
      WithModal,
      getImage,
      theme,
      languages,
      DisplayComponent: LoginLanguageDisplayComponent,
      resolveMQ
    }),
    {
      activeMQ: "ActiveMQ",
      setLanguage: "App.setLanguage",
      getLanguage: "App.getLanguage"
    }
  );

  const WebLoginCommonFooter = withContext(
    WebLoginCommonFooterHoc({ Box, theme, I18N, resolveMQ, getComponent, LoginLanguageSelector, getImage, AppConfig }),
    {
      activeMQ: "ActiveMQ",
      urls: "App.urls"
    }
  );

  const WebLoginHeader = withContext(WebLoginHeaderHoc({ Box, getImage, getAction, theme, I18N, resolveMQ }), {
    activeMQ: "ActiveMQ"
  });

  const WebLoginOption = withContext(
    WebLoginOptionHoc({
      Box,
      Editor,
      getImage,
      getAction,
      theme,
      LoginHeader: WebLoginHeader,
      I18N,
      resolveMQ,
      AppConfig,
      Button: LoginButton,
      ChatLinkComponent
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );
  const WebRegister = withContext(
    WebRegisterHoc({
      Box,
      LoginHeader: WebLoginHeader,
      Editor: LoginEditor,
      getImage,
      theme,
      I18N,
      resolveMQ,
      Button: LoginButton,
      ResendOtp,
      resendPincode: FSLoginApi.resendPincode,
      showMessage,
      afterLogin,
      ChatLinkComponent,
      getErrorMessage
    }),
    {
      replaceUri: "Router.replaceUri",
      activeMQ: "ActiveMQ"
    }
  );

  const WebLogin = withContext(
    WebLoginHoc({
      Box,
      Editor: LoginEditor,
      LoginHeader: WebLoginHeader,
      Button: LoginButton,
      theme,
      I18N,
      resolveMQ,
      ChatLinkComponent,
      getErrorMessage,
      logFirebaseAnalyticsEvent
    }),
    {
      addUri: "Router.addUri",
      getPath: "Router.getPath",
      activeMQ: "ActiveMQ"
    }
  );
  const WebValidateOtp = withContext(
    WebValidateOtpHoc({
      Box,
      LoginHeader: WebLoginHeader,
      Editor: LoginEditor,
      theme,
      I18N,
      resolveMQ,
      getComponent,
      ResendOtp,
      Button: LoginButton,
      showMessage,
      afterLogin,
      resendPincode: FSLoginApi.resendPincode,
      AppConfig,
      ChatLinkComponent,
      getErrorMessage
    }),
    {
      replaceUri: "Router.replaceUri",
      activeMQ: "ActiveMQ"
    }
  );

  const WebLandingScreen = withContext(
    WebLandingScreenHoc({
      Box,
      Editor,
      getImage,
      getComponent,
      getInput,
      getAction,
      theme,
      I18N,
      resolveMQ,
      ScrollView,
      Footer: WebLoginCommonFooter,
      LoginContainer,
      WebLoginOption,
      WebLogin,
      WebValidateOtp,
      WebRegister,
      AppConfig
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const OtpBox = OtpBoxHoc({ theme: theme.otpBoxStyle, getInput });

  return {
    Walkthrough,
    LoginContainer,
    LoginErrorComponent,
    WebLoginOption,
    ValidateOtp,
    WebLogin,
    ReCaptcha,
    WebValidateOtp,
    WebLandingScreen,
    OtpBox,
    ChatLinkComponent,
    LoginEditor,
    LoginButton,
    LoginOption,
    Login,
    Register,
    ResendOtp
  };
};
