export default ({ fonts, colors, images, bgs, gradients }) => {
  const { h4, h3, h16_l, h4MD, h10 } = fonts;
  const { primaryBg1, primaryBorder, primaryBg2, borderBg3 } = bgs;
  const { secondaryColor, themeColor, errorColor } = colors;
  const { toggleIconDown, toggleIconUp } = images;

  const formStyle = {
    scrollViewStyleSM: {
      style: {
        flex: 1
      }
    },
    scrollViewStyleMD: {
      style: {
        flex: 1,
        backgroundColor: primaryBg2,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 15,
        paddingBottom: 15
      }
    }
  };

  const verticalEditorContainerStyle = {
    containerStyleSM: {
      viewStyle: {
        paddingBottom: 6,
        paddingTop: 6
      }
    },
    containerStyleMD: {
      viewStyle: {
        paddingBottom: 9,
        paddingTop: 9
      }
    },
    headerContainerStyleSM: {
      viewStyle: {
        paddingBottom: 4
      },
      textStyle: {
        ...h4,
        color: secondaryColor
      }
    },
    headerContainerStyleMD: {
      viewStyle: {
        paddingBottom: 5
      },
      textStyle: {
        ...h4MD,
        color: secondaryColor
      }
    },
    activeHeaderContainerStyleSM: {
      viewStyle: {
        paddingBottom: 4
      },
      textStyle: {
        ...h4,
        color: themeColor
      }
    },
    activeHeaderContainerStyleMD: {
      viewStyle: {
        paddingBottom: 5
      },
      textStyle: {
        ...h4MD,
        color: themeColor
      }
    },
    editorContainerStyleSM: {
      paddingBottom: 4,
      borderColor: "transparent",
      borderBottomWidth: 1,
      borderBottomStyle: "solid"
    },
    editorContainerStyleMD: {
      paddingBottom: 6,
      borderColor: "transparent",
      borderBottomWidth: 1,
      borderBottomStyle: "solid"
    },
    activeEditorContainerStyle: {
      borderColor: themeColor
    },
    iconStyle: {
      viewStyle: {
        alignItems: "center",
        justifyContent: "center",
        width: 40
      },
      imageStyle: {
        height: 16,
        width: 16
      }
    },
    errorStyle: {
      viewStyle: {
        paddingTop: 5
      },
      textStyle: {
        color: errorColor,
        ...h3
      }
    }
  };

  const loginVerticalEditorContainerStyle = {
    containerStyleSM: {
      viewStyle: {
        paddingBottom: 6,
        paddingTop: 6
      }
    },
    containerStyleMD: {
      viewStyle: {
        paddingBottom: 9,
        paddingTop: 9
      }
    },
    headerContainerStyleSM: {
      viewStyle: {
        paddingBottom: 2
      },
      textStyle: {
        ...h4,
        color: secondaryColor
      }
    },
    headerContainerStyleMD: {
      viewStyle: {
        paddingBottom: 5
      },
      textStyle: {
        ...h4,
        color: secondaryColor
      }
    },
    activeHeaderContainerStyleSM: {
      viewStyle: {
        paddingBottom: 2
      },
      textStyle: {
        ...h4,
        color: themeColor
      }
    },
    activeHeaderContainerStyleMD: {
      viewStyle: {
        paddingBottom: 5
      },
      textStyle: {
        ...h4,
        color: themeColor
      }
    },
    editorContainerStyleSM: {
      paddingBottom: 4,
      borderColor: borderBg3,
      borderBottomWidth: 1,
      borderBottomStyle: "solid"
    },
    editorContainerStyleMD: {
      paddingBottom: 6,
      borderColor: borderBg3,
      borderBottomWidth: 1,
      borderBottomStyle: "solid"
    },
    activeEditorContainerStyle: {
      borderColor: themeColor
    },
    iconStyle: {
      viewStyle: {
        alignItems: "center",
        justifyContent: "center",
        width: 40
      },
      imageStyle: {
        height: 16,
        width: 16
      }
    },
    errorStyle: {
      viewStyle: {
        paddingTop: 5
      },
      textStyle: {
        color: errorColor,
        ...h3
      }
    }
  };

  const formCardGridStyle = {
    columnStyleSM: {
      viewStyle: {
        paddingLeft: 6,
        paddingRight: 6
      }
    },
    columnStyleMD: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8
      }
    }
  };

  const groupLabelStyle = {
    boxStyleSM: {
      viewStyle: {
        backgroundColor: "white",
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 8,
        marginRight: 8,
        paddingTop: 6,
        paddingBottom: 2,
        borderColor: primaryBorder,
        borderWidth: 1
      }
    },
    boxStyleMD: {
      viewStyle: {
        marginBottom: 8,
        paddingTop: 6,
        paddingBottom: 9,
        borderColor: primaryBorder,
        borderWidth: 1
      }
    },
    headerContainerStyleSM: {
      viewStyle: {
        paddingLeft: 6,
        paddingRight: 6,
        paddingBottom: 6,
        alignItems: "center"
      },
      direction: "row"
    },
    headerContainerStyleMD: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 0,
        alignItems: "center"
      },
      direction: "row"
    },
    titleStyleSM: {
      viewStyle: {
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 6,
        paddingBottom: 4
      },
      textStyle: {
        ...h10,
        color: themeColor
      }
    },
    titleStyleMD: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 9,
        paddingBottom: 6
      },
      textStyle: {
        ...h3,
        color: themeColor
      }
    },
    expandStyleSM: {
      expandImage: toggleIconDown,
      collapseImage: toggleIconUp,
      imageContainerStyle: {
        viewStyle: {
          marginLeft: 6,
          marginRight: 6,
          marginTop: 6,
          marginBottom: 4,
          alignItems: "center",
          justifyContent: "center"
        }
      }
    },
    expandStyleMD: {
      expandImage: toggleIconDown,
      collapseImage: toggleIconUp,
      imageContainerStyle: {
        viewStyle: {
          width: 22,
          height: 22,
          borderRadius: 11,
          backgroundColor: primaryBg1,
          marginLeft: 8,
          marginRight: 8,
          alignItems: "center",
          justifyContent: "center"
        }
      }
    },
    rowStyleSM: {
      viewStyle: {
        paddingLeft: 6,
        paddingRight: 6
      }
    },
    rowStyleMD: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    columnStyleSM: {
      viewStyle: {
        paddingLeft: 6,
        paddingRight: 6
      }
    },
    columnStyleMD: {
      viewStyle: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    actionsContainerStyle: {
      viewStyle: {
        justifyContent: "center"
      }
    }
  };

  const formCardStyle = {
    titleStyle: {
      textAlign: "center",
      numberOfLines: 1,
      paddingBottom: 8,
      ...h3
    },
    countStyle: {
      textAlign: "center",
      numberOfLines: 1,
      ...h16_l
    },
    viewStyle: {
      height: 66,
      padding: 10,
      borderWidth: 1,
      borderColor: primaryBorder,
      borderRadius: 2,
      marginTop: 10,
      marginBottom: 7,
      justifyContent: "center"
    }
  };

  return {
    formCardGridStyle,
    formStyle,
    verticalEditorContainerStyle,
    loginVerticalEditorContainerStyle,
    groupLabelStyle,
    formCardStyle
  };
};
