export default {
  "/archive": {
    screenSM: "ArchiveSM",
    screenMD: "ArchiveMD",
    hash: ({ hash }) => {
      if (hash) {
        return {
          SelectedTab: hash
        };
      }
    }
  }
};
