export const getSource = source => {
  if (typeof source === "function") {
    source = source();
  }
  if (typeof source === "object" && source !== null) {
    let { uri } = source;
    source = uri;
  }
  return source;
};
