import React from "react";
import ResourceDetailComponent from "../resource/ResourceDetailComponent";
import {
  galleryDetailQuery,
  albumGalleryDetailQuery,
  searchGalleryDetailQuery,
  groupGalleryDetailQuery,
  vaultGalleryDetailQuery,
  placeGalleryDetailQuery,
  archiveGalleryDetailQuery,
  duplicateResourceDetailQuery,
  deviceFolderGalleryDetailQuery
} from "../queries";

let commonDetailProps = {
  component: "table",
  Component: props => {
    let { link } = props;
    let videoField = (link && link.videoField) || "converted_mp4_url";
    return <ResourceDetailComponent videoField={videoField} {...props} />;
  },
  panelProps: {
    showHeader: false
  },
  hideNavHeader: true
};

export default {
  GalleryDetail: {
    ...commonDetailProps,
    ...galleryDetailQuery
  },
  AlbumGalleryDetail: {
    ...commonDetailProps,
    ...albumGalleryDetailQuery
  },
  PlaceGalleryDetail: {
    ...commonDetailProps,
    ...placeGalleryDetailQuery
  },
  DeviceFolderGalleryDetail: {
    ...commonDetailProps,
    ...deviceFolderGalleryDetailQuery
  },
  SearchGalleryDetail: {
    ...commonDetailProps,
    ...searchGalleryDetailQuery
  },
  GroupGalleryDetail: {
    ...commonDetailProps,
    ...groupGalleryDetailQuery
  },
  VaultGalleryDetail: {
    ...commonDetailProps,
    ...vaultGalleryDetailQuery
  },
  ArchiveGalleryDetail: {
    ...commonDetailProps,
    ...archiveGalleryDetailQuery
  },
  DuplicateResourceDetail: {
    ...commonDetailProps,
    ...duplicateResourceDetailQuery
  }
};
