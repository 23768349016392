import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./fs-common-frontend/FsCloudApp";
import * as serviceWorker from "./serviceWorker";
class MainApp extends Component {
  componentDidMount() {
    const loadingdiv = document.getElementById("fs-loading");
    loadingdiv && loadingdiv.parentNode.removeChild(loadingdiv);
    const loadImagesdiv = document.getElementById("load-images");
    loadImagesdiv && loadImagesdiv.parentNode.removeChild(loadImagesdiv);
  }

  render() {
    return <App />;
  }
}

ReactDOM.render(<MainApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
