import React from "react";
import NetworkListenerContext from "./NetworkListenerContext";
export default ({ View, NoNetworkToast, onConnectionChange }) => {
  class NetInfo extends React.Component {
    state = {};
    componentDidMount() {
      window.addEventListener("offline", this.setOffline);
      window.addEventListener("online", this.setOnline);
    }
    setOnline = () => {
      this.clearTimeInterval();
      if (!this.listenerContext.online) {
        this.listenerContext.online = true;
        onConnectionChange && onConnectionChange(true);
        this.setState({});
      }
    };

    setOffline = () => {
      this.clearTimeInterval();
      if (this.listenerContext.online) {
        this.listenerContext.online = false;
        onConnectionChange && onConnectionChange(false);
        this.setState({});
      }
    };
    componentWillUnmount() {
      this.clearTimeInterval();
      window.removeEventListener("offline", this.setOffline);
      window.removeEventListener("online", this.setOnline);
    }

    clearTimeInterval = () => {
      this.timeInterval && clearInterval(this.timeInterval);
      this.timeInterval = void 0;
    };

    showNetworkError = (err, duration = 3000) => {
      if (this.listenerContext.online) {
        this.clearTimeInterval();
        this.setOffline();
        this.timeInterval = setTimeout(() => {
          this.setOnline();
        }, duration);
      }
    };

    listenerContext = { online: navigator.onLine, showNetworkError: this.showNetworkError };

    render() {
      let { children, user } = this.props;
      let { online } = this.listenerContext;
      return (
        <NetworkListenerContext.Provider value={this.listenerContext}>
          <View style={{ flex: 1 }}>
            {!user || online ? (
              void 0
            ) : (
              <View style={{ overflow: void 0 }}>
                <NoNetworkToast />
              </View>
            )}
            {children}
          </View>
        </NetworkListenerContext.Provider>
      );
    }
  }
  return NetInfo;
};
