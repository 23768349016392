import React from "react";
import {
  ScrollView,
  Box,
  I18N,
  theme,
  getImage,
  WithModal,
  withContext,
  StatusBar as LoadingIndicator,
  View,
  Modal,
  ConfirmBox,
  Platform,
  logFirebaseAnalyticsEvent,
  getSizeFormat,
  DecryptedImage
} from "../../../FsCloudComponent";
import FreeUpSpaceUtilityHoc from "./FreeUpSpaceUtility";
const { fonts, colors } = theme;
const { greyishBrownTwo, themeColor, highlightColor, primaryColor, brownGrey } = colors;
const { h9_24, h1, h1_18, h16, h1_24, h16_l, h1_16, h2_l_18 } = fonts;

const modalStyle = {
  modal: {
    autoHide: true,
    width: 0.9,
    animationType: "none",
    style: { borderRadius: 4 }
  }
};

class LearnMoreComponent extends React.Component {
  renderModal = ({ closeModal } = {}) => {
    return (
      <Box
        style={{
          paddingLeft: 16,
          paddingRight: 13,
          paddingTop: 17,
          paddingBottom: 20
        }}
        render={[
          {
            textStyle: { color: greyishBrownTwo, ...h1_24 },
            text: I18N.t("freeSpaceModalMessage1")
          },
          {
            textStyle: { color: greyishBrownTwo, ...h9_24 },
            text: I18N.t("freeSpaceModalMessage2")
          },
          {
            viewStyle: { alignItems: "flex-end", paddingTop: 30, paddingRight: 15 },
            onClick: closeModal,
            textStyle: { color: themeColor, ...h1 },
            text: I18N.t("close")
          }
        ]}
      />
    );
  };

  render() {
    return (
      <WithModal {...modalStyle} renderModal={this.renderModal}>
        <Box
          render={[
            {
              viewStyle: {
                paddingTop: 18,
                paddingBottom: 18,
                justifyContent: "center",
                alignItems: "center"
              },
              textStyle: { color: themeColor, ...h16_l },
              text: I18N.t("learnMore")
            }
          ]}
        />
      </WithModal>
    );
  }
}

class BeforeStartComponent extends React.Component {
  state = {};

  onClick = () => {
    logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ screen: "free_up_space", event: "calculate_space" });
    const { setState, invoke, urls } = this.props;
    const { spaceCalculator } = FreeUpSpaceUtilityHoc({ invoke, urls });
    this.setState({ loading: true });
    spaceCalculator()
      .then(res => {
        this.setState({ loading: false });
        setState && setState({ ...res, type: "StartUp" });
      })
      .catch(err => {
        this.setState({ loading: false });
        setState && setState({ type: "NoData" });
      });
  };

  render() {
    let { loading } = this.state;
    return (
      <View>
        <Box
          viewStyle={{ paddingLeft: 16, paddingRight: 16 }}
          render={[
            {
              viewStyle: { paddingTop: 85, paddingBottom: 60, justifyContent: "center", alignItems: "center" },
              image: getImage("freeUpSpaceIcon")
            },
            {
              viewStyle: {
                paddingLeft: 8,
                paddingRight: 8
              },
              textStyle: { color: greyishBrownTwo, textAlign: "center", ...h1_18 },
              text: I18N.t(loading ? "calculatingFreeSpace" : "freeUpSpaceMessage1")
            },
            loading && {
              viewStyle: {
                height: 100
              },
              render: <LoadingIndicator />
            },
            !loading && <LearnMoreComponent />,
            !loading && {
              viewStyle: {
                marginTop: 44,
                marginBottom: 18,
                paddingTop: 14,
                paddingBottom: 14,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
                backgroundColor: themeColor
              },
              textStyle: { color: highlightColor, ...h16 },
              text: I18N.t("start"),
              onClick: this.onClick
            }
          ]}
        />
      </View>
    );
  }
}

class FreeUpSpaceNoDataComponent extends React.Component {
  render() {
    return (
      <View style={{ padding: 24 }}>
        <Box
          viewStyle={{ paddingTop: 93, paddingBottom: 60, alignItems: "center" }}
          image={getImage("freeUpSpaceIcon")}
        />
        <Box
          textStyle={{ color: greyishBrownTwo, textAlign: "center", ...h1_18 }}
          text={I18N.t("freeUpNoSpaceMessage")}
        />
        <Box viewStyle={{ marginBottom: 76 }} render={<LearnMoreComponent />} />
      </View>
    );
  }
}

class StartUpComponent extends React.Component {
  state = {};

  onClick = () => {
    logFirebaseAnalyticsEvent && logFirebaseAnalyticsEvent({ screen: "free_up_space", event: "freed_space" });
    const { setState, state: { dataToDelete = [] } = {}, invoke, urls } = this.props;
    const { freeUpSpace } = FreeUpSpaceUtilityHoc({ invoke, urls });
    this.setState({ loading: true, modalOpen: false });
    freeUpSpace(dataToDelete)
      .then(res => {
        this.setState({ loading: false });
        setState && setState({ ...res, type: "AfterFreeSpace" });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  openConfirmModal = () => {
    this.setState({ modalOpen: true });
  };

  closeConfirmModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    let { state: { sizeToBeFreed, dataToDelete } = {} } = this.props;
    return (
      <View>
        {this.state.loading && <LoadingIndicator />}
        <Box
          viewStyle={{ paddingLeft: 16, paddingRight: 16 }}
          render={[
            {
              viewStyle: { paddingTop: 85, paddingBottom: 60, justifyContent: "center", alignItems: "center" },
              image: getImage("freeUpSpaceIcon")
            },
            {
              viewStyle: {
                paddingLeft: 8,
                paddingRight: 8
              },
              render: [
                {
                  textStyle: { color: greyishBrownTwo, textAlign: "center", ...h1_18 },
                  text: () => {
                    let message = I18N.t("FreeUpSpace");
                    message = message.replace("__size__", sizeToBeFreed);
                    return message;
                  }
                },
                {
                  viewStyle: { paddingTop: 16 },
                  textStyle: { color: greyishBrownTwo, textAlign: "center", ...h1_18 },
                  text: () => {
                    let message = I18N.t("SafeFreeUpSpace");
                    message = message.replace("__size__", sizeToBeFreed);
                    return message;
                  }
                }
              ]
            },
            {
              viewStyle: {
                marginTop: 45,
                marginBottom: 30,
                paddingTop: 14,
                paddingBottom: 14,
                borderRadius: 2,
                alignItems: "center",
                height: 46,
                backgroundColor: themeColor
              },
              textStyle: { color: highlightColor, ...h16 },
              text: I18N.t("continue"),
              onClick: Platform.OS === "ios" ? this.onClick : this.openConfirmModal
            },
            {
              render: [
                dataToDelete &&
                  dataToDelete.map(item => {
                    const { type, thumbnail_url, resource_size, name: resource_name } = item || {};
                    let imageComponent = void 0;
                    if (thumbnail_url) {
                      imageComponent = (
                        <DecryptedImage
                          data={item}
                          style={{
                            height: 51,
                            width: 51
                          }}
                          source={{ uri: thumbnail_url }}
                        />
                      );
                    } else {
                      imageComponent = { viewStyle: { height: 51, width: 51 }, image: getImage("statsGallery") };
                      if (type === "doc") {
                        imageComponent.image = getImage("statsDocument");
                      } else if (type === "audio") {
                        imageComponent.image = getImage("statsMusic");
                      }
                    }
                    return {
                      direction: "row",
                      viewStyle: {
                        height: 74,
                        alignItems: "center",
                        paddingLeft: 8,
                        paddingRight: 8
                      },
                      render: [
                        imageComponent,
                        {
                          width: "1fr",
                          render: [
                            {
                              textStyle: {
                                ...h1_16,
                                color: primaryColor,
                                numberOfLines: 1,
                                whiteSpace: "pre",
                                paddingLeft: 16
                              },
                              text: resource_name
                            },
                            {
                              direction: "row",
                              textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
                              viewStyle: { paddingTop: 4, paddingLeft: 16 },
                              render: [
                                {
                                  text: getSizeFormat(resource_size)
                                },
                                {
                                  viewStyle: { paddingLeft: 16 },
                                  text: type
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    };
                  })
              ]
            },
            <Modal
              key={"freeSpaceConfirm"}
              position={"center"}
              width={0.9}
              animationType={"none"}
              style={{
                borderRadius: 4,
                overflow: "hidden"
              }}
              onClose={this.closeConfirmModal}
              isOpen={this.state.modalOpen}
            >
              <ConfirmBox
                message={() => {
                  let message = I18N.t("freeSpaceConfirmMessage");
                  message = message.replace("__itemCount__", dataToDelete.length);
                  return message;
                }}
                confirmText={I18N.t("continue")}
                onConfirm={this.onClick}
                onCancel={this.closeConfirmModal}
              />
            </Modal>
          ]}
        />
      </View>
    );
  }
}

class AfterFreeSpaceComponent extends React.Component {
  render() {
    let { state: { sizeFreed } = {} } = this.props;
    return (
      <Box
        viewStyle={{ justifyContent: "center", alignItems: "center", paddingLeft: 24, paddingRight: 24 }}
        render={[
          {
            viewStyle: { paddingTop: 85, paddingBottom: 60 },
            image: getImage("freeUpSpaceIcon")
          },
          {
            textStyle: { color: greyishBrownTwo, textAlign: "center", ...h1_18 },
            text: () => {
              let message = I18N.t("CongratesFreeUpmessage");
              message = message.replace("__size__", sizeFreed);
              return message;
            }
          },
          <LearnMoreComponent />
        ]}
      />
    );
  }
}

class FreeUpSpaceComponent extends React.Component {
  state = {};

  render() {
    let Component = BeforeStartComponent;
    let { type } = this.state;
    if (type === "NoData") {
      Component = FreeUpSpaceNoDataComponent;
    } else if (type === "StartUp") {
      Component = StartUpComponent;
    } else if (type === "AfterFreeSpace") {
      Component = AfterFreeSpaceComponent;
    }
    return (
      <ScrollView style={{ flex: 1 }} bounces={false}>
        <Component
          {...this.props}
          setState={state => {
            this.setState(state);
          }}
          state={this.state}
        />
      </ScrollView>
    );
  }
}

FreeUpSpaceComponent = withContext(FreeUpSpaceComponent, {
  invoke: "App.invoke",
  urls: "App.urls"
});

export default {
  FreeUpSpace: {
    component: "table",
    title: I18N.t("freeUpSpaceTitle"),
    Component: FreeUpSpaceComponent
  }
};
