export default {
  MyProfile: {
    fields: {
      name: {
        header: "name",
        field: "name",
        type: "text",
        width: "200"
      },
      mobile: {
        header: "mobile",
        field: "mobile",
        type: "text",
        width: "200"
      },
      email: { header: "email", field: "email", type: "text", width: "200" },
      profile_pic: {
        header: "Profile Pic",
        field: "profile_pic",
        width: "500",
        type: "image"
      }
    },
    updateProps: {
      model: "User"
    }
  }
};
