import React from "react";
import {
  TableHeaderRow as TableHeaderRowHoc,
  TableRow as TableRowHoc,
  Card as CardHoc,
  ScrollNotifier as ScrollNotifierHoc,
  TableBody as TableBodyHoc,
  InfiniteScrollList as InfiniteScrollListHoc,
  Table as TableHoc
} from "table";

import SortableHeaderColumnHoc from "./SortableHeaderColumn";

export default ({
  theme = {},
  Box,
  View,
  withContext,
  ScrollContext,
  resolveValue,
  resolveVisibleExp,
  resolveField,
  resolveColumn,
  getAggregateValue,
  separateNumber,
  renderProps,
  getTheme,
  dataFormat,
  resolveMQ,
  getImage,
  getAction,
  getCardComponent,
  Swipeout,
  getComponent,
  showError
}) => {
  let { bgs } = theme;

  const ScrollNotifier = ScrollNotifierHoc({ renderProps });

  const InfiniteScrollList = withContext(
    InfiniteScrollListHoc({ getList: getComponent, pageSize: 20, ScrollContext }),
    {
      addScrollListener: "ScrollView.addScrollListener"
    }
  );

  const Card = withContext(
    CardHoc({
      Box,
      Swipeout,
      getTheme,
      getImage,
      getAction,
      resolveMQ,
      getComponent: getCardComponent,
      dataFormat,
      resolveField,
      resolveValue,
      resolveVisibleExp
    }),
    {
      getImageUrl: "App.getImageUrl"
    }
  );

  const tableRowProps = {
    Card,
    View,
    resolveColumn,
    resolveMQ,
    getTheme,
    resolveVisibleExp,
    minWidthFrMD: theme.minWidthFrMD,
    minWidthFrSM: theme.minWidthFrSM
  };

  const HeaderRow = withContext(
    TableHeaderRowHoc({
      ...tableRowProps,
      getAggregateValue,
      dataFormat,
      typeFormat: theme.typeFormat,
      typeStyle: theme.typeStyle,
      themeSM: theme.tableHeaderRowStyle,
      themeMD: theme.tableHeaderRowStyleMD
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const TableRow = withContext(
    TableRowHoc({
      ...tableRowProps,
      separateNumber,
      typeStyle: theme.typeStyle,
      themeSM: theme.tableRowStyle,
      themeMD: theme.tableRowStyleMD
    }),
    {
      activeMQ: "ActiveMQ",
      selectRow: "SelectionStore.selectRow",
      selectionMode: "SelectionStore.selectionMode",
      isSelected: "SelectionStore.isSelected"
    }
  );

  const DataRow = withContext(
    props => {
      const { data, isSelected } = props;
      const selected = isSelected && isSelected(data);
      return (
        <Box
          render={[
            <TableRow {...props} />,
            {
              viewStyle: {
                marginLeft: 30,
                marginRight: 30,
                borderTopWidth: 1,
                borderColor: selected ? "transparent" : bgs.lightPink
              }
            }
          ]}
        />
      );
    },
    {
      isSelected: "SelectionStore.isSelected"
    }
  );

  const TableBody = withContext(
    TableBodyHoc({
      List: InfiniteScrollList,
      DataRowSM: TableRow,
      DataRowMD: DataRow,
      bodyStyleSM: theme.tableBodyStyle,
      bodyStyleMD: theme.tableBodyStyleMD,
      resolveMQ,
      getTheme,
      horizontalScrollEnable: true //pass props to indicate the list may be enable horizontal scroll
    }),
    {
      activeMQ: "ActiveMQ",
      selectedIds: "SelectionStore.selectedIds"
    }
  );

  const Table = withContext(
    TableHoc({
      View,
      resolveMQ,
      ScrollNotifier,
      HeaderRow,
      Body: TableBody,
      style: { flex: 1 }
    }),
    {
      activeMQ: "ActiveMQ"
    }
  );

  const SortableHeaderColumn = withContext(
    SortableHeaderColumnHoc({
      Box,
      getImage
    }),
    {
      setParams: "Screen.Connect.setParams",
      dataParams: "Screen.Connect.dataParams"
    }
  );
  return {
    Table,
    SortableHeaderColumn
  };
};
