//var firebase = require("firebase");
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/functions";
import "firebase/analytics";

export default firebase;
