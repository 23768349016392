import React from "react";
import {
  View,
  resolveMQ,
  withContext,
  StatusBar as LoadingIndicator,
  showMessage,
  I18N,
  Platform
} from "../../../FsCloudComponent";
import ImageDetailComponentSM from "../gallery/ImageDetailComponentSM";
import ImageDetailComponentMD from "../gallery/ImageDetailComponent";
import DocDetailComponentSM from "../docs/DocDetailComponentSM";
import MusicDetailComponentSM from "../music/MusicDetailComponentSM";
import MusicDetailComponentMD from "../music/MusicDetailComponent";

const metadata = {
  gallery: {
    ComponentSM: ImageDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  album: {
    ComponentSM: ImageDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  places: {
    ComponentSM: ImageDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  deviceFolder: {
    ComponentSM: ImageDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  doc: {
    ComponentSM: DocDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  set: {
    ComponentSM: DocDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  music: {
    ComponentSM: MusicDetailComponentSM,
    ComponentMD: MusicDetailComponentMD
  },
  playlist: {
    ComponentSM: MusicDetailComponentSM,
    ComponentMD: MusicDetailComponentMD
  },
  vaultGallery: {
    ComponentSM: ImageDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  vaultDoc: {
    ComponentSM: DocDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  vaultAlbum: {
    ComponentSM: ImageDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  },
  vaultSet: {
    ComponentSM: DocDetailComponentSM,
    ComponentMD: ImageDetailComponentMD
  }
};

class ResourceDetailComponent extends React.Component {
  state = {};

  onClose = () => {
    let { deleteUri, url, link } = this.props;
    this.deleted = true;
    deleteUri && deleteUri(url, link);
  };

  componentDidUpdate() {
    if (Platform.OS !== "web" && this.currentIndex !== undefined) {
      let { fetchMore, data, hasNext } = this.props;
      if (fetchMore && hasNext && this.lastFetchIndex !== this.currentIndex) {
        if (data.length - this.currentIndex <= 50) {
          this.lastFetchIndex = this.currentIndex;
          fetchMore({ fetchInBackground: false });
        }
      }
    }
  }

  getIndex = ({ detailId, data = [] }) => {
    for (let index = 0; index < data.length; index++) {
      if (data[index]._id === detailId) {
        return index;
      }
    }
  };

  next = () => {
    let { data = [], replaceUri, link } = this.props;
    let currentIndex = this.currentIndex;
    if (currentIndex < data.length - 1) {
      currentIndex++;
      let newDetailId = data[currentIndex]._id;
      replaceUri({ ...link, detailId: newDetailId });
    }
  };
  prev = () => {
    let { data = [], replaceUri, link } = this.props;
    let currentIndex = this.currentIndex;
    if (currentIndex > 0) {
      currentIndex--;
      let newDetailId = data[currentIndex]._id;
      replaceUri({ ...link, detailId: newDetailId });
    }
  };

  onIndexChange = ({ index }) => {
    const { data, link, replaceUri } = this.props;
    let newDetailId = data[index]._id;
    let newLink = { ...link, detailId: newDetailId };
    if (Platform.OS === "web") {
      replaceUri(newLink);
    } else {
      this.setState({ link: newLink });
    }
  };

  render() {
    let { data = [], link, hasNext, activeMQ } = this.props;
    if (this.deleted) {
      return null;
    }
    link = this.state.link || link;
    let { detailId, origin, fromGroup, fromHighlight, fromShare, fromUpload, fromArtist, decryptionSourceProps } =
      link || {};
    let currentIndex = this.getIndex({ detailId, data: this.props.data });

    if (currentIndex >= 0) {
      this.currentIndex = currentIndex;
    } else {
      currentIndex = this.currentIndex;
      if (currentIndex !== undefined && currentIndex >= data.length) {
        if (data.length) {
          currentIndex = data.length - 1;
          this.currentIndex = currentIndex;
        } else {
          this.onClose();
          return null;
        }
      }
    }
    if (currentIndex >= 0) {
      detailId = data[currentIndex]._id;
    }
    let { Component } = resolveMQ(metadata[origin], ["Component"], activeMQ);
    if (currentIndex === undefined && detailId && fromUpload && data && data.length && !hasNext) {
      //close this view as item has been deleted
      this.onClose();
      showMessage(I18N.t("trashed_item_can_not_view"), 3000);
      return null;
    }
    return (
      <View style={{ flex: 1 }}>
        {currentIndex === undefined && <LoadingIndicator />}
        {Component && (
          <Component
            {...this.props}
            link={link}
            next={this.next}
            prev={this.prev}
            origin={origin}
            fromShare={fromShare}
            detailId={detailId}
            fromGroup={fromGroup}
            fromArtist={fromArtist}
            fromHighlight={fromHighlight}
            decryptionSourceProps={decryptionSourceProps}
            index={currentIndex}
            onIndexChange={this.onIndexChange}
            onClose={this.onClose}
            detailFromProps
          />
        )}
      </View>
    );
  }
}

ResourceDetailComponent = withContext(ResourceDetailComponent, {
  replaceUri: "Router.replaceUri",
  deleteUri: "Router.deleteUri",
  url: "ScreenRoute.url",
  link: "ScreenRoute.link",
  activeMQ: "ActiveMQ",
  hasNext: "Screen.Connect.hasNext",
  fetchMore: "Screen.Connect.fetchMore",
  user: "User.user"
});

export default ResourceDetailComponent;
