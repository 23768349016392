// fetch music not supported in web:-- Umang Sarkari:: 24-04-2019
/*fetch document not supported in web jai 16/4/2019 */

export default () => {
  const DeviceSetting = void 0;
  const fetchDocuments = async () => {
    return { result: [] };
  };
  const fetchMusic = async () => {
    return { result: [] };
  };

  return { DeviceSetting, fetchDocuments, fetchMusic };
};
