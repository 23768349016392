export default () => {
  const spaceCalulator = async () => {
    return new Promise((resolve, reject) => resolve({}));
  };

  const freeUpSpace = async () => {
    return new Promise((resolve, reject) => resolve({}));
  };

  return {
    spaceCalulator,
    freeUpSpace
  };
};
