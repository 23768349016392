export default ({ resolveImage }) => {
  const docExtShadowSMSprite = {
    uri: "/webImages/docExtShadowIcons/SM/1_sprite.png",
    shadow3dsIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    aacShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -56px",
      objectFit: "none"
    },
    aiShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -102px",
      objectFit: "none"
    },
    aviShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -148px",
      objectFit: "none"
    },
    bmpShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -194px",
      objectFit: "none"
    },
    cadShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -240px",
      objectFit: "none"
    },
    cdrShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -286px",
      objectFit: "none"
    },
    cssShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -332px",
      objectFit: "none"
    },
    csvShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -378px",
      objectFit: "none"
    },
    datShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-10px -424px",
      objectFit: "none"
    },
    dllShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -10px",
      objectFit: "none"
    },
    dmgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -56px",
      objectFit: "none"
    },
    docShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -102px",
      objectFit: "none"
    },
    docxShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -148px",
      objectFit: "none"
    },
    epsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -194px",
      objectFit: "none"
    },
    excelShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -240px",
      objectFit: "none"
    },
    flaShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -286px",
      objectFit: "none"
    },
    flvShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -332px",
      objectFit: "none"
    },
    gifShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -378px",
      objectFit: "none"
    },
    htmlShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-52px -424px",
      objectFit: "none"
    },
    inddShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -10px",
      objectFit: "none"
    },
    isoShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -56px",
      objectFit: "none"
    },
    jpgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -102px",
      objectFit: "none"
    },
    jsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -148px",
      objectFit: "none"
    },
    midiShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -194px",
      objectFit: "none"
    },
    movShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -240px",
      objectFit: "none"
    },
    mp3ShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -286px",
      objectFit: "none"
    },
    mpgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -332px",
      objectFit: "none"
    },
    odpShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -378px",
      objectFit: "none"
    },
    odsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-94px -424px",
      objectFit: "none"
    },
    odtShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -10px",
      objectFit: "none"
    },
    pdfShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -56px",
      objectFit: "none"
    },
    phpShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -102px",
      objectFit: "none"
    },
    pngShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -148px",
      objectFit: "none"
    },
    pptShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -194px",
      objectFit: "none"
    },
    psdShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -240px",
      objectFit: "none"
    },
    psShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -286px",
      objectFit: "none"
    },
    rawShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -332px",
      objectFit: "none"
    },
    sqlShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -378px",
      objectFit: "none"
    },
    svgShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-136px -242px",
      objectFit: "none"
    },
    tifShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -10px",
      objectFit: "none"
    },
    txtShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -56px",
      objectFit: "none"
    },
    unknownShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -102px",
      objectFit: "none"
    },
    wmvShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -148px",
      objectFit: "none"
    },
    wordShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -240px",
      objectFit: "none"
    },
    xlsShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -240px",
      objectFit: "none"
    },
    xlsxShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -286px",
      objectFit: "none"
    },
    xmlShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -332px",
      objectFit: "none"
    },
    zipShadowIcon: {
      width: 32,
      height: 36,
      objectPosition: "-178px -378px",
      objectFit: "none"
    }
  };
  const docExtShadowPopupMDSprite = {
    uri: "/webImages/docExtShadowIcons/MD/1_popupSprite.png",
    shadow3dsIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    aacShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -56px",
      objectFit: "none"
    },
    aiShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -102px",
      objectFit: "none"
    },
    aviShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -148px",
      objectFit: "none"
    },
    bmpShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -194px",
      objectFit: "none"
    },
    cadShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -240px",
      objectFit: "none"
    },
    cdrShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -286px",
      objectFit: "none"
    },
    cssShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-10px -332px",
      objectFit: "none"
    },
    csvShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-48px -10px",
      objectFit: "none"
    },
    datShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-18px -56px",
      objectFit: "none"
    },
    dllShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-238px -10px",
      objectFit: "none"
    },
    dmgShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-48px -102px",
      objectFit: "none"
    },
    docShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-48px -148px",
      objectFit: "none"
    },
    docxShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-48px -194px",
      objectFit: "none"
    },
    epsShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-48px -240px",
      objectFit: "none"
    },
    excelShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-48px -286px",
      objectFit: "none"
    },
    flaShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-48px -332px",
      objectFit: "none"
    },
    flvShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -10px",
      objectFit: "none"
    },
    gifShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -56px",
      objectFit: "none"
    },
    htmlShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -102px",
      objectFit: "none"
    },
    inddShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -148px",
      objectFit: "none"
    },
    isoShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -194px",
      objectFit: "none"
    },
    jpgShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -240px",
      objectFit: "none"
    },
    jsShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -286px",
      objectFit: "none"
    },
    midiShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-86px -332px",
      objectFit: "none"
    },
    movShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-124px -10px",
      objectFit: "none"
    },
    mp3ShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-124px -56px",
      objectFit: "none"
    },
    mpgShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-124px -102px",
      objectFit: "none"
    },
    odpShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-124px -1148px",
      objectFit: "none"
    },
    odsShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-124px -198px",
      objectFit: "none"
    },
    odtShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-124px -240px",
      objectFit: "none"
    },
    pdfShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-124px -286px",
      objectFit: "none"
    },
    phpShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-14px -332px",
      objectFit: "none"
    },
    pngShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -10px",
      objectFit: "none"
    },
    pptShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -56px",
      objectFit: "none"
    },
    psdShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -102px",
      objectFit: "none"
    },
    psShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -148px",
      objectFit: "none"
    },
    rawShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -194px",
      objectFit: "none"
    },
    sqlShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -240px",
      objectFit: "none"
    },
    svgShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -286px",
      objectFit: "none"
    },
    tifShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-162px -332px",
      objectFit: "none"
    },
    txtShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -10px",
      objectFit: "none"
    },
    unknownShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -56px",
      objectFit: "none"
    },
    wmvShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -102px",
      objectFit: "none"
    },
    wordShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -148px",
      objectFit: "none"
    },
    xlsShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -164px",
      objectFit: "none"
    },
    xlsxShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -240px",
      objectFit: "none"
    },
    xmlShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -286px",
      objectFit: "none"
    },
    zipShadowIcon: {
      width: 28,
      height: 36,
      objectPosition: "-200px -322px",
      objectFit: "none"
    }
  };

  const shadowpopup3dsIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.shadow3dsIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.shadow3dsIcon
        }
      }
    });
  const aacShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.aacShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.aacShadowIcon
        }
      }
    });
  const aiShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.aiShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.aiShadowIcon
        }
      }
    });
  const aviShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.aviShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.aviShadowIcon
        }
      }
    });
  const bmpShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.bmpShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.bmpShadowIcon
        }
      }
    });
  const cadShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.cadShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.cadShadowIcon
        }
      }
    });
  const cdrShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.cdrShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.cdrShadowIcon
        }
      }
    });
  const cssShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.cssShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.cssShadowIcon
        }
      }
    });
  const csvShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.csvShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.csvShadowIcon
        }
      }
    });
  const datShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.datShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.datShadowIcon
        }
      }
    });
  const dllShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.dllShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.dllShadowIcon
        }
      }
    });
  const dmgShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.dmgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.dmgShadowIcon
        }
      }
    });
  const docShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.docShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.docShadowIcon
        }
      }
    });
  const docxShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.docxShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.docxShadowIcon
        }
      }
    });
  const epsShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.epsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.epsShadowIcon
        }
      }
    });
  const excelShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.excelShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.excelShadowIcon
        }
      }
    });
  const flaShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.flaShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.flaShadowIcon
        }
      }
    });
  const flvShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.flvShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.flvShadowIcon
        }
      }
    });
  const gifShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.gifShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.gifShadowIcon
        }
      }
    });
  const htmlShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.htmlShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.htmlShadowIcon
        }
      }
    });
  const inddShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.inddShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.inddShadowIcon
        }
      }
    });
  const isoShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.isoShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.isoShadowIcon
        }
      }
    });
  const jpgShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.jpgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.jpgShadowIcon
        }
      }
    });
  const jsShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.jsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.jsShadowIcon
        }
      }
    });
  const midiShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.midiShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.midiShadowIcon
        }
      }
    });
  const movShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.movShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.movShadowIcon
        }
      }
    });
  const mp3ShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.mp3ShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.mp3ShadowIcon
        }
      }
    });
  const mpgShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.mpgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.mpgShadowIcon
        }
      }
    });
  const odpShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.odpShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.odpShadowIcon
        }
      }
    });
  const odsShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.odsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.odsShadowIcon
        }
      }
    });
  const odtShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.odtShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.odtShadowIcon
        }
      }
    });
  const pdfShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.pdfShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.pdfShadowIcon
        }
      }
    });
  const phpShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.phpShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.phpShadowIcon
        }
      }
    });
  const pngShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.pngShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.pngShadowIcon
        }
      }
    });
  const pptShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.pptShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.pptShadowIcon
        }
      }
    });
  const psShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.psShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.psShadowIcon
        }
      }
    });
  const psdShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.psdShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.psdShadowIcon
        }
      }
    });
  const rawShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.rawShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.rawShadowIcon
        }
      }
    });
  const sqlShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.sqlShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.sqlShadowIcon
        }
      }
    });
  const svgShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.svgShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.svgShadowIcon
        }
      }
    });
  const tifShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.tifShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.tifShadowIcon
        }
      }
    });
  const txtShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.txtShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.txtShadowIcon
        }
      }
    });
  const unknownShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.unknownShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.unknownShadowIcon
        }
      }
    });
  const wmvShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.wmvShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.wmvShadowIcon
        }
      }
    });
  const wordShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.wordShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.wordShadowIcon
        }
      }
    });
  const xlsShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.xlsShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.xlsShadowIcon
        }
      }
    });
  const xlsxShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.xlsxShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.xlsxShadowIcon
        }
      }
    });
  const xmlShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.xmlShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.xmlShadowIcon
        }
      }
    });
  const zipShadowpopupIcon = () =>
    resolveImage({
      SM: {
        uri: docExtShadowSMSprite.uri,
        spriteProps: {
          ...docExtShadowSMSprite.zipShadowIcon
        }
      },
      MD: {
        uri: docExtShadowPopupMDSprite.uri,
        spriteProps: {
          ...docExtShadowPopupMDSprite.zipShadowIcon
        }
      }
    });

  return {
    shadowpopup3dsIcon,
    aacShadowpopupIcon,
    aiShadowpopupIcon,
    aviShadowpopupIcon,
    bmpShadowpopupIcon,
    cadShadowpopupIcon,
    cdrShadowpopupIcon,
    cssShadowpopupIcon,
    csvShadowpopupIcon,
    datShadowpopupIcon,
    dllShadowpopupIcon,
    dmgShadowpopupIcon,
    docShadowpopupIcon,
    docxShadowpopupIcon,
    epsShadowpopupIcon,
    excelShadowpopupIcon,
    flaShadowpopupIcon,
    flvShadowpopupIcon,
    gifShadowpopupIcon,
    htmlShadowpopupIcon,
    inddShadowpopupIcon,
    isoShadowpopupIcon,
    jpgShadowpopupIcon,
    jsShadowpopupIcon,
    midiShadowpopupIcon,
    movShadowpopupIcon,
    mp3ShadowpopupIcon,
    mpgShadowpopupIcon,
    odpShadowpopupIcon,
    odsShadowpopupIcon,
    odtShadowpopupIcon,
    pdfShadowpopupIcon,
    phpShadowpopupIcon,
    pngShadowpopupIcon,
    pptShadowpopupIcon,
    psdShadowpopupIcon,
    psShadowpopupIcon,
    rawShadowpopupIcon,
    sqlShadowpopupIcon,
    svgShadowpopupIcon,
    tifShadowpopupIcon,
    txtShadowpopupIcon,
    unknownShadowpopupIcon,
    wmvShadowpopupIcon,
    wordShadowpopupIcon,
    xlsShadowpopupIcon,
    xlsxShadowpopupIcon,
    xmlShadowpopupIcon,
    zipShadowpopupIcon
  };
};
