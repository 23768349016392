import React from "react";
import { Card } from "./NonUploadItemCard";
import { NonUploadedNoData } from "../noData/NoData";
import { I18N, theme } from "../../../FsCloudComponent";
import { nonUploadedItemsQuery } from "../queries";

export default {
  NonUploadedItems: {
    component: "table",
    title: () => I18N.t("nonUploadedItems"),
    dataObject: "Resource",
    renderNoData: <NonUploadedNoData />,
    ...nonUploadedItemsQuery,
    listProps: {
      addMinHeightSM: 56,
      addMinHeightMD: false,
      list: "scrollIndicatorList"
    },
    showHeaderMD: true,
    headerRowPropsMD: {
      theme: {
        ...theme.tableHeaderRowStyleMD,
        viewStyle: {
          ...theme.tableHeaderRowStyleMD.viewStyle,
          marginRight: 95,
          marginLeft: 95
        }
      }
    },
    bodyPropsMD: {
      bodyStyle: {
        flex: 1,
        paddingRight: 80,
        paddingLeft: 81
      }
    },
    rowProps: {
      ...Card
    },
    headerActionsSM: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: ["selectItems", "selectAll", "selectNone", "removeNonUpload", "clearNonUploadSM"]
      }
    ],
    headerActionsMD: [
      {
        type: "headerAction",
        visible: ({ data, selectionMode }) => !selectionMode && data && data.length,
        action: "clearNonUpload"
      },
      {
        type: "headerAction",
        image: "iconMenu",
        visible: ({ data }) => data && data.length,
        action: ["selectItems", "selectAll", "selectNone", "removeNonUpload"]
      }
    ]
  }
};
