import { commonLayout } from "../../layouts";
import { I18N, theme, getSizeFormat } from "../../../FsCloudComponent";

const { fonts, colors } = theme;
const { h1_16, h2_l_18 } = fonts;
const { primaryColor, brownGrey } = colors;

const CardSM = {
  render: [
    {
      direction: "row",
      viewStyle: {
        height: 74,
        alignItems: "center"
      },
      render: [
        {
          viewStyle: { paddingLeft: 12, paddingRight: 16 },
          value: ({ data }) => {
            return {
              field: "name",
              type: "imageDoc",
              componentName: data.type === "audio" ? "musicImage" : void 0
            };
          }
        },
        {
          width: "1fr",
          render: [
            {
              textStyle: { ...h1_16, color: primaryColor, numberOfLines: 1, whiteSpace: "pre" },
              value: { field: "name" }
            },
            {
              direction: "row",
              textStyle: { ...h2_l_18, color: brownGrey, numberOfLines: 1 },
              viewStyle: { paddingTop: 4 },
              separator: "|",
              render: [
                {
                  value: ({ data }) => {
                    return { text: getSizeFormat(data.resource_size) };
                  }
                },
                {
                  value: ({ data }) => {
                    let text = "Unknown";
                    let { type, docType } = data;
                    if (type === "doc") {
                      text = docType === "Unknown" ? I18N.t("Unknown") : docType;
                    } else if (type === "audio") {
                      text = "Audio";
                    } else if (type === "image") {
                      text = "Image";
                    } else if (type === "video") {
                      text = "Video";
                    } else {
                      text = type;
                    }
                    return { text };
                  }
                },
                {
                  value: {
                    field: "_createdOn",
                    type: "date",
                    format: "classic",
                    classic: {
                      today: "LT",
                      rest: "DD/MM/YYYY"
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

const CardMD = ({ origin }) => {
  return {
    columns: [
      {
        width: "1fr",
        header: () => I18N.t("name"),
        field: "name",
        type: "imageDoc"
      },
      {
        header: () => I18N.t("size"),
        field: "resource_size",
        type: "fileSize",
        width: "175px"
      },
      {
        header: () => I18N.t("date"),
        type: "date",
        field: "_createdOn",
        width: "202px",
        format: "classic",
        classic: {
          today: "LT",
          rest: "DD/MM/YYYY"
        }
      },
      {
        header: () => I18N.t("type"),
        type: "text",
        field: data => {
          if (!data) {
            return;
          }
          let { type, docType } = data;
          if (type === "doc") {
            return docType === "Unknown" ? I18N.t("Unknown") : docType;
          } else if (type === "audio") {
            return "Audio";
          } else if (type === "image") {
            return "Image";
          } else if (type === "video") {
            return "Video";
          } else {
            return type;
          }
        },
        width: "148px"
      },
      {
        header: () => (origin === "failed" ? I18N.t("reason") : I18N.t("Virus")),
        type: "text",
        field: data => {
          let { infected, upload_error, viruses } = data;
          if (infected) {
            return viruses[0] ? viruses[0] : I18N.t("Unknown");
          } else {
            return upload_error ? upload_error : I18N.t("Unknown");
          }
        },
        width: "250px"
      }
    ]
  };
};

const Card = props =>
  commonLayout({
    SM: CardSM,
    MD: CardMD(props)
  });

export { Card };
