import React from "react";
import { View, theme, getComponent, getJsonComponent } from "../../../FsCloudComponent";
import { DocsCard } from "../docs/DocsCards";
import { MusicCard } from "../music/MusicCards";

const headerRowTheme = {
  ...theme.tableHeaderRowStyleMD,
  viewStyle: {
    ...theme.tableHeaderRowStyleMD.viewStyle,
    marginRight: 15
  }
};

class MasonaryList extends React.Component {
  render() {
    let { data } = this.props;
    const tableProps = {
      componentType: "table",
      component: getComponent("table"),
      data,
      componentProps: {
        selectable: true,
        listProps: {
          list: "virtualMasonaryList",
          pageSize: 1000,
          scrollViewStyle: { paddingLeft: 18, paddingRight: 18 }
        },
        selectionMode: true
      }
    };
    const Table = getJsonComponent("jsonTable");
    return (
      <View style={{ flex: 1, paddingLeft: 12, paddingRight: 12, paddingTop: 24 }}>
        <Table {...tableProps} />
      </View>
    );
  }
}

class SimpleTable extends React.Component {
  render() {
    let { rowProps, data, dataIds, hasNext, lastDoc, fetchMore } = this.props;
    const tableProps = {
      componentType: "table",
      component: getComponent("table"),
      data,
      dataIds,
      hasNext,
      lastDoc,
      fetchMore,
      componentProps: {
        selectable: true,
        showHeader: true,
        rowProps,
        bodyProps: {
          bodyStyle: {
            flex: 1
          }
        },
        headerRowProps: {
          theme: headerRowTheme
        }
      }
    };
    const Table = getJsonComponent("jsonTable");
    return <Table {...tableProps} />;
  }
}

export const AlbumItemSelector = MasonaryList;
export const SetItemSelector = props => <SimpleTable {...props} rowProps={{ ...DocsCard }} />;
export const PlaylistItemSelector = props => <SimpleTable {...props} rowProps={{ ...MusicCard }} />;
