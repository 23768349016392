export default () => {
  const montageSprite = {
    uri: "/webImages/montageIcons/1_sprite.png",
    montagePlayIcon: {
      width: 50,
      height: 50,
      objectPosition: "-42px -70px",
      objectFit: "none"
    },
    montagePauseIcon: {
      width: 50,
      height: 50,
      objectPosition: "-42px -10px",
      objectFit: "none"
    },
    montagePrevWhiteIcon: {
      width: 11,
      height: 16,
      objectPosition: "-10px -153px",
      objectFit: "none"
    },
    montageNextWhiteIcon: {
      width: 11,
      height: 16,
      objectPosition: "-10px -35px",
      objectFit: "none"
    },
    montageBackwardIcon: {
      width: 22,
      height: 15,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    montageGetLinkIcon: {
      width: 22,
      height: 22,
      objectPosition: "-10px -94px",
      objectFit: "none"
    },
    montageCopyLinkIcon: {
      width: 18,
      height: 23,
      objectPosition: "-10px -61px",
      objectFit: "none"
    },
    montageLinkCopiedIcon: {
      width: 22,
      height: 17,
      objectPosition: "-10px -126px",
      objectFit: "none"
    },
    montageVolumnOffIcon: {
      width: 22,
      height: 22,
      objectPosition: "-10px -179px",
      objectFit: "none"
    },
    montageVolumnUpIcon: {
      width: 23,
      height: 23,
      objectPosition: "-10px -211px",
      objectFit: "none"
    },
    montageToSeeAlbumPhotosIcon:{
      width: 24,
      height: 16,
      objectPosition: "-42px -130px",
      objectFit: "none"
    }

  }



  const montagePlayIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montagePlayIcon
    }
  };
  const montagePauseIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montagePauseIcon
    }
  };
  const montagePrevWhiteIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montagePrevWhiteIcon
    }
  };;
  const montageNextWhiteIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageNextWhiteIcon
    }
  };
  const montageBackwardIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageBackwardIcon
    }
  };
  const montageGetLinkIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageGetLinkIcon
    }
  };
  const montageCopyLinkIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageCopyLinkIcon
    }
  };
  const montageLinkCopiedIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageLinkCopiedIcon
    }
  };
  const montageVolumnOffIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageVolumnOffIcon
    }
  };
  const montageVolumnUpIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageVolumnUpIcon
    }
  };
  const montageToSeeAlbumPhotosIcon = {
    uri: montageSprite.uri,
    spriteProps: {
      ...montageSprite.montageToSeeAlbumPhotosIcon
    }
  };

  return {
    montagePlayIcon,
    montagePauseIcon,
    montagePrevWhiteIcon,
    montageNextWhiteIcon,
    montageBackwardIcon,
    montageGetLinkIcon,
    montageCopyLinkIcon,
    montageLinkCopiedIcon,
    montageVolumnOffIcon,
    montageVolumnUpIcon,
    montageToSeeAlbumPhotosIcon
  };
};
