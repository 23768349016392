import React from "react";
import { ArtistCardSMListProps } from "./MusicCards";
import { ArtistNoData } from "../noData/NoData";
import { artistQuery } from "../queries";
import ResourceActions from "../resource/ResourceActions";
import CollectionActions from "../collection/CollectionActions";
import { FSList } from "../../../FsCloudComponent";

export default {
  Artist: {
    component: "table",
    dataObject: "Collection",
    notifyNavOnScroll: true,
    selectable: false,
    ComponentSM: FSList,
    ComponentMD: null,
    componentPropsSM: {
      ...ArtistCardSMListProps,
      detailLink: CollectionActions.artistDetailLink.link
    },
    componentTypeMD: {
      gridTable: {
        cardPerRowLG: 3,
        cardPerRowMD: 2,
        bodyStyleMD: {
          flex: 1,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 15,
          paddingRight: 85
        }
      }
    },
    renderNoData: <ArtistNoData />,
    ...artistQuery,
    headerActions: [ResourceActions.searchMusic]
  }
};
