// sqlite util functions not supported in web - Umang Sarkari- 15 Apr, 2019
export const UPLOAD_DATA_KEY_TYPES = {};
export const DOWNLOAD_DATA_KEY_TYPES = {};
export const PAUSE_REASON_ENUM = {};

export const getUploadedFiles = () => {};
export const removeUploadItem = () => {};
export const getSqlExistStatusUsingResource = () => {};
export const addEntryInDownloadTableAfterComplete = () => {};
export const getQueuedAndInProgressDownloadFiles = () => {};
export const getDownloadDataById = () => {};
export const getDownloadDataByJobId = () => {};
export const updateDownloadTable = () => {};
export const dropTable = () => {};
export const getSettingInfo = () => {};
export const updateSettingInformation = () => {};
export const insertSettingInformation = () => {};
export const getQueuedAndInProgressUploadFiles = () => {};
export const updateUploadTable = () => {};
export const getDataUsingUriArray = () => {};

export const convertMetadataToDownloadData = () => {};
export const getDownloadingFiles = () => {};
export const allUploadedAndDownloadedData = () => {};
export const clearUploadAndDownloadInfoById = () => {};
export const deleteContact = () => {};
export const deleteAllContact = () => {};
export const addContact = () => {};
export const getAllContactsByModifiedTime = () => {};
export const getAllContactsFromTable = () => {};
export const updateContactTable = () => {};
export const resetContactServerId = () => {};
export const getAllNewContactsOnDevice = () => {};
export const clearUploadAndDownloadQueue = () => {};
export const getFilesPausedDueToWifi = () => {};
export const inProcessDownloadQueueLength = () => {};
export const getSyncedDirectoriesData = () => {};
export const updateSyncedDirectories = () => {};
export const deleteAllSyncedDirectories = () => {};
export const addDownloadItemInBatch = () => {};
export const getFailedUploadIds = () => {};
export const removeUploadByIds = () => {};
export const getUploadCount = () => {};
export const getFileUploadsFromUploadTable = () => {};
export const getFileDownloadsFromDownloadTable = () => {};
export const deleteFileUploadsFromUploadTable = () => {};
export const deleteFileDownloadsFromDownloadTable = () => {};
export const getFileUploadsTableCount = () => {};
export const getFileDownloadsTableCount = () => {};
export const addMetadataInBatch = () => {};
export const updateMetadata = () => {};
export const deleteMetadata = () => {};
export const getMetadata = () => {};
export const deleteAllMetadata = () => {};
export const getExistingMetadataIds = () => {};
export const emptyTrash = () => {};
export const emptyNonUploaded = () => {};
export const cancelQueuedUploads = () => {};
export const cancelQueuedDownloads = () => {};
export const resetUploadQueueMemory = () => {};
export const resetDownloadQueueMemory = () => {};
export const clearUploadQueue = () => {};
export const clearDownloadQueue = () => {};
