import React from "react";

export default ({
  Box,
  resolveMQ,
  resolveValue,
  getImage,
  getComponent,
  Platform,
  theme,
  ImageBackground,
  DecryptedImage,
  ...hocProps
}) => {
  let { colors, fonts } = theme;
  let { h16_l } = fonts;
  let { brownGrey, whiteTwo } = colors;
  class DocsImageComponent extends React.Component {
    render() {
      let { field, data, value, selected, activeMQ, fieldDef: { componentName } = {} } = this.props;
      let { selectable, imageMappings } = resolveMQ(hocProps, ["selectable", "imageMappings"], activeMQ);
      if (value === undefined && data && field) {
        value = resolveValue && resolveValue(data, field);
      }
      const extIndex = value && value.lastIndexOf(".");
      let ext = "";
      if (extIndex >= 0) {
        ext = value.substring(extIndex + 1);
      }
      ext = ext.toLowerCase();
      let extImage = imageMappings[ext];

      let imageComponent = void 0;
      if (selectable) {
        if (selected) {
          imageComponent = <Box image={getImage("docSelectedIcon")} />;
        } else if (componentName && getComponent && getComponent(componentName)) {
          let CustomComponent = getComponent(componentName);
          imageComponent = <CustomComponent {...this.props} />;
        } else if (data.type === "image" || data.type === "video") {
          if (data.thumbnail_url) {
            let imageProps = {
              source: { uri: data.thumbnail_url },
              data,
              style: {
                width: 50,
                height: 50,
                borderRadius: 25
              }
            };
            if (Platform.OS === "web") {
              imageProps.style.objectFit = "cover";
            } else {
              imageProps.resizeMode = "cover";
            }

            imageComponent = (
              <Box
                key={data.thumbnail_url}
                viewStyle={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: whiteTwo
                }}
                render={<DecryptedImage {...imageProps} />}
              />
            );
          } else {
            imageComponent = (
              <ImageBackground
                style={{
                  height: 50,
                  width: 50,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat"
                }}
                imageStyle={{ resizeMode: "contain" }}
                source={getImage("docsImageBackground")}
              >
                <Box image={getImage("homeGallerySelectedIcon")} />
              </ImageBackground>
            );
          }
        } else {
          imageComponent = (
            <ImageBackground
              style={{
                height: 50,
                width: 50,
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat"
              }}
              imageStyle={{ resizeMode: "contain" }}
              source={getImage("docsImageBackground")}
            >
              <Box image={getImage(extImage || "unknownIcon")} />
            </ImageBackground>
          );
        }
      } else {
        let imageInfo = void 0;
        if (data.type === "doc") {
          imageInfo = { image: getImage(extImage || "unknownIcon") };
        } else if (data.type === "audio") {
          imageInfo = { image: getImage("musicIcon") };
        } else if (data.type === "image" || data.type === "video") {
          if (data.thumbnail_url) {
            let imageProps = {
              source: { uri: data.thumbnail_url },
              data,
              style: {
                width: 40,
                height: 40,
                backgroundColor: whiteTwo
              }
            };
            if (Platform.OS === "web") {
              imageProps.style.objectFit = "cover";
            } else {
              imageProps.resizeMode = "cover";
            }
            imageInfo = {
              viewStyle: {
                height: 40,
                width: 40
              },
              render: <DecryptedImage {...imageProps} />
            };
          } else {
            imageInfo = { image: getImage("homeGallerySelectedIcon") };
          }
        }
        imageComponent = (
          <Box
            direction="row"
            viewStyle={{ alignItems: "center" }}
            render={[
              { ...imageInfo, viewStyle: { height: 40, width: 40, alignItems: "center", justifyContent: "center" } },
              {
                width: "1fr",
                viewStyle: { paddingLeft: 30 },
                textStyle: { ...h16_l, color: brownGrey, numberOfLines: 1 },
                text: value,
                title: value
              }
            ]}
          />
        );
      }
      return imageComponent;
    }
  }
  return DocsImageComponent;
};
