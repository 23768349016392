import React from "react";
/**
 *
 * showDetail=false => means it is manually closed.
 */
export default ({ Box, List, Platform, fromNowDateFormatter, ScrollIndicator, renderChildren, resolveMQ }) => {
  class ScrollIndicatorList extends React.Component {
    state = {
      scrollTitle: "",
      showIndicator: false
    };

    componentWillUnmount() {
      this.timer && clearTimeout(this.timer);
    }

    selectRow = data => {
      let { selectRow } = this.props;
      selectRow && selectRow(data);
    };

    onClick = (e, { item }) => {
      let { isSelectionMode, detailLink, addUri, getPath, getUri, link } = this.props;
      if (isSelectionMode && isSelectionMode()) {
        this.selectRow(item);
      } else if (detailLink) {
        if (typeof detailLink === "function") {
          detailLink = detailLink({ data: item, link });
        }
        let currentUri = getUri();
        let { query, subscribe, cache } = (currentUri && currentUri.props && currentUri.props) || {};
        detailLink = {
          ...detailLink,
          query,
          subscribe,
          cache,
          parentInfo:
            Platform.OS === "web"
              ? void 0
              : {
                  data: this.props.data,
                  dataIds: this.props.dataIds,
                  hasNext: this.props.hasNext,
                  lastDoc: this.props.lastDoc
                }
        };
        addUri && addUri(getPath(), detailLink);
      }
    };

    onViewableItemsChanged = ({ viewableItems = [] }) => {
      let { indicator_field } = this.props;
      if (!indicator_field) {
        return;
      }
      let { item = {} } = viewableItems[0] || {}; //getting first data of changed view
      let dateValue = item[indicator_field];
      if (!dateValue) return;
      dateValue = fromNowDateFormatter(dateValue);
      if (this.state.scrollTitle !== dateValue) {
        this.setState({ scrollTitle: dateValue });
      }
    };

    onScroll = e => {
      let { onScroll, indicator_field } = this.props;
      onScroll && onScroll(e);
      if (!this.state.showIndicator && indicator_field) {
        this.timer && clearTimeout(this.timer);
        this.setState({ showIndicator: true });
      }
    };

    onMomentumScrollEnd = () => {
      let { indicator_field } = this.props;
      if (indicator_field) {
        this.timer = setTimeout(() => {
          this.setState({ showIndicator: false });
        }, 1000);
      }
    };

    renderItem = props => {
      let { renderItem } = this.props;
      let renderItemComponent = renderItem(props);
      return renderChildren(renderItemComponent, {
        onClick: e => {
          this.onClick(e, props);
        }
      });
    };

    render() {
      let { activeMQ, renderItem, ...restProps } = this.props;
      let { addMinHeight, contentContainerStyle = {} } = resolveMQ(
        this.props,
        ["addMinHeight", "contentContainerStyle"],
        activeMQ
      );
      let extraListProps = {
        onScroll: this.onScroll,
        renderItem: this.renderItem
      };
      if (Platform.OS !== "web") {
        extraListProps.onViewableItemsChanged = this.onViewableItemsChanged;
      }
      if (addMinHeight && this.state.height) {
        contentContainerStyle.minHeight = this.state.height + addMinHeight;
      }
      return (
        <Box
          viewStyle={{
            flex: 1
          }}
          render={[
            {
              Container: ScrollIndicator,
              containerProps: { title: this.state.showIndicator ? this.state.scrollTitle : "" }
            },
            {
              viewStyle: { flex: 1 },
              onLayout: e => {
                let {
                  nativeEvent: {
                    layout: { height }
                  }
                } = e;
                if (this.state.height !== height) {
                  this.setState({ height });
                }
              },
              render: (
                <List
                  {...restProps}
                  {...extraListProps}
                  onMomentumScrollEnd={this.onMomentumScrollEnd}
                  contentContainerStyle={contentContainerStyle}
                />
              )
            }
          ]}
        />
      );
    }
  }

  return ScrollIndicatorList;
};
