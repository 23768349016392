const en = {
  firstWalkthroughPrimaryText: "Store online and automatically organize your photos, videos, music and files",
  secondWalkthroughPrimaryText: "and Leave more free memory space on your phone",
  thirdWalkthroughPrimaryText: "access whenever you want, from anywhere",
  fourthWalkthroughPrimaryText: "keep everything that is important to you in one place",
  webLandingTitle: "Keep everything that is important to you in one place"
};

const pt = {
  firstWalkthroughPrimaryText: "Guarde online e organize automaticamente as suas fotos, vídeos, músicas e arquivos",
  secondWalkthroughPrimaryText: "Deixando mais espaço livre no telefone com memória e sua vida digital protegida",
  thirdWalkthroughPrimaryText: "E ainda encontre seus arquivos na pesquisa, para compartilhar com quem quiser!",
  fourthWalkthroughPrimaryText: "Mantenha tudo o que é importante para você em um só lugar",
  webLandingTitle: "Mantenha tudo o que é importante para você em um só lugar"
};

export default {
  en,
  pt
};
