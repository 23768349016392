import React from "react";

export default ({ Box, resolveExp, resolveMQ, dataFormat, theme: themeHoc = {} }) => props => {
  let { activeMQ, style, textStyle, action, data, aggregates, onClick, theme = themeHoc, imageOnly } = props;
  let { title, image } = action;
  if (Array.isArray(title)) {
    title = title
      .map(titleDoc => {
        return resolveExp({ ...titleDoc, dataFormat, data: { data, aggregates }, returnWhenNotAvailable: true });
      })
      .filter(titleDoc => titleDoc)
      .join(" ");
  } else if (typeof title === "object" && title.exp) {
    title = resolveExp({ ...title, dataFormat, data: { data, aggregates } });
  }
  if (typeof title === "function") {
    title = title(props);
  }
  if (title === undefined && !image) {
    return null;
  }
  let render = [];
  if (image) {
    render.push({ image });
  }
  if (!imageOnly && title !== undefined) {
    render.push({ text: title });
  }
  let { boxStyle } = resolveMQ(theme, ["boxStyle"], activeMQ);
  return (
    <Box
      onClick={
        onClick
          ? () => {
              onClick(props);
            }
          : void 0
      }
      style={style}
      textStyle={textStyle}
      {...boxStyle}
      render={render}
    />
  );
};
