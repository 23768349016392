import React from "react";
import { TrashCard } from "./TrashCards";
import { TrashNoData } from "../noData/NoData";
import { I18N, theme } from "../../../FsCloudComponent";
import { trashItemsQuery } from "../queries";
import ResourceActions from "../resource/ResourceActions";

export default {
  TrashCan: {
    component: "table",
    title: () => I18N.t("trashCan"),
    dataObject: "Resource",
    renderNoData: <TrashNoData />,
    ...trashItemsQuery,
    listProps: {
      addMinHeightSM: 56,
      addMinHeightMD: false,
      list: "scrollIndicatorList"
    },
    showHeaderMD: true,
    headerRowPropsMD: {
      theme: {
        ...theme.tableHeaderRowStyleMD,
        viewStyle: {
          ...theme.tableHeaderRowStyleMD.viewStyle,
          marginLeft: 95,
          marginRight: 95,
          marginTop: 14
        }
      }
    },
    bodyPropsMD: {
      bodyStyle: {
        flex: 1,
        paddingLeft: 81,
        paddingRight: 80
      }
    },
    rowProps: {
      ...TrashCard
    },
    headerActionsSM: [
      {
        type: "multiActions",
        visible: ({ data }) => data && data.length > 0,
        action: [
          "selectItems",
          "selectAll",
          {
            ...ResourceActions.clearTrash,
            visible: (data, { selectedIds = [] }) => !selectedIds.length
          },
          "selectNone",
          "restoreTrashSM",
          "deleteTrashSM"
        ]
      }
    ],
    headerActionsMD: [
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        action: "restoreTrash"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds }) => selectedIds && selectedIds.length > 0,
        action: "deleteTrash"
      }
    ]
  }
};
