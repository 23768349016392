import AvatarComponentsHoc from "./avatar.js";
import CustomComponentsHoc from "./customComponents.js";
import ActionComponentsHoc from "./actionComponents";
import EditorComponentsHoc from "./editorComponents";
import TabComponentsHoc from "./tabComponents";
import TableComponentsHoc from "./tableComponents";
import LoginComponentsHoc from "./login";

export default (components, otherProps) => {
  const {
    mergeActions,
    mergeFormEditors,
    mergeCardComponents,
    mergeListEditors,
    mergeInputComponents,
    mergeComponents
  } = components;

  const AvatarComponents = AvatarComponentsHoc(components);
  const CustomComponents = CustomComponentsHoc(components, otherProps);
  const ActionComponents = ActionComponentsHoc(components, otherProps);
  const EditorComponents = EditorComponentsHoc(components);
  const TabComponents = TabComponentsHoc({
    ...components,
    fromNowDateFormatter: CustomComponents.fromNowDateFormatter
  });
  const LoginComponents = LoginComponentsHoc(components, otherProps);
  const TableComponents = TableComponentsHoc(components);

  mergeActions({
    navTitle: components.NavTitle,
    panelTitle: components.PanelTitle,
    menu: components.NavDrawerMenu,
    tabTitle: TabComponents.TabTitle,
    navLogo: CustomComponents.NavLogo,
    webAppUpload: CustomComponents.WebAppUpload,
    languageSelector: CustomComponents.LanguageSelector,
    sortingLastModifiedAction: ActionComponents.SortingLastModifiedAction,
    sortingSizeAction: ActionComponents.SortingSizeAction,
    playMusicAction: CustomComponents.PlayMusicAction,
    sortingCreatedOnAction: ActionComponents.SortingCreatedOnAction,
    collectionDetailHeaderAction: ActionComponents.CollectionDetailHeaderAction,
    searchLink: CustomComponents.SearchLink,
    searchInput: CustomComponents.SearchInput,
    anchorAction: ActionComponents.AnchorAction,
    edit: ActionComponents.Edit,
    nonEditMode: ActionComponents.NonEditMode,
    clearCache: ActionComponents.ClearCache,
    cancelItem: ActionComponents.CancelItem,
    cancelAllItems: ActionComponents.CancelAllItems,
    cancelQueuedItem: ActionComponents.CancelQueuedItem,
    saveAsPdf: ActionComponents.SaveAsPdf,
    openCameraAndUpload: ActionComponents.OpenCameraAndUpload
  });

  mergeInputComponents({
    sFile: EditorComponents.SimpleFileInput,
    text: EditorComponents.TextInput,
    otpInput: EditorComponents.OtpInput,
    textArea: EditorComponents.TextAreaInput,
    number: EditorComponents.NumberInput,
    password: EditorComponents.PasswordInput,
    boolean: EditorComponents.Checked,
    file: EditorComponents.FileInput,
    image: EditorComponents.ImageInput
  });

  mergeListEditors({
    text: EditorComponents.TextBox,
    longText: EditorComponents.TextAreaBox,
    number: EditorComponents.NumberBox,
    decimal: EditorComponents.NumberBox,
    boolean: EditorComponents.CheckBox,
    file: EditorComponents.FileBox,
    link: EditorComponents.LinkBox,
    image: EditorComponents.ImageBox,
    deleteAction: ActionComponents.DeleteAction,
    phoneNumber: EditorComponents.PhoneNumberBox
  });

  mergeFormEditors({
    text: EditorComponents.TextBox,
    multiText: EditorComponents.MultiTextBox,
    longText: EditorComponents.TextAreaBox,
    number: EditorComponents.NumberBox,
    decimal: EditorComponents.NumberBox,
    boolean: EditorComponents.CheckBox,
    password: EditorComponents.PasswordBox,
    file: EditorComponents.FileBox,
    link: EditorComponents.LinkBox,
    image: EditorComponents.ImageBox,
    phoneNumber: EditorComponents.PhoneNumberBox,
    otpBox: LoginComponents.OtpBox,
    reCaptcha: LoginComponents.ReCaptcha
  });

  mergeCardComponents({
    avatarBox: AvatarComponents.AvatarBox,
    imageViewAR: EditorComponents.ImageViewAR,
    link: EditorComponents.LinkBox,
    imageDoc: CustomComponents.DocsImageComponent,
    audioTitle: CustomComponents.AudioTitleComponent,
    musicImage: CustomComponents.MusicImageComponent
  });

  mergeComponents({
    tab: TabComponents.TabPanel,
    tabBar: TabComponents.TabBar,
    bottomTab: TabComponents.BottomTabPanel,
    leftTab: TabComponents.LeftTabPanel,
    profileTab: TabComponents.ProfileTabPanel,
    contactTab: TabComponents.ContactTabPanel,
    masonryList: CustomComponents.MasonryList,
    recyclerList: CustomComponents.RecyclerList,
    scrollIndicatorList: CustomComponents.ScrollIndicatorList,
    webMasonaryList: CustomComponents.WebMasonaryList,
    virtualMasonaryList: CustomComponents.VirtualMasonaryList,
    imageViewerHeaderMenuItem: CustomComponents.ImageViewerHeaderMenuItem,
    listLayoutSelector: CustomComponents.ListLayoutSelector,
    addItemsToCollection: CustomComponents.AddItemsToCollection,
    addGroupMember: CustomComponents.AddGroupMember,
    collectionItemSelector: CustomComponents.CollectionItemSelector,
    albumCoverSelector: CustomComponents.AlbumCoverSelector,
    renameCollection: CustomComponents.RenameCollection,
    savePDFName: CustomComponents.SavePDFName,
    saveGIFName: CustomComponents.SaveGIFName,
    addCollectionNameMobile: CustomComponents.AddCollectionNameMobile,
    createCollectionName: CustomComponents.CreateCollectionName,
    resourceInfo: CustomComponents.ResourceInfoComponent,
    resourceInfoWeb: CustomComponents.WebResourceInfoComponent,
    webImageInfoComponent: CustomComponents.WebImageInfoComponent,
    anchor: EditorComponents.LinkBox,
    documentViewer: EditorComponents.DocumentViewer,
    videoPlayer: CustomComponents.VideoPlayer,
    tabScrollAnimation: TabComponents.TabScrollAnimation,
    sortableHeader: TableComponents.SortableHeaderColumn,
    tabBorder: TabComponents.TabBorder,
    profileTabBorder: TabComponents.ProfileTabBorder,
    contactTabBorder: TabComponents.ContactTabBorder,
    table: TableComponents.Table,
    multiText: EditorComponents.MultiTextBox,
    fileMultiWeb: EditorComponents.IssuesMultiImageViewer,
    multipleText: CustomComponents.MultipleText,
    decryptedVideo: CustomComponents.DecryptedVideo
  });

  return {
    ...AvatarComponents,
    ...CustomComponents,
    ...ActionComponents,
    ...EditorComponents,
    ...TabComponents,
    ...LoginComponents,
    ...TableComponents
  };
};
