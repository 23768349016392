import React, { Component } from "react";
import { Box, theme, withContext, getAction, I18N } from "../../../FsCloudComponent";
import ResourceActions, { RESOURCE_SHARE_TYPE_ENUM } from "../resource/ResourceActions";

const { fonts, colors } = theme;
const { themeColor, primaryColor } = colors;
const { h16, h16_l } = fonts;

const RESOURCE_EXTENSION_ENUM = {
  image: "jpeg",
  video: "mp4"
};

const getTotalResourceSize = (data = []) => {
  let totalOriginalSizeInByte = 0;
  let totalConvertedSizeInByte = 0;
  data &&
    data.forEach(item => {
      let { resource_size, type } = item;
      if (resource_size) {
        totalOriginalSizeInByte += resource_size;
      }
      if (type) {
        let covertedFieldName = `${RESOURCE_SHARE_TYPE_ENUM.CONVERTED}_${RESOURCE_EXTENSION_ENUM[type]}_size`;
        if (item[covertedFieldName]) {
          totalConvertedSizeInByte += item[covertedFieldName];
        }
      }
    });
  return {
    totalConvertedSizeInByte,
    totalOriginalSizeInByte
  };
};

class ResourceShareOptionsPopup extends Component {
  componentDidMount() {
    let { getSelectedData, data } = this.props;
    let resourceData = (getSelectedData && getSelectedData()) || [];
    if (!resourceData.length) {
      if (data && data._id) {
        resourceData = [data];
      }
    }
    let { totalConvertedSizeInByte, totalOriginalSizeInByte } = getTotalResourceSize(resourceData);
    this.lowResolutionItemsSize = Math.round((totalConvertedSizeInByte / Math.pow(1024, 2)) * 100) / 100;
    this.originalItemsSize = Math.round((totalOriginalSizeInByte / Math.pow(1024, 2)) * 100) / 100;
  }

  getActionComponent = ({ text, action }) => {
    let { onClose, data } = this.props;
    return {
      viewStyle: { padding: 15 },
      textStyle: { ...h16_l, color: primaryColor },
      text: text,
      Container: getAction("shareMedia"),
      containerProps: {
        ...this.props,
        autoClick: false,
        action: { ...action, visible: true },
        data,
        onClick: () => onClose && onClose()
      }
    };
  };

  render() {
    const { action: { origin } = {} } = this.props;
    return (
      <Box
        render={[
          {
            text: I18N.t("share"),
            style: { padding: 15, borderBottomWidth: 1, borderBottomColor: themeColor },
            textStyle: { ...h16, color: primaryColor }
          },
          {
            render: [
              origin !== "doc" &&
                this.getActionComponent({
                  text: () => {
                    let message = I18N.t("lowResolution");
                    message = message.replace("__size__", `${this.lowResolutionItemsSize} MB`);
                    return message;
                  },
                  action: ResourceActions.nativeShareConverted
                }),
              this.getActionComponent({
                text: () => {
                  let message = I18N.t("originalItems");
                  message = message.replace("__size__", `${this.originalItemsSize} MB`);
                  return message;
                },
                action: origin === "doc" ? ResourceActions.nativeShareDoc : ResourceActions.nativeShareGallery
              }),
              this.getActionComponent({
                text: () => I18N.t("linkToItems"),
                action: { ...ResourceActions.nativeShareLink, origin }
              })
            ]
          }
        ]}
      />
    );
  }
}

export default withContext(ResourceShareOptionsPopup, { getSelectedData: "SelectionStore.getSelectedData" });
