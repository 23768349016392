import React from "react";
export default ({ theme: { boxStyle = {}, containerStyle = {} } = {} }) => {
  const show = (message, ms) => {
    if (!message) {
      return;
    }
    const node = document.createElement("div");
    for (let index in boxStyle) {
      node.style[index] = boxStyle[index];
    }
    const containerNode = document.createElement("div");
    for (let index in containerStyle) {
      containerNode.style[index] = containerStyle[index];
    }
    ms = ms || 10 * 1000;
    containerNode.innerHTML = `${message}`;
    node.appendChild(containerNode);
    document.getElementById("root").appendChild(node);
    setTimeout(() => {
      document.getElementById("root").removeChild(node);
    }, ms);
  };

  const showError = (e, ms) => {
    show(e.message, ms);
  };

  class Toast extends React.Component {
    render() {
      return null;
    }
  }

  return {
    show,
    showError,
    Toast
  };
};
