import React from "react";

export default ({ Box, layoutOptions, ScrollView, theme }) => {
  class ListLayoutSelector extends React.Component {
    getLayoutOptionComponent = ({ layoutStyle, props }) => {
      const { componentState, onClose, setComponentState } = props;
      let columnsPerRow = componentState && componentState.columnsPerRow;
      if (columnsPerRow !== undefined) {
        columnsPerRow = columnsPerRow + "";
      }
      let layoutKeys = layoutOptions ? Object.keys(layoutOptions) : [];
      return (
        <Box
          render={[
            ...layoutKeys.map(layoutKey => {
              let isSelected = layoutKey === columnsPerRow;
              let render = [];
              render.push({ ...((isSelected && layoutStyle.selectedIconStyle) || layoutStyle.iconStyle) });
              render.push({ ...layoutStyle.titleStyle, text: layoutOptions[layoutKey] });
              return {
                ...layoutStyle.itemContainerStyle,
                onClick: isSelected
                  ? void 0
                  : () => {
                      setComponentState &&
                        setTimeout(() => {
                          setComponentState({
                            columnsPerRow: Number(layoutKey)
                          });
                        }, 0);
                      onClose && onClose();
                    },
                render
              };
            })
          ]}
        />
      );
    };

    render() {
      let component = (
        <Box
          {...theme.containerStyle}
          render={[
            theme.headerStyle && { ...theme.headerStyle },
            theme.bodyStyle && {
              ...theme.bodyStyle.containerStyle,
              render: this.getLayoutOptionComponent({ layoutStyle: theme.bodyStyle, props: this.props })
            }
          ]}
        />
      );
      if (ScrollView) {
        component = <ScrollView>{component}</ScrollView>;
      }
      return component;
    }
  }

  return ListLayoutSelector;
};
