import React from "react";
import { Box, getImage, I18N, ScrollView, theme, getContactPicUrl, getComponent } from "../../../../FsCloudComponent";
import { folkColumnsSM, jobColumnsSM, otherColumnsSM } from "./ContactColumns";
import { contactDetailQuery } from "../../queries";
const { fonts, colors } = theme;
const { themeColor, primaryColor, whiteTwo, brownGrey } = colors;

const { h16, h16_l, h3 } = fonts;
class ContactInfo extends React.Component {
  getFormGroupRender = ({ title, columns, data }) => {
    let columnCount = columns ? columns.length : 0;
    if (!columnCount) {
      return;
    }
    let formGroupRender = [];
    for (let i = 0; i < columnCount; i++) {
      let { field, label, isEmail, isWebsite } = columns[i];
      let fieldValue = data[field];
      if (fieldValue) {
        let valueComponent = {
          viewStyle: { flex: 1 },
          textStyle: { color: brownGrey, ...h3, numberOfLines: 1 },
          text: fieldValue
        };
        if (isWebsite || isEmail) {
          const LinkBox = getComponent("anchor");
          let url = `${isWebsite ? "http://" : "mailto:"}${fieldValue}`;
          let openInNewTab = isWebsite ? true : false;
          valueComponent = {
            viewStyle: { flex: 1, cursor: "pointer" },
            render: (
              <LinkBox url={url} openInNewTab={openInNewTab}>
                <Box textStyle={{ color: themeColor, ...h3, numberOfLines: 1 }} text={fieldValue} />
              </LinkBox>
            )
          };
        }
        formGroupRender.push({
          direction: "row",
          viewStyle: { paddingTop: 4, paddingBottom: 4 },
          render: [
            {
              viewStyle: { width: 115, paddingRight: 12 },
              textStyle: { color: primaryColor, ...h3, numberOfLines: 1 },
              text: I18N.t(label)
            },
            valueComponent
          ]
        });
      }
    }
    if (formGroupRender.length) {
      formGroupRender.unshift({
        viewStyle: { paddingTop: 20, paddingBottom: 12 },
        textStyle: { color: themeColor, ...h16_l },
        text: I18N.t(title)
      });
      return formGroupRender;
    }
  };

  render() {
    let { data }  = this.props;
    data = data && data.length ? data[0] : {};
    let { name, office, photo_id, department, company } = data;
    return (
      <ScrollView
        style={{
          flex: 1,
          marginBottom: 16
        }}
      >
        <Box
          viewStyle={{
            paddingTop: 16,
            paddingLeft: 16,
            paddingRight: 16
          }}
          render={[
            {
              direction: "row",
              viewStyle: { paddingBottom: 10 },
              render: [
                {
                  viewStyle: { paddingRight: 16 },
                  imageStyle: { width: 80, height: 80, borderRadius: 40, backgroundColor: whiteTwo },
                  image: photo_id ? { uri: getContactPicUrl(photo_id) } : getImage("profileImageIcon")
                },
                {
                  width: "1fr",
                  render: [
                    {
                      viewStyle: { paddingTop: 2, paddingBottom: 6 },
                      textStyle: { color: primaryColor, ...h16 },
                      text: name
                    },
                    {
                      itemStyle: { paddingBottom: 2 },
                      textStyle: { color: primaryColor, ...h3 },
                      render: [
                        office && {
                          text: office
                        },
                        department && {
                          text: department
                        },
                        company && {
                          text: company
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            this.getFormGroupRender({
              title: "folks",
              columns: folkColumnsSM,
              data
            }),
            this.getFormGroupRender({
              title: "job",
              columns: jobColumnsSM,
              data
            }),
            this.getFormGroupRender({
              title: "other",
              columns: otherColumnsSM,
              data
            })
          ]}
        />
      </ScrollView>
    );
  }
}

export default {
  ContactDetail: {
    component: "custom",
    dataObject: "Contact",
    title: ({ data }) => (data && data.length && data[0].name) || "",
    Component: ContactInfo,
    ...contactDetailQuery(),
    headerActions: [
      {
        type: "headerAction",
        action: "editContactLink"
      },
      {
        type: "headerAction",
        action: "deleteContactDetail"
      }
    ]
  }
};
