const addListeners = ({ resize, backPress }) => {
  if (resize) {
    window.addEventListener("resize", resize);
  }
  if (backPress) {
    window.addEventListener("popstate", backPress);
  }
};

const removeListeners = ({ resize }) => {
  if (resize) {
    window.removeEventListener("resize", resize);
  }
};

export { addListeners, removeListeners };
