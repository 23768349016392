import DetailCollectionComponent from "../collection/DetailCollection";
import { albumDetailQuery, placeDetailQuery, vaultAlbumDetailQuery, deviceFolderDetailQuery } from "../queries";

const commonProps = {
  component: "table",
  dataObject: "Resource",
  Component: DetailCollectionComponent,
  panelProps: {
    showHeader: false
  },
  listProps: {
    imageField: "thumbnail_url",
    imageHeight: "thumbnail_height",
    imageWidth: "thumbnail_width",
    indicator_field: "resource_lastModified",
    addMinHeight: 56,
    rowProps: {
      imageField: "thumbnail_url",
      fields: {
        name: "thumbnail_name",
        resource_name: "resource_name",
        size: "thumbnail_size",
        resource_size: "resource_size",
        height: "thumbnail_height",
        width: "thumbnail_width",
        lastModified: "resource_lastModified"
      },
      imagePrefix: "thumbnail"
    }
  },
  hideNavHeader: true
};
export default {
  AlbumDetail: {
    ...commonProps,
    listProps: {
      ...commonProps.listProps,
      detailLink: ({ data = {}, link = {} }) => {
        let { props: { params: { collection = {} } = {} } = {} } = link;
        let { name, colloborator_mode, mode, owner } = collection;
        return {
          uri: `/album-gallery-detail`,
          decryptionSourceProps: { decryptionSource: "collection" },
          detailId: data._id,
          props: {
            params: {
              collection: { ...data.collection, name, colloborator_mode, mode, owner }
            }
          },
          origin: "album"
        };
      }
    },
    ...albumDetailQuery
  },
  PlacesDetail: {
    ...commonProps,
    listProps: {
      ...commonProps.listProps,
      detailLink: ({ data = {} }) => {
        return {
          uri: `/place-gallery-detail`,
          detailId: data._id,
          origin: "places"
        };
      }
    },
    ...placeDetailQuery
  },
  DeviceFolderDetail: {
    ...commonProps,
    listProps: {
      ...commonProps.listProps,
      detailLink: ({ data = {} }) => {
        return {
          uri: `/device-folder-gallery-detail`,
          detailId: data._id,
          origin: "deviceFolder"
        };
      }
    },
    ...deviceFolderDetailQuery
  },
  VaultAlbumDetail: {
    ...commonProps,
    listProps: {
      ...commonProps.listProps,
      detailLink: ({ data = {} }) => {
        return {
          uri: `/album-gallery-detail`,
          decryptionSourceProps: { decryptionSource: "collection" },
          detailId: data._id,
          origin: "vaultAlbum"
        };
      }
    },
    ...vaultAlbumDetailQuery
  }
};
