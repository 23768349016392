import React from "react";

export default ({ getComponent }) => {
  class AnchorAction extends React.Component {
    render() {
      let { children, action, onClick } = this.props;
      let { url } = action;
      if (!url) {
        return null;
      }
      const LinkBox = getComponent("anchor");
      return (
        <LinkBox style={{ textDecoration: "none" }} target="_self" url={url} onClick={onClick}>
          {children}
        </LinkBox>
      );
    }
  }
  return AnchorAction;
};
