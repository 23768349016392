export default {
  "/search-gallery": {
    screenSM: "SearchGalleryAll",
    screenMD: "SearchGalleryAllWeb"
  },
  "/search-vault-gallery": {
    screenSM: "SearchVaultGallery",
    screenMD: "SearchVaultGalleryWeb"
  },
  "/search-archive-gallery": {
    screenSM: "SearchArchiveGallery",
    screenMD: "SearchArchiveGalleryWeb"
  },
  "/search-gallery-type": {
    screenSM: "SearchGalleryAllWithType",
    screenMD: "SearchGalleryAllWithTypeWeb"
  },
  "/search-vault-gallery-type": {
    screenSM: "SearchVaultGalleryWithType",
    screenMD: "SearchVaultGalleryWithTypeWeb"
  },
  "/search-archive-gallery-type": {
    screenSM: "SearchArchiveGalleryWithType",
    screenMD: "SearchArchiveGalleryWithTypeWeb"
  },
  "/search-docs": {
    screen: "SearchDocsAll"
  },
  "/search-vault-docs": {
    screen: "SearchVaultDocs"
  },
  "/search-vault-docs-type": {
    screen: "SearchVaultDocsType"
  },
  "/search-archive-docs": {
    screen: "SearchArchiveDocs"
  },
  "/search-archive-docs-type": {
    screen: "SearchArchiveDocsType"
  },
  "/search-docs-type": {
    screen: "SearchDocsType"
  },
  "/search-music": {
    screen: "SearchMusicAll"
  },
  "/search-music-type": {
    screen: "SearchMusicType"
  },
  "/search-archive-music": {
    screen: "SearchArchiveMusicAll"
  },
  "/search-archive-music-type": {
    screen: "SearchArchiveMusicType"
  },
  "/search-group-gallery": {
    screenSM: "SearchGroupGallery",
    screenMD: "SearchGroupGalleryWeb"
  },
  "/search-group-docs": {
    screen: "SearchGroupDocs"
  }
};
