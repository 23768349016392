import React from "react";
import { DocsCard } from "./DocsCards";
import { SelectDocsNoData } from "../noData/NoData";
import { selectDocsQuery, selectVaultDocsQuery } from "../queries";
import { I18N } from "../../../FsCloudComponent";

export default {
  SelectDocs: {
    component: "table",
    listProps: {
      list: "scrollIndicatorList",
      indicator_field: "resource_lastModified" //indicator field to show on scroll
    },
    dataObject: "Resource",
    ...selectDocsQuery,
    selectionMode: true,
    renderNoData: <SelectDocsNoData />,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "set" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("addCaps"),
        action: "selectCollectionAddItems"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "groupDoc" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("postCaps"),
        action: "selectPostToGroupItems"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "vaultDocs" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("moveCaps"),
        action: "selectMoveToSafe"
      },
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "archiveDocs" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("moveCaps"),
        action: "selectMoveToArchive"
      },
      {
        type: "multiActions",
        visible: ({ data, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin !== "groupDoc" && data && data.length > 0;
        },
        action: ["selectNone", "selectAll"]
      }
    ],
    rowProps: {
      ...DocsCard
    }
  },
  SelectVaultDocs: {
    component: "table",
    listProps: {
      list: "scrollIndicatorList",
      indicator_field: "resource_lastModified" //indicator field to show on scroll
    },
    dataObject: "Resource",
    ...selectVaultDocsQuery,
    selectionMode: true,
    renderNoData: <SelectDocsNoData />,
    headerActions: [
      {
        type: "headerAction",
        visible: ({ selectedIds, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin === "vaultSet" && selectedIds && selectedIds.length > 0;
        },
        text: () => I18N.t("addCaps"),
        action: "selectCollectionAddItems"
      },
      {
        type: "multiActions",
        visible: ({ data, link }) => {
          let origin = link && link.props && link.props.params && link.props.params.origin;
          return origin !== "groupDoc" && data && data.length > 0;
        },
        action: ["selectNone", "selectAll"]
      }
    ],
    rowProps: {
      ...DocsCard
    }
  }
};
