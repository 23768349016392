import React from "react";
import ProgressIndicatorHoc from "progress-indicator";
const StatusConstant = {
  REQUESTED: "requested",
  RUNNING: "running",
  PAUSED: "paused",
  CANCEL: "cancel",
  COMPLETED: "completed",
  THUMBNAIL: "thumbnailGeneration",
  FAILED: "failed",
  SCANNING: "scanning"
};

const HEADER_HEIGHT = 64;

export default ({ Box, View, shallowCompare, Modal, resolveMQ, getImage, theme, getSizeFormat, I18N }) => {
  const ProgressIndicator = ProgressIndicatorHoc({ Box, getImage, View, theme });

  const { colors, fonts, bgs } = theme;
  const { themeBg, lightPink } = bgs;
  const { highlightColor, bluish, brownGrey, primaryColor, tomato } = colors;
  const { h16_l, h3MD, h5, h3 } = fonts;

  let modalTheme = {
    modalStyleSM: {
      position: "absolute",
      height: 300,
      noBackdrop: true,
      pointerEvents: "auto",
      escEnabled: false,
      style: {
        bottom: 60,
        right: 8,
        left: 8,
        borderWidth: 3,
        borderRadius: 4,
        overflow: "hidden",
        transitionDuration: "1s",
        transitionTimingFunction: "ease",
        transitionDelay: "0s"
      }
    },
    modalStyleMD: {
      position: "absolute",
      height: 300,
      width: 425,
      noBackdrop: true,
      escEnabled: false,
      pointerEvents: "auto",
      style: {
        bottom: 30,
        right: 30,
        borderWidth: 3,
        borderRadius: 4,
        overflow: "hidden",
        transitionDuration: "1s",
        transitionTimingFunction: "ease",
        transitionDelay: "0s"
      }
    }
  };

  class UploadRow extends React.Component {
    shouldComponentUpdate(nextProps) {
      let value = nextProps.value;
      let prevValue = this.value;
      if (value.status !== prevValue.status || value.progress !== prevValue.progress) {
        return true;
      }
      let isEqual = shallowCompare(nextProps, this.props, {
        matchProps: { language: 1, orientation: 1, screenWidth: 1, secureType: 1 }
      });
      return !isEqual;
    }

    render() {
      let { onOpen, onCancel, onResume, value, index, indicatorRef, scrollComponent } = this.props;
      let { file, progress, status, size: prevSize } = value;
      this.value = {
        status,
        progress
      };
      let thumbnailMessage = "generatingThumbnailMessage";
      if (value.type && value.type === "audio") {
        thumbnailMessage = "extractingDetailMessage";
      }
      if (status === StatusConstant.RUNNING) {
        scrollComponent(index);
      }
      let { name = "ImageName", size } = file;
      if (status === StatusConstant.RUNNING || status === StatusConstant.COMPLETED) {
        size = prevSize;
      }
      size = size > 0 ? getSizeFormat && getSizeFormat(size) : `0 B`;
      let showOpen = this.props.secureType === value.secureType;
      return (
        <Box
          viewStyle={{ marginLeft: 12, marginRight: 12, borderBottomColor: lightPink, borderBottomWidth: 1 }}
          getRef={ref => (indicatorRef[index] = ref)}
          render={[
            {
              direction: "row",
              viewStyle: {
                paddingTop: 12,
                paddingBottom: 12,
                alignItems: "center"
              },
              render: [
                {
                  viewStyle: {
                    paddingLeft: 12,
                    paddingRight: 6,
                    alignItems: "center",
                    justifyContent: "center"
                  },
                  imageStyle: { height: 24, width: 24 },
                  image: getImage("uploadCoverIcon")
                },
                {
                  width: "1fr",
                  viewStyle: {
                    paddingLeft: 6,
                    paddingRight: 8
                  },
                  render: [
                    {
                      textStyle: { numberOfLines: 1, ...h3MD, color: primaryColor },
                      text: name.toUpperCase()
                    },
                    (status === StatusConstant.FAILED ||
                      status === StatusConstant.COMPLETED ||
                      status === StatusConstant.THUMBNAIL ||
                      status === StatusConstant.SCANNING ||
                      status === StatusConstant.CANCEL) && {
                      viewStyle: { paddingTop: 6 },
                      textStyle: { numberOfLines: 1, ...h5, color: brownGrey },
                      text:
                        (status === StatusConstant.COMPLETED && I18N.t("successFullyUploaded")) ||
                        (status === StatusConstant.SCANNING && I18N.t("scanningMessage")) ||
                        (status === StatusConstant.THUMBNAIL && I18N.t(thumbnailMessage)) ||
                        I18N.t(value.errorCode) ||
                        value.errorMessage ||
                        I18N.t("uploadingFailed")
                    },
                    status === StatusConstant.RUNNING && {
                      viewStyle: { paddingTop: 6 },
                      textStyle: { numberOfLines: 1, ...h5, color: brownGrey },
                      text: `${I18N.t("uploading")}...`
                    }
                  ]
                },
                {
                  viewStyle: { width: 75, paddingLeft: 8, paddingRight: 8, justifyContent: "center" },
                  textStyle: { numberOfLines: 1, ...h3, color: brownGrey },
                  text: size
                },
                status !== StatusConstant.COMPLETED &&
                  status !== StatusConstant.FAILED &&
                  status !== StatusConstant.CANCEL && {
                    viewStyle: { width: 84 },
                    direction: "row",
                    render:
                      status === StatusConstant.REQUESTED || status === StatusConstant.RUNNING
                        ? [
                            {
                              viewStyle: {
                                width: 40,
                                height: 24,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingRight: 8,
                                paddingLeft: 8
                              },
                              render: <ProgressIndicator w={progress} size={size} />
                            },
                            {
                              viewStyle: {
                                width: 44,
                                height: 24,
                                paddingLeft: 8,
                                paddingRight: 12,
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer"
                              },
                              onClick: () => onCancel && onCancel({ index }),
                              image: getImage("infoCrossWebIcon")
                            }
                          ]
                        : [
                            { width: 40 },
                            {
                              viewStyle: {
                                width: 44,
                                height: 24,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingRight: 12,
                                paddingLeft: 8
                              },
                              render: <ProgressIndicator w={progress} size={size} />
                            }
                          ]
                  },
                status === StatusConstant.CANCEL && {
                  viewStyle: { paddingLeft: 8, paddingRight: 12, width: 84, cursor: "pointer" },
                  textStyle: { ...h3MD, color: bluish, textAlign: "right" },
                  onClick: () => onResume && onResume({ index }),
                  text: I18N.t("tryAgain")
                },
                status === StatusConstant.FAILED && {
                  viewStyle: { paddingLeft: 8, paddingRight: 12, width: 84, cursor: "pointer" },
                  textStyle: { ...h3MD, color: tomato, textAlign: "right" },
                  text: I18N.t("error")
                },
                status === StatusConstant.COMPLETED &&
                  showOpen && {
                    viewStyle: { paddingLeft: 8, paddingRight: 12, width: 84, cursor: "pointer" },
                    textStyle: { ...h3MD, color: bluish, textAlign: "right" },
                    text: I18N.t("open"),
                    onClick: () => {
                      onOpen && onOpen({ value });
                    }
                  }
              ]
            }
          ]}
        />
      );
    }
  }

  class UploadDialogBox extends React.Component {
    state = {
      w: 1,
      showModal: true,
      expanded: true
    };
    indicatorRef = [];
    scrollComponent = index => {
      if (!this.ref || !this.indicatorRef[index]) {
        return;
      }
      if (index > 0) {
        if (!this.indicatorRef[index - 1]) {
          return;
        }
        this.ref.scrollTop = index * this.indicatorRef[index - 1].getBoundingClientRect().height;
      } else {
        this.ref.scrollTop = index * this.indicatorRef[index].getBoundingClientRect().height;
      }
    };

    closeModal = () => {
      this.setState({
        showModal: false
      });
      this.props.onClose && this.props.onClose();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.newUpload) {
        if (!this.state.modal || !this.state.expanded) {
          this.setState({ showModal: true, expanded: true });
        }
      }
    }

    getUploadingInfo = () => {
      let { files } = this.props;
      let uploadingCount = 0;
      let completedCount = 0;
      let failedCount = 0;
      let cancelCount = 0;
      for (var i = 0; i < files.length; i++) {
        let status = files[i].status;
        if (status === StatusConstant.REQUESTED || status === StatusConstant.RUNNING) {
          uploadingCount += 1;
        } else if (status === StatusConstant.COMPLETED || status === StatusConstant.THUMBNAIL) {
          completedCount += 1;
        } else if (status === StatusConstant.FAILED) {
          failedCount += 1;
        } else if (status === StatusConstant.CANCEL) {
          cancelCount += 1;
        }
      }
      return {
        uploadingCount,
        completedCount,
        failedCount,
        cancelCount
      };
    };

    open = ({ value }) => {
      const { addUri, getPath } = this.props;

      let { resourceId, type, secureType } = value;
      let isVaultView = secureType === "vault";
      const mappings = {
        audio: {
          uri: "/music-detail",
          origin: "music"
        },
        video: {
          uri: isVaultView ? "/vault-gallery-detail" : "/gallery-detail",
          origin: "gallery"
        },
        doc: {
          uri: isVaultView ? "/vault-doc-detail" : "/doc-detail",
          origin: "doc"
        },
        image: {
          uri: isVaultView ? "/vault-gallery-detail" : "/gallery-detail",
          origin: "gallery"
        }
      };

      let { uri, origin } = mappings[type];

      let detailLink = { uri, detailId: resourceId, origin, fromUpload: true };
      this.setState({ expanded: !this.state.expanded });
      addUri(getPath(), detailLink);
    };

    render() {
      let { showModal, expanded } = this.state;
      let { files = [], activeMQ, onCancelAll, onResumeAll, componentsCount, getSecureType } = this.props;

      if (!files.length || !showModal) {
        return null;
      }
      let secureType = getSecureType && getSecureType();
      let showComponent = componentsCount === 1 || (componentsCount === 2 && secureType === "vault");
      if (!showComponent) {
        return null;
      }
      let { modalStyle } = resolveMQ(modalTheme, ["modalStyle"], activeMQ);
      let { uploadingCount, completedCount, failedCount, cancelCount } = this.getUploadingInfo();

      let uploadingText = uploadingCount
        ? `${I18N.t("uploading")} ${completedCount + 1} ${I18N.t("of")} ${uploadingCount +
            completedCount +
            failedCount} ${I18N.t("items")}`
        : `${completedCount} ${I18N.t("itemsUploaded")}`;
      if (failedCount) {
        uploadingText += `, ${failedCount} ${I18N.t("failed")}`;
      }

      let headerComponent = {
        direction: "row",
        viewStyle: {
          height: HEADER_HEIGHT,
          backgroundColor: themeBg,
          paddingLeft: 12,
          paddingRight: 12,
          alignItems: "center",
          justifyContent: "center"
        },
        render: [
          {
            width: "1fr",
            viewStyle: {
              marginRight: 12,
              marginLeft: 12
            },
            textStyle: {
              ...h16_l,
              color: highlightColor
            },
            text: uploadingText
          },
          {
            viewStyle: {
              width: 48,
              height: 48,
              paddingLeft: 12,
              paddingRight: 12,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            },
            image: expanded ? getImage("toggleDownIcon") : getImage("toggleUpIcon"),
            onClick: () => {
              this.setState({ expanded: !this.state.expanded });
            }
          },
          !uploadingCount && {
            viewStyle: {
              width: 48,
              height: 48,
              paddingLeft: 12,
              paddingRight: 12,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            },
            image: getImage("crossWhiteIcon"),
            onClick: this.closeModal
          }
        ]
      };

      const bodyComponent = {
        render: [
          (uploadingCount || cancelCount) && {
            direction: "row",
            viewStyle: {
              borderBottomColor: lightPink,
              borderBottomWidth: 1,
              paddingTop: 12,
              paddingBottom: 12,
              marginLeft: 12,
              marginRight: 12,
              justifyContent: "flex-end"
            },
            render: [
              uploadingCount
                ? {
                    viewStyle: {
                      marginLeft: 12,
                      marginRight: 12,
                      cursor: "pointer"
                    },
                    textStyle: { ...h3MD, color: bluish },
                    onClick: () => onCancelAll && onCancelAll(),
                    text: I18N.t("cancelAll")
                  }
                : {
                    viewStyle: {
                      marginLeft: 12,
                      marginRight: 12,
                      cursor: "pointer"
                    },
                    textStyle: { ...h3MD, color: bluish },
                    onClick: () => onResumeAll && onResumeAll(),
                    text: I18N.t("resumeAll")
                  }
            ]
          },
          {
            viewStyle: { height: uploadingCount || cancelCount ? 190 : 236, overflow: "auto" },
            getRef: ref => {
              this.ref = ref;
            },
            render: files.map((row, index) => (
              <UploadRow
                onOpen={this.open}
                value={row}
                index={index}
                scrollComponent={this.scrollComponent}
                indicatorRef={this.indicatorRef}
                secureType={secureType}
                {...this.props}
              />
            ))
          }
        ]
      };
      return (
        <Modal
          {...{
            ...modalStyle,
            height: expanded ? modalStyle.height : HEADER_HEIGHT
          }}
          id="uploadDialogBox"
          isOpen={true}
          onClose={this.closeModal}
        >
          {<Box render={[headerComponent, bodyComponent]} />}
        </Modal>
      );
    }
  }
  return UploadDialogBox;
};
