export default ({ resolveImage }) => {
  const homeSprite = {
    uri: "/webImages/homeIcons/1_sprite.png",
    menuIcon: {
      height: 14,
      width: 18,
      objectPosition: "-74px -184px",
      objectFit: "none"
    },
    menuMDIcon: {
      height: 16,
      width: 22,
      objectPosition: "-42px -144px",
      objectFit: "none"
    },
    uploadIcon: {
      width: 20,
      height: 19,
      objectPosition: "-42px -85px",
      objectFit: "none"
    },
    languageSelectorDropDownIcon: {
      width: 11,
      height: 6,
      objectPosition: "-42px -37px",
      objectFit: "none"
    },
    languageSelectorIcon: {
      width: 26,
      height: 22,
      objectPosition: "-42px -53px",
      objectFit: "none"
    },
    searchIcon: {
      width: 17,
      height: 17,
      objectPosition: "-42px -10px",
      objectFit: "none"
    },
    homeHighlightGreyIcon: {
      width: 22,
      height: 22,
      objectPosition: "-10px -216px",
      objectFit: "none"
    },
    homeHighlightSelectedIcon: {
      width: 22,
      height: 22,
      objectPosition: "-10px -184px",
      objectFit: "none"
    },
    homeHighLightIcon: {
      width: 22,
      height: 22,
      objectPosition: "-74px -92px",
      objectFit: "none"
    },
    homeMenuHomeIcon: {
      width: 22,
      height: 22,
      objectPosition: "-74px -92px",
      objectFit: "none"
    },
    homeMenuHomeSelectedIcon: {
      width: 22,
      height: 22,
      objectPosition: "-74px -92px",
      objectFit: "none"
    },
    homeGalleryIcon: {
      width: 22,
      height: 20,
      objectPosition: "-74px -37px",
      objectFit: "none"
    },
    homeGalleryGreyIcon: {
      width: 22,
      height: 20,
      objectPosition: "-10px -104px",
      objectFit: "none"
    },
    homeGallerySelectedIcon: {
      width: 22,
      height: 20,
      objectPosition: "-10px -74px",
      objectFit: "none"
    },
    homeDocIcon: {
      width: 20,
      height: 20,
      objectPosition: "-74px -7px",
      objectFit: "none"
    },
    homeDocGreyIcon: {
      width: 22,
      height: 22,
      objectPosition: "-10px -42px",
      objectFit: "none"
    },
    homeDocSelectedIcon: {
      width: 22,
      height: 22,
      objectPosition: "-10px -10px",
      objectFit: "none"
    },
    homeMusicIcon: {
      width: 19,
      height: 20,
      objectPosition: "-74px -124px",
      objectFit: "none"
    },
    homeMusicGreyIcon: {
      width: 19,
      height: 20,
      objectPosition: "-42px -114px",
      objectFit: "none"
    },
    homeMusicSelectedIcon: {
      width: 19,
      height: 20,
      objectPosition: "-74px -154px",
      objectFit: "none"
    },
    homeGroupIcon: {
      width: 24,
      height: 15,
      objectPosition: "-74px -67px",
      objectFit: "none"
    },
    homeGroupGreyIcon: {
      width: 24,
      height: 15,
      objectPosition: "-10px -159px",
      objectFit: "none"
    },
    homeGroupSelectedIcon: {
      width: 24,
      height: 15,
      objectPosition: "-10px -134px",
      objectFit: "none"
    }
  };

  const menuIcon = () =>
    resolveImage({
      SM:{
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.menuIcon
        }
      },
      MD: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.menuMDIcon
        }
      }
    });
  const uploadIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.uploadIcon
    }
  };
  const languageSelectorDropdown = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.languageSelectorDropDownIcon
    }
  };
  const languageSelectorIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.languageSelectorIcon
    }
  };
  const searchIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.searchIcon
    }
  };
  const homeHighlightIcon = () =>
    resolveImage({
      SM: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeHighLightIcon
        }
      },
      MD: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeHighlightGreyIcon
        }
      }
    });
  const homeMenuHomeIcon = () =>
    resolveImage({
      SM: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeMenuHomeIcon
        }
      },
    });
  const homeMenuHomeSelectedIcon = () =>
    resolveImage({
      SM: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeMenuHomeSelectedIcon
        }
      },
    });
  const homeHighlightSelectedIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.homeHighlightSelectedIcon
    }
  };
  const homeGalleryIcon = () =>
    resolveImage({
      SM: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeGalleryIcon
        }
      },
      MD: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeGalleryGreyIcon
        }
      }
    });
  const homeGallerySelectedIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.homeGallerySelectedIcon
    }
  };
  const homeDocIcon = () =>
    resolveImage({
      SM: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeDocIcon
        }
      },
      MD: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeDocGreyIcon
        }
      }
    });
  const homeDocSelectedIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.homeDocSelectedIcon
    }
  };
  const homeMusicIcon = () =>
    resolveImage({
      SM: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeMusicIcon
        }
      },
      MD: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeMusicGreyIcon
        }
      }
    });
  const homeMusicSelectedIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.homeMusicSelectedIcon
    }
  };
  const homeGroupIcon = () =>
    resolveImage({
      SM: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeGroupIcon
        }
      },
      MD: {
        uri: homeSprite.uri,
        spriteProps: {
          ...homeSprite.homeGroupGreyIcon
        }
      }
    });
  const homeGroupSelectedIcon = {
    uri: homeSprite.uri,
    spriteProps: {
      ...homeSprite.homeGroupSelectedIcon
    }
  };

  return {
    menuIcon,
    uploadIcon,
    languageSelectorDropdown,
    languageSelectorIcon,
    searchIcon,
    homeHighlightIcon,
    homeHighlightSelectedIcon,
    homeGalleryIcon,
    homeGallerySelectedIcon,
    homeDocIcon,
    homeDocSelectedIcon,
    homeMusicIcon,
    homeMusicSelectedIcon,
    homeGroupIcon,
    homeGroupSelectedIcon,
    homeMenuHomeIcon,
    homeMenuHomeSelectedIcon
  };
};
