/**
 * showModal : prop - due to row action, updateSchedule button : need to show popup opened when clicked, there is single action in row action - so first level popup will not be shown - rohit bansal - 07-01-19
 *
 * onContextMenu: prop - passed to children to show modal action on cursor right click action, and "onContextMenu" is passed to children when "openModalOnRightClick" is passed. useCaseProject - fsCloud - rohit garg - 3-5-19
 *
 */
import React from "react";
export default ({
  Modal,
  ScrollView,
  renderChildren,
  Component,
  scrollViewStyle,
  resolveMQ,
  onContextMenuClick,
  ...restHOCProps
}) => {
  const mqHocProps = ["modal"];
  class ActionModal extends React.Component {
    state = { showModal: false };

    componentWillUnmount() {
      this._unmounted = true;
    }

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    closeModal = () => {
      const { onModalClose } = this.props;
      this._setState({ showModal: false, top: void 0, left: void 0, position: void 0 });
      onModalClose && onModalClose();
    };
    
    getModal = () => {
      let { renderModal, children, activeMQ, showModal, ...rest } = this.props;
      let { top, left, position } = this.state;
      showModal = showModal || this.state.showModal;
      let { modal: modalHoc } = resolveMQ ? resolveMQ(restHOCProps, mqHocProps, activeMQ) : restHOCProps;
      let { modal: modalProps } = resolveMQ ? resolveMQ(rest, mqHocProps, activeMQ) : rest;
      let modal = modalProps || modalHoc;
      if (!(modal && modal.animate) && !showModal) {
        return;
      }
      let render = void 0;
      let renderProps = {
        closeModal: this.closeModal,
        ...rest
      };
      if (renderModal) {
        render = renderChildren(renderModal, renderProps);
      } else {
        render = <Component {...renderProps} />;
      }
      if (ScrollView) {
        render = <ScrollView style={scrollViewStyle}>{render}</ScrollView>;
      }
      let modalPropsToPass = { ...modal };
      if (position) {
        modalPropsToPass = { ...modalProps, top, left, position };
      }
      return (
        <Modal parentRef={this.ref} {...modalPropsToPass} isOpen={showModal} onClose={this.closeModal}>
          {render}
        </Modal>
      );
    };
    onClick = e => {
      e && e.stopPropagation && e.stopPropagation();
      const { onModalOpen } = this.props;
      this._setState({ showModal: true });
      onModalOpen && onModalOpen();
    };
    onContextMenu = e => {
      e.preventDefault();
      const { onModalOpen } = this.props;
      onContextMenuClick && onContextMenuClick(this.props);
      this._setState({ showModal: true, top: e.clientY, left: e.clientX, position: "cursor" });
      onModalOpen && onModalOpen();
    };
    getRef = ref => {
      this.ref = ref;
    };
    render() {
      const { children, openModalOnRightClick } = this.props;
      if (!children) {
        return null;
      }
      let childrenProps = {
        key: "children",
        getRef: this.getRef
      };
      if (openModalOnRightClick) {
        childrenProps.onContextMenu = this.onContextMenu;
      } else {
        childrenProps.onClick = this.onClick;
      }
      const _children = renderChildren(children, { ...childrenProps });
      return (
        <>
          {_children}
          {this.getModal()}
        </>
      );
    }
  }
  return ActionModal;
};
