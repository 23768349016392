import React from "react";
import moment from "moment";

export default ({ Box, ScrollView, getSizeFormat, getTimeStringFromSeconds, theme, I18N }) => {
  const { colors, fonts } = theme;
  const { themeColor, blackColor, secondaryColor } = colors;
  const { h16_24, h1, h9 } = fonts;

  const getInfoToShow = ({ data, origin }) => {
    let size = data.initialSize || data.resource_size;
    if (size !== undefined) {
      size = getSizeFormat(size);
    }
    let lastModifiedDate = moment(data.resource_lastModified).format("DD/MM/YYYY");
    let info = {};
    if (origin === "gallery" || origin === "album" || origin === "places") {
      let dimension =
        data.resource_height && data.resource_width
          ? `${data.resource_height} x ${data.resource_width} ${I18N.t("pixels")}`
          : "";
      if (dimension) {
        info["resolution"] = dimension;
      }
      info["size"] = size;
      info["dateTaken"] = lastModifiedDate;
      let city = data?.location?.city?.lower_name;
      if (city) {
        // to convert first letter of each word in uppercase.
        city = city.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
        info["location"] = city;
      }
    } else if (origin === "doc" || origin === "set") {
      info["size"] = size;
      info["lastChange"] = lastModifiedDate;
    } else if (origin === "music" || origin === "playlist") {
      if (data.resource_title) {
        info["name"] = data.resource_title;
      }
      if (data.resource_artist) {
        info["artist"] = data.resource_artist;
      }
      if (data.resource_album) {
        info["album"] = data.resource_album;
      }
      if (data.resource_duration) {
        info["duration"] = getTimeStringFromSeconds(data.resource_duration / 1000);
      }
      if (data.resource_genre) {
        info["genre"] = data.resource_genre;
      }
      if (data.resource_artist) {
        info["artist"] = data.resource_artist;
      }
      info["size"] = size;
      info["lastChange"] = lastModifiedDate;
    }
    return info;
  };

  class ResourceInfoComponent extends React.Component {
    render() {
      let { onClose, data, action } = this.props;
      let origin = action && action.origin;
      const info = getInfoToShow({ data, origin });
      let component = (
        <Box
          viewStyle={{
            padding: 16
          }}
          render={[
            {
              viewStyle: {
                paddingTop: 8,
                paddingBottom: 8
              },
              textStyle: {
                ...h16_24,
                color: themeColor
              },
              text: data.resource_name
            },
            {
              viewStyle: {
                paddingTop: 12,
                paddingBottom: 12
              },
              render: Object.keys(info).map(key => ({
                direction: "row",
                viewStyle: {
                  marginTop: 8,
                  marginBottom: 8
                },
                render: [
                  {
                    width: "2fr",
                    textStyle: {
                      ...h9,
                      color: secondaryColor
                    },
                    text: I18N.t(key)
                  },
                  {
                    width: "3fr",
                    textStyle: {
                      ...h9,
                      color: blackColor,
                      numberOfLines: 2
                    },
                    text: info[key]
                  }
                ]
              }))
            },
            {
              viewStyle: {
                paddingTop: 8,
                paddingBottom: 8,
                paddingRight: 14,
                alignItems: "flex-end"
              },
              textStyle: {
                ...h1,
                color: themeColor
              },
              text: I18N.t("okCaps"),
              onClick: onClose
            }
          ]}
        />
      );
      if (ScrollView) {
        component = <ScrollView>{component}</ScrollView>;
      }
      return component;
    }
  }
  return ResourceInfoComponent;
};
