import React from "react";

export default ({ Box, ImageRender, theme: themeHoc }) => {
  class ImageRenderBox extends React.Component {
    render() {
      let { value, onRemove, onClick, theme } = this.props;
      theme = theme || themeHoc || {};
      let { key, name } = value;
      return (
        <Box
          {...theme.boxStyle}
          render={[
            {
              ...theme.imageRenderContainerStyle,
              onClick,
              render: [
                {
                  ...theme.imageBoxStyle,
                  render: [<ImageRender value={value} theme={theme.imageRenderStyle} />]
                },
                theme.imageNameStyle && {
                  ...theme.imageNameStyle,
                  text: name || key || ""
                }
              ]
            },
            { ...theme.crossStyle, onClick: onRemove }
          ]}
        />
      );
    }
  }
  return ImageRenderBox;
};
