import React from "react";
import {
  Box,
  I18N,
  getImage,
  theme,
  afterLogin,
  Register,
  withContext,
  FSLoginApi,
  showMessage,
  ResendOtp,
  ScrollView,
  SmsReader,
  Platform
} from "../../../FsCloudComponent";

let { resendPincode } = FSLoginApi;
const { startListener, removeListener } = SmsReader;

const { colors, fonts, bgs } = theme;
const { highlightColor, themeColor, primaryColor } = colors;
const { h9, h16, h16_18, h1 } = fonts;
const { loginOtpBg } = bgs;

const resendOtpTheme = {
  resendMessageStyle: {
    textStyle: {
      ...h9,
      color: primaryColor
    }
  },
  timerStyle: {
    viewStyle: {
      paddingLeft: 5
    },
    textStyle: {
      ...h9,
      color: themeColor
    }
  },
  resendTextStyle: {
    textStyle: {
      ...h9,
      color: themeColor
    }
  }
};

const resendCode = ({ transaction_id, onError, message = "codeSentMessage" }) => {
  let isResend = false;
  return () => {
    if (isResend || !resendPincode) {
      return;
    }
    isResend = true;
    resendPincode({ transaction_id })
      .then(_ => {
        showMessage && showMessage(I18N.t(message), 2000);
        isResend = false;
      })
      .catch(err => {
        isResend = false;
        onError && onError(err);
      });
  };
};

const registerEmailInfo = {
  header: {
    logo: "emailIcon",
    title: "informationRecord",
    primaryText: "enterEmailMessage"
  },
  editor: {
    field: "email",
    icon: getImage("emailIcon18Icon"),
    placeholder: "enterEmailId"
  },
  button: {
    text: "submit",
    loaderText: "submitting"
  },
  onClick: ({ data, link }) => {
    let { email } = data;
    let { props: { fromLandline } = {} } = link;
    return {
      functionName: "sendEmail",
      functionParams: { email, fromLandline },
      afterSubmitLink: result => ({
        uri: "/validate-email-otp",
        props: {
          email,
          transaction_id: result.transaction_id,
          fromLandline
        }
      })
    };
  }
};

const validateEmailOtp = {
  minHeight: 450,
  header: {
    logo: "emailIcon",
    title: "checkTheEmailBox",
    primaryText: "enterTheCodeEmail"
  },
  editor: {
    field: "otp",
    placeholder: "typeCode"
  },
  button: {
    text: "validate",
    loaderText: "validating"
  },
  onClick: ({ data, link }) => {
    let { otp } = data;
    let { parentUrl, props: { transaction_id, email, fromLandline } = {} } = link;
    return {
      functionName: "validateOtp",
      functionParams: {
        otp,
        transaction_id,
        email
      },
      replaceLink: true,
      afterSubmitLink: result => {
        return {
          parentUrl,
          uri: `/register-mobile`,
          props: {
            mergeToken: result["fs-custom-token"],
            fromLandline
          }
        };
      }
    };
  }
};

const registerMobile = {
  header: {
    logo: "mobileRegistrationIcon",
    title: "itAlmostFinishing",
    primaryText: "loginMobileDescription"
  },
  editor: {
    field: "mobile",
    icon: getImage("telephoneIcon"),
    placeholder: "loginPlaceholder"
  },
  button: {
    text: "submit",
    loaderText: "submitting"
  },
  onClick: async ({ data, link }) => {
    let { mobile } = data;
    let { props: { fromLandline, mergeToken } = {} } = link;
    let functionParams = { mobile, fromLandline };
    if (Platform.OS === "android") {
      functionParams.smshash = await SmsReader.getHash();
    }
    return {
      functionName: "sendSMS",
      functionParams: functionParams,
      afterSubmitLink: result => ({
        uri: "/validate-mobile-otp",
        props: {
          transaction_id: result.transaction_id,
          fromLandline,
          mergeToken
        }
      })
    };
  }
};

const validateNumberOtp = {
  header: {
    logo: "mobileRegistrationIcon",
    title: "toFinish",
    primaryText: "loginOtpMobileDescription"
  },
  editor: {
    field: "otp",
    placeholder: "typeCode"
  },
  button: {
    text: "validate",
    loaderText: "validating"
  },
  onClick: ({ data, link }) => {
    let { otp } = data;
    let { props: { transaction_id, mergeToken, fromLandline } = {} } = link;
    return {
      functionName: "validateOtp",
      functionParams: {
        fromLandline,
        otp,
        transaction_id,
        mergeToken
      },
      afterSubmit: (result, props) => {
        afterLogin &&
          afterLogin(
            {
              user: result.user
            },
            props
          );
      }
    };
  }
};

const forgotEmail = {
  header: {
    logo: "emailQuestionIcon",
    title: "forgetEmail",
    primaryText: "forgetEmailMessage"
  },
  editor: {
    field: "documentId",
    icon: getImage("forgotEmailFileIcon"),
    placeholder: "forgetEmailPlaceholder"
  },
  button: {
    text: "submit",
    loaderText: "submit"
  },
  onClick: ({ data, link }) => {
    let { documentId } = data;
    let { props: { fromLandline } = {} } = link;
    return {
      functionName: "forgotEmail",
      functionParams: { documentId },
      replaceLink: true,
      afterSubmitLink: result => {
        return {
          parentUrl: link.parentUrl,
          uri: `/show-forgot-email`,
          props: {
            email: result.email,
            fromLandline
          }
        };
      }
    };
  }
};

const ShowForgotEmailDisable = {
  header: {
    logo: "emailQuestionIcon",
    title: "emailReminder",
    primaryText: "showForgotEmailDescription"
  }
};

const translationExists = code => {
  let exists = false;
  if (code) {
    let message = I18N.t(code);
    if (!message || (message.indexOf("missing") !== -1 && message.indexOf("translation") !== -1)) {
      exists = false;
    } else {
      exists = true;
    }
  }
  return exists;
};

class RegisterEmail extends React.Component {
  getFooterComponent = () => {
    // return (
    //   <Box
    //     textStyle={{ ...h9, color: themeColor, textAlign: "center" }}
    //     text={I18N.t("forgetEmail")}
    //     Container={getAction("link")}
    //     containerProps={{
    //       action: {
    //         link: {
    //           uri: "/forgot-email"
    //         }
    //       }
    //     }}
    //   />
    // );
  };
  render() {
    let { link } = this.props;
    let { fromLandline } = (link && link.props) || {};
    let registerProps = {
      ...registerEmailInfo,
      header: { ...registerEmailInfo.header },
      editor: { ...registerEmailInfo.editor }
    };
    if (fromLandline) {
      if (translationExists("vivoFixaInformationRecord")) {
        registerProps.header.title = "vivoFixaInformationRecord";
      }
      if (translationExists("vivoFixaNeedSomeInformation")) {
        registerProps.header.primaryText = "vivoFixaNeedSomeInformation";
      }
      if (translationExists("vivoFixaEnterEmailMessage")) {
        registerProps.header.secondaryText = "vivoFixaEnterEmailMessage";
      }
      if (translationExists("vivoFixaEnterEmailId")) {
        registerProps.editor.placeholder = "vivoFixaEnterEmailId";
      }
    }
    return <Register {...registerProps} footerComponent={this.getFooterComponent} />;
  }
}

class ValidateEmailOtp extends React.Component {
  getFooterComponent = ({ onError }) => {
    let { link: { props: { transaction_id } = {} } = {} } = this.props;
    return (
      <Box
        render={[
          {
            viewStyle: { alignItems: "center", paddingBottom: 4, paddingTop: 8 },
            textStyle: { ...h9, color: themeColor },
            text: I18N.t("didNotGetEmail")
          },
          {
            viewStyle: { alignItems: "center", paddingTop: 4, paddingBottom: 4 },
            textStyle: { ...h16, color: themeColor },
            text: I18N.t("resendEmail"),
            onClick: resendCode({ transaction_id, onError })
          }
        ]}
      />
    );
  };

  render() {
    return <Register {...validateEmailOtp} footerComponent={this.getFooterComponent} />;
  }
}

class RegisterMobile extends React.Component {
  render() {
    return <Register {...registerMobile} />;
  }
}
class ValidateMobileOtp extends React.Component {
  componentDidMount() {
    if (Platform.OS === "android") {
      this.startListener();
    }
  }

  onMessageReceived = message => {
    removeListener();
    if (message.includes("Timeout Error.")) {
      this.startListener();
    } else {
      let code = message.match(/\d+/) && message.match(/\d+/)[0];
      if (code && this.registerRef) {
        this.registerRef.setValue && this.registerRef.setValue({ field: "otp", value: code });
        this.registerRef.onSubmit && this.registerRef.onSubmit();
      }
    }
  };

  startListener = async () => {
    try {
      await startListener(this.onMessageReceived);
    } catch (err) {
      this.registerRef && this.registerRef.onError && this.registerRef.onError(err);
    }
  };

  componentWillUnmount() {
    if (Platform.OS === "android") {
      removeListener();
    }
  }

  getResendOtpComponent = ({ onError }) => {
    let { link: { props: { transaction_id } = {} } = {} } = this.props;
    return (
      <Box
        viewStyle={{ alignItems: "center", paddingTop: 16 }}
        render={
          <ResendOtp
            resendMessageStyle={resendOtpTheme.resendMessageStyle}
            timerStyle={resendOtpTheme.timerStyle}
            resendStyle={resendOtpTheme.resendTextStyle}
            onClick={resendCode({ transaction_id, onError })}
            time={30}
          />
        }
      />
    );
  };

  render() {
    return (
      <Register
        {...validateNumberOtp}
        getRef={_ => (this.registerRef = _)}
        resendOtpComponent={this.getResendOtpComponent}
      />
    );
  }
}

class RegisterSuccess extends React.Component {
  render() {
    return (
      <ScrollView style={{ flex: 1 }}>
        <Box
          render={[
            {
              viewStyle: { marginTop: 106, marginBottom: 12, justifyItems: "center", alignItems: "center" },
              image: getImage("successfulRegistrationIcon")
            },
            {
              viewStyle: { marginTop: 12, marginBottom: 30, justifyItems: "center", alignItems: "center" },
              textStyle: { color: themeColor, ...h16 },
              text: I18N.t("successfulRegistration")
            },
            {
              onClick: () => {
                const { link: { props: { user } = {} } = {} } = this.props;
                afterLogin &&
                  afterLogin(
                    {
                      user
                    },
                    this.props
                  );
              },
              viewStyle: {
                marginTop: 30,
                marginBottom: 30,
                marginLeft: 24,
                marginRight: 24,
                padding: 14,
                borderRadius: 4,
                backgroundColor: themeColor,
                justifyItems: "center",
                alignItems: "center"
              },
              textStyle: { color: highlightColor, ...h16_18 },
              text: I18N.t("continue")
            }
          ]}
        />
      </ScrollView>
    );
  }
}

RegisterSuccess = withContext(RegisterSuccess, {
  replaceUri: "Router.replaceUri",
  link: "Screen.Route.link",
  activeMQ: "ActiveMQ"
});

class ForgotEmail extends React.Component {
  render() {
    return <Register {...forgotEmail} />;
  }
}

class ShowForgotEmail extends React.Component {
  getFooterComponent = () => {
    let { link: { props: { email } = {} } = {} } = this.props;
    return (
      <Box
        viewStyle={{
          marginTop: 16,
          marginRight: 14,
          marginLeft: 14,
          alignItems: "center",
          paddingBottom: 14,
          paddingTop: 14,
          borderRadius: 4,
          backgroundColor: loginOtpBg
        }}
        textStyle={{ ...h1, color: themeColor }}
        text={email}
      />
    );
  };
  render() {
    return <Register {...ShowForgotEmailDisable} footerComponent={this.getFooterComponent} />;
  }
}

export {
  RegisterEmail,
  ValidateEmailOtp,
  RegisterMobile,
  ValidateMobileOtp,
  RegisterSuccess,
  ForgotEmail,
  ShowForgotEmail
};
