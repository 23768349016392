export default ({ fonts, colors }) => {
  const { h1 } = fonts;
  const { primaryColor } = colors;

  const avatarCardStyle = {
    textStyle: {
      ...h1,
      numberOfLines: 1,
      color: primaryColor
    },
    containerViewStyle: { alignItems: "center" },
    viewStyle: { paddingRight: 12, justifyContent: "center" }
  };
  return {
    avatarCardStyle
  };
};
