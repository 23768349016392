import React from "react";
import { renderChildren } from "./Utility";

export default ({ Box, FileDownloadLink, Platform, ImageRender, DetailComponent, resolveMQ, theme: themeHoc }) => {
  class DocumentViewer extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showInfo: false, index: props.index };
    }

    componentDidMount() {
      if (Platform && Platform.OS === "web") {
        // window.addEventListener("keydown", this.handleKey);
      }
    }

    componentWillUnmount() {
      if (Platform && Platform.OS === "web") {
        // window.removeEventListener("keydown", this.handleKey);
      }
    }

    handleKey = e => {
      if (e.keyCode === 39) {
        // go to the right
        let { values = [] } = this.props;
        let { index } = this._getState();
        index < values.length - 1 && this.onNextClick();
      } else if (e.keyCode === 37) {
        // go to the left
        let { index } = this._getState();
        index > 0 && this.onPrevClick();
      }
    };

    _getState = () => {
      return this.props.state ? this.props.state : this.state;
    };

    _setState = state => {
      if (this.props.setState) {
        this.props.setState(state);
      } else {
        this.setState(state);
      }
    };

    onPrevClick = () => {
      let { index } = this._getState();
      this._setState({ index: index - 1 });
    };

    onNextClick = () => {
      let { index } = this._getState();
      this._setState({ index: index + 1 });
    };

    showInfo = () => {
      let { showInfo } = this._getState();
      this._setState({ showInfo: !showInfo });
    };

    render() {
      let {
        values = [],
        onClose,
        activeMQ,
        loadingRender,
        theme = themeHoc,
        showVideoBar,
        imageRenderProps
      } = this.props;
      let { index } = this._getState();
      let value = values[index] || {};
      const {
        boxStyle,
        contentWrapperStyle,
        headerStyle,
        imageContainerStyle,
        leftArrowIconStyle,
        rightArrowIconStyle,
        documentDetailStyle
      } = resolveMQ(
        theme,
        [
          "boxStyle",
          "contentWrapperStyle",
          "headerStyle",
          "imageContainerStyle",
          "leftArrowIconStyle",
          "rightArrowIconStyle",
          "documentDetailStyle"
        ],
        activeMQ
      );
      const { renderHeader, renderFooter, renderBody } = resolveMQ(
        this.props,
        ["renderHeader", "renderFooter", "renderBody"],
        activeMQ
      );

      let headerComponent = renderHeader
        ? renderChildren(renderHeader, {
            showInfo: this.showInfo,
            index,
            value
          })
        : {
            ...headerStyle.containerStyle,
            render: [
              {
                ...headerStyle.backIconStyle,
                onClick: onClose
              },
              {
                width: "1fr",
                ...headerStyle.imageNameStyle,
                text: value.name || value.key || ""
              },
              FileDownloadLink && (
                <FileDownloadLink value={value}>
                  <Box {...headerStyle.downloadIconStyle} />
                </FileDownloadLink>
              ),
              {
                ...headerStyle.detailIconStyle,
                onClick: this.onDetailOpen
              }
            ]
          };

      let footerComponent = renderFooter ? renderChildren(renderFooter, { index, value }) : void 0;
      let bodyComponent = {
        ...imageContainerStyle.imageViewConatinerStyle,
        render: (
          <ImageRender
            loadingRender={loadingRender}
            showVideoBar={showVideoBar}
            hideOpen
            theme={imageContainerStyle.imageViewStyle}
            value={value}
            {...imageRenderProps}
          />
        )
      };
      if (renderBody) {
        bodyComponent = renderChildren(renderBody, {
          index,
          value
        });
        if (React.isValidElement(bodyComponent)) {
          bodyComponent = {
            render: bodyComponent
          };
        }
      }
      return (
        <Box
          {...boxStyle}
          render={[
            {
              ...contentWrapperStyle,
              render: [
                headerComponent,
                // Body
                values.length && {
                  ...imageContainerStyle.boxStyle,
                  render: [
                    index > 0 && {
                      ...leftArrowIconStyle,
                      onClick: this.onPrevClick
                    },
                    bodyComponent && {
                      key: `imageRender_${index}`,
                      width: "1fr",
                      ...bodyComponent
                    },
                    index < values.length - 1 && {
                      ...rightArrowIconStyle,
                      onClick: this.onNextClick
                    }
                  ]
                },
                footerComponent
              ]
            },
            this.state.showInfo &&
              DetailComponent && {
                width: "1fr",
                render: (
                  <DetailComponent
                    onClose={() => this.setState({ showInfo: false })}
                    theme={documentDetailStyle}
                    index={index}
                    value={value}
                  />
                )
              }
          ]}
        />
      );
    }
  }

  return DocumentViewer;
};
