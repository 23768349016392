export default {
  "/profile": {
    screenSM: "AccountTab",
    screenMD: "ProfileTab",
    hash: ({ hash }) => {
      if (hash) {
        return {
          SelectedTab: hash
        };
      }
    }
  },
  "/introduce-yourself": {
    screen: "IntroduceYourself"
  },
  "/upload-profile-picture": {
    screen: "UploadProfilePicture"
  },
  "/block-device": {
    screen: "BlockDevice"
  },
  "/otp-block-device": {
    screen: "OtpBlockDevice"
  },
  "/block-device-mail": {
    screen: "blockDeviceMail",
    hash: ({ hash }) => {
      if (hash) {
        return {
          blockToken: hash
        };
      }
    }
  }
};
