export default () => {
  const drawerSprite = {
    uri: "/webImages/drawerIcons/1_sprite.png",
    menuCrossIcon: {
      height: 20,
      width: 20,
      objectPosition: "-46px -46px",
      objectFit: "none"
    },
    menuPencilIcon: {
      height: 18,
      width: 18,
      objectPosition: "-13px -149px",
      objectFit: "none"
    },

    menuHighlightIcon: {
      height: 22,
      width: 22,
      objectPosition: "-45px -147px",
      objectFit: "none"
    },
    menuHighlightSelectedIcon: {
      height: 22,
      width: 22,
      objectPosition: "-45px -181px",
      objectFit: "none"
    },
    menuGalleryIcon: {
      height: 20,
      width: 22,
      objectPosition: "-79px -80px",
      objectFit: "none"
    },
    menuGallerySelectedIcon: {
      height: 20,
      width: 22,
      objectPosition: "-79px -114px",
      objectFit: "none"
    },
    menuDocIcon: {
      height: 22,
      width: 22,
      objectPosition: "-79px -215px",
      objectFit: "none"
    },
    menuDocSelectedIcon: {
      height: 22,
      width: 22,
      objectPosition: "-113px -11px",
      objectFit: "none"
    },
    menuMusicIcon: {
      height: 20,
      width: 19,
      objectPosition: "-13px -182px",
      objectFit: "none"
    },
    menuMusicSelectedIcon: {
      height: 20,
      width: 19,
      objectPosition: "-13px -216px",
      objectFit: "none"
    },
    menuGroupIcon: {
      height: 15,
      width: 24,
      objectPosition: "-44px -219px",
      objectFit: "none"
    },
    menuGroupSelectedIcon: {
      height: 15,
      width: 24,
      objectPosition: "-78px -15px",
      objectFit: "none"
    },
    menuContactIcon: {
      height: 22,
      width: 20,
      objectPosition: "-114px -45px",
      objectFit: "none"
    },
    menuContactSelectedIcon: {
      height: 22,
      width: 20,
      objectPosition: "-112px -78px",
      objectFit: "none"
    },
    menuTrashIcon: {
      height: 19,
      width: 16,
      objectPosition: "-14px -13px",
      objectFit: "none"
    },
    menuTrashSelectedIcon: {
      height: 19,
      width: 16,
      objectPosition: "-14px -47px",
      objectFit: "none"
    },
    menuLogoutIcon: {
      height: 18,
      width: 16,
      objectPosition: "-48px -81px",
      objectFit: "none"
    },
    menuLogoutSelectedIcon: {
      height: 18,
      width: 16,
      objectPosition: "-48px -115px",
      objectFit: "none"
    },
    menuShareIcon: {
      height: 18,
      width: 17,
      objectPosition: "-14px -81px",
      objectFit: "none"
    },
    menuShareSelectedIcon: {
      height: 19,
      width: 17,
      objectPosition: "-14px -115px",
      objectFit: "none"
    },
    menuHomeIcon: {
      height: 19,
      width: 19,
      objectPosition: "-115px -183px",
      objectFit: "none"
    },
    menuHomeSelectedIcon: {
      height: 19,
      width: 19,
      objectPosition: "-115px -217px",
      objectFit: "none"
    },
    menuAllFilesIcon: {
      height: 15,
      width: 19,
      objectPosition: "-115px -117px",
      objectFit: "none"
    },
    menuAllFilesSelectedIcon: {
      height: 15,
      width: 19,
      objectPosition: "-115px -151px",
      objectFit: "none"
    },
    menuSettingIcon: {
      height: 18,
      width: 18,
      objectPosition: "-148px -13px",
      objectFit: "none"
    },
    menuSettingSelectedIcon: {
      height: 18,
      width: 18,
      objectPosition: "-148px -47px",
      objectFit: "none"
    }
  };

  const menuCrossIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuCrossIcon
    }
  };
  const menuPencilIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuPencilIcon
    }
  };
  const menuHomeIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuHomeIcon
    }
  };
  const menuHomeSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuHomeSelectedIcon
    }
  };
  const menuHighlightIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuHighlightIcon
    }
  };
  const menuHighlightSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuHighlightSelectedIcon
    }
  };
  const menuGalleryIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuGalleryIcon
    }
  };
  const menuGallerySelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuGallerySelectedIcon
    }
  };
  const menuDocIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuDocIcon
    }
  };
  const menuDocSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuDocSelectedIcon
    }
  };
  const menuMusicIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuMusicIcon
    }
  };
  const menuMusicSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuMusicSelectedIcon
    }
  };
  const menuGroupIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuGroupIcon
    }
  };
  const menuGroupSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuGroupSelectedIcon
    }
  };
  const menuAllFilesIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuAllFilesIcon
    }
  };
  const menuAllFilesSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuAllFilesSelectedIcon
    }
  };
  const menuSettingIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuSettingIcon
    }
  };
  const menuSettingSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuSettingSelectedIcon
    }
  };

  const menuShareIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuShareIcon
    }
  };
  const menuShareSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuShareSelectedIcon
    }
  };
  const menuLogoutIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuLogoutIcon
    }
  };
  const menuLogoutSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuLogoutSelectedIcon
    }
  };
  const menuContactIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuContactIcon
    }
  };
  const menuContactSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuContactSelectedIcon
    }
  };
  const menuTrashIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuTrashIcon
    }
  };
  const menuTrashSelectedIcon = {
    uri: drawerSprite.uri,
    spriteProps: {
      ...drawerSprite.menuTrashSelectedIcon
    }
  };
  const reportIcon = "/webImages/drawerIcons/reportGrey.png";
  const reportSelectedIcon = "/webImages/drawerIcons/report.png";
  const menuSafeAreaIcon = "/webImages/drawerIcons/menuSafeArea.png";
  const menuSafeAreaSelectedIcon = "/webImages/drawerIcons/menuSafeAreaSelected.png";
  const menuFailedIcon = "/webImages/drawerIcons/menuFailed.png";
  const menuFailedSelectedIcon = "/webImages/drawerIcons/menuFailedSelected.png";
  const nonUploadedIcon = "/webImages/drawerIcons/nonUploaded.png";
  const nonUploadedSelectedIcon = "/webImages/drawerIcons/nonUploadedSelected.png";
  const menuDuplicateIcon = "/webImages/drawerIcons/menuDuplicate.png";
  const menuDuplicateSelectedIcon = "/webImages/drawerIcons/menuDuplicateSelected.png";
  const menuArchiveIcon = "/webImages/drawerIcons/menuArchive.png";
  const menuArchiveSelectedIcon = "/webImages/drawerIcons/menuArchiveSelected.png";
  const DesktopAppIcon = "/webImages/drawerIcons/DesktopAppIcon.png";

  return {
    menuCrossIcon,
    menuPencilIcon,
    menuHomeIcon,
    menuHomeSelectedIcon,
    menuHighlightIcon,
    menuHighlightSelectedIcon,
    menuGalleryIcon,
    menuGallerySelectedIcon,
    menuDocIcon,
    menuDocSelectedIcon,
    menuMusicIcon,
    menuMusicSelectedIcon,
    menuGroupIcon,
    menuGroupSelectedIcon,
    menuShareIcon,
    menuShareSelectedIcon,
    menuAllFilesIcon,
    menuAllFilesSelectedIcon,
    menuSettingIcon,
    menuSettingSelectedIcon,
    menuLogoutIcon,
    menuLogoutSelectedIcon,
    menuContactIcon,
    menuContactSelectedIcon,
    menuTrashIcon,
    menuTrashSelectedIcon,
    reportIcon,
    reportSelectedIcon,
    menuSafeAreaIcon,
    menuSafeAreaSelectedIcon,
    menuFailedIcon,
    menuFailedSelectedIcon,
    nonUploadedIcon,
    nonUploadedSelectedIcon,
    menuDuplicateIcon,
    menuDuplicateSelectedIcon,
    menuArchiveIcon,
    menuArchiveSelectedIcon,
    DesktopAppIcon
  };
};
