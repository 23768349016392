import React from "react";
import { Box, I18N, getImage, Link } from "../../../FsCloudComponent";

export default class AlbumSlideShow extends React.Component {
  render() {
    let { style, data, origin = "album" } = this.props;
    return (
      <Box
        style={style}
        render={[
          {
            image: getImage("slideShowIcon"),
            title: () => I18N.t("slideshowTitle"),
            Container: Link,
            containerProps: {
              action: {
                link: {
                  uri:
                    origin === "album"
                      ? "/montage"
                      : origin === "vaultAlbum"
                      ? "/montage-vault"
                      : origin === "deviceFolder"
                      ? "/montage-folder"
                      : "/montage-place",
                  props: { params: { data } }
                }
              }
            }
          }
        ]}
      />
    );
  }
}
