import React from "react";
import DecryptedUtility from "./DecryptedUtility";
export default HOCProps => {
  const { getComponent, isEncrypted, Platform, getDecryptionProps, decryptFile, LoadingIndicator } = HOCProps;
  const { setToken, unsetToken } = DecryptedUtility(HOCProps);
  class DecryptedVideo extends React.Component {
    state = {};

    _setState = state => {
      if (this._unmounted) {
        return;
      }
      this.setState(state);
    };

    isEncrypted = url => isEncrypted(getDecryptionProps(this.props.value, this.props.decryptionSourceProps), url);

    componentDidMount() {
      let source = this.getSource(this.props);
      if (this.isEncrypted(source)) {
        this.setToken();
      }
    }

    setToken = async () => {
      let { value, decryptionSourceProps } = this.props;
      let data = await setToken(value, decryptionSourceProps);
      let poster;
      if (Platform.OS === "web") {
        try {
          // This throws error in case of invalid url.
          poster = await decryptFile({
            url: value.converted_jpeg_url || value.thumbnail_url,
            decryptionProps: getDecryptionProps(value, decryptionSourceProps)
          });
        } catch (err) {}
      }
      this._setState({ ...data, poster });
    };

    getSource = props => {
      if (Platform.OS === "web") {
        return props.value && (props.value.converted_mp4_url || props.value.resource_url);
      } else {
        return props.source && props.source.uri;
      }
    };

    async componentDidUpdate(prevProps) {
      try {
        let source = this.getSource(this.props);
        let prevSource = this.getSource(prevProps);
        if (prevSource !== source) {
          let { token } = this.state;
          token && (await unsetToken(token));
          if (this.isEncrypted(source)) {
            this.setToken();
          }
        }
      } catch (err) {}
    }

    componentWillUnmount() {
      let { token } = this.state;
      token && unsetToken(token);
      this._unmounted = true;
    }

    render() {
      const Component = getComponent && getComponent("videoPlayer");
      let source = this.getSource(this.props);
      if (this.isEncrypted(source)) {
        source = this.state.url;
      }
      let videoProps = {
        source: Platform.OS !== "web" ? { uri: source } : source,
        key: source,
        poster: this.state.poster
      };
      // dont show loader in native
      return videoProps.source ? <Component {...this.props} {...videoProps} /> : <LoadingIndicator />;
    }
  }

  return DecryptedVideo;
};
