import React from "react";
import {
  Box,
  getImage,
  theme,
  I18N,
  RowAction,
  StatusBar as LoadingIndicator,
  withContext,
  DecryptedImage,
  Link
} from "../../../FsCloudComponent";
import ResourceActions from "../resource/ResourceActions";
import { fetchWeekData, fetchMonthData, fetchYearData, fetchMoreData, loadPicOfDay } from "./storyViewUtility";
const { gradients } = theme;
const { bottomBlackGradient, topBlackGradient } = gradients;

class WebStorySM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storiesData: {},
      importGallery: false
    };
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    this.setState(state);
  };

  // These names are used in translation.
  displaySequence = [
    { title: "photoOfTheDay" },
    { title: "recentHighlights" },
    { title: "OneMonthAgo" },
    { title: "OneYearAgo" },
    { title: "MoreThanAnYearAgo" }
  ];

  handleStoriesData = (params = {}, name) => {
    if (!name) {
      return;
    }
    let { storiesData } = this.state;
    storiesData[name] = params;
    this._setState({ storiesData });
  };

  fetchStories = async () => {
    // POD
    loadPicOfDay().then(res => this.handleStoriesData(res, "photoOfTheDay"));
    // Recent
    fetchWeekData().then(res => this.handleStoriesData(res, "recentHighlights"));
    // OneMonth
    fetchMonthData().then(res => this.handleStoriesData(res, "OneMonthAgo"));
    // OneYear
    fetchYearData().then(res => this.handleStoriesData(res, "OneYearAgo"));
    // More
    fetchMoreData().then(res => this.handleStoriesData(res, "MoreThanAnYearAgo"));
  };

  componentDidMount() {
    this.fetchStories();
  }
  render() {
    const { storiesData, importGallery } = this.state;
    const storiesCount = Object.keys(storiesData).length;
    return (
      <Box
        direction="row"
        style={{
          flex: 1,
          marginLeft: 20,
          marginTop: 20,
          marginRight: 10,
          justifyContent: storiesCount === 1 ? "no" : "space-around",
          flexWrap: "wrap"
        }}
        render={this.displaySequence.map(({ title } = {}) => {
          const { to: endDate, from: startDate, data: stories = [] } = storiesData[title] || {};
          const data = stories && stories[0];
          if (!data) {
            return void 0;
          }
          data.startDate = startDate;
          data.endDate = endDate;
          let resourceMenuActions = [ResourceActions.viewAll];
          if (title === "photoOfTheDay") {
            if (data.exported || importGallery) {
              resourceMenuActions = [
                ResourceActions.postToGroup,
                ResourceActions.getLink,
                ResourceActions.webShareGallery
              ];
            } else {
              resourceMenuActions = [
                {
                  ...ResourceActions.importPhotoToGallery,
                  text: () => I18N.t("importToGallery"),
                  image: getImage("highlightImportGreyIcon"),
                  title: () => I18N.t("importToGallery"),
                  updateStateOnInvoke: (_, props) => {
                    let { invokeResult } = props;
                    if (invokeResult && !invokeResult.error) {
                      this._setState({ importGallery: true });
                    }
                  }
                }
              ];
            }
          }
          const uri = data.thumbnail_url;
          const loadingImageKey = `onLoad${data._id}`;
          return {
            viewStyle:
              storiesCount % 2 === 0
                ? {
                    flex: "50%",
                    justifyContent: "center",
                    alignItems: "center"
                  }
                : {},
            render: [
              {
                viewStyle: {
                  height: 170,
                  width: 100,
                  borderRadius: 10,
                  marginRight: 5,
                  marginBottom: 10
                },
                render: [
                  <DecryptedImage
                    data={data}
                    style={{
                      height: 170,
                      width: 100,
                      objectFit: "cover"
                    }}
                    onLoad={() => {
                      this._setState({ [loadingImageKey]: true });
                    }}
                    source={{ uri }}
                  />,
                  {
                    viewStyle: {
                      ...bottomBlackGradient,
                      position: "absolute",
                      bottom: 0,
                      width: 100,
                      height: 16,
                      alignItems: "center"
                    },
                    textStyle: {
                      color: "white",
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                      paddingBottom: 6,
                      fontSize: 11
                    },
                    text: I18N.t(title)
                  },
                  this.state[loadingImageKey]
                    ? {
                        viewStyle: {
                          position: "absolute",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          alignSelf: "center",
                          top: 65,
                          borderRadius: 10,
                          padding: 6,
                          backgroundColor: "rgba(0,0,0,0.6)"
                        },
                        Container: Link,
                        containerProps: {
                          action: {
                            link: {
                              uri: "/montage-highlights",
                              props: {
                                parentInfo: { _connectProps: { data: stories } }
                              }
                            }
                          }
                        },
                        image: getImage("albumSlideshowTransparentIcon")
                      }
                    : {
                        viewStyle: { flex: 1 },
                        render: <LoadingIndicator />
                      },
                  {
                    gradient: topBlackGradient,
                    gradientStyle: {
                      position: "absolute",
                      top: 0,
                      height: 30,
                      width: 100
                    },
                    viewStyle: {
                      flex: 1,
                      position: "absolute",
                      height: 32,
                      width: 32,
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: 4,
                      paddingRight: 4,
                      cursor: "pointer",
                      top: 1,
                      left: 71,
                      zIndex: 1
                    },
                    render: <RowAction data={data} action={resourceMenuActions} image={getImage("iconMenuWhite")} />
                  }
                ]
              }
            ]
          };
        })}
      />
    );
  }
}

WebStorySM = withContext(WebStorySM, {
  user: "User.user"
});

export default WebStorySM;
