export default ({ images, colors, fonts, shadows }) => {
  const { crossIcon, uploadIcon, documentIcon } = images;
  const { primaryColor, secondaryColor, highlightColor, darkBg, themeColor } = colors;
  const { h2, h5 } = fonts;
  const fileInputStyle = {
    boxStyle: {
      viewStyle: {
        height: 48,
        padding: 16,
        alignItems: "center",
        backgroundColor: "rgb(239,239,239)",
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: "rgb(142,142,142)",
        cursor: "pointer",
        justifyContent: "center"
      },
      direction: "row"
    },
    uploadImageStyle: {
      image: uploadIcon,
      viewStyle: {
        width: 20,
        marginRight: 6
      },
      imageProps: {
        resizeMode: "contain"
      },
      imageStyle: {
        width: 16,
        height: 16
      }
    },
    uploadTextStyleSM: {
      width: "1fr",
      viewStyle: {
        marginLeft: 6,
        marginRight: 6
      },
      text: "Upload files to attach",
      textStyle: {
        ...h5,
        color: darkBg
      }
    },
    uploadTextStyleMD: {
      text: "You can also drop your files here",
      textStyle: {
        ...h2,
        color: themeColor
      }
    }
  };

  const fileAdndImageRenderStyleForDocumentSlider = {
    boxStyle: { viewStyle: { flex: 1, alignItems: "center", justifyContent: "center" } },
    messageStyle: {
      viewStyle: {
        height: 150,
        width: 150,
        alignItems: "center",
        justifyContent: "center"
      },
      text: "No Preview available for this file format.",
      textStyle: {
        font: h2,
        color: secondaryColor,
        textAlign: "center"
      }
    },
    imageRenderStyle: {
      viewStyle: { flex: 1, alignItems: "center", justifyContent: "center" },
      imageStyle: { maxWidth: "100%", maxHeight: "100%" }
    }
  };

  const fileRenderBoxStyle = {
    boxStyle: {
      viewStyle: {
        paddingTop: 6,
        paddingBottom: 6
      },
      direction: "row"
    },
    boxStyleMD: {
      viewStyle: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 6,
        paddingRight: 6
      },
      direction: "row"
    },
    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6
      },
      imageStyle: {
        width: 16,
        height: 16
      }
    },
    downloadLinkContainerStyle: {
      viewStyle: {
        flex: 1,
        padding: 6
      }
    },
    crossStyle: {
      image: crossIcon,
      viewStyle: {
        padding: 6
      },
      imageStyle: {
        width: 15,
        height: 15
      }
    }
  };

  const imageRenderBoxStyle = {
    boxStyle: {
      viewStyle: {
        padding: 12,
        cursor: "pointer"
      },
      direction: "row"
    },
    imageRenderStyle: {
      imageStyle: {
        width: 150,
        height: 150
      }
    },
    imageNameStyle: {
      viewStyle: {
        width: 150,
        paddingTop: 6
      },
      textStyle: {
        fontSize: 14,
        numberOfLines: 2
      }
    },
    crossStyle: {
      viewStyle: {
        ...shadows.shadow2,
        backgroundColor: highlightColor,
        position: "absolute",
        left: 142,
        top: 12,
        width: 20,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      },
      image: crossIcon
    }
  };

  const fileDownloadLinkStyle = {
    boxStyle: {
      textStyle: {
        ...h2,
        color: primaryColor,
        numberOfLines: 1
      }
    }
  };

  const imageRenderStyle = {
    imageStyle: {
      width: 200,
      height: 200
    }
  };
  const fileTextInputStyle = {
    width: 0
  };

  const imageFieldRenderStyle = {
    imageStyle: {
      height: 80,
      width: 80
    }
  };

  const networkImageRenderStyle = {
    viewStyle: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    }
  };

  return {
    fileDownloadLinkStyle,
    fileInputStyle,
    fileRenderBoxStyle,
    fileAdndImageRenderStyleForDocumentSlider,
    imageRenderBoxStyle,
    networkImageRenderStyle,
    imageRenderStyle,
    fileTextInputStyle,
    imageFieldRenderStyle
  };
};
