export default ({ fonts, colors }) => {
  const { primaryColor } = colors;
  const { h3MD, h10 } = fonts;

  const inputStyle = {
    color: primaryColor,
    borderWidth: 0,
    padding: 0,
    outline: "none",
    backgroundColor: "transparent"
  };

  const inputStyleSM = {
    ...inputStyle,
    ...h10
  };

  const inputStyleMD = {
    ...inputStyle,
    ...h3MD
  };
  return { inputStyleSM, inputStyleMD };
};
