import React from "react";
import { Location, WithState, WithStateRouter, AsyncStorage, ToastContext } from "./BasicComponents";
import { setState as setStateHoc } from "app-utility-functions";
import NoInternetComponent from "./NoInternetScreen";
import { getUploadPortingCount } from "./porting/PortingMetadataUtils";
import UploadPortingProgress from "./porting/UploadPortingProgress";
import { handleGlobalError } from "./ErrorHandler";

const LocationKey = "location";
const RouterKey = "router";
const UserStoreKey = "userStore";
const AppStoreKey = "appStore";

export default ({
  getRoute,
  UserStore,
  AppStore,
  StatusBar,
  landingUrl,
  renderNav,
  Nav,
  resolveMQ,
  NotificationHandler,
  showError,
  showMessage,
  AppLoader,
  Toast,
  setRouterContextInfo,
  BlockerStatusBar,
  getPendingBlockerRef,
  isPendingBlocker,
  pushLog,
  versionData,
  ensureSettingInfo,
  Platform,
  getUser,
  firebaseSave
}) => {
  class AppWithState extends React.Component {
    constructor(props) {
      super(props);
      this.toastContextValue = {
        showError,
        showMessage
      };
      this.state = {};
      if (Platform.OS !== "web") {
        this.handleInitialProcess();
      }
    }

    handleInitialProcess = async () => {
      let uploadPortCount = void 0;
      try {
        ensureSettingInfo && (await ensureSettingInfo());
        uploadPortCount = await getUploadPortingCount();
      } catch (err) {
        pushLog && pushLog(`Error in handleInitialProcess..${err.message}`, true);
      }
      if (!this._unmounted) {
        this.setState({
          initialProcessCompleted: true,
          uploadPortCount
        });
      }
    };

    onPortComplete = () => {
      this.setState({ uploadPortCount: void 0 });
    };

    componentDidMount() {
      pushLog && pushLog(`App mount called..`, true);
      handleGlobalError && handleGlobalError({ pushLog, versionData, getUser, firebaseSave });
    }

    componentWillUnmount() {
      this._unmounted = true;
      pushLog && pushLog(`App Unmount called..`, true);
    }

    clearState = () => {
      //need to clear for logout state,
      //todo hardCode userLoaded and defaultAppState
      const { state, setState } = this.props;
      const pstate = state.pstate;
      pstate[RouterKey] = {};
      pstate[UserStoreKey] = {};
      pstate[AppStoreKey] = {};

      setState({
        [RouterKey]: { pstate: pstate[RouterKey] },
        [UserStoreKey]: { pstate: pstate[UserStoreKey], userLoaded: true },
        [AppStoreKey]: { pstate: pstate[AppStoreKey] }
      });
    };

    setIgnoreHistoryChange = setStateProps => {
      //here user may come and we need to set it when history changes. case when user have no group and create group then we need to set user in UserStor along with deleteUri. : rohit bansal - 15-06-19
      // we now call history.go(-1*n) on deleteUri in stateRouter
      this.ignoreHistoryChange = setStateProps;
    };
    onWebBackPress = ({ user }) => {
      const { pathname, hash } = window.location;
      let history = window.history;
      let stateLocationUrl = this.props.state[LocationKey] && this.props.state[LocationKey].url;
      if (pathname && (pathname.indexOf("be-part") >= 0 || pathname.indexOf("join-group") >= 0)) {
        history && history.go(-1);
      } else if (
        hash &&
        hash.indexOf("/vault") >= 0 &&
        (!stateLocationUrl || stateLocationUrl.indexOf("/vault") === -1)
      ) {
        history && history.go(-1);
      } else if (user && pathname && pathname.indexOf("/landing") >= 0) {
        let currentUrl = "/home#gallery";
        let history = window.history;
        history && history.pushState({}, "", currentUrl);
      } else {
        let state = history.state;
        let link = state && state.link;
        let nowUrl = pathname;
        if (hash) {
          nowUrl = nowUrl + hash;
        }
        if (!link) {
          let lastUriIndex = nowUrl.lastIndexOf("/");
          let uri = nowUrl.substring(lastUriIndex);
          link = { uri };
        }

        let linkUri = link.uri;
        let uriIndex = nowUrl.indexOf(linkUri);
        let preUrl = nowUrl.substring(0, uriIndex);
        this.routerContext.addUri(preUrl, { ...link, ...this.ignoreHistoryChange }, { setUrl: { replace: true } });
      }
      this.ignoreHistoryChange = void 0;
      return false;
    };

    setRouterContext = routerContext => {
      //this is needed to call set state on history change : rohit bansal - 15-06-19
      this.routerContext = routerContext;
      setRouterContextInfo && setRouterContextInfo(routerContext);
    };

    appSetState = _state => {
      let { setState } = this.props;
      setState(state => {
        let routeState = typeof _state === "function" ? _state(state[RouterKey]) : _state;
        const { _location, _user, ...restState } = routeState;

        let newStateToSet = {
          [RouterKey]: { ...state[RouterKey], ...restState }
        };
        if (_location) {
          newStateToSet[LocationKey] = { url: _location };
        }
        if (_user) {
          let { user, ...restUserState } = state[UserStoreKey];
          newStateToSet[UserStoreKey] = {
            ...restUserState,
            user: {
              ...user,
              ..._user
            }
          };
        }
        return newStateToSet;
      });
    };

    render() {
      if (Platform.OS !== "web" && !this.state.initialProcessCompleted) {
        return null;
      }
      const { state, setState } = this.props;
      const pstate = state.pstate;

      pstate[LocationKey] = pstate[LocationKey] || {};
      pstate[RouterKey] = pstate[RouterKey] || {};
      pstate[UserStoreKey] = pstate[UserStoreKey] || {};
      pstate[AppStoreKey] = pstate[AppStoreKey] || {};

      state[LocationKey] = state[LocationKey] || { pstate: pstate[LocationKey] };
      state[RouterKey] = state[RouterKey] || { pstate: pstate[RouterKey] };
      state[UserStoreKey] = state[UserStoreKey] || { pstate: pstate[UserStoreKey] };
      state[AppStoreKey] = state[AppStoreKey] || { pstate: pstate[AppStoreKey] };

      return (
        <ToastContext.Provider value={this.toastContextValue}>
          <Location state={state[LocationKey]} setState={setStateHoc({ key: LocationKey, setState })}>
            {({ getUrl, setUrl }) => {
              return (
                <UserStore
                  clearState={this.clearState}
                  state={state[UserStoreKey]}
                  setState={setStateHoc({ key: UserStoreKey, setState })}
                >
                  {({ user, loading, error, loadUser }) => {
                    if (loading) {
                      return AppLoader ? <AppLoader /> : <StatusBar />;
                    } else if (Platform.OS !== "web" && this.state.uploadPortCount) {
                      return (
                        <UploadPortingProgress
                          onComplete={this.onPortComplete}
                          totalCount={this.state.uploadPortCount}
                        />
                      );
                    } else if (error) {
                      return <NoInternetComponent error={error} retryInternet={loadUser} />;
                    } else {
                      return (
                        <AppStore
                          onWebBackPress={this.onWebBackPress}
                          user={user}
                          state={state[AppStoreKey]}
                          setState={setStateHoc({ key: AppStoreKey, setState })}
                        >
                          <WithStateRouter
                            BlockerStatusBar={BlockerStatusBar}
                            getPendingBlockerRef={getPendingBlockerRef}
                            isPendingBlocker={isPendingBlocker}
                            setRouterContext={this.setRouterContext}
                            resolveMQ={resolveMQ}
                            getRoute={getRoute}
                            getUrl={getUrl}
                            setUrl={setUrl}
                            landingUrl={landingUrl}
                            state={state[RouterKey]}
                            setState={this.appSetState}
                            setIgnoreHistoryChange={this.setIgnoreHistoryChange}
                          >
                            <Nav user={user} renderNav={renderNav} />
                          </WithStateRouter>
                        </AppStore>
                      );
                    }
                  }}
                </UserStore>
              );
            }}
          </Location>
        </ToastContext.Provider>
      );
    }
  }

  class App extends React.Component {
    getPersistState = () => {
      return AsyncStorage.getItem("encrypted")
        .then(encrypted => {
          if (!encrypted) {
            let token = void 0;
            return AsyncStorage.getItem("token")
              .then(_token => {
                token = _token;
                return AsyncStorage.getItem("_appState");
              })
              .then(appState => {
                if (appState) {
                  return AsyncStorage.setItem("_appState", appState);
                }
              })
              .then(_ => {
                if (token) {
                  return AsyncStorage.setItem("token", token);
                }
              });
          }
        })
        .then(_ => {
          return AsyncStorage.getItem("_appState");
        })
        .then(state => {
          if (state) {
            state = JSON.parse(state);
          }
          return state;
        });
    };

    persistState = state => {
      AsyncStorage.setItem("_appState", JSON.stringify(state));
    };

    render() {
      let appComponent = (
        <WithState getPersistState={this.getPersistState} persistState={this.persistState}>
          {({ state, setState }) => <AppWithState state={state} setState={setState} />}
        </WithState>
      );
      if (NotificationHandler) {
        appComponent = (
          <NotificationHandler>
            {appComponent}
            <Toast />
          </NotificationHandler>
        );
      }
      return appComponent;
    }
  }
  return App;
};
