import React from "react";
import { addItems, getDocPopUpExtIcon, getErrorMessage } from "./CollectionUtility";

export default ({
  Box,
  getImage,
  theme,
  GridTable,
  I18N,
  Header,
  LoadingIndicator,
  View,
  resolveMQ,
  numberFormatter,
  showMessage,
  Platform,
  getModeText,
  DecryptedImage
}) => {
  const { colors, fonts, bgs, shadows, gradients } = theme;
  const { themeColor, primaryColor, highlightColor, tomato, brownGrey, whiteTwo } = colors;
  const { themeBg } = bgs;
  const { h16_l_22, h16_l_20, h2, h9, h2_16, h5_16 } = fonts;
  const { cardShadow } = shadows;
  const { topToBottomTransparentToBlackGradient, topToBottomBlackToTransparentGradient } = gradients;

  const CreateNewCollectionCard = ({ onClick, title }) => {
    return (
      <Box
        viewStyle={{
          alignItems: "center",
          height: 92,
          margin: 2,
          paddingLeft: 8,
          paddingRight: 8,
          borderWidth: 1,
          borderColor: themeBg,
          borderRadius: 4,
          cursor: "pointer"
        }}
        onClick={onClick}
        render={[
          {
            viewStyle: {
              paddingTop: 28,
              paddingBottom: 12
            },
            image: getImage("createNewPopupIcon")
          },
          {
            textStyle: {
              ...h2,
              color: themeColor,
              textAlign: "center",
              numberOfLines: 1
            },
            text: title
          }
        ]}
      />
    );
  };

  const DataCollectionCard = ({ data, selected, onClick, iconStyle, shareable }) => {
    return (
      <Box
        viewStyle={{
          height: 92,
          margin: 2,
          borderRadius: 4,
          paddingLeft: 8,
          paddingRight: 8,
          cursor: "pointer",
          ...cardShadow
        }}
        onClick={onClick}
        render={[
          { ...iconStyle },
          {
            textStyle: {
              ...h2_16,
              color: primaryColor,
              textAlign: "center",
              numberOfLines: 1,
              whiteSpace: "pre"
            },
            text: data.name
          },
          {
            direction: "row",
            viewStyle: {
              paddingTop: 2,
              alignSelf: "center"
            },
            textStyle: {
              ...h5_16,
              textAlign: "center",
              color: brownGrey
            },
            render: [
              { text: `${numberFormatter(data.itemCount, "0.0i")} ${I18N.t("items")}` },
              shareable !== false && { viewStyle: { paddingLeft: 3, paddingRight: 3 }, text: "|" },
              shareable !== false && { text: getModeText(data) }
            ]
          },
          selected && {
            viewStyle: {
              position: "absolute",
              top: 6,
              right: 6
            },
            image: getImage("checkedIcon")
          }
        ]}
      />
    );
  };

  const AlbumCard = ({ data = {}, selected, onClick }) => {
    let coverData = { ...data.coverImage, collection: { _id: data._id } };
    let coverImageProps = {
      data: coverData,
      source: { uri: coverData.converted_jpeg_url },
      decryptionSourceProps: { decryptionSource: "collection" },
      style: { height: 92, borderRadius: 4 }
    };
    if (Platform.OS === "web") {
      coverImageProps.style.objectFit = "cover";
    } else {
      coverImageProps.resizeMode = "cover";
    }

    return (
      <Box
        viewStyle={{
          height: 92,
          margin: 2,
          borderRadius: 4,
          cursor: "pointer",
          backgroundColor: whiteTwo,
          ...cardShadow
        }}
        onClick={onClick}
        render={[
          <DecryptedImage {...coverImageProps} />,
          {
            gradient: {
              ...topToBottomTransparentToBlackGradient
            },
            gradientStyle: {
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              padding: 6
            },
            render: [
              {
                textStyle: {
                  ...h2,
                  color: highlightColor,
                  numberOfLines: 1,
                  whiteSpace: "pre"
                },
                text: data.name
              },
              {
                textStyle: {
                  ...h2,
                  color: highlightColor
                },
                text: `(${numberFormatter(data.itemCount, "0.0i")})`
              }
            ]
          },
          selected && {
            gradient: {
              ...topToBottomBlackToTransparentGradient
            },
            gradientStyle: {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              padding: 6,
              alignItems: "flex-end"
            },
            image: getImage("imageCheckedSelectedIcon")
          }
        ]}
      />
    );
  };

  const SetCard = props => {
    let { data } = props;
    return (
      <DataCollectionCard
        iconStyle={{
          viewStyle: { paddingTop: 8, paddingBottom: 6, alignSelf: "center" },
          image: getDocPopUpExtIcon({ data, getImage })
        }}
        {...props}
      />
    );
  };

  const PlaylistCard = props => {
    return (
      <DataCollectionCard
        iconStyle={{
          viewStyle: { paddingTop: 12, paddingBottom: 8, alignSelf: "center" },
          image: getImage("playlistIcon")
        }}
        shareable={false}
        {...props}
      />
    );
  };

  const metadata = {
    gallery: {
      headerTitle: () => I18N.t("addToAlbumCaps"),
      selectMessage: () => I18N.t("addToAlbumMessage"),
      newCardTitle: () => I18N.t("createAlbum"),
      itemsAddedMessage: () => I18N.t("itemsCountAddedToAlbum"),
      itemIgnoredMessage: () => I18N.t("itemsCountIgnoredToAlbum"),
      CardComponent: AlbumCard
    },
    doc: {
      headerTitle: () => I18N.t("addToSetCaps"),
      selectMessage: () => I18N.t("addToSetMessage"),
      newCardTitle: () => I18N.t("createSet"),
      itemsAddedMessage: () => I18N.t("itemsCountAddedToSet"),
      itemIgnoredMessage: () => I18N.t("itemsCountIgnoredToSet"),
      CardComponent: SetCard
    },
    music: {
      headerTitle: () => I18N.t("addToPlaylistCaps"),
      selectMessage: () => I18N.t("addToPlaylistMessage"),
      newCardTitle: () => I18N.t("createPlaylist"),
      itemsAddedMessage: () => I18N.t("itemsCountAddedToPlaylist"),
      itemIgnoredMessage: () => I18N.t("itemsCountIgnoredToPlaylist"),
      CardComponent: PlaylistCard
    },
    vaultAlbum: {
      headerTitle: () => I18N.t("addToAlbumCaps"),
      selectMessage: () => I18N.t("addToAlbumMessage"),
      newCardTitle: () => I18N.t("createAlbum"),
      itemsAddedMessage: () => I18N.t("itemsAddedToAlbum"),
      itemAddedMessage: () => I18N.t("itemAddedToAlbum"),
      CardComponent: AlbumCard
    },
    vaultSet: {
      headerTitle: () => I18N.t("addToSetCaps"),
      selectMessage: () => I18N.t("addToSetMessage"),
      newCardTitle: () => I18N.t("createSet"),
      itemsAddedMessage: () => I18N.t("itemsAddedToSet"),
      itemAddedMessage: () => I18N.t("itemAddedToSet"),
      CardComponent: SetCard
    }
  };

  class CollectionCard extends React.Component {
    onClick = () => {
      let { onClick, data } = this.props;
      onClick && onClick(data);
    };

    render() {
      let { data, isSelected, newCardTitle, CardComponent } = this.props;
      if (data._id === "createNew") {
        return <CreateNewCollectionCard onClick={this.onClick} title={newCardTitle} />;
      } else {
        let selected = isSelected && isSelected(data);
        return <CardComponent selected={selected} data={data} onClick={this.onClick} />;
      }
    }
  }

  class AddItemsCollectionSelector extends React.Component {
    state = { error: void 0 };

    componentDidMount() {
      if (Platform && Platform.OS === "web") {
        window.addEventListener("keydown", this.handleKey);
      }
    }

    componentWillUnmount() {
      if (Platform && Platform.OS === "web") {
        window.removeEventListener("keydown", this.handleKey);
      }
    }

    handleKey = e => {
      if (e.keyCode === 13) {
        this.onSubmit();
      }
    };

    onSubmit = async () => {
      let collection = this.state.selectedAlbum;
      if (!collection) {
        this.setState({
          error: I18N.t("selectErrorMesage")
        });
        return;
      }
      let { onClose, invoke, urls, rowData, selectedIds = [], origin } = this.props;
      try {
        this.setState({ loading: true });
        const { itemIgnoredMessage, itemsAddedMessage } = metadata[origin];
        if (!selectedIds.length && rowData && rowData._id) {
          selectedIds = [rowData._id];
        }
        const result = await addItems({ invoke, urls, selectedIds, origin, collection });
        const { totalResources, addedResources } = result;
        const pendingItems = totalResources - addedResources;
        let toastMessage = itemsAddedMessage().replace("__itemcount__", addedResources);
        if (pendingItems && addedResources) {
          toastMessage += ` ${I18N.t("and")} ${itemIgnoredMessage().replace("__itemcount__", totalResources)}`;
        }
        if (pendingItems && !addedResources) {
          toastMessage = itemIgnoredMessage().replace("__itemcount__", totalResources);
        }
        showMessage && showMessage(toastMessage, 2000);
        onClose ? onClose() : this.setState({ loading: false });
      } catch (e) {
        let message = getErrorMessage(e, { I18N });
        this.setState({ error: message, loading: false });
      }
    };

    onRowClick = data => {
      let { setViewType } = this.props;
      if (data._id === "createNew") {
        setViewType && setViewType("createView");
      } else {
        let newState = {};
        if (this.state.error) {
          newState.error = void 0;
        }
        if (!this.state.selectedAlbum || this.state.selectedAlbum._id !== data._id) {
          newState.selectedAlbum = data;
        }
        if (Object.keys(newState).length) {
          this.setState(newState);
        }
      }
    };

    isSelected = data => {
      if (this.state.selectedAlbum && this.state.selectedAlbum._id === data._id) {
        return true;
      }
    };

    render() {
      let { data, pending, loadingMore, fetchMore, onClose, origin, activeMQ } = this.props;

      const { headerTitle, selectMessage, newCardTitle, CardComponent } = resolveMQ(
        metadata[origin],
        ["headerTitle", "selectMessage", "newCardTitle", "CardComponent"],
        activeMQ
      );

      if (!pending || (data && data.length)) {
        data = [{ _id: "createNew" }, ...data];
      }

      return (
        <View style={{ flex: 1 }}>
          {((pending && !loadingMore) || this.state.loading) && <LoadingIndicator />}
          <Header title={headerTitle} onClose={onClose} />
          {data && data.length ? (
            <Box
              viewStyle={{ flex: 1 }}
              render={[
                {
                  viewStyle: { flex: 1, paddingLeft: 18, paddingRight: 18 },
                  render: [
                    {
                      viewStyle: {
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 16,
                        paddingBottom: 14
                      },
                      textStyle: {
                        ...h16_l_22,
                        color: primaryColor
                      },
                      text: selectMessage
                    },
                    {
                      viewStyle: { flex: 1 },
                      render: (
                        <GridTable
                          data={data}
                          fetchMore={fetchMore}
                          cardPerRowSM={3}
                          cardPerRowMD={3}
                          DataRow={CollectionCard}
                          dataRowProps={{
                            onClick: this.onRowClick,
                            isSelected: this.isSelected,
                            newCardTitle,
                            CardComponent
                          }}
                          bodyStyle={{ flex: 1, paddingLeft: 12, paddingRight: 12 }}
                        />
                      )
                    }
                  ]
                },
                {
                  viewStyle: {
                    alignItems: "center",
                    paddingLeft: 24,
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingRight: 30
                  },
                  direction: "row",
                  render: [
                    {
                      viewStyle: {
                        flex: 1,
                        paddingRight: 12
                      },
                      textStyle: {
                        ...h9,
                        color: tomato
                      },
                      text: this.state.error || ""
                    },
                    {
                      viewStyle: {
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: themeBg,
                        cursor: "pointer",
                        borderRadius: 4
                      },
                      textStyle: {
                        ...h16_l_20,
                        color: highlightColor
                      },
                      text: I18N.t("add"),
                      onClick: this.onSubmit
                    }
                  ]
                }
              ]}
            />
          ) : (
            void 0
          )}
        </View>
      );
    }
  }
  return AddItemsCollectionSelector;
};
